import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarcodeComponent } from './barcode.component';
import { IonicModule } from '@ionic/angular';
import { NgxBarcodeModule } from 'ngx-barcode';



@NgModule({
  declarations: [BarcodeComponent],
  imports: [
    CommonModule,
    NgxBarcodeModule,
    IonicModule
  ],
  exports:[BarcodeComponent]
})
export class BarcodeModule { }
