import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpClient } from "@angular/common/http";
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-packetwiseapprovallist',
  templateUrl: './packetwiseapprovallist.component.html',
  styleUrls: ['./packetwiseapprovallist.component.scss'],
})
export class PacketwiseapprovallistComponent implements OnInit {

  packetwiseApprovalData : any;
  barcode: string;
  barcodeInputList: any;
  refreshGrid: boolean = false;
  columns :any[] 
  type: string;
  headingText: string;
  dashboarddata:any;
  OpinionDashData: any;

  constructor(
    public httpService: HttpService,
    public configService: ConfigService,
    public http: HttpClient,
    private loaderService: LoaderService,
    public modalCtrl:ModalController,
    private route: ActivatedRoute
  
  ) { 
    this.route.queryParams.subscribe(params => {
       this.type = params['type'];
       if (this.type) {
        this.fetchOpinionTaskData();
        this.updateHeading();
        console.log(this.OpinionDashData)
      } else {
        this.fetchGridData();
        this.updateHeading();
      }
 
      if(this.type === "rejectedcolumn"){
        this.columns = [
          { name: 'Packet Label', prop: 'packetLabel'},
          { name: 'lotNo', prop: 'lotNo'},
          { name: 'cts', prop: 'cts'},
          { name: 'From User Name', prop: 'fromUserName' },
          { name: 'Approval 1', prop: 'approval_1'},
          { name: 'Approval 1 Status', prop: 'approval_1_status' },
          { name: 'Approval 2', prop: 'approval_2'},
          { name: 'Approval 2 Status', prop: 'approval_2_status' },
          { name: 'Approval 3', prop: 'approval_3'},
          { name: 'Approval 3 Status', prop: 'approval_3_status' },
          { name: 'Rejected by User', prop: 'rejectedBy'},
          { name: 'remarks', prop: 'rejectedRemarks' },
      
        ];
      }
      else {
        this.columns = [
          { name: 'Packet Label', prop: 'packetLabel'},
          { name: 'lotNo', prop: 'lotNo'},
          { name: 'cts', prop: 'cts'},
          { name: 'From User Name', prop: 'fromUserName' },
          { name: 'Approval 1', prop: 'approval_1'},
          { name: 'Approval 1 Status', prop: 'approval_1_status' },
          { name: 'Approval 2', prop: 'approval_2'},
          { name: 'Approval 2 Status', prop: 'approval_2_status' },
          { name: 'Approval 3', prop: 'approval_3'},
          { name: 'Approval 3 Status', prop: 'approval_3_status' },
        ];

      }
    });
  }

  ngOnInit() {
    // this.updateHeading();
    // this.fetchDataBasedOnType();
    // this.fetchGridData();
  }

  clearBarcode() {
    this.barcode = null;
    // this.fetchGridData();
    this.fetchDataBasedOnType();

  }


  barcodeDetection(event) {
  
    this.barcode = (event?.toUpperCase()).trim();
    if (!this.barcode) {
      this.configService.showToast('error', 'No barcode/PacketId detected');
      return;
    }

    if (this.type) {
      const filteredData = this.OpinionDashData.filter((packet: any) =>
        packet.packetLabel.toUpperCase() === this.barcode
      );
  
      if (filteredData.length > 0) {
        this.OpinionDashData = filteredData;
      } else {
        this.configService.showToast('Error', 'No Data Available for this packet');
        this.fetchDataBasedOnType();
      }
    } else {
      const filteredData = this.packetwiseApprovalData.filter((packet: any) =>
        packet.packetLabel.toUpperCase() === this.barcode
      );
  
      if (filteredData.length > 0) {
        this.packetwiseApprovalData = filteredData;
      } else {
        this.configService.showToast('Error', 'No Data Available for this packet');
        this.fetchGridData();
      }
    }
  }

  updateHeading() {
    switch (this.type) {
      case 'pending':
        this.headingText = 'Pending Packet Opinion List:';
        break;
      case 'rejectedcolumn':
        this.headingText = 'Rejected Packet Opinion List:';
        break;
      case 'approved': 
        this.headingText = 'Approved Packet Opinion List:';
        break;
      default:
        this.headingText = 'Pending Packet Opinion List:';
        break;
    }
  }
  async fetchGridData() {
    try {
      
      let endpoint: string = `packet/packetWiseApprovalList`;
  
      const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
    
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
  
        if (resultCode === 200) {
          if (data === null || Object.keys(data).length === 0) {
            this.packetwiseApprovalData = [];
            this.configService.showToast('Error', 'No Data Available');
          } else {
            this.packetwiseApprovalData = Object.keys(data).map(key => {
              let packet = data[key];
              for (const prop in packet) {
                if (packet[prop] === null) {
                  packet[prop] = "-";
                }
              }
              return packet;
            });
            if (this.packetwiseApprovalData.length === 0) {
              this.configService.showToast('Error', 'No Data Available');
            }
          }
        } else {
            this.configService.showToast('Error', res.error);
          }
      } else {
        this.configService.showToast('Error', 'Unexpected response format');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      this.configService.showToast('Error', 'Something went wrong!');
    } finally {
      
    }
  }

  async fetchOpinionTaskData() {
    try {
      let endpoint: string = `packet/getOpinionStatusByUserId`;
      const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
      
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
  
        if (resultCode === 200) {
          if (!data) {
            this.OpinionDashData = { Pending: { data: [], count: 0 }, Completed: { data: [], count: 0 }, Rejected: { data: [], count: 0 } };
            // this.configService.showToast('error', 'No Data Available');
          } else {
            this.OpinionDashData = data;
  
            if (this.type === 'pending') {
              this.OpinionDashData = this.OpinionDashData.Pending.data;
            } else if (this.type === 'rejectedcolumn') {
              this.OpinionDashData = this.OpinionDashData.Rejected.data;
            } else if (this.type === 'approved') {
              this.OpinionDashData = this.OpinionDashData.Completed.data;
            } else {
              this.OpinionDashData = [];
            }
  
            this.OpinionDashData = this.OpinionDashData.map(packet => {
              for (const prop in packet) {
                if (packet[prop] === null) {
                  packet[prop] = "-";
                }
              }
              return packet;
            });
  
            if (this.OpinionDashData.length === 0) {
              this.configService.showToast('error', 'No Data Available');
            }
          }
        } else {
          this.configService.showToast('error', res.error);
        }
      } else {
        this.configService.showToast('error', 'Unexpected response format');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      this.configService.showToast('error', 'Something went wrong!');
    } finally {
      // Any additional cleanup if necessary
    }
  }

  refresh(){
    this.fetchDataBasedOnType();
  }
  fetchDataBasedOnType() {
    if (this.type) {
      this.fetchOpinionTaskData();
    } else {
      this.fetchGridData();
    }
  }
  
  }
  
