import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly storageKey = 'token';

  constructor() {
    window.addEventListener('storage', (event) => {
   
      if (event.key === this.storageKey) {
        this.checkSession();
      }
    });
  }

  login(sessionId: string): void {
    localStorage.setItem(this.storageKey, sessionId);
  }

  logout(): void {
    localStorage.removeItem(this.storageKey);
    localStorage.removeItem("userId");
  }

  checkSession(): void {
    const sessionId = localStorage.getItem(this.storageKey);
    if (!sessionId) {
      // Logout or notify the user
      this.logout()
    }
    window.location.reload()
    // Additional session checking logic here
  }
}