import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AlertController, ModalController } from '@ionic/angular';
import { AppState } from 'src/app/reducers';
import { routeMasterAPIs } from 'src/app/packet-route-master/state/packet-route-master.data';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
@Component({
  selector: 'app-packetcriteria',
  templateUrl: './packetcriteria.component.html',
  styleUrls: ['./packetcriteria.component.scss'],
})
export class PacketcriteriaComponent implements OnInit {

showGrid: boolean = true;
criteriaParameterLists = [];
criteriaParameterHead = [];
routeID: any;
criterialistData: any[];
criteriaList: {};
Proportion = [
  { name: 'EXCELENT', id: 1, paramNameId: 1, checked: false },
  { name: 'V.GOOD', id: 2, paramNameId: 2, checked: false },
  { name: 'FAIR', id: 3, paramNameId: 3, checked: false },
  { name: 'POOR', id: 4, paramNameId: 4, checked: false },
];
tinch = [
  { name: 'N/A', id: 1, paramNameId: 1, checked: false },
  { name: 'LIGHT BROWN', id: 2, paramNameId: 2, checked: false },
  { name: 'BROWN', id: 3, paramNameId: 3, checked: false },
  { name: 'DARKBROWN', id: 4, paramNameId: 4, checked: false },
  { name: 'DARK LC', id: 5, paramNameId: 4, checked: false },
  { name: 'MIX', id: 6, paramNameId: 4, checked: false },
];
topOpens = [
  { name: 'N/A', id: 1, paramNameId: 1, checked: false },
  { name: 'LIGHT', id: 2, paramNameId: 2, checked: false },
  { name: 'HEAVY', id: 3, paramNameId: 3, checked: false },
  { name: 'STRONG', id: 4, paramNameId: 4, checked: false },
];
BotOpens = [
  { name: 'N/A', id: 1, paramNameId: 1, checked: false },
  { name: 'LIGHT', id: 2, paramNameId: 2, checked: false },
  { name: 'HEAVY', id: 3, paramNameId: 3, checked: false },
  { name: 'STRONG', id: 4, paramNameId: 4, checked: false },
];
selectedCriteria: any[];
formData: any = {
  tableVal: {
    lowerLimit: '',
    upperLimit: '',
  },
  girdle: {
    lowerLimit: '',
    upperLimit: '',
  },
  widthMM: {
    lowerLimit: '',
    upperLimit: '',
  },
  lengthMM: {
    lowerLimit: '',
    upperLimit: '',
  },
  culet: {
    lowerLimit: '',
    upperLimit: '',
  },
  diameter: {
    lowerLimit: '',
    upperLimit: '',
  },
  ratio: {
    lowerLimit: '',
    upperLimit: '',
  },
  height: {
    lowerLimit: '',
    upperLimit: '',
  },
  crHeight: {
    lowerLimit: '',
    upperLimit: '',
  },
  crAngle: {
    lowerLimit: '',
    upperLimit: '',
  },
  pavHeight: {
    lowerLimit: '',
    upperLimit: '',
  },
  pavAngle: {
    lowerLimit: '',
    upperLimit: '',
  },
};
saveJsonPayload = {
  in: {},
  range: {},
  equals: {},
};
selectedRow: any;
UpdatePacketCriteria: any;
constructor(
  private router: Router,
  private httpService: HttpService,
  private loaderService: LoaderService,
  private configService: ConfigService,
  private alertController: AlertController,
  private modalCtrl: ModalController,
  // public popoverController: PopoverController,
  private store: Store<AppState>) {}

ngOnInit() {
  this.fetchRequiredParameterValues();
}


keyUp(event: any) {
  const pattern = /[0-9\+\ ]/;
  let inputChar = String.fromCharCode(event.key);
  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}

onClearCriteriaParameterLists() {
  this.criteriaParameterLists.map((cpl) => {
    if (cpl?.parameterName) {
      cpl?.paramValues.map((pv) => {
        pv.isSelected = false;
      });
    }
  });
}

addRange(value) {
  this.formData['tableVal'] = value['tableVal'] ?? '-';
  this.formData['crAngle'] = value['crAngle'] ?? '-';
  this.formData['crHeight'] = value['crHeight'] ?? '-';
  this.formData['culet'] = value['culet'] ?? '-';
  this.formData['height'] = value['height'] ?? '-';
  this.formData['diameter'] = value['diameter'] ?? '-';
  this.formData['girdle'] = value['girdle'] ?? '-';
  this.formData['ratio'] = value['ratio'] ?? '-';
  this.formData['lengthMM'] = value['lengthMM'] ?? '-';
  this.formData['pavAngle'] = value['pavAngle'] ?? '-';
  this.formData['pavHeight'] = value['pavHeight'] ?? '-';
  this.formData['widthMM'] = value['widthMM'] ?? '-';
}

fetchRequiredParameterValues() {
  let payloadObj: object = {
    names: [
      'Color',
      'Shape',
      'Fluorescence',
      'Clarity',
      'Polish',
      'Symmetry',
      'Tinch',
      'Cut',
      'Milkyness',
      'Proportion',
      'Lab',
      'Depth',
      'Luster',
      'Natts',
      'Tension Type',
      'TOP OPEN',
      'BOTTOM OPEN',
    ],
  };
  // let payloadObj: object = {
  //   names: this.parameterNames
  // };
  this.httpService
    .getData(true, routeMasterAPIs.parameterValuesEndpoint, payloadObj)
    .subscribe((result) => {
      if (result.statusCode === 200) {
        this.criteriaParameterLists = result?.data.map((dt) => {
          return {
            ...dt,
            startIndex: null,
            endIndex: null,
            paramValues: dt?.paramValues?.map((pv) => {
              return { ...pv, isSelected: false };
            }),
          };
        });
        this.criteriaParameterHead = result?.data.map(
          (dt) => dt.parameterName
        );

        this.criterialistData = [];
        this.criteriaList = {};
        result.data.forEach((d) => {
          let obj = {
            paramId: d.parameterNameId,
            property: 'name',
            list: d.paramValues, //[]
            selected: [],
            isMultiple: true,
            name: d.parameterName,
          };
          this.criteriaList[d.parameterName] = d.parameterNameId;
          // if (d.parameterName == 'Color') {
          //   this.colorList['name'] = 'Color';
          //   this.colorList['property'] = 'name';
          //   this.colorList['list'] = d.paramValues;
          // } else if (d.parameterName == 'Shape') {
          //   this.shapeList['name'] = 'Shape';
          //   this.shapeList['property'] = 'name';
          //   this.shapeList['list'] = d.paramValues;
          // } else {

          // }
          this.criterialistData.push(obj);
        });
        let obj = {
          paramId: null,
          property: 'name',
          list: this.Proportion, //[]
          selected: [],
          isMultiple: true,
          name: 'Proportion',
        };
        this.criterialistData.push(obj);
        let obj2 = {
          paramId: null,
          property: 'name',
          list: this.tinch, //[]
          selected: [],
          isMultiple: true,
          name: 'Tinch',
        };
        this.criterialistData.push(obj2);
        let obj3 = {
          paramId: null,
          property: 'name',
          list: this.topOpens, //[]
          selected: [],
          isMultiple: true,
          name: 'TopOpens',
        };
        this.criterialistData.push(obj3);
        let obj4 = {
          paramId: null,
          property: 'name',
          list: this.BotOpens, //[]
          selected: [],
          isMultiple: true,
          name: 'BotOpens',
        };
        this.criterialistData.push(obj4);
      } else {
        this.httpService.getErrorAndDisplayIt(result);
      }
    });
}


emptyData() {
 
  this.formData = {
    tableVal: {
      lowerLimit: '',
      upperLimit: '',
    },
    girdle: {
      lowerLimit: '',
      upperLimit: '',
    },
    widthMM: {
      lowerLimit: '',
      upperLimit: '',
    },
    lengthMM: {
      lowerLimit: '',
      upperLimit: '',
    },
    culet: {
      lowerLimit: '',
      upperLimit: '',
    },
    diameter: {
      lowerLimit: '',
      upperLimit: '',
    },
    ratio: {
      lowerLimit: '',
      upperLimit: '',
    },
    height: {
      lowerLimit: '',
      upperLimit: '',
    },
    crHeight: {
      lowerLimit: '',
      upperLimit: '',
    },
    crAngle: {
      lowerLimit: '',
      upperLimit: '',
    },
    pavHeight: {
      lowerLimit: '',
      upperLimit: '',
    },
    pavAngle: {
      lowerLimit: '',
      upperLimit: '',
    },
  };

}
async onSelectParamater(parameter, value) {
  console.log('Selected Parameter:', parameter);
  console.log('Selected Value:', value);
  if (
    !!this.criteriaParameterLists &&
    this.criteriaParameterLists.length > 0
  ) {

    //range logic

    // let startIndex, endIndex;
    // this.criteriaParameterLists.map((cpl) => {

    //   if (cpl.parameterName === parameter.parameterName) {
    //     if (cpl?.startIndex != null && cpl?.endIndex != null) {
    //       cpl.startIndex = startIndex = null;
    //       cpl.endIndex = endIndex = null;
    //     }
    //     if (cpl?.startIndex != null && cpl?.endIndex == null) {
    //       let index = cpl?.paramValues?.findIndex(
    //         (val) => val.id === value.id
    //       );
    //       if (index > -1) {
    //         cpl.endIndex = endIndex = index;
    //       }
    //     } else if (cpl?.endIndex != null && endIndex == null) {
    //       endIndex = cpl.endIndex;
    //     }
    //     if (cpl?.startIndex == null) {
    //       let index = cpl?.paramValues?.findIndex(
    //         (val) => val.id === value.id
    //       );
    //       if (index > -1) {
    //         cpl.startIndex = startIndex = index;
    //       }
    //     } else if (startIndex == null) {
    //       startIndex = cpl.startIndex;
    //     }
    //     if (startIndex != null && endIndex == null) {
    //       cpl?.paramValues?.map((pv, i) => (pv.isSelected = false));
    //       cpl.paramValues[startIndex].isSelected = true;
    //     } else if (startIndex != null && endIndex != null) {
    //       cpl?.paramValues?.map((pv, i) => {
    //         pv.isSelected = false;
    //         if (i >= startIndex && i <= endIndex) {
    //           pv.isSelected = !pv.isSelected;
    //         }
    //       });
    //     }
    //   }
    // });
    // normal select
    const selectedParamDoc = this.criteriaParameterLists.find((el: any) => el['parameterNameId'] === value['paramNameId'])
    const selectedParamDocValues = selectedParamDoc.paramValues.find((el: any) => el.id === value?.id)
    selectedParamDocValues.isSelected = !selectedParamDocValues.isSelected
  }


  let selectedValues = this.criteriaParameterLists.map((cpl) => ({
    ...cpl,
    paramValues: cpl.paramValues.filter((pv) => pv.isSelected),
  }));
  this.selectedCriteria = selectedValues.filter(
    (item) => item.paramValues.length > 0
  );
  console.log(selectedValues)

  let newArray = [];
  this.selectedCriteria = await this.selectedCriteria.map((x) => {
    if (x.parameterName) {
      let obj = {};
      obj[x.parameterName] = {
        value: [],
      };
      let valuesArray = x.paramValues.map((y) => y.id);
      obj[x.parameterName]['value'] = valuesArray;
      newArray.push(obj);
    }
  });
  let newObject = {};

  newArray.forEach((obj) => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    if (!newObject[key]) {
      newObject[key] = value;
    } else {
      newObject[key].value = newObject[key].value.concat(value.value);
    }
  });

  let formDataWithRange = Object.entries(this.formData)
    .filter(([key, value]) => typeof value === 'object' && value !== null && ('lowerLimit' in value || 'upperLimit' in value))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  console.log(newObject)
  this.saveJsonPayload.in = newObject;
  this.saveJsonPayload.range = formDataWithRange;
  console.log(this.saveJsonPayload);



}
}

