import { createReducer, on } from "@ngrx/store";
import { tempDataActions } from "./tempData.action-type";
import { initialTempDataState } from "./tempData.state";

export const tempDataReducer = createReducer(
    initialTempDataState,
    on(tempDataActions.setCurrentAction, (state, action) => {
        return {
            ...state,
            currentAction: action.actionData
        }
    }),
    on(tempDataActions.resetCurrentAction, (state, action) => {
        return {
            ...state,
            currentAction: null
        }
    }),
    on(tempDataActions.setCurrentSearch, (state, action) => {
        return {
            ...state,
            currentSearchData: action.searchData
        }
    }),
    on(tempDataActions.resetCurrentSearch, (state, action) => {
        return {
            ...state,
            currentSearchData: null
        }
    }),
    on(tempDataActions.saveCurrentRowData, (state, action) => {
        return {
            ...state,
            rowData: action.rowData
        };
    }),
    on(tempDataActions.saveConversation, (state, action) => {
        return {
            ...state,
            chatData: action.chatData
        };
    }),
    on(tempDataActions.removeConversation, (state, action) => {
        return {
            ...state,
            chatData: []
        };
    }),
    on(tempDataActions.removeCurrentRowData, (state, action) => {
        return {
            ...state,
            packetPlans: []
        };
    }),
    on(tempDataActions.addOrderRemarkDataToStore, (state, action) => {
        return {
            ...state,
            ids: action.ids
        };
    }),
)