export const HEADER_COLUMNS = [
    {
      name: 'Plan Seq No',
      size: 68,
      showCheckbox: true,
    },
    {
      name: 'Sub Seq No',
      size: 68,
      valueKey: 'subSeqTag',
    },
    {
      name: 'Shape',
      size: 55,
      valueKey: 'shapeId',
    },
    {
      name: 'Saw Rgh Wt',
      size: 55,
      valueKey: 'sawRghWgt',
    },
    {
      name: 'Exp Pol Wt',
      size: 55,
      valueKey: 'expPol',
    },
    {
      name: 'Yield %',
      size: 67,
      valueKey: 'yield',
    },
    {
      name: 'Col',
      size: 42,
      valueKey: 'colorId',
    },
    {
      name: 'Clarity',
      size: 52,
      valueKey: 'clarityId',
    },
    {
      name: 'FL',
      size: 36,
      valueKey: 'fluorescenceId',
    },
    {
      name: 'Cut',
      size: 50,
      valueKey: 'cutId',
    },
    {
      name: 'Pol',
      size: 50,
      valueKey: 'polishId',
    },
    {
      name: 'Sym',
      size: 50,
      valueKey: 'symmetryId',
    },
    {
      name: 'Natts',
      size: 58,
      valueKey: 'natts',
    },
    {
      name: 'Tinch',
      size: 39,
      valueKey: 'tinch',
    },
    {
      name: 'Luster',
      size: 58,
      valueKey: 'luster',
    },
    {
      name: 'Dia / Ratio',
      size: 56,
      valueKey: 'diameter',
    },
    {
      name: 'PD Cat',
      size: 40,
      valueKey: 'pdCat',
    },
    {
      name: 'Depth %',
      size: 53,
      valueKey: 'height',
    },
    {
      name: 'LAB',
      size: 41,
      valueKey: 'mLab',
    },
    {
      name: 'Pointer Range',
      size: 55,
      valueKey: 'pointer_range',
    },
    {
      name: 'Value',
      size: 55,
      valueKey: 'kgRate',
    },
    {
      name: 'Labour',
      size: 54,
      valueKey: 'labour',
    },
    {
      name: 'Orders',
      size: 48,
      valueKey: 'maxOrderValue',
      showOrders: true,
    },
    {
      name: 'Net value',
      size: 77,
      valueKey: 'net_stone_value',
    },
    {
      name: 'Rank',
      size: 42,
      valueKey: '',
    },
    {
      name: 'Diff',
      size: 56,
      valueKey: '',
    },
    {
      name: 'Assort',
      size: 58,
      valueKey: 'assort',
    },
    {
      name: 'Trend',
      size: 56,
      valueKey: 'trend',
    },
    {
      name: 'Approver Name',
      size: 56,
      valueKey: 'approvals',
    },
    {
      name: 'Remarks',
      size: 56,
      valueKey: '',
    },
    {
      name: 'Mistake Of',
      size: 56,
      valueKey: '',
    },
    {
      name: 'Process',
      size: 60,
      valueKey: 'actionName',
    },
    {
      name: 'Department',
      size: 78,
      valueKey: 'deptName',
    },
    {
      name: 'Worker',
      size: 56,
      valueKey: '',
      class: 'br-none'
    },
];

export const SUGGESTED_HEADER_COLUMNS = [
    {
      name: 'Plan Seq No',
      size: 68,
      showCheckbox: true,
    },
    {
      name: 'Sub Seq No',
      size: 68,
      valueKey: 'subSeqTag',
    },
    {
      name: 'Shape',
      size: 55,
      valueKey: 'shape',
    },
    {
      name: 'Saw Rgh Wt',
      size: 55,
      valueKey: 'sawRghWgt',
    },
    {
      name: 'Exp Pol Wt',
      size: 55,
      valueKey: 'expPol',
    },
    {
      name: 'Yield %',
      size: 67,
      valueKey: 'yield',
    },
    {
      name: 'Col',
      size: 42,
      valueKey: 'color',
    },
    {
      name: 'Clarity',
      size: 52,
      valueKey: 'clarity',
    },
    {
      name: 'FL',
      size: 36,
      valueKey: 'fluorescence',
    },
    {
      name: 'Cut',
      size: 40,
      valueKey: 'cut',
    },
    {
      name: 'Pol',
      size: 40,
      valueKey: 'pol',
    },
    {
      name: 'Sym',
      size: 40,
      valueKey: 'symmetry',
    },
    {
      name: 'Natts',
      size: 58,
      valueKey: 'nattsName',
    },
    {
      name: 'Tinch',
      size: 39,
      valueKey: 'tinchName',
    },
    {
      name: 'Luster',
      size: 58,
      valueKey: 'lusterName',
    },
    {
      name: 'Dia / Ratio',
      size: 56,
      valueKey: 'diameter',
    },
    {
      name: 'PD Cat',
      size: 40,
      valueKey: 'pdCat',
    },
    {
      name: 'Depth %',
      size: 53,
      valueKey: 'height',
    },
    {
      name: 'LAB',
      size: 41,
      valueKey: 'mLabName',
    },
    {
      name: 'Pointer Range',
      size: 55,
      valueKey: 'pointer_range',
    },
    {
      name: 'Value',
      size: 55,
      valueKey: 'kgRate',
    },
    {
      name: 'Labour',
      size: 54,
      valueKey: 'labour',
    },
    {
      name: 'Orders',
      size: 48,
      valueKey: 'maxOrderValue',
      showOrders: true,
    },
    {
      name: 'Net value',
      size: 56,
      valueKey: 'net_stone_value',
    },
    {
      name: 'Rank',
      size: 42,
      valueKey: 'rank',
    },
    {
      name: 'Diff',
      size: 56,
      valueKey: 'diff',
    },
    {
      name: 'Assort',
      size: 58,
      valueKey: 'assort',
    },
    {
      name: 'Trend',
      size: 40,
      valueKey: 'trend',
  },
     {
      name: 'Created By',
      size: 40,
      valueKey: 'planGiveBy',
    },
    {
      name: 'Approver Name',
      size: 56,
      valueKey: 'approvals',
    },
    {
      name: 'Remarks',
      size: 56,
      valueKey: 'remark',
    },
    {
      name: 'Mistake Of',
      size: 56,
      valueKey: 'mistakeOff',
    },
    {
      name: 'Process',
      size: 60,
      valueKey: 'process',
    },
    {
      name: 'Department',
      size: 78,
      valueKey: 'deptId',
    },
    {
      name: 'Worker',
      size: 56,
      valueKey: 'userId',
    },
    {
      name: 'Action',
      size: 44,
      valueKey: '',
      class: 'br-none',
      showDelete: true,
    },
];