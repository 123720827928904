import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { withLatestFrom, mergeMap, map, catchError } from "rxjs/operators";
import { HttpService } from "src/app/services/http/http.service";
import { Int_GET_ChildParameters, Int_GET_MasterParameter, MasterState } from "./masters.interface";
import { GET_CHILD_PARAMETERS, GET_CHILD_PARAMETERS_SUCCESS, GET_MASTER_PARAMETERS, GET_MASTER_PARAMETERS_SUCCESS } from "./masters.action";
import { selectMasterParameters } from "./masters.selector";
import { EMPTY } from "rxjs";

@Injectable()
export class MasterEffect {
  constructor(private httpService: HttpService, private store: Store<MasterState>, private action$: Actions) {}

  loadMasterParameters$ = createEffect(() =>
    this.action$.pipe(
      ofType(GET_MASTER_PARAMETERS),
      withLatestFrom(this.store.select(selectMasterParameters)),
      mergeMap(([action, currentState]) => {
        return this.httpService.getAllMasterParameters().pipe(
          map((res: any) => {
            const { data } = res;
            const tempModal: Int_GET_MasterParameter = {
              masterList: data ? JSON.parse(JSON.stringify(data)) : [],
              isLoaded: true,
            };
            return GET_MASTER_PARAMETERS_SUCCESS({ payload: tempModal });
          })
        );
      })
    )
  );

  loadParameters$ = createEffect(() =>
    this.action$.pipe(
        ofType(GET_CHILD_PARAMETERS),
        withLatestFrom(this.store.select(selectMasterParameters)),
        mergeMap(([action, currentState]) => {
        return this.httpService.getChildParameterDataByMasterId(action.ID).pipe(
            map((res: any) => {

              if(res.statusCode !==200){
                const tempModal: any = {
                  parameters: [],
                  isLoaded: true,
                  stoneParameterNameId:action.ID
              };
              return GET_CHILD_PARAMETERS_SUCCESS({ payload: tempModal })
              
              }

            const { data } = res;
            const tempModal: any = {
                parameters: data ? JSON.parse(JSON.stringify(data)) : {},
                isLoaded: true,
                stoneParameterNameId:action.ID
            };
            return GET_CHILD_PARAMETERS_SUCCESS({ payload: tempModal});
            }),
        );
        })
    )
    );

}