import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DropdownComponent } from './dropdown.component';



@NgModule({
  declarations: [DropdownComponent],
  imports: [
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule
  ],
  exports: [DropdownComponent],
})
export class DropdownModule { }
