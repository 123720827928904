import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { HttpService } from '../services/http/http.service';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';
import { Store, select } from '@ngrx/store';
import { ModalController, PopoverController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import {
  getCompletedPacketsData,
  getPendingPacketsData,
} from '../home/state/home.selector';
import { takeUntil } from 'rxjs/operators';
import { HomeActions } from '../home/state/home.action-type';
import { getCurrentAction } from '../tempDataStore/tempData.selector';
import { PopoverUserComponent } from '../components/popover-user/popover-user.component';
import { virtualAPIs } from '../components/my-virtual-task/state/my-virtual-task.data';


@Component({
  selector: 'app-qc-check-and-annotation',
  templateUrl: './qc-check-and-annotation.component.html',
  styleUrls: ['./qc-check-and-annotation.component.scss'],
})
export class QcCheckAndAnnotationComponent implements OnInit {
  rowData: any;
  qcActionData;
  private unsubscribe$: Subject<any> = new Subject<any>();
  currentIndex = 0;
  packetData;
  actionStatus: string = '';
  isCleaning: any;
  hideHeader: boolean = false;
  dropdown: any = {};
  issuePacketId: any;
  videoOfTension: any = '';
  isVirtual: number = 1;
  packetIDUrl: string = '';
  selfIssuePacketId: any;
  selfIssueNoFilterId: any;
  wholeDataOfCompleted: any;
  selectedPacketNoInCompleted: any;
  qcCheckStatus: any;
  @Input() data: any;
  @Input() forPage: any='' ;
  dropDownCopyPacketData: any;
  packetDataArrList: any = false;
  isButtonDisabled: boolean = true;
  rvVerify: any;
  fileName: any;
  showUploadbtn: boolean = false;
  isCountShown: boolean = false;
  packetCount: number;
  capFileUrl:any;
  selectedFileNew: any;
  remark: any;
  // @Input() forPage: any = '';
  private subscriptions: Subscription[] = [];
  isUpdateDisable: boolean = true;
  dropdownCleaning: any = {
    name: 'cleaning',
    placeText: 'Select',
    options: [
      {
        id: 1,
        name: 'yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };

  // ************************
  isFilter: boolean = false;
  finalSelection: any;
  shownSelection: any = '';
  dropDownLotData: any = {
    name: 'lotNo',
    placeText: 'lot no.',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };
  dropDownPacketData: any = {
    name: 'PacketNo',
    placeText: 'Pacekt No.',
    options: [],
    selectedData: [],
    key: 'packet',
    modeFlag: 'single', //single/multiple
  };
  unique: any = [];
  form;
  packetName: any = [];
  arrayOfPacketData: any = [];
  constructor(
    private httpService: HttpService,
    private _ConfigService: ConfigService,
    public loaderService: LoaderService,
    public store: Store,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    public popoverController: PopoverController,
  ) {}

  ngOnInit() {
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          console.log('Selected action');
          console.log(action);
          this.qcActionData = action;
          this.actionStatus = action.status;
        }
      });

if(this.forPage == '' || this.forPage == 'undefined'){
  this.store.dispatch(
    HomeActions.getActionNStatusWisePacketList({
      actionId: 311,
      status: this.qcActionData.status,
    })
    );
  }
  if(this.forPage == '' || this.forPage == 'undefined'){

      if (this.qcActionData.status == 'Pending') {
        this.subscriptions.push(
          this.store
            .pipe(select(getPendingPacketsData(this.qcActionData.id)))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              if (data) {
                this.data = data;
                this.packetData = Object.values(data);
                this.dropDownCopyPacketData = Object.values(data);
                console.log(this.packetData);
                let lotNo = this.packetData.map((item) => item.lotNo);
                console.log(lotNo);

                this.unique = Array.from(new Set(lotNo));
                console.log(this.packetData);
                this.dropDownPacketData.options = this.unique;
                if (this.actionStatus == 'Pending') {
                  this.fetchVideoOfTension();
                  this.unsubscribe();
                }
              }
            })
        );
      }

     else if (this.qcActionData.status == 'Completed') {
      this.subscriptions.push(
        this.store
          .pipe(select(getCompletedPacketsData(this.qcActionData.id)))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data) => {
            if (data) {
              this.data = data;
              this.packetData = Object.values(data);
              this.dropDownCopyPacketData = Object.values(data);
              console.log(this.packetData);
              this.wholeDataOfCompleted = this.packetData;
              console.log(this.wholeDataOfCompleted);
              let lotNo = this.packetData.map((item) => item.lotNo);
              console.log(lotNo);

              this.unique = Array.from(new Set(lotNo));
              console.log(this.packetData);
              this.dropDownPacketData.options = this.unique;
              //  this.stressName = this.packetData[0]?.inputDetails[0]?.paramSValue;
              //  this.stressValue = this.packetData[0]?.inputDetails[1]?.paramSValue;
              if (this.actionStatus == 'Completed') {
                // this.dropdownCleaning.selectedData=this.packetData[0].inputDetails[0].paramSValue;

                // if(!!this.packetData[0].inputDetails){

                this.qcCheckStatus =
                  this.packetData[0]?.inputDetails[
                    this.packetData[0]?.inputDetails?.length - 1
                  ]?.paramSValue;

                console.log(this.qcCheckStatus);
                this.rvVerify = this.qcCheckStatus;

                this.fetchVideoOfTension();
                this.unsubscribe();
              }
            }
          })
      );
    }
  }
    if (this.forPage == 'qvCheck') {
      this.packetData = this.data;
      this.dropDownCopyPacketData = Object.values(this.packetData);
      console.log(this.data, this.packetData);
      let lotNo = this.data.map((item) => item.lotNo);
      this.unique = Array.from(new Set(lotNo));
      console.log(this.data);
      this.dropDownLotData.options = this.unique;
      this.qcActionData = { id: 132, name: 'Tension Mapping' };
      this.isVirtual = 1;
      this.donwloadCapFile();

      this.fetchVideoOfTension();
    }


    console.log(this.packetData);
    this.cdr.detectChanges();
    //   this.dropdownCleaning.options=[{
    //     id:1,
    //     name:"yes"
    //   },
    // {
    //   id:2,
    //   name:"No"
    // }]
  }

  
  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
        this.isCleaning = evt.arrayList[0].name;
        console.log(this.isCleaning);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.selectedPacketNoInCompleted = this.dropdown.lotNo;
    console.log(this.selectedPacketNoInCompleted);
    // this.isCleaning = this.dropdown.lotNo.name;
    this.isUpdateDisable =
      Object.keys(this.dropdown).length === 0 ? true : false;
  }

  async donwloadCapFile() {
    let payload = {
      packetIds: [this.packetData?.[this.currentIndex]?.id]
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(
      true,
      'processActionInput/getFileThroughCentralSystemForDbcCap',
      payload
    );
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.capFileUrl=response.data[0];
      console.log(this.capFileUrl);
     let file =  this._ConfigService.getTrybeBaseUrl() +`${this.capFileUrl}`
    //  let file = "https://kps-pd.s3.ap-south-1.amazonaws.com//javascript_tutorial.pdf"
      if(this.capFileUrl){
        console.log(file);
        window.open(file,"Download")
      }
    } else {
      console.error(response);
    this._ConfigService.showToast("error","Cap File Downloaded failed")

    }
  }

  // async downloadFile(file) {
  //   try {
  //     // Step 1: Make an HTTP request to get the file URL from the API
  //     const fileUrl = file

  //     // Step 2: Create a safe URL from the file URL
  //     const safeFileUrl: any = this.domSanitizer.bypassSecurityTrustResourceUrl(fileUrl);

  //     // Step 3: Create a link and trigger the download
  //     const link = document.createElement('a');
  //     link.href = safeFileUrl.toString();
  //     link.download = 'downloaded_file.txt'; // Change the filename as needed
  //     document.body.appendChild(link);
  //     link.click();

  //     // Clean up: Remove the link from the DOM
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error('Error downloading the file:', error);
  //   }
  // }

  onRvSelection(event) {
    console.log(event);
    this.rvVerify = event.detail.value;
    if (this.rvVerify == 'No') {
      console.log('user selected No');
      this.showUploadbtn = false;
      // if (this.actionStatus == 'Pending') {
      this.remarkModal();
      // }
    } else if (this.rvVerify == 'yes') {
      this.showUploadbtn = true;
    }
    else{
      this.showUploadbtn=false;
    }
  }

  dropDownSelection(evt: any, plan: any) {
    // this.arrayOfPacketData = [];

    console.log('dropDownSelection');
    console.log(evt);

    this.finalSelection = evt.arrayList[0];

    if (evt.property == 'PacketNo') {
      if (evt.arrayList.length === 0) {
        this.dropDownLotData.options = [];
        this.isButtonDisabled = true;
        this.isCountShown = false;
      } else {
        this.isCountShown = true;
      }
      // this.dropDownPacketData.selectedData = [];
      this.dropDownLotData.selectedData = [];
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);

      let map = new Map();
      for (let item of this.dropDownCopyPacketData) {
        let lotNo = item.lotNo;
        if (map.has(lotNo)) {
          let data = map.get(lotNo);
          data.push(item.packetId);
          map.set(lotNo, data);
        } else {
          map.set(lotNo, [item.packetId]);
        }
      }

      console.log(map);

      // arrayOfPacketData: any = [];
      Array.from(map)
        .map(([name, value]) => ({ name, value }))
        .filter((data: any) => {
          if (evt.arrayList[0] === data.name) {
            this.dropDownLotData.options = data.value;
            this.packetCount = this.dropDownLotData.options.length;

            return true;
          }
          return false;
        });

      console.log(this.arrayOfPacketData);
      this.packetName = this.arrayOfPacketData;
      console.log(this.dropDownLotData.selectedData);
    } else if (evt.property == 'lotNo') {
      if (evt.arrayList.length == 0) {
        this.isButtonDisabled = true;
      } else {
        this.isButtonDisabled = false;
      }
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);
    } else if (evt.property == 'cleaning') {
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);
      console.log(this.dropdownCleaning);
    }
  }

  showNextArray(): void {
    if (this.isFilter != true) {
      if (this.currentIndex === this.packetData.length - 1) {
        return; // Stop if last index is reached
      }
      this.currentIndex++;
    }
    this.fetchVideoOfTension();
  }

  uploadFile(event) {
    const file = event.target.files[0];
    this.selectedFileNew = event.target.files[0];
    console.log(this.selectedFileNew);
    this.fileName = this.selectedFileNew.name;
    console.log(this.fileName);
  }

  // onSaveClick() {
  //   if (this.rvVerify == 'yes') {
  //     let formType = 'Multipart';
  //     let formatData = new FormData();
  //     formatData.append('file', this.selectedFileNew);
  //     let obj = {
  //       packetId: 820,
  //       listOfParameter: [],
  //     };
  //     console.log(obj);
  //     let barcodeObj = {
  //       packetId: this.packetData[this.currentIndex].packetId,
  //     };
  //     let isTensionVirtual = this.isVirtual.toString();

  //     formatData.append('listOfAllPackets', JSON.stringify(obj));
  //     formatData.append('isTensionVirtual', isTensionVirtual);

  //     console.log(formatData);

  //     this.store.dispatch(
  //       masterDataActions.saveStoneParamater({
  //         parameterDetails: formatData,
  //         actionId: this.qcActionData.id,
  //         formType: formType,
  //         barcodeObj: barcodeObj,
  //         actionInfo: this.qcActionData.name,
  //       })
  //     );
  //   } else if (this.rvVerify == 'No') {
  //     let listOfParameter = [];
  //     let isCleaning = {
  //       parameterName: 'isRemark',
  //       parameterValue: this.remark,
  //     };
  //     console.log(isCleaning);

  //     listOfParameter.push(isCleaning);

  //     let barcodeObj = {
  //       packetId: this.packetData[this.currentIndex].packetId,
  //     };
  //     let obj = {
  //       listOfAllPackets: [
  //         {
  //           packetId: this.packetData[this.currentIndex].id,
  //           listOfParameter,
  //         },
  //       ],
  //       isTensionVirtual: this.isVirtual,
  //     };
  //     console.log(this.qcActionData.name);
  //     console.log(barcodeObj);
  //     console.log(this.currentIndex);
  //     console.log(listOfParameter);
  //     console.log(obj);
  //     console.log(this.packetData);
  //     this.store.dispatch(
  //       masterDataActions.saveStoneParamater({
  //         parameterDetails: obj,
  //         actionId: this.qcActionData.id,
  //         formType: 'JSON',
  //         barcodeObj: barcodeObj,
  //         actionInfo: this.qcActionData.name,
  //       })
  //     );
  //   }
  // }

  onSaveClick(){
    let formData={
      "Tension Mapping": 5582
  }
      let formType: string = 'JSON'


      let fromJsonInputsModified = {
        "packetId": "",
        "listOfParameter": [
            {
                "parameterName": "Qc Check",
                "parameterValue": "Yes"
            }
        ]
    };

    if (this.rvVerify === "No") {
      // Modify the listOfParameter object for "No" case
      fromJsonInputsModified.listOfParameter = [
        {
          "parameterName": "remark",
          "parameterValue": this.remark
        },
        // Add more parameter objects if needed
      ];
    }
      fromJsonInputsModified.listOfParameter =
        fromJsonInputsModified.listOfParameter.map((x) => {
          const xData = { ...x };
          let paramName = xData.parameterName;
          if (formData[paramName]) {
            xData.parameterValue = formData[paramName];
          }
          return { ...xData };
        });
      fromJsonInputsModified.packetId = this.packetData[this.currentIndex].id;
      // let barcode = this.selectedRow.packetId;
      let parameters = {
        listOfAllPackets: [],
      };
   
      if (this.rvVerify=="yes") {
        const formatData = new FormData();
        formType = 'Multipart';
        formatData.append('file', this.selectedFileNew);
        formatData.append('id', this.packetData[this.currentIndex].virtualId);
        let newListOfParameters = fromJsonInputsModified.listOfParameter.filter((d: any) => d.parameterValue != '');
        fromJsonInputsModified.listOfParameter = newListOfParameters;
        parameters['listOfAllPackets'].push(fromJsonInputsModified);
        formatData.append('listOfAllPackets', JSON.stringify(parameters['listOfAllPackets']));
        this.saveMyVirtualTask(formatData, formType);
      } else if(this.rvVerify=="No") {
        parameters['listOfAllPackets'].push(fromJsonInputsModified);
        console.log(parameters);
        this.saveMyVirtualTask(parameters, formType);
      }
    
  }

  saveMyVirtualTask(payload: any, formType: string) {
    console.log('saving my virtual task');
    
    let endpoint: string = `${virtualAPIs.saveVirtualTaskEndPoint}${this.packetData[this.currentIndex].virtualAction}`;
    console.log(payload, formType, endpoint);
    this.httpService.saveDataToDB(true, endpoint, payload, formType).subscribe((res: any) => {
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        let message: string = res.message;
        if (resultCode === 200) {
          this.modalCtrl.dismiss();
          this._ConfigService.showToast("success","Qc Task Completed Successfully")
          
          this.selectedFileNew="";
        } else {
          
        
        }
      }
    })
  }

  async selfIssueAndReceive(packetData) {
    let payload = {};
    if (this.isFilter == true) {
      console.log(this.selfIssuePacketId);

      const packetIdToFilter = this.selfIssuePacketId; // Specify the packetId to filter

      const filteredIds = packetData
        .filter((obj) => obj.packetId === packetIdToFilter)
        .map((obj) => obj.id);
      let payload = {
        packetIds: filteredIds,
      };

      console.log(payload);
    } else {
      let nonFilterId = this.selfIssueNoFilterId.listOfAllPackets[0].packetId;
      payload = {
        packetIds: [nonFilterId],
      };
    }

    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(
      true,
      'packet/selfIssueAndReceiveForStn',
      payload
    );
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dropdownCleaning.selectedData = [];
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this._ConfigService.showToast('success', data);
    } else {
      console.error(response);
      this._ConfigService.showToast('error', response.error.message);
    }
  }

  async fetchVideoOfTension() {
    if (!!this.packetData) {
      console.log(this.packetData);
      console.log(this.currentIndex);
      console.log('fetchAllActions');
      const packetID = this.packetData?.[this.currentIndex]?.id;
      if (packetID) {
        let response: any = await this.httpService.fetchDataOfTensionFile(
          true,
          `processActionInput/getFileThroughCentralSystem/${packetID}`
        );
        console.log(response);
        const barcode = this.httpService.getErrorAndDisplayIt(response);
        const self = this;
        if (response.statusCode === 200) {
          const filePath = response.data;
          setTimeout(function () {
            self.videoOfTension =
              self.domSanitizer.bypassSecurityTrustResourceUrl(
                `${self._ConfigService.getTrybeBaseUrl()}${filePath}`
              );
            console.log(self.videoOfTension);
            console.log(
              '%cActions----------',
              'color:green;font-weight:bold;font-size:1rem;'
            );
          }, 1000);
        } else {
          console.error(response);
        }
      }
    }
  }

  unsubscribe() {
    // Unsubscribe from all subscriptions in the array
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  getData(e) {
    console.log(e);
    this.isFilter = true;
    console.log(this.dropDownPacketData.options);
    //  this.packetData[0].packetId = this.finalSelection =this.shownSelection;

    //  let target = ;
    //  let temp:any = {}
    //  for(let item of (this.data)){
    //      if(item.packetId == target){
    //          temp = item
    //          break
    //      }
    //  }

    this.shownSelection = this.finalSelection;
    console.log(this.dropDownPacketData.selectedData);
    console.log('temp', this.shownSelection);
    console.log(this.finalSelection);
    this.selfIssuePacketId = this.finalSelection;
    const desiredId = this.finalSelection;

    // const desiredObject = [
    //   this.packetData.find((obj) => obj.packetId === desiredId),
    // ];
    const desiredObject = [
      this.dropDownCopyPacketData.find((obj) => obj.packetId === desiredId),
    ];
    console.log(desiredObject);
    this.packetData = desiredObject;
    console.log(desiredObject);
    this.packetData = desiredObject;

    console.log(this.packetData);

    console.log(this.wholeDataOfCompleted);
    console.log(this.selectedPacketNoInCompleted);

    // const desiredPacketId = "10645-12-DIAVIK#57";

    if (!!this.packetData[0].inputDetails) {
      const filteredData = this.wholeDataOfCompleted.filter(
        (item) => item.packetId === this.selectedPacketNoInCompleted
      );

      this.qcCheckStatus =
        filteredData[0].inputDetails[
          filteredData[0].inputDetails.length - 1
        ].paramSValue;
      console.log(this.qcCheckStatus);
      this.rvVerify = this.qcCheckStatus;
    }

    this.fetchVideoOfTension();
  }

  async remarkModal() {
    console.log('-------onUpdate......');
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'rvVerify',
      componentProps: { id: 1, forPage: 'rvVerifyRemark' },
    });
    await popover.present();
    popover.onDidDismiss().then((res) => {
      if (res.data) {
        console.log(res.data);
        this.remark = res.data.remark;
      }
    });
  }

  onUpload() {}
}
