import {
  Component, EventEmitter, Input,
  OnInit, Output, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AlertController,
  ModalController
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ScannerinputComponent } from '../../scannerinput/scannerinput.component';
//import { AppDataGridComponent } from '../../app-data-grid/app-data-grid.component';
import DataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import Query from 'devextreme/data/query';

@Component({
  selector: 'app-work-quality-master',
  templateUrl: './work-quality-master.component.html',
  styleUrls: ['./work-quality-master.component.scss'],
})
export class WorkQualityMasterComponent implements OnInit {
  //@ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;
  //@ViewChild(AppDataGridComponent) dataGrid!: AppDataGridComponent;
  @Output() onCreditChange: EventEmitter<any> = new EventEmitter();
  loggedinUser$: Observable<any>;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  @Input() actionName: any;
  status: string;
  formJson: any;
  showSubmit: boolean = false;
  columns = [];
  refreshField: boolean = false;
  showDrawer: boolean = false;
  refreshGrid: boolean = false;
  showRemoveButton: boolean = false;
  listOfMappingParams = [];
  page = 'Work Quality Master';
  file: any;
  workQualityData = [];
  uploadForm: FormGroup;
  selectedFile: any;
  public data = [];
  public results = [];
  fromJsonInputs: any;
  rowsIndex: any;
  noOfRecords = 10;
  pageSize = 20;
  pageNumber = 0;
  count = 0;
  public listOfWorkQualityData: any = {};
  public selectedRow: any;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private storage: Storage,
    private modalController: ModalController
  ) {
    this.columns = [
      { name: 'Category', prop: 'category' },
      { name: 'Parent Entity Type', prop: 'parentEntityType' },
      { name: 'Parent Entity', prop: 'parentEntity' },
      { name: 'Remark', prop: 'remark' },
      { name: 'Parent Remark', prop: 'parentRemark' },
      { name: 'Remark Description', prop: 'remarkDescription' },
      { name: 'Ord', prop: 'orderNumber' },
    ];
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: [''],
    });
    this.getWorkQualityData();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = <File>event.target.files[0];
      //this.uploadForm.get('profile').setValue(file);
      this.getFile();
    }
  }

  getFile() {
    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile);
    console.log(formData);
    this.httpService.getWorkquality(formData).subscribe(
      (res) => {
        console.log(res);
        if (res.data) {
          this.workQualityData = res.data;
        }
      },
      (err) => {
        this.configService.showToast('error', err.message);
      }
    );
  }

  clearData() {
    this.refreshGrid = true;
    this.workQualityData = [];
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  async getWorkQualityData() {
    this.listOfWorkQualityData = new CustomStore({
      key: 'id',
      load: async (loadOptions: any) => {
        console.log('loadOptions ', loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let obj = {
            limit: this.noOfRecords,
            page: this.pageNumber,
          };
          this.httpService.fetchworkqualityData(obj).subscribe(
            async (res) => {
              let data = res.data;
              console.log(res);
              console.log(data.data, data.totalCount);
              this.listOfWorkQualityData = data.data;
              return {
                data: Query(data.data).toArray(),
                totalCount: data.totalCount,
                groupCount: data.totalCount,
              };
            },
            (err) => {
              this.configService.showToast('error', err.message);
            }
          );
        } else {
          console.log(this.count);
          return {
            data: [],
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }
}
