import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { select, Store } from "@ngrx/store";
import { DxDataGridComponent } from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import themes from "devextreme/ui/themes";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthActions } from "src/app/authStore/auth.action-type";
import { authUser } from "src/app/authStore/auth.selector";
import { HomeActions } from "src/app/home/state/home.action-type";
import { IncomingStones } from "src/app/models/incoming-stone.models";
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { ScannerinputComponent } from "../../scannerinput/scannerinput.component";
import { TrybemodalComponent } from "../../trybemodal/trybemodal.component";
import { VoucherwisepacketComponent } from "../../voucherwisepacket/voucherwisepacket.component";
import { MultipleBarcodeScannerComponent } from "../../multiple-barcode-scanner/multiple-barcode-scanner.component";
import { getIncomingPacketsData } from "src/app/home/state/home.selector";

@Component({
  selector: "app-incoming",
  templateUrl: "./incoming.component.html",
  styleUrls: ["./incoming.component.scss"],
})
export class IncomingComponent implements OnInit {
  incomingStones$: Observable<IncomingStones>;
  totalIncomingStones$: Observable<number>;
  loggedinUser$: Observable<any>;
  allMode: string;
  private unsubscribe$: Subject<any> = new Subject<any>();
  checkBoxesMode: string;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid!: DxDataGridComponent;
  dataSource: any = [];
  modifiedDataSource: any;
  columns;
  selectedRows: any = [];
  totalIncomingStones: number = 0;
  selectedRowsLen: number = 0;
  sendData: any = {};
  page = "Incoming";
  isDisabled: boolean = false;
  pageSize = 10;
  mode: string = "single";
  pageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  public incomingGridPageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  masterData;
  pageNumber: number = 0;
  barcodeInputs: any;
  voucherid: any;
  rowsIndex: any;
  openVoucher: boolean;
  toshowVoucherModal: any;
  scanned: boolean;
  refreshGrid: boolean = false;
  user: any;
  packet: [];
  roles: any = "";
  isManager: any;
  isUserPermission: boolean = false;
  currUserFullName: string = "";
  igSuscription: Subscription;
  igSuscription2: Subscription;
  igSuscription3: Subscription;
  barcodeInputList: string[] = [];
  payload: { packetId: any };
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private alertCtrl: AlertController,
    public configService: ConfigService,
    private storage: Storage,
    private loaderService: LoaderService,
    private modalController: ModalController,
    private navCtrl: NavController
  ) {
    this.allMode = "allPages";
  }

  ngOnInit() {
    this.store.dispatch(AuthActions.getIfManager());
    this.fetchGridData();
    // this.incomingStones$ = this.store.pipe(select(getIncomingPacketsData));
    // this.igSuscription = this.store.pipe(select(getIncomingPacketsData)).subscribe(data => {
    //   if (data) {
    //     this.masterData = data;
    //     this.dataSource = data;
    //   }
    // });
    // this.igSuscription2 = this.store.pipe(select(getIncomingPacketsLen)).subscribe(data => {
    //   if (data) {
    //     this.totalIncomingStones = data;
    // if (!!this.dataSource && !!this.totalIncomingStones) {
    // this.getIncomingGridDataWithPagination();
    // }
    //   }
    // });
    // this.getIncomingGridDataWithPagination();//fetch incoming grid data with pagination
    //     this.getIncomingGridData(function (state) {
    //   if (state) {
    //     console.log('incoming data loaded successfully');
    //     ob.getIncomingGridDataWithPagination();
    //   } else {
    //     console.log('Something went wrong to load data');
    //   }
    // });
    // this.totalIncomingStones$ = this.store.pipe(select(getIncomingPacketsLen));
    this.store
      .pipe(select(authUser))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userData => {
        if (userData) {
          console.log("Current Collged In User");
          this.isManager = userData.isManager;
          console.log(this.isManager);
        }
      });
    this.columns = [
      // { name: "voucherId", prop: "voucherId" },
      { name: "Voucher No", prop: "voucherLabel" },
      { name: "Total Packets", prop: "totalCount" },
      { name: "From Dept", prop: "fromDept" },
      { name: "From User", prop: "fromUser" },
      // { name: "To User", prop: "toUser" },
      { name: "voucherCreatedDate", prop: "voucherCreatedDate" },
      // { name: "Location", prop: "location" }
    ];
    // let grid = this.dataGrid.instance;
    // new ResizeObserver((entries: any) => {
    //   grid.updateDimensions();
    // }).observe(grid.element());
    this.loggedinUser$ = this.store.pipe(select(authUser));
    console.log(themes.current());
    // themes.current('generic.dark');
    // this.store.pipe(state => state["auth"].stoneName);
    this.igSuscription3 = this.store.pipe(select(authUser)).subscribe(userData => {
      console.log("......authUser...");
      console.log(userData);
      if (userData != null) {
        this.currUserFullName = userData.fullName;
        console.log(userData);
        this.user = userData;
        if (this.user.roles) {
          this.roles = this.user.roles.join();
        }
        if (this.user.listOfRoles) {
          this.roles = this.user.listOfRoles
            .map(d => {
              return d.roleName;
            })
            .join();
        }
        if (this.roles.includes("Manager") || this.roles.includes("Operator")) {
          this.isUserPermission = true;
        } else {
          this.isUserPermission = false;
        }
        // this.mode = this.isUserPermission ? 'single' : 'multiple';
      }
    });
  }

  async barcodeDetection(barcode) {
    this.barcodeInputs = "";
    this.barcodeInputs = barcode;
    //let dataArray: [] = this.barcodeInputs.split(',');

    if (this.barcodeInputs) {
      let payload = {
        packetId: this.barcodeInputs,
      };
      let response: any;
      // await this.httpService.showLoader();
      
      // response = await this.httpService.getIncomingStones(payload);
      this.dataSource = [];
      // if (response.statusCode == 200) {
      //   this.dataSource = response.data.packetData;
      //   if (this.dataSource.length == 0) {
      //     this.showModal(false, 'Invalid barcode/Packet Id, no records found!', {});
      //     return;
      //   }
      // } else {
      //   this.dataSource = this.dataSource;
      //   console.log(response);
      // }
      this.scanned = true;
      setTimeout(() => {
        this.scanned = false;
        
      }, 1000);
      // await this.httpService.dismissLoader();
      
      // this.rowsIndex = [];
      // for (let i = 0; i < dataArray.length; i++) {
      //   let el: any = dataArray[i];
      //   let index: number = this.dataSource.findIndex(item => item.packetId == el.trim());
      //   this.rowsIndex.push(index);
      //   if (index > -1) {
      //     this.selectedRows.push(this.dataSource[index])
      //   }
      // }
      // this.scanned = true
      // setTimeout(() => {
      //   this.scanned = false;
      // }, 1000)
    }
  }
  clearBarcode() {
    this.scannerComponet.onResetForm();
    this.barcodeInputs = "";
    this.selectedRows = [];
    this.rowsIndex = [];
    this.refreshGrid = true;
    this.dataSource = this.masterData;
    // this.isStoneSelected = false;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  customizeColumns(columns) {
    columns[0].width = 20;
  }

  fetchGridData() {
    this.getIncomingGridDataWithPagination();
    this.httpService.currentIncomingGridData$.subscribe(data => {
      if (data) {
        this.dataSource = data.vouchersList;
        this.totalIncomingStones = data.totalCount;
      }
    });
    this.isDisabled = true;
    this.igSuscription = this.store.pipe(select(getIncomingPacketsData)).subscribe(data => {
      if (data) {
        this.masterData = data;
        this.dataSource = data;
      }
    });
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  backIt() {
    console.warn("Back button");
    // this.loc.back();
    //history.back();
    this.navCtrl.pop();
  }
  async logout() {
    this.store.dispatch({ type: "Logout" });
    await this.storage.clear();
    this.httpService.isAuthenticated.next(false);
    this.router.navigateByUrl("/login");
  }

  onSelectionChanged(event) {
    console.log("onSelectionChanged");
    console.log(event);
    this.store.pipe(select(authUser)).subscribe(userData => {
      console.log("......authUser...");
      console.log(userData);
      if (userData != null) {
        this.user = userData;
        if (this.user.roles) {
          this.roles = this.user.roles.join();
        }
        if (this.user.listOfRoles) {
          this.roles = this.user.listOfRoles
            .map(d => {
              return d.roleName;
            })
            .join();
        }
        if (this.roles.includes("Manager") || this.roles.includes("Operator") || (!!event && !!event.row && !!event.row.fromActionName && event.row.fromActionName == "Return")) {
          this.isUserPermission = true;
          console.log(this.isUserPermission);
        } else {
          this.isUserPermission = false;
          console.log(this.isUserPermission);
        }
        // this.mode = this.isUserPermission ? 'single' : 'multiple';
      }
    });
    this.selectedRows = event.selectedRowsData;
    this.selectedRowsLen = event.selectedRowsData.length;

    if (this.selectedRows && this.selectedRows.length > 0) {
      this.openBarcodeScannerModal();
    }
    // if (this.selectedRows.length > 0) {
    //   this.store.dispatch(
    //     saveCurrentRowData({ rowData: event.selectedRowsData })
    //   );

    //   console.log(event)

    //   this.toshowVoucherModal = event.selectedRowKeys.length;
    //   if (this.toshowVoucherModal > 1) {
    //     this.openVoucher = false
    //   }
    //   else {
    //     this.openVoucher = true
    //   }
    //   if (this.selectedRows.length > 0) {
    //     event.row = event.selectedRowsData[0];
    //     this.voucherid = event.row.voucherId
    //     console.log(this.voucherid);
    //   }
    //    this.openVoucherModal(this.openVoucher, event.selectedRowsData);
    // }
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalController.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      this.receiveOrRejectPacket("Receive");
      // this.getIncomingGridDataWithPagination();
    }
  }

  async receiveOrRejectPacket(action) {
    console.log(this.selectedRows);
    let parameters: any = {};
    let info = {
      rowData: this.selectedRows,
      status: "WorkQualityMaster",
    };
    let packetUserFullName = !!this.selectedRows[0] ? this.selectedRows[0].fromUser : "";
    let packetFromAction = !!this.selectedRows[0] ? this.selectedRows[0].lastAction : "";

    console.log(this.selectedRows);
    let vouIds = this.selectedRows.map((x: any) => x.voucherId);
    let voucherNeedToReceive = {
      voucherList: vouIds,
    };
    if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
      voucherNeedToReceive["packetList"] = this.barcodeInputList;
    }
    console.log(voucherNeedToReceive);
    console.log(action);
    parameters["payload"] = voucherNeedToReceive;
    parameters["incomingGridPageInfo"] = this.incomingGridPageInfo;
    console.log(this.isManager);
    if (
      this.isManager == true &&
      !!packetFromAction &&
      packetFromAction != "Return" &&
      !!packetUserFullName &&
      this.currUserFullName != packetUserFullName &&
      this.user.departmentName != "Botswana A1 Department"
    ) {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: "Work Quality",
          data: info,
          transactionType: action,
        },
        cssClass: "workQualityMaster",
      });
      modal.onDidDismiss().then(d => {
        this.handleModalDismiss(d);
      });
      return await modal.present();
    } else {
      if (action == "Receive") {
        console.log("receive btn is coming here");
        // this.store.dispatch(masterDataActions.receivePacketVoucherwise({ param: parameters }));
        this.receivePacketFromUser(parameters);
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: "Work Quality",
            data: info,
            transactionType: action,
          },
          cssClass: "workQualityMaster",
        });
        modal.onDidDismiss().then(d => {
          this.handleModalDismiss(d);
        });
        return await modal.present();
      }
    }
  }

  customDateTime(rd) {
    if (!!rd.inDate) {
      console.log("InDate");
      // return moment.utc(rd.inDate).local().format('DD-MMM-YYYY hh:mm a');
      return new Date(rd.inDate).toLocaleDateString();
    }
    if (!!rd.outDate) {
      console.log("OutDate");
      // return moment.utc(rd.outDate).local().format('DD-MMM-YYYY hh:mm a');
      return new Date(rd.outDate).toLocaleDateString();
    }
  }

  getShortTitle(str) {
    var newStr = str.split(" ").reduce((result, currentWord) => result + currentWord.charAt(0).toUpperCase(), "");
    return newStr;
  }

  async showModal(state, message, data) {
    if (state) {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
          data: data,
        },
        cssClass: "trybe_modal_1",
      });
      modal.onDidDismiss().then(dataReturned => {
        if (dataReturned !== null) {
          console.log(dataReturned);
        }
      });
      return await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      return await modal.present();
    }
  }
  async handleModalDismiss(d) {
    console.log("handleModalDismiss Incoming Page");
    if (!!d && !!d.data && d.data.status == "WorkQualitySubmitted") {
      let vouIds = this.selectedRows.map((x: any) => x.voucherId);
      // this.sendData['data'] = this.selectedRows;
      // this.sendData['packetIds'] = this.selectedRows.map(d => d.packetId);
      let workQuality = d.data.remark;
      let damageRemark = d.data.damageName;
      let parameters: any = {};
      let payload = {
        voucherList: vouIds,
      };
      if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
        payload["packetList"] = this.barcodeInputList;
      }
      if (!!workQuality && !!workQuality.id) {
        payload["workQualityMasterId"] = workQuality.id;
      }
      if (!!damageRemark && !!damageRemark.id) {
        payload["damageMasterId"] = damageRemark.id;
      }
      console.log(d.data.transactionType);
      console.log(payload);
      parameters["payload"] = payload;
      parameters["incomingGridPageInfo"] = this.incomingGridPageInfo;
      if (d.data.transactionType == "Receive") {
        // this.store.dispatch(masterDataActions.receivePacketVoucherwise({ param: parameters }));
        this.receivePacketFromUser(parameters);
      } else if (d.data.transactionType == "Reject") {
        //currently hide to change this store code
        // this.store.dispatch(masterDataActions.rejectPacket({ payload: payload, modalData: this.sendData }));
        await this.rejectPackeToUser(parameters);
      } else {
        console.log("none");
      }
      // return
    }
  }
  onPaginationChange(evt) {
    let nagName = evt.fullName;
    console.log(evt);
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.incomingGridPageInfo["limit"] = size;
        this.getIncomingGridDataWithPagination();
        console.log(this.pageInfo);
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.incomingGridPageInfo["page"] = pageIndex;
        this.getIncomingGridDataWithPagination();
        break;
    }
  }
  // calcTotalPageSize(pageSize, totalCount) {
  //   return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  // };
  isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== "";
  }

  async getIncomingGridDataWithPagination() {
    console.log("getIncomingGridDataWithPagination");
    let payload = null;
    if (!!this.barcodeInputs) {
      this.payload = { packetId: this.barcodeInputs.trim() }; //toFiltter grid Data by barcodeInputs
      console.log(payload);
    } else if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
      console.log(this.barcodeInputList);
      this.payload = { packetId: this.barcodeInputList };
    }
    this.modifiedDataSource = new CustomStore({
      key: "voucherId",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        const filterObject = {};
        if (!!loadOptions.filter) {
          var params = loadOptions.filter;
          console.log(params);
          for (let i = 0; i < params.length; i++) {
            if (typeof params[0] == "string") {
              const key = params[0];
              const value = params[2];
              if (key && value) {
                filterObject[key] = value;
              }
            } else if (Array.isArray(params[i])) {
              const key = params[i][0];
              const value = params[i][2];
              if (key && value) {
                filterObject[key] = value;
              }
            }
            console.log(filterObject);
          }
        }
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo["limit"];
          }
          // else {
          //   this.incomingGridPageInfo['limit'] = loadOptions.take;
          // }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.incomingGridPageInfo["page"] = this.pageNumber;
          if (!!Object.keys(filterObject).length) {
            payload = filterObject;
          } else if (!!this.barcodeInputList) {
            payload = this.payload;
          } else {
            payload = null;
          }
          console.log(payload);
          let response: any;
          response = await this.httpService.fetchIncomingGridData(this.incomingGridPageInfo, payload);
          console.log(this.incomingGridPageInfo);
          console.log("%cEngineeringWork................", "color:green;border:2px solid green;");
          console.log(response, this.dataSource, this.totalIncomingStones);
          if (response.statusCode === 200) {
            this.httpService.updateIncomingGridData(response.data);
          } else {
            console.error(response);
            this.httpService.getErrorAndDisplayIt(response);
          }
          return {
            data: Query(this.dataSource).toArray(),
            totalCount: this.totalIncomingStones,
            groupCount: this.totalIncomingStones,
          };
        } else {
          return {
            data: this.dataSource,
            totalCount: this.totalIncomingStones,
            groupCount: this.totalIncomingStones,
          };
        }
      },
    });
  }

  receivePacketFromUser(param) {
    // await this.httpService.showLoader();
    
    this.igSuscription = this.httpService.receiveVoucherwise(param.payload).subscribe((d: any) => {
      console.log("%cPacket Receival......", "color:orange;font-size:18px;");
      console.log(d);
      if (d.statusCode == 200) {
        let limit = { limit: 50, page: 0 };
        console.log("Success Packet Received");
        this.getIncomingGridDataWithPagination();
        // this.store.dispatch(HomeActions.getReceivedPackets({ limit: limit }));
        this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
        this.store.dispatch(HomeActions.getPacketCount());
        // this.httpService.dismissLoader();
        
        // this.showModal(true, d.message, {});
        this.configService.showToast("success", d.message);
        if (!!this.igSuscription) {
          this.igSuscription.unsubscribe();
        }
      } else {
        console.log(d);
        console.error("Error in Packet Received");
        // this.httpService.dismissLoader();
        
        // this.showModal(false, d.message, {});
        this.configService.showToast("error", d.message);
      }
    });
  }
  // HomeActions.getIncomingPackets(data.param.incomingGridPageInfo),
  //         HomeActions.getReceivedPackets(withMeGridPageInfo),
  //         HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
  //         HomeActions.getPacketCount(),
  async rejectPackeToUser(param) {
    // await this.httpService.showLoader();
    
    this.igSuscription2 = this.httpService.reject(param.payload).subscribe(async (d: any) => {
      console.log("%cPacket Rejection......", "color:orange;font-size:18px;");
      console.log(d);
      if (d.statusCode == 200) {
        let limit = { limit: 2000, page: 1 };
        console.log("Success Packet Reject");
        await this.getIncomingGridDataWithPagination();
        await this.store.dispatch(HomeActions.getReceivedPackets(limit));
        // await this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
        await this.store.dispatch(HomeActions.getPacketCount());
        // await this.httpService.dismissLoader();
        
        // await this.showModal(true, d.message, {});
        this.configService.showToast("success", d.message);
        if (!!this.igSuscription2) {
          this.igSuscription2.unsubscribe();
        }
      } else {
        console.error("Error in Packet Reject");
        // await this.httpService.dismissLoader();
        
        // await this.showModal(false, d.message, {});
        this.configService.showToast("error", d.message);
      }
    });
  }
  async openVoucherModal(openVoucher, d: any) {
    console.log(openVoucher);
    if (openVoucher) {
      const modal = await this.modalController.create({
        component: VoucherwisepacketComponent,
        cssClass: "extraLargePage-for-voucher",
        componentProps: {
          message: "Voucherid",
          d,
          incomingGridPageInfo: this.incomingGridPageInfo,
        },
      });
      console.log("calling ");
      modal.onDidDismiss().then((d: any) => this.handleModalDismissforVoucher(d));
      return await modal.present();
    } else {
      console.log("not opening");
    }
  }
  handleModalDismissforVoucher(d: any): any {
    console.log("closingvoucher modal");
    console.log(d);
    this.getIncomingGridDataWithPagination();
    // if (!d.data) {
    //   console.log('closed Modal');
    //   this.refreshGrid = true;
    //   setTimeout(() => {
    //     this.refreshGrid = false;
    //   }, 500)
    // }
  }

  onRowDoubleClick(event) {
    console.log("aonRowDoubleClick");
    console.log(event);
    // console.log(this.selectedRows);
    // this.selectedRows = [event.data];//Array of Object convers
    // this.selectedRowsLen = this.selectedRows.length;
    // if (!!this.selectedRows && this.selectedRows.length > 0) {
    //   this.voucherid = this.selectedRows[0].voucherId
    //   console.log(this.voucherid);
    //   this.store.dispatch(
    //     saveCurrentRowData({ rowData: this.selectedRows })
    //   );
    //   this.openVoucher = true;
    //   this.openVoucherModal(this.openVoucher, event.data);
    // } else {
    //   this.openVoucher = false
    // }
  }
  ngOnDestroy() {
    if (!!this.igSuscription) {
      this.igSuscription.unsubscribe();
    }
    if (!!this.igSuscription2) {
      this.igSuscription2.unsubscribe();
    }
    if (!!this.igSuscription3) {
      this.igSuscription3.unsubscribe();
    }
  }
}
