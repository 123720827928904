import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderComponentModule } from '../components/header/header.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { SingleMultiDropdownModule } from '../components/single-multi-dropdown/single-multi-dropdown.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { DxDataGridModule, DxPopoverModule } from 'devextreme-angular';
import { TallyStockComponent } from './tally-stock.component';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';

@NgModule({
  declarations: [TallyStockComponent],
  entryComponents: [],
  exports: [TallyStockComponent],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DxDataGridModule,
    DataGridComponentModule,
    WidthAlertModule,
    ScannerinputModule,
    IonicModule,
    HeaderComponentModule,
    SingleMultiDropdownModule,
  ],
})
export class TallyStockModule {}
