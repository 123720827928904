import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { getProgramData } from '../trybesigner/state/signer.selector';

@Component({
  selector: 'app-signermodal',
  templateUrl: './signermodal.component.html',
  styleUrls: ['./signermodal.component.scss'],
})
export class SignermodalComponent implements OnInit {

  selectedProgram: any = [];
  programs: any = [
    // { id: 1, progName: 'HOLD-RAM-IM-0003', selected: false },
    // { id: 2, progName: 'HOLD-RAM-IM-0002', selected: false },
  ];
  message: string;
  data: any = {};
  status: string;
  currentRow: any = {};

  remarks: any = [
    { id: 1, remark: 'Neat & Clean', selected: false },
    { id: 2, remark: 'Solid', selected: false },
    { id: 3, remark: 'Best plan', selected: false },
  ];
  selectedRemark: any = [];
  isNewRemark: boolean = false;
  inputRemark: string = '';
  constructor(
    private modalCtl: ModalController,
    public navParam: NavParams,
    private store: Store<AppState>
  ) { }

  async ngOnInit() {
    this.message = this.navParam.get('message');
    this.data = this.navParam.get('data');
    this.status = this.navParam.get('status');
    this.currentRow = this.navParam.get('plan');
    this.store.pipe(select(getProgramData)).subscribe(program => {
      if (program) {
        console.log('......program.........');
        console.log(program);
        if (program.length > 0) {
          this.programs = program;
        }
      }
    });
    console.log(this.message);
    console.log(this.data);
    console.log(this.status);
  }
  closeModal(o: any) {
    if (!!o) {
      this.modalCtl.dismiss(o);
    } else {
      this.modalCtl.dismiss();
    }
  }
  onProgramClick(program) {
    console.log('onProgramClick');
    console.log(program);
    console.log(this.data);

    this.programs = this.programs.map((p: any) => {
      if (p.id == program.id) {
        p.selected = true;
      } else {
        p.selected = false;
      }
      return p;
    });
    this.selectedProgram = this.programs.filter(program => program.selected);
    console.log(this.selectedProgram);
  }
  onRemarkSelection(note, remark) {
    console.log('onRemarkSelection');
    console.log(note);
    console.log(remark);
    if (note == 'new') {
      this.isNewRemark = true;
      this.remarks = this.remarks;
      this.selectedRemark = [];
    } else {
      this.remarks = this.remarks.map((d: any) => {
        if (d.id == remark.id) {
          d.selected = true;
        } else {
          d.selected = false;
        }
        return d;
      });
      this.selectedRemark = this.remarks.filter(remark => remark.selected);
      console.log(this.selectedRemark);
    }

  }
  onSave(model, text) {
    console.log('onSave');
    if (text === 'showProgram') {
      console.warn(this.selectedProgram);
      let program = this.selectedProgram[0];
      console.log(program);
      console.log(this.currentRow);

      let obj = {
        program: program,
        for: 'saveProgram'
      }
      this.closeModal(obj);
    } else {
      console.warn(this.selectedRemark);
      let remark = this.selectedRemark[0];
      console.log(remark);
      console.log(this.currentRow);
      let obj = {
        remark: remark,
        for: 'saveRemark'
      }
      this.closeModal(obj);
    }

  }

  onRemarkSubmit(evt, message) {
    let obj = {
      packetPlanID: this.data.id,
      plannedRemark: this.inputRemark,
      for: 'savePlannedRemark'
    }
    setTimeout(() => {
      this.closeModal(obj);
    }, 500);
  }
  onPlannedRemarkChange() {
    console.log('getPlannedRemakrs');
    console.log(this.inputRemark);

  }
}
