import { packetInwordReducer } from "./packet-inward.reducer";

export interface packetInward {
    PACKET_INWARD_LIST: Int_packetInwordList,
}

export const packetInwardMasterReducer = {
    PACKET_INWARD_LIST: packetInwordReducer
}

export interface Int_packetInwordList {
    packetInword: any[],
    isLoaded: boolean,
}