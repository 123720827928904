import { SignerPlans } from "./signer.data";

// debugger
export interface SignerState {
  allPacketPlans: any;
  packetPlans: any;
  selectPlans: any;
  manualPlans:any;
  planForSpectrum:any;
  filteredPlans: any;
  packetPrograms: any;
  packetPlanData: any;
  capFile: string;
  // signerBestPlans: SignerPlans[];
  submittedPlansByUsers: SignerPlans[];
  remarkForPacketPlanDrawer:any;
  remarkForPacketPlanFinalTab:any;
  cuts: any[];
  shapes: any[];
  clarities: any[];
  orderForm:any[];
  ecsvValue: any[];
  reconfirmBidData:any;
  colors: any[];
  stoneRemarks: any[];
  reviewStoneDept : {};
  checkerIdForSpectrum:any[];
  reviewByCheckerActionId:any[];
  reviewStoneId:{};
  fileDetails : any[];
  pdFile: any,
  otherFile: any,
}

export const initialSignerState: SignerState = {
  allPacketPlans: [],
  packetPlans: [],
  manualPlans:[],
  planForSpectrum:[],
  selectPlans: [],
  filteredPlans: [],
  remarkForPacketPlanDrawer:[],
  remarkForPacketPlanFinalTab:[],
  packetPrograms: [],
  packetPlanData: [],
  capFile: "",
  ecsvValue: [],
  reconfirmBidData:{},
  // signerBestPlans: [],
  submittedPlansByUsers: [],
  cuts: [],
  orderForm:[],
  shapes: [],
  clarities: [],
  checkerIdForSpectrum:[],
  reviewByCheckerActionId:[],
  colors: [],
  stoneRemarks: [],
  reviewStoneDept : {},
  reviewStoneId : {},
  fileDetails : [],
  pdFile: '',
  otherFile: '',
};
