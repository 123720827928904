// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// let fromLocation = 'trybe';
let fromLocation = 'surat';
if (
  window &&
  "location" in window &&
  "protocol" in window.location &&
  "pathname" in window.location &&
  "host" in window.location
) {
  fromLocation = window.location.hostname.split(".")[0];
  if(fromLocation == "surat" || fromLocation == "surat-dev") {
    fromLocation = 'Facet';
  }
}
if (fromLocation == 'localhost' || fromLocation == 'trybe-dev-debug') {
  // fromLocation = 'trybe'
  // fromLocation = 'surat'
  fromLocation = 'kps'

  // fromLocation = 'botswana-dev'
}
export const environment = {
  appName : 'trybe',
  production: false,
  // mode: 'Production',
  mode: 'Dev',
  latticeUrl: "https://lattice-dev.atishae.com",
  UMBaseUrl: "https://lattice-dev.atishae.com/",
  baseUrl: "https://kps-dev.trybe.mobioffice.io/",
  latticeUMUrl:"https://user-management.trybe-app.com/",
  centralFileUrl: "https://kpcentral.mobioffice.io/",
  PricingUrl:"https://lattice-app.com/master-service/api/price_policy/calculatePriceES?startChainId=17&basePdChainId=18&mixPriceChainId=19&type=MANUFACTURING",
  StockReportUrl: "https://lookerstudio.google.com/embed/reporting/5beaece1-7341-40e8-8472-4105da5efd96/page/4jPsD",
  PrevirtualPlanningStatusReport: "https://lookerstudio.google.com/embed/reporting/c6a4341b-6d0a-42fd-9a45-e7d63b699c16/page/WdWsD ",
  LotReport: "https://lookerstudio.google.com/embed/u/0/reporting/954a6549-fc7a-4832-b5c4-3690e0254919/page/xbbtD",
  PacketPlanReportUrl: "",
  AllActionParameterReport: "https://lookerstudio.google.com/embed/reporting/5496de53-f54e-493e-a1b1-f14f3e4cc49a/page/Z0osD",
  YehudaReport : 'https://lookerstudio.google.com/embed//reporting/328bb975-748f-4460-97f0-ccb97fb0055f/page/p_wynw4ergfd',
  ColorReport:'https://lookerstudio.google.com/embed//reporting/84f379c6-9957-4dd2-977d-5d148a41aae1/page/p_wynw4ergfd',
  signerCheckerReport:'https://lookerstudio.google.com/embed/reporting/8239efa8-d09d-4e51-9490-3bf085ebe747/page/6zvsD',
  currDeptParamReport:'https://lookerstudio.google.com/embed/reporting/beae0c13-cec3-45b5-933a-4146fd0bc673/page/rhgsD',
  mixpanel_token: "00661bb70a484b8068c0794a0bc215b1",
  // centralFileUrl: "http://123.201.81.70:3000/",
  fromLocation: fromLocation,

   firebaseConfig:{
    apiKey: "AIzaSyBp9CeHpjKvInGC7KxJO3CQ9ZpBG9zN67o",
    authDomain: "trybechat-caeb0.firebaseapp.com",
    databaseURL: "https://trybechat-caeb0-default-rtdb.firebaseio.com",
    projectId: "trybechat-caeb0",
    storageBucket: "trybechat-caeb0.appspot.com",
    messagingSenderId: "278249266007",
    appId: "1:278249266007:web:33a30868130792df1a3c12",
    measurementId: "G-6PCQYNG72C"
  },
  
  project: "kg",
  companyName:"Facet",
  logo: 'assets/icon/trybe_logo_.png',
  favicon: 'assets/kps/kps_logo_png.png',
  printlogo:'assets/kps/kps_logo_png.png'

}

export enum StaticInfo {
  'company' = 'Param Diamond',
  'address' = 'k.P Sanghvi, Nr. Umiyamata Temple, A.K. Road, Surat - 395008',
  'stoneType' = 'Single',
  'processRemark' = 'INCLUSION PLANNIG',
  'subProcessRemark' = 'G-MB',
  'senderNotes' = 'Urgent LotNo',
}

export const SERVER_URL = 'https://ec2-13-233-48-108.ap-south-1.compute.amazonaws.com:3000/';


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
