import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { BackButtonDisableModule } from "angular-disable-browser-back-button";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import * as auth from "./authStore";
import { AuthEffects } from "./authStore/auth.effect";
import { AutoReceiveEffects } from "./components/actions/auto-receive/state/auto.receive.effects";
import { ComponentsModule } from "./components/components.module";
import { CheckerEffects } from "./components/trybe-checker/state/checker.effects";
import { HomeEffects } from "./home/state/home.effect";
import { MasterDataEffects } from "./masterDataStore/masterData.effect";
import { metaReducers, reducers } from "./reducers";
import { HttpService } from "./services/http/http.service";
import { NgMentionsModule } from "ng-mentions";
import { DxDataGridModule } from "devextreme-angular";
import { MixpanelInterceptor } from "./services/mixpanel/mixpanel.interceptor";
import { StorageService } from "./storage.service";
import { LoadingModule } from "./loading/loading.module";
import { FooterModule } from "./footer/footer.module";
import { WidthAlertModule } from "./width-alert/width-alert.module";
import { DynamicdrawerComponent } from "./components/dynamicdrawer/dynamicdrawer.component";
import { MainInterceptor } from "./middleware/main.interceptor";

// import { PacketplannerModule } from './packetplanner/packetplanner.module';

@NgModule({
  declarations: [AppComponent, DynamicdrawerComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    IonicStorageModule.forRoot(),
    IonicStorageModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    DxDataGridModule,
    ReactiveFormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    LoadingModule,
    FooterModule,
    WidthAlertModule,
    // PacketplannerModule,
    NgSelectModule,
    ComponentsModule,
    NgMentionsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([]),
    StoreModule.forFeature("auth", auth.authReducer),
    EffectsModule.forFeature([AuthEffects, MasterDataEffects, HomeEffects, AutoReceiveEffects, CheckerEffects]),
    BackButtonDisableModule.forRoot(),
    BrowserAnimationsModule,
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StorageService,
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
