import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTotalUsingKey',
  pure: false
})
export class getTotalUsingKeyPipe implements PipeTransform {

    transform(array: any, key1:string = '', key2:string = ''): any {
      let total = 0;
      if(key1){
        array.map((dt:any)=>{
          if(key2){
            if(dt?.[key1]?.[key2])
            total+=Number(dt[key1][key2]);
          } 
          else
          if(dt?.[key1])
          total+=Number(dt[key1]);
        });
        return total.toFixed(2);
      } else 
      return 0;
    }
}
