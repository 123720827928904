import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DataGridComponentModule } from '../data-grid/data-grid.module';
import { HeaderComponentModule } from '../header/header.module';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgMentionsModule } from 'ng-mentions';
import { MentionModule } from 'angular-mentions';
import { ChatAlertModelComponent } from './chat-alert-model.component';




@NgModule({
    declarations: [ChatAlertModelComponent],
    exports: [ChatAlertModelComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        DataGridComponentModule,
        HeaderComponentModule,
    ]
})
export class ChatAlertModule { }
