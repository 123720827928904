import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grade'
})
export class GradePipe implements PipeTransform {

  transform(items: any[], search: any): any {
    if(search != null)
      return items.filter(i => i.fullName?.toString().includes(search))
    else
      return items
  }

}
