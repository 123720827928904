import { createReducer, on } from "@ngrx/store";
import { myVirtualTaskActions } from "./my-virtual-task.actions.type";
import { initialMyVirtualTaskState } from "./my-virtual-task.state";

export const myVirtualTaskReducer = createReducer(
    initialMyVirtualTaskState,
    on(myVirtualTaskActions.saveMyVirtualTasks, (state, action) => {
        return {
            ...state,
            myVirtualTasks: action.myVirtualtask
        }
    }),

    on(myVirtualTaskActions.saveFilterMyVirtualTasks, (state, action) => {
        // let myPendingTask=action.myFilterVirtualtask
        let myModifiedTask={...state.myVirtualTasks}
        if(action.api.indexOf("pending")>-1){
            myModifiedTask.pending=action.myFilterVirtualtask.pending;
            myModifiedTask.pendingCount=action.myFilterVirtualtask.pendingCount;

        }
        if(action.api.indexOf("completed")>-1){
            myModifiedTask.completed=action.myFilterVirtualtask.completed;
            myModifiedTask.completedCount=action.myFilterVirtualtask.completedCount;
        }
        if(action.api.indexOf("assignedByMe")>-1){
            myModifiedTask.assigned=action.myFilterVirtualtask.assigned;
            myModifiedTask.assignedCount=action.myFilterVirtualtask.assignedCount;
        }
        // myModifiedTask.completed=action.myFilterVirtualtask.completed;
        // myModifiedTask.assigned=action.myFilterVirtualtask.assigned;
        console.log(state.myVirtualTasks,myModifiedTask);
        
        return {
            ...state,
            // myFilterVirtualTasks: action.myFilterVirtualtask
            myVirtualTasks: myModifiedTask
        }
    }),
)