import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';

export interface pyramidProps{
  data:any[],
  nodeId:string,
  parentNodeId:string
}
@Component({
  selector: 'app-d3-pyramid-chart',
  templateUrl: './d3-pyramid-chart.component.html',
  styleUrls: ['./d3-pyramid-chart.component.scss'],
})
export class D3PyramidChartComponent implements OnInit {
  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Input() pyramidProps:pyramidProps={
    data:[],
    nodeId:'packetLabel',
    parentNodeId:'originalLabel'
  }
  chart;
  constructor() {}

  ngOnInit() {
    console.log(this.pyramidProps);
  }

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    setTimeout(() => {
      this.updateChart();
    }, 300);
  }

  ngOnChanges() {
    this.updateChart();
  }
  updateChart() {
    if (!this.pyramidProps.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.chart
      .nodeId((dataItem) => dataItem[this.pyramidProps.nodeId])
      .parentNodeId((dataItem) => dataItem[this.pyramidProps.parentNodeId])
      .nodeHeight((node) =>  144 + (node.depth * 5)
        )
      .nodeWidth((node) => 144 + (node.depth * 5))
      .childrenMargin((d) => 50)
      .compactMarginBetween((d) => 70)
      .compactMarginPair((d) => 200)
      .neighbourMargin((a, b) => 200)
      .siblingsMargin((node) => 200)
      .linkUpdate(function (d, i, arr) {
        d3.select(this)
          .attr('stroke', '#1CA8DD')
          .attr('stroke-width', 1)
          .attr('stroke-dasharray', '5,5');
      })
      .setActiveNodeCentered(false)
      .buttonContent(
        ({node,state}) =>{ 
          return `
      <div style="background: #1CA8DD;border-radius:50%; width:20px;height:20px;margin-top: 4px;margin-left: 11px; display:flex;flex-direction:column;justify-content:center;align-items:center;">
      ${!node.children?'<ion-icon name="chevron-down-outline"></ion-icon>':'<ion-icon name="chevron-up-outline"></ion-icon>'}
      </div>`},
      )
      .nodeContent(function (d, i, arr, state) {
        console.log(d)
        return `
        <div style="font-family: 'var(--ion-font-family)', sans-serif; width: 136px; height: 151px; background-image: url('assets/diamond.svg'); background-repeat: no-repeat; background-position: center; display: flex;flex-direction:column;gap:4px; justify-content: center; align-items: center;width:100%;padding:8px">
        <div style="font-size:14px;color:#FFFFFF;font-weight:600">${d.data.originalLabel? d.data.packetId : d.data.packetLabel }</div>
        <div style="font-size:12px;color:#FFFFFF;">  ${d.data.cts? 'Carat: '+d.data.cts : '' }</div>
        </div>
                          `;
      })
      .onNodeClick((d) => console.log(d, ' node clicked'))
      .data(this.pyramidProps.data)
      .container(this.chartContainer.nativeElement)
      .render();

      setTimeout(()=>{
        this.chart.expandAll().fit()
      },100)

  }
}