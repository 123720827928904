import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { HttpService } from 'src/app/services/http/http.service';
import { CopyplanmodalComponent } from '../copyplanmodal/copyplanmodal.component';
import { PDComponent } from '../pd/pd.component';
import { SignermodalComponent } from '../signermodal/signermodal.component';
import { chekerAction } from '../trybe-checker/state/checker.action.type';
import { SignerAction } from '../trybesigner/state/signer.action.type';

@Component({
  selector: 'app-plan-info-grid',
  templateUrl: './plan-info-grid.component.html',
  styleUrls: ['./plan-info-grid.component.scss'],
})
export class PlanInfoGridComponent implements OnInit {
  @Input() packetPlans: any;
  @Input() allData: [];
  @Input() allowAction: boolean;
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  isAllCollapse: boolean = false;
  selectedPlans: any = [];
  rowData: any = {}
  rowDatas: any = []
  currentPlan: any = {};
  currentParentPlan: any = {};
  filteredPlan: any = [];
  changes: any = {};
  dropdown: any = {};
  chLength: number = 0
  public ids = [];
  public tensionData = [];
  public stressData = [];
  public stressTypeData = [];
  public isDisable = true;
  public isAllChildFileSelected = false;
  totalPlans: any;
  packetId;

  public remarksPlanID = [];
  public selectedFinalPlan;
  packetPlanStones: any;
  currActionData: any;

  remProg: any = [{ id: 1, name: 'Plan Program (Red)', selected: false }, { id: 2, name: 'Select Program (White)', selected: false }, { id: 3, name: 'All Program', selected: false }];
  searchShape: string = '';
  searchColor: string = '';
  searchClarity: string = '';
  searchCut: string = '';
  searchProgram: string = '';
  @Output() planSelected: EventEmitter<any> = new EventEmitter();
  @Output() updatePlansData: EventEmitter<any> = new EventEmitter();

  constructor(public router: Router,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    public httpService: HttpService,
    private store: Store<AppState>,
    public alertController: AlertController,
    public popoverController: PopoverController) { }


  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }
  ngOnInit() { }

  async copyPlan(evt: any, planData) {
    evt.preventDefault();
    evt.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: CopyplanmodalComponent,
      cssClass: 'extraLargePage',
      componentProps: {
        'data': planData,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.copyPlanExit(data.data);
    });
    return await modal.present();
  }

  async copyPlanExit(modalData) {
    if (modalData) {
      // let mac = [...this.packetPlans].map(x => x.id)
      // console.log(mac)
      let maxId = Math.max(...this.packetPlans.map(x => x.id));
      let maxTag = Math.max(...this.packetPlans.map(x => x.tag));
      this.packetPlans.map(x => {
        let i = parseInt(x.seqNo)
        if (typeof i == 'number') {
          return i
        }
      })
      let maxSeqNo = Math.max(...this.packetPlans.map(x => parseInt(x.seqNo)).filter(x => !!x))
      console.log(maxSeqNo)
      let copyPlan = { ...modalData };
      copyPlan.id = maxId + 1;
      copyPlan.tag = maxTag + 1;
      copyPlan.seqNo = maxSeqNo + 1
      copyPlan['copyFrom'] = modalData.seqNo
      console.log(copyPlan)
      // this.packetPlans.push(copyPlan)
      let packets = [...this.packetPlans]
      packets.push(copyPlan);
      console.log(packets)
      this.packetPlans = packets
    }
  }

  async deleteRow(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(id, 'packetplanid');
    console.log('-------Delete......');
    // alert(id);
    // let result = confirm('Are you sure to delete the row?');
    // if (result) {
    //   let updatedSignerData = this.packetPlans.filter(d => d.id != id)
    //   console.log(updatedSignerData);
    //   console.log(this.packetPlans);
    //   this.packetPlans = updatedSignerData;
    // }
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'CLOSE',
        handler: (d: any) => {
          console.log('Closed', d);
        }
      },
      {
        text: 'OK',
        handler: (d: any) => {
          console.log('-----------temp-----------');
          // let updatedSignerData = this.packetPlans.filter((d) => d.id != id);
          // this.packetPlans = updatedSignerData;
        }
      }]
    });
    await alert.present();
  }

  togglingRow() {
    this.isAllCollapse = !this.isAllCollapse ? true : false;
    if (this.isAllCollapse) {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: false }));
    } else {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: true }));
    }
    console.log(this.packetPlans);
  }
  onToggleBtnClick() {
    this.togglingRow();
    console.log(this.isAllCollapse);
  }
  onRefreshingCurrRate() {
    console.log('-------onRefreshingCurrRate......');
  }

  parentPlanChange(evt: any, inData: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(inData, "indata")
    console.log('-----------parentPlanChange-----------');
    console.log(this.packetPlans)

    let filter = this.packetPlans.filter(x => x.diffSelect)
    console.log(filter.length)
    if (evt.detail.checked && filter.length < 2) {

      this.packetPlans = this.packetPlans.map(s => {
        if (s.id == inData.id) {
          return {
            ...s,
            diffSelect: true,
            checked: true
          }
        } else {
          return s
        }
      })
    } else {

      this.packetPlans = this.packetPlans.map(s => {
        if (s.id == inData.id) {
          return {
            ...s,
            diffSelect: false,
            checked: false
          }
        } else {
          return s
        }

      })
    }
    this.updatePlansData.emit(this.packetPlans)
    // this.store.dispatch(SignerAction.changePlanOncheck({ plan: inData, isChecked: evt.detail.checked }));
  }
  childPlanChange(evt: any, d: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('childPlanChange');
    console.log(evt);
    console.log(d);
  }

  showDetail(evt: any, item: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(item);
    // if (item.isExpand == true) {
    //   item.isExpand = false;
    // } else {
    //   item.isExpand = true;
    // }
  }

  async getPrograms(rowData, currPlan) {
    console.log('getPrograms');
    let packetId = rowData.packetId;
    let planStoneId = rowData.id;
    this.currentPlan = currPlan;
    this.currentParentPlan = rowData;
    console.log(currPlan)
    console.log(packetId)
    console.log(planStoneId)
    // await this.store.dispatch(getProgramBypacketIdAndPlanStoneId({ packetId: packetId, planStoneId: planStoneId }));
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'signerModal',
      componentProps: {
        "message": 'showProgram',
        'data': rowData,
        'plan': currPlan,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }

  onModalExit(d: any) {
    console.log('---onModalExit');
    console.log(d);
    if (!!d.data) {
      if (d.data.for == 'saveProgram') {
        let packetId = this.currentParentPlan.packetId;
        let planStoneID = this.currentPlan.id;
        let progName = d.data.program.progName;
        let progId = d.data.program.id;
        if (this.changes['progName'] == undefined) {
          this.changes['progName'] = {};
          this.changes['progName'] = d.data.program;
        } else {
          this.changes['progName'] = d.data.program;
        }
        this.chLength = Object.keys(this.changes).length;
        this.isDisable = this.chLength !== 0 ? false : true;
        console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
        console.log(this.chLength);
        console.log(this.isDisable);
        // this.store.dispatch(SignerAction.showSelectedProgram({ packetId: packetId, planStoneID: planStoneID, program: progName, programID: progId }));
      } else if (d.data.for == 'savePlannedRemark') {
        console.log('Planned Remark');
        console.log(d);
        this.packetPlans = [...this.packetPlans].map(n => {
          let p = { ...n }
          if (p.id == d.data.packetPlanID) {
            p.plannedRemark = d.data.plannedRemark;
          }
          return p;
        })
      }
    }
  }

  async onParentPlanSelection(evt, rd) {
    //this.selectedFinalPlan = 
    this.planSelected.emit(rd.id)
    console.log('---------onParentPlanSelection-------------');
    console.log(evt);
    console.log(rd);
    let order = rd.selectionOrder
    console.log(evt.detail.checked);
    console.log(this.packetPlans);
    let isAllChildFileSelected = false;
    let isAllChldPDSaved = false;

    if (evt.detail.checked) {
      console.log('Checked');

      let chkSelFileLength = 0;
      let chkPdSavedLength = 0
      let childRows = rd.packetPlanStones;
      let childRowsLength = rd.packetPlanStones.length;
      childRows.forEach((d: any) => {
        if (d.isFileSelected || d.xpsFile) {
          chkSelFileLength++;
        }
        if (d.pdSaved) {
          chkPdSavedLength++;
        }
      });
      if (childRowsLength > 0) {
        isAllChildFileSelected = true;
      }

      if (childRowsLength == chkPdSavedLength) {
        isAllChldPDSaved = true;
      }

      // let order = 1;
      // const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      // if (indexes.length > 0) {
      //   console.log(order)
      //   order = Math.max(...indexes) + 1;
      // }

      this.packetPlans = this.packetPlans.map(x => {
        let n = { ...x }
        if (n.id == rd.id) {
          //let clone = { ...x };
          n['selectionOrder'] = order
          n.selected = true;
        } else {
          n.selected = false;
        }
        n['selectionOrder'] = order
          n.selected = true;
          n.isSelected = 1
        return n
      })
    } else {
      this.packetPlans = this.packetPlans.map(x => {
        if (x.id == rd.id) {
          let n = { ...x }
          delete n.selectionOrder
          return n
        } else {
          return x
        }

      })
      // const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      // if (indexes.length > 0) {
      //   this.packetPlans = this.packetPlans.map(x => {
      //     if (x.selectionOrder && x.selectionOrder > order) {
      //       x.selectionOrder = x.selectionOrder - 1
      //     }
      //     return x
      //   })
      // }


    }
    console.log(isAllChildFileSelected);
    console.log(isAllChldPDSaved);

    const checkboxes = this.packetPlans.length;
    // let selected = 0;
    // this.packetPlans.map((d: any) => {
    //   console.log(d);

    //   if (d.selected) selected++;
    // });

    // if (selected > 0 && selected < checkboxes) {
    //   this.setIndeterminateState = true;
    //   this.parentCheckbox = false;
    // } else if (selected == checkboxes) {
    //   this.parentCheckbox = true;
    //   this.setIndeterminateState = false;
    // } else {
    //   this.setIndeterminateState = false;
    //   this.parentCheckbox = false;
    // }
    console.log(checkboxes);
    // console.log(selected);
    // console.log(this.setIndeterminateState);
    this.store.dispatch(chekerAction.changePlanOncheck({ plan: rd, isChecked: evt.detail.checked }));
  }


  async onClickPD(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('-------PD......');
    // console.log
    const pdmodal = await this.modalCtrl.create({
      component: PDComponent,
      cssClass: 'popup',
      componentProps: {
        // "message": 'message',
        'data': { packetPlanStoneId: id }
      },
    });

    pdmodal.onDidDismiss().then((d: any) => {
      if (d.data) {
        this.PDValueSubmission(id)
      }
    });
    return await pdmodal.present();
  }
  filterAsSearched(ev: any, searchStr: string, val: any) {
    console.log('%cfilterAsSearched =' + val, 'color:green;font-weight:bold;');
    console.log(this.allData);
    // this.packetPlans = this.allData.filter((d) => {
    //   return d.seqNo.toLowerCase().indexOf(val.toLowerCase()) > -1;
    // });
    if (searchStr != null || val != null) {
      this.packetPlans = this.allData.filter((d: any) => {
        return d.packetPlanStones.some((x: any) => {
          return d.id === x.packetPlanId && x[searchStr].toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
      });
    }
    console.log(this.allData);
    console.log(this.packetPlans);
  }
  PDValueSubmission(m: any) {
    console.log(m.data)
    this.packetPlans = this.packetPlans.map(x => {
      if (x.packetPlanStones.length > 0) {
        let newPd = x.packetPlanStones.map(b => {
          if (b.id == m.data) {
            return {
              ...b,
              pdSaved: true
            }
          } else {
            return b
          }
        })
        return {
          ...x,
          packetPlanStones: newPd
        }
      }
    })
    console.log('PDValueSubmission');
    console.log(m);
    console.log(this.packetPlans)
    // this.store.dispatch(SignerAction.savePDParameterPacketPlanStone({pdParam:}));
  }

  async uploadRowFile(event, planId, rowId, index) {
    if (this.allowAction) {
      console.log(planId, index)
      if (event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        //console.log(this.packetPlans);
        this.packetPlans = [...this.packetPlans].map(n => {
          if (n.id == rowId) {
            const formatData = new FormData();
            formatData.append("file", selectedFile);
            formatData.append("packetPlanStoneId", planId)
            let otherParam = { packetPlanStoneId: planId, for: 'uploadXpsOrHtmlFile', "packetId": rowId }
            this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));
            //const copyOfObj = [...n.packetPlanStones];
            const packetPlanStones = n.packetPlanStones.map(x => {
              if (x.id == planId) {
                return {
                  ...x,
                  isFileSelected: true,
                  xpsFile: selectedFile
                }
              } else {
                return x
              }
            })
            return { ...n, packetPlanStones }
          } else {
            return n
          }
        })
        // this.packetPlans.filter(n => n.id == rowId)[0].packetPlanStones.filter(x => x.id == planData.id).map(y => {
        //   let newObj = Object.assign({ file: selectedFile }, y);
        //   console.log(newObj)
        //   return newObj
        // })
        console.log(this.packetPlans)
        this.validationCheck()
      }
    }

  }
  deletePartPlan(plan) {

  }

  download(file) {
    window.open(file, 'Download');
  }


  async validationCheck() {
    this.selectedPlans = this.packetPlans.filter(x => x.selected)
    console.log(this.selectedPlans)
    if (this.selectedPlans.length > 0) {
      await this.selectedPlans.filter(n => {
        let packetsLength = n.packetPlanStones.length
        let fileLength = n.packetPlanStones.filter(x => x.isFileSelected).length;


      })
    }
  }

  twoDecimalvalues(x) {
    return parseFloat(parseFloat(x).toFixed(2));
  }

  ngOnDestroy() {
    console.log('checker Screen Component destoryed');
  }

}
