import { createAction, props } from '@ngrx/store';
// import { Stones } from '../models/incoming-stone.models';
import { User } from './user';

export const showLoader = createAction(
    '[Auth] Show Loader',
);

export const hideLoader = createAction(
    '[Auth] Hide Loader',
    // props<{ userName: string, password: string }>(),
);
export const logout = createAction(
    '[Auth] Logout',
);

export const login = createAction(
    '[Auth] login User',
    props<{ userName: string, password: string }>(),
);

export const getUser = createAction(
    '[Auth] Get User',
    props<{ userId: number }>(),
);

export const saveUser = createAction(
    '[Auth] Save User',
    props<{ user: User }>(),
);

export const getUserActions = createAction(
    '[Auth] Get User Actions',
    props<{ userId: number }>(),
);
// let userId = data.id
// // this.listOfActions = data.listOfActions
// this.store.dispatch(
//   HomeActions.getUserActions({ userId: userId }),
export const saveUserActions = createAction(
    '[Auth] Save User Actions',
    props<{ actions: any }>(),
);

export const getUserStoneActions = createAction(
    '[Auth] Get User Stone Actions',
);

export const saveUserStoneActions = createAction(
    '[Auth] Save User Stone Actions',
    props<{ actions: any }>(),
);

export const getActionFormsByIds = createAction(
    '[Auth] get Action Forms',
    props<{ actionIds: [] }>(),
);

export const saveActionForms = createAction(
    '[Auth] Save User Action Forms',
    props<{ actionForms: any }>(),
);

export const getUserSubmittedPlans = createAction(
    '[Auth] get User Submitted Plans',
    props<{ userId: any }>(),
);

export const saveUserSubmittedPlans = createAction(
    '[Auth] save User Submitted Plans',
    props<{ plans: [] }>(),
);

export const getIfManager = createAction(
    '[Auth] Get if manager'
);

export const saveIfManager = createAction(
    '[Auth] save  if Manager',
    props<{ userIfManager: any }>(),
);
export const getNSaveLatticeAuthToken = createAction(
    '[Auth] Get & Save latticeAuthToken',
    props<{ payload: any }>()
);

// export const saveLatticeAuthToken = createAction(
//     '[Auth] save  latticeAuthToken',
//     props<{ latticeAuthToken: any }>(),
// );





