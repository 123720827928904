import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispatchPacketComponent } from './dispatch-packet.component';
import { HeaderComponentModule } from '../components/header/header.module';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../footer/footer.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { SingleMultiDropdownModule } from '../components/single-multi-dropdown/single-multi-dropdown.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';
import { NgxFormModule } from '../components/ngx-form/ngx-form.module';



@NgModule({
  declarations: [DispatchPacketComponent],
  exports: [DispatchPacketComponent],
  imports: [
    CommonModule,
    HeaderComponentModule,
    IonicModule,
    FooterModule,
    DataGridComponentModule,
    SingleMultiDropdownModule,
    WidthAlertModule,
    ScannerinputModule,
    NgxFormModule
  ]
})
export class DispatchPacketModule { }
