import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from '@ionic/storage-angular';
import { shareReplay } from "rxjs/operators";
import { ConfigService } from "src/app/services/config/config.service";


@Injectable({
    providedIn: 'root',
})
export class PacketInwardService {
    constructor(
        private storage: Storage,
        public configService: ConfigService,
        private http: HttpClient,

    ) {
        this.getTrybeBaseUrl();

    }
    public header: any = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };
    public fileHeader: any = {
        'enctype': 'multipart/form-data',
        Accept: 'application/json',
    };
    token: String = '';
    trybeUrl: String = '';

    async ngOnInit() {
        // await this.storage.create();
        this.loadToken();
    }

    async loadToken() {
        let token = await  localStorage.getItem("token");
        if (token) this.token = token;
    }
    async getTrybeBaseUrl() {
        this.trybeUrl = this.configService.getTrybeBaseUrl();
    }

    getInwardPacketList(data) {
        // this.header.Authorization = await this.storage.get('token');
        this.header.Authorization = this.token;
        return this.http.post(this.trybeUrl + 'trybe-packet-inward-status/getAll', data ,{ headers: this.header }).pipe(shareReplay());
    }

    async uploadPacketFile(payload: any,jobid,AccId,partyId) {
        try {
            this.fileHeader.Authorization = this.token;
            const response = await this.http.post(this.trybeUrl + `trybe-packet-inward-status/packetInward?jobPartyId=${partyId}&accountNo=${AccId}&jobProcessId=${jobid}`, payload, { headers: this.fileHeader }).toPromise();
            return response;
        }
        catch (error) {
            return await error;
        }
    }

    async downloadPacketFile(fileName: string) {
        try {
            // this.fileHeader.Authorization = this.token;
            window.open(`${this.trybeUrl}packetInward/${fileName}`);
            // const response = await this.http.get(`${this.trybeUrl}packetInward/${fileName}`, { headers: this.fileHeader }).toPromise();
            // return response;
        }
        catch (error) {
            return await error;
        }
    }

    async getLotsToAllocate() {
        try {
            this.header.Authorization = this.token;
            const response = await this.http.get(this.trybeUrl + 'packet/getCurrentLotsAndTheirStatus', { headers: this.header }).toPromise();
            return response;
        }
        catch (error) {
            return await error;
        }
    }

    async getVirtualSuperCheckerAndPhyscialChecker() {
        try {
            this.header.Authorization = this.token;
            const response = await this.http.get(this.trybeUrl + 'tbl-role/getVirtualSuperCheckerAndPhyscialChecker', { headers: this.header }).toPromise();
            return response;
        }
        catch (error) {
            return await error;
        }
    }

    async getLotAllocationValues(payload: any) {
        try {
            this.header.Authorization = this.token;
            const response = await this.http.post(this.trybeUrl + 'lot-allocation/getLotAllocationValues', payload, { headers: this.header }).toPromise();
            return response;
        }
        catch (error) {
            return await error;
        }
    }

    async allocateLot(payload: any) {
        try {
            this.header.Authorization = this.token;
            const response = await this.http.post(this.trybeUrl + 'lot-allocation/save', payload, { headers: this.header }).toPromise();
            return response;
        }
        catch (error) {
            return await error;
        }
    }

    async getCompanies() {
        try {
            this.header.Authorization = this.token;
            const response = await this.http.get(this.trybeUrl + 'users/getCompanyListOfKPS', { headers: this.header }).toPromise();
            return response;
        }
        catch (error) {
            return await error;
        }
    }
}
