import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderComponentModule } from '../components/header/header.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { SingleMultiDropdownModule } from '../components/single-multi-dropdown/single-multi-dropdown.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { DxDataGridModule, DxPopoverModule } from 'devextreme-angular';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';
import { TransferPacketToDispatchComponent } from './transfer-packet-to-dispatch.component';
import { PrintskeltonModule } from '../printskelton/printskelton.module';
import { PrintPageModule } from '../components/print-page/print-page.module';
import { TrybecardComponent } from '../components/trybecard/trybecard.component';
import { TrybeCardModule } from '../components/trybecard/trybecard.module';
import { NewDropDownModule } from '../components/new-drop-down/new-drop-down.module';
import { DynamicModalModule } from './dynamic-modal/dynamic-modal.module';

@NgModule({
  declarations: [TransferPacketToDispatchComponent],
  entryComponents: [],
  exports: [TransferPacketToDispatchComponent],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    DataGridComponentModule,
    WidthAlertModule,
    ScannerinputModule,
    IonicModule,
    HeaderComponentModule,
    TrybeCardModule,
    PrintskeltonModule,
    SingleMultiDropdownModule,
    PrintPageModule,
    NewDropDownModule,
    DynamicModalModule
  ],
})
export class TransferPacketToDispatchModule {}
