import { createReducer, on } from "@ngrx/store";
import { chekerAction } from "./checker.action.type";
import { initialChekerState } from "./checker.state";
import { act } from "@ngrx/effects";

export const chekerReducer = createReducer(
    initialChekerState,
    on(chekerAction.saveCheckerPlans, (state, action) => {
        let packetPlans: any = [...(action?.packetPlanstones?.packetPlans || [])];
        let alpha: String = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        console.log(action);
        let cuts: any = [], shapes: any = [], clarities: any = [], colors: any = [], stoneRemarks: any = [], plansData: any = [];
        let cutMap: any = {}, shapeMap: any = {}, clarityMap: any = {}, colorMap: any = {}, stoneRemarkMap: any = {};
        let newPacketPlans = packetPlans.map((d: any) => {
            let newPacketPlanStones: any = [];
            let weightSum: number = 0;
            let tag: string = null;
            let totalKGRate: number = 0;
            let bestRate: number = 0;
            let totalRate: number = 0;
            let expPolPer1: number = 0;//some of Packet Plan stones/Org WT*100
            let expPolPer2: number = 0;//some of Packet Plan stones/Curr WT*100
            let newOb = { ...d };
            let seqNum = newOb['seqNo']
            if (!!d && !!d?.packetPlanStones) {
                newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
                    let obj = { ...m };
                    obj['piece'] = alpha[i];

                    obj['selected'] = false;
                    obj['isFileSelected'] = false;
                    // obj['isExpand'] = true;
                    // obj['fluorescence'] = null;
                    let kgRate = m.kgRate != null ? m.kgRate : 0;

                    cutMap[m.cutId] = m.cut;
                    shapeMap[m.shapeId] = m.shape;
                    clarityMap[m.clarityId] = m.clarity;
                    colorMap[m.colorId] = m.color;

                    // obj['progName'] = '-';
                    // obj['progId'] = 0;
                    tag = (m.tag)
                    weightSum += parseFloat(m.expPol);
                    totalKGRate += parseFloat(kgRate);
                    return obj;
                });

                cuts = Object.keys(cutMap).map(prop => {
                    let obj: any = { id: prop, name: cutMap[prop] };

                    return obj;
                });
                shapes = Object.keys(shapeMap).map(prop => {
                    let obj: any = { id: prop, name: shapeMap[prop] };
                    return obj;
                });
                clarities = Object.keys(clarityMap).map(prop => {
                    let obj: any = { id: prop, name: clarityMap[prop] };
                    return obj;
                });

                colors = Object.keys(colorMap).map(prop => {
                    let obj: any = { id: prop, name: colorMap[prop] };
                    return obj;
                });
            }
            newOb['seqNo'] = isNaN(seqNum) ? seqNum : "";
            newOb['selected'] = false;
            newOb['isExpand'] = true;
            newOb['totExpYield'] = Math.round(weightSum * 100) / 100;//Exp P Yield calculated Field
            newOb['expPolPer1'] = expPolPer1;
            newOb['tag'] = tag;
            newOb['expPolPer2'] = expPolPer2;
            newOb['packetPlanStones'] = newPacketPlanStones;
            newOb['plannedRemark'] = "";
            newOb['totalKGRate'] = totalKGRate;//totalKGRate/KG Rate calculated Field
            newOb['bestRate'] = bestRate;//Exp P Yield calculated Field
            newOb['totalRate'] = !!totalRate ? totalRate : totalKGRate;//KgRate Need to remove calculated Field
            newOb['bidAmt'] = !!newOb['bidAmt'] ? newOb['bidAmt'] : totalKGRate;//KgRate Need to remove calculated Field
            return newOb;
        });
        return {
            ...state,
            //allPacketPlans:newPacketPlans,
            packetPlanStones: newPacketPlans,
            cuts: cuts,
            shapes: shapes,
            clarities: clarities,
            colors: colors,
            stoneRemarks: stoneRemarks,
            plansData: action?.packetPlanstones?.plansData
        }
    }),

    on(chekerAction.updateDropDownParam, (state, action) => {
        let param = action.updateData;
        let newPacketPlans = [];
        newPacketPlans = [...state.packetPlanStones].map((d: any) => {
            let newPacketPlanStones: any = [];
            let newOb = { ...d };
            if (!!d.packetPlanStones) {
                newPacketPlanStones = d.packetPlanStones.map((m: any, j: number) => {
                    let obj = { ...m };
                    if (param.shape == 'All' && param.piece == 'All') {
                        if (!!param.fluorescence) {
                            obj['fluorescence'] = param.fluorescence;
                            obj['fluorescenceId'] = param.fluorescenceID;
                        }
                        if (!!param.color) {
                            obj['planColor'] = param.color;
                            obj['colorId'] = param.colorID;
                        }
                        if (!!param.clarity) {
                            obj['planClarity'] = param.clarity;
                            obj['clarityId'] = param.clarityID;
                        }
                    } else if (param.shape == 'All' && param.piece != 'All') {
                        if (obj.piece == param.piece) {
                            if (!!param.fluorescence) {
                                obj['fluorescence'] = param.fluorescence;
                                obj['fluorescenceId'] = param.fluorescenceID;
                            }
                            if (!!param.color) {
                                obj['planColor'] = param.color;
                                obj['colorId'] = param.colorID;
                            }
                            if (!!param.clarity) {
                                obj['planClarity'] = param.clarity;
                                obj['clarityId'] = param.clarityID;
                            }
                        }
                    } else if (param.shape != 'All' && param.piece == 'All') {
                        if (obj.planShape == param.shape) {
                            if (!!param.fluorescence) {
                                obj['fluorescence'] = param.fluorescence;
                                obj['fluorescenceId'] = param.fluorescenceID;
                            }
                            if (!!param.color) {
                                obj['planColor'] = param.color;
                                obj['colorId'] = param.colorID;
                            }
                            if (!!param.clarity) {
                                obj['planClarity'] = param.clarity;
                                obj['clarityId'] = param.clarityID;
                            }
                        }
                    } else {
                        if (obj.planShape == param.shape && obj.piece == param.piece) {
                            if (!!param.fluorescence) {
                                obj['fluorescence'] = param.fluorescence;
                                obj['fluorescenceId'] = param.fluorescenceID;
                            }
                            if (!!param.color) {
                                obj['planColor'] = param.color;
                                obj['colorId'] = param.colorID;
                            }
                            if (!!param.clarity) {
                                obj['planClarity'] = param.clarity;
                                obj['clarityId'] = param.clarityID;
                            }
                        }
                    }
                    return obj;
                });
            }
            newOb['packetPlanStones'] = newPacketPlanStones;
            return newOb;
        });
        return {
            ...state,
            packetPlanStones: newPacketPlans
        };
    }),
    on(chekerAction.changePlanOncheck, (state, action) => {
        let plans: any = [...state.selectPlans];
        let currPlan: any = action.plan;
        let currentChkState: any = action.isChecked;
        console.log('changePlanOncheck');
        console.log(plans);
        console.log(currentChkState);
        console.log(currPlan);
        plans = [];
        plans.push(currPlan);
        return {
            ...state,
            selectPlans: plans
        }
    }),
    on(chekerAction.saveFinalPlanData, (state, action) => {
        return {
            ...state,
            finalPlans: action.packetPlanstones
        };
    }),
    on(chekerAction.resetAllPlans, (state, action) => {
        return {
            ...state,
            packetPlanStones: []
        };
    }),
    on(chekerAction.removeFinalPlanData, (state, action) => {
        return {
            ...state,
            finalPlans: []
        };
    }),

    on(chekerAction.savePlansAccToUSers, (state, action) => {
        let packetPlans: any = [...action.PlanAccordingToUSers];
        let alpha: String = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        console.log(state);
        let cuts: any = [], shapes: any = [], clarities: any = [], colors: any = [], stoneRemarks: any = [];
        let cutMap: any = {}, shapeMap: any = {}, clarityMap: any = {}, colorMap: any = {}, stoneRemarkMap: any = {};
        let newPacketPlans = packetPlans.map((d: any) => {
            let newPacketPlanStones: any = [];
            let weightSum: number = 0;
            let tag: string = null;
            let totalKGRate: number = 0;
            let bestRate: number = 0;
            let totalRate: number = 0;
            let expPolPer1: number = 0;//some of Packet Plan stones/Org WT*100
            let expPolPer2: number = 0;//some of Packet Plan stones/Curr WT*100
            let newOb = { ...d };
            let seqNum = newOb['seqNo']
            if (!!d.packetPlanStones) {
                newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
                    let obj = { ...m };
                    obj['piece'] = alpha[i];

                    obj['selected'] = false;
                    obj['isFileSelected'] = false;
                    // obj['isExpand'] = true;
                    // obj['fluorescence'] = null;
                    let kgRate = m.kgRate != null ? m.kgRate : 0;

                    cutMap[m.cutId] = m.cut;
                    shapeMap[m.shapeId] = m.shape;
                    clarityMap[m.clarityId] = m.clarity;
                    colorMap[m.colorId] = m.color;

                    // obj['progName'] = '-';
                    // obj['progId'] = 0;
                    tag = (m.tag)
                    weightSum += parseFloat(m.expPol);
                    totalKGRate += parseFloat(kgRate);
                    return obj;
                });

                cuts = Object.keys(cutMap).map(prop => {
                    let obj: any = { id: prop, name: cutMap[prop] };

                    return obj;
                });
                shapes = Object.keys(shapeMap).map(prop => {
                    let obj: any = { id: prop, name: shapeMap[prop] };
                    return obj;
                });
                clarities = Object.keys(clarityMap).map(prop => {
                    let obj: any = { id: prop, name: clarityMap[prop] };
                    return obj;
                });

                colors = Object.keys(colorMap).map(prop => {
                    let obj: any = { id: prop, name: colorMap[prop] };
                    return obj;
                });
            }
            newOb['seqNo'] = isNaN(seqNum) ? seqNum : "";
            newOb['selected'] = false;
            newOb['isExpand'] = true;
            newOb['totExpYield'] = Math.round(weightSum * 100) / 100;//Exp P Yield calculated Field
            newOb['expPolPer1'] = expPolPer1;
            newOb['tag'] = tag;
            newOb['expPolPer2'] = expPolPer2;
            newOb['packetPlanStones'] = newPacketPlanStones;
            newOb['plannedRemark'] = "";
            newOb['totalKGRate'] = totalKGRate;//totalKGRate/KG Rate calculated Field
            newOb['bestRate'] = bestRate;//Exp P Yield calculated Field
            newOb['totalRate'] = !!totalRate ? totalRate : totalKGRate;//KgRate Need to remove calculated Field
            newOb['bidAmt'] = !!newOb['bidAmt'] ? newOb['bidAmt'] : totalKGRate;//KgRate Need to remove calculated Field
            return newOb;
        });
        return {
            ...state,
            plansAccToUser: newPacketPlans,
            cuts: cuts,
            shapes: shapes,
            clarities: clarities,
            colors: colors,
            stoneRemarks: stoneRemarks
        };
    }),

)