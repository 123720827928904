import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { AlertController, IonRouterOutlet, ModalController, PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { CopyplanmodalComponent } from 'src/app/components/copyplanmodal/copyplanmodal.component';
import { myVirtualTaskActions } from 'src/app/components/my-virtual-task/state/my-virtual-task.actions.type';
import { virtualAPIs } from 'src/app/components/my-virtual-task/state/my-virtual-task.data';
import { getMyTasks } from 'src/app/components/my-virtual-task/state/my-virtual.task.selectors';
import { PopoverUserComponent } from 'src/app/components/popover-user/popover-user.component';
import { SignerAction } from 'src/app/components/trybesigner/state/signer.action.type';
import { AppState } from 'src/app/reducers';
import { HttpService } from 'src/app/services/http/http.service';
import { getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';

@Component({
  selector: 'app-planning-review',
  templateUrl: './planning-review.component.html',
  styleUrls: ['./planning-review.component.scss'],
})
export class PlanningReviewComponent implements OnInit {
  rowData: any;
  eventObj: { rowData: any; };
  isPageValue:any="planningReview"
  public showPlanDrawer: boolean = false;
  @Output() onPlanSelection: EventEmitter<any> = new EventEmitter();
  currentPlanRowData: any;
  hidePlanDrawer: boolean;


  constructor(
    public popoverController: PopoverController,
    private store: Store<AppState>,
    private httpService: HttpService,
    private routerOutlet: IonRouterOutlet,
    private alertController: AlertController,
    public modalCtrl:ModalController

  ) { }

  gridData: any = [];
  updatePayload: any = [];
  hideGrid: any = {};
  selectedPacketId: number = 0;
  checkedPlans:any = [];
  allPlanInfo: any = {
    received: {}
  };
  planInfoFetched:boolean =false;
  taskCount:any = {
    pending: 0,
    hold: 0,
  }

  ngOnInit() {
    this.getSelectedRow();
    this.getTasks();
  }

  getSelectedRow(){
    this.store.pipe(select(getCurrentRowData)).subscribe(res => {
      this.rowData=res?.[0];
      this.selectedPacketId = res?.[0]?.id || 0;
      if(this.selectedPacketId)
      this.getTaskCount();
    })
  }

  getTaskCount(){
    this.store.pipe(select(getMyTasks)).subscribe((res:any) => {
      if(res?.pendingCount)
      this.taskCount.pending = res.pendingCount;
    });

    this.httpService.getData(true, 'virtual-task/getVirtualTaskOnBasisOfTask?task=hold',{packetId:this.selectedPacketId}).subscribe((res:any) => {
      if(res?.holdCount)
      this.taskCount.hold = res.holdCount;
    })
  }

  getTasks(){
    let fetchMyVirtualTasksDispatched:boolean = false;
    this.store.pipe(select(getMyTasks)).subscribe(res => {
      if(res?.data){
        if(res?.data?.["Planning Review by Head Checker"]?.[0]){
          this.httpService.fetchData(true, `packetPlanStone/findAllByPacketIdOnBasisOfIsSelected/${this.selectedPacketId || res?.data?.["Planning Review by Head Checker"]?.[0]?.id}`).subscribe((res:any) => {
            this.gridData = (res?.data || []).map((dt:any) => {
              let alpha: String = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
              const packetPlanStones = dt.packetPlanStones?.map((ps:any, i:number) => {
                
                if(Number(((ps.expPol / ps.sawRghWgt)*100)?.toFixed(3)) <= 15){
                  ps['inActive'] = 1;
                }
                return {
                  ...ps,
                  piece: alpha[i]
                };
              })
              return {
                ...dt,
                packetPlanStones,
              }
            });
          })
        }
      } else if(!fetchMyVirtualTasksDispatched){
        fetchMyVirtualTasksDispatched=true;
        this.store.dispatch(
          myVirtualTaskActions.fetchMyVirtualTasks({
            api: virtualAPIs.virtualTaskEndPoint,
            Payload: null,
          })
        );
      }
    });
  }

  getPlanInfo(gridItem:any){
    if(!this.planInfoFetched){
      this.httpService.fetchData(true, `packetPlanStone/getThePlanInfoWhenTaskAssignFirst/${this.selectedPacketId}`).subscribe((res:any) => {
        this.planInfoFetched = true;
        (res?.data || []).map((dt:any) => {
          this.allPlanInfo.received[dt.name] = dt.createdDate;
        });
      });
    }
  }

  planCheck(event:any, gridIem:any){
    if(!event.target.checked)
    this.checkedPlans.push({...gridIem, isChecked: true});
    else
    this.checkedPlans = this.checkedPlans.filter((dt:any)=>dt.id!==gridIem.id);
  }

  async updateParamClick(){
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'planningParameterSelection',
      componentProps: { forPage: 'planningParameterSelection' },
    });
    await popover.present();
    popover.onDidDismiss().then((res) => {
      if(!res?.data?.gridRow)
      return;
      this.checkedPlans = (this.checkedPlans || []).map((dt:any) => {
        const packetPlanStones = (dt?.packetPlanStones || []).map((dtdt:any) => {
          return {...dtdt, ...(res?.data?.gridRow || {})};
        });
        return {...dt, packetPlanStones};
      });
      this.gridData = this.gridData.map((dt:any) => {
        if(this.checkedPlans.find((cp:any)=>cp.id==dt.id))
        return this.checkedPlans.find((cp:any)=>cp.id==dt.id);
        else
        return dt;
      });
      const updatePayload = [];
      this.gridData.map((dt:any)=>{
        dt.packetPlanStones.map((ps:any)=>{
          if(this.updatePayload.find((up:any)=>up.id==ps.id)){
            updatePayload.push({
              ...this.updatePayload.find((up:any)=>up.id==ps.id),
              ...res.data.gridRow
            });
          } else {
            updatePayload.push({
              id:ps.id,
              ...res.data.gridRow,
            })
          }
        });
      });
      this.updatePayload = updatePayload;
    });
  }

  async rowClick(gridRow: any){
    const rowData = JSON.parse(JSON.stringify(gridRow))
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'planningRowParameterSelection',
      componentProps: { forPage: 'planningRowParameterSelection', gridRow },
    });
    await popover.present();
    popover.onDidDismiss().then((res:any) => {
      if(!res?.data?.gridRow?.id){
        gridRow = rowData;
        return;
      }
      if(this.updatePayload.find((up:any)=>up.id==res.data.gridRow.id)){
        this.updatePayload = this.updatePayload.map((dt:any) => {
          if(dt.id == res.data.gridRow.id)
          return {
            ...dt,
            ...res.data.planningReviewUpdated,
          };
          return dt;
        })
      } else {
        this.updatePayload.push({
          id:res.data.gridRow.id,
          ...res.data.planningReviewUpdated,
        })
      }
    });
  }

  async confirmAssignPlan(event:any,selectedRow:any){
    if(!event.target.checked){
      const alert = await this.alertController.create({
        header: 'Are you sure you want to allocate plan?',
        cssClass: 'my-custom-class',
        buttons: [{
          text: 'NO',
          handler: (d: any) => {
            event.target.checked = false;
          }
        },
        {
          text: 'YES',
          handler: (d: any) => {
            this.assignplan(selectedRow);
          }
        }]
      });
      await alert.present();
    }
  }

  assignplan(selectedRow:any){
    this.httpService.saveData(true, `packetPlan/setPacketPlanToIsFinal/${selectedRow.id}`,{}).subscribe((res:any) => {
      // debugger;
    });
    // this.store.dispatch(SignerAction.setPacketPlanToIsFinal({ packetplan: {listOfPacketPlaneIdList:[selectedRow.id]}, rowData: selectedRow }));
  }

  getTotalCarat(gridItem){
    let totalCarat = 0;
    (gridItem?.packetPlanStones || [])?.map((dt:any) => {
      totalCarat += (dt?.expPol || 0);
    })
    return totalCarat;
  }

  inActiveRow(gridRow: any){
    gridRow['inActive'] = (gridRow?.['inActive']==1) ? 0 : 1;
    if(this.updatePayload.find((up:any)=>up.id==gridRow.id)){
      this.updatePayload = this.updatePayload.map((dt:any) => {
        if(dt.id == gridRow.id)
        return {
          ...dt,
          inActive: gridRow['inActive'] 
        };
        return dt;
      });
    } else {
      this.updatePayload.push({
        id:gridRow.id,
        inActive: gridRow['inActive'],
      })
    }
    // in active on save
    // Post
    // packetPlanStone/inActivePacketPlanStone
    // Payload
    // {
    //   "packetPlanStoneId" : 123,
    //   "inActive" : 1
    // }
  }

  holdPacket(){
    if(!this.rowData?.virtualId)
    return;
    const holdPayload = {
      id: this.rowData.virtualId,
      taskType : 4
    };
    this.httpService.saveData(true, 'virtual-task/modifyTheTaskStatus', holdPayload).subscribe((res:any) => {
      // debugger;
    });
  }

  saveAll(){
    // const updatePayload = [];
    // this.gridData.map((dt:any) => {
    //   updatePayload.push(...(dt?.packetPlanStones || []));
    // });
    if(this.updatePayload?.length)
    this.httpService.saveData(true, 'packetPlanStone/updatePacketPlanStoneParameter', this.updatePayload).subscribe((res:any) => {
      // debugger;
    })
  }

  async showVision360(message:string) {
    const modal = await this.modalCtrl.create({
      component: CopyplanmodalComponent,
      cssClass: 'showImage_Video',
      componentProps: {
        "message": message,
        'data': this?.rowData, 
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data) {
        // this.onModalExit(data);
      }
    });
    return await modal.present();
  }

  onClickOfPlan(data){
    this.showPlanDrawer = true;
    setTimeout(() => {
      this.showPlanDrawer = false;
    }, 300);
    this.eventObj = {
      rowData: data
    }

    this.currentPlanRowData = data;
    console.log(this.currentPlanRowData);


  }

  onPlanDrawerSave(evt) {
    console.log('onPlanDrawerSave');
    console.log(evt);
    this.hidePlanDrawer = true;
    setTimeout(() => {
      this.hidePlanDrawer = false;
    }, 300);
  }

  
}
