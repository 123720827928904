import { action, searchData } from "./tempData.model";

// debugger
export interface TempDataState {
    currentAction: action;
    currentSearchData: searchData;
    rowData: any;
    chatData:any;
    ids:any;
}


export const initialTempDataState: TempDataState = {
    currentAction: null,
    currentSearchData: null,
    rowData: [],
    chatData:[],
    ids:[]
};