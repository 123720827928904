import { createReducer, on } from "@ngrx/store";
import { Int_packetInwordList } from "./packet-inward.interface";
import { GET_packetInwardList, SAVE_packetInwardLoadSuccess } from "./packet-inward.action";

const initialPacketInword: Int_packetInwordList = {
    packetInword: [],
    isLoaded: false,
}

export const packetInwordReducer = createReducer(
    initialPacketInword,
    on(GET_packetInwardList, (state: Int_packetInwordList)=> state),
    on(SAVE_packetInwardLoadSuccess, (state: Int_packetInwordList, { data })=>{return {  ...state, packetInword: data, isLoaded: true }}),
)