import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponentModule } from '../header/header.module';
import { DataGridComponentModule } from '../data-grid/data-grid.module';
import { PacketcriteriaComponent } from './packetcriteria.component';
import { FooterModule } from 'src/app/footer/footer.module';
import { WidthAlertModule } from 'src/app/width-alert/width-alert.module';
import { SingleMultiDropdownModule } from '../single-multi-dropdown/single-multi-dropdown.module';
import { DropdownModule } from 'src/app/dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PacketcriteriaComponent],
  imports: [
    CommonModule,
    HeaderComponentModule,
    IonicModule,
    WidthAlertModule,
    FormsModule,
    FooterModule,
    DataGridComponentModule,
    SingleMultiDropdownModule,
    WidthAlertModule,
    DropdownModule,
    SingleMultiDropdownModule,
  ],
  exports: [PacketcriteriaComponent],
})
export class packetcriteriaModule { }
