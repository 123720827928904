import {
    createReducer,
    on
} from '@ngrx/store';
import { AuthActions } from './auth.action-type';
import { User } from './user';

// debugger
export interface AuthState {
    user: User;
    isLoading: boolean;
}

export const initialAuthState: AuthState = {
    user: null,
    isLoading: false,
};

export const authReducer = createReducer(
    initialAuthState,
    on(AuthActions.logout, (state, action) => {
        return {
            ...state,
            user: null,
            isLoading: false
        };
    }),
    on(AuthActions.saveUser, (state, action) => {
        return {
            ...state,
            user: action.user,
        };
    }),
    on(AuthActions.saveUserActions, (state, action) => {
        return {
            ...state,
            user: {
                ...state.user,
                listOfActions: action.actions
            }
        };
    }),
    on(AuthActions.saveUserActions, (state, action) => {
        return {
            ...state,
            user: {
                ...state.user,
                stoneActions: action.actions
            }
        };
    }),
    on(AuthActions.hideLoader, (state, action) => {
        return {
            ...state,
            isLoading: false
        };
    }),
    on(AuthActions.showLoader, (state, action) => {
        return {
            ...state,
            isLoading: true
        };
    }),


    on(AuthActions.saveIfManager, (state, action) => {
        return {
            ...state,
            user: {
                ...state.user,
                isManager: action.userIfManager
            }
        };
    }),

    on(AuthActions.saveUserSubmittedPlans, (state, action) => {
        return {
            ...state,
            user: {
                ...state.user,
                submittedPlans: action.plans
            }
        }
    })

);