import { MyVirtualTask } from "./my-virtual-task.data";

// debugger
export interface MyVirtualTaskState {
    myVirtualTasks: MyVirtualTask;
    myFilterVirtualTasks:MyVirtualTask

}

export const initialMyVirtualTaskState: MyVirtualTaskState = {
    myVirtualTasks: null,
    myFilterVirtualTasks:null
};