import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { routeMasterAPIs } from '../packet-route-master/state/packet-route-master.data';
import { ModalController } from '@ionic/angular';
import { RouteCriteriaComponent } from '../route-criteria/route-criteria.component';
import { ConfigService } from '../services/config/config.service';

@Component({
  selector: 'app-lock-criteria-master',
  templateUrl: './lock-criteria-master.component.html',
  styleUrls: ['./lock-criteria-master.component.scss'],
})
export class LockCriteriaMasterComponent implements OnInit {
  public currentView: string = "lockMaster";

  public columns: any = [
    { name: "Action", prop: "actionName" },
    { name: "Criteria", prop: "criteriaValue" },
  ];
  public lockCriteriaMasterData: any[];
  public page: string = 'Lock Master';
  public pageSize: number = 20;
  dropDownActions: any = {
    name: 'Actions',
    placeText: 'Select Action',
    options: [],
    selectedData: [],
    propertName: 'actionName',
    modeFlag: 'single',
    isLoaded: false,
  };
  actionsRows:any[] = [
    {
      action: [],
      criteria: [],
    }
  ];
  isEdit:boolean = false;
  constructor(
    private httpService: HttpService,
    private modalCtrl: ModalController,
    private _ConfigService: ConfigService,
  ) { }

  ngOnInit() {
    this.setTableData();
  }

  setTableData(){
    this.httpService.getLockCriterias().subscribe((res:any)=>{
      this.lockCriteriaMasterData = (res?.data?.map((dt:any)=>{return {...dt,criteria:JSON.parse(dt.permissionCriterion),criteriaValue:'Criteria'}}) || []);
    });
  }

  async openLockMasterForm(){
    this.currentView = 'lockMasterForm';
    if(!this.dropDownActions.isLoaded){
      try {
        this.dropDownActions.options = (await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getActionsEndPoint))?.data || [];
        this.dropDownActions.isLoaded = true; 
      } catch (error) {
        this.dropDownActions.isLoaded = false;
      }
    }
  }

  backToLockMaster(){
    this.dropDownActions.selectedData = [];
    this.actionsRows = [
      {
        action: [],
        criteria: [],
      }
    ];
    this.currentView = 'lockMaster';
    this.isEdit = false;
  }

  actionDropDownSelection(event: any, index: number){
    this.actionsRows[index]['action'] = (event?.arrayList || []);
  }


  async createNewOrEditOldCriteria(from: string, index: number) {
    if (from === 'new') {
      const modal = await this.modalCtrl.create({
        component: RouteCriteriaComponent,
        cssClass: 'routeCriteria',
        componentProps: {
          message: 'newCriteria',
          resultCriterionData: null,
        },
      });
      modal.onDidDismiss().then((data) => {
        if(data?.data)
        this.actionsRows[index].criteria = data.data;
      });
      return await modal.present();

    } else {
      const modal = await this.modalCtrl.create({
        component: RouteCriteriaComponent,
        cssClass: 'routeCriteria',
        componentProps: {
          message: 'editCriteria', resultCriterionData: (this.actionsRows?.[index]?.criteria || [])
        },
      });
      modal.onDidDismiss().then((data) => {
        if(data?.data)
        this.actionsRows[index].criteria = (data.data);
      });
      return await modal.present();
    }
  }

  async showCriteria(event: any){
    const modal = await this.modalCtrl.create({
      component: RouteCriteriaComponent,
      cssClass: 'routeCriteria',
      componentProps: {
        message: 'editCriteria', 
        resultCriterionData: (event?.row?.data?.criteria?.tableCriteriaData || []),
        hideParameterRow: true,
        hideActionCol: true,
        hideSubInput: true,
      },
    });
    await modal.present();
  }

  actionRowChange(index: number){
    if(this.actionsRows.length == (index+1))
    this.actionsRows.push({
      action: [],
      criteria: [],
    });
    else
    this.actionsRows.splice(index, 1);
  }

  save(){
    const payload = [];
    this.actionsRows.map((actionRow : any) => {
      if(!actionRow?.action?.[0]?.id || !actionRow?.criteria?.length)
      return;
      let criteria = {
        tableCriteriaData: actionRow.criteria,
      };
      actionRow.criteria.map((rowCriteria : any) => {
        const { paramNameId, id, ...paramValue } = rowCriteria.paramValue;
        criteria[rowCriteria.operator] = {
          ...(criteria?.[rowCriteria.operator] || {}),
          [rowCriteria.paramName]: paramValue
        };
      });
      payload.push(
        {
          actionId: actionRow.action?.[0]?.id,
          [this.isEdit ? 'permissionCriterion' : 'criteria'] : (this.isEdit ? JSON.stringify(criteria) : criteria),
        }
      );
    });
    if(!payload.length)
    return;
    this.httpService.saveLockCriteria((this.isEdit ? payload[0] : payload), this.isEdit, (this.actionsRows?.[0]?.lockCriteriaId || '')).subscribe((res: any) => {
      if(res.statusCode == 200){
        this._ConfigService.showToast("success", (res?.message || 'Saved successfully!'));
        this.setTableData();
        this.backToLockMaster();
      }
      else
      this._ConfigService.showToast("error", (res?.message || 'Something went wrong'));
    });
  }

  openEdit(event: any){
    const criteriaData = event?.row?.data?.criteria;
    this.actionsRows = [];
    this.actionsRows.push(
      {
        lockCriteriaId: event?.row?.data?.id,
        action: [{
          id: event?.row?.data?.actionId,
          name: event?.row?.data?.actionName,
        }],
        criteria: (criteriaData?.tableCriteriaData || []),
      }
    );
    this.dropDownActions.selectedData = [{
      id: event?.row?.data?.actionId,
      actionName: event?.row?.data?.actionName,
    }];
    this.isEdit = true;
    this.openLockMasterForm();
  }

  delete(event: any){
    if(event?.row?.data?.id)
    this.httpService.deleteLockCriteria(event.row.data.id).subscribe((res:any)=>{
      if(res.statusCode == 200){
        this._ConfigService.showToast("success", (res?.message || 'Saved successfully!'));
        this.setTableData();
      }
      else
      this._ConfigService.showToast("error", (res?.message || 'Something went wrong'));
    });
  }

}
