import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimalvalues'
})
export class twoDecimalvaluesPipe implements PipeTransform {

    transform(number: any): any {
        if(!number)
        return number;

        let temp = String(number);
        let s = temp?.split(".");
    
        if(s?.length > 1)
        return `${s[0]}${s.length > 1 ? "." + s[1].substring(0, 2) : ""}`;
        else
        return number;
    }
}
