import { Component, OnInit } from '@angular/core';
import { PacketInwardService } from '../services/packet-inward.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-lot-allocation',
  templateUrl: './lot-allocation.component.html',
  styleUrls: ['./lot-allocation.component.scss'],
})
export class LotAllocationComponent implements OnInit {

  columns = [
    { name: "Lot Number", prop: "lotNo" },
    {name: "Lot Priority", prop:"priority"},
    { name: "Status", prop: "status", columnTemplate: 'chipTemplate' }
  ];
  mode: string = 'single';
  page = "Lot Allocation";
  tableData: any = [];
  usersData: any = [];
  pageSize = 50;
  isEdit: boolean = false;
  viewOnly: boolean = false;
  noOfPlanningOptions: any[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
  ];
  planningTypeOptions: any[] = [
    'Any Of',
    'Fix',
  ];
  priorityTypeOptions: any[] = [
    'Regular',
    'High',
  ];
  companyOptions: any[] = [
    'kps',
    'kpsichapur',
    'tieadiamond',
    'tieaenterprise',
    'vardhan',
  ];
  allocateUserOptions: any[] = [
    'SBS',
    'AM',
    'HOD',
    'CH',
  ];
  prioritySelectedData;
  selectedTab: string = 'controls';
  formData:any = {
    "lotno" : "123a",
    "isVirtual" : -1, // 1 for virtual and 0 for physical 
    "totNoOfPlan" : '',
    'lotPriority':['Regular'], // 0 by default for regular
    "planDetails" : [
       {
          "companyId" : '', 
          "type" : '', // 1 for fix 2 for any 
          "noOfPlans" : '1',
       }
    ],
    "packetDetails" : [
        // {
        //    "paramName" : "qcCheck",
        //    "paramValue" : 0 // 0 for false 1 for true
        // }
    ],
    "lotApproval" : [
     
   ]
  };
  lotApprovalForm:any = {
    "fromSize" : '',
    "toSize" : '',
    "virtaulApproval" : '',
    "fromPriceRange" : '',
    "toPriceRange" : '',
  };
  editLotApprovalFormId:number = -1;
  planDetails:any=[];
  radioDetails:any={};

  constructor(
    private packetInwardService: PacketInwardService,
    private _ConfigService: ConfigService,
    public router: Router,
    public httpService: HttpService,
    public configService:ConfigService,
    public alertController:AlertController,
    private loaderService: LoaderService
    ) { }

  async ngOnInit() {
    await this.packetInwardService.loadToken();
    this.getLotsToAllocate();
    this.getUsersData();
    
  }

  async getLotsToAllocate(){
    
    this.tableData = (
      (await this.packetInwardService.getLotsToAllocate())?.data?.map((dt:any)=> {
        
        return {...dt, status: (dt?.status == 0 ? 'Pending' : 'Completed'),priority:(dt?.lotPriority == 0 ? 'Regular' : 'High') };
      })
      // .sort((a:any,b:any)=>(Date.parse(b.createdDate)-Date.parse(a.createdDate)))
      || []);
      
  }

  async getUsersData(){
    this.usersData = (await this.packetInwardService.getVirtualSuperCheckerAndPhyscialChecker())?.data || {};
  }

  rowSelected(event){
    this.getCompanies(event);
    this.isEdit = true;
    this.formData.lotno = (event?.row?.lotNo || '');

    if(event?.row?.status == 'Completed')
    this.viewOnly = true;
  }

  async getLotAllocationValues(row: any){
    const res = await this.packetInwardService.getLotAllocationValues({lotNo: row?.lotNo});
    if(!res?.data)
    return;
    this.formData = {
      ...this.formData,
      "isVirtual" : res?.data?.isVirtual || '',
      "lotPriority": ((res?.data?.lotPriority === 0) ? ["Regular"] : ["High"]) || '',
      "totNoOfPlan" : res?.data?.noOfPlans || '',
      "planDetails" : res?.data?.lotAllocation || [],
      "packetDetails" : res?.data?.details || [],
      "lotApproval":res?.data?.lotApproval
    };

    res?.data?.lotAllocation?.map((dt:any, index: number)=>{
      if(!this.planDetails?.[index])
      this.planDetails[index] = {};

      this.planDetails[index]['type'] = [dt.type == 1 ? 'Fix' : 'Any Of'];

      const selectedCompany = this.companyOptions.find((co:any) => (co.id == dt.companyId));
      this.planDetails[index]['companyId'] = selectedCompany ? [selectedCompany] : null;
    });

    res?.data?.details?.map((dt:any) => {
      this.radioDetails[dt.paramName] = Number(dt?.paramValue || '');
    });
  }
  
  segmentChanged(event:any){
    this.selectedTab = event.detail.value;
  }

  addPlanDetailRow(){
    this.formData.planDetails.push(
      {
         "companyId" : '',
         "type" : '',
         "noOfPlans" : '1'
      }
    );
  }

  deletePlanDetailRow(i: number){
    if(this.formData.planDetails?.length>1)
    this.formData.planDetails.splice(i, 1);
    if(this.planDetails?.[i])
    this.planDetails.splice(i, 1);
  }

  dropDownSelection(event:any, index:any, parameter:any){
    if(!event?.arrayList?.[0])
    return;
    
    if(parameter == 'totNoOfPlan'){
      this.formData.totNoOfPlan = event.arrayList[0];
      return;
    }

    if(!this.planDetails?.[index])
    this.planDetails[index] = {[parameter]:''};
    this.planDetails[index][parameter] = event.arrayList;

    if(parameter == 'type')
    event.arrayList = (event?.arrayList?.[0] == 'Fix') ? [1] : [2];

    if(parameter == 'priority'){
      console.log(event.arrayList);
      this.formData.lotPriority = event.arrayList;
      if(this.viewOnly===true){
        this.updatePriority(this.formData.lotno)
      }
      return;
    }
    if(parameter == 'companyId')
    event.arrayList = [event?.arrayList?.[0]?.id];
    
    this.formData.planDetails[index][parameter] = event.arrayList[0];
    console.log(this.formData.planDetails);
    
  }

  async updatePriority(lotnum) {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to update the lot Priority?',
      cssClass: 'planAlertBox',
      buttons: [{
        text: 'No',
        handler: (d: any) => {
          this.formData.lotPriority = this.formData.lotPriority[0] === 'High' ? ['Regular'] : ['High'];
        }
      },
      {
        text: 'Yes',
        handler: (d: any) => {
      console.log(lotnum);
      this.isEdit=false;
      this.updateLotPriority(lotnum);
        }
      }]
    });
    await alert.present();
  }

 async updateLotPriority(lotnum){
  if(!!lotnum){
    var payload = {
      "lotNo" : lotnum,
      "lotPriority" : this.formData.lotPriority?.length ? ((this.formData.lotPriority[0] === "Regular") ? 0 : 1) : ''
    }
  }
    let response = await this.httpService.postDataUsingPromise(
      true,
      'packet/updatePriorityLotWise',
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.isEdit=false;
      let data = response.message;
      this.configService.showToast('success', data);
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  }
  radioChange(event:any, paramName: string){
    if(this.formData.packetDetails.filter((dt:any)=>dt.paramName==paramName)?.length > 0){
      let objIndex = this.formData.packetDetails.findIndex((obj:any)=>{return obj.paramName===paramName});
      this.formData.packetDetails[objIndex].paramValue=event.detail.value;
    }else{
      this.formData.packetDetails.push({'paramName':paramName,'paramValue':event.detail.value});
    }
    this.radioDetails[paramName]=event.detail.value;
  }

  isCompanyLoaded: boolean = false;
  async getCompanies(event? : any){
    if(!this.isCompanyLoaded)
    this.companyOptions = ((await this.packetInwardService.getCompanies())?.data || []);
    this.isCompanyLoaded = true;

    if(event?.row?.status == 'Completed')
    this.getLotAllocationValues(event.row);
  }

  async save(){
    this.formData.lotPriority = this.formData.lotPriority?.length ? ((this.formData.lotPriority[0] === "Regular") ? 0 : 1) : this.formData.lotPriority;
    console.log(this.formData);
    const res = await this.packetInwardService.allocateLot(this.formData);
    if(res?.statusCode==200){
      this._ConfigService.showToast("success", (res.message || 'Saved successfully'));
      // this.router.navigate(['/home']);
      this.cancel();
    } else
    this._ConfigService.showToast("error", (res?.message || 'Something went wrong'));
  }

  cancel(){
    this.isEdit = false;
    this.formData = {
      "lotno" : "",
      "isVirtual" : -1,
      "lotPriority":"0",
      "totNoOfPlan" : '',
      "planDetails" : [
         {
            "companyId" : '', 
            "type" : '',
            "noOfPlans" : '1',

            
         }
      ],
      "packetDetails" : [],
    };
    this.radioDetails={};
    this.planDetails=[];
    this.viewOnly = false;
    this.selectedTab = 'controls';
    this.getLotsToAllocate();
  }

  addLotApproval(){
    if(this.editLotApprovalFormId > -1){
      this.formData['lotApproval'][this.editLotApprovalFormId] = this.lotApprovalForm;
      this.editLotApprovalFormId = -1;
    } else

    this.formData['lotApproval'].push(this.lotApprovalForm);
    this.lotApprovalForm = {
      ...this.lotApprovalForm,
      "fromSize" : '',
      "toSize" : '',
      "virtaulApproval" : null,
      // "fromPriceRange" : '',
      // "toPriceRange" : '',
    };
  
  }

  editLotApproval(index:any){
    this.lotApprovalForm = this.formData['lotApproval'][index];
    this.editLotApprovalFormId = index;

  }

  deleteLotApproval(index:any){
    this.formData['lotApproval'].splice(index,1);
  }

  getName(input:any, array:any, inputKey:any, outputKey:any){
    return array.find((dt:any)=>dt[inputKey]==input)?.[outputKey] || '';
  }

  getNumber(text:string){
    return Number(text);
  }
}
