import { Component, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavController, PopoverController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { select, Store } from "@ngrx/store";
import { DxDataGridComponent } from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import * as moment from "moment";
import { Observable, Subject, Subscription } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { actions, authUser, getIfUserIsManager } from "src/app/authStore/auth.selector";
import { HomeActions } from "src/app/home/state/home.action-type";
import { getAllRolesOfUser, getPacketParameterHistoryData, getParamsValuesListByName } from "src/app/masterDataStore/masterData.selector";
//import { ReceivedStones } from 'src/app/models/incoming-stone.models';
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { saveCurrentRowData } from "src/app/tempDataStore/tempData.action";
import { tempDataActions } from "src/app/tempDataStore/tempData.action-type";
import { getCurrentSearchData } from "src/app/tempDataStore/tempData.selector";
import { StaticInfo, environment } from "src/environments/environment";
import { printData } from "../../../masterDataStore/master.data";
import { ProcessActionComponent } from "../../processAction";
import { ScannerinputComponent } from "../../scannerinput/scannerinput.component";
import { TrybemodalComponent } from "../../trybemodal/trybemodal.component";
import { IssueAction } from "./state/issue.action.type";
import { getActionsByPacketData, getStartFromData, getUsersByActionData } from "./state/issue.selector";
import { ResetordermodalComponent } from "src/app/resetordermodal/resetordermodal.component";
import { MenuController } from "@ionic/angular";
import { LoadOptions } from "devextreme/data";
import { FileDownloadComponent } from "src/app/file-download/file-download.component";
import { MultipleBarcodeScannerComponent } from "../../multiple-barcode-scanner/multiple-barcode-scanner.component";
import { TrybeCheckerComponent } from "../../trybe-checker/trybe-checker.component";
import { PlanningGridComponent } from "../../planning-grid/planning-grid.component";
import { getFinalPlanData } from "../../trybe-checker/state/checker.selector";
import { getReceivedPacketsData, getReceivedPacketsDataLen } from "src/app/home/state/home.selector";
import { FinalInspectionComponent } from "../../final-inspection/final-inspection.component";

@Component({
  selector: "app-issue",
  templateUrl: "./issue.component.html",
  styleUrls: ["./issue.component.scss"],
})
export class IssueComponent implements ProcessActionComponent {
  @ViewChild("auto") auto;

  @Input() data: any;
  @Input() actionName: any;
  @Input() toRole: any;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  columns = [];
  loggedinUser$: Observable<any>;
  //recStoneData$: Observable<ReceivedStones[]>;
  allMode: string;
  checkBoxesMode: string;
  @ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;
  dataSource: any = [];
  modifiedDataSource: any;
  totalWitMeStonesCount: number = 0;
  masterData = [];
  listOfActions = [];
  selectedRows: any = [];
  listOfActionsByStone: any = [];
  private unsubscribe$: Subject<any> = new Subject<any>();
  isStoneSelected: boolean = false;
  startWith: string;
  actionUsers: any = [];
  actionId: number;
  selectedAction: any;
  // actionName:any;
  userId: number;
  machineId: number;
  messageTab: boolean = false;
  deptId: any;
  test: boolean = true;
  machinesByUser: any = [];
  userMachines: any = [];
  currSelectedMachine: any = [];
  sendData: any = {};
  initialValue = "";

  roleTab: boolean = false;
  isProcessVirtual: boolean = false;
  public selectedUser: string = "";
  public keyword = "fullName";

  public selectedRole: string = "";
  public roleKey = "roleName";
  public selectedRoleObj: any;
  pageSize = 10;
  page = "Issue";
  isModal$: Observable<any>;

  selectedPacketIds = [];
  dataFromModal = {};
  barcodeInputs: any = "";
  barcodeInputList: string[] = [];
  rowsIndex: any;
  isConfirm: boolean = true;
  scanned: boolean;
  refreshGrid: boolean = false;
  clearData: string = "";
  openModalActionId: any;
  selectedRow: any = {};
  status: string;
  showSubmit: boolean = true;
  showRemoveButton: boolean = false;
  refreshField: boolean = false;
  resetForm: boolean = false;
  showDrawer: boolean = false;
  showProcessForm: boolean = false;
  isHistory: boolean = false;
  formJson: any;
  formJsonHist: any;
  openmodal: boolean = false;
  reloadForm: boolean = false;
  fromJsonInputs: any;
  paramSelectionList: any = {};
  private subscriptions: Subscription[] = [];
  processNotes: any = {};
  selectedOption = "msg";
  actionOfUser: string = "-";
  actionInfo: any;
  packetArray: [];
  proActionName: string = "";
  dataLoaded = false;
  processNote: string = "";
  processTitle: string = "Process";
  isManager: boolean;
  currUserId: any;
  searchedPageName: string = "";
  currActionInfo: any;
  defaultSelection: string = "User";
  isVirtualProcess: boolean = false;
  planning: any;
  roles;

  showProcessTab: boolean = false;
  isSigner: boolean = false;
  currAction: any;
  currProcess: any;
  roleDropDown: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: [],
    propertName: "roleName",
    modeFlag: "single", //single/multiple
  };
  currentUserFullName: string = "";
  public withMeGridPageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  pageNumber: number = 0;
  selectedSubManager: any;
  packetId: any;
  filterPayload: any = { filter: {} };
  isDynamicFormShown: boolean = false;
  currentUserDept: any = "";
  subManagerDatas: any;
  ischecked: boolean;
  printData: any = printData;
  infoToPrint: Object = {};
  Id: any;
  fdata: any;
  id: any;
  packetSelectedForRoute!: any;
  userDataForFullProcess: any;
  is41FinalFullProcessManager: boolean = false;
  weightLoss: any;
  weightLossActionInfo: any;
  payload: { packetId: any };
  isCsv: boolean = false;
  showCheckerFinal: boolean = true;
  finalPacketPlans: any;
  isDisabled: boolean = false;
  packetCounts: any;
  finaInspectionData: any;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private loaderService: LoaderService,
    private navCtrl: NavController,
    public popoverController: PopoverController,
    private storage: Storage,
    private menuCtrl: MenuController
  ) {
    // this.isModal$ = false;
    // debugger
    // this.subscriptions.push(this.store.pipe(select(getReceivedPacketsData)).subscribe(data => {
    //   if (data) {
    //     this.dataSource = data;
    //     this.masterData = data;
    //   }
    // }));
    this.allMode = "allPages";
    this.columns = [
      { name: "Lot No", prop: "lotNo", columnTemplate: "lot-svg-template" },
      { name: "barcode No", prop: "barcodeNo" },
      { name: "packet Num", prop: "pktNo" },
      { name: "Packet ID", prop: "packetId" },
      { name: "IssueDate", prop: "test" },
      { name: "Cts", prop: "cts" },
      { name: "Grade", prop: "grade" },
      { name: "Current Action", prop: "currActionName" },
      { name: "recievedOn", prop: "receivedOn" },
      { name: "Last Action", prop: "lastActionName" },
      { name: "Last Action status", prop: "lastActionStatus" },
      { name: "Owner", prop: "owner" },
      // { name: "InwardDate", prop: "inDate" },
      // { name: "CreatedDate", prop: "createdDate" },
      // { name: "Location", prop: "location" }
    ];
    // this.displayHistory = this.displayHistory.bind(this);
  }

  ngOnInit() {
    // this.fetchallSubManager();
    this.infoToPrint = {
      company: StaticInfo["company"], //Static Name
      address: StaticInfo["address"], //Static Address
      issueFor: null,
      issueDate: null, //Jangad Date
      issueNo: null, //Jnd No,
      stoneType: StaticInfo["stoneType"],
      senderNotes: StaticInfo["senderNotes"],
      processRemark: StaticInfo["processRemark"],
      subProcessRemark: StaticInfo["subProcessRemark"],
      data: {},
    };

    this.store
      .pipe(select(actions))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data) {
          this.listOfActions = data;
        }
      });
    // this.subscriptions.push(
    //   this.store
    //     .pipe(select(getCurrentAction))
    //     .pipe(
    //       filter((data) => !!data),
    //       first()
    //     )
    //     .subscribe((data: any) => {
    //       if (data) {
    //         console.log(data);
    //         this.status = data.status;
    //         this.actionId = data.id;
    //         this.actionName = data.name;
    //         this.actionInfo = data;
    //         if (data.actionForm) {
    //           let formDataObject = JSON.parse(data.actionForm);
    //           let parsedFormData: any = formDataObject.form;
    //           //this.formJson = parsedFormData
    //           this.fromJsonInputs = formDataObject.inputJson;
    //           let fdata = parsedFormData.map((field) => {
    //             if (field.type == 'select' || 'searchSelect') {
    //               let paramName = field.key;
    //               console.log(paramName);
    //               this.subscriptions.push(
    //                 this.store
    //                   .pipe(select(getParamsValuesListByName(paramName)))
    //                   .subscribe((data) => {
    //                     if (data) {
    //                       console.log('getParamsValuesListByName');
    //                       this.paramSelectionList[paramName] = data;
    //                       console.log(data);
    //                       let newData = data.map((x) => {
    //                         let newobj = {
    //                           label: x.name,
    //                           value: x.id,
    //                         };
    //                         return newobj;
    //                       });
    //                       field.templateOptions.options = newData;
    //                     }
    //                   })
    //               );
    //             }
    //             return field;
    //           });
    //           this.formJson = fdata;
    //         }
    //       }
    //     })
    // );

    this.store.pipe(select(authUser)).subscribe((user: any) => {
      if (!!user) {
        if (user.roles) {
          this.roles = user.roles.join();
        }
        if (user.listOfRoles) {
          this.roles = user.listOfRoles
            .map(d => {
              return d.roleName;
            })
            .join();
        }
        console.log(user);
        this.currUserId = user.id || user.userId;
        console.log(this.currUserId);
        console.log(user.listOfActions);
        if (user.roles.includes("Central Stock Controller")) {
          this.isCsv = true;
        }
        user.listOfActions.forEach(item => {
          if (item?.actionName === "Loss Weight") {
            if (user.roles.includes("41 Final Full Process Manager") || user.roles.includes("Full Process Manager") || user.roles.includes("Full Process Operator")) {
              this.is41FinalFullProcessManager = true;
            }
            this.weightLossActionInfo = item;
          }
        });
        if (user.listOfActions) {
          this.planning = user.listOfActions.filter((r: any) => {
            return r.actionName == "Planning";
          });
        }
        console.log(this.actionId);
        this.openModalActionId = user.listOfActions;
        this.currentUserFullName = user.fullName;
        console.log(this.infoToPrint);
        this.infoToPrint["fromUser"] = user.fullName ?? "";
        this.infoToPrint["fromDept"] = user.departmentName ?? "";
        this.currentUserDept = user.departmentName ?? "";
        this.infoToPrint["fromCompany"] = user.companyName ?? "";
        this.infoToPrint["company"] = environment.fromLocation ?? user.companyName;
        this.infoToPrint["issueFor"] = `${user.departmentName ?? "-"} Issue`;
      }
    });
    this.store
      .pipe(select(getIfUserIsManager))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        console.log(data);
        this.isManager = data;
      });
    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentSearchData))
        .pipe(
          filter(d => !!d),
          first()
        )
        .subscribe((d: any) => {
          if (d) {
            this.searchedPageName = d.name;
            this.actionInfo = d;
          }
        })
    );

    this.store
      .pipe(select(getReceivedPacketsDataLen))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        console.log(data);
        this.packetCounts = data;
      });

    this.subscriptions.push(
      this.store.pipe(select(getReceivedPacketsData)).subscribe(data => {
        if (data) {
          console.log(data);
          this.dataSource = data;
          this.dataLoaded = true;
          this.masterData = data;
        }
      })
    );
    // this.getFreshWithMeGridDataAndSetPaginationToGrid();
    this.httpService.currentWithMeGridData$.subscribe(data => {
      if (data) {
        let checkNull = Object.prototype.toString.call(null).toLowerCase();
        this.dataSource = data.packetData;
        this.dataLoaded = true;
        this.masterData = data.packetData;
        this.totalWitMeStonesCount = data.countOfTotalPackets;
      }
    });
    // console.log(this);
    // console.log(this.dataGrid);

    // let grid = this.dataGrid.instance;
    // new ResizeObserver((entries: any) => {
    //   grid.updateDimensions();
    // }).observe(grid.element());
    this.loggedinUser$ = this.store.pipe(select(authUser));
    this.populateActions();
    this.populateUsers();
    this.subscriptions.push(
      this.store.pipe(select(getStartFromData)).subscribe(d => {
        if (d) {
          this.startWith = d;
          console.log(this.startWith);
        }
      })
    );
    // this.subscriptions.push(
    //   this.store.pipe(select(getAllRolesOfUser)).subscribe(data => {
    //     if (data) {
    //       this.roleDropDown["options"] = data;
    //       console.log(this.roleDropDown["options"]);
    //     }
    //   })
    // );
    this.isSigner = this.roles.includes("Signer");
    console.log(this.isSigner);
  }

  showRoute($event: any) {
    this.packetSelectedForRoute = $event.row.data;
    console.log(this.packetSelectedForRoute);
    this.openDrawerNew();
  }

  openDrawerNew() {
    this.menuCtrl.enable(true, "drawer");
    this.menuCtrl.open("drawer");
  }

  closeDrawer($event: any) {
    this.menuCtrl.close("drawer");
  }

  async orderForm(ev) {
    console.log(ev);
    const resetOrder = await this.modalCtrl.create({
      component: ResetordermodalComponent,
      cssClass: "restorePage",
    });

    resetOrder.onDidDismiss().then((d: any) => {});
    return await resetOrder.present();
  }
  async packetScanned(barcode) {
    this.barcodeInputs = !!barcode ? barcode.trim() : null;
    this.withMeGridPageInfo = {
      limit: 10,
      page: 0,
    };
    // let dataArray: [] = this.barcodeInputs.split(',');
    // //let selectedDataArray = this.dataSource.fin
    // this.rowsIndex = [];
    // for (let i = 0; i < dataArray.length; i++) {
    //   let el: any = dataArray[i];
    //   let index: number = this.dataSource.findIndex(item => item.packetId == el.trim());
    //   this.rowsIndex.push(index);
    //   if (index > -1) {
    //     this.selectedRows.push(this.dataSource[index])
    //   }
    // }
    this.getFreshWithMeGridDataAndSetPaginationToGrid();
    this.scanned = true;
    setTimeout(() => {
      this.scanned = false;
    }, 1000);
  }

  cancel() {
    this.isDisabled = false;
    this.payload = null;
    this.scannerComponet.onResetForm();
    this.barcodeInputs = "";
    this.selectedRows = [];
    this.rowsIndex = [];
    this.barcodeInputList = [];
    this.modifiedDataSource = [];
    // this.getFreshWithMeGridDataAndSetPaginationToGrid();
    this.refreshGrid = true;
    this.isStoneSelected = false;
    // this.dataSource = this.masterData;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }
  async getFreshWithMeGridDataAndSetPaginationToGrid() {
    console.log("getFreshWithMeGridDataAndSetPaginationToGrid-WithMe Grid");

    let payload = null;
    if (!!this.barcodeInputs) {
      this.payload = { packetId: this.barcodeInputs.trim() }; //toFiltter grid Data by barcodeInputs
      console.log(payload);
    } else if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
      console.log(this.barcodeInputList);
      this.payload = { packetId: this.barcodeInputList };
    }
    this.modifiedDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        let dataToFilter: any = [];
        if (!!loadOptions.filter) {
          ["filter"].forEach(function (i) {
            const optionValue = loadOptions[i as keyof LoadOptions];
            if (i in loadOptions) {
              console.log(loadOptions[i]);
              dataToFilter.push(loadOptions[i]);
              console.log(dataToFilter);
            }
          });
          if (Array.isArray(dataToFilter[0][0])) {
            dataToFilter[0].forEach((info, index) => {
              if (index % 2 === 0) {
                this.filterPayload.filter[info[0]] = info[2];
              }
            });
          } else {
            this.filterPayload.filter = {
              [dataToFilter[0][0]]: dataToFilter[0][2],
            };
          }
          console.log(this.filterPayload);
        } else {
          this.filterPayload = {};
        }
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.withMeGridPageInfo["limit"];
          } else {
            this.withMeGridPageInfo["limit"] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.withMeGridPageInfo["page"] = this.pageNumber;
          console.log(this.filterPayload);
          if (!!Object.keys(this.filterPayload).length) {
            payload = this.filterPayload;
          } else if (!!this.barcodeInputList) {
            payload = this.payload;
          } else {
            payload = null;
          }

          console.log(payload);
          let response: any = await this.httpService.fetchWithMeGridData(this.withMeGridPageInfo, payload);
          console.log(payload);
          this.httpService.getErrorAndDisplayIt(response);
          if (response.statusCode === 200) {
            if (this.barcodeInputList.length >= 0 && response.data?.packetData?.length < this.barcodeInputList.length) {
              this.configService.showToast("error", "Packet Not Found ");
            }
            this.isDisabled = true;
            this.httpService.updateWithMeGridData(response.data);
          } else {
            console.error(response);
          }

          if (this.barcodeInputList && this.barcodeInputList.length > 0) {
            this.scanned = true;
            this.rowsIndex = this.dataSource.map((d, index: number) => index);
            setTimeout(() => {
              this.scanned = false;
            }, 1000);
          }
          return {
            data: Query(this.dataSource).toArray(),
            totalCount: this.totalWitMeStonesCount,
            groupCount: this.totalWitMeStonesCount,
          };
        } else {
          if (this.barcodeInputList && this.barcodeInputList.length > 0) {
            this.scanned = true;
            this.rowsIndex = this.dataSource.map((d, index: number) => index);
            setTimeout(() => {
              this.scanned = false;
            }, 1000);
          }
          return {
            data: this.dataSource,
            totalCount: this.totalWitMeStonesCount,
            groupCount: this.totalWitMeStonesCount,
          };
        }
      },
    });
  }
  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.withMeGridPageInfo["limit"] = size;
        this.getFreshWithMeGridDataAndSetPaginationToGrid();
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.withMeGridPageInfo["page"] = pageIndex;
        this.getFreshWithMeGridDataAndSetPaginationToGrid();
        break;
    }
  }
  async selectAction(currActionObj: any) {
    if (!!currActionObj && currActionObj.isVirtual == 1) {
      this.isVirtualProcess = true;
    }
    let actionId: any = !!currActionObj ? currActionObj.id : null,
      isActionVirtual: any = !!currActionObj ? currActionObj.isVirtual : null;
    //actionId
    this.openmodal = false;
    if (!!actionId) {
      let x = this.openModalActionId.filter((element: any) => {
        return element.id == actionId;
      });
      console.log(x);
      if (!!x && x.length > 0) {
        this.currProcess = x[0].actionName;
        console.log(this.currProcess);
      }

      if (x.length > 0) {
        this.openmodal = true;
      } else {
        this.openmodal = false;
      }
      this.formjsonForDynamicForm(x);
    }
    this.machinesByUser = [];
    this.actionId = actionId;
    this.listOfActionsByStone = this.listOfActionsByStone.map(item => {
      let l = { ...item };
      if (!!l.hasOwnProperty("isSelected") && l.id == actionId && l.isVirtual == isActionVirtual) {
        this.roleDropDownOptions(actionId);
        l.isSelected = true;
      } else {
        l.isSelected = false;
      }
      return l;
    });
    this.selectedAction = this.listOfActionsByStone.filter(item => !!item.isSelected && item.id == actionId)[0];
    if (!!this.selectedAction) {
      this.isVirtualProcess = this.selectedAction.isVirtual === 1 ? true : false;
      // if (this.selectedAction.isVirtual === 1) {
      //   this.defaultSelection = "Role";
      // } else {
      //   this.defaultSelection = "User";
      // }
    } else {
      this.isVirtualProcess = false;
    }
    let actionTitle = !!this.selectedAction && this.selectedAction.actionName ? this.selectedAction.actionName : "-";
    this.infoToPrint["currentAction"] = this.selectedAction;
    this.infoToPrint["processRemark"] = `${actionTitle}`;

    console.log("this.selectedAction");
    console.log(this.selectedAction);
    console.log(currActionObj);
    console.log(actionTitle);
    let pktIds = this.selectedRows.map(x => x.id);
    let packets = {
      listOfPacketIds: pktIds,
    };
    if (!!actionId && this.startWith != "Person") {
      this.selectedUser = "";
      this.store.dispatch(
        IssueAction.getUsersByAction({
          actionId: actionId,
          packetIDs: packets,
          isVirtual: this.selectedAction.isVirtual,
        })
      );
    }
    this.showProcessNotes(actionId);
    console.log("%cInProgress", "color:green;font-size:14px");
    console.log(this.isVirtualProcess);
    console.log(this.selectedRow, this.actionId, this.selectedRoleObj, this.userId);
    console.log(this.defaultSelection);
  }

  async roleDropDownOptions(id) {
    if (!!id) {
      let response: any = await this.httpService.fetchDataUsingPromise(true, `tbl-role/action/${id}`);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.roleDropDown["options"] = response.data;
        if (response.data.length > 0 && response.data.length === 1) {
          let selectedData = response.data[0];
          this.selectedRole = selectedData.roleName;
          this.selectRole(response.data[0]);
        } else {
          console.error(response);
        }
      }
    }
  }

  async formjsonForDynamicForm(action: any, status = null) {
    console.warn("goToAction----");
    console.log(this.listOfActions);
    console.log(action);
    console.log(status);
    if (action.length !== 0 && action[0]) {
      let act = action[0];
      let formData = act.hasOwnProperty("listOfForms") && act.listOfForms.length !== 0 ? act.listOfForms[0].formData : null;
      let actionObj = {};
      actionObj = {
        id: act.id,
        name: act.actionName,
        status: "Pending",
        actionForm: formData,
      };
      this.currActionInfo = actionObj;
      console.log(actionObj);
      this.showDynamicFormAsPerJson(actionObj, action);
    }
  }
  async showDynamicFormAsPerJson(data: any, action: any) {
    if (data) {
      console.log("showDynamicFormAsPerJson");
      console.log(data);
      this.status = data.status;
      this.actionId = data.id;
      this.actionName = data.name;
      this.actionInfo = data;
      console.log(this.actionName);

      // adding an option api and code for full process in galad
      if (data.actionForm) {
        if (this.actionName == "Assign Full Process Worker") {
          console.log(action);
          let formDataObject = JSON.parse(action[0].listOfForms[0].formData);
          let parsedFormData: any = formDataObject.form;
          console.log(parsedFormData);
          console.log(formDataObject);
          (await this.getDropDownValueOfUserForFullProcess()).subscribe((res: any) => {
            if (res) {
              console.log("change Status");
              console.log(res);
              let resultCode: number = res.statusCode;
              console.log(resultCode);
              let d: any = res.data;
              console.log(d);
              if (resultCode == 200) {
                this.userDataForFullProcess = res.data;
                console.log(this.userDataForFullProcess);

                if (this.userDataForFullProcess) {
                  console.log(this.userDataForFullProcess);
                  let newData = this.userDataForFullProcess.map(x => {
                    let newobj = {
                      label: x.roleName,
                      value: x.id,
                    };
                    return newobj;
                  });
                  console.log(newData);
                  console.log(action);
                  // console.log(JSON.stringify(newData))
                  //add flur in select options
                  console.log(newData);
                  console.log(formDataObject);

                  formDataObject.form[0].templateOptions.options = newData;
                  console.log(newData);
                  console.log(formDataObject);
                }
              }

              return res.data;
            }
          });
        }
        let formDataObject = JSON.parse(data.actionForm);
        let parsedFormData: any = formDataObject.form;
        //this.formJson = parsedFormData
        this.fromJsonInputs = formDataObject.inputJson;
        if (this.actionName == "Assign Full Process Worker") {
          this.fdata = parsedFormData.map(async field => {
            (await this.getDropDownValueOfUserForFullProcess()).subscribe((res: any) => {
              if (res) {
                console.log("change Status");
                console.log(res);
                let resultCode: number = res.statusCode;
                console.log(resultCode);
                let d: any = res.data;
                console.log(d);
                if (resultCode == 200) {
                  this.userDataForFullProcess = res.data;
                  console.log(this.userDataForFullProcess);

                  if (this.userDataForFullProcess) {
                    console.log(this.userDataForFullProcess);
                    let newData = this.userDataForFullProcess.map(x => {
                      let newobj = {
                        label: x.fullName,
                        value: x.id,
                      };
                      return newobj;
                    });
                    console.log(newData);
                    console.log(action);
                    // console.log(JSON.stringify(newData))
                    //add flur in select options
                    console.log(newData);
                    console.log(formDataObject);

                    formDataObject.form[0].templateOptions.options = newData;
                    console.log(newData);
                    console.log(formDataObject);
                  }
                }

                return res.data;
              }
            });

            return field;
          });
        }

        this.fdata = parsedFormData.map(field => {
          if (field.type == "select" || ("searchSelect" && this.actionName != "Assign Full Process Worker")) {
            let paramName = field.key;
            console.log(paramName);
            this.subscriptions.push(
              this.store.pipe(select(getParamsValuesListByName(paramName))).subscribe(data => {
                if (data) {
                  console.log("getParamsValuesListByName");
                  this.paramSelectionList[paramName] = data;
                  console.log(data);
                  let newData = data.map(x => {
                    let newobj = {
                      label: x.name,
                      value: x.id,
                    };
                    return newobj;
                  });
                  field.templateOptions.options = newData;
                }
              })
            );
          }
          return field;
        });

        this.formJson = this.fdata;
        console.log(this.formJson);
      }
    }
    this.resetForm = true;
    this.reloadForm = true;
    setTimeout(() => {
      this.reloadForm = false;
      this.resetForm = false;
    }, 500);
  }
  async showProcessNotes(actionId: any) {
    console.log("showProcessNotes");
    console.log(actionId);
    this.processNotes = {};
    this.proActionName = "";
    this.processNote = "";
    if (!!actionId) {
      console.log("%cIFFFFF", "color:green;");
      let data = {
        actionId: actionId,
        paramName: "issueParameter",
      };

      let pktIds = this.selectedRows.map(x => x.packetId);
      console.log(pktIds);
      this.processNotes = {};
      this.processNote = "";
      // await this.store.dispatch(IssueAction.getProcessParameter({ data: data, packetIds: pktIds }));
      this.httpService.fetchProcessNoteKeyByActionId(data).subscribe(param => {
        if (!!param) {
          console.log(param);
          console.log(param.data);
          this.httpService.getErrorAndDisplayIt(param);
          if (!!param.data) {
            let res = !!param["data"] && !!param["data"][0] && !!param["data"][0] && !!param["data"][0]["paramValue"] ? param["data"][0]["paramValue"] : "[]";
            let paramKey = JSON.parse(res);

            this.subscriptions.push(
              this.store.pipe(select(getPacketParameterHistoryData)).subscribe(history => {
                if (history) {
                  console.log(".......history......");
                  console.log(param);
                  console.log(history);
                  // this.packetParamHistories = [];
                  this.httpService.getErrorAndDisplayIt(history);
                  let filteredHistory = history.filter(d => d.actionId == 152 || d.actionId == 461);

                  let fh = filteredHistory[0];
                  if (!!fh && !!fh.inputDetails && !!paramKey[0]) {
                    let filteredDetails = fh.inputDetails.filter(m => m.paramName.toLowerCase() == paramKey[0].toLowerCase());
                    let processFilter = fh.inputDetails.filter(m => m.paramName.toLowerCase() == paramKey[1].toLowerCase());
                    console.log(filteredDetails);
                    this.processNotes = {
                      151: {
                        id: 1,
                        actionId: 152,
                        actionName: "Cutting",
                        note: "HP Polaris",
                      },
                      126: {
                        id: 1,
                        actionId: 126,
                        actionName: "Fluorescence",
                        note: "HP Polaris",
                      },
                    };
                    this.proActionName = !!this.processNotes[actionId] && !!this.processNotes[actionId].actionName ? this.processNotes[actionId].actionName : "";
                    this.processNote = !!filteredDetails[0] && !!filteredDetails[0].paramValue ? paramKey[0] + " : " + filteredDetails[0].paramValue : "";
                    let proce = +!!processFilter[0] && !!processFilter[0].paramValue ? " - " + paramKey[1] + " : " + processFilter[0].paramValue : "";
                    this.processNote = this.processNote + proce;
                    this.getUserMachines(filteredDetails[0].paramValue);
                  }
                }
              })
            );
          } else {
            this.processNotes = {};
            this.proActionName = "";
            this.processNote = "";
          }
        }
      });
    } else {
      console.log("%cELSE", "color:green;");
      this.processNotes = {};
      this.proActionName = "";
      this.processNote = "";
    }
  }
  populateUsers() {
    this.subscriptions.push(
      this.store.pipe(select(getUsersByActionData)).subscribe(d => {
        console.log(d);
        if (d) {
          this.actionUsers = !!d && Array.isArray(d) ? JSON.parse(JSON.stringify(d)) : [];
          this.subscriptions.push(
            this.store.pipe(select(getStartFromData)).subscribe(d => {
              if (d) {
                this.startWith = d;
                console.log(this.startWith);
              }
            })
          );
          console.log(this.startWith);
          if (this.startWith === "Person") {
            this.messageTab = true;
            console.log("i am the Person");
          } else if (this.startWith === "Process") {
            this.selectedUser = "";
            this.messageTab = true;
            this.userId = null;
          } else {
            this.selectedUser = "";
            this.userId = null;
          }
          console.log("----------populateUsers-----------");
          console.log(this.actionUsers);
          if (this.actionUsers.length > 0 && this.actionUsers.length === 1) {
            let userData = this.actionUsers[0];
            this.selectedUser = userData.fullName;
            userData["disabled"] = true;
            this.selectEvent(userData);
            this.sendData["toUser"] = userData;
          } else if (this.actionUsers.length > 1) {
            this.actionUsers = this.actionUsers.map(item => {
              item["disabled"] = false;
              if (!item.hasOwnProperty("isSelected")) {
                return Object.assign({}, item, { isSelected: false });
              } else {
                return item;
              }
            });
            let selectedUsers = this.actionUsers.filter(a => a.isSelected == true);
            if (selectedUsers.length == 1) {
              this.selectedUser = selectedUsers[0].fullName;
              this.selectEvent(selectedUsers[0]);
            } else {
              this.selectedUser = "";
            }
          }
          console.log(this.actionUsers);
          console.log(this.selectedUser);
        }
      })
    );
  }
  selectUser(userId) {
    this.actionUsers.map(a => (a.isSelected = false));
    this.userId = userId;
    this.actionUsers.map(a => {
      if (a.id == userId) {
        a.isSelected = true;
      }
      return a;
    });
  }

  customizeColumns(columns) {
    columns[0].width = 20;
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  backIt() {
    console.warn("Back button");
    // this.loc.back();
    //history.back();
    this.navCtrl.pop();
  }

  async logout() {
    this.store.dispatch({ type: "Logout" });
    await this.storage.clear();
    this.httpService.isAuthenticated.next(false);
    this.router.navigateByUrl("/login");
  }
  onSelectionChanged(event) {
    this.messageTab = false;
    this.machinesByUser = [];
    this.selectedSubManager = "";
    this.proActionName = "";
    this.userMachines = [];
    this.selectedOption = "msg";
    this.processNotes = {};
    this.actionId = null;
    this.selectAction(null);
    // this.messageTab=false;
    // if (this.actionUsers.length != 1) {
    //   this.selectedUser = '';
    // }
    // this.formJson=[];
    // console.log(event)
    event.row = event.selectedRowsData[0];
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.selectedRowsData[0];
    this.store.dispatch(saveCurrentRowData({ rowData: [this.selectedRow] }));
    let pktIds = this.selectedRows.map(x => x.id);
    this.packetArray = this.selectedRows.map(x => x.id);
    if (event.selectedRowsData.length > 0) {
      this.currAction = this.selectedRows.map(x => x.currActionName).toString();
      console.log(this.currAction);
      this.isStoneSelected = true;
      this.defaultSelection = "User";
      // this.startWith = this.selectedRow['dataAsPer'];
    } else {
      this.isStoneSelected = false;
      if (this.startWith != "Process") {
        // this.actionUsers = [];
        this.selectedUser = "";
      }
    }

    if (this.startWith == "Process") {
      let obj = {
        listOfPacketIds: pktIds,
      };
      console.log(this.selectedAction);
      console.log(this.selectedAction.isVirtual);
      this.store.dispatch(
        IssueAction.getUsersByAction({
          actionId: this.actionId,
          packetIDs: obj,
          isVirtual: this.selectedAction.isVirtual,
        })
      );
    }
    //changing
    console.log(pktIds);
    this.selectedPacketIds = pktIds;
    this.openmodal = false;
    console.log(this.isStoneSelected);
    console.log("%c" + this.startWith, "color:green;font-size:16px");
    this.processTitle = "Process";
    this.store.dispatch(IssueAction.resetUser());
    if (!this.startWith || this.startWith == "Packet") {
      if (event.selectedRowsData.length > 0) {
        this.isStoneSelected = true;
        this.showProcessNotes(null);
      } else {
        this.isStoneSelected = false;
        // this.actionUsers = [];
        this.selectedUser = "";
        this.selectAction(null);
        this.showProcessNotes(null);
      }
      this.userId = this.isStoneSelected ? this.userId : null;
      if (this.isStoneSelected && pktIds.length !== 0) {
        this.store.dispatch(IssueAction.fetchProcessByPackets({ packetIDs: pktIds }));
        // this.populateActions();
      } else {
        this.store.dispatch(IssueAction.unSelectionOfPacket());
        // this.populateActions();
        this.showProcessNotes(null);
      }
    } else if (this.startWith == "Person") {
      if (this.isStoneSelected && pktIds.length !== 0) {
        this.store.dispatch(IssueAction.fetchProcessByPackets({ packetIDs: pktIds }));
      } else {
        this.store.dispatch(IssueAction.updateActions({ actionsByPacket: [] }));
        this.populateUsers();
        this.showProcessNotes(null);
      }
    }
    // this.populateUsers();
    if (!!this.selectedRow) {
      this.packetId = this.selectedRow.packetId;
      this.Id = this.selectedRow.id;
    }
    console.log(this.selectedRow, this.actionId, this.selectedRoleObj, this.userId);
  }

  onRadiobtnSelection(evt) {
    console.log(evt);
    this.selectedOption = evt.detail.value;
    console.log(this.selectedOption);
  }

  populateActions() {
    console.log("....populateActions....");
    this.subscriptions.push(
      this.store.pipe(select(getActionsByPacketData)).subscribe(data => {
        if (data) {
          let except = ["With Me", "Packet Tracking", "Fluorescence", "Work Quality Master", "Rough Inward"];
          data = data.filter((x: any) => except.indexOf(x.actionName) === -1);
          this.listOfActionsByStone = data;
          console.log(this.listOfActionsByStone);

          if (data.length === 1) {
            this.autoSelectSingleValues(data);
          } else if (data.length > 1) {
            if (this.startWith == "Process") {
              let selectedAction = this.listOfActionsByStone.filter(a => a.isSelected == true);
              console.log(selectedAction);
              if (selectedAction.length === 1) {
                let action = selectedAction[0];
                this.selectAction(action);
              }
            } else {
              this.listOfActionsByStone = data.map(item => {
                if (!item.hasOwnProperty("isSelected")) {
                  return Object.assign({}, item, { isSelected: false });
                } else {
                  return item;
                }
              });
              this.selectAction(null);
            }
          }
        }
      })
    );
  }
  autoSelectSingleValues(data: any) {
    this.listOfActionsByStone = data.map(item => Object.assign({}, item, { isSelected: true }));
    let action = this.listOfActionsByStone[0];
    this.selectAction(action);
  }
  displayHistory(e) {
    this.isHistory = true;
    console.log("-------onHistortyClick-------");
    console.log(e);
    let currentPacketId = e.row.data.id;
    this.selectedRow = e.row.data;
    console.log(currentPacketId);
    console.log(e.row.data.packetId);
    this.store.dispatch(saveCurrentRowData({ rowData: [this.selectedRow] }));
    if (!!currentPacketId) {
      console.log("History found");
      this.showDrawer = false;
      setTimeout(() => {
        this.showDrawer = true;
      }, 500);
    }
  }
  openDrawer(evt: any) {
    this.isDynamicFormShown = true;
    console.log(this.isDynamicFormShown);
  }
  closeDynamicForm() {
    this.isDynamicFormShown = false;
    console.log(this.isDynamicFormShown);

    // this.isStoneSelected = false;
    // this.selectedRow = {};
    // this.rowsIndex = [];
    // this.isStoneSelected = false;
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  customDateTime(data) {
    console.log(data);
    if (!!data.inDate) {
      return moment.utc(data.inDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.outDate) {
      return moment.utc(data.outDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  getShortTitle(str) {
    var newStr = str.split(" ").reduce((result, currentWord) => result + currentWord.charAt(0).toUpperCase(), "");
    return newStr;
  }

  showMachines() {
    if (
      // this.selectedUser &&
      !!this.machinesByUser &&
      this.machinesByUser.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  selectEvent(item: any) {
    // for user
    console.log(item.id);
    console.log(this.currUserId);
    console.log(this.currAction);
    console.log(this.currProcess);
    item.isSelected = false;
    // do something with selected item
    // if(!!item && this.is41FinalFullProcessManager){
    //   this.onAddingWeightLoss();
    // }
    this.userId = item.id;
    this.selectedUser = item.fullName;
    this.machinesByUser = !!item.machineList && Array.isArray(item.machineList) ? item.machineList : [];
    if (this.machinesByUser.length == 0) this.machinesByUser = this.userMachines;
    if (this.machinesByUser.length == 1 && !!this.selectedAction) this.machinesByUser.map((m: any) => (m.isSelected = true));
    else if (!!this.selectedAction) this.machinesByUser.map((m: any) => (m.isSelected = false));
    else this.machinesByUser = [];
    this.currSelectedMachine = [];
    this.sendData["toUser"] = item;
    this.infoToPrint["toUser"] = item.fullName ?? "";
    this.infoToPrint["toDept"] = item.departmentName ?? this.currentUserDept;
    if (this.currAction === this.currProcess && this.currUserId === item.id) {
      console.log("same process");
      this.configService.showToast("error", "This Packet is already issued to you for selected action");
      setTimeout(() => {
        this.userId = null;
        this.selectedUser = "";
      });
    } else if (this.currUserId == item.id || (!!this.planning[0]?.actionName && this.planning[0]?.actionName != "Planning")) {
      this.Do();
    }
  }

  selectSubManagerEvent(item: any) {
    this.selectedSubManager = item;
  }

  selectedMachine(machineId) {
    console.log("selectedMachine");
    console.log(machineId);
    this.machineId = machineId;
    this.currSelectedMachine = [];
    this.currSelectedMachine.push(machineId);
    this.machinesByUser.map(m => {
      console.log(m);
      if (m.id == machineId) {
        m.isSelected = true;
      } else {
        m.isSelected = false;
      }
      return m;
    });
  }
  selectRole(evt: any) {
    console.log("selectRole");
    console.log(evt);
    this.selectedRoleObj = evt;
  }
  onClickClearSelection() {
    console.log("onClickClearSelection");
    this.machinesByUser.map(m => {
      console.log(m);
      if (m.id) {
        m.isSelected = false;
      }
      return m;
    });
  }
  onUserRoleRadioSelection() {
    console.log("onUserRoleRadioSelection");
    console.log(this.defaultSelection);
    if (this.defaultSelection && this.defaultSelection == "Role") {
      this.selectedUser = "";
      this.userId = null;
    } else {
      this.selectedRole = "";
      this.selectedRoleObj = null;
      this.populateUsers();
    }
  }
  inputCleared(event) {
    console.log(event);
  }

  onChangeSearch(val: string) {
    console.log("---", val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    console.log("---", e);
    // do something when input is focused
  }

  reset() {
    if (this.startWith == "Person") {
      this.scannerComponet.onResetForm();
      this.barcodeInputs = "";
      this.actionId = null;
      this.listOfActionsByStone.map(l => (l.isSelected = false));
      this.isStoneSelected = false;
    } else if (this.startWith == "Process") {
      this.scannerComponet.onResetForm();
      this.barcodeInputs = "";
      this.userId = null;
      this.actionUsers = [];
      this.selectedUser = "";
      this.selectedRows = [];
      this.machinesByUser.map(m => (m.isSelected = false));
      this.isStoneSelected = false;
    } else {
      this.scannerComponet.onResetForm();
      this.barcodeInputs = "";
      this.userId = null;
      this.actionUsers = [];
      this.selectedUser = "";
      this.selectedRows = [];
      this.machinesByUser.map(m => (m.isSelected = false));
      this.actionId = null;
      this.listOfActionsByStone.map(l => (l.isSelected = false));
      this.isStoneSelected = false;
      this.selectedRoleObj = null;
      this.selectedRole = "";
    }
  }

  async Do() {
    this.isHistory = false;
    this.showProcessForm = true;
    setTimeout(() => {
      this.showProcessForm = false;
    }, 500);
  }

  async issuePacket() {
    if (this.is41FinalFullProcessManager) {
      this.onAddingWeightLoss();
    } else {
      console.log(this.selectedRows);
      this.infoToPrint["transRemark"] = "Issue";
      this.infoToPrint["data"] = {};
      this.infoToPrint["data"]["packets"] = this.selectedRows;
      let totalCts = 0;
      let pktIds = this.selectedRows.map(x => {
        totalCts += x.cts;
        return x.id;
      });
      this.infoToPrint["data"]["totalCts"] = totalCts;
      this.infoToPrint["data"]["totalPackets"] = this.selectedRows.length;
      console.log(this.selectedAction);
      let isVirtual: number = this.selectedAction.isVirtual;
      console.log(isVirtual);
      let issueObj = {
        listOfPacketIds: pktIds,
        actionId: this.actionId,
        // toUser: this.userId
        // machineId: this.machineId
      };
      if (!this.roleTab && this.userId) {
        issueObj["toUser"] = this.userId;
      }
      if (this.roleTab && this.selectedRoleObj) {
        issueObj["toRole"] = this.selectedRoleObj.id;
      }
      // this.isModal$ = false;
      // this.sendData['data'] = this.selectedRows;
      this.sendData["packetIds"] = this.selectedRows.map(d => d.packetId);
      console.log(this.printData);
      this.httpService.issuePacket(issueObj, isVirtual).subscribe((res: any) => {
        if (res?.data) {
          console.log(res);
          this.infoToPrint["voucherDate"] = new Date(res.data.voucherDate).toLocaleDateString("en-GB");
          this.infoToPrint["issueDate"] = new Date(res.data.voucherDate).toLocaleDateString("en-GB");
          this.infoToPrint["voucherNo"] = res.data.voucherLabel;
          this.infoToPrint["jangadNo"] = res.data.voucherLabel;
          this.infoToPrint["issueNo"] = res.data.voucherLabel;
          let voucherData = {
            data: res.data,
          };
          this.httpService.getErrorAndDisplayIt(res);
          if (res.statusCode === 200) {
            this.selectedOption = "msg";
            this.onIssuedSuccess(isVirtual, pktIds, () => {
              this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
              console.log(this.selectedAction);
              console.log(this.printData);

              this.showModal(true, res.message, voucherData);
            });
          } else {
            // this.showModal(false, res.message, null);
          }
        } else if (res.error == 500) {
          this.configService.showToast("", res.message);
        }
      });
      // this.store.dispatch(issuePacket({ packetDetails: issueObj, isProcessVirtual: this.selectedAction.isVirtual }));

      // this.reset();
      // this.clearData = "";
      this.defaultSelection = "User";
      this.selectedRole = "";
    }
  }
  async onIssuedSuccess(isVirtual, packetIds, afterSucces) {
    if (isVirtual != 1) {
      // this.store.dispatch(HomeActions.getUpdatedReceivedPackets({ packetIDs: packetIds }));
      //  await this.getFreshWithMeGridDataAndSetPaginationToGrid();
    }
    let limit = { limit: 50, page: 1 };
    // this.store.dispatch(HomeActions.getOutgoingPackets(limit));
    // this.store.dispatch(HomeActions.getPacketCount());
    this.store.dispatch(HomeActions.getReceivedPackets(limit));
    this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
    this.reset();
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 200);
    this.clearData = "";

    afterSucces();
  }

  async getDropDownValueOfUserForFullProcess() {
    let endpoint: string = `users/getFullProcessWorker`;
    return this.httpService.fetchData(true, endpoint);
  }

  async showCheckerFinalPlan(currHistory) {
    this.store.dispatch(
      HomeActions.getPacketPlanStoneIfIsFinal({
        packetId: this.selectedRow.id,
      })
    );
    this.store
      .pipe(select(getFinalPlanData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (data: any) => {
        console.log(data);
        if (data?.length && this.showCheckerFinal) {
          this.finalPacketPlans = data;

          this.showCheckerFinal = false;

          const popover = await this.popoverController.create({
            component: PlanningGridComponent,
            cssClass: "planningRowOrderspopoup p-none",
            componentProps: {
              packetPlans: this.finalPacketPlans,
              allowAction: false,
              showClosePopup: true,
              getifDraweropen: "getifDraweropen",
              selectedPacket: this.selectedRow,
            },
          });

          await popover.present();

          popover.onDidDismiss().then(res => {
            this.showCheckerFinal = true;
            if (res.data) {
              //after model close....
            }
          });
        }
      });
    // this.router.navigate(['/trybechecker'], {
    //   queryParams: {
    //     packetId: this.selectedRow.id,
    //     status: 'Completed',
    //     msg: 'ViewOnlyChecker',
    //     isConfirm: true,
    //   },
    // });
  }
  async showModal(state, message, data) {
    if (state) {
      if (!!data) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: "modalprint",
        });
        modal.onDidDismiss().then(dataReturned => {
          if (dataReturned !== null) {
            console.log(dataReturned);
          }
        });
        return await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
          },
          cssClass: "trybe_modal_1",
        });
        return await modal.present();
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      return await modal.present();
    }
  }

  // async onIssueClick() {
  //   const modal = await this.modalController.create({
  //     component: TrybemodalComponent,
  //     componentProps: { packetIds: this.packets },
  //     cssClass: 'modal_main',
  //     backdropDismiss: false,
  //   });

  //   modal.present();

  //   const modalData = await modal.onWillDismiss();
  //   this.dataFromModal = modalData.data.fullName ? modalData.data.fullName : modalData.data.ionic;
  // }
  closeModal() {
    this.modalController.dismiss();
  }

  formSubmitted(formData: any) {
    console.log("formSubmitted calling");
    console.log(formData);
    this.submitFormData(formData);
  }
  async removeProcessDetails(formData: any) {
    console.log("removeProcessDetails");
    console.log(formData);
    console.log(this.formJson);
  }
  // setTimeout(() => {
  //   this.httpService.dismissLoader();
  // }, 500);
  async eventOnkeyPress(evt) {
    console.log("...eventOnkeyPress...");
    console.log(evt.keyCode);
    console.log(this.isManager);
    if (this.isManager === true) {
      console.log("You do have permission to issue stone for Rework");
      if (evt.keyCode === 82 || evt.keyCode === 114) {
        let payload: any = {
          listOfPacketIds: [],
        };
        payload.listOfPacketIds = this.selectedPacketIds;
        let reWorkActions: any = await this.getReworkProcess(payload);
        console.log(this.listOfActionsByStone);
        console.log(this.selectedPacketIds);
        if (reWorkActions.length === 1) {
          this.processTitle = "Rework";
          this.autoSelectSingleValues(reWorkActions);
        } else if (reWorkActions.length > 1) {
          this.processTitle = "Rework";
          this.listOfActionsByStone = reWorkActions;
        } else {
          this.listOfActionsByStone = this.listOfActionsByStone;
        }
      }
    } else {
      console.log("You do no have permission to issue stone for Rework");
    }
  }
  async getReworkProcess(stonIds) {
    return new Promise((resolve, reject) => {
      this.httpService.fetchReworkProcess(stonIds).subscribe(res => {
        console.log(res);
        if (res && res.data != "no data found") {
          console.log(res.data);
          resolve(res.data);
        } else {
          reject([]);
        }
      });
    });
  }

  switchToMessageTab(event) {
    console.log(event);
    this.ischecked = event.detail.checked;
    this.messageTab = true;
    this.showProcessTab = true;
    // setTimeout(() => {
    //   this.showProcessTab = false;
    // }, 500);
    if (this.ischecked == false) {
      this.messageTab = false;
    }
    // else{
    //   this.messageTab=true;
    // }
  }
  switchToRoleTab(event) {
    console.log(event);
    this.ischecked = event.detail.checked;
    this.roleTab = true;
    if (this.ischecked == false && this.isVirtualProcess == true) {
      this.roleTab = false;
    }
    // else{
    //   this.messageTab=true;
    // }
  }
  submitFormData(formData: any) {
    let formType = "JSON";
    let actionId = this.currActionInfo.id;
    console.log(formData);
    console.log(this.currActionInfo);
    console.log(this.fromJsonInputs);
    let fromJsonInputsModified = { ...this.fromJsonInputs };
    let cuttingListOfParameter = [];
    let bifurcatePayload = {
      stones: [],
    };
    if (this.currActionInfo.name == "Cutting") {
      bifurcatePayload["packetId"] = this.selectedRow.id;
      let changedFormData = Object.values(formData).map((d, i) => {
        let no = i + 1;
        let keyPlanType = "Packet-Plan-Type-" + no;
        let keyPlan = "Packet-Plan-StoneId-" + no;
        let m = JSON.parse(JSON.stringify(d));
        if (m[keyPlanType] === 0) {
          m[keyPlan] = 0;
        }
        return m;
      });
      changedFormData.map(e => {
        console.log(e);
        let newObj = {};
        for (const [key, value] of Object.entries(e)) {
          console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
          let planType: any;
          if (key.indexOf("NFC-Code") > -1) {
            newObj["nfcCode"] = value;
          }
          if (key.indexOf("Packet-Weight") > -1) {
            newObj["cts"] = value;
          }
          if (key.indexOf("Loss-Weight") > -1) {
            newObj["lossWeight"] = value;
          }
          if (key.indexOf("Packet-Plan-StoneId") > -1) {
            newObj["packetPlanStoneId"] = value;
          }
        }
        bifurcatePayload.stones.push(newObj);
      });
      console.log("bifurcatePayload");
      console.log(bifurcatePayload);
      this.httpService.addNewPacketsAfterBifurcation(bifurcatePayload).subscribe(res => {
        if (res) {
          this.httpService.getErrorAndDisplayIt(res);
          if (res.statusCode === 200) {
            // this.popup(res);
            // this.httpService.showToast("success", res.message);
            console.log(res.data + "popup________________");
            // this.clearBarcode(true);
            // this.autoReceiveData();
          } else {
            this.showModal(false, res.message, null);
            // this.clearBarcode(true);
            // this.autoReceiveData();
          }
        }
      });
    } else {
      fromJsonInputsModified.listOfParameter = fromJsonInputsModified.listOfParameter.map(x => {
        const xData = { ...x };
        let paramName = xData.parameterName;
        if (formData[paramName]) {
          xData.parameterValue = formData[paramName];
        }
        let packet1 = xData.Packet1;
        let packet2 = xData.Packet2;
        if (!!packet1 && formData["Packet1"]) {
          let f1 = formData["Packet1"];
          packet1.forEach((p1: any) => {
            let paramName = p1.parameterName;
            if (f1[paramName]) {
              p1.parameterValue = f1[paramName];
              cuttingListOfParameter.push(p1);
            }
          });
        }
        if (!!packet2 && formData["Packet2"]) {
          let f2 = formData["Packet2"];
          packet2.forEach((p2: any) => {
            let paramName = p2.parameterName;
            if (f2[paramName]) {
              p2.parameterValue = f2[paramName];
              cuttingListOfParameter.push(p2);
            }
          });
        }
        return { ...xData };
      });
      fromJsonInputsModified.packetId = this.selectedRow.id;
      let barcode = this.selectedRow.packetId;
      if (this.searchedPageName == "Auto Receive" && this.selectedRow.currActionName === "Cutting") {
        console.log(cuttingListOfParameter);
        console.log(this.selectedRow.id);
        fromJsonInputsModified.listOfParameter = cuttingListOfParameter;
      } else if (this.actionName == "Assign Full Process Worker") {
        console.log(this.selectedRow);
        console.log(formData);

        fromJsonInputsModified.listOfParameter = [
          {
            parameterName: "NextUser",
            parameterValue: formData.assignFullProcess,
          },
        ];
        this.sendToUserForFullProcess(this.selectedRow.id, formData);
      } else {
        fromJsonInputsModified.listOfParameter = fromJsonInputsModified.listOfParameter;
      }
      let parameters = {
        listOfAllPackets: [],
      };
      let barcodeObj = {
        packetId: barcode,
      };
      const formatData = new FormData();
      if (!!this.scannerComponet) {
        this.scannerComponet.onResetForm();
      }
      console.log(fromJsonInputsModified);
      console.log(bifurcatePayload);
      // return;
      if (formData["file"]) {
        console.log(formData["file"]);
        formType = "Multipart";
        let file = formData["file"]["0"];
        console.log(file);
        formatData.append("file", file);
        formatData.append("actionId", actionId);
        console.log(formatData);
        let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(d => d.parameterValue != "");
        fromJsonInputsModified.listOfParameter = newListOfParameters;
        parameters["listOfAllPackets"].push(fromJsonInputsModified);
        // parameters['actionId'] = actionId;
        // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
        formatData.append("listOfAllPackets", JSON.stringify(parameters["listOfAllPackets"]));
        // formatData.append(
        //   'listOfAllPackets',
        //   JSON.stringify(parameters)
        // );
        console.log(formatData);
        console.log(parameters);
        // return
        this.store.dispatch(
          IssueAction.saveActionInputParameter({
            payload: formatData,
            actionId: actionId,
            formType: formType,
            barcodeObj: barcodeObj,
            actionInfo: this.currActionInfo,
          })
        );
      } else {
        parameters["listOfAllPackets"].push(fromJsonInputsModified);
        parameters["actionId"] = actionId;
        this.store.dispatch(
          IssueAction.saveActionInputParameter({
            payload: parameters,
            actionId: actionId,
            formType: formType,
            barcodeObj: barcodeObj,
            actionInfo: this.currActionInfo,
          })
        );
      }
    }

    // this.store.dispatch(masterDataActions.createNewPackets({payload:}))
    // let selectedData: any
    // if (this.actionName == 'Fluorescence') {
    //   selectedData = this.fluorescence.filter(x => x.id == data.fluorescence)[0];
    //   console.log(selectedData);
    // }

    // let fData = {
    //   packetId: this.selectedRow.id,
    //   parameterValue: selectedData.id,
    //   parameterName: this.actionName,
    //   pName: selectedData.name
    // }

    // console.log(fData)
    // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: fData, actionId: this.actionId }));
    // this.resetForm = true;
    // this.httpService.showLoader();
    // if (!!barcode) {
    //   this.store.dispatch(
    //     AutoReceiveAction.getScannedPacketDetails({ packetIdObj: barcodeObj }),
    //   );
    // }

    // setTimeout(() => {
    //   this.resetForm = false;
    //   if (
    //     this.searchedPageName == 'Auto Receive' &&
    //     this.dataSource.length === 0
    //   ) {
    //     this.status = undefined;
    //     let limit = { limit: 2000, page: 1 };

    //     this.store.dispatch(HomeActions.getReceivedPackets(limit));
    //   }
    //   console.log('%cIssue detection', 'color:orange;font-size:2rem;');
    //   console.log(this.formJson);
    //   console.log(this.showSubmit);
    //   console.log(this.selectedRow);
    // }, 500);
  }

  async printPage() {
    console.log("printPage");
    // this.configService.print();
    // window.print();
    this.showModal(true, "Issued", { data: 101 });
  }

  async fetchallSubManager() {
    let res: any;
    res = await this.httpService.getSubManagerWorker();

    if (!!res && res.data) {
      this.subManagerDatas = res.data.map(obj => ({
        id: obj.id,
        fullName: obj.fullName,
        departmentId: obj.departmentId,
        roleId: obj.roleId,
      }));
      console.log(this.subManagerDatas);
    }
    let data = res;
    console.log(data);
    if (res.status == 401) {
    } else if (res.status == 500) {
    } else if (res.status == 400) {
    }
  }

  async transferPacketToSubManager() {
    let payload = {
      toDepartment: this.selectedSubManager.departmentId,
      toRole: this.selectedSubManager.roleId,
      toUser: this.selectedSubManager.id,
      listOfPacketIds: this.packetArray,
    };

    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet/transfer", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.selectedOption = "msg";
      this.selectedSubManager = "";
      this.getFreshWithMeGridDataAndSetPaginationToGrid();
      let limit = { limit: 500, page: 1 };
      // this.store.dispatch(HomeActions.getOutgoingPackets(limit));
      this.store.dispatch(HomeActions.getPacketCount());
      this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
      this.reset();
      this.clearData = "";

      console.log(response.data);
      let data = response.message;

      console.log(data);
    } else {
      console.error(response);
    }
  }

  async onAddingWeightLoss() {
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: "rejectRemark",
      componentProps: {
        data: { status: "weightloss" },
      },
    });

    Modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await Modal.present();
  }
  async handleModalDismiss(d) {
    if (!!d.data) this.weightLoss = d.data;
    if (!!this.weightLoss) {
      this.callingSelfIssuedAPiFor14FirstProcessManager();
    }
  }

  async sendToUserForFullProcess(packetID, formValue) {
    let payload = {
      userId: formValue.assignFullProcess,
      packetId: packetID,
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet-next-user/save", payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  async callingSelfIssuedAPiFor14FirstProcessManager() {
    let payload = {
      listOfAllPackets: [
        {
          packetId: this.packetArray.toString(),
          listOfParameter: [
            {
              parameterName: "Weight Loss",
              parameterValue: this.weightLoss,
            },
          ],
        },
      ],
      actionId: this.weightLossActionInfo?.id,
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet/selfIssueAndInputReceive", payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      this.issueCodeFor41FullProcessWorker();
      let data = response.message;
      console.log(data);
    } else {
      console.error(response);
    }
  }

  async issueCodeFor41FullProcessWorker() {
    this.infoToPrint["transRemark"] = "Issue";
    this.infoToPrint["data"] = {};
    this.infoToPrint["data"]["packets"] = this.selectedRows;
    let totalCts = 0;
    let pktIds = this.selectedRows.map(x => {
      totalCts += x.cts;
      return x.id;
    });
    this.infoToPrint["data"]["totalCts"] = totalCts;
    this.infoToPrint["data"]["totalPackets"] = this.selectedRows.length;
    console.log(this.selectedAction);
    let isVirtual: number = this.selectedAction.isVirtual;
    console.log(isVirtual);
    let issueObj = {
      listOfPacketIds: pktIds,
      actionId: this.actionId,
      // toUser: this.userId
      // machineId: this.machineId
    };
    if (this.userId) {
      issueObj["toUser"] = this.userId;
    }
    if (this.defaultSelection == "Role" && this.selectedRoleObj) {
      issueObj["toRole"] = this.selectedRoleObj.id;
    }
    // this.isModal$ = false;
    // this.sendData['data'] = this.selectedRows;
    console.log(issueObj);
    this.sendData["packetIds"] = this.selectedRows.map(d => d.packetId);
    console.log(this.printData);

    this.httpService.issuePacket(issueObj, isVirtual).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.infoToPrint["voucherDate"] = new Date(res.data.voucherDate).toLocaleDateString("en-GB");
        this.infoToPrint["issueDate"] = new Date(res.data.voucherDate).toLocaleDateString("en-GB");
        this.infoToPrint["voucherNo"] = res.data.voucherLabel;
        this.infoToPrint["jangadNo"] = res.data.voucherLabel;
        this.infoToPrint["issueNo"] = res.data.voucherLabel;
        let voucherData = {
          data: res.data,
        };
        this.httpService.getErrorAndDisplayIt(res);
        if (res.statusCode === 200) {
          this.selectedOption = "msg";
          this.onIssuedSuccess(isVirtual, pktIds, () => {
            this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
            console.log(this.selectedAction);
            console.log(this.printData);

            this.showModal(true, res.message, voucherData);
          });
        } else {
          // this.showModal(false, res.message, null);
        }
      }
    });
    // this.store.dispatch(issuePacket({ packetDetails: issueObj, isProcessVirtual: this.selectedAction.isVirtual }));

    // this.reset();
    // this.clearData = "";
    this.defaultSelection = "User";
    this.selectedRole = "";
  }

  async openDownloadModal(packetData) {
    const Modal = await this.modalController.create({
      component: FileDownloadComponent,
      cssClass: "DownloadFileModal",
      componentProps: {
        data: { data: packetData },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissOfDownload(d));
    return await Modal.present();
  }

  async handleModalDismissOfDownload(d) {
    if (d.data) {
      console.log(d);
    }
    console.log("closing modal");
  }

  onAssignLot() {
    this.httpService.fetchData(true, `packet/assignTaskForKGNewFlow`).subscribe(response => {
      console.log(response);
      if (response.statusCode == 200) {
        this.configService.showToast("true", response.message);
        this.router.navigate(["/home"]);
      } else {
        this.configService.showToast(true, response.message);
      }
      this.httpService.getErrorAndDisplayIt(response);
    });
  }

  downloadFile(event) {
    console.log(event);
    let packetid = event.row.data;
    this.openDownloadModal(packetid);
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      this.getFreshWithMeGridDataAndSetPaginationToGrid();
    }
  }

  getUserMachines(processName) {
    let payload = {
      processName: processName,
    };
    this.httpService.getUserMachineOnBasisOfProcessName(payload).subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.userMachines = res?.data || [];
          this.machinesByUser = this.userMachines;
          if (this.machinesByUser.length == 1 && !!this.selectedAction) {
            this.machinesByUser.map(m => (m.isSelected = true));
          } else if (!!this.selectedAction) {
            this.machinesByUser.map(m => (m.isSelected = false));
          } else {
            this.machinesByUser = [];
          }
          if (!this.dataSource?.length) this.configService.showToast("error", "No data found!");
        } else {
          this.configService.showToast("error", res.error.message || "Something went wrong!");
        }
      },
      (err: any) => {
        this.configService.showToast("error", err.message || "Something went wrong!");
      }
    );
  }

  async showFinalInspection(event: any) {
    await this.getFinalInspectionData(this.selectedRow?.id);
    const Modal = await this.modalController.create({
      component: FinalInspectionComponent,
      cssClass: "finalInspection",
      componentProps: {
        data: { data: this.finaInspectionData, fromPage: "With Me" },
      },
    });
    Modal.onDidDismiss().then((d: any) => console.log(d));
    return await Modal.present();
  }

  getFinalInspectionData(id) {
    return new Promise((resolve, reject) => {
      if (id) {
        let endpoint: string = `packet-parameter-on-each-dept/findPacketParametersForFinalInception/${id}`;
        this.httpService.fetchData(true, endpoint).subscribe(
          (res: any) => {
            resolve(null);
            if (res) {
              let resultCode: number = res.statusCode;
              console.log(resultCode);
              let d: any = res.data;
              console.log(d);
              if (resultCode == 200) {
                this.finaInspectionData = res.data;
              }
            }
          },
          err => {
            reject();
          }
        );
      } else {
        reject();
      }
    });
  }

  ngOnDestroy() {
    console.log("WithMe State Destroyed...");
    this.store.dispatch(IssueAction.unSelectionOfPacket());
    this.subscriptions.forEach(subscription => {
      if (!!subscription) {
        subscription.unsubscribe();
      }
    });
    this.store.dispatch(tempDataActions.removeCurrentRowData());
    this.httpService.updateWithMeGridData({ countOfTotalPackets: 0, packetData: [] });
  }
}
function getUsersByActionDatayActionData(getUsersByActionDatayActionData: any): import("rxjs").OperatorFunction<AppState, any> {
  throw new Error("Function not implemented.");
}
