import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { Storage } from '@ionic/storage-angular';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-user-setting-popover',
  templateUrl: './user-setting-popover.component.html',
  styleUrls: ['./user-setting-popover.component.scss'],
})
export class UserSettingPopoverComponent implements OnInit {
  loggedinUser$: Observable<any>;
  constructor(private store: Store<AppState>,
    public router: Router,
    public httpService: HttpService,
    public storage: Storage,
    private navParams: NavParams,
    public configService: ConfigService,
    private http: HttpClient,
    // private mixpanelService: MixpanelService,
    public popoverController: PopoverController) { }

  ngOnInit() {
    this.loggedinUser$ = this.store.pipe(select(authUser));

  }


  async logout() {
    this.store.dispatch({ type: 'Logout' });
    await this.storage.clear();
    this.httpService.isAuthenticated.next(false);
    this.popoverController.dismiss({ 'type': 'Logout', 'forPage': '' });
    setTimeout(()=>{
      sessionStorage.setItem('refreshData','false')
    },100)
    this.router.navigate(['/login']);
    // this.mixpanelService.logout();
  }

  getFirstLetterOfEachWord(str: string) {
    if (!!str)
      var newStr = str.split(' ').reduce((result: any, currentWord: any) =>
        result + currentWord.charAt(0).toUpperCase(), '');
    return newStr;
  }
}
