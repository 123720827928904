import { Component, OnInit } from "@angular/core";
import CustomStore from "devextreme/data/custom_store";
import { HttpService } from "src/app/services/http/http.service";
import Query from "devextreme/data/query";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/reducers";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";
import { getAllRolesOfUser, getAllTrybeDepartments } from "src/app/masterDataStore/masterData.selector";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigService } from "src/app/services/config/config.service";
import { MultipleBarcodeScannerComponent } from "../multiple-barcode-scanner/multiple-barcode-scanner.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-transfer-with-action",
  templateUrl: "./transfer-with-action.component.html",
  styleUrls: ["./transfer-with-action.component.scss"],
})
export class TransferWithActionComponent implements OnInit {
  tranferGridColumns: any = [
    { name: "Lot No", prop: "lotNo", columnTemplate: "lot-svg-template" },
    { name: "Packet ID", prop: "packetId" },
    { name: "Cts", prop: "cts" },
    { name: "InwardDate", prop: "inDate" },
    { name: "CreatedDate", prop: "createdDate" },
  ];
  transferGridPage = "Tranfer";
  stonesForTransfer: any = [];
  transferGridpageSize = 10;
  rowsIndex: any = [];
  scanned: boolean;
  refreshGrid: boolean = false;
  tableDataCount: number | string = 0;
  deptDropdownData: any = {
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],

    propertName: "name",
    modeFlag: "single", //single/multiple
  };
  selectRoles: any = [];
  roleDropDownData: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: this.selectRoles,
    propertName: "roleName",
    modeFlag: "single", //single/multiple
  };
  listOfActionsByStone: Observable<any>;
  pageInfo = { limit: 50, page: 0 };
  pageNumber: number = 0;
  gridDataCount: number = 0;
  gridData: any = [];
  modifiedGridObject: any;
  private subscriptions: Subscription[] = [];
  selectedRows: any = [];
  selectedRow: any;
  selectedDepartment: any;
  selectedRole: any;
  selectedAction: any;
  barcodeInputList: any;

  constructor(public httpService: HttpService, private store: Store<AppState>, private _ConfigService: ConfigService, private modalController: ModalController) {}

  ngOnInit() {
    this.httpService.currenttranferReturnAssignGridData$.subscribe(data => {
      if (data) {
        this.gridDataCount = data.countOfTotalPackets;
        this.gridData = data.packetData;
      }
    });
    this.getFreshDataAndSetPaginationToGrid();
    this.store.dispatch(masterDataActions.getRolesForUser());
    // this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.subscriptions.push(
      this.store.pipe(select(getAllRolesOfUser)).subscribe(data => {
        if (data) {
          this.roleDropDownData["options"] = data;
        }
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
        if (data) {
          this.deptDropdownData["options"] = data;
          if (this.selectedDepartment && data?.filter((dt: any) => dt.id == this.selectedDepartment.id)?.length) this.getStoneActionForTransfer();
          else this.selectedDepartment = undefined;
        }
      })
    );
  }

  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.pageInfo["limit"] = size;
        this.getFreshDataAndSetPaginationToGrid();
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.pageInfo["page"] = pageIndex;
        this.getFreshDataAndSetPaginationToGrid();
        break;
    }
  }
  getFreshDataAndSetPaginationToGrid() {
    let payload = null;
    if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
      payload = { packetId: this.barcodeInputList };
    }
    this.modifiedGridObject = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo["limit"];
          } else {
            this.pageInfo["limit"] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.pageInfo["page"] = this.pageNumber;
          let response: any = await this.httpService.fetchWithMeGridData(this.pageInfo, payload);
          if (response.statusCode == 200) {
            this.stonesForTransfer = response.data.packetData;
            this.httpService.updateTranferReturnAssignGridData(response.data);
            this.tableDataCount = response?.data?.countOfTotalPackets || "0";
          }
          return {
            data: Query(this.gridData).toArray(),
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        } else {
          return {
            data: this.gridData,
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        }
      },
    });
  }

  async onSelectionChanged(event: any) {
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    let listofIds = this.selectedRows.map(id => id.id);

    if (this.selectedRow?.id) {
      this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: listofIds }));
    } else if (this.selectedRows.length === 0) {
      this.selectedRole = [];
      this.listOfActionsByStone = undefined;
      this.roleDropDownData["selectedData"] = [];
      this.deptDropdownData["selectedData"] = [];
    }
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalController.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      console.log(this.barcodeInputList);
      this.getFreshDataAndSetPaginationToGrid();
    }
  }

  async dropDownSelection(event: any) {
    if (event?.arrayList?.[0]) {
      this.selectedDepartment = event.arrayList[0];
      if (this.selectedRows) this.getStoneActionForTransfer();
      let selected = event.arrayList;

      let selDepartment = this.deptDropdownData["options"].filter(d => {
        return d.id == selected[0].id;
      });
      this.selectedRole = [];
      this.selectedRole = this.roleDropDownData["options"].filter(d => {
        return d.id == selDepartment[0].receiveRole;
      });
      this.roleDropDownData["selectedData"] = this?.selectedRole;
    } else {
      this.selectedDepartment = undefined;
      this.selectedAction = undefined;
      this.selectedRole = [];
      this.roleDropDownData["selectedData"] = [];
      this.listOfActionsByStone = undefined;
    }
  }

  getStoneActionForTransfer() {
    let listofIds = this.selectedRows.map(id => id.id);
    const payload = {
      listOfPacketIds: listofIds,
      deptId: this.selectedDepartment.id,
    };
    this.listOfActionsByStone = this.httpService.fetchUserStoneActionsForTransfer(payload).pipe(
      map((data: any) => {
        this.selectedAction = undefined;
        if (data?.data?.length > 0) return data.data;
        else return [];
      })
    );
  }

  selectAction(action: any) {
    if (action?.id) this.selectedAction = action;
    else this.selectedAction = undefined;
  }

  roleDropDownSelection(event: any) {
    if (event?.arrayList?.[0]) this.selectedRole = event.arrayList[0];
    else this.selectedRole = undefined;
  }

  transfer() {
    let listofIds = this.selectedRows.map(id => id.id);
    const payload = {
      toDepartment: this.selectedDepartment.id,
      toRole: this.selectedRole[0].id,
      actionId: this.selectedAction.id,
      listOfPacketIds: listofIds,
    };
    this.httpService.transferPacket(payload).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this._ConfigService.showToast("success", data?.message || "Packet transfered successfully");
        this.getFreshDataAndSetPaginationToGrid();
        this.selectedAction = undefined;
        this.selectedRow = undefined;
      } else this._ConfigService.showToast("error", data?.message || "Something went wrong");
    });
  }
}
