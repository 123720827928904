import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import Query from "devextreme/data/query";
import CustomStore from "devextreme/data/custom_store";
import { Router } from "@angular/router";
import { getCurrentAction } from "src/app/tempDataStore/tempData.selector";
import { filter, first } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/reducers";

@Component({
  selector: "app-wages-master",
  templateUrl: "./wages-master.component.html",
  styleUrls: ["./wages-master.component.scss"],
})
export class WagesMasterComponent implements OnInit {
  wagesColumn: any;
  refreshGrid: boolean;
  public pageNumber: number = 1;
  count: number = 50;
  public pageSize: number = 50;
  public PageInfo: any = {
    limit: this.pageSize,
    page: 1,
  };
  actionName: any;
  wagesPage: any;
  modifiedGridObject: any;
  public dataSource: any = [];
  modifiedDataSource: any;
  iswagesMaster: boolean = false;

  constructor(public httpService: HttpService, private router: Router, private store: Store<AppState>) {
    this.wagesColumn = [
      { name: "Wage Name", prop: "wageName" },
      { name: "fromDate", prop: "fromDate" },
      { name: "Departments", prop: "deptName" },
      { name: "Process", prop: "actionName" },
      // { name: "From Dept", prop: "fromDept" },
      { name: "Status", prop: "status" },
      { name: "Type", prop: "wageType" },
      { name: "Priority", prop: "weightage" },
      { name: "Rate", prop: "amount" },
    ];
  }

  async ngOnInit() {
    await this.store
      .pipe(select(getCurrentAction))
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.actionName = data.name;
        }
      });

    if (this.actionName == "Wages Master") {
      this.setPaginationForWageMaster();
      this.iswagesMaster = true;
      //  this.onSavebtnClick();
      this.httpService.currentWagesMaster$.subscribe(data => {
        this.count = data.totalCount;
        if (data && data.wagesRuleMasterList) {
          this.dataSource = data.wagesRuleMasterList.map(item => ({
            ...item,
            status: item.status === 1 ? "Active" : "Inactive",
          }));
        }
      });
    } else {
    }
  }

  onSelectionChanged(event) {
    console.log(event);
  }

  editData(event) {}

  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.PageInfo["limit"] = size;
        this.setPaginationForWageMaster();
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.PageInfo["page"] = pageIndex;
        this.setPaginationForWageMaster();
        break;
    }
  }

  async setPaginationForWageMaster() {
    this.modifiedDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // if (!!loadOptions.filter) {
        //   var params = '';
        //   params += '&filter=' + JSON.stringify(loadOptions.filter); // <- Add this line
        // }
        const filterObject = {};
        if (!!loadOptions.filter) {
          var params = loadOptions.filter;
          console.log(params);
          for (let i = 0; i < params.length; i++) {
            if (typeof params[0] == "string") {
              const key = params[0];
              const value = params[2];
              if (key && value) {
                filterObject[key] = value;
              }
            } else if (Array.isArray(params[i])) {
              const key = params[i][0];
              const value = params[i][2];
              if (key && value) {
                filterObject[key] = value;
              }
            }
            console.log(filterObject);
          }
        }
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.PageInfo["limit"];
          } else {
            this.PageInfo["limit"] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.PageInfo["page"] = this.pageNumber;
          // if(this.dataSource.length == 0){
          let response: any = await this.httpService.getDataForWagesMaster(this.PageInfo, filterObject);
          this.httpService.updateWagesMasterData(response.data);
          // }
          return {
            data: Query(this.dataSource).toArray(),
            totalCount: this.count,
            groupCount: this.count,
          };
        } else {
          return {
            data: this.dataSource,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }

  onAdd() {
    if (this.actionName == "Wages Master") {
      this.router.navigate(["/wages-add"]);
    }
  }

  viewWages(event) {
    console.log(event.row.data);
    this.router.navigate(["/wages-add"], { queryParams: { rowData: JSON.stringify(event.row.data) } });
  }
}
