import { createAction, props } from "@ngrx/store";
import { packetDetails } from "./auto-receive.data";

export const getScannedPacketDetails = createAction(
    '[AutoReceived] get details of scanned Packet',
    props<{ packetIdObj: any }>()
);

export const savePacketDetails = createAction(
    '[AutoReceived] Scanned Packet details saved',
    props<{ scannedPacketDetails: packetDetails[] }>()
)

export const getAutoReceivePacket = createAction(
    '[AutoReceived] Get auto Received packet ',
);
export const saveAutoReceivePacket = createAction(
    '[AutoReceived] save auto Received packets ',
    props<{ saveAutoReceive: any }>()
);