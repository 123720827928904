import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { DataGridComponentModule } from "../data-grid/data-grid.module";
import { HeaderComponentModule } from "../header/header.module";
import { NewDropDownModule } from "../new-drop-down/new-drop-down.module";
import { NgxFormModule } from "../ngx-form/ngx-form.module";
import { DynamicPopoverComponent } from "./dynamic-popover.component";



@NgModule({
  declarations: [DynamicPopoverComponent],
  imports: [
    CommonModule,
    IonicModule,
    HeaderComponentModule,
    DataGridComponentModule,
    NgxFormModule,
    NewDropDownModule
  ],
  exports: [DynamicPopoverComponent],
})
export class DynamicPopoverModule { }
