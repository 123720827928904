import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Store } from '@ngrx/store';
import { AuthActions } from './authStore/auth.action-type';
import { AppState } from './reducers';
import { HttpService } from './services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private authStore: Store<AppState>,
    private httpService: HttpService,
    private router: Router,
    private storage: Storage
  ) {

    // this.httpService.isAuthenticated$.subscribe(res => {
    //   if (!!res) {
    //     this.router.navigate(["/home"]);
    //   } else {
    //     this.router.navigate(["/login"]);
    //   }
    // })
    sessionStorage.setItem('refreshData','false')


  }
  env = environment;
  AVOID_URLS:string[] = ['login']
  showFooter:boolean = false;

  isTokenLoaded: boolean = false;
  async ngOnInit() {

    this.router.events.pipe(
    ).subscribe(() => {
      this.updateFooterVisibility();
    });

    // Initial check
    this.updateFooterVisibility();
     if(environment.favicon)
    document.getElementById('appFavicon')?.setAttribute('href', environment.favicon);

    await this.storage.create();
    const data = localStorage.getItem("token");
      this.isTokenLoaded = true;
      if (data) {
        this.httpService.token = data
        this.httpService.isAuthenticated.next(true)
        const userId = localStorage.getItem("userId")
     
          if (userId) {
            console.log(userId);
            this.authStore.dispatch(
              AuthActions.getUser({ userId: +userId }),
            );
            console.log(this.router.url)
            if (this.router.url != '/home') {
              // this.router.navigateByUrl("/home");
              this.router.navigate(["/home"]);
            }
          }
    
      } else {
        this.router.navigateByUrl("/login");
      }
  }

  private updateFooterVisibility(): void {
    this.showFooter = !this.AVOID_URLS.some(url => this.router.url.includes(url)) && this.env.project !== 'kps';
  }

}
