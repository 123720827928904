import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { ConfigService } from '../services/config/config.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-packet-history-view',
  templateUrl: './packet-history-view.component.html',
  styleUrls: ['./packet-history-view.component.scss'],
})
export class PacketHistoryViewComponent implements OnInit {
  columns: any;
  pageSize: any;
  dataSource: any;
  PacketId: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    public configService: ConfigService
  ) { }

  ngOnInit() {
    this.columns = [
      { name: 'ActionName', prop: 'actionName' },
      { name: 'From Dept', prop: 'fromDeptName' },
      { name: 'From User Name', prop: 'fromUserName' },
      { name: 'Issue Cts', prop: 'issueCts' },
      { name: 'To Dept', prop: 'toDeptName' },
      { name: 'To User', prop: 'toUserName' },
    ];
   }


  async fetchGridData() {
    const encodedSpecialCharacter = encodeURIComponent(this.PacketId);
    console.log(encodedSpecialCharacter);
    let endpoint: string = `packet/getPacketHistory?packetId=${encodedSpecialCharacter}`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
        if (resultCode == 200) {
          console.log(res.data);
          if (res.data.length == 0) {
            this.configService.showToast('Error', 'No Data Available');
          }
          this.dataSource = res.data;
        } else {
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

  onReset() {
    this.PacketId = null;
    this.dataSource = null;
  }

  onEnterKeyPress() {
    this.fetchGridData();
  }

}
