import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { MultipleBarcodeScannerComponent } from '../multiple-barcode-scanner/multiple-barcode-scanner.component';
import { ModalController } from '@ionic/angular';
import { myVirtualTaskActions } from '../my-virtual-task/state/my-virtual-task.actions.type';
import * as moment from 'moment';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-viewvirtualtask',
  templateUrl: './viewvirtualtask.component.html',
  styleUrls: ['./viewvirtualtask.component.scss'],
})
export class ViewvirtualtaskComponent implements OnInit {
  segment:string = 'pending'
  pendingColumns: any[];
  completedColumns: any[];
  refreshGrid:any; 
  showGrid: boolean = false;
  toDate: string = moment().format('YYYY-MM-DD');
  fromDate = moment(this.toDate).subtract(7, 'days').format('YYYY-MM-DD');
  barcodeInputList: any;
  completedDataSource:any;
  pendingDataSource:any;
  constructor(
   private configService: ConfigService,
   private store: Store<AppState>,
   public modalCtrl:ModalController,
   public httpService: HttpService,
   private loaderService: LoaderService,
  ) { 
    this.pendingColumns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'IssueDate', prop: 'issueDate' },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      // { name: 'Status', prop: 'pktStatus' },
      // { name: 'PredLimit', prop: 'predLimit' },
      { name: 'toUser', prop: 'toUserName' },
      { name: 'fromUser', prop: 'fromUserName' },
      { name: 'Role', prop: 'Role' },
      { name: 'Process', prop: 'virtualActionName' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'OutwardDate', prop: 'outDate' },
    ];
    this.completedColumns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'IssueDate', prop: 'issueDate' },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      // { name: 'Status', prop: 'pktStatus' },
      { name: 'PredLimit', prop: 'predLimit' },
      { name: 'toUser', prop: 'toUserName' },
      { name: 'Role', prop: 'Role' },
      { name: 'Process', prop: 'virtualActionName' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'OutwardDate', prop: 'outDate' },
    ];
  }

  ngOnInit() {
    this.fetchGridData(null,true,null);
  }


  barcodeDetection(event){
    this.fetchGridData([event], null,null)
  }

  segmentChanged(event){
    this.segment = event.detail.value;
  }

  clearBarcode(type){
  console.log(type);
  
  }

  
  checkDates() {
    const fromDateObj = new Date(this.fromDate);
    const toDateObj = new Date(this.toDate);

    if (fromDateObj.getTime() === toDateObj.getTime()) {
      this.configService.showToast('Error', 'To And From Date Cant Be Same');
      this.toDate = null;
      this.fromDate = null;
    } else {
      this.showGrid = true;
      this.fetchGridData();
    }
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: 'multiplBarcodeScanner',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'done') {
      this.barcodeInputList = data.map((barcode) => barcode.barcode);
      this.fetchGridData(this.barcodeInputList,null,null);
    }
  }



async  fetchGridData(barcode?,isDate?,isshowAll?){
  console.log(barcode);
    let endpoint: string = 'virtual-task/getVirtualTaskCreatedByAnyUser';
    let payload: any = null;
    try{
      
    if(isDate){
      payload = {
        fromDate : this.fromDate ,
        toDate :this.toDate
      }
    }
    else if (!!barcode) {
      let packetIds;
      if (typeof this.barcodeInputList === 'string') {
        packetIds = this.barcodeInputList.split(',');
      } else {
        packetIds = barcode;
      }
      payload = {
        packetIds: packetIds,
        fromDate: this.fromDate,
        toDate: this.toDate
      };
     console.log(payload);
    }
    else if (isshowAll==='showAll'){
      this.fromDate = null;
      this.toDate = null;
      payload = null;

    }
    let response = await this.httpService.postDataUsingPromise(
      true,
      endpoint,
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
     this.pendingDataSource = response.data.pendingTasks;
     this.completedDataSource = response.data.completedTasks;
    //  if(response.data.pendingTasks || response.data.completedTasks){  
    //   this.configService.showToast('error', 'No Data Found');

    //  }
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  } catch (error) {
    console.error("an error occurred", error);
    this.configService.showToast('error',"something went wrong ");
  } finally {
    
  }
  }

  async remove(event) {
    let data = event.row.data
    let virtualId = data.virtualId;
    console.log(virtualId);
    if (!!virtualId) {
      this.store.dispatch(myVirtualTaskActions.deleteAssignedTask({ virtualId: virtualId }));
      this.fetchGridData(null,true,null);
    }
  }
  }


