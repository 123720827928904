import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../services/http/http.service';
import { LoaderService } from '../services/loader/loader.service';
import { ConfigService } from '../services/config/config.service';
import { AlertController, ModalController } from '@ionic/angular';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { getParamsValuesListByName } from '../masterDataStore/masterData.selector';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-polish-grade',
  templateUrl: './polish-grade.component.html',
  styleUrls: ['./polish-grade.component.scss'],
})
export class PolishGradeComponent implements OnInit {
  showGrid: boolean = true;
  columns: any;
  dataSource: [];
  criteriaParameterHead = [];
  criteriaParameterLists = [];
  selectedCriteria: any;
  sizeTo: any;
  sizeFrom: any;
  Grade: any;
  isEdit: boolean = false;
  data: any;
  updateID: any;
  constructor(
    private router: Router,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private configService: ConfigService,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private store: Store<AppState>
  ) {
    this.columns = [
      { name: 'Grade', prop: 'grade' },
      // { name: 'CreatedDate', prop: 'createdDate' },
      // { name: 'Created By', prop: 'createdBy' },
    ]
  }

  ngOnInit() {
    this.store.pipe(select(getParamsValuesListByName('Clarity'))).pipe().subscribe(data => {
      if (!!data) {
        console.log(data);
        this.data = [data];
        this.criteriaParameterHead.push('Clarity')
      }
    });
    this.criteriaParameterLists = this.data.map((dt) => {
      return {
        "parameterName": "Clarity",
        startIndex: null,
        endIndex: null,
        paramValues: dt?.map((pv) => {
          return { ...pv, isSelected: false };
        }),
      };
    });

    console.log(this.criteriaParameterLists);
    this.fetchData();
  }


  onAdd() {
    this.showGrid = false;
    this.isEdit = false;
    this.criteriaParameterLists = this.data.map((dt) => {
      return {
        "parameterName": "Clarity",
        startIndex: null,
        endIndex: null,
        paramValues: dt?.map((pv) => {
          return { ...pv, isSelected: false };
        }),
      };
    });

    this.selectedCriteria = [];
  }

  onEdit(event) {
    this.isEdit = true
    this.showGrid = false;
    let editData = JSON.parse(event.row.data.criteria);
    console.log(editData);
    this.sizeTo = editData[0]?.range?.['polished cts']?.toSize;
    this.sizeFrom = editData[0]?.range?.['polished cts']?.fromSize;
    this.selectedCriteria = editData[0].priorityRange.clarity.selectedData;
    this.criteriaParameterLists = editData[0].priorityRange.clarity.criteriaParameterLists
    this.Grade = event?.row?.data?.grade
    this.updateID = event.row?.data?.id;

  }

  onBack() {
    this.showGrid = true;
    this.Grade = null;
    this.sizeFrom = null;
    this.sizeTo = null;
    this.selectedCriteria?.[0]?.paramValues?.map((pv) => {
      pv.isSelected = false;
    })
  }

  delete(event) {
    const id = event.row.data.id;
    this.httpService.deleteData(true, `checker-polish-grade/remove/${id}`).subscribe(
      (res) => {
        if (res.statusCode === 200) {
          this.fetchData();
          this.configService.showToast("success", 'Data Deleted Successfully!');
        } else {
          this.configService.showToast("error", 'Error In Deleting Data!');
        }
      },
      (error) => {
        console.error('Error deleting data:', error);
        this.configService.showToast("error", 'Error In Deleting Data!');
      }
    );
  }



  async fetchData() {
    const res = await this.httpService.fetchDataUsingPromise(true, 'checker-polish-grade/findAll');
    if (res?.data?.length) {
      this.dataSource = res.data;
    }
    else
      this.configService.showToast("error", ('No data found!'));
  }

  onSelectionChanged(event) {

  }

  async onSelectParamater(parameter, value) {
    if (
      !!this.criteriaParameterLists &&
      this.criteriaParameterLists.length > 0
    ) {

      //range logic

      let startIndex, endIndex;
      this.criteriaParameterLists.map((cpl) => {

        if (cpl.parameterName === parameter.parameterName) {
          if (cpl?.startIndex != null && cpl?.endIndex != null) {
            cpl.startIndex = startIndex = null;
            cpl.endIndex = endIndex = null;
          }
          if (cpl?.startIndex != null && cpl?.endIndex == null) {
            let index = cpl?.paramValues?.findIndex(
              (val) => val.id === value.id
            );
            if (index > -1) {
              cpl.endIndex = endIndex = index;
            }
          } else if (cpl?.endIndex != null && endIndex == null) {
            endIndex = cpl.endIndex;
          }
          if (cpl?.startIndex == null) {
            let index = cpl?.paramValues?.findIndex(
              (val) => val.id === value.id
            );
            if (index > -1) {
              cpl.startIndex = startIndex = index;
            }
          } else if (startIndex == null) {
            startIndex = cpl.startIndex;
          }
          if (startIndex != null && endIndex == null) {
            cpl?.paramValues?.map((pv, i) => (pv.isSelected = false));
            cpl.paramValues[startIndex].isSelected = true;
          } else if (startIndex != null && endIndex != null) {
            cpl?.paramValues?.map((pv, i) => {
              pv.isSelected = false;
              if (i >= startIndex && i <= endIndex) {
                pv.isSelected = !pv.isSelected;
              }
            });
          }
        }
      });
      // normal select
      // const selectedParamDoc = this.criteriaParameterLists.find((el: any) => el['parameterNameId'] === value['paramNameId'])
      // const selectedParamDocValues = selectedParamDoc.paramValues.find((el: any) => el.id === value?.id)
      // selectedParamDocValues.isSelected = !selectedParamDocValues.isSelected
    }


    let selectedValues = this.criteriaParameterLists.map((cpl) => ({
      ...cpl,
      paramValues: cpl.paramValues.filter((pv) => pv.isSelected),
    }));
    this.selectedCriteria = selectedValues.filter(
      (item) => item.paramValues.length > 0
    );

    // let newArray = [];
    // this.selectedCriteria = await this.selectedCriteria.map((x) => {
    //   if (x.parameterName) {
    //     let obj = {};
    //     obj[x.parameterName] = {
    //       value: [],
    //     };
    //     let valuesArray = x.paramValues.map((y) => y.id);
    //     obj[x.parameterName]['value'] = valuesArray;
    //     newArray.push(obj);
    //   }
    // });
    // let newObject = {};

    // newArray.forEach((obj) => {
    //   const key = Object.keys(obj)[0];
    //   const value = obj[key];
    //   if (!newObject[key]) {
    //     newObject[key] = value;
    //   } else {
    //     newObject[key].value = newObject[key].value.concat(value.value);
    //   }
    // });


  }


  async onSave(type) {
    console.log(this.selectedCriteria);
    return
    let payload;

    if (type === 'save') {
      payload = [
        {
          "grade": this.Grade,
          "criteria": JSON.stringify([
            {
              "priorityRange": {
                "clarity": {
                  "paramNameId": null,
                  "value": {
                    "lowerPriorityLimitId": this.selectedCriteria[0].paramValues[0].priority,
                    "upperPriorityLimitId": this.selectedCriteria[0].paramValues[this.selectedCriteria[0].paramValues.length - 1].priority
                  },
                  'selectedData': this.selectedCriteria,
                  'criteriaParameterLists': this.criteriaParameterLists
                }
              },
              "range": {
                "polished cts": {
                  "paramNameId": null,
                  "value": {
                    "lowerPriorityLimitId": this.sizeFrom,
                    "upperPriorityLimitId": this.sizeTo
                  },
                  "toSize": this.sizeTo,
                  "fromSize": this.sizeFrom
                }
              }
            }
          ])
        }
      ]
    }

    else if (type === 'update') {
      payload = [
        {
          'id': this.updateID,
          "grade": this.Grade,
          "criteria": JSON.stringify([
            {
              "priorityRange": {
                "clarity": {
                  "paramNameId": null,
                  "value": {
                    "lowerPriorityLimitId": this.selectedCriteria[0].paramValues[0].priority,
                    "upperPriorityLimitId": this.selectedCriteria[0].paramValues[this.selectedCriteria[0].paramValues.length - 1].priority
                  },
                  'selectedData': this.selectedCriteria,
                  'criteriaParameterLists': this.criteriaParameterLists
                }
              },
              "range": {
                "polished cts": {
                  "paramNameId": null,
                  "value": {
                    "lowerPriorityLimitId": this.sizeFrom,
                    "upperPriorityLimitId": this.sizeTo
                  },
                  "toSize": this.sizeTo,
                  "fromSize": this.sizeFrom
                }
              }
            }
          ])
        }
      ]
    }
    let response = await this.httpService.postDataUsingPromise(
      true,
      'checker-polish-grade/save',
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data = response.message;
      this.onBack()
      this.fetchData();
      this.configService.showToast('success', data);
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }

  }

}
