import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FilterModule } from '../pipes/filter/filter.module';
import { SingleMultiListComponent } from './single-multi-list.component';



@NgModule({
  declarations: [SingleMultiListComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    FilterModule
  ],
  exports: [
    SingleMultiListComponent
  ]
})
export class SingleMultiListModule { }
