import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { HttpService } from "../services/http/http.service";
import { PopoverController } from "@ionic/angular";
import { ConfigService } from "../services/config/config.service";
import { LoaderService } from "../services/loader/loader.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-kg-lot-allocation",
  templateUrl: "./kg-lot-allocation.component.html",
  styleUrls: ["./kg-lot-allocation.component.scss"],
})
export class KgLotAllocationComponent implements OnInit {
  lotType: any = "Auto";
  roughTypes = [];
  countries = [];
  categories = [];
  NoofGroupLot: any = 0;
  NameofGroupLot: string = "";
  ncodes = [];
  dtcSelection = [
    {
      Name: "DTC",
      value: "DTC",
    },
    {
      Name: "Non DTC",
      value: "Non DTC",
    },
  ];
  columns: any[] = [
    { name: "lotNo", prop: "lotNo" },
    { name: "lotCategory", prop: "lotCategory" },
    { name: "roughType", prop: "roughType" },
    { name: "Created By", prop: "createdBy" },
    { name: "CreatedDate", prop: "createdDate" },
  ];
  showCreateLotPage: boolean = false;
  isinfoLot: boolean = false;
  lotdata: any;
  LotCreationFormData = {
    lotNo: this.NameofGroupLot,
    lotCategoryId: null,
    CmString: "",
    month: "",
    nCodeId: null,
    CooString: "",
    cts: 0,
    rough: "",
    FmString: "",
    isGroup: 1,
    roughTypeId: null,
    dtcNonDtc: "DTC",
    countryId: null,
    noOfLots: "",
  };
  numberOfBoxes: number = 0;
  boxes: any[] = [];
  Carat: any;
  dynamicBoxes: any = 1;
  showTable: boolean = false;
  inputValues: any[] = [{}];

  constructor(
    private httpService: HttpService,
    public popoverController: PopoverController,
    public configService: ConfigService,
    private _ConfigService: ConfigService,
    private loaderService: LoaderService,
    private router: Router
  ) {}

  filterChange(ev: any) {
    if (ev?.arrayList?.length) {
      let property = ev.property;
      let idList = ev.idList;
      let selected = ev.arrayList;
      if (property === "Category") {
        // department dropdown
        this.LotCreationFormData.lotCategoryId = ev.arrayList[0]?.id;
      } else if (property === "Rough Type") {
        this.LotCreationFormData.roughTypeId = ev.arrayList[0]?.id;
      } else if (property === "Country") {
        this.LotCreationFormData.countryId = ev.arrayList[0]?.id;
      } else if (property === "Ncode") {
        this.LotCreationFormData.nCodeId = ev.arrayList[0]?.id;
      } else {
      }
    } else {
      this.roughTypes = [];
    }
  }

  async getRoughType() {
    const res = await this.httpService.fetchDataUsingPromise(true, "rough-lot/getRoughType");
    if (res?.data?.length) {
      this.roughTypes = res.data;
    } else this._ConfigService.showToast("error", "No data found!");
  }

  async getCategory() {
    const res = await this.httpService.fetchDataUsingPromise(true, "rough-lot/getCategory");
    if (res?.data?.length) {
      this.categories = res.data;
    } else this._ConfigService.showToast("error", "No data found!");
  }

  generateBoxes() {
    if (isNaN(this.NoofGroupLot) || this.NoofGroupLot < 0) {
      this._ConfigService.showToast("error", "Number of Lots must be a valid non-negative number");
      return;
    }
    this.showTable = true;
    this.numberOfBoxes = this.NoofGroupLot;
    this.boxes = [];
    for (let i = 0; i < this.numberOfBoxes; i++) {
      let lotDefaultValue = this.LotCreationFormData?.lotNo + "-" + (i + 1);
      if (this.NoofGroupLot == 1) {
        lotDefaultValue = this.LotCreationFormData?.lotNo;
      }
      console.log(lotDefaultValue);
      this.boxes.push({ lotNo: lotDefaultValue, pcs: "", predlimit: "", cts: "" });
    }
  }

  async getCountries() {
    const res = await this.httpService.fetchDataUsingPromise(true, "rough-lot/getCountries");
    if (res?.data?.length) {
      this.countries = res.data;
    } else {
      this._ConfigService.showToast("error", "No data found!");
    }
  }

  calculateDefaultLotNo(index: number): string {
    const prefix = this.NameofGroupLot || "";
    return prefix + (index + 1);
  }

  async getNCode() {
    const res = await this.httpService.fetchDataUsingPromise(true, "rough-lot/getNCode");
    if (res?.data?.length) {
      this.ncodes = res.data;
    } else {
      this._ConfigService.showToast("error", "No data found!");
    }
  }

  updateInputValues(index: number, key: string, event: any) {
    const newValue = event?.target?.value || null;
    if (!this.inputValues[index]) {
      this.inputValues[index] = {};
    }
    this.inputValues[index][key] = newValue;
  }

  setDefaultName() {
    this.LotCreationFormData.lotNo = this.NameofGroupLot;
  }

  addDynamicInputs(numInputs: number) {
    this.inputValues = new Array(numInputs).fill({}).map(() => ({}));
  }

  selectBox(type: string) {
    let month = moment().month() + 1;
    this.LotCreationFormData.month = month.toString();
    if (type == "Auto") {
      this.LotCreationFormData.rough = "";
    } else {
      this.LotCreationFormData.rough = "";
    }
  }

  keyUp(event: any) {
    const pattern = /[0-9\+\ ]/;
    let inputChar = String.fromCharCode(event.key);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.fetchGridData();
    this.selectBox("Auto");
    this.getRoughType();
    this.getCategory();
    this.getCountries();
    this.getNCode();
  }

  cancel() {
    this.showCreateLotPage = false;
    this.NoofGroupLot =  "";
    this.NameofGroupLot =""
    this.LotCreationFormData = {
      lotNo: this.NameofGroupLot,
      lotCategoryId: null,
      CmString: "",
      month: "",
      nCodeId: null,
      CooString: "",
      cts: 0,
      rough: "",
      FmString: "",
      isGroup: 1,
      roughTypeId: null,
      dtcNonDtc: "DTC",
      countryId: null,
      noOfLots: "",
    };
  }

  showcreateLotpage() {
    this.showCreateLotPage = true;
    this.isinfoLot = false;

  }

  infoLot(event) {
    this.showCreateLotPage = true;
    this.isinfoLot = true;

    this.NoofGroupLot = event.row.data.noOfLots;
    this.NameofGroupLot = event.row.data.lotNo || event.row.data.NameofGroupLot;

    const lotData = event.row.data;
    this.LotCreationFormData = {
      lotNo: lotData.lotNo || this.NameofGroupLot,
      lotCategoryId: lotData.lotCategory || null,
      CmString: lotData.CmString || "",
      month: lotData.month || "",
      nCodeId: lotData.nCode || null,
      CooString: lotData.CooString || "",
      cts: lotData.cts || 0,
      rough: lotData.rough || "",
      FmString: lotData.FmString || "",
      isGroup: 1,
      roughTypeId: lotData.roughType || null,
      dtcNonDtc: lotData.dtcNonDtc || "DTC",
      countryId: lotData.coutry || null,
      noOfLots: lotData.noOfLots || "",
    };
  }

  async fetchGridData() {
    try {
      let endpoint: string = `rough-lot/getParentLots`;
      const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
        if (resultCode == 200) {
          console.log(res.data);
          if (res.data.length == 0) {
            this.configService.showToast("Error", "No Data Available");
          }
          this.lotdata = res.data;
        } else {
          this.configService.showToast("Error", res.error);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("Error", "Something went wrong!");
    } finally {
    }
  }

  async createLot() {
    this.LotCreationFormData.noOfLots = this.NoofGroupLot;
    let endpoint: string = "rough-lot/create";
    let finalPayload = [];
    let item = JSON.parse(JSON.stringify(this.LotCreationFormData));
    finalPayload[0] = JSON.parse(JSON.stringify(item));
    delete item.isGroup;
    delete item.noOfLots;
    delete item.cts;
    delete item.lotNo;
    for (let element of this.boxes) {
      let finalObj = Object.assign({}, element, item);
      finalPayload.push(finalObj);
    }
    console.log(finalPayload);
    let response = await this.httpService.postDataUsingPromise(true, endpoint, finalPayload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      // this.router.navigate(["/home"]);
      this.showCreateLotPage = false;
      this._ConfigService.showToast("success", response.message);
    } else {
      this._ConfigService.showToast("error", response.message || "Something went wrong!");
    }
  }
}
