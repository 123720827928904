import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthState } from ".";

export const selectAuthState = createFeatureSelector<AuthState>(
    'auth',
);

export const authUser = createSelector(selectAuthState, state => {
    return state.user;
});

export const authUserId = createSelector(selectAuthState, state => {
    console.log(state.user)
    if (state.user && (state.user.id || state.user.userId)) {
        let userId;
        if (state.user.id) {
            userId = state.user.id
        }
        if (state.user.userId) {
            userId = state.user.userId
        }
        return userId
    }
});

export const actions = createSelector(selectAuthState, state => {
    return state.user?.listOfActions;
});

export const actionlist = createSelector(selectAuthState, state => {
    return state.user?.listOfActions;
});
export const acessToken = createSelector(selectAuthState, state => {
    return !!state.user && state.user.access_token;
});
export const authLoader = createSelector(selectAuthState, state => {
    return state.isLoading;
});
export const getUserSubmittedPlans = createSelector(selectAuthState, state => {
    return state.user?.submittedPlans;
});
export const getIfUserIsManager = createSelector(selectAuthState, state => {
    return state.user?.isManager;
});

