import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { select, Store } from '@ngrx/store';
import * as moment from "moment";
import { authUser } from 'src/app/authStore/auth.selector';
import { AppState } from 'src/app/reducers';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PDComponent } from '../pd/pd.component';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';



@Component({
  selector: 'app-signer-planning-history',
  templateUrl: './signer-planning-history.component.html',
  styleUrls: ['./signer-planning-history.component.scss'],
})
export class SignerPlanningHistoryComponent implements OnInit {
  plans:any = [];
  packetPlans = []
  allData = [];
  columns = [];
  userData: any;
  packetId = "2839";
  switchingViews = 0;
  selectedRows = [];
  dataSource: any = [];
  packetid: any;
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  isAllCollapse: boolean = false;
  fromDate = moment().format('YYYY-MM-DD')
  toDate = moment().format('YYYY-MM-DD')
  authService: any;
  configService: any;
  historyData = [];
  allowAction: boolean = false;

  constructor(
    private store: Store,
    public router: Router,
    private modalCtrl: ModalController,
    private storage: Storage,
    private loaderService: LoaderService,
    private authStore: Store<AppState>,
    private httpService: HttpService
  ) {

    this.columns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      { name: 'Date', prop: 'createdDate' },
      { name: "Status", prop: "status" }
    ];
    // this.columns = [
    //   { name: "Packet Id", prop: "packetID" },
    //   { name: "Cts", prop: "cts" },
    //   { name: "CurrentDate", prop: "currentDate" },
    //   { name: "Status", prop: "status"}
    // ];
  }

  ngOnInit() {

    this.store.pipe(select(authUser)).subscribe(d => {
      if (d) {
        this.userData = d;
        console.log('........userData');
        console.log(this.userData);
        this.go()
      }
    });

    // this.dataSource = [{ packetID: "2053", currentDate: "20-25-2021", status: "Bid",cts:"2.8" }, { packetID: "2171", currentDate: "20-25-2021", status: "loose", cts:"1.9" }];
    // //this.httpService.showLoader();

  }

  childRowsClasses(plan) {
    let classes = ''
    if (plan.isExpand) {
      classes = 'childRow show';
    } else {
      classes = 'hide'
    }
  }

  togglingRow() {
    this.isAllCollapse = !this.isAllCollapse ? true : false;
    if (this.isAllCollapse) {
      this.plans = this.plans.map((d: any) => Object.assign({}, d, { isExpand: false }));
    } else {
      this.plans = this.plans.map((d: any) => Object.assign({}, d, { isExpand: true }));
    }
    console.log(this.plans);
  }

  onToggleBtnClick() {
    this.togglingRow();
    console.log(this.isAllCollapse);

  }

  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  twoDecimalvalues(x) {
    return parseFloat(parseFloat(x).toFixed(2));
  }

  async onClickPD(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    const pdModal = await this.modalCtrl.create({
      component: PDComponent,
      cssClass: 'popup',
      componentProps: {
        "hideSubmit": true,
        'data': { packetPlanStoneId: id }
      },
    });

    return await pdModal.present();
    // this.router.navigate(['/PD']);

  }

  back() {
    this.switchingViews = 0;
    console.log("going back to main page");

  }

  onSelectionChanged(event: any) {
    this.selectedRows = event.selectedRowsData;
    console.log(this.selectedRows);
    console.log(this.selectedRows[0].packetID);

  }

  showPlans(e) {

    console.log(e.row.data);
    //this.plans = e.row.data.plans;

    this.plans = e.row.data.plans.map((d: any) => {
      let newPacketPlanStones: any = [];
      let weightSum: number = 0;
      let totalKGRate: number = 0;
      let bestRate: number = 0;
      let totalRate: number = 0;
      let expPolPer1: number = 0;//some of Packet Plan stones/Org WT*100
      let expPolPer2: number = 0;//some of Packet Plan stones/Curr WT*100
      let newOb = { ...d };
      let seqNum = newOb['seqNo']
      if (!!d.packetPlanStones) {
        newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
          let obj = { ...m };
          // obj['piece'] = alpha[i];
          obj['selected'] = false;
          obj['isFileSelected'] = false;
          // obj['isExpand'] = true;
          obj['fluorescence'] = null;
          let kgRate = m.kgRate != null ? m.kgRate : 0;
          // obj['progName'] = '-';
          // obj['progId'] = 0;

          weightSum += parseFloat(m.expPol);
          totalKGRate += parseFloat(kgRate);
          return obj;
        });
      }
      newOb['seqNo'] = isNaN(seqNum) ? seqNum : "";
      newOb['selected'] = false;
      newOb['isExpand'] = true;
      newOb['totExpYield'] = Math.round(weightSum * 100) / 100;//Exp P Yield calculated Field
      newOb['expPolPer1'] = expPolPer1;
      newOb['expPolPer2'] = expPolPer2;
      newOb['packetPlanStones'] = newPacketPlanStones;
      newOb['plannedRemark'] = "";
      newOb['totalKGRate'] = totalKGRate;//totalKGRate/KG Rate calculated Field
      newOb['bestRate'] = bestRate;//Exp P Yield calculated Field
      newOb['totalRate'] = !!totalRate ? totalRate : totalKGRate;//KgRate Need to remove calculated Field
      newOb['bidAmt'] = !!newOb['bidAmt'] ? newOb['bidAmt'] : totalKGRate;//KgRate Need to remove calculated Field
      return newOb;
    });
    this.packetId = e.row.data.packetId;
    this.switchingViews = 1;
    console.log(e.row.data);
    // var i = e.row.data.packetID;

    // if (this.packetId) {
    //   this.store.dispatch(SignerAction.findAllTheBidSubmittedByUser({ packetId: this.packetId, userid: this.userData.id }))
    //   console.log("loading...");
    //   console.log(this.packetId);

    // }

    // console.log(i);
  }

  async go() {
    let obj = {
      "fromDate": this.fromDate,
      "toDate": this.toDate
    }
    let userId = this.userData.userId || this.userData.id;
    // await this.httpService.showLoader();
    
    this.httpService.getUserSubmittedPlans(userId, obj).subscribe(res => {
     
      if (res) {
        
        this.historyData = res.data
      }

      
    })
  }
  
}


