import { TemplateRef } from "@angular/core";

export interface action {
  name: string;
  id: number;
  status: string;
}
export interface searchData {
  name: string;
  id: number;
  status: string;
}

export class Cls_DynamicDialogConfig {
  innerContent!: TemplateRef<any> | null;
  isOpen: boolean = false;
  drawerClass?: string = "";
}
