// Route Master Data
export const routeMasterAPIs = {
    departmenttRuleConfigEndPoint: "department-rule-config/findByRouteId", //GET
    routeMasterEndPoint: "route-master-selection/getAllRouteSelection?limit=300&page=0", //GET
    // http://localhost:3001/route-master-selection/getAllRouteSelection?limit=10&page=0
    // routeMasterEndPoint: "route-master/getAllRoutesInfo", //GET if u wnt names in route selection
    parameterValuesEndpoint: "packet/getStoneParameterMasterValue", //GET
    parametersEndpoint: "packet/getAllParameterAvaiableForCriteria", //GET
    getDeptAndIfDoneDeptOnBasisOfRouteId: "route-master-selection/getDeptAndIfDoneDeptOnBasisOfRouteId/",//GET
    newRouteEndpoint: "route-master/createNewRouteAndCriteria", //GET
    updateRouteEndpoint: "route-master/updateRouteWithId/", //GET
    deleteDetCriterionEndpoint: "department-rule-config/remove", //delete
    deleteRouteEndpoint: "route-master-selection/deleteRouteSelection/", //GET
    saveUpdateRouteEndpoint: 'department-rule-config/saveOrUpdateDepartmentAndProcess',//POST
    saveUpdateRouteSelectionEndpoint: 'route-master-selection/saveRouteSelection',//POST
    routeSelectionEndPoint: "route-master-selection/getAllRouteSelection?limit=500&page=0", //GET
    deleteSelectionRouteEndpoint: "route-master-selection/deleteRouteSelection/", //GET
    defaultDepartmentFlow: "defalut-department-flow/findAll",//GET
    //Process Config APIs
    getAllProcesConfigEndPoint: "processRuleConfig/findAll",//GET
    getStatesEndPoint: "state/findAll",
    getActionsEndPoint: "tbl-action/getAll",
    deleteProcessConfigEndPoint: "processRuleConfig/deleteById",

};

export const operators = [
    { id: 1, title: 'Equals-To', operator: 'equals' },
    { id: 2, title: 'Not-Equals-To', operator: 'notEqual' },
    { id: 3, title: 'Between', operator: 'range' },
    { id: 4, title: 'In', operator: 'in' },
    { id: 5, title: 'Not-In', operator: 'notIn' },
    { id: 6, title: 'Priority-Range', operator: 'priorityRange' }
];
export const preDefinedRouteJSON = {
    "range": {
        "paramName": {
            "paramNameId": null,//number
            "lowerLimit": null,//number
            "upperLimit": null,//number
            "lowerLimitText": null,//string in case of dropdown only
            "upperLimitText": null//string in case of dropdown only
        }
    },
    "priorityRange": {
        "paramName": {
            "paramNameId": null,//number
            "value": {
                "lowerPriorityLimitId": null,//number
                "upperPriorityLimitId": null,//number
                "lowerPriorityLimitText": null,//string in case of dropdown only
                "upperPriorityLimitText": null//string in case of dropdown only
            }
        },
        // "paramName2": {//it can be multiple
        //     "paramNameId": null,//number
        //     "value": {
        //         "lowerPriorityLimitId": null,//number
        //         "upperPriorityLimitId": null,//number
        //     }
        // }
    },
    "equals": {
        // "paramName": null,//string like fresh
        "paramName": { id: null, paramValue: null, paramValueText: null },//string like fresh
        // "paramValueText": null,//string/number in case of dropdown only
    },
    "in": {
        "paramName": {
            "paramNameId": null,//number
            "values": [],//array of number like [21,33,44,5]
            "valuesText": []//array of string like [] in case of dropdown only
        }
    },
    "notIn": {
        "paramName": {
            "paramNameId": null,//number
            "values": [],//array of number like [21,33,44,5]
            "valuesText": []//array of string like [] in case of dropdown only
        }
    },
    "notEqual": {
        // "paramName": null,//string like fresh
        "paramName": { id: null, paramValue: null, paramValueText: null },//string like fresh
        // "paramValueText": null,//string/number in case of dropdown only
    },
    "stoneProperties": null
};
export const routeTypes = [
    // { id: 1, routeType: 'Department Route' },
    // { id: 2, routeType: 'Process Route' }
]
export interface RouteMasterData {
    id: number,
    name: string,
    criterion: any,//json
    fromDate: any,
    createdDate: any
    createdBy: string,
    modifiedDate: any,
    modifiedBy: number,
}
// data modals
/*export interface RouteForm {
    id: number,
    properties: string,//
    routeName: string,
    criterion: object,//json
    routeType: any
    fromDate: any,
    orderKey: string,//department orders
    // modifiedDate: any,
    // modifiedBy: number,
}*/
export interface saveRoutePayLoad {
    // id: number,
    routeName: string,
    routeType: number
    specialKey: string,
    criterion: string,//json
    fromDate: Date,
    departmentList: Array<[]>,
    ifDones: Array<[]>
}
/*{
    "fromDate" : "09-01-2023",
    "routeName" : "testing",
    "routeType" : 1,
    "speacialKey" : "789-97",
    "criterion" : "xyz",
    "departmentList" : [
        {
            "departmentId" : 7
        },{
            "departmentId" : 8,
            "deptCriteria" : "test"
        },{
            "departmentId" : 9,
            "deptCriteria" : "test"
        }
    ],
    "ifDones" : [
        {
            "9" : [7]
        }
    ]
}*/
