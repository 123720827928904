import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from "./home.reducer";
// import { DashboardState } from ".";

// debugger
export const selectDashboardState = createFeatureSelector<DashboardState>(
    'dashboard',
);
export const getAllStoneActionsData = createSelector(selectDashboardState, state => {
    return state.allStoneActions;
});
export const getAllStoneActionsMap = createSelector(selectDashboardState, state => {
    return state.actionMap;
});
export const getAllUsersData = createSelector(selectDashboardState, state => {
    return state.allUsers;
});
export const getIncomingPacketsData = createSelector(selectDashboardState, state => {
    return state.incomingStones;
});

export const getIncomingPacketsDataVoucherWise = createSelector(selectDashboardState, state => {
    return state.incomongStonesVoucherWise;
});

export const getIncomingPacketsLen = createSelector(selectDashboardState, state => {
    return state.totalIncomingStones;
});
export const getOutgoingPacketsData = createSelector(selectDashboardState, state => {
    return state.outgoingStones;
});
export const getOutgoingPacketsDatalen = createSelector(selectDashboardState, state => {
    return state.totalOutGoingStones;
});
export const getReceivedPacketsData = createSelector(selectDashboardState, (state) => state.receivedStones);//with me
export const getReceivedPacketsDataLen = createSelector(selectDashboardState, (state) => state.totalReceivedStones);//with me
export const getUserActionsData = createSelector(selectDashboardState, (state) => {
    return state.userActions
});//with me

export const getLockedPacketData = createSelector(selectDashboardState, (state) => {
    return state.lockedPacketData
});
export const actionsStatusList = createSelector(selectDashboardState, state => {
    return state.actionsStatusList;
});

export const getActionFormByName = (actionName) => createSelector(selectDashboardState, state => {
    let actionData = state.userActions.filter(x => x.actionName == actionName)[0];
    if (actionData.actionForm) {
        return actionData.actionForm
    } else {
        return false
    }
})

export const getFinalPlanBYPacketID = (packetId) => createSelector(selectDashboardState, state => {
    let data = state.receivedStones.filter(x => x.id == packetId)[0]
    if (data) {
        return data
    } else {
        return false
    }
})

export const getPendingPacketsData = (actionId) => createSelector(selectDashboardState, state => {
    console.log(actionId)
    console.log(state.actionsStatusList)
    let packetData = state.actionsStatusList.filter(x => x.id == actionId)[0];
    console.log(packetData)
    if (packetData) {
        if (Array.isArray(packetData.packetList)) {
            return packetData.packetList.filter(i => i.historyStatus == 'Pending')
        }
    }
})


export const getCompletedPacketsData = (actionId) => createSelector(selectDashboardState, state => {
    console.log(actionId);
    console.log(state);
    let packetData = state.actionsStatusList.filter(x => x.id == actionId)[0];
    console.log(packetData);
    if (packetData) {
        if (Array.isArray(packetData.packetList)) {
            return packetData.packetList.filter(i => i.historyStatus == 'Completed')
        }
    }
})

export const getPacketDataByActionId = (actionId) => createSelector(selectDashboardState, state => {
    let packetData = state.actionsStatusList.filter(x => x.id == actionId)[0];
    if (packetData) {
        if (Array.isArray(packetData.packetList)) {
            return packetData.packetList
        }
    }
})

export const packetCounts = createSelector(selectDashboardState, state => {
    return state.packetCount;
});

export const getUrlsOfStnFilesfromIdsSelector = createSelector(selectDashboardState, state => {
    return state.listOfUrlForStnFiles;
})
export const isAllWithMeData = createSelector(selectDashboardState, state => {
    return state.isAllDataClicked;
})