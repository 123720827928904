import { createAction, props } from "@ngrx/store";
import { parameterDetails } from "src/app/masterDataStore/master.data";
import { actionsStatusList, IncomingStones, OutgoingStones, packetCount, ReceivedStones, UserActions } from "src/app/models/incoming-stone.models";

export const getIncomingPackets = createAction("[Dashboard] Get Incoming Packets", props<{ limit: any }>());
export const saveIncomingPackets = createAction("[Dashboard] Incoming Packets saved", props<{ incomingStones: IncomingStones[]; totalIncomingStones: number }>());
export const getOutgoingPackets = createAction("[Dashboard] Get Outgoing Packets", props<{ limit: any }>());

export const saveOutgoingPackets = createAction("[Dashboard] Outgoing Packets saved", props<{ outgoingStones: OutgoingStones[]; totalOutgoingStones: number }>());

export const getReceivedPackets = createAction("[Dashboard] Get Received Packets", props<{ limit: any }>());
export const saveReceivedPackets = createAction("[Dashboard] Received Packets saved", props<{ receivedStones: ReceivedStones[]; totalReceivedStones: number }>());
export const issuePacket = createAction("[Dashboard] Issue Packets called", props<{ packetDetails: any; isProcessVirtual: number; pageInfo: any }>());
export const issueVirtualPacket = createAction("[Dashboard] Issue Virtual Packets called", props<{ packetDetails: any }>());

export const savePacketTransferDetails = createAction("[Dashboard]  Issued Packets details saved", props<{ transferVoucherDetails: any }>());
export const getUpdatedReceivedPackets = createAction("[Dashboard] Get update received Packets from WithMe", props<{ packetIDs: any }>());
export const getPacketCount = createAction("[Dashboard] packetCount fetched");
export const savePacketCount = createAction("[Dashboard] packetCount saved", props<{ packetCount: packetCount[] }>());
export const getUserActions = createAction("[Dashboard] Get User Actions", props<{ userId: number }>());
export const saveUserActions = createAction("[Dashboard] User Actions saved", props<{ userActions: UserActions[] }>());

export const getLockedPackets = createAction("[Dashboard] Get Locked Packet");
export const saveLockedPackets = createAction("[Dashboard] Save Locked Packet", props<{ lockedPacket: [] }>());

export const parameterSaved = createAction("[Master Data] parameter saved", props<{ parameterDetails: parameterDetails }>());

export const parameterRemoved = createAction("[Master Data] parameter removed", props<{ parameterDetails: any }>());

export const getActionWisePendingAndCompletedPacketsCountList = createAction("[Dashboard] getActionWisePendingAndCompletedPacketsCountList ");

export const saveActionWisePacketStatusList = createAction("[Dashboard] saveActionWisePacketStatusList ", props<{ actionsStatusList: actionsStatusList[] }>());

export const getActionWisePacketList = createAction("[Dashboard] getActionWisePacketList ", props<{ actionId: number }>());

export const saveActionWisePacketList = createAction("[Dashboard] saveActionWisePacketList ", props<{ actionPacketList: any; actionId: number }>());

export const getActionNStatusWisePacketList = createAction("[Dashboard] getActionNStatusWise PacketList", props<{ actionId: number; status: string }>());

export const saveActionNStatusWisePacketList = createAction("[Dashboard] saveActionNStatusWise PacketList", props<{ actionPacketList: any; actionId: number }>());
export const submitParameter = createAction("[Dashboard] submit parameters and fetch received PacketList", props<{ actionId: number; userId: number }>());

export const getAllStoneActions = createAction("[Dashboard] get all stone actions");
export const saveAllStoneActions = createAction("[Dashboard] all stone actions saved", props<{ allStoneActions: any }>());
export const getAllUsers = createAction("[Dashboard] get all users");
export const saveAllUsers = createAction("[Dashboard] all users saved", props<{ allUsers: any }>());

export const getPacketsHistory = createAction("[Dashboard] Get Packets History ");

export const savePacketsHistory = createAction("[Dashboard] Packets History saved", props<{ packetsHistory: [] }>());

export const getPacketPlanStoneIfIsFinal = createAction("[Dashboard] get Packet PlanStone If Is Final", props<{ packetId: any }>());

export const saveFinalPlans = createAction("[Dashboard] saveFinalPlans", props<{ plans: any; packetId: any }>());
export const getIncomingPacketsVoucherwisePacket = createAction("[Dashboard] Get Incoming Packets Voucher wise", props<{ voucherId: any }>());
export const saveIncomingPacketsVoucherwise = createAction("[Dashboard] Incoming Packets saved Packets Voucher wise", props<{ incomingStonesVoucherwise: [] }>());
export const requestForStone = createAction("[Dashboard] Ask for Stone", props<{ data: any }>());

export const getUrlsOfStnFilesfromIds = createAction("[Dashboard] get all the urls of given ids for stn files", props<{ arrayOfIds: [] }>());

export const clearUrlsOfStnFilesfromIds = createAction("[Dashboard] clear all the urls of given ids for stn files");

export const saveUrlsOfStnFilesfromIds = createAction("[Dashboard] save all the urls of given ids for stn files", props<{ arrayOfUrls: [] }>());

export const isAllPacketIsSelected = createAction("[Button Component] Button Clicked", props<{ isAllData: boolean }>());
