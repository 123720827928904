import { EventEmitter, Injectable, Output } from '@angular/core';
import { ToastController } from "@ionic/angular";
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';
// import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public mode = environment.mode;
  public UMbaseURL : string = environment.UMBaseUrl;
  public baseURL : string = environment.baseUrl;
  @Output() backClickedEvent = new EventEmitter<string>();
  public scrollPosition;

  private scrollSource = new BehaviorSubject(0);
  scrollChanged = this.scrollSource.asObservable();

  constructor(private storage: Storage, private toastCtrl: ToastController) { }

  changeScroll(val) {
    this.scrollSource.next(this.scrollPosition)
  }

  backClicked(data) {
    this.backClickedEvent.emit(data);
  }

  async getBaseUrl() {
    return this.UMbaseURL;
    if (this.mode == "Production") {
      return "https://user-management.trybe-app.com/";
    } else if (this.mode == "Dev") {
      return "https://lattice-dev.atishae.com/"
    } else {
      return "https://lattice-dev.atishae.com/"
    }
  }
  async getBaseLatticeProdUrl() {
    return "https://user-management.trybe-app.com/";
  }
   getTrybeBaseUrl() {
    return this.baseURL;
    if (this.mode == "Production") {
      return "https://api.trybe-app.com/";
    } else if (this.mode == "Dev") {
      // return 'https://trybe-dev.atishae.com/';// Base URL changes now
      return 'https://api.trybe-dev.atishae.com/';
    } else {
      return "http://localhost:3000/";
    }
  }

  getCentralFileUrl(){
    return environment.centralFileUrl;
  }

  getPricingUrl(){
    return environment.PricingUrl
  }

  async showToast(status, message) {
    if (status == "success") {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        // color: "success"
        cssClass: 'trybe-toast-success'
      });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        // color: "danger"
        cssClass: "trybe-toast-error"
      });
      await toast.present();
    }
  }
  async print() {
    let css = '@media print { @page { size: A4; } }';

    if (document.getElementsByTagName("app-printskelton").length != 0) {
      css = '@media print { @page { size: A5; } }';
    }
    if (!!document.getElementById("stylePrint")) {
      document.getElementById("stylePrint").innerHTML = css;
    } else {
      let head = document.head || document.getElementsByTagName('head')[0],
        style: any = document.createElement('style');

      head.appendChild(style);

      style.type = 'text/css';
      style.id = 'stylePrint';
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
    console.log("============>", css)
    window.print();
  }

  createExcel(data: any[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    const url: string = window.URL.createObjectURL(data);

    downloadLink.href = url;
    downloadLink.download = fileName + '.xlsx';
    downloadLink.click();

    window.URL.revokeObjectURL(url);
    downloadLink.remove();
  }

  
}
