import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfigService } from "../services/config/config.service";
import { HttpService } from "../services/http/http.service";
import { routeMasterAPIs } from "../routemaster/state/routemaster.data";
import { AppState } from "../reducers";
import { Store, select } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { authUser } from "../authStore/auth.selector";
import { LoaderService } from "../services/loader/loader.service";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { ACTIONNAMES, InternalCompany } from "../services/endpoint.enum";
import { confirm } from "devextreme/ui/dialog";
import { ModalController } from "@ionic/angular";
import { UserManagementPopupsComponent } from "./user-management-popups/user-management-popups.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  isEdited: boolean = false;
  @ViewChild("workerGrid", { static: false })
  dropdown: any = {};
  ActionName: any;
  UserName: any;
  password: any;
  Email: any;
  receiveRoles:any;
  issearch: boolean = false;
  refreshGrid: boolean = false;
  addNewWorker: boolean = false;
  addNewStockFlag: boolean = false;
  addNewManagerFlag: boolean = false;
  isDeptSelected: boolean = false;
  actionFormId: any;
  isThirdParty: boolean = false;
  isThirdPartyDepartment: boolean = false;
  isInternal: boolean = false;
  selectedTab: any = "stock controller";
  autoReceive: boolean = false;
  createRoleCheck: boolean = false;
  userTabs = [
    { value: "stock controller", label: "Stock Controller" },
    { value: "manager", label: "Managers" },
    { value: "worker", label: "Workers" },
  ];
  stockContollerColumns = [
    { name: "Username", prop: "fullName", required: true, columnTemplate: "row-click" },
    { name: "Password", prop: "password", required: true, columnTemplate: "row-click" },
    {
      name: "Role",
      prop: "roleId",
      cellTemplate: "multiRoleSelection",
    },
    {
      name: "Active",
      prop: "isActive",
      columnTemplate: "isActive",
    },
  ];

  userColumns: any[] = [
    { name: "Username", prop: "fullName", required: true, columnTemplate: "row-click" },
    { name: "Password", prop: "password", required: true, columnTemplate: "row-click" },
    { name: "Designation", prop: "designation", required: true },
    {
      name: "Role",
      prop: "roleId",
      cellTemplate: "multiRoleSelection",
    },
    {
      name: "Active",
      prop: "isActive",
      columnTemplate: "isActive",
    },
  ];

  userHeading = "Users";
  showAddUser: boolean = false;
  stockControllerData = [];
  managerColumns = [
    { name: "Username", prop: "fullName", columnTemplate: "row-click" },
    { name: "Password", prop: "password", columnTemplate: "row-click" },
    {
      name: "Role",
      prop: "roleId",
      cellTemplate: "multiRoleSelection",
      columnTemplate: "row-click",
    },
    {
      name: "Active",
      prop: "isActive",
      columnTemplate: "isActive",
    },
  ];
  managerData = [];
  workerColumns = [
    { name: "Username", prop: "fullName", columnTemplate: "row-click" },
    { name: "Password", prop: "password", columnTemplate: "row-click" },
    {
      name: "Role",
      prop: "roleId",
      cellTemplate: "multiRoleSelection",
    },
    {
      name: "Grade",
      prop: "gradeId",
      cellTemplate: "multiGradeSelection",
      required: false,
    },
    {
      name: "Auto Receive",
      prop: "isAutoReceive",
      required: false,
      columnTemplate: "check-box",
    },
    {
      name: "Active",
      prop: "isActive",
      columnTemplate: "isActive",
    },
  ];

  signerQueuecolumns = [
    { name: "Full Name", prop: "fullName" },
    { name: "Grades", prop: "grade" },
    { name: "Department Name", prop: "dept" },
    {name: "Packet ID", prop: "packetId" },
    { name: "Request Recieved On", prop: "createdDate" },
    { name: "Request Assigned On", prop: "modifiedDate" },
    { name: "Status", prop: "status" , columnTemplate: "signer-request-status" }, // columnTemplate: "signer-request-status"  columnTemplate: 'chipTemplate'
  ];
  mode: string = "single";
  pageSize = 50;
  page = "Signer Queue";
  workerData = [];
  deptDropdownData: any = {
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],
    key: "name",
    propertyName: "name",
    modeFlag: "single", //single/multiple
  };
  userManagementCards = [
    {
      title: "User",
      description: "Add Users",
    },
    {
      title: "Action",
      description: "Add Actions",
    },
    {
      title: "Department",
      description: "Add Department",
    },
    {
      title: "Role",
      description: "Add Role",
    },
    {
      title: "Grades",
      description: "Edit Grades",
    },
    {
      title: "Signer Request",
      description: "View Signer Request",
    },
    {
      title: "Party Master",
      description: "Party Master",
    },
  ];
  currentBox: any;
  isUpdateDisable: boolean = true;
  addDepartmentFrom: boolean = false;
  RoleName: string;
  DepartmentName: string;
  processes: { id: any; process: any }[];

  dropDownProcessData: any = {
    name: "Process",
    placeText: "Select Action",
    options: [],
    selectedData: [],
    key: "process",
    modeFlag: "multiple", //single/multiple
  };
  dropDownRole: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: [],
    key: "role",
    // modeFlag: 'single', //single/multiple
    modeFlag: "multiple",
  };
  // dropDownActions: any = {}
  dropDownCompany: any = {
    name: "Company",
    placeText: "Select Company",
    options: [],
    selectedData: [],
    key: "companyName",
    modeFlag: "single", //single/multiple
  };

  dropDownDepartment: any = {
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownGrades = [];

  gradesOptions: any = {
    name: "Grades",
    placeText: "Select Grade",
    options: [],
    selectedData: [],
    key: "grade",
    modeFlag: "single",
  };

  columns = [];

  plannerGradeData = [];
  AllPlanners = [];
  selectedDept: any = null;

  signerQueue = [];
  searchTerm = "";
  selectedRows: any = [];
  isDisableSaveAll: boolean = false;

  roleActionArray: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  DeptRoleDropdown: any;
  role: { id: any; role: any }[];
  DeptArray: any;
  company: any;
  roleForUser: any;
  deptForUser: any;
  departments: any;
  showActionForm: boolean = false;
  showDepartmentForm: boolean = false;
  showRoleForm: boolean = false;
  userDept: any;
  selectedDepartmentRole: any = [];
  selectedRole: any;
  isviewAction: boolean = false;
  viewActionId: any;
  iseditDepartment: boolean = false;
  editDepartmentId: any;
  isEditRole: boolean = false;
  editRoleId: any;

  actionGrid = [
    { name: "Active", prop: "isActive", columnTemplate: "isActive" },
    { name: "Action Name", prop: "actionName" },
    { name: "CreatedDate", prop: "createdDate" },
    { name: "createdBy", prop: "createdBy" },
  ];

  departmentGrid = [
    { name: "Active", prop: "isActive", columnTemplate: "isActive" },
    { name: "DepartmentName", prop: "name" },
    { name: "CreatedDate", prop: "createdDate" },
    { name: "createdBy", prop: "createdBy" },
  ];

  roleGrid = [
    { name: "Active", prop: "isActive", columnTemplate: "isActive" },
    { name: "Role", prop: "roleName" },
    { name: "CreatedDate", prop: "createdDate" },
    { name: "createdBy", prop: "createdBy" },
  ];

  usersList: any = [];
  isdDeptStockControllerAvailble: boolean = false;
  userGridColumns = [
    { name: "Name", prop: "fullName" },
    { name: "Designation", prop: "designation" },
    // { name: 'Email', prop: 'email' },
  ];
  userPayload = {
    stockController: {},
    managers: [],
    workers: [],
  };
  actionList: any[];
  departmentList: any[];
  roleList: any[];
  reSelectDeptId: any;
  currAction: any;
  actionFormOption: any;
  userSearchText: string = "";
  environment = environment;
  userData: any;
  searchedUserData: any[] = [];
  selectedDepartment: any[] = [];
  selectedReceiverole : any[] = [];
  constructor(
    public httpService: HttpService,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    public router: Router,
    public configService: ConfigService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.userDepartment;
    this.actionFormOption = [
      {
        id: "Attachment",
        value: "Common Attachments Upload",
      },
      {
        id: "weight",
        value: "Common Weight",
      },
      {
        id: "tick mark",
        value: "Common tick mark",
      },
    ];
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.currAction = action?.name;
          if (this.currAction == ACTIONNAMES.userAdmin) {
            this.currentBox = "HomePage";
          } else if (this.currAction == ACTIONNAMES.signerRequest) {
            this.currentBox = "Signer Request";
            this.getSignerQueue();
          }
        }
      });
    this.fetchAllActions();
    this.fetchAllRole();
    this.fetchAllCompany();
    this.fetchAllDepartment();
    this.fetchGrade();

    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        this.userData = d;
      }
    });
  }

  onCardClick(title: string) {
    console.log(title);
    this.resetData();
    this.currentBox = title;
    if (this.currentBox == "Grades") {
      this.getAllGrades();
      this.getPlannerList();
      this.selectedDept = null;
    } else if (this.currentBox == "Signer Request") {
      this.getSignerQueue();
    } else if (this.currentBox == "Action") {
      this.showActionForm = false;
      this.isviewAction = false;
      this.viewActionId = "";
      this.fetchAllActionData();
    } else if (this.currentBox == "Department") {
      this.showDepartmentForm = false;
      this.iseditDepartment = false;
      this.editDepartmentId = "";
      this.fetchAllDepartment();
    } else if (this.currentBox == "Role") {
      this.showRoleForm = false;
      this.isEditRole = false;
      this.editRoleId = "";
      this.fetchAllRole();
    }
  }

  viewAction(event) {
    // console.log(event);
    this.isviewAction = true;
    this.showActionForm = true;
    this.ActionName = event.row.data.actionName;
    this.viewActionId = event.row.data.id;
    this.autoReceive = event.row.data.isAutoReceive === "1";
    this.isInternal = event.row.data.isThirdParty;
    this.actionFormId = event.row.data.actionForm;
  }

  async editDepartment(event) {
    console.log(event);
    this.iseditDepartment = true;
    this.showDepartmentForm = true;
    this.DepartmentName = event.row.data.name;
    this.editDepartmentId = event.row.data.id;

    this.dropDownRole["selectedData"] = this.dropDownRole["options"]?.filter(element => {
      return element?.id == event.row.data.receiveRole;
    });
    this.dropDownCompany["selectedData"] = this.dropDownCompany["options"]?.filter(element => {
      return element?.id == event.row.data.companyId;
    });

    if (!!this.dropDownRole["selectedData"]) {
      this.DeptArray = this.dropDownRole["selectedData"][0].id;
    }
    // else {
    //   this.DeptArray = [];
    // }

    if (!!this.dropDownCompany["selectedData"]) {
      this.company = this.dropDownCompany["selectedData"][0].id;
    }
    // else {
    //   this.company = [];
    // }
  }

  editRole(event) {
    console.log(event);
    this.dropDownProcessData["selectedData"] = [];
    this.isEditRole = true;
    this.showRoleForm = true;
    this.RoleName = event.row.data.roleName;
    this.editRoleId = event.row.data.id;
    console.log(this.dropDownProcessData);

    this.dropDownProcessData["selectedData"] = this.dropDownProcessData["options"]?.filter(element => event.row.data.actions.map(el => el.actionId).includes(element.id));

    if (!!this.dropDownProcessData["selectedData"]) {
      this.roleActionArray = this.dropDownProcessData["selectedData"][0].id;
    }

    /////
    // this.dropDownProcessData['selectedData'] = this.dropDownProcessData['options']?.filter(
    //   (element) => {
    //     return element?.id == event.row.data.id;
    //   }
    // );
    // if(!!this.dropDownProcessData['selectedData']){
    //   this.roleActionArray = this.dropDownProcessData['selectedData'].id;
    // }
    //////////////
  }

  createAction() {
    this.showActionForm = true;
    this.ActionName = "";
    this.actionFormId = null;
    this.autoReceive = false;
    this.isInternal = false;

  }

  autoReciveCheckboxChange(event: any) {
    this.autoReceive = event.target.checked;
  }

  createRoleCheckboxChange(event: any) {
    this.createRoleCheck = event.target.checked;
  }

  toggleChanged() {
    this.isThirdParty = !this.isInternal;
  }

  createDepartment() {
    this.showDepartmentForm = true;
    this.DepartmentName = "";
    this.dropDownRole["selectedData"] = [];
    this.dropDownCompany["selectedData"] = [];
  }

  createRole() {
    this.showRoleForm = true;
    this.RoleName = "";
    this.dropDownProcessData["selectedData"] = [];
  }

  resetData() {
    this.isEdited = false;
    this.isdDeptStockControllerAvailble = false;
    this.addDepartmentFrom = false;
    this.DeptArray;
    this.stockControllerData = [];
    this.refreshGrid = true;
    this.managerData = [];
    this.workerData = [];
    this.userHeading = "Users";
    this.showAddUser = false;
    this.userDept = "";
    this.userPayload = {
      stockController: {},
      managers: [],
      workers: [],
    };
    this.addNewStockFlag = false;
  }

  onBackBtnClick() {
    if (this.currAction === ACTIONNAMES.signerRequest) {
      this.router.navigate(["/home"]);
    } else if (this.addDepartmentFrom) {
      this.currentBox = "User";
    } else {
      this.currentBox = "HomePage";
    }

    this.resetData();
  }

  addNewUser() {
    this.showAddUser = true;
    this.userHeading = "Add User";
  }

  // async formValidate() {
  //   if (!this.userPayload.stockController.fullName) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  async fetchAllActions() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getActionsEndPoint);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log("%cActions----------", "color:green;font-weight:bold;font-size:1rem;");
      let data: Array<[]> = response.data;
      this.processes = data.map((d: any) => {
        let { id, actionName } = d;
        return { id: id, process: actionName };
      });
      this.processes = this.processes.filter(na => na.process != null);
      this.dropDownProcessData["options"] = [];
      this.dropDownProcessData["options"] = this.processes;
    } else {
      console.error(response);
    }
  }

  async fetchGrade() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "users/getGrade");
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dropDownGrades = [];
      this.dropDownGrades = response.data;
    } else {
      console.error(response);
    }
  }

  async fetchAllDepartment() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "tbl-department/findAll");
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.departmentList = response.data;
      this.dropDownDepartment["options"] = [];
      this.dropDownDepartment["options"] = response.data;
      this.deptDropdownData["options"] = [];
      this.deptDropdownData["options"] = response.data;
      this.departments = response.data;
      this.receiveRoles = response.data;
    } else {
      console.error(response);
    }
  }

  async fetchAllRole() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "tbl-role/getall");
    this.httpService.getErrorAndDisplayIt(response);
    this.roleList = response.data;
    if (response.statusCode === 200) {
      let data: Array<[]> = response.data;
      this.role = data.map((d: any) => {
        let { id, roleName } = d;
        return { id: id, role: roleName };
      });
      this.role = this.role.filter(na => na.role != null);
      this.dropDownRole["options"] = [];
      this.dropDownRole["options"] = this.role;
    } else {
      console.error(response);
    }
  }

  async fetchAllActionData() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "tbl-action/getall");
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      this.actionList = response.data;
    } else {
      console.error(response);
    }
  }

  // async fetchAllDepartmentData(){
  //   let response: any = await this.httpService.fetchDataUsingPromise(
  //     true,
  //     'tbl-department/findAll'
  //   );
  //   this.httpService.getErrorAndDisplayIt(response);
  //   if (response.statusCode === 200) {
  //   console.log('===');
  //   console.log(response.data);
  //    this.actionList = response.data;
  //   console.log('===');
  //   } else {
  //     console.error(response);
  //   }
  // }

  async fetchAllCompany() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "auth/getAllCompany");
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data: Array<[]> = response.data;
      this.company = data.map((d: any) => {
        let { id, companyName } = d;
        return { id: id, companyName: companyName };
      });
      this.company = this.company.filter(na => na.companyName != null);
      this.dropDownCompany["options"] = [];
      this.dropDownCompany["options"] = this.company;
    } else {
      console.error(response);
    }
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }

  dropDownSelection(evt: any, plan: any) {
    console.log(evt);
    this.isDeptSelected = false;
    if (evt.property == "Process") {
      //this.setValuesOfDropdown(evt);
      this.roleActionArray = evt.idList;
    } else if (evt.property == "Role") {
      //this.setValuesOfDropdown(evt);
      this.DeptRoleDropdown = evt.idList;
      this.DeptArray = evt.idList;
      if (this.currentBox == "User") {
        this.deptForUser = evt.idList;
      }
    } else if (evt.property == "Company") {
      //this.setValuesOfDropdown(evt);
      if(evt.arrayList[0].companyName == InternalCompany.facet || evt.arrayList[0].companyName == InternalCompany.tieaDiamond){
        this.isThirdPartyDepartment = false;
      }else this.isThirdPartyDepartment = true;
      
      this.company = evt.idList;
    } else if (evt.property == "Department") {
      this.isDeptSelected = true;
      //this.setValuesOfDropdown(evt);
      if (this.currentBox == "Grades") {
        this.selectedDept = evt.idList[0] ? evt.idList[0] : null;
        this.plannerGradeData = this.filterPlannerDeptWise(this.selectedDept);
      } else {
        this.receiveRoles = this.departmentList.find(d => d.id == evt.idList[0])
        console.log("receive roleeeeeeeee ", this.receiveRoles)
        this.resetData();
        let role = this.dropDownDepartment["options"]
          .filter(x => x.id == evt.idList[0])
          .map(x => {
            if (x.roleName) {
              let n = this.dropDownRole["options"].find(u => u.role == x.roleName)?.id;
              return n;
            }
          });
        this.selectedDepartmentRole = role;
        this.userDept = evt.idList;
        if (evt.idList.length) {
          this.selectedDepartment = evt.idList;
          this.userSearchText = "";
          this.searchedUserData = [];
        }
        if (evt.idList.length == 0) {
          this.userDept = "";
          this.selectedDepartment = evt.idList;
        }
        // this.fetchDeptandDesignationWiseUsers();
        this.segmentChangedForSignerTabs("stock controller", "initial");
      }
    } else {
      console.log("--->>>>");
    }
  }

  async onRoleSave(type) {
    let payload = {};
    // let payload = {
    //   roleName: this.RoleName,
    //   actions: this.roleActionArray,
    // };
    if (type === "save") {
      if (this.roleList.some(role => role.roleName === this.RoleName)) {
        this.configService.showToast("error", "Role with the same name already exists");
        return; // Prevent further execution
      }
      payload = {
        roleName: this.RoleName,
        actions: this.roleActionArray,
      };
    } else if (type === "update") {
      payload = {
        id: this.editRoleId,
        roleName: this.RoleName,
        actions: this.roleActionArray,
      };
    }
    console.log(payload);

    let response = await this.httpService.postDataUsingPromise(true, `tbl-role/createNew`, payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.fetchAllActions();
      this.RoleName = "";
      this.fetchAllRole();
      this.fetchAllCompany();
      this.currentBox = "HomePage";

      // this.configService.showToast('success', 'Role added successfully');
      this.configService.showToast("success", `Role ${type === "save" ? "added" : "updated"} successfully`);
      console.log(response.data);
      let data = response.message;
    } else {
      console.error(response);
    }
  }

  async onDeptSave(type) {
    // console.log(this.DeptArray);
    // console.log(this.company);
    // console.log(this.dropDownCompany);

    let payload = {};
    // let payload = {
    //   departmentName: this.DepartmentName,
    //   roleID: this.DeptArray[0],
    //   companyId: this.company,
    // };
    if (type === "save") {
      if (this.departmentList.some(department => department.name === this.DepartmentName)) {
        this.configService.showToast("error", "Entered Department name already exists ");
        return; // Prevent further execution
      }
      payload = {
        departmentName: this.DepartmentName,
        roleID: Array.isArray(this.DeptArray) ? this.DeptArray[0] : this.DeptArray,
        companyId: Array.isArray(this.company) ? this.company[0] : this.company,
        isThirdParty: this.isThirdPartyDepartment
      };
    } else if (type === "update") {
      payload = {
        id: this.editDepartmentId,
        departmentName: this.DepartmentName,
        roleID: Array.isArray(this.DeptArray) ? this.DeptArray[0] : this.DeptArray,
        companyId: Array.isArray(this.company) ? this.company[0] : this.company,
        isThirdParty: this.isThirdPartyDepartment
      };
    }

    console.log(payload);

    let response = await this.httpService.postDataUsingPromise(true, `tbl-department/createNew`, payload);
    console.log(response);

    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.fetchAllActions();
      this.fetchAllRole();
      this.fetchAllCompany();
      this.currentBox = "HomePage";
      // this.configService.showToast('success', 'Department added successfully');
      this.configService.showToast("success", `Department ${type === "save" ? "added" : "updated"} successfully`);
      if (this.addDepartmentFrom) {
        this.currentBox = "User";
        this.addDepartmentFrom = false;
        this.fetchAllDepartment();
      }
      console.log(response.data);
      let data = response.message;
    } else {
      console.error(response);
    }
  }

  async onActionSave(type) {
    if (!this.ActionName) {
      this.configService.showToast("error", "Action name cannot be empty");
      return;
    }

    let payload = {};
    if (type === "save") {
      if (this.actionList.some(action => action.actionName === this.ActionName)) {
        this.configService.showToast("error", "Action with the same name already exist");
        return; // Prevent further execution
      }
      payload = {
        actionName: this.ActionName,
        isAutoReceive: this.autoReceive ? 1 : 0,
        isCreateRole: this.createRoleCheck ? 1 : 0,
        actionForm: this.actionFormId,
        isThirdParty: this.isInternal ? 1 : 0,
      };
    }
    // else if (type==='update'){
    // //   payload = {
    //     // id: this.viewActionId,
    //     // actionName: this.ActionName,
    //     // isAutoReceive: this.autoReceive ? "1" : "0",
    //     // actionForm :this.actionFormId,
    //     // isThirdParty: this.isInternal ? "1" : "0"
    //   // };
    // }
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, `tbl-action/createNew`, payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.fetchAllActions();
      this.fetchAllRole();
      this.ActionName = "";
      this.fetchAllCompany();
      this.currentBox = "HomePage";
      // this.configService.showToast('success', 'Action Created successfully');
      this.configService.showToast("success", `Action ${type === "save" ? "added" : "updated"} successfully`);
      console.log(response.data);
      let data = response.message;
    } else {
      console.error(response);
    }
  }

  async fetchDeptandDesignationWiseUsers(selectedTab) {
    this.usersList = [];
    this.isdDeptStockControllerAvailble = false;
    if (this.userDept) {
      
      // let response = await this.httpService.fetchDataUsingPromise(
      //   true,
      //   `users/returnListOfUsersByDept/${this.userDept}`
      // );
      let payload = {
        deptId: this.userDept,
        designation: selectedTab,
      };
      let response = await this.httpService.postDataUsingPromise(true, `users/returnListOfUsersByDeptandDesignation`, payload);
      
      await this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        if (!!response.data) {
          return response.data;
          // this.usersList = await this.usersList.map(user => ({
          //   ...user,
          //   password: '***'
          // }));
          // this.stockControllerData = this.usersList
          // .filter((x) => x.designation === 'stock controller')
          // .map((stockController) => ({
          //   fullName: stockController.fullName,
          //   password: stockController.originalPassword,
          //   roleId: stockController.roleId,
          //   company: [stockController.companyId],
          //   departmentId: stockController.departmentId,
          //   ...(stockController.id && { id: stockController.id }),
          // }));
          // if (this.stockControllerData.length > 0) {
          //   this.isdDeptStockControllerAvailble = true;
          // }
          // this.managerData = this.usersList
          //   .filter((x) => x.designation === 'manager transfer')
          //   .map((managerTransfer) => ({
          //     fullName: managerTransfer.fullName,
          //     password: managerTransfer.originalPassword,
          //     roleId: managerTransfer.roleId,
          //     company: [managerTransfer.companyId],
          //     departmentId: [managerTransfer.departmentId],
          //     ...(managerTransfer.id && { id: managerTransfer.id }),
          //   }));
          // this.workerData = this.usersList
          //   .filter((x) => x.designation === 'worker')
          //   .map((worker) => ({
          //     fullName: worker.fullName,
          //     password: worker.originalPassword,
          //     roleId: worker.roleId,
          //     gradeId: worker.gradeId,
          //     company: [worker.companyId],
          //     departmentId: [worker.departmentId],
          //     ...(worker.id && { id: worker.id }),
          //   }));
        }
      } else {
        console.error(response);
      }
    }
  }

  async addDepartment() {
    this.addDepartmentFrom = true;
  }

  async onUserSave() {
    console.log("saved");
    console.log(this.userPayload);
    this.userPayload = {
      stockController: {},
      managers: [],
      workers: [],
    };
    if (!!this.selectedTab && this.selectedTab == "stock controller") {
      this.userPayload.stockController["fullName"] = this.stockControllerData[0].fullName;
      if (!!this.stockControllerData[0].id) {
        this.userPayload.stockController["id"] = this.stockControllerData[0].id;
      }
      this.userPayload.stockController["departmentId"] = [];
      this.userPayload.stockController["departmentId"].push(this.stockControllerData[0].departmentId);
      this.stockControllerData[0].departmentId;
      this.userPayload.stockController["company"] = this.stockControllerData[0].company;
      this.userPayload.stockController["password"] = this.stockControllerData[0].password;
      this.userPayload.stockController["roleId"] = this.stockControllerData[0].roleId;
      this.userPayload.stockController["isActive"] = this.stockControllerData[0].isActive;
      console.log(this.userPayload);
    } else if (!!this.selectedTab && this.selectedTab == "manager") {
      this.userPayload.managers = await this.managerData.map(x => {
        console.log(x);
        let manager = {
          fullName: x.fullName,
          password: x.password,
          roleId: x.roleId,
          departmentId: x.departmentId,
          isVariablePay: x.isVariablePay,
          company: x.company,
          isActive: x.isActive,
        };
        if (x.id) {
          manager["id"] = x.id;
        }
        return x;
      });
    } else if (!!this.selectedTab && this.selectedTab == "worker") {
      this.userPayload.workers = await this.workerData.map(x => {
        let worker = {
          fullName: x.fullName,
          password: x.password,
          roleId: x.roleId,
          gradeId: x.gradeId,
          isAutoReceive: x.isAutoReceive,
          departmentId: x.departmentId,
          isActive: x.isActive,
          isVariablePay: x.isVariablePay,
          company: x.company,
        };
        if (x.id) {
          worker["id"] = x.id;
        }
        return x;
      });
    }
    console.log("this.userPayload---------->", this.userPayload);
    let response = await this.httpService.postDataUsingPromise(true, `users/saveAllUserOfOnDept `, this.userPayload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.configService.showToast("success", " User saved successfully");
      // this.onBackBtnClick();
    } else {
      console.error(response);
    }
    // console.log(payload);
    // let response = await this.httpService.postDataUsingPromise(
    //   true,
    //   `users/createUsers `,
    //   payload
    // );
    // console.log(response);
    // await this.httpService.getErrorAndDisplayIt(response);
    // if (response.statusCode === 200) {
    //   this.fetchAllActions();
    //   this.fetchAllRole();
    //   this.fetchAllCompany();
    //   // this.currentBox = 'HomePage';
    //   this.configService.showToast('success', ' Created successfully');
    //   this.clearForm();
    //   let data = response.message;
    // } else {
    //   console.error(response);
    // }
  }

  clearForm() {
    this.UserName = null;
    this.password = null;
    this.Email = null;

    this.dropDownRole["selectedData"] = [];
    this.dropDownCompany["selectedData"] = [];
    this.dropDownDepartment["selectedData"] = [];
    this.deptDropdownData["selectedData"] = [];
  }

  saveData(alteredData: any, dataName) {
    if (dataName == "Stock Controller") {
      console.log(alteredData);

      if (!!alteredData) {
        let roleId = [...this.dropDownRole["options"]].find(x => x.role === alteredData[0]?.roleId)?.id;
        alteredData = alteredData.map(x => {
          x["departmentId"] = this.userDept?.[0];
          x["company"] = new Array();
          x["company"].push(this.userData.companyId);
          return x;
        });
      }

      this.stockControllerData = alteredData;
      if (this.stockControllerData.length == 0) {
        this.isdDeptStockControllerAvailble = false;
      }
      // this.userPayload.stockController['fullName'] = alteredData[0].fullName;
      // this.userPayload.stockController['departmentId'] = this.userDept;
      // this.userPayload.stockController['company'] = [];
      // this.userPayload.stockController['company'].push(this.userData.companyId);
      // this.userPayload.stockController['password'] = alteredData[0].password;
      // this.userPayload.stockController['roleId'] = [];
      // this.userPayload.stockController['roleId'].push(roleId);
    } else if (dataName == "Manager") {
      alteredData = alteredData.map(x => {
        x["departmentId"] = this.userDept;
        x["company"] = new Array();
        x["company"].push(this.userData.companyId);
        return x;
      });
      this.managerData = alteredData.concat(this.managerData);

      // this.userPayload.managers = this.managerData;
    } else if (dataName == "Worker") {
      console.log(this.workerData);
      alteredData = alteredData.map(x => {
        x["departmentId"] = this.userDept;
        x["company"] = new Array();
        x["company"].push(this.userData.companyId);
        return x;
      });
      this.workerData = alteredData.concat(this.workerData);
      // this.userPayload.workers = this.workerData;
    }
    this.isEdited = true;
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 500);
  }

  async addNewStock() {
    console.log(this.stockControllerData);
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-popups",
      componentProps: {
        data: {
          forPage: "Stock Controller",
          department: this.selectedDepartment,
          roles: this.dropDownRole["options"],
          isReadOnly : false,
          receiveRoles: this.receiveRoles
        },
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.saveData(data, "Stock Controller");

      this.segmentChangedForSignerTabs("stock controller", "initial");
      // this.addNewStock(); //Commenting because only one stock controller will added from front-end 
    }
  }

  editCanceled(event) {
    console.log("here in edit cancle");
    if (!!event && event.Name == "RowRemoved") {
      this.deleteUser(event?.rowData?.data);
    }
    this.addNewStockFlag = false;
    this.isdDeptStockControllerAvailble = false;
    // this.stockControllerData = [];
  }

  async addWorker() {
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-worker",
      componentProps: {
        data: {
          forPage: "Worker",
          selectedTab: this.selectedTab,
          roles: this.dropDownRole["options"],
          department: this.selectedDepartment,
          grades: this.dropDownGrades,
          isReadOnly : false
        },
      },
    });
    modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      this.saveData(data, "Worker");
      this.addWorker();
    }
  }

  async addManager() {
    // this.addNewManagerFlag = true;
    // setTimeout(() => {
    //   this.addNewManagerFlag = false;
    // }, 1000);
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-manager",
      componentProps: {
        data: {
          forPage: "Manager",
          selectedTab: this.selectedTab,
          department: this.selectedDepartment,
          roles: this.dropDownRole["options"],
          isReadOnly : false
        },
      },
    });
    modal.present();

    const { data } = await modal.onWillDismiss();
    if (!data) return;
    this.saveData(data, "Manager");
    this.segmentChangedForSignerTabs("manager", "initial");
    this.addManager();
  }

  async getAllGrades() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "users/getGrade").toPromise();
      this.gradesOptions["options"] = [];
      this.gradesOptions["options"] = data?.data;
      this.columns = [];
      this.gradesOptions?.options?.map((op, i) => this.columns.push({ name: `P${i + 1}`, prop: `p${i + 1}` }));
    } catch (error) {
      console.error("Error fetching user grades data:", error);
    }
  }

  async getPlannerList() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "users/showPlannerUser").toPromise();
      this.plannerGradeData = this.AllPlanners = await data?.data?.map((dt: any) => {
        return {
          ...dt,
          isEdit: false,
          isDisableSave: false,
          gradesName: (dt.grades || [])?.map((gdt: any) => {
            return this.gradesOptions?.options?.find((godt: any) => godt.id == gdt)?.grade || "";
          }),
          gradeModifiedDate: dt.gradeModifiedDate,
        };
      });
      if (!!this.dropDownDepartment && this.dropDownDepartment?.["options"]?.length > 0) {
        this.deptDropdownData["options"] = this.deptDropdownData?.["options"]?.filter(dept => this.AllPlanners.some(planner => planner.departmentId === dept.id));
      }
      this.plannerGradeData = this.filterPlannerDeptWise(this.selectedDept);
    } catch (error) {
      console.error("Error fetching planners data:", error);
    }
  }

  editGrade(currentRow, index) {
    this.filteredData[index].isEdit = !this.filteredData[index]?.isEdit;
  }

  saveGrade(currentRow, index) {
    if (!!currentRow?.grades && currentRow?.grades.length) {
      currentRow.grades = currentRow?.grades.filter((g, i) => i <= this.gradesOptions?.options.length - 1);
      currentRow.grades = currentRow?.grades.filter(g => g != null);
      const duplicates = currentRow?.grades.filter((item, index) => currentRow?.grades.indexOf(item) !== index);
      if (!!duplicates && duplicates.length > 0) this.configService.showToast("error", "Duplicate grade found!");
      else {
        this.filteredData[index].isEdit = !this.filteredData[index].isEdit;
        let currentSelected = {
          userId: currentRow.id,
          grades: currentRow.grades,
        };
        this.saveGradeAPI([currentSelected]);
      }
    } else {
      this.filteredData[index].isEdit = !this.filteredData[index].isEdit;
    }
  }

  onGradeSelection(event, dropdownIndex, rowIndex) {
    var rowData = this.filteredData[rowIndex];
    if (event.idList[0]) {
      const selectedGrade = event.idList[0];
      const gradeName = this.gradesOptions?.options?.find((godt: any) => godt.id == selectedGrade)?.grade || "";
      // rowData.isDisableSave = this.isDisableSaveAll = false;
      if (rowData?.grades?.length) {
        let index = rowData?.grades.findIndex(g => g == selectedGrade);
        // if (index == -1) {
        //   rowData.isDisableSave = this.isDisableSaveAll = false;
        if (rowData?.grades[dropdownIndex] !== undefined) {
          rowData.grades[dropdownIndex] = selectedGrade;
          rowData["gradesName"][dropdownIndex] = gradeName;
        } else {
          rowData?.grades.push(selectedGrade);
          rowData["gradesName"].push(gradeName);
        }
        // }
        // else {
        //   if (!(rowData?.grades[dropdownIndex] == selectedGrade)) {
        //     rowData.isDisableSave = this.isDisableSaveAll = true;
        //     this.configService.showToast('error', 'Selected grade already assigned!')
        //   }
        // }
      } else {
        // rowData.isDisableSave = this.isDisableSaveAll = false;
        rowData["grades"] = event?.idList;
        rowData["gradesName"] = [gradeName];
      }
    } else {
      if (rowData?.grades[dropdownIndex] !== undefined) {
        rowData.grades[dropdownIndex] = null;
      }
    }
  }

  saveAllGrades() {
    this.plannerGradeData.map(pg => {
      if (pg?.isEdit) {
        // pg.isEdit = !pg.isEdit
        this.selectedRows.push({
          userId: pg?.id,
          grades: pg?.grades,
        });
      }
    });

    if (!!this.selectedRows && this.selectedRows.length) {
      let found = false;
      this.selectedRows?.some(item => {
        if (!!item?.grades && item?.grades.length) {
          item.grades = item?.grades.filter((g, i) => i <= this.gradesOptions?.options.length - 1);
          item.grades = item?.grades.filter(g => g != null);
          const duplicates = item?.grades.filter((g, index) => item?.grades.indexOf(g) !== index);
          if (!!duplicates && duplicates.length > 0) {
            found = true;
            this.configService.showToast("error", "Duplicate grade found in one of the planner!");
            return true;
          }
        }
      });

      if (!found) this.saveGradeAPI(this.selectedRows);
    }
  }

  saveGradeAPI(payload) {
    
    this.httpService.updatePlannerGrade(payload).subscribe(
      (res: any) => {
        console.log(res);
        
        if (res.statusCode == 200) {
          this.getPlannerList();
          this.configService.showToast("success", "Planner grades updated successfully");
        } else {
          this.configService.showToast("error", res?.message || "Something went wrong!");
        }
      },
      (err: any) => {
        
        this.configService.showToast("error", err?.message || "Something went wrong!");
      }
    );
  }

  get filteredData() {
    return this.plannerGradeData?.filter(item => item?.fullName?.toString().includes(this.searchTerm));
  }

  async getSignerQueue() {
    try {
      
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "assign-signer/getSignerQueue").toPromise();
      
      this.signerQueue = [];
      this.signerQueue = data?.data;
      // this.signerQueue = data?.data?.map((dt: any) => {
      //   return { ...dt, status: dt.status == 1 ? "Pending" : dt.status == 3 ? "Completed" : dt.status == 2 ? "Limit Reached" : "No Packet Found" };
      // });
      this.departments = this.departments.filter(dp => this.signerQueue.some(sq => sq.dept === dp.name));
    } catch (error) {
      console.error("Error fetching signer data:", error);
    }
  }

  filterPlannerDeptWise(deptId) {
    if (!!this.AllPlanners && this.AllPlanners.length > 0) {
      if (deptId != null) return this.AllPlanners.filter(planner => planner.departmentId == deptId);
      else return this.AllPlanners;
    }
  }

  refreshTableData() {
    this.signerQueue = [];
    this.getSignerQueue();
  }

  areAllStockControllersInactive(): boolean {
    return this.stockControllerData.every(item => !item.isActive);
  }

  async segmentChangedForSignerTabs(event, type) {
    let value;
    if (type == "initial") {
      value = event;
    } else {
      value = event?.detail?.value;
    }
    if (!!value) {
      let selected = (this.selectedTab = value); //.detail.value
      let userList = await this.fetchDeptandDesignationWiseUsers(this.selectedTab);
      if (!!userList && userList?.length > 0) {
        this.stockControllerData = [];
        this.managerData = [];
        this.workerData = [];
        switch (value) {
          case "stock controller":
            this.stockControllerData = userList.map(ul => ({
              fullName: ul.fullName,
              password: ul.password,
              roleId: ul.roleId,
              isActive: ul.isActive,
              company: [ul.companyId],
              departmentId: [ul.departmentId],
              ...(ul.id && { id: ul.id }),
            }));
            break;
          case "manager":
            this.managerData = userList.map(ul => ({
              fullName: ul.fullName,
              password: ul.password,
              roleId: ul.roleId,
              isActive: ul.isActive,
              isVariablePay: ul.isVariablePay,
              company: [ul.companyId],
              departmentId: [ul.departmentId],
              ...(ul.id && { id: ul.id }),
            }));
            break;
          case "worker":
            this.workerData = userList.map(ul => ({
              fullName: ul.fullName,
              password: ul.password,
              roleId: ul.roleId,
              isActive: ul.isActive,
              gradeId: ul.gradeId,
              isAutoReceive: ul.isAutoReceive,
              isVariablePay: ul.isVariablePay,
              company: [ul.companyId],
              departmentId: [ul.departmentId],
              ...(ul.id && { id: ul.id }),
            }));
            break;
        }
      }
    }
  }

  deleteUser(row) {
    
    if (row?.id)
      this.httpService.deleteData(true, `users/${row.id}`).subscribe(
        (res: any) => {
          if (res?.statusCode == 200) {
            this.configService.showToast("success", res?.message || "Deleted successfully!");
          } else this.configService.showToast("error", res?.message || "Something went wrong!");
        },
        (err: any) => {
          this.configService.showToast("error", err?.error?.message || "Something went wrong!");
        }
      );
  }

  onActionActiveChange(event: any) {
    const { data } = event.row;
    confirm(`Do you really want to ${!data?.isActive ? "Enable Action" : "Disable Action"} `, "Warning").then(res => {
      if (res) {
        let payload = JSON.parse(JSON.stringify(data));
        payload.isActive = payload.isActive === 0 ? 1 : 0;
        let response = this.httpService.postDataUsingPromise(true, `tbl-action/createNew`, payload);
        let index = this.actionList.findIndex((el: any) => el.id === data.id);
        this.actionList[index].isActive = payload.isActive;
      } else {
        let index = this.actionList.findIndex((el: any) => el.id === data.id);
        this.actionList[index] = { ...data };
      }
    });
  }

  onRoleActiveChange(event: any) {
    const { data } = event.row;
    confirm(`Do you really want to ${!data?.isActive ? "Enable Role" : "Disable Role"} `, "Warning").then(res => {
      if (res) {
        let payload = JSON.parse(JSON.stringify(data));
        payload.isActive = payload.isActive === 0 ? 1 : 0;
        let response = this.httpService.postDataUsingPromise(true, `tbl-role/createNew`, payload);

        let index = this.roleList.findIndex((el: any) => el.id === data.id);
        this.roleList[index].isActive = payload.isActive;
      } else {
        let index = this.roleList.findIndex((el: any) => el.id === data.id);
        this.roleList[index] = { ...data };
      }
    });
  }

  onDepartmentActiveChange(event: any) {
    const { data } = event.row;
    confirm(`Do you really want to ${!data?.isActive ? "Enable Department" : "Disable Department"} `, "Warning").then(res => {
      if (res) {
        let payload = JSON.parse(JSON.stringify(data));
        payload.isActive = payload.isActive === 0 ? 1 : 0;
        let response = this.httpService.postDataUsingPromise(true, `tbl-department/createNew`, payload);
        let index = this.departmentList.findIndex((el: any) => el.id === data.id);
        this.departmentList[index].isActive = payload.isActive;
      } else {
        let index = this.departmentList.findIndex((el: any) => el.id === data.id);
        this.departmentList[index] = { ...data };
      }
    });
  }

  isActiveChange(type, event) {
    const { data } = event;
    switch (type) {
      case "stockController":
        const element = this.stockControllerData.find((el: any) => el.id === data.id);
        this.onUserActiveChange(element, type);
        break;
      case "manager":
        const mangerElement = this.managerData.find((el: any) => el.id === data.id);
        this.onUserActiveChange(mangerElement, type);
        break;
      case "worker":
        const workerElement = this.workerData.find((el: any) => el.id === data.id);
        this.onUserActiveChange(workerElement, type);
        break;
      case "user":
        this.onUserActiveChange(this.searchedUserData[0], type);
        break;
    }
  }

  onUserActiveChange(element: any, type: string) {
    confirm(`Do you really want to ${!element?.isActive ? "Enable user" : "Disable user"} `, "Warning").then(res => {
      let isActive = res ? (element.isActive == 0 ? 1 : 0) : element.isActive;
      switch (type) {
        case "stockController":
          let stockIndex = this.stockControllerData.findIndex((el: any) => el.id === element.id);
          const stockCopyData = JSON.parse(JSON.stringify(this.stockControllerData));
          stockCopyData[stockIndex].isActive = isActive;
          this.stockControllerData = [...stockCopyData];
          if (res) {
            this.onUserSave();
          }
          break;
        case "manager":
          let managerIndex = this.managerData.findIndex((el: any) => el.id === element.id);
          const managerCopyData = JSON.parse(JSON.stringify(this.managerData));
          managerCopyData[managerIndex].isActive = isActive;
          this.managerData = [...managerCopyData];
          if (res) {
            this.onUserSave();
          }
          break;
        case "worker":
          let workerIndex = this.workerData.findIndex((el: any) => el.id === element.id);
          const workerCopyData = JSON.parse(JSON.stringify(this.workerData));
          workerCopyData[workerIndex].isActive = isActive;
          this.workerData = [...workerCopyData];
          if (res) {
            this.onUserSave();
          }
          break;
        case "user":
          const userCopyData = JSON.parse(JSON.stringify(this.searchedUserData));
          userCopyData[0].isActive = isActive;
          this.searchedUserData = [...userCopyData];
          if (res) {
            this.searchedUserUpdate(userCopyData);
          }
          break;
      }
    });
  }

  async onStockControllerRowClick(event: any) {
    const { row } = event;
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-popups",
      componentProps: {
        data: {
          forPage: "stock controller",
          roles: this.dropDownRole["options"],
          rowData: row.data,
          isReadOnly : true
        },
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const tempData = { ...row.data, ...data[0] };
      this.saveData([tempData], "Stock Controller");
    }
  }

  async onManagerRowClick(event: any) {
    const { row } = event;
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-manager",
      componentProps: {
        data: {
          forPage: "manager",
          roles: this.dropDownRole["options"],
          selectedTab: this.selectedTab,
          department: this.selectedDepartment,
          rowData: row.data,
          isReadOnly : true
        },
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      this.selectedTab = "stock controller";

      let index = this.managerData.findIndex(el => row.data.id === el.id);

      if (index != -1) {
        this.managerData[index] = { ...this.managerData[index], ...data[0] };
      }
    }
  }

  async onWorkerRowClick(event: any) {
    const { row } = event;
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-worker",
      componentProps: {
        data: {
          forPage: "worker",
          roles: this.dropDownRole["options"],
          department: this.selectedDepartment,
          grades: this.dropDownGrades,
          selectedTab: this.selectedTab,
          rowData: row.data,
          isReadOnly : true
        },
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.selectedTab = "stock controller";
      let index = this.workerData.findIndex(el => row.data.id === el.id);
      if (index != -1) {
        this.workerData[index] = { ...this.workerData[index], ...data[0] };
      }
    }
  }

  isAutoReceiveChange(event) {
    const { data } = event;
    confirm(`Do you really want to ${!data.isAutoReceive ? "Enable Auto receive" : "Disable Auto receive"} `, "Warning").then(res => {
      const index = this.workerData.findIndex(el => el.id === data.id);
      if (res) {
        this.workerData[index].isAutoReceive = !data.isAutoReceive;
        this.onSaveUpdateUser([{ ...this.workerData[index], designation: "worker" }]);
      } else {
        if (index !== -1) {
          this.workerData[index] = { ...data };
        }
      }
    });
  }

  async searchUserByUsername() {
    if (!this.userSearchText) {
      this.configService.showToast("warning", "Enter valid UserName");
      return;
    }
    const payload = {
      appName: environment.appName,
      companyName: environment.companyName,
      username: this.userSearchText,
    };
    let response = await this.httpService.postDataUsingPromise(true, `users/getUserDataByUserName`, payload);
    console.log(response);
    const { data } = response;
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      if (!Object.keys(response.data).length) {
        this.configService.showToast("warning", "User Not Found");
        this.searchedUserData = [];
        return;
      }
      this.configService.showToast("success", "User found");
      let extraUserColumns = [
        {
          name: "Department Name",
          prop: "departmentName",
          columnTemplate: "row-click",
        },
        { name: "Designation", prop: "designation", columnTemplate: "row-click" },
      ];
      switch (data.designation) {
        case "stock controller":
          this.userColumns = [...extraUserColumns, ...this.stockContollerColumns];
          break;
        case "manager":
          this.userColumns = [...extraUserColumns, ...this.managerColumns];
          break;
        case "worker":
          this.userColumns = [...extraUserColumns, ...this.workerColumns];
          break;
      }

      this.searchedUserData = [data].map(ul => ({
        fullName: ul.fullName,
        password: ul.password,
        roleId: ul.roleId,
        isActive: ul.isActive,
        gradeId: ul.gradeId,
        isAutoReceive: ul.isAutoReceive,
        isVariablePay: ul.isVariablePay,
        company: [ul.companyId],
        departmentId: [ul.departmentId],
        designation: ul.designation,
        departmentName : ul.departmentName,
        ...(ul.id && { id: ul.id }),
      }));

      this.isDeptSelected = false;
      this.dropDownDepartment.selectedData = [];
    } else {
      this.configService.showToast("error", response);
    }
  }

  async onUserRowClick(event) {
    const { row } = event;
    // row.data.password = row.data?.originalPassword;
    const payload = {
      forPage: this.searchedUserData[0].designation,
      roles: this.dropDownRole["options"],
      department: [row.data.departmentId],
      grades: this.dropDownGrades,
      rowData: row.data,
      isReadOnly : true
    };
    const modal = await this.modalController.create({
      component: UserManagementPopupsComponent,
      cssClass: "user-management-worker",
      componentProps: {
        data: { ...payload },
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      let copyUserData = JSON.parse(JSON.stringify(this.searchedUserData));
      copyUserData[0] = { ...copyUserData[0], ...data[0] };
      this.searchedUserUpdate(copyUserData);
    }
  }

  searchChange() {
    if (!this.userSearchText) {
      this.searchedUserData = [];
    }
  }

  async searchedUserUpdate(payload) {
    payload[0].department = this.selectedDepartment;
    payload[0].company = [this.userData.companyId];
    let response = await this.httpService.postDataUsingPromise(true, `users/saveUpdateUser`, payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.searchedUserData[0] = { ...payload[0] };
      this.configService.showToast("success", " User saved successfully");
      // this.onBackBtnClick();
    } else {
      this.configService.showToast("warning", "Something went wrong");
    }
  }

  async onSaveUpdateUser(data) {
    let response = await this.httpService.postDataUsingPromise(true, `users/saveUpdateUser`, data);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.configService.showToast("success", " User saved successfully");
      // this.onBackBtnClick();
    } else {
      if (response.message.includes("User Found")) this.configService.showToast("error", "User already exist");
      else this.configService.showToast("error", "Something went wrong");
    }
  }

  get userDepartment() {
    console.log(this.userDept, this.selectedDepartment);
    return this.userDept;
  }
}
