import { createReducer, on } from "@ngrx/store";
import { chekerAction } from "src/app/components/trybe-checker/state/checker.action.type";
import { AuthActions } from "../../authStore/auth.action-type";
import { actionsStatusList, IncomingStones, OutgoingStones, packetCount, ReceivedStones, UserActions } from "../../models/incoming-stone.models";
import { HomeActions } from "./home.action-type";

// debugger
export interface DashboardState {
  allStoneActions: any;
  allUsers: any;
  incomingStones: IncomingStones[];
  incomongStonesVoucherWise: [];
  outgoingStones: OutgoingStones[];
  receivedStones: ReceivedStones[];
  userActions: UserActions[];
  actionsStatusList: actionsStatusList[];
  lockedPacketData: any;
  packetCount: packetCount[];
  actionMap: any;
  totalIncomingStones: number;
  totalReceivedStones: number;
  totalOutGoingStones: number;
  listOfUrlForStnFiles: [];
  isAllDataClicked: boolean;
}

export const initialDashboardState: DashboardState = {
  allStoneActions: [],
  allUsers: [],
  incomingStones: [],
  incomongStonesVoucherWise: [],
  totalIncomingStones: 0,
  totalReceivedStones: 0,
  totalOutGoingStones: 0,
  receivedStones: [],
  outgoingStones: [],
  userActions: [],
  lockedPacketData: [],
  actionsStatusList: [],
  packetCount: [],
  actionMap: {},
  listOfUrlForStnFiles: [],
  isAllDataClicked: null,
};

export const dashboardReducer = createReducer(
  initialDashboardState,
  on(AuthActions.logout, (state, action) => {
    return {
      ...state,
      user: null,
      incomingStones: [],
      totalIncomingStones: 0,
      totalReceivedStones: 0,
      totalOutGoingStones: 0,
      receivedStones: [],
      outgoingStones: [],
      userActions: [],
      actionsStatusList: [],
      packetCount: [],
    };
  }),
  on(HomeActions.saveIncomingPackets, (state, action) => {
    console.log("saveIncomingPackets");
    console.log(action);
    return {
      ...state,
      incomingStones: action.incomingStones,
      totalIncomingStones: action.totalIncomingStones,
    };
  }),
  on(HomeActions.saveIncomingPacketsVoucherwise, (state, action) => {
    console.log("saveIncomingPackets");
    console.log(action);
    return {
      ...state,
      incomongStonesVoucherWise: action.incomingStonesVoucherwise,
    };
  }),
  on(HomeActions.saveOutgoingPackets, (state, action) => {
    return {
      ...state,
      outgoingStones: action.outgoingStones,
      totalOutGoingStones: action.totalOutgoingStones,
    };
  }),
  on(HomeActions.saveReceivedPackets, (state, action) => {
    return {
      ...state,
      receivedStones: action.receivedStones,
      totalReceivedStones: action.totalReceivedStones,
    };
  }),
  on(HomeActions.saveUserActions, (state, action) => {
    return {
      ...state,
      userActions: action.userActions,
    };
  }),
  on(HomeActions.saveLockedPackets, (state, action) => {
    return {
      ...state,
      lockedPacketData: action.lockedPacket,
    };
  }),
  on(HomeActions.saveActionWisePacketStatusList, (state, action) => {
    return {
      ...state,
      actionsStatusList: action.actionsStatusList,
    };
  }),
  on(HomeActions.saveActionWisePacketList, (state, action) => {
    let actionsStatusList = [];
    if (!!state.actionsStatusList) {
      actionsStatusList = state.actionsStatusList.map(x => {
        if (x.id == action.actionId) {
          return {
            ...x,
            packetList: action.actionPacketList,
          };
        } else {
          return x;
        }
      });
    }
    return {
      ...state,
      actionsStatusList: actionsStatusList,
    };
  }),
  on(HomeActions.parameterSaved, (state, action) => {
    // console.warn('Issue Check--------');
    // console.log(state);

    console.log(action.parameterDetails.listOfAllPackets);
    let receivedStones = state.receivedStones.map(x => {
      // console.log(x);
      // console.log(x.id);
      let packet: any;
      if (!!action.parameterDetails && !!action.parameterDetails.listOfAllPackets) {
        packet = action.parameterDetails.listOfAllPackets.filter(p => p.packetId == x.id)[0];
      }
      console.log(packet);
      if (!!packet && !!packet.listOfParameter)
        for (let i = 0; i < packet.listOfParameter.length; i++) {
          let parameterName = packet.listOfParameter[i].parameterName;
          let parameterValue = packet.listOfParameter[i].parameterValue;
          if (parameterName == "Fluorescence") {
            console.log(parameterValue);
          }
        }
      return x;
      // if (x.id == action.parameterDetails) {
      //     return {
      //         ...x,
      //         fluroscence: action.parameterDetails.pName
      //     }
      // } else {
      //     return x;
      // }
    });
    return {
      ...state,
      receivedStones: receivedStones,
    };
  }),

  on(HomeActions.parameterRemoved, (state, action) => {
    console.log(action.parameterDetails);
    let receivedStones = state.receivedStones.map(x => {
      if (x.id == action.parameterDetails.packetId) {
        // delete x['fluroscence'];
        return x;
      } else {
        return x;
      }
    });
    return {
      ...state,
      receivedStones: receivedStones,
    };
  }),
  on(HomeActions.saveActionNStatusWisePacketList, (state, action) => {
    console.log("%csaveActionNStatusWisePacketList", "color:orange;font-weight:bold;");
    console.log(action, state.actionsStatusList);
    let actionsStatusList = state.actionsStatusList.map(x => {
      if (x.id == action.actionId) {
        return {
          ...x,
          packetList: action.actionPacketList,
        };
      } else {
        return x;
      }
    });
    console.log(actionsStatusList);
    console.log(state);
    console.log(action);
    return {
      ...state,
      actionsStatusList: actionsStatusList,
    };
  }),
  on(HomeActions.savePacketCount, (state, action) => {
    return {
      ...state,
      packetCount: action.packetCount,
    };
  }),
  on(HomeActions.getUpdatedReceivedPackets, (state, action) => {
    const updateReceivedStones = state.receivedStones.filter(d => action.packetIDs.indexOf(d.id) === -1);
    return {
      ...state,
      receivedStones: updateReceivedStones,
    };
  }),

  on(HomeActions.saveAllStoneActions, (state, action) => {
    let actionMap = {};
    action.allStoneActions.forEach(d => {
      if (actionMap[d.id] == undefined) {
        actionMap[d.id] = d;
      }
    });
    return {
      ...state,
      allStoneActions: action.allStoneActions,
      actionMap: actionMap,
    };
  }),
  on(HomeActions.saveAllUsers, (state, action) => {
    return {
      ...state,
      allUsers: action.allUsers,
    };
  }),

  on(HomeActions.saveFinalPlans, (state, action) => {
    let receivedStones = state.receivedStones.map(x => {
      if (x.id == action.packetId) {
        let alpha: String = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        console.log(state);
        let newPacketPlans = action.plans.map((d: any) => {
          let newPacketPlanStones: any = [];
          let weightSum: number = 0;
          let totalKGRate: number = 0;
          let bestRate: number = 0;
          let totalRate: number = 0;
          let expPolPer1: number = 0; //some of Packet Plan stones/Org WT*100
          let expPolPer2: number = 0; //some of Packet Plan stones/Curr WT*100
          let newOb = { ...d };
          let seqNum = newOb["seqNo"];
          if (!!d.packetPlanStones) {
            newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
              let obj = { ...m };
              obj["piece"] = alpha[i];
              obj["selected"] = false;
              obj["isFileSelected"] = false;
              // obj['isExpand'] = true;
              obj["fluorescence"] = null;
              let kgRate = m.kgRate != null ? m.kgRate : 0;
              // obj['progName'] = '-';
              // obj['progId'] = 0;

              weightSum += parseFloat(m.expPol);
              totalKGRate += parseFloat(kgRate);
              return obj;
            });
          }
          newOb["seqNo"] = isNaN(seqNum) ? seqNum : "";
          newOb["selected"] = false;
          newOb["isExpand"] = true;
          newOb["totExpYield"] = Math.round(weightSum * 100) / 100; //Exp P Yield calculated Field
          newOb["expPolPer1"] = expPolPer1;
          newOb["expPolPer2"] = expPolPer2;
          newOb["packetPlanStones"] = newPacketPlanStones;
          newOb["plannedRemark"] = "";
          newOb["totalKGRate"] = totalKGRate; //totalKGRate/KG Rate calculated Field
          newOb["bestRate"] = bestRate; //Exp P Yield calculated Field
          newOb["totalRate"] = !!totalRate ? totalRate : totalKGRate; //KgRate Need to remove calculated Field
          newOb["bidAmt"] = !!newOb["bidAmt"] ? newOb["bidAmt"] : totalKGRate; //KgRate Need to remove calculated Field
          return newOb;
        });
        return {
          ...x,
          finalPlan: newPacketPlans,
        };
      } else {
        return x;
      }
    });
    return {
      ...state,
      receivedStones: receivedStones,
    };
  }),
  on(chekerAction.removeFinalPlanData, (state, action) => {
    let receivedStones = state.receivedStones.map(x => {
      return {
        ...x,
        finalPlan: null,
      };
    });
    return {
      ...state,
      receivedStones: receivedStones,
    };
  }),
  on(HomeActions.saveUrlsOfStnFilesfromIds, (state, action) => {
    return {
      ...state,
      listOfUrlForStnFiles: action.arrayOfUrls,
    };
  }),
  on(HomeActions.clearUrlsOfStnFilesfromIds, (state, action) => {
    return {
      ...state,
      listOfUrlForStnFiles: [],
    };
  }),
  on(HomeActions.isAllPacketIsSelected, (state, { isAllData }) => ({
    ...state,
    isAllDataClicked: isAllData,
  }))
);
