import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { HeaderComponentModule } from "src/app/components/header/header.module";
import { FooterModule } from "src/app/footer/footer.module";
import { DataGridComponentModule } from "src/app/components/data-grid/data-grid.module";
import { LotAllocationComponent } from "./lot-allocation.component";
import { SingleMultiDropdownModule } from "src/app/components/single-multi-dropdown/single-multi-dropdown.module";
import { SingleMultiDropdownComponent } from "src/app/components/single-multi-dropdown/single-multi-dropdown.component";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        LotAllocationComponent,
    ],
    imports: [
        CommonModule,
        HeaderComponentModule,
        IonicModule,
        FooterModule,
        FormsModule,
        DataGridComponentModule, 
        SingleMultiDropdownModule,
        FormsModule,
    ],
    exports: [
        LotAllocationComponent,
    ],
    entryComponents: [
        SingleMultiDropdownComponent,
    ],
    providers: []
})
export class lotAllocationModule {}