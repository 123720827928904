import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GET_packetInwardList, SAVE_packetInwardLoadSuccess } from "./packet-inward.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { PacketInwardService } from "../../services/packet-inward.service";

@Injectable()
export class PacketInwardEffect {
    constructor(
        private actions$: Actions,
        private packetInwardService: PacketInwardService,
    ){}

    loadPacketInward$ = createEffect(() => this.actions$.pipe(
        ofType(GET_packetInwardList),
        switchMap((data) => this.packetInwardService.getInwardPacketList(data.Payload).pipe(
            map((data: any) => SAVE_packetInwardLoadSuccess({ data: data.data })),
            catchError(async () => SAVE_packetInwardLoadSuccess({ data: [] })))
        ))
    );
}