import { Component, Input, OnInit, SimpleChange } from "@angular/core";
import { LoaderService } from "src/app/services/loader/loader.service";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  ishttpLoaded: boolean = false;
  isLoaded: boolean = false;
  loaderCount: number;

  constructor(public loadingService: LoaderService) {}
  ngOnInit() {
    this.loadingService.loaderCount$.subscribe(count => {
      this.loaderCount = count;
    });
  }
  @Input() loading: boolean = false;
  @Input() httploading: boolean = false;

  ngOnChanges(changes: SimpleChange) {
    console.log(changes); //logging the changes in @Input()
  }
}
