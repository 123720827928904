import { Component, OnInit } from '@angular/core';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';
import { ModalController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http/http.service';
import { AppState } from 'src/app/reducers';
import { Store, select } from '@ngrx/store';
import { saveCurrentRowData } from 'src/app/tempDataStore/tempData.action';
import { getFinalPlanBYPacketID } from 'src/app/home/state/home.selector';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { Router } from '@angular/router';
import { getCurrentAction } from 'src/app/tempDataStore/tempData.selector';
import { filter, first } from 'rxjs/operators';
import { getPacketParameterHistoryData, getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-manual-final-parameters',
  templateUrl: './manual-final-parameters.component.html',
  styleUrls: ['./manual-final-parameters.component.scss'],
})
export class ManualFinalParametersComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    private httpService: HttpService,
    private configService: ConfigService,
    private store: Store<AppState>,
    private router: Router,
    public loaderService: LoaderService,

  ) { }

  x: any = {
    name: 'pera',
    placeText: 'Select',
    options: [],
    selectedData: [],
    key: 'name',
    propertyName: 'name',
    modeFlag: 'single', //single/multiple
  };
  paramSelectionList = {};
  packetHistories: any = [];
  fromJsonInputs: any;
  selectedRow: any;

  ngOnInit() {
    this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter((data) => !!data),
          first()
        )
        .subscribe(async (data: any) => {
          if (data) {
            if (data.actionForm) {
              let formDataObject = JSON.parse(data.actionForm);
              let parsedFormData: any = formDataObject.form;
              this.fromJsonInputs = formDataObject.inputJson;
              let fdata = parsedFormData.map((field) => {
                if (field.type == 'select' || 'searchSelect') {
                  let paramName = field.key;
                  console.log(paramName);
                    this.store
                      .pipe(select(getParamsValuesListByName(paramName)))
                      .subscribe((data) => {
                        if (data) {
                          this.paramSelectionList[paramName] = data;
                          let newData = data.map((x) => {
                            let newobj = {
                              label: x.name,
                              value: x.id,
                            };
                            return newobj;
                          });
                          field.templateOptions.options = newData;
                        }
                      })
                }
                return field;
              });
            }
          }
        });


  this.store
    .pipe(select(getPacketParameterHistoryData))
    .subscribe((history) => {
      if (history) {
        console.log(history);
        this.packetHistories = [];
        for (let index = 0; index < history.length; index++) {
          const d = history[index];
          if (d.historyStatus == 'Completed') {
            this.packetHistories.push(d);
          }
        }
      }
    });
  }

  async packetScanned(event:any){
    if(!event?.length)
    return;

    const payload = {
      "packetId" :event,
    };
    this.httpService.getData(true, 'packet/getPacketDataForManualFinalPlan', payload).subscribe(async (res:any)=>{
      console.log(res);
      if(res?.data?.packet){
        this.store.dispatch(
          saveCurrentRowData({ rowData: res.data.packet })
        );
        this.selectedRow = res.data.packet;
        console.log(this.paramSelectionList)
        console.log(res.data.data)
        let info = {
          rowData: res.data.packet,
          status: 'Initial',
          parameterList: res.data.data,
          className: 'ecsvModal',
          history: res.data.data,
          dropdownValues: res.data.data
        };
        console.log(this.paramSelectionList)
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: 'e-csv Upload',
            data: info,
          },
          cssClass: 'ecsvModal',
        });
        modal.onDidDismiss().then((d) => {
          console.log(d)
          if(d.data)
          this.ecsvUploadWithOtherDetails(d.data);
        
        });
        return await modal.present();
      } else {
        this.configService.showToast('error', 'No data found!');
      }
    })
  }

  dropDownSelection(evt) {
    
  }

  async ecsvUploadWithOtherDetails(formData, isAppend?: boolean) {
    let formType = 'JSON';
    console.log(formData)
    let fromJsonInputsModified = { ...this.fromJsonInputs };

    console.log(fromJsonInputsModified)
    // fromJsonInputsModified.listOfParameter =
    //   fromJsonInputsModified.listOfParameter.map((x) => {
    //     const xData = { ...x };
    //     let paramName = xData.parameterName;
    //     console.log(paramName);
    //     if (formData[paramName]) {
    //       xData.parameterValue = formData[paramName];
    //     }
    //     let packet1 = xData.packet1;
    //     let packet2 = xData.packet2;
    //     if (!!packet1 && formData['packet1']) {
    //       let f1 = formData['packet1'];
    //       packet1.forEach((p1: any) => {
    //         let paramName = p1.parameterName;
    //         if (f1[paramName]) {
    //           p1.parameterValue = f1[paramName];
    //         }
    //       });
    //     }
    //     if (!!packet2 && formData['packet2']) {
    //       let f2 = formData['packet2'];
    //       packet2.forEach((p2: any) => {
    //         let paramName = p2.parameterName;
    //         if (f2[paramName]) {
    //           p2.parameterValue = f2[paramName];
    //         }
    //       });
    //     }
    //     return { ...xData };
    //   });
    
    fromJsonInputsModified.packetId = this.selectedRow.id;

    let obj = {
      listOfAllPackets: [],
    };
    const formatData = new FormData();
    if (formData['file']) {
      console.log(formData['file']);
      formType = 'Multipart';
      let file = formData['file']['0'];
      formatData.append('file', file);
      // let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(
      //   (d) => d.parameterValue != ''
      // );
      delete formData["file"]
      let keys = Object.keys(formData)
      let list = []
      for(let temp of keys) {
        if(temp == "status") {

        } else {
          let obj = {"parameterName" : temp,"parameterValue" : formData[temp]}
          list.push(obj)
        }
        
      }
      fromJsonInputsModified.listOfParameter = list;
      obj['listOfAllPackets'].push(fromJsonInputsModified);
      formatData.append(
        'listOfAllPackets',
        JSON.stringify(obj['listOfAllPackets'])
      );
      
      this.httpService.saveDataToDB(true, `packetPlan/uploadPacketAdvisorCsv?packetId=${this.selectedRow.id}&isFinal=1`, formatData, 'Multipart').subscribe((res:any)=>{
        
        if(res.statusCode == 200) {
          if(res.data) {
            let temp = res.data
            if(temp.error == "error") {
              this.configService.showToast('error', 'In correct file mapping!');
            } else {
              this.configService.showToast('success', res.message || 'Plan saved successfully!');
            }
          }
        }
        
        else
          this.configService.showToast('error', res.error.message || 'Something went wrong!');
      }, (err:any)=>{
        
        this.configService.showToast('error', err.error.message || 'Something went wrong!');
      });
    } 
    else {
      this.configService.showToast('error', 'Please select file!');
    }
  }

  getInputData(event){

  }

  onECSVSubmit(event){

  }

  closeModal(data){

  }
}
