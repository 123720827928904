import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-printskelton',
  templateUrl: './printskelton.component.html',
  styleUrls: ['./printskelton.component.scss'],
})
export class PrintskeltonComponent implements OnInit {
  currentTime: string = new Date().toLocaleTimeString('en-GB');
  printDate: string = new Date().toLocaleDateString('en-GB') + " " + this.currentTime;
  jangadDate: string = new Date().toLocaleDateString('en-GB');
  @Input() data: any;
  @Input() isThirdPartyTransfer: boolean
  environment = environment;
  @Input() displayData: any;
  // data: any = printData;
  isthirdPartyTransfer:boolean = false;
  transType = "MIX";
  summaryDetail: any = "TEST";
  requestAction: any = "Test Action"
  status = 'Testing'
  companyDetails: any = {
    companyFullName: 'PARAN Diamond',
    partyName: 'KG Virat',
    address: 'KG House, Vesu, Surat',
  };
  customerDetails: any = {
    companyFullName: 'PARAN Diamond',
    partyName: 'KG Virat',
    address: 'KG House, Vesu, Surat',
  };
  constructor() { }

  ngOnInit() {
    console.log(this.printDate);
    console.log(this.data);
    console.log(this.displayData);
    console.log(this.jangadDate);
    // const elements = document.querySelectorAll('.element');
    // console.log(elements);
    // for (const box of elements) {
    //   box.classList.add('showInPrint');
    // }
  }
  
  ngOnChanges(changes) {
    this.isthirdPartyTransfer = this.isThirdPartyTransfer? this.isThirdPartyTransfer : false
  }

  twoDecimalvalues(x) {
    let num = parseFloat(parseFloat(x).toFixed(3))
    return !!num ? num : 0;
  }

  twoDecimalWithoutRoundUp(number, decimalPlaces){

    // var multiplier = Math.pow(10, decimalPlaces);
    // var formmattedVal = Math.floor(number * multiplier) / multiplier;
    let temp = String(number)
    let s = temp.split(".")
    return `${s[0]}${s.length > 1 ? "." + s[1].substring(0, decimalPlaces) : ""}`

  }

}
