import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-file',
  template: `
  <div style="float:right;"> 
  <ion-icon name="attach-outline" class="file-icon"  ></ion-icon>
  </div>
  <div style="float:left;margin-left:10px;" class="labelDiv"> 
  <label for="file-upload" class="custom-file-upload pl-10" (click)="fileInput.click()"  >
 {{attachFile}}
</label>
</div>
    <input type="file" (change)="onChange($event)" #fileInput [formControl]="formControl" [formlyAttributes]="field" multiple>
  `,
  styles: [
    `.custom-file-upload {
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
      /* width:100%; */
      width: 325px;
      /* width: 382px; */
      color:gray;
      /* font-size:25px */
      font-size:1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

  }
  .file-icon{
    font-size:25px;
  }
  .labelDiv{
    width:100px;
    padding-left:10px;
  }
  input[type="file"] {
    display: none;
    transform: scale(1.4,1.4);
}`

  ]
})
export class FormlyFieldFile extends FieldType {
  formControl: FormControl;
  selectedFiles: File[]
  fileNames: any = [];
  attachFile: string = "";//Attach a File text

  onChange(ec) {
    this.selectedFiles = ec.target.files;
    this.fileNames = [];
    console.log(this.selectedFiles);

    for (let i = 0; i < this.selectedFiles.length; i++) {
      const d = this.selectedFiles[i];
      if (!!d && !!d.name) {
        console.log(d.name);
        this.fileNames.push(d.name);
      }
    }
    if (!!this.fileNames) {
      this.attachFile = this.fileNames.join();
    }
    console.log(this.attachFile);
  }
}