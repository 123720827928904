import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { authUser } from '../authStore/auth.selector';
import { AppState } from '../reducers';
import { HttpService } from '../services/http/http.service';
import { LoaderService } from '../services/loader/loader.service';
import { getAllUsers } from '../masterDataStore/masterData.action';
import { fetchAllUsers } from '../masterDataStore/masterData.selector';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfigService } from '../services/config/config.service';
import { ChatAlertModelComponent } from '../components/chat-alert-model/chat-alert-model.component';

@Component({
  selector: 'app-packetchat',
  templateUrl: './packetchat.component.html',
  styleUrls: ['./packetchat.component.scss'],
})
export class PacketchatComponent implements OnInit {

 

  @Input() Id: any;
  @Input() packedID:any;
  @Input() impMsg: any = true;
  public segment: string = "importantMessage";
  public date: string = "02 feb,2022"
  public msginput: string = '';
  public userName: any;
  public allMessages: Array<any> = [];
  public importantMessages: Array<any> = [];
  public oldDay = moment(new Date('2021-08-02T20:24:44.125Z')).format("DD MMM, YYYY");
  public today = moment(new Date()).format("DD MMM, YYYY");
  public currentTime;
  public scroolOnUpdate: boolean = true;
  public scroolOnsave: boolean = false;
  public timestamp;
  allUsersdata: any;
  impicon: boolean = false;
  private payload: object = {};
  allUsers: Array<any> = [];
  inputValue: string = '';
  showUserBox: boolean ;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  isAlertModalopened:boolean=false;
  uploadProgress: Observable<number>;
  userNames: any[]=[];
  chatContentForPopup: any[] = [];
  selectedUser: string = '';
  selectedIndex = -1;
x : any;
uploadedFileUrl: any;

userNotFound : boolean = false;
selectedFile: File | null = null;
inputText: string = '';
selectedFileBool : boolean = false;


//   cities = [
//     {id: 1, name: 'Vilnius'},
//     {id: 2, name: 'Kaunas'},
//     {id: 3, name: 'Pavilnys', disabled: true},
//     {id: 4, name: 'Pabradė'},
//     {id: 5, name: 'Klaipėda'}
// ];
  filteredUserNames: string[] = [];

  @ViewChild(IonContent) private ionContent: any;
  type: any = 'text';
  uploadedFileName: any;
  curremtUser: any;
  currentUser: any;
  constructor(
    private httpService: HttpService,
    private configure:ConfigService,
    private afStorage: AngularFireStorage,
    private modalController: ModalController,
    private store: Store<AppState>,
    private loaderService: LoaderService
  ) {
    if (!this.impMsg) {
      this.segment = "allMessage";
    }
  }

  ngOnInit() {
    if (!this.impMsg) {   
      this.segment = "allMessage";
    }
    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        this.currentUser = d.fullName;
        this.userName = d;
      }
    });
    this.importantMessages = []

    this.store.pipe(select(fetchAllUsers)).subscribe(async (arr: any) => {
      if (arr.length) {
        let names = arr.map(item=>item["fullName"]);
        names = names.filter(name => name !== this.currentUser  && name !== this.userName?.departmentName);
         this.userNames = await names;
      }else{
        this.store.dispatch(getAllUsers());
      }
    });
    this.scrollToBottomOnInit();
  }

  ngOnChanges() {

    if(!this.isAlertModalopened){
      this.fetchChatMessages();
    }

  }

  async createContentForPopup() {
    const userNameToMatch = this.userName?.fullName;
    const deptNameToMatch = this.userName?.roles;
    this.chatContentForPopup = this.allMessages?.filter((messageObj: any) => messageObj?.message.includes(`@${userNameToMatch}`) || deptNameToMatch?.some(deptName => messageObj?.message.includes(`@${deptName}`)));
    if (this.chatContentForPopup.length > 0) {
      await this.chatAlertComponent();
    this.isAlertModalopened = true;
    }
  }

  async fetchChatMessages() {
    this.httpService.getChatMessages("Packet", this.Id?.toString()).subscribe(d => {
      this.allMessages = d;
      console.log(this.allMessages);
      if (this.allMessages && this.allMessages.length > 0) {
        this.createContentForPopup();
      }
      console.log(this.importantMessages);
      this.filteredImportantMessages();   // this.scrollbottom=false;
      if (this.scroolOnUpdate) {
        setTimeout(() => {
          this.scrollToBottomOnInit();
          this.type='text';
          this.selectedFile=null;
        }, 100);
      }
    });

  }

  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
    console.log(this.segment);
    if (this.segment === 'importantMessage') {
      this.filteredImportantMessages();
    } else {
      this.allMessages = this.allMessages;
      setTimeout(() => {
        this.scrollToBottomOnInit();
      }, 1);
    }
    console.log(this.allMessages, this.importantMessages);
    // setTimeout(() => {
    //   this.scrollToBottomOnInit();
    // }, 1);
  }

  filteredImportantMessages() {
    this.importantMessages = this.allMessages.filter(d => d.important);
  }

  sendmessage(fileurl?: string) {
    this.scroolOnsave = true;
    console.log(fileurl);
    this.currentTime = moment().format("hh:mm A");
    this.timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
    if (this.msginput.length == 0 && this.type!='application' && this.type!='image' && this.type!='video') {
      this.configure.showToast("error","This Format is not supported")
      return;
    }
    console.log(fileurl)
    if(fileurl && (this.type=='application' || this.type=='image'  || this.type=='video')){
    console.log(this.type);

    this.payload = {
      "packetId": this.Id,
      "info": {
        "message": fileurl,
        "username": this.userName.fullName,
        "time": this.currentTime,
        "date": this.today,
        "filename": this.uploadedFileName,
        "media":this.type,
        "timestamp": this.timestamp,
        "important": false,
      },
    }
 }

  else {  
    console.log(this.type);
    this.selectedFile= null;
    this.payload = {
      "packetId": this.Id,
      "info": {
        "message": this.msginput,
        "username": this.userName.fullName,
        "time": this.currentTime,
        "media":this.type,
        "date": this.today,
        "timestamp": this.timestamp,
        "important": false,
      },
    }
  }
    if (this.segment == 'importantMessage') {
      this.payload["info"]["important"] = true;
    }
    this.filteredImportantMessages();
    this.sendMessageToFirebase()
    if (this.scroolOnsave) {
      setTimeout(() => {
        this.scrollToBottomOnInit();
      }, 500);
    }
    setTimeout(() => {
      this.msginput = "";
      console.log(this.allMessages);
    }, 100);

  }
  

  kePressOnMessage(evt:any) {
    if (evt.key.toLowerCase() == 'enter') {
      this.sendmessage();

    }
  }


  scrollToBottomOnInit() {
    if (this.ionContent) {
      this.ionContent.scrollToBottom(300);
    }
  }

  async sendMessageToFirebase() {
    console.log('onsendmessage');
    console.log(this.payload);
    let response = await this.httpService.postDataUsingPromise(true, "chat-app/saveChat", this.payload)
    console.log(response);

    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.data;
      console.log(data);
      if (this.scroolOnUpdate) {
        setTimeout(() => {
          this.scrollToBottomOnInit();
        }, 100);
      }
    } else {
      console.error(response);
    }

  }

  async updatemessagetoFirebase(updatepayload) {
    this.scroolOnUpdate = false;
    setTimeout(() => {
      this.scroolOnUpdate = true;
    }, 500);
    let response = await this.httpService.postDataUsingPromise(true, "chat-app/updateChat", updatepayload)

    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.data;
      console.log(data);
    } else {
      console.error(response);
    }

  }

  async detectBottom() {
    const scrollElement = await this.ionContent.getScrollElement(); // get scroll element
    // calculate if max bottom was reached
    if (
      scrollElement.scrollTop ===
      scrollElement.scrollHeight - scrollElement.clientHeight
    ) {
      // console.log("bottom for page");
      // this.scrool=false;


    }
  }

  scrolling(event: any) {
    // console.log(event);

    this.detectBottom();
  }

  onclickpin(msginfo) {
    let impkey = msginfo["important"] == !msginfo;
    console.log(impkey);

    let updateimp = JSON.parse(JSON.stringify(msginfo))
    updateimp["important"] = impkey;
    console.log(updateimp);
    let updatepayload = {
      "packetId": this.Id,
      "doc": msginfo.id,
      "info": updateimp
    }
   this.updatemessagetoFirebase(updatepayload);
  }

  selectUser(user: string) {
    // Handle user selection
    this.msginput += user;
    this.showUserBox = false;
    // this.filteredUserNames=[];
    // this.selectedUser = user;
  }

  openFileInput() {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();

  }
  async handleFileInput(event) {
    console.log(this.Id);
    console.log(this.type);
   let files = event.target.files
    this.selectedFile = files.item(0);
    if (event.target.files.length !== 0) {
      // this.progressBarBoolean = true;
      // setTimeout(() => {
      //   this.scrollToBottomMessage();
      // }, 500);
      this.ref = this.afStorage.ref("packetID" + this.Id + "/" + moment().unix().toString());
      const filePath = '/testing/' + "packetID" + this.Id + "/" + moment().unix().toString();
      console.log(this.ref);
      console.log(filePath);
      const fileRef = this.afStorage.ref(filePath);
      console.log(fileRef);
      this.task = this.afStorage.upload(filePath, event.target.files[0]);
      // this.uploadProgress = this.task.percentageChanges();
      
      this.task.snapshotChanges().pipe(
        finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          if (!!res) {
            
            this.uploadedFileUrl = res;
            console.log(res);
            this.type = event.target.files[0].type.split("/")[0];
            console.log(this.type);
            this.selectedFile = event.target.files[0].name;
            this.uploadedFileName = event.target.files[0].name;
            console.log(this.selectedFile);
            console.log(this.type);
            this.sendmessage(this.uploadedFileUrl);
          }
        })  
        console.log(this.uploadedFileUrl);
      })).subscribe()
      console.log(this.uploadProgress, this.uploadedFileUrl, event.target.files[0].type);
    } else {
      console.log('No File found to upload -' + event.target.files[0]);
    }
  

}
  handleMentionSelect(event: any) {
    console.log('Selected mention:', event);
    // Do something with the selected mention
  }


  getHtml(text) {

    let textArray = text.split(' ');
    let mentionTextIndex = textArray.findIndex(el => el[0] === '@');
    let textHtml = '';
    textArray.forEach((word, index) => {
      if (index !== 0) {
        textHtml += ' ';
      }
      if (index === mentionTextIndex && this.isWordMention(word)) {

        textHtml += `<span class="hight-light-color" >${word}</span>`;
      } else {
        textHtml += word;
      }
    });
    return textHtml;
  }

  downloadFiles(data){
    console.log('downloadFile');
    console.log(data);  
    window.open(data.message,'_blank');
  }

  async chatAlertComponent () {
    console.log(this.chatContentForPopup);
    if (this.chatContentForPopup && this.chatContentForPopup.length) {
      const Modal = await this.modalController.create({
        component: ChatAlertModelComponent,
        cssClass: 'chatAlertModel',
        componentProps: {
          data: this.chatContentForPopup
        },
      })
      Modal.onDidDismiss().then((d: any) => this.handleModalDismissOfAlert(d));
      return await Modal.present();
    }
    return undefined;
  }

  async handleModalDismissOfAlert(d) {
    this.isAlertModalopened = false;
    this.chatContentForPopup = [];
    if(d.data){
     console.log(d);
    }
    console.log("closing modal");
  }

  isWordMention(word):boolean{
  if(this.userNames){
    let cleanWord = word.slice(1);
    return this.userNames.includes(cleanWord);
  }
  return false
}

  shouldAlignStart(message){
    return message?.username !== this.currentUser
  }

}
