import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';
import { FinalInspectionComponent } from '../final-inspection/final-inspection.component';

@Component({
  selector: 'app-paramhistory',
  templateUrl: './paramhistory.component.html',
  styleUrls: ['./paramhistory.component.scss'],
})
export class ParamhistoryComponent implements OnInit {
  @Input() data: any;
  @Input() showEditBtn: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  @Input() isHistoryExpanded: any;
  @Input() pageName: any;
  @Input() actionName: any;
  @Input() packetId: any;
  @Output() editBtnTriggered: EventEmitter<any> = new EventEmitter();
  @Output() showFinalPlans: EventEmitter<any> = new EventEmitter();
  @Output() showFinalInspection: EventEmitter<any> = new EventEmitter();
  @Output() viewMoreEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private store: Store<AppState>,
    public httpService: HttpService,
    private configService: ConfigService,
    public loaderService: LoaderService,
    private modalController: ModalController,
    ) {
     }

  ngOnInit() {
    // this.store.pipe(select(actions)).subscribe(data => {
    //   if (data) {
    //     let actions = data.map((x: any) => x.actionName)
    //     this.data = this.data.map(x => {
    //       let showEdit = false
    //       if (x.actionName && actions.includes(x.actionName)) {
    //         showEdit = true
    //       }
    //       return {
    //         ...x,
    //         showEdit: showEdit
    //       }
    //     })
    //   }
    // });
  }
   
  ngOnChanges(){
    console.log(this.packetId);
  }


  editHistoryParameters(evt: any, d: any) {
    this.editBtnTriggered.emit(d)
  }
  showFinalPlan(action) {
    this.showFinalPlans.emit(action)
  }

  showFinalInspectionPlans(action){
    this.showFinalInspection.emit(action)
  }

  async FileFromFileSystem(packetId,actionid) {
    let obj = {
      "packetId": packetId,
      "actionId": actionid
    }
    console.log(obj);
    
    let res: any;
    res = await this.httpService.getFilesFromFileSystem(obj);
    
    if (!!res && res.statusCode==200) {
      this.fileCopyFromSystem(res.data)
      this.configService.showToast("success", res.message);
    }
    let data = res;
    if (res.status == 401) {
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    }
  }

  async fileCopyFromSystem(payload) {
    // let obj = {
    //   "packet":"",
    //   "actionName" : "",
    //   "userPath" : "",
    // }
    console.log(payload);
    
    let res: any;
    res = await this.httpService.getFileCopy(payload);
    
    if (!!res && res.statusCode==200) {
      this.configService.showToast("success", res.message);
    }
    let data = res;
    if (res.status == 401) {
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    }
  }

  async viewMore(data:any){
   const Modal = await this.modalController.create({
     component: FinalInspectionComponent,
     cssClass: ['finalInspection','pointer-none'],
     componentProps: {
       data: {data:  data},
       hideTitleToggle:true,
       hideSubmit:true,
     },
   });
   return await Modal.present();
  }
}
