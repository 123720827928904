import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, first, skip, switchMap, takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { getAllTrybeDepartments, getPacketParameterHistoryData, getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { ResetordermodalComponent } from 'src/app/resetordermodal/resetordermodal.component';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import * as XLSX from 'xlsx';
import { getCurrentAction, getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';
import { AppState } from '../../reducers/index';
import { CopyplanmodalComponent } from '../copyplanmodal/copyplanmodal.component';
import { PDComponent } from '../pd/pd.component';
import { PopoverUserComponent } from '../popover-user/popover-user.component';
import { SignermodalComponent } from '../signermodal/signermodal.component';
import { chekerAction } from '../trybe-checker/state/checker.action.type';
import { SignerAction } from '../trybesigner/state/signer.action.type';
import { actionIDForSpectrum, checkerIDForSpectrum, getAllPacketPlans, getPlanData, getSelectedEcsvOption, getSelectedPlanData, getSpectrumOtherFile, getSpectrumPdFile, getUploadedFileData } from '../trybesigner/state/signer.selector';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { savePlansAccToUSers } from '../trybe-checker/state/checker.action';
import { getCheckerAllPacketPlans, getPlanDataForChecker, getPlansAccToUserChecker } from '../trybe-checker/state/checker.selector';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { environment } from 'src/environments/environment';
import { PlanningModalComponent } from 'src/app/planning-modal/planning-modal.component';
import { myVirtualTaskActions } from '../my-virtual-task/state/my-virtual-task.actions.type';
import { virtualAPIs } from '../my-virtual-task/state/my-virtual-task.data';
import { ACTIONNAMES } from 'src/app/services/endpoint.enum';
import { ParameterFilterComponent } from './parameter-filter/parameter-filter.component';
import { HttpStatusCode } from '@angular/common/http';
import { DynamicModalComponent } from 'src/app/transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component';
import { mistakeOfArr } from 'src/app/services/common.static';

@Component({
  selector: 'app-planning-grid',
  templateUrl: './planning-grid.component.html',
  styleUrls: ['./planning-grid.component.scss'],
})
export class PlanningGridComponent implements OnInit, OnChanges {
  @ViewChild('segmentButtons', { read: ElementRef }) segmentButtons: ElementRef;
  @ViewChild('packetsGrid', { static: true }) packetsGrid: ElementRef;
  @ViewChild('intermediateSave') intermediateSave: ElementRef;
  @ViewChild('bombayMailModel') bombayMailModel: ElementRef;
  @Input() packetPlans: any = [];
  packetPlansBackupBeforeFilter: any = [];
  @Input() allData: [];
  @Input() page: any;
  @Input() subpage: any;
  @Input() isviewOnly: any;
  @Input() isfinalPage: any;
  @Input() isfinalPlanWithTabs: any;
  @Input() allowAction: boolean;
  @Input() getifDraweropen: boolean;
  @Input() calledFrom: any;
  @Input() selectedPacket: any;//rowData
  @Input() drawerFrom: string;
  @Input() showClosePopup: boolean;
  @Input() packetDetail: any;
  @Input() saveDraftUpdateDataSubject: Subject<any>;
  @Input() findByPacketIdResponse: any;
  @ViewChild("fileUpload") fileInput!: ElementRef;
  @ViewChild("#fileUpload2") fileInput2!: ElementRef;
  selectedRemarkProgram: any;
  planHex: any = [];
  ACTIONNAMES = ACTIONNAMES;
  saveDraftUpdateData: any = [];
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  project: string = environment.project;
  public gradeToNum = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26 };
  isAllCollapse: boolean = false;
  selectedPlans: any = [];
  selectedSpectrumPlans: any = [];
  currentPlan: any = {};
  currentParentPlan: any = {};
  filteredPlan: any = [];
  changes: any = {};
  dropdown: any = {};
  chLength: number = 0
  public ids = [];
  isCapFileUpload: boolean = false;
  public tensionData = [];
  public stressData = [];
  public stressTypeData = [];
  public isDisable = true;
  public isAllChildFileSelected = false;
  totalPlans: any;
  packetId;
  selectedDropDnRemark: any = [];
  allPacketPlans: any;
  public remarksPlanID = [];
  backdrop: boolean = false;
  public isParentcheckbox: any;
  public isremark: boolean = false;
  public selectedFinalPlan;
  public selectedRemark = {};
  packetPlanStones: any;
  isexpand: boolean;
  currActionData: any;
  colorDataFromStore = [];
  indexOfColors: any;
  colorDetails: any;
  signerWhoPlanned: any = [];
  updateColor: any;
  presentColorObject: any;
  signerPlanTabDatas: any;
  clarityDataFromStore = [];
  clarityDetails: any;
  fluorescenceDataFromStore = [];
  fluorescenceDetails: any;
  indexOfClarity: any;
  indexOfFluorescence: any;
  paramSelectionList = {};
  isShownDoubtfulValue: boolean;
  doubtfulValue: boolean = false;
  colDoubtfulValue: any = {};
  isDoubtfulValue: boolean = false;
  packetPlanDataForDoubtfulValue: any;
  testDoubtfulValue: any = 0;
  isSavedByUser: boolean = false;
  mistakeOfOptions: any[] = mistakeOfArr
  remarkDataOptions: any[] = [];
  intermediateMistakeEntryForm: any = {
    mistakeOf: '',
    remark: '',
  }
  selectedPlanIndex
  remProg: any = [{ id: 1, name: 'Plan Program (Red)', selected: false }, { id: 2, name: 'Select Program (White)', selected: false }, { id: 3, name: 'All Program', selected: false }];
  searchShape: string = '';
  searchColor: string = '';
  searchClarity: string = '';
  searchCut: string = '';
  searchProgram: string = '';
  @Output() planSelected: EventEmitter<any> = new EventEmitter();
  @Output() isParentCheckbox: EventEmitter<any> = new EventEmitter();
  @Output() isCurrentTab: EventEmitter<any> = new EventEmitter();
  @Output() updatePlansData: EventEmitter<any> = new EventEmitter();
  @Output() onPlanSelection: EventEmitter<any> = new EventEmitter();
  // @Output() openXPSFileDrawer: EventEmitter<any> = new EventEmitter();
  @Output() onNextBtnevent: EventEmitter<any> = new EventEmitter();
  @Output() onXrayEvent: EventEmitter<any> = new EventEmitter();
  @Output() onNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() uploadJPG: EventEmitter<any> = new EventEmitter();
  @Output() getIntermediatePlanningData: EventEmitter<any> = new EventEmitter();
  unsubscribe$: Subject<any> = new Subject<any>();
  dropDownFLData: any = {
    name: 'Fluorescence',
    placeText: 'Select FL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLData: any = {
    name: 'Color',
    placeText: 'Select CL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLAData: any = {
    name: 'Clarity',
    placeText: 'Select CLA',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownShapeData: any = {
    name: 'Shape',
    placeText: 'Select Shape',
    options: [],
    selectedData: [{ id: 0, name: 'All' }],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownPieceData: any = {
    name: 'Piece',//stone piece
    placeText: 'Select Piece',
    options: [],
    selectedData: [{ id: 0, name: 'All' }],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownSortingData: any = {
    name: 'Sorting',
    placeText: 'Select Sorting',
    options: [{ id: 0, name: 'High' }, { id: 1, name: 'Low' }, { id: 2, name: 'Highest Rank First' }, { id: 3, name: 'Lowest Rank First' }],
    selectedData: [null],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  remarkDropDown: any = {
    name: 'Sorting',
    placeText: 'Select Sorting',
    options: [{ id: 0, name: 'High' }, { id: 1, name: 'Low' }],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  salesPersonDropdown: any = {
    name: 'fullName',
    placeText: 'Select sales person',
    options: [],
    selectedData: [],
    key: 'fullName',
    propertyName: 'fullName',
    modeFlag: 'single', //single/multiple
  };
  selectedsalesPerson;
  isUploadECSVfromPlanning : boolean = false;
  objArray = [{ foo: 2, bar: 2 }, { foo: 1, bar: 4 }, { foo: 3, bar: 6 }];
  transformedObject: any;
  rowData: any = {};
  // rowData: any = {
  //   "id": 2360,
  //   "packetId": "9169-01-CHP-8G#77",
  //   "cts": 2.332,
  //   "inDate": "2021-02-01T15:17:38.000Z",
  //   "lotNo": "9169-01-CHP-8G",
  //   "outDate": "2021-02-25T00:00:00.000Z",
  //   "refPacketId": null,
  //   "nfcCode": null,
  //   "pktGrade": "A",
  //   "pktStatus": "R",
  //   "pktNo": "77",
  //   "predLimit": 3,
  //   "currAction": 149,
  //   "currState": 2,
  //   "currDept": 11,
  //   "currLocation": 6,
  //   "userId": 135,
  //   "responsibleUserId": 134,
  //   "partNo": null,
  //   "createdDate": "2022-05-28T08:43:02.000Z",
  //   "createdBy": "2-trybe-dim",
  //   "modifiedDate": "2022-08-03T05:27:08.000Z",
  //   "modifiedBy": "2-trybe-signer1",
  //   "processActionInputId": "2852,2852",
  //   "historyStatus": "Pending",
  // };
  rowDatas: any = [{
    "id": 2360,
    "packetId": "9169-01-CHP-8G#77",
    "cts": 2.332,
    "inDate": "2021-02-01T15:17:38.000Z",
    "lotNo": "9169-01-CHP-8G",
    "outDate": "2021-02-25T00:00:00.000Z",
    "refPacketId": null,
    "nfcCode": null,
    "pktGrade": "A",
    "pktStatus": "R",
    "pktNo": "77",
    "predLimit": 3,
    "currAction": 149,
    "currState": 2,
    "currDept": 11,
    "currLocation": 6,
    "userId": 135,
    "responsibleUserId": 134,
    "partNo": null,
    "createdDate": "2022-05-28T08:43:02.000Z",
    "createdBy": "2-trybe-dim",
    "modifiedDate": "2022-08-03T05:27:08.000Z",
    "modifiedBy": "2-trybe-signer1",
    "processActionInputId": "2852,2852",
    "historyStatus": "Pending"
  }];
  isUpdateDisable: boolean = true;
  selectedFile: any;
  jpgFile: any;
  spectrumFile: any;
  userData: any;
  sortvalue: any;
  userDataForFinalPlanning: any = {};
  public planShapes = [];
  public originalPacketPlans: any;
  isDataSavebyUser: boolean = false;
  public allTrybeDept: any;
  public Id: number | string;
  selectedValue = "All";
  public planData: any = [];
  public currPacketCapFile: any;
  public currJpgFile: any;
  public appliedFilter: any = {};
  planTags: any = [];
  public selectedCheckerTabs: any = "All";
  scrollPosition;
  isCheckboxfalseAfterSave: boolean = false;
  planTag: any = {};
  public appliedFilterData: any = {};
  isMarkedAsSold: boolean = false;
  public appliedFilterDatas: any = [];
  public eventObj: any = {};
  refreshGrid: boolean;
  selectedRow: any;
  selectedSequence: any;
  actionInfo: any;
  fromJsonInputs: any;
  private subscriptions: Subscription[] = [];
  formJson: any;
  packetHistories: any = [];
  pdFile: any = '';
  otherFile: any = '';
  mainFormJson: any;
  actionId: any;
  selectedEcsvOption: any;
  signerID: any;
  selectedEcsvData: any;
  currSpectrumFile: any;
  checkerIDForSpectrum: any;
  actionIdForSpectrum: any;
  payload: { id: any; chapkaWeight: any; };
  packetheaderData: any;
  modifiedPacketDetails: {};
  chapka: any;
  headerColor: any;
  headerFlour: any;
  isCurrentCheckerID: any;
  isCurrentUserSame: boolean = false;
  actionName: any;
  isWeightCorrect: boolean = true;
  hasCheckerCapFile: boolean = false;
  backPercentagePayload = []
  bombayMailRemarks : any
  actionNames = ACTIONNAMES;
 isSalesMan:boolean = false;
  constructor(public router: Router,
    private renderer: Renderer2,
    private modalCtrl: ModalController,
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    public httpService: HttpService,
    private store: Store<AppState>,
    public alertController: AlertController,
    private _ConfigService: ConfigService,
    public popoverController: PopoverController) { }


  getHexValue(a, doneIndexs = []) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }
  // skips: number[] = [];
  // getChar(i: number) {

  //   while (this.skips.includes(i)) {
  //     i++;
  //   } 
  //   this.skips.push(i);

  //   return String.fromCharCode(i + 65);
  // }
 async ngOnInit() {
    this._ConfigService.scrollChanged.subscribe(val => {
      console.log(val);
      this.packetsGrid.nativeElement.scrollTop = val;
    })

    console.log(this.subpage);
    console.log(this.sortvalue)
    console.log('ngOnInit');
    console.log('-------------packetPlans---------');
    console.log(this.packetPlans);
    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (!!d) {
        this.userData = d;
        this.isCurrentCheckerID = d.id;
        if (this.page == 'checker') {
          console.log(this.selectedRow?.id)
          this.getCapandJpgFile(this.isCurrentCheckerID)
        }
        this.signerID = d.id
        console.log('........action id');
        console.log(this.userData);

        // console.log(this.userData.listOfRoles)
        let temp: any = [];
        // this.userDataForFinalPlanning=temp[0].roleName;
        if (d && d.roles) {
          temp = d.roles.filter((r) => {
            return r == "Surat Checker"
          });
          this.userDataForFinalPlanning = !!temp[0] ? temp[0] : null;
        }
        if (d && d.listOfRoles) {
          temp = d.listOfRoles.filter((r) => {
            return r.roleName == "Surat Checker"
          });
          this.userDataForFinalPlanning = !!temp[0] && !!temp[0].roleName ? temp[0].roleName : null;
        }
      }

    });
    // this._ConfigService.scrollChanged.subscribe(val => {
    //   console.log(val);
    //   this.packetsGrid.nativeElement.scrollTop = val;

    // })
    // fromEvent(this.packetsGrid.nativeElement,'scroll').subscribe((e: Event) => {
    //   debugger
    //   let p = e.target['scrollTop']
    //   console.log(p)
    // });
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.store.pipe(select(getAllTrybeDepartments)).subscribe((data) => {
      if (data) {
        console.log('AllTrybeDepartments');
        this.allTrybeDept = data;
      }
    })

    this.store.pipe(select(getSelectedPlanData)).subscribe((plan: any) => {
      if (plan) {
        console.log('Selected plan');
        console.log(plan);
        this.selectedPlans = plan;
        console.log(this.selectedPlans);
      }
    });

    this.store.pipe(select(getParamsValuesListByName('Tension Mapping'))).subscribe(data => {
      if (!!data) {
        this.tensionData = data
      }
    })


    this.store.pipe(select(getParamsValuesListByName('Stress'))).subscribe(data => {
      if (!!data) {
        this.stressData = data
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Stress Type'))).subscribe(data => {
      if (!!data) {
        this.stressTypeData = data
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Fluorescence'))).subscribe(data => {
      if (!!data) {
        this.dropDownFLData.options = data;
        this.fluorescenceDataFromStore = data;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Color'))).subscribe(data => {
      if (!!data) {
        this.dropDownCLData.options = data;
        console.log(this.dropDownCLData.options)
        this.colorDataFromStore = data;
      }
      console.log(this.colorDataFromStore)
    })



    this.store.pipe(select(getParamsValuesListByName('Clarity'))).subscribe(data => {
      if (!!data) {
        this.dropDownCLAData.options = data;
        console.log(this.dropDownCLAData);
        this.clarityDataFromStore = data;
      }
      console.log(this.clarityDataFromStore);
    })
    this.store.pipe(select(getParamsValuesListByName('Remarks'))).subscribe(data => {
      if (!!data) {
        this.remarkDropDown.options = data;
        console.log('..........Remarks');
        // console.log(data);
      }
    });

    // this.store.dispatch(
    //   SignerAction.getPacketPlans({ packetIdOb: { packetId: "9776-09-FLT4-6#5" } })
    // );

    if (this.selectedPacket?.vitualActionName === "Smart Recut Planning" || this.selectedPacket?.vitualActionName === this.ACTIONNAMES.anyCut) {
      this.getPricingDataForSmartRecut();
    }
    this.store.pipe(select(getAllPacketPlans)).pipe(takeUntil(this.unsubscribe$)).subscribe(async (plans: any) => {
      console.log(this.packetPlans);

      this.packetPlanDataForDoubtfulValue = this.packetPlans;
      if (plans) {
        console.log('getAllPacketPlans');

        // this.packetsGrid.nativeElement.setPosition(this.scrollPosition)
        console.log(plans);
        let hexes = [];
        if (plans.length === 0) {
          this.packetPlans = this.packetPlans;

          this.allPacketPlans = JSON.parse(JSON.stringify(this.packetPlans || []));
        } else {
          this.packetPlans = plans;
          this.allData = plans;
          // this.packetPlans = plans.map(packetPlan => {
          //   let packetPlanClone = { ...packetPlan };
          //   return {
          //     ...packetPlan,
          //     packetPlanStones: packetPlanClone.packetPlanStones.map(plans => {
          //       let plansClone = { ...plans };
          //       let maxFinalValue: any = 0;
          //       return {
          //         ...plansClone,
          //         orders: plansClone.orders.map(order => {
          //           let orderClone = { ...order };
          //           let calculatedValue = this.getRate(order.rateType, plans.kgRate, plans.rapoRate, order.value, plans.expPol, order);
          //           console.log(calculatedValue, order.orderNo)
          //           orderClone.finalValue = calculatedValue
          //           maxFinalValue = Math.max(maxFinalValue, orderClone.finalValue);
          //           console.log(maxFinalValue)
          //           plansClone.maxOrderValue = maxFinalValue;
          //           console.log("plansClone", plansClone)
          //           return orderClone
          //         })
          //       }
          //     })
          //   }

          // })
          await this.packetCalculationsInit()
          console.log("KKKKKKKKKKKKKKKK", this.packetPlans)
          this.allPacketPlans = JSON.parse(JSON.stringify(this.packetPlans || []));
        }

        let newPacketPlans = (this.packetPlans || [])?.map((d: any) => {
          let remark = d.remarks
          let newPacketPlanStones: any = [];
          let weightSum: number = 0;
          let expPolPer1: number = 0;//some of Packet Plan stones/Org WT*100
          let expPolPer2: number = 0;//some of Packet Plan stones/Curr WT*100
          let currWt = this.rowData.cts;
          let orgWt = this.rowData.cts;
          let newOb = { ...d };
          let tag = ''
          if (!!d.packetPlanStones) {
            tag = !!d.packetPlanStones[0] ? d.packetPlanStones[0].tag : '';
            newPacketPlanStones = d.packetPlanStones.map((m: any, j: number) => {
              let obj = { ...m };
              // obj['piece'] = this.getHexValue(j);
              weightSum += parseFloat(m.expPol);
              return obj;
            });
            expPolPer1 = (weightSum / orgWt) * 100;
            expPolPer2 = (weightSum / currWt) * 100;
            newOb['expPolPer1'] = Number(parseFloat(expPolPer1.toString()).toFixed(2));
            newOb['expPolPer2'] = Number(parseFloat(expPolPer2.toString()).toFixed(2));
            newOb['packetPlanStones'] = newPacketPlanStones;
            newOb['tag'] = tag
            let remarkArray = [];
            if (remark) {
              remarkArray = this.remarkDropDown.options.filter(x => x.name == remark)
            }
            newOb['selectedRemark'] = remarkArray
            return newOb;
          }

        });
        this.packetPlans = newPacketPlans;
        this.planShapes = [...this.packetPlans].map(n => {
          let shape = ''
          let shapeId = ''
          //const copyOfObj = [...n.packetPlanStones];
          const packetPlanStones = n.packetPlanStones.map(x => {
            if (hexes.indexOf(x['piece']) < 0) {
              hexes.push(x['piece']);
            }
            if (x.planShape) {
              shape = x.planShape
              shapeId = x.shapeId
            }
          })
          return { name: shape, id: shapeId }

        })
        var uniq = {};
        this.planShapes = this.planShapes.filter(obj => !uniq[obj.name] && (uniq[obj.name] = true));
        this.planShapes.unshift({ 'name': 'All', id: 0 });
        this.dropDownShapeData['options'] = this.planShapes;
        this.planHex = hexes.map((x, k) => {
          return { name: x, id: k + 1 }
        });
        this.planHex.unshift({ 'name': 'All', id: 0 });
        this.dropDownPieceData['options'] = this.planHex;
        console.log(this.planHex);
        console.log(this.planShapes);
        console.log(this.packetPlans);
        this.totalPlans = this.packetPlans.length;
        //this.packetPlans =  this.packetPlans
        const CHECKER_PLAN = (this.packetPlans || [])?.find((dt: any) => (dt.planDone === '1'));
        if (!CHECKER_PLAN)
          this.packetPlans = this.packetPlans.sort(function (a, b) {
            if (a.totalKGRate > b.totalKGRate)
              return -1;
            if (a.totalKGRate < b.totalKGRate)
              return 1;
            return 0;
          });

        for (let i = 1; i < this.packetPlans.length; ++i) {
          let PacketPlan = this.packetPlans[0];
          let test = PacketPlan.totalKGRate;
          const entry = this.packetPlans[i];
          test = entry.totalKGRate;
          // entry.totalKGRate = test;
          let rate = (test * 100 / PacketPlan.totalKGRate).toFixed(2);

          if (rate == 'NaN' || rate == 'Infinity' || rate == '-Infinity' || rate == '+Inifinity') {
            rate = '0';
          }
          //entry.bestRate = 0;//setting zero
          entry.bestRate = rate;
          console.log(rate, "Rate");
          // console.log(PacketPlan.totalKGRate, "Rate");
        }
        console.log(this.packetPlans, "array");

        // let param = { packetID: this.rowData.id, for: 'uploadCapFile' }
        // this.store.dispatch(
        //   SignerAction.getUploadedFile({ param: param })
        // );
      }
    });

    this.store.pipe(select(getCurrentRowData)).pipe(takeUntil(this.unsubscribe$)).subscribe((rd: any) => {
      if (rd) {
        console.log(rd);
        this.Id = !!rd[0] ? rd[0].id : null;
        console.log(this.Id);
        this.selectedRow = rd[0];
        // this.packetDetails = rd[0];
        // !!this.rowData[0] ? this.rowData[0].historyStatus : '-';
        if (rd.length == 0) {
          this.rowData = this.rowData;//////
          this.rowDatas = this.rowDatas;//////
        } else {
          this.rowData = rd[0];
          this.rowDatas = rd;
        }
        console.log(this.isviewOnly);
        if (this.page == 'checker') {
          console.log(this.selectedRow?.id)
          this.getCapandJpgFile(this.isCurrentCheckerID)
        }
        
        if ((this.page == "checker" || this.page === 'headchecker' || this.page === 'bombay mail') && this.isviewOnly==false) {
          
          this.fetchinfoOfSingers(this.Id)
          
        }

        if (this.page == 'Spectrum' || this.page === 'reviewByChecker' || this.page === 'reviewForSmartRecut') {
          this.store.pipe(select(checkerIDForSpectrum)).pipe(takeUntil(this.unsubscribe$)).subscribe((checkerid: any) => {
            console.log(checkerid);
            this.checkerIDForSpectrum = checkerid
          });

          this.store.pipe(select(actionIDForSpectrum)).pipe(takeUntil(this.unsubscribe$)).subscribe((actionIdForSpectrum: any) => {
            console.log(actionIdForSpectrum);
            this.actionIdForSpectrum = actionIdForSpectrum
          });
        }

        // this.httpService.fetchHistoryDataByPacketId(this?.rowData['id']).pipe(takeUntil(this.unsubscribe$))?
        this.store.pipe(select(getPacketParameterHistoryData)).subscribe(history => {
          if (history) {
            history={data:history};
            if (!!history.data && !!history.data && history.data.length > 0) {
              let csspData = history.data.filter((h: any) => h.actionName == 'CFSP');//Fluorescence
              let visionData = history.data.filter((h: any) => h.actionName == 'Vision 360');//Fluorescence
              if (csspData.length > 0 && csspData[0]?.inputDetails?.length > 0) {
                let purityData = csspData[0]?.inputDetails.filter(x => x.paramName == "Purity")
                if (purityData.length > 0) {
                  let pValue = purityData[0].paramValue
                  this.rowData = { ...this.rowData, purity: pValue }
                }
              }
              if (visionData.length > 0 && visionData[0]?.inputDetails?.length > 0) {
                let vision360Data = visionData[0]?.inputDetails.filter(x => x.paramName == "Vision 360")
                if (vision360Data.length > 0) {
                  let pValue = vision360Data[0].paramValue
                  this.rowData = { ...this.rowData, vision360: pValue }
                }
              }
            }
          }
        });
        if (this.page == 'checker' || this.page === 'headchecker' || this.page === 'adminApproval' || this.page === 'bombay mail') {
          this.store.pipe(select(getPlanDataForChecker)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
            if (data) {
              console.log(data);
              this.planData = data;
              this.planData.filter(x => {
                if (x.masterName == 'Fluorescence') {
                  //this.rowData = { ...this.rowData, fluorescence: x.paramValue }
                  let fValue = this.dropDownFLData.options.filter(y => y.id == x.paramValue)[0]?.name
                  this.rowData = { ...this.rowData, fluorescence: fValue }
                  let newPacketPlans = this.packetPlans.map((d: any) => {
                    let newPacketPlanStones: any = [];
                    let weightSum: number = 0;
                    let newOb = { ...d };
                    if (!!d.packetPlanStones) {
                      newPacketPlanStones = d.packetPlanStones.map((m: any) => {
                        let obj = { ...m };
                        if (!obj.fluorescence) {
                          obj.fluorescence = fValue;
                        }
                        return obj;
                      });
                    }
                    newOb['packetPlanStones'] = newPacketPlanStones;
                    return newOb;
                  });
                  this.packetPlans = newPacketPlans;
                }

                if (x.paramName == 'Tension Mapping') {
                  let tValue = this.tensionData.filter(y => y.id == x.paramValue)[0].name
                  this.rowData = { ...this.rowData, stress: tValue }
                }

                if (x.paramName == 'Stress Type') {
                  let stValue = this.stressTypeData.filter(y => y.id == x.paramValue)[0].name
                  this.rowData = { ...this.rowData, stressType: stValue }
                }
                console.log("%cInProgress-", 'color:green;font-weight:bold;');
                console.log(this.rowData);
                console.log(data);
                this.transformedObject = data.reduce((obj, item) => {
                  obj[item.masterName] = item.name;
                  obj[item.masterName + 'Id'] = item.masterValue;
                  return obj;
                }, {});
              })
            }
          });

        } else {
          this.store.pipe(select(getPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
            if (data) {
              this.planData = data;
              this.planData.filter(x => {
                if (x.masterName == 'Fluorescence') {
                  //this.rowData = { ...this.rowData, fluorescence: x.paramValue }
                  let fValue = this.dropDownFLData.options.filter(y => y.id == x.paramValue)[0]?.name
                  this.rowData = { ...this.rowData, fluorescence: fValue }
                  let newPacketPlans = this.packetPlans.map((d: any) => {
                    let newPacketPlanStones: any = [];
                    let weightSum: number = 0;
                    let newOb = { ...d };
                    if (!!d.packetPlanStones) {
                      newPacketPlanStones = d.packetPlanStones.map((m: any) => {
                        let obj = { ...m };
                        if (!obj.fluorescence) {
                          obj.fluorescence = fValue;
                        }
                        return obj;
                      });
                    }
                    newOb['packetPlanStones'] = newPacketPlanStones;
                    return newOb;
                  });
                  this.packetPlans = newPacketPlans;
                }

                if (x.paramName == 'Tension Mapping') {
                  let tValue = this.tensionData.filter(y => y.id == x.paramValue)[0].name
                  this.rowData = { ...this.rowData, stress: tValue }
                }

                if (x.paramName == 'Stress Type') {
                  let stValue = this.stressTypeData.filter(y => y.id == x.paramValue)[0].name
                  this.rowData = { ...this.rowData, stressType: stValue }
                }
                console.log("%cInProgress-", 'color:green;font-weight:bold;');
                console.log(this.rowData);
                this.transformedObject = data.reduce((obj, item) => {
                  obj[item.masterName] = item.name;
                  return obj;
                }, {});
              })
            }
          });
        }


      }
    });
    this.store.pipe(select(getUploadedFileData)).subscribe((capFile) => {
      if (!!capFile) {
        this.currPacketCapFile = capFile;
        console.log('getUploadedCapFileData');//capFile
        console.log(this.currPacketCapFile);

      }
    });

    this.originalPacketPlans = [...(this.packetPlans || [])];
    console.log(this.originalPacketPlans);

    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.actionId = data.id;
          this.actionInfo = data;
          this.actionName = data.name
          if (data.actionForm) {
            let formDataObject = JSON.parse(data.actionForm);
            let parsedFormData: any = formDataObject.form;
            console.log(parsedFormData);
            this.fromJsonInputs = formDataObject.inputJson;
            let fdata = parsedFormData.map((field) => {
              if (field.type == 'select' || 'searchSelect') {
                let paramName = field.key;
                console.log(paramName);
                this.subscriptions.push(
                  this.store
                    .pipe(select(getParamsValuesListByName(paramName)))
                    .subscribe((data) => {
                      if (data) {
                        this.paramSelectionList[paramName] = data;
                        console.log(data);
                        let newData = data.map((x) => {
                          let newobj = {
                            label: x.name,
                            value: x.id,
                          };
                          return newobj;
                        });
                        // console.log(JSON.stringify(newData))
                        //add flur in select options
                        field.templateOptions.options = newData;
                        console.log(newData);
                      }
                    })
                );
              }
              return field;
            });
            this.formJson = fdata;
            this.mainFormJson = fdata;
          }
        }
      })

    this.subscriptions.push(
      this.store
        .pipe(select(getPacketParameterHistoryData))
        .subscribe((history) => {
          if (history) {
            console.log(history);
            this.packetHistories = [];
            for (let index = 0; index < history.length; index++) {
              const d = history[index];
              if (d.historyStatus == 'Completed') {
                this.packetHistories.push(d);
              }
            }
          }
        })
    );

    this.store.dispatch(SignerAction.getSelectedEcsvValue());
    this.store.select(getSelectedEcsvOption).subscribe((data) => {
      this.selectedEcsvData = data;
    });

    if (this.page == 'planning' && this.selectedEcsvData !== 'newFile') {
      this.getCapandJpgFile(this?.signerID)
    }
    // if(this.page=='Spectrum'){
    // if (!!this.selectedRow && !!this.selectedRow.id) {
    //   this.fetchPacketDetailById(this.selectedRow.id)
    // }
    // }

    if (this.page == 'reviewByChecker' || this.page === 'reviewForSmartRecut') {
      this.store.pipe(select(getSpectrumPdFile)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => { this.pdFile = data; });
      this.store.pipe(select(getSpectrumOtherFile)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => { this.otherFile = data });
    }

  }
  ngOnChanges(changes) {
    let alpha: String = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    console.log('ngOnChanges');
    console.log('-------------changes---------');
    console.log(this.getifDraweropen);
    console.log(changes);
    console.log(this.packetPlans);
    if (!!changes.packetPlans && changes.packetPlans.currentValue) {
      this.store.pipe(select(getCurrentRowData)).pipe(takeUntil(this.unsubscribe$)).subscribe((rd: any) => {
        if (rd?.[0]) {
          if ((rd?.[0]?.vitualActionName == "Smart Recut Planning") || (rd?.[0]?.currActionName == "Smart Recut Planning") || (rd?.[0]?.vitualActionName == this.ACTIONNAMES.anyCut) || (rd?.[0]?.currActionName == this.ACTIONNAMES.anyCut)) {
            const CHECKER_PLAN = this.packetPlans.find((dt: any) => (dt.planDone === '1'));
            if (CHECKER_PLAN) {
              const KEYS_TO_COPY = [
                'shape', 'shapeId',
                'sawRghWgt',
                'expPol',
                'yield',
                'color',
                'clarity', 'clarityId',
                'fluorescence', 'fluorescenceId', 'fluorescenceIds',
                'cut', 'cutId',
                'pol', 'polish', 'polishId',
                'symmetry', 'symmetryId',
                'nattsName', 'natts',
                'tinchName', 'tinch',
                'lusterName', 'luster',
                'diameter', 'lOrW',
                'pdCat',
                'height', 'depthPercentage',
                'Lab', 'labName', 'mLabName',
                'pointer_range', 'progName',
              ];
              this.packetPlans = this.packetPlans.map((pp: any) => {
                if (CHECKER_PLAN.id !== pp.id) {
                  CHECKER_PLAN.packetPlanStones.map((pps: any, i: number) => {
                    // console.log(pp?.packetPlanStones?.[i])
                    if (pp?.packetPlanStones?.[i] == null) {
                      return;
                    }

                    KEYS_TO_COPY.map((key: string) => {
                      console.log(pp.packetPlanStones[i][key])
                      console.log(key)
                      console.log("---")
                      console.log(pps[key])
                      if ((pp.packetPlanStones[i][key] == null || undefined == pp.packetPlanStones[i][key]) && pps[key]) {
                        pp.packetPlanStones[i][key] = pps[key];
                        if (key == "nattsName") {
                          pp.packetPlanStones[i]["newNattsName"] = pps[key];
                        }
                      }
                    });
                  });
                }
                return pp;
              });
              console.log("==-=-=-=-")
              console.log(this.packetPlans)
            }
          }
        }
      });
      const planDoneIndex = this.packetPlans.findIndex((dt: any) => (dt.planDone === '1'));
      if (planDoneIndex > 0) {
        const planDonePlan = this.packetPlans.find((dt: any) => (dt.planDone === '1'));
        this.packetPlans = [planDonePlan, ...this.packetPlans.filter((dt: any) => (dt.planDone !== '1'))];
      }

      this.packetCalculationsInit()
      let pieces = changes.packetPlans.currentValue;
      this.planTags = [];
      console.log(pieces);
      if (pieces) {
        pieces.forEach(element => {
          element.packetPlanStones.forEach((item, k) => {
            this.planTag[this.gradeToNum[item.piece]] = item.piece;
          })
        });
        console.log(this.planTag);
        Object.keys(this.planTag).forEach(prop => {
          if (this.planTag[prop]) {
            let obj: any = { id: prop, name: this.planTag[prop] };
            this.planTags.push(obj);
          }
        });
      }

      console.log(this.planTags);



      // this.packetPlans?.map((dt: any) => {
      //   if (dt.netStoneValues > higestNetStoneValue)
      //     higestNetStoneValue = dt.netStoneValues;
      // });

      let higestNetStoneValue;
      let rank = 1;
      
      if(
        (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
        (this.selectedRow?.currActionName == "Smart Recut Planning") ||
        (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
        (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
      ){
        this.packetPlans?.map((dt: any) => {
          if (dt.planDone === '1')
            higestNetStoneValue = dt.netStoneValues;
        });
      }
      if(!(higestNetStoneValue > -1)){
        this.packetPlans?.map((dt: any) => {
          if (dt.netStoneValues > (higestNetStoneValue||0))
            higestNetStoneValue = dt.netStoneValues;
        });
      }
      let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
      this.packetPlans?.forEach((dt: any) => {
        if (dt?.netStoneValues <= previousNetStoneValue) {
          dt.rank = rank;
          previousNetStoneValue = dt.netStoneValues;
        }
        if (dt?.netStoneValues && higestNetStoneValue){
          if(
            (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
            (this.selectedRow?.currActionName == "Smart Recut Planning") ||
            (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
            (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
          )
          dt.rankDiff = (((dt.netStoneValues-higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
          else
          dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
        }
        else
          dt.rankDiff = 0;
        rank++;
      });

      this.packetPlansBackupBeforeFilter = [...(JSON.parse(JSON.stringify(this.packetPlans)))];

      this.httpService.dismissLoader();
    }
    console.log('thisdrawerFrom');
    console.log(this.drawerFrom);
    if (!!changes.drawerFrom && changes.drawerFrom.currentValue) {
      let text: string = changes.drawerFrom.currentValue;
      if (text == 'plan-close') {
        console.log('Close this Plan');
        this.backdrop = false;
        this.clearSelection(this.eventObj.event, this.eventObj.rowData);
        this.store.dispatch(SignerAction.changePlanOncheck({ plan: this.eventObj.rowData, isChecked: this.eventObj.event.detail.checked }));
      }
    }

    this.originalPacketPlans = [...(this.packetPlans || [])];
    if (changes.packetPlans)
      this.packetPlans = (this.packetPlans || []).map((dt: any) => {
        if (dt.isSelected == 1) {
          this.store.dispatch(SignerAction.changePlanOncheck({ plan: dt, isChecked: true }));
          return { ...dt, selected: true, selectionOrder: dt.planPriority }
        }
        return { ...dt, selectionOrder: dt.planPriority }
      });
      this.httpService.dismissLoader();

    if(changes?.findByPacketIdResponse?.currentValue){
      this.fetchPacketDetailById(changes.findByPacketIdResponse.currentValue);
    }
  }
  async copyPlan(evt: any, planData, index) {
    console.log(this.packetPlans);
    evt.preventDefault();
    evt.stopPropagation();
        let totalRw = 0;
    planData.packetPlanStones.map((ps: any) => {
      totalRw += Number(ps.sawRghWgt || "0");
    });
     totalRw = await this.processValue(totalRw)
        let carat = await this.processValue(this.packetDetail?.Cts ? this.packetDetail.Cts : this.packetDetail?.carat)
    if ((Number(totalRw) > Number(carat))) {
      this._ConfigService.showToast("error", 'Cannot Copy Plan , Total saw rgh wt cannot be more than Org wt!');
      return
    }
    let data = { ...planData };
    data.packetPlanStones = data.packetPlanStones.map((pps: any) => {
      if (!pps.color && this.transformedObject?.Color) {
        pps = { ...pps, color: this.transformedObject?.Color, colorId: this.transformedObject?.ColorId }
      }
      if (!pps.fluorescence && this.transformedObject?.Fluorescence) {
        pps = { ...pps, fluorescence: this.transformedObject?.Fluorescence, fluorescenceId: this.transformedObject?.FluorescenceId }
      }
      if (!pps.lusterName && this.transformedObject?.Luster) {
        pps = { ...pps, lusterName: this.transformedObject?.Luster, luster: this.transformedObject?.LusterId }
      }
      if (!pps.tinchName && this.transformedObject?.Tinch) {
        pps = { ...pps, tinchName: this.transformedObject?.Tinch, tinch: this.transformedObject?.TinchId }
      }
      if (pps?.mLabName) {
        pps = { ...pps, mLabName: pps?.mLabName }
      }
      if ((!pps?.Lab && pps?.labName) || (pps?.Lab && !pps?.labName)) {
        pps = { ...pps, Lab: (pps?.Lab || pps?.labName), labName: (pps?.Lab || pps?.labName) }
      }
      return pps;
    })
    const modal = await this.modalCtrl.create({
      component: CopyplanmodalComponent,
      cssClass: 'extraLargePage copyModal',
      componentProps: {
        'data': data,
        forPage: 'Planning'
      },
    });
    modal.onDidDismiss().then(async(data) => {
      if(data?.data)
      await this.copyPlanExit(data?.data, index);
      let curUserID = this.userData?.id ? this.userData?.id : this.userData?.userId
      if(this.selectedCheckerTabs != curUserID){
      this.selectedCheckerTabs= curUserID;
      // this.segmentChangedForSignerTabs({detail:{value:curUserID}});
      }
    });
    return await modal.present();
  }

  async getPricingDataForSmartRecut() {

    await Promise.all((JSON.parse(JSON.stringify([...this.packetPlans])) || [])?.map(async (ppdt: any, i: number) => {

      await ppdt?.packetPlanStones?.map(async (ppsdt: any, j: number) => {
        let ratio = null;
        if (this.packetPlans[i]['packetPlanStones'][j].shape == "ROUND" || this.packetPlans[i]['packetPlanStones'][j].expPol < 0.18) {
          ratio = this.packetPlans[i]['packetPlanStones'][j].diameter
        } else {
          ratio = this.packetPlans[i]['packetPlanStones'][j].lOrW
          // ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100
        }
        
        if(this.project == 'kg')
          ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100;

        if (this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage) {
          this.packetPlans[i]['packetPlanStones'][j].height = this.packetPlans[i]['packetPlanStones'][j].depthPercentage
        }

        let lab = ""
        if(this.packetPlans[i]['packetPlanStones'][j]?.mLabName){
          lab = this.packetPlans[i]['packetPlanStones'][j]?.mLabName;
        } else
        if (this.packetPlans[i]['packetPlanStones'][j]?.labName == "-") {
          lab = ""
        } else {
          lab = this.packetPlans[i]['packetPlanStones'][j].labName;
        }

        const kpPricingPayload = {
          "id": this.packetPlans[i]['packetPlanStones'][j]?.id, //
          "mShape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
          "mCrts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
          "mLab": lab || "",
          "mColor": this.packetPlans[i]['packetPlanStones'][j]?.color,
          "mPurity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
          "mFlrc": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
          "mCut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
          "mPol": this.packetPlans[i]['packetPlanStones'][j]?.pol,
          "mSym": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
          "mDepth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
          "mRatio": ratio,
          "mNatts": this.packetPlans[i]['packetPlanStones'][j]?.nattsName || "NO BLK",
          "mTinch": this.packetPlans[i]['packetPlanStones'][j]?.tinchName || "N/A",
          "mMilky": this.packetPlans[i]['packetPlanStones'][j]?.lusterName || "NORMAL",
          "mTO": this.packetPlans[i]['packetPlanStones'][j].topOpenName ? this.packetPlans[i]['packetPlanStones'][j].topOpenName : "N/A", 
          "mBO": this.packetPlans[i]['packetPlanStones'][j].bottomOpenName ? this.packetPlans[i]['packetPlanStones'][j].bottomOpenName : "N/A"
        };

        const kgPricingPayload = {
          "cts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
          "Shape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
          "Clarity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
          "Color": this.packetPlans[i]['packetPlanStones'][j]?.color,
          "Cut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
          "Polish": this.packetPlans[i]['packetPlanStones'][j]?.pol,
          "Symmetry": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
          "Fluorescence": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
          "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
          "Pd Cat": this.packetPlans[i]['packetPlanStones'][j]?.pdCat,
          "Length": this.packetPlans[i]['packetPlanStones'][j].lengthMM,
          "Width": this.packetPlans[i]['packetPlanStones'][j].widthMM,
          "Table Per": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
          "Tot Depth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
          "New Cut": "IDEAL",
          "L_W": ratio
        };


        const kgPricingNewCutPayload = {
          "expPol": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
          "shapeId": this.packetPlans[i]['packetPlanStones'][j]?.shapeId,
          "clarityId": this.packetPlans[i]['packetPlanStones'][j]?.clarityId,
          "colorId": this.packetPlans[i]['packetPlanStones'][j]?.colorId,
          "cutId": this.packetPlans[i]['packetPlanStones'][j]?.cutId,
          "polishId": this.packetPlans[i]['packetPlanStones'][j]?.polishId,
          "symmetryId": this.packetPlans[i]['packetPlanStones'][j]?.symmetryId,
          "fluorescenceId": this.packetPlans[i]['packetPlanStones'][j]?.fluorescenceId || 497,
          "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
          "pdCatId": this.packetPlans[i]['packetPlanStones'][j]?.pdCatId,
          "crAngle": this.packetPlans[i]['packetPlanStones'][j]?.crAngle,
          "tableVal": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
          "height": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
          "New Cut": "",
          "lOrW": ratio,
          "girdle": this.packetPlans[i]['packetPlanStones'][j]?.girdle,
          "tinch": this.packetPlans[i]['packetPlanStones'][j]?.tinch,
        }
        if (this.project == 'kg') {
          await (new Promise((resolve, reject) => {
            this.httpService.getNewCutValue(kgPricingNewCutPayload).subscribe((resCut: any) => {
              if (!!resCut.data) {
                kgPricingPayload['New Cut'] = resCut.data?.name || '';
              } else {
                kgPricingPayload['New Cut'] = '';
              }
              this.httpService.getPrincing(environment.PricingUrl , [kgPricingPayload]).subscribe((res: any) => {
                if(res?.data?.[0]?.Errors){
                  this._ConfigService.showToast('error', res?.data?.[0]?.Errors);
                  return;
                }
                let data = res.data[0];
                let finalPrice = 0;
                if (!data['MPM BASE_PD']) {
                  data['MPM BASE_PD'] = 0
                }
                if (!data['MIX PRICE']) {
                  data['MIX PRICE'] = 0
                }

                finalPrice = Math.max(data['MPM BASE_PD'], data['MIX PRICE']);
                if (data && finalPrice) {
                  this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = Math.round(finalPrice * this.packetPlans[i]['packetPlanStones'][j].expPol * 100) / 100;
                  this.packetPlans[i]['packetPlanStones'][j]['rapoRate'] = data.RAPAPORT;
                  this.packetPlans[i]['packetPlanStones'][j]['RAPAPORT'] = data.RAPAPORT;
                  this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify((res?.data || [])?.find(res => res.ID == this.packetPlans[i]['packetPlanStones'][j]?.id));
                  let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan:any)=>(!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
                  let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
                  this.packetPlans[i]['totalKGRate'] = totalRateSum;
                  let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
                  let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
                  this.packetPlans[i]["netStoneValues"] = newPrice;

                  let higestNetStoneValue;
                  let rank = 1;
                  if(
                    (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                    (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                  ){
                    this.packetPlans?.map((dt: any) => {
                      if (dt.planDone === '1')
                        higestNetStoneValue = dt.netStoneValues;
                    });
                  }
                  if(!(higestNetStoneValue > -1)){
                    this.packetPlans?.map((dt: any) => {
                      if (dt.netStoneValues > (higestNetStoneValue||0))
                        higestNetStoneValue = dt.netStoneValues;
                    });
                  }
                  let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
                  this.packetPlans?.forEach((dt: any) => {
                    if (dt?.netStoneValues <= previousNetStoneValue) {
                      dt.rank = rank;
                      previousNetStoneValue = dt.netStoneValues;
                    }
                    if (dt?.netStoneValues && higestNetStoneValue){
                      if(
                        (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                        (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                        (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                        (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                      )
                      dt.rankDiff = (((dt.netStoneValues-higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                      else
                      dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                    }
                    else
                      dt.rankDiff = 0;
                    rank++;
                  });
                }
                resolve('');
              }, (err: any) => { reject('') });
            })

          }));
        }
        else {
          await (new Promise((resolve, reject) => {
            this.httpService.getPrincing(environment.PricingUrl, [kpPricingPayload]).subscribe((res: any) => {
              if (res.length > 0) {
                let data = res[0]
                this.packetPlans[i]['packetPlanStones'][j]["Lab"] = data["mLab"]
                this.packetPlans[i]['packetPlanStones'][j]["mLabName "] = data["mLab"]
                this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = data["stone_value"]
                this.packetPlans[i]['packetPlanStones'][j]['labour'] = data["labour"];
                this.packetPlans[i]['packetPlanStones'][j]['pointer_range'] = data["pointer_range"];
                this.packetPlans[i]['packetPlanStones'][j]['net_stone_value'] = data["net_stone_value"];
                this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res.find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
                let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
                let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
                this.packetPlans[i]["netStoneValues"] = Math.round(newPrice * 100) / 100
                for (let data of this.packetPlans[i].packetPlanStones) {
                  data["netValuePer"] = (data["net_stone_value"] / newPrice * 100).toFixed(2);
                }
                let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan:any)=>(!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
                let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
                this.packetPlans[i]['totalKGRate'] = Math.round(totalRateSum * 100) / 100;


                let higestNetStoneValue;
                let rank = 1;
                if(
                  (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                  (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                  (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                  (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                ){
                  this.packetPlans?.map((dt: any) => {
                    if (dt.planDone === '1')
                      higestNetStoneValue = dt.netStoneValues;
                  });
                }
                if(!(higestNetStoneValue > -1)){
                  this.packetPlans?.map((dt: any) => {
                    if (dt.netStoneValues > (higestNetStoneValue||0))
                      higestNetStoneValue = dt.netStoneValues;
                  });
                }
                let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
                this.packetPlans?.forEach((dt: any) => {
                  if (dt?.netStoneValues <= previousNetStoneValue) {
                    dt.rank = rank;
                    previousNetStoneValue = dt.netStoneValues;
                  }
                  if (dt?.netStoneValues && higestNetStoneValue){
                    if(
                      (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                      (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                      (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                      (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                    )
                      dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                    else
                      dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                  }
                  else
                    dt.rankDiff = 0;
                  rank++;
                });
              }
              resolve('');
            }, (err: any) => { reject(''); });
          }));
        }
      })
    }))
  }

  async copyPlanExit(modalData, index) {
    console.log(index);
    if (modalData) {
      // let mac = [...this.packetPlans].map(x => x.id)
      // console.log(mac)
      let maxId = Math.max(...this.packetPlans.map(x => x.id));
      let maxTag = Math.max(...this.packetPlans.map(x => x.tag));
      let maxTagValue = Math.max(...this.packetPlans.map(x => x.tagValue));
      console.log(maxTag);
      this.packetPlans.map(x => {
        console.log(x.seqNo)
        let i = parseInt(x.seqNo)
        if (typeof i == 'number') {
          return i
        }

      })
      let maxSeqNo = Math.max(...this.packetPlans.map(x => {
        let j = parseInt(x.seqNo)
        console.log(typeof j)
        if (typeof j == 'number') {
          return j
        }
      }).filter(x => !!x))
      console.log(maxSeqNo)
      let copyPlan = { ...modalData };
      copyPlan.tag = maxTag + 1;
      copyPlan.tagValue = maxTagValue + 1;
      copyPlan.id = maxId + 1;
      //copyPlan.seqNo = maxSeqNo + 1
      copyPlan['copyFrom'] = modalData.tagValue
      console.log(copyPlan)
      let packets = [...this.packetPlans]
      packets.splice(index + 1, 0, copyPlan);
      console.log(packets);
      // packets.push(copyPlan);
      // console.log(packets)
      this.packetPlans = packets
      console.log(modalData)
      let packetPlanId = modalData.id
      let packetId = this.rowData.id
      let seqNo = copyPlan.seqNo
      let tag = copyPlan.tag

      let packetStones = copyPlan.packetPlanStones.map(x => {
        let n = { ...x, "packetIdText": this.rowData.packetId, "nattsId": x.natts, "bopensId" : x.bottomOpen, "topensId" : x.topOpen };
        // let newObj = {
        //   "packetId": packetId,
        //   "seqNo": seqNo,
        //   "tag": tag,
        //   "packetIdText": this.rowData.packetId,
        //   "expPol": n.expPol,
        //   "shapeId": n.shapeId,
        //   "cutId": n.cutId,
        //   "clarityId": n.clarityId,
        //   "colorId": n.colorId,
        //   "pavAngle": n.pavAngle,
        //   "pavHeight": n.pavHeight,
        //   "crAngle": n.crAngle,
        //   "crHeight": n.crHeight,
        //   "tableVal": n.tableVal,
        //   "girdle": n.girdle,
        //   "diameter": n.diameter,
        //   "height": n.height,
        //   "lengthMM": n.lengthMM,
        //   "heightMM": n.heightMM,
        //   "widthMM": n.widthMM,
        //   "lOrW": n.lOrW,
        //   "planShape": n.planShape,
        //   "planColor": n.planColor,
        //   "planClarity": n.planClarity,
        //   "planCut": n.planCut,
        //   "pdCat":n.pdCat,
        //   "polish":n.polish,
        //   "nattsId":n.natts,
        //   "symmetry":n.symmetry,
        // }
        return n
      })
      
     if (this.page !== "headchecker") {
     const payload = packetStones;
     try {
     const res: any = await this.httpService.postDataUsingPromise(true, `packetPlan/copyPacketPlanAndItsChild/${packetPlanId}`, payload);
     if (res?.statusCode === 200) {
      copyPlan.id = res.data?.ids?.[0];
      console.log('Copy successful:', copyPlan);
      this.fetchinfoOfSingers(this.Id);
      } else {
      console.error('Unexpected response status:', res?.statusCode, res?.message);
     }
    } catch (error) {
    console.error("An error occurred while copying the packet plan:", error);
    } 
   }

  // this.store.dispatch(SignerAction.copyPacketPlan({ packetPlanId: packetPlanId, planData: packetStones }))
      await this.updateOrderFormAfterUpdatingParam([copyPlan], index + 1);
      // let newCopyObj = {
      //   packetId:copyPlan.packetId
      //   seqNo : copyPlan.seqNo,
      //   tag:
      //   copyPlan
      // }


    }

    this.packetPlans.map(async (ppdt: any, i: number) => {
      if ((index + 1) == i)
        await ppdt?.packetPlanStones?.map(async (ppsdt: any, j: number) => {

          let ratio = null;
          if (this.packetPlans[i]['packetPlanStones'][j].shape == "ROUND" || this.packetPlans[i]['packetPlanStones'][j].expPol < 0.18) {
            ratio = this.packetPlans[i]['packetPlanStones'][j].diameter
          } else {
            ratio = this.packetPlans[i]['packetPlanStones'][j].lOrW
            // ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100
          }
          
          if (this.project == 'kg')
            ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100

          if (this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage) {
            this.packetPlans[i]['packetPlanStones'][j].height = this.packetPlans[i]['packetPlanStones'][j].depthPercentage
          }

          let lab = "";
          if (this.packetPlans[i]['packetPlanStones'][j]?.mLabName) {
            lab = this.packetPlans[i]['packetPlanStones'][j]?.mLabName;
          } else
            if (this.packetPlans[i]['packetPlanStones'][j]?.labName == "-") {
              lab = ""
            } else {
              lab = this.packetPlans[i]['packetPlanStones'][j].labName;
            }

          const kpPricingPayload = {
            "id": this.packetPlans[i]['packetPlanStones'][j]?.id, //
            "mShape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
            "mCrts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
            "mLab": lab || "",
            "mColor": this.packetPlans[i]['packetPlanStones'][j]?.color,
            "mPurity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
            "mFlrc": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
            "mCut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
            "mPol": this.packetPlans[i]['packetPlanStones'][j]?.pol,
            "mSym": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
            "mDepth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
            "mRatio": ratio,
            "mNatts": this.packetPlans[i]['packetPlanStones'][j]?.nattsName || "NO BLK",
            "mTinch": this.packetPlans[i]['packetPlanStones'][j]?.tinchName || "N/A",
            "mMilky": this.packetPlans[i]['packetPlanStones'][j]?.lusterName || this.transformedObject?.['Luster'] || "NORMAL",
            "mTO": this.packetPlans[i]['packetPlanStones'][j].topOpenName ? this.packetPlans[i]['packetPlanStones'][j].topOpenName : "N/A",
            "mBO": this.packetPlans[i]['packetPlanStones'][j].bottomOpenName ? this.packetPlans[i]['packetPlanStones'][j].bottomOpenName : "N/A"
          };

          const kgPricingPayload = {
            "cts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
            "Shape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
            "Clarity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
            "Color": this.packetPlans[i]['packetPlanStones'][j]?.color,
            "Cut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
            "Polish": this.packetPlans[i]['packetPlanStones'][j]?.pol,
            "Symmetry": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
            "Fluorescence": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
            "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
            "Pd Cat": this.packetPlans[i]['packetPlanStones'][j]?.pdCat,
            "Length": this.packetPlans[i]['packetPlanStones'][j].lengthMM,
            "Width": this.packetPlans[i]['packetPlanStones'][j].widthMM,
            "Table Per": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
            "Tot Depth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
            "New Cut": "IDEAL",
            "L_W": ratio
          };


          const kgPricingNewCutPayload = {
            "expPol": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
            "shapeId": this.packetPlans[i]['packetPlanStones'][j]?.shapeId,
            "clarityId": this.packetPlans[i]['packetPlanStones'][j]?.clarityId,
            "colorId": this.packetPlans[i]['packetPlanStones'][j]?.colorId,
            "cutId": this.packetPlans[i]['packetPlanStones'][j]?.cutId,
            "polishId": this.packetPlans[i]['packetPlanStones'][j]?.polishId,
            "symmetryId": this.packetPlans[i]['packetPlanStones'][j]?.symmetryId,
            "fluorescenceId": this.packetPlans[i]['packetPlanStones'][j]?.fluorescenceId || 497,
            "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
            "pdCatId": this.packetPlans[i]['packetPlanStones'][j]?.pdCatId,
            "crAngle": this.packetPlans[i]['packetPlanStones'][j]?.crAngle,
            "tableVal": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
            "height": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
            "New Cut": "",
            "lOrW": ratio,
            "girdle": this.packetPlans[i]['packetPlanStones'][j]?.girdle,
            "tinch": this.packetPlans[i]['packetPlanStones'][j]?.tinch,
          }

          if (this.project == 'kg') {
            await (new Promise((resolve, reject) => {
              this.httpService.getNewCutValue(kgPricingNewCutPayload).subscribe((resCut: any) => {
                if (!!resCut.data) {
                  kgPricingPayload['New Cut'] = resCut.data?.name || '';
                } else {
                  kgPricingPayload['New Cut'] = '';
                }
                this.httpService.getPrincing(environment.PricingUrl, [kgPricingPayload]).subscribe((res: any) => {
                  if (res?.data?.[0]?.Errors) {
                    this._ConfigService.showToast('error', res?.data?.[0]?.Errors);
                    return;
                  }
                  let data = res.data[0];
                  let finalPrice = 0;
                  if (!data['MPM BASE_PD']) {
                    data['MPM BASE_PD'] = 0
                  }
                  if (!data['MIX PRICE']) {
                    data['MIX PRICE'] = 0
                  }

                  finalPrice = Math.max(data['MPM BASE_PD'], data['MIX PRICE']);
                  if (data && finalPrice) {
                    this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = Math.round(finalPrice * this.packetPlans[i]['packetPlanStones'][j].expPol * 100) / 100;
                    this.packetPlans[i]['packetPlanStones'][j]['rapoRate'] = data.RAPAPORT;
                    this.packetPlans[i]['packetPlanStones'][j]['RAPAPORT'] = data.RAPAPORT;
                    this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res.find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
                    let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan: any) => (!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
                    let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
                    this.packetPlans[i]['totalKGRate'] = totalRateSum
                    let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
                    let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
                    this.packetPlans[i]["netStoneValues"] = newPrice;

                    let higestNetStoneValue;
                    let rank = 1;
                    if (
                      (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                      (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                      (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                      (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                    ) {
                      this.packetPlans?.map((dt: any) => {
                        if (dt.planDone === '1')
                          higestNetStoneValue = dt.netStoneValues;
                      });
                    }
                    if (!(higestNetStoneValue > -1)) {
                      this.packetPlans?.map((dt: any) => {
                        if (dt.netStoneValues > (higestNetStoneValue || 0))
                          higestNetStoneValue = dt.netStoneValues;
                      });
                    }
                    let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
                    this.packetPlans?.forEach((dt: any) => {
                      if (dt?.netStoneValues <= previousNetStoneValue) {
                        dt.rank = rank;
                        previousNetStoneValue = dt.netStoneValues;
                      }
                      if (dt?.netStoneValues && higestNetStoneValue) {
                        if (
                          (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                          (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                          (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                          (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                        )
                          dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                        else
                          dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                      }
                      else
                        dt.rankDiff = 0;
                      rank++;
                    });
                  }
                  resolve('');
                }, (err: any) => { reject('') });
              })

            }));
          }
          else {
            await (new Promise((resolve, reject) => {
              this.httpService.getPrincing(environment.PricingUrl, [kpPricingPayload]).subscribe((res: any) => {
                if (res.length > 0) {
                  let data = res[0]
                  this.packetPlans[i]['packetPlanStones'][j]["Lab"] = data["mLab"]
                  this.packetPlans[i]['packetPlanStones'][j]["mLabName"] = data["mLab"]
                  this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = data["stone_value"]
                  this.packetPlans[i]['packetPlanStones'][j]['labour'] = data["labour"];
                  this.packetPlans[i]['packetPlanStones'][j]['pointer_range'] = data["pointer_range"];
                  this.packetPlans[i]['packetPlanStones'][j]['net_stone_value'] = data["net_stone_value"];
                  this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res.find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
                  let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
                  let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
                  this.packetPlans[i]["netStoneValues"] = Math.round(newPrice * 100) / 100
                  for (let data of this.packetPlans[i].packetPlanStones) {
                    data["netValuePer"] = (data["net_stone_value"] / newPrice * 100).toFixed(2);
                  }
                  let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan: any) => (!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
                  let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
                  this.packetPlans[i]['totalKGRate'] = Math.round(totalRateSum * 100) / 100;


                  let higestNetStoneValue;
                  let rank = 1;
                  if (
                    (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                    (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                  ) {
                    this.packetPlans?.map((dt: any) => {
                      if (dt.planDone === '1')
                        higestNetStoneValue = dt.netStoneValues;
                    });
                  }
                  if (!(higestNetStoneValue > -1)) {
                    this.packetPlans?.map((dt: any) => {
                      if (dt.netStoneValues > (higestNetStoneValue || 0))
                        higestNetStoneValue = dt.netStoneValues;
                    });
                  }
                  let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
                  this.packetPlans?.forEach((dt: any) => {
                    if (dt?.netStoneValues <= previousNetStoneValue) {
                      dt.rank = rank;
                      previousNetStoneValue = dt.netStoneValues;
                    }
                    if (dt?.netStoneValues && higestNetStoneValue) {
                      if (
                        (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                        (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                        (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                        (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                      )
                        dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                      else
                        dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                    }
                    else
                      dt.rankDiff = 0;
                    rank++;
                  });
                }
                resolve('');
              }, (err: any) => { reject(''); });
            }));
          }
        })
    });
  }

  async deleteRow(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(id, 'packetplanid');
    console.log('-------Delete......');
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'planAlertBox',
      buttons: [{
        text: 'CLOSE',
        handler: (d: any) => {
          console.log('Closed', d);
        }
      },
      {
        text: 'OK',
        handler: (d: any) => {
          console.log('-----------temp-----------');
          let updatedSignerData = this.packetPlans.filter((d) => d.id != id);
          if (this.page !== 'headchecker')
            this.httpService.deleteDataOnPacketPlan(true, `packetPlan/deletePacketPlanAndAllItsStoneUsingPacketPlanID/${id}`).subscribe();
          this.packetPlans = updatedSignerData;
        }
      }]
    });
    await alert.present();
  }

  togglingRow() {
    this.isAllCollapse = !this.isAllCollapse ? true : false;
    if (this.isAllCollapse) {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: false }));
    } else {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: true }));
    }
    console.log(this.packetPlans);
  }
  onToggleBtnClick() {
    this.togglingRow();
    console.log(this.isAllCollapse);
  }
  async onUpdate() {
    console.log('-------onUpdate......');
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'pushOverUpdate',
      componentProps: { id: 1, name: "All Program", grades: this.planTags, forPage: 'PlanningUpdate' },

      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss()
      .then((res) => {
        if (res.data) {
          this.updateModal(res.data)
          // this.updatePayload(res.data)
        }
      });
  }

  async updateModal(data) {
    console.log(data);
    if (!data?.dropdown?.Clarity && !data?.dropdown?.Color && !data?.dropdown?.Fluorescence)
      return;

    this.packetPlans = this.packetPlans.map((dt: any) => {
      delete dt.orders
      if (data?.grade?.length) {
        let packetPlanStones = dt.packetPlanStones;
        data?.grade.map((gdt: any) => {
          if (!packetPlanStones?.[Number(gdt.id) - 1])
            return;
          let ppdt = packetPlanStones[Number(gdt.id) - 1];
          let res = { ...ppdt };
          if (data?.dropdown?.Clarity)
            res = { ...res, clarity: data?.dropdown?.Clarity?.name, clarityId: data?.dropdown?.Clarity?.id };
          if (data?.dropdown?.Color)
            res = { ...res, color: data?.dropdown?.Color?.name, colorId: data?.dropdown?.Color?.id };
          if (data?.dropdown?.Fluorescence)
            res = { ...res, fluorescence: data?.dropdown?.Fluorescence?.name, fluorescenceId: data?.dropdown?.Fluorescence?.id };
          packetPlanStones[Number(gdt.id) - 1] = res;
        });
        return { ...dt, packetPlanStones };
      } else {
        let packetPlanStones = dt.packetPlanStones;
        packetPlanStones = packetPlanStones.map((ppdt: any) => {
          let res = { ...ppdt };
          if (data?.dropdown?.Clarity)
            res = { ...res, clarity: data?.dropdown?.Clarity?.name, clarityId: data?.dropdown?.Clarity?.id };
          if (data?.dropdown?.Color)
            res = { ...res, color: data?.dropdown?.Color?.name, colorId: data?.dropdown?.Color?.id };
          if (data?.dropdown?.Fluorescence)
            res = { ...res, fluorescence: data?.dropdown?.Fluorescence?.name, fluorescenceId: data?.dropdown?.Fluorescence?.id };
          return res;
        })
        return { ...dt, packetPlanStones };
      }
    });
    this.httpService.saveData(true, 'packetPlan/updatePacketPlansAndStones', this.packetPlans).subscribe(async (res: any) => {
      if (res.statusCode === 200) {
        this._ConfigService.showToast("success", res.message || 'Saved successfully!');

        // START - gett pricing after updating packetplanstone
        const pricingPayload = {};

        this.packetPlans.map((dt: any, i: number) => {
          if (data?.grade?.length) {
            data?.grade?.map((gdt: any) => {
              if (!dt.packetPlanStones?.[Number(gdt.id) - 1])
                return;
              if (!pricingPayload[i])
                pricingPayload[i] = {};
              pricingPayload[i][Number(gdt.id) - 1] = this.getPricingPayload(i, Number(gdt.id) - 1);
            });
          }
          else {
            dt.packetPlanStones.map((ppdt: any, j: number) => {
              if (!pricingPayload[i])
                pricingPayload[i] = {};
              pricingPayload[i][j] = this.getPricingPayload(i, j);
            });
          }
        });
        const finalPricingPayload = [];
        for (const i in pricingPayload) {
          for (const j in pricingPayload[i]) {
            if (this.project == 'kg') {
              pricingPayload[i][j]['New Cut'] = await this.getKgNewCutValue(i, j);
            }
            finalPricingPayload.push(pricingPayload[i][j]);
          }
        }
        if (this.project == 'kg') {
          this.httpService.getPrincing(environment.PricingUrl, finalPricingPayload).subscribe((res: any) => {
            let index = 0;
            for (const i in pricingPayload) {
              for (const j in pricingPayload[i]) {
                if (res?.data?.[index]?.Errors) {
                  this._ConfigService.showToast('error', res?.data?.[index]?.Errors);
                  index++;
                  continue;
                }
                if (res?.data?.[index]) {
                  let data = res.data[index];
                  let finalPrice = 0;
                  if (!data['MPM BASE_PD']) {
                    data['MPM BASE_PD'] = 0
                  }
                  if (!data['MIX PRICE']) {
                    data['MIX PRICE'] = 0
                  }
                  finalPrice = Math.max(data['MPM BASE_PD'], data['MIX PRICE']);
                  if (data && finalPrice) {
                    this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = Math.round(finalPrice * this.packetPlans[i]['packetPlanStones'][j].expPol * 100) / 100;
                    this.packetPlans[i]['packetPlanStones'][j]['rapoRate'] = data.RAPAPORT;
                    this.packetPlans[i]['packetPlanStones'][j]['RAPAPORT'] = data.RAPAPORT;
                    this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify((res?.data || []).find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
                    let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan: any) => (!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
                    let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
                    this.packetPlans[i]['totalKGRate'] = totalRateSum;
                    let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
                    let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
                    this.packetPlans[i]["netStoneValues"] = newPrice;

                    let higestNetStoneValue;
                    let rank = 1;
                    if (
                      (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                      (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                      (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                      (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                    ) {
                      this.packetPlans?.map((dt: any) => {
                        if (dt.planDone === '1')
                          higestNetStoneValue = dt.netStoneValues;
                      });
                    }
                    if (!(higestNetStoneValue > -1)) {
                      this.packetPlans?.map((dt: any) => {
                        if (dt.netStoneValues > (higestNetStoneValue || 0))
                          higestNetStoneValue = dt.netStoneValues;
                      });
                    }
                    let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
                    this.packetPlans?.forEach((dt: any) => {
                      if (dt?.netStoneValues <= previousNetStoneValue) {
                        dt.rank = rank;
                        previousNetStoneValue = dt.netStoneValues;
                      }
                      if (dt?.netStoneValues && higestNetStoneValue) {
                        if (
                          (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                          (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                          (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                          (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                        )
                          dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                        else
                          dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                      }
                      else
                        dt.rankDiff = 0;
                      rank++;
                    });
                  }
                }
                index++;
              }
            }
          });
        }
        else {
          this.httpService.getPrincing(environment.PricingUrl, finalPricingPayload).subscribe((res: any) => {
            let index = 0;
            for (const i in pricingPayload) {
              for (const j in pricingPayload[i]) {
                if (res?.[index]) {
                  let data = res[index];
                  this.packetPlans[i]['packetPlanStones'][j]["Lab"] = data["mLab"]
                  this.packetPlans[i]['packetPlanStones'][j]["mLabName"] = data["mLab"]
                  this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = data["stone_value"]
                  this.packetPlans[i]['packetPlanStones'][j]['labour'] = data["labour"];
                  this.packetPlans[i]['packetPlanStones'][j]['pointer_range'] = data["pointer_range"];
                  this.packetPlans[i]['packetPlanStones'][j]['net_stone_value'] = data["net_stone_value"];
                  this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res.find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
                  let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
                  let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
                  this.packetPlans[i]["netStoneValues"] = Math.round(newPrice * 100) / 100
                  for (let data of this.packetPlans[i].packetPlanStones) {
                    data["netValuePer"] = (data["net_stone_value"] / newPrice * 100).toFixed(2);
                  }
                  let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan: any) => (!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
                  let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
                  this.packetPlans[i]['totalKGRate'] = Math.round(totalRateSum * 100) / 100;
                  let higestNetStoneValue;
                  let rank = 1;
                  if (
                    (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                    (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                  ) {
                    this.packetPlans?.map((dt: any) => {
                      if (dt.planDone === '1')
                        higestNetStoneValue = dt.netStoneValues;
                    });
                  }
                  if (!(higestNetStoneValue > -1)) {
                    this.packetPlans?.map((dt: any) => {
                      if (dt.netStoneValues > (higestNetStoneValue || 0))
                        higestNetStoneValue = dt.netStoneValues;
                    });
                  }
                  let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
                  this.packetPlans?.forEach((dt: any) => {
                    if (dt?.netStoneValues <= previousNetStoneValue) {
                      dt.rank = rank;
                      previousNetStoneValue = dt.netStoneValues;
                    }
                    if (dt?.netStoneValues && higestNetStoneValue) {
                      if (
                        (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                        (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                        (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                        (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                      )
                        dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                      else
                        dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                    }
                    else
                      dt.rankDiff = 0;
                    rank++;
                  });
                }
                index++;
              }
            }
          });
        }
        // END - gett pricing after updating packetplanstone

        this.updateOrderFormAfterUpdatingParam(this.packetPlans);

        return true;
        // } else {
        //   this._ConfigService.showToast("error", res.error.message || 'Something went wrong!');
        //   return false;
      }
    });
  }

  getPricingPayload(i: number, j: number) {
    let ratio = null;
    if (this.packetPlans[i]['packetPlanStones'][j].shape == "ROUND" || this.packetPlans[i]['packetPlanStones'][j].expPol < 0.18) {
      ratio = this.packetPlans[i]['packetPlanStones'][j].diameter
    } else {
      ratio = this.packetPlans[i]['packetPlanStones'][j].lOrW
      // ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100
    }
    
    if (this.project == 'kg')
      ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100

    if (this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage) {
      this.packetPlans[i]['packetPlanStones'][j].height = this.packetPlans[i]['packetPlanStones'][j].depthPercentage
    }

    let lab = ""
    if (this.packetPlans[i]['packetPlanStones'][j]?.labName == "-") {
      lab = ""
    } else {
      lab = this.packetPlans[i]['packetPlanStones'][j].labName;
    }
    if (this.project == 'kg') {
      return {
        "cts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
        "Shape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
        "Clarity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
        "Color": this.packetPlans[i]['packetPlanStones'][j]?.color,
        "Cut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
        "Polish": this.packetPlans[i]['packetPlanStones'][j]?.pol,
        "Symmetry": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
        "Fluorescence": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
        "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
        "Pd Cat": this.packetPlans[i]['packetPlanStones'][j]?.pdCat,
        "Length": this.packetPlans[i]['packetPlanStones'][j].lengthMM,
        "Width": this.packetPlans[i]['packetPlanStones'][j].widthMM,
        "Table Per": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
        "Tot Depth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
        "New Cut": "",
        "L_W": ratio
      }
    } else {
      return {
        "id": this.packetPlans[i]['packetPlanStones'][j]?.id, //
        "mShape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
        "mCrts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
        "mLab": lab || "",
        "mColor": this.packetPlans[i]['packetPlanStones'][j]?.color,
        "mPurity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
        "mFlrc": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
        "mCut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
        "mPol": this.packetPlans[i]['packetPlanStones'][j]?.pol,
        "mSym": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
        "mDepth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
        "mRatio": ratio,
        "mNatts": this.packetPlans[i]['packetPlanStones'][j]?.nattsName || "NO BLK",
        "mTinch": this.packetPlans[i]['packetPlanStones'][j]?.tinchName || "N/A",
        "mMilky": this.packetPlans[i]['packetPlanStones'][j]?.lusterName || this.transformedObject?.['Luster'] || "NORMAL",
        "mTO": "N/A", //this.packetPlans[i]['packetPlanStones'][j]?.topOpenName, 
        "mBO": "N/A"//this.packetPlans[i]['packetPlanStones'][j]?.bottomOpenName 
      };
    }
  }
  async getKgNewCutValue(i: any, j: any) {
    let ratio = null;
    if (this.packetPlans[i]['packetPlanStones'][j].shape == "ROUND" || this.packetPlans[i]['packetPlanStones'][j].expPol < 0.18) {
      ratio = this.packetPlans[i]['packetPlanStones'][j].diameter
    } else {
      ratio = this.packetPlans[i]['packetPlanStones'][j].lOrW
      // ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100
    }
    
    if (this.project == 'kg')
      ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100

    const kgPricingNewCutPayload = {
      "expPol": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
      "shapeId": this.packetPlans[i]['packetPlanStones'][j]?.shapeId,
      "clarityId": this.packetPlans[i]['packetPlanStones'][j]?.clarityId,
      "colorId": this.packetPlans[i]['packetPlanStones'][j]?.colorId,
      "cutId": this.packetPlans[i]['packetPlanStones'][j]?.cutId,
      "polishId": this.packetPlans[i]['packetPlanStones'][j]?.polishId,
      "symmetryId": this.packetPlans[i]['packetPlanStones'][j]?.symmetryId,
      "fluorescenceId": this.packetPlans[i]['packetPlanStones'][j]?.fluorescenceId || 497,
      "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
      "pdCatId": this.packetPlans[i]['packetPlanStones'][j]?.pdCatId,
      "tableVal": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
      "crAngle": this.packetPlans[i]['packetPlanStones'][j]?.crAngle,
      "height": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
      "New Cut": "",
      "lOrW": ratio,
      "girdle": this.packetPlans[i]['packetPlanStones'][j]?.girdle,
      "tinch": this.packetPlans[i]['packetPlanStones'][j]?.tinch,
    };
    let newCutResponse = {};
    await (
      new Promise((resolve, reject) => {
        this.httpService.getNewCutValue(kgPricingNewCutPayload).subscribe((resCut: any) => {
          if (!!resCut.data) {
            newCutResponse['New Cut'] = resCut.data?.name || '';
          } else {
            newCutResponse['New Cut'] = '';
          }
          resolve('');
        }, (err: any) => { reject() });
      })
    );
    return newCutResponse;
  }

  getTotalOfPlan(d: any, key: string) {
    let total = 0.000;
    d?.packetPlanStones?.map((dt: any) => {
      if (dt[key])
        total += Number(dt[key]);
    });
    return total.toFixed(3);
  }

  async rowClick(gridRow: any, i: number, j: number) {
    let rowData = JSON.parse(JSON.stringify({ ...gridRow }))
    if (!gridRow.fluorescence) {
      gridRow = {
        ...gridRow,
        fluorescence: this.transformedObject?.Fluorescence
      };
    }
    if (!gridRow.tinchName) {
      gridRow = {
        ...gridRow,
        tinchName: this.transformedObject?.['Tinch']
      };
    }
    if (!gridRow.lusterName) {
      gridRow = {
        ...gridRow,
        lusterName: this.transformedObject?.['Luster']
      };
    }
    if (!gridRow.labName) {
      gridRow = {
        ...gridRow,
        labName: gridRow['mLabName']
      };
    }
    if (!gridRow.depthPercentage) {
      gridRow = {
        ...gridRow,
        depthPercentage: gridRow['height']
      };
    }
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'planningRowParameterSelection',
      componentProps: { forPage: 'planningRowParameterSelection', gridRow, planDone: (this.packetPlans[i]?.planDone || '') },
    });
    await popover.present();
    popover.onDidDismiss().then((res: any) => {
      if (!res?.data)
        return;
      console.log(this.packetPlans)
      if (!!res.data) {
        
      }
      if (res?.data?.gridRow) {
        const gridRowCopy = JSON.parse(JSON.stringify(res.data.gridRow));
        // const gridRowCopy = res.data.gridRow
        const newPacketPlanStones = [...this.packetPlans[i].packetPlanStones];
        newPacketPlanStones[j] = {...gridRowCopy};
        this.packetPlans[i].packetPlanStones = newPacketPlanStones;
      }
      console.log(this.packetPlans[i]['packetPlanStones'][j]);

      if (!res.data) {
        this.packetPlans[i]['packetPlanStones'][j] = {
          ...rowData,
          depthPercentage: gridRow['height'] ? (Number(gridRow['height']).toFixed(3)) : null
        };
      } else {
        this.packetPlans[i]['packetPlanStones'][j] = {
          ...rowData,
          ...res?.data?.gridRow,
          Lab: res?.data?.labName,
          mLabName :  res?.data?.labName,
          height: (res?.data?.depthPercentage),
          depthPercentage: (res?.data?.gridRow?.depthPercentage)
        };
      }

      let ratio = null;
      if (this.packetPlans[i]['packetPlanStones'][j].shape == "ROUND" || this.packetPlans[i]['packetPlanStones'][j].expPol < 0.18) {
        ratio = this.packetPlans[i]['packetPlanStones'][j].diameter
      } else {
        ratio = this.packetPlans[i]['packetPlanStones'][j].lOrW
        // ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100
      }
      
      if (this.project == 'kg')
        ratio = Math.round(this.packetPlans[i]['packetPlanStones'][j].lengthMM / this.packetPlans[i]['packetPlanStones'][j].widthMM * 100) / 100

      if (this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage) {
        this.packetPlans[i]['packetPlanStones'][j].height = this.packetPlans[i]['packetPlanStones'][j].depthPercentage
      }
      console.log(ratio)
      let lab = "";
      // if (this.packetPlans[i]['packetPlanStones'][j]?.mLabName && !res?.data?.planningReviewUpdated?.lab) {
      //   lab = this.packetPlans[i]['packetPlanStones'][j]?.mLabName;
      // } else
      //   if (this.packetPlans[i]['packetPlanStones'][j]?.labName == "-") {
      //     lab = ""
      //   } else {
      //     lab = this.packetPlans[i]['packetPlanStones'][j].labName;
      //   }
      // this.packetPlans[i]['packetPlanStones'][j].mLabName = lab
      lab = this.packetPlans[i]['packetPlanStones'][j].labName;
      console.log(this.packetPlans[i]['packetPlanStones'][j].mLabName)
      console.log(this.packetPlans[i]['packetPlanStones'][j]?.topOpenName)
      const kpPricingPayload = {
        "id": this.packetPlans[i]['packetPlanStones'][j]?.id, //
        "mShape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
        "mCrts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
        "mLab": lab || "",
        "mColor": this.packetPlans[i]['packetPlanStones'][j]?.color,
        "mPurity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
        "mFlrc": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
        "mCut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
        "mPol": this.packetPlans[i]['packetPlanStones'][j]?.pol,
        "mSym": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
        "mDepth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
        "mRatio": ratio,
        "mNatts": this.packetPlans[i]['packetPlanStones'][j]?.nattsName || "NO BLK",
        "mTinch": this.packetPlans[i]['packetPlanStones'][j]?.tinchName || "N/A",
        "mMilky": this.packetPlans[i]['packetPlanStones'][j]?.lusterName || this.transformedObject?.['Luster'] || "NORMAL",
        "mTO": this.packetPlans[i]['packetPlanStones'][j].topOpenName ? this.packetPlans[i]['packetPlanStones'][j].topOpenName : "N/A",
        "mBO": this.packetPlans[i]['packetPlanStones'][j].bottomOpenName ? this.packetPlans[i]['packetPlanStones'][j].bottomOpenName : "N/A"
      };
      console.log(this.packetPlans[i]['packetPlanStones'][j])

      const kgPricingPayload = {
        "cts": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
        "Shape": this.packetPlans[i]['packetPlanStones'][j]?.shape,
        "Clarity": this.packetPlans[i]['packetPlanStones'][j]?.clarity,
        "Color": this.packetPlans[i]['packetPlanStones'][j]?.color,
        "Cut": this.packetPlans[i]['packetPlanStones'][j]?.cut,
        "Polish": this.packetPlans[i]['packetPlanStones'][j]?.pol,
        "Symmetry": this.packetPlans[i]['packetPlanStones'][j]?.symmetry,
        "Fluorescence": this.packetPlans[i]['packetPlanStones'][j]?.fluorescence || "NONE",
        "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
        "Pd Cat": this.packetPlans[i]['packetPlanStones'][j]?.pdCat,
        "Length": this.packetPlans[i]['packetPlanStones'][j].lengthMM,
        "Width": this.packetPlans[i]['packetPlanStones'][j].widthMM,
        "Table Per": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
        "Tot Depth": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
        "New Cut": "",
        "L_W": ratio
      }

      const kgPricingNewCutPayload = {
        "expPol": this.packetPlans[i]['packetPlanStones'][j]?.expPol,
        "shapeId": this.packetPlans[i]['packetPlanStones'][j]?.shapeId,
        "clarityId": this.packetPlans[i]['packetPlanStones'][j]?.clarityId,
        "colorId": this.packetPlans[i]['packetPlanStones'][j]?.colorId,
        "cutId": this.packetPlans[i]['packetPlanStones'][j]?.cutId,
        "polishId": this.packetPlans[i]['packetPlanStones'][j]?.polishId,
        "symmetryId": this.packetPlans[i]['packetPlanStones'][j]?.symmetryId,
        "fluorescenceId": this.packetPlans[i]['packetPlanStones'][j]?.fluorescenceId || 497,
        "ID": this.packetPlans[i]['packetPlanStones'][j]?.id,
        "pdCatId": this.packetPlans[i]['packetPlanStones'][j]?.pdCatId,
        "tableVal": Math.round(this.packetPlans[i]['packetPlanStones'][j].tableVal * 100) / 100,
        "crAngle": this.packetPlans[i]['packetPlanStones'][j]?.crAngle,
        "height": this.packetPlans[i]['packetPlanStones'][j]?.height || this.packetPlans[i]['packetPlanStones'][j]?.depthPercentage,
        "New Cut": "",
        "lOrW": ratio,
        "girdle": this.packetPlans[i]['packetPlanStones'][j]?.girdle,
        "tinch": this.packetPlans[i]['packetPlanStones'][j]?.tinch,
      }
      if (this.project == 'kg') {
        this.httpService.getNewCutValue(kgPricingNewCutPayload).subscribe((resCut: any) => {
          console.log("KEVALLLLLLLLLLLLLLLLLLL", resCut.data)
          if (!!resCut.data) {
            kgPricingPayload['New Cut'] = resCut.data?.name || '';
          } else {
            kgPricingPayload['New Cut'] = '';
          }
          this.httpService.getPrincing(environment.PricingUrl, [kgPricingPayload]).subscribe((res: any) => {
            if (res?.data?.[0]?.Errors) {
              this._ConfigService.showToast('error', res?.data?.[0]?.Errors);
              return;
            }
            
            if (this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.packetPlans[i].id) > -1) {
              this.saveDraftUpdateData = this.saveDraftUpdateData.filter((dt: any) => dt.id != this.packetPlans[i].id);
              this.saveDraftUpdateData.push(this.packetPlans[i]);
            } else
              this.saveDraftUpdateData.push(this.packetPlans[i]);
            if (this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.packetPlans[i].id)) {
              this.saveDraftUpdateData[this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.packetPlans[i].id)]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res);
              this.saveDraftUpdateDataSubject.next(this.saveDraftUpdateData);
            }
            // if(this.saveDraftUpdateData?.length)
            // this.saveDraftUpdateDataSubject.next(this.saveDraftUpdateData);
          

            console.log(res.data)
            let temp = res.data
            let data = res.data[0]
            let finalPrice = 0;
            if (!data['MPM BASE_PD']) {
              data['MPM BASE_PD'] = 0
            }
            if (!data['MIX PRICE']) {
              data['MIX PRICE'] = 0
            }

            finalPrice = Math.max(data['MPM BASE_PD'], data['MIX PRICE']);
            if (data && finalPrice) {
              this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = Math.round(finalPrice * this.packetPlans[i]['packetPlanStones'][j].expPol * 100) / 100;
              this.packetPlans[i]['packetPlanStones'][j]['rapoRate'] = data.RAPAPORT;
              this.packetPlans[i]['packetPlanStones'][j]['RAPAPORT'] = data.RAPAPORT
              this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(temp.find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
              let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan: any) => (!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
              let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
              this.packetPlans[i]['totalKGRate'] = totalRateSum
              let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
              let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
              this.packetPlans[i]["netStoneValues"] = newPrice;

              let higestNetStoneValue;
              let rank = 1;
              if (
                (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
              ) {
                this.packetPlans?.map((dt: any) => {
                  if (dt.planDone === '1')
                    higestNetStoneValue = dt.netStoneValues;
                });
              }
              if (!(higestNetStoneValue > -1)) {
                this.packetPlans?.map((dt: any) => {
                  if (dt.netStoneValues > (higestNetStoneValue || 0))
                    higestNetStoneValue = dt.netStoneValues;
                });
              }
              let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
              this.packetPlans?.forEach((dt: any) => {
                if (dt?.netStoneValues <= previousNetStoneValue) {
                  dt.rank = rank;
                  previousNetStoneValue = dt.netStoneValues;
                }
                if (dt?.netStoneValues && higestNetStoneValue) {
                  if (
                    (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                    (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                    (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                  )
                    dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                  else
                    dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
                }
                else
                  dt.rankDiff = 0;
                rank++;
              });
              this.updateOrderFormAfterUpdatingParam([this.packetPlans[i]['packetPlanStones'][j]], i, j);
            } else {
              this.updateOrderFormAfterUpdatingParam([this.packetPlans[i]['packetPlanStones'][j]], i, j);
            }
            if (this.packetPlans?.[i]?.selected)
              this.store.dispatch(SignerAction.changePlanOncheck({ plan: this.packetPlans[i], isChecked: this.packetPlans?.[i]?.selected }));
            // if(this.packetPlans..find((up:any)=>up.id==res.data.gridRow.id)){
            //   // this.updatePayload = this.updatePayload.map((dt:any) => {
            //   //   if(dt.id == res.data.gridRow.id)
            //   //   return {
            //   //     ...dt,
            //   //     ...res.data.planningReviewUpdated,
            //   //   };
            //   //   return dt;
            //   // })
            // } else {
            //   // this.updatePayload.push({
            //   //   id:res.data.gridRow.id,
            //   //   ...res.data.planningReviewUpdated,
            //   // })
            // }
            // let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
            //   let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
            //   this.packetPlans[i]["netStoneValues"] = 200 //newPrice
            //   console.log("===--")
            //   console.log(newStonePRice)
            //   console.log(newPrice)
            //   console.log(this.packetPlans[i])
            //     for(let data of this.packetPlans[i].packetPlanStones) {
            //       data["net_stone_value"] = 200
            //       data["netValuePer"] = Math.round( data["net_stone_value"] / 200 * 100)
            //       console.log("===--")
            //       console.log(data["netValuePer"])
            //     }

          }, (err: any) => {
            this._ConfigService.showToast('error', 'Pricing fetch failed, please update again!');
          });
        })

      }
      else {
        console.log(kpPricingPayload)
        // let oldRateArray = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"]);
        // let oldValue = this.packetPlans[i]['packetPlanStones'][j]['net_stone_value']
        // let sum = oldRateArray.reduce((sum, current) => sum + current, 0);
        this.httpService.getPrincing(environment.PricingUrl, [kpPricingPayload]).subscribe((res: any) => {
          if (res.length > 0) {
            
            if (this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.packetPlans[i].id) > -1) {
              this.saveDraftUpdateData = this.saveDraftUpdateData.filter((dt: any) => dt.id != this.packetPlans[i].id);
              this.saveDraftUpdateData.push(this.packetPlans[i]);
            } else
              this.saveDraftUpdateData.push(this.packetPlans[i]);

            // if(this.saveDraftUpdateData?.length)
            // this.saveDraftUpdateDataSubject.next(this.saveDraftUpdateData);

            if (this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.packetPlans[i].id)) {
              this.saveDraftUpdateData[this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.packetPlans[i].id)]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res);
              this.saveDraftUpdateDataSubject.next(this.saveDraftUpdateData);
            }
            let data = res[0]
            this.packetPlans[i]['packetPlanStones'][j]["Lab"] = data["mLab"]
            this.packetPlans[i]['packetPlanStones'][j]["mLabName"] = data["mLab"];
            this.packetPlans[i]['packetPlanStones'][j]["labName"] =data["mLab"];
            this.packetPlans[i]['packetPlanStones'][j]['kgRate'] = data["stone_value"]
            this.packetPlans[i]['packetPlanStones'][j]['labour'] = data["labour"];
            this.packetPlans[i]['packetPlanStones'][j]['pointer_range'] = data["pointer_range"];
            this.packetPlans[i]['packetPlanStones'][j]['net_stone_value'] = data["net_stone_value"];
            this.packetPlans[i]['packetPlanStones'][j]['pricingResponse'] = JSON.stringify(res.find(res => res.id == this.packetPlans[i]['packetPlanStones'][j]?.id));
            let newStonePRice = this.packetPlans[i]['packetPlanStones'].map(e => e["net_stone_value"])
            let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
            this.packetPlans[i]["netStoneValues"] = Math.round(newPrice * 100) / 100

            for (let data of this.packetPlans[i].packetPlanStones) {
              data["netValuePer"] = (data["net_stone_value"] / newPrice * 100).toFixed(2);
            }
            let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan: any) => (!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
            let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
            this.packetPlans[i]['totalKGRate'] = Math.round(totalRateSum * 100) / 100;


            let higestNetStoneValue;
            let rank = 1;
            if (
              (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
              (this.selectedRow?.currActionName == "Smart Recut Planning") ||
              (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
              (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
            ) {
              this.packetPlans?.map((dt: any) => {
                if (dt.planDone === '1')
                  higestNetStoneValue = dt.netStoneValues;
              });
            }
            if (!(higestNetStoneValue > -1)) {
              this.packetPlans?.map((dt: any) => {
                if (dt.netStoneValues > (higestNetStoneValue || 0))
                  higestNetStoneValue = dt.netStoneValues;
              });
            }
            let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
            this.packetPlans?.forEach((dt: any) => {
              if (dt?.netStoneValues <= previousNetStoneValue) {
                dt.rank = rank;
                previousNetStoneValue = dt.netStoneValues;
              }
              if (dt?.netStoneValues && higestNetStoneValue) {
                if (
                  (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                  (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                  (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                  (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
                )
                  dt.rankDiff = (((dt.netStoneValues - higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
                else
                  dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
              }
              else
                dt.rankDiff = 0;
              rank++;
            });

            // newPrice = sum - data["net_stone_value"]
            // this.packetPlans[i]['packetPlanStones'][j]['net_stone_value'];
            // this.getTotal(this.packetPlans[i],"net_stone_value")

          }
          // tempPRice["stone_value"]
          //                     data["labour"] = tempPRice["labour"]
          //                     data["pointer_range"] = tempPRice["pointer_range"]
          //                     data["net_stone_value"] = tempPRice["net_stone_value"]
        }, (err: any) => {
          this._ConfigService.showToast('error', 'Pricing fetch failed, please update again!');
        });
      }
      if (!!res.data) {
        
      }
    });
  }

  async updateOrderFormAfterUpdatingParam(payload, i?: number, j?: number) {
    console.log(payload);
    console.log(i);
    let response = await this.httpService.postDataUsingPromise(
      true,
      'packetPlanStone/getOrderList',
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data = response.data;
      if (data?.length) {
        if (i > -1 && j > -1) {
          this.packetPlans[i]['packetPlanStones'][j] = data[0];
          this.packetCalculationsInit();
        } else if (i > -1) {
          console.log(this.packetPlans[i]);
          this.packetPlans[i] = data[0];
          this.packetCalculationsInit()
        }

        else {
          this.packetPlans = data;
        }
      } else {
        this.packetCalculationsInit()
      }
    } else {
      console.error(response);
    }
  }

  disaplyMaxValue(orderValue, kgRate) {
    if (orderValue === undefined || orderValue === null || orderValue === "-" || isNaN(orderValue)) {
      orderValue = 0;
    }
    if (kgRate === undefined || kgRate === null || kgRate === "-" || isNaN(kgRate)) {
      kgRate = 0;
    }
    return Math.max(Number(orderValue), Number(kgRate));
  }

  getGridMarginTop(plan: any, index: number) {
    if (this.page == "intermediatePlanning") {
      return '16px';
    }
    if(this.packetPlans.length > 0 ){
      const absoluteIndex = this.packetPlans.findIndex((plan: any) => plan?.planDone === '1');
      if (absoluteIndex > -1) {
        const absoluteEle = document.getElementById('grid_' + absoluteIndex);
        if (absoluteIndex == 0) {
          if (index == 1)
            return `${((absoluteEle?.clientHeight) ? (absoluteEle.clientHeight + 40) : 0) + 16}px`;
        } else {
          if (index == 0)
            return `${((absoluteEle?.clientHeight) ? (absoluteEle.clientHeight + 40) : 0) + 16}px`;
        }
      }
    }
    return '16px';
  }
  getGridWidth(plan: any, index: number) {
    if (this.page == "intermediatePlanning") {
      return 'auto';
    }
    if (plan?.planDone === '1') {
      const widthIndex = this.packetPlans.findIndex((plan: any) => plan.planDone !== '1');
      if (widthIndex < 0) {
        const widthEle = document.getElementById('tableHead');
        return ((widthEle?.clientWidth - 25) + 'px') || 'auto';
      }
      const widthEle = document.getElementById('grid_' + widthIndex);
      return ((widthEle?.clientWidth + 2) + 'px') || 'auto';
    }
    return 'auto';
  }

  updatePayload(res) {
    console.log(res);
    let grade = res.grade[0];
    let parameters = {};
    let fl = res.dropdown['Fluorescence'];
    let cl = res.dropdown['Color'];
    let cla = res.dropdown['Clarity'];
    console.log(fl);
    console.log(cl);
    console.log(cla);
    let fluorescence = !!fl ? fl.name : '';
    let fluorescenceID = !!fl ? fl.id : null;
    let color = !!cl ? cl.name : '';
    let colorID = !!cl ? cl.id : null;
    let clarity = !!cla ? cla.name : '';
    let clarityID = !!cla ? cla.id : null;
    let packetID = this.packetPlans[0].packetId;
    console.log(packetID);
    let packetIdObj = { packetId: this.rowData.packetId };
    parameters = {
      'packetID': packetID,
      'fluorescence': fluorescence,
      'fluorescenceID': fluorescenceID,
      'color': color,
      'colorID': colorID,
      'clarity': clarity,
      'clarityID': clarityID,
      'shape': null,
      'shapeId': null,
      'piece': null
    }
    let postData = {
      "flourescenceId": fluorescenceID,
      "packetId": packetID,
      "clarityId": clarityID,
      "colorId": colorID,
      "grade": !!grade ? grade.id : null
      // "shapeId":  null,
    }
    console.log(fluorescence);
    console.log(fluorescenceID);
    console.log(color);
    console.log(colorID);
    console.log(clarity);
    console.log(clarityID);
    console.log(postData);

    console.log(parameters);
    this.store.dispatch(SignerAction.updateFLCLClAInPacketPlanStone({ updateData: postData, packetIdObj: packetIdObj, parameters: parameters }));
    this.store.dispatch(SignerAction.updateDropDownParam({ updateData: parameters }));
    this.dropdown = {};
    if (fluorescenceID != null) {
      this.dropDownFLData.selectedData = [];
    }
    if (colorID != null) {
      this.dropDownCLData.selectedData = [];
    }
    if (clarityID != null) {
      this.dropDownCLAData.selectedData = [];
    }
  }


  DownloadExcel() {
      let data = this.packetPlans.filter(p => p.signerId === this.isCurrentCheckerID);
     if (data.length > 0) {
      const headers = [
        { header: 'id', key: 'id' },
        { header: 'packetId', key: 'packetId' },
        { header: 'piece', key: 'piece' },
        { header: 'planShape', key: 'planShape' },
        { header: 'planColor', key: 'planColor' },
        { header: 'planCut', key: 'planCut' },
        { header: 'planSymmetry', key: 'planSymmetry' },
        { header: 'planFluorescence', key: 'planFluorescence' },
        { header: 'planClarity', key: 'planClarity' },
        { header: 'sawRghWgt', key: 'sawRghWgt' },
        { header: 'signerName', key: 'signerName' },
        { header: 'kgRate', key: 'kgRate' },
        { header: 'seqNo', key: 'seqNo' },
        { header: 'planModifiedBy', key: 'planModifiedBy' },
        { header: 'netStoneValues', key: 'netStoneValues' },
        { header: 'totExpYield', key: 'totExpYield' },
        { header: 'totalKGRate', key: 'totalKGRate' },
        { header: 'totalMaxOrderValue', key: 'totalMaxOrderValue' },
        { header: 'rank', key: 'rank' },
        { header: 'rankDiff', key: 'rankDiff' },
        { header: 'pavHeight', key: 'pavHeight' },
        { header: 'crAngle', key: 'crAngle' },
        { header: 'crHeight', key: 'crHeight' },
        { header: 'tableVal', key: 'tableVal' },
        { header: 'girdle', key: 'girdle' },
        { header: 'diameter', key: 'diameter' },
        { header: 'height', key: 'height' },
        { header: 'lOrW', key: 'lOrW' },
        { header: 'heightMM', key: 'heightMM' },
        { header: 'widthMM', key: 'widthMM' },
        { header: 'lengthMM', key: 'lengthMM' },
        { header: 'labour', key: 'labour' },
        { header: 'pointer_range', key: 'pointer_range' },
        { header: 'net_stone_value', key: 'net_stone_value' },
        { header: 'netValuePer', key: 'netValuePer' },
      ];

      let flattenedData = [];

      // Flatten the data
      data.forEach(item => {
        if (item.packetPlanStones && item.packetPlanStones.length > 0) {
          item.packetPlanStones.forEach(stone => {
            let flattenedItem = {};
            headers.forEach(h => {
              flattenedItem[h.header] = stone[h.key] !== undefined ? stone[h.key] : item[h.key];
            });
            flattenedData.push(flattenedItem);
          });
        } else {
          let flattenedItem = {};
          headers.forEach(h => {
            flattenedItem[h.header] = item[h.key];
          });
          flattenedData.push(flattenedItem);
        }
      });

      console.log(flattenedData);

      const fileName = "export.xlsx";
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const workbookBinaryString = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([this.bufferExcel(workbookBinaryString)], { type: "application/octet-stream" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
     else {
       this._ConfigService.showToast('error','No Data Present')
    }
  }

bufferExcel(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}


  isExpand(val, i) {
    console.log(val);
    this.isexpand = !val;
    this.packetPlans[i].isExpand = this.isexpand;

  }
  parentPlanChange(evt: any, inData: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(inData, "indata")
    console.log('-----------parentPlanChange-----------');
    console.log(this.packetPlans)

    let filter = this.packetPlans.filter(x => x.diffSelect)
    console.log(filter.length)
    if (evt.detail.checked && filter.length < 2) {

      this.packetPlans = this.packetPlans.map(s => {
        if (s.id == inData.id) {
          return {
            ...s,
            diffSelect: true,
            checked: true
          }
        } else {
          return s
        }
      })
    } else {

      this.packetPlans = this.packetPlans.map(s => {
        if (s.id == inData.id) {
          return {
            ...s,
            diffSelect: false,
            checked: false
          }
        } else {
          return s
        }

      })
    }
    this.updatePlansData.emit(this.packetPlans)
    // this.store.dispatch(SignerAction.changePlanOncheck({ plan: inData, isChecked: evt.detail.checked }));
  }
  childPlanChange(evt: any, d: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('childPlanChange');
    console.log(evt);
    console.log(d);
  }

  showDetail(evt: any, rd: any, index: any) {
    return;
    console.log(this.page);
    if (this.page != 'Spectrum') {
      console.log(index);
      evt.preventDefault();
      evt.stopPropagation();
      let emData = {
        event: evt,
        rowData: rd
      }
      console.log(index);

      console.log(rd.selected);

      if (index != null && this.isCapFileUpload) {
        this.onPlanSelection.emit(emData);
        console.log(index);
        if (!this.packetPlans[index].selected) {
          this.packetPlans[index].selected = true;

        }
      }
      else if (!this.isCapFileUpload) {
        this._ConfigService.showToast(true, "Please Upload Cap File")
      }
    }

  }

  async getPrograms(rowData, currPlan) {
    console.log('getPrograms');
    let packetId = rowData.packetId;
    let planStoneId = rowData.id;
    this.currentPlan = currPlan;
    this.currentParentPlan = rowData;
    console.log(currPlan)
    console.log(packetId)
    console.log(planStoneId)
    // await this.store.dispatch(getProgramBypacketIdAndPlanStoneId({ packetId: packetId, planStoneId: planStoneId }));
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'signerModal',
      componentProps: {
        "message": 'showProgram',
        'data': rowData,
        'plan': currPlan,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }

  onModalExit(d: any) {
    console.log('---onModalExit');
    console.log(d);
    if (!!d.data) {
      if (d.data.for == 'saveProgram') {
        let packetId = this.currentParentPlan.packetId;
        let planStoneID = this.currentPlan.id;
        let progName = d.data.program.progName;
        let progId = d.data.program.id;
        if (this.changes['progName'] == undefined) {
          this.changes['progName'] = {};
          this.changes['progName'] = d.data.program;
        } else {
          this.changes['progName'] = d.data.program;
        }
        this.chLength = Object.keys(this.changes).length;
        this.isDisable = this.chLength !== 0 ? false : true;
        console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
        console.log(this.chLength);
        console.log(this.isDisable);
        // this.store.dispatch(SignerAction.showSelectedProgram({ packetId: packetId, planStoneID: planStoneID, program: progName, programID: progId }));
      } else if (d.data.for == 'savePlannedRemark') {
        console.log('Planned Remark');
        console.log(d);
        this.packetPlans = [...this.packetPlans].map(n => {
          let p = { ...n }
          if (p.id == d.data.packetPlanID) {
            p.plannedRemark = d.data.plannedRemark;
          }
          return p;
        })
      }
    }
  }

  async onParentPlanSelection(evt, rd) {
    //this.selectedFinalPlan = 
    // this.planSelected.emit(rd.id)
    console.log('---------onParentPlanSelection-------------');
    console.log(evt);
    console.log(rd);
    console.log(this.selectedRow)
    let order = rd.selectionOrder
    console.log(evt.detail.checked);
    console.log(this.packetPlans);
    let isAllChildFileSelected = false;
    let isAllChldPDSaved = false;

    if (evt.detail.checked) {
      console.log('Checked');

      let chkSelFileLength = 0;
      let chkPdSavedLength = 0
      let childRows = rd.packetPlanStones;
      let childRowsLength = rd.packetPlanStones.length;
      childRows.forEach((d: any) => {
        if (d.isFileSelected || d.xpsFile) {
          chkSelFileLength++;
        }
        if (d.pdSaved) {
          chkPdSavedLength++;
        }
      });
      if (childRowsLength > 0) {
        isAllChildFileSelected = true;
      }

      if (childRowsLength == chkPdSavedLength) {
        isAllChldPDSaved = true;
      }

      let order = 1;
      const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      if (indexes.length > 0) {
        console.log(order)
        order = Math.max(...indexes) + 1;
      }

      this.packetPlans = this.packetPlans.map(x => {
        let n = { ...x }
        if (n.id == rd.id) {
          //let clone = { ...x };
          n['selectionOrder'] = order;
          n['planPriority'] = order;
          n.selected = true;
        } else {
          n.selected = false;
        }
        return n
      })
    } else {
      this.packetPlans = this.packetPlans.map(x => {
        if (x.id == rd.id) {
          let n = { ...x }
          delete n.selectionOrder;
          delete n.planPriority;
          return n
        } else {
          return x
        }

      })
      // const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      // if (indexes.length > 0) {
      //   this.packetPlans = this.packetPlans.map(x => {
      //     if (x.selectionOrder && x.selectionOrder > order) {
      //       x.selectionOrder = x.selectionOrder - 1
      //     }
      //     return x
      //   })
      // }


    }
    console.log(isAllChildFileSelected);
    console.log(isAllChldPDSaved);

    const checkboxes = this.packetPlans.length;
    // let selected = 0;
    // this.packetPlans.map((d: any) => {
    //   console.log(d);

    //   if (d.selected) selected++;
    // });

    // if (selected > 0 && selected < checkboxes) {
    //   this.setIndeterminateState = true;
    //   this.parentCheckbox = false;
    // } else if (selected == checkboxes) {
    //   this.parentCheckbox = true;
    //   this.setIndeterminateState = false;
    // } else {
    //   this.setIndeterminateState = false;
    //   this.parentCheckbox = false;
    // }
    console.log(checkboxes);
    // console.log(selected);
    // console.log(this.setIndeterminateState);
    this.store.dispatch(chekerAction.changePlanOncheck({ plan: rd, isChecked: evt.detail.checked }));
  }
  
  async processValue(value) {
    value = String(value);
    
    let decimalIndex = value.indexOf('.');
    
    let newValue;
    
    if (decimalIndex === -1 || value.length - decimalIndex - 1 <= 2) {
      newValue = Number(value).toFixed(2);
    } else {
      let thirdDecimalPlace = value.charAt(decimalIndex + 3);
      
      if (Number(thirdDecimalPlace) >= 8) {
        newValue = (Math.ceil(Number(value) * 100) / 100).toFixed(2);
      } else {
        newValue = (Math.floor(Number(value) * 100) / 100).toFixed(2);
      }
    }

    return newValue;
  }
  // on Parent Checkbox selection  START
  async onParentCheckBoxSelection(evt, rd,i){ 
    this.selectedPlanIndex = i;
    let totalRw = 0;
    rd.packetPlanStones.map((ps: any) => {
      totalRw += Number(ps.sawRghWgt || '0');
    });
    if(this.selectedRow.vitualActionName == "Bombay Mail" && this.selectedCheckerTabs == 'All'){
      this.packetPlans = this.packetPlans.map(x => {
        let n = { ...x }
        n['selected'] = false;
        n['isSelected'] = false;
        if (n.selectionOrder) {
          delete n.selectionOrder
          delete n.planPriority
        }
        return n
      })
      this._ConfigService.showToast("error", 'Action not permitted!');
      return;
    }
    if (this.page == 'adminApproval')
      this.store.dispatch(SignerAction.removeSelectedPlans());

    if ((this.page == 'checker' || this.page === 'headchecker' || this.page == "adminApproval" ||  this.page == ACTIONNAMES.MAKEABLE_PLANNING ||  this.page == ACTIONNAMES.intermediatePlanning) && this.selectedCheckerTabs == 'All') {
      this.packetPlans = this.packetPlans.map(x => {
        let n = { ...x }
        n['selected'] = false;
        n['isSelected'] = false;
        if (n.selectionOrder) {
          delete n.selectionOrder
          delete n.planPriority
        }

        return n
      })
    }

    console.log('---------onParentPlanSelection-------------');
    console.log(evt);
    console.log(rd);
    console.log(this.selectedRow)
    console.log(this.page)
    if(this.selectedRow?.lastActionName === "Final Planning" && this.selectedRow?.lastActionStatus == "Completed") {
      return
    }

    this.eventObj = {
      event: evt,
      rowData: rd
    }
    // this.onPlanSelection.emit(this.eventObj);
    // return
    let order = rd.selectionOrder
    console.log(evt.detail.checked);
    console.log(this.isParentcheckbox);
    this.backdrop = evt.detail.checked;
    this.isParentcheckbox = evt.detail.checked;
    this.isDataSavebyUser = evt.detail.checked;
    this.isParentCheckbox.emit(this.isParentcheckbox)
    this.isCurrentTab.emit(this.selectedCheckerTabs)
    // if(!this.isDataSavebyUser){
    // console.log("when its true");
    // //  this.isParentcheckbox=true;
    //   await this.showAlertForCheckbox(rd);


    // }
    let isAllChildFileSelected = false;
    let isAllChldPDSaved = false;
    if (evt.detail.checked && (this.isCapFileUpload || (this.page == "adminApproval")) && this.isWeightCorrect) {
      this.remarksPlanID.push(rd.id);
      let chkSelFileLength = 0;
      let chkPdSavedLength = 0
      let childRows = rd.packetPlanStones;
      let childRowsLength = rd.packetPlanStones.length;
      childRows.forEach((d: any) => {
        if (d.isFileSelected || d.xpsFile) {
          chkSelFileLength++;
        }
        if (d.pdSaved) {
          chkPdSavedLength++;
        }
      });
      if (childRowsLength == chkSelFileLength) {
        isAllChildFileSelected = true;
      }

      if (childRowsLength == chkPdSavedLength) {
        isAllChldPDSaved = true;
      }

      let order = 1;
      const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      if (indexes.length > 0) {
        console.log(order)
        order = Math.max(...indexes) + 1;
      }

      this.packetPlans = this.packetPlans.map((x: any) => {
        if (x.id == rd.id) {
          let item = { ...x };
          if (this.isCapFileUpload || (this.page == "adminApproval")) {
            item['selectionOrder'] = order;
            rd["selectionOrder"] = order
          }

          item['planPriority'] = order;
          item['selected'] = true;
          item['isSelected'] = true;
          x = { ...item };
        }
        return x
      })
      console.log(this.packetPlans)
      if (this.page == 'planning') {
        rd.selectionOrder = order;
        rd.planPriority = order;
        rd['selected'] = true;
        rd['isSelected'] = true;
      }
    } else {
      this.packetPlans = this.packetPlans.map(x => {
        if (x.id == rd.id) {
          let n = { ...x }
          n['selected'] = false
          n['isSelected'] = false;
          delete n.selectionOrder;
          delete n.planPriority;

          rd['selected'] = false
          rd['isSelected'] = false;
          delete rd.selectionOrder;
          delete rd.planPriority;

          return n
        } else {
          return x
        }

      })
      const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder);

      const otherPlansToUpdate = [];
      if (indexes.length > 0) {
        this.packetPlans = this.packetPlans.map(x => {
          let n = { ...x }
          if (n.selectionOrder && n.selectionOrder > order && x.id !== rd.id) {
            n.selectionOrder = n.selectionOrder - 1;
            n.planPriority = n.planPriority - 1;
            n['selected'] = true;
            n['isSelected'] = true;
            otherPlansToUpdate.push(n);

            this.store.dispatch(SignerAction.changePlanOncheck({ plan: n, isChecked: true }));
          }
          return n
        })
      }
      // this.httpService.saveData(true, 'packetPlan/updatePacketPlansAndStones', [rd, ...otherPlansToUpdate]).subscribe((res: any) => {
      //   if (res.statusCode === 200) { }
      //   else {
      //     this._ConfigService.showToast("error", res.error.message || 'Something went wrong!');
      //   }
      // });

    }

    this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: evt.detail.checked }));
    console.log(isAllChildFileSelected);
    console.log(isAllChldPDSaved);
    console.log(!this.selectedFile);
    /*if (evt.detail.checked && !this.currPacketCapFile && !isAllChildFileSelected) {
      const alert = await this.alertController.create({
        header: 'Please upload Cap File and XPS file First',
        // subHeader: 'Subtitle for alert',
        // message: 'Are you sure do you want to delete the row?',
        cssClass: 'planAlertBox',
        buttons: [{
          text: 'OK',
          handler: (d: any) => {
            this.clearSelection(evt, rd)
            console.log('OK', d);
          }
        }]
      });
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        this.clearSelection(evt, rd)
      });
      return;
    }
    if (evt.detail.checked && !this.currPacketCapFile) {
      const alert = await this.alertController.create({
        header: 'Please upload Cap File First',
        // subHeader: 'Subtitle for alert',
        // message: 'Are you sure doyou want to delete the row?',
        cssClass: 'planAlertBox',
        buttons: [{
          text: 'OK',
          handler: (async (d: any) => {
            this.clearSelection(evt, rd)
            console.log('OK', d);
          })
        }]
      });
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        this.clearSelection(evt, rd)
      });
      return;
    }*/
    console.log(this.isCapFileUpload);
    console.log(this.hasCheckerCapFile);
    if (!this.isCapFileUpload && this.page != 'adminApproval' && this.page != 'checker') {
      console.log("closing ")
      if(this.page == 'headchecker') {

      }
      else if ( this.page != 'adminApproval' || this.page != 'checker') {
        this._ConfigService.showToast(true, "Please Upload Cap File");
      }
      this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: false }));
      this.clearSelection(evt, rd)
    }
    if (this.page == 'checker' && !(this.isCapFileUpload || this.hasCheckerCapFile) && this.selectedRow.vitualActionName != ACTIONNAMES.BOMBAY_MAIL) {
      this._ConfigService.showToast(true, "Please Upload Cap File");
    }
    // else if ((this.isCapFileUpload  || this.hasCheckerCapFile==false) && this.page=='checker'){
    //   this._ConfigService.showToast(true, "Please Upload Cap File");  
    // }
    else
      if (((this.isCapFileUpload || this.hasCheckerCapFile) && this.page == 'checker') || (this.page !== 'checker') || this.selectedRow.vitualActionName == ACTIONNAMES.BOMBAY_MAIL) {
        // this._ConfigService.showToast(true, "Please Upload Cap File");  
        totalRw = await this.processValue(totalRw)
        let carat = await this.processValue(this.packetDetail?.Cts ? this.packetDetail.Cts : this.packetDetail?.carat)
        if ((Number(totalRw) > Number(carat))) {
          this._ConfigService.showToast("error", 'Total saw rgh wt cannot be more than Org wt!');
          this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: false }));
          this.clearSelection(evt, rd)
          return;
        } else
          if (evt.detail.checked && (this.isCapFileUpload || (this.page == "adminApproval" || this.selectedRow.vitualActionName == ACTIONNAMES.BOMBAY_MAIL) || ((this.isCapFileUpload || this.hasCheckerCapFile) && this.page == 'checker'))) {
            // if(this.page == 'intermediatePlanning' || this.page==ACTIONNAMES.MAKEABLE_PLANNING){
            //   return;
            // }
            console.log(this.packetPlans, this.eventObj.rowData);
            this.packetPlans.filter(el => {
              if (el.id === this.eventObj.rowData.id) {
                let rowD = { ...this.eventObj.rowData };
                rowD.planPriority = el.selectionOrder;
                rowD.selectionOrder = el.selectionOrder;
                this.eventObj.rowData = {
                  ...rowD,
                  selected: true,
                  isSelected: true,
                };
              }
            })
            if (
              !(
                (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
              ) && this.page !== "adminApproval"
            ) {
              this.onPlanSelection.emit(this.eventObj);
            }

            // const alert = await this.alertController.create({
            //   header: 'Please upload XPS File First',
            //   // subHeader: 'Subtitle for alert',
            //   // message: 'Are you sure doyou want to delete the row?',
            //   cssClass: 'planAlertBox',
            //   buttons: [{
            //     text: 'OK',
            //     handler: (d: any) => {
            //       this.clearSelection(evt, rd)
            //       console.log('OK', d);
            //     }
            //   }]
            // });
            // await alert.present();
            // await alert.onDidDismiss().then((data) => {
            //   this.clearSelection(evt, rd)
            // });
            // return;
          }
      }

    if (this.selectedRow?.vitualActionName == "Smart Recut Planning" || this.selectedRow?.currActionName == "Smart Recut Planning" || this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut || this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut) {
      if (this.selectedPlans.length > 1) {
        this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: false }));
        this.clearSelection(evt, rd)
        this._ConfigService.showToast("error", 'You Can Only Submit One Plan')
      }
    }
    // if (evt.detail.checked && !isAllChldPDSaved) {
    //   const alert = await this.alertController.create({
    //     header: 'Please Add PD Parameters',
    //     // subHeader: 'Subtitle for alert',
    //     // message: 'Are you sure doyou want to delete the row?',
    //     cssClass: 'my-custom-class',
    //     buttons: [{
    //       text: 'OK',
    //       handler: (d: any) => {
    //         this.clearSelection(evt, rd)
    //         console.log('OK', d);
    //       }
    //     }]
    //   });
    //   await alert.present();
    //   await alert.onDidDismiss().then((data) => {
    //     this.clearSelection(evt, rd)
    //   });
    //   return
    // }

    const checkboxes = this.packetPlans.length;
    // let selected = 0;
    // this.packetPlans.map((d: any) => {
    //   console.log(d);

    //   if (d.selected) selected++;
    // });

    // if (selected > 0 && selected < checkboxes) {
    //   this.setIndeterminateState = true;
    //   this.parentCheckbox = false;
    // } else if (selected == checkboxes) {
    //   this.parentCheckbox = true;
    //   this.setIndeterminateState = false;
    // } else {
    //   this.setIndeterminateState = false;
    //   this.parentCheckbox = false;
    // }
    console.log(checkboxes);

    // console.log(selected);
    // console.log(this.setIndeterminateState);
    // if(this.isSavedByUser === false && evt.detail.checked === false){
    // } else {

    // }

  }

  async clearSelection(evt, rd) {
    console.log(rd);
    evt.detail.checked = false;
    this.packetPlans = this.packetPlans.map(x => {
      if (x.id == rd.id) {
        let n = { ...x }
        n.checked = false
        n.selected = false;
        delete n?.selectionOrder
        delete n?.planPriority
        return n
      } else {
        return x
      }

    })
  }
  //on Parent Checkbox Selection END


  async showAlertForCheckbox(rd) {
    console.log(rd);
    const alert = await this.alertController.create({
      header: 'Are you sure you want to unselect checkbox ?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'Yes',
          handler: (d: any) => {
            console.log('Yes', d);
            this.isSavedByUser = true;
            this.packetPlans = this.packetPlans.map(x => {
              if (x.id == rd.id) {
                let n = { ...x }
                n['selected'] = false
                delete n.selectionOrder
                return n
              } else {
                return x
              }

            })
            // const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
            // if (indexes.length > 0) {
            //   this.packetPlans = this.packetPlans.map(x => {
            //     let n = { ...x }
            //     if (n.selectionOrder && n.selectionOrder > order) {
            //       n.selectionOrder = n.selectionOrder - 1
            //     }
            //     return n
            //   })
            // this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: false }));
          },
        },
        {
          text: 'No',
          handler: (d: any) => {
            console.log("ok");
            console.log(rd);
            this.isSavedByUser = false;
            this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: true }));

          },
        },
      ],
    });
    await alert.present();
  }

  async onClickPD(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('-------PD......');
    // console.log
    const pdmodal = await this.modalCtrl.create({
      component: PDComponent,
      // component: PdsComponent,
      cssClass: 'pdpopup',
      componentProps: {
        // "message": 'message',
        'data': { packetPlanStoneId: id, packetId: this.rowData.packetId, from: 'planGrid' }
      },
    });

    pdmodal.onDidDismiss().then((d: any) => {
      if (d.data) {
        this.PDValueSubmission(id)
      }
    });
    return await pdmodal.present();
  }
  filterAsSearched(ev: any, searchStr: string, val: any) {
    console.log('%cfilterAsSearched =' + val, 'color:green;font-weight:bold;');
    console.log(this.allData);
    // this.packetPlans = this.allData.filter((d) => {
    //   return d.seqNo.toLowerCase().indexOf(val.toLowerCase()) > -1;
    // });
    if (searchStr != null || val != null) {
      this.packetPlans = this.allData.filter((d: any) => {
        return d.packetPlanStones.some((x: any) => {
          return d.id === x.packetPlanId && x[searchStr].toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
      });
    }
    console.log(this.allData);
    console.log(this.packetPlans);
  }
  PDValueSubmission(m: any) {
    console.log(m.data)
    this.packetPlans = this.packetPlans.map(x => {
      if (x.packetPlanStones.length > 0) {
        let newPd = x.packetPlanStones.map(b => {
          if (b.id == m.data) {
            return {
              ...b,
              pdSaved: true
            }
          } else {
            return b
          }
        })
        return {
          ...x,
          packetPlanStones: newPd
        }
      }
    })
    console.log('PDValueSubmission');
    console.log(m);
    console.log(this.packetPlans)
    // this.store.dispatch(SignerAction.savePDParameterPacketPlanStone({pdParam:}));
  }

  async uploadRowFile(event, planId, rowId, index) {
    if (this.allowAction) {
      console.log(planId, index)
      if (event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        //console.log(this.packetPlans);
        this.packetPlans = [...this.packetPlans].map(n => {
          if (n.id == rowId) {
            const formatData = new FormData();
            formatData.append("file", selectedFile);
            formatData.append("packetPlanStoneId", planId)
            let otherParam = { packetPlanStoneId: planId, for: 'uploadXpsOrHtmlFile', "packetId": rowId }
            this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));
            //const copyOfObj = [...n.packetPlanStones];
            const packetPlanStones = n.packetPlanStones.map(x => {
              if (x.id == planId) {
                return {
                  ...x,
                  isFileSelected: true,
                  xpsFile: selectedFile
                }
              } else {
                return x
              }
            })
            return { ...n, packetPlanStones }
          } else {
            return n
          }
        })
        // this.packetPlans.filter(n => n.id == rowId)[0].packetPlanStones.filter(x => x.id == planData.id).map(y => {
        //   let newObj = Object.assign({ file: selectedFile }, y);
        //   console.log(newObj)
        //   return newObj
        // })
        console.log(this.packetPlans)
        this.validationCheck()
      }
    }

  }
  deletePartPlan(plan) {

  }

  download(file) {
    if (file) {
      let newFile = file.split("uploads/")
      let filePath = newFile[0] + "uploads/" + encodeURIComponent(newFile[1])
      window.open(filePath, 'Download');
    }
      
  }

  downloadjpg(file) {
    if (file) {
      let newFile = file.split("uploads/")
      let filePath = newFile[0] + "uploads/" + encodeURIComponent(newFile[1])
      window.open(filePath, 'Download');
    }

  }

  twoDecimalvalues(number) {
    let temp = String(number)
    let s = temp.split(".")
    return `${s[0]}${s.length > 1 ? "." + s[1].substring(0, 2) : ""}`
  }
  getTotal(d: any, key: string) {
    let total = 0;
    if (d?.packetPlanStones)
      d?.packetPlanStones?.map((dt: any) => {
        total += (dt?.[key] ? (typeof dt?.[key] == 'string' ? Number(dt?.[key]) : dt?.[key]) : 0);
      });
    return total.toFixed(2);
  }
  onProgramRemark() {
    this.remProg = this.remProg.map((item) => {
      let l = { ...item }
      l.selected = false
      return l;
    });
  }
  getNetPerc(net_stone_value: any, d: any) {
    let total = 0;
    d.packetPlanStones.map((dt: any) => {
      if (dt?.net_stone_value)
        total += dt.net_stone_value;
    })
    if (total > 0)
      return ((net_stone_value / total) * 100).toFixed(2);
    return 0;
  }

  async onRemark(id, selectedobj, currPacketPlanStone?:any) {
    let isPlanSelected = false;
    if (this.selectedSpectrumPlans?.length) {
      isPlanSelected = true;
    }
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'popupSignerRemarks',
      componentProps: { id: 1, name: "All Remark", grades: this.planTags, forPage: 'Planning', selectedDatas: selectedobj, isPlanSelected, currPacketPlanStone },

      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss()
      .then((res) => {
        if (res?.data) {
          if (isPlanSelected) {
            const payload = {
              "remark": res.data?.selectProgramRemark || "",
              "packetplanList": this.selectedSpectrumPlans?.map((dt: any) => dt.id),
            };
            this.httpService.saveData(true, 'packetPlan/updateRemarkOnMultipleRemark', payload).subscribe((res: any) => {
              if (res.statusCode === 200) {
                this._ConfigService.showToast("success", res.message || 'Saved successfully!');
                return true;
              } else {
                this._ConfigService.showToast("error", res.error.message || 'Something went wrong!');
                return false;
              }
            });
            return;
          }
          let Remark = !!res && res.data && res.data.selectProgramRemark ? res.data.selectProgramRemark : "";
          let grade = res.data.grade//d.data.selectProgramRemark.id;
          let gradeIds = []
          gradeIds = grade.map(n => parseInt(n.id));

          let listOfPacketPlaneIdList = this.packetPlans.map(x => x.id);
          if (this.isremark && id) {
            listOfPacketPlaneIdList = [];
            listOfPacketPlaneIdList.push(id);
          }
          console.log(gradeIds);
          console.log(Remark);
          console.log(listOfPacketPlaneIdList);
          this.store.dispatch(SignerAction.addRemarkForPacketPlan({
            remark: Remark,
            Grade: gradeIds,
            listOfPacketPlaneIdList: listOfPacketPlaneIdList,
            packetIdObj: { packetId: this.rowData.packetId },
            refresh: true,
          }));
        }
      });
  }
  async onProgramRemSelection(ev: any, d: any) {
    console.log('onProgramRemSelection');
    console.log(d);
    this.selectedRemarkProgram = {};
    this.remProg = this.remProg.map((item) => {
      let l = { ...item }
      if (!!l.hasOwnProperty('selected') && l.id == d.id) {
        this.selectedRemarkProgram = d;
        l.selected = true
      } else {
        l.selected = false
      }
      return l;
    }
    );
    console.log(d);
    console.log(this.remProg);
    console.log(this.selectedRemarkProgram);
    await this.popoverController.dismiss();
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'popupSigner',
      // showBackdrop: false,
      componentProps: { id: d.id, name: d.name, grades: this.planHex, forPage: 'Signer' },
      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss()
      .then((res) => {
        if (res.data) {
          // this.pushOverOpen = false;
          console.log('Signer pushOver Exit');
          console.log(res);
          this.onProgramRemarkSubmission(res);
        }
      });
  }
  async onProgramRemarkSubmission(d) {
    console.log('onProgramRemarkSubmission');
    console.log(d);
    let Remark = d.data.selectProgramRemark.name;
    let grade = this.gradeToNum[Remark]//d.data.selectProgramRemark.id;
    console.log(grade, "grade");
    let gradeIds = []
    gradeIds = await d.data.selectedGrades.filter(x => x.id).map(n => n.id)
    let listOfPacketPlaneIdList = await this.packetPlans.map(x => x.id)
    this.store.dispatch(SignerAction.addRemarkForPacketPlan({
      remark: Remark,
      Grade: gradeIds,
      listOfPacketPlaneIdList: listOfPacketPlaneIdList,
      packetIdObj: { packetId: this.rowData.packetId },
      refresh: true,
    }))

  }
  dropDownSelection(evt: any, plan: any) {
    console.log('dropDownSelection');
    console.log(evt);
    console.log(this.changes);
    if (evt.property == "Remarks") {
      console.log('remark');
      console.log(evt);
      this.setChanges(evt, plan);
    } else if (evt.property == 'Fluorescence') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Color') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Clarity') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Shape') {
      this.dropDownShapeData.selectedData = evt.arrayList;
    } else if (evt.property == 'Piece') {
      this.dropDownPieceData.selectedData = evt.arrayList;
    }
    else if (evt.property == 'Sorting') {
      this.dropDownSortingData.selectedData = evt.arrayList;
      console.log(this.dropDownSortingData.selectedData);
      if (this.dropDownSortingData.selectedData.length > 0) {
        this.sortvalue = this.dropDownSortingData.selectedData[0].name;
        console.log(this.sortvalue);
        this.sortpacket();
      }
      else {
        this.sortvalue = "";
        this.sortpacket();
      }

    }
  }
  selectionChange(evt: any) {
    console.log('evt');
    console.log(evt);


  }
  onSpectrum() {
    if ((this.isCurrentUserSame) || (this.page==='checker' && this.hasCheckerCapFile) ) {
      this.apiToSendForXray_Spectrum_bombayMail().then((apiResult) => {
        if (apiResult === true) {
          let payload = {
            "nextDeptId": 'spectrum ',
            "packetId": this.rowData.id
          };
          this.sendStoneToParticularDept(payload);
        } else {
          console.log("apiToSendForXray_Spectrum_bombayMail did not return true.");
        }
      });
    }
    else {
      this._ConfigService.showToast(false, "Please upload Cap File")
    }
  }

  async onBombayMail() {
    // if (this.selectedSpectrumPlans.length <= 0) {
    //   this._ConfigService.showToast("error", 'No Plan Selected');
    //   return
    // }
    await this.fetchBombayMailDropdownData()
    const modal = await this.modalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.bombayMailModel,
      },
      cssClass: 'DynamicSalesPersonModalSmall',
    });
    modal.onDidDismiss().then(async (d: any) => {
      if (d.data) {
        let payload = {
          nextDept: 'bombay mail',
          packetId : this.rowData.id,
          toUser : d.data.toUser.id,
        }

      // await this.apiToBombayMail()
        this.bombayMailRemarks = d?.data?.remark
    // this.apiToSendForXray_Spectrum_bombayMail().then((apiResult) => {
      // if (apiResult === true) {
      await this.sendStoneToParticularDept(payload);
      // this.router.navigate(["/home"]);
      // this._ConfigService.showToast("success", 'Successfully sent for bombay mail');
        // let payload = {
        //   "nextDept": 'bombay mail',
        //   "packetId": this.rowData.id,
        //   "toUser" : d?.data?.toUser?.id,
        // };
      // } else {
      //   console.log("apiToSendForXray_Spectrum_bombayMail did not return true.");
      // }
    // });
   }
  })
    return await modal.present();
}


  async onXray() {
    const modal = await this.modalCtrl.create({
      component: PlanningModalComponent,
      cssClass: 'trybe_modal_1',

    });

    modal.onDidDismiss().then((d: any) => {
      if (d.data) {
        console.log(d.data)
        if (d.data?.recutVal) {
          this.apiToSendForXray_Spectrum_bombayMail().then((apiResult) => {
            if (apiResult === true) {
              let payload = {
                "nextDept": "x-ray",
                "packetId": this.rowData.id,
                "isSmartRecuit": (d?.data?.recutVal == "Smart Recut") ? 1 : 0,
              };
              this.sendStoneToParticularDept(payload);
            } else {
              console.log("apiToSendForXray_Spectrum_bombayMail did not return true.");
            }
          });
        }
      }
    });
    return await modal.present();
  }

  onUploadEcsv() {
    console.log("uploading the ecsv");

  }
  isSpectrumSelected(data: any) {
    return this.selectedSpectrumPlans.find((dt: any) => dt.id === data.id) ? true : false;
  }

  spectrumCheck(event: any, data: any) {
    if (this.selectedSpectrumPlans.find((dt: any) => dt.id === data.id))
      this.selectedSpectrumPlans = this.selectedSpectrumPlans.filter((dt: any) => dt.id !== data.id);
    else
      this.selectedSpectrumPlans.push(data);
  }

  async apiToSendForXray_Spectrum_bombayMail() {
    if (this.selectedSpectrumPlans.length <= 0) {
      this._ConfigService.showToast("error", 'No Plan Selected');
      return
    }

    try {
      const packetPlansList = [];
      this.selectedSpectrumPlans.map((dt: any) => {
        packetPlansList.push(dt.id);
      });
      let payload = {
        "packetPlansList": packetPlansList,
        "packetId": this.rowData.id,
        "remarks" :  this.bombayMailRemarks
      };

      let response = await this.httpService.postDataUsingPromise(true, "plan-review-after-checker/save", payload);
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);

      if (response.statusCode === 200) {
        console.log(response.data);
        let data = response.message;
        console.log(data);
        this._ConfigService.showToast("success", data);
        return true;
      } else {
        console.error(response);
        this._ConfigService.showToast("error", response.error.message);
        return false;
      }
    } catch (error) {
      console.error("Error in API request", error);
      return false;
    }
  }



  async sendStoneToParticularDept(payload) {
    console.log('send to xray and spectrum dept');
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "stone-specific-rule/saveNextActionAndDept", payload)
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.router.navigate(["/home"]);
      this._ConfigService.showToast("success", data);
    } else {
      console.error(response);
      this._ConfigService.showToast("error", response.error.message);
    }

  }

  downloadCap() {
    console.log("hiiiiiiiiiiiiiiii");
  }
 async uploadCapFile() {
    console.log('...........uploadCapFile..........');
    console.log(this.selectedFile);
    console.log(this.selectedValue);
    if (this.isCurrentCheckerID == this.selectedValue) {
      this.hasCheckerCapFile = true;
    }
    let packetId = this.rowData.id;
    const formatData = new FormData();
    console.log(packetId);
    console.log(formatData);
    formatData.append("file", this.selectedFile);
    console.log(formatData);
    let otherParam = { packetID: packetId, for: 'uploadCapFile' }
    console.log(otherParam);
    this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));//uploadCapFile
    this.isCapFileUpload = true;
    // this.selectedFile = null;
  }

  inActivePacketPlanStone(plan: any, d?:any, i?:number)  {
    if((this.page == 'intermediatePlanning' || this.page==ACTIONNAMES.MAKEABLE_PLANNING) && d?.planDone==='1') return;
    const payload = {
      "inActive": plan.inActive,
      "packetPlanStoneIds": [plan.id]
    };
    if (this.page !== 'headchecker')
      this.httpService.saveData(true, 'packetPlanStone/updateInActive', payload).subscribe((res: any) => {
        if (res.statusCode === 200) {
          // this._ConfigService.showToast('success', res.message || 'Uploaded successfully!');
          if(i > -1){
            let totalRate = this.packetPlans[i]['packetPlanStones'].filter((plan:any)=>(!(plan?.inActive === 1 || plan?.intermediateInActive === 1))).map((e: any) => this.disaplyMaxValue(0, e["kgRate"]));
            let totalRateSum = totalRate.reduce((sum, current) => sum + current, 0);
            this.packetPlans[i]['totalKGRate'] = totalRateSum;
          }

        } else {
          this._ConfigService.showToast('error', res.message || 'Something went wrong updating plan!');
          plan.inActive = (plan.inActive == 1) ? 0 : 1;
        }
      })
  }

  onmsgiconclick(planId, currPacketPlanStone) {
    // console.log(remakrvalue)
    // console.log(i);
    // console.log(id)
    // .remark,plan.piece
    console.log(planId);
    console.log(currPacketPlanStone);

    console.log("onremarkclick");
    this.selectedRemark = {
      "remarkVal": currPacketPlanStone.remark,
      "piece": currPacketPlanStone.piece,
      "packetPlanStoneId": currPacketPlanStone.id,
      "planId": planId,
    }
    this.isremark = true;
    console.log(this.isremark);
    this.onRemark(planId, this.selectedRemark, currPacketPlanStone);
  }

  onNextButton() {
    console.log("next btn");
    let nextbtn = true;
    this.onNextBtnevent.emit(nextbtn);
  }
  setChanges(evt: any, plan: any) {
    console.log(evt, 'set Changes');
    console.log(plan, 'set Changes');

    let dropDnValues = evt.arrayList[0];
    if (evt.arrayList.length > 0 && !!plan && !!plan.id) {
      let obj = {
        planID: plan.id,
        ...evt.arrayList[0]
      };
      console.log(obj);
      let currRecordIndex = this.selectedDropDnRemark.findIndex((i: any) => i.planID == plan.id);
      if (this.selectedDropDnRemark.length > 0 && currRecordIndex > -1) {
        this.selectedDropDnRemark[currRecordIndex] = obj;
      } else {
        this.selectedDropDnRemark.push(obj);
      }
    } else {
      let currRecordIndex = this.selectedDropDnRemark.findIndex((i: any) => i.planID == plan.id);
      console.log(currRecordIndex);
      if (currRecordIndex > -1) {
        this.selectedDropDnRemark.splice(currRecordIndex, 1);
      }
    }
    console.log(this.selectedDropDnRemark);
    this.chLength = this.selectedDropDnRemark.length;
    this.isDisable = this.chLength !== 0 ? false : true;
    console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
    console.log(this.chLength);
    console.log(this.isDisable);
  }

  async onResetOrder(d, indexofChild, indexOfPlan?: any) {
  // let isActiveFound = false;
  // this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'] = this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'].map((dt: any) => {
  //   if (dt.isActive && !dt.orderRemark && !isActiveFound) {
  //     isActiveFound = true;
  //   }
  //   return { ...dt }; // Create a shallow copy of the order object
  // });

  // if (!isActiveFound) {
  //   let highestValue = 0, highestValueId;
  //   (d?.packetPlanStones?.[indexofChild]?.orders || []).forEach((dt: any) => {
  //     if (!dt?.orderRemark && (highestValue < dt.finalValue)) {
  //       highestValue = dt.finalValue;
  //       highestValueId = dt.id;
  //     }
  //   });
  //   (d?.packetPlanStones?.[indexofChild]?.orders || []).forEach((dt: any, i: number) => {
  //     if (!dt?.orderRemark && highestValueId === dt.id) {
  //       this.packetPlans[indexOfPlan].packetPlanStones[indexofChild].orders[i] = {
  //         ...dt,
  //         isActive: true
  //       };
  //     } else {
  //       this.packetPlans[indexOfPlan].packetPlanStones[indexofChild].orders[i] = {
  //         ...dt,
  //         isActive: false
  //       };
  //     }
  //   });
  // }
console.log(d?.packetPlanStones[indexofChild]);
  const resetOrder = await this.modalCtrl.create({
    component: ResetordermodalComponent,
    cssClass: 'restorePage',
    componentProps: {
      data: { orderDetails: d?.packetPlanStones[indexofChild], packetId: this.rowData?.id, showIsActive: true }
    },
  });

  resetOrder.onDidDismiss().then((d: any) => {
    if (d.data) {
      if (d.data?.dataSource) {
        this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'] = d.data.dataSource.map((dt: any) => {
          let odt = (this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'] || []).find((oodt: any) => oodt.id == dt.id);
          return {
            ...(odt || {}),
            ...dt,
            orderRemark: dt.orderRemark?.name || '',
          };
        });
        // add updated orders
      }
      let highestValue = 0, isActiveFound = false;
      this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'] = this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'].map((dt: any) => {
        if (dt.isActive && !dt.orderRemark && !isActiveFound) {
          highestValue = dt.finalValue;
          isActiveFound = true;
        }
        return { ...dt }; // Create a shallow copy of the order object
      });

      if (!isActiveFound) {
        (this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['orders'] || []).forEach((dt: any) => {
          if (!dt?.orderRemark && (highestValue < dt.finalValue)) {
            highestValue = dt.finalValue;
          }
        });
      }
      this.packetPlans[indexOfPlan].packetPlanStones[indexofChild]['maxOrderValue'] = highestValue;

      this.packetCalculationsInit();
      // this.PDValueSubmission(id)
    }
  });
  return await resetOrder.present();
}

  async packetCalculationsInit() {
    this.packetPlans = this.packetPlans.map(packetPlan => {
      let packetPlanClone = { ...packetPlan };
      packetPlanClone['totalMaxOrderValue'] = 0;
      packetPlanClone["totalKGRate"] = 0
      return {
        ...packetPlan,
        packetPlanStones: packetPlan.packetPlanStones.map(plans => {
          let plansClone = { ...plans };
          plansClone['maxOrderValue'] = null;
          return plansClone;
        })
      }

    })
    // debugger
    if(this.packetPlans.length > 0){
    for (let packetplan of this.packetPlans) {
      // if (!packetplan?.['totalMaxOrderValue']) {

      // }
      packetplan['totalMaxOrderValue'] = 0;
      packetplan["totalKGRate"] = 0
      // packetplan['totalMaxOrderValue'] = 0;
      packetplan.isWinner = null
      for (let plans of packetplan?.packetPlanStones) {
        plans['maxOrderValue'] = null;
        let maxFinalValue: any = 0;
        let isFinal = false;
        if (!!plans.orders && plans.orders.length != 0) {
          let isFinalOrderExist = plans.orders.find(el => !!el.isMarked)
          if (!!isFinalOrderExist) {
            maxFinalValue = isFinalOrderExist.finalValue;
          }
          else {
            plans.orders = plans.orders.map(order => {
              let clonedOrder = JSON.parse(JSON.stringify(order));
              
              let rap = plans.RAPAPORT > 0 ? plans.RAPAPORT : plans.rapoRate;
              let calculatedValue = this.getRate(clonedOrder.rateType, plans.kgRate, rap, clonedOrder.value, plans.expPol, clonedOrder);
              clonedOrder.finalValue = calculatedValue;

              if (!clonedOrder.orderRemark) {
                maxFinalValue = this.disaplyMaxValue(maxFinalValue, clonedOrder.finalValue)
              }
              return clonedOrder;
            });

          }
          plans['maxOrderValue'] = Number(maxFinalValue.toFixed(2));
        }
        else {
          plans['maxOrderValue'] = "-";
        }
        packetplan['totalMaxOrderValue'] += (this.disaplyMaxValue(plans['maxOrderValue'], plans['kgRate']) || 0);

        packetplan["totalKGRate"] += Number(plans["kgRate"]);
        // packetplan['totalKGRate'] = packetplan['totalMaxOrderValue']
      };
    };
  }
    if(this.selectedCheckerTabs == 'All'){
      let winnerIndex: number = -1, winnerValue:any = 0;
      if( (this.page === 'checker' || this.page==='headchecker' || this.page === 'bombay mail') && this.isfinalPage !== true && this.isfinalPage !== 'true'){
        this.packetPlans.map((dt: any, i: number) => {
          if(winnerValue < dt.totalMaxOrderValue && dt.planPriority == 1){
            winnerIndex=i;
            winnerValue=dt.totalMaxOrderValue;
          }
        });
      }
      this.packetPlans = this.packetPlans.map((dt: any, i: number) => {
        if(winnerIndex == i){
          dt.isWinner = true;
        }
        return {
          ...dt,
          totalMaxOrderValue: dt.packetPlanStones.map((ppsdt: any) => (this.disaplyMaxValue(ppsdt['maxOrderValue'], ppsdt['kgRate']) || 0)).reduce((accumulator, currentValue) => {
            return accumulator + currentValue
          }, 0),
        }
      });
    }
    this.defaultSettingIsActive();
    console.log(this.packetPlans)
  }

  getRate(rateType: string, kgRate: number, RAPAPORT: number, value: number, totalCarat: number, order: any) {
    let finalOrderValue = 1;
    switch (rateType) {
      case "Back":
        finalOrderValue = RAPAPORT - (RAPAPORT * (value / 100));
        finalOrderValue = finalOrderValue * totalCarat;
        break;
      case "Piece":
        finalOrderValue = value;
        break;
      case "Percentage":
        finalOrderValue = Number(kgRate) + Number(kgRate) * (value * - 1 / 100);
        break;
      case "Rate Below":
        finalOrderValue = value;
        break;
      case "per cts rate":
        finalOrderValue = value * totalCarat;
        break;
    }
    return (Number.isNaN(finalOrderValue)) ? 0 : finalOrderValue;
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }

    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }
  async updateFields() {
    console.log('..........updateFields..........');
    const alert = await this.alertController.create({
      header: 'Do you want to update?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'planAlertBox',
      buttons: [{
        text: 'OK',
        handler: (d: any) => {
          this.makeChangesToPlans();
        }
      }]
    });
    await alert.present();
  }
  makeChangesToPlans() {
    console.log('makeChangesToPlans');
    let parameters = {};
    let gradeToNum = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26 };
    let fl = this.dropdown['Fluorescence'];
    let cl = this.dropdown['Color'];
    let cla = this.dropdown['Clarity'];
    let sha = this.dropDownShapeData.selectedData[0];
    let pie = this.dropDownPieceData.selectedData[0];
    let fluorescence = !!fl ? fl.name : '';
    let fluorescenceID = !!fl ? fl.id : null;
    let color = !!cl ? cl.name : '';
    let colorID = !!cl ? cl.id : null;
    let clarity = !!cla ? cla.name : '';
    let clarityID = !!cla ? cla.id : null;
    let shape = !!sha ? sha.name : '';
    let shapeId = !!sha ? sha.id : null;
    let piece = !!pie ? pie.name : '';
    let packetID = this.rowData.id;
    let packetIdObj = { packetId: this.rowData.packetId };
    parameters = {
      'packetID': packetID,
      'fluorescence': fluorescence,
      'fluorescenceID': fluorescenceID,
      'color': color,
      'colorID': colorID,
      'clarity': clarity,
      'clarityID': clarityID,
      'shape': shape,
      'shapeId': shapeId,
      'piece': piece
    }
    let postData = {
      "flourescenceId": fluorescenceID,
      "packetId": packetID,
      "grade": !!gradeToNum[piece] ? gradeToNum[piece] : null,
      "clarityId": clarityID,
      "colorId": colorID,
      "shapeId": !!shapeId ? shapeId : null
    }
    console.log(fluorescence);
    console.log(fluorescenceID);
    console.log(color);
    console.log(colorID);
    console.log(clarity);
    console.log(clarityID);
    console.log(shape);
    console.log(shapeId);
    console.log(piece);
    console.log(parameters);
    this.store.dispatch(SignerAction.updateFLCLClAInPacketPlanStone({ updateData: postData, packetIdObj: packetIdObj, parameters: parameters }));
    // this.store.dispatch(SignerAction.updateDropDownParam({ updateData: parameters }));
    this.dropdown = {};
    if (fluorescenceID != null) {
      this.dropDownFLData.selectedData = [];
    }
    if (colorID != null) {
      this.dropDownCLData.selectedData = [];
    }
    if (clarityID != null) {
      this.dropDownCLAData.selectedData = [];
    }
  }

async defaultSettingIsActive() {
  await this.packetPlans.forEach(plan => {
    plan.packetPlanStones.forEach(stone => {
      
      let isActiveFound = false;
      
      stone.orders = stone.orders.map(order => {
        if (order.isActive && !order.orderRemark && !isActiveFound) {
          isActiveFound = true;
        }
        return { ...order };
      });
      if (!isActiveFound) {
        let highestValue = 0;
        let highestValueId = null;

        stone.orders.forEach(order => {
          if (!order.orderRemark && order.finalValue > highestValue) {
            highestValue = order.finalValue;
            highestValueId = order.id;
          }
        });

        stone.orders = stone.orders.map(order => {
          if (!order.orderRemark && order.id === highestValueId) {
            return { ...order, isActive: true };
          } else {
            return { ...order, isActive: false };
          }
        });

      }
     
    });
  });

  console.log(this.packetPlans);
}


  uploadFile(event) {
    console.log(event.target.files)
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = <File>event.target.files[0];
      this.clearFileInputs();
      console.log(this.selectedFile);
      this.uploadCapFile();
      this.validationCheck()
    }
  }

    clearFileInputs() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    if (this.fileInput2) {
      this.fileInput2.nativeElement.value = '';
    }
  }


  uploadSpectrumPDF(event) {
    if (event.target.files.length > 0) {
      this.selectedFile = <File>event.target.files[0];
      let packetId = this.rowData.id;
      const formatData = new FormData();
      formatData.append("file", this.selectedFile);
      this.httpService.uploadSignerPDF(formatData, packetId).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this._ConfigService.showToast('success', res.message || 'Uploaded successfully!');
        } else {
          this._ConfigService.showToast('error', res.error.message || 'Something went wrong!');
        }
      });
    }
  }

  // uploadJPGFile(event) {
  //   if (event.target.files.length > 0) {
  //     // const file = event.target.files[0];
  //     this.jpgFile = <File>event.target.files[0];
  //     this.uploadJPG.emit(event);
  //   }
  // }

  async uploadJpgFile(event) {
    if (event.target.files.length > 0) {
      this.jpgFile = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('file', this.jpgFile);
      formData.append('packetId', this.rowData?.id);
      let res: any;

      res = await this.httpService.uploadJpgFileForplanning(formData)
      this.httpService.getErrorAndDisplayIt(res);
      // await this.httpService.dismissLoader()
      if (res.statusCode === 200) {
        let data = res.message;
        this._ConfigService.showToast("success", data);
        this.currJpgFile = res.data;
      } else {
        console.error(res);
        this._ConfigService.showToast("error", res.error.message);
      }
    }
  }

  async uploadSpectrumFile(event) {
    if (event.target.files.length > 0) {
      this.jpgFile = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('file', this.jpgFile);
      formData.append('packetId', this.rowData?.id);
      let res: any;

      res = await this.httpService.uploadSpectrumFileForPlanning(formData, this.packetId)
      this.httpService.getErrorAndDisplayIt(res);
      // await this.httpService.dismissLoader()
      if (res.statusCode === 200) {
        let data = res.message;
        this._ConfigService.showToast("success", data);
        this.currSpectrumFile = res.data;
      } else {
        console.error(res);
        this._ConfigService.showToast("error", res.error.message);
      }
    }
  }

  uploadOtherFile(event) {
    if (event.target.files.length > 0) {
      this.jpgFile = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('file', this.jpgFile);
      formData.append('packetId', this.rowData?.id);
      this.httpService.uploadSignerOtherFile(formData, this.rowData?.id).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this._ConfigService.showToast('success', res.message || 'Uploaded successfully!');
        } else {
          this._ConfigService.showToast('error', res.error.message || 'Something went wrong!');
        }
      });
    }
  }

  async onFilter() {
    console.log("on filter");
    const popover = await this.popoverController.create({
      component: ParameterFilterComponent,
      cssClass: 'finalInspectionParameterSelection',
      componentProps: { forPage: 'Planningfilterbtn', defaultFilterData: this.appliedFilterData, pageForFilter: this.page },

      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss()
      .then((res) => {
        if (res.data) {
          this.onFilterSubmission(res.data);
        }
      });
  }

  onFilterSubmission(data) {
    // if(!this.packetPlanDataForDoubtfulValue?.length)
    // this.packetPlansBackupBeforeFilter = [...(JSON.parse(JSON.stringify(this.packetPlans)))];
    console.log('onFilterSubmission');
    let filterBy: any = data.filterBy;
    this.appliedFilterData = filterBy;//data received from Filters and again send for the value selection in Filters
    this.appliedFilterDatas = [];//Data to Show the values in toolbar and length of filter applied.
    this.appliedFilter = {};//for applying filters using pipe for filtering data of Plan grid.
    for (let prop in filterBy) {
      let values: any = filterBy[prop];
      console.log(prop, values);
      let obj: any = {}, obj2 = {};
      if (values && values.length === 1) {
        obj = Object.assign(values[0], { key: prop });
        this.appliedFilter[prop.toLowerCase()] = values[0].name;
        this.appliedFilterDatas.push(obj);
      }
      // if (values[0]) {
      //   obj2 = Object.assign(values[0], { key: prop });
      // }
      // if (prop.toLowerCase() == 'shape') {
      //   this.appliedFilterDatas.push(obj);
      //   console.log(this.appliedFilterDatas);
      // }
    }
    if (Object.keys(data['filterBy']).length === 0) {
      this.appliedFilter = {}
      this.appliedFilterDatas = [];
    }
    console.log(this.appliedFilter, this.appliedFilterDatas);

    if(filterBy?.gridRow){
      for(const key in filterBy.gridRow){
        if(filterBy.gridRow[key]?.from && filterBy.gridRow[key]?.to){
          this.packetPlans = this.packetPlansBackupBeforeFilter.filter((dt:any)=>{
            let isTrue = false;
            dt.packetPlanStones.find((pdt:any)=>{
              if( filterBy.gridRow[key].from <= pdt?.[key] && pdt?.[key] <= filterBy.gridRow[key].to){
                isTrue = true;
              }
              return isTrue;
            })
            return isTrue;
          });
        }
      }
    }
  }

  deSelectFilter(evt) {
    console.log('deSelectFilter');
    console.log(evt);
    let property: string = evt && evt.key ? evt.key.toLowerCase() : "";
    this.appliedFilter = {};
    if (this.appliedFilterData[evt.key]) {
      delete this.appliedFilterData[evt.key];
    }
    this.appliedFilterDatas = this.appliedFilterDatas.filter(d => d.key !== evt.key);
    this.appliedFilterDatas.forEach(d => {
      let property: string = d && d.key ? d.key.toLowerCase() : "";
      if (property) {
        this.appliedFilter[property] = d.name;
      }
    })
    // if (property && this.appliedFilter[property]) {
    //   delete this.appliedFilter[property];
    // }
    console.log(this.appliedFilterData[evt.key]);
    console.log(this.appliedFilterDatas);
    console.log(this.appliedFilterData);

    console.log(this.appliedFilter, this.appliedFilter[property], property)
    // if (this.appliedFilterDatas.length === 0) {
    //   this.resetFilter();
    // }
  }
  resetFilter() {
    console.log('resetFilter');
    // this.searchedItem = '';
    // this.searchItemProperty = '';
    this.appliedFilter = {};
    this.appliedFilterData = {};
    this.appliedFilterDatas = [];
  }
  async validationCheck() {
    this.selectedPlans = this.packetPlans.filter(x => x.selected)
    console.log(this.selectedPlans)
    if (this.selectedPlans.length > 0) {
      await this.selectedPlans.filter(n => {
        let packetsLength = n.packetPlanStones.length
        let fileLength = n.packetPlanStones.filter(x => x.isFileSelected).length;
        console.log(this.selectedFile)
        if (packetsLength == fileLength && this.selectedFile) {
          // this.isDisable = false
          //this.httpService.showToast("danger", "Please Upload files for plans");
        } else if (this.selectedFile) {

          // this.isDisable = true
        }

      })
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    console.log('checker Screen Component destoryed');
    this.packetPlans = [];
    this.rowData = null;
    this.store.dispatch(SignerAction.removeSavePacketPlans());
    this.rowDatas = null;
    this.isUploadECSVfromPlanning = false;
    this.selectedEcsvOption = null;
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  selectChildRow(evt: any) {
    console.log('Select Children');
    console.log(evt);
  }
  onChkChange(elm: any, res: any) {
    console.log(elm);
    console.log(res);

  }

  sortpacket() {
    console.log(this.sortvalue);
    console.log(this.allPacketPlans);
    console.log(this.packetPlans);

    if (this.page == "checker" || this.page === 'headchecker' || this.page === 'bombay mail') {
      this.allPacketPlans = JSON.parse(JSON.stringify(this.packetPlans));
      console.log(this.allPacketPlans);

    }


    if (this.sortvalue == "Low") {
      if(this.project==='kg'){
        this.packetPlans = this.sortNumbersForBidAmt(this.packetPlans, "totalMaxOrderValue", "Asc", this.page, "totalKGRate")
      } else {
        this.packetPlans = this.sortNumbersForBidAmt(this.packetPlans, "netStoneValues", "Asc", this.page)
      }
    }
    else if (this.sortvalue == "High") {
      if(this.project==='kg'){
        this.packetPlans = this.sortNumbersForBidAmt(this.packetPlans, "totalMaxOrderValue", "", this.page, "totalKGRate")
      } else {
        this.packetPlans = this.sortNumbersForBidAmt(this.packetPlans, "netStoneValues", "", this.page)
      }
      // this.packetPlans = this.httpService.sortNumbers(this.packetPlans, "bidAmt", "", this.page)
    } else if (this.sortvalue == 'Highest Rank First') {
      this.packetPlans = this.httpService.sortNumbers(this.packetPlans, "rank", "", this.page)
    } else if (this.sortvalue == 'Lowest Rank First') {
      this.packetPlans = this.httpService.sortNumbers(this.packetPlans, "rank", "Asc", this.page)
    } else {
      this.packetPlans = this.allPacketPlans;
    }
  }

  sortNumbersForBidAmt(data: any, key: string, sortType: string, page: string, key2?: string) {
    if (page == 'checker') {
      data = [...data];
    }

    if (sortType.toUpperCase() === 'ASC') {
      data.sort((a: any, b: any) => {
        if(key2){
          let res = (a[key2] ? this.disaplyMaxValue(a[key],a[key2]) : a[key]) - (b[key2] ? this.disaplyMaxValue(b[key],b[key2]) : b[key])
          return res;
        }
        return a[key] - b[key];
      });
    } else {
      data.sort((a: any, b: any) => {
        if(key2){
          let res = (b[key2] ? this.disaplyMaxValue(b[key],b[key2]):b[key]) - (a[key2] ? this.disaplyMaxValue(a[key],a[key2]) : a[key])
          return res;
        }
        return b[key] - a[key];
      });
    }
    return data;
  }

  incrementColor($event, colorFromApi, rowIndex, indexOfChild, data) {
    console.log($event, colorFromApi, rowIndex, indexOfChild, data);
    const wholeGrid = $event.target.parentElement.parentElement.parentElement.parentElement.children;
    // this.clearColClarFlorSelection(wholeGrid);
    this.renderer.setStyle($event.target, "color", 'green');
    this.isShownDoubtfulValue = data.isShownDoubtfulValue = false;
    console.log(rowIndex, data);
    console.log(indexOfChild);
    data.doubtfulValue = 40;
    data.isShownDoubtfulValue = true;
    this.isShownDoubtfulValue = data.isShownDoubtfulValue;
    this.doubtfulValue = true;
    console.log(data.doubtfulValue);
    this.indexOfColors = rowIndex;

    if (this.indexOfColors < this.colorDataFromStore.length - 1) {
      this.indexOfColors++;
    }
    console.log(this.indexOfColors);
    console.log("increment value of color")
    console.log(colorFromApi); //H
    let colorIndex;
    this.updateColor = colorFromApi; //H

    console.log(this.colorDataFromStore) //ARAAY = 112
    for (let j = 0; j < this.colorDataFromStore.length; j++) {

      if (this.colorDataFromStore[j].name == colorFromApi) {
        colorIndex = j - 1;
        this.colorDetails = this.colorDataFromStore[j - 1].name;
        console.log(colorIndex); //3
        this.updateColor = this.colorDetails; //G
        console.log(colorFromApi);
        console.log(this.colorDetails); //G
        console.log(j);   //gives index of the color, H
        console.log(this.updateColor); //G
        console.log(this.colorDataFromStore[j - 1]);  //gives the object of the DECREMENTED color, has id and name

        console.log(this.packetPlanDataForDoubtfulValue);

        this.testDoubtfulValue = rowIndex.toString() + indexOfChild.toString() + colorFromApi;

      }
    }
    console.log(colorIndex) //incremented index
    console.log(this.colorDetails) //gives the incremented color name

    this.colDoubtfulValue['color'] = data.doubtfulValue;
  }

  clearColClarFlorSelection(wholeGrid: any) {
    console.log(wholeGrid);
    for (let i = 0; i < wholeGrid.length; i++) {
      let row = wholeGrid[i].children;
      for (let j = 6; j < 9; j++) {
        let targetElements = row[j].children[1].children;
        for (let i = 0; i < targetElements.length; i++) {
          this.renderer.setStyle(targetElements[i], "color", 'white');
        }
      }
    }
  }


  decrementColor($event, colorFromApi, rowIndex, indexOfChild, data) {
    const wholeGrid = $event.target.parentElement.parentElement.parentElement.parentElement.children;
    // this.clearColClarFlorSelection(wholeGrid);
    this.renderer.setStyle($event.target, 'color', 'red');
    console.log(colorFromApi);
    console.log(rowIndex, data);
    console.log(this.clarityDataFromStore);
    console.log(data.doubtfulValue);
    this.isShownDoubtfulValue = data.isShownDoubtfulValue = false;

    data.doubtfulValue = 20;
    data.isShownDoubtfulValue = true;
    // this.isShownDoubtfulValue = true;
    this.doubtfulValue = true;

    // if (this.indexOfColors > 0) {
    //   this.indexOfColors--;
    // }
    this.indexOfColors = rowIndex;
    if (this.indexOfColors < this.colorDataFromStore.length - 1) {
      this.indexOfColors++;
    }
    console.log(this.indexOfColors);
    console.log("decrement value of color")
    console.log(colorFromApi);
    // this.updateIndexInAPI();
    let colorIndex;
    this.updateColor = colorFromApi;

    // ***********************************
    for (let j = 0; j < this.colorDataFromStore.length; j++) {

      if (this.colorDataFromStore[j].name == colorFromApi) {
        colorIndex = j + 1;      //index updated
        this.colorDetails = this.colorDataFromStore[j + 1].name; //I
        console.log(colorIndex); //5
        this.updateColor = this.colorDetails; //I
        console.log(colorFromApi); //H
        console.log(this.colorDetails); //I


        console.log(j);   //gives index of the color, 4, H
        console.log(this.updateColor); //I
        console.log(this.colorDataFromStore[j + 1]);  //gives the object of the color, has id and name

      }
    }
    this.colDoubtfulValue['color'] = data.doubtfulValue;

    console.log(colorIndex); //decremented index
    console.log(this.colorDetails);
  }

  incrementClarity($event, clarityValueFromApi, i, j, d) {
    const wholeGrid = $event.target.parentElement.parentElement.parentElement.parentElement.children;
    // this.clearColClarFlorSelection(wholeGrid);
    this.renderer.setStyle($event.target, "color", 'green');
    this.isShownDoubtfulValue = d.isShownDoubtfulValue = false;
    // console.log(rowIndex , data);
    // console.log(indexOfChild);
    d.doubtfulValue = 45;
    d.isShownDoubtfulValue = true;
    this.isShownDoubtfulValue = d.isShownDoubtfulValue;
    this.doubtfulValue = true;
    console.log(d.doubtfulValue);
    console.log(clarityValueFromApi); //SI3
    console.log(i, d); //0, DATA
    console.log(this.clarityDataFromStore); // ARRAY : 118

    this.indexOfClarity = i;
    if (this.indexOfClarity < this.clarityDataFromStore.length - 1) {
      this.indexOfClarity++;
    }

    for (let m = 0; m < this.clarityDataFromStore.length; m++) {
      if (this.clarityDataFromStore[m].name == clarityValueFromApi) {
        this.clarityDetails = this.clarityDataFromStore[m - 1].name;
        console.log(this.clarityDetails); //UPDATED, INCREMENTED CLARITY:" SI3+"
        console.log(this.clarityDataFromStore[m - 1]); // OBJECT OF UPDATED CLARITY PARAMETER


      }
    }

    this.colDoubtfulValue['clarity'] = d.doubtfulValue;
  }
  decrementClarity($event, clarityValueFromApi, i, j, d) {
    const wholeGrid = $event.target.parentElement.parentElement.parentElement.parentElement.children;
    // this.clearColClarFlorSelection(wholeGrid);
    this.renderer.setStyle($event.target, 'color', 'red');
    console.log(clarityValueFromApi);
    console.log(i, d);
    console.log(this.clarityDataFromStore);
    this.isShownDoubtfulValue = d.isShownDoubtfulValue = false;

    d.doubtfulValue = 26;
    d.isShownDoubtfulValue = true;
    this.isShownDoubtfulValue = d.isShownDoubtfulValue;
    this.doubtfulValue = true;
    // console.log( data.doubtfulValue );

    this.indexOfClarity = i;
    if (this.indexOfClarity < this.clarityDataFromStore.length - 1) {
      this.indexOfClarity++;
    }

    for (let m = 0; m < this.clarityDataFromStore.length; m++) {
      if (this.clarityDataFromStore[m].name == clarityValueFromApi) {
        this.clarityDetails = this.clarityDataFromStore[m + 1].name;
        console.log(this.clarityDetails);
        console.log(this.clarityDataFromStore[m + 1]);
      }
    }
    this.colDoubtfulValue['clarity'] = d.doubtfulValue;
  }


  incrementFluorescence($event, fluorescenceValueFromApi, i, j, d) {
    const wholeGrid = $event.target.parentElement.parentElement.parentElement.parentElement.children;
    // this.clearColClarFlorSelection(wholeGrid);
    this.renderer.setStyle($event.target, "color", 'green');
    console.log(fluorescenceValueFromApi);
    console.log(i, d);
    console.log(this.fluorescenceDataFromStore);

    this.isShownDoubtfulValue = d.isShownDoubtfulValue = false;

    d.doubtfulValue = 53;
    d.isShownDoubtfulValue = true;
    this.isShownDoubtfulValue = d.isShownDoubtfulValue;
    this.doubtfulValue = true;




    this.indexOfFluorescence = i;
    if (this.indexOfFluorescence < this.fluorescenceDataFromStore.length - 1) {
      this.indexOfFluorescence++;
    }

    for (let m = 0; m < this.fluorescenceDataFromStore.length; m++) {
      if (this.fluorescenceDataFromStore[m].name == fluorescenceValueFromApi) {
        this.fluorescenceDetails = this.fluorescenceDataFromStore[m - 1].name;
        console.log(this.fluorescenceDetails); //UPDATED, INCREMENTED FL:" SI3+"
        console.log(this.fluorescenceDataFromStore[m - 1]); // OBJECT OF UPDATED FL PARAMETER
      }
    }

    this.colDoubtfulValue['fluorescence'] = d.doubtfulValue;
  }
  decrementFluorescence($event, fluorescenceValueFromApi, i, j, d) {
    const wholeGrid = $event.target.parentElement.parentElement.parentElement.parentElement.children;
    // this.clearColClarFlorSelection(wholeGrid);
    this.renderer.setStyle($event.target, "color", 'red');
    console.log(fluorescenceValueFromApi);
    console.log(i, d);
    console.log(this.fluorescenceDataFromStore);

    this.isShownDoubtfulValue = d.isShownDoubtfulValue = false;

    d.doubtfulValue = 19;
    d.isShownDoubtfulValue = true;
    this.isShownDoubtfulValue = d.isShownDoubtfulValue;
    this.doubtfulValue = true;



    this.indexOfFluorescence = i;
    if (this.indexOfFluorescence < this.fluorescenceDataFromStore.length - 1) {
      this.indexOfFluorescence++;
    }

    for (let m = 0; m < this.fluorescenceDataFromStore.length; m++) {
      if (this.fluorescenceDataFromStore[m].name == fluorescenceValueFromApi) {
        this.fluorescenceDetails = this.fluorescenceDataFromStore[m + 1].name;
        console.log(this.fluorescenceDetails); //UPDATED, INCREMENTED FL:" SI3+"
        console.log(this.fluorescenceDataFromStore[m + 1]); // OBJECT OF UPDATED FL PARAMETER
      }
    }
    this.colDoubtfulValue['fluorescence'] = d.doubtfulValue;

    // this.isDoubtfulValue = true;
  }

  removeDoubtfulValue(event, color, i, j, d, colType?: string) {
    // this.isShownDoubtfulValue = d.isShownDoubtfulValue = false;
    const targetChildren = event.target.parentElement.children;
    for (let i = 0; i < targetChildren.length; i++) {
      this.renderer.setStyle(targetChildren[i], "color", 'white');
    }
    // event.target.parentElement.children.forEach(element => {
    // });
    let data = d;
    // d.packetPlanStones.splice(i);
    console.log(i);
    console.log(d);
    data.isShownDoubtfulValue = false;
    this.isShownDoubtfulValue = data.isShownDoubtfulValue;

    // d.doubtfulValue = 0;

    this.doubtfulValue = false;
    // data.doubtfulValue = 0;

    this.colDoubtfulValue[colType] = null;
  }




  scroll(data) {
    this._ConfigService.scrollPosition = data.target.scrollTop;

  }

  logScrollStart(event) {
    console.log("logScrollStart : When Scroll Starts", event);
  }

  logScrolling(event) {
    console.log("logScrolling : When Scrolling", event);
  }

  logScrollEnd(event) {
    console.log("logScrollEnd : When Scroll Ends", event);
  }

  isSubscribedToPlansForChecker:boolean = false;

  segmentChangedForSignerTabs(event: any) {
    if(!this.isSubscribedToPlansForChecker){
      this.isSubscribedToPlansForChecker = true;
      this.store.pipe(select(getCheckerAllPacketPlans)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
        if (data) {
          this.packetPlans = data;

          let higestNetStoneValue;
          let rank = 1;
          if(
            (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
            (this.selectedRow?.currActionName == "Smart Recut Planning") ||
            (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
            (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
          ){
            this.packetPlans?.map((dt: any) => {
              if (dt.planDone === '1')
                higestNetStoneValue = dt.netStoneValues;
            });
          }
          if(!(higestNetStoneValue > -1)){
            this.packetPlans?.map((dt: any) => {
              if (dt.netStoneValues > (higestNetStoneValue||0))
                higestNetStoneValue = dt.netStoneValues;
            });
          }
          let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
          this.packetPlans = this.packetPlans?.map((dt: any) => {
            let dtClone = { ...dt }
            if (dt?.netStoneValues <= previousNetStoneValue) {
              dtClone.rank = rank;
              previousNetStoneValue = dt.netStoneValues;
            }
            if (dt?.netStoneValues && higestNetStoneValue){
              if(
                (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
              )
              dtClone.rankDiff = (((dt.netStoneValues-higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
              else
              dtClone.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
            }
            else
              dtClone.rankDiff = 0;
            rank++;
            return dtClone;
          });
          // this.packetPlans?.forEach((dt: any) => {
          //   if (dt?.netStoneValues <= previousNetStoneValue) {
          //     dt.rank = rank;
          //     previousNetStoneValue = dt.netStoneValues;
          //   }
          //   if (dt?.netStoneValues && higestNetStoneValue)
          //     dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
          //   else
          //     dt.rankDiff = 0;
          //   rank++;
          // });
          this.packetCalculationsInit()
        }
      });

      this.store.pipe(select(getPlansAccToUserChecker)).subscribe((data) => {
        console.log(data);
        if (data) {
          this.signerPlanTabDatas = data;
          this.packetPlans = [...this.signerPlanTabDatas]

          let higestNetStoneValue;
          let rank = 1;
          if(
            (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
            (this.selectedRow?.currActionName == "Smart Recut Planning") ||
            (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
            (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
          ){
            this.packetPlans?.map((dt: any) => {
              if (dt.planDone === '1')
                higestNetStoneValue = dt.netStoneValues;
            });
          }
          if(!(higestNetStoneValue > -1)){
            this.packetPlans?.map((dt: any) => {
              if (dt.netStoneValues > (higestNetStoneValue||0))
                higestNetStoneValue = dt.netStoneValues;
            });
          }
          let previousNetStoneValue = Number.MAX_SAFE_INTEGER;
          this.packetPlans = this.packetPlans?.map((dt: any) => {
            let dtClone = { ...dt };
            if (dt?.netStoneValues <= previousNetStoneValue) {
              dtClone.rank = rank;
              previousNetStoneValue = dt.netStoneValues;
            }
            if (dt?.netStoneValues && higestNetStoneValue){
              if(
                (this.selectedRow?.vitualActionName == "Smart Recut Planning") ||
                (this.selectedRow?.currActionName == "Smart Recut Planning") ||
                (this.selectedRow?.vitualActionName == this.ACTIONNAMES.anyCut) ||
                (this.selectedRow?.currActionName == this.ACTIONNAMES.anyCut)
              )
              dtClone.rankDiff = (((dt.netStoneValues-higestNetStoneValue) / higestNetStoneValue) * 100).toFixed(2);
              else
              dtClone.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
            }
            else
              dtClone.rankDiff = 0;
            rank++;
            return dtClone;
          });
          // this.packetPlans?.forEach((dt: any) => {
          //   if (dt?.netStoneValues <= previousNetStoneValue) {
          //     dt.rank = rank;
          //     previousNetStoneValue = dt.netStoneValues;
          //   }
          //   if (dt?.netStoneValues && higestNetStoneValue)
          //     dt.rankDiff = (((higestNetStoneValue - dt.netStoneValues) / higestNetStoneValue) * 100).toFixed(2);
          //   else
          //     dt.rankDiff = 0;
          //   rank++;
          // });
          this.packetCalculationsInit()
        }
      });
    }
    let selectedValue;
    if (!!event) {
      selectedValue = event.detail.value;
      this.selectedValue = selectedValue;
      if (this.isCurrentCheckerID == selectedValue) {
        this.isCurrentUserSame = true;
      } else {
        this.isCurrentUserSame = false;
      }
      this.isCapFileUpload = false;
    }
    // else {
    //   selectedValue = 'All';
    // }
    this.packetPlans = [];
    if (this.selectedValue === 'All') {
      this.getCapandJpgFile(this.isCurrentCheckerID)

      console.log(this.packetPlans);
      this.jpgFile = null;
      this.selectedFile = null;
      if (this.page != 'checker' && this.page !== 'headchecker')
        this.isCapFileUpload = false;


      if (this.rowData?.id && !this.isCurrentUserSame) {
        this.store.dispatch(chekerAction.getCheckerPlans({ packetIdOb: { packetId: this.rowData?.id } }));
        
      }
    } else {
      this.getCapandJpgFile(this.selectedValue)
      console.log(event);
      if(event?.detail?.value)
      this.store.dispatch(chekerAction.getPlansAccToUSers({ id: event?.detail?.value, packetId: this.Id })); console.log(event);
    }
  }



  fetchinfoOfSingers(id) {
    this.httpService.fetchData(true, `packetPlanStone/getAllSignersWhoPlannedOnPacket?packetId=${id}`).subscribe(response => {
      this.signerWhoPlanned = response.data.map(obj => {
        if(this.userData?.fullName === obj.fullName){
          return {
            signerName: obj.fullName,
            signerid: obj.signerId,
            isCurrentUser: true,
          };
        }
        return {
          signerName: obj.fullName,
          signerid: obj.signerId
        };
      });
      this.httpService.getErrorAndDisplayIt(response);
    });
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }
  async saveIntermediatePlan(evt?){

    // if(!this.remarkDataOptions?.length)
    // this.getOpnionRemark();

    // const modal = await this.modalCtrl.create({
    //   component: DynamicModalComponent,
    //   componentProps: {
    //     template:this.intermediateSave,
    //     class:'scrool'
    //   },
    //   cssClass: 'intermediate-save',
    // });
    // modal.onDidDismiss().then((d) => {
    //   debugger;
    // });
    // await modal.present();
    // return;

    this.httpService.showLoader();

    await this.updatePacketPlansAndStones();
    const planStoneIds = [];
    const inActiveIds = [];
    let allPlanActive = []
    this.selectedPlans.map((dt:any)=>{
      dt.packetPlanStones.map((pp:any)=>{
        planStoneIds.push(pp.id);
        if(pp.inActive == null) {
          allPlanActive.push(pp.id)
        }
      })
    });
    this.packetPlans.map((p:any)=>{
      if(p.planDone == '1'){
        p.packetPlanStones.map((pp:any)=>{
          if(pp.intermediateInActive==1){
            inActiveIds.push(pp.id);
          }
          if(pp.isDone == null && !(pp.inActive == 1 || pp.intermediateInActive == 1)) {
            allPlanActive.push(pp.id);
          }
        })
      }
      
    });
    const payload = {
      "packetId" : this.selectedRow.id,
      "planStoneIds" : planStoneIds,
      "inActiveIds" : inActiveIds,
      "isMakeAble": evt.isMakeAble ? 1 : 0,
      "packetPlanId": evt.packetPlanId,
    };
    console.log("payload", this.payload)
  
    const res = await this.httpService.postDataUsingPromise(true, 'packetPlan/modifyPlanOnIntermediatePacket', payload);
    this.httpService.getErrorAndDisplayIt(res);
    if(res.statusCode == 200){
      if(this.page===this.actionNames.MAKEABLE_PLANNING){
        await this.saveMakeablePlanning();
        return;
      }
      if(this.page =='intermediatePlanning' && evt.checkIsActive && allPlanActive.length == 1) {
        await this.saveMakeablePlanning(); 
      }
      this.router.navigate(["/home"]);
    }

    this.httpService.dismissLoader();
  }

  async saveMakeablePlanning(){
    const payload = {
      "packetLabel" : this.selectedRow.packetLabel,
    };
    const res = await this.httpService.postDataUsingPromise(true, 'packet/rejectAllPropertyIfAvaiable', payload);
    this.httpService.getErrorAndDisplayIt(res);

    if(res.statusCode == 200 && this.selectedPlans?.[0]?.packetPlanStones?.length==1){
      const payload = {
        "packetId" : this.selectedRow.id,
        "packetPlanStoneId" : this.selectedPlans?.[0]?.packetPlanStones?.[0].id,
      };
      const res = await this.httpService.postDataUsingPromise(true, 'packet/storePacketDetailFromChecker', payload);
      this.httpService.getErrorAndDisplayIt(res);
      this.router.navigate(["/home"]);
    }
    this.httpService.dismissLoader();
  }
  
  async resetIntermediatePlan(){

    const alert = await this.alertController.create({
      header: 'Are you want to reset plans?',
      cssClass: 'planAlertBox',
      buttons: [{
        text: 'No',
        handler: (d: any) => {}
      },
      {
        text: 'Yes',
        handler: async (d: any) => {
          this.httpService.showLoader();
          const response = await this.httpService.fetchDataUsingPromise(true, `packetPlan/resetIntermediatePacket?packetId=${this.selectedRow.id}`);
          this.httpService.dismissLoader();

          if(response.statusCode === 200){
            this.getIntermediatePlanningData.emit();
          } else {
            this._ConfigService.showToast('Error', 'Something went wrong! Please try again');
          }
        }
      }]
    });
    await alert.present();
  }
  
  async reUploadEcsv() {
    if(this.selectedRow?.currActionName === "Final Planning"){
      this.isUploadECSVfromPlanning = true;
    }
    if(this.page == 'intermediatePlanning'){
      const planDone = this.packetPlans.find((dt:any)=>dt.planDone=='1');
      // const doneIndexs = [];
      // const sequence1 = planDone.packetPlanStones
      // .filter((dt:any, i)=>{
      //   if (dt.isDone) doneIndexs.push(i);
      //   return dt.isDone;
      // })
      // this.skips = doneIndexs;
      // const sequence = sequence1.map((dt:any, i:number)=>({
      //   id:dt.id, 
      //   name:this.getChar(i)
      // }));
      const sequence = planDone.packetPlanStones.map((dt:any, i:number)=>({id:dt.id, name:this.getHexValue(i), isDone:dt.isDone})).filter((dt:any)=>(this.page==ACTIONNAMES.MAKEABLE_PLANNING?dt.isDone:!dt.isDone));
      const Modal = await this.modalCtrl.create({
        component: TrybemodalComponent,
        cssClass: 'intermediateEcsvModal',
        componentProps: {
          data: { status: this.page==ACTIONNAMES.MAKEABLE_PLANNING?ACTIONNAMES.MAKEABLE_PLANNING:'intermediatePlanning', sequence: sequence}
        },
      });
      Modal.onDidDismiss().then((d: any) => {
        if(d?.data?.length){
          this.selectedSequence = d.data;
          let info = {
            rowData: this.selectedRow,
            status: 'Initial',
            parameterList: this.paramSelectionList,
            className: 'ecsvModal',
            history: this.packetHistories,
          };
          console.log(this.paramSelectionList);
          // this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
          this.showModal(true, 'e-csv Upload', info, true);
        }
      });
      return await Modal.present();
    }

    else if (this.page==ACTIONNAMES.MAKEABLE_PLANNING){
      const planDone = this.packetPlans.find((dt:any)=>dt.planDone=='1');
      const sequence = planDone.packetPlanStones.map((dt:any, i:number)=>({id:dt.id, name:this.getHexValue(i), isDone:dt.isDone})).filter((dt:any)=>(this.page==ACTIONNAMES.MAKEABLE_PLANNING?dt.isDone:!dt.isDone));
      this.selectedSequence = sequence;
      let info = {
        rowData: this.selectedRow,
        status: 'Initial',
        parameterList: this.paramSelectionList,
        className: 'ecsvModal',
        history: this.packetHistories,
      };
      this.showModal(true, 'e-csv Upload', info, true);

    }
    else{
      
    const Modal = await this.modalCtrl.create({
      component: TrybemodalComponent,
      cssClass: 'lockModal',
      componentProps: {
        data: { status: 'isPreviousPlantrueForSigner'}
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissForPreviousPlan(d));
    return await Modal.present();
    // let info = {
    //   rowData: this.selectedRow,
    //   status: 'Initial',
    //   parameterList: this.paramSelectionList,
    //   className: 'ecsvModal',
    //   history: this.packetHistories,
    // };
    // this.showModal(true, 'e-csv Upload', info);
  }
  }

  getCopyFrom(fromId) {
    let res = '';
    this.packetPlans.map((dt: any) => {
      if (dt.id == fromId)
        res = dt.tagValue
    });
    return res;
  }

  async handleModalDismissForPreviousPlan(data) {
    console.log(data);
    
    if (!!data) {
      this.selectedEcsvOption = data.data;
      console.log(this.selectedEcsvOption);
      if (this.selectedEcsvOption === 'newFile') {
        this.store.pipe(select(authUser)).subscribe((d: any) => {
          if (!!d && d?.roles.includes('Checker')) {
            this.paramSelectionList = []
          }
      })
      let info = {
        rowData: this.selectedRow,
        status: 'Initial',
        parameterList: this.paramSelectionList,
        className: 'ecsvModal',
        history: this.packetHistories,
      };
      this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
      this.showModal(true, 'e-csv Upload', info);

      }
      else if (this.selectedEcsvOption === 'appendData') {
        let info = {
          rowData: this.selectedRow,
          status: 'Initial',
          parameterList: this.paramSelectionList,
          className: 'ecsvModal',
          history: this.packetHistories,
        };
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
        this.showModal(true, 'e-csv Upload', info, true);
      }
    }
  }

  async showModal(state: boolean, message: string, data: any, isAppend?: boolean) {
    console.log(data)
    if(this.isUploadECSVfromPlanning) data['isPlanningPage'] = true;
    this.refreshGrid = false;
    let append = isAppend;
    if (state) {
      if (Object.keys(data).length !== 0 && !!data.className) {
        const modal = await this.modalCtrl.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: data.className,
        });
        modal.onDidDismiss().then((d) => {
          // this.isUploadECSVfromPlanning = false;
          this.handleModalDismiss(d, append);
        });
        return await modal.present();
      }
    }
  }


  handleModalDismiss(data: any, isAppend?: boolean) {
    console.log('handleModalDismiss');
    console.log(data);
    this.isCapFileUpload = false;
    this.selectedFile = '';
    this.hasCheckerCapFile = false;
    let append = isAppend;
    if (!!data && !!data.data && data.data.status == 'ecsv file submitted') {
      console.log('Form Submitted');
      console.log(data.data);
      if(this.page == 'intermediatePlanning' || this.page==ACTIONNAMES.MAKEABLE_PLANNING){
        this.ecsvUploadForIntermediate(data.data);
        return;
      }
      // this.formSubmitted(data.data);
      this.ecsvUploadWithOtherDetails(data.data, append);
    } else {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 1000);
    }
  }


  async ecsvUploadWithOtherDetails(formData, isAppend?: boolean) {
    this.store.dispatch(SignerAction.removeSelectedPlans());
    // if(this.selectedEcsvOption=='newFile'){
    //   this.store.dispatch(SignerAction.removeSelectedPlans());
    // }
    let append = isAppend;
    let formType = 'JSON';
    console.log(formData);
    console.log(this.fromJsonInputs);
    let fromJsonInputsModified = { ...this.fromJsonInputs };
    fromJsonInputsModified.listOfParameter =
      fromJsonInputsModified?.listOfParameter?.map((x) => {
        const xData = { ...x };
        let paramName = xData.parameterName;
        if (formData[paramName]) {
          xData.parameterValue = formData[paramName];
        }
        let packet1 = xData.packet1;
        let packet2 = xData.packet2;
        if (!!packet1 && formData['packet1']) {
          let f1 = formData['packet1'];
          packet1.forEach((p1: any) => {
            let paramName = p1.parameterName;
            if (f1[paramName]) {
              p1.parameterValue = f1[paramName];
            }
          });
        }
        if (!!packet2 && formData['packet2']) {
          let f2 = formData['packet2'];
          packet2.forEach((p2: any) => {
            let paramName = p2.parameterName;
            if (f2[paramName]) {
              p2.parameterValue = f2[paramName];
            }
          });
        }
        return { ...xData };
      });
    fromJsonInputsModified.packetId = this.selectedRow.id;
    let barcode = this.selectedRow.packetId;

    let obj = {
      listOfAllPackets: [],
    };
    let barcodeObj = {
      packetId: barcode,
    };
    const formatData = new FormData();
    // return;
    if (formData['file']) {
      console.log(formData['file']);
      formType = 'Multipart';
      let file = formData['file']['0'];
      formatData.append('file', file);
      let payload = {id : this.selectedRow.id}
      let response = await this.httpService.postDataUsingPromise(
        true,
        'packetPlan/getParameterOnBasisOfPacketId',
        payload
      );
      console.log(response);
      let newListOfParameters = fromJsonInputsModified?.listOfParameter?.filter(
        (d) => d.parameterValue != ''
      );
      fromJsonInputsModified.listOfParameter = newListOfParameters || response.data;
      obj['listOfAllPackets'].push(fromJsonInputsModified);
      // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
      formatData.append(
        'listOfAllPackets',
        JSON.stringify(obj['listOfAllPackets'])
      );
      // uploadPacketAdvisorCsv API getteing called
      let userId = this.userData?.id ? this.userData?.id : this.userData?.userId
      this.store.dispatch(
        masterDataActions.ecsvUpload({
          parameterDetails: formatData,
          packetID: this.selectedRow.id,
          actionId: this.actionId || null,
          formType: formType,
          barcodeObj: barcodeObj,
          actionInfo: this.actionInfo || this.selectedRow.vitualActionName,
          isAppend: append || false,
          packetString: this.selectedRow.packetId,
          isGetPacketPlansCalled : this.selectedRow?.currActionName == ACTIONNAMES.Planning ? true : false,
          isFromPlanningScreen : this.isUploadECSVfromPlanning? true : false,
          currentUserId : userId
        })
      );
      setTimeout(() => {
        if(this.signerWhoPlanned.find((dt:any)=>dt.signerid==this.selectedCheckerTabs)?.isCurrentUser)
        this.segmentChangedForSignerTabs(null);
        this.fetchinfoOfSingers(this.Id);
        // this.closeDynamicForm();
      }, 3000);

    } else {
      obj['listOfAllPackets'].push(fromJsonInputsModified);
      console.log(obj);
      // return;
      // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: formType, barcodeObj: barcodeObj }));
    }

  }

  async onModalDismiss(d: any) {
    console.log('onModalDismiss.....');
    console.log(d);
    this.refreshGrid = true;
    // if (!!d.data && d.data.actionId == 149) {
    //   await this.store.dispatch(masterDataActions.getPacketPlans({ packetIdOb: d.data.barcodeObj }));
    //   await this.router.navigate(['/trybesigner']);
    // }
  }

  async ecsvUploadForIntermediate(data:any){
    if(data?.['file']){
      const formData = new FormData();
      formData.append('file', data['file']['0']);
      formData.append('reviewPlanIds', JSON.stringify(this.selectedSequence.map((dt:any)=>dt.id)));
      let fromJsonInputsModified = { ...this.fromJsonInputs };
      fromJsonInputsModified?.listOfParameter?.map((x) => {
        const xData = { ...x };
        let paramName = xData.parameterName;
        if (formData[paramName]) {
          xData.parameterValue = formData[paramName];
        }
        let packet1 = xData.packet1;
        let packet2 = xData.packet2;
        if (!!packet1 && formData['packet1']) {
          let f1 = formData['packet1'];
          packet1.forEach((p1: any) => {
            let paramName = p1.parameterName;
            if (f1[paramName]) {
              p1.parameterValue = f1[paramName];
            }
          });
        }
        if (!!packet2 && formData['packet2']) {
          let f2 = formData['packet2'];
          packet2.forEach((p2: any) => {
            let paramName = p2.parameterName;
            if (f2[paramName]) {
              p2.parameterValue = f2[paramName];
            }
          });
        }
        return { ...xData };
      });
      fromJsonInputsModified.packetId = this.selectedRow.id;
      let payload = {id : this.selectedRow.id}
      let response = await this.httpService.postDataUsingPromise(
        true,
        'packetPlan/getParameterOnBasisOfPacketId',
        payload
      );
      let obj = {
        listOfAllPackets: [],
      };
      let newListOfParameters = fromJsonInputsModified?.listOfParameter?.filter(
        (d) => d.parameterValue != ''
      );
      fromJsonInputsModified.listOfParameter = newListOfParameters || response.data;
      obj['listOfAllPackets'].push(fromJsonInputsModified);
      formData.append(
        'listOfAllPackets',
        JSON.stringify(obj['listOfAllPackets'])
      );
      this.httpService.saveDataToDB(true, `packetPlan/uploadPacketAdvisorCsvForIntermediatePlan?packetId=${this.selectedRow.id}`, formData, 'Multipart').subscribe((res:any)=> {
        if(res.statusCode==200){
          this.getIntermediatePlanningData.emit();
        } else {
          this._ConfigService.showToast('error', 'Something went wrong! Please try again');
        }
      });
    }
  }

  async fetchPacketDetailById(findByPacketIdResponse: any) {
    // let endpoint: string = `packetDetails/findByPacketId/${packetID}`
    // let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint)
    let response:any = findByPacketIdResponse;
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.packetheaderData = response.data;
      console.log(this.packetheaderData);

      this.modifiedPacketDetails = {};
      for (let n of this.packetheaderData) {
        console.log(n.paramName)
        let name = n.paramName;
        let value = n.paramValue;
        if (value == null) {
          value = n.paramSValue
        }
        if (!this.modifiedPacketDetails[name]) {
          this.modifiedPacketDetails[name] = value;
        } else {
          this.modifiedPacketDetails[name] = value;
        }

      }
      console.log(this.modifiedPacketDetails);
      var modifiedObject = {};

      Object.keys(this.modifiedPacketDetails).forEach(key => {
        var modifiedKey = key.replace(/\s+/g, ''); // Remove spaces from the key
        modifiedObject[modifiedKey] = this.modifiedPacketDetails[key];
      });
      this.chapka = modifiedObject["chapka"];
      this.headerColor = modifiedObject["Color"];
      this.headerFlour = modifiedObject["fluorescence"];

    } else {
      console.error(response);
    }
  }



  async getCapandJpgFile(signerId) {
    let payload = {
      "packetId": this.selectedRow?.id,
      "signerId": signerId
    };

    try {
      console.log(payload);
      let response = await this.httpService.postDataUsingPromise(
        true,
        'packetPlan/getUploadedCapFileAndJpgFile',
        payload
      );
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);

      if (response.statusCode === 200) {
        if(this.selectedValue != 'All'){
          this.selectedFile = response?.data?.capFile;
        }
        this.currPacketCapFile = response?.data?.capFile;
        this.jpgFile = response?.data?.imageFile;
        this.currJpgFile = response?.data?.imageFile;
        if (this.isCurrentCheckerID == signerId && !!response.data.capFile) {
          this.hasCheckerCapFile = true;
        }
        if (this.selectedFile) {
          this.isCapFileUpload = true;
        }
        let data = response.message;
        console.log(data);
      } else {
        this.selectedFile = null;
        this.jpgFile = null;
      }
    } catch (error) {
      this.selectedFile = null;
      this.jpgFile = null;
    }
  }

  async updatePacketPlansAndStones() {

    let userId = this.userData.id? this.userData.id : this.userData.userId;
    if(this.selectedRow?.vitualActionName == ACTIONNAMES.BOMBAY_MAIL &&  this.selectedCheckerTabs == userId){
      this.saveinputParameter(true);
    }
    if((userId != this.eventObj.rowData.signerId)){
      this.copyPlanExit(this.eventObj.rowData,this.selectedPlanIndex);
    }else{
    if (this.saveDraftUpdateData?.length){
      this.saveDraftUpdateData = this.saveDraftUpdateData.map((dt:any) => {
        return {
          ...dt,
          packetPlanStones: dt.packetPlanStones.map((ps:any)=>{
            let pricingResponse
            if(ps.pricingResponse){
              pricingResponse = JSON.parse(ps.pricingResponse);
              pricingResponse = {...pricingResponse, maxOrderValue: ps.maxOrderValue};
              pricingResponse = JSON.stringify(pricingResponse);
            }
            return {...ps, pricingResponse};
          })
        }
      })
      await this.httpService.postDataUsingPromise(true, 'packetPlan/updatePacketPlansAndStones', this.saveDraftUpdateData);
    }
    }
    if (this.selectedRow?.vitualActionName == ACTIONNAMES.BOMBAY_MAIL){
      const payload = {
        packetId: this.rowData.id,
      };
      await this.httpService.postDataUsingPromise(true, "packet/unholdPacket", payload);
    }
  }

  async SaveAsDraft() {
    // if (this.saveDraftUpdateData?.length)
    //   this.httpService.saveData(true, 'packetPlan/updatePacketPlansAndStones', this.saveDraftUpdateData).subscribe((res: any) => { });

    const payload = {
      "signerId" : this.isCurrentCheckerID,
      "packetId" : this.rowData.id,
      "draftData" : JSON.stringify(this.packetPlans),
    };
    this.httpService.saveData(true, 'save-plan-draft/save', payload).subscribe((response: any) => {
      if (response.statusCode === 200) {
        this._ConfigService.showToast('success', response.message || 'Saved Successfully!');
      } else {
        this._ConfigService.showToast('error', response.error.message || response.message || 'Something went wrong!');
      }
    }, (error) => {
      this._ConfigService.showToast('error', error.error.message || 'Something went wrong!');
    });

    return;

    const plansPayload = this.selectedPlans.map(item => ({
      packetPlanId: item.id,
      planPriority: item.selectionOrder || item.planPriority
    }));
    console.log(plansPayload);
    let response = await this.httpService.postDataUsingPromise(
      true,
      'packetPlan/saveDraft ',
      plansPayload
    );
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      // this.router.navigate(["/home"]);
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this._ConfigService.showToast('success', data);
    } else {
      console.error(response);
      this._ConfigService.showToast('error', response.error.message);
    }
  }

  closePlanGridPopup(ansd) {
    this.popoverController.dismiss();
  }

  async onSpectrumOkay() {
    if (this.page == 'reviewForSmartRecut') {
      // this.httpService.updateData(true, `virtual-task/update/${this.selectedPacket.virtualId}`, {}).subscribe((res: any) => {
      //   this.httpService.getErrorAndDisplayIt(res);
      //   this.router.navigate(["/home"]);
      // });
      let listOfParameter = [];
      let barcodeObj = {
        packetId: this.selectedRow?.id,
      };
      let paramObj;
      paramObj = {
        parameterName: 'Spectrum Done',
        parameterValue: 'yes',
      };

      listOfParameter.push(paramObj);
      let obj = {
        listOfAllPackets: [
          {
            packetId: this.selectedRow?.id,
            listOfParameter,
          },
        ],
      };
      // if (isVirtual) {
      obj["isTensionVirtual"] = "1";
      // }
      this.store.dispatch(
        masterDataActions.saveStoneParamater({
          parameterDetails: obj,
          actionId: this.selectedRow?.virtualAction || this.actionId,
          formType: 'JSON',
          barcodeObj: barcodeObj,
          actionInfo: this.actionName,
        })
      );
      return;
    }
    if (this.page == 'reviewByChecker') {
      this.httpService.updateData(true, `virtual-task/update/${this.selectedPacket.virtualId}`, {}).subscribe((res: any) => {
        this.httpService.getErrorAndDisplayIt(res);
        this.router.navigate(["/home"]);
      });
      return;
    }
    let payload = {
      listOfPacketIds: [this.selectedRow.id],
      actionId: this?.actionIdForSpectrum,
      toUser: this?.checkerIDForSpectrum,
    }
    console.log(payload);
    let isProcessVirtual: number = 1;
    
    this.httpService.issuePacket(payload, isProcessVirtual, this.page).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.httpService.getErrorAndDisplayIt(res);
        if (res.statusCode === 200) {
          this.onSaveOfSpectrum()
          this.router.navigate(["/home"]);
          this.onIssuedSuccess()
          if (!!this.selectedRow?.vitualActionName) {
            this.httpService.updateData(true, `virtual-task/update/${this.selectedRow.virtualId}`, {}).subscribe((res: any) => {
              this.httpService.getErrorAndDisplayIt(res);
              this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
              this.saveinputParameter(true);
            });
          }

          else {
            this.saveinputParameter();
          }

        } else {
          
          // this.showModal(false, res.message, null);
        }
      }
    }, (err: any) => {
      
    }
    )
  }

  onIssuedSuccess() {
    let limit = { limit: 50, page: 0 };
    this.store.dispatch(HomeActions.getOutgoingPackets(limit));
    this.store.dispatch(HomeActions.getPacketCount());
    this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
    
  }

  saveinputParameter(isVirtual?) {
    let listOfParameter = [];
    let barcodeObj = {
      packetId: this.selectedRow?.id,
    };
    let paramObj;
    paramObj = {
      parameterName:  (this.selectedRow?.vitualActionName == ACTIONNAMES.BOMBAY_MAIL)? ACTIONNAMES.BOMBAY_MAIL : 'Spectrum Done',
      parameterValue: 'yes',
    };

    listOfParameter.push(paramObj);
    let obj = {
      listOfAllPackets: [
        {
          packetId: this.selectedRow?.id,
          listOfParameter,
        },
      ],
    };
    if (isVirtual) {
      obj["isTensionVirtual"] = "1";
    }
    if(this.selectedRow?.vitualActionName == ACTIONNAMES.BOMBAY_MAIL) this.actionId = this.selectedRow?.virtualAction
    this.store.dispatch(
      masterDataActions.saveStoneParamater({
        parameterDetails: obj,
        actionId: this.actionId || this.selectedRow?.currAction,
        formType: 'JSON',
        barcodeObj: barcodeObj,
        actionInfo: this.actionName,
      })
    );
  }

  
  getOpnionRemark(){
    this.httpService.fetchData(true, 'packet/getOpnionRemark').pipe(takeUntil(this.unsubscribe$)).subscribe((res:any)=> {
      this.remarkDataOptions = res.data || [];
    })
  }

  chapkaChange(event, plan) {
    this.payload = {
      id: plan?.id,
      chapkaWeight: (event?.target?.value)
    };
  }

  onSaveOfSpectrum() {
    if (this.payload) {
      this.httpService.patchData(true, 'packetPlanStone/updateChapkaWeightUsingPlanStoneId', [this.payload]).subscribe((res: any) => {
        if (res.data) {
          if (res.data.statusCode == 200) {
            this._ConfigService.showToast(true, "Updated Successfully")
          }
          else {
            this._ConfigService.showToast(false, "Error Updating")

          }
        }
      })
    }
  }

  onBombayMailBackChange(event, plan) {
    let backPercentage = event?.target?.value;
    let pricingResponse:any = {}
    if(plan.pricingResponse){
      pricingResponse = JSON.parse(plan.pricingResponse);
      let calAmount = (backPercentage / 100) * pricingResponse.RAPAPORT;
      let ctsAmount = calAmount * this.selectedRow.cts
      pricingResponse['backPercentage'] = backPercentage;
      pricingResponse['backPricing'] = ctsAmount;
    }else{
      pricingResponse['backPercentage'] = backPercentage;
    }
    plan.pricingResponse = JSON.stringify(pricingResponse)
    
    let index = this.backPercentagePayload.findIndex(obj => obj.id == plan?.id)
    if (index > -1) {
      this.backPercentagePayload[index].pricingResponse = plan.pricingResponse
    } else {
      let payload = {
        id: plan?.id,
        pricingResponse: plan.pricingResponse
      };
      this.backPercentagePayload.push(payload);
    }
  }

  onSaveBackPercentage() {
    if (this.backPercentagePayload.length > 0) {
      this.httpService.patchData(true, 'packetPlanStone/updateBackPercentageUsingPlanStoneId', this.backPercentagePayload).subscribe(async (res: any) => {
          if (res.statusCode == 200) {
            if (!!this.selectedRow?.vitualActionName) {
             await this.httpService.updateData(true, `virtual-task/update/${this.selectedRow.virtualId}`, {}).subscribe((res: any) => {
                this.httpService.getErrorAndDisplayIt(res);
                this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
                this.router.navigate(["/home"]);
              });
            }
            this._ConfigService.showToast('success', res.message);
          }
          else {
            this._ConfigService.showToast('error', "Error while updating back percentage");
          }
      })
    }
  }

  onsalesPersonSelection(event){
    this.selectedsalesPerson = event?.arrayList[0];
  }
  sendforBombayMail(){
    let data = {
      // remark : this.remark,
      toUser : this.selectedsalesPerson
    }
    this.modalCtrl.dismiss(data);
  }
   async fetchBombayMailDropdownData() {
    try {
      const salePersonsList = await this.httpService
        .getDataWithoutPayloadForGet(true, `tbl-role/getSalesPersonList?isSalesperson=${!this.isSalesMan}`)
        .toPromise();
      if (salePersonsList && salePersonsList.data) {
        this.salesPersonDropdown['options'] = salePersonsList.data;
      } else {
        console.warn("No sales person data found.");
      }
    } catch (error) {
      console.error("Error fetching sales person data:", error.message || error);
    }
  }


  onToggleChange(event: any) {
    const newState = event.detail.checked;
    if (this.isSalesMan !== newState) {
      this.isSalesMan = newState;
      this.fetchBombayMailDropdownData();
    }
  }

  Suggestion() {
    console.log(this.selectedPlans);
  }

}







//Required in TS of Parent Component
/*
selectedFinalPlan: any = [];
  isShowTool: boolean = true;
  isDevelopment: boolean = true;//Development is in progress

  packetPlans: any = [
    {
      "id": 11340,
      "packetId": 2122,
      "signerId": 147,
      "seqNo": "18",
      "remarks": null,
      "bidAmt": null,
      "packetPlanStones": [
        {
          "id": 13007,
          "packetPlanId": 11340,
          "tag": 18,
          "expPol": "1.471",
          "shapeId": 715,
          "clarityId": 69,
          "cutId": 379,
          "colorId": 227,
          "pavAngle": "41.640",
          "pavHeight": "44.500",
          "crAngle": "38.990",
          "crHeight": "15.000",
          "tableVal": "63.000",
          "girdle": "5.500",
          "diameter": "6.300",
          "height": "64.900",
          "lOrW": "1.400",
          "rapoRate": "3103.000",
          "sawMic": "mic",
          "lengthMM": "8.810",
          "heightMM": "6.300",
          "widthMM": "4.090",
          "createdDate": "2022-07-16T09:09:29.000Z",
          "createdBy": "2-trybe-signer2",
          "modifiedDate": null,
          "modifiedBy": null,
          "isDeleted": 0,
          "planShape": "Oval11",
          "planColor": "H",
          "planCut": "VG-2",
          "planClarity": "SI3",
          "planSymmetry": "",
          "symmetryId": 0,
          "shape": "OVAL11",
          "clarity": "SI3",
          "color": "H",
          "cut": "VG-2",
          "symmetry": null,
          "progName": null,
          "selectedProgramId": null,
          "kgRate": 30,
          "selected": false,
          "isFileSelected": false,
          "fluorescence": null
        }
      ],
      "selected": false,
      "isExpand": true,
      "weightSum": 1.47,
      "plannedRemark": "",
      "totalKGRate": 30,
      "bestRate": 0,
      "totalRate": 0,
      "expPolPer1": 122.58,
      "expPolPer2": 122.58
    },
    {
      "id": 11353,
      "packetId": 2122,
      "signerId": 147,
      "seqNo": "Saw29-1",
      "remarks": null,
      "bidAmt": null,
      "packetPlanStones": [
        {
          "id": 13021,
          "packetPlanId": 11353,
          "tag": 231,
          "expPol": "1.141",
          "shapeId": 655,
          "clarityId": 61,
          "cutId": 376,
          "colorId": 227,
          "pavAngle": "40.620",
          "pavHeight": "42.900",
          "crAngle": "34.610",
          "crHeight": "15.500",
          "tableVal": "55.000",
          "girdle": "4.500",
          "diameter": "6.630",
          "height": "63.000",
          "lOrW": "1.000",
          "rapoRate": "6456.000",
          "sawMic": "70mic",
          "lengthMM": "6.630",
          "heightMM": "6.630",
          "widthMM": "4.170",
          "createdDate": "2022-07-16T09:09:29.000Z",
          "createdBy": "2-trybe-signer2",
          "modifiedDate": null,
          "modifiedBy": null,
          "isDeleted": 0,
          "planShape": "ROUND",
          "planColor": "H",
          "planCut": "EX",
          "planClarity": "VS2",
          "planSymmetry": "EX",
          "symmetryId": 0,
          "shape": "ROUND",
          "clarity": "VS2",
          "color": "H",
          "cut": "EXCL",
          "symmetry": null,
          "progName": null,
          "selectedProgramId": null,
          "kgRate": null,
          "selected": false,
          "isFileSelected": false,
          "fluorescence": null
        },
        {
          "id": 13022,
          "packetPlanId": 11353,
          "tag": 314,
          "expPol": "0.303",
          "shapeId": 655,
          "clarityId": 61,
          "cutId": 376,
          "colorId": 227,
          "pavAngle": "40.670",
          "pavHeight": "43.000",
          "crAngle": "35.620",
          "crHeight": "16.100",
          "tableVal": "55.000",
          "girdle": "3.900",
          "diameter": "4.270",
          "height": "63.000",
          "lOrW": "1.000",
          "rapoRate": "374.000",
          "sawMic": "70mic",
          "lengthMM": "4.270",
          "heightMM": "4.270",
          "widthMM": "2.690",
          "createdDate": "2022-07-16T09:09:29.000Z",
          "createdBy": "2-trybe-signer2",
          "modifiedDate": null,
          "modifiedBy": null,
          "isDeleted": 0,
          "planShape": "ROUND",
          "planColor": "H",
          "planCut": "EX",
          "planClarity": "VS2",
          "planSymmetry": "",
          "symmetryId": 0,
          "shape": "ROUND",
          "clarity": "VS2",
          "color": "H",
          "cut": "EXCL",
          "symmetry": null,
          "progName": null,
          "selectedProgramId": null,
          "kgRate": null,
          "selected": false,
          "isFileSelected": false,
          "fluorescence": null
        }
      ],
      "selected": false,
      "isExpand": true,
      "weightSum": 1.44,
      "plannedRemark": "",
      "totalKGRate": 0,
      "bestRate": 0,
      "totalRate": 0,
      "expPolPer1": 120.33,
      "expPolPer2": 120.33
    }];
  allData: any = [];



  async planSelected(data) {
    this.selectedFinalPlan = data
  }
  updatePlansData(data) {
    this.packetPlans = data
  }

  Template/html
  <ion-content *ngIf="isDevelopment" class="bg-plan">
  <app-planning-grid [packetPlans]="packetPlans" [allData]="allData" [allowAction]="isShowTool"></app-planning-grid>
</ion-content>

module
    PlanningGridModule

*/