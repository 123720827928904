import { Component, Input, OnInit } from '@angular/core';
import { saveCurrentRowData } from '../tempDataStore/tempData.action';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { HttpService } from '../services/http/http.service';
import { ModalController, NavController } from '@ionic/angular';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';
import { TrybemodalComponent } from '../components/trybemodal/trybemodal.component';
import { OpinionMasterComponent } from '../opinion-master/opinion-master.component';

@Component({
  selector: 'app-lockmaster',
  templateUrl: './lockmaster.component.html',
  styleUrls: ['./lockmaster.component.scss'],
})
export class LockmasterComponent implements OnInit {
  columns = [];
  id: any;
  formJsonHist: any;
  showSubmit: boolean = true;
  isHistory: boolean = false;
  dataSource: any;
  refreshGrid: boolean = false;
  @Input() actionName: any;
  status: string;
  showDrawer: boolean = false;
  isDynamicFormShown: boolean = false;
  selectedRows: any;
  selectedRow: any;
  payload: any;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    public httpService: HttpService,
    private configService: ConfigService,
    private loaderService: LoaderService,
    private navCtrl: NavController
  ) {
    this.columns = [
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      { name: 'packet Num', prop: 'pktNo' },
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'Current Action', prop: 'currActionName' },
    ];
  }

  ngOnInit() {
    this.fecthGridData();
  }

  barcodeDetection(event) {
    console.log(event);
  }

  clearBarcode() {}

  onSelectionChanged(event) {
    console.log(event);
    this.selectedRows = event?.selectedRowsData;
    this.selectedRow = event?.selectedRowsData[0];
    if(this.selectedRow){
      this.lockModal(this.selectedRow)
    }
  }

  openDrawer(evt: any) {
    this.isDynamicFormShown = true;
    console.log(this.isDynamicFormShown);
  }

  closeDynamicForm() {
    this.isDynamicFormShown = false;
    console.log(this.isDynamicFormShown);

    // this.isStoneSelected = false;
    // this.selectedRow = {};
    // this.rowsIndex = [];
    // this.isStoneSelected = false;
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  displayHistory(e) {
    this.isHistory = true;
    let currentPacketId = e.row.data.id;
    this.selectedRow = e.row.data;
    console.log(currentPacketId);
    console.log(e.row.data.packetId);
    this.store.dispatch(saveCurrentRowData({ rowData: [this.selectedRow] }));
    if (!!currentPacketId) {
      console.log('History found');
      this.showDrawer = true;
      setTimeout(() => {
        this.showDrawer = false;
      }, 500);
    }
  }

  fecthGridData() {
    let endpoint: string = `packet/getLockedPackets`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log('change Status');
        console.log(res);
        let resultCode: number = res.statusCode;
        console.log(resultCode);
        let d: any = res.data;
        console.log(d);
        if (resultCode == 200) {
          this.dataSource = res.data.data;
        } else {
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

  

  async lockModal(data) {
    console.log("hiiiiiiii");
    const Modal = await this.modalController.create({
      component:OpinionMasterComponent,
      cssClass: 'opinion-screen-modal',
     // component: TrybemodalComponent,
     // cssClass: 'lockModal',
      componentProps: {
        data: { status: 'lockModal', data: data, actionName: this.actionName }
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  async handleModalDismisss(d) {
    console.log(d);
    
    if(typeof d.data ==='string'){
      this.payload = {
        'packetId':this.selectedRow?.id
      }

    this.saveRouteDataToDB(this.payload)

    }

    else if (typeof d.data ==='object'){
      this.payload = {
        'routeId':d.data?.name?.id,
        'packetId':this.selectedRow?.id
      }
    this.saveRouteDataToDB(this.payload)

    }


  }


  async saveRouteDataToDB(payload){
   console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet/unholdPacket", payload)
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }
}
