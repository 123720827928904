import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PacketwiseapprovallistComponent } from './packetwiseapprovallist.component';
import { DataGridComponentModule } from '../data-grid/data-grid.module';
import { HeaderComponentModule } from '../header/header.module';
import { ScannerinputModule } from '../scannerinput/scannerinput.module';




@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      // FormsModule,
      // ReactiveFormsModule,
      DataGridComponentModule,
      ScannerinputModule,
      HeaderComponentModule,
    ],
    declarations: [
        PacketwiseapprovallistComponent,
      ],
      entryComponents: [
        PacketwiseapprovallistComponent,
      ],
      exports: [PacketwiseapprovallistComponent],
      providers: []
    })

export class packetWiseApprovalListModule { }