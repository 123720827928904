import { Component, Input } from '@angular/core';
import {
  LoadingController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { getPdParameters } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ProcessActionComponent } from '../processAction';
import { SignerAction } from '../trybesigner/state/signer.action.type';

@Component({
  selector: 'app-pd',
  templateUrl: './pd.component.html',
  styleUrls: ['./pd.component.scss'],
})
export class PDComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() hideSubmit: boolean = false
  @Input() from: string;
  public stoneList = [];
  public stoneIdList = [];
  public stoneParameter = [];
  public stoneId: string = '';
  public pageNumber = 0;
  public pValues: any;
  public noOfRecords: Number = 100;
  public paramList = [];
  public singleParameter: any;
  public showPopover = false;
  public selectedValuesArray = [];
  public paramIndex = 0;
  public totalParam: any;
  public pdObject = {};
  public pdData = {};
  public isDisable = true;
  public SSParamList = [];
  selectedPDvalues: any = [];
  public pdObjectArray = [];
  packetPlanStoneId;
  packetId: string;

  constructor(
    private store: Store<AppState>,
    public httpApiService: HttpService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService
  ) {


  }

  async ngOnInit() {
    console.log(this.data)
    if (!!this.data && !!this.data.from) {
      this.from = this.data.from;
    }
    console.log(this.from);
    if (!!this.data && !!this.data.packetPlanStoneId) {
      this.packetPlanStoneId = this.data.packetPlanStoneId
    }
    if (!!this.data && !!this.data.packetId) {
      this.packetId = this.data.packetId
    }
    this.inStockStone();
    this.store.pipe(select(getPdParameters)).subscribe(data => {
      if (data.length > 0) {
        this.singlestoneParameter(data);
      }
    })
    //await this.singlestoneParameter();
    await this.getPdParamsByStoneId(this.packetPlanStoneId);
  }

  // dropDownSelection(ev) {
  //   console.log(ev);
  //   let property = ev.property;
  //   let idList = ev.idList;
  //   let obj = ev.arrayList;
  //   let masterId = ev.masterId;
  //   if (property == 'stoneParam') {
  //     if (idList.length > 0) {
  //       this.stoneId = obj[0].stoneId;
  //       this.isDisable = true;
  //       this.cloneData();
  //       this.getPdParamsByStoneId(this.stoneId);
  //     } else {
  //       this.isDisable = true;
  //       this.stoneId = null;
  //       this.selectedValuesArray = [];
  //     }
  //   }
  // }

  async singlestoneParameter(dataList) {
    let data1 = [];
    for (let i = 0; i < dataList.length; i++) {
      if (!!dataList[i].nameGroup) {
        if (data1.length > 0) {
          let flag = data1.some(
            (el) => el.nameGroup === dataList[i].nameGroup
          );
          if (!flag) {
            data1.push({
              id: dataList[i].id,
              nameGroup: dataList[i].nameGroup,
              isGroup: true,
              companyId: dataList[i].companyId,
              downTolerance: dataList[i].companyId,
              masterName: dataList[i].masterName,
              paramMasterId: dataList[i].paramMasterId,
              subParameters: [],
            });
          }
        } else {
          data1.push({
            id: dataList[i].id,
            nameGroup: dataList[i].nameGroup,
            isGroup: true,
            companyId: dataList[i].companyId,
            downTolerance: dataList[i].companyId,
            masterName: dataList[i].masterName,
            paramMasterId: dataList[i].paramMasterId,
            subParameters: [],
          });
        }
      } else {
        let isValueGroup: any;
        let valueGroup1: any;
        if (dataList[i]?.parameterValues?.length > 0) {
          dataList[i].parameterValues.forEach((el) => {
            if (!!el.valueGroup) {
              valueGroup1 = el.valueGroup;
            }
          });
          let flag: Boolean;
          dataList[i].parameterValues.filter(function (item) {
            if (item.valueGroup != valueGroup1) {
              flag = true;
              return true;
            }
            return;
          });
          if (!!flag && flag == true) {
            isValueGroup = true;
          } else {
            isValueGroup = false;
          }
        }
        data1.push({
          id: dataList[i].id,
          nameGroup: dataList[i].masterName,
          isValueGroup: isValueGroup,
          isGroup: false,
          companyId: dataList[i].companyId,
          downTolerance: dataList[i].companyId,
          masterName: dataList[i].masterName,
          paramMasterId: dataList[i].paramMasterId,
          parameterValues: dataList[i].parameterValues,
        });
      }
    }

    for (let i = 0; i < data1.length; i++) {
      if (data1[i].isGroup == true) {
        data1[i].totalsubParamList = [];
        dataList.forEach((el) => {
          if (el.nameGroup == data1[i].nameGroup) {
            data1[i].subParameters.push(el);
          }
        });
        data1[i].subParameters.forEach((el) => {
          data1[i].totalsubParamList.push(el.masterName);
        });
      }
    }

    this.SSParamList = data1;
    this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
    console.log(this.stoneParameter);

  }

  async getPdParamsByStoneId(stoneId) {
    let res: any;
    console.log(stoneId);
    this.httpApiService.getPdParamsByStoneId(stoneId).subscribe(async (res) => {
      console.log(res);
      this.httpApiService.getErrorAndDisplayIt(res);
      if (res.data.length > 0) {
        let dataValue = res.data;
        console.log(dataValue);
        this.isDisable = false;
        dataValue.forEach((el) => {
          let valueList = el.paramValueIds.toString().split(',').map(Number);
          // if (!!el.paramValueIds && el.paramValueIds.toString().indexOf(',') != -1) {
          //   valueList = el.paramValueIds.split(',').map(Number);
          // } else {
          //   valueList = el.paramValueIds
          // }
          for (let i = 0; i < this.stoneParameter.length; i++) {
            if (this.stoneParameter[i].isGroup == true) {
              this.stoneParameter[i].subParameters.forEach((subparamEl) => {
                if (subparamEl.masterName == el.paramName) {
                  let valueIdList = [];
                  let pds = [];
                  subparamEl.parameterValues.forEach((item) => {
                    let flag = valueList.some((val) => val == item.id);
                    console.log('1', valueList, item.id, item.code, flag);
                    if (flag) {
                      item.isSelected = true;
                      valueIdList.push(item.id);
                      pds.push(item);
                      this.pdObject[subparamEl.masterName] = valueIdList;

                      if (!this.pdData[subparamEl.masterName]) {
                        this.pdData[subparamEl.masterName] = {};
                        this.pdData[subparamEl.masterName] = { name: subparamEl.masterName, value: valueIdList, pd: pds };
                      } else {
                        this.pdData[subparamEl.masterName] = { name: subparamEl.masterName, value: valueIdList, pd: pds };
                      }
                    } else {
                      item.isSelected = false;
                    }
                  });
                }
              });
            } else {
              if (el.paramName == this.stoneParameter[i].nameGroup) {
                let valueIdList = [];
                let pds = [];
                this.stoneParameter[i].parameterValues.forEach((item) => {
                  let flag = valueList.some((val) => val == item.id);
                  console.log('2', valueList, item.id, item.code, flag);
                  if (flag) {
                    item.isSelected = true;
                    valueIdList.push(item.id);
                    pds.push(item);
                    this.pdObject[this.stoneParameter[i].masterName] =
                      valueIdList;
                    if (!this.pdData[this.stoneParameter[i].masterName]) {
                      this.pdData[this.stoneParameter[i].masterName] = {};
                      this.pdData[this.stoneParameter[i].masterName] = { name: this.stoneParameter[i].masterName, value: valueIdList, pd: pds };
                    } else {
                      this.pdData[this.stoneParameter[i].masterName] = { name: this.stoneParameter[i].masterName, value: valueIdList, pd: pds };
                    }
                  } else {
                    item.isSelected = false;
                  }
                });
              }
            }
          }
        });
        console.log(this.stoneParameter, this.pdObject);
      }
    });
    // res = await this.httpApiService.getPdParamsByStoneId(stoneId);
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   let dataValue = data.data
    //   if (dataValue.length > 0) {
    //     this.isDisable = false;
    //     console.log(dataValue)
    //     dataValue.forEach(el => {
    //       let valueList = el.paramValueIds.split(',').map(Number);
    //       for (let i = 0; i < this.stoneParameter.length; i++) {
    //         if (this.stoneParameter[i].isGroup == true) {
    //           this.stoneParameter[i].subParameters.forEach(subparamEl => {
    //             if (subparamEl.masterName == el.paramName) {
    //               let valueIdList = []
    //               subparamEl.parameterValues.forEach(item => {
    //                 let flag = valueList.some(val => val == item.id);
    //                 console.log("1", valueList, item.id, item.code, flag)
    //                 if (flag) {
    //                   item.isSelected = true;
    //                   valueIdList.push(item.id)
    //                   this.pdObject[subparamEl.masterName] = valueIdList
    //                 } else {
    //                   item.isSelected = false;
    //                 }
    //               })
    //             }
    //           })
    //         } else {
    //           if (el.paramName == this.stoneParameter[i].nameGroup) {
    //             let valueIdList = []
    //             this.stoneParameter[i].parameterValues.forEach(item => {
    //               let flag = valueList.some(val => val == item.id);
    //               console.log("2", valueList, item.id, item.code, flag)
    //               if (flag) {
    //                 item.isSelected = true;
    //                 valueIdList.push(item.id)
    //                 this.pdObject[this.stoneParameter[i].masterName] = valueIdList
    //               } else {
    //                 item.isSelected = false;
    //               }
    //             })
    //           }
    //         }
    //       }
    //     })
    //     console.log(this.stoneParameter, this.pdObject)
    //   }
    // }
  }

  cloneData() {
    this.stoneParameter = [];
    this.pdObject = {};
    this.pdData = {};
    this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
  }

  async inStockStone() {
    let res: any;
    this.httpApiService.getStockStone().subscribe(async (res) => {
      let dataList = [
        {
          id: 372422,
          stoneId: 'N0077062',
          stoneCts: 0.18,
          transType: 'SS',
          barcode: '7352314',
        },
        {
          id: 372450,
          stoneId: 'OWSW-01874-AC',
          stoneCts: 0.19,
          transType: 'SS',
          barcode: '7446275',
        },
        {
          id: 372457,
          stoneId: 'N0090881',
          stoneCts: 0.18,
          transType: 'SS',
          barcode: '7369245',
        },
        {
          id: 399531,
          stoneId: 'N0139784',
          stoneCts: 0.53,
          transType: 'SS',
          barcode: '7499369',
        },
      ]; // res.data
      let data1 = [];
      if (dataList.length > 0) {
        dataList.forEach((el) => {
          console.log;
          if (el.transType == 'SS') {
            data1.push(el);
          }
        });
        console.log(data1);
        this.stoneList = data1;
      }
      console.log(this.stoneList);
    });
    // res = await this.httpApiService.getStockStone();
    // let data = res.json();
    // console.log(data)
    //
    //  if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   let dataList = data.data;
    //   let data1 = []
    //   if (dataList.length > 0) {
    //     dataList.forEach(el => {
    //       console.log
    //       if (el.transType == 'SS') {
    //         data1.push(el)
    //       }
    //     })
    //     console.log(data1)
    //     this.stoneList = data1
    //   }
    //   console.log(this.stoneList)
    // }
  }

  //select sub parameter value


  async selectedPDParameterValue(parameters, sub, stones, index) {
    //this.pdObjectArray = [];
    console.log(parameters, sub, stones, index)
    if (parameters.isGroup == true) {
      if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
        parameters.isValueGroup = false;
      } else {
        stones.forEach((el, i) => {
          if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
            stones[i].isSelected = false;
          }
        })
        parameters.isValueGroup = false;
        stones.forEach((el, i) => {
          if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
            stones[i].isSelected = false;
            stones[index].isSelected = true;
          } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
            stones[index].isSelected = true;
          }
        })
      }
    } else {
      if (parameters.isValueGroup && parameters.isValueGroup == true) {
        if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
          stones.forEach(element => {
            element.isSelected = false;
          });
          stones[index].isSelected = true;
        } else {
          stones.forEach((el, i) => {
            if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
              stones[i].isSelected = false;
            }
          })
          stones.forEach((el, i) => {
            if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
              stones[i].isSelected = false;
              stones[index].isSelected = true;
            } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
              stones[index].isSelected = true;
            }
          })
        }
      } else {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
      }
    }
    if (!!parameters.isValueGroup && parameters.isValueGroup == true && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let pds = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            let obj = {}
            obj['paramName'] = paramList[i].masterName
            obj['paramValue'] = valueList[j].id
            this.pdObjectArray.push(obj);
            valueIdList.push(valueList[j].id);
            pds.push(valueList[j]);
          }
        }
        // let obj = {}
        // obj['paramName'] = paramList[i].masterName
        // obj['paramValue'] = valueIdList
        // this.pdObjectArray.push(obj)
        this.pdObject[paramList[i].masterName] = valueIdList;
        if (!this.pdData[paramList[i].masterName]) {
          this.pdData[paramList[i].masterName] = {};
          this.pdData[paramList[i].masterName] = { name: paramList[i].masterName, value: valueIdList, pd: pds };
        } else {
          this.pdData[paramList[i].masterName] = { name: paramList[i].masterName, value: valueIdList, pd: pds };
        }
      }
    } if (parameters.isValueGroup == false && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      console.log(paramList, "1")
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        let pds = [];
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            let obj = {}
            obj['paramName'] = paramList[i].masterName
            obj['paramValue'] = valueList[j].id
            this.pdObjectArray.push(obj);
            pds.push(valueList[j]);

            valueIdList.push(valueList[j].id)
          }
        }

        this.pdObject[paramList[i].masterName] = valueIdList;
        if (!this.pdData[paramList[i].masterName]) {
          this.pdData[paramList[i].masterName] = {};
          this.pdData[paramList[i].masterName] = { name: paramList[i].masterName, value: valueIdList, pd: pds };
        } else {
          this.pdData[paramList[i].masterName] = { name: paramList[i].masterName, value: valueIdList, pd: pds };
        }
      }
    } else if (parameters.isGroup == false && parameters.isValueGroup == true) {
      let paramList = stones;
      console.log(paramList, "2")
      let valueIdList = [];
      let pds = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].id);
          pds.push(paramList[i]);
        }
      }
      this.pdObject[parameters.nameGroup] = valueIdList;
      if (!this.pdData[parameters.nameGroup]) {
        this.pdData[parameters.nameGroup] = {};
        this.pdData[parameters.nameGroup] = { name: parameters.nameGroup, value: valueIdList, pd: pds };
      } else {
        this.pdData[parameters.nameGroup] = { name: parameters.nameGroup, value: valueIdList, pd: pds };
      }
    } else if (parameters.isGroup == false && parameters.isValueGroup == false) {
      let paramList = stones;
      let valueIdList = [];
      let pds = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          let obj = {}
          obj['paramName'] = parameters.nameGroup
          obj['paramValue'] = paramList[i].id
          this.pdObjectArray.push(obj)
          valueIdList.push(paramList[i].id)
          pds.push(paramList[i]);
        }
      }
      this.pdObject[parameters.nameGroup] = valueIdList;
      if (!this.pdData[parameters.nameGroup]) {
        this.pdData[parameters.nameGroup] = {};
        this.pdData[parameters.nameGroup] = { name: parameters.nameGroup, value: valueIdList, pd: pds };
      } else {
        this.pdData[parameters.nameGroup] = { name: parameters.nameGroup, value: valueIdList, pd: pds };
      }
    }
    console.log(this.pdObject, this.stoneParameter);

    if (Object.keys(this.pdObject).length > 0) {
      let pdKeyArray = []
      pdKeyArray = Object.keys(this.pdObject)

      this.stoneParameter.forEach(el => {
        if (el.isGroup == true) {
          if (el.totalsubParamList.length > 0) {
            let test = true;
            el.totalsubParamList.forEach(item => {
              for (let i = 0; i < pdKeyArray.length && test; i++) {
                if (pdKeyArray[i] == item) {
                  if (this.pdObject[pdKeyArray[i]].length > 0) {
                    el.isSelected = true;
                  } else {
                    el.isSelected = false;
                    test = false
                  }
                }
              }
            })
          }
        } else {
          for (let key in this.pdObject) {
            if (el.masterName == key) {
              if (this.pdObject[key].length > 0) {
                el.isSelected = true;
              } else {
                el.isSelected = false;
              }
            }
          }
        }
      })
    }

    console.log(this.pdObject)
  }

  async savePD() {
    console.log('savePD');
    console.log(this.selectedValuesArray);
  }
  closePDModal(res: any) {
    console.log('closePDModal');
    console.log(this.selectedValuesArray);
    if (!!res) {
      let obj = { packetId: this.packetPlanStoneId }
      this.modalCtrl.dismiss(obj);
    } else {
      this.modalCtrl.dismiss();
    }
  }

  async fillWithNone() {
    console.log('---------fillWithNone--------');
    console.log(this.pdData);
    console.log(this.pdObject);
    console.log(this.stoneParameter);
    this.stoneParameter.forEach(el => {
      console.log(el);
      let masterName = el.masterName;
      if (el.isGroup) {
        if (el.subParameters.length > 0) {
          el.subParameters.forEach(el => {
            masterName = el.masterName;
            console.log(el);
            let flag = el.parameterValues.find(item => !!item.isSelected == true)
            if (!flag) {
              el.parameterValues.forEach(el1 => {
                console.log(el1)
                if (el1.code.toLowerCase() == 'non' || el1.code.toLowerCase() == 'Non' || el1.name.toLowerCase() == 'none') {
                  let val = el1.id;

                  this.pdObject[masterName] = val;
                  if (!this.pdData[masterName]) {
                    this.pdData[masterName] = {};
                    this.pdData[masterName] = { name: masterName, value: val, pd: [el1] };
                  } else {
                    this.pdData[masterName] = { name: masterName, value: val, pd: [el1] };
                  }
                  el1.isSelected = true
                }
              });
            }
          });
        }

      } else {
        let flag = el.parameterValues.find(item => !!item.isSelected == true)
        if (!flag) {
          el.parameterValues.forEach(el1 => {
            if (el1.code.toLowerCase() == 'non' || el1.code.toLowerCase() == 'Non' || el1.name.toLowerCase() == 'none') {
              let val = el1.id
              this.pdObject[masterName] = val;
              if (!this.pdData[masterName]) {
                this.pdData[masterName] = {};
                this.pdData[masterName] = { name: masterName, value: val, pd: [el1] };
              } else {
                this.pdData[masterName] = { name: masterName, value: val, pd: [el1] };
              }
              el1.isSelected = true
            }
          })
        }
      }
    })

    console.log(this.stoneParameter)
    // this.modalCtrl.dismiss()
  }








  async submitPDs() {
    await this.fillWithNone();
    console.log('submitPDs');
    console.log(this.selectedPDvalues);

    console.log(this.pdObject);
    console.log(this.pdData);

    let pdPayload = [];

    for (let key in this.pdObject) {
      console.log(key);
      let paramName = key;
      console.log(this.pdObject[key]);
      let val = this.pdObject[key];
      let pdObj = this.pdData[key];
      console.log(pdObj);
      if (val.length > 0) {
        for (let i in val) {
          let obj = {}
          obj['paramName'] = paramName
          obj['paramValue'] = val[i];
          obj['packetPlanStoneId'] = this.packetPlanStoneId;
          obj['paramStrValue'] = pdObj.pd[i].code;
          pdPayload.push(obj)
        }
      } else {
        let obj = {}
        obj['paramName'] = paramName
        obj['paramValue'] = val;
        obj['packetPlanStoneId'] = this.packetPlanStoneId;
        obj['paramStrValue'] = pdObj.pd.length === 1 ? pdObj.pd[0].code : null;
        pdPayload.push(obj)
      }
    }
    console.log(pdPayload);
    // return;
    console.log(this.packetId);
    this.store.dispatch(
      SignerAction.savePDParameterPacketPlanStone({
        pdPayload: pdPayload, packetIdObj: { packetId: this.packetId }
      })
    );
    console.log(this.packetPlanStoneId)
    await this.modalCtrl.dismiss(this.packetPlanStoneId);
    //this.closePDModal(this.packetPlanStoneId)
  }
}
