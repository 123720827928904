import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  getAllParameters,
  getParamsValuesListByName,
} from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-parameter-filter',
  templateUrl: './parameter-filter.component.html',
  styleUrls: ['./parameter-filter.component.scss'],
})
export class ParameterFilterComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private httpService: HttpService,
    private configService: ConfigService,
    private popoverController: PopoverController
  ) {}

  ngOnInit() {

    this.getAllParameters()
  }

  gridRow: any = {
    sawRghWgt: {from:'', to:''},
    expPol: {from:'', to:''},
    diameter: {from:'', to:''},
    depthPercentage: {from:'', to:''},
    lOrW: {from:'', to:''},
  };
  depthDropdown: any;
  highestOptionLength: any[] = [];
  subscriptions = new Subscription();
  orderFlDropdown: any[] = [];
  environment:any= environment
  public selectedGrades: any = [];
  PRPS_colSize: number = environment.project == 'kps' ? 0.8 : 0.8571;

  orderClarityDropdown:any[]=[];
  nattsDropdown:any[]=[];
  orderColorDropdown:any[]=[];
  tinchMaster:any[]=[];
  lusterDropdown:any[]=[];
  orderShapeDropdown:any[]=[];
  labDropdown:any[]=[];
  cutMaster:any[]=[];
  polDropdown:any[]=[];
  symmetryDropdown:any[]=[];
  ratioDropdown:any[]=[];
  topOpenDropdown:any[]=[]
  bottomOpenDropdown:any[]=[]
  planningReviewUpdated: any = {};
  selectedParameters:any={};
  @Input() defaultFilterData: any;

  getAllParameters() {
    const subs = this.store.select(getAllParameters).subscribe((res: any) => {
      const copyParameters = JSON.parse(JSON.stringify(res))
      
      Object.keys(copyParameters).forEach((el:any)=>{
        this.selectedParameters[el]=[]
        copyParameters[el].map((parameter:any,index:number)=>{
          parameter.isChecked = false
          return parameter
        })
        if(this.defaultFilterData[el]){
          this.defaultFilterData[el].forEach((param):any=>{
            const element = copyParameters[el].find((element)=>param.id === element.id)
            element.isChecked = true;
          })
          this.selectedParameters[el]= this.defaultFilterData[el]
        }
      })
      this.selectedParameters['gridRow'] = this.defaultFilterData['gridRow'] ? this.defaultFilterData['gridRow'] : {}
      this.gridRow = {...this.gridRow, ...(this.defaultFilterData['gridRow'] ? this.defaultFilterData['gridRow'] : {})}
      this.orderClarityDropdown = copyParameters['Clarity']
      this.nattsDropdown = copyParameters['NATTS']
      this.orderColorDropdown = copyParameters['Color']
      this.orderFlDropdown = copyParameters['Fluorescence']
      this.orderShapeDropdown = copyParameters['Shape']
      this.labDropdown = copyParameters['Lab']
      this.tinchMaster = copyParameters['Tinch']
      this.cutMaster = copyParameters['CUT']
      this.polDropdown = copyParameters['Polish']
      this.symmetryDropdown = copyParameters['Symmetry']
      this.depthDropdown = copyParameters['DEPTH']
      this.ratioDropdown = copyParameters['RATIO']
      this.topOpenDropdown = copyParameters['TOP OPEN']
      this.bottomOpenDropdown = copyParameters['BOTTOM OPEN']
      this.lusterDropdown = copyParameters['LUSTER']
    });

    this.subscriptions.add(subs);

  }

  async getDepthByShapeId(shapeId) {
    return await this.httpService.getDepthByShapeId(shapeId);
  }

  async getPDCatValueFromNatts(nattsId) {
    return await this.httpService.getPDCatValueFromNatts(nattsId)
  }

  async getRatioByShapeId(shapeId) {
    return await this.httpService.getRatioByShapeId(shapeId)
  }

  closePushOver(res) {
    if (res) {
      res['gridRow'] = this.gridRow
      
      let d = {
        filterBy:res,

      }
      this.popoverController.dismiss(d);
    } else {
      this.popoverController.dismiss();
    }
  }

  optionClick(variable: string, index: number, resKey?: string) {
    
    this[variable][index]['isChecked']=!this[variable][index]['isChecked']
    if (!this[variable][index]['isChecked']) {
      const elementId = this[variable][index]?.id;
      const selectedIndex = this.selectedParameters[resKey].findIndex((el) => el.id === elementId);
      if (selectedIndex !== -1) {
        this.selectedParameters[resKey].splice(selectedIndex, 1);
      }
    }else{
    this.selectedParameters[resKey]=[...this.selectedParameters[resKey],this[variable][index]]
    }
    console.log(this.selectedParameters)
  }

  depthPercentageChange(event: any) {
    if (!(!isNaN(event) && !isNaN(parseFloat(event))))
      return;

    this.depthDropdown = this.depthDropdown?.map((item: any) => {
      const range = item.name.match(/[0-9]+\.[0-9]+/g);

      if ((Number(range?.[0]) <= Number(event)) && (Number(range?.[1]) >= Number(event)))
        return { ...item, isChecked: true };
      else
        return { ...item, isChecked: false };
    });
  }

}
