import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { AppState } from 'src/app/reducers';
import CustomStore from "devextreme/data/custom_store";
import { HttpService } from 'src/app/services/http/http.service';

import Query from "devextreme/data/query";
import { ConfigService } from 'src/app/services/config/config.service';
import { LockChanges } from '@ngrx/store-devtools/src/actions';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { ScannerinputComponent } from '../scannerinput/scannerinput.component';

@Component({
  selector: 'app-userwisestockdetails',
  templateUrl: './userwisestockdetails.component.html',
  styleUrls: ['./userwisestockdetails.component.scss'],
})
export class UserwisestockdetailsComponent implements OnInit {
  @ViewChild('auto') auto: AutocompleteComponent;
  @ViewChild(ScannerinputComponent)
  private scannerComponet: ScannerinputComponent;
  
  columns=[];
  dataSource:any=[];
  Date = moment().format('YYYY-MM-DD');
  public pageSize=15;
  search:any;
  
   obj = {
    "Date": this.Date
  }
   pageinfo = {
    "limit": this.pageSize,
    "offset":0
  }

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    public httpService: HttpService,
    public configService: ConfigService,
    private store: Store<AppState>,
    public alertController: AlertController,
    public popoverController: PopoverController
  ) { 

    this.columns = [
      { name: 'Username', prop: 'userName' },
      { name: 'Total packet', prop: 'totalPacket' },
      { name: 'Department', prop: 'departmentName' }

    ];
  }

  ngOnInit() {
    console.log(this.Date);
    this.fetch();
  }

  fetch(){

    this.dataSource = new CustomStore({
  
      // key: "id",
      load: async (loadOptions: any) => {
     
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageinfo.limit;
          } else {
            this.pageSize = loadOptions.take;
          }
          
          this.pageinfo.limit = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageinfo.offset = loadOptions.skip / loadOptions.take;
          let res: any;
          this.obj["Date"]=this.Date;
          this.obj["userName"]=this.search;
          console.log(this.obj);
          
          res = await this.httpService.fetchReportByUsers(this.obj,this.pageinfo)
          console.log(this.obj);
          
          let data = res;
  
          if (res.status == 401) {
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
        
            console.log(data);
            
            return {
              data: Query(data.data.details)
                .toArray(),
              totalCount: data.data.totalPackets,
              groupCount: data.data.totalPackets
            };
          }
        } else {
          return {
            data:[],
            totalCount: 0,
            groupCount: 0
          }
        }
      }
    });
  }
  barcodeDetection($event){
    console.log($event);
     this.search = $event;
    
    // this.obj2 = {
    //   "userName":$event
    // }
    // console.log(this.obj2);
    
    this.fetch();
    
  }
  clearBarcode(isClear: boolean){
    if(isClear){
    this.scannerComponet.onResetForm();
      // this.auto.clear();
      this.search=""
      this.barcodeDetection(this.search)
      this.fetch();
    }
  }
  
  }


