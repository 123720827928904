import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ConfigService } from '../services/config/config.service';
import { AppState } from '../reducers';
import { Store, select } from '@ngrx/store';
import { getCurrentRowData } from '../tempDataStore/tempData.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-pd',
  templateUrl: './new-pd.component.html',
  styleUrls: ['./new-pd.component.scss'],
})
export class NewPdComponent implements OnInit {
  public stoneParameter = {};
  public pdObjectArray = [];
  payloadObject: object[] = [];
  rowData: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  constructor(
    private store: Store<AppState>,
    public httpApiService: HttpService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService
  ) {}

  ngOnInit() {

    this.fetchPdValues();
    this.store
      .pipe(select(getCurrentRowData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rd: any) => {
        if (rd) {
          console.log('getCurrentRowData');
          console.log(rd);
          this.rowData = rd[0];
        }
      });

  }

  fetchPdValues() {
    let endpoint: string = `pd-parameter-value/getPDParams`;
    this.httpApiService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        if (resultCode == 200) {
          this.stoneParameter = res.data;
          this.fetchSelectedPdData();
        } else {
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

  closePDModal(evt) {
    this.modalCtrl.dismiss();
  }

  async submitPDs() {
    if(this.payloadObject){
    let response = await this.httpApiService.postDataUsingPromise(
      true,
      'premium-discount/save',
      this.payloadObject
    );
    this.httpApiService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.configService.showToast('success', response.data);
      this.modalCtrl.dismiss();
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  }
  }
  onClickOfbtns(selectedValue, thirdparam, secondparam, firstheader) {
    if (
      this.stoneParameter &&
      this.stoneParameter[firstheader] &&
      this.stoneParameter[firstheader][secondparam] &&
      this.stoneParameter[firstheader][secondparam][thirdparam]
    ) {
      this.stoneParameter[firstheader][secondparam][thirdparam].forEach(
        (item) => {
          if (item.paramName === selectedValue.paramName) {
            if (item.isSelected) {
              item.isSelected = false;
              this.payloadObject = this.payloadObject.filter(
                (dt: any) =>
                  item.paramName != dt.paramValue
              );
            } else {
              item.isSelected = true;
              this.payloadObject.push({
                paramName: thirdparam,
                paramValue: item.paramName,
                type: secondparam,
                packetId: this.rowData.id,
              });
            }
          } else {
            this.payloadObject = this.payloadObject.filter(
              (dt: any) =>
                item.paramName != dt.paramValue
            );
            delete item.isSelected;
          }
        }
      );
      console.log(this.payloadObject, this.stoneParameter[firstheader][secondparam][thirdparam]);
      
    }
  }

  fetchSelectedPdData(){
    this.httpApiService.fetchData(true, `premium-discount/getPremiumDiscountOnBasisOfPacketPlanStoneId/${this.rowData?.id}`).subscribe(response => {
      console.log(response);
      this.httpApiService.getErrorAndDisplayIt(response);
    });
  }
}
