import { createFeatureSelector, createSelector } from "@ngrx/store";
import { WithMeState } from "./issue.state";


// debugger
export const selectWithMeState = createFeatureSelector<WithMeState>(
    'withme',
);

export const getUsersByActionData = createSelector(selectWithMeState, state => {
    return state.usersByAction;
});
export const getUsersByVirtualActionData = createSelector(selectWithMeState, state => {
    return state.usersByVirtualAction;
});
export const getActionsByPacketData = createSelector(selectWithMeState, state => {
    return state.actionsByPacket;
});
export const getStartFromData = createSelector(selectWithMeState, state => {
    return state.startFrom;
});
export const getActionsByVirtualPacketData = createSelector(selectWithMeState, state => {
    return state.actionsByVirtualPacket;
});
