import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../services/config/config.service';
import { HttpService } from '../services/http/http.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { LoaderService } from '../services/loader/loader.service';
import * as JsBarcode from "jsbarcode";
import { DatePipe } from '@angular/common';

enum PACKETINWARD {
 URL='https://child-safety-docs.s3.ap-south-1.amazonaws.com/dev/packetInwardsD.xlsx'
}

@Component({
  selector: 'app-kg-packet-creation',
  templateUrl: './kg-packet-creation.component.html',
  styleUrls: ['./kg-packet-creation.component.scss'],
})
export class KgPacketCreationComponent implements OnInit {
  lotColumns = [
    { name: 'Lot No.', prop: 'lotNo',columnTemplate:'lot-svg-template' },
    { name: 'Parent Lot No.', prop: 'parentLotNo' },
    { name: 'CreatedDate', prop: 'createdDate' },
    { name: 'Status', prop: 'completed', columnTemplate: 'chipTemplate' },
    { name: 'FM String', prop: 'FmString' },
    { name: 'Coo String', prop: 'CooString' },
    { name: 'CM String', prop: 'CmString' },
    { name: 'Total PCS', prop: 'pcs' },
    { name: 'Total Cts', prop: 'cts' },
    { name: 'Weight Diff Jangad Date', prop: 'wtDiff' },
    { name: 'Ack Status', prop: 'ackStatus' },
    { name: 'Status', prop: 'status', columnTemplate: 'chipTemplate' },
    { name: 'Ref. Issue Id', prop: 'parentLotId' },
  ];
  packetColumns = [
    { name: 'FileName', prop: 'fileName' },
    { name: 'Status', prop: 'status', columnTemplate: 'chipTemplate' },
    // { name: 'Grade', prop: 'grade' },
    // { name: 'Level', prop: 'level' },
    // { name: 'Pred Flow', prop: 'predFlow' },
  ];
  refreshGrid = false;
  lotDataSource = [];
  packetDataSource = [];
  boardBaseUrl = '';
  showFileGrid = false;
  isNfcDataScanned: true;
  selectedLot: any;
  isSelectionEnabled: boolean = true;
  readonly excelRegex = /\.xlsx$/i;
  barcodeList: any[] = [];

  @ViewChild('barcodeContainer') barcodeContainer: ElementRef;

  constructor(
    private httpService: HttpService,
    private _ConfigService: ConfigService,
    private datePipe: DatePipe,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.getPendingLot()
  }

  async scanNfc() {
    this.isNfcDataScanned = true;
    let res;
    if (!!this.boardBaseUrl) {
      res = await this.httpService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == 'error') {
      } else {
      }
    }
  }

  async getPrintDataApi(event) {
    let endpoint: string = 'packet/getPacketsOnBasisOFLot';
    let payload = {
     "lotNo" : event?.row?.data?.lotNo
    }
    
    let response = await this.httpService.postDataUsingPromise(
      true,
      endpoint,
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      if (response.data.length === 0) {
        this._ConfigService.showToast('error', 'No data available');
        return; 
      }
      const newData = response.data;
      this.getBarcodeToPrint(newData);
      return newData
    } else {
      console.error(response);
      this._ConfigService.showToast('error', response.error.message);
    }
  }



  // printBarcode(data: any){
  //   const packetDetails = data;
  //   if(!packetDetails?.length){
  //     this._ConfigService.showToast("error", ('No data found!'));
  //     return;
  //   }
  //   const allChild = [];
  //   const printWindow = window.open('', '_blank');
  //   printWindow.document.write('<html>');
  //   printWindow.document.write('<body style="margin: 0;">');
  //   for(const key in packetDetails){
  //     const canvas = document.createElement('canvas');
  //     const textToShow = {};
  //     const keyToShow = ['LotNo','PacketLabel','cts','grade','inDate'];
  //     let barcode = '';

  //     // packetDetails[key].map((data:any)=>{
  //       if(packetDetails[key]?.PacketLabel)
  //       barcode=packetDetails[key].LotNo + "#" + packetDetails[key].PacketLabel;
  //     //   if(keyToShow.includes(data.paramName))
  //     //   textToShow[data.paramName]=data.paramValue;
  //     // });

  //     JsBarcode(canvas, barcode, {
  //       format: 'CODE128',
  //       displayValue: false,
  //       text: `
  //         ${packetDetails[key]?.[keyToShow[0]]}\t${packetDetails[key]?.[keyToShow[1]]}\t${packetDetails[key]?.[keyToShow[2]]}\n${packetDetails[key]?.[keyToShow[3]]}\t${packetDetails[key]?.[keyToShow[4]]}}
  //       `,
  //     });
  //     canvas.style.margin = '1px 0';
  //     canvas.style.padding = '0';
  //     canvas.style.border = 'none';
  //     canvas.style.width = '45%';
  //     canvas.style.height = '43px';
      

  //     const containerDiv = document.createElement('div');
  //     containerDiv.style.display = 'flex';
  //     containerDiv.style.flexDirection = 'column';
  //     containerDiv.style.alignItems = 'left';
  //     containerDiv.style.justifyContent = 'left';
  //     containerDiv.style.fontWeight = '900';
  //     containerDiv.style.fontFamily = 'monospace';
  //     containerDiv.style.padding = '3px';
  //     containerDiv.style.margin = '1px 0';
  //     containerDiv.appendChild(canvas);

  //     const innerDiv = document.createElement('div');
  //     innerDiv.style.breakAfter = 'page';
  //     innerDiv.style.fontSize = '16px';
  //     innerDiv.style.display = 'flex';
  //     innerDiv.style.alignItems = 'left';
  //     innerDiv.style.flexDirection = 'column';

  //     const paragraph1 = document.createElement('p');
  //     paragraph1.style.margin = '2px 0';
  //     paragraph1.style.padding = "0 1px"
  //     paragraph1.textContent = `${packetDetails[key]?.[keyToShow[0]]}  ${packetDetails[key]?.[keyToShow[1]]}  ${packetDetails[key]?.[keyToShow[2]]}`;

  //     const paragraph2 = document.createElement('p');
  //     paragraph2.style.margin = '2px 0';
  //     paragraph2.style.padding = "0 1px"
  //     paragraph2.textContent = `${packetDetails[key]?.[keyToShow[3]]} ${this.datePipe.transform(packetDetails[key]?.[keyToShow[4]], 'dd-MMM-yyyy')}`;

  //     innerDiv.appendChild(paragraph1);
  //     innerDiv.appendChild(paragraph2);

  //     containerDiv.appendChild(innerDiv);

  //     printWindow.document.body.appendChild(containerDiv);

  //     allChild.push(containerDiv);
  //   }
  //   printWindow.document.write('</body></html>');
  //   printWindow.document.close();
  //   printWindow.print();
  //   allChild.map((containerDiv:any)=>{
  //     printWindow.document.body.removeChild(containerDiv);
  //   });
  //   printWindow.close();
  // }

  //   printBarcode(data: any) {
  //     const packetDetails = data;
  //     if (!packetDetails?.length) {
  //         this._ConfigService.showToast("error", ('No data found!'));
  //         return;
  //     }
  //     const printWindow = window.open('', '_blank');
  //     printWindow.document.write('<html>');
  //     printWindow.document.write('<body style="margin: 0;">');
  //     for (const key in packetDetails) {
  //         const containerDiv = document.createElement('div');
  //         containerDiv.style.display = 'flex';
  //         containerDiv.style.alignItems = 'center'; // Center items vertically
  //         containerDiv.style.justifyContent = 'start';
  //         // containerDiv.style.marginLeft = '20px'; // Adjust margin as needed
  //         containerDiv.style.width = "100%"
  //         containerDiv.style.height = "100%"
  //         const barcodeCanvas = document.createElement('canvas');
  //         const barcodeText = packetDetails[key]?.LotNo + "#" + packetDetails[key]?.PacketLabel;
  //         barcodeCanvas.style.width = "62%";
  //         barcodeCanvas.style.height = "45px";
  //         JsBarcode(barcodeCanvas, barcodeText, {
  //             format: 'CODE128',
  //             displayValue: false
  //         });
  //         containerDiv.appendChild(barcodeCanvas);
  //         const detailsDiv = document.createElement('div');
  //         detailsDiv.style.fontFamily = 'monospace';
  //         detailsDiv.style.fontSize = '7px';
  //         // detailsDiv.style.breakAfter = 'page';
  //         detailsDiv.style.marginLeft = '2px'; // Adjust margin as needed
  //         detailsDiv.innerHTML = `
  //             <p>${packetDetails[key]?.LotNo} ${packetDetails[key]?.PacketLabel} ${packetDetails[key]?.cts}</p>
  //             <p>${packetDetails[key]?.grade} ${this.datePipe.transform(packetDetails[key]?.inDate, 'dd-MMM-yyyy')}</p>
  //         `;
  //         containerDiv.appendChild(detailsDiv);
  //         printWindow.document.body.appendChild(containerDiv);
  //     }
  //     printWindow.document.write('</body></html>');
  //     printWindow.document.close();
  //     // Wait for a short time to ensure the document is fully rendered before printing
  //     setTimeout(() => {
  //         printWindow.print();
  //         printWindow.close();
  //     }, 1000); // Adjust delay as needed
  // }

  getBarcodeToPrint(data: any) {
    this.barcodeList=[];

    const packetDetails = data;
    if (!packetDetails?.length) {
      this._ConfigService.showToast('error', 'No data found!');
      return;
    }

    let content = ``
    for (const key in packetDetails) {
      let barcode=packetDetails[key]?.LotNo + '#' + packetDetails[key]?.PacketLabel;
      let data = `

O
Q133,13
q540
S4
D15
ZT
JF


      
N
A540,110,2,2,2,1,N,"${packetDetails[key]?.LotNo}"
A540,20,1,3,1,1,N,"KGHO"
A120,110,2,3,1,1,N,"${packetDetails[key]?.PacketLabel}"
A50,110,2,2,1,1,N,"1"
B475,90,2,1,2,0,35,N,"${barcode}"
A240,48,2,3,1,1,N,"  ${packetDetails[key]?.cts}"
A330,20,2,2,1,1,N,"${packetDetails[key]?.CooString}"
A285,20,2,2,1,1,N,"${packetDetails[key]?.FmString}"
P1\n`
      content = content + data;
      
      let barcodeText1=`${packetDetails[key]?.LotNo} ${packetDetails[key]?.PacketLabel}`;
      let barcodeText2=`${packetDetails[key]?.cts} ${packetDetails[key]?.grade} ${this.datePipe.transform(packetDetails[key]?.inDate, 'dd-MMM-yyyy')}`;
      this.barcodeList.push({
        barcode: barcode,
        barcodeText1: barcodeText1,
        barcodeText2: barcodeText2
      });
    }

    var a = document.createElement('a');
    let file = new Blob([content], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download =  'BarocdeFile' + '.txt';
    a.click();
  }

  printBarcode() {
    const printContents = this.barcodeContainer.nativeElement.innerHTML;
    const popupWin = window.open('', '_blank', 'width=1000,height=1000');
    popupWin?.document.open();
    popupWin?.document.write(`
      <html>
        <head>
          <title>Print Barcode</title>
        </head>
        <style>
          body{
            margin: 0;
          }
          .show-barcode {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
          .barcode-text {
            margin-top: -5px;
          }
          .barcode-text-1 {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
          }
          .barcode-text-2 {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
          }
          .barcodePrint {
              width: 300px;
          }
          .barcode-container {
            height: 55px;
            overflow: hidden;
            display: flex;
            justify-content: center;
          }
          .barcodePrint svg{
            width: 150px;
            height: 90px;            
            position: relative;
            top: -35px;
          }
        </style>
        <body onload="window.print();window.close()">
          <div class="barcodePrint">
            ${printContents}
          <div>
        </body>
      </html>
    `);
    popupWin?.document.close();
  }

  hasCompletedRows(): boolean {
    return this.lotDataSource.some(row => row.isCompleted === 1);
  }


  async getPendingLot() {
    let endpoint: string = `rough-lot/getSubLots`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res?.statusCode == 200) {
        this.lotDataSource = (res?.data || [])?.map((dt:any) => ({
          ...dt,
          completed: dt.isCompleted==1 ? 'Completed' : 'Pending',
        }));
      }
    });
  }



  onLotSelection(event) {
    this.packetDataSource = []
    this.selectedLot = event?.row?.id;
    this.showFileGrid = true;
    this.getUploadedFileDetail(this.selectedLot);
    //this.isSelectionEnabled = false
  }

  async getUploadedFileDetail(id) {
    if (!!id) {
      const res = await this.httpService.fetchDataUsingPromise(
        true,
        `trybe-packet-inward-status/getUploadedFilesAndStatus?roughId=${id}`
      );
      if (res?.data?.length) {
        this.packetDataSource = res.data;
      } else this._ConfigService.showToast('error', 'No data found!');
    }
  }

  onRefresh(){
    this.getUploadedFileDetail(this.selectedLot);
  }


  async assignLot() {
    
    let endpoint: string = `rough-lot/markLotAsCompleted?roughId=${this.selectedLot}`;
    let response = await this.httpService.postDataUsingPromise(
      true,
      endpoint,
      null
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.refreshGrid = true;
      this.getPendingLot();
      
      this._ConfigService.showToast('success', "Lot Allocated Successfully");
    } else {
      
      this._ConfigService.showToast(
        'error',
        response.message || 'Something went wrong!'
      );
    }
  }

  validateFile(fileName: string) {
      let isValid = true;
      let errorMsg = '';

      if (!this.excelRegex.test(fileName)) {
          isValid = false;
          errorMsg = 'File must be in .xlsx, .xls';
      }
      if(errorMsg){
        this._ConfigService.showToast('error',errorMsg)
      }
      return isValid
  }
  

  async handleFileInput(event) {
    let file = event.target.files[0];
    if(!this.validateFile(file?.name)) return
    const reader = new FileReader();
    const formatData = new FormData();
    console.log(formatData);
    formatData.append("file", file);
    console.log(formatData);
    this.httpService.saveDataToDB(true, `trybe-packet-inward-status/packetInward?roughId=${this.selectedLot}`, formatData, 'Multipart').subscribe((res: any) => {
      
      if (res.statusCode == 200) {
        if (res.data) {
          this.getUploadedFileDetail(this.selectedLot)
          let temp = res.data
          if (temp.error == "error") {
            this._ConfigService.showToast('error', 'Error In Uploading File');
          } else {
            this._ConfigService.showToast('success', res.message);
          }
        }
      }
      else
        this._ConfigService.showToast('error', res.error.message || 'Something went wrong!');
    }, (err: any) => {
      
      this._ConfigService.showToast('error', err.error.message || 'Something went wrong!');
    });
  }

  uploadPacket() {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  }
  
  downloadPacket(){
    window.open(PACKETINWARD.URL,'_blank')
  }
}
