import { Injectable } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { from, throwError } from "rxjs";
import { catchError, concatMap, map, mergeMap, tap } from "rxjs/operators";
import { TrybemodalComponent } from "src/app/components/trybemodal/trybemodal.component";
import { HomeActions } from "src/app/home/state/home.action-type";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { IssueAction } from "./issue.action.type";
import { WithMeState } from "./issue.state";

@Injectable()
export class IssueEffects {
    constructor(
        private navCtrl: NavController,
        private actions: Actions,
        public configService: ConfigService,
        public httpService: HttpService,
        private store: Store<WithMeState>,
        private loaderService: LoaderService,
        private modalController: ModalController
    ) {

    }
    getUsersByAction$ = createEffect(() =>
        this.actions.pipe(
            ofType(IssueAction.getUsersByAction),
            concatMap((action) =>
                from(
                    this.httpService.getUsersAMachineByActionId(action.actionId, action.packetIDs, action.isVirtual),
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return !!res.data ? res.data : [];
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((res: any) => {
                if (!!res.error && res.error.length > 0) {
                    console.log(res.error);
                }
                // this.httpService.dismissLoader()
            }),
            map((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    console.log(data.error);
                    data = [];
                }
                return IssueAction.saveUsersByAction({ usersbyAction: data });

            })
        ),
    );

    getUsersByVirtualAction$ = createEffect(() =>
        this.actions.pipe(
            ofType(IssueAction.getUsersByVirtualAction),
            concatMap((action) =>
                from(
                    this.httpService.getUsersAMachineByVirtualActionId(action.actionId, action.packetIDs),
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return !!res.data ? res.data : [];
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((res: any) => {
                if (!!res.error && res.error.length > 0) {
                    console.log(res.error);
                }
                // this.httpService.dismissLoader()
            }),
            map((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    console.log(data.error);
                    data = [];
                }
                return IssueAction.saveUsersByVirtualAction({ usersbyVirtualAction: data });

            })
        ),
    );

    getActionsByPacket$ = createEffect(() =>
        this.actions.pipe(
            ofType(IssueAction.fetchProcessByPackets),
            concatMap((action) =>
                from(
                    this.httpService.fetchUserActionsbyStone(action.packetIDs),
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return !!res.data ? res.data : [];
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((res: any) => {
                if (!!res.error && res.error.length > 0) {
                    console.log(res.error);
                }
                // this.httpService.dismissLoader()
            }),
            map((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    console.log(data.error);
                    data = [];
                }
                return IssueAction.saveProcessByPackets({ actionsByPacket: data });

            })
        ),
    );
    getVirtualTasksActionsByPacket$ = createEffect(() =>
        this.actions.pipe(
            ofType(IssueAction.fetchNextActionForAssignVirtualTasks),
            concatMap((action) =>
                from(
                    this.httpService.fetchNextActionForVirtualTasks(action.packetIDs),
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return !!res.data ? res.data : [];
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((res: any) => {
                if (!!res.error && res.error.length > 0) {
                    console.log(res.error);
                }
                // this.httpService.dismissLoader()
            }),
            map((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    console.log(data.error);
                    data = [];
                }
                return IssueAction.saveProcessByVirtualPackets({ actionsByVirtualPacket: data });

            })
        ),
    );
    getProcessParameter$ = createEffect(() =>
        this.actions.pipe(
            ofType(IssueAction.getProcessParameter),
            concatMap((action) =>
                from(
                    this.httpService.fetchProcessNoteKeyByActionId(action.data),
                ).pipe(
                    map(res => {
                        console.log('fetchProcessNoteKeyByActionId');
                        console.log(res);
                        let d = res.data[0];
                        let resultData = [];
                        resultData.push({ id: d.id, paramName: d.paramName, paramValue: JSON.parse(d.paramValue) })
                        this.httpService.getErrorAndDisplayIt(res);
                        return !!resultData ? resultData : [];
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((res: any) => {
                if (!!res.error && res.error.length > 0) {
                    console.log(res.error);
                }
                // this.httpService.dismissLoader()
            }),
            map((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    console.log(data.error);
                    data = [];
                }
                return IssueAction.saveProcessParameter({ processnoteByActionID: data });

            })
        ),
    );
    saveActionInputparameter$ = createEffect(() =>
        this.actions.pipe(
            ofType(IssueAction.saveActionInputParameter),
            tap((res: any) => {
                console.log('loader started');
                
            }),
            concatMap((action) =>
                from(
                    this.httpService.saveDataToDB(true, 'packet/selfIssueAndInputReceive', action.payload, action.formType)
                ).pipe(
                    map((res) => {
                        console.log('saveActionInputparameter');
                        console.log(res);
                        
                        if (res.statusCode === 200) {
                            if (action.actionInfo.name === 'Planning') {
                                this.showModal(true, res.message, action);
                            } else if (action.actionInfo.name == 'Final Planning') {
                                console.log('no modal need to show');
                            }
                            else if (action.actionInfo.name == 'QC Check & Annotation') {
                                this.showModal(true, res.message, {});
                            } else {
                                // this.showModal(true, res.message, {});
                                this.configService.showToast("success", res.message);
                            }
                        } else {
                            console.log(action.barcodeObj);
                            this.showModal(false, res.message, {});
                        }
                        this.httpService.getErrorAndDisplayIt(res);
                        return action;
                    }),
                    catchError(error => {
                        
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                )),
            tap((res: any) => {
                console.log('hide loader');
                
            }),

            mergeMap((data) => {
                return [
                    HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
                    HomeActions.getActionWisePacketList({ actionId: data.actionId }),
                    HomeActions.parameterSaved({
                        parameterDetails: data.payload,
                    }),
                    // AutoReceiveAction.getScannedPacketDetails({
                    //   packetIdObj: data.barcodeObj,
                    // }),
                ]
            })
        )
    );
    async showModal(state: boolean, message: string, data: any) {
        if (state) {
            if (!!data.data && !!data.data.voucherLabel) {
                const modal = await this.modalController.create({
                    component: TrybemodalComponent,
                    componentProps: {
                        message: message,
                        data: data,
                    },
                    cssClass: 'trybe_modal_print',
                });
                modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
                return await modal.present();
            } else {
                const modal = await this.modalController.create({
                    component: TrybemodalComponent,
                    componentProps: {
                        message: message,
                        data: data,
                    },
                    cssClass: 'trybe_modal_1',
                });
                modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
                return await modal.present();
            }
        } else {
            const modal = await this.modalController.create({
                component: TrybemodalComponent,
                componentProps: {
                    message: message,
                },
                cssClass: 'trybe_modal_0',
            });
            modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
            return await modal.present();
        }
    }
    async onModalDismiss(d: any) {
        console.log('onModalDismiss.....Effects');
        console.log(d);
    }
}