import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ScannerinputComponent } from '../../scannerinput/scannerinput.component';


@Component({
  selector: 'app-packet-history',
  templateUrl: './packet-history.component.html',
  styleUrls: ['./packet-history.component.scss'],
})
export class PacketHistoryComponent implements OnInit {
  barcodeInputs;
  @Input() data: any;
  @Input() actionName: any;
  @Input() toRole: any;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  columns = [];
  refreshGrid: boolean = false
  columnsForTransferDetail = [];
  loggedinUser$: Observable<any>;
  //recStoneData$: Observable<ReceivedStones[]>;
  allMode: string;
  checkBoxesMode: string;
  @ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;
  page = "Packet History";
  mode = "Multiple";
  pageSize = 20;
  dataSource: any;
  selectedRows: any = [];
  packetsData = [];

  selectedRow: any = {};
  status: string;
  showSubmit: boolean = false;
  showRemoveButton: boolean = false;
  refreshField: boolean = false;
  resetForm: boolean = false;
  showDrawer: boolean = false;
  formJson: any;
  fromJsonInputs: any;
  packetHistoryData:any[]=[];

  constructor(
    public httpService: HttpService,
    private configService: ConfigService,
  ) {
    this.columns = [
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      // { name: "Pkt Grade", prop: "pktGrade" },
      { name: "Pkt Status", prop: "pktStatus" },
      { name: "Lot No", prop: "lotNo" },
      { name: "Status", prop: "pktStatus" },
      // { name: "PredLimit", prop: "predLimit" },
      { name: "InwardDate", prop: "inDate" },
      { name: "Loss", prop: "loss" },
      { name: "Current Action", prop: "currAction" },
      { name: "Current State", prop: "currState" },
      { name: "Current Dept.", prop: "currDept" },
    ]
    this.columnsForTransferDetail = [
      { name: "From Dept", prop: "fromDept" },
      { name: "To Dept", prop: "toDept" },  
      { name: "To Role", prop: "toRole" },  
      // { name: "Type", prop: "type" },
      { name: "Cts", prop: "cts" },
      { name: "Status", prop: "status" },      
      { name: "Voucher No", prop: "voucherLabel" },
      { name: "Actions", prop: "actionName" },
      { name: "Received By", prop: "receivedBy" },
      { name: "CreatedDate", prop: "createdDate" },
      {name :"Is Virtual", prop : "isVirtual"}
    ]
  }

  ngOnInit() { }

  barcodeDetection(barcode) {
    this.barcodeInputs = '';
    this.barcodeInputs = barcode.trim();

    let obj = {
      "packetId": this.barcodeInputs //"9697-08-MBCOMM#111" 
    }
 
    this.httpService.getPacketMovementByPacketId(obj).subscribe(res => {
      console.log(res)
      if(res.data){
        this.packetsData = res.data?.map((dt:any)=>{
          return {
            ...dt,
            loss: (dt?.issueCts && dt?.returnCts) ? 
            ((typeof dt.issueCts == 'string' ? Number(dt.issueCts) : dt.issueCts) - (typeof dt.returnCts == 'string' ? Number(dt.returnCts) : dt.returnCts)) 
            : '-',
          };
        });
        if(this.packetsData.length > 0){
         this.processPacketHistoryData(this.packetsData[0]?.packetMovementAndHistoryDto)
        }
      }
      
    }, err => {
      this.configService.showToast("error", err.message);
    })
  }

  processPacketHistoryData(data){
    this.packetHistoryData = data.map(packetMovement => {
      if(packetMovement.processActionHistoryLogDetails.length > 0){
        packetMovement['status']= packetMovement.processActionHistoryLogDetails[0]['historyStatus']
      }
      else{
        packetMovement['status']  = '-';
      }
      return packetMovement
    }) 
  }

  onDataGridSelection(event){
    const {row}= event
    this.processPacketHistoryData(row.packetMovementAndHistoryDto)
  }

  displayHistory(e) {
    console.log('-------onHistortyClick-------');
    console.log(e);
    let currentPacketId = e.row.key.id;
    this.selectedRow = e.row.key;
    this.selectedRow .id = e.row.key.packetId;
    console.log(currentPacketId);
    console.log(e.row.key.packetId);

    if (!!currentPacketId) {
      console.log('History found');
      this.showDrawer = true
      setTimeout(() => {
        this.showDrawer = false;
      }, 500)
    }
  }
  

  clearBarcode() {    
    this.scannerComponet.onResetForm();
    this.barcodeInputs = '';
    this.selectedRows = [];
    this.refreshGrid = true;
    this.packetsData = [];
    // this.isStoneSelected = false;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000)
  }

}
