import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { ENDPOINTS } from '../services/endpoint.enum';
import { ModalController } from '@ionic/angular';
import { OpinionMasterComponent } from '../opinion-master/opinion-master.component';
import { select, Store } from '@ngrx/store';
import { getCurrentAction } from '../tempDataStore/tempData.selector';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../reducers';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-opinion-generator-view',
  templateUrl: './opinion-generator-view.component.html',
  styleUrls: ['./opinion-generator-view.component.scss'],
})
export class OpinionGeneratorViewComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();
  columns:any;
  tableData:any = [];
  actionName: any;

  constructor(
    private httpService: HttpService,
    private modalController: ModalController,
    private store: Store<AppState>,
    ) {
    this.columns = [
      { name: 'Lot No', prop: 'lotNo' },
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'PredLimit', prop: 'predLimit' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'CreatedDate', prop: 'createdDate' },
    ];
  }

  ngOnInit() {
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.actionName = action?.name;
        }
      });
    this.getTableData();
  }

  async getTableData(){
    this.tableData = (await this.httpService.fetchDataUsingPromise(true, ENDPOINTS.fetchPacketWhichAreLock))?.data || [];
  }

  rowClicked(event: any){
    if(event?.row){
      this.lockModal(event.row);
    }
  }

  async lockModal(data: any) {
    const Modal = await this.modalController.create({
      component: OpinionMasterComponent,
      cssClass: 'opinion-screen-modal',
      componentProps: {
        data: { status: 'lockModal', data: data, actionName: '', viewOnly: true}
      },
    });
    Modal.onDidDismiss().then((d: any) => {});
    return await Modal.present();
  }
}
