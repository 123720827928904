import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController, ModalController, NavParams, PopoverController } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { getAllParameters } from "src/app/masterDataStore/masterData.selector";
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { ProcessService } from "src/app/services/process/process.service";
import { getCurrentAction } from "src/app/tempDataStore/tempData.selector";
import { environment } from "src/environments/environment";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import { PopoverUserComponent } from "../components/popover-user/popover-user.component";
import { authUser } from "../authStore/auth.selector";
import { ENDPOINTS, ACTIONNAMES, ROLES } from "../services/endpoint.enum";
import { saveCurrentRowData } from "../tempDataStore/tempData.action";
import { virtualAPIs } from "../components/my-virtual-task/state/my-virtual-task.data";
import { myVirtualTaskActions } from "../components/my-virtual-task/state/my-virtual-task.actions.type";
import { HttpStatusCode } from "@angular/common/http";
import { ResetordermodalComponent } from "../resetordermodal/resetordermodal.component";
@Component({
  selector: "app-opinion-master",
  templateUrl: "./opinion-master.component.html",
  styleUrls: ["./opinion-master.component.scss"],
})
export class OpinionMasterComponent implements OnInit, OnDestroy {
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter();
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Input() LockMasterData: any;

  public unsuscribes$: Subject<any> = new Subject<any>();
  receivedData;
  project: string = environment.project;
  currentActionData: any;
  userData: any;
  allMasterData: any = {};
  actionNames: any = ACTIONNAMES;
  copyButtonDisabled: boolean = false;
  rejectButtonDisabled: boolean = false;
  lockCopyButtonDisabled: boolean = false;
  isPlanCopied: boolean = false;
  dropDownRemarkData: any = {
    name: "Remark",
    placeText: "Remark Selection",
    options: [],
    selectedData: [],
    key: "remark",
    modeFlag: "single", //single/multiple
    payloadKey: "remark",
  };
  dropDownMistakeOf: any = {
    name: "mistakeOf",
    placeText: "Mistake Of",
    options: [
      // 'Stock Controller',
      // 'Manager Transfer',
      "Worker",
      "Checker",
      "Smart Recut",
      "No worker Mistake",
      "Department",
      "Process",
    ],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
    payloadKey: "mistakeOf",
  };
  dropDownUsers: any = {
    name: "Users",
    placeText: "Users",
    options: [],
    selectedData: [],
    key: "fullName",
    modeFlag: "multiple", //single/multiple
    payloadKey: "workerId",
  };
  dropDownDepartment: any = {
    name: "Department",
    placeText: "Department",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
    payloadKey: "departmentId",
  };
  dropDownProcess: any = {
    name: "Process",
    placeText: "Process",
    options: [],
    selectedData: [],
    key: "actionName",
    modeFlag: "single", //single/multiple
    payloadKey: "processId",
  };
  dropDownLabour: any = {
    name: "Labour",
    placeText: "Labour",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownAmount: any = {
    name: "Amount",
    placeText: "Amount",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  payload: any;
  paramHistoryGridData: {};
  addMistakeLogsDropDowns: any = {};
  addMistakeLogsStatus: any;
  addMistakeLogsInputs: any = {
    remark: "",
  };
  data: any;
  allPacketData: any;
  depIdOfRejectedPlan: any;
  arrayOfObjects: any = [];
  changedParamsToHighlight: any = {};
  greenParamsToHighlight: any = {};
  checkerPacketPlanId: any;
  clarityData: any;
  cutData: any;
  symmetryData: any;
  polishData: any;
  packetDetails: string;
  listOfUsersByDept: any = [];
  isCurrPlanFound: boolean = true;
  fromJsonInputs: any;
  paramSelectionList = {};
  saveButtonDisabled: boolean = false;
  approvedPlans: any = [];
  rejectedPlans: any = [];
  private unsubscribe$: Subject<any> = new Subject<any>();
  readonly ACTIONNAMES = ACTIONNAMES;
  isMistakeSelected: boolean = true;
  disableBtn: boolean = false;
  mistakeOff: string;
  constructor(
    private store: Store<AppState>,
    private navParams: NavParams,
    public router: Router,
    public processService: ProcessService,
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    public httpService: HttpService,
    private configService: ConfigService,
    private alertCtrl: AlertController,
    public popoverController: PopoverController
  ) {}

  ngOnInit() {
    this.receivedData = this.LockMasterData || this.navParams.get("data");

    this.store.pipe(select(getAllParameters)).subscribe(allMasterData => {
      this.allMasterData = allMasterData;

      if (this.receivedData?.data?.id) this.parameterDetails();
    });

    this.store
      .pipe(select(getCurrentAction))
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe(async (data: any) => {
        this.currentActionData = data;
        if (data?.actionForm) {
          let formDataObject = JSON.parse(data.actionForm);
          this.fromJsonInputs = formDataObject.inputJson;
        }
      });

    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (!!d) {
        this.userData = d;
        // this.getUsersListByDepartment();
        this.getCheckerOrSmartRecutForPacket();
      }
    });

    this.getOpnionRemark();
  }

  parameterDetails() {
    let endpoint: string = `packet-parameter-on-each-dept/${!this.receivedData?.viewOnly ? "findPacketParametersEachDeptWise" : "viewOpinionGenerator"}/${this.receivedData?.data.id}`;
    let abcd = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    this.httpService.fetchData(true, endpoint).subscribe(async (res: any) => {
      if (res?.statusCode == 200 && res?.data?.data) {
        this.arrayOfObjects = [];
        this.allPacketData = JSON.parse(JSON.stringify(res.data.data));
        this.data = {};
        // this.data=res.data.data;
        for (const key in res.data.data) {
          if ((key.includes("Checker") || key.includes("X-Ray Department")) && !this.data[key]) {
            this.data[key] = res.data.data[key];
            continue;
          }
          let isCurrentOrNewPlan = false;
          res.data.data[key]?.map((dt: any) => {
            if (dt.currPlan || dt.newPlan) isCurrentOrNewPlan = true;
          });
          if (isCurrentOrNewPlan) this.data[key] = res.data.data[key];
        }

        if (this.receivedData?.actionName === this.actionNames.mistakeEntry) {
          let isCurrPlanFound = false,
            lastKey;
          for (const key in this.data) {
            let newPlanFound = false;
            this.data[key]?.map((dt: any) => {
              if (dt.currPlan) isCurrPlanFound = true;
              if (dt.newPlan) newPlanFound = true;
            });
            if (!newPlanFound) lastKey = key;
          }
          if (!isCurrPlanFound && lastKey) this.data[lastKey] = this.data[lastKey].map((dt: any) => ({ ...dt, currPlan: 1 }));
        }

        let transformedObject: any = {};
        this.paramHistoryGridData = {};
        for (const key in this.data) {
          transformedObject = {};
          this.data[key]?.map((dt: any) => {
            if (dt.paramName == "sameBlock") return;

            if (dt.isApproved) this.approvedPlans.push(key);
            else if (dt.isRejected) this.rejectedPlans.push(key);

            if (dt.newPlan) {
              transformedObject["newPlan"] = true;
              // this.copyButtonDisabled = true;
              if (this.receivedData?.actionName === this.actionNames.mistakeEntry) {
                transformedObject["planSeq"] = "A";
                transformedObject["sameBlock"] = true;
                transformedObject["showAdd"] = true;
                this.isPlanCopied = true;
              }

              if (dt.deptId) this.depIdOfRejectedPlan = dt.deptId;
            } else if (dt.currPlan) {
              transformedObject["currPlan"] = true;
            }

            if (dt.paramName == "pricingResponse" && dt.paramValue?.length) {
              try {
                transformedObject[dt.paramName] = JSON.parse(dt.paramValue);

                if (this.project == "kg") {
                  let data = transformedObject["pricingResponse"];
                  let finalPrice = 0;
                  if (!data["MPM BASE_PD"]) {
                    data["MPM BASE_PD"] = 0;
                  }
                  if (!data["MIX PRICE"]) {
                    data["MIX PRICE"] = 0;
                  }

                  finalPrice = Math.max(data["MPM BASE_PD"], data["MIX PRICE"]);
                  if (data && finalPrice) {
                    if (!transformedObject["pricingResponse"]) transformedObject["pricingResponse"] = { ...data };
                    transformedObject["pricingResponse"]["stone_value"] = Math.round(finalPrice * transformedObject.expPol * 100) / 100;
                  }
                }
              } catch (error) {}
            } else if (
              dt.paramName != "shapeId" &&
              dt.paramName != "clarityId" &&
              dt.paramName != "cutId" &&
              dt.paramName != "symmetryId" &&
              dt.paramName != "natts" &&
              dt.paramName !== "colorId" &&
              dt.paramName !== "luster" &&
              dt.paramName !== "tinch" &&
              dt.paramName !== "fluorescenceId" &&
              dt.paramName !== "polishId"
            ) {
              transformedObject[dt.paramName] = dt.paramValue;
            } else {
              transformedObject[dt?.paramName] = dt?.masterValueName;
            }
            if (!this.changedParamsToHighlight?.[this.arrayOfObjects?.length]) this.changedParamsToHighlight[this.arrayOfObjects?.length] = [];
            if (!this.greenParamsToHighlight?.[this.arrayOfObjects?.length]) this.greenParamsToHighlight[this.arrayOfObjects?.length] = [];
            if (dt.opinionGenerated && dt.opinionGenerated != 0) {
              if (dt.opinionGenerated == 1) this.changedParamsToHighlight[this.arrayOfObjects?.length].push(dt.paramName);
              if (dt.opinionGenerated == 2) this.greenParamsToHighlight[this.arrayOfObjects?.length].push(dt.paramName);
            }
          });

          transformedObject["titleKey"] = key;
          if (!this.paramHistoryGridData?.[key]) this.paramHistoryGridData[key] = [];

          this.paramHistoryGridData[key].push(transformedObject);

          this.arrayOfObjects.push([transformedObject]);
        }

        if (this.receivedData?.actionName === this.actionNames.mistakeEntry) {
          this.isCurrPlanFound = false;
          this.arrayOfObjects.map((dt: any) => {
            if (dt?.[0]?.currPlan) {
              this.isCurrPlanFound = true;
            }
          });
        }
        this.packetCalculationsInit();
      }

      if (res?.data?.planData?.packetPlans?.length) {
        (res?.data?.planData?.packetPlans || []).map((dt: any) => {
          if (dt?.packetPlanStones?.length) {
            this.arrayOfObjects.push(
              dt.packetPlanStones.map((pps: any, index: number) => {
                const nameToKey = ["shapeId", "clarityId", "colorId", "cutId", "fluorescenceId", "natts", "labName", "polishId", "symmetryId", "tinch", "luster"];
                const optionsOfkey = {
                  shapeId: "Shape",
                  clarityId: "CLARITY",
                  colorId: "Color",
                  cutId: "CUT",
                  fluorescenceId: "Fluorescence",
                  natts: "NATTS",
                  labName: "Lab",
                  polishId: "Polish",
                  symmetryId: "Symmetry",
                  luster: "LUSTER",
                  tinch: "Tinch",
                };
                for (const key in pps) {
                  // if(key == 'labName' && (typeof pps[key] == 'string')){
                  //   pps[key] = Number(pps[key]);
                  // }
                  if (nameToKey.includes(key)) {
                    pps[key] = this.getIdByName(pps[key], this.allMasterData[optionsOfkey[key]], "id", "name");
                  }
                }
                if (pps.pricingResponse) pps.pricingResponse = JSON.parse(pps.pricingResponse);

                return {
                  ...pps,
                  planSeq: abcd[index],
                  sameBlock: true,
                  showAdd: true,
                  titleKey: this.userData?.departmentName,
                };
              })
            );
          }
        });
        this.isPlanCopied = true;
      }
    });
  }

  getUsersListByDepartment() {
    this.httpService
      .fetchData(true, `users/returnListOfUsersByDept/${this.userData.departmentId}`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.listOfUsersByDept = res.data || [];

        if (this.receivedData?.actionName === this.actionNames.lockMaster) {
          this.getLastMistakeEntry();
        }

        this.getCheckerOrSmartRecutForPacket();
      });
  }

  getCheckerOrSmartRecutForPacket() {
    this.httpService
      .fetchData(true, `packet/getCheckerOrSmartRecutForPacket?packetId=${this.receivedData?.data?.id}&isChecker=1`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.listOfUsersByDept.push(...(res.data || []).map((dt: any) => ({ ...dt, designation: "Checker" })));

        this.httpService
          .fetchData(true, `packet/getCheckerOrSmartRecutForPacket?packetId=${this.receivedData?.data?.id}&isChecker=2`)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            this.listOfUsersByDept.push(...(res.data || []).map((dt: any) => ({ ...dt, designation: "Smart Recut" })));

            if (this.receivedData?.actionName === this.actionNames.lockMaster) {
              this.getLastMistakeEntry();
            }
          });
      });
  }

  getOpnionRemark() {
    this.httpService
      .fetchData(true, "packet/getOpnionRemark")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.dropDownRemarkData.options = res.data || [];
      });
  }

  getLastMistakeEntry() {
    this.httpService
      .fetchData(true, ENDPOINTS.getLastMistakeEntry + `?packetId=${this.receivedData?.data?.id}`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res?.data?.[0]) {
          const packetEntryData = res?.data?.[0];
          this.dropDownUsers.options = [];
          if (packetEntryData?.remark) {
            let remarks = packetEntryData.remark.split("&&");
            this.dropDownRemarkData.selectedData = [this.dropDownRemarkData.options.find((rdt: any) => rdt.remark == remarks[0])];
            this.addMistakeLogsInputs["remark"] = remarks[1];
            this.disableBtn = true;
          }
          if (packetEntryData?.mistakeOf) {
            this.dropDownMistakeOf.selectedData = [packetEntryData?.mistakeOf];
            res.data.map((el: any) => {
              this.dropDownUsers.options = this.listOfUsersByDept.filter((dt: any) => dt.designation?.toLowerCase() == packetEntryData?.mistakeOf?.toLowerCase());
            });
            if (packetEntryData.mistakeOf == "No worker Mistake") {
              this.disableBtn = true;
            }
          }
          if (packetEntryData?.userId) {
            this.dropDownUsers.options.push(
              ...(res?.data || []).map((el: any) => {
                if (!this.dropDownUsers.options.find((dt: any) => dt.fullName != el.userName)) {
                  return {
                    id: el?.userId,
                    name: el?.userName,
                    fullName: el?.userName,
                  };
                }
              })
            );
            this.dropDownUsers.selectedData = res.data.map((el: any) => {
              return {
                id: el?.userId,
                name: el?.userName,
                fullName: el?.userName,
              };
            });
          }
        }
      });
  }

  async rowClick(values: any, index: number, j: number) {
    // if(!values.newPlan && (this.receivedData?.actionName !== this.actionNames.lockMaster))
    // return;

    // if(!values?.showAdd && (this.receivedData?.actionName === this.actionNames.lockMaster))
    // return;

    if (this.receivedData?.viewOnly) return;

    values = {
      ...values,
      nattsName: values.natts,
      shape: values.shapeId,
      symmetry: values.symmetryId,
      clarity: values.clarityId,
      color: values.colorId,
      fluorescence: values.fluorescenceId,
      cut: values.cutId,
      pol: values.polishId,
      tinchName: values.tinch,
      lusterName: values.luster,
      diameter: values.diameter ? values.diameter : values.lOrW,
      labName: values?.pricingResponse?.mLab,
    };

    const isPopupDisabled =
      (!values.newPlan && this.receivedData?.actionName !== this.actionNames.lockMaster && this.receivedData?.actionName !== this.actionNames.verifyRepairingAsking) ||
      (!values?.showAdd && (this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName === this.actionNames.verifyRepairingAsking));
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "planningRowParameterSelection",
      componentProps: { forPage: "planningRowParameterSelection", gridRow: values, isPopupDisabled: isPopupDisabled },
    });
    await popover.present();
    popover.onDidDismiss().then((res: any) => {
      if (res?.data?.gridRow) {
        this.arrayOfObjects[index][j] = {
          ...res.data.gridRow,
          natts: res.data.gridRow.nattsName,
          shapeId: res.data.gridRow.shape,
          symmetryId: res.data.gridRow.symmetry,
          clarityId: res.data.gridRow.clarity,
          colorId: res.data.gridRow.color,
          fluorescenceId: res.data.gridRow.fluorescence,
          cutId: res.data.gridRow.cut,
          polishId: res.data.gridRow.pol,
          tinch: res.data.gridRow.tinchName,
          luster: res.data.gridRow.lusterName,
        };
        if (res?.data?.gridRow?.labName && this.arrayOfObjects[index][j].pricingResponse) this.arrayOfObjects[index][j].pricingResponse.mLab = res?.data?.gridRow?.labName;

        this.getPricing(index, j);
      }
    });
  }

  getPricing(i: number, j: number) {
    let ratio = null;
    if (this.arrayOfObjects[i][j].shape == "ROUND" || this.arrayOfObjects[i][j].expPol < 0.18) {
      ratio = this.arrayOfObjects[i][j].diameter;
    } else {
      ratio = this.arrayOfObjects[i][j].lOrW;
    }
    
    if(this.project == 'kg')
      ratio = Math.round(this.arrayOfObjects[i].lengthMM / this.arrayOfObjects[i].widthMM * 100) / 100

    if (this.arrayOfObjects[i][j]?.depthPercentage) {
      this.arrayOfObjects[i][j].height = this.arrayOfObjects[i][j].depthPercentage;
    }
    let lab = "";
    if (this.arrayOfObjects[i][j]?.pricingResponse?.mLabName) {
      lab = this.arrayOfObjects[i][j]?.pricingResponse?.mLabName;
    } else lab = this.arrayOfObjects[i][j]?.pricingResponse?.mLab ? this.arrayOfObjects[i][j]?.pricingResponse?.mLab : this.arrayOfObjects[i][j].labName;

    const kpPricingPayload = {
      id: i + "-" + j, //
      mShape: this.arrayOfObjects[i][j]?.shape,
      mCrts: this.arrayOfObjects[i][j]?.expPol,
      mLab: lab || "",
      mColor: this.arrayOfObjects[i][j]?.color,
      mPurity: this.arrayOfObjects[i][j]?.clarity,
      mFlrc: this.arrayOfObjects[i][j]?.fluorescence || "NONE",
      mCut: this.arrayOfObjects[i][j]?.cut,
      mPol: this.arrayOfObjects[i][j]?.pol,
      mSym: this.arrayOfObjects[i][j]?.symmetry,
      mDepth: this.arrayOfObjects[i][j]?.height || this.arrayOfObjects[i][j]?.depthPercentage,
      mRatio: ratio,
      mNatts: this.arrayOfObjects[i][j]?.nattsName || "NO BLK",
      mTinch: this.arrayOfObjects[i][j]?.tinchName || "N/A",
      mMilky: this.arrayOfObjects[i][j]?.lusterName || "NORMAL",
      mTO: "N/A", //this.arrayOfObjects[i][j]?.topOpenName,
      mBO: "N/A", //this.arrayOfObjects[i][j]?.bottomOpenName
    };

    const kgPricingPayload = {
      cts: this.arrayOfObjects[i][j]?.expPol,
      Shape: this.arrayOfObjects[i][j]?.shape,
      Clarity: this.arrayOfObjects[i][j]?.clarity,
      Color: this.arrayOfObjects[i][j]?.color,
      Cut: this.arrayOfObjects[i][j]?.cut,
      Polish: this.arrayOfObjects[i][j]?.pol,
      Symmetry: this.arrayOfObjects[i][j]?.symmetry,
      Fluorescence: this.arrayOfObjects[i][j]?.fluorescence || "NONE",
      ID: i + "-" + j,
      "Pd Cat": this.arrayOfObjects[i][j]?.pdCat,
      Length: this.arrayOfObjects[i][j].lengthMM,
      Width: this.arrayOfObjects[i][j].widthMM,
      "Table Per": Math.round(this.arrayOfObjects[i][j].tableVal * 100) / 100,
      "Tot Depth": this.arrayOfObjects[i][j]?.height || this.arrayOfObjects[i][j]?.depthPercentage,
      "New Cut": "",
      L_W: ratio,
    };

    const kgPricingNewCutPayload = {
      expPol: this.arrayOfObjects[i][j]?.expPol,
      shapeId: this.arrayOfObjects[i][j]?.shapeId,
      clarityId: this.arrayOfObjects[i][j]?.clarityId,
      colorId: this.arrayOfObjects[i][j]?.colorId,
      cutId: this.arrayOfObjects[i][j]?.cutId,
      polishId: this.arrayOfObjects[i][j]?.polishId,
      symmetryId: this.arrayOfObjects[i][j]?.symmetryId,
      fluorescenceId: this.arrayOfObjects[i][j]?.fluorescenceId || 497,
      ID: this.arrayOfObjects[i][j]?.id,
      pdCatId: this.arrayOfObjects[i][j]?.pdCatId,
      tableVal: Math.round(this.arrayOfObjects[i][j].tableVal * 100) / 100,
      crAngle: this.arrayOfObjects[i][j]?.crAngle,
      height: this.arrayOfObjects[i][j]?.height || this.arrayOfObjects[i][j]?.depthPercentage,
      "New Cut": "",
      lOrW: ratio,
      girdle: this.arrayOfObjects[i][j]?.girdle,
      tinch: this.arrayOfObjects[i][j]?.tinch,
    };
    if (this.project == "kg") {
      this.httpService.getNewCutValue(kgPricingNewCutPayload).subscribe((resCut: any) => {
        if (!!resCut.data && resCut?.data?.status == 200) {
          kgPricingPayload["New Cut"] = resCut.data?.name || "";
        } else {
          kgPricingPayload["New Cut"] = "";
        }
        this.httpService.getPrincing(environment.PricingUrl, [kgPricingPayload]).subscribe((res: any) => {
          if (res?.data?.[0]?.Errors) {
            this.configService.showToast("error", res?.data?.[0]?.Errors);
            return;
          }
          let data = res.data[0];
          let finalPrice = 0;
          if (!data["MPM BASE_PD"]) {
            data["MPM BASE_PD"] = 0;
          }
          if (!data["MIX PRICE"]) {
            data["MIX PRICE"] = 0;
          }

          finalPrice = Math.max(data["MPM BASE_PD"], data["MIX PRICE"]);
          if (data && finalPrice) {
            if (!this.arrayOfObjects[i][j]["pricingResponse"]) this.arrayOfObjects[i][j]["pricingResponse"] = { ...data };
            this.arrayOfObjects[i][j]["pricingResponse"]["stone_value"] = Math.round(finalPrice * this.arrayOfObjects[i][j].expPol * 100) / 100;
            this.arrayOfObjects[i][j]["pricingResponse"]["rapoRate"] = data.RAPAPORT;
            this.arrayOfObjects[i][j]["pricingResponse"]["RAPAPORT"] = data.RAPAPORT;
            // this.updateOrderFormAfterUpdatingParam([this.arrayOfObjects[i][j]], i, j);
          } else {
            // this.updateOrderFormAfterUpdatingParam([this.arrayOfObjects[i][j]], i, j);
          }
        });
      });
    } else {
      this.httpService.getPrincing(environment.PricingUrl, [kpPricingPayload]).subscribe((res: any) => {
        if (res.length > 0) {
          let data = res[0];
          if (!this.arrayOfObjects[i][j]["pricingResponse"]) this.arrayOfObjects[i][j]["pricingResponse"] = {};
          this.arrayOfObjects[i][j]["pricingResponse"]["Lab"] = data["mLab"];
          this.arrayOfObjects[i][j]["pricingResponse"]["mLab"] = data["mLab"];
          this.arrayOfObjects[i][j]["pricingResponse"]["stone_value"] = data["stone_value"];
          this.arrayOfObjects[i][j]["pricingResponse"]["labour"] = data["labour"];
          this.arrayOfObjects[i][j]["pricingResponse"]["pointer_range"] = data["pointer_range"];
          this.arrayOfObjects[i][j]["pricingResponse"]["net_stone_value"] = data["net_stone_value"];
        }
      });
    }
  }

  async updateOrderFormAfterUpdatingParam(payload, i?: number, j?: number) {
    console.log(payload);
    console.log(i);
    let response = await this.httpService.postDataUsingPromise(true, "packetPlanStone/getOrderList", payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data = response.data;
      if (data?.length) {
        if (i > -1 && j > -1) {
          this.arrayOfObjects[i][j] = data[0];
          this.packetCalculationsInit();
        } else if (i > -1) {
          // this.arrayOfObjects[i] = data[0];
          // this.packetCalculationsInit()
        } else {
          // this.arrayOfObjects[i][j] = data;
        }
      }
    } else {
      console.error(response);
    }
  }

  async packetCalculationsInit() {
    // this.arrayOfObjects =
    this.arrayOfObjects.map(packetPlan => {
      // let packetPlanClone = { ...packetPlan };
      // packetPlanClone['totalMaxOrderValue'] = 0;
      // return {
      // ...packetPlan,
      packetPlan = packetPlan.map(plans => {
        let plansClone = { ...plans };
        plansClone["maxOrderValue"] = null;
        return plansClone;
      });
      // }
    });
    for (let packetplan of this.arrayOfObjects) {
      // if (!packetplan?.['totalMaxOrderValue'])
      // packetplan['totalMaxOrderValue'] = 0;
      for (let plans of packetplan) {
        plans["maxOrderValue"] = null;
        let maxFinalValue: any = 0;
        let isFinal = false;
        if (!!plans.orders) {
          let isFinalOrderExist = plans.orders.find(el => !!el.isMarked);
          if (!!isFinalOrderExist) {
            maxFinalValue = isFinalOrderExist.finalValue;
          } else {
            for (let order of plans.orders) {
              if (!order?.orderRemark) {
                let rap = plans.RAPAPORT > 0 ? plans.RAPAPORT : plans.rapoRate;
                let calculatedValue = this.getRate(order.rateType, plans.kgRate, rap, order.value, plans.expPol, order);
                order.finalValue = calculatedValue;
                maxFinalValue = Math.max(maxFinalValue, order.finalValue);
              }
            }
          }
        }
        plans["maxOrderValue"] = Number(maxFinalValue.toFixed(2));
        // packetplan['totalMaxOrderValue'] += (this.disaplyMaxValue(plans['maxOrderValue'], plans['kgRate']) || 0);
        // packetplan['totalKGRate'] = packetplan['totalMaxOrderValue']
      }
    }
    // this.arrayOfObjects = this.arrayOfObjects.map((dt: any) => {
    //   return {
    //     ...dt,
    //     totalMaxOrderValue: dt.packetPlanStones.map((ppsdt: any) => (this.disaplyMaxValue(ppsdt['maxOrderValue'], ppsdt['kgRate']) || 0)).reduce((accumulator, currentValue) => {
    //       return accumulator + currentValue
    //     }, 0),
    //   }
    // });
    // console.log(this.packetPlans)
  }

  getRate(rateType: string, kgRate: number, RAPAPORT: number, value: number, totalCarat: number, order: any) {
    let finalOrderValue = 1;
    switch (rateType) {
      case "Back":
        finalOrderValue = RAPAPORT - RAPAPORT * (value / 100);
        finalOrderValue = finalOrderValue * totalCarat;
        break;
      case "Piece":
        finalOrderValue = value;
        break;
      case "Percentage":
        finalOrderValue = kgRate + kgRate * ((value * -1) / 100);
        break;
      case "Rate Below":
        finalOrderValue = value;
        break;
      case "per cts rate":
        finalOrderValue = value * totalCarat;
        break;
    }
    return Number.isNaN(finalOrderValue) ? 0 : finalOrderValue;
  }

  copyPlan(values: any, index: number) {
    this.copyButtonDisabled = true;
    this.isPlanCopied = true;
    this.arrayOfObjects.push([
      {
        ...values,
        titleKey: this.userData?.departmentName,
        currPlan: null,
        newPlan: true,
        planSeq: "A",
        sameBlock: true,
      },
    ]);
  }

  addPlan(valuesArray, i) {
    let planSeq = "A",
      abcd = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (this.arrayOfObjects?.[i]?.length > 1) {
      if (this.arrayOfObjects[i][this.arrayOfObjects[i].length - 1]["planSeq"]) {
        planSeq = abcd[abcd.indexOf(this.arrayOfObjects[i][this.arrayOfObjects[i].length - 1]["planSeq"]) + 1];
      }
    } else if (valuesArray["planSeq"]) {
      planSeq = abcd[abcd.indexOf(valuesArray["planSeq"]) + 1];
    }
    this.arrayOfObjects[i].push({
      ...JSON.parse(JSON.stringify(valuesArray)),
      currPlan: null,
      newPlan: true,
      planSeq,
    });
  }

  async viewHistory() {
    let arrayOfObjects = [];
    if (this.allPacketData) {
      if (this.receivedData?.actionName === this.actionNames.mistakeEntry) {
        let isCurrPlanFound = false,
          lastKey;
        for (const key in this.allPacketData) {
          let newPlanFound = false;
          this.allPacketData[key]?.map((dt: any) => {
            if (dt.currPlan) isCurrPlanFound = true;
            if (dt.newPlan) newPlanFound = true;
          });
          if (!newPlanFound) lastKey = key;
        }
        if (!isCurrPlanFound && lastKey) this.allPacketData[lastKey] = this.allPacketData[lastKey].map((dt: any) => ({ ...dt, currPlan: 1 }));
      }

      let transformedObject: any = {};
      for (const key in this.allPacketData) {
        transformedObject = {};
        this.allPacketData[key]?.map((dt: any) => {
          if (dt.paramName == "sameBlock") return;

          if (dt.newPlan) {
            transformedObject["newPlan"] = true;
            // this.copyButtonDisabled = true;
            if (this.receivedData?.actionName === this.actionNames.mistakeEntry) {
              transformedObject["planSeq"] = "A";
              transformedObject["sameBlock"] = true;
              this.isPlanCopied = true;
            }

            if (dt.deptId) this.depIdOfRejectedPlan = dt.deptId;
          } else if (dt.currPlan) {
            transformedObject["currPlan"] = true;
          }

          if (dt.paramName == "pricingResponse" && dt.paramValue?.length) {
            try {
              transformedObject[dt.paramName] = JSON.parse(dt.paramValue);

              if (this.project == "kg") {
                let data = transformedObject["pricingResponse"];
                let finalPrice = 0;
                if (!data["MPM BASE_PD"]) {
                  data["MPM BASE_PD"] = 0;
                }
                if (!data["MIX PRICE"]) {
                  data["MIX PRICE"] = 0;
                }

                finalPrice = Math.max(data["MPM BASE_PD"], data["MIX PRICE"]);
                if (data && finalPrice) {
                  if (!transformedObject["pricingResponse"]) transformedObject["pricingResponse"] = { ...data };
                  transformedObject["pricingResponse"]["stone_value"] = Math.round(finalPrice * transformedObject.expPol * 100) / 100;
                }
              }
            } catch (error) {}
          } else if (
            dt.paramName != "shapeId" &&
            dt.paramName != "clarityId" &&
            dt.paramName != "cutId" &&
            dt.paramName != "symmetryId" &&
            dt.paramName != "natts" &&
            dt.paramName !== "colorId" &&
            dt.paramName !== "luster" &&
            dt.paramName !== "tinch" &&
            dt.paramName !== "fluorescenceId" &&
            dt.paramName !== "polishId"
          ) {
            transformedObject[dt.paramName] = dt.paramValue;
          } else {
            transformedObject[dt?.paramName] = dt?.masterValueName;
          }
        });
        transformedObject["titleKey"] = key;
        arrayOfObjects.push([transformedObject]);
      }
    }

    const Modal = await this.modalController.create({
      component: OpinionMasterComponent,
      cssClass: "opinion-screen-modal",
      componentProps: {
        data: { isViewHistory: true, viewHistoryData: arrayOfObjects },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  async viewWorkerHistory() {
    const Modal = await this.modalController.create({
      component: OpinionMasterComponent,
      cssClass: "qcAnnotation",
      componentProps: {
        data: { isWorkerHistory: true },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  close() {
    this.modalController.dismiss();
  }

  reject(values: any) {
    if (!this.rejectButtonDisabled) {
      this.rejectButtonDisabled = true;
      this.isPlanCopied = true;
      if (!this.lockCopyButtonDisabled) {
        this.arrayOfObjects.push([
          {
            ...JSON.parse(JSON.stringify(values)),
            currPlan: null,
            newPlan: null,
            titleKey: this.userData?.departmentName,
            showAdd: true,
            planSeq: "A",
            sameBlock: true,
          },
        ]);
        this.lockCopyButtonDisabled = true;
      }
    }
  }
  confirm() {
    this.isPlanCopied = false;
    this.rejectButtonDisabled = false;
    this.arrayOfObjects = this.arrayOfObjects.filter((dt: any) => !dt?.[0]?.showAdd);
    this.lockCopyButtonDisabled = false;
  }

  copyLockPlan(values: any) {
    this.lockCopyButtonDisabled = true;
    this.isPlanCopied = true;
    this.arrayOfObjects.push([
      {
        ...JSON.parse(JSON.stringify(values)),
        currPlan: null,
        newPlan: null,
        titleKey: this.userData?.departmentName,
        showAdd: true,
        planSeq: "A",
        sameBlock: true,
      },
    ]);
  }

  async deleteRow(array: any, index: number, parentIndex: number) {
    const alert = await this.alertCtrl.create({
      header: "Are you sure you want to delete plan row?",
      cssClass: "my-custom-class",
      buttons: [
        {
          text: "NO",
          handler: (d: any) => {
            // console.log('Closed', d);
          },
        },
        {
          text: "YES",
          handler: (d: any) => {
            if (array.length > 1) array.splice(index, 1);
            // else if(this.receivedData?.actionName == this.actionNames.lockMaster)
            // this.arrayOfObjects.splice(parentIndex, 1);
          },
        },
      ],
    });
    return await alert.present();
  }

  onChkChange(event: any, i: number) {
    if (!event?.target?.checked) {
      this.checkerPacketPlanId = this.arrayOfObjects?.[i]?.[0]?.packetPlanId;
    }
  }

  async onResetOrder(indexofChild: number, indexOfPlan?: number) {
    let isActiveFound = false;
    if (this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["appliedOrders"]?.length) {
      this.arrayOfObjects[indexOfPlan][indexofChild]["ordersList"] = [
        ...this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["appliedOrders"].map((dt: any) => ({ ...dt, isActive: dt.remark ? false : true, orderRemark: dt.remark })),
        ...(this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"] || []),
      ];
      isActiveFound = true;
    } else {
      (this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"] || []).map((dt: any) => {
        if (dt.isActive && !dt.orderRemark && !isActiveFound) {
          isActiveFound = true;
        }
      });
    }
    if (!isActiveFound) {
      let highestValue = 0,
        highestValueId;
      (this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"] || []).map((dt: any) => {
        if (!dt?.orderRemark && highestValue < dt.finalValue) {
          highestValue = dt.finalValue;
          highestValueId = dt.id;
        }
      });
      (this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"] || []).map((dt: any, i: number) => {
        if (!dt?.orderRemark && highestValueId == dt.id) {
          this.arrayOfObjects[indexOfPlan][indexofChild]["ordersList"][i]["isActive"] = true;
        } else {
          this.arrayOfObjects[indexOfPlan][indexofChild]["ordersList"][i]["isActive"] = false;
        }
      });
    }

    const resetOrder = await this.modalController.create({
      component: ResetordermodalComponent,
      cssClass: "restorePage",
      componentProps: {
        data: { orderDetails: this.arrayOfObjects[indexOfPlan][indexofChild], packetId: this.receivedData?.data?.id, showIsActive: true },
      },
    });

    resetOrder.onDidDismiss().then((d: any) => {
      if (d.data) {
        if (d.data?.dataSource) {
          this.arrayOfObjects[indexOfPlan][indexofChild]["ordersList"] = d.data.dataSource.map((dt: any) => {
            let odt = (this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"] || []).find((oodt: any) => oodt.id == dt.id);
            return {
              ...(odt || {}),
              ...dt,
              orderRemark: dt.orderRemark?.name || "",
            };
          });
          //add updated orders
        }
        let highestValue = 0,
          isActiveFound = false;
        this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"].map((dt: any) => {
          if (dt.isActive && !dt.orderRemark && !isActiveFound) {
            highestValue = dt.finalValue;
            isActiveFound = true;
          }
        });
        if (!isActiveFound)
          (this.arrayOfObjects?.[indexOfPlan]?.[indexofChild]?.["ordersList"] || []).map((dt: any) => {
            if (!dt?.orderRemark && highestValue < dt.finalValue) {
              highestValue = dt.finalValue;
            }
          });
        this.arrayOfObjects[indexOfPlan][indexofChild]["maxOrderValue"] = highestValue;

        this.packetCalculationsInit();
        // this.PDValueSubmission(id)
      }
    });
    return await resetOrder.present();
  }

  async saveForApprovalLock(isConfirm) {
    const parameter = [];
    let newPlan;

    newPlan = this.arrayOfObjects.find((dt: any) => dt?.[0]?.newPlan)?.[0];
    if (!newPlan) return;

    const nameToKey = ["shapeId", "clarityId", "colorId", "cutId", "fluorescenceId", "natts", "labName", "polishId", "symmetryId", "tinch", "luster"];

    const optionsOfkey = {
      shapeId: "Shape",
      clarityId: "CLARITY",
      colorId: "Color",
      cutId: "CUT",
      fluorescenceId: "Fluorescence",
      natts: "NATTS",
      labName: "Lab",
      polishId: "Polish",
      symmetryId: "Symmetry",
      luster: "LUSTER",
      tinch: "Tinch",
    };

    for (const key in newPlan || {}) {
      if (nameToKey.includes(key)) {
        parameter.push({
          paramName: key,
          paramValue: this.getIdByName(newPlan[key], this.allMasterData[optionsOfkey[key]], "name"),
        });
      } else {
        if (key == "pricingResponse")
          parameter.push({
            paramName: key,
            paramValue: JSON.stringify(newPlan[key]),
          });
        else if (key == "lOrW" && newPlan["diameter"] && newPlan[key] == 1)
          parameter.push({
            paramName: key,
            paramValue: newPlan["diameter"],
          });
        else
          parameter.push({
            paramName: key,
            paramValue: newPlan[key],
          });
      }
    }

    let payload = {
      packetId: this.receivedData?.data?.id,
      actionId: this.receivedData?.data?.virtualAction,
      newPlan: !isConfirm ? 1 : 0,
      mistakeOf: this.addMistakeLogsDropDowns["mistakeOf"],
      parameter,
    };

    let response = await this.httpService.postDataUsingPromise(true, "packet-parameter-on-each-dept/saveFinalParametersAfterLock", payload);
    this.httpService.getErrorAndDisplayIt(response);
    
    this.saveButtonDisabled = false;
    if (response.statusCode === 200) {
      this.configService.showToast("success", response?.message || "Saved successfully!");
      this.close();
    } else {
      this.configService.showToast("error", response?.error?.message || response?.message || "Something went wrong!");
    }
  }

  async save(isConfirm?: boolean) {
    if (this.receivedData?.actionName == this.actionNames.finalApprovalOnLock) {
      this.saveForApprovalLock(isConfirm);
      return;
    }

    if (
      !(this.addMistakeLogsStatus == 0 || this.addMistakeLogsStatus == 1) &&
      (this.receivedData?.actionName == this.actionNames.lockMaster ||
        this.receivedData?.actionName == this.actionNames.packetReparing ||
        this.receivedData?.actionName == this.actionNames.verifyRepairingAsking)
    ) {
      this.configService.showToast("error", "Please confirm or reject plan first!");
      return;
    }
    this.saveButtonDisabled = true;
    if (this.receivedData?.actionName == this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) {
      await this.inputReceiveByActionId(); // will be called every time on lock master
      if(this.receivedData?.actionName == this.actionNames.lockMaster) {
        // await this.saveOnMistakeEntry(isConfirm);
      }
    } else if (this.receivedData?.actionName == this.actionNames.verifyRepairingAsking) {
      await this.inputReceiveByActionIdForPhyscial();
    }
    if (!!isConfirm) {
      this.httpService.fetchData(true, `${ENDPOINTS.assignNextTaskForApprovalOnLock}?isConfirm=1&packetId=${this.receivedData?.data?.id}`).pipe(takeUntil(this.unsubscribe$)).subscribe();
      
      if (this.receivedData?.actionName == this.actionNames.verifyRepairingAsking) this.modalController.dismiss();
    }
    if (
      this.receivedData?.actionName == this.actionNames.mistakeEntry ||
       this.receivedData?.actionName == this.actionNames.verifyRepairingAsking) {
      await this.saveOnMistakeEntry(isConfirm);
      this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
      
      return;
    } else if (
      this.receivedData?.actionName == this.actionNames.lockMaster ||
      this.receivedData?.actionName == this.actionNames.packetReparing ||
      // &&
      // ((this.userData?.listOfRoles||[])?.map((dt:any)=>dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles||[])?.includes(ROLES.checker))
      this.receivedData?.actionName == this.actionNames.mistakeEntry
    ) {
      if (this.addMistakeLogsStatus == 0 || this.addMistakeLogsStatus == 1) {
        if(this.receivedData?.actionName == this.actionNames.lockMaster) 
        await this.saveOnMistakeEntry(isConfirm);

        if (this.addMistakeLogsStatus == 1) {
          if(! (this.receivedData?.actionName == this.actionNames.lockMaster)) 
            await this.saveOnMistakeEntry(isConfirm);
          // this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
          if (
            (this.receivedData?.actionName == this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) &&
            ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker))
          ) {
            this.showAdminApprovalModal();
          }
          this.onFormSubmit.emit(this.receivedData.data);
          
          return;
        } else {
          let isMultiTag = false;
          let sameBlockLength = this.arrayOfObjects.filter((dt: any) => {
            if (dt?.length > 1) isMultiTag = true;
            return dt?.[0]?.sameBlock;
          })?.length;
          if (sameBlockLength == 1 && !isMultiTag) {
            if(! (this.receivedData?.actionName == this.actionNames.lockMaster)) 
              await this.saveOnMistakeEntry();
            // this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
            if (
              (this.receivedData?.actionName == this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) &&
              ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker))
            ) {
              this.showAdminApprovalModal();
            }
            
            return;
          }
        }
      } else {
        this.configService.showToast("error", "Please confirm or reject plan first!");
        
        return;
      }
    }

    const savePayload = [];
    let payloadIndex = -1;
    this.arrayOfObjects.map((dt: any, aoindex: any) => {
      if (dt?.[0]?.sameBlock) {
        payloadIndex++;
        if (!savePayload?.[payloadIndex])
          savePayload[payloadIndex] = {
            packetPlanStones: [],
            packetId: this.receivedData?.data?.id,
            tagValue: aoindex,
          };
        let netStoneValues = 0,
          bidAmt = 0;
        dt.map((values: any, vindex: number) => {
          values = {
            ...values,
            nattsName: values.natts,
            shape: values.shapeId,
            symmetry: values.symmetryId,
            clarity: values.clarityId,
            color: values.colorId,
            fluorescence: values.fluorescenceId || values.fluorescenceId,
            flourescence: values.flourescenceId || values.fluorescenceId,
            cut: values.cutId,
            pol: values.polishId,
            tinchName: values.tinch,
            lusterName: values.luster,
            diameter: values.diameter ? values.diameter : values.lOrW,
            labName: values?.pricingResponse?.mLab,
          };
          if (!values?.topOpenName) {
            values["topOpenName"] = "N/A";
            values["topOpen"] = this.getIdByName(values["topOpenName"], this.allMasterData["TOP OPEN"], "name");
          }
          if (!values?.bottomOpenName) {
            values["bottomOpenName"] = "N/A";
            values["bottomOpen"] = this.getIdByName(values["bottomOpenName"], this.allMasterData["BOTTOM OPEN"], "name");
          }
          for (const key in values || {}) {
            const nameToKey = ["shapeId", "clarityId", "colorId", "cutId", "fluorescenceId", "natts", "labName", "polishId", "symmetryId", "tinch", "luster"];
            const optionsOfkey = {
              shapeId: "Shape",
              clarityId: "CLARITY",
              colorId: "Color",
              cutId: "CUT",
              fluorescenceId: "Fluorescence",
              flourescenceId: "Fluorescence",
              natts: "NATTS",
              labName: "Lab",
              polishId: "Polish",
              symmetryId: "Symmetry",
              luster: "LUSTER",
              tinch: "Tinch",
            };
            if (nameToKey.includes(key)) {
              values[key] = this.getIdByName(values[key], this.allMasterData[optionsOfkey[key]], "name");
            }
          }
          if (!values?.flourescenceId && values?.fluorescenceId) {
            values.flourescenceId = values.fluorescenceId;
          }
          if (values?.flourescenceId && !values?.fluorescenceId) {
            values.fluorescenceId = values.flourescenceId;
          }
          netStoneValues += values?.pricingResponse?.stone_value || 0;
          bidAmt += values?.pricingResponse?.stone_value || 0;
          values.pricingResponse = JSON.stringify({ ...values?.pricingResponse, id: vindex + "-" + aoindex });
          savePayload[payloadIndex]["packetPlanStones"].push(values);
        });
        savePayload[payloadIndex]["netStoneValues"] = netStoneValues;
        savePayload[payloadIndex]["bidAmt"] = bidAmt;
      }
    });

    if (
      (this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) &&
      ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.adminApproval) || (this.userData?.roles || [])?.includes(ROLES.adminApproval)) &&
      this.checkerPacketPlanId
    ) {
      this.httpService.fetchData(true, `packetPlan/updatePlanGeneratedOnOpinion?packetPlanId=${this.checkerPacketPlanId}&packetId=${this.receivedData?.data?.id}`).subscribe((res: any) => {}); // admin jab select karega tabhi call karni h
    }

    if (!(this.addMistakeLogsStatus == 1)) {
      const res = await this.httpService.postDataUsingPromise(true, "packetPlan/addNewPlansForOpninion", savePayload); // if admin and checker add more than one plan
      if (res.statusCode == 200) {
        this.close();
        this.configService.showToast("success", res.message || "Saved successfully!");
      } else {
        this.configService.showToast("error", res?.error?.message || res?.message || "Something went wrong!");
      }
    }
    
    this.saveButtonDisabled = false;

    if ((this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) && this.addMistakeLogsStatus == 0) {
      this.httpService.fetchDataUsingPromise(true, `${ENDPOINTS.isRejectedStatus}?packetId=${this.receivedData?.data?.id}&deptId=${this.depIdOfRejectedPlan}`); // will be called every time when gets rejected on lock master

      if (!((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.adminApproval) || (this.userData?.roles || [])?.includes(ROLES.adminApproval))) {
        // await this.httpService.fetchDataUsingPromise(
        //   true,
        //   ENDPOINTS.generateTaskForReject + this.receivedData?.data?.id
        // );
      }
    }
    this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
  }

  async showAdminApprovalModal() {
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: "holdPacket",
      componentProps: {
        data: { status: "lockPacket", data: "Packet went for admin approval" },
      },
    });
    Modal.onDidDismiss().then((d: any) => {});
    return await Modal.present();
  }

  async saveOnMistakeEntry(isConfirm?: boolean) {
    if (
      isConfirm &&
      ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) ||
        (this.userData?.roles || [])?.includes(ROLES.checker) ||
        (this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.adminApproval) ||
        (this.userData?.roles || [])?.includes(ROLES.adminApproval))
    ) {
      // this.httpService.fetchData(true, `${ENDPOINTS.assignNextTaskForApprovalOnLock}?packetId=${this.receivedData?.data?.id}`).subscribe();
      // return;
    }

    let newPlan;

    if (this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) {
      if (isConfirm) {
        newPlan = this.arrayOfObjects.find((dt: any) => dt?.[0]?.newPlan)?.[0];
        if (!newPlan) return;
      } else {
        newPlan = this.arrayOfObjects.find((dt: any) => dt?.[0]?.showAdd)?.[0];
        if (!newPlan) return;
      }
    } else {
      newPlan = this.arrayOfObjects.find((dt: any) => dt?.[0]?.newPlan)?.[0];
      if (!newPlan) return;
    }

    if (
      (this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) &&
      (this.rejectButtonDisabled ||
        (!this.rejectButtonDisabled && ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker))))
    ) {
      // await this.httpService.fetchDataUsingPromise(
      //   true,
      //   ENDPOINTS.generateTaskForReject + this.receivedData?.data?.id
      // );
    }

    // if (
    //   (this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) &&
    //   !this.rejectButtonDisabled &&
    //   ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker))
    // ) {
      
    //   this.saveButtonDisabled = false;
    //   this.close();
    //   return;
    // }
    if (this.receivedData?.actionName !== this.actionNames.verifyRepairingAsking) {
      const parameter = [];
      for (const key in newPlan || {}) {
        if (newPlan[key] == null || newPlan[key] == undefined) continue;

        const keysToPass = [
          "cts",
          "shapeId",
          "clarityId",
          "cutId",
          "pavAngle",
          "pavHeight",
          "crAngle",
          "crHeight",
          "tableVal",
          "girdle",
          "diameter",
          "height",
          "lOrW",
          "sawMic",
          "lengthMM",
          "heightMM",
          "widthMM",
          "symmetryId",
          "sawRghWgt",
          "yield",
          "natts",
          "polishId",
          "nattsName",
          "shape",
          "symmetry",
          "clarity",
          "color",
          "fluorescence",
          "cut",
          "pol",
          "tinchName",
          "lusterName",
          "labName",
          "expPol",
          "colorId",
          "fluorescenceId",
          "tinch",
          "luster",
          "depthPercentage",
          "pricingResponse",
        ];

        if (!keysToPass?.includes(key)) continue;

        const nameToKey = ["shapeId", "clarityId", "colorId", "cutId", "fluorescenceId", "natts", "labName", "polishId", "symmetryId", "tinch", "luster"];
        const optionsOfkey = {
          shapeId: "Shape",
          clarityId: "CLARITY",
          colorId: "Color",
          cutId: "CUT",
          fluorescenceId: "Fluorescence",
          natts: "NATTS",
          labName: "Lab",
          polishId: "Polish",
          symmetryId: "Symmetry",
          luster: "LUSTER",
          tinch: "Tinch",
        };
        if (nameToKey.includes(key)) {
          parameter.push({
            paramName: key,
            paramValue: this.getIdByName(newPlan[key], this.allMasterData[optionsOfkey[key]], "name"),
          });
        } else {
          if (key == "pricingResponse")
            parameter.push({
              paramName: key,
              paramValue: JSON.stringify(newPlan[key]),
            });
          else if (key == "lOrW" && newPlan["diameter"] && newPlan[key] == 1)
            parameter.push({
              paramName: key,
              paramValue: newPlan["diameter"],
            });
          else if (key !== "newPlan")
            parameter.push({
              paramName: key,
              paramValue: newPlan[key],
            });
        }
      }
      let payload = {
        packetId: this.receivedData?.data?.id,
        actionId: this.receivedData?.data?.virtualAction,
        // "newPlan": (this.receivedData?.actionName === this.actionNames.lockMaster) ? 0 : 1,
        newPlan: 1,
        mistakeOf: this.addMistakeLogsDropDowns["mistakeOf"],
        parameter,
      };

      if (
        ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker)) &&
        this.receivedData?.actionName === this.actionNames.lockMaster
      ) {
        payload["newPlan"] = 1;
      }
      let response;
      // if (!isConfirm) {
      response = await this.httpService.postDataUsingPromise(true, "packet-parameter-on-each-dept/savePacketParameter", payload);
      this.httpService.getErrorAndDisplayIt(response);
      // }
    
      this.saveButtonDisabled = false;
      if (response?.statusCode === 200) {
        // this.httpService.fetchDataUsingPromise(true, `packet/assignNextTaskForApprovalOnLock?packetId=${this.receivedData?.data?.id}`);
        this.close();
        if (this.receivedData?.actionName !== this.actionNames.verifyRepairingAsking) {
          if (((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker)) && isConfirm) {
            const payloadTopass = {
              workerId: this.addMistakeLogsDropDowns.workerId, // checker ka userId
              // "amount" : Number(this.addMistakeLogsDropDowns.amount ? this.addMistakeLogsDropDowns.amount : ''),
              remark: (this.addMistakeLogsDropDowns.remark ? this.addMistakeLogsDropDowns.remark : this.dropDownRemarkData?.selectedData?.[0]?.remark ? this.dropDownRemarkData?.selectedData?.[0]?.remark : this.dropDownRemarkData?.selectedData?.[0]?.id ? this.dropDownRemarkData?.selectedData?.[0]?.id : this.dropDownRemarkData?.selectedData?.[0]) + "&&" + this.addMistakeLogsInputs.remark,
              packetId: this.receivedData?.data?.id,
              mistakeOf: this.userData.fullName, // checker ka name
              parameter,
            };
            this.addMistakeEntry(parameter, payloadTopass, true);
          } else {
            this.addMistakeEntry(parameter);
          }
        }
        let data = response?.message;
        this.configService.showToast("success", data || "Saved successfully!");
      } else if (!response) {
        if (this.receivedData?.actionName !== this.actionNames.verifyRepairingAsking) {
          const payloadTopass = {
            workerId: this.addMistakeLogsDropDowns.workerId, // checker ka userId
            // "amount" : Number(this.addMistakeLogsDropDowns.amount ? this.addMistakeLogsDropDowns.amount : ''),
            remark: (this.addMistakeLogsDropDowns.remark ? this.addMistakeLogsDropDowns.remark : this.dropDownRemarkData?.selectedData?.[0]?.remark ? this.dropDownRemarkData?.selectedData?.[0]?.remark : this.dropDownRemarkData?.selectedData?.[0]?.id ? this.dropDownRemarkData?.selectedData?.[0]?.id : this.dropDownRemarkData?.selectedData?.[0]) + "&&" + this.addMistakeLogsInputs.remark,
            packetId: this.receivedData?.data?.id,
            mistakeOf: this.userData.fullName, // checker ka name
            parameter,
          };
          this.addMistakeEntry(parameter, payloadTopass, true);
        }
        this.close();
      } else {
        console.error(response);
        this.configService.showToast("error", response?.error?.message || response?.message || "Something went wrong!");
      }
    }
  }

  async inputReceiveByActionId() {
    const payload = {
      isTensionVirtual: 1,
      listOfAllPackets: [
        {
          packetId: this.receivedData?.data?.id,
          listOfParameter: [
            {
              parameterName: "Approval",
              parameterValue: "Done",
            },
          ],
        },
      ],
    };
    await this.httpService.postDataUsingPromise(true, `${ENDPOINTS.inputReceiverByActionId}/${this.receivedData?.data?.virtualAction}`, payload);
  }

  async inputReceiveByActionIdForPhyscial() {
    const payload = {
      isTensionVirtual: null,
      listOfAllPackets: [
        {
          packetId: this.receivedData?.data?.id,
          listOfParameter: [
            {
              parameterName: "Approval",
              parameterValue: "Done",
            },
          ],
        },
      ],
    };
    await this.httpService.postDataUsingPromise(true, `${ENDPOINTS.inputReceiverByActionId}/${this.receivedData?.data?.currAction}`, payload);
  }

  async addMistakeEntry(parameter: any, passedPayload?: any, callBoth?: boolean) {
    // if (
    //   ((this.userData?.listOfRoles || [])?.map((dt: any) => dt.roleName)?.includes(ROLES.checker) || (this.userData?.roles || [])?.includes(ROLES.checker)) &&
    //   this.receivedData?.actionName === this.actionNames.lockMaster
    // )
    //   return;

    let payload;
    if (passedPayload) {
      payload = passedPayload;
    } else {
      payload = {
        workerId: this.addMistakeLogsDropDowns.workerId, // checker ka userId
        // "amount" : Number(this.addMistakeLogsDropDowns.amount ? this.addMistakeLogsDropDowns.amount : ''),
        remark: (this.addMistakeLogsDropDowns.remark ? this.addMistakeLogsDropDowns.remark : this.dropDownRemarkData?.selectedData?.[0]?.remark ? this.dropDownRemarkData?.selectedData?.[0]?.remark : this.dropDownRemarkData?.selectedData?.[0]?.id ? this.dropDownRemarkData?.selectedData?.[0]?.id : this.dropDownRemarkData?.selectedData?.[0]) + "&&" + this.addMistakeLogsInputs.remark,
        packetId: this.receivedData?.data?.id,
        mistakeOf: this.addMistakeLogsDropDowns.mistakeOf, // checker ka name
        parameter,
      };
    }

    if (this.receivedData?.actionName === this.actionNames.lockMaster || this.receivedData?.actionName == this.actionNames.packetReparing) payload["status"] = this.addMistakeLogsStatus; // 1 for approved 0 for reject

    if (callBoth) {
      await this.httpService.postDataUsingPromise(true, ENDPOINTS["addMistakeLogs"], payload);
      await this.httpService.postDataUsingPromise(true, ENDPOINTS["addMistakeEntryOnLock"], payload);
    } else {
      await this.httpService.postDataUsingPromise(true, ENDPOINTS[this.receivedData?.actionName === this.actionNames.mistakeEntry ? "addMistakeEntryOnLock" : "addMistakeLogs"], payload);
    }
  }

  getIdByName(name: any, array: any, key: any, id?: any) {
    if (name) return array?.find((dt: any) => dt[key] == name)?.[id ? id : "id"];
    return null;
  }

  isDepartmentFetched: boolean = false;
  fetchTrybeDepartments() {
    if (!this.isDepartmentFetched) {
      // this.httpService.showLoader();
      this.httpService.fetchTrybeDepartments([this.receivedData.data.id]).subscribe(
        (res: any) => {
          this.dropDownDepartment.options = res?.data || [];
          this.httpService.dismissLoader();
        },
        (e: any) => {}
      );
      this.isDepartmentFetched = true;
    }
  }

  isProcessFetched: boolean = false;
  async fetchProcessOnBasisOfPacketId() {
    if (!this.isProcessFetched) {
      // this.httpService.showLoader();
      const res = await this.httpService.fetchDataUsingPromise(true, `packet/getProcessAndItsWorkerOnBasisOfPacketId?packetId=${this.receivedData.data.id}`);
      // this.httpService.dismissLoader();
      // dropDownDepartment = {
      //   name: 'Department',
      //   placeText: 'Department',
      //   options: [],
      //   selectedData: [],
      //   key: 'name',
      //   modeFlag: 'single', //single/multiple
      //   payloadKey: 'departmentId',
      // };
      this.dropDownProcess.options = res?.data || [];
      // this.dropDownDepartment.key = 'actionName';
      // this.dropDownDepartment.name = 'actionName';
      this.isProcessFetched = true;
    }
  }

  deptWiseStockController: any = {};
  async fetchStockControllerOnBasisOfDept() {
    this.dropDownUsers.options = [];

    if (!this.deptWiseStockController[this.addMistakeLogsDropDowns.departmentId]) {
      const res = await this.httpService.fetchDataUsingPromise(true, `packet/getStockControllerOnBasisOfDept?deptId=${this.addMistakeLogsDropDowns.departmentId}`);
      this.deptWiseStockController[this.addMistakeLogsDropDowns.departmentId] = res?.data || [];
      this.dropDownUsers.options = res?.data || [];
    } else {
      this.dropDownUsers.options = this.deptWiseStockController[this.addMistakeLogsDropDowns.departmentId];
    }
  }

  async dropDownSelection(evt: any, dropDown: any) {
    if (evt.property == "mistakeOf" && evt?.arrayList?.[0]) {
      this.mistakeOff = evt?.arrayList?.[0];
      if (this.mistakeOff == "Department") {
        this.fetchTrybeDepartments();
      } else if (this.mistakeOff == "Process") {
        this.fetchProcessOnBasisOfPacketId();
      }
      this.disableBtn = this.mistakeOff != "No worker Mistake" ? false : true;
      if (evt?.arrayList?.[0] == "Smart Recut" || evt?.arrayList?.[0] == "Checker") {
        this.dropDownUsers.options = this.listOfUsersByDept.filter((dt: any) => dt.designation?.toLowerCase() == evt?.arrayList?.[0]?.toLowerCase());
      } else {
        const payload = {
          deptId: this.userData.departmentId,
          designation: evt?.arrayList?.[0]?.toLowerCase() == "manager transfer" ? "manager" : evt?.arrayList?.[0]?.toLowerCase(),
        };
        const res = await this.httpService.postDataUsingPromise(true, "users/returnListOfUsersByDeptandDesignation", payload);
        console.log(res);
        if (res?.data) {
          this.dropDownUsers.options = res.data;
        } else {
          this.dropDownUsers.options = [];
        }
        // this.httpService.dismissLoader();
      }
    }
      else if (evt.property == "mistakeOf" && !evt?.arrayList?.[0]) {
        this.dropDownDepartment.selectedData = [];
        // this.dropDownDepartment.options = [];
        this.mistakeOff = '';
        this.dropDownUsers.selectedData = [];
        this.dropDownUsers.options = [];
      }

    if (evt.property == "shape") {
      this.setValuesOfDropdown(evt);
    }
    if (evt.property == "Users") {
      this.addMistakeLogsDropDowns[this[dropDown]["payloadKey"]] = (evt?.arrayList || [])?.map((dt: any) => dt.id);
    } else {
      const remark = evt?.arrayList?.[0]?.remark;
      const id = evt?.arrayList?.[0]?.id;
      console.log(this.mistakeOff != "No worker Mistake");
      if (this.mistakeOff != "No worker Mistake") this.disableBtn = remark !== null && remark !== undefined && remark !== "" && id !== null && id !== undefined && id !== "";
      this.addMistakeLogsDropDowns[this[dropDown]["payloadKey"]] = evt?.arrayList?.[0]?.remark ? evt?.arrayList?.[0]?.remark : evt?.arrayList?.[0]?.id ? evt?.arrayList?.[0]?.id : evt?.arrayList?.[0];
    }

    
    if(this.mistakeOff.toLowerCase() == 'worker'){
      if(!this.addMistakeLogsDropDowns[this['dropDownUsers']["payloadKey"]]?.length || !this.addMistakeLogsDropDowns[this['dropDownRemarkData']["payloadKey"]]){
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    }

    if (evt.property == "Department" && evt?.arrayList?.[0]) {
      this.fetchStockControllerOnBasisOfDept();
    }  
    
    else if (evt.property == "Department" && !evt?.arrayList?.[0]) {
      this.dropDownUsers.selectedData = [];
      this.dropDownUsers.options = [];
  }

    if (evt.property == "Process" && evt?.arrayList?.[0]) {
      this.dropDownUsers.options = this.dropDownProcess.options.filter((pc: any) => pc.actionName == evt.arrayList[0].actionName);
    }
    else if (evt.property == "Process" && !evt?.arrayList?.[0]) {
      this.dropDownUsers.selectedData = [];
      this.dropDownUsers.options = [];
  }
    this.httpService.dismissLoader();
  }

  inputChanged(event: any, key: string) {
    this.addMistakeLogsInputs[key] = event.target.value;
  }

  onClickClearSelection() {}

  setValuesOfDropdown(evt) {
    console.log(evt);
  }

  async openChangeRouteModal() {
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: "lockModal",
      componentProps: {
        data: { status: "lockModal", data: this.receivedData.data },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  uploadEcsvClicked() {
    const payload = {
      packetId: this.receivedData?.data?.barcodeNo,
    };
    this.httpService.getData(true, "packet/getPacketDataForManualFinalPlan", payload).subscribe(async (res: any) => {
      console.log(res);
      if (res?.data?.packet) {
        this.store.dispatch(saveCurrentRowData({ rowData: res.data.packet }));
        // this.selectedRow = res.data.packet;
        console.log(this.paramSelectionList);
        console.log(res.data.data);
        let info = {
          rowData: res.data.packet,
          status: "Initial",
          parameterList: res.data.data,
          className: "ecsvModal",
          history: res.data.data,
          dropdownValues: res.data.data,
        };
        console.log(this.paramSelectionList);
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: "e-csv Upload",
            data: info,
          },
          cssClass: "ecsvModal",
        });
        modal.onDidDismiss().then(d => {
          console.log(d);
          if (d.data) this.ecsvUploadWithOtherDetails(d.data);
        });
        return await modal.present();
      }
    });
  }

  async ecsvUploadWithOtherDetails(formData, isAppend?: boolean) {
    let formType = "JSON";
    let fromJsonInputsModified = { ...this.fromJsonInputs };
    fromJsonInputsModified.packetId = this.receivedData?.data?.id;
    let obj = {
      listOfAllPackets: [],
    };
    const formatData = new FormData();
    if (formData["file"]) {
      console.log(formData["file"]);
      formType = "Multipart";
      let file = formData["file"]["0"];
      formatData.append("file", file);
      delete formData["file"];
      let keys = Object.keys(formData);
      let list = [];
      for (let temp of keys) {
        if (temp == "status") {
        } else {
          let obj = { parameterName: temp, parameterValue: formData[temp] };
          list.push(obj);
        }
      }
      fromJsonInputsModified.listOfParameter = list;
      obj["listOfAllPackets"].push(fromJsonInputsModified);
      formatData.append("listOfAllPackets", JSON.stringify(obj["listOfAllPackets"]));
      
      this.httpService.saveDataToDB(true, `packetPlan/uploadPacketAdvisorCsv?packetId=${this.receivedData?.data?.id}&isSelected=1`, formatData, "Multipart").subscribe(
        (res: any) => {
          
          if (res.statusCode == 200) {
            if (res.data) {
              let temp = res.data;
              if (temp.error == "error") {
                this.configService.showToast("error", "In correct file mapping!");
              } else {
                this.configService.showToast("success", res.message || "Plan saved successfully!");
                this.parameterDetails();
              }
            }
          } else this.configService.showToast("error", res.error.message || "Something went wrong!");
        },
        (err: any) => {
          
          this.configService.showToast("error", err.error.message || "Something went wrong!");
        }
      );
    } else {
      this.configService.showToast("error", "Please select file!");
    }
  }

  async handleModalDismisss(d) {
    console.log(d);

    if (typeof d.data === "string") {
      this.payload = {
        packetId: this.receivedData?.data,
      };

      this.saveRouteDataToDB(this.payload);
    } else if (typeof d.data === "object") {
      this.payload = {
        routeId: d.data?.name?.id,
        packetId: this.receivedData?.data,
      };
      this.saveRouteDataToDB(this.payload);
    }
  }

  async saveRouteDataToDB(payload) {
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet/unholdPacket", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  onCancel() {
    this.closeEmitter.emit(null);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
