import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NewDropDownComponent } from './new-drop-down.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgSelectModule,
  ],
  declarations: [
    NewDropDownComponent
  ],

  exports: [NewDropDownComponent],
  providers: []
})
export class NewDropDownModule { }
