import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';


@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  


  constructor(private elementRef:ElementRef) { 
    console.log('Directive WOrking')
  }

  @HostListener('document:click',['$event'])

  public onClick(event:MouseEvent){
    console.log(event,'event')
    const clickInside = this.elementRef.nativeElement.contains(event.target);
    if(!clickInside){
      console.log('yes clicked oott')
    }
    else{
      console.log("click inside");
      
    }
    
    
  }
}
