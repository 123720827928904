import { Component, OnInit } from '@angular/core';
import { MultipleBarcodeScannerComponent } from '../components/multiple-barcode-scanner/multiple-barcode-scanner.component';
import { HttpService } from '../services/http/http.service';
import { ModalController } from '@ionic/angular';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { element } from 'protractor';

@Component({
  selector: 'app-dispatch-packet',
  templateUrl: './dispatch-packet.component.html',
  styleUrls: ['./dispatch-packet.component.scss'],
})
export class DispatchPacketComponent implements OnInit {
  column: any;
  dataSource: any;
  barcodeInputList: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private modalCtrl: ModalController,
    private configService: ConfigService,
    private modalController: ModalController,
    private loaderService: LoaderService,
  ) {
    this.column = [
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      { name: 'Packet Id', prop: 'currPacketId' },
      { name: 'org Carat', prop: 'originalWt' },
      { name: 'Return Type', prop: 'returnType' },
      { name: 'Opening Cts', prop: 'outStandingWt' },
      { name: 'Value', prop: 'amount' },
      { name: 'Loss', prop: 'loss' },
      { name: 'RD', prop: 'roughCutWt' },
      { name: 'Last Packet', prop: 'isLastPacket' },

    ];
  }

  ngOnInit() { }


  onSelectionChanged(event) {
  console.log(event);
  }



  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: 'multiplBarcodeScanner',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'done') {
      this.barcodeInputList = data.map((barcode) => barcode.barcode);
      let payload = {
        "packetId": this.barcodeInputList
      }
      this.PacketOutput(payload)
    }
  }


  async PacketOutput(payload) {
    
    try {
      let response = await this.httpService.postDataUsingPromise(true, "packet/dispatchPacket", payload);
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        console.log(response.data);



        const newData = response.data.dispatchVoucher.map(item => {
          return { ...item, loss: item.loss.toFixed(3) };
        });

          
        if (!this.dataSource) {
          this.dataSource = newData;
        } else {
        const uniqueData = newData.filter(newItem => {
          return !this.dataSource.some(existingItem => existingItem.currPacketId === newItem.currPacketId);
        });
        this.dataSource = [...this.dataSource, ...uniqueData];
      }

      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      
    }
  }


  async OnDone() {
    let packetLabels = this.dataSource.map(element => element?.currPacketId);
    let payload = {
      "packetId": packetLabels
    }
    console.log(payload);
    
    let response = await this.httpService.postDataUsingPromise(true, "packet/transferPacketToDispatch", payload)
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dataSource = [];
      
      let data = response.message;
    } else {
      console.error(response);
      
      this.configService.showToast("error", response.error.message);
    }

  }



  remove(event) {
    console.log(this.dataSource);
    const idToRemove = event.row.data.id;

    const indexToRemove = this.dataSource.findIndex(
      (item) => item.id === idToRemove
    );

    if (indexToRemove !== -1) {
      this.dataSource.splice(indexToRemove, 1);
    }

  }
}
