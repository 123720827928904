import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualFinalParametersComponent } from './manual-final-parameters.component';
import { ScannerinputModule } from '../scannerinput/scannerinput.module';
import { HeaderComponentModule } from '../header/header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownModule } from 'src/app/dropdown/dropdown.module';
import { WidthAlertModule } from 'src/app/width-alert/width-alert.module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [ManualFinalParametersComponent],
  imports: [
    CommonModule,
    ScannerinputModule,
    HeaderComponentModule,
    NgSelectModule,
    DropdownModule,
    WidthAlertModule,
    IonicModule
  ],
  exports: [ManualFinalParametersComponent]
})
export class ManualFinalParametersModule { }
