import { createAction, props } from "@ngrx/store";

export const getCheckerPlans = createAction(
    '[Checker] Get Packet Plans from Packet ID',
    props<{ packetIdOb: any }>(),
);

export const saveCheckerPlans = createAction(
    '[Checker] save Packet Plans from Packet ID',
    props<{ packetPlanstones: any }>(),
);

export const saveSelectedPlans = createAction(
    '[Checker] save selected plan from packet ID',
    props<{ packetPlanID: any, pktData: any }>(),
);

export const changePlanOncheck = createAction(
    '[Checker] change Plans on Parent Chk Change',
    props<{ plan: any, isChecked: boolean }>()
);

export const updateFLCLClAFromPacketIdForAllSelectedPlan = createAction(
    '[Checker] Update Fluorescence,Color,Clarity for all selected plan',
    props<{ updateData: any, packetIdObj: any }>(),
);

export const updateDropDownParam = createAction(
    '[Checker] Update DropDown Parameter in Packet Plans stone data',
    props<{ updateData: any }>(),
);
export const saveFinalPlanData = createAction(
    '[Checker] save Final Plan to Checker',
    props<{ packetPlanstones: [] }>(),
);
export const removeFinalPlanData = createAction(
    '[Checker] remove Final Plan to Checker from store',
    props<{ packetId: any }>(),
);
export const sendChat = createAction(
    '[Checker] send chat',
    props<{ chatData: any }>(),
);
export const getChatConv = createAction(
    '[Checker] get chat for packet',
    props<{ packetId: any }>(),
);
export const saveChatConv = createAction(
    '[Checker] save chat for packet',
    props<{ chatData: [], packetId: any }>(),
);

export const getPlansAccToUSers = createAction(
    '[Checker] Get Packet Plans Acc to Users',
    props<{ id: any, packetId: any; }>(),
);

export const savePlansAccToUSers = createAction(
    '[Checker] save Packet Plans Acc to Users',
    props<{ PlanAccordingToUSers: [] }>(),
);

export const setCheckerPlanFinal = createAction(
    '[Checker] set Checker Plan Final',
    props<{ packetPlanId: any; }>(),
);

export const resetAllPlans = createAction(
    '[Checker] RESET all plan',
);




