import { Component, EventEmitter, Input, Output } from "@angular/core";

type DropDownPosition = "bottom" | "top" | "auto";

@Component({
  selector: "app-new-drop-down",
  templateUrl: "./new-drop-down.component.html",
  styleUrls: ["./new-drop-down.component.scss"],
})
export class NewDropDownComponent {
  @Input() optionList: any[] = [];
  @Input() model: any;
  @Input() bindValue: string = "";
  @Input() bindLabel: string = "";
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() notFoundText: string = "Not Found";
  @Input() isRequired: boolean = false;
  @Input() multiple: boolean = false;
  @Input() clearable: boolean = false;
  @Input() closeOnSelect: boolean = true;
  @Input() clearSearchOnAdd: boolean = false;
  @Input() clearOnBackspace: boolean = false;
  @Input() readonly: boolean = false;
  @Input() searchable: boolean = true;
  @Input() hideSelected: boolean = false;
  @Input() deselectOnClick: boolean = false;
  @Input() maxSelectedItems: number = 99;
  @Input() dropdownPosition: DropDownPosition = "auto";

  @Output() modelChange = new EventEmitter();
  @Output() addEmitter = new EventEmitter();
  @Output() blurEmitter = new EventEmitter();
  @Output() changeEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  @Output() clearEmitter = new EventEmitter();
  @Output() focusEmitter = new EventEmitter();
  @Output() searchEmitter = new EventEmitter();
  @Output() openEmitter = new EventEmitter();
  @Output() removeEmitter = new EventEmitter();
  @Output() scrollEmitter = new EventEmitter();
  @Output() scrollToEndEmitter = new EventEmitter();

  onModelChange() {
    this.modelChange.emit(this.model);
  }

  onAdd($event: any) {
    this.addEmitter.emit($event);
  }

  onBlur($event: any) {
    this.blurEmitter.emit($event);
  }

  onChange($event: any) {
    this.changeEmitter.emit($event);
  }

  onClose($event: any) {
    this.closeEmitter.emit($event);
  }

  onClear($event: any) {
    this.clearEmitter.emit($event);
  }

  onFocus($event: any) {
    this.focusEmitter.emit($event);
  }

  onSearch($event: any) {
    this.searchEmitter.emit($event);
  }

  onOpen($event: any) {
    this.openEmitter.emit($event);
  }

  onRemove($event: any) {
    this.removeEmitter.emit($event);
  }

  onScroll($event: any) {
    this.scrollEmitter.emit($event);
  }

  onScrollToEnd($event: any) {
    this.scrollToEndEmitter.emit($event);
  }
}
