import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CheckerState } from "./checker.state";

export const selectCheckerState = createFeatureSelector<CheckerState>(
    'checker',
);

export const getCheckerAllPacketPlans = createSelector(selectCheckerState, state => {
    return state.packetPlanStones;
});

export const getSelectedPlanData = createSelector(selectCheckerState, state => {
    return state.selectPlans;
});
export const getFinalPlanData = createSelector(selectCheckerState, state => {
    return state.finalPlans;
});

export const getCutsForChecker = createSelector(selectCheckerState, state => {
    return state.cuts;
});
export const getClaritiesForChecker = createSelector(selectCheckerState, state => {
    return state.clarities;
});
export const getColorsForChecker = createSelector(selectCheckerState, state => {
    return state.colors;
});
export const getShapesForChecker = createSelector(selectCheckerState, state => {
    return state.shapes;
});
export const getStoneRemarksForChecker = createSelector(selectCheckerState, state => {
    return state.stoneRemarks;
});
export const getPlansAccToUserChecker = createSelector(selectCheckerState, state => {
    return state.plansAccToUser;
});

export const getPlanDataForChecker = createSelector(selectCheckerState, state => {
    return state.plansData;
});

