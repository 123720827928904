import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PartyconfigComponent } from './partyconfig.component';
import { DataGridComponentModule } from '../data-grid/data-grid.module';
import { HeaderComponentModule } from '../header/header.module';
import { NewDropDownModule } from '../new-drop-down/new-drop-down.module';

@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      DataGridComponentModule,
      HeaderComponentModule,
      NewDropDownModule,
        ],
    declarations: [
        PartyconfigComponent,
      ],
      entryComponents: [
        PartyconfigComponent,
      ],
      exports: [PartyconfigComponent],
      providers: []
    })

export class partyconfigModule { }