import { CommonModule } from "@angular/common";
import { TrybecardComponent } from "./trybecard.component";
import { FormsModule } from "@angular/forms";
import { DxDataGridModule } from "devextreme-angular";
import { DataGridComponentModule } from "../data-grid/data-grid.module";
import { WidthAlertModule } from "src/app/width-alert/width-alert.module";
import { ScannerinputModule } from "../scannerinput/scannerinput.module";
import { IonicModule } from "@ionic/angular";
import { HeaderComponentModule } from "../header/header.module";
import { PrintskeltonModule } from "src/app/printskelton/printskelton.module";
import { SingleMultiDropdownModule } from "../single-multi-dropdown/single-multi-dropdown.module";
import { PrintPageModule } from "../print-page/print-page.module";
import { NgModule } from "@angular/core";


@NgModule({
  declarations: [TrybecardComponent],
  entryComponents: [],
  exports: [TrybecardComponent],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    DataGridComponentModule,
    WidthAlertModule,
    ScannerinputModule,
    IonicModule,
    HeaderComponentModule,
    PrintskeltonModule,
    SingleMultiDropdownModule,
  ],
})
export class TrybeCardModule {}
