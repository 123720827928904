import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { HeaderComponentModule } from "../components/header/header.module";
import { WidthAlertModule } from "../width-alert/width-alert.module";
import { SingleMultiDropdownModule } from "../components/single-multi-dropdown/single-multi-dropdown.module";
import { DataGridComponentModule } from "../components/data-grid/data-grid.module";
import { DxDataGridModule, DxPopoverModule } from "devextreme-angular";
import { ScannerinputModule } from "../components/scannerinput/scannerinput.module";
import { NewDropDownModule } from "../components/new-drop-down/new-drop-down.module";
import { OpinionScreenComponent } from "./opinion-screen.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [OpinionScreenComponent],
  entryComponents: [],
  exports: [OpinionScreenComponent],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DxDataGridModule,
    DataGridComponentModule,
    WidthAlertModule,
    ScannerinputModule,
    IonicModule,
    HeaderComponentModule,
    MatTooltipModule,
    SingleMultiDropdownModule,
    NewDropDownModule,
  ],
})
export class OpinionScreenModule {}
