import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';


@Component({
  selector: 'app-pyramidviewcomponent',
  templateUrl: './pyramidviewcomponent.component.html',
  styleUrls: ['./pyramidviewcomponent.component.scss'],
})
export class PyramidviewcomponentComponent implements OnInit {
  barcode: string;
  pyramidProps={
    data:[],
    nodeId:'packetLabel',
    parentNodeId:'originalLabel'
  }
  constructor(
    private configService: ConfigService,
    private httpService: HttpService,
  ) {}

  ngOnInit() {}

  clearBarcode() {
    this.barcode = null;
    this.pyramidProps.data= [];
  }
  
  barcodeDetection(event) {
  
    this.barcode = event?.toUpperCase();
    if (this.barcode == undefined || this.barcode == '') {
      this.configService.showToast('error', 'No barcode/No Packet Id detected');
      return;
    } 
    else {
      this.displayChartData(this.barcode);
    }
  }


  displayChartData(barcode) {
    this.pyramidProps.data = [];

    if (!!barcode) {
      this.httpService.fetchDataUsingPromise(true, `packet/getPacketFamilyTree?packetLabel=${barcode}`)
        .then((res) => {
          if (res?.data) {
            this.pyramidProps.data = res.data;
          } else {
            console.error("No data found for pyramid chart.");
            this.configService.showToast('error', 'No data found for pyramid chart');
            this.pyramidProps.data = [];
            return
          }
        })
        .catch((error) => {
          console.error('Error fetching chart data:', error);
          this.configService.showToast('error', 'Error fetching chart data');
        });
    }
  }

}



