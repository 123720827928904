import { Component,EventEmitter, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment, StaticInfo } from 'src/environments/environment';


@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss'],
})
export class PrintPageComponent implements OnInit {
  @ViewChild('page1', { static: false }) page1: ElementRef;
  @ViewChild('page2', { static: false }) page2: ElementRef;
  @ViewChild('voucherContent', { static: false }) voucherContent: ElementRef;
  environment = environment;
  StaticInfo = StaticInfo;
  @Input() data: any;
  @Input() dispatchData: any;
  @Input() voucherDto:any;
  @Input() isLoss:boolean;
  @Output() printDiv: EventEmitter<any> = new EventEmitter();
  // template = 'vardhan gems';
  template = 'KPtoTiea';
  // template = 'Kp'
  process = ['Part Rough Return', 'Part Polish Return', 'Manufacturing']
  companyDetails = {
    name: 'VARDHAN GEMS',
    branchOfficeAddress: `PLOTNO 37 A-WING, B- WING, 2ND FLOOR, 3RD FLOOR, 4RTH FLOOR, 5TH FLOOR, 6TH
    FLOOR, KP SANGHVI BUILDING, NR, UMIYA MATA TEMPLE, AK ROAD, SURAT, GUJARAT-395008, SURAT`,
    to: `K. P. SANGHVI & SONS LLP (GSTIN 24AAAPK8390P1ZD)
    NEAR UMIYA MATA TEMPLE, ASHWINI KUMAR ROAD, SURAT`,
    fromGST: '24AATFV5953A1ZY',
    accNo: '2329',
    No: '1',
    Process: 'Part Rough Return',
    HSNCode: '71023100',
    stateCode: '24 GUJARAT'
  }

  

  constructor() { }
  
  ngOnChanges(changes) {
    if (!!changes.dispatchData && !!changes.dispatchData.currentValue) {
      let value = changes.dispatchData.currentValue
      if(value.length>0){
        setTimeout(() => {
          this.printDiv.emit();    
        }, 1000);
      }
    }
  }



  ngOnInit() {
  }

  twoDecimalvalues(x) {
    let num = parseFloat(x).toFixed(2)
    return !!num ? num : 0;
  }

  twoDecimalWithoutRoundUp(number, decimalPlaces){
    let temp = String(number)
    let s = temp.split(".")
    return `${s[0]}${s.length > 1 ? "." + s[1].substring(0, decimalPlaces) : ""}`
  }
}
