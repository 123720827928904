import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MasterDataState } from "./masterData.state";

// debugger
export const selectMasterDataState = createFeatureSelector<MasterDataState>(
    'masterData',
);

export const getPdParameters = createSelector(selectMasterDataState, state => {
    return state.pdStaticParametrs;
});

export const getParamsValuesListByName = (paramName) => createSelector(selectMasterDataState, state => {
    return state.parameters[paramName];
});
export const getAllParameters = createSelector(selectMasterDataState, state => {
    return state.parameters;
});
export const getPacketParameterHistoryData = createSelector(selectMasterDataState, state => {
    return state.parameterHistory;
});
export const getAllTrybeDepartments = createSelector(selectMasterDataState, state => {
    return state.trybeDepartments;
});
export const getAllRolesOfUser = createSelector(selectMasterDataState, state => {
    return state.trybeRoles;
});
export const getAllWorkQualityMasterData = createSelector(selectMasterDataState, state => {
    return state.qualityMasterData;
});
export const getAllDamageMasterData = createSelector(selectMasterDataState, state => {
    return state.damageMasterData;
});
export const getUsersDataByPacketId = createSelector(selectMasterDataState, state => {
    return state.usersbyPacketId;
});
export const saveDetailFormResult = createSelector(selectMasterDataState, state => {
    return state.saveDetailFormObj;
});
export const getOriginalAnnotationImg = createSelector(selectMasterDataState, state => {
    return state.originalAnnotationImage;
});
export const getDetailsAnnotation = createSelector(selectMasterDataState, state => {
    return state.DetailsOfAnnotation;
});
export const getPreplanningSummaryDetails = createSelector(selectMasterDataState, state => {
    return state.DetailsOfPrePlanningSummary;
});
export const FetchAllStoneParameter = createSelector(selectMasterDataState, state => {

    return state.stoneParameterMaster;
});
export const FetchAllStoneParameterById = createSelector(selectMasterDataState, state => {

    return state.stoneParameterMasterById;
});

export const fetchAllUsers = createSelector(selectMasterDataState, state => {

    return state.allUsers;
});
export const FetchAllLabParameterData = createSelector(selectMasterDataState, state => {

    return state.labParameterData;
});
export const FetchAllColorData = createSelector(selectMasterDataState, state => {

    return state.ColorData;
});
export const FetchAllFancyData = createSelector(selectMasterDataState, state => {

    return state.FancyData;
});
export const FetchAllColorShadeData = createSelector(selectMasterDataState, state => {

    return state.ColorShadeData;
});

export const selectLabParametersData = createSelector(
    selectMasterDataState,
    (state: any) => state.labParameterData
  );
  
  export const selectColorValues = createSelector(
    selectMasterDataState,
    (state: any) => state.ColorData
  );
  
  export const selectFancyValues = createSelector(
    selectMasterDataState,
    (state: any) => state.FancyData
  );
  
  export const selectColorShade = createSelector(
    selectMasterDataState,
    (state: any) => state.ColorShadeData
  );

