import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanInfoGridComponent } from './plan-info-grid.component';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PlanInfoGridComponent],
  imports: [
    IonicModule,
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    CommonModule
  ],
  exports:[
    PlanInfoGridComponent
  ],
  entryComponents: [PlanInfoGridComponent],
})
export class PlanInfoGridModule { }
