import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { ConfigService } from '../service/config.service';
import { NavController } from "@ionic/angular";
import { Storage } from '@ionic/storage-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, throwError } from 'rxjs';
import {
    catchError,
    concatMap, map, mergeMap, tap
} from 'rxjs/operators';
import { EnumUsers } from '../masterDataStore/master.data';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import { AppState } from '../reducers';
import { HttpService } from '../services/http/http.service';
import { LoaderService } from '../services/loader/loader.service';
import { AuthActions } from './auth.action-type';
import { MixpanelService } from '../services/mixpanel/mixpanel.service';
import { StorageService } from '../storage.service';

@Injectable()
export class AuthEffects {

    constructor(
        private navCtrl: NavController,
        private actions: Actions,
        public httpService: HttpService,
        public loaderService: LoaderService,
        private store: Store<AppState>,
        public router: Router,
        private storage: Storage,
        // private mixpanelService: MixpanelService,/
        private storageService:StorageService
    ) {
        console.log('***')
    }

    getUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.getUser),
            tap(x => {
                // this.httpService.showLoader();
            }),
            concatMap((action) =>
                from(
                    this.httpService.getUserById(action.userId),
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        if (res.data) {
                            return res.data;
                        }
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error)
                        if (error && !!error.status && error.status == 401) {
                            this.httpService.logout();
                        }
                        return throwError(error);
                    })
                ),
            ),
            tap((response: any) => {
                // this.httpService.dismissLoader()
                if (response.error == 'Unauthorized') {
                    this.httpService.logout()
                }
            }),
            mergeMap((user: any) => {
                let latticeAuthPayload = {
                    "username": EnumUsers.keval,
                    "password": EnumUsers.kevalPass,
                    "companyName": EnumUsers.mumbai,
                    "appName": EnumUsers.lattice
                }
                // check for register request success
                return [
                    // AuthActions.getIfManager(),
                    AuthActions.saveUser({ user }),
                    AuthActions.getUserActions({ userId: user.id }),
                    masterDataActions.getParametersList({ paramList: ["Fluorescence", "Color", "Purity", "Shape", "Tension Mapping", "Stress", "Stress Type", 'Die', 'Stand Number', 'Machine Number', 'Worker Code', 'Machine Type', 'Process Type', 'Remarks', 'CLARITY', 'Clarity', 'Milkyness', 'Tinch','CUT','Symmetry','Polish','Girdle','chapka','NATTS','LUSTER','POL','TOP OPEN','BOTTOM OPEN','DEPTH','Lab','Luster','Tinch','Stress Value','OpinionRemark'] }),
                    AuthActions.getNSaveLatticeAuthToken({ payload: latticeAuthPayload }),
                    masterDataActions.getAllParametersByTransType({ transType: 'SS' })
                ]
            })
            // mergeMap((user: any) => {
            //         return AuthActions.saveUser({ user });
            // }),
        ),
    );

    // getUserStoneActions$ = createEffect(() =>
    //     this.actions.pipe(
    //         ofType(AuthActions.getUserStoneActions),
    //         concatMap((action) =>
    //             from(
    //                 this.httpService.getUserStoneActions()
    //                 // fetchListOfActions
    //             ).pipe(
    //                 map(res => {
    //                     return res
    //                 }),
    //                 catchError(error => {
    //     this.httpService.getErrorAndDisplayIt(error)
    //     return throwError(error)
    //   })
    //             ),
    //         ),
    //         tap((response: any) => {
    //             if (response.error == 'Unauthorized') {
    //                 this.httpService.logout()
    //             }
    //             // this.httpService.dismissLoader();
    //         }),
    //         map((data: any) => {
    //             if (!!data.error && data.error.length > 0) {
    //                 data = [];
    //             }
    //             return AuthActions.saveUserStoneActions({ actions: data });

    //         })
    //     ),
    // )

    getActionFormsByIds$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.getActionFormsByIds),
            concatMap((action) =>
                from(
                    this.httpService.getActionFormsByIds(action.actionIds)
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return res;
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((response: any) => {
                if (response.error == 'Unauthorized') {
                    this.httpService.logout();
                }
                // this.httpService.dismissLoader();
            }),
            map((data: any) => {
                return AuthActions.saveActionForms({ actionForms: data });
            })
        ),
    )

    getUserActions$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.getUserActions),
            concatMap((action) =>
                from(
                    // this.httpService.fetchUserActions(action.userId)
                    this.httpService.fetchUserActionsByUserId(action.userId)
                    // this.httpService.getUserStoneActions()
                    // this.httpService.fetchListOfActions()
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return res.data;
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((response: any) => {
                //this.httpService.userStored.next(true);
                if (response.error == 'Unauthorized') {
                    this.httpService.logout()
                }
                // this.httpService.dismissLoader()
            }),
            mergeMap((data: any) => {
                console.log(data);
                if (!!data.error && data.error.length > 0) {
                    data = [];
                }
                // let actionIds = data.map(x => x.id)
                return [
                    AuthActions.saveUserActions({ actions: data }),
                    // AuthActions.getActionFormsByIds({ actionIds: actionIds })
                ]

            })
        ),
    )

    // async setToken(response) {
    //     let token = response.token_type + " " + response.access_token;
    //     await this.storage.set('token', token);
    //     await this.storage.set('userId', response.userId);
    //     debugger
    //     this.httpService.isAuthenticated.next(true);
    //     this.router.navigate(["/home"]);
    // }

    getUserSubmittedPlans$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.getUserSubmittedPlans),
            concatMap((action) =>
                from(
                    this.httpService.getUserSubmittedPlans(action.userId, null)
                ).pipe(
                    map(res => {
                        return res.data;
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
                ),
            ),
            tap((response: any) => {
                if (!!response && response.error == 'Unauthorized') {
                    this.httpService.logout()
                }
                // this.httpService.dismissLoader()
            }),
            mergeMap((data: any) => {
                if (!!data && !!data.error && data.error.length > 0) {
                    data = [];
                }
                return [
                    AuthActions.saveUserSubmittedPlans({ plans: data }),
                    // AuthActions.getActionFormsByIds({ actionIds: actionIds })
                ]

            })
        ),
    )

    getListOfActions$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.getUserActions),
            concatMap((action) =>
                from(
                    this.httpService.fetchListOfActions()
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return res.data
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error)
                    })
                ),
            ),
            tap((response: any) => {
                if (response.error == 'Unauthorized') {
                    this.httpService.logout()
                }
                // this.httpService.dismissLoader()
            }),
            mergeMap((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    data = [];
                }
                return [
                    AuthActions.saveUserActions({ actions: data }),
                    // AuthActions.getActionFormsByIds({ actionIds: actionIds })
                ]

            })
        ),
    )

    ifUserIsManager$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.getIfManager),
            concatMap((action) =>
                from(
                    this.httpService.checkIfUserIsManager()
                ).pipe(
                    map(res => {
                        this.httpService.getErrorAndDisplayIt(res);
                        return res.data;
                    }),
                    catchError(error => {
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error)
                    })
                ),
            ),
            tap((response: any) => {
                if (response.error == 'Unauthorized') {
                    this.httpService.logout()
                }
                // this.httpService.dismissLoader()
            }),
            mergeMap((data: any) => {
                if (!!data.error && data.error.length > 0) {
                    data = "";
                }
                return [
                    AuthActions.saveIfManager({ userIfManager: data }),
                ]

            })
        ),
    )
    login$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            tap(x => {
                
            }),
            concatMap((action) =>
                from(
                    this.httpService.login(action.userName, action.password),
                ).pipe(
                    map((res) => {
                        let userDetail = res.data;
                        console.log(res);
                        console.log(userDetail);
                        this.httpService.getErrorAndDisplayIt(res)
                        
                        if (!!userDetail) {
                            //this.setToken(response)
                            let token = userDetail.token_type + " " + userDetail.access_token;
                            // console.log(token)
                            this.httpService.token = token;
                            this.storageService.login(token)
                            localStorage.setItem("userId", userDetail.userId)
                           // this.storage.set('token', token);
                           // this.storage.set('userId', userDetail.userId);
                            this.httpService.isAuthenticated.next(true);
                            // this.mixpanelService.identify(userDetail.userId);
                            // this.mixpanelService.setPeople({ "name": userDetail.username });
                            // this.mixpanelService.track( 'Log In', { userName: userDetail?.username, fullName: userDetail?.fullName, userId: userDetail?.userId } );
                            return userDetail;
                        } else {
                            this.httpService.isAuthenticated.next(false);
                            this.httpService.handleError(res)
                            console.log('User is invalid');
                            return res;
                        }
                    }),
                    catchError(error => {
                        
                        this.httpService.showToast('error', error?.error?.error)
                        return throwError(error);
                    }),
                ),
            ),
            mergeMap((user: any) => {
                // check for register request success
                let data = !!user && !!user.userId ? user : null;
                console.log(data);
                let latticeAuthPayload = {
                    "username": EnumUsers.keval,
                    "password": EnumUsers.kevalPass,
                    "companyName": EnumUsers.mumbai,
                    "appName": EnumUsers.lattice
                }
                if (data) {
                    return [
                        // AuthActions.getIfManager(),
                        AuthActions.saveUser({ user }),
                        AuthActions.getUserActions({ userId: user.userId }),
                        masterDataActions.getParametersList({ paramList: ["Fluorescence", "Color", "Purity", "Shape", "Tension Mapping", "Stress", "Stress Type", 'Die', 'Stand Number', 'Machine Number', 'Worker Code', 'Machine Type', 'Process Type', 'Remarks', 'CLARITY', 'Clarity', 'Milkyness', 'Tinch','CUT','Symmetry','Polish','Girdle','chapka','NATTS','LUSTER','POL','TOP OPEN','BOTTOM OPEN','DEPTH','Lab','Luster','Tinch','Stress Value','OpinionRemark'] }),
                        AuthActions.getNSaveLatticeAuthToken({ payload: latticeAuthPayload }),
                    ]
                } else {
                    console.error('Error occured to login');
                    return []
                }

            })
        ),
    );

    // getAndSaveLatticeToken$ = createEffect(() =>
    //     this.actions.pipe(
    //         ofType(AuthActions.getNSaveLatticeAuthToken),
    //         concatMap((action) =>
    //             from(
    //                 this.httpService.loginOnLattice(action.payload)
    //             ).pipe(
    //                 map(res => {
    //                     console.log('Lattice Auth Log');
    //                     console.log(res);
    //                     this.httpService.getErrorAndDisplayIt(res);
    //                     let user: any = res.data;
    //                     if (!!user) {
    //                         //this.setToken(response)
    //                         let token = user.token_type + " " + user.access_token;
    //                         // console.log(token);
    //                         this.httpService.latToken = token;
    //                         this.storage.set('latToken', token);
    //                         this.storage.set('latUserId', user.userId);
    //                         this.httpService.islatticeAuthenticated.next(true);
    //                         return user;
    //                     } else {
    //                         // this.httpService.handleError(res)
    //                         this.httpService.islatticeAuthenticated.next(false);
    //                         console.log('User is invalid');
    //                         return res;
    //                     }
    //                 }),
    //                 catchError(error => {
    //                     this.httpService.getErrorAndDisplayIt(error);
    //                     return throwError(error);
    //                 })
    //             ),
    //         ),
    //         mergeMap((data: any) => {
    //             return [
    //                 masterDataActions.getAllParametersByTransType({ transType: 'SS' }),
    //             ];

    //         })
    //     ),
    // )
}