import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../services/config/config.service';
import { Router } from '@angular/router';
import { HttpService } from '../services/http/http.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { TrybemodalComponent } from '../components/trybemodal/trybemodal.component';
import { ModalController } from '@ionic/angular';
import { ScannerinputComponent } from '../components/scannerinput/scannerinput.component';

@Component({
  selector: 'app-pull',
  templateUrl: './pull.component.html',
  styleUrls: ['./pull.component.scss'],
})
export class PullComponent implements OnInit {
  columns: any;
  dataSource: any;
  pageSize: 20;
  remarkvalue: string;
  refreshGrid: boolean;
  disablebtn: boolean = true;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  packetids: [];
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private modalController: ModalController,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.columns = [
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      { name: 'packet Num', prop: 'pktNo' },
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'barcodeNo', prop: 'barcodeNo' },
    ];
  }

  barcodeDetection(barcode: string) {
    if (barcode == undefined || barcode == '') {
      this.configService.showToast(false, 'No barcode/Packet Id detected');
      return;
    } else {
      this.onBarcodeSearch(barcode);
    }
  }

  onSelectionChanged(event) {
    this.packetids = event?.selectedRowsData.map((data) => data?.id);
    this.disablebtn = !!!this.packetids?.length;
  }

  clearBarcode() {
    this.dataSource = [];
  }

  async onBarcodeSearch(barcode) {
    if (!!barcode) {
      let payload = {
        lotNo: barcode,
      };
      let response = await this.httpService.postDataUsingPromise(
        true,
        'packet/getListOfPacketFromLot',
        payload
      );
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        let data = response.data;
        this.dataSource = data;
      } else {
        console.error(response);
        this.configService.showToast('error', response.error.message);
      }
    }
  }

  async onPullBackRemark() {
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: 'rejectRemark',
      componentProps: {
        data: { status: 'RemarkForPull' },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  async handleModalDismisss(d) {
    if (!!d) {
      this.remarkvalue = d.data;
      if (!!this.remarkvalue) {
        this.fetchstoneForPull(this.remarkvalue);
      }
    }
  }

  onPullBackStone() {
    this.onPullBackRemark();
  }

  async fetchstoneForPull(remarkval) {
    let payload = {
      packetIds: this.packetids,
      remark: remarkval,
    };
    let response = await this.httpService.postDataUsingPromise(
      true,
      'packet/pullPacketForAdmin',
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dataSource = [];
      this.scannerComponet.onResetForm();
      this.configService.showToast('success', response.message);
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  }
}
