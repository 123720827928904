import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { SignerAction } from '../trybesigner/state/signer.action.type';
import { submittedPlansByUsers } from '../trybesigner/state/signer.selector';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bid-plans',
  templateUrl: './bid-plans.component.html',
  styleUrls: ['./bid-plans.component.scss'],
})
export class BidPlansComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();
  @Input() packetId;
  @Input() userId;
  plans = [];
  allData = [];
  userData: any;
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  message:string = '';
  constructor(private store: Store, private modalCtl: ModalController) {
    this.store.pipe(select(submittedPlansByUsers)).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      console.log(res)
      if (!!res && res.length > 0) {
        this.plans = res
      }
    })
  }

  ngOnInit() {
   

    

  }

  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  twoDecimalvalues(x) {
    return parseFloat(parseFloat(x).toFixed(2));
  }

  closeModal() {
    this.modalCtl.dismiss();
  }
}
