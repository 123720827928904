import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponentModule } from 'src/app/components/header/header.module';
import { FooterModule } from 'src/app/footer/footer.module';
import { DataGridComponentModule } from 'src/app/components/data-grid/data-grid.module';
import { SingleMultiDropdownModule } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.module';
import { SingleMultiDropdownComponent } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.component';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';
import { ScannerinputComponent } from '../components/scannerinput/scannerinput.component';
import { LockmasterComponent } from './lockmaster.component';
import { NgxFormModule } from "../components/ngx-form/ngx-form.module";

@NgModule({
    declarations: [LockmasterComponent],
    exports: [LockmasterComponent],
    entryComponents: [SingleMultiDropdownComponent],
    providers: [],
    imports: [
        CommonModule,
        HeaderComponentModule,
        IonicModule,
        FooterModule,
        DataGridComponentModule,
        SingleMultiDropdownModule,
        WidthAlertModule,
        ScannerinputModule,
        NgxFormModule
    ]
})
export class LockMasterModule {}
