import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-trybecard',
  templateUrl: './trybecard.component.html',
  styleUrls: ['./trybecard.component.scss'],
})
export class TrybecardComponent implements OnInit {
  @Input() dropDownData: any;

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() selectionFieldClickEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log("dropDownData",this.dropDownData);
   }

  dropDownSelection(event) {
    this.onChange.emit(event);
  }

  multiSelectDropdownClicked(event) {
    this.selectionFieldClickEvent.emit(event);
  }
}
