import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { SUGGESTED_HEADER_COLUMNS } from '../opinion-screen.data';

@Component({
  selector: 'app-opinion-view-history',
  templateUrl: './opinion-view-history.component.html',
  styleUrls: ['./../opinion-screen.component.scss'],
})
export class OpinionViewHistoryComponent implements OnInit {

  historyData: any[];
  suggestedHeaderColumns = SUGGESTED_HEADER_COLUMNS;
  suggestedBasicSize:number = 0.00631578947; // 12 / 1900

  constructor(
    private navParams: NavParams,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.historyData = this.navParams.data.historyData || [];
  }

  close(){
    this.popoverController.dismiss();
  }

}
