import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Query from 'devextreme/data/query';
import { HttpService } from 'src/app/services/http/http.service';
import { FileDownloadComponent } from 'src/app/file-download/file-download.component';
import { ModalController } from '@ionic/angular';
import { ENDPOINTS } from 'src/app/services/endpoint.enum';
import { ConfigService } from 'src/app/services/config/config.service';
import * as moment from 'moment';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';
import { ScannerinputComponent } from '../scannerinput/scannerinput.component';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-reprint-dispatch-voucher',
  templateUrl: './reprint-dispatch-voucher.component.html',
  styleUrls: ['./reprint-dispatch-voucher.component.scss'],
})
export class ReprintDispatchVoucherComponent implements OnInit {

  barcode: string;
  columns: any;
  remarkList: any;
  isUpdateDisable: boolean = true;
  dataSource: any;
  modifiedDataSource: any;
  name:string;
  public pageNumber: number = 1;
  dropdown: any = {};
  payload: any = [];
  public pageSize: number = 50;
  public pageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  modifiedGridObject:any;
  count:number = 0;
  private unsubscribe$: Subject<any> = new Subject<any>();
  dispatchPrintData: any = {};
  dispatchLossData: any
  printData: any = {};
  dispatchData: any = [];
  voucherData: any = {}
  voucherDto:any;
  voucherLabel: any

  @ViewChild(ScannerinputComponent)
  private scannerComponent!: ScannerinputComponent;

  constructor( private httpService:HttpService,
    private modalController: ModalController,
    private configService: ConfigService, 
    private loaderService: LoaderService) { }

  ngOnInit() {
    // this.setPaginationForWageMaster();
    this.httpService.getCurrentVoucherGridData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.count = data.totalCount;
      if (data && data?.VoucherList?.length) {
        this.dataSource = data.VoucherList.map((item:any) => ({
          ...item,
          Date: moment
          .utc(item.Date)
          .local()
          .format('DD-MMM-YYYY')
      }));
      }
    });

    this.onInit();
  }

  private onInit(){
    this.columns = [
      { name: 'voucherLabel', prop: 'voucherLabel' },
      { name: 'Date', prop: 'Date' },
    ];
  
    // this.getFreshDataAndSetPaginationToGrid()
  }

  getTableData(){
   
  }


  onSelectionChanged(event){

  }

  onBackBtnClick() {
    // this.ngOnDestroy();
    // this.router.navigate(['/actionsWindow']);
    // this.selectedProcess = '';
    // this.fromDate = '';
    // this.dataSource = [];
    // this.fromWeight = '';
    // this.disableInteraction = false;
    // this.toWeight = '';
    // this.weightage = '';
    // this.price = '';
  }

  

  async detailFunction(event:any){
    const modal = await this.modalController.create({
      component: TrybemodalComponent,
      componentProps: {
        data: {
          status: 'reprintOptions'
        }
      }, 
      cssClass: 'modalprint',
    });
    modal.onDidDismiss().then(async (dataReturned) => {
      if (dataReturned?.data) {
        if(event?.row?.data?.id){
          this.httpService.showLoader();
          let calculate:number=0;
          if(dataReturned?.data==="Calculate & Reprint")
          calculate=1;
          const printRes = await this.httpService.getDataWithoutPayloadForGet(true, `${ENDPOINTS.getPrintDataForDispatch}${event.row.data.id}?calculate=${calculate}`).toPromise();
          this.httpService.dismissLoader();
          if (printRes?.data?.dispatchVoucher?.length) {
            this.dispatchData = printRes?.data?.dispatchVoucher;
            this.voucherDto = printRes?.data?.voucherDto || {};
            this.dispatchPrintData['voucherNo'] = event.row.data?.voucherLabel;
            this.dispatchPrintData['jangadNo'] = event.row.data?.voucherLabel;
            this.dispatchPrintData['issueNo'] = event.row.data?.voucherLabel;
            this.dispatchPrintData['issueFor'] = `Transfer`;
            this.dispatchPrintData['fromCompany'] = this.dispatchData[0]?.fromCompany;
            this.dispatchPrintData['toCompany'] = this.dispatchData[0]?.toCompany;
            this.dispatchPrintData['voucherId'] = this.dispatchData[0]?.voucherId;
            this.dispatchPrintData['TocompanyName'] = this.dispatchData[0]?.partyName;
            this.voucherData['voucherLabel'] = event.row.data?.voucherLabel;
            this.dispatchPrintData['date'] = moment
              .utc(this.dispatchData[0].Date)
              .local()
              .format('DD-MMM-YYYY');
            this.dispatchPrintData['ttlPkt'] = this.dispatchData?.length;
            let rctsttl = 0;
            let ctsttl = 0;
            let totalLoss = 0;
            let totalRgCutWt = 0, totalValue = 0;
            this.dispatchData.map((d) => {
              if (d.originalWt) ctsttl += Math.round(d.originalWt * 1000) / 1000;
              if (d.wt) rctsttl += Math.round(d.wt * 1000) / 1000;
              if (d.loss) totalLoss += Math.round(d.loss * 1000) / 1000;
              if (d.roughCutWt) totalRgCutWt += Math.round(d.roughCutWt * 1000) / 1000;
              if(d.crtValue) totalValue +=  Math.round(d.crtValue * 1000) / 1000;
              // d['lotNo'] = d.currPacketId.split('#')[0];
            });
            this.dispatchPrintData['owtttl'] = ctsttl
            this.dispatchPrintData['rctsttl'] = rctsttl;
            this.dispatchPrintData["totalLoss"] = totalLoss;
            this.dispatchPrintData["newTotalRgCutWt"] = totalRgCutWt;
            this.dispatchPrintData['totalValue'] = totalValue;
            let baseURL = this.configService.getTrybeBaseUrl();
            if (printRes?.data?.lastPartReturnPath)
              window.open(baseURL + printRes?.data?.lastPartReturnPath, '_blank');
    
            if (printRes?.data?.partReturn) {
              setTimeout(() => {
                const window2 = window.open(baseURL + printRes?.data?.partReturn, '_blank');
                console.log("hello")
                console.log(baseURL)
                if (window2) {
                  window2.focus();
                }
              }, 500);
            }
    
            if (printRes?.data?.combinePartPath) {
              setTimeout(() => {
                const window2 = window.open(baseURL + printRes?.data?.combinePartPath, '_blank');
                if (window2) {
                  window2.focus();
                }
              }, 500);
            }

            if (printRes?.data?.newDispatchPath) {
              setTimeout(() => {
                const window2 = window.open(baseURL + printRes?.data?.newDispatchPath, '_blank');
                if (window2) {
                  window2.focus();
                }
              }, 500);
            }
    
            let voucherData = {
              data: printRes.data,
            };
            this.printData = JSON.parse(JSON.stringify(this.dispatchPrintData));
            this.dispatchLossData = this.dispatchData?.filter(d => (d.loss != null));
            this.voucherDto['voucherLabel'] = event.row.data?.voucherLabel;
    
            const modal = await this.modalController.create({
              component: TrybemodalComponent,
              componentProps: {
                message: printRes.message,
                data: voucherData,
                dispatchData : this.dispatchData,
                voucherDto : this.voucherDto,
                printData : this.printData,
                dispatchLossData : this.dispatchLossData? this.dispatchLossData : null
              }, 
              cssClass: 'modalprint',
            });
            modal.onDidDismiss().then((dataReturned) => {
              if (dataReturned !== null) {
                console.log(dataReturned);
              }
            });
            return await modal.present();
          }
        }
      }
    });
    return await modal.present();
    
  }


  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case 'paging.pageSize':
        let size = evt.value;
        this.pageInfo['limit'] = size;
       this.setPaginationForVouchers()
        break;
      case 'paging.pageIndex':
        let pageIndex = evt.value;
        this.pageInfo['page'] = pageIndex;
        this.setPaginationForVouchers()
        break;
    }
  }

  async setPaginationForVouchers() {
    this.modifiedDataSource = new CustomStore({
      key: 'id',
      load: async (loadOptions: any) => {
        console.log('loadOptions ', loadOptions);
        // if (!!loadOptions.filter) {
        //   var params = '';
        //   params += '&filter=' + JSON.stringify(loadOptions.filter); // <- Add this line
        // }
        const filterObject = {};
        if (!!loadOptions.filter) {
          var params = loadOptions.filter;
          console.log(params);
          for (let i = 0; i < params.length; i++) {
            if (typeof params[0] == 'string') {
              const key = params[0];
              const value = params[2];
              if (key && value) {
                filterObject[key] = value;
              }
            } else if (Array.isArray(params[i])) {
              const key = params[i][0];
              const value = params[i][2];
              if (key && value) {
                filterObject[key] = value;
              }
            }
            console.log(filterObject);
          }
        }
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo['limit'];
          } else {
            this.pageInfo['limit'] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.pageInfo['page'] = this.pageNumber;
          // if(this.dataSource.length == 0){
          let response: any = await this.httpService.getReprintPacketVoucher(
            this.pageInfo,
            filterObject,
            null
          );
          this.httpService.updateVoucherGridData(response.data);
          // }
          return {
            data: this.dataSource,
            totalCount: this.count,
            groupCount: this.count,
          };
        } else {
          return {
            data: this.dataSource,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }

  async packetScanned(evt){
    let payload = {
      voucherLabel: evt,
    };
    this.voucherLabel = evt
    this.dataSource = [];
    this.modifiedDataSource = null;
    
    let response: any = await this.httpService.getReprintPacketVoucher(
      this.pageInfo,
      null,
      payload
    );
    // this.dataSource = response;
    this.httpService.updateVoucherGridData(response.data);
    
  }

  cancel(){
    this.scannerComponent.onResetForm();
    this.voucherLabel = '';
    this.dataSource = [];
    this.httpService.updateVoucherGridData(null);
  }
  
  onShowAllVouchers(){
    this.dataSource = [];
    this.setPaginationForVouchers();
  }


  
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
