import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { tempDataActions } from 'src/app/tempDataStore/tempData.action-type';
import { getCurrentAction, getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';
import { CopyplanmodalComponent } from '../copyplanmodal/copyplanmodal.component';
import { PDComponent } from '../pd/pd.component';
import { PopoverUserComponent } from '../popover-user/popover-user.component';
import { SignermodalComponent } from '../signermodal/signermodal.component';
import { chekerAction } from '../trybe-checker/state/checker.action.type';
import { SignerAction } from './state/signer.action.type';
import { getAllPacketPlans, getFilteredPlanData, getPlanData, getSelectedPlanData, getUploadedFileData } from './state/signer.selector';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';

@Component({
  selector: 'app-trybesigner',
  templateUrl: './trybesigner.component.html',
  styleUrls: ['./trybesigner.component.scss'],
})
export class TrybesignerComponent implements OnInit {
  @ViewChild('packetsGrid', { static: true }) packetsGrid: ElementRef;
  scrollPosition;
  public unsubscribeBackEvent: any;
  screenFor: string = ""
  setIndeterminateState: boolean = false;
  parentCheckbox: boolean = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public paramList = [];
  public planData = [];
  public gradeToNum = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26 };
  selectedFile: any;
  public planShapes = [];
  public planHex = [];
  packetPlans: any = [];
  public remarksPlanID = [];
  Id: any;

  // packetPlans: any = [
  //   {
  //     "id": 11340,
  //     "packetId": 2122,
  //     "signerId": 147,
  //     "seqNo": "18",
  //     "remarks": null,
  //     "bidAmt": null,
  //     "packetPlanStones": [
  //       {
  //         "id": 13007,
  //         "packetPlanId": 11340,
  //         "tag": 18,
  //         "expPol": "1.471",
  //         "shapeId": 715,
  //         "clarityId": 69,
  //         "cutId": 379,
  //         "colorId": 227,
  //         "pavAngle": "41.640",
  //         "pavHeight": "44.500",
  //         "crAngle": "38.990",
  //         "crHeight": "15.000",
  //         "tableVal": "63.000",
  //         "girdle": "5.500",
  //         "diameter": "6.300",
  //         "height": "64.900",
  //         "lOrW": "1.400",
  //         "rapoRate": "3103.000",
  //         "sawMic": "mic",
  //         "lengthMM": "8.810",
  //         "heightMM": "6.300",
  //         "widthMM": "4.090",
  //         "createdDate": "2022-07-16T09:09:29.000Z",
  //         "createdBy": "2-trybe-signer2",
  //         "modifiedDate": null,
  //         "modifiedBy": null,
  //         "isDeleted": 0,
  //         "planShape": "Oval11",
  //         "planColor": "H",
  //         "planCut": "VG-2",
  //         "planClarity": "SI3",
  //         "planSymmetry": "",
  //         "symmetryId": 0,
  //         "shape": "OVAL11",
  //         "clarity": "SI3",
  //         "color": "H",
  //         "cut": "VG-2",
  //         "symmetry": null,
  //         "progName": null,
  //         "selectedProgramId": null,
  //         "kgRate": 30,
  //         "selected": false,
  //         "isFileSelected": false,
  //         "fluorescence": null
  //       }
  //     ],
  //     "selected": false,
  //     "isExpand": true,
  //     "weightSum": 1.47,
  //     "plannedRemark": "",
  //     "totalKGRate": 30,
  //     "bestRate": 0,
  //     "totalRate": 0,
  //     "expPolPer1": 122.58,
  //     "expPolPer2": 122.58
  //   },
  //   {
  //     "id": 11353,
  //     "packetId": 2122,
  //     "signerId": 147,
  //     "seqNo": "Saw29-1",
  //     "remarks": null,
  //     "bidAmt": null,
  //     "packetPlanStones": [
  //       {
  //         "id": 13021,
  //         "packetPlanId": 11353,
  //         "tag": 231,
  //         "expPol": "1.141",
  //         "shapeId": 655,
  //         "clarityId": 61,
  //         "cutId": 376,
  //         "colorId": 227,
  //         "pavAngle": "40.620",
  //         "pavHeight": "42.900",
  //         "crAngle": "34.610",
  //         "crHeight": "15.500",
  //         "tableVal": "55.000",
  //         "girdle": "4.500",
  //         "diameter": "6.630",
  //         "height": "63.000",
  //         "lOrW": "1.000",
  //         "rapoRate": "6456.000",
  //         "sawMic": "70mic",
  //         "lengthMM": "6.630",
  //         "heightMM": "6.630",
  //         "widthMM": "4.170",
  //         "createdDate": "2022-07-16T09:09:29.000Z",
  //         "createdBy": "2-trybe-signer2",
  //         "modifiedDate": null,
  //         "modifiedBy": null,
  //         "isDeleted": 0,
  //         "planShape": "ROUND",
  //         "planColor": "H",
  //         "planCut": "EX",
  //         "planClarity": "VS2",
  //         "planSymmetry": "EX",
  //         "symmetryId": 0,
  //         "shape": "ROUND",
  //         "clarity": "VS2",
  //         "color": "H",
  //         "cut": "EXCL",
  //         "symmetry": null,
  //         "progName": null,
  //         "selectedProgramId": null,
  //         "kgRate": null,
  //         "selected": false,
  //         "isFileSelected": false,
  //         "fluorescence": null
  //       },
  //       {
  //         "id": 13022,
  //         "packetPlanId": 11353,
  //         "tag": 314,
  //         "expPol": "0.303",
  //         "shapeId": 655,
  //         "clarityId": 61,
  //         "cutId": 376,
  //         "colorId": 227,
  //         "pavAngle": "40.670",
  //         "pavHeight": "43.000",
  //         "crAngle": "35.620",
  //         "crHeight": "16.100",
  //         "tableVal": "55.000",
  //         "girdle": "3.900",
  //         "diameter": "4.270",
  //         "height": "63.000",
  //         "lOrW": "1.000",
  //         "rapoRate": "374.000",
  //         "sawMic": "70mic",
  //         "lengthMM": "4.270",
  //         "heightMM": "4.270",
  //         "widthMM": "2.690",
  //         "createdDate": "2022-07-16T09:09:29.000Z",
  //         "createdBy": "2-trybe-signer2",
  //         "modifiedDate": null,
  //         "modifiedBy": null,
  //         "isDeleted": 0,
  //         "planShape": "ROUND",
  //         "planColor": "H",
  //         "planCut": "EX",
  //         "planClarity": "VS2",
  //         "planSymmetry": "",
  //         "symmetryId": 0,
  //         "shape": "ROUND",
  //         "clarity": "VS2",
  //         "color": "H",
  //         "cut": "EXCL",
  //         "symmetry": null,
  //         "progName": null,
  //         "selectedProgramId": null,
  //         "kgRate": null,
  //         "selected": false,
  //         "isFileSelected": false,
  //         "fluorescence": null
  //       }
  //     ],
  //     "selected": false,
  //     "isExpand": true,
  //     "weightSum": 1.44,
  //     "plannedRemark": "",
  //     "totalKGRate": 0,
  //     "bestRate": 0,
  //     "totalRate": 0,
  //     "expPolPer1": 120.33,
  //     "expPolPer2": 120.33
  //   }];

  public ids = [];
  public tensionData = [];
  public stressData = [];
  public stressTypeData = [];
  public isDisable = true;
  public isAllChildFileSelected = false;
  public isAllChldPDSaved = false;

  totalPlans: any;

  dropDownFLData: any = {
    name: 'Fluorescence',
    placeText: 'Select FL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLData: any = {
    name: 'Color',
    placeText: 'Select CL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLAData: any = {
    name: 'Clarity',
    placeText: 'Select CLA',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownShapeData: any = {
    name: 'Shape',
    placeText: 'Select Shape',
    options: [],
    selectedData: [{ id: 0, name: 'All' }],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownPieceData: any = {
    name: 'Piece',//stone piece
    placeText: 'Select Piece',
    options: [],
    selectedData: [{ id: 0, name: 'All' }],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  isAllCollapse: boolean = false;
  selectedPlans: any = [];
  rowData: any = {
    "id": 2360,
    "packetId": "9169-01-CHP-8G#77",
    "cts": 2.332,
    "inDate": "2021-02-01T15:17:38.000Z",
    "lotNo": "9169-01-CHP-8G",
    "outDate": "2021-02-25T00:00:00.000Z",
    "refPacketId": null,
    "nfcCode": null,
    "pktGrade": "A",
    "pktStatus": "R",
    "pktNo": "77",
    "predLimit": 3,
    "currAction": 149,
    "currState": 2,
    "currDept": 11,
    "currLocation": 6,
    "userId": 135,
    "responsibleUserId": 134,
    "partNo": null,
    "createdDate": "2022-05-28T08:43:02.000Z",
    "createdBy": "2-trybe-dim",
    "modifiedDate": "2022-08-03T05:27:08.000Z",
    "modifiedBy": "2-trybe-signer1",
    "processActionInputId": "2852,2852",
    "historyStatus": "Pending"
  };
  rowDatas: any = [{
    "id": 2360,
    "packetId": "9169-01-CHP-8G#77",
    "cts": 2.332,
    "inDate": "2021-02-01T15:17:38.000Z",
    "lotNo": "9169-01-CHP-8G",
    "outDate": "2021-02-25T00:00:00.000Z",
    "refPacketId": null,
    "nfcCode": null,
    "pktGrade": "A",
    "pktStatus": "R",
    "pktNo": "77",
    "predLimit": 3,
    "currAction": 149,
    "currState": 2,
    "currDept": 11,
    "currLocation": 6,
    "userId": 135,
    "responsibleUserId": 134,
    "partNo": null,
    "createdDate": "2022-05-28T08:43:02.000Z",
    "createdBy": "2-trybe-dim",
    "modifiedDate": "2022-08-03T05:27:08.000Z",
    "modifiedBy": "2-trybe-signer1",
    "processActionInputId": "2852,2852",
    "historyStatus": "Pending"
  }];

  filteredPlan: any = [];
  changes: any = {};
  dropdown: any = {};
  chLength: number = 0
  // dynamic form Data
  currentSegment: string = 'Forms';
  formJson: any;
  showSubmit: boolean = true;
  refreshField: boolean = true;
  selectedRow: any = this.rowDatas[0];
  packetDetails: any = this.rowDatas[0];
  showDrawer: boolean = false;
  showRemoveButton: boolean = false;
  resetForm: boolean = true;
  actionStatus: any = !!this.rowDatas[0] ? this.rowDatas[0].historyStatus : '-';
  isFormRequired: boolean = true;
  actionName: string = '-';
  actionId: number;
  userData: any;
  userDataForFinalPlanning: any;
  showEdit: boolean = false;
  isPlanSaved: boolean = false;
  isUpdateDisable: boolean = true;

  currentPlan: any = {};
  currentParentPlan: any = {};
  selectedValue: any = [];
  placeValue: 'Select remark';
  keyword: 'remark';
  remarkDropDown: any = {
    selectedData: [],
    options: [],
    modeFlag: 'single',
    placeText: 'Select Remark',
    name: 'Remarks',
    key: 'name'
  };
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  remProg: any = [{ id: 1, name: 'Plan Program (Red)', selected: false }, { id: 2, name: 'Select Program (White)', selected: false }, { id: 3, name: 'All Program', selected: false }];
  searchShape: string = '';
  searchColor: string = '';
  searchClarity: string = '';
  searchCut: string = '';
  searchProgram: string = '';
  allData: any = [];
  currPacketCapFile: any;
  selectedRemarkProgram: any;
  selectedDropDnRemark: any = [];
  // signerLimit: number = 2;//no of Signer can Plan //Static data, need to discuss and remove it later
  // signerBestPlans: any = [];//no of Signer can Plan 
  // currSignerCount: number = 0;

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    public httpService: HttpService,
    private store: Store<AppState>,
    public alertController: AlertController,
    public popoverController: PopoverController,
    public _ConfigService: ConfigService
  ) { }


  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  ngOnInit() {
    this.store.pipe(select(authUser)).subscribe((d: any) => {

      this.userData = d;
      console.log('........action id');
      console.log(this.userData);
      // console.log(this.userData.listOfRoles)
      let temp: any = [];
      // this.userDataForFinalPlanning=temp[0].roleName;
      if (d.roles) {
        temp = d.roles.filter((r) => {
          return r == "Surat Checker"
        });
        this.userDataForFinalPlanning = !!temp[0] ? temp[0] : null;
      }
      if (d.listOfRoles) {
        temp = d.listOfRoles.filter((r) => {
          return r.roleName == "Surat Checker"
        });
        this.userDataForFinalPlanning = !!temp[0] && !!temp[0].roleName ? temp[0].roleName : null;
      }
    });
    this._ConfigService.scrollChanged.subscribe(val => {
      console.log(val);
      this.packetsGrid.nativeElement.scrollTop = val;

    })
    // fromEvent(this.packetsGrid.nativeElement,'scroll').subscribe((e: Event) => {
    //   debugger
    //   let p = e.target['scrollTop']
    //   console.log(p)
    // });

    this.store.pipe(select(getParamsValuesListByName('Tension Mapping'))).subscribe(data => {
      if (!!data) {
        this.tensionData = data
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Stress'))).subscribe(data => {
      if (!!data) {
        this.stressData = data
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Stress Type'))).subscribe(data => {
      if (!!data) {
        this.stressTypeData = data
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Fluorescence'))).subscribe(data => {
      if (!!data) {
        this.dropDownFLData.options = data;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Color'))).subscribe(data => {
      if (!!data) {
        this.dropDownCLData.options = data;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Clarity'))).subscribe(data => {
      if (!!data) {
        this.dropDownCLAData.options = data;
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Remarks'))).subscribe(data => {
      if (!!data) {
        this.remarkDropDown.options = data;
        console.log('..........Remarks');
        console.log(data);
      }
    });

    // this.store.dispatch(
    //   SignerAction.getPacketPlans({ packetIdOb: { packetId: "9776-09-FLT4-6#5" } })
    // );

    this.store.pipe(select(getAllPacketPlans)).pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
      if (plans) {
        console.log('getAllPacketPlans');

        // this.packetsGrid.nativeElement.setPosition(this.scrollPosition)
        console.log(plans);
        let hexes = [];
        if (plans.length === 0) {
          this.packetPlans = this.packetPlans;
        } else {
          this.packetPlans = plans;
          this.allData = plans;
        }

        let newPacketPlans = this.packetPlans.map((d: any) => {
          let remark = d.remarks
          let newPacketPlanStones: any = [];
          let weightSum: number = 0;
          let expPolPer1: number = 0;//some of Packet Plan stones/Org WT*100
          let expPolPer2: number = 0;//some of Packet Plan stones/Curr WT*100
          let currWt = this.rowData.cts;
          let orgWt = this.rowData.cts;
          let newOb = { ...d };
          let tag = ''
          if (!!d.packetPlanStones) {
            tag = !!d.packetPlanStones[0] ? d.packetPlanStones[0].tag : '';
            newPacketPlanStones = d.packetPlanStones.map((m: any, j: number) => {
              let obj = { ...m };
              // obj['piece'] = this.getHexValue(j);
              weightSum += parseFloat(m.expPol);
              return obj;
            });
            expPolPer1 = (weightSum / orgWt) * 100;
            expPolPer2 = (weightSum / currWt) * 100;
            newOb['expPolPer1'] = Number(parseFloat(expPolPer1.toString()).toFixed(2));
            newOb['expPolPer2'] = Number(parseFloat(expPolPer2.toString()).toFixed(2));
            newOb['packetPlanStones'] = newPacketPlanStones;
            newOb['tag'] = tag
            let remarkArray = [];
            if (remark) {
              remarkArray = this.remarkDropDown.options.filter(x => x.name == remark)
            }
            newOb['selectedRemark'] = remarkArray
            return newOb;
          }

        });
        this.packetPlans = newPacketPlans;
        this.planShapes = [...this.packetPlans].map(n => {
          let shape = ''
          let shapeId = ''
          //const copyOfObj = [...n.packetPlanStones];
          const packetPlanStones = n.packetPlanStones.map(x => {
            if (hexes.indexOf(x['piece']) < 0) {
              hexes.push(x['piece']);
            }
            if (x.planShape) {
              shape = x.planShape
              shapeId = x.shapeId
            }
          })
          return { name: shape, id: shapeId }

        })
        var uniq = {};
        this.planShapes = this.planShapes.filter(obj => !uniq[obj.name] && (uniq[obj.name] = true));
        this.planShapes.unshift({ 'name': 'All', id: 0 });
        this.dropDownShapeData['options'] = this.planShapes;
        this.planHex = hexes.map((x, k) => {
          return { name: x, id: k + 1 }
        });
        this.planHex.unshift({ 'name': 'All', id: 0 });
        this.dropDownPieceData['options'] = this.planHex;
        console.log(this.planHex);
        console.log(this.planShapes);
        console.log(this.packetPlans);
        this.totalPlans = this.packetPlans.length;
        //this.packetPlans =  this.packetPlans

        this.packetPlans = this.packetPlans.sort(function (a, b) {
          if (a.totalKGRate > b.totalKGRate)
            return -1;
          if (a.totalKGRate < b.totalKGRate)
            return 1;
          return 0;
        });

        for (let i = 1; i < this.packetPlans.length; ++i) {
          let PacketPlan = this.packetPlans[0];
          let test = PacketPlan.totalKGRate;
          const entry = this.packetPlans[i];
          test = entry.totalKGRate;
          // entry.totalKGRate = test;
          let rate = (test * 100 / PacketPlan.totalKGRate).toFixed(2);

          if (rate == 'NaN' || rate == 'Infinity' || rate == '-Infinity' || rate == '+Inifinity') {
            rate = '0';
          }
          //entry.bestRate = 0;//setting zero
          entry.bestRate = rate;
          console.log(rate, "Rate");
          // console.log(PacketPlan.totalKGRate, "Rate");
        }
        console.log(this.packetPlans, "array");

        // let param = { packetID: this.rowData.id, for: 'uploadCapFile' }
        // this.store.dispatch(
        //   SignerAction.getUploadedFile({ param: param })
        // );
      }
    });

    this.store.pipe(select(getCurrentRowData)).pipe(takeUntil(this.unsubscribe$)).subscribe((rd: any) => {
      if (rd) {
        console.log(rd);
        this.Id = rd[0].id;
        console.log(this.Id);
        // this.selectedRow = rd[0];
        // this.packetDetails = rd[0];
        // !!this.rowData[0] ? this.rowData[0].historyStatus : '-';
        if (rd.length == 0) {
          this.rowData = this.rowData;//////
          this.rowDatas = this.rowDatas;//////
        } else {
          this.rowData = rd[0];
          this.rowDatas = rd;
        }

        this.httpService.fetchHistoryDataByPacketId(this.rowData['id']).subscribe(history => {
          if (history) {
            if (!!history.data && !!history.data && history.data.length > 0) {

              let modifiedData = !!history.data ? history.data : [];
              modifiedData = modifiedData.filter(fd => fd.historyStatus == 'Completed');
              this.store.dispatch(masterDataActions.saveParameterHistory({ parameterHistory: modifiedData }));

              let csspData = history.data.filter((h: any) => h.actionName == 'CFSP');//Fluorescence
              let visionData = history.data.filter((h: any) => h.actionName == 'Vision 360');//Fluorescence
              if (csspData.length > 0 && csspData[0].inputDetails.length > 0) {
                let purityData = csspData[0].inputDetails.filter(x => x.paramName == "Purity")
                if (purityData.length > 0) {
                  let pValue = purityData[0].paramValue
                  this.rowData = { ...this.rowData, purity: pValue }
                }
              }
              if (visionData.length > 0 && visionData[0].inputDetails.length > 0) {
                let vision360Data = visionData[0].inputDetails.filter(x => x.paramName == "Vision 360")
                if (vision360Data.length > 0) {
                  let pValue = vision360Data[0].paramValue
                  this.rowData = { ...this.rowData, vision360: pValue }
                }
              }
            }
          }
        });

        this.store.pipe(select(getPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
          if (data) {
            this.planData = data;

            let fl = this.planData.filter(x => {
              if (x.paramName == 'Fluorescence') {
                //this.rowData = { ...this.rowData, fluorescence: x.paramValue }
                let fValue = this.dropDownFLData.options.filter(y => y.id == x.paramValue)[0].name
                this.rowData = { ...this.rowData, fluorescence: fValue }
                let newPacketPlans = this.packetPlans.map((d: any) => {
                  let newPacketPlanStones: any = [];
                  let weightSum: number = 0;
                  let newOb = { ...d };
                  if (!!d.packetPlanStones) {
                    newPacketPlanStones = d.packetPlanStones.map((m: any) => {
                      let obj = { ...m };
                      if (!obj.fluorescence) {
                        obj.fluorescence = fValue;
                      }
                      return obj;
                    });
                  }
                  newOb['packetPlanStones'] = newPacketPlanStones;
                  return newOb;
                });
                this.packetPlans = newPacketPlans;
              }

              if (x.paramName == 'Tension Mapping') {
                let tValue = this.tensionData.filter(y => y.id == x.paramValue)[0].name
                this.rowData = { ...this.rowData, stress: tValue }
              }

              if (x.paramName == 'Stress Type') {
                let stValue = this.stressTypeData.filter(y => y.id == x.paramValue)[0].name
                this.rowData = { ...this.rowData, stressType: stValue }
              }
            })
          }
        })

      }
    });
    this.store.pipe(select(getSelectedPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      if (plan) {
        console.log('Selected plan');
        console.log(plan);
        this.selectedPlans = plan;
        console.log(this.selectedPlans);
      }
    });
    this.store.pipe(select(getCurrentAction)).pipe(takeUntil(this.unsubscribe$)).pipe(filter(data => !!data), first()).subscribe((data: any) => {
      if (data) {
        console.log(data);
        this.actionStatus = data.status;
        this.actionName = data.name;
        this.actionId = data.id;
      }
    });
    this.store.pipe(select(getFilteredPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      if (plan) {
        console.log(plan);
        this.filteredPlan = plan;
      }
    });
    console.log("%cngOnInit", "color:green;font-weight:bold;")
    if (!!this.rowDatas[0] && !!this.rowDatas[0].inputDetails && this.rowDatas[0].inputDetails.length > 0) {
      this.isPlanSaved = true;
    }
    this.store.pipe(select(getUploadedFileData)).subscribe((capFile) => {
      if (!!capFile) {
        this.currPacketCapFile = capFile;
        console.log('getUploadedFileData');//capFile
        console.log(this.currPacketCapFile);

      }
    });
    // this.store.dispatch(SignerAction.getProgramByPacketIdAndPlanStoneId())
    // fetchProgramByPacketIdAndPlanStoneId
    // this.store.dispatch(getSignerBestPlans({ pktData: this.rowData, message: 'fetch' }));
    // this.store.pipe(select(getSignerBestPlanData)).subscribe(splan => {
    //   if (splan) {
    //     console.warn('signer Best Plan');
    //     console.log(splan);
    //     let newData = [];
    //     this.signerBestPlans = splan;
    //     splan.forEach(d => {
    //       if (!newData.includes(d.signerId)) {
    //         console.log(newData);
    //         newData.push(d.signerId);
    //       }
    //     });
    //     this.currSignerCount = newData.length;
    //   }
    // });
    //for testing purpose only
    // this.store.dispatch(SignerAction.getPacketPlans({ packetIdOb: { packetId: this.rowData.packetId } }));//for testing purpose only
    console.log('this.rowData');
    console.log(this.rowData);
  }
  twoDecimalvalues(x) {
    return parseFloat(parseFloat(x).toFixed(2));
  }
  async getPrograms(rowData, currPlan) {
    console.log('getPrograms');
    let packetID = rowData.packetId;
    let planStoneId = rowData.id;
    this.currentPlan = currPlan;
    this.currentParentPlan = rowData;
    console.log(currPlan)
    console.log(packetID)
    console.log(planStoneId)
    await this.store.dispatch(SignerAction.getProgramByPacketIdAndPlanStoneId({ packetId: packetID, planStoneId: planStoneId }));
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'signerModal',
      componentProps: {
        "message": 'showProgram',
        'data': rowData,
        'plan': currPlan,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }
  async openmodel() {
    console.log("openmodel");
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'signerModal',
      componentProps: {
        "message": 'Test Ganesh',
        'data': {}
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }
  onModalExit(d: any) {
    console.log('---onModalExit');
    console.log(d);
    if (!!d.data) {
      if (d.data.for == 'saveProgram') {
        let packetId = this.currentParentPlan.packetId;
        let planStoneID = this.currentPlan.id;
        let progName = d.data.program.progName;
        let progId = d.data.program.id;
        if (this.changes['progName'] == undefined) {
          this.changes['progName'] = {};
          this.changes['progName'] = d.data.program;
        } else {
          this.changes['progName'] = d.data.program;
        }
        this.chLength = Object.keys(this.changes).length;
        this.isDisable = this.chLength !== 0 ? false : true;
        console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
        console.log(this.chLength);
        console.log(this.isDisable);
        this.store.dispatch(SignerAction.showSelectedProgram({ packetID: packetId, planStoneID: planStoneID, program: progName, programID: progId }));

      } else if (d.data.for == 'savePlannedRemark') {
        console.log('Planned Remark');
        console.log(d);
        this.packetPlans = [...this.packetPlans].map(n => {
          let p = { ...n }
          if (p.id == d.data.packetPlanID) {
            p.plannedRemark = d.data.plannedRemark;
          }
          return p;
        })
      }
    }
  }

  togglingRow() {
    this.isAllCollapse = !this.isAllCollapse ? true : false;
    if (this.isAllCollapse) {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: false }));
    } else {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: true }));
    }
    console.log(this.packetPlans);
  }

  onToggleBtnClick() {
    this.togglingRow();
    console.log(this.isAllCollapse);
  }
  onRefreshingCurrRate() {
    console.log('-------onRefreshingCurrRate......');

  }

  onChkChange(elm: any, res: any) {
    console.log(elm);
    console.log(res);

  }
  onParentCBChange(evt: any) {
    setTimeout(() => {
      this.packetPlans.forEach((item: any) => {
        item.selected = this.parentCheckbox;
      });
    });
  }

  async onParentPlanSelection(evt, rd) {
    console.log('---------onParentPlanSelection-------------');
    console.log(evt);
    console.log(rd);
    let order = rd.selectionOrder
    console.log(evt.detail.checked);
    console.log(this.packetPlans);
    let isAllChildFileSelected = false;
    let isAllChldPDSaved = false;

    if (evt.detail.checked) {
      console.log('00000000000000000000000000');
      this.remarksPlanID.push(rd.id);
      let chkSelFileLength = 0;
      let chkPdSavedLength = 0
      let childRows = rd.packetPlanStones;
      let childRowsLength = rd.packetPlanStones.length;
      childRows.forEach((d: any) => {
        if (d.isFileSelected || d.xpsFile) {
          chkSelFileLength++;
        }
        if (d.pdSaved) {
          chkPdSavedLength++;
        }
      });
      if (childRowsLength == chkSelFileLength) {
        isAllChildFileSelected = true;
      }

      if (childRowsLength == chkPdSavedLength) {
        isAllChldPDSaved = true;
      }

      let order = 1;
      const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      if (indexes.length > 0) {
        console.log(order)
        order = Math.max(...indexes) + 1;
      }

      this.packetPlans = this.packetPlans.map(x => {
        if (x.id == rd.id) {
          //let clone = { ...x };
          x['selectionOrder'] = order
          x['selected'] = true

        }
        return x
      })
    } else {
      this.packetPlans = this.packetPlans.map(x => {
        if (x.id == rd.id) {
          let n = { ...x }
          n['selected'] = false
          delete n.selectionOrder
          return n
        } else {
          return x
        }

      })
      const indexes = this.packetPlans.filter(object => object.selectionOrder).map(x => x.selectionOrder)
      if (indexes.length > 0) {
        this.packetPlans = this.packetPlans.map(x => {
          let n = { ...x }
          if (n.selectionOrder && n.selectionOrder > order) {
            n.selectionOrder = n.selectionOrder - 1
          }
          return n
        })
      }


    }
    console.log(isAllChildFileSelected);
    console.log(isAllChldPDSaved);
    console.log(!this.selectedFile);
    console.log(this.selectedFile);
    if (evt.detail.checked && !this.currPacketCapFile && !isAllChildFileSelected) {
      const alert = await this.alertController.create({
        header: 'Please upload Cap File and XPS file First',
        // subHeader: 'Subtitle for alert',
        // message: 'Are you sure doyou want to delete the row?',
        cssClass: 'my-custom-class',
        buttons: [{
          text: 'OK',
          handler: (d: any) => {
            this.clearSelection(evt, rd)
            console.log('OK', d);
          }
        }]
      });
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        this.clearSelection(evt, rd)
      });
      return;
    }
    if (evt.detail.checked && !this.currPacketCapFile) {
      const alert = await this.alertController.create({
        header: 'Please upload Cap File First',
        // subHeader: 'Subtitle for alert',
        // message: 'Are you sure doyou want to delete the row?',
        cssClass: 'my-custom-class',
        buttons: [{
          text: 'OK',
          handler: (async (d: any) => {
            this.clearSelection(evt, rd)
            console.log('OK', d);
          })
        }]
      });
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        this.clearSelection(evt, rd)
      });
      return;
    }
    if (evt.detail.checked && !isAllChildFileSelected) {
      const alert = await this.alertController.create({
        header: 'Please upload XPS File First',
        // subHeader: 'Subtitle for alert',
        // message: 'Are you sure doyou want to delete the row?',
        cssClass: 'my-custom-class',
        buttons: [{
          text: 'OK',
          handler: (d: any) => {
            this.clearSelection(evt, rd)
            console.log('OK', d);
          }
        }]
      });
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        this.clearSelection(evt, rd)
      });
      return
    }
    // if (evt.detail.checked && !isAllChldPDSaved) {
    //   const alert = await this.alertController.create({
    //     header: 'Please Add PD Parameters',
    //     // subHeader: 'Subtitle for alert',
    //     // message: 'Are you sure doyou want to delete the row?',
    //     cssClass: 'my-custom-class',
    //     buttons: [{
    //       text: 'OK',
    //       handler: (d: any) => {
    //         this.clearSelection(evt, rd)
    //         console.log('OK', d);
    //       }
    //     }]
    //   });
    //   await alert.present();
    //   await alert.onDidDismiss().then((data) => {
    //     this.clearSelection(evt, rd)
    //   });
    //   return
    // }

    const checkboxes = this.packetPlans.length;
    // let selected = 0;
    // this.packetPlans.map((d: any) => {
    //   console.log(d);

    //   if (d.selected) selected++;
    // });

    // if (selected > 0 && selected < checkboxes) {
    //   this.setIndeterminateState = true;
    //   this.parentCheckbox = false;
    // } else if (selected == checkboxes) {
    //   this.parentCheckbox = true;
    //   this.setIndeterminateState = false;
    // } else {
    //   this.setIndeterminateState = false;
    //   this.parentCheckbox = false;
    // }
    console.log(checkboxes);
    // console.log(selected);
    // console.log(this.setIndeterminateState);
    this.store.dispatch(SignerAction.changePlanOncheck({ plan: rd, isChecked: evt.detail.checked }));

  }

  async clearSelection(evt, rd) {
    evt.detail.checked = false;
    this.packetPlans = this.packetPlans.map(x => {
      if (x.id == rd.id) {
        let n = { ...x }
        n.checked = false
        n.selected = false;
        delete n.selectionOrder
        return n
      } else {
        return x
      }

    })
  }

  async selectPlan(evt: any, rowId, index) {
    let val = evt.target.checked
    this.packetPlans = await [...this.packetPlans].map(n => {
      if (n.id == rowId) {
        //const copyOfObj = [...n.packetPlanStones];
        const packetPlanStones = n.packetPlanStones.map(x => {
          return {
            ...x,
            selected: !val
          }

        })
        return { ...n, selected: !val, packetPlanStones }
      } else {
        return n
      }
    })
    this.validationCheck()
  }
  selectChildRow(evt: any) {
    console.log('Select Children');
    console.log(evt);
  }
  dropDownSelection(evt: any, plan: any) {
    console.log('dropDownSelection');
    console.log(evt);
    console.log(this.changes);
    if (evt.property == "Remarks") {
      console.log('remark');
      console.log(evt);
      this.setChanges(evt, plan);
    } else if (evt.property == 'Fluorescence') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Color') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Clarity') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Shape') {
      this.dropDownShapeData.selectedData = evt.arrayList;
    } else if (evt.property == 'Piece') {
      this.dropDownPieceData.selectedData = evt.arrayList;
    }
  }
  selectionChange(evt: any) {
    console.log('evt');
    console.log(evt);

  }
  setChanges(evt: any, plan: any) {
    console.log(evt, 'set Changes');
    console.log(plan, 'set Changes');

    let dropDnValues = evt.arrayList[0];
    if (evt.arrayList.length > 0 && !!plan && !!plan.id) {
      let obj = {
        planID: plan.id,
        ...evt.arrayList[0]
      };
      console.log(obj);
      let currRecordIndex = this.selectedDropDnRemark.findIndex((i: any) => i.planID == plan.id);
      if (this.selectedDropDnRemark.length > 0 && currRecordIndex > -1) {
        this.selectedDropDnRemark[currRecordIndex] = obj;
      } else {
        this.selectedDropDnRemark.push(obj);
      }
    } else {
      let currRecordIndex = this.selectedDropDnRemark.findIndex((i: any) => i.planID == plan.id);
      console.log(currRecordIndex);
      if (currRecordIndex > -1) {
        this.selectedDropDnRemark.splice(currRecordIndex, 1);
      }
    }
    console.log(this.selectedDropDnRemark);
    this.chLength = this.selectedDropDnRemark.length;
    this.isDisable = this.chLength !== 0 ? false : true;
    console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
    console.log(this.chLength);
    console.log(this.isDisable);
  }
  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }

    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }
  showDetail(evt: any, item: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(item);
    // if (item.isExpand == true) {
    //   item.isExpand = false;
    // } else {
    //   item.isExpand = true;
    // }
  }
  async onMenuIconClick(evt, rowData) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(evt);
    console.log(rowData);
    this.currentParentPlan = rowData;
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'remark',
      componentProps: {
        // "message": 'showRemarks',
        "message": 'remark',
        'data': rowData,
        // 'plan': currPlan,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }
  async onRemarkClick(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

  }
  onRemarkChange(evt: any, val: string) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('-------onRemarkChange......');
    console.warn(val)
    // alert(val);
  }
  async onClickPD(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('-------PD......');
    // console.log
    const pdModal = await this.modalCtrl.create({
      component: PDComponent,
      cssClass: 'popup',
      componentProps: {
        // "message": 'message',
        'data': { packetPlanStoneId: id, packetId: this.rowData.packetId }
      },
    });


    pdModal.onDidDismiss().then((d: any) => {
      if (d.data) {
        this.PDValueSubmission(id)
      }
    });
    return await pdModal.present();
    // this.router.navigate(['/PD']);

  }

  PDValueSubmission(packetPlanStoneId: any) {
    console.log(packetPlanStoneId)
    // this.packetPlans = this.packetPlans.map(x => {
    //   if (x.packetPlanStones.length > 0) {
    //     let newPd = x.packetPlanStones.map(b => {
    //       if (b.id == packetPlanStoneId) {
    //         return {
    //           ...b,
    //           pdSaved: true
    //         }
    //       } else {
    //         return b
    //       }

    //     })
    //     return {
    //       ...x,
    //       packetPlanStones: newPd
    //     }
    //   }

    // })
    console.log('PDValueSubmission');
    console.log(this.packetPlans)

    // this.store.dispatch(SignerAction.savePDParameterPacketPlanStone({pdParam:}));
  }

  async showVision360() {
    console.log('showVision360');
    console.log(this.rowData);
    const modal = await this.modalCtrl.create({
      component: CopyplanmodalComponent,
      cssClass: 'showImage_Video',
      componentProps: {
        "message": 'showVision360',
        'data': this.rowData,
        // 'plan': currPlan,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data) {
        this.onModalExit(data);
      }

    });
    return await modal.present();
  }
  async copyPlan(evt: any, planData) {
    evt.preventDefault();
    evt.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: CopyplanmodalComponent,
      cssClass: 'extraLargePage',
      componentProps: {
        'data': planData,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.copyPlanExit(data.data);
    });
    return await modal.present();
  }

  async copyPlanExit(modalData) {
    if (modalData) {
      // let mac = [...this.packetPlans].map(x => x.id)
      // console.log(mac)
      let maxId = Math.max(...this.packetPlans.map(x => x.id));
      let maxTag = Math.max(...this.packetPlans.map(x => x.tag));
      this.packetPlans.map(x => {
        console.log(x.seqNo)
        let i = parseInt(x.seqNo)
        if (typeof i == 'number') {
          return i
        }

      })
      let maxSeqNo = Math.max(...this.packetPlans.map(x => {
        let j = parseInt(x.seqNo)
        console.log(typeof j)
        if (typeof j == 'number') {
          return j
        }
      }).filter(x => !!x))
      console.log(maxSeqNo)
      let copyPlan = { ...modalData };
      copyPlan.tag = maxTag + 1;
      copyPlan.id = maxId + 1;
      //copyPlan.seqNo = maxSeqNo + 1
      copyPlan['copyFrom'] = modalData.tag
      console.log(copyPlan)
      // this.packetPlans.push(copyPlan)
      let packets = [...this.packetPlans]
      packets.push(copyPlan);
      console.log(packets)
      this.packetPlans = packets
      console.log(modalData)
      let packetPlanId = modalData.id
      let packetId = this.rowData.id
      let seqNo = copyPlan.seqNo
      let tag = copyPlan.tag
      let packetStones = copyPlan.packetPlanStones.map(x => {
        let n = { ...x };
        let newObj = {
          "packetId": packetId,
          "seqNo": seqNo,
          "tag": tag,
          "expPol": n.expPol,
          "shapeId": n.shapeId,
          "cutId": n.cutId,
          "clarityId": n.clarityId,
          "colorId": n.colorId,
          "pavAngle": n.pavAngle,
          "pavHeight": n.pavHeight,
          "crAngle": n.crAngle,
          "crHeight": n.crHeight,
          "tableVal": n.tableVal,
          "girdle": n.girdle,
          "diameter": n.diameter,
          "height": n.height,
          "lengthMM": n.lengthMM,
          "heightMM": n.heightMM,
          "widthMM": n.widthMM,
          "lOrW": n.lOrW,
          "planShape": n.planShape,
          "planColor": n.planColor,
          "planClarity": n.planClarity,
          "planCut": n.planCut
        }
        return newObj
      })
      console.log(packetStones)
      this.store.dispatch(SignerAction.copyPacketPlan({ packetPlanId: packetPlanId, planData: packetStones }))
      // let newCopyObj = {
      //   packetId:copyPlan.packetId
      //   seqNo : copyPlan.seqNo,
      //   tag:
      //   copyPlan
      // }
    }
  }

  onPlanChange(data) {
    console.log('onPlanChange');
    console.log(data);

  }

  async showDiff() {
    // for (let i = 0; i < this.packetPlans.length - 1; i++) {
    //   let sub = this.packetPlans[i + 1].seqNo - this.packetPlans[i].seqNo;
    // }
    let diff = 0;
    console.log(this.packetPlans[0])
    let slectedArray = this.packetPlans.filter(x => x.diffSelect)
    if (slectedArray.length == 2) {
      if (slectedArray[0].totalRate > slectedArray[1].totalRate) {
        diff =
          (1 - slectedArray[1].totalRate / slectedArray[0].totalRate) *
          100;
        console.log(diff);
      } else {
        diff =
          (1 - slectedArray[0].totalRate / slectedArray[1].totalRate) *
          100;
        console.log(diff);
      }


      const alert = await this.alertController.create({
        header: `Difference between Seq. No. ${slectedArray[0].tag} and ${slectedArray[1].tag} is ${diff.toFixed(2)}%`,
        cssClass: 'my-custom-class',
        buttons: [
          {
            text: 'CLOSE',
          },
          {
            text: 'OK',
          },
        ],
      });
      await alert.present();
    }
  }
  async deleteRow(evt: any, id: number) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(id, 'packetplanid');
    console.log('-------Delete......');

    // alert(id);
    // let result = confirm('Are you sure to delete the row?');
    // if (result) {
    //   let updatedSignerData = this.packetPlans.filter(d => d.id != id)
    //   console.log(updatedSignerData);
    //   console.log(this.packetPlans);
    //   this.packetPlans = updatedSignerData;
    // }

    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'CLOSE',
        handler: (d: any) => {
          console.log('Closed', d);
        }
      },
      {
        text: 'OK',
        handler: (d: any) => {
          console.log('-----------temp-----------');
          // let updatedSignerData = this.packetPlans.filter((d) => d.id != id);
          // this.packetPlans = updatedSignerData;
          this.store.dispatch(
            SignerAction.deletePacketPlan({
              packetPlanID: id,
              packetIdOb: { packetId: this.rowData.packetId },
            })
          );
        }
      }]
    });

    await alert.present();
  }
  //delete single part plan of a Plan
  async deletePartPlan(plan) {
    console.log('object :>>deletePartPlan');
    this.showAlert(plan)
  }
  async showAlert(data) {
    let id = data.id;
    console.log(id, 'packetpartplanid');
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'CLOSE',
          handler: (d: any) => {
            console.log('Closed', d);
          },
        },
        {
          text: 'OK',
          handler: (d: any) => {
            console.log('Ok Information', d);
            console.log('Packet Plan ID', data.id);
            this.store.dispatch(
              SignerAction.deletePartPacketPlan({
                id: data.id,
                packetIdOb: { packetId: this.rowData.packetId },
              })
            );
          },
        },
      ],
    });
    await alert.present();
  }

  parentPlanChange(evt: any, inData: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(inData, "indata")
    console.log('-----------parentPlanChange-----------');
    console.log(this.packetPlans)

    let filter = this.packetPlans.filter(x => x.diffSelect)
    console.log(filter.length)
    if (evt.detail.checked && filter.length < 2) {

      this.packetPlans = this.packetPlans.map(s => {
        if (s.id == inData.id) {
          return {
            ...s,
            diffSelect: true,
            checked: true
          }
        } else {
          return s
        }
      })
    } else {

      this.packetPlans = this.packetPlans.map(s => {
        if (s.id == inData.id) {
          return {
            ...s,
            diffSelect: false,
            checked: false
          }
        } else {
          return s
        }

      })
    }
    // if (evt.detail.checked) {
    //   this.remarksPlanID.push(inData.id);
    //   console.log(this.remarksPlanID, "remarkplanID");
    // }
    // else {
    //   let found = this.remarksPlanID.some((e) => e.id == inData.id)
    //   if (found) {
    //     this.remarksPlanID.splice(inData.id);
    //   }
    // }

    // this.store.dispatch(SignerAction.changePlanOncheck({ plan: inData, isChecked: evt.detail.checked }));
  }
  childPlanChange(evt: any, d: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('childPlanChange');
    console.log(evt);
    console.log(d);
  }

  submitPlan() {
    console.log('submitPlan');
    console.log(this.rowData);

    this.selectedPlans = this.packetPlans.filter(x => {
      if (x.selected) {
        return x
      }
    })
    console.log(this.selectedPlans);
    this.selectedRow = this.rowData;
    this.packetDetails = this.rowData;
    let currentPacketId = this.rowData.id;
    console.log(currentPacketId);

    if (!!currentPacketId) {
      console.log('Show Drawer');
      this.showDrawer = true
      setTimeout(() => {
        this.showDrawer = false;
      });
    }
  }

  async savePlans() {
    console.log('---savePlans---');
    console.log(this.rowData);
    console.log('---savePlan0ghhbs---');
    console.log(this.selectedPlans);
    console.log('---savePlans---fdhfg');
    console.log(this.rowData.id);
    console.log('---savePlans---555555');
    console.log(this.userDataForFinalPlanning);
    // this.store.dispatch(getSignerBestPlans({ packetId: this.rowData.id }));

    let listOfPlans = this.selectedPlans.map(d => {
      return d.id;
    });
    let plansPayload = {
      "listOfPacketPlaneIdList": listOfPlans
    }
    console.log(plansPayload);
    if (this.userDataForFinalPlanning == "Surat Checker") {
      console.log("Do new thing");
      // console.log()
      this.store.dispatch(chekerAction.saveSelectedPlans({
        packetPlanID: { packetPlanId: this.selectedPlans[0].id },
        pktData: this.rowData
      }));
    }
    else {

      this.store.dispatch(SignerAction.saveSignerBestPlans({ payload: plansPayload, pktData: this.rowData }));
      this.store.dispatch(SignerAction.removeSelectedPlans());
    }
    // console.log(this.signerLimit);
    // if (this.currSignerCount === this.signerLimit) {
    //   console.log('GOING TO SAVE PARAMETER and PLAN DONE ');
    //   this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: formType, barcodeObj: barcodeObj }));
    // }
    // if (this.selectedPlans[0].id) {
    //   this.httpService.setWinnerPlan(this.selectedPlans[0].id).subscribe(res => {
    //     if (res) {
    //       console.warn('Winner Plan saved');
    //       console.warn(res);
    //     }
    //   });
    // }

    // setTimeout(() => {
    // let saveData = {};
    // let listOfParameter = [];
    // this.signerBestPlans.forEach((d: any, k: number) => {
    //   let snNo = k + 1;
    //   let paranmName = "Plan " + snNo;
    //   let obj = {
    //     "parameterName": paranmName,
    //     "parameterValue": d.id
    //   }
    //   listOfParameter.push(obj);
    // })
    // let formType = "JSON";
    // saveData['packetId'] = this.selectedRow.id;
    // saveData['listOfParameter'] = listOfParameter;

    // let barcode = this.selectedRow.packetId;

    // let obj = {
    //   "listOfAllPackets": []
    // }
    // let barcodeObj = {
    //   packetId: barcode
    // }
    // obj['listOfAllPackets'].push(saveData);
    // // this.rowData = {}
    // console.log(obj);
    // console.log(this.signerBestPlans);
    // console.log(this.currSignerCount);
    // console.log(this.signerLimit);
    // if (this.currSignerCount === this.signerLimit) {
    //   console.log('GOING TO SAVE PARAMETER and PLAN DONE ');
    //   this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: formType, barcodeObj: barcodeObj }));
    // }
    // this.selectedPlans = [];
    // this.signerBestPlans = [];
    // }, 1200);

  }

  async validationCheck() {
    this.selectedPlans = this.packetPlans.filter(x => x.selected)
    console.log(this.selectedPlans)
    if (this.selectedPlans.length > 0) {
      await this.selectedPlans.filter(n => {
        let packetsLength = n.packetPlanStones.length
        let fileLength = n.packetPlanStones.filter(x => x.isFileSelected).length;
        console.log(this.selectedFile)
        if (packetsLength == fileLength && this.selectedFile) {
          // this.isDisable = false
          //this.httpService.showToast("danger", "Please Upload files for plans");
        } else if (this.selectedFile) {
          // this.isDisable = true
        }

      })
    }
  }

  childRowsClasses(plan) {
    let classes = ''
    if (plan.isExpand) {
      classes = 'childRow show';
    } else {
      classes = 'hide'
    }
  }

  saveDetailInAPlan() {
    console.log('save/updatePlans');
    console.log(this.rowData);
    console.log(this.selectedPlans);
    let selPacket = this.rowDatas[0];
    let currentPacketId = this.rowDatas[0].id;
    console.log(this.selectedDropDnRemark);
    let payLoad: any = [];
    this.selectedDropDnRemark.forEach((d: any) => {
      let obj: any = {
        'packetPlan': d.planID,
        'remark': d.name,
      };
      payLoad.push(obj);
    });

    console.log(payLoad);
    console.log('changes');
    console.log(this.changes);
    this.store.dispatch(SignerAction.updatePacketPlan({ updateData: payLoad }));
    //updatePacketPlan
    if (!!currentPacketId) {
      console.log('Show Drawer');
      this.showDrawer = true
      setTimeout(() => {
        this.showDrawer = false;
      });
    }
    setTimeout(() => {
      payLoad = [];
    }, 1000);
  }
  onConfirmBoxClosure(d) {
    console.log('onConfirmBoxClosure :>> ');
    console.log(d);
  }
  onProgramRemark() {
    this.remProg = this.remProg.map((item) => {
      let l = { ...item }
      l.selected = false
      return l;
    });
    console.log(this.remProg, "hello");
  }
  async onProgramRemSelection(ev: any, d: any) {
    console.log('onProgramRemSelection');
    console.log(d);
    this.selectedRemarkProgram = {};
    this.remProg = this.remProg.map((item) => {
      let l = { ...item }
      if (!!l.hasOwnProperty('selected') && l.id == d.id) {
        this.selectedRemarkProgram = d;
        l.selected = true
      } else {
        l.selected = false
      }
      return l;
    });
    console.log(d);
    console.log(this.remProg);
    console.log(this.selectedRemarkProgram);
    await this.popoverController.dismiss();
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'popupSigner',
      // showBackdrop: false,
      componentProps: { id: d.id, name: d.name, grades: this.planHex, forPage: 'Signer' },
      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss()
      .then((res) => {
        if (res.data) {
          // this.pushOverOpen = false;
          console.log('Signer pushOver Exit');
          console.log(res);
          this.onProgramRemarkSubmission(res);
        }
      });
  }

  async onProgramRemarkSubmission(d) {
    console.log('onProgramRemarkSubmission');
    console.log(d);
    let Remark = d.data.selectProgramRemark.name;
    let grade = this.gradeToNum[Remark]//d.data.selectProgramRemark.id;
    console.log(grade, "grade");
    let gradeIds = []
    gradeIds = await d.data.selectedGrades.filter(x => x.id).map(n => n.id)
    let listOfPacketPlaneIdList = await this.packetPlans.map(x => x.id)
    this.store.dispatch(SignerAction.addRemarkForPacketPlan({
      remark: Remark,
      Grade: gradeIds,
      listOfPacketPlaneIdList: listOfPacketPlaneIdList,
      packetIdObj: { packetId: this.rowData.packetId },
      refresh:true,
    }))
  }
  ngOnDestroy() {
    console.log('%cPacket Plan Component Destroyed', 'color:orange;font-size:.625rem');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(tempDataActions.removeCurrentRowData());
    this.store.dispatch(SignerAction.removeSelectedPlans());
    this.store.dispatch(SignerAction.removeSavePacketPlans());
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = <File>event.target.files[0];
      this.validationCheck()
    }
  }

  async uploadRowFile(event, planId, rowId, index) {
    console.log(planId, index)
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      //console.log(this.packetPlans);
      this.packetPlans = [...this.packetPlans].map(n => {
        if (n.id == rowId) {
          const formatData = new FormData();
          formatData.append("file", selectedFile);
          formatData.append("packetPlanStoneId", planId)
          let otherParam = { packetPlanStoneId: planId, for: 'uploadXpsOrHtmlFile', "packetID": rowId }
          this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));
          //const copyOfObj = [...n.packetPlanStones];
          const packetPlanStones = n.packetPlanStones.map(x => {
            if (x.id == planId) {
              return {
                ...x,
                isFileSelected: true,
                xpsFile: selectedFile
              }
            } else {
              return x
            }
          })
          return { ...n, packetPlanStones }
        } else {
          return n
        }
      })
      // this.packetPlans.filter(n => n.id == rowId)[0].packetPlanStones.filter(x => x.id == planData.id).map(y => {
      //   let newObj = Object.assign({ file: selectedFile }, y);
      //   console.log(newObj)
      //   return newObj
      // })
      console.log(this.packetPlans)
      this.validationCheck()
    }
  }

  // ionViewDidEnter() {
  //   console.log('%cionViewDidEnter', 'color:green;font-weight:bold;');
  //   this.allData = this.packetPlans;
  // }
  filterAsSearched(ev: any, searchStr: string, val: any) {
    console.log('%cfilterAsSearched =' + val, 'color:green;font-weight:bold;');
    console.log(this.allData);
    // this.packetPlans = this.allData.filter((d) => {
    //   return d.seqNo.toLowerCase().indexOf(val.toLowerCase()) > -1;
    // });
    if (searchStr != null || val != null) {
      this.packetPlans = this.allData.filter((d: any) => {
        return d.packetPlanStones.some((x: any) => {
          return d.id === x.packetPlanId && x[searchStr].toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
      });
    }

    console.log(this.allData);
    console.log(this.packetPlans);

  }
  uploadCapFile() {
    console.log('...........uploadCapFile..........');
    console.log(this.selectedFile);
    let packetId = this.rowData.id;
    const formatData = new FormData();
    console.log(packetId);
    console.log(formatData);
    formatData.append("file", this.selectedFile);
    console.log(formatData);
    let otherParam = { packetID: packetId, for: 'uploadCapFile' }
    console.log(otherParam);
    this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));//uploadCapFile
    this.selectedFile = null
  }
  async updateFields() {
    console.log('..........updateFields..........');
    const alert = await this.alertController.create({
      header: 'Do you want to update?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'OK',
        handler: (d: any) => {
          this.makeChangesToPlans();
        }
      }]
    });
    await alert.present();
  }
  makeChangesToPlans() {
    console.log('makeChangesToPlans');
    let parameters = {};
    let gradeToNum = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26 };
    let fl = this.dropdown['Fluorescence'];
    let cl = this.dropdown['Color'];
    let cla = this.dropdown['Clarity'];
    let sha = this.dropDownShapeData.selectedData[0];
    let pie = this.dropDownPieceData.selectedData[0];
    let fluorescence = !!fl ? fl.name : '';
    let fluorescenceID = !!fl ? fl.id : null;
    let color = !!cl ? cl.name : '';
    let colorID = !!cl ? cl.id : null;
    let clarity = !!cla ? cla.name : '';
    let clarityID = !!cla ? cla.id : null;
    let shape = !!sha ? sha.name : '';
    let shapeId = !!sha ? sha.id : null;
    let piece = !!pie ? pie.name : '';
    let packetID = this.rowData.id;
    let packetIdObj = { packetId: this.rowData.packetId };
    parameters = {
      'packetID': packetID,
      'fluorescence': fluorescence,
      'fluorescenceID': fluorescenceID,
      'color': color,
      'colorID': colorID,
      'clarity': clarity,
      'clarityID': clarityID,
      'shape': shape,
      'shapeId': shapeId,
      'piece': piece
    }
    let postData = {
      "flourescenceId": fluorescenceID,
      "packetId": packetID,
      "grade": !!gradeToNum[piece] ? gradeToNum[piece] : null,
      "clarityId": clarityID,
      "colorId": colorID,
      "shapeId": !!shapeId ? shapeId : null
    }
    console.log(fluorescence);
    console.log(fluorescenceID);
    console.log(color);
    console.log(colorID);
    console.log(clarity);
    console.log(clarityID);
    console.log(shape);
    console.log(shapeId);
    console.log(piece);
    console.log(parameters);
    this.store.dispatch(SignerAction.updateFLCLClAInPacketPlanStone({ updateData: postData, packetIdObj: packetIdObj, parameters: parameters }));
    // this.store.dispatch(SignerAction.updateDropDownParam({ updateData: parameters }));
    this.dropdown = {};
    if (fluorescenceID != null) {
      this.dropDownFLData.selectedData = [];
    }
    if (colorID != null) {
      this.dropDownCLData.selectedData = [];
    }
    if (clarityID != null) {
      this.dropDownCLAData.selectedData = [];
    }
  }

  download(file) {
    window.open(file, 'Download');
  }

  identify(index, item) {
    return item.selected;
  }

  setPosition() {

  }

  scroll(data) {
    this._ConfigService.scrollPosition = data.target.scrollTop;
  }

  logScrollStart(event) {
    console.log("logScrollStart : When Scroll Starts", event);
  }

  logScrolling(event) {
    console.log("logScrolling : When Scrolling", event);
  }

  logScrollEnd(event) {
    console.log("logScrollEnd : When Scroll Ends", event);
  }
}
