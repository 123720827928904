import { createAction, props } from "@ngrx/store";
import { UsersByAction, UsersByVirtualAction } from "./issue.data";

export const getUsersByAction = createAction(
    '[WithMe] Get Users by Action',
    props<{ actionId: number, packetIDs: any, isVirtual: number }>()
);

export const saveUsersByAction = createAction(
    '[WithMe] Users by Action saved',
    props<{ usersbyAction: UsersByAction[] }>()
);
export const getUsersByVirtualAction = createAction(
    '[WithMe] Get Users by Virtual Action',
    props<{ actionId: number, packetIDs: any }>()
);

export const saveUsersByVirtualAction = createAction(
    '[WithMe] save Users by Virtual Action saved',
    props<{ usersbyVirtualAction: UsersByVirtualAction[] }>()
);
// export const issuePacket = createAction(
//     '[WithMe] Issue Packets called',
//     props<{ packetDetails: any }>()
// );
export const fetchProcessByPackets = createAction(
    '[WithMe] Fetch Process by packet', //stone actions
    props<{ packetIDs: any }>()
);
export const fetchNextActionForAssignVirtualTasks = createAction(
    '[WithMe] Fetch next action for virtual tasks by packet', //stone next actions
    props<{ packetIDs: any }>()
);
export const saveProcessByPackets = createAction(
    '[WithMe] Process by packet saved', //stone actions
    props<{ actionsByPacket: any }>()
);
export const saveProcessByVirtualPackets = createAction(
    '[WithMe] Process by virtual packet saved', //stone actions
    props<{ actionsByVirtualPacket: any }>()
);
export const unSelectionOfPacket = createAction(
    '[WithMe] reset action and user data',
);
export const updateActions = createAction(
    '[WithMe] update actions data',
    props<{ actionsByPacket: any }>()
);
export const updateUsers = createAction(
    '[WithMe] update users data',
    props<{ usersbyAction: any }>()
);
export const setStartPoint = createAction(
    '[WithMe] setStart Point for With Me',
    props<{ startFrom: string }>()
);

export const getProcessParameter = createAction(
    '[WithMe] Get Process Parameter(Key)',
    props<{ data: any, packetIds: any }>()
);

export const saveProcessParameter = createAction(
    '[WithMe] Users by Action saved',
    props<{ processnoteByActionID: any }>()
);
export const saveActionInputParameter = createAction(
    '[WithMe] save Action Input Parameter',
    props<{ payload: any, actionId: number, formType: string, barcodeObj: any, actionInfo: any }>()
);
export const resetUser = createAction(
    '[WithMe] Reset User to Store',
);

