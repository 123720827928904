import { createAction, props } from "@ngrx/store";

export const GET_packetInwardList = createAction(
    '[GET_packetInward] get packet inward list',
    props<{Payload: any}>()
)

export const SAVE_packetInwardLoadSuccess = createAction(
    '[Packet Inward] Load Success',
    props<{data:any}>()
);