import { NgModule } from "@angular/core";
import { RouteCriteriaComponent } from "./route-criteria.component";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { DropdownModule } from "../dropdown/dropdown.module";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [RouteCriteriaComponent],
    imports: [
        CommonModule,
        IonicModule,
        DropdownModule,
        FormsModule
    ],
    exports: [
        RouteCriteriaComponent,
    ]
})
export class RouteCriteriaModule {}
// @NgModule({
//   declarations: [RouteCriteriaComponent, SingleMultiDropdownComponent],
//   imports: [
//     CommonModule,
//     // HeaderComponentModule,
//     // LoadingModule,
//     IonicModule,
//     NgMultiSelectDropDownModule,
//     FormsModule
//   ]
// })
// export class RouteCriteriaModule { }
