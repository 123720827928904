import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MyVirtualTaskState } from "./my-virtual-task.state";

export const selectAuthState = createFeatureSelector<MyVirtualTaskState>(
    'mytask',
);

export const getMyTasks = createSelector(selectAuthState, state => {
    return state.myVirtualTasks;
});
export const getMyFilterTasks = createSelector(selectAuthState, state => {
    return state.myFilterVirtualTasks;
});