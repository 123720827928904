
// debugger
export interface MasterDataState {
    parameters: any;
    parameterHistory: any;
    trybeDepartments: any;
    trybeRoles: any;
    qualityMasterData: any;
    damageMasterData: any;
    pdStaticParametrs: any;
    usersbyPacketId: any;
    DetailsOfAnnotation: any;
    saveDetailFormObj: any;
    originalAnnotationImage: any;
    DetailsOfPrePlanningSummary: any;
    stoneParameterMaster:any;
    stoneParameterMasterById:any
    annotationDetails: any;
    labParameterData: any;
    ColorData: any;
    FancyData: any;
    ColorShadeData:any;
    allUsers : any[];
}


export const initialMasterDataState: MasterDataState = {
    parameters: {},
    parameterHistory: [],
    trybeDepartments: [],
    trybeRoles: [],
    qualityMasterData: [],
    damageMasterData: [],
    pdStaticParametrs: [],
    usersbyPacketId: [],
    DetailsOfAnnotation: [],
    saveDetailFormObj: {},
    originalAnnotationImage: {},
    DetailsOfPrePlanningSummary: [],
    stoneParameterMaster:[],
    stoneParameterMasterById:[],
    labParameterData: [],
    ColorData:[],
    FancyData: [],
    ColorShadeData: [],
    annotationDetails: [],
    allUsers : []
};