import { Component, OnInit } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { actions } from "src/app/authStore/auth.selector";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
// import { ICountry, IState, ICity } from 'country-state-city'

interface ICompany {
  id: string;
  companyName: string;
  companyFullName: string;
  email: string;
  address: string;
  address2: string;
  actionIds: string;
  cinNo: string;
  fax: string;
  gstin: string;
  pan: string;
  state: string;
  stateCode: string;
  tel: string;
}

@Component({
  selector: "app-party-master",
  templateUrl: "./party-master.component.html",
  styleUrls: ["./party-master.component.scss"],
})
export class PartyMasterComponent implements OnInit {
  columns: any = [
    { name: "Company Name", prop: "companyName" },
    { name: "Email", prop: "email" },
    { name: "Country", prop: "country" },
    { name: "Phone No.", prop: "phone" },
    { name: "Operation", prop: "operation" },
  ];
  company: ICompany;
  datasource: any = [];
  pageSize = 50;
  page = "Party Master";
  actionID: any = null;
  actionOptions: any;
  selectedRows: any;
  constructor(private menuCtrl: MenuController, public httpService: HttpService, private loaderService: LoaderService, private _ConfigService: ConfigService, private store: Store<any>) {
    this.company = {} as ICompany;
  }

  ngOnInit() {
    this.fetchActionForPartyMaster();
    this.getAllCompanyData();
    console.log(this.company);
  }

  async getAllCompanyData() {
    try {
      
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "tbl-company/findAll").toPromise();
      
      if (!!data) {
        this.datasource = data?.data;
        console.log("Company Data------>", this.datasource);
      }
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }
  OnAddPartyMaster() {
    this.company = {} as ICompany;
    this.menuCtrl.enable(true, "party-drawer");
    this.menuCtrl.open("party-drawer");
  }

  closeDrawer($event: any) {
    this.menuCtrl.close("party-drawer");
    this.selectedRows = null;
  }

  onSelectionChanged(event: any) {
    this.actionID = null;
    this.selectedRows = event.selectedRowsData[0];
    this.company = { ...this.selectedRows };
    if (!!this.selectedRows) {
      this.actionID = event.selectedRowsData[0].actionIds;
      this.menuCtrl.enable(true, "party-drawer");
      this.menuCtrl.open("party-drawer");
    }
  }
  drawerOpened() {}
  onSubmit(form) {
    if (form.valid) {
      
      this.httpService.addUpdatePartyMaster([this.company]).subscribe(res => {
        
        if (res.statusCode == 200) {
          this._ConfigService.showToast("success", res.message || "Saved successfully!");
          this.menuCtrl.close("party-drawer");
          this.getAllCompanyData();
        } else {
          this.httpService.getErrorAndDisplayIt(res);
          console.error(res);
          // this._ConfigService.showToast("error", res.message || 'Something Went Wrong!');
        }
      });
    }
  }

  async fetchActionForPartyMaster() {
    const res = await this.httpService.fetchDataUsingPromise(true, "tbl-action/getThirdPartyAction");
    if (res?.data?.length) {
      this.actionOptions = res.data;
    } else this._ConfigService.showToast("error", "No data found!");
  }
}
