import { createAction, props } from "@ngrx/store";
import { FormPostData } from "./qc-annotation.data";

export const saveDetailsFormData = createAction(
    '[annotation] saveDetailsFormData',
    props<{ postData: any }>()
);
export const saveDetailsFormResponse = createAction(
    '[annotation] saveDetailsFormResponse to store',
    props<{ response: any }>()
);
export const removesaveDetailsFormResponse = createAction(
    '[annotation] remove saved Details From Response',
);
export const updateDetailsFormData = createAction(
    '[annotation] updateDetailsFormData',
    props<{ postData: FormPostData }>()
)
export const updateDetailsFormDataSuccess = createAction(
    '[annotation] updateDetails SUCCESS',
    props<{ success: any }>()
);
export const updateDetailsFormDataFailure = createAction(
    '[annotation] updateDetails FAILURE',
    props<{ error: any }>()
)
export const removeUpdateDetailsFormDataSuccess = createAction(
    '[annotation] removeDetails SUCCESS',
);
export const getAllPacketWhoseFinalPlanningIsDoneBYSuratChecker = createAction(
    '[annotation] show details of FinalPlanningIsDoneBYSuratChecker ',
    // props<{ packetID: any }>()
);
export const saveAllPacketWhoseFinalPlanningIsDoneBYSuratChecker= createAction(
    '[annotation] save details of  FinalPlanningIsDoneBYSuratChecker',
    props<{ getPlansWhoseFinalPlanningIsDoneBYSuratChecker: any }>()
);
