import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import * as moment from "moment";
import { Subject, Subscription } from 'rxjs';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import { FetchAllStoneParameter, getAllTrybeDepartments } from '../masterDataStore/masterData.selector';
import { AppState } from '../reducers';
import { RouteCriteriaComponent } from '../route-criteria/route-criteria.component';
import { ConfigService } from '../services/config/config.service';
import { HttpService } from '../services/http/http.service';
import { LoaderService } from '../services/loader/loader.service';
import { operators, routeMasterAPIs, RouteMasterData } from './state/packet-route-master.data';
@Component({
  selector: 'app-packet-route-master',
  templateUrl: './packet-route-master.component.html',
  styleUrls: ['./packet-route-master.component.scss'],
})
export class PacketRouteMasterComponent {
  private subscriptions: Subscription[] = [];
  public segment: string = "routeMaster";
  public message: string;
  public currentView: string = "packetRouterMaster";
  public formValues: any = null;
  fromDate = moment().format('YYYY-MM-DD');
  public routeTypeName: string = "";
  public routeName: string = null;
  // public weightage: number = null;
  // public defaultWeightagePlaceMessage: string = 'eg. 100,50';
  // public weightagePlaceMessage: string = "";
  private isDefault: boolean = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  //routemaster Data
  public routeMasterData: RouteMasterData[];
  public routeTypeData: any = [];
  public departmentConfigRules: any = [];
  public allMode: any;
  public selectedOperator: any;
  public columns: any;
  private requireDepartmentIds: any = [];
  public id: any;
  public inputParamValue: any;
  inputFromParamValue: any;
  inputToParamValue: any;
  public parameter: any;
  public page: string = 'Route Master';
  public pageSize: number = 50;
  // v14.16.1.
  dropDownRouteTypeData: any = {
    name: 'Route Type',
    placeText: 'Select',
    options: this.routeTypeData,
    selectedData: [this.routeTypeData[0]],//by default Department
    propertName: 'routeType',
    modeFlag: 'single', //single/multiple
  };
  dropDownParameterData: any = {
    name: 'Parameter',
    placeText: 'Select',
    options: [],
    selectedData: [],
    propertName: 'parameter',
    modeFlag: 'single', //single/multiple
  };
  dropDownFromData: any = {
    name: 'From',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'fromParameter',
    modeFlag: 'single', //single/multiple
  };
  dropDownToData: any = {
    name: 'To',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'toParameter',
    modeFlag: 'single', //single/multiple
  };
  dropDownCurrentDept: any = {
    name: 'currentDept',
    placeText: 'Select Department',
    options: [],
    selectedData: [],
    propertName: 'currentDept',
    modeFlag: 'single', //single/multiple
  };
  dropDownNextDept: any = {
    name: 'nextDept',
    placeText: 'Select Department',
    options: [],
    selectedData: [],
    propertName: 'nextDept',
    modeFlag: 'single', //single/multiple
  };
  dropDownOperatorData: any = {
    name: 'Operator',
    placeText: 'Select',
    options: operators,
    selectedData: [],
    propertName: 'title',
    modeFlag: 'single', //single/multiple
  };
  dropDownInData: any = {
    name: 'In',
    placeText: 'Select Valusegmente',
    options: [],
    selectedData: [],
    propertName: 'name',
    modeFlag: null, //single/multiple
  };
  dropDownNotInData: any = {
    name: 'NotIn',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'name',
    modeFlag: null, //single/multiple
  };

  processes: any;
  dropDownProcessData: any = {
    name: 'Process',
    placeText: 'Processes',
    options: [],
    selectedData: [],
    key: 'process',
    modeFlag: 'multiple',//single/multiple
  };
  currDeptProcOpt: any[] = [];
  selectedProcesses: any[] = [];
  unselectedProcesses: any[] = [];
  routeInformation: any = {};
  savedRouteInformation: any = {};
  criteriaParameterLists = [];
  criteriaParameterHead = [];

  showProcessOptions: boolean = false;
  routeId: number;

  // dropDownDeptData: any = {
  //   name: '',
  //   placeText: 'Select',
  //   options:"Department" ,
  //   selectedData: [],
  //   propertName: 'title',
  //   modeFlag: 'single', //single/multiple
  // };
  public parameters: any = [];
  public parameterNames: any = [];
  public fromParameter: any = [];//from parameter for Equals & not Equals & range and priority range 
  public toParameter: any = [];//from parameter for range and priority range 
  public currentDept: any = [];
  public nextDept: any = [];
  // public isInputRequire: boolean = false;
  public deptCriteriaJSON: any = {};
  public routeJSONData: any = {};
  public selectionId: any;
  public isAddCriteriaDisabled: boolean = true;
  public departmentCriteriaTableData: any = [];
  public selectedParameter: any;
  public selectedParameterValues: any;//multiple selected value
  public selectedFromParamValue: any;
  public selectedToParamValue: any;
  public selectedInNotInParamValue: any;//string commaseparated
  public currentOperatorObj: any;
  public routeSelectionData = []
  private selectJSONForm: any = null;
  public isDisableSaveRoute: boolean = true;
  routeFormJSON: any = {
    // id: null,
    properties: null,//
    routeName: null,
    criterion: null,//json
    routeType: null,
    fromDate: this.fromDate,
    orderKey: null,//department orders
    // modifiedDate: any,
    // modifiedBy: number,
  };
  criterionData: any = [];
  private suscriptionParam: Subscription;
  private suscriptionParam2: Subscription;
  public isInputDropdown: number = 0;//0 for in dropdown and 1 for input box
  public criteriaFormJSON: any = {//test
    paramName: null,
    isAbsolute: null,
    operator: null,
    paramValue: null
  };
  departmentCriteriaFormJSON = {//test
    id: null,
    currDepartment: null,
    currDeptObj: null,
    nextDepartment: null,
    nextDeptObj: null,
    // route: null,
    criteria: null,
    criterion: [],
  };

  Proportion = [
    { name: 'EXCELENT', id: 1, paramNameId: 1, checked: false },
    { name: 'V.GOOD', id: 2, paramNameId: 2, checked: false },
    { name: 'FAIR', id: 3, paramNameId: 3, checked: false },
    { name: 'POOR', id: 4, paramNameId: 4, checked: false },

  ]
  tinch = [
    { name: 'N/A', id: 1, paramNameId: 1, checked: false },
    { name: 'LIGHT BROWN', id: 2, paramNameId: 2, checked: false },
    { name: 'BROWN', id: 3, paramNameId: 3, checked: false },
    { name: 'DARKBROWN', id: 4, paramNameId: 4, checked: false },
    { name: 'DARK LC', id: 5, paramNameId: 4, checked: false },
    { name: 'MIX', id: 6, paramNameId: 4, checked: false },
  ]
  topOpens = [

    { name: 'N/A', id: 1, paramNameId: 1, checked: false },
    { name: 'LIGHT', id: 2, paramNameId: 2, checked: false },
    { name: 'HEAVY', id: 3, paramNameId: 3, checked: false },
    { name: 'STRONG', id: 4, paramNameId: 4, checked: false },

  ]
  BotOpens = [

    { name: 'N/A', id: 1, paramNameId: 1, checked: false },
    { name: 'LIGHT', id: 2, paramNameId: 2, checked: false },
    { name: 'HEAVY', id: 3, paramNameId: 3, checked: false },
    { name: 'STRONG', id: 4, paramNameId: 4, checked: false },

  ]
  rangeParamStrValue: string = "";
  private currentCriteriaRowData: any = null;
  public isEditDeptCriteria: boolean = false;
  public isParameterDisabled: boolean = false;
  public isOperatorDisabled: boolean = false;
  public isEditRoute: boolean = false;
  public isNewUserCriteriaJSON: boolean = true;
  public deptCriteriaIndex: number;
  public deptCriterion: any = [];
  viewDeptAndIfDone: any;

  // ProcessRoute Variable Start
  public isNewDepartmentJSON: boolean = true;
  public isNewPacketCriteriaJSON: boolean = true;
  public isAddProcessCriteriaDisabled: boolean = false;
  public processCriteriaTableData: any = [];
  public actions: any = [];
  public states: any = [];
  public nextActions: any = [];
  public ifDonActions: any = [];
  public restrictArray: any = [];
  public selectedState: any = [];
  public selectedNextAction: any = [];
  public selectedIfDonAction: any = [];
  defaultDepartments: any;
  reorderedDepartments: any;
  public isVirtual: boolean = false;
  public currentProcessCriteriaIndex: number = -1;
  dropDownStateData: any = {
    name: 'Current State',
    placeText: 'Select State',
    options: [{ id: 1, state: 'Received' }, { id: 2, state: 'Return' }],
    selectedData: [],//by default Department
    propertName: 'state',
    modeFlag: 'single', //single/multiple
  };
  dropDownNextActionsData: any = {
    name: 'Next Actions',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'nextAction',
    modeFlag: 'single', //single/multiple
  };
  dropDownIfDoneData: any = {
    name: 'If Done Actions',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'ifDoneAction',
    modeFlag: 'single', //single/multiple
  };
  processCriteriaFormJSON = {//test
    id: null,
    currDepartment: null,
    currState: null,
    nextAction: null,
    ifDoneAction: null,
    // route: null,
    packetCriteria: null,
    packetCriterion: [],
    // packetCriteria: null,//for User crtiteria
    // packetCriterion: [],
    isVirtual: 0//number
  };

  // ProcessRoute Variable end

  // New UI fields
  /*shapeList: any = {
    name: '',
    list: [],
    selected: [],
    property: 'title',
    isMultiple: true,
  };
  colorList: any = {
    name: '',
    list: [],
    selected: [],
    property: 'title',
    isMultiple: true,
  };
  clarityList: any = {
    name: '',
    list: [],
    selected: [],
    property: 'title',
    isMultiple: true,
  };
  polishList: any = {
    name: '',
    list: [],
    selected: [],
    property: 'title',
    isMultiple: true,
  };*/
  criterialistData: any = [];
  criteriaList: any = {};
  showSave: boolean = true;
  disableSave: boolean = true;
  selectedProperties: any = {};
  otherCriterionData: any = [];
  areProcessesVisible: boolean = false;
  selectedDepartmentIdForProcesses: number;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private configService: ConfigService,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    // public popoverController: PopoverController,
    private store: Store<AppState>) {
  }


  ngOnInit() {
    this.deptapicall();
    this.allMode = 'allPages';
    this.columns = [
      { name: "Route Type", prop: "routeType" },
      // { name: "Route", prop: "name" },
      { name: "Rule name", prop: "ruleName" },
      // { name: "Weightage", prop: "weightage" },
      // { name: "Applied From", prop: "fromDate" },
      { name: "CreatedDate", prop: "createdDate" },
      { name: "Creator", prop: "createdBy" }
    ];

    this.getRoutemasterData();//fetching data for Route Master grid
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.getRouteSelectionData();
    this.fetchAllParameter();
    this.store.dispatch(masterDataActions.getAllStoneMaster());
    this.suscriptionParam = this.store.pipe(select(FetchAllStoneParameter)).subscribe(data => {
      if (data) {
        console.log('FetchAllStoneParameter');
        this.parameters = [];
        this.parameterNames = data.map(d => d.parameters);
        data.forEach((curr: any, k: number) => {
          let paramOb: object = {};
          // paramOb = { id: curr.id, parameter: curr.masterName },
          paramOb = { id: !!curr.id ? curr.id : k + 1, parameter: curr.parameters, isAbsolute: curr.isAbsolute },
            this.parameters.push(paramOb);
          // console.log(paramOb);
        });
        this.dropDownParameterData['options'] = this.parameters;
        console.log(this.parameterNames);
      }
    });
    this.subscriptions.push(
      this.store.pipe(select(getAllTrybeDepartments)).subscribe((data) => {
        if (data) {
          console.log(data);
          console.log('paramterTypeFromTo');
          console.log(data);
          this.currentDept = [];
          this.nextDept = []
          data.forEach((curr: any, k: number) => {
            let obj1: object = {}, obj2: object = {};
            obj1 = { id: curr.id, currentDept: curr.name };
            obj2 = { id: curr.id, nextDept: curr.name };
            this.currentDept.push(obj1);
            this.nextDept.push(obj2);
            //  console.log(obj1);
          });
          this.dropDownCurrentDept['options'] = this.currentDept;
          this.dropDownNextDept['options'] = this.nextDept;
          // this.dropDownDeptData['Department'] = data;
        }
      })
    );
    // this.routeFormJSON['routeType'] = this.dropDownRouteTypeData['selectedData'];
    // this.routeTypeName = this.dropDownRouteTypeData['selectedData'].routeType;
    // this.paramterTypeFromTo();
    this.fetchAllProcessConfigs();
    this.fetchAllStates();
    this.fetchAllAtions();
    // this.store.pipe(select(actions)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
    //   if (data) {
    //     console.warn('actions');
    //     console.log(data);

    //   }
    // });
    // 
  }
  updateRoute() {
    console.log('updateRoute');

  }
  onBackArrowClick() {
    console.log('onBackArrowClick or Back button');
  }
  onRouteNameChange(evt) {
    this.routeName = evt && evt.detail ? evt.detail.value : null;
    this.routeFormJSON['routeName'] = this.routeName;
    this.checkSaveRouteFormValidation();
  }
  backToRouteMaster() {
    console.log('backToRouteMaster');
    this.currDeptProcOpt = [];
    this.selectedProcesses = [];
    this.unselectedProcesses = [];
    this.routeInformation = {};
    this.savedRouteInformation = {};
    this.routeId = undefined;
    this.selectedDepartmentIdForProcesses = undefined;
    this.areProcessesVisible = false;
    this.currentView = "packetRouterMaster";
  }
  openNewPacketRouteForm() {
    this.currDeptProcOpt = [];
    this.selectedProcesses = [];
    this.unselectedProcesses = [];
    this.routeInformation = {};
    this.savedRouteInformation = {};
    this.routeId = undefined;
    this.selectedDepartmentIdForProcesses = undefined;
    this.areProcessesVisible = false;
    console.log('createNewRoute');
    // 
    this.currentView = "packetRouteForm";
    // setTimeout(() => {
    this.emptyRouteJSONForm();
    console.log(this.routeFormJSON);
    // }, 500);
  }
  emptyRouteJSONForm() {
    console.log('--------emptyRouteJSONForm-------------');
    this.dropDownRouteTypeData['selectedData'] = this.dropDownRouteTypeData['selectedData'];
    this.routeTypeName = !!this.dropDownRouteTypeData['selectedData'][0] ? this.dropDownRouteTypeData['selectedData'][0].routeType : null;
    this.routeFormJSON['routeType'] = this.dropDownRouteTypeData['selectedData'];
    this.routeName = "";
    this.dropDownCurrentDept['selectedData'] = [];//common dropdown array in both Routes(Departement or process)
    this.dropDownNextDept['selectedData'] = [];
    this.departmentCriteriaTableData = [];
    this.isEditRoute = false;
    this.disableSave = false;
    this.fromDate = moment().format('YYYY-MM-DD');
    this.routeFormJSON['fromDate'] = this.fromDate;
    this.routeFormJSON['routeName'] = null;
    this.routeFormJSON['orderKey'] = null;
    this.routeFormJSON['routeSelectionId'] = null;
    // this.isEditRoute = false;
    this.reorderedDepartments = [];
    this.reorderedDepartments = JSON.parse(JSON.stringify(this.defaultDepartments));
    this.criterialistData = this.criterialistData.map(d => {
      d.selected = [];
      return d;
    });
    this.selectedProperties = {};
    this.routeFormJSON['properties'] = null;
    this.criterionData = [];
    //Process Route
    this.dropDownStateData['selectedData'] = [];
    this.dropDownNextActionsData['selectedData'] = [];
    this.dropDownIfDoneData['selectedData'] = [];
    this.checkSaveRouteFormValidation();
    // 
  }
  onDismisOfUserPushOver(evt) {
    console.log('onDismisOfUserPushOver');
    console.log(evt);
  }
  //Get Data of Route Master Grid
  getRoutemasterData() {
    this.routeTypeData = [];
    this.httpService.fetchData(true, routeMasterAPIs.routeMasterEndPoint).subscribe(response => {
      console.log('getRoutemasterData');
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.routeMasterData = response.data;
        let routeTypeObj = {};
        response.data.forEach((d: any) => {
          if (!!d.routeType && !!d.routeTypeId) {
            routeTypeObj[d.routeType] = d.routeTypeId;
          }
        });
        console.log(routeTypeObj);
        for (let ob in routeTypeObj) {
          this.routeTypeData.push({ id: routeTypeObj[ob], routeType: ob });
        }
        this.routeTypeData = this.httpService.sortStrings(this.routeTypeData, 'routeType', 'ASC');
        console.log(this.routeTypeData);
        this.routeTypeData = this.routeTypeData.filter(r => r.routeType != null);
        this.dropDownRouteTypeData['options'] = [];
        this.dropDownRouteTypeData['options'] = this.routeTypeData;
        this.dropDownRouteTypeData['selectedData'] = [];//Department Route by default set
        this.dropDownRouteTypeData['selectedData'] = [this.routeTypeData[0]];//Department Route by default set
        this.routeTypeName = null;
        this.routeTypeName = !!this.routeTypeData[0] ? this.routeTypeData[0].routeType : null;
        console.log(this.routeFormJSON);
        // this.routeFormJSON['routeType'] = [this.routeTypeData[0]];//Department Route by default set
        // let allRouteType= response.data.map((d: any) => {
        //   let obj = {
        //     routeId: d.id,
        //     id: d.id,
        //     routeType: d.routeType,
        //   }
        //   return obj;
        // });
        // this.routeMasterData = response.data.map((d: any) => {
        //   if (!!d.routeType) {
        //     let routeTypeObj = this.routeTypeData.filter(routeT => routeT.id == d.routeType)[0];
        //     d['routeTypeName'] = !!routeTypeObj ? routeTypeObj.routeType : null;
        //   }
        //   return d;
        // });
      } else {
        console.error(response);
      }
      console.log(this.routeMasterData);
    });
  }
  getRouteSelectionData() {
    // this.httpService.fetchData(true, routeMasterAPIs.routeSelectionEndPoint).subscribe(response => {
    //   console.log('getRouterSelectionData');
    //   console.log(response);
    //   this.httpService.getErrorAndDisplayIt(response);
    //   if (response.statusCode === 200) {
    //     this.routeSelectionData = response.data;
    //     if (!!this.selectionId && this.selectionId != null) {
    //     }
    //   } else {
    //     console.error(response);
    //   }
    // });
  }
  async fetchAllProcessConfigs() {
    console.log('fetchAllProcessConfigs');
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getAllProcesConfigEndPoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
    } else {
      console.error(response);
    }
  }
  async fetchAllParameter() {
    console.log('fetchAllParameter');
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.parametersEndpoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      this.parameterNames = response.data.map(d => d.parameters);
      console.log(this.parameterNames);
    } else {
      console.error(response);
    }
    this.fetchRequiredParameterValues();
  }
  async fetchAllStates() {//Next or If done
    console.log('fetchAllStates');
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getStatesEndPoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data: Array<[]> = response.data
      this.actions = data;
      this.states = data.map((d: any) => {
        let { id, name } = d;
        return { id: id, state: name };
      });
      this.states = this.states.filter(st => st.state != null);
      this.dropDownStateData['options'] = [];
      this.dropDownStateData['options'] = this.states;
    } else {
      console.error(response);
    }
  }
  async fetchAllAtions() {//Next or If done
    console.log('fetchAllActions');
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getActionsEndPoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log('%cActions----------', 'color:green;font-weight:bold;font-size:1rem;');
      let data: Array<[]> = response.data
      this.actions = data;
      this.nextActions = data.map((d: any) => {
        let { id, actionName } = d;
        return { id: id, nextAction: actionName };
      });
      this.ifDonActions = data.map((d: any) => {
        let { id, actionName } = d;
        return { id: id, ifDoneAction: actionName };
      });
      this.nextActions = this.nextActions.filter(na => na.nextAction != null);
      this.ifDonActions = this.ifDonActions.filter(ifDA => ifDA.ifDoneAction != null);
      this.dropDownNextActionsData['options'] = [];
      this.dropDownNextActionsData['options'] = this.nextActions;
      this.dropDownIfDoneData['options'] = [];
      this.dropDownIfDoneData['options'] = this.ifDonActions;
      console.log(this.nextActions);
      console.log(this.ifDonActions);
    } else {
      console.error(response);
    }
  }
  fetchRequiredParameterValues() {
    console.log('fetchRequiredParameterValues');
    console.log(this.parameterNames);
    let payloadObj: object = {
      names: ['Color', 'Shape', 'Fluorescence', 'Clarity', 'Polish', 'Symmetry', 'Tinch', 'Milkyness',
        'Proportion', 'Natts', 'TopOpens', 'BotOpens', 'Tension Type']
    };
    // let payloadObj: object = {
    //   names: this.parameterNames
    // };
    this.httpService.getData(true, routeMasterAPIs.parameterValuesEndpoint, payloadObj).subscribe(result => {
      if (result.statusCode === 200) {
        this.criteriaParameterLists = result?.data.map((dt) => {return {...dt, startIndex : null, endIndex : null, paramValues : dt?.paramValues?.map(pv => {return {...pv, isSelected : false}})}});
        this.criteriaParameterHead = result?.data.map(dt => dt.parameterName);
        this.criterialistData = [];
        this.criteriaList = {};
        console.log(result);
        result.data.forEach((d) => {
          let obj = {
            'paramId': d.parameterNameId,
            'property': 'name',
            'list': d.paramValues,//[]
            'selected': [],
            'isMultiple': true,
            'name': d.parameterName,
          };
          this.criteriaList[d.parameterName] = d.parameterNameId;
          // if (d.parameterName == 'Color') {
          //   this.colorList['name'] = 'Color';
          //   this.colorList['property'] = 'name';
          //   this.colorList['list'] = d.paramValues;
          // } else if (d.parameterName == 'Shape') {
          //   this.shapeList['name'] = 'Shape';
          //   this.shapeList['property'] = 'name';
          //   this.shapeList['list'] = d.paramValues;
          // } else {

          // }
          this.criterialistData.push(obj);
          // console.log(this.criterialistData);
        });
        let obj = {
          'paramId': null,
          'property': 'name',
          'list': this.Proportion,//[]
          'selected': [],
          'isMultiple': true,
          'name': "Proportion",
        };
        this.criterialistData.push(obj);
        let obj2 = {
          'paramId': null,
          'property': 'name',
          'list': this.tinch,//[]
          'selected': [],
          'isMultiple': true,
          'name': "Tinch",
        };
        this.criterialistData.push(obj2);
        let obj3 = {
          'paramId': null,
          'property': 'name',
          'list': this.topOpens,//[]
          'selected': [],
          'isMultiple': true,
          'name': "TopOpens",
        };
        this.criterialistData.push(obj3);
        let obj4 = {
          'paramId': null,
          'property': 'name',
          'list': this.BotOpens,//[]
          'selected': [],
          'isMultiple': true,
          'name': "BotOpens",
        };
        this.criterialistData.push(obj4);

      } else {
        this.httpService.getErrorAndDisplayIt(result);
      }
    });
  }

  showProcessesToSelect (event) {
    console.log(event);
    this.showProcessOptions = !this.showProcessOptions;
  }

  dropDownSelection(evt: any, operator: any) {
    console.log('dropDownSelection');
    console.log(evt);
    let selectedArrayList = evt.arrayList;
    if (evt.property == "routeType") {
      this.dropDownRouteTypeData['selectedData'] = selectedArrayList;
      this.routeFormJSON['routeType'] = selectedArrayList;
      if (selectedArrayList.length > 0) {
        this.routeTypeName = !!selectedArrayList[0] ? selectedArrayList[0].routeType : null;
      } else {
        this.routeTypeName = null;
      }
      this.dropDownCurrentDept['selectedData'] = [];//common dropdown array in both Routes(Departement or process)
    } else if (evt.property == "currentDept") {
      this.dropDownCurrentDept['selectedData'] = selectedArrayList;
      let obj = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      if (selectedArrayList.length > 0) {
        this.departmentCriteriaFormJSON['currDepartment'] = obj.currentDept;
        this.departmentCriteriaFormJSON['currDeptObj'] = obj;
        this.processCriteriaFormJSON['currDepartment'] = obj.currentDept;
        this.processCriteriaFormJSON['currDepartmentObj'] = obj;
      } else {
        this.departmentCriteriaFormJSON['currDepartment'] = obj;
        this.departmentCriteriaFormJSON['currDeptObj'] = obj;
        this.processCriteriaFormJSON['currDepartment'] = obj;
        this.processCriteriaFormJSON['currDepartmentObj'] = obj;
      }
      this.checkAddDeptCriteriaFormValidation();
      this.checkProcessCriteriaFormValidation();
    } else if (evt.property == "nextDept") {
      this.dropDownNextDept['selectedData'] = selectedArrayList;
      let obj = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      if (selectedArrayList.length > 0) {
        this.departmentCriteriaFormJSON['nextDepartment'] = obj.nextDept;
        this.departmentCriteriaFormJSON['nextDeptObj'] = obj;
      } else {
        this.departmentCriteriaFormJSON['nextDepartment'] = obj;
        this.departmentCriteriaFormJSON['nextDeptObj'] = obj;
      }
      this.checkAddDeptCriteriaFormValidation();
    } else if (evt.property == "state") {
      this.dropDownStateData['selectedData'] = selectedArrayList;
      let obj = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      if (selectedArrayList.length > 0) {
        this.processCriteriaFormJSON['currState'] = obj.state;
        this.processCriteriaFormJSON['currStateObj'] = obj;
      } else {
        this.processCriteriaFormJSON['currState'] = obj;
        this.processCriteriaFormJSON['currStateObj'] = obj;
      }
      this.checkProcessCriteriaFormValidation();
    } else if (evt.property == "nextAction") {
      this.dropDownNextActionsData['selectedData'] = selectedArrayList;
      let obj = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      if (selectedArrayList.length > 0) {
        this.processCriteriaFormJSON['nextAction'] = obj.nextAction;
        this.processCriteriaFormJSON['nextActionObj'] = obj;
      } else {
        this.processCriteriaFormJSON['nextAction'] = obj;
        this.processCriteriaFormJSON['nextActionObj'] = obj;
      }
      this.checkProcessCriteriaFormValidation();
    } else if (evt.property == "ifDoneAction") {
      this.dropDownIfDoneData['selectedData'] = selectedArrayList;
      let obj = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      if (selectedArrayList.length > 0) {
        this.processCriteriaFormJSON['ifDoneAction'] = obj.ifDoneAction;
        this.processCriteriaFormJSON['ifDoneActionObj'] = obj;
      } else {
        this.processCriteriaFormJSON['ifDoneAction'] = obj;
        this.processCriteriaFormJSON['ifDoneActionObj'] = obj;
      }
      this.checkProcessCriteriaFormValidation();
    }
    else {
      console.log('no dropdown change');
    }

  }

  onParameterInputChange(evt) {//Currenly not in use
    console.log('onParameterChangeValue');
    console.log(evt);
    // this.parameter = !!evt && evt.detail && evt.detail.value ? evt.detail.value : null;
  }

  checkAddDeptCriteriaFormValidation() {
    console.log('checkAddDeptCriteriaFormValidation');
    let obj = this.departmentCriteriaFormJSON;
    console.log(obj);
    let deptsAndCriteriaValues: any = Object.values(obj);
    let nulls: any = deptsAndCriteriaValues.filter(d => d === null);
    console.log(nulls);
    console.log(deptsAndCriteriaValues);
    //inputboxes validation start
    if (deptsAndCriteriaValues.length === 0 || deptsAndCriteriaValues.includes('') || deptsAndCriteriaValues.includes('undefined') || deptsAndCriteriaValues.includes(undefined) || nulls.length > 2) {
      this.isAddCriteriaDisabled = true;
    } else {
      this.isAddCriteriaDisabled = false;
    }
    //inputboxes validation End
    console.log(deptsAndCriteriaValues, this.isAddCriteriaDisabled);
  }
  checkSaveRouteFormValidation() {
    console.log('checkSaveRouteFormValidation');
    if (!this.routeFormJSON['routeName'] || !this.routeFormJSON['routeType'] || this.routeFormJSON['routeType'].length === 0 || (!this.selectedProperties && Object.keys(this.selectedProperties).length === 0)) {
      this.disableSave = true;
    } else {
      this.disableSave = false;
    }
    console.log(this.routeFormJSON);
    console.log(this.disableSave);
  }

  async createNewOrEditOldCriteria(from: string) {
    console.log(this.routeTypeName);

    let data: unknown = null;
    if (from === 'new') {
      console.log("creatingggg new criteria");
      data = null;
      // this.showCriteriaModal(null);
      const modal = await this.modalCtrl.create({
        component: RouteCriteriaComponent,
        cssClass: 'routeCriteria',
        componentProps: {
          "message": 'newCriteria',
          resultCriterionData: data
        },
      });
      modal.onDidDismiss().then((data) => {
        this.onCriteriaMoalDismiss(data);
      });
      return await modal.present();

    } else {
      console.log("Editing existing criteria");
      console.log(this.deptCriterion);
      console.log(this.processCriteriaFormJSON['packetCriterion']);
      //edit/update
      // this.showCriteriaModal({ criteria: this.deptCriteriaJSON });
      if (this.routeTypeName && this.routeTypeName.toLowerCase() === "process route") {
        data = this.processCriteriaFormJSON['packetCriterion'];
      } else {
        data = this.departmentCriteriaFormJSON['criterion'];
      }
      console.log(data)
      const modal = await this.modalCtrl.create({
        component: RouteCriteriaComponent,
        cssClass: 'routeCriteria',
        componentProps: {
          "message": 'editCriteria', resultCriterionData: data
        },
      });
      modal.onDidDismiss().then((data) => {
        this.onCriteriaMoalDismiss(data);
      });
      return await modal.present();
    }
  }
  openRouteOnLink(event) {
    console.log(event)
    this.selectionId = event.row.data.id;
    console.log(this.selectionId, this.routeSelectionData);

    this.segment = "routeSelection";
    let routeSelectionDataCopy = JSON.parse(JSON.stringify(this.routeSelectionData));
    routeSelectionDataCopy = this.routeSelectionData.filter(el => {
      console.log(el.routeMasterId);

      return el.routeMasterId == this.selectionId
    })

    console.log(routeSelectionDataCopy)
    this.routeSelectionData = routeSelectionDataCopy
  }
  addDeptCriteria() {
    console.log("dept add btn");
    console.log(this.departmentCriteriaFormJSON);
    // let {currDepartment,nextDepartment, route,criteria} = this.departmentCriteriaFormJSON;
    // let index = this.departmentCriteriaTableData.findIndex((d: any) => d.currDepartment == this.departmentCriteriaFormJSON['currDepartment'] && d.nextDepartment == this.departmentCriteriaFormJSON['nextDepartment']);
    // console.log(index);
    console.log(this.deptCriteriaIndex);
    if (this.deptCriteriaIndex != null && this.deptCriteriaIndex > -1) {
      this.departmentCriteriaTableData[this.deptCriteriaIndex] = this.departmentCriteriaFormJSON;
    } else {
      console.log('add NEWWWWWWWWWWWW');
      this.departmentCriteriaTableData.push(this.departmentCriteriaFormJSON);
    }
    // this.departmentCriteriaTableData.push(this.departmentCriteriaFormJSON);
    this.dropDownCurrentDept['selectedData'] = [];
    this.dropDownNextDept['selectedData'] = [];
    this.deptCriteriaJSON = null;
    this.departmentCriteriaFormJSON = {//test
      id: null,
      currDepartment: null,
      currDeptObj: null,
      nextDepartment: null,
      nextDeptObj: null,
      // route: null,
      criteria: null,
      criterion: [],
    };
    this.isNewDepartmentJSON = true;
    this.deptCriteriaIndex = null;
    this.checkAddDeptCriteriaFormValidation();
    this.checkSaveRouteFormValidation();
  }
  async editDeptCriteria(rd, idx) {
    console.log('===========editDeptCriteria===========');
    console.log(idx);
    this.deptCriteriaIndex = idx;
    let rowData = this.departmentCriteriaTableData[idx];
    console.log(rowData);
    // this.emptyParamOperatorBasedFrom();
    // this.currentCriteriaRowData = !!rowData ? rowData : null;
    // console.log(this.routeCriteriaTableData);

    if (rowData) {
      this.isEditDeptCriteria = true;
      let criterionData: any = [];
      console.log(rowData);
      this.dropDownCurrentDept['selectedData'] = [rowData.currDeptObj];
      this.dropDownNextDept['selectedData'] = [rowData.nextDeptObj];
      this.deptCriteriaJSON = rowData.criteria;
      this.departmentCriteriaFormJSON = {//test
        id: rowData.id,
        currDepartment: rowData.currDepartment,
        currDeptObj: rowData.currDeptObj,
        nextDepartment: rowData.nextDepartment,
        nextDeptObj: rowData.nextDeptObj,
        // route: null,
        criteria: JSON.parse(JSON.stringify(rowData.criteria)),
        criterion: rowData.criterion
      };
      // this.weightage = rowData.weightage;
      criterionData = this.getCriterionData(rowData.criteria);
      console.log(this.deptCriterion);
      // this.deptCriterion
      this.deptCriterion = criterionData;
      this.departmentCriteriaFormJSON['criterion'] = criterionData;
      this.isNewDepartmentJSON = this.departmentCriteriaFormJSON['criterion'].length === 0;
      console.log(this.isNewDepartmentJSON);
      console.log(criterionData);
      this.checkAddDeptCriteriaFormValidation();
      // this.populateAndOpenEditCriteriaForm();
    }
  }

  getCriterionData(criteria) {
    let criterionData: any = [];
    let criterion: any = criteria;
    console.log(criterion);
    for (let operator in criterion) {
      console.log(operator);
      console.log(operators);
      let operatorObj: any = operators.filter(d => d.operator == operator)[0];
      let criteriaObj = {};
      criteriaObj['operator'] = operator;
      criteriaObj['operatorText'] = operatorObj.title;
      // let object:any= this.httpService.checkTypeOfVariable(criterion)
      for (let parameter in criterion[operator]) {
        criteriaObj['paramName'] = parameter;
        let paramObj: any = this.parameters.filter(d => d.parameter == parameter)[0];
        let isAbsolute = !!paramObj ? parseInt(paramObj.isAbsolute) : null;
        criteriaObj['isAbsolute'] = isAbsolute;
        let paramValue: any = criterion[operator][parameter];
        console.log(parameter, paramObj);
        if (isAbsolute === 0) {
          if (operator && (operator == 'equals' || operator == 'notEqual')) {
            criteriaObj['paramValue'] = paramValue;
            criteriaObj['paramStrValue'] = paramValue.paramValue;
          } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
            if (operator == 'range') {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = `${paramValue.lowerLimit} - ${paramValue.upperLimit}`;
            } else {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = `${paramValue.value.lowerPriorityLimitId} - ${paramValue.value.upperPriorityLimitId}`;
              criteriaObj['paramNameId'] = paramValue.paramNameId;
            }
          } else if (operator && (operator == 'in' || operator == 'notIn')) {
            criteriaObj['paramValue'] = paramValue;
            criteriaObj['paramStrValue'] = paramValue.values.join();
            criteriaObj['paramNameId'] = paramValue.paramNameId;
          }
          // console.log(this.criteriaFormJSON, this.rangeParamStrValue,);
        } else {
          if (operator && (operator == 'equals' || operator == 'notEqual')) {
            // let paramValueObj = this.dropDownFromData.options.filter(p => p.id == paramValue)[0];
            // console.log(this.dropDownFromData.options, paramValueObj, paramValue);
            // criteriaObj['paramValue'] = paramValue;
            criteriaObj['paramValue'] = paramValue;
            // criteriaObj['paramStrValue'] = paramValue.paramValue;
            criteriaObj['paramStrValue'] = paramValue.paramValueText;
          } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
            console.log(operator);
            console.log(paramValue);
            if (operator == 'range') {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = `${paramValue.lowerLimitText}-${paramValue.upperLimitText}`;
              // criteriaObj['paramNameId'] = paramValue.paramNameId;
              criteriaObj['paramNameId'] = paramValue.paramNameId;
            } else {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = `${paramValue.value.lowerPriorityLimitText}-${paramValue.value.upperPriorityLimitText}`;
              criteriaObj['paramNameId'] = paramValue.paramNameId;
            }
          } else if (operator && (operator == 'in' || operator == 'notIn')) {
            if (operator == 'notIn') {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = paramValue.valuesText.join();
              criteriaObj['paramNameId'] = paramValue.paramNameId;
            } else {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = paramValue.valuesText.join();
              criteriaObj['paramNameId'] = paramValue.paramNameId;
            }
          }
        }
        criterionData.push(JSON.parse(JSON.stringify(criteriaObj)));
      }
    }
    return criterionData;
  }
  async removeDeptCriteria(ID, index) {
    console.log('===========removeDeptCriteria===========');
    console.log(ID, index);
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'CLOSE',
        handler: (d: any) => {
          console.log('Closed', d);
        }
      },
      {
        text: 'OK',
        handler: (d: any) => {
          console.log('-----------temp-----------');
          if (!!ID) {
            this.deleteDeptCrietion(ID, index);
          } else {
            this.departmentCriteriaTableData.splice(index, 1);
          }
          // this.routeCriteriaTableData = this.routeCriteriaTableData.filter(d =>  != id);
        }
      }]
    });
    await alert.present();
    // this.isEditDeptCriteria = true;
    // let rowData = this.routeCriteriaTableData[index];
    // this.criteriaFormJSON = {//test
    //   paramName: null,
    //   isAbsolute: null,
    //   operator: null,
    //   paramValue: null
    // };
    // this.dropDownParameterData['selectedData'] = [];
    // this.dropDownOperatorData['selectedData'] = [];
    // this.emptyParamOperatorBasedFrom();
    // this.currentCriteriaRowData = !!rowData ? rowData : null;
    // console.log(this.routeCriteriaTableData);
    // if (this.currentCriteriaRowData) {
    //   this.populateAndOpenEditCriteriaForm();
    // }
  }
  deleteDeptCrietion(id: number, indexNum: number) {
    console.log('%cdeleteDeptCrietion evt', "color:green;fot-size:14px");
    console.log(id);

    let deleteAPIEndpoint: string = `${routeMasterAPIs.deleteDetCriterionEndpoint}/${id}`;
    console.log(deleteAPIEndpoint);

    if (deleteAPIEndpoint.length > 5) {
      
      this.httpService.deleteData(true, deleteAPIEndpoint).subscribe((result: any) => {
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          // this.getRoutemasterData();//fetching data for Route Master grid
          this.departmentCriteriaTableData.splice(indexNum, 1);
          
          this.configService.showToast("success", result.message);
        } else {
          
          // this.configService.showToast("error", d.message);
        }
      });
    } else {
      console.error("NO valid delete dept criterion api found!");
    }

  }
  async showDepartmentCriteria(rd: any, k: number) {
    console.log('showDepartmentCriteria');
    console.log(rd);
    console.log(this.isEditRoute);
    let criteriaTitle: string = "Department Criteria", criterionData: any = [];
    // this.showCriteriaModal({ criteria: this.deptCriteriaJSON });
    if (this.routeTypeName && this.routeTypeName.toLowerCase() === "department route") {
      criteriaTitle = "Department Criteria"
      if (this.isEditRoute === false) {
        criterionData = rd.criterion;
      } else {
        criterionData = this.getCriterionData(rd);
      }
    } else {
      criteriaTitle = "Packet Criteria"
      if (this.isEditRoute === false) {
        criterionData = rd.packetCriterion;
      } else {
        criterionData = this.getCriterionData(rd);
      }
    }

    const modal = await this.modalCtrl.create({
      component: RouteCriteriaComponent,
      cssClass: 'routeCriteria',
      componentProps: {
        "message": 'viewCriteria',
        resultCriterionData: criterionData, criteriaTitle
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onCriteriaMoalDismiss(data);
    });
    return await modal.present();
  }

  eventOnkeyPress(evt) {
    console.log('eventOnkeyPress');
    console.log(evt);
    if (evt.key == 'Enter') {
      this.addDeptCriteria();
    }
  }
  logScrolling(evt) {

  }
  logScrollEnd() {

  }
  logScrollStart() {

  }
  onSelectionChanged(evt) {
    console.log('%cRow Selection', "color:orange;fot-size:14px;font-weight:bold");
    console.log(evt);

  }
  async removeCriteria(event, id, idx) {

    console.log('removeCriteria');
    console.log(this.defaultDepartments);
    console.log(id);
    // const alert = await this.alertController.create({
    //   header: 'Are you sure do you want to delete the row?',
    //   // subHeader: 'Subtitle for alert',
    //   // message: 'Are you sure doyou want to delete the row?',
    //   cssClass: 'my-custom-class',
    //   buttons: [{
    //     text: 'CLOSE',
    //     handler: (d: any) => {
    //       console.log('Closed', d);
    //     }
    //   },
    //   {
    //     text: 'OK',
    //     handler: (d: any) => {
    //       console.log('-----------temp-----------');
    //       this.reorderedDepartments.splice(idx, 1);
    //       console.log(this.reorderedDepartments);
    //       console.log(this.defaultDepartments);
    //     }
    //   }]
    // });
    // await alert.present();
    console.log(event.detail.checked);
    // this.reorderedDepartments = this.reorderedDepartments.map(d)
    if (event.detail.checked) {
      // this.reorderedDepartments[idx] = this.reorderedDepartments[idx].departmentChkDisdabled=!event.detail.checked;
      this.reorderedDepartments = this.reorderedDepartments.map(d => {
        if (d.id == id) {
          d.departmentChkDisabled = true
        }
        return d;
      });
    } else {
      this.reorderedDepartments = this.reorderedDepartments.map(d => {
        if (d.id == id) {
          d.departmentChkDisabled = false
        }
        return d;
      });
    }
    var index = this.restrictArray.indexOf(id);
    if (index === -1) {
      this.restrictArray.push(id);
    } else {
      this.restrictArray.splice(index, 1);
    }
    console.log(this.restrictArray);


    // let isrestrict= this.restrictArray.map((x: any) => x);
    // console.log(isrestrict);

    // this.reorderedDepartments= this.reorderedDepartments.map((d)=>{
    //   if (isrestrict.includes(d.id)) {
    //     d.checked=false;
    //   }
    //   return d
    // })

  }

  async showDepartmentProcesses(selectedDept: any) {
    this.refreshSelectedProcesses();
    if (!this.routeId && this.routeInformation.hasOwnProperty(selectedDept.id)) {
      this.currDeptProcOpt = this.routeInformation[selectedDept.id];
      if (selectedDept.id === this.selectedDepartmentIdForProcesses) {
        this.areProcessesVisible = !this.areProcessesVisible;
      } else {
        this.areProcessesVisible = true;
      }
      this.selectedDepartmentIdForProcesses = selectedDept.id;
      this.currDeptProcOpt = this.routeInformation[selectedDept.id];
      this.refreshSelectedProcesses();
      this.refreshUnselectedProcesses();
    } else {
      await this.fetchAllActions(selectedDept);
      if (selectedDept.id === this.selectedDepartmentIdForProcesses) {
        this.areProcessesVisible = !this.areProcessesVisible;
      } else {
        this.areProcessesVisible = true;
      }
      this.selectedDepartmentIdForProcesses = selectedDept.id;
    }
    console.log(this.routeInformation);
  }

  async fetchAllActions(selectedDept) {
    console.log('fetchAllActions');
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getActionsEndPoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log('%cActions----------', 'color:green;font-weight:bold;font-size:1rem;');
      let data: Array<[]> = response.data
      this.processes = data.map((d: any) => {
        let { id, actionName } = d;
        return { id: id, process: actionName };
      });
      console.log(this.processes);
      this.processes = this.processes.filter(na => na.process != null);
      this.dropDownProcessData['options'] = [];
      this.dropDownProcessData['options'] = this.processes;
      if (!this.routeId) {
        this.currDeptProcOpt = this.processes.map((obj: any) => {
          return {
            ...obj,
            isSelected: false,
            isIfDoneChosen: false, 
            isVirtual: false,
            selectedData: []
          }
        });
      } else {
        const processRuleConfigList = this.savedRouteInformation[selectedDept.id];
        console.log(this.savedRouteInformation, selectedDept);
        this.currDeptProcOpt = this.processes.map((obj: any) => {
          let isSelected = false;
          let isVirtual: boolean = false;
          const currProc = processRuleConfigList?.find((processInfo) => processInfo.nextAction === obj.id)
          if (currProc) {
            isSelected = true;
            isVirtual = currProc.isVirtual
            return {
              ...obj,
              isSelected,
              isIfDoneChosen: currProc?.ifDoneActions.length ? true : false,
              selectedData: currProc?.ifDoneActions,
              isVirtual
            }
          } else {
            return obj;
          }
        })
      }
      this.routeInformation[this.selectedDepartmentIdForProcesses] = this.currDeptProcOpt;
      this.refreshSelectedProcesses();
      this.dropDownProcessData.options = this.selectedProcesses;
      this.refreshUnselectedProcesses();
      // this.drawerData['processes'] = this.processes;
      console.log(this.processes);
    } else {
      console.error(response);
    }
  }
  
  toggleChecked(processObj) {
    console.log(processObj);
    this.currDeptProcOpt = this.currDeptProcOpt.map((obj) => {
      if (obj.id === processObj.id) {
        return {
          ...processObj,
          isVirtual: !processObj.isVirtual
        };
      } else {
        return obj;
      }
    })
    this.routeInformation[this.selectedDepartmentIdForProcesses] = this.currDeptProcOpt;
    this.refreshSelectedProcesses();
  }

  toggleIsIfDoneChosen(processObj) {
    this.currDeptProcOpt = this.currDeptProcOpt.map((obj) => {
      if (obj.id === processObj.id) {
        return {
          ...processObj,
          isIfDoneChosen: !processObj.isIfDoneChosen
        }
      } else {
        return obj;
      }
    })
    this.routeInformation[this.selectedDepartmentIdForProcesses] = this.currDeptProcOpt;
    this.refreshSelectedProcesses();
    this.dropDownProcessData.options = this.selectedProcesses;
  }

  fillIfDoneActions(event, processObj) {
    console.log(event, processObj);
    this.currDeptProcOpt = this.currDeptProcOpt.map((obj) => {
      if (obj.id === processObj.id) {
        return {
          ...processObj,
          selectedData: event.arrayList
        }
      } else {
        return obj;
      }
    })
    this.routeInformation[this.selectedDepartmentIdForProcesses] = this.currDeptProcOpt;
  }

  filterIfDoneOptionsForProcess(event, currProcess: any) {
    const clickTarget = event.target.innerText;
    const targetSymbol = clickTarget[0];
    if (clickTarget == "Processes" || clickTarget === "" || targetSymbol == '+') {
      this.dropDownProcessData.options = this.selectedProcesses;
      console.log(this.dropDownProcessData);
      console.log(currProcess);
      const filteredIfDoneOptions = this.dropDownProcessData.options.filter((option) => option.id !== currProcess.id)
      this.dropDownProcessData.options = filteredIfDoneOptions;
      console.log(filteredIfDoneOptions);
    }
  }

  refreshUnselectedProcesses () {
    this.unselectedProcesses = this.currDeptProcOpt.filter((obj: any) => {
      return !obj.isSelected; 
    })
    console.log(this.unselectedProcesses);
  }

  refreshSelectedProcesses () {
    this.selectedProcesses = this.currDeptProcOpt.filter((obj: any) => {
      return obj.isSelected; 
    })
    this.selectedProcesses = this.selectedProcesses.map((process) => {
      return {
        ...process,
        showIfDoneOptions: false
      }
    })
  }

  toggleProcessSelection(selectedProcess: any) {
    console.log(selectedProcess);
    this.currDeptProcOpt = this.currDeptProcOpt.map((process) => {
      if (process.id === selectedProcess.id) {
        return {
          ...process,
          isSelected: !process.isSelected
        }
      } else {
        return process;
      }
    })
    console.log(this.currDeptProcOpt);
    this.routeInformation = {
      ...this.routeInformation,
      [this.selectedDepartmentIdForProcesses]: this.currDeptProcOpt
    }
    this.refreshSelectedProcesses();
    this.refreshUnselectedProcesses();
  }

  onchangeDept(event, id: number) {
    const temp = event.detail.value;
    console.log(temp);
    if (event.detail.checked) {
      // this.reorderedDepartments[idx] = this.reorderedDepartments[idx].departmentChkDisdabled=!event.detail.checked;
      this.reorderedDepartments = this.reorderedDepartments.map(d => {
        if (d.id == id) {
          d.restrictedDisabled = true
        }
        return d;
      });
    } else {
      this.reorderedDepartments = this.reorderedDepartments.map(d => {
        if (d.id == id) {
          d.restrictedDisabled = false
        }
        return d;
      });
    }
    // this.requireDepartmentIds.push(temp);
    // console.log(this.requireDepartmentIds);
    var index = this.requireDepartmentIds.indexOf(id);
    if (index === -1) {
      this.requireDepartmentIds.push(id);
    } else {
      this.requireDepartmentIds.splice(index, 1);
    }
    let deptIDs = this.reorderedDepartments.map((data) => {
      return data.id
    })
    let checkbox = this.requireDepartmentIds.join("");
    let specialKey = deptIDs.join("") + "-" + checkbox;
    this.routeFormJSON['orderKey'] = specialKey;
    console.log(this.requireDepartmentIds);
    this.checkSaveRouteFormValidation();
  }
  async editRoute(evt) {
    this.emptyRouteJSONForm();
    this.isEditRoute = true;
    console.log('%ceditRoute Route evt', "color:green;fot-size:14px");
    console.log(evt);
    let rowData: any = evt.row.data;
    // let selData: any = evt.row.key;
    console.log(rowData);
    if (rowData) {
      let allCriteria: object = !!rowData.criterion ? JSON.parse(rowData.criterion) : null;
      let clonedAllCriteria: object = !!allCriteria ? JSON.parse(JSON.stringify(allCriteria)) : null;
      delete clonedAllCriteria['stoneProperties'];
      let stoneProperties: object = !!allCriteria['stoneProperties'] ? JSON.parse(JSON.stringify(allCriteria['stoneProperties'])) : null;
      let otherCritera: object = !!clonedAllCriteria ? clonedAllCriteria : null;
      let otherCriterion: Array<[]> = this.getCriterionData(otherCritera);
      let customStoneProperties: {} = {}
      console.log(allCriteria);
      console.log(stoneProperties);
      console.log(clonedAllCriteria);
      console.log(otherCritera);
      console.log(otherCriterion);
      console.log(customStoneProperties);
      this.criterionData = otherCriterion;
      for (let operator in stoneProperties) {
        let parameters = stoneProperties[operator];
        console.log(operator);
        for (let paramName in parameters) {
          let val = parameters[paramName];
          console.log(paramName);
          console.log(val);
          let values = val.values, valuesText = val.valuesText
          let valArr = values.map((d, k) => {
            return { id: d, name: valuesText[k] };
          });
          customStoneProperties[paramName] = valArr;
          console.log(valArr);
          // for (let index in values) {
          //   let id = values[index];
          //   console.log();
          //   customStoneProperties[paramName].push({ id: id, name: valuesText[index] });
          //   if (customStoneProperties[paramName].length === 0) {
          //   customStoneProperties[paramName].push({ id: id, name: valuesText[index] });
          // } else {
          //   let sdIndex = customStoneProperties[paramName].findIndex(d => {
          //     return d.id === id;
          //   });
          //   if (sdIndex === -1) {
          //     customStoneProperties[paramName].push({ id: id, name: valuesText[index] });
          //   }
          // }
          // }
        }
      }
      // console.log('customStoneProperties');
      // console.log(customStoneProperties);
      // console.log(this.criterialistData);
      this.criterialistData = this.criterialistData.map(cd => {
        if (customStoneProperties[cd.name]) {
          cd['selected'] = customStoneProperties[cd.name];
        }
        return cd;
      });
      this.selectedProperties = JSON.parse(JSON.stringify(customStoneProperties));
      console.log(this.criterialistData);
      this.viewDeptAndIfDone = await this.getDeptAndIfDoneDeptOnBasisOfRouteId(evt);
      console.log(this.viewDeptAndIfDone)
      if (!this.viewDeptAndIfDone) {
        console.log("returning");
        return;
      }
      this.currentView = 'packetRouteForm';
      let ID: number = rowData.id;
      let routeType: string = !!rowData.routeType ? rowData.routeType : null;//id
      let routeTypeArr: any = !!routeType && rowData.routeTypeId ? [{ id: rowData.routeTypeId, routeType: rowData.routeType }] : [];
      this.routeName = rowData.ruleName;
      let date = rowData.fromDate;
      console.log(date);
      const d = new Date(date);
      let month: string | number = (d.getMonth() + 1)
      month = month < 10 ? '0' + month : month;
      let day: string | number = d.getDate();
      day = day < 10 ? '0' + day : day;
      const result = d.getFullYear() + '-' + month + '-' + day;
      console.log(result);
      this.fromDate = result;
      console.log(this.viewDeptAndIfDone);
      let idarray = this.viewDeptAndIfDone.deptList.map((x: any) => x.deptId);
      this.requireDepartmentIds = this.viewDeptAndIfDone.ifDoneList.map((x: any) => x.ifDoneDept);//ifDones
      let toggleOffArr = [];
      this.reorderedDepartments = this.reorderedDepartments.filter((dept) => {
        if (!idarray.includes(dept.id)) {
          dept.departmentChkDisabled = true;
          dept.toggle = true;
          toggleOffArr.push(dept)
        }
        return idarray.includes(dept.id);
      });
      console.log(toggleOffArr);
      this.restrictArray = toggleOffArr.map((d) => d.id);
      this.reorderedDepartments = this.reorderedDepartments.map((d) => {
        if (this.requireDepartmentIds.includes(d.id)) {
          d.checked = true;
          d.restrictedDisabled = true;

        }
        return d
      });
      //order array departement as per  this.viewDeptAndIfDone.deptList
      this.reorderedDepartments.sort(function (a, b) {
        return idarray.indexOf(a.id) - idarray.indexOf(b.id);
      });
      this.reorderedDepartments = this.reorderedDepartments.concat(toggleOffArr);
      console.log(this.reorderedDepartments);
      console.log(this.requireDepartmentIds);
      console.log(idarray);

      // 'Hello' + ' ' + 'World';
      /*let endpoint: string = `${routeMasterAPIs.departmenttRuleConfigEndPoint}/${ID}`;
      // let deptConfigRules: number = rowData.id;
      let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint)
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {

        // currentDept
        this.departmentConfigRules = response.data;
        this.departmentCriteriaTableData = this.departmentConfigRules.map((d: any) => {
          let obj: any = {//test
            id: d.id,
            currDepartment: d.currDept,
            currDeptObj: { id: d.currDeptId, currentDept: d.currDept },
            nextDepartment: d.nextDepartment,
            nextDeptObj: { id: d.nextDeptId, nextDept: d.nextDepartment },
            // route: null,
            criteria: JSON.parse(d.deptCriteria),
            criterion: [],
          };
          return obj;
        });
      } else {
        console.error(response);
      }
      console.log(routeTypeArr);
      console.log(this.departmentCriteriaTableData);
      console.log(this.departmentConfigRules);*/
      this.routeFormJSON['routeSelectionId'] = rowData.routeSelectionId;
      this.routeFormJSON['routeType'] = routeTypeArr;
      this.routeFormJSON['routeName'] = this.routeName;
      this.routeFormJSON['orderKey'] = rowData.routeName;
      this.routeFormJSON['fromDate'] = result;
      this.routeFormJSON['properties'] = this.selectedProperties;
      // this.routeFormJSON['departmentConfigRules'] = this.departmentConfigRules;
      // this.routeFormJSON['departmentCriteriaTableData'] = this.departmentCriteriaTableData;
      // this.routeFormJSON['weightage'] = this.weightage;
      // this.routeFormJSON['isDefault'] = this.isDefault;
      // this.routeFormJSON['routeJSONData'] = {};
      console.log(this.routeFormJSON);
      this.checkSaveRouteFormValidation();
    }
  }
  deleteRoute(evt) {
    // console.log('%cdeleteRoute evt', "color:green;fot-size:14px");
    // console.log(evt);
    let rowData: any = evt.row.data;
    // let selData: any = evt.row.key;
    // console.log(selData, rowData);
    if (rowData) {
      let ID: number = rowData.routeSelectionId;
      let deleteAPIEndpoint: string = `${routeMasterAPIs.deleteRouteEndpoint}${ID}`
      
      this.httpService.deleteData(true, deleteAPIEndpoint).subscribe((result: any) => {
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          this.getRoutemasterData();//fetching data for Route Master grid
          
          this.configService.showToast("success", result.message);
        } else {
          
          // this.configService.showToast("error", d.message);
        }
      });
    }
  }

  async getDeptAndIfDoneDeptOnBasisOfRouteId(evt) {
    let rowData: any = evt.row.key;
    // let selData: any = evt.row.key;
    // console.log(selData, rowData);
    if (rowData) {
      let ID: number = rowData.routeMasterId;
      this.routeId = ID;
      let endpoint: string = `${routeMasterAPIs.getDeptAndIfDoneDeptOnBasisOfRouteId}${ID}`
      let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint)
      if (response.statusCode === 200) {
        response.data.deptList.map((deptInfo) => {
          if (deptInfo.processRuleConfigList) {
            this.savedRouteInformation[deptInfo.deptId] = deptInfo.processRuleConfigList;
          }
        })
        // this.reorderedDepartments = this.reorderedDepartments.filter(obj => {
        //   if (response.data.deptList.find((deptInfo) => deptInfo.deptId === obj.id)) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // })
        // this.reorderedDepartments = this.reorderedDepartments.map((deptInfo) => {
        //   return {
        //     ...deptInfo,
        //     toggle: false,
        //     checked: false
        //   }
        // })
      }
      console.log(this.defaultDepartments);
      console.log(this.savedRouteInformation);
      console.log(this.reorderedDepartments);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.viewDeptAndIfDone = response.data;
        console.log(this.viewDeptAndIfDone);
      } else {
        console.error(response);
        return null;
      }
      return this.viewDeptAndIfDone;
    }
  }
  onPaginationChange(evt) {
    // console.log('%cPagination change', "color:green;fot-size:14px");
    // console.log(evt);
  }

  async fetchDepartementRuleConfigDataByRouteId(routeID: number) {

    let endpoint: string = `${routeMasterAPIs.departmenttRuleConfigEndPoint}/${routeID}`
    // this.httpService.fetchData(true, endpoint).subscribe(response => {
    //   console.log('fetchDepartementRuleConfigDataByRouteId');
    //   console.log(response);
    //   this.httpService.getErrorAndDisplayIt(response);
    //   if (response.statusCode === 200) {
    //     this.departmentConfigRules = response.data;
    //   } else {
    //     console.error(response);
    //   }
    // });
    let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint)
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.departmentConfigRules = response.data;
    } else {
      console.error(response);
    }
  }
  // departmentCriteriaFormJSON
  // checkAddParameterFormValidity() {
  onCriteriaMoalDismiss(d) {
    console.log('onCriteriaMoalDismiss');
    console.log(d);
    console.log(this.routeTypeName);
    let requiredCriterion: any = d.data, resultCriterion: any = {};
    this.deptCriterion = d.data;//Array
    if (!!requiredCriterion && requiredCriterion.length !== 0) {
      resultCriterion = {};
      requiredCriterion.forEach((r: any) => {
        if (r.isAbsolute == 0) {
          if (resultCriterion[r.operator] == undefined) {
            resultCriterion[r.operator] = {};//do not empty it every time
            resultCriterion[r.operator][r.paramName] = r.paramValue;
          } else {
            resultCriterion[r.operator][r.paramName] = r.paramValue;
          }
        } else {
          console.log(r.operator, r.paramValue);
          //dropdown
          if (r.operator && (r.operator == 'equals' || r.operator == 'notEqual')) {
            if (resultCriterion[r.operator] == undefined) {
              resultCriterion[r.operator] = {};//do not empty it every time
              resultCriterion[r.operator][r.paramName] = r.paramValue;
              // resultCriterion[r.operator]['paramValueText'] = r.paramValueText;
            } else {
              resultCriterion[r.operator][r.paramName] = r.paramValue;
              // resultCriterion[r.operator]['paramValueText'] = r.paramValueText;
            }
          } else {
            if (resultCriterion[r.operator] == undefined) {
              resultCriterion[r.operator] = {};//do not empty it every time
              resultCriterion[r.operator][r.paramName] = r.paramValue;
            } else {
              resultCriterion[r.operator][r.paramName] = r.paramValue;
            }
          }
        }
      });
      console.log(this.deptCriteriaJSON);

      if (this.routeTypeName && this.routeTypeName.toLowerCase() == "department route") {
        this.deptCriteriaJSON = resultCriterion
        this.departmentCriteriaFormJSON['criteria'] = this.deptCriteriaJSON;
        this.departmentCriteriaFormJSON['criterion'] = d.data;
        this.isNewDepartmentJSON = Object.keys(this.deptCriteriaJSON).length === 0;
      } else {
        //Process route
        // this.deptCriteriaJSON = resultCriterion
        this.processCriteriaFormJSON['packetCriteria'] = resultCriterion;
        this.processCriteriaFormJSON['packetCriterion'] = d.data;
        this.isNewPacketCriteriaJSON = Object.keys(resultCriterion).length === 0;
      }
      // departmentCriteriaFormJSON = {//test
      //   currDepartment: null,
      //   nextDepartment: null,
      //   route: null,
      //   criteria: null
      // };
      this.checkAddDeptCriteriaFormValidation();
    }
  }
  deptapicall() {
    this.defaultDepartments = [];
    this.reorderedDepartments = [];
    this.httpService.fetchData(true, routeMasterAPIs.defaultDepartmentFlow).subscribe(response => {
      console.log('getRouterDeptData');
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        let data: any = response.data;
        data = data.map(d => {
          let md: any = { ...d };
          md.restrictedDisabled = false;
          md.departmentChkDisabled = false;
          return md;
        })
        this.defaultDepartments = response.data;
        this.reorderedDepartments = response.data;
      } else {
        console.error(response);
      }
      console.log(this.defaultDepartments);

    });
  }
  // saveNewRouteData(from) {
  saveRouteData(from) {
    console.log('saveNewRouteData-Department or Process');
    console.log(this.selectedProperties);
    console.log(this.reorderedDepartments);
    console.log(this.requireDepartmentIds);
    console.log(this.restrictArray);
    let finalDepartpartments = JSON.parse(JSON.stringify(this.reorderedDepartments));
    console.log(finalDepartpartments);
    finalDepartpartments = finalDepartpartments.filter(fd => this.restrictArray.indexOf(fd.id) == -1);
    console.log(finalDepartpartments);
    let ifDoneDepartments = [];
    let ifDones = {};
    finalDepartpartments.forEach((d, k) => {
      this.requireDepartmentIds.forEach((ID) => {
        let index = finalDepartpartments.findIndex(d => d.id === ID);
        if (k > index) {
          if (!ifDones[d.id]) {
            ifDones[d.id] = [];
            ifDones[d.id].push(ID);
          } else {
            if (ifDones[d.id].indexOf(ID) == -1) {
              ifDones[d.id].push(ID);
            }
          }
        }
      });
    });
    for (let d in ifDones) {
      let obj = {};
      obj[d] = ifDones[d];
      ifDoneDepartments.push(obj);
    }
    console.log(ifDoneDepartments);
    // ifDoneDepartments = {
    //   [this.requireDepartmentIds[1]]: this.requireDepartmentIds[0]
    // }
    console.log(ifDoneDepartments);
    let departmentList = [];
    let deptid = finalDepartpartments.map((data) => {
      let obj = {
        "departmentId": data.id,
        "deptCriteria": null
      }
      departmentList.push(obj);
      return data.id
    })
    let checkbox = this.requireDepartmentIds.join("");
    let specialKey = deptid.join("") + "-" + checkbox;
    this.routeFormJSON['orderKey'] = specialKey;
    this.routeJSONData = {}; let criteriaTableData: any = [], properties: any = [];
    let payload: any = {};
    // this.routeFormJSON['routeJSONData'] = this.deptCriteriaJSON;
    // let departmentRuleConfigs: any = [], processRuleConfigs: any = [];
    let routeTypeId: number = !!this.routeFormJSON && !!this.routeFormJSON.routeType[0] && this.routeFormJSON.routeType[0].id ? this.routeFormJSON.routeType[0].id : 1//set default route(Department);
    // let weightage: number = !!this.routeFormJSON && this.routeFormJSON.weightage ? parseInt(this.routeFormJSON.weightage) : null;
    if (this.routeFormJSON['properties']) {
      Object.keys(this.routeFormJSON['properties']).forEach(key => {
        if (this.routeFormJSON['properties'][key] && this.routeFormJSON['properties'][key].length > 0) {
          let obj: Object = {
            isAbsolute: 1,//dropdown
            operator: 'in',//dropdown
            operatorText: 'In',//dropdown
            paramName: key,
            paramNameObj: { id: this.criteriaList[key], name: key },
            paramStrValue: this.routeFormJSON['properties'][key].map(d => d.name).join(),
            paramValue: {
              paramNameId: this.criteriaList[key],
              values: this.routeFormJSON['properties'][key].map(d => d.id),
              valuesText: this.routeFormJSON['properties'][key].map(d => d.name)
            },
          };
          properties.push(obj);
        }
      });
    }

    console.log(properties);
    // criteriaTableData = criteriaTableData.concat(properties, !!this.routeFormJSON['criterion'] ? this.routeFormJSON['criterion'] : []);//
    // console.log(criteriaTableData);
    let criterion: any = this.makeCriterionAndReturnedIt(!!this.routeFormJSON['criterion'] ? this.routeFormJSON['criterion'] : []);
    let propertiesCriterion: any = this.makeCriterionAndReturnedIt(properties);
    let allCriterion: object = null;
    if (!!criterion) {
      allCriterion = JSON.parse(JSON.stringify(criterion));
      allCriterion['stoneProperties'] = propertiesCriterion;
    } else {
      allCriterion = {};
      allCriterion['stoneProperties'] = propertiesCriterion;
    }
    console.log(criterion);
    console.log(propertiesCriterion);
    console.log(allCriterion);
    payload = {
      // id: this.routeFormJSON['id'],
      routeType: routeTypeId,
      ruleName: this.routeFormJSON['routeName'],
      fromDate: this.fromDate,//new Date()
      specialKey: this.routeFormJSON['orderKey'],
      criterion: !!allCriterion ? JSON.stringify(allCriterion) : null,
      ifDones: ifDoneDepartments.length !== 0 ? ifDoneDepartments : null,
      // ifDones: JSON.stringify(ifDoneDepartments),
      departmentList: departmentList
    };
    // if (this.routeTypeName && this.routeTypeName.toLowerCase() === 'department route') {
    //   if (from == 'saveRoute') {
    // this.departmentCriteriaTableData.forEach((d: any) => {
    //   let newObj = {
    //     "currDept": d.currDeptObj.id,
    //     "nextDepartment": d.nextDeptObj.id,
    //     "deptCriteria": !!d.criteria ? JSON.stringify(d.criteria) : null
    //   };
    //   departmentRuleConfigs.push(newObj);
    // });
    // } else {
    // this.departmentCriteriaTableData.forEach((d: any) => {
    //   let newObj = {
    //     "id": d.id,
    //     "currDept": d.currDeptObj.id,
    //     "nextDepartment": d.nextDeptObj.id,
    //     "deptCriteria": !!d.criteria ? JSON.stringify(d.criteria) : null
    //   };
    //   departmentRuleConfigs.push(newObj);
    // });
    // }
    // this.routeFormJSON['departmentConfigRules'] = departmentRuleConfigs;
    // this.routeFormJSON['departmentCriteriaTableData'] = this.departmentCriteriaTableData;
    //Department


    // } else {
    // if (from == 'saveRoute') {
    // this.processCriteriaTableData.forEach((d: any) => {
    //   let obj = {
    //     // "allowDuplicates": 1,
    //     "deptId": d.currDepartmentObj.id,
    //     "ifDone": !!d && !!d.ifDoneActionObj && !!d.ifDoneActionObj.id ? d.ifDoneActionObj.id : null,
    //     "stateId": d.currStateObj.id,
    //     "nextAction": d.nextActionObj.id,
    //     "isVirtual": d.isVirtual,
    //     "packetCriterion": !!d.packetCriteria ? JSON.stringify(d.packetCriteria) : null,
    //     "userCriterion": null
    //   };
    //   processRuleConfigs.push(obj);
    // });
    // } else {
    // this.processCriteriaTableData.forEach((d: any) => {
    //   let obj = {
    //     // "allowDuplicates": 1,
    //     "id": d.id,
    //     "deptId": d.currDepartmentObj.id,
    //     "stateId": d.currStateObj.id,
    //     "nextAction": d.nextActionObj.id,
    //     "ifDone": d.ifDoneActionObj.id,
    //     "isVirtual": d.isVirtual,
    //     "packetCriterion": !!d.packetCriteria ? JSON.stringify(d.packetCriteria) : null,
    //     "userCriterion": null
    //   };
    //   processRuleConfigs.push(obj);
    // });
    // }
    // this.routeFormJSON['processCriteriaTableData'] = this.processCriteriaTableData;
    // this.routeFormJSON['processConfigRules'] = processRuleConfigs;
    // payload = {
    //   // id: this.routeFormJSON['id'],
    //   routeType: routeTypeId,
    //   routeName: this.routeName,
    //   // fromDate: "",//new Date()
    //   processRuleConfigList: processRuleConfigs
    // };
    // }
    // let updateAPIEndpoint: string = "";
    // if (this.routeFormJSON['id']) {
    //   updateAPIEndpoint = `${routeMasterAPIs.saveUpdateRouteEndpoint}/${this.routeFormJSON['id']}`;
    // }

    // console.log(this.routeJSONData);
    console.log(this.routeFormJSON);
    payload.departmentList = payload.departmentList.map((info: any) => {
      if (this.routeInformation.hasOwnProperty(info.departmentId)) {
        let processRuleConfigList = this.routeInformation[info.departmentId].filter((info)=> info.isSelected);
        processRuleConfigList = processRuleConfigList.map((info) => {
          return {
            nextAction: info.id,
            isVirtual: info.isVirtual,
            stateId: 2,
            ifDoneActions: info.selectedData
          }
        })
        return {
          ...info,
          processRuleConfigList: processRuleConfigList
        }
      } else {
        return {
          ...info,
          processRuleConfigList: []
        }
      }
    })
    console.log(payload);
    if (from == 'saveRoute') {
      
      this.httpService.saveData(true, routeMasterAPIs.saveUpdateRouteEndpoint, payload).subscribe((result: any) => {
        console.log('%cPacket-Rule Save......', 'color:orange;font-size:18px;');
        console.log(result);
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          console.log('Success');
          this.routeName = null;
          // this.weightage = null;
          this.isEditRoute = false;
          this.isDefault = false;
          this.isNewDepartmentJSON = true;
          this.isNewPacketCriteriaJSON = true;
          this.isEditDeptCriteria = false;
          this.reorderedDepartments = [];
          this.requireDepartmentIds = [];
          this.routeJSONData = {};
          this.deptCriteriaJSON = {};
          this.departmentCriteriaTableData = [];
          this.processCriteriaTableData = [];
          // departmentRuleConfigs = [], processRuleConfigs = [];
          this.criterialistData = this.criterialistData.map(d => {
            d.selected = [];
            return d;
          });
          this.selectedProperties = {};
          payload = {};
          this.routeFormJSON = {
            // id: null,
            routeName: null,
            properties: null,
            criterion: null,
            routeType: null,
            fromDate: null,
            orderKey: null,//department orders
          };
          if (this.suscriptionParam2) {
            this.suscriptionParam2.unsubscribe();
          }
          this.getRoutemasterData();//fetching data for Route Master grid
          this.currentView = "packetRouterMaster";
          
          this.configService.showToast("success", result.message);
          this.emptyRouteJSONForm();
        } else {
          console.log(result);
          console.error('Error');
          
          // this.configService.showToast("error", d.message);
        }
      });
    } else {
      payload['id'] = this.routeId;
      payload['routeSelectionId'] = this.routeFormJSON.routeSelectionId;
      console.log(payload);
      
      this.httpService.saveData(true, routeMasterAPIs.saveUpdateRouteEndpoint, payload).subscribe((result: any) => {
        console.log('%cPacket Rule Update......', 'color:orange;font-size:18px;');
        console.log(result);
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          console.log('Success');
          this.routeName = null;
          // this.weightage = null;
          // this.isEdit=false;
          this.isEditRoute = false;
          this.isEditDeptCriteria = false;
          this.isNewDepartmentJSON = true;
          this.isNewPacketCriteriaJSON = true;
          this.reorderedDepartments = [];
          this.requireDepartmentIds = [];
          this.routeJSONData = {};
          this.deptCriteriaJSON = {};
          this.departmentCriteriaTableData = [];
          this.processCriteriaTableData = [];
          // departmentRuleConfigs = [], processRuleConfigs = [];
          this.criterialistData = this.criterialistData.map(d => {
            d.selected = [];
            return d;
          });
          this.selectedProperties = {};
          payload = {};
          this.routeFormJSON = {
            id: null,
            routeName: null,
            properties: null,
            criterion: null,
            routeType: null,
            fromDate: null,
            orderKey: null,//department orders
          };
          if (this.suscriptionParam2) {
            this.suscriptionParam2.unsubscribe();
          }
          this.getRoutemasterData();//fetching data for Route Master grid
          this.currentView = "packetRouterMaster";
          
          this.configService.showToast("success", result.message);
          this.emptyRouteJSONForm();
        } else {
          console.log(result);
          console.error('Error');
          
          // this.configService.showToast("error", d.message);
        }
      });
    }
  }
  makeCriterionAndReturnedIt(data) {
    let obj: Object = {};
    data.forEach((r: any) => {
      if (r.isAbsolute == 0) {
        if (obj[r.operator] == undefined) {
          obj[r.operator] = {};//do not empty it every time
          obj[r.operator][r.paramName] = r.paramValue;
        } else {
          obj[r.operator][r.paramName] = r.paramValue;
        }
      } else {
        console.log(r.operator, r.paramValue);
        //dropdown
        if (r.operator && (r.operator == 'equals' || r.operator == 'notEqual')) {
          if (obj[r.operator] == undefined) {
            obj[r.operator] = {};//do not empty it every time
            obj[r.operator][r.paramName] = r.paramValue;
            // obj[r.operator]['paramValueText'] = r.paramValueText;
          } else {
            obj[r.operator][r.paramName] = r.paramValue;
            // obj[r.operator]['paramValueText'] = r.paramValueText;
          }
        } else {
          if (obj[r.operator] == undefined) {
            obj[r.operator] = {};//do not empty it every time
            obj[r.operator][r.paramName] = r.paramValue;
          } else {
            obj[r.operator][r.paramName] = r.paramValue;
          }
        }
      }
    });
    if (Object.keys(obj).length === 0) {
      obj = null;
    }
    return obj;
  }
  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
    if (this.segment == "routeMaster") {
      this.getRouteSelectionData();
      this.currentView = "packetRouterMaster";
    }
    // this.getRouteSelectionData();
  }
  onIsVirtualChange(evt) {
    console.log('onIsVirtualChange');
    console.log(evt);
    this.isVirtual = !!evt && evt.detail && evt.detail.checked ? evt.detail.checked : null;
    this.processCriteriaFormJSON['isVirtual'] = this.isVirtual === true ? 1 : 0;
  }
  addProcessCriteria() {
    console.log("Process add btn");
    console.log(this.processCriteriaFormJSON);
    // this.processCriteriaFormJSON = {//test
    //   id: null,
    //   currDepartment: this.pro,
    //   currState: this.selectedState,
    //   nextAction: this.selectedNextAction,
    //   ifDoneAction: this.selectedIfDonAction,
    //   // route: null,
    //   packetCriteria: null,
    //   packetCriterion: [],
    //   isVirtual: this.isVirtual,
    //   // packetCriteria: null,//for User crtiteria
    //   // packetCriterion: [],
    // };
    // let {currDepartment,nextDepartment, route,criteria} = this.departmentCriteriaFormJSON;
    // let index = this.departmentCriteriaTableData.findIndex((d: any) => d.currDepartment == this.departmentCriteriaFormJSON['currDepartment'] && d.nextDepartment == this.departmentCriteriaFormJSON['nextDepartment']);
    // console.log(index);
    console.log(this.deptCriteriaIndex);
    //   routeFormJSON: any = {
    //     routeType: [],
    //     routeName: this.routeName,
    //     // currentDept: [],
    //     // nextDept: [],
    //     routeJSONData: {},
    //     departmentConfigRules: [],
    //     processConfigRules: [],
    //     departmentCriteriaTableData: [],
    //     processCriteriaTableData: [],
    //   };
    this.processCriteriaTableData.push(this.processCriteriaFormJSON);
    console.log(this.processCriteriaTableData);
    //   routeFormJSON: any = {
    //     routeType: [],
    //     routeName: this.routeName,
    //     // currentDept: [],
    //     // nextDept: [],
    //     routeJSONData: {},
    //     departmentConfigRules: [],
    //     processConfigRules: [],
    //     departmentCriteriaTableData: [],
    //     processCriteriaTableData: [],
    //   };
    this.processCriteriaFormJSON = {//test
      id: null,
      currDepartment: null,
      currState: null,
      nextAction: null,
      ifDoneAction: null,
      // route: null,
      packetCriteria: null,
      packetCriterion: [],
      // packetCriteria: null,//for User crtiteria
      // packetCriterion: [],
      isVirtual: 0
    };
    let isVirtual: boolean = this.processCriteriaFormJSON['isVirtual'] === 1 ? true : false;
    this.dropDownCurrentDept['selectedData'] = [];
    this.dropDownStateData['selectedData'] = [];
    this.dropDownNextActionsData['selectedData'] = [];
    this.dropDownIfDoneData['selectedData'] = [];
    this.isVirtual = isVirtual;
    // this.isNewPacketCriteriaJSON = !!this.processCriteriaFormJSON['packetCriteria'] && Object.keys(this.processCriteriaFormJSON['packetCriteria']).length === 0;
    this.isNewPacketCriteriaJSON = true;
    // isNewDepartmentJSON
    /*if (this.deptCriteriaIndex != null && this.deptCriteriaIndex > -1) {
      this.departmentCriteriaTableData[this.deptCriteriaIndex] = this.departmentCriteriaFormJSON;
    } else {
      console.log('add NEWWWWWWWWWWWW');
      this.departmentCriteriaTableData.push(this.departmentCriteriaFormJSON);
    }
    // this.departmentCriteriaTableData.push(this.departmentCriteriaFormJSON);
    this.dropDownCurrentDept['selectedData'] = [];
    this.dropDownNextDept['selectedData'] = [];
    this.deptCriteriaJSON = null;
    this.departmentCriteriaFormJSON = {//test
      id: null,
      currDepartment: null,
      currDeptObj: null,
      nextDepartment: null,
      nextDeptObj: null,
      // route: null,
      criteria: null,
      criterion: [],
    };
    this.isNewDepartmentJSON = true;
    this.deptCriteriaIndex = null;
    this.checkAddDeptCriteriaFormValidation();*/
    this.checkSaveRouteFormValidation();
  }
  async editProcessCriteria(id, idx) {
    console.log('===========editDeptCriteria===========');
    console.log(idx);
    /*this.deptCriteriaIndex = idx;
    let rowData = this.departmentCriteriaTableData[idx];
    console.log(rowData);
    // this.emptyParamOperatorBasedFrom();
    // this.currentCriteriaRowData = !!rowData ? rowData : null;
    // console.log(this.routeCriteriaTableData);

    if (rowData) {
      this.isEditDeptCriteria = true;
      let criterionData: any = [];
      console.log(rowData);
      this.dropDownCurrentDept['selectedData'] = [rowData.currDeptObj];
      this.dropDownNextDept['selectedData'] = [rowData.nextDeptObj];
      this.deptCriteriaJSON = rowData.criteria;
      this.departmentCriteriaFormJSON = {//test
        id: rowData.id,
        currDepartment: rowData.currDepartment,
        currDeptObj: rowData.currDeptObj,
        nextDepartment: rowData.nextDepartment,
        nextDeptObj: rowData.nextDeptObj,
        // route: null,
        criteria: rowData.criteria,
        criterion: rowData.criterion
      };
      // this.weightage = rowData.weightage;
      criterionData = this.getCriterionData(rowData);
      console.log(this.deptCriterion);
      // this.deptCriterion
      this.deptCriterion = criterionData;
      this.departmentCriteriaFormJSON['criterion'] = criterionData;
      this.isNewPacketCriteriaJSON = this.departmentCriteriaFormJSON['criterion'].length === 0;
          // this.isNewPacketCriteriaJSON = true;
      console.log(this.isNewDepartmentJSON);
      console.log(criterionData);
      this.checkAddDeptCriteriaFormValidation();
      // this.populateAndOpenEditCriteriaForm();
    }*/
  }
  async removeProcessCriteria(ID: number, index: number) {
    console.log('===========removeDeptCriteria===========');
    console.log(ID, index);
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'CLOSE',
        handler: (d: any) => {
          console.log('Closed', d);
        }
      },
      {
        text: 'OK',
        handler: (d: any) => {
          console.log('-----------temp-----------');
          if (!!ID) {
            this.deleteProcessCriterion(ID, index);
          } else {
            this.processCriteriaTableData.splice(index, 1);
          }
          // this.routeCriteriaTableData = this.routeCriteriaTableData.filter(d =>  != id);
        }
      }]
    });
    await alert.present();
  }
  deleteProcessCriterion(id: number, indexNum: number) {
    console.log('%cdeleteDeptCrietion evt', "color:green;fot-size:14px");
    console.log(id);
    let deleteAPIEndpoint: string = `${routeMasterAPIs.deleteProcessConfigEndPoint}/${id}`;
    console.log(deleteAPIEndpoint);
    
    this.httpService.deleteData(true, deleteAPIEndpoint).subscribe((result: any) => {
      this.httpService.getErrorAndDisplayIt(result);
      if (result.statusCode == 200) {
        // this.getRoutemasterData();//fetching data for Route Master grid
        this.processCriteriaTableData.splice(indexNum, 1);
        
        this.configService.showToast("success", result.message);
      } else {
        
        // this.configService.showToast("error", d.message);
      }
    });
  }
  checkProcessCriteriaFormValidation() {
    console.log('checkProcessCriteriaFormValidation');
    console.log(this.processCriteriaFormJSON);

  }

  onSelectionChange(evt) {
    console.log('onSelectionChange');
    console.log(evt);
    console.log(this.criterialistData);
    let selectedArray: any = evt.selectedArray;
    let name: any = evt.name;
    console.log(this.selectedProperties);
    if (this.selectedProperties[name] == undefined) {
      this.selectedProperties[name] = []
      this.selectedProperties[name] = selectedArray
    } else {
      this.selectedProperties[name] = selectedArray
      if (selectedArray.length === 0) {
        delete this.selectedProperties[name];
      }
    }
    /*{
      "paramName": "Color",
      "paramValue": {
          "paramNameId": 6,
          "values": [
              223,
              224,
              225
          ],
          "valuesText": [
              "D",
              "E",
              "F"
          ]
      },
      "operator": "in",
      "isAbsolute": 1,
      "paramNameObj": {
          "id": 6,
          "name": "Color"
      },
      "paramStrValue": "D,E,F",
      "tempId": 3,
      "operatorText": "In"
  }*/
    console.log(this.selectedProperties);
    this.routeFormJSON['properties'] = this.selectedProperties;
    this.checkSaveRouteFormValidation();
  }
  getOtherCriteria(evt) {
    console.log('getOtherCriteria');
    console.log(evt);
    this.otherCriterionData = evt;
    this.routeFormJSON['criterion'] = evt;
    this.checkSaveRouteFormValidation();
  }
  ngOnChanges(change) {
    console.log('---ngOnChanges---');
    console.log(change);
  }
  // ngDoCheck() {
  //   console.log('---ngDoCheck---');
  // }
  // ngAfterContentInit() {
  //   console.log('---ngAfterContentInit---');
  //   
  // }
  // ngAfterContentChecked() {
  //   console.log('---ngAfterContentChecked---');
  // }
  // ngAfterViewInit() {
  //   console.log('---ngAfterViewInit---');
  //   
  // }
  // ngAfterViewChecked() {
  //   console.log('---ngAfterViewChecked---');
  //   
  //   }
  handleReorderedData(evt: CustomEvent<ItemReorderEventDetail>) {
    console.log('handleReorderedData');
    // console.log(evt);
    const itemMove = this.reorderedDepartments.splice(evt.detail.from, 1)[0];
    this.reorderedDepartments.splice(evt.detail.to, 0, itemMove);
    console.log(itemMove);
    console.log('Dragged from index', evt.detail.from, 'to', evt.detail.to);
    console.log(this.reorderedDepartments);
    evt.detail.complete();

  }

  onSelectParamater(parameter, value){
    if(!!this.criteriaParameterLists && this.criteriaParameterLists.length > 0){
      let startIndex,endIndex;
      this.criteriaParameterLists.map(cpl =>{
        if(cpl.parameterName === parameter.parameterName){
          if(cpl?.startIndex != null && cpl?.endIndex != null){
            cpl.startIndex = startIndex = null; 
            cpl.endIndex = endIndex = null; 
            // const reversedIndex = [...cpl?.paramValues].reverse().findIndex(item => item.isSelected === true);
            // endIndex = reversedIndex !== -1 ? cpl?.paramValues.length - 1 - reversedIndex : -1;
            // let index = cpl?.paramValues.findIndex(val => val.id === value.id)
            // if(index > endIndex){
            //   cpl.endIndex = endIndex = index;
            // }else{
            //   startIndex = cpl.startIndex = index;
            // }
          }
          if(cpl?.startIndex != null && cpl?.endIndex == null){
            let index = cpl?.paramValues?.findIndex(val => val.id === value.id)
            if(index > -1){
              cpl.endIndex = endIndex = index;  
            }
          }else if(cpl?.endIndex != null && endIndex == null){
            endIndex = cpl.endIndex;
          }
          if(cpl?.startIndex == null){
            let index = cpl?.paramValues?.findIndex(val => val.id === value.id)
            if(index > -1){
              cpl.startIndex = startIndex = index;  
            }
          }else if(startIndex == null){
            startIndex = cpl.startIndex;
          }
          if(startIndex != null && endIndex == null){
            cpl?.paramValues?.map((pv,i) => pv.isSelected = false)
            cpl.paramValues[startIndex].isSelected = true;
          }else if(startIndex != null && endIndex != null){
            cpl?.paramValues?.map((pv,i) =>{
              pv.isSelected = false;
              if(i >= startIndex && i<=endIndex){
                pv.isSelected = true;
              }
            })
          }
        }
      })
    }
  }

  ngOnDestroy() {
    console.log("---Route Master Destroyed---");
    if (this.suscriptionParam) {
      this.suscriptionParam.unsubscribe();
    }
  }
}
