import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { ConfigService } from '../service/config.service';
import { ModalController, NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { tempDataActions } from 'src/app/tempDataStore/tempData.action-type';
import { TrybemodalComponent } from '../../trybemodal/trybemodal.component';
import { chekerAction } from './checker.action.type';
import { CheckerState } from './checker.state';

@Injectable()
export class CheckerEffects {
  [x: string]: any;

  constructor(private navCtrl: NavController,
    private actions: Actions,
    public httpService: HttpService,
    private store: Store<CheckerState>,
    private loaderService: LoaderService,
    private modalController: ModalController,
    public router: Router) { }

  getCheckerPlans$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.getCheckerPlans),
      tap((x: any) => {
        
      }),
      concatMap((action) => {
        return this.httpService.fetchChekerPlanData(action.packetIdOb).pipe(
          map((res) => {
            console.log('..Packet Plans..');
            console.log(res);
            let resultData = res.data;
            if (res.statusCode == 200) {
              console.log('..HIDE..Packet Plans..');
              //this.httpService.dismissLoader();
              
            }
              this.httpService.getErrorAndDisplayIt(res);
            return !!resultData ? resultData : [];
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error)
            return throwError(error);
          })
        )
      }),
      map((data: any) => {
        return chekerAction.saveCheckerPlans({
          packetPlanstones: data
        })
      })
    ),
  );



  updateFLCLClAFromPacketIdForAllSelectedPlan$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.updateFLCLClAFromPacketIdForAllSelectedPlan),
      tap((x: any) => {
        console.log('Update Stones Plan data by FL,CL, CLA and show loader');
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap((action) =>
        from(
          this.httpService.updateFLCLClAFromPacketIdForAllSelectedPlan(action.updateData)
        ).pipe(
          map((res) => {
            console.log(res);
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              this.showModal(true, res.message, {});
            } else {
              // this.httpService.dismissLoader();
              this.showModal(false, res.message, {});
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((dta: any) => {
        return [
          // SignerAction.getPacketPlans({packetIdOb:dta.packetIdObj})
        ];
      })
    )
  );

  getChatConv$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.getChatConv),
      concatMap((action) => {
        console.log(action, "action");
        return this.httpService.fetchChatConv(action.packetId).pipe(
          map((res) => {
            console.log('..save Packet Plans..');
            console.log(res);
            let resultData = res.data;
            if (res.statusCode == 200) {
              console.log('..HIDE..Packet Plans..');
              //this.httpService.dismissLoader();
              
            }
            return { data: resultData, packetId: action.packetId };
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      }),
      mergeMap((res: any) => {
        return [
          chekerAction.saveChatConv({ chatData: res.data, packetId: res.packetId }),
          tempDataActions.saveConversation({ chatData: res.data })
        ];
      })
      // map((res: any) => {
      //   return chekerAction.saveChatConv({
      //     chatData: res.data, packetId:res.packetId
      //   })
      // })
    ),
  )

  sendChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.sendChat),
      tap(async (x: any) => {
        // await this.httpService.showLoader();
        
      }),
      concatMap((action) => {
        console.log(action, "action");
        return this.httpService.sendChat(action.chatData).pipe(
          map((res) => {
            // this.httpService.dismissLoader();
            
            console.log('..save Packet Plans..');
            console.log(res);
            let resultData = res.data;
            if (res.statusCode == 200) {
              console.log('..HIDE..Packet Plans..');
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action.chatData;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      }),
      map((data: any) => {
        return chekerAction.getChatConv({
          packetId: data.packetId
        })
      })
    ),
  )

  saveSelectedPlans$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.saveSelectedPlans),
      concatMap((action) => {
        console.log(action, "action");
        return this.httpService.saveChekerPlanData(action.packetPlanID).pipe(
          map((res) => {
            console.log('..save Packet Plans..');
            console.log(res);
            let resultData = res.data;
            if (res.statusCode == 200) {
              console.log('..HIDE..Packet Plans..');
              //this.httpService.dismissLoader();
              
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action.pktData;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      }),
      map((data: any) => {
        return chekerAction.getCheckerPlans({ packetIdOb: { packetId: data.id } })


      })
    ),
  )

  async showModal(state: boolean, message: string, data: any) {
    if (state) {
      if (!!data.data && !!data.data.voucherLabel) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: 'trybe_modal_print',
        });
        modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        return await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: 'trybe_modal_1',
        });
        modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        return await modal.present();
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: 'trybe_modal_0',
      });
      modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
      return await modal.present();
    }
  }
  async onModalDismiss(d: any) {
    console.log('onModalDismiss.....Effects');
    console.log(d);
  }



  getCheckerPlansAccordingToUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.getPlansAccToUSers),
      tap((x: any) => {
        
      }),
      concatMap((action) => {
        console.log(action, "action");
        return this.httpService.getPlansAccordingToUsers(action.id, action.packetId).pipe(
          map((res) => {
            let resultData = res.data;
            if (res.statusCode == 200) {
              console.log('..HIDE..Packet Plans..');
              //this.httpService.dismissLoader();
              
            }
            this.httpService.getErrorAndDisplayIt(res);
            return !!resultData ? resultData : [];
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error)
            return throwError(error);
          })
        )
      }),
      map((data: any) => {
        return chekerAction.savePlansAccToUSers({
          PlanAccordingToUSers: data
        })
      })
    ),
  );

  setCheckerPlanFinal$ = createEffect(() =>
    this.actions.pipe(
      ofType(chekerAction.setCheckerPlanFinal),
      tap((x: any) => {
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap((action) =>
        from(
          this.httpService.setCheckerPlanFinal(action.packetPlanId)
        ).pipe(
          map((res) => {
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // this.router.navigate['/actionsWindow'];

              this.router.navigate(['/home']);
              // this.showModal(true, res.message, {});
            } else {
              this.showModal(false, res.message, {});
            }
            // this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((dta: any) => {
        return [
          // masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: dta.rowData?.actionInfo?.id, formType: formType, barcodeObj: { packetId: dta.rowData[0].packetId }, actionInfo: dta.rowData.actionInfo })
        ];
      })
    )
  );
}