import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { DxSelectBoxModule } from 'devextreme-angular';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { NgxFormModule } from '../components/ngx-form/ngx-form.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { PlanDrawerComponent } from './plan-drawer.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { twoDecimalvaluesPipeModule } from '../pipes/two-decimal-values/two-decimal-values.module';



@NgModule({
  declarations: [PlanDrawerComponent, ClickOutsideDirective],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // HeaderComponentModule,
    DataGridComponentModule,
    NgxFormModule,
    DropdownModule,
    NgSelectModule,
    DropdownModule,
    DxSelectBoxModule,
    twoDecimalvaluesPipeModule,
  ],
  exports: [PlanDrawerComponent],
})
export class PlanDrawerModule { }
