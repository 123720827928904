import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ParameterMasterComponent } from './parameter-master.component';
import { FooterModule } from 'src/app/footer/footer.module';
import { WidthAlertModule } from 'src/app/width-alert/width-alert.module';
import { DataGridComponentModule } from '../../data-grid/data-grid.module';
import { HeaderComponentModule } from '../../header/header.module';
import { SingleMultiDropdownComponent } from '../../single-multi-dropdown/single-multi-dropdown.component';
import { SingleMultiDropdownModule } from '../../single-multi-dropdown/single-multi-dropdown.module';
import { NewDropDownModule } from '../../new-drop-down/new-drop-down.module';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { masterReducer } from '../state-management/masters.interface';
import { MasterEffect } from '../state-management/masters.effect';
import { childParameterReducer } from '../state-management/masters.reducers';
import { DynamicModalModule } from 'src/app/transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.module';

@NgModule({
  imports: [
    CommonModule,
    HeaderComponentModule,
    IonicModule,
    FooterModule,
    DataGridComponentModule,
    FormsModule,
    WidthAlertModule,
    StoreModule.forFeature('MASTERS',masterReducer ),
    EffectsModule.forFeature([MasterEffect]),
    SingleMultiDropdownModule,
    NewDropDownModule,
    DynamicModalModule
  ],
  declarations: [ParameterMasterComponent],
  entryComponents: [SingleMultiDropdownComponent],
providers: [],
})
export class ParameterMasterModule {}
