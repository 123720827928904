import { Component , OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpClient } from "@angular/common/http";
import { LoaderService } from 'src/app/services/loader/loader.service';
// import { Store } from '@ngrx/store';

@Component({
  selector: 'app-roughpacketvalue',
  templateUrl: './roughpacketvalue.component.html',
  styleUrls: ['./roughpacketvalue.component.scss'],
})
export class RoughpacketvalueComponent implements OnInit {
  readonly excelRegex = /\.xlsx$/i;
  readonly csvRegex = /\.csv$/i;
  showDownload: boolean = true;
  pageSize: any;
  roughpktdata: any;
  columns :any[] = [
    { name: 'File Name', prop: 'file'},
    { name: 'Created By', prop: 'createdBy'},
    { name: 'CreatedDate', prop: 'createdDate'},
    { name: 'Status', prop: 'status',columnTemplate:'file-upload-status'},
    // { name: 'Packet ID', prop: 'packetId'},
  ];



  constructor(
    public httpService: HttpService,
    public configService: ConfigService,
    public http: HttpClient,
    private loaderService: LoaderService
    // private store: Store<packetInward>,
  ) {
    this.getTrybeBaseUrl();
  }

  token: String = '';
  trybeUrl: String = '';
  ngOnInit() {
    this.fetchGridData();
  }

  async getTrybeBaseUrl() {
    this.trybeUrl = this.configService.getTrybeBaseUrl();
}

async fetchGridData() {
  try {
    
    let endpoint: string = `pricing-file-upload/pricingFiles`;
    const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
    if (res) {
      console.log(res);
      let resultCode: number = res.statusCode;
      let data: any = res.data;
      console.log(data);
      if (resultCode == 200) {
        console.log(res.data);
        if (res.data.length == 0) {
          this.configService.showToast('Error', 'No Data Available');
        }
        this.roughpktdata = res.data;
      } else {
        this.configService.showToast('Error', res.error);
      }
    }
  } catch (error) {
    console.error('An error occurred:', error);
    this.configService.showToast('Error', 'Something went wrong!');
  } finally {
     
  }
}



validateFile(fileName: string) {
  let isValid = true;
  let errorMsg = '';

  if ((!this.csvRegex.test(fileName)) && (!this.excelRegex.test(fileName))) {
      isValid = false;
      errorMsg = 'File must be in .csv or .xlsx';
  }
  if(errorMsg){
    this.configService.showToast('error',errorMsg)
  }
  return isValid
}




async fileUpload(event) {
  try {
     
    let file = event.target.files[0];
    if (!this.validateFile(file?.name)) return;
    const formatData = new FormData();
    formatData.append("file", file);
    const res: any = await this.httpService.saveDataToDB(true, `pricing-file-upload/uploadPricingFile`, formatData, 'Multipart').toPromise();
    if (res.statusCode == 200) {
      if (res.data) {
        let temp = res.data;
        if (temp.error == "error") {
          this.configService.showToast('error', 'Error In Uploading File');
        } else {
          this.configService.showToast('success', res.message);
          this.fetchGridData();
        }
      }
    } else {
      this.configService.showToast('error', res.error.message || 'Something went wrong!');
    }
  } catch (error) {
    console.error('An error occurred:', error);
    this.configService.showToast('error', error.message || 'Something went wrong!');
  } finally {
     
  }
}



async downloadFile(event:any){
  const fileName = event?.row?.data?.file || '';
  if(!fileName){
    this.configService.showToast('error', "File does not exist!");
    return;
  }
  let newFileName = fileName.split("/")
  let joinedPath = newFileName.slice(1).join('/');
  console.log(this.configService.getCentralFileUrl() +fileName);
  // window.open(this.configService.getCentralFileUrl() + newFileName + "/" + encodeURIComponent(joinedPath),'download');
  this.http.get(this.configService.getCentralFileUrl() +fileName, { responseType: 'blob' }).subscribe(
    (response: Blob) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    },
    (error) => {
      console.error('Failed to download file:', fileName, error);
    }
  );
  
  
}
  refreshTableData(){
    this.fetchGridData()
  }
}