
// debugger
export interface QCAnnotationState {
    isProcessing: boolean;
    isUpdateLoading: boolean;
    updateDetailSuccess: any;
    updateFailure: any;
    detailsOfPlansDoneBySuratChecker:any
    saveDetailsFormResponse: any;
}

export const initialQCAnnotationState: QCAnnotationState = {
    isProcessing: false,
    isUpdateLoading: false,
    updateDetailSuccess: null,
    updateFailure: null,
    detailsOfPlansDoneBySuratChecker:[],
    saveDetailsFormResponse: null
};