import { StaticInfo } from "src/environments/environment"

export interface Fluorescence {
  name: string,
  id: number
}

export interface parameterDetails {
  listOfAllPackets: ListOfAllPacket[]
}

export interface ListOfAllPacket {
  packetId: number
  listOfParameter: ListOfParameter[]
}

export interface ListOfParameter {
  parameterName: string
  parameterValue: any
}

export const printData: Object = {
  'company': StaticInfo['company'],
  'address': StaticInfo['address'],
  'issueFor': 'Flourescence Issue',
  'issueDate': null,//Jangad Date
  'issueNo': null,//Jnd No,
  'fromDept': 'Digital Imaging',//Jnd No,
  'fromUser': 'Hemant Vasantbhai Paththarwala',//
  'toDept': 'Digital Imaging',//Jnd No,
  'toUser': 'Jignesh DhirajKumar Modi',//Jnd No,
  'stoneType': StaticInfo['stoneType'],
  'processRemark': StaticInfo['processRemark'],
  'subProcessRemark': StaticInfo['subProcessRemark'],
  'transRemark': 'Issue',//or Return,
  'senderNotes': StaticInfo['senderNotes'],
  'data': {
    'packets': [{
      "id": 19,
      "packetId": "10649-12-BRAUNA#9",
      "cts": 2.605,
      "inDate": "2022-12-13T10:58:07.000Z",
      "lotNo": "10649-12-BRAUNA",
      "outDate": null,
      "refPacketId": null,
      "nfcCode": "E00401506493FA0A",
      "pktGrade": "A",
      "pktStatus": "O",
      "pktNo": "9",
      "predLimit": 3,
      "predRule": "ssc",
      "currAction": 131,
      "currState": 2,
      "currDept": 7,
      "currLocation": 6,
      "userId": 119,
      "responsibleUserId": 118,
      "partNo": null,
      "createdDate": "2022-12-26T11:10:49.000Z",
      "createdBy": "2-surat-dev-CSC",
      "modifiedDate": "2023-02-09T10:58:56.000Z",
      "modifiedBy": "2-surat-dev-di2",
      "refParentPacketId": null,
      "lastAction": null,
      "isPermissionNeeded": null,
      "routeId": 163,
      "barcodeNo": "7772062",
      "processRouteId": 2,
      "lastActionName": null,
      "lastActionStatus": "-",
      "currActionName": "CSSP",
      "currStateName": "Received",
      "location": "surat"
    }]
  }
}
export enum EnumUsers {
  'keval' = 'keval',
  'kevalPass' = 'Admin123',
  'mumbai' = 'mumbai',
  'lattice' = 'lattice',

}
export const endpoints = {
  'latticeLogIn': 'user-service/api/user/login',//from lattice
  'pdValues': 'master-service/api/company_sspd_settings/findAllByCompany?transType=SS',///from lattice
}
