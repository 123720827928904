

import { createAction, props } from "@ngrx/store";
import { Int_GET_ChildParameters, Int_GET_MasterParameter } from "./masters.interface";

//#region Masters Parameters
  export const GET_MASTER_PARAMETERS = createAction(
    '[GET_MASTER_PARAMETERS] GET All MASTER_PARAMETERS',
    props<{ isReload: boolean }>()
  );
  
  export const GET_MASTER_PARAMETERS_SUCCESS = createAction(
    '[GET_MASTER_PARAMETERS_SUCCESS] GOT All MASTER_PARAMETERS',
    props<{ payload: Int_GET_MasterParameter }>()
  );
  
  export const ADD_MASTER_PARAMETERS = createAction(
    '[ADD_MASTER_PARAMETERS] ADD MASTER_PARAMETERS',
    props<{ payload: any }>()
  );
  
  export const UPDATE_MASTER_PARAMETERS = createAction(
    '[UPDATE_MASTER_PARAMETERS] UPDATE MASTER_PARAMETERS',
    props<{ payload: any }>()
  );
  
  export const DELETE_MASTER_PARAMETERS = createAction(
    '[DELETE_MASTER_PARAMETERS] DELETE MASTER_PARAMETERS',
    props<{ id: number }>()
  );
  
  //#endregion

  //#region Parameters

    export const GET_CHILD_PARAMETERS = createAction(
        '[GET_CHILD_PARAMETERS] GET All CHILD_PARAMETERS',
        props<{ ID:number,isReload: boolean }>()
    );
    
    export const GET_CHILD_PARAMETERS_SUCCESS = createAction(
        '[GET_CHILD_PARAMETERS_SUCCESS] GOT All CHILD_PARAMETERS',
        props<{ payload: any }>()
    );
    
    export const ADD_CHILD_PARAMETERS = createAction(
        '[ADD_CHILD_PARAMETERS] ADD CHILD_PARAMETERS',
        props<{ payload: any }>()
    );
    
    export const UPDATE_CHILD_PARAMETERS = createAction(
        '[UPDATE_CHILD_PARAMETERS] UPDATE CHILD_PARAMETERS',
        props<{ payload: any }>()
    );
    
    export const DELETE_CHILD_PARAMETERS = createAction(
        '[DELETE_CHILD_PARAMETERS] DELETE CHILD_PARAMETERS',
        props<{ id: number }>()
    );
  
  //#endregion