import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { getParamsValuesListByName } from '../masterDataStore/masterData.selector';
import { takeUntil, filter } from 'rxjs/operators';
import { AppState } from '../reducers';
import { Subject } from 'rxjs';
import { HttpService } from '../services/http/http.service';
import { ConfigService } from '../services/config/config.service';
import * as moment from 'moment';
import { PopoverUserComponent } from '../components/popover-user/popover-user.component';
import { SignerAction } from '../components/trybesigner/state/signer.action.type';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-resetordermodal',
  templateUrl: './resetordermodal.component.html',
  styleUrls: ['./resetordermodal.component.scss'],
})
export class ResetordermodalComponent implements OnInit {
  inputremark: any;
  columns: any;
  isDisableSubmit: true;
  private unsubscribe$: Subject<any> = new Subject<any>();
  remark: any;
  remarkList: any;
  dataSource: any = [];
  remarkValue: any;
  rowData: any;
  selectedRows: any;
  selectedRow: any;
  packetPlanStoneId: any;
  refreshGrid: boolean = false;
  packetId: any;
  orderKey: any;
  // public remarkList = [{
  //   name: 'PASS',
  //   id: 1
  // }, {
  //   name: 'FAIL',
  //   id: 2
  // }]
  constructor(
    public modalCtrl: ModalController,
    private store: Store<AppState>,
    private navParams: NavParams,
    public httpServie: HttpService,
    public configService: ConfigService,
    public popoverController: PopoverController,
    public loaderService: LoaderService
  ) {}

  async ngOnInit() {
    this.columns = [
      // { name: "voucherId", prop: "voucherId" },
      { name: 'Order Number', prop: 'orderNo' },
      { name: 'Order Name', prop: 'orderName' },
      { name: 'Date', prop: 'orderDate' },
      { name: 'Cts', prop: 'cts' },
      { name: 'createdBy', prop: 'createdBy' },
      { name: 'RemarkValue', prop: 'remark' },
      { name: 'value', prop: 'finalValue' },
      // { name: "Qty", prop: "remark" },
      { name: 'Order Rejection Remark', prop: 'orderRemark' },
    ];
    // this.dataSource=[{id:1,"orderNumber":"1","order":"#232323","criteria":"shape","CurrentOrderRate":"$24324","remark":"" },{id:1,"orderNumber":"2","order":"#232323","criteria":"shape","CurrentOrderRate":"$24324","remark":"" },{id:1,"orderNumber":"3","order":"#232323","criteria":"shape","CurrentOrderRate":"$24324","remark":"" },{id:1,"orderNumber":"4","order":"#232323","criteria":"shape","CurrentOrderRate":"$24324","remark":"" }];


    const data = this.navParams.get('data');
    this.packetId = data?.packetId;
    if(data?.showIsActive){
      this.columns.push(
        { name: "Active", prop: "isActive", columnTemplate: 'checkboxCol' }
      );
    }
    this.packetPlanStoneId = Number(data?.orderDetails?.id);
    if (data.isPage != 'opinion') {
      this.dataSource = (data?.orderDetails?.orders || []);
      if (!!this.dataSource) {
        this.dataSource = await this.dataSource.map((order, index) => {
          let row = { ...order };
          row.selected = index === 0;
          if (row.finalValue) {
            row.finalValue = Number(row.finalValue.toFixed(2));
          }
          row.orderDate = moment
            .utc(row.orderDate)
            .local()
            .format('DD-MMM-YYYY hh:mm a');
          return row;
        });
        await this.dataSource.sort((a, b) => {
          return b.finalValue - a.finalValue;
        });
        this.dataSource = await this.dataSource.map((order, index) => {
          order.selected = index === 0;
          return order;
        });
      }
    }
    else {
      this.dataSource = (data?.orderDetails["plans"][0]["orders"] || []);
      if (!!this.dataSource) {
        this.dataSource = await this.dataSource.map((order, index) => {
          let row = { ...order };
          row.selected = index === 0;
          if (row.finalValue) {
            row.finalValue = Number(row.finalValue.toFixed(2));
          }
          row.orderDate = moment
            .utc(row.orderDate)
            .local()
            .format('DD-MMM-YYYY hh:mm a');
          return row;
        });
        await this.dataSource.sort((a, b) => {
          return b.finalValue - a.finalValue;
        });
        this.dataSource = await this.dataSource.map((order, index) => {
          order.selected = index === 0;
          return order;
        });
      }
    }

    
    this.store
      .pipe(select(getParamsValuesListByName('Remarks')))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!!data) {
          this.remarkList = data;
          this.dataSource = this.dataSource.map((dt:any)=>{
            if(dt.orderRemark){
              dt.orderRemark = this.remarkList.find((rl:any)=>rl.name==dt.orderRemark);
            }
            return dt;
          })
        }
      });
  }
  enterRemark(ev) {
    console.log(ev);
    this.inputremark = ev.target.value;
    console.log('enter the key');
    console.log(this.inputremark);
  }

  onRemarkSelection(event: any) {
    console.log(event, this.selectedRow);
    this.remarkValue = event.data.value;
    if (!!this.remarkValue) {
      // this.updateRemarks(this.remarkValue);
    }
  }

  async updateRemarks(remark) {
    
    this.dataSource = await this.dataSource.map((y) => {
      y['selected'] = false;
      if (y.id == this.selectedRows[0]?.id) {
        y['remark'] = remark;
      }
      return y;
    });
    let noRemarksIndex = -1;
    for (let index = 0; index < this.dataSource.length; index++) {
      if (!this.dataSource[index].remark) {
        noRemarksIndex = index;
        this.dataSource[noRemarksIndex]['selected'] = true;
        break;
      }
    }

    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 500);
  }

  async onSave() {
    
    let payload = await this.dataSource.filter((x:any)=>(x?.orderRemark?.name || x.orderRemark)).map(x=>{
      let obj ={
        packetPlanStoneId:this.packetPlanStoneId,
        orderId:x.id,
        orderRemark:x.orderRemark?.name || x.orderRemark
      };
      return obj;
    });
    this.orderKey = payload;
    // let response = await this.httpServie.postDataUsingPromise(
    //   true,
    //   'plan-stone-order/createNew',
    //   payload
    // );
    
    // await this.httpServie.getErrorAndDisplayIt(response);
    // if (response.statusCode === 200) {
      // let message = response?.message;
      const selectedOrder = this.dataSource.find((dt:any)=>(dt.isActive));
      // if(){
      //   const payload = {
      //     id: this.packetPlanStoneId,
      //     orderId: Number(selectedOrder.id)
      //   };
      //   await this.httpServie.postDataUsingPromise(
      //     true,
      //     'packetPlanStone/updateOrderOnPlanStone',
      //     payload
      //   );
    // }
  
    this.dataSource.map(dt => dt.packetplanStoneId = this.packetPlanStoneId)
      this.modalCtrl.dismiss({'orders':this.orderKey, 'dataSource':this.dataSource});
      // SignerAction.savePktPlanRemarks({
      //   orders: payload,
      //   pktPlanStoneId: this.packetPlanStoneId,
      // })
      // debugger
      // this.configService.showToast('success', message);
    // } else {
    //   console.error(response);
    //   this.configService.showToast('error', response.error.message);
    // }
   
  }

  async resetRecord() {
    this.dataSource = await this.dataSource.map((y) => {
      y['selected'] = false;
      if (y.id == this.selectedRows[0]?.id) {
        y['selected'] = true;
        y['remark'] = '';
      }
      return y;
    });
    
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 500);
  }

  async onSelectionChanged(event: any) {
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    console.log(this.selectedRow);
    console.log(this.selectedRows);
    if (this.selectedRow?.remark) {
      this.resetRecord();
    }
  }

  async orderPlaceApi(orderid) {
    
    let payload = {
      id: this.packetPlanStoneId,
      orderId: Number(orderid),
    };
    console.log(payload);
    let response = await this.httpServie.postDataUsingPromise(
      true,
      'packetPlanStone/updateOrderOnPlanStone',
      payload
    );

    console.log(response);
    
    await this.httpServie.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.store.dispatch(
        SignerAction.orderMarkedOnPlanStone({
          orderId: this.selectedRows[0]?.id,
          pktPlanStoneId: this.packetPlanStoneId,
        })
      );

      this.configService.showToast('success', response.message);
      this.modalCtrl.dismiss(this.dataSource);
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  }

  async infoFunction(event) {
    // let id = event?.row?.data?.id
    // if (this.packetId) {
    //   
    //   this.httpServie.getData(true, `packet/getOrderOnBasisOfPacketId/${this.packetId}`, {}).subscribe(async (res: any) => {
    //     const roughCriteriaData = res.data.map((dt: any) => {
    //       if (!dt?.uiJson)
    //         return {
    //           orderNo: dt?.orderNo,
    //           orderDate: dt?.orderDate,
    //         };
    //       const uiJson = JSON.parse(dt?.uiJson);
    //       return {
    //         ...uiJson,
    //         Shape: uiJson?.dropdownJson?.Shape?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Color: uiJson?.dropdownJson?.Color?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Clarity: uiJson?.dropdownJson?.Clarity?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Propagation: uiJson?.dropdownJson?.Propagation?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         CUT: uiJson?.dropdownJson?.CUT?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Polish: uiJson?.dropdownJson?.Polish?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Symmetry: uiJson?.dropdownJson?.Symmetry?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Fluorescence: uiJson?.dropdownJson?.Fluorescence?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Tinch: uiJson?.dropdownJson?.Tinch?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Natts: uiJson?.dropdownJson?.Natts?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Milkyness: uiJson?.dropdownJson?.Milkyness?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Topsopens: uiJson?.dropdownJson?.Topsopens?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         Botsopen: uiJson?.dropdownJson?.Botsopen?.datas?.map((sdt: any) => sdt.name).join(', '),
    //         perpieceRate: uiJson?.perpieceRate?.from,
    //         sizeRange: (uiJson?.sizeRange?.from || '') + '-' + (uiJson?.sizeRange?.to || ''),
    //         diameterRatio: (uiJson?.diameter?.from || uiJson?.diameter?.to) ? ((uiJson?.diameter?.from || '') + '-' + (uiJson?.diameter?.to || '')) : ((uiJson?.ratio?.from || '') + '-' + (uiJson?.ratio?.to || '')),
    //         table: (uiJson?.table?.from || '') + '-' + (uiJson?.table?.to || ''),
    //         cheight: (uiJson?.cheight?.from || '') + '-' + (uiJson?.cheight?.to || ''),
    //         girdle: (uiJson?.girdle?.from || '') + '-' + (uiJson?.girdle?.to || ''),
    //         cangle: (uiJson?.cangle?.from || '') + '-' + (uiJson?.cangle?.to || ''),
    //         culet: (uiJson?.culet?.from || '') + '-' + (uiJson?.culet?.to || ''),
    //         depth: (uiJson?.depth?.from || '') + '-' + (uiJson?.depth?.to || ''),
    //         pdepth: (uiJson?.pdepth?.from || '') + '-' + (uiJson?.pdepth?.to || ''),
    //         pangle: (uiJson?.pangle?.from || '') + '-' + (uiJson?.pangle?.to || ''),
    //         remark: uiJson?.remark,
    //         orderNo: dt?.orderNo,
    //         orderDate: dt?.orderDate,
    //       }
    //     });
    //     
    //     if (roughCriteriaData?.length) {
    //       const popover = await this.popoverController.create({
    //         component: PopoverUserComponent,
    //         cssClass: 'planningRowOrderspopoup',
    //         componentProps: {
    //           forPage: 'roughCriteria',
    //           roughCriteriaData
    //         },
    //       });
    //       await popover.present();
    //     }
    //   });
    // }
    let order = event?.row?.data;
    let roughCriteriaData: any;
    if (!order?.uiJson) {
      roughCriteriaData = [
        {
          orderNo: order?.orderNo,
          orderDate: order?.orderDate,
        },
      ];
    } else {
      const uiJson = JSON.parse(order?.uiJson);
      roughCriteriaData = [
        {
          ...uiJson,
          Shape: uiJson?.dropdownJson?.Shape?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Color: uiJson?.dropdownJson?.Color?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Clarity: uiJson?.dropdownJson?.Clarity?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Propagation: uiJson?.dropdownJson?.Propagation?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          CUT: uiJson?.dropdownJson?.CUT?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Polish: uiJson?.dropdownJson?.Polish?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Symmetry: uiJson?.dropdownJson?.Symmetry?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Fluorescence: uiJson?.dropdownJson?.Fluorescence?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Tinch: uiJson?.dropdownJson?.Tinch?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Natts: uiJson?.dropdownJson?.Natts?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Milkyness: uiJson?.dropdownJson?.Milkyness?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Topsopens: uiJson?.dropdownJson?.Topsopens?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          Botsopen: uiJson?.dropdownJson?.Botsopen?.datas
            ?.map((sdt: any) => sdt.name)
            .join(', '),
          perpieceRate: uiJson?.perpieceRate?.from,
          sizeRange:
            (uiJson?.sizeRange?.from || '') +
            '-' +
            (uiJson?.sizeRange?.to || ''),
          diameterRatio:
            uiJson?.diameter?.from || uiJson?.diameter?.to
              ? (uiJson?.diameter?.from || '') +
                '-' +
                (uiJson?.diameter?.to || '')
              : (uiJson?.ratio?.from || '') + '-' + (uiJson?.ratio?.to || ''),
          table: (uiJson?.table?.from || '') + '-' + (uiJson?.table?.to || ''),
          cheight:
            (uiJson?.cheight?.from || '') + '-' + (uiJson?.cheight?.to || ''),
          girdle:
            (uiJson?.girdle?.from || '') + '-' + (uiJson?.girdle?.to || ''),
          cangle:
            (uiJson?.cangle?.from || '') + '-' + (uiJson?.cangle?.to || ''),
          culet: (uiJson?.culet?.from || '') + '-' + (uiJson?.culet?.to || ''),
          depth: (uiJson?.depth?.from || '') + '-' + (uiJson?.depth?.to || ''),
          pdepth:
            (uiJson?.pdepth?.from || '') + '-' + (uiJson?.pdepth?.to || ''),
          pangle:
            (uiJson?.pangle?.from || '') + '-' + (uiJson?.pangle?.to || ''),
          remark: uiJson?.remark,
          orderNo: order?.orderNo,
          orderDate: order?.orderDate,
        },
      ];
    }
    if (roughCriteriaData?.length) {
      const popover = await this.popoverController.create({
        component: PopoverUserComponent,
        cssClass: 'planningRowOrderspopoup',
        componentProps: {
          forPage: 'roughCriteria',
          roughCriteriaData,
        },
      });
      await popover.present();
    }
  }

  onCancel() {
    this.modalCtrl.dismiss();
  }

  ngOndestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
