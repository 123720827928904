import { EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
//import { ConfigService } from '../service/config.service';
import { ModalController, NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { from, throwError } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, tap } from "rxjs/operators";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { TrybemodalComponent } from "../../trybemodal/trybemodal.component";
import { SignerAction } from "./signer.action.type";
import { SignerState } from "./signer.state";
import { myVirtualTaskActions } from "../../my-virtual-task/state/my-virtual-task.actions.type";
import { virtualAPIs } from "../../my-virtual-task/state/my-virtual-task.data";
import { chekerAction } from "../../trybe-checker/state/checker.action.type";

@Injectable()
export class SignerEffects {
  @Output() messageEvent = new EventEmitter<string>();
  constructor(
    private _ConfigService: ConfigService,
    private navCtrl: NavController,
    private actions: Actions,
    public httpService: HttpService,
    private store: Store<SignerState>,
    private loaderService: LoaderService,
    private modalController: ModalController,
    public router: Router
  ) {}

  getPacketPlans$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.getPacketPlans),
      tap((w: any) => {
        console.log(this.actions);
        console.log(w);

        
        // this.httpService.showLoader();
      }),

      concatMap(action =>
        from(this.httpService.fetchPacketPlansByPacketId(action.packetIdOb)).pipe(
          map(res => {
            console.log("..Packet Plans..");
            console.log(res);
            let resultData = res.data;
            
            if (res.statusCode == 200) {
              console.log("..HIDE..Packet Plans..");
              // this.httpService.dismissLoader();
            }
            this.httpService.getErrorAndDisplayIt(res);
            return !!resultData ? resultData : { packetPlans: [], plansData: [] };
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      map((data: any) => {
        return SignerAction.savePacketPlans({
          packetPlans: data.packetPlans,
          packetPlanData: data.plansData,
        });
      })
    )
  );

  findPacketPlanStoneByPacketIdForSmartRecut$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.findPacketPlanStoneByPacketIdForSmartRecut),
      tap((w: any) => {
        
        // this.httpService.showLoader();
      }),

      concatMap((action, temp) =>
        from(this.httpService.findPacketPlanStoneByPacketIdForSmartRecut({ packetId: action.packetIdOb.packetId, reviewForSmartRecut: action.reviewForSmartRecut })).pipe(
          map(res => {
            console.log("..Packet Plans..");
            console.log(res);
            let resultData = res.data;
            
            if (res.statusCode == 200) {
              console.log("..HIDE..Packet Plans..");
              // this.httpService.dismissLoader();
            }
            this.httpService.getErrorAndDisplayIt(res);
            return !!resultData ? resultData : { packetPlans: [], plansData: [] };
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      map((data: any) => {
        return SignerAction.savePacketPlans({
          packetPlans: data.packetPlans,
          packetPlanData: data.plansData,
        });
      })
    )
  );

  getManualPacketPlans$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.getManualPacketPlan),
      tap((w: any) => {
        console.log(w);
        
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.fetchManualPacketPlansByPacketId(action.packetIdOb)).pipe(
          map(res => {
            console.log("..Packet Plans..");
            console.log(res);
            let resultData = res.data;
            
            if (res.statusCode == 200) {
              console.log("..HIDE..Packet Plans..");
              // this.httpService.dismissLoader();
            }
            this.httpService.getErrorAndDisplayIt(res);
            return !!resultData ? resultData : { packetPlans: [], plansData: [] };
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      map((data: any) => {
        return SignerAction.saveManualPacketPlans({
          packetPlans: data,
        });
      })
    )
  );

  getPacketPlansForSpectrum$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.getPacketPlansForSpectrum),
      tap((w: any) => {
        
        // this.httpService.showLoader();
        console.log(this.actions);
      }),

      concatMap(action =>
        from(this.httpService.fetchPacketByPacketIdForSpectrum(action.packetId)).pipe(
          map(res => {
            console.log("..Packet Plans..");
            console.log(res);
            let resultData = res.data.packetPlanList;
            
            if (res.statusCode == 200) {
              console.log("..HIDE..Packet Plans..");
              // this.httpService.dismissLoader();
            }
            this.httpService.getErrorAndDisplayIt(res);
            return !!res ? res : [];
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      map((data: any) => {
        return SignerAction.savePacketPlansForSpectrum({
          packetPlans: data.data.packetPlanList,
          checkerId: data.data.checkerId,
          reviewByCheckerActionId: data.data.reviewByCheckerActionId,
          pdFile: data?.data?.pdFile,
          otherFile: data?.data?.otherFile,
        });
      })
    )
  );

  fetchProgramByPacketIdAndPlanStoneId$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.getProgramByPacketIdAndPlanStoneId),
      concatMap(action =>
        from(this.httpService.fetchProgramByPacketIdAndPlanStoneId(action.packetId, action.planStoneId)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            // 
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response && response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return SignerAction.savePrograms({ packetPrograms: data });
      })
    )
  );

  updatePacketPlanDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.updatePacketPlan),
      tap((x: any) => {
        console.log("Update Stones Plan data and show loader");
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.updateDetailsInPacketPlan(action.updateData)).pipe(
          map(res => {
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              this.showModal(true, res.message, {});
            } else {
              // this.httpService.dismissLoader();
              this.showModal(false, res.message, {});
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            // 
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((dta: any) => {
        return [SignerAction.savePacketPlan({ updatedData: dta.updateData })];
      })
    )
  );

  savePDParameterPacketPlanStone = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.savePDParameterPacketPlanStone),
      tap((x: any) => {
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.savePDParameterPacketPlan(action.pdPayload)).pipe(
          map(res => {
            
            console.log(action);

            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // this.showModal(true, res.message, {});
              this._ConfigService.showToast("success", res.message);
            } else {
              // this.showModal(false, res.message, {});
              this._ConfigService.showToast("error", res.message);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // setTimeout(() => {
        // this.httpService.dismissLoader();
        
        // }, 300);
      }),
      mergeMap((dta: any) => {
        console.log(dta);
        return [SignerAction.getPacketPlans({ packetIdOb: dta.packetIdObj }), SignerAction.changePDsavedFlag({ packetPlanStoneId: dta.pdPayload[0]["packetPlanStoneId"] })];
      })
    )
  );

  updateFLCLClAInPacketPlanStone$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.updateFLCLClAInPacketPlanStone),
      tap((x: any) => {
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.updateFluCLORClarityInPacketPlan(action.updateData)).pipe(
          map(res => {
            console.log(res);

            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              this._ConfigService.showToast("success", res.message);
            } else {
              // this.httpService.dismissLoader();
              this._ConfigService.showToast("error", res.message);
            }
            this.httpService.getErrorAndDisplayIt(res);
            console.log(action);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        setTimeout(() => {
          // this.httpService.dismissLoader();
          
        }, 300);
      }),
      mergeMap((dta: any) => {
        console.log("....details.......2");
        console.warn(dta);
        return [SignerAction.updateDropDownParam({ updateData: dta.parameters }), SignerAction.getPacketPlans({ packetIdOb: dta.packetIdObj })];
      })
    )
  );

  // uploadXpsFileToPacketPlans$ = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(SignerAction.uploadXpsFileToPacketPlans),
  //     tap((x: any) => {
  //       console.log('Upload Cap File and show loader');
  //       console.log(x);
  //       this.httpService.showLoader();
  //     }),
  //     concatMap((action) =>
  //       from(
  //         this.httpService.uploadXpsFileToServer(action.postData)
  //       ).pipe(
  //         map((res) => {
  //           console.log('res');
  //           console.log(res);
  //           if (res.statusCode === 200) {
  //             this.httpService.dismissLoader();
  //             setTimeout(() => {
  //               this.showModal(true, res.message, {});
  //             }, 1000);
  //           } else {
  //             this.httpService.dismissLoader();
  //             setTimeout(() => {
  //               this.showModal(false, res.message, {});
  //             }, 1000);
  //           }
  //           return action;
  //         }),
  //         catchError(error => {
  // 
  //                       this.httpService.getErrorAndDisplayIt(error);
  //                       return throwError(error);
  //                   })
  // //       )
  //     ),
  //     tap(async (response: any) => {
  //       await this.httpService.dismissLoader();
  //     }),
  //     mergeMap((dta: any) => {
  //       console.log('....details.......Upload');
  //       console.warn(dta);
  //       console.warn(dta.param);
  //       return [SignerAction.getUploadedFile({ param: dta.param })];
  //     })
  //   )
  // );

  uploadFiile$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.uploadFileToPacketPlans),
      tap((x: any) => {
        console.log("Upload Cap File and show loader");
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.uploadFileToServer(action.postData, action.param)).pipe(
          map(res => {
            console.log("res");
            console.log(res);
            let data = {};
            
            if (res.statusCode === 200) {
              data = {
                file: res.data,
                param: action.param,
              };
              // setTimeout(() => {
              // this.showModal(true, res.message, {});
              this._ConfigService.showToast("success", res.message);
              // }, 1000);
            } else {
              // setTimeout(() => {
              // this.showModal(false, res.message, {});
              this._ConfigService.showToast("error", res.message);
              // }, 1000);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return data;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((data: any) => {
        return [SignerAction.saveUploadedFile({ file: data.file, param: data.param })];
      })
    )
  );
  /* getUploadedData$ = createEffect(() =>
     this.actions.pipe(
       ofType(SignerAction.getUploadedFile),
       concatMap((action) =>
         from(this.httpService.fetchUploadedFile(action.param)).pipe(
           map((res) => {
             console.log('params--------');
             console.log(action);
             console.log(res);
             return { file: res.data, param: action.param };
           }),
           catchError(error => {
                        // 
                        this.httpService.getErrorAndDisplayIt(error);
                        return errorm;
                    })
         )
       ),
       tap((response: any) => {
         if (response.error == 'Unauthorized') {
           this.httpService.logout();
         }
       }),
       map((data: any) => {
         console.log(data);
         return SignerAction.saveUploadedFile({ file: data.file, param: data.param });
       })
     )
   );*/
  deletePacketPlans$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.deletePacketPlan),
      tap((w: any) => {
        console.log(w);
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.removePacketPlan(action.packetPlanID)).pipe(
          map(res => {
            console.log(action);
            console.log(res);

            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              this.showModal(true, res.message, {});
            } else {
              // this.httpService.dismissLoader();
              this.showModal(false, res.message, {});
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            // 
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // setTimeout(() => {
        // this.httpService.dismissLoader();
        
        // }, 500);
      }),
      mergeMap((data: any) => {
        console.log(data, "data");
        let packet = data.packetIdOb;
        return [SignerAction.getPacketPlans({ packetIdOb: packet })];
      })
    )
  );

  deletePartPacketPlans$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.deletePartPacketPlan),
      tap((w: any) => {
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.removePartPacketPlan(action.id)).pipe(
          map(res => {
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // setTimeout(() => {
              this.showModal(true, res.message, {});
              // }, 1000);
            } else {
              // this.httpService.dismissLoader();
              // setTimeout(() => {
              this.showModal(false, res.message, {});
              // }, 1000);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((data: any) => {
        let packet = data.packetIdOb;
        return [SignerAction.getPacketPlans({ packetIdOb: packet })];
      })
    )
  );

  saveSignerBestPlan$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.saveSignerBestPlans),
      tap((x: any) => {
        console.log("save saveSignerBestPlans and show loader");
        console.log("%cTest1", "color:green;font-weight:bold;");
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.saveSignerBestPlans(action.payload)).pipe(
          map(res => {
            console.log("%cTest2 (Map)", "color:green;font-weight:bold;");
            console.log(res);
            console.log(action);
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // this.router.navigate['/actionsWindow'];
              console.log("----------ROUTE----------------");
              this.store.dispatch(
                myVirtualTaskActions.fetchMyVirtualTasks({
                  api: virtualAPIs.virtualTaskEndPoint,
                  Payload: null,
                })
              );
              this.router.navigate(["/home"]);
              setTimeout(() => {
                this.showModal(true, res.message, {});
              }, 1000);
            } else {
              // this.httpService.dismissLoader();
              setTimeout(() => {
                this.showModal(false, res.message, {});
              }, 500);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        console.log("%cTest3", "color:orange;font-weight:bold;");
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((dta: any) => {
        console.log("%cTest3", "color:red;font-weight:bold;");
        console.warn(dta);
        return [
          // SignerAction.getSignerBestPlans({ pktData: dta.pktData, message: 'afterPlanSave' }),
          SignerAction.getPacketPlans({ packetIdOb: { packetId: dta.pktData.packetId } }),
        ];
      })
    )
  );

  /*fetchSignerBestPlan$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.getSignerBestPlans),
      tap((x: any) => {
        console.log('get Best Plans and show loader');
        console.log(x);
      }),
      concatMap((action) =>
        from(
          this.httpService.fetchSignerPlans(action.pktData.id)
        ).pipe(
          map((res) => {
            console.log(res);
            return {
              'data': !!res.data ? res.data : [],
              'action': action
            }
          }),
          catchError(error => {
                        // 
                        this.httpService.getErrorAndDisplayIt(error);
                        return throwError(error);
                    })
        )
      ),
      tap((response: any) => {
      }),
      mergeMap((dta: any) => {
        console.log('....data.......');
        console.warn(dta);
        let signerLimit = 2;
        let newData = [];
        dta.data.forEach(d => {
          if (!newData.includes(d.signerId)) {
            console.log(newData);
            newData.push(d.signerId);
          }
        });
        let currSignerCount = newData.length;
        let saveData = {};
        let listOfParameter = [];
        dta.data.forEach((d: any, k: number) => {
          let snNo = k + 1;
          let paranmName = "Plan " + snNo;
          let obj = {
            "parameterName": paranmName,
            "parameterValue": d.id
          }
          listOfParameter.push(obj);
        })
        let formType = "JSON";
        saveData['packetId'] = !!dta['data'] && !!dta['data'][0] ? dta['data'][0].packetId : "";
        saveData['listOfParameter'] = listOfParameter;
        let obj = {
          "listOfAllPackets": []
        }
        obj['listOfAllPackets'].push(saveData);
        // this.rowData = {}
        console.log('...........saveSignerBestPlansToStore');
        console.log(newData);
        console.log(dta);
        console.log(obj);
        console.log(currSignerCount);
        console.log(signerLimit);
        console.log(dta.action.message);
        console.log(dta.action.pktData.packetId);
        if (currSignerCount === signerLimit && dta.action.message == 'afterPlanSave') {
          console.log('GOING TO SAVE PARAMETER and PLAN DONE ');
          this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: 149, formType: formType, barcodeObj: { packetId: dta.action.pktData.packetId } }));
        }
        return [
          SignerAction.saveSignerBestPlansToStore({ signerBestPlans: dta.data })
        ];
      })
    )
  );*/

  async showModal(state: boolean, message: string, data: any) {
    if (state) {
      if (!!data.data && !!data.data.voucherLabel) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: "trybe_modal_print",
        });
        modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        return await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: "trybe_modal_1",
        });
        modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        return await modal.present();
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
      return await modal.present();
    }
  }
  async onModalDismiss(d: any) {
    console.log("onModalDismiss.....Effects");
    this._ConfigService.changeScroll(true);
    console.log(d);
  }

  setPacketPlanToIsFinal$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.setPacketPlanToIsFinal),
      tap((x: any) => {
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.setPacketPlanToIsFinal(action.payload)).pipe(
          map(res => {
            console.log("%cTest2 (Map)", "color:green;font-weight:bold;");
            console.log(res);
            console.log(action);
            
            if (res.statusCode === 200) {
              this.store.dispatch(SignerAction.removeSelectedPlans());
              // this.httpService.dismissLoader();
              // this.router.navigate['/actionsWindow'];
              console.log("----------ROUTE----------------");
              this.store.dispatch(
                myVirtualTaskActions.fetchMyVirtualTasks({
                  api: virtualAPIs.virtualTaskEndPoint,
                  Payload: null,
                })
              );
              this.router.navigate(["/home"]);
              debugger;
              this.showModal(true, res.message, {});
            } else {
              this.showModal(false, res.message, {});
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((dta: any) => {
        console.log("response");
        console.log(dta);
        let postData = {};
        let listOfParameter = [];
        let paramObj = {
          parameterName: dta.rowData.paramData.paramName,
          parameterValue: dta.rowData.paramData.paramValue,
        };
        listOfParameter.push(paramObj);
        let formType = "JSON";
        postData["packetId"] = dta.rowData[0].id;
        postData["listOfParameter"] = listOfParameter;
        let obj = {
          listOfAllPackets: [],
        };
        obj["listOfAllPackets"].push(postData);
        console.log(postData);
        console.log({ parameterDetails: obj, actionId: dta.rowData.currAction, formType: formType, barcodeObj: { packetId: dta.rowData.packetId } });

        return [
          // masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: dta.rowData?.actionInfo?.id, formType: formType, barcodeObj: { packetId: dta.rowData[0].packetId }, actionInfo: dta.rowData.actionInfo })
        ];
      })
    )
  );

  selectPacketPlansForCheckerView$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.selectPacketPlansForCheckerView),
      tap((x: any) => {
        console.log(x);
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.selectPacketPlansForCheckerView({ planPriority: action.planPriority, id: action.id })).pipe(
          map(res => {
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // this.router.navigate['/actionsWindow'];
              // console.log('----------ROUTE----------------');
              // this.store.dispatch(
              //   myVirtualTaskActions.fetchMyVirtualTasks({
              //     api: virtualAPIs.virtualTaskEndPoint,
              //     Payload: null,
              //   })
              // );
              // this.router.navigate(["/home"]);
              this.store.dispatch(chekerAction.getPlansAccToUSers({ id: action.userId, packetId: action.packetId }));
              this.showModal(true, res.message, {});
            } else {
              this.showModal(false, res.message, {});
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((dta: any) => {
        console.log("response");
        console.log(dta);
        let postData = {};
        let listOfParameter = [];
        let paramObj = {
          parameterName: dta?.rowData?.paramData?.paramName,
          parameterValue: dta?.rowData?.paramData?.paramValue,
        };
        listOfParameter.push(paramObj);
        let formType = "JSON";
        postData["packetId"] = dta.rowData?.[0]?.id;
        postData["listOfParameter"] = listOfParameter;
        let obj = {
          listOfAllPackets: [],
        };
        obj["listOfAllPackets"].push(postData);
        console.log(postData);

        return [
          // masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: dta.rowData?.actionInfo?.id, formType: formType, barcodeObj: { packetId: dta.rowData[0].packetId }, actionInfo: dta.rowData.actionInfo })
        ];
      })
    )
  );

  findAllTheBidSubmittedByUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.findAllTheBidSubmittedByUser),
      tap((x: any) => {
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.findAllTheBidSubmittedByUser(action.packetId, action.userid)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            
            if (res.statusCode === 200) {
              console.log(res);
              // this.httpService.dismissLoader();
              return res.data;
            } else {
              // this.httpService.dismissLoader();
              return action;
            }
          }),
          catchError(error => {
            // 
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((data: any) => {
        return [SignerAction.saveAllTheBidSubmittedByUser({ plans: data })];
      })
    )
  );

  copyPacketPlan$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.copyPacketPlan),
      concatMap(action =>
        from(this.httpService.copyPacketPlan(action.planData, action.packetPlanId)).pipe(
          map(res => {
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // this.showModal(true, res.message, {});
              this._ConfigService.showToast("success", res.message);
              return action;
            } else {
              // this.httpService.dismissLoader();
              // this.showModal(false, res.message, {});
              this._ConfigService.showToast("error", res.message);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response && response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      mergeMap((res: any) => {
        return [
          // SignerAction.getPacketPlans({ packetIdOb: { packetId: res.planData[0].packetIdText} })
        ];
      })
    )
  );

  addRemark$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.addRemarkForPacketPlan),
      concatMap(action =>
        from(this.httpService.addRemarkForPacketPlan(action.remark, action.Grade, action.listOfPacketPlaneIdList)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              return action;
            } else {
              // this.httpService.dismissLoader();
              // return res
              return action;
            }
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),

      // filter stop a process so aint going in this
      filter((res: any) => res.refresh === true),
      mergeMap((res: any) => {
        return [
          // SignerAction.getPacketPlans({ packetIdOb: { packetId: res.planData[0].packetIdText} })
        ];
      })
      // map((res: any) => {
      //   return SignerAction.getPacketPlans({ packetIdOb: res.packetIdObj });
      // })
    )
  );

  addRemarkForPacketPlanDrawer$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.addRemarkForPacketPlandrawer),
      concatMap(action =>
        from(this.httpService.addRemarkForPacketPlanDrawer(action.remark, action.packetplanStoneID)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              return action;
            } else {
              // this.httpService.dismissLoader();
              // return res
              return action;
            }
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      mergeMap((res: any) => {
        console.log(res);
        return [
          // SignerAction.getPacketPlans({ packetIdOb: { packetId: res.planData[0].packetIdText} })
          SignerAction.saveRemarksForPacketPlanDrawer({ remark: res }),
        ];
      })
    )
  );

  addRemarkForPacketPlanDrawerFinaltab$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.addRemarkForPacketPlandrawerFinalTab),
      concatMap(action =>
        from(this.httpService.addRemarkForPacketPlanDrawerFinalTab(action.remark, action.packetplanStoneID, action.noOfCut)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              return action;
            } else {
              // this.httpService.dismissLoader();
              // return res
              return action;
            }
          }),
          catchError(error => {
            
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      mergeMap((res: any) => {
        return [
          // SignerAction.getPacketPlans({ packetIdOb: { packetId: res.planData[0].packetIdText} })
          SignerAction.saveRemarksForPacketPlanDrawerFinalTab({ remark: res }),
        ];
      })
    )
  );

  reviewStoneData$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.addReviewStoneData),
      tap((x: any) => {
        // this.httpService.showLoader();
        
      }),
      concatMap(action =>
        from(this.httpService.reviewStoneById(action.payload)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            
            if (res.statusCode === 200) {
              console.log(res);
              // this.httpService.dismissLoader();
              return {
                action: action,
                res: res,
              };
            } else {
              // this.httpService.dismissLoader();
              return {
                action: action,
                res: res,
              };
            }
          }),
          catchError(error => {
            // 
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        
      }),
      mergeMap((data: any) => {
        if (data.res.statusCode == 201 || data.res.statusCode == 200) {
          return [SignerAction.addDeptToReviewStone({ payload: data.action.storePayload, tabId: data.action.tabId })];
        }
      })
    )
  );

  getOrderForm$ = createEffect(() =>
    this.actions.pipe(
      ofType(SignerAction.getOrderForm),
      tap((response: any) => {}),
      concatMap(action =>
        from(this.httpService.fetchOrderForms()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode === 200) {
            }
            return res.data;
          }),
          catchError(error => {
            // 
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader()
        
      }),
      map((data: any) => {
        if (!!data && !!data.error) {
          data = {};
        }
        return SignerAction.saveOrderForm({ orderForm: data });
      })
    )
  );
}
