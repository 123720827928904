import { Component, OnInit, ViewChild } from '@angular/core';
import { ScannerinputComponent } from '../scannerinput/scannerinput.component';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getCurrentAction } from 'src/app/tempDataStore/tempData.selector';
import { AppState } from 'src/app/reducers';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ACTIONNAMES } from 'src/app/services/endpoint.enum';
import { ModalController } from '@ionic/angular';
import { VoucherwisepacketComponent } from '../voucherwisepacket/voucherwisepacket.component';
import { saveCurrentRowData } from 'src/app/tempDataStore/tempData.action';

@Component({
  selector: 'app-sales-return-voucher',
  templateUrl: './sales-return-voucher.component.html',
  styleUrls: ['./sales-return-voucher.component.scss'],
})
export class SalesReturnVoucherComponent implements OnInit {

  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  private unsubscribe$: Subject<any> = new Subject<any>();
  inputBarcode;
  selectedRow: any;
  actionName: string;
  dataSource = [];
  isThirdParty:boolean = false;
  voucherDto:any;
  infoToPrint: any = {}

  GridColumns = [
    { name: "Voucher No", prop: "voucherLabel" },
    { name: "Total Packets", prop: "totalPackets" },
    { name: "Voucher Date", prop: "createdDate" }
  ];
  constructor(private store: Store<AppState>,
    public httpService: HttpService,
    private loaderService: LoaderService,
    private configService: ConfigService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.actionName = action?.name;
        }
      });
  }

  async packetScanned(event){
    let payload = {
      voucherLabel : event.trim()
    }
    
    let response = await this.httpService.postDataUsingPromise(
      true,
      'voucher/getVoucherByLabel',
      payload
    );

    this.httpService.getErrorAndDisplayIt(response);
    

    if (response?.data && response.statusCode == 200){
      if(response?.data?.length)
        this.dataSource = response?.data;
      else
        this.configService.showToast(
          'error',
          'Voucher not found!'
        );
    }
    else
      this.configService.showToast(
        'error',
        'Something went wrong!'
      );
  }
  async onSelectionChanged(event){
    console.log(event);
    this.selectedRow = event.row;
    this.store.dispatch(
      saveCurrentRowData({ rowData: [this.selectedRow] })
    );
    
  }

  clearInputBarcode() {
    this.scannerComponet.onResetForm();
    this.inputBarcode = '';
    // this.selectedRows = [];
    this.selectedRow = {};
    this.dataSource = [];
  }

  async onTransfer(){
    let endpoint;
    if(this.actionName == ACTIONNAMES.Sales_Return_Voucher){
      endpoint = 'packet/returnPacketsByVocuher';
    }else if(this.actionName == ACTIONNAMES.Self_Sales_Return_Voucher){
      endpoint = 'packet/selfReturnPacketsByVocuher';
    }
    
    const payload = {
      voucherLabel : this.selectedRow?.voucherLabel
    } 
    let response = await this.httpService.postDataUsingPromise(
      true,
      endpoint,
      payload
    );

    this.httpService.getErrorAndDisplayIt(response);
    

    if (response?.data && response.statusCode == 200){
      this.configService.showToast('success', response.message);
      this.dataSource = [];
      this.clearInputBarcode()
    }
    else
      this.configService.showToast(
        'error',
        response.message
      );
  }

  infoFunction(event){
    console.log('info event---->',event)
    const voucherId = event?.row?.data?.voucherId;
    this.store.dispatch(
      saveCurrentRowData({ rowData: [ event?.row?.data] })
    );
    this.voucherDto = event?.row?.data;
    this.isThirdParty = event?.row?.data?.isThirdParty;
    this.getAllPacketData(voucherId);
  }

  async openVoucherModal(packetData) {
    const modal = await this.modalController.create({
      component: VoucherwisepacketComponent,
      cssClass: 'extraLargePage-for-voucher',
      componentProps: {
        "message": "Voucherid",
        "packetInfo": packetData,
        "voucherDto": this.voucherDto,
        "isThirdParty" : this.isThirdParty
      },
    });
    

    modal.onDidDismiss().then((d: any) => this.handleModalDismissforVoucher(d,null));

    return await modal.present();
  }

  handleModalDismissforVoucher(d: any,evt): any {
    console.log("closingvoucher modal");
    setTimeout(() => {
    }, 500);
  }

  async getAllPacketData(voucherID?: string) {
    let size = 1000;
    let page = 0;
    let endpoint: string = `packet/getPacketsListForVoucher?voucherId=${voucherID}&limit=${size}&page=${page}`;
    try {
      const response = await this.httpService.getDataWithoutPayloadForGet(true, endpoint).toPromise();
      if (response.statusCode === 200) {
        console.log(response.data.packetsList);
        this.infoToPrint['data'] = [];
        this.infoToPrint['data']['packets'] = response?.data?.packetsList;
        let totalCts = 0;
        let pktIds = response.data.packetsList.map((x) => {
          totalCts += x.cts;
          return x.id;
        });
        this.infoToPrint['process'] = response.data.packetsList[0].displayName;
        this.infoToPrint['fromCompany'] = response?.data?.fromCompany;
        this.infoToPrint['toCompany'] = response?.data?.toCompany;
        this.infoToPrint['data']['totalCts'] = totalCts;
        this.infoToPrint['data']['totalPackets'] = response?.data?.totalCount;
        response.data.packetsList.map(pl => pl.toUser = this.selectedRow?.toUser )
        await   this.openVoucherModal(response.data.packetsList)
      } else {
        console.error(response);
        this.configService.showToast('error', response.error.message);
      }
    } catch (error) {
      console.error('Error fetching worker data:', error);
    }
  }
}
