import { Component, OnInit } from "@angular/core";
import { getAllTrybeDepartments } from "../masterDataStore/masterData.selector";
import { select, Store } from "@ngrx/store";
import { HttpService } from "../services/http/http.service";
import { ConfigService } from "../services/config/config.service";
import { AppState } from "../reducers";
import { routeMasterAPIs } from "src/app/packet-route-master/state/packet-route-master.data";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { LoaderService } from "../services/loader/loader.service";

@Component({
  selector: "app-processactionconfig",
  templateUrl: "./processactionconfig.component.html",
  styleUrls: ["./processactionconfig.component.scss"],
})
export class ProcessactionconfigComponent implements OnInit {
  columns: any;
  dataSource: any;
  isUpdateDisable: boolean = true;
  showGrid: boolean = true;
  routeName: string;
  dropDownCurrentDept: any = {
    name: "currentDept",
    placeText: "Select Department",
    options: [],
    selectedData: [],
    propertName: "currDept",
    modeFlag: "single",
  };
  dropDownNextDept: any = {
    name: "nextDept",
    placeText: "Select Department",
    options: [],
    selectedData: [],
    propertName: "nextDepartment",
    modeFlag: "multiple",
  };
  dropDownResponsible: any = {
    name: "responsible",
    placeText: "Select Responsible User",
    options: [
      { id: 1, isResponsible: "Yes" },
      { id: 0, isResponsible: "No" },
      { id: 2, isResponsible: "Share" },
    ],
    selectedData: [],
    propertName: "isResponsible",
    modeFlag: "single",
  };
  currentDept: any[];
  isEdit: boolean = false;
  nextDept: any[];
  currSelectedData: any = {
    currDept: { "0": [] },
    NextDept: { "0": [] },
    responsesiblity: { "0": [] },
  };
  processActionForm: any = [
    {
      currDept: "",
      nextDepartment: "",
      // "isResponsible": ''
    },
  ];
  criteriaParameterLists = [];
  criteriaParameterHead = [];
  routeID: any;
  criterialistData: any[];
  criteriaList: {};
  Proportion = [
    { name: "EXCELENT", id: 1, paramNameId: 1, checked: false },
    { name: "V.GOOD", id: 2, paramNameId: 2, checked: false },
    { name: "FAIR", id: 3, paramNameId: 3, checked: false },
    { name: "POOR", id: 4, paramNameId: 4, checked: false },
  ];
  tinch = [
    { name: "N/A", id: 1, paramNameId: 1, checked: false },
    { name: "LIGHT BROWN", id: 2, paramNameId: 2, checked: false },
    { name: "BROWN", id: 3, paramNameId: 3, checked: false },
    { name: "DARKBROWN", id: 4, paramNameId: 4, checked: false },
    { name: "DARK LC", id: 5, paramNameId: 4, checked: false },
    { name: "MIX", id: 6, paramNameId: 4, checked: false },
  ];
  topOpens = [
    { name: "N/A", id: 1, paramNameId: 1, checked: false },
    { name: "LIGHT", id: 2, paramNameId: 2, checked: false },
    { name: "HEAVY", id: 3, paramNameId: 3, checked: false },
    { name: "STRONG", id: 4, paramNameId: 4, checked: false },
  ];
  BotOpens = [
    { name: "N/A", id: 1, paramNameId: 1, checked: false },
    { name: "LIGHT", id: 2, paramNameId: 2, checked: false },
    { name: "HEAVY", id: 3, paramNameId: 3, checked: false },
    { name: "STRONG", id: 4, paramNameId: 4, checked: false },
  ];
  selectedCriteria: any[];
  formData: any = {
    tableVal: {
      lowerLimit: "",
      upperLimit: "",
    },
    girdle: {
      lowerLimit: "",
      upperLimit: "",
    },
    widthMM: {
      lowerLimit: "",
      upperLimit: "",
    },
    lengthMM: {
      lowerLimit: "",
      upperLimit: "",
    },
    culet: {
      lowerLimit: "",
      upperLimit: "",
    },
    diameter: {
      lowerLimit: "",
      upperLimit: "",
    },
    ratio: {
      lowerLimit: "",
      upperLimit: "",
    },
    currwt: {
      lowerLimit: "",
      upperLimit: "",
    },
    height: {
      lowerLimit: "",
      upperLimit: "",
    },
    crHeight: {
      lowerLimit: "",
      upperLimit: "",
    },
    crAngle: {
      lowerLimit: "",
      upperLimit: "",
    },
    pavHeight: {
      lowerLimit: "",
      upperLimit: "",
    },
    pavAngle: {
      lowerLimit: "",
      upperLimit: "",
    },
    currentCts: {
      lowerLimit: "",
      upperLimit: "",
    },
  };
  saveJsonPayload = {
    in: {},
    range: {},
    equals: {},
  };
  selectedRow: any;
  UpdatePacketCriteria: any;
  allCheckedParams: any = {};

  constructor(private httpService: HttpService, private configService: ConfigService, private store: Store<AppState>, private loaderService: LoaderService) {
    this.columns = [
      { name: "Route Name", prop: "name" },
      { name: "CreatedDate", prop: "createdDate" },
      { name: "Created By", prop: "createdBy" },
    ];
  }

  ngOnInit() {
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
      if (data) {
        this.currentDept = [];
        this.nextDept = [];
        data.forEach((curr: any, k: number) => {
          let obj1: object = {},
            obj2: object = {};
          obj1 = { id: curr.id, currDept: curr.name };
          obj2 = { id: curr.id, nextDepartment: curr.name };
          this.currentDept.push(obj1);
          this.nextDept.push(obj2);
        });
        this.dropDownCurrentDept["options"] = this.currentDept;
        this.dropDownNextDept["options"] = this.nextDept;
      }
    });

    this.fetchData();
    this.fetchRequiredParameterValues();
  }

  async fetchData() {
    const res = await this.httpService.fetchDataUsingPromise(true, "department-rule-config/fetchAllRoute");
    if (res?.data?.length) {
      this.dataSource = res.data;
    } else this.configService.showToast("error", "No data found!");
  }

  onAdd() {
    this.showGrid = false;
    this.onClearCriteriaParameterLists();
    this.emptyData();
    this.currSelectedData = {
      currDept: { "0": [] },
      NextDept: { "0": [] },
      responsesiblity: { "0": [] },
    };
  }

  keyUp(event: any) {
    const pattern = /[0-9\+\ ]/;
    let inputChar = String.fromCharCode(event.key);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onBack() {
    this.routeName = "";
    this.isEdit = false;
    this.showGrid = true;
    this.onClearCriteriaParameterLists();
    this.emptyData();
    this.processActionForm = [
      {
        currDept: "",
        nextDepartment: "",
        // "isResponsible": ''
      },
    ];

    this.currSelectedData = {
      currDept: { "0": [] },
      NextDept: { "0": [] },
      // responsesiblity: {'0':[]}
    };
  }

  onSelectionChanged(event) {
    console.log(event);
  }

  async onEdit(event) {
    this.routeName = event.row.data.name;
    this.isEdit = true;

    this.routeID = event.row.data.id;
    this.getDataUsingRouteId(this.routeID);
    this.showGrid = false;
  }

  onClearCriteriaParameterLists() {
    this.criteriaParameterLists.map(cpl => {
      if (cpl?.parameterName) {
        cpl?.paramValues.map(pv => {
          pv.isSelected = false;
        });
      }
    });
  }

  addRange(value) {
    this.formData["tableVal"] = value["tableVal"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["crAngle"] = value["crAngle"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["crHeight"] = value["crHeight"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["culet"] = value["culet"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["height"] = value["height"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["diameter"] = value["diameter"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["girdle"] = value["girdle"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["ratio"] = value["ratio"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["lengthMM"] = value["lengthMM"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["pavAngle"] = value["pavAngle"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["pavHeight"] = value["pavHeight"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["widthMM"] = value["widthMM"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
    this.formData["currentCts"] = value["currentCts"] ?? {
      lowerLimit: "",
      upperLimit: "",
    };
  }

  fetchRequiredParameterValues() {
    let payloadObj: object = {
      names: [
        "Color",
        "Shape",
        "Fluorescence",
        "Clarity",
        "Polish",
        "Symmetry",
        "Tinch",
        "Cut",
        "Milkyness",
        "Proportion",
        "Lab",
        "Depth",
        "Luster",
        "Natts",
        "Tension Type",
        "TOP OPEN",
        "BOTTOM OPEN",
      ],
    };
    // let payloadObj: object = {
    //   names: this.parameterNames
    // };
    this.httpService.getData(true, routeMasterAPIs.parameterValuesEndpoint, payloadObj).subscribe(result => {
      if (result.statusCode === 200) {
        this.criteriaParameterLists = result?.data.map(dt => {
          return {
            ...dt,
            startIndex: null,
            endIndex: null,
            paramValues: dt?.paramValues?.map(pv => {
              return { ...pv, isSelected: false };
            }),
          };
        });
        this.criteriaParameterHead = result?.data.map(dt => dt.parameterName);

        this.criterialistData = [];
        this.criteriaList = {};
        result.data.forEach(d => {
          let obj = {
            paramId: d.parameterNameId,
            property: "name",
            list: d.paramValues, //[]
            selected: [],
            isMultiple: true,
            name: d.parameterName,
          };
          this.criteriaList[d.parameterName] = d.parameterNameId;
          // if (d.parameterName == 'Color') {
          //   this.colorList['name'] = 'Color';
          //   this.colorList['property'] = 'name';
          //   this.colorList['list'] = d.paramValues;
          // } else if (d.parameterName == 'Shape') {
          //   this.shapeList['name'] = 'Shape';
          //   this.shapeList['property'] = 'name';
          //   this.shapeList['list'] = d.paramValues;
          // } else {

          // }
          this.criterialistData.push(obj);
        });
        let obj = {
          paramId: null,
          property: "name",
          list: this.Proportion, //[]
          selected: [],
          isMultiple: true,
          name: "Proportion",
        };
        this.criterialistData.push(obj);
        let obj2 = {
          paramId: null,
          property: "name",
          list: this.tinch, //[]
          selected: [],
          isMultiple: true,
          name: "Tinch",
        };
        this.criterialistData.push(obj2);
        let obj3 = {
          paramId: null,
          property: "name",
          list: this.topOpens, //[]
          selected: [],
          isMultiple: true,
          name: "TopOpens",
        };
        this.criterialistData.push(obj3);
        let obj4 = {
          paramId: null,
          property: "name",
          list: this.BotOpens, //[]
          selected: [],
          isMultiple: true,
          name: "BotOpens",
        };
        this.criterialistData.push(obj4);
      } else {
        this.httpService.getErrorAndDisplayIt(result);
      }
    });
  }

  async getDataUsingRouteId(id) {
    const res = await this.httpService.fetchDataUsingPromise(true, `department-rule-config/getDepartmentRouteOnBasisOfRouteId?routeId=${id}`);
    if (res?.data?.length) {
      this.processActionForm = res.data;
      if (!!res?.data?.length) {
        res.data.map((dt: any, i: number) => {
          this.currSelectedData["currDept"][i] = this.dropDownCurrentDept["options"].filter((e: any) => e.id == dt.currDept);
          this.currSelectedData["NextDept"][i] = this.dropDownNextDept["options"].filter((e: any) => dt.nextDepartment?.includes(e.id));
          // this.currSelectedData['responsesiblity'][i] = this.dropDownResponsible['options'].filter((e:any) => e.id == dt.isResponsibleTransfer);
        });
        if (!!res.data?.[0]?.criteria) {
          this.UpdatePacketCriteria = JSON.parse(res.data?.[0]?.criteria);
          const keyToChange = {
            colorId: "COLOR",
            cutId: "CUT",
            clarityId: "Clarity",
            fluorescenceId: "Fluorescence",
            natts: "NATTS",
            mLab: "Lab",
            luster: "Luster",
            bottomOpen: "BOTTOM OPEN",
            topOpen: "TOP OPEN",
            height: "DEPTH",
            polishId: "Polish",
            shapeId: "SHAPE",
            symmetryId: "Symmetry",
            tinch: "Tinch",
          };
          const modifiedObject = {};
          if (this.UpdatePacketCriteria?.in) {
            for (const [key, value] of Object?.entries(this.UpdatePacketCriteria?.in)) {
              modifiedObject[keyToChange[key]] = value;
            }
          }
          this.UpdatePacketCriteria.in = modifiedObject;
          if (this.criteriaParameterLists.length > 0) {
            this.onClearCriteriaParameterLists();
            await this.criteriaParameterLists.map(cpl => {
              if (cpl?.parameterName) {
                if (this.UpdatePacketCriteria?.in?.[cpl?.parameterName]) {
                  cpl?.paramValues.map(pv => {
                    this.UpdatePacketCriteria?.in?.[cpl?.parameterName]?.["value"].map(v => {
                      if (pv.id == v) {
                        pv.isSelected = true;
                      }
                    });
                  });
                }
              }
            });
          }
          this.allCheckedParamsCheck(this.criteriaParameterLists);
        }
        if (!!this.UpdatePacketCriteria?.range) {
          this.addRange(this.UpdatePacketCriteria?.range);
        }
      }
    } else this.configService.showToast("error", "No Data Found");
  }

  dropDownDynamicSelection(evt: any, index: number) {
    if (evt.property == "currDept") {
      this.setValuesOfDropdownDynamically(evt, index);
    } else if (evt.property == "nextDepartment") {
      this.setValuesOfDropdownDynamically(evt, index);
    }
    // else if (evt.property == 'isResponsible') {
    //   this.setValuesOfDropdownDynamically(evt, index);
    // }
  }

  setValuesOfDropdownDynamically(evt, index) {
    if (this.processActionForm[index][evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.processActionForm[index][evt.property] = {};
        this.processActionForm[index][evt.property] = evt.idList;
      } else {
        this.processActionForm[index][evt.property] = "";
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.processActionForm[index][evt.property] = {};
        this.processActionForm[index][evt.property] = evt.idList;
      } else {
        this.processActionForm[index][evt.property] = "";
      }
    }
  }

  onDelete(index) {
    this.processActionForm.splice(index, 1);
  }

  isSaveDisabled() {
    return !this.routeName || this.processActionForm.some(item => !item.currDept);
  }

  onAddCriteria() {
    const form = {
      currDept: "",
      nextDepartment: [],
      // "isResponsible": ''
    };
    this.processActionForm.push(form);
    console.log(this.processActionForm);
  }

  async onSave(type) {
    await this.processSelectedParameters();

    if (type === "update") {
      this.setPacketCriteriaOnUpdate();
    }
    const keyToChange = {
      COLOR: "colorId",
      Clarity: "clarityId",
      Fluorescence: "fluorescenceId",
      NATTS: "natts",
      CUT: "cutId",
      Lab: "mLab",
      Luster: "luster",
      "BOTTOM OPEN": "bottomOpen",
      "TOP OPEN": "topOpen",
      DEPTH: "height",
      Polish: "polishId",
      SHAPE: "shapeId",
      Symmetry: "symmetryId",
      Tinch: "tinch",
    };
    const modifiedObject = {};
    for (const [key, value] of Object.entries(this.saveJsonPayload.in)) {
      modifiedObject[keyToChange[key]] = value;
    }
    this.saveJsonPayload.in = { ...this.saveJsonPayload.in, ...modifiedObject };
    this.saveJsonPayload.range = this.formData;
    console.log(this.saveJsonPayload);
    let payload;
    if (type === "save") {
      payload = {
        departmentRuleConfigList: this.processActionForm,
        routeName: this.routeName,
        criteria: JSON.stringify(this.saveJsonPayload),
      };
    } else if (type === "update") {
      payload = {
        departmentRuleConfigList: this.processActionForm,
        routeName: this.routeName,
        criteria: JSON.stringify(this.saveJsonPayload),
        routeId: this.routeID,
      };
    }

    payload.departmentRuleConfigList.forEach(item => {
      if (Array.isArray(item.currDept) && item.currDept.length > 0) {
        item.currDept = item.currDept[0].toString();
      }
    });
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "department-rule-config/saveDepartmentRoute ", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.onBack();
      this.fetchData();
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  emptyData() {
    this.formData = {
      tableVal: {
        lowerLimit: "",
        upperLimit: "",
      },
      girdle: {
        lowerLimit: "",
        upperLimit: "",
      },
      widthMM: {
        lowerLimit: "",
        upperLimit: "",
      },
      lengthMM: {
        lowerLimit: "",
        upperLimit: "",
      },
      culet: {
        lowerLimit: "",
        upperLimit: "",
      },
      diameter: {
        lowerLimit: "",
        upperLimit: "",
      },
      ratio: {
        lowerLimit: "",
        upperLimit: "",
      },
      currwt: {
        lowerLimit: "",
        upperLimit: "",
      },
      height: {
        lowerLimit: "",
        upperLimit: "",
      },
      crHeight: {
        lowerLimit: "",
        upperLimit: "",
      },
      crAngle: {
        lowerLimit: "",
        upperLimit: "",
      },
      pavHeight: {
        lowerLimit: "",
        upperLimit: "",
      },
      pavAngle: {
        lowerLimit: "",
        upperLimit: "",
      },
      currentCts: {
        lowerLimit: "",
        upperLimit: "",
      },
    };
  }
  async onSelectParamater(parameter, value) {
    if (!!this.criteriaParameterLists && this.criteriaParameterLists.length > 0) {
      //range logic

      // let startIndex, endIndex;
      // this.criteriaParameterLists.map((cpl) => {

      //   if (cpl.parameterName === parameter.parameterName) {
      //     if (cpl?.startIndex != null && cpl?.endIndex != null) {
      //       cpl.startIndex = startIndex = null;
      //       cpl.endIndex = endIndex = null;
      //     }
      //     if (cpl?.startIndex != null && cpl?.endIndex == null) {
      //       let index = cpl?.paramValues?.findIndex(
      //         (val) => val.id === value.id
      //       );
      //       if (index > -1) {
      //         cpl.endIndex = endIndex = index;
      //       }
      //     } else if (cpl?.endIndex != null && endIndex == null) {
      //       endIndex = cpl.endIndex;
      //     }
      //     if (cpl?.startIndex == null) {
      //       let index = cpl?.paramValues?.findIndex(
      //         (val) => val.id === value.id
      //       );
      //       if (index > -1) {
      //         cpl.startIndex = startIndex = index;
      //       }
      //     } else if (startIndex == null) {
      //       startIndex = cpl.startIndex;
      //     }
      //     if (startIndex != null && endIndex == null) {
      //       cpl?.paramValues?.map((pv, i) => (pv.isSelected = false));
      //       cpl.paramValues[startIndex].isSelected = true;
      //     } else if (startIndex != null && endIndex != null) {
      //       cpl?.paramValues?.map((pv, i) => {
      //         pv.isSelected = false;
      //         if (i >= startIndex && i <= endIndex) {
      //           pv.isSelected = !pv.isSelected;
      //         }
      //       });
      //     }
      //   }
      // });
      // normal select
      const selectedParamDoc = this.criteriaParameterLists.find((el: any) => el["parameterNameId"] === value["paramNameId"]);
      const selectedParamDocValues = selectedParamDoc.paramValues.find((el: any) => el.id === value?.id);
      selectedParamDocValues.isSelected = !selectedParamDocValues.isSelected;
    }

    this.allCheckedParamsCheck(this.criteriaParameterLists);
  }

  async setPacketCriteriaOnUpdate() {
    console.log(this.criteriaParameterLists);
    let selectedValues = this.criteriaParameterLists.map(cpl => ({
      ...cpl,
      paramValues: cpl.paramValues.filter(pv => pv.isSelected),
    }));
    this.selectedCriteria = selectedValues.filter(item => item.paramValues.length > 0);

    let newArray = [];
    this.selectedCriteria = await this.selectedCriteria.map(x => {
      if (x.parameterName) {
        let obj = {};
        obj[x.parameterName] = {
          value: [],
        };
        let valuesArray = x.paramValues.map(y => y.id);
        obj[x.parameterName]["value"] = valuesArray;
        newArray.push(obj);
      }
    });
    let newObject = {};

    newArray.forEach(obj => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      if (!newObject[key]) {
        newObject[key] = value;
      } else {
        newObject[key].value = newObject[key].value.concat(value.value);
      }
    });
    console.log(newObject);
    this.saveJsonPayload.in = newObject;
  }

  onCheckBoxChange(isSelected: boolean, variable: string) {
    console.log(isSelected);
    const parameterList = this.criteriaParameterLists.find(el => el.parameterName === variable);
    parameterList.paramValues.map(el => {
      el.isSelected = isSelected;
      return el;
    });
  }

  onSearchChange(event, parameterName) {
    const { value } = event.target;
    const criteria = this.criteriaParameterLists.find(el => el.parameterName === parameterName);
    criteria.searchText = value;
  }

  allCheckedParamsCheck(criteriaParameterLists: any) {
    criteriaParameterLists.map((cp: any) => {
      if (cp.paramValues.every((pv: any) => pv.isSelected)) this.allCheckedParams[cp.parameterName] = true;
      else this.allCheckedParams[cp.parameterName] = false;
    });
  }

  async processSelectedParameters() {
    let selectedValues = this.criteriaParameterLists.map(cpl => ({
      ...cpl,
      paramValues: cpl.paramValues.filter(pv => pv.isSelected),
    }));
    this.selectedCriteria = selectedValues.filter(item => item.paramValues.length > 0);
    console.log(selectedValues);

    let newArray = [];
    this.selectedCriteria = await this.selectedCriteria.map(x => {
      if (x.parameterName) {
        let obj = {};
        obj[x.parameterName] = {
          value: [],
        };
        let valuesArray = x.paramValues.map(y => y.id);
        obj[x.parameterName]["value"] = valuesArray;
        newArray.push(obj);
      }
    });
    let newObject = {};

    newArray.forEach(obj => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      if (!newObject[key]) {
        newObject[key] = value;
      } else {
        newObject[key].value = newObject[key].value.concat(value.value);
      }
    });
    console.log(newObject);
    this.saveJsonPayload.in = newObject;
  }
}
