import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-single-multi-dropdown',
  templateUrl: './single-multi-dropdown.component.html',
  styleUrls: ['./single-multi-dropdown.component.scss']
})
export class SingleMultiDropdownComponent implements OnInit {
  @Input() itemList: any;
  @Input() name: any;
  @Input() singleSelection: any;
  @Input() property: any;
  @Input() placeholder: any;
  @Input() ids: any;
  @Input() type: any;
  @Input() showSelected: any;
  @Input() masterId: any;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Input() isDisable: any;
  @Input() keyName: any;
  @Input() allowSearchFilter: boolean;
  public dropdownSettings: IDropdownSettings;
  public itemIds: any;
  public selectionFlag :boolean = true;
  private localStorageKey = 'selectedItems'; 
  constructor() {

   }

  ngOnInit() {
    // this.updateSelectedItems();
    if(this.singleSelection == 'multiple'){
      this.selectionFlag = false
    }
    this.dropdownSettings = {
      singleSelection: this.selectionFlag ,
      idField: 'id',
      textField: this.name,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: this.showSelected === 0 ? 0 : 3,
      allowSearchFilter: this.allowSearchFilter === false ? this.allowSearchFilter : true,
      closeDropDownOnSelection: true
    };
  }

  onItemSelect(item: any) {
    // this.updateSelectedItems();
    this.itemIds = [];
    this.ids.forEach(el => {
      this.itemIds.push(el.id);
    });
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: this.ids,
      masterId: this.masterId
    }
    //  this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.property == 'shapeList') {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      } else if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  onSelectAll(items: any) {
    // this.updateSelectedItems();
    this.itemIds = [];
    items.forEach(el => {
      this.itemIds.push(el.id);
    });
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: items,
      masterId: this.masterId
    }
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  onDeSelect(item: any) {
    // this.updateSelectedItems();
    this.itemIds = [];
    this.ids.forEach(el => {
      this.itemIds.push(el.id);
    });
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: this.ids,
      masterId: this.masterId
    }
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  onDeSelectAll(items: any) {
    // this.updateSelectedItems();  
    this.itemIds = [];
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: this.ids,
      masterId: this.masterId
    }
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  //#region selected options to display at top
  updateSelectedItems() {
    if (this.ids && this.ids.length > 0 && this.ids[0].id !== undefined) {
      this.itemIds = this.ids.map((el) => el.id);
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.ids));
      // this.moveSelectedToTop();
    }
  }


  moveSelectedToTop() {
  if (this.ids && this.ids.length > 0 && this.ids[0].id !== undefined) {
    const selectedItems = this.ids.map((selected: any) =>
      this.itemList.find((item: any) => item.id === selected.id)
    );
    const unselectedItems = this.itemList.filter(
      (item: any) => !this.ids.some((selected: any) => selected.id === item.id)
    );
    this.itemList = [...selectedItems, ...unselectedItems];
    // console.log(this.itemList, 'selected items moved to top');
  }
}

}
