import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponentModule } from 'src/app/components/header/header.module';
import { FooterModule } from 'src/app/footer/footer.module';
import { DataGridComponentModule } from 'src/app/components/data-grid/data-grid.module';
import { SingleMultiDropdownModule } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.module';
import { SingleMultiDropdownComponent } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.component';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';
import { ScannerinputComponent } from '../components/scannerinput/scannerinput.component';
import { FileDownloadComponent } from './file-download.component';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [FileDownloadComponent],
  imports: [
    CommonModule,
    HeaderComponentModule,
    FooterModule,
    IonicModule,
    DataGridComponentModule,
    SingleMultiDropdownModule,
    WidthAlertModule,
    ScannerinputModule,
  ],
  exports: [FileDownloadComponent],
  entryComponents: [SingleMultiDropdownComponent],
  providers: [],
})
export class FileDownloadModule {}
