import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { getFinalPlanBYPacketID } from 'src/app/home/state/home.selector';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { tempDataActions } from 'src/app/tempDataStore/tempData.action-type';
import { getChatData, getCurrentAction, getCurrentRowData, getCurrentSearchData } from 'src/app/tempDataStore/tempData.selector';
import { environment } from 'src/environments/environment';
import { BidPlansComponent } from '../bid-plans/bid-plans.component';
import { PopoverUserComponent } from '../popover-user/popover-user.component';
import { SignermodalComponent } from '../signermodal/signermodal.component';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';
import { SignerAction } from '../trybesigner/state/signer.action.type';
import { chekerAction } from './state/checker.action.type';
import { getCheckerAllPacketPlans, getFinalPlanData, getSelectedPlanData } from './state/checker.selector';

@Component({
  selector: 'app-trybe-checker',
  templateUrl: './trybe-checker.component.html',
  styleUrls: ['./trybe-checker.component.scss'],
})
export class TrybeCheckerComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  paramSelectionList = {};
  screenFor: string = "";
  fromJsonInputs: any;
  setIndeterminateState: boolean = false;
  parentCheckbox: boolean = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public paramList = [];
  public planData = [];
  selectedFile: any;
  actionid: any;
  selectedPacket: any;
  toUser: any;
  public planShapes = [];
  public planHex = [];
  public test: boolean = false;
  // packetPlans$:Observable<any>;
  packetPlans: any = [];
  public remarksPlanID = [];
  public selectedFinalPlan;
  public conversations = [];
  public showChat: boolean = false;

  // packetPlans: any = [
  //   {
  //     "id": 11340,
  //     "packetId": 2122,
  //     "signerId": 147,
  //     "seqNo": "18",
  //     "remarks": null,
  //     "bidAmt": null,
  //     "packetPlanStones": [
  //       {
  //         "id": 13007,
  //         "packetPlanId": 11340,
  //         "tag": 18,
  //         "expPol": "1.471",
  //         "shapeId": 715,
  //         "clarityId": 69,
  //         "cutId": 379,
  //         "colorId": 227,
  //         "pavAngle": "41.640",
  //         "pavHeight": "44.500",
  //         "crAngle": "38.990",
  //         "crHeight": "15.000",
  //         "tableVal": "63.000",
  //         "girdle": "5.500",
  //         "diameter": "6.300",
  //         "height": "64.900",
  //         "lOrW": "1.400",
  //         "rapoRate": "3103.000",
  //         "sawMic": "mic",
  //         "lengthMM": "8.810",
  //         "heightMM": "6.300",
  //         "widthMM": "4.090",
  //         "createdDate": "2022-07-16T09:09:29.000Z",
  //         "createdBy": "2-trybe-signer2",
  //         "modifiedDate": null,
  //         "modifiedBy": null,
  //         "isDeleted": 0,
  //         "planShape": "Oval11",
  //         "planColor": "H",
  //         "planCut": "VG-2",
  //         "planClarity": "SI3",
  //         "planSymmetry": "",
  //         "symmetryId": 0,
  //         "shape": "OVAL11",
  //         "clarity": "SI3",
  //         "color": "H",
  //         "cut": "VG-2",
  //         "symmetry": null,
  //         "progName": null,
  //         "selectedProgramId": null,
  //         "kgRate": 30,
  //         "selected": false,
  //         "isFileSelected": false,
  //         "fluorescence": null
  //       }
  //     ],
  //     "selected": false,
  //     "isExpand": true,
  //     "weightSum": 1.47,
  //     "plannedRemark": "",
  //     "totalKGRate": 30,
  //     "bestRate": 0,
  //     "totalRate": 0,
  //     "expPolPer1": 122.58,
  //     "expPolPer2": 122.58
  //   },
  //   {
  //     "id": 11353,
  //     "packetId": 2122,
  //     "signerId": 147,
  //     "seqNo": "Saw29-1",
  //     "remarks": null,
  //     "bidAmt": null,
  //     "packetPlanStones": [
  //       {
  //         "id": 13021,
  //         "packetPlanId": 11353,
  //         "tag": 231,
  //         "expPol": "1.141",
  //         "shapeId": 655,
  //         "clarityId": 61,
  //         "cutId": 376,
  //         "colorId": 227,
  //         "pavAngle": "40.620",
  //         "pavHeight": "42.900",
  //         "crAngle": "34.610",
  //         "crHeight": "15.500",
  //         "tableVal": "55.000",
  //         "girdle": "4.500",
  //         "diameter": "6.630",
  //         "height": "63.000",
  //         "lOrW": "1.000",
  //         "rapoRate": "6456.000",
  //         "sawMic": "70mic",
  //         "lengthMM": "6.630",
  //         "heightMM": "6.630",
  //         "widthMM": "4.170",
  //         "createdDate": "2022-07-16T09:09:29.000Z",
  //         "createdBy": "2-trybe-signer2",
  //         "modifiedDate": null,
  //         "modifiedBy": null,
  //         "isDeleted": 0,
  //         "planShape": "ROUND",
  //         "planColor": "H",
  //         "planCut": "EX",
  //         "planClarity": "VS2",
  //         "planSymmetry": "EX",
  //         "symmetryId": 0,
  //         "shape": "ROUND",
  //         "clarity": "VS2",
  //         "color": "H",
  //         "cut": "EXCL",
  //         "symmetry": null,
  //         "progName": null,
  //         "selectedProgramId": null,
  //         "kgRate": null,
  //         "selected": false,
  //         "isFileSelected": false,
  //         "fluorescence": null
  //       },
  //       {
  //         "id": 13022,
  //         "packetPlanId": 11353,
  //         "tag": 314,
  //         "expPol": "0.303",
  //         "shapeId": 655,
  //         "clarityId": 61,
  //         "cutId": 376,
  //         "colorId": 227,
  //         "pavAngle": "40.670",
  //         "pavHeight": "43.000",
  //         "crAngle": "35.620",
  //         "crHeight": "16.100",
  //         "tableVal": "55.000",
  //         "girdle": "3.900",
  //         "diameter": "4.270",
  //         "height": "63.000",
  //         "lOrW": "1.000",
  //         "rapoRate": "374.000",
  //         "sawMic": "70mic",
  //         "lengthMM": "4.270",
  //         "heightMM": "4.270",
  //         "widthMM": "2.690",
  //         "createdDate": "2022-07-16T09:09:29.000Z",
  //         "createdBy": "2-trybe-signer2",
  //         "modifiedDate": null,
  //         "modifiedBy": null,
  //         "isDeleted": 0,
  //         "planShape": "ROUND",
  //         "planColor": "H",
  //         "planCut": "EX",
  //         "planClarity": "VS2",
  //         "planSymmetry": "",
  //         "symmetryId": 0,
  //         "shape": "ROUND",
  //         "clarity": "VS2",
  //         "color": "H",
  //         "cut": "EXCL",
  //         "symmetry": null,
  //         "progName": null,
  //         "selectedProgramId": null,
  //         "kgRate": null,
  //         "selected": false,
  //         "isFileSelected": false,
  //         "fluorescence": null
  //       }
  //     ],
  //     "selected": false,
  //     "isExpand": true,
  //     "weightSum": 1.44,
  //     "plannedRemark": "",
  //     "totalKGRate": 0,
  //     "bestRate": 0,
  //     "totalRate": 0,
  //     "expPolPer1": 120.33,
  //     "expPolPer2": 120.33
  //   }];

  public ids = [];
  public tensionData = [];
  public stressData = [];
  public stressTypeData = [];
  public isDisable = true;
  public isAllChildFileSelected = false;
  totalPlans: any;
  packetId;
  status;

  dropDownFLData: any = {
    name: 'Fluorescence',
    placeText: 'Select FL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLData: any = {
    name: 'Color',
    placeText: 'Select CL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLAData: any = {
    name: 'Clarity',
    placeText: 'Select CLA',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownShapeData: any = {
    name: 'Shape',
    placeText: 'Select Shape',
    options: [],
    selectedData: [{ id: 0, name: 'All' }],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownPieceData: any = {
    name: 'Piece',//stone piece
    placeText: 'Select Piece',
    options: [],
    selectedData: [{ id: 0, name: 'All' }],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  isAllCollapse: boolean = false;
  selectedPlans: any = [];
  rowData: any = {
    "id": 2122,
    "packetId": "9776-09-FLT4-6#31",
    "cts": 1.2,
    "inDate": "2021-09-30T09:06:53.000Z",
    "lotNo": "9776-09-FLT4-6",
    "outDate": "2021-10-11T00:00:00.000Z",
    "refpacketId": 2122,
    "nfcCode": null,
    "pktGrade": "C",
    "pktStatus": "R",
    "pktNo": "31",
    "predLimit": 2,
    "currAction": 149,
    "currState": 2,
    "currDept": 11,
    "currLocation": 6,
    "userId": 147,
    "responsibleUserId": 134,
    "partNo": null,
    "createdDate": "2022-03-21T08:17:01.000Z",
    "createdBy": "2-trybe-di1",
    "modifiedDate": "2022-07-13T10:56:59.000Z",
    "modifiedBy": "2-trybe-c1",
    "processActionInputId": null,
    "historyStatus": "Pending"
  };
  rowDatas: any = [{
    "id": 2122,
    "packetId": "9776-09-FLT4-6#31",
    "cts": 1.2,
    "inDate": "2021-09-30T09:06:53.000Z",
    "lotNo": "9776-09-FLT4-6",
    "outDate": "2021-10-11T00:00:00.000Z",
    "refpacketId": 2122,
    "nfcCode": null,
    "pktGrade": "C",
    "pktStatus": "R",
    "pktNo": "31",
    "predLimit": 2,
    "currAction": 149,
    "currState": 2,
    "currDept": 11,
    "currLocation": 6,
    "userId": 147,
    "responsibleUserId": 134,
    "partNo": null,
    "createdDate": "2022-03-21T08:17:01.000Z",
    "createdBy": "2-trybe-di1",
    "modifiedDate": "2022-07-13T10:56:59.000Z",
    "modifiedBy": "2-trybe-c1",
    "processActionInputId": null,
    "historyStatus": "Pending"
  }];

  filteredPlan: any = [];
  changes: any = {};
  dropdown: any = {};
  chLength: number = 0
  // dynamic form Data
  currentSegment: string = 'Forms';
  formJson: any;
  showSubmit: boolean = true;
  refreshField: boolean = true;
  selectedRow: any = this.rowDatas[0];
  packetDetails: any = this.rowDatas[0];
  showDrawer: boolean = false;
  showRemoveButton: boolean = false;
  resetForm: boolean = true;
  actionStatus: any = !!this.rowDatas[0] ? this.rowDatas[0].historyStatus : '-';
  isFormRequired: boolean = true;
  actionName: string = '-';
  actionId: number;
  isConfirm: boolean = false;
  showEdit: boolean = false;
  payload;
  isPlanSaved: boolean = false;
  isUpdateDisable: boolean = true;

  currentPlan: any = {};
  currentParentPlan: any = {};
  selectedValue: any = [];
  placeValue: 'Select remark';
  keyword: 'remark';
  remarkDropDown: any = {
    selectedData: [],
    options: [],
    modeFlag: 'single',
    placeText: 'Select Remark',
    name: 'Remarks',
    key: 'name'
  };

  remProg: any = [{ id: 1, name: 'Plan Program (Red)', selected: false }, { id: 2, name: 'Select Program (White)', selected: false }, { id: 3, name: 'All Program', selected: false }];
  searchShape: string = '';
  searchColor: string = '';
  searchClarity: string = '';
  searchCut: string = '';
  searchProgram: string = '';
  allData: any = [];
  currPacketCapFile: any;
  selectedRemarkProgram: any;
  selectedDropDnRemark: any = [];
  signerLimit: number = 2;//no of Signer can Plan //Static data, need to discuss and remove it later
  signerBestPlans: any = [];//no of Signer can Plan 
  currSignerCount: number = 0;
  //configService: any;
  packetPlanStones: any;
  currActionData: any;
  userData;
  currPacketId: any;
  isShowTool: boolean = true;
  roles: string = ""
  public actionInfo: any = {};
  chatRemarks;
  imageFile;
  appLocation = environment.fromLocation.toLowerCase();
  constructor(public router: Router,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    public httpService: HttpService,
    private store: Store<AppState>,
    public alertController: AlertController,
    private modalController: ModalController,
    private configService: ConfigService,
    private navParams: NavParams,
    public popoverController: PopoverController) {


    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        this.userData = d;
        // console.log('Current loggedIn User');
        // console.log(d);
        if (!!d.listOfActions) {
          let filteredAction = d.listOfActions.filter((r) => {
            return r.actionName == "Planning review"
          });
          this.actionId = filteredAction.length > 0 && !!filteredAction[0] ? filteredAction[0].id : null;
          console.log(this.actionId)
        }
        // console.log(temp[0].id)
        // debugger

        if (d.roles) {
          this.roles = d.roles.join();
        }
        if (d.listOfRoles) {
          this.roles = d.listOfRoles.map(d => {
            return d.roleName;
          }).join();
        }
        console.log(this.roles);
        if (this.roles.includes("Botswana Checker")) {
          this.isConfirm = true;
          console.log(this.isConfirm);

        }
        console.log(this.isConfirm);
        // if (d.departmentName == "A1/R1 Department" && d.listOfRoles[0].roleName == "Checker") {
        //   this.isConfirm = true;
        // }
        // else{
        //   this.isConfirm=false;
        // }
      }
    });
  }


  async updateData(plans) {
    console.log('getCheckerAllPacketPlans');
    console.log(plans);
    let hexes = [];
    if (!!plans) {
      this.packetPlans = plans;
      this.allData = plans;
    }
    let newPacketPlans = this.packetPlans.map((d: any) => {
      let newPacketPlanStones: any = [];
      let weightSum: number = 0;
      let expPolPer1: number = 0;//some of Packet Plan stones/Org WT*100
      let expPolPer2: number = 0;//some of Packet Plan stones/Curr WT*100
      let currWt = this.rowData.cts;
      let orgWt = this.rowData.cts;
      let newOb = { ...d };
      let tag = ''
      let isSetting = this.isShowTool;
      if (!!d.packetPlanStones) {
        if (d.packetPlanStones.length > 0) {
          tag = d.packetPlanStones[0].tag
          newPacketPlanStones = d.packetPlanStones.map((m: any, j: number) => {
            let obj = { ...m };
            obj['isSetting'] = isSetting;
            // obj['piece'] = this.getHexValue(j);
            weightSum += parseFloat(m.expPol);
            return obj;
          });
        }
      }
      expPolPer1 = (weightSum / orgWt) * 100;
      expPolPer2 = (weightSum / currWt) * 100;
      newOb['expPolPer1'] = Number(parseFloat(expPolPer1.toString()).toFixed(2));
      newOb['expPolPer2'] = Number(parseFloat(expPolPer2.toString()).toFixed(2));
      newOb['packetPlanStones'] = newPacketPlanStones;
      newOb['tag'] = tag
      newOb['isSetting'] = isSetting;
      return newOb;
    });
    this.packetPlans = newPacketPlans;
    this.planShapes = [...this.packetPlans].map(n => {
      let shape = ''
      let shapeId = ''
      //const copyOfObj = [...n.packetPlanStones];
      const packetPlanStones = n.packetPlanStones.map(x => {
        if (hexes.indexOf(x['piece']) < 0) {
          hexes.push(x['piece']);
        }
        if (x.planShape) {
          shape = x.planShape
          shapeId = x.shapeId
        }
      })
      return { name: shape, id: shapeId }

    })
    var uniq = {};
    this.planShapes = this.planShapes.filter(obj => !uniq[obj.name] && (uniq[obj.name] = true));
    this.planShapes.unshift({ 'name': 'All', id: 0 });
    this.dropDownShapeData['options'] = this.planShapes;
    this.planHex = hexes.map((x, k) => {
      return { name: x, id: k + 1 }
    });
    this.planHex.unshift({ 'name': 'All', id: 0 });
    this.dropDownPieceData['options'] = this.planHex;
    console.log(this.planHex);
    console.log(this.planShapes);
    console.log(this.packetPlans);
    this.totalPlans = this.packetPlans.length;
    //this.packetPlans =  this.packetPlans

    this.packetPlans = this.packetPlans.sort(function (a, b) {
      if (a.totalKGRate > b.totalKGRate)
        return -1;
      if (a.totalKGRate < b.totalKGRate)
        return 1;
      return 0;
    });

    for (let i = 1; i < this.packetPlans.length; ++i) {
      let PacketPlan = this.packetPlans[0];
      let test = PacketPlan.totalKGRate;
      const entry = this.packetPlans[i];
      test -= entry.totalKGRate;
      // entry.totalKGRate = test;
      let rate = (test * 100 / PacketPlan.totalKGRate).toFixed(2);
      entry.bestRate = rate;
      console.log(rate, "Rate");
    }
    console.log(this.packetPlans, "array");
  }
  ngOnInit() {

    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter((data) => !!data),
          first()
        )
        .subscribe((data: any) => {
          if (data) {
            console.log(data);
            this.status = data.status;
            this.actionId = data.id;
            this.actionName = data.name;
            if (this.actionName == "Planning review" || this.actionName == "Final Planning review") {
              this.showChat = true
            }

            this.actionInfo = data;
            if (data.actionForm) {
              let formDataObject = JSON.parse(data.actionForm);
              let parsedFormData: any = formDataObject.form;
              //this.formJson = parsedFormData
              this.fromJsonInputs = formDataObject.inputJson;

              let fdata = parsedFormData.map((field) => {
                if (field.type == 'select' || 'searchSelect') {
                  let paramName = field.key;
                  console.log(paramName);
                  this.subscriptions.push(
                    this.store
                      .pipe(select(getParamsValuesListByName(paramName)))
                      .subscribe((data) => {
                        if (data) {
                          this.paramSelectionList[paramName] = data;
                          console.log(data);
                          let newData = data.map((x) => {
                            let newobj = {
                              label: x.name,
                              value: x.id,
                            };
                            return newobj;
                          });
                          // console.log(JSON.stringify(newData))
                          //add flur in select options
                          field.templateOptions.options = newData;
                        }
                      })
                  );
                }
                return field;
              });
              this.formJson = fdata;
            }

          }
        })
    );

    this.store.pipe(select(getCurrentRowData)).pipe(takeUntil(this.unsubscribe$)).subscribe((rd: any) => {
      if (rd && rd[0]) {
        console.log('getCurrentRowData');
        this.currPacketId = rd[0].id;
        console.log(this.currPacketId);
      }
    });

    // this.route.queryParams.subscribe(params => {
    //   this.test = params['true'];
    //   console.log("init");

    // });

    //this.getCheckerPlan();
    console.log('Checker...........ngInit');
    console.log(this.appLocation);
    if (this.appLocation == 'botswana-dev') {
      console.log(this.appLocation);
      this.store.pipe(select(getChatData)).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        if (res) {
          console.log('getChatData');
          this.conversations = res

          setTimeout(() => {
            let chatList = document.getElementById("chatList");
            chatList.scrollTop = chatList.scrollHeight
          }, 500);

        }
      });
    }
    this.store.pipe(select(getCurrentRowData)).pipe(takeUntil(this.unsubscribe$)).subscribe((rd: any) => {
      if (rd && rd[0]) {
        this.toUser = rd[0].userId;

        console.log(this.toUser);
        console.log('getCurrentRowData');
        console.log(rd);
        this.rowDatas = rd;
        this.rowData = rd[0];
      }
    });
    this.store.pipe(select(getCurrentSearchData)).pipe(filter((d) => !!d), first()).pipe(takeUntil(this.unsubscribe$)).subscribe((d: any) => {
      if (d) {
        console.log('searchedPageName');
        console.log(d);
        this.actionInfo = d;
      }
    });

    
    // this.route.queryParams.subscribe(params => {
    //   this.packetId = params['packetId'];
    //   this.status = params['status'];

    //   if (!!params) {
    //     this.isShowTool = params['msg'] === 'ViewOnlyChecker' || params['status'] === 'Completed' ? false : (this.appLocation == "botswana-dev" ? false : true);
    //   }
      console.log('params');
      // console.log(params);
      // this.isConfirm= params.isConfirm;
      // console.log(this.isConfirm);
      this.packetId = this.navParams?.data['packetId'];
      this.status = this.navParams?.data['status'];

      if (!!this.navParams.data) {
        this.isShowTool =this.navParams?.data['msg'] === 'ViewOnlyChecker' || this.navParams?.data['status'] === 'Completed' ? false : (this.appLocation == "botswana-dev" ? false : true);
      }
      if (this.status == 'Completed') {
        this.store.pipe(select(getFinalPlanBYPacketID(this.packetId))).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
          console.log('%c' + 'Dev Work In Progress...1', 'color:green;font-weight:bold;font-size:16px')
          console.log(data)
          if (data) {
            let finalPlans = data.finalPlan
            this.updateData(finalPlans)
          }
        });
      } else if (this.status == 'Done') {
        this.store.pipe(select(getFinalPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
          console.log('%c' + 'Dev Work In Progress...1', 'color:green;font-weight:bold;font-size:16px')
          console.log(data)
          if (data) {
            let finalPlans = data
            this.updateData(finalPlans)
          }
        })
      }
      else {
        this.store.pipe(select(getCheckerAllPacketPlans)).pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
          if (plans) {
            // console.log('%c' + 'getCheckerAllPacketPlans', 'color:green;font-weight:bold;')
            // console.log('%c' + 'Dev Work In Progress...1', 'color:green;font-weight:bold;font-size:18px')
            console.log(plans);
            this.updateData(plans)
          }
        });
      }

    // });

    this.store.pipe(select(getParamsValuesListByName('Tension Mapping'))).subscribe(data => {
      if (!!data) {
        this.tensionData = data
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Stress'))).subscribe(data => {
      if (!!data) {
        this.stressData = data
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Stress Type'))).subscribe(data => {
      if (!!data) {
        this.stressTypeData = data
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Fluorescence'))).subscribe(data => {
      if (!!data) {
        this.dropDownFLData.options = data;
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Color'))).subscribe(data => {
      if (!!data) {
        this.dropDownCLData.options = data;
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Clarity'))).subscribe(data => {
      if (!!data) {
        this.dropDownCLAData.options = data;
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Remarks'))).subscribe(data => {
      if (!!data) {
        this.remarkDropDown.options = data;
        console.log('..........Remarks');
        console.log(data);
      }
    });
    if (this.status != 'Completed') {
      if (this.roles.includes("Surat Checker")) {
        console.log("Surat Checker")
        //this.store.dispatch(HomeActions.getPacketPlanStoneIfIsFinal({ packetId: this.packetId }))
      } else {
        this.store.dispatch(
          chekerAction.getCheckerPlans({ packetIdOb: { packetId: this.packetId } })
        );
      }
    }

    // this.packetPlans = this.store.pipe(select(getCheckerAllPacketPlans));

    this.store.pipe(select(getSelectedPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      if (plan) {
        console.log('Selected plan');
        console.log(plan);
        this.selectedPlans = plan;
        console.log(this.selectedPlans);
      }
    });

    console.log("this is checker");
    this.packetPlans = this.packetPlans;
    this.rowData = this.rowData;

    console.log(this.packetPlans, "hey");

    console.log(this.rowData, "rowdata");
    // this.currpacketId=this.rowData.id;
    // console.log(this.currpacketId);

    this.store.pipe(select(getCurrentAction)).pipe(takeUntil(this.unsubscribe$)).subscribe((action: any) => {
      if (action) {
        console.log('Selected action');
        console.log(action);
        this.currActionData = action;
      }
    });

    this.store.dispatch(chekerAction.getChatConv({ packetId: this.packetId }));

    // this.store.pipe(select(getConversation)).pipe(takeUntil(this.unsubscribe$)).subscribe((action: any) => {
    //   if (action) {
    //     console.log('Selected action');
    //     console.log(action);
    //     this.conversations = action;
    //   }
    // });
  }
  onBackButtonClick() {
    console.log('checkerScreen.........');
    console.log('onBackButtonClick');
  }
  dropDownSelection(evt: any, plan: any) {
    console.log('dropDownSelection');
    console.log(evt);
    console.log(this.changes);
    if (evt.property == "Remarks") {
      console.log('remark');
      console.log(evt);
      this.setChanges(evt, plan);
    } else if (evt.property == 'Fluorescence') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Color') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Clarity') {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == 'Shape') {
      this.dropDownShapeData.selectedData = evt.arrayList;
    } else if (evt.property == 'Piece') {
      this.dropDownPieceData.selectedData = evt.arrayList;
    }
  }
  selectionChange(evt: any) {
    console.log('evt');
    console.log(evt);

  }
  setChanges(evt: any, plan: any) {
    console.log(evt, 'set Changes');
    console.log(plan, 'set Changes');

    let dropDnValues = evt.arrayList[0];
    if (evt.arrayList.length > 0 && !!plan && !!plan.id) {
      let obj = {
        planID: plan.id,
        ...evt.arrayList[0]
      };
      console.log(obj);
      let currRecordIndex = this.selectedDropDnRemark.findIndex((i: any) => i.planID == plan.id);
      if (this.selectedDropDnRemark.length > 0 && currRecordIndex > -1) {
        this.selectedDropDnRemark[currRecordIndex] = obj;
      } else {
        this.selectedDropDnRemark.push(obj);
      }
    } else {
      let currRecordIndex = this.selectedDropDnRemark.findIndex((i: any) => i.planID == plan.id);
      console.log(currRecordIndex);
      if (currRecordIndex > -1) {
        this.selectedDropDnRemark.splice(currRecordIndex, 1);
      }
    }
    console.log(this.selectedDropDnRemark);
    this.chLength = this.selectedDropDnRemark.length;
    this.isDisable = this.chLength !== 0 ? false : true;
    console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
    console.log(this.chLength);
    console.log(this.isDisable);
  }
  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }

    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }


  showDetail(evt: any, item: any) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(item);
    // if (item.isExpand == true) {
    //   item.isExpand = false;
    // } else {
    //   item.isExpand = true;
    // }
  }
  togglingRow() {
    this.isAllCollapse = !this.isAllCollapse ? true : false;
    if (this.isAllCollapse) {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: false }));
    } else {
      this.packetPlans = this.packetPlans.map((d: any) => Object.assign({}, d, { isExpand: true }));
    }
    console.log(this.packetPlans);
  }
  onToggleBtnClick() {
    this.togglingRow();
    console.log(this.isAllCollapse);
  }
  onRefreshingCurrRate() {
    console.log('-------onRefreshingCurrRate......');
  }
  async showBidPlan() {
    if (this.packetId) {
      this.store.dispatch(SignerAction.findAllTheBidSubmittedByUser({ packetId: this.packetId, userid: this.userData.id ?? this.userData.userId }))
    }
    const modal = await this.modalCtrl.create({
      component: BidPlansComponent,
      cssClass: 'extraLargePage',
      componentProps: {
        // "message": 'message',
        'packetId': this.packetId
      },
    });

    return await modal.present();
  }

  async planSelected(data) {
    this.selectedFinalPlan = data
  }

  async submitPlan() {

    if (this.rowData.companyName == "botswana-dev") {

      this.onSubmitedSpecialIssue();
      this.saveparameter()
    }
    else {
      console.log('submitPlan', this.selectedFinalPlan);


      let paramData = {};
      let postData = {};
      let currActionName = this.currActionData.name
      paramData = {
        paramName: this.currActionData.name,
        paramValue: this.selectedFinalPlan,
      };
      console.log(this.currActionData);
      let rowDta = { ...this.rowData };
      rowDta['paramData'] = paramData;
      rowDta['actionInfo'] = this.actionInfo;
      console.log(rowDta);
      postData['listOfPacketPlaneIdList'] = [];
      postData['listOfPacketPlaneIdList'] = [this.selectedFinalPlan];
      console.log(postData);
      // await this.store.dispatch(SignerAction.setPacketPlanToIsFinal({ packetplan: postData, rowData: rowDta }));
    }
  }
  async onSubmitedSpecialIssue() {
    let obj = {
      "listOfPacketIds": [this.currPacketId
      ],
      "actionId": this.actionId,
      "toUser": this.toUser,
    }
    console.log(obj);
    // await this.httpService.showLoader();
    let res: any;
    res = await this.httpService.specialIssues(obj);
    await this.httpService.dismissLoader();
    this.router.navigate(['./home']);
    if (!!res && res.data) {
      this.configService.showToast("success", res.message);
    }
    let data = res;
    if (res.status == 401) {
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    }
  }

  saveparameter() {
    let postData = {};
    let listOfParameter = [];
    let paramObj = {
      "parameterName": "final planning review",
      "parameterValue": this.currPacketId
    }
    listOfParameter.push(paramObj);
    let formType = "JSON";
    postData['packetId'] = this.currPacketId;
    postData['listOfParameter'] = listOfParameter;
    this.payload = {
      "listOfAllPackets": []
    }
    this.payload['listOfAllPackets'].push(postData);
    console.log(this.payload);
    console.log({ parameterDetails: this.payload, actionId: this.rowData.currAction, formType: formType, barcodeObj: { packetId: this.currPacketId } });
    if (this.payload['listOfAllPackets'].length !== 0) {
      this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: this.payload, actionId: this.rowData.currAction, formType: "JSON", barcodeObj: { packetId: this.rowData.packetId }, actionInfo: "" }));
    }
  }


  async openmodel() {
    console.log("openmodel");
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'signerModal',
      componentProps: {
        "message": 'Test Ganesh',
        'data': {}
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }
  onModalExit(d: any) {
    console.log('---onModalExit');
    console.log(d);
    if (!!d.data) {
      if (d.data.for == 'saveProgram') {
        let packetId = this.currentParentPlan.packetId;
        let planStoneID = this.currentPlan.id;
        let progName = d.data.program.progName;
        let progId = d.data.program.id;
        if (this.changes['progName'] == undefined) {
          this.changes['progName'] = {};
          this.changes['progName'] = d.data.program;
        } else {
          this.changes['progName'] = d.data.program;
        }
        this.chLength = Object.keys(this.changes).length;
        this.isDisable = this.chLength !== 0 ? false : true;
        console.log('%cSAVE isDisable', 'color:Orange;font-size:16px');
        console.log(this.chLength);
        console.log(this.isDisable);
        // this.store.dispatch(SignerAction.showSelectedProgram({ packetId: packetId, planStoneID: planStoneID, program: progName, programID: progId }));
      } else if (d.data.for == 'savePlannedRemark') {
        console.log('Planned Remark');
        console.log(d);
        this.packetPlans = [...this.packetPlans].map(n => {
          let p = { ...n }
          if (p.id == d.data.packetPlanID) {
            p.plannedRemark = d.data.plannedRemark;
          }
          return p;
        })
      }
    }
  }
  // async onParentPlanSelection(evt, rd) {
  //   console.log('---------onParentPlanSelection-------------');
  //   console.log(evt);
  //   console.log(rd);

  //   if(evt.detail.checked){
  //     let childRows = rd.packetPlanStones;
  //     console.log(childRows,"childrow");
  //   }
  // }


  onPlanChange(data) {
    console.log('onPlanChange');
    console.log(data);

  }
  async savePlans() {
    console.log('---savePlans---');
    console.log(this.rowData);
    console.log(this.selectedPlans);
    console.log(this.rowData.id);
    console.log(this.packetPlanStones);

    // this.store.dispatch(getSignerBestPlans({ packetId: this.rowData.id }));

    let listOfPlans = this.selectedPlans.map(d => {
      return d.id
    });
    let plansPayload = {
      "listOfPacketPlaneIdList": listOfPlans
    }
    console.log(plansPayload);
    this.store.dispatch(chekerAction.saveSelectedPlans({
      packetPlanID: { packetPlanId: this.selectedPlans[0].id },
      pktData: this.rowData
    }));

    // setTimeout(() => {
    //   console.log(this.signerBestPlans);
    //   this.selectedPlans = [];
    // }, 1200);

  }
  childRowsClasses(plan) {
    let classes = ''
    if (plan.isExpand) {
      classes = 'childRow show';
    } else {
      classes = 'hide'
    }
  }

  onProgramRemark() {
    this.remProg = this.remProg.map((item) => {
      let l = { ...item }
      l.selected = false
      return l;
    });
    console.log(this.remProg, "hello");
  }
  onProgramRemarkSubmission(d) {
    console.log('onProgramRemarkSubmission');
    console.log(d);
    let Remark = d.data.selectProgramRemark.name;
    let grade = d.data.selectProgramRemark.id;
    console.log(grade, "grade");
    // this.store.dispatch(SignerAction.addRemarkForPacketPlan({
    //   remark: Remark,
    //   Grade: grade,
    //   listOfPacketPlanStoneIdList: this.remarksPlanID,
  }
  // ))

  async onProgramRemSelection(ev: any, d: any) {
    console.log('onProgramRemSelection');
    console.log(d);
    this.selectedRemarkProgram = {};
    this.remProg = this.remProg.map((item) => {
      let l = { ...item }
      if (!!l.hasOwnProperty('selected') && l.id == d.id) {
        this.selectedRemarkProgram = d;
        l.selected = true
      } else {
        l.selected = false
      }
      return l;
    });
    console.log(d);
    console.log(this.remProg);
    console.log(this.selectedRemarkProgram);
    await this.popoverController.dismiss();
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: 'popupSigner',
      // showBackdrop: false,
      componentProps: { id: d.id, name: d.name, grades: this.planHex, forPage: 'Signer' },
      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss()
      .then((data) => {
        // this.pushOverOpen = false;
        console.log('Signer pushOver Exit');
        console.log(data);
        this.onProgramRemarkSubmission(data);
      });
  }
  async onMenuIconClick(evt, rowData) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(evt);
    console.log(rowData);
    this.currentParentPlan = rowData;
    const modal = await this.modalCtrl.create({
      component: SignermodalComponent,
      cssClass: 'remark',
      componentProps: {
        // "message": 'showRemarks',
        "message": 'remark',
        'data': rowData,
        // 'plan': currPlan,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.onModalExit(data);
    });
    return await modal.present();
  }

  async deletePartPlan(plan) {
    console.log('object :>>deletePartPlan');
    this.showAlert(plan)
  }
  async showAlert(data) {
    let id = data.id;
    console.log(id, 'packetpartplanid');
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'CLOSE',
          handler: (d: any) => {
            console.log('Closed', d);
          },
        },
        {
          text: 'OK',
          handler: (d: any) => {
            console.log('Ok Information', d);
            console.log('Packet Plan ID', data.id);
          },
        },
      ],
    });
    await alert.present();
  }

  // getCheckerPlan(){
  //   debugger
  //   console.log("hello");
  //   let obj={
  //     packetId:this.packetId
  //   }
  //   this.httpService.fetchChekerPlanData(obj).subscribe(
  //     (res) => {
  //       console.log(res);
  //       if (res.data) {
  //         this.packetPlans = res.data;
  //         console.log(this.packetPlans,"packetplan");
  //       }
  //     },
  //     (err) => {
  //       this.configService.showToast('error', err.message);
  //     }
  //   );
  // this.packetPlans = new CustomStore({
  //   key: 'id',
  //   load: async (loadOptions: any) => {
  //     let obj={
  //       packetId:this.packetId
  //     }
  //     console.log('loadOptions ', loadOptions);
  //     if (!!loadOptions.take) {

  //       this.httpService.fetcChekerPlanData(obj).subscribe(
  //         async (res) => {
  //           if(res){
  //           console.log(res,"res");
  //           //let data = res.data.data;
  //           this.packetPlans = res.data;
  //           }

  //         },
  //         (err) => {
  //           this.configService.showToast('error', err.message);
  //         }
  //       );
  //     } 
  //     else {
  //       return {
  //         data: this.packetPlans
  //       };
  //     }
  //   },
  // });

  //}

  // async getCheckerPlan() {
  //   let res: any;
  //   this.packetPlans=[];
  //   let obj={
  //     packetId:this.packetId
  //    };
  //   res = await this.httpService.fetchChekerPlanData(obj).subscribe(
  //     data=>{
  //       console.log(data.data,"data")
  //       if(data){
  //         this.packetPlans=data.data;
  //         console.log(this.packetPlans,"data");
  //       }
  //     }
  //   ); 
  // }

  twoDecimalvalues(x) {
    let num = parseFloat(parseFloat(x).toFixed(2));
    num = !!num ? num : 0;
    return num;
  }
  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = <File>event.target.files[0];
      this.validationCheck()
    }
  }

  async validationCheck() {
    this.selectedPlans = this.packetPlans.filter(x => x.selected)
    console.log(this.selectedPlans)
    if (this.selectedPlans.length > 0) {
      await this.selectedPlans.filter(n => {
        let packetsLength = n.packetPlanStones.length
        let fileLength = n.packetPlanStones.filter(x => x.isFileSelected).length;
        console.log(this.selectedFile)
        if (packetsLength == fileLength && this.selectedFile) {
          // this.isDisable = false
          //this.httpService.showToast("danger", "Please Upload files for plans");
        } else if (this.selectedFile) {
          // this.isDisable = true
        }

      })
    }
  }

  uploadCapFile() {
    console.log('...........uploadCapFile..........');
    console.log(this.selectedFile);
    let packetId = this.rowData.id;
    const formatData = new FormData();
    console.log(packetId);
    console.log(formatData);
    formatData.append("file", this.selectedFile);
    console.log(formatData);
    let otherParam = { packetId: packetId, for: 'uploadCapFile' }
    console.log(otherParam);
    this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));//uploadCapFile
    this.selectedFile = null
  }



  download(file) {
    window.open(file, 'Download');
  }




  async updateFields() {
    console.log('..........updateFields..........');
    const alert = await this.alertController.create({
      header: 'Do you want to update?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'OK',
        handler: (d: any) => {
          this.makeChangesToPlans();
        }
      }]
    });
    await alert.present();
  }

  makeChangesToPlans() {
    console.log('makeChangesToPlans');
    let parameters = {};
    let gradeToNum = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26 };
    let fl = this.dropdown['Fluorescence'];
    let cl = this.dropdown['Color'];
    let cla = this.dropdown['Clarity'];
    let sha = this.dropDownShapeData.selectedData[0];
    let pie = this.dropDownPieceData.selectedData[0];
    let fluorescence = !!fl ? fl.name : '';
    let fluorescenceID = !!fl ? fl.id : null;
    let color = !!cl ? cl.name : '';
    let colorID = !!cl ? cl.id : null;
    let clarity = !!cla ? cla.name : '';
    let clarityID = !!cla ? cla.id : null;
    let shape = !!sha ? sha.name : '';
    let shapeId = !!sha ? sha.id : null;
    let piece = !!pie ? pie.name : '';
    let packetID = this.packetId;
    let packetIdObj = { packetId: this.packetId };
    parameters = {
      'packetID': packetID,
      'fluorescence': fluorescence,
      'fluorescenceID': fluorescenceID,
      'color': color,
      'colorID': colorID,
      'clarity': clarity,
      'clarityID': clarityID,
      'shape': shape,
      'shapeId': shapeId,
      'piece': piece
    }
    let postData = {
      "flourescenceId": fluorescenceID,
      "packetId": packetID,
      "grade": !!gradeToNum[piece] ? gradeToNum[piece] : null,
      "clarityId": clarityID,
      "colorId": colorID,
      "shapeId": !!shapeId ? shapeId : null
    }
    console.log(fluorescence);
    console.log(fluorescenceID);
    console.log(color);
    console.log(colorID);
    console.log(clarity);
    console.log(clarityID);
    console.log(shape);
    console.log(shapeId);
    console.log(piece);
    console.log(parameters);
    this.store.dispatch(chekerAction.updateFLCLClAFromPacketIdForAllSelectedPlan({ updateData: postData, packetIdObj: packetIdObj }));
    this.store.dispatch(chekerAction.updateDropDownParam({ updateData: parameters }));
    this.dropdown = {};
    if (fluorescenceID != null) {
      this.dropDownFLData.selectedData = [];
    }
    if (colorID != null) {
      this.dropDownCLData.selectedData = [];
    }
    if (clarityID != null) {
      this.dropDownCLAData.selectedData = [];
    }
  }
  saveDetailInAPlan() {
    console.log('saveDetailInAPlan');
  }

  async showDiff() {

    console.log('.....showDiff........');
    let diff = 0;
    console.log(this.packetPlans[0])
    let slectedArray = this.packetPlans.filter(x => x.diffSelect)
    if (slectedArray.length == 2) {
      if (slectedArray[0].totalRate > slectedArray[1].totalRate) {
        diff =
          (1 - slectedArray[1].totalRate / slectedArray[0].totalRate) *
          100;
        console.log(diff);
      } else {
        diff =
          (1 - slectedArray[0].totalRate / slectedArray[1].totalRate) *
          100;
        console.log(diff);
      }


      const alert = await this.alertController.create({
        header: `Difference between ${slectedArray[0].totalRate} and ${slectedArray[1].totalRate} is ${diff.toFixed(2)}%`,
        cssClass: 'my-custom-class',
        buttons: [
          {
            text: 'CLOSE',
          },
          {
            text: 'OK',
          },
        ],
      });
      await alert.present();
    }
  }

  updatePlansData(data) {
    this.packetPlans = data
  }
  ngOnDestroy() {
    console.log('%cChecker Screen Component Destroyed', 'color:orange;font-size:.625rem');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(chekerAction.removeFinalPlanData({ packetId: 0 }));

    this.store.dispatch(tempDataActions.removeCurrentRowData());
    // this.store.dispatch(SignerAction.removeSelectedPlans());
    // this.store.dispatch(SignerAction.removeSavePacketPlans());
  }

  async onReject() {
    console.log("Rejecting the stones");

    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: 'rejectRemark',
      componentProps: {
        data: { status: 'rejectRemark' }
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();

  }

  async handleModalDismisss(d) {
    console.log("hi");

  }

  onConfirm() {
    console.log("confirming the stones");
    console.log("calling the apiiiii");
    this.router.navigate(['./home']);

  }

  async send() {
    let chatData = {
      "packetId": parseInt(this.packetId),
      "remark": this.chatRemarks
    }
    if (this.imageFile) {
      chatData['url'] = this.imageFile;
    }

    this.chatRemarks = ""
    this.store.dispatch(chekerAction.sendChat({ chatData }));
  }

  async showModal(state: boolean, message: string, data: any) {
    if (state) {
      if (Object.keys(data).length !== 0 && !!data.className) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: data.className,
        });
        modal.onDidDismiss().then((d) => {
          this.handleModalDismiss(d);
        });
        return await modal.present();
      } else {
        if (Object.keys(data).length !== 0 && !!data.actionId) {
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: message,
              data: data,
            },
            cssClass: 'trybe_modal_1',
          });
          modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        } else {
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: message,
            },
            cssClass: 'trybe_modal_1',
          });
          modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
          return await modal.present();
        }
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: 'trybe_modal_0',
      });
      return await modal.present();
    }
  }
  // signerModal
  handleModalDismiss(data: any) {
    console.log('handleModalDismiss');
    console.log(data);
    if (!!data && !!data.data && data.data.status == 'ecsv file submitted') {
      console.log('Form Submitted');
      console.log(data.data);
      // this.formSubmitted(data.data);
      this.ecsvUploadWithOtherDetails(data.data);
    }
  }

  async ecsvUploadWithOtherDetails(formData) {
    let formType = 'JSON';
    console.log(formData);
    console.log(this.fromJsonInputs);
    // let fromJsonInputsModified = { ...this.fromJsonInputs };
    // fromJsonInputsModified.listOfParameter =
    //   fromJsonInputsModified.listOfParameter.map((x) => {
    //     const xData = { ...x };
    //     let paramName = xData.parameterName;
    //     if (formData[paramName]) {
    //       xData.parameterValue = formData[paramName];
    //     }
    //     let packet1 = xData.packet1;
    //     let packet2 = xData.packet2;
    //     if (!!packet1 && formData['packet1']) {
    //       let f1 = formData['packet1'];
    //       packet1.forEach((p1: any) => {
    //         let paramName = p1.parameterName;
    //         if (f1[paramName]) {
    //           p1.parameterValue = f1[paramName];
    //         }
    //       });
    //     }
    //     if (!!packet2 && formData['packet2']) {
    //       let f2 = formData['packet2'];
    //       packet2.forEach((p2: any) => {
    //         let paramName = p2.parameterName;
    //         if (f2[paramName]) {
    //           p2.parameterValue = f2[paramName];
    //         }
    //       });
    //     }
    //     return { ...xData };
    //   });
    // fromJsonInputsModified.packetId = this.selectedRow.id;
    let barcode = this.selectedRow.packetId;

    let obj = {
      listOfAllPackets: [],
    };
    let barcodeObj = {
      packetId: barcode,
    };
    const formatData = new FormData();

    // return;
    if (formData['file']) {
      console.log(formData['file']);
      formType = 'Multipart';
      let file = formData['file']['0'];
      formatData.append('file', file);
      // let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(
      //   (d) => d.parameterValue != ''
      // );
      // fromJsonInputsModified.listOfParameter = newListOfParameters;
      // obj['listOfAllPackets'].push(fromJsonInputsModified);
      // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
      formatData.append(
        'listOfAllPackets',
        JSON.stringify(obj['listOfAllPackets'])
      );
      let userId = this.userData?.id ? this.userData?.id : this.userData?.userId
      await this.store.dispatch(
        masterDataActions.ecsvUpload({
          parameterDetails: formatData,
          packetID: this.selectedRow.id,
          actionId: this.actionId,
          formType: formType,
          barcodeObj: barcodeObj,
          actionInfo: this.actionInfo,
          isAppend:false,
        })
      );
    } else {
      // obj['listOfAllPackets'].push(fromJsonInputsModified);
      console.log(obj);
      // return;
      // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: formType, barcodeObj: barcodeObj }));
    }

    // setTimeout(() => {
    //   this.resetForm = false;
    //   if (
    //     this.searchedPageName == 'Auto Receive' &&
    //     this.dataSource.length === 0
    //   ) {
    //     this.status = undefined;
    //     let limit = { limit: 2000, page: 1 };
    //     this.store.dispatch(HomeActions.getReceivedPackets(limit));
    //   }
    //   console.log('%cECSV', 'color:orange;font-size:2rem;');
    // }, 500);
  }
  async onModalDismiss(d: any) {
    console.log('onModalDismiss.....');
    console.log(d);

    // if (!!d.data && d.data.actionId == 149) {
    //   await this.store.dispatch(masterDataActions.getPacketPlans({ packetIdOb: d.data.barcodeObj }));
    //   await this.router.navigate(['/trybesigner']);
    // }
  }
  async newPlanning() {
    let info = {
      rowData: this.selectedRow,
      status: 'Initial',
      parameterList: { Fluorescence: this.dropDownFLData.options, "Tension Mapping": this.tensionData, "Stress Type": this.stressTypeData },
      className: 'ecsvModal',
      history: [],
    };
    this.showModal(true, 'e-csv Upload', info);

  }

  async fileAdded(event) {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    console.log(formData)
    await this.httpService.showLoader()
    let res: any;
    res = await this.httpService.uploadChatFile(formData)
    await this.httpService.dismissLoader()
    if (!!res && res.path) {
      this.imageFile = res.path;
      this.send()
      this.configService.showToast("success", "File Uploaded");
    }

  }



}

