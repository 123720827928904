// apis
export const virtualAPIs = {
    virtualTaskEndPoint: "virtual-task/getVirtualTaskOnBasisOfTask", //GET
    saveVirtualTaskEndPoint: "virtual-task/inputReceiver/",//POST
    statusVirtualTaskEndPoint: "virtual-task/changeStatusForTask?id=",//GET
    getProcessDetailEndpoint: "virtual-task/getProcessActionInputInfo",//GET
};

// data modals
export interface MyVirtualTask {
    data:any,
    assigned: any,
    assignedCount: number,
    completed: any,
    completedCount: number,
    pending: any,
    pendingCount: number
}