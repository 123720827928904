import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { from, throwError } from "rxjs";
import { catchError, concatMap, map, tap } from "rxjs/operators";
import { HttpService } from "src/app/services/http/http.service";
import { AutoReceiveAction } from "./auto-receive.action.type";
import { AutoReceiveState } from "./auto-receive.state";

@Injectable()
export class AutoReceiveEffects {
  constructor(
    private navCtrl: NavController,
    private actions: Actions,
    public httpService: HttpService,
    private store: Store<AutoReceiveState>,
  ) {

  }
  getPacketDetailsByBarcode$ = createEffect(() =>
    this.actions.pipe(
      ofType(AutoReceiveAction.getScannedPacketDetails),
      concatMap((action) =>
        from(
          this.httpService.fetchPacketDetailsById(action.packetIdObj),
        ).pipe(
          map(res => {
            console.log("%cTest Raju", "color:green;");
            console.log(res);
            // this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            // this.httpService.getErrorAndDisplayIt(error)
            return throwError(error);
          })
        ),
      ),
      tap((res: any) => {
        if (!!res.error && res.error.length > 0) {
          console.log(res.error);
        }
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        console.log('data---barcode');
        console.log(data);

        if (!!data.error && data.error.length > 0) {
          console.log(data.error);
          data = [];
        }
        return AutoReceiveAction.savePacketDetails({ scannedPacketDetails: data });

      })
    ),
  );
  getAutoReceivePacket$ = createEffect(() =>
    this.actions.pipe(
      ofType(AutoReceiveAction.getAutoReceivePacket),
      concatMap((action) =>
        from(this.httpService.fetchAutoReceivePacket()).pipe(
          map((res) => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data.packetData;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error)
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response && response.error == 'Unauthorized') {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return AutoReceiveAction.saveAutoReceivePacket({ saveAutoReceive: data });
      })
    )
  );

}