import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { getPreplanningSummaryDetails } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { saveCurrentRowData } from 'src/app/tempDataStore/tempData.action';

@Component({
  selector: 'app-preplanningsummary',
  templateUrl: './preplanningsummary.component.html',
  styleUrls: ['./preplanningsummary.component.scss'],
})
export class PreplanningsummaryComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  columns: any;
  dataSource: any;
  selectedRows: any;
  selectedRow: any;

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    public alertController: AlertController,
    private store: Store<AppState>,
    private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
    this.store.dispatch(masterDataActions.getPreplanningSummary());

    this.columns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Status', prop: 'status' },
      { name: 'Cts', prop: 'cts' },
      { name: 'Created Date', prop: 'createdDate' },
      { name: 'Packet Status', prop: 'pktStatus' },
    ];


    this.subscriptions.push(this.store.pipe(select(getPreplanningSummaryDetails)).subscribe((data) => {
      if (data) {
        console.log(data);
        this.dataSource = data;
        
      }
    }));

  }
  preview($event) {
    console.log("Pre-Planning Comments");
  }
  onSelectionChanged(event) {
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    console.log(this.selectedRow);
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    this.store.dispatch(
      saveCurrentRowData({ rowData: event.selectedRowsData })
    );

    this.router.navigate(['/preplanningComments'], { queryParams: { packetId: this.selectedRow, status: 'Completed', state: true } });
  }



}
