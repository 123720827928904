import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignerState } from "./signer.state";


// debugger
export const selectSignerState = createFeatureSelector<SignerState>(
    'signer',
);
export const getAllPacketPlans = createSelector(selectSignerState, state => {
    return state.packetPlans;
});
export const getAllManualPacketPlans = createSelector(selectSignerState, state => {
    return state.manualPlans;
});
export const getPlanForSpectrum = createSelector(selectSignerState, state => {
    console.log(state);
    return state.planForSpectrum;
});
export const getPlanData = createSelector(selectSignerState, state => {
    return state.packetPlanData;
});
export const getCuts = createSelector(selectSignerState, state => {
    return state.cuts;
});
export const getClarities = createSelector(selectSignerState, state => {
    return state.clarities;
});
export const getColors = createSelector(selectSignerState, state => {
    return state.colors;
});
export const getShapes = createSelector(selectSignerState, state => {
    return state.shapes;
});
export const getStoneRemarks = createSelector(selectSignerState, state => {
    return state.stoneRemarks;
});
export const getSelectedPlanData = createSelector(selectSignerState, state => {
    return state.selectPlans;
});
export const getProgramData = createSelector(selectSignerState, state => {
    return state.packetPrograms;
});
export const getFilteredPlanData = createSelector(selectSignerState, state => {
    return state.filteredPlans;
});
export const getUploadedFileData = createSelector(selectSignerState, state => {
    return state.capFile;
});
// export const getSignerBestPlanData = createSelector(selectSignerState, state => {
//     return state.signerBestPlans;
// });
export const submittedPlansByUsers = createSelector(selectSignerState, state => {
    return state.submittedPlansByUsers;
});

export const getReviewStoneDept = createSelector(selectSignerState, state => {
    return state.reviewStoneDept;
});
export const selectFileDetails = createSelector(selectSignerState, state => {
    return state.fileDetails;
});
export const selectSpecificFileDetail = (tabId: number) => createSelector(selectSignerState, state => {
    let fileDetail = [];
    console.log(state.fileDetails, tabId);
    for(let key in state.fileDetails) {
        console.log(key , tabId)
        if(parseInt(key) === tabId) {

            fileDetail = state.fileDetails[key];
        }
    }
    console.log(fileDetail, tabId);
    // debugger
    return fileDetail;
});

export const getOrderForm = createSelector(selectSignerState, state => {
    return state.orderForm;
});

export const getSelectedEcsvOption = createSelector(selectSignerState, state => {
    return state.ecsvValue;
});


export const getSpectrumOtherFile = createSelector(selectSignerState, state => {
    return state.otherFile;
});


export const getSpectrumPdFile = createSelector(selectSignerState, state => {
    return state.pdFile;
});

export const getReconfirmData = createSelector(selectSignerState, state => {
    return state.reconfirmBidData;
});

export const checkerIDForSpectrum = createSelector(
    selectSignerState,
    (state) => state.checkerIdForSpectrum
  );

  export const actionIDForSpectrum = createSelector(
    selectSignerState,
    (state) => state.reviewByCheckerActionId
  );
  