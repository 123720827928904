import { NgModule } from "@angular/core";
import { PacketInwardComponent } from "./packet-inward.component";
import { CommonModule, DatePipe } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { HeaderComponentModule } from "src/app/components/header/header.module";
import { FooterModule } from "src/app/footer/footer.module";
import { DataGridComponentModule } from "src/app/components/data-grid/data-grid.module";
import { PacketInwardService } from "../services/packet-inward.service";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { PacketInwardEffect } from "../state-management/packet-inward/packet-inward.effect";
import { packetInwardMasterReducer } from "../state-management/packet-inward/packet-inward.interface";
import { FormsModule } from "@angular/forms";
import { NewDropDownModule } from "src/app/components/new-drop-down/new-drop-down.module";

@NgModule({
    declarations: [
        PacketInwardComponent,
    ],
    imports: [
        CommonModule,
        HeaderComponentModule,
        IonicModule,
        FooterModule,
        DataGridComponentModule, 
        EffectsModule.forFeature([ PacketInwardEffect ]),
        StoreModule.forFeature('PACKETINWARD', packetInwardMasterReducer),
        FormsModule,
        NewDropDownModule
    ],
    exports: [
        PacketInwardComponent,
    ],
    providers: [
        PacketInwardService,
        DatePipe,
    ]
})
export class PacketInwardModule {}