import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RoughpacketvalueComponent } from './roughpacketvalue.component';
import { DataGridComponentModule } from '../data-grid/data-grid.module';
import { HeaderComponentModule } from '../header/header.module';



@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      DataGridComponentModule,
      HeaderComponentModule,
    ],
    declarations: [
        RoughpacketvalueComponent,
      ],
      entryComponents: [
        RoughpacketvalueComponent,
      ],
      exports: [RoughpacketvalueComponent],
      providers: []
    })
    export class RoughpacketvalueModule { }