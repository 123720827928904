import { createReducer, on } from "@ngrx/store";
import { IssueAction } from "./issue.action.type";
import { initialWithMeState } from "./issue.state";

export const withMeReducer = createReducer(
    initialWithMeState,
    on(IssueAction.saveUsersByAction, (state, action) => {
        return {
            ...state,
            usersByAction: action.usersbyAction
        }
    }),

    on(IssueAction.saveUsersByVirtualAction, (state, action) => {
        return {
            ...state,
            usersByVirtualAction: action.usersbyVirtualAction
        }
    }),

    on(IssueAction.saveProcessByPackets, (state, action) => {
        return {
            ...state,
            actionsByPacket: action.actionsByPacket
        }
    }),
    on(IssueAction.saveProcessByVirtualPackets, (state, action) => {
        return {
            ...state,
            actionsByVirtualPacket: action.actionsByVirtualPacket
        }
    }),
    on(IssueAction.unSelectionOfPacket, (state, action) => {
        return {
            ...state,
            usersByAction: [],
            actionsByPacket: [],
        }
    }),
    on(IssueAction.updateActions, (state, action) => {
        return {
            ...state,
            actionsByPacket: action.actionsByPacket,
        }
    }),
    on(IssueAction.updateUsers, (state, action) => {
        return {
            ...state,
            usersByAction: action.usersbyAction,
        }
    }),
    on(IssueAction.setStartPoint, (state, action) => {
        return {
            ...state,
            startFrom: action.startFrom,
        }
    }),
    on(IssueAction.saveProcessParameter, (state, action) => {
        return {
            ...state,
            processnoteByActionID: action.processnoteByActionID,
        }
    }),
    on(IssueAction.resetUser, (state, action) => {
        return {
            ...state,
            usersByAction: [],
        }
    })

)