import { createReducer, on } from '@ngrx/store';
import { SignerAction } from './signer.action.type';
import { initialSignerState } from './signer.state';
import { action } from 'src/app/tempDataStore/tempData.model';

export const signerReducer = createReducer(
  initialSignerState,
  on(SignerAction.savePacketPlans, (state, action) => {
    let packetPlans: any = [...action.packetPlans];
    let packetPlanData = action.packetPlanData;
    let alpha: String = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    console.log(state);
    let cuts: any = [],
      shapes: any = [],
      clarities: any = [],
      colors: any = [],
      stoneRemarks: any = [];
    let cutMap: any = {},
      shapeMap: any = {},
      clarityMap: any = {},
      colorMap: any = {},
      stoneRemarkMap: any = {};
    let newPacketPlans = packetPlans.map((d: any) => {
      let newPacketPlanStones: any = [];
      let weightSum: number = 0;
      let yieldnum: number = 0;
      let totalKGRate: number = 0;
      let doubtfulValue: number = 0;
      let isShownDoubtfulValue: boolean = false;
      let bestRate: number = 0;
      let hasSavedPlan: boolean = false;
      let totalRate: number = 0;
      let expPolPer1: number = 0; //some of Packet Plan stones/Org WT*100
      let expPolPer2: number = 0; //some of Packet Plan stones/Curr WT*100
      let newOb = { ...d };
      let totalRghWt: number = 0;
      console.log(newOb);
      let seqNum = newOb['seqNo'];
      if (!!d.packetPlanStones) {
        newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
          let obj = { ...m };
          obj['piece'] = alpha[i];
          obj['selected'] = obj['selected'] ? obj['selected'] : false;
          obj['isFileSelected'] = obj['isFileSelected']
            ? obj['isFileSelected']
            : false;
          // obj['isExpand'] = true;
          obj['doubtfulValue'] = doubtfulValue;
          obj['isShownDoubtfulValue'] = isShownDoubtfulValue;

          if (!obj['fluorescence']) {
            obj['fluorescence'] = null;
          }
          cutMap[m.cutId] = m.cut;
          shapeMap[m.shapeId] = m.shape;
          clarityMap[m.clarityId] = m.clarity;
          colorMap[m.colorId] = m.color;

          // cuts.push({ id: m.cutId, name: m.cut });
          // shapes.push({ id: m.shapeId, name: m.shape });
          // clarities.push({ id: m.clarityId, name: m.clarity });
          // colors.push({ id: m.colorId, name: m.color });
          // stoneRemarkMap[m.remarkId]=m.remark;

          // stoneRemarks.push({id:m.colorId,cut:m.color});
          let kgRate = m.kgRate != null ? m.kgRate : 0;
          // obj['progName'] = '-';
          // obj['progId'] = 0;

          weightSum += parseFloat(m.expPol);
          totalRghWt += parseFloat(m.sawRghWgt);
          yieldnum += m.yield;
          totalKGRate += parseFloat(kgRate);
          return obj;
        });
        cuts = Object.keys(cutMap).map((prop) => {
          let obj: any = { id: prop, name: cutMap[prop] };
          console.log(obj);
          return obj;
        });
        shapes = Object.keys(shapeMap).map((prop) => {
          let obj: any = { id: prop, name: shapeMap[prop] };
          return obj;
        });
        clarities = Object.keys(clarityMap).map((prop) => {
          let obj: any = { id: prop, name: clarityMap[prop] };
          return obj;
        });
        colors = Object.keys(colorMap).map((prop) => {
          let obj: any = { id: prop, name: colorMap[prop] };
          return obj;
        });
        // stoneRemarks=Object.keys(cutMap).map(prop=> {
        //     let obj:any={ id: prop, name: cutMap[prop] };
        //     return obj;
        // });
      }
      newOb['seqNo'] = isNaN(seqNum) ? seqNum : '';
      newOb['selected'] = newOb['selected'] ? newOb['selected'] : false;
      newOb['isExpand'] = newOb['selected'] ? newOb['selected'] : true;
      newOb['totExpYield'] = Math.round(weightSum * 100) / 100; //Exp P Yield calculated Field
      newOb['totRghYield'] = Math.round(totalRghWt * 100) / 100;
      newOb['Yield'] = Number(yieldnum.toFixed(2)); // Yield calculated Field
      newOb['expPolPer1'] = expPolPer1;
      newOb['expPolPer2'] = expPolPer2;
      newOb['packetPlanStones'] = newPacketPlanStones;
      newOb['plannedRemark'] = '';
      newOb['hasSavedPlan'] = hasSavedPlan;
      newOb['planPriority'] = newOb['planPriority'];
      newOb['selectionOrder'] = newOb['selectionOrder'];

      newOb['totalKGRate'] = totalKGRate; //totalKGRate/KG Rate calculated Field
      newOb['bestRate'] = bestRate; //Exp P Yield calculated Field
      newOb['totalRate'] = !!totalRate ? totalRate : totalKGRate; //KgRate Need to remove calculated Field
      newOb['bidAmt'] = !!newOb['bidAmt'] ? newOb['bidAmt'] : totalKGRate; //KgRate Need to remove calculated Field
      return newOb;
    });
    return {
      ...state,
      allPacketPlans: newPacketPlans,
      packetPlans: newPacketPlans,
      packetPlanData: packetPlanData,
      cuts: cuts,
      shapes: shapes,
      clarities: clarities,
      colors: colors,
      stoneRemarks: stoneRemarks,
    };
  }),
  on(SignerAction.saveManualPacketPlans, (state, action) => {
    console.log(action);

    let packetPlans: any = [...action.packetPlans];
    console.log(packetPlans);
    let alpha: String = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    console.log(state);
    let cuts: any = [],
      shapes: any = [],
      clarities: any = [],
      colors: any = [],
      stoneRemarks: any = [];
    let cutMap: any = {},
      shapeMap: any = {},
      clarityMap: any = {},
      colorMap: any = {},
      stoneRemarkMap: any = {};
    let newManualPacketPlans = packetPlans.map((d: any) => {
      let newPacketPlanStones: any = [];
      let weightSum: number = 0;
      let yieldnum: number = 0;
      let totalKGRate: number = 0;
      let doubtfulValue: number = 0;
      let isShownDoubtfulValue: boolean = false;
      let bestRate: number = 0;
      let hasSavedPlan: boolean = false;
      let totalRate: number = 0;
      let expPolPer1: number = 0; //some of Packet Plan stones/Org WT*100
      let expPolPer2: number = 0; //some of Packet Plan stones/Curr WT*100
      let newOb = { ...d };
      let totalRghWt: number = 0;

      let seqNum = newOb['seqNo'];
      console.log(d);
      if (!!d.packetPlanStones) {
        newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
          let obj = { ...m };
          obj['piece'] = alpha[i];
          obj['selected'] = obj['selected'] ? obj['selected'] : false;
          obj['isFileSelected'] = obj['isFileSelected']
            ? obj['isFileSelected']
            : false;
          // obj['isExpand'] = true;
          obj['doubtfulValue'] = doubtfulValue;
          obj['isShownDoubtfulValue'] = isShownDoubtfulValue;

          if (!obj['fluorescence']) {
            obj['fluorescence'] = null;
          }
          cutMap[m.cutId] = m.cut;
          shapeMap[m.shapeId] = m.shape;
          clarityMap[m.clarityId] = m.clarity;
          colorMap[m.colorId] = m.color;

          // cuts.push({ id: m.cutId, name: m.cut });
          // shapes.push({ id: m.shapeId, name: m.shape });
          // clarities.push({ id: m.clarityId, name: m.clarity });
          // colors.push({ id: m.colorId, name: m.color });
          // stoneRemarkMap[m.remarkId]=m.remark;

          // stoneRemarks.push({id:m.colorId,cut:m.color});
          let kgRate = m.kgRate != null ? m.kgRate : 0;
          // obj['progName'] = '-';
          // obj['progId'] = 0;

          weightSum += parseFloat(m.expPol);
          totalRghWt += parseFloat(m.sawRghWgt);
          yieldnum += m.yield;
          totalKGRate += parseFloat(kgRate);
          return obj;
        });
        cuts = Object.keys(cutMap).map((prop) => {
          let obj: any = { id: prop, name: cutMap[prop] };
          console.log(obj);
          return obj;
        });
        shapes = Object.keys(shapeMap).map((prop) => {
          let obj: any = { id: prop, name: shapeMap[prop] };
          return obj;
        });
        clarities = Object.keys(clarityMap).map((prop) => {
          let obj: any = { id: prop, name: clarityMap[prop] };
          return obj;
        });
        colors = Object.keys(colorMap).map((prop) => {
          let obj: any = { id: prop, name: colorMap[prop] };
          return obj;
        });
        // stoneRemarks=Object.keys(cutMap).map(prop=> {
        //     let obj:any={ id: prop, name: cutMap[prop] };
        //     return obj;
        // });
      }
      newOb['seqNo'] = isNaN(seqNum) ? seqNum : '';
      newOb['selected'] = newOb['selected'] ? newOb['selected'] : false;
      newOb['isExpand'] = newOb['selected'] ? newOb['selected'] : true;
      newOb['totExpYield'] = Math.round(weightSum * 100) / 100; //Exp P Yield calculated Field
      newOb['totRghYield'] = Math.round(totalRghWt * 100) / 100;
      newOb['Yield'] = Number(yieldnum.toFixed(2)); // Yield calculated Field
      newOb['expPolPer1'] = expPolPer1;
      newOb['expPolPer2'] = expPolPer2;
      newOb['packetPlanStones'] = newPacketPlanStones;
      newOb['plannedRemark'] = '';
      newOb['hasSavedPlan'] = hasSavedPlan;

      newOb['totalKGRate'] = totalKGRate; //totalKGRate/KG Rate calculated Field
      newOb['bestRate'] = bestRate; //Exp P Yield calculated Field
      newOb['totalRate'] = !!totalRate ? totalRate : totalKGRate; //KgRate Need to remove calculated Field
      newOb['bidAmt'] = !!newOb['bidAmt'] ? newOb['bidAmt'] : totalKGRate; //KgRate Need to remove calculated Field
      return newOb;
    });
    return {
      ...state,
      manualPlans: newManualPacketPlans,
    };
  }),

  on(SignerAction.savePacketPlansForSpectrum, (state, action) => {
    console.log(action);
    let checkerId = action.checkerId;
    let actionId = action.reviewByCheckerActionId;

    let packetPlans: any = [...action.packetPlans];
    let alpha: String = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    console.log(state);
    let cuts: any = [],
      shapes: any = [],
      clarities: any = [],
      colors: any = [],
      stoneRemarks: any = [];
    let cutMap: any = {},
      shapeMap: any = {},
      clarityMap: any = {},
      colorMap: any = {},
      stoneRemarkMap: any = {};
    let planForSpectrum = packetPlans.map((d: any) => {
      let newPacketPlanStones: any = [];
      let weightSum: number = 0;
      let yieldnum: number = 0;
      let totalKGRate: number = 0;
      let doubtfulValue: number = 0;
      let isShownDoubtfulValue: boolean = false;
      let bestRate: number = 0;
      let hasSavedPlan: boolean = false;
      let totalRate: number = 0;
      let expPolPer1: number = 0; //some of Packet Plan stones/Org WT*100
      let expPolPer2: number = 0; //some of Packet Plan stones/Curr WT*100
      let newOb = { ...d };
      let totalRghWt: number = 0;

      let seqNum = newOb['seqNo'];
      if (!!d.packetPlanStones) {
        newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
          let obj = { ...m };
          obj['piece'] = alpha[i];
          obj['selected'] = obj['selected'] ? obj['selected'] : false;
          obj['isFileSelected'] = obj['isFileSelected']
            ? obj['isFileSelected']
            : false;
          // obj['isExpand'] = true;
          obj['doubtfulValue'] = doubtfulValue;
          obj['isShownDoubtfulValue'] = isShownDoubtfulValue;

          if (!obj['fluorescence']) {
            obj['fluorescence'] = null;
          }
          cutMap[m.cutId] = m.cut;
          shapeMap[m.shapeId] = m.shape;
          clarityMap[m.clarityId] = m.clarity;
          colorMap[m.colorId] = m.color;

          // cuts.push({ id: m.cutId, name: m.cut });
          // shapes.push({ id: m.shapeId, name: m.shape });
          // clarities.push({ id: m.clarityId, name: m.clarity });
          // colors.push({ id: m.colorId, name: m.color });
          // stoneRemarkMap[m.remarkId]=m.remark;

          // stoneRemarks.push({id:m.colorId,cut:m.color});
          let kgRate = m.kgRate != null ? m.kgRate : 0;
          // obj['progName'] = '-';
          // obj['progId'] = 0;

          weightSum += parseFloat(m.expPol);
          totalRghWt += parseFloat(m.sawRghWgt);
          yieldnum += Number(yieldnum.toFixed(2));
          totalKGRate += parseFloat(kgRate);
          return obj;
        });
        cuts = Object.keys(cutMap).map((prop) => {
          let obj: any = { id: prop, name: cutMap[prop] };
          console.log(obj);
          return obj;
        });
        shapes = Object.keys(shapeMap).map((prop) => {
          let obj: any = { id: prop, name: shapeMap[prop] };
          return obj;
        });
        clarities = Object.keys(clarityMap).map((prop) => {
          let obj: any = { id: prop, name: clarityMap[prop] };
          return obj;
        });
        colors = Object.keys(colorMap).map((prop) => {
          let obj: any = { id: prop, name: colorMap[prop] };
          return obj;
        });
        // stoneRemarks=Object.keys(cutMap).map(prop=> {
        //     let obj:any={ id: prop, name: cutMap[prop] };
        //     return obj;
        // });
      }
      newOb['seqNo'] = isNaN(seqNum) ? seqNum : '';
      newOb['selected'] = newOb['selected'] ? newOb['selected'] : false;
      newOb['isExpand'] = newOb['selected'] ? newOb['selected'] : true;
      newOb['totExpYield'] = Math.round(weightSum * 100) / 100; //Exp P Yield calculated Field
      newOb['totRghYield'] = Math.round(totalRghWt * 100) / 100;
      newOb['Yield'] = Math.round(yieldnum); // Yield calculated Field
      newOb['expPolPer1'] = expPolPer1;
      newOb['expPolPer2'] = expPolPer2;
      newOb['packetPlanStones'] = newPacketPlanStones;
      newOb['plannedRemark'] = '';
      newOb['hasSavedPlan'] = hasSavedPlan;

      newOb['totalKGRate'] = totalKGRate; //totalKGRate/KG Rate calculated Field
      newOb['bestRate'] = bestRate; //Exp P Yield calculated Field
      newOb['totalRate'] = !!totalRate ? totalRate : totalKGRate; //KgRate Need to remove calculated Field
      newOb['bidAmt'] = !!newOb['bidAmt'] ? newOb['bidAmt'] : totalKGRate; //KgRate Need to remove calculated Field
      return newOb;
    });
    return {
      ...state,
      planForSpectrum: planForSpectrum,
      checkerIdForSpectrum: checkerId,
      reviewByCheckerActionId: actionId,
      otherFile: action.otherFile,
      pdFile: action.pdFile,
    };
  }),

  on(SignerAction.changePlanOncheck, (state, action) => {
    let plans: any = [...state.selectPlans];
    let currPlan: any = action.plan;
    let currentChkState: any = action.isChecked;
    if (currentChkState) {
      let currRecordIndex = plans.findIndex((i: any) => i.id == currPlan.id);
      if (currRecordIndex > -1) {
        plans.splice(currRecordIndex, 1);
      }
      if (currPlan?.planPriority || currPlan?.selectionOrder)
        plans.push(currPlan);
    } else {
      let currRecordIndex = plans.findIndex((i: any) => i.id == currPlan.id);
      if (currRecordIndex > -1) {
        plans.splice(currRecordIndex, 1);
      }
    }

    return {
      ...state,
      selectPlans: plans,
    };
  }),
  on(SignerAction.removeSelectedPlans, (state, action) => {
    return {
      ...state,
      selectPlans: [],
    };
  }),
  on(SignerAction.savePrograms, (state, action) => {
    return {
      ...state,
      packetPrograms: action.packetPrograms,
    };
  }),
  on(SignerAction.showSelectedPlan, (state, action) => {
    let plans = [...state.allPacketPlans];
    let selPlan = plans.filter(
      (plan) => plan.packetId === action.packetID && plan.id == action.planID
    );
    return {
      ...state,
      packetPlans: selPlan,
    };
  }),
  on(SignerAction.showAllSelPlan, (state, action) => {
    let plans = [...state.allPacketPlans];
    const filterByReference = (arr1, arr2) => {
      let res = [];
      res = arr1.filter((selPan) => {
        return !arr2.find((plan) => {
          console.log(plan.paramIValue == selPan.id);

          return plan.paramIValue !== selPan.id;
        });
      });
      return res;
    };
    console.log(filterByReference(plans, action.planIDs));
    return {
      ...state,
      packetPlans: filterByReference(plans, action.planIDs),
    };
  }),
  on(SignerAction.removeSavePacketPlans, (state, action) => {
    return {
      ...state,
      packetPlans: [],
    };
  }),
  on(SignerAction.showSelectedProgram, (state, action) => {
    console.log('showSelectedProgram');
    let allPlans = [...state.packetPlans];
    console.log(allPlans);
    let modifiedPlan = allPlans.map((plan) => {
      let p = { ...plan };
      let packetPlanStones = p.packetPlanStones;
      if (p.packetId == action.packetID) {
        packetPlanStones = packetPlanStones.map((ps) => {
          let planStone = { ...ps };
          if (planStone.id == action.planStoneID) {
            planStone['progName'] = action.program;
            planStone['selectedProgramId'] = action.programID;
          }
          return planStone;
        });
      }
      p['packetPlanStones'] = packetPlanStones;
      return p;
    });
    console.log(modifiedPlan);
    return {
      ...state,
      packetPlans: modifiedPlan,
    };
  }),
  on(SignerAction.updateDropDownParam, (state, action) => {
    console.log('--->>updateDropDownParam');
    let param = action.updateData;
    let newPacketPlans = [...state.packetPlans];
    let modifiedPlan = newPacketPlans.map((d: any) => {
      let newPacketPlanStones: any = [];
      let newOb = { ...d };

      if (!!d.packetPlanStones) {
        newPacketPlanStones = d.packetPlanStones.map((m: any, j: number) => {
          let obj = { ...m };
          if (param.shape == 'All' && param.piece == 'All') {
            if (!!param.fluorescence) {
              obj['fluorescence'] = param.fluorescence;
              obj['fluorescenceId'] = param.fluorescenceID;
            }
            if (!!param.color) {
              obj['planColor'] = param.color;
              obj['colorId'] = param.colorID;
            }
            if (!!param.clarity) {
              obj['planClarity'] = param.clarity;
              obj['clarityId'] = param.clarityID;
            }
          } else if (param.shape == 'All' && param.piece != 'All') {
            if (obj.piece == param.piece) {
              if (!!param.fluorescence) {
                console.log('fl......');
                obj['fluorescence'] = param.fluorescence;
                obj['fluorescenceId'] = param.fluorescenceID;
              }
              if (!!param.color) {
                console.log('cl......');
                obj['planColor'] = param.color;
                obj['colorId'] = param.colorID;
              }
              if (!!param.clarity) {
                console.log('cla......');
                obj['planClarity'] = param.clarity;
                obj['clarityId'] = param.clarityID;
              }
            }
          } else if (param.shape != 'All' && param.piece == 'All') {
            if (obj.planShape == param.shape) {
              if (!!param.fluorescence) {
                obj['fluorescence'] = param.fluorescence;
                obj['fluorescenceId'] = param.fluorescenceID;
              }
              if (!!param.color) {
                obj['planColor'] = param.color;
                obj['colorId'] = param.colorID;
              }
              if (!!param.clarity) {
                obj['planClarity'] = param.clarity;
                obj['clarityId'] = param.clarityID;
              }
            }
          } else {
            if (obj.planShape == param.shape && obj.piece == param.piece) {
              if (!!param.fluorescence) {
                obj['fluorescence'] = param.fluorescence;
                obj['fluorescenceId'] = param.fluorescenceID;
              }
              if (!!param.color) {
                obj['planColor'] = param.color;
                obj['colorId'] = param.colorID;
              }
              if (!!param.clarity) {
                obj['planClarity'] = param.clarity;
                obj['clarityId'] = param.clarityID;
              }
            }
          }
          return obj;
        });
      }
      newOb['packetPlanStones'] = newPacketPlanStones;
      return newOb;
    });
    if (modifiedPlan.length > 0) {
      return {
        ...state,
        packetPlans: modifiedPlan,
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(SignerAction.saveUploadedFile, (state, action) => {
    if (action.param.for == 'uploadCapFile' && action.file) {
      return {
        ...state,
        capFile: action.file,
      };
    } else if (action.param.for == 'uploadXpsOrHtmlFile' && action.file) {
      let packetID = action.param.packetID;
      let packetPlanStoneId = action.param.packetPlanStoneId;

      let allPlans = [...state.packetPlans];

      let modifiedPlan = allPlans.map((plan) => {
        let p = { ...plan };
        let packetPlanStones = p.packetPlanStones;
        if (p.id == packetID) {
          packetPlanStones = packetPlanStones.map((ps) => {
            let planStone = { ...ps };
            if (planStone.id == packetPlanStoneId) {
              planStone['xpsFile'] = action.file;
              planStone['isFileSelected'] = true;
            }
            return planStone;
          });
        }
        p['packetPlanStones'] = packetPlanStones;
        return p;
      });
      console.log(modifiedPlan);
      return {
        ...state,
        packetPlans: modifiedPlan,
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  // on(SignerAction.saveSignerBestPlansToStore, (state, action) => {
  //     return {
  //         ...state,
  //         signerBestPlans: action.signerBestPlans
  //     };
  // }),
  on(SignerAction.saveAllTheBidSubmittedByUser, (state, action) => {
    return {
      ...state,
      submittedPlansByUsers: action.plans,
    };
  }),

  on(SignerAction.saveRemarksForPacketPlanDrawer, (state, action) => {
    console.log(action.remark);
    return {
      ...state,
      remarkForPacketPlanDrawer: action.remark,
    };
  }),

  on(SignerAction.saveRemarksForPacketPlanDrawerFinalTab, (state, action) => {
    return {
      ...state,
      remarkForPacketPlanFinalTab: action.remark,
    };
  }),

  on(SignerAction.saveRemarksForPacketPlan, (state, action) => {
    let grade = action.Grade;
    let remark = action.remark;
    let indexArray = grade.length > 0 ? grade : [];
    let packetPlans = state.packetPlans;
    let updatedPacketPlans = packetPlans.map((x) => {
      let n = { ...x };
      if (n.packetPlanStones.length > 0) {
        n.packetPlanStones = n.packetPlanStones.map(function (val, i) {
          let newVal = { ...val };
          console.log(i);
          if (indexArray.length > 0) {
            let findIndex = i + 1;
            console.log(findIndex, indexArray.indexOf(findIndex));
            if (indexArray.indexOf(findIndex) >= 0) {
              newVal['remark'] = remark;
            }
          } else {
            newVal['remark'] = remark;
          }
          return newVal;
        });
      }
      return n;
    });
    console.log(updatedPacketPlans);
    return {
      ...state,
      packetPlans: updatedPacketPlans,
    };
  }),
  on(SignerAction.changePDsavedFlag, (state, action) => {
    let packetPlanStoneId = action.packetPlanStoneId;

    let allPlans = [...state.packetPlans];

    let modifiedPlan = allPlans.map((plan) => {
      let p = { ...plan };
      let packetPlanStones = p.packetPlanStones;
      packetPlanStones = packetPlanStones.map((ps) => {
        let planStone = { ...ps };
        if (planStone.id == packetPlanStoneId) {
          planStone['pdSaved'] = true;
        }
        return planStone;
      });

      p['packetPlanStones'] = packetPlanStones;
      return p;
    });
    return {
      ...state,
      packetPlans: modifiedPlan,
    };
  }),
  on(SignerAction.savePacketPlan, (state, action) => {
    if (action.updatedData && action.updatedData.length > 0) {
      let packetPlansData = action.updatedData;
      let allPlans = [...state.packetPlans];

      let modifiedPlan = allPlans.map((plan) => {
        let p = { ...plan };
        let filterPlan = packetPlansData.filter((x) => x.packetPlan == p.id);
        if (filterPlan.length > 0) {
          let planId = filterPlan[0].packetPlan;
          let remark = filterPlan[0].remark;
          if (p.id == planId) {
            p['remarks'] = remark;
          }
        }
        return p;
      });
      return {
        ...state,
        packetPlans: modifiedPlan,
      };
    }
  }),
  on(SignerAction.addDeptToReviewStone, (state, action) => {
    console.log('action', action, action.payload);
    const key = action.tabId;
    const arr = action.payload;
    const res = {};
    res[key] = arr;

    return {
      ...state,
      reviewStoneDept: { ...state.reviewStoneDept, ...res },
    };
  }),

  // on(SignerAction.saveReviewStoneData, (state, action) => {
  //     return {
  //         ...state,
  //         reviewStoneId: action.reviewData
  //     };
  // }),
  on(SignerAction.saveFileDetails, (state, action) => {
    console.log(action);
    let obj = { ...state.fileDetails };
    let newKeys = Object.keys(obj);
    let bool;
    if (newKeys.length) {
      bool = newKeys.some((key: any, index: number) => {
        if (key === action.tabId) {
          return true;
        }
        return false;
      });
    }

    if (bool) {
      obj[action.tabId] = action.payload;
      return {
        ...state,
        fileDetails: obj,
      };
    }
    return {
      ...state,
      fileDetails: { ...obj, ...action.payload },
    };
  }),

  on(SignerAction.saveOrderForm, (state, action) => {
    console.log(action.orderForm);
    return {
      ...state,
      orderForm: action.orderForm,
    };
  }),

  on(SignerAction.setSelectedEcsvOption, (state, action) => {
    console.log(action.value);
    return {
      ...state,
      ecsvValue: action.value,
    };
  }),
    on(SignerAction.savePacketPlansForReconfirmBidPlan, (state, action) => {
      console.log(action);
      
    return {
      ...state,
      reconfirmBidData: {
         packetPlans: action.reconfirmBidData.packetPlans,
      planData: action.reconfirmBidData.planData
      },
    };
  }),
  on(SignerAction.savePktPlanRemarks, (state, action) => {
    console.log('showSelectedProgram', action.orders);
    let allPlans = [...state.packetPlans];
    let modifiedPlan = allPlans.map((plan) => {
      let p = { ...plan };
      p.packetPlanStones = p.packetPlanStones.map((ps) => {
        let planStone = { ...ps };
        if (planStone.id == action.pktPlanStoneId) {
          planStone.orders = planStone.orders.map((psorder) => {
            let order = { ...psorder };
            let findOrderRemark = action.orders.filter((x:any)=>x.orderId == order.id)[0]['remark']
           
            //order['remark'] = action.remark;

            return order;
          });
        }
        return planStone;
      });
      return p;
    });
    console.log(modifiedPlan);
    return {
      ...state,
      packetPlans: modifiedPlan,
    };
  }),
  on(SignerAction.orderMarkedOnPlanStone, (state, action) => {
    let allPlans = [...state.packetPlans];
    let modifiedPlan = allPlans.map((plan) => {
      let p = { ...plan };
      p.packetPlanStones = p.packetPlanStones.map((ps) => {
        let planStone = { ...ps };
        if (planStone.id == action.pktPlanStoneId) {
          planStone.orders = planStone.orders.map((psorder) => {
            let order = { ...psorder };
            if (action.orderId == order.id) {
              order['isMarked'] = true;
            }
            return order;
          });
        }
        return planStone;
      });
      return p;
    });
    console.log(modifiedPlan);
    return {
      ...state,
      packetPlans: modifiedPlan,
    };
  })
);
