import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NewDropDownModule } from 'src/app/components/new-drop-down/new-drop-down.module';
import { DynamicModalComponent } from './dynamic-modal.component';
import { LoadingModule } from 'src/app/loading/loading.module';

@NgModule({
  declarations: [DynamicModalComponent],
  entryComponents: [],
  exports: [DynamicModalComponent],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NewDropDownModule,
    LoadingModule
  ],
})
export class DynamicModalModule {}
