import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-scannerinput',
  templateUrl: './scannerinput.component.html',
  styleUrls: ['./scannerinput.component.scss'],
})
export class ScannerinputComponent implements OnInit {
  scanForm: any;
  @Input() clearData: string;
  @Input() page: string;
  @Output() barcodeDetectedEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openModalEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.scanForm = new FormGroup({
      barcode: new FormControl(''),
    });
  }

  ngOnInit() { }

  packetScanned(evt) {
    let barcode = this.scanForm.value.barcode;
    this.barcodeDetectedEvent.emit(barcode);
    if (this.page === 'TallyStock') {
      this.cancel();
    }
  }
  cancel() {
    this.onResetForm();
    this.cancelEvent.emit();
  }

  onResetForm() {
    this.scanForm.reset();
  }

  openModal() {
    this.openModalEvent.emit();
  }
}
