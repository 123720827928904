import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { DxSelectBoxModule } from 'devextreme-angular';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { NgxFormModule } from '../components/ngx-form/ngx-form.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { twoDecimalvaluesPipeModule } from '../pipes/two-decimal-values/two-decimal-values.module';
import { MyTaskForVirtualComponent } from './my-task-for-virtual.component';
import { HeaderComponentModule } from '../components/header/header.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';



@NgModule({
  declarations: [MyTaskForVirtualComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // HeaderComponentModule,
    DataGridComponentModule,
    NgxFormModule,
    DropdownModule,
    ScannerinputModule,
    HeaderComponentModule,
    WidthAlertModule,
    NgSelectModule,
    DropdownModule,
    DxSelectBoxModule,
    twoDecimalvaluesPipeModule,
  ],
  exports: [MyTaskForVirtualComponent],
})
export class MyTaskForVirtualModule { }
