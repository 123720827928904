import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-delete-button',
  template: `
  <button class="deleteBtn" (click)="onChange($event)">Delete</button>
  <!-- <div style="float:right;"> 
  <ion-icon name="attach-outline" class="file-icon"  ></ion-icon>
  </div>
  <div style="float:left;margin-left:10px;" class="labelDiv"> 
  <label for="file-upload" class="custom-file-upload pl-10" (click)="fileInput.click()"  >
 {{attachFile}}
</label>
</div>
    <input type="file" (change)="onChange($event)" #fileInput [formControl]="formControl" [formlyAttributes]="field" multiple> -->
  `,
  styles: [
    `.custom-file-upload {
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
      /* width:100%; */
      width: 325px;
      /* width: 382px; */
      color:gray;
      /* font-size:25px */
      font-size:1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

  }
  .file-icon{
    font-size:25px;
  }
  .labelDiv{
    width:100px;
    padding-left:10px;
  }
  input[type="file"] {
    display: none;
    transform: scale(1.4,1.4);
} 
.deleteBtn{
  background-color:red;
  min-height:45px;
  min-width:70px;
  color:white;
  border-radius : 10px
}`

  ]
})
export class FormlyFieldDeleteBtn extends FieldType {
  formControl: FormControl;
  selectedFiles: File[]
  fileNames: any = [];
  attachFile: string = "";//Attach a File text

  onChange(ec) {    
    if(this.to?.click)
    this.to.click(ec);
  }
}