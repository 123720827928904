import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { authUser } from 'src/app/authStore/auth.selector';
import { AppState } from 'src/app/reducers';

@Component({
  selector: 'app-chat-alert-model',
  templateUrl: './chat-alert-model.component.html',
  styleUrls: ['./chat-alert-model.component.scss'],
})
export class ChatAlertModelComponent implements OnInit {

  constructor(
    private store: Store<AppState>
  ) { }
  @Input() data: any;
  userName: any;

  ngOnInit() {
    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        console.log(d);
        this.userName = d;
        console.log(this.userName);
      }
    });
  }
}
