import { AfterViewInit, Component, Input, OnChanges, OnInit, SecurityContext, SimpleChange } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { ConfigService } from "../services/config/config.service";
import { LoaderService } from "../services/loader/loader.service";
import { ModalController, NavParams, PopoverController } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getCompletedPacketsData, getPendingPacketsData } from "../home/state/home.selector";
import { HomeActions } from "../home/state/home.action-type";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { takeUntil } from "rxjs/operators";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { DomSanitizer } from "@angular/platform-browser";
import { PopoverUserComponent } from "../components/popover-user/popover-user.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-rvverify",
  templateUrl: "./rvverify.component.html",
  styleUrls: ["./rvverify.component.scss"],
})
export class RvverifyComponent implements OnChanges {
  rowData: any;
  rvActionData;
  private unsubscribe$: Subject<any> = new Subject<any>();
  currentIndex = 0;
  packetData;
  actionStatus: string = "";
  isCleaning: any;
  hideHeader: boolean = false;
  dropdown: any = {};
  issuePacketId: any;
  videoOfTension: any = "";
  isVirtual: number = 0;
  packetIDUrl: string = "";
  isCountShown: boolean = false;
  selfIssuePacketId: any;
  packetCount: number;
  selfIssueNoFilterId: any;
  wholeDataOfCompleted: any;
  selectedPacketNoInCompleted: any;
  rvCheckStatus: any;
  @Input() data: any;
  dropDownCopyPacketData: any;
  packetDataArrList: any = false;
  isButtonDisabled: boolean = true;
  rvVerify: any;
  // @Input() forPage: any = '';

  private subscriptions: Subscription[] = [];
  isUpdateDisable: boolean = true;
  dropdownCleaning: any = {
    name: "cleaning",
    placeText: "Select",
    options: [
      {
        id: 1,
        name: "yes",
      },
      {
        id: 2,
        name: "No",
      },
    ],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  // ************************
  isFilter: boolean = false;
  finalSelection: any;
  shownSelection: any = "";
  dropDownLotData: any = {
    name: "lotNo",
    placeText: "lot no.",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownPacketData: any = {
    name: "PacketNo",
    placeText: "Pacekt No.",
    options: [],
    selectedData: [],
    key: "packet",
    modeFlag: "single", //single/multiple
  };
  unique: any = [];
  packetName: any = [];
  arrayOfPacketData: any = [];
  remark: any;

  constructor(
    private httpService: HttpService,
    private _ConfigService: ConfigService,
    public loaderService: LoaderService,
    public store: Store,
    public router: Router,
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    public popoverController: PopoverController
  ) {}

  ngOnChanges(changes): void {}

  ngOnInit() {
    // console.log(

    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          console.log("Selected action");
          console.log(action);
          this.rvActionData = action;
          this.actionStatus = action.status;
        }
      });

    this.store.dispatch(
      HomeActions.getActionNStatusWisePacketList({
        actionId: 311,
        status: this.rvActionData.status,
      })
    );
    if (this.actionStatus == "Pending") {
      if (this.rvActionData.status == "Pending") {
        this.subscriptions.push(
          this.store
            .pipe(select(getPendingPacketsData(this.rvActionData.id)))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
              if (data) {
                this.data = data;
                this.packetData = Object.values(data);
                this.dropDownCopyPacketData = Object.values(data);
                console.log(this.packetData);
                let lotNo = this.packetData.map(item => item.lotNo);
                console.log(lotNo);

                this.unique = Array.from(new Set(lotNo));
                console.log(this.packetData);
                this.dropDownPacketData.options = this.unique;
                if (this.actionStatus == "Pending") {
                  this.fetchVideoOfTension();
                  this.unsubscribe();
                }
              }
            })
        );
      }
    } else if (this.rvActionData.status == "Completed") {
      this.subscriptions.push(
        this.store
          .pipe(select(getCompletedPacketsData(this.rvActionData.id)))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            if (data) {
              this.data = data;
              this.packetData = Object.values(data);
              this.dropDownCopyPacketData = Object.values(data);
              console.log(this.packetData);
              this.wholeDataOfCompleted = this.packetData;
              console.log(this.wholeDataOfCompleted);
              let lotNo = this.packetData.map(item => item.lotNo);
              console.log(lotNo);

              this.unique = Array.from(new Set(lotNo));
              console.log(this.packetData);
              this.dropDownPacketData.options = this.unique;
              //  this.stressName = this.packetData[0]?.inputDetails[0]?.paramSValue;
              //  this.stressValue = this.packetData[0]?.inputDetails[1]?.paramSValue;
              if (this.actionStatus == "Completed") {
                // this.dropdownCleaning.selectedData=this.packetData[0].inputDetails[0].paramSValue;

                // if(!!this.packetData[0].inputDetails){

                this.rvCheckStatus = this.packetData[0]?.inputDetails[this.packetData[0]?.inputDetails?.length - 1]?.paramSValue;
                this.rvVerify = this.rvCheckStatus;
                this.fetchVideoOfTension();
                this.unsubscribe();
              }
            }
          })
      );
    }

    console.log(this.packetData);

    //   this.dropdownCleaning.options=[{
    //     id:1,
    //     name:"yes"
    //   },
    // {
    //   id:2,
    //   name:"No"
    // }]
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
        this.isCleaning = evt.arrayList[0].name;
        console.log(this.isCleaning);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.selectedPacketNoInCompleted = this.dropdown.lotNo;
    console.log(this.selectedPacketNoInCompleted);
    // this.isCleaning = this.dropdown.lotNo.name;
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }

  onRvSelection(event) {
    console.log(event);
    this.rvVerify = event.detail.value;
    if (this.rvVerify == "No") {
      console.log("user selected No");
      if (this.actionStatus == "Pending") {
        this.remarkModal();
      }
    }
  }

  dropDownSelection(evt: any, plan: any) {
    // this.arrayOfPacketData = [];

    console.log("dropDownSelection");
    console.log(evt);

    this.finalSelection = evt.arrayList[0];

    if (evt.property == "PacketNo") {
      if (evt.arrayList.length === 0) {
        this.dropDownLotData.options = [];
        this.isButtonDisabled = true;
        this.isCountShown = false;
      } else {
        this.isCountShown = true;
      }
      // this.dropDownPacketData.selectedData = [];
      this.dropDownLotData.selectedData = [];
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);

      let map = new Map();
      for (let item of this.dropDownCopyPacketData) {
        let lotNo = item.lotNo;
        if (map.has(lotNo)) {
          let data = map.get(lotNo);
          data.push(item.packetId);
          map.set(lotNo, data);
        } else {
          map.set(lotNo, [item.packetId]);
        }
      }

      console.log(map);

      // arrayOfPacketData: any = [];
      Array.from(map)
        .map(([name, value]) => ({ name, value }))
        .filter((data: any) => {
          if (evt.arrayList[0] === data.name) {
            this.dropDownLotData.options = data.value;
            this.packetCount = this.dropDownLotData.options.length;
            return true;
          }
          return false;
        });

      console.log(this.arrayOfPacketData);
      this.packetName = this.arrayOfPacketData;
      console.log(this.dropDownLotData.selectedData);
    } else if (evt.property == "lotNo") {
      if (evt.arrayList.length == 0) {
        this.isButtonDisabled = true;
      } else {
        this.isButtonDisabled = false;
      }
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);
    } else if (evt.property == "cleaning") {
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);
      console.log(this.dropdownCleaning);
    }
  }

  showNextArray(): void {
    if (this.isFilter != true) {
      if (this.currentIndex === this.packetData.length - 1) {
        return; // Stop if last index is reached
      }
      this.currentIndex++;
    }
    this.fetchVideoOfTension();
  }

  onSaveClick() {
    console.log(this.dropdownCleaning.selectedData);

    console.log(this.packetData);
    console.log(this.packetData[0].packetId);
    let listOfParameter = [];
    if (this.rvVerify == "yes") {
      this.isCleaning = {
        parameterName: "isRvCheck",
        parameterValue: this.rvVerify,
      };
    } else if (this.rvVerify == "No") {
      this.isCleaning = {
        parameterName: "remark",
        parameterValue: this.remark,
      };
    }
    console.log(this.isCleaning);
    // let stressVal= {
    //   "parameterName": "Stress Value",
    //   "parameterValue": this.stressValue
    // }
    listOfParameter.push(this.isCleaning);
    // listOfParameter.push(stressVal)
    let formType = "Multipart";
    let barcodeObj = {
      packetId: this.packetData[this.currentIndex].packetId,
    };
    let obj = {
      listOfAllPackets: [
        {
          packetId: this.packetData[this.currentIndex].id,
          listOfParameter,
        },
      ],
      actionId: this.rvActionData.id,
    };
    if (this.isFilter == true) {
      this.currentIndex = 0;
      this.packetData = this.data;
      console.log(this.data);
      this.dropDownLotData.selectedData = [];
      this.dropDownPacketData.selectedData = [];
    } else if (!!obj) {
      this.selfIssueNoFilterId = obj;
    }
    if (this.currentIndex === this.packetData.length - 1) {
      this.router.navigate(["/home"]);
    }
    this.saveInputParameer(obj);
    // this.store.dispatch(
    //   masterDataActions.saveStoneParamater({
    //     parameterDetails: obj,
    //     actionId: this.rvActionData.id,
    //     formType: 'JSON',
    //     barcodeObj: barcodeObj,
    //     actionInfo: this.rvActionData.name,
    //   })
    // );
  }

  async saveInputParameer(obj) {
    // const dynamicVal = this.arrayOfObjects.map((item) => {
    //   return Object.entries(item).map(([key, value]) => ({
    //     'parameterName': key,
    //     'parameterValue': value
    //   }));
    // }).flat();
    // console.log(dynamicVal);
    let payload = obj;
    let response = await this.httpService.postDataUsingPromise(true, `processActionInput/inputReceiverByActionId/${this.rvActionData.id}`, payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      if (this.rvVerify == "yes") {
        this.selfIssueAndReceive(this.packetData);
      }
      this.showNextArray();
      console.log(response.data);
      let data = response.message;
      console.log(data);
    } else {
      console.error(response);
    }
  }

  async selfIssueAndReceive(packetData) {
    let payload = {};
    if (this.isFilter == true) {
      console.log(this.selfIssuePacketId);

      const packetIdToFilter = this.selfIssuePacketId; // Specify the packetId to filter

      const filteredIds = packetData.filter(obj => obj.packetId === packetIdToFilter).map(obj => obj.id);
      let payload = {
        packetIds: filteredIds,
      };

      console.log(payload);
    } else {
      let nonFilterId = this.selfIssueNoFilterId.listOfAllPackets[0].packetId;
      payload = {
        packetIds: [nonFilterId],
      };
    }

    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet/selfIssueAndReceiveForStn", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dropdownCleaning.selectedData = [];
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this._ConfigService.showToast("success", data);
    } else {
      console.error(response);
      this._ConfigService.showToast("error", response.error.message);
    }
  }

  async fetchVideoOfTension() {
    if (!!this.packetData) {
      console.log(this.packetData);
      console.log(this.currentIndex);
      console.log("fetchAllActions");
      const packetID = this.packetData?.[this.currentIndex]?.id;
      if (packetID) {
        let response: any = await this.httpService.fetchDataOfTensionFile(true, `processActionInput/getFileThroughCentralSystem/${packetID}`);
        console.log(response);
        const barcode = this.httpService.getErrorAndDisplayIt(response);
        const self = this;
        if (response.statusCode === 200) {
          if (response.data.filePath.length != 0) {
            const filePath = response.data;
            let newFileName = filePath.filePath[0];
            newFileName = newFileName.split("/");
            let joinedPath = newFileName.slice(1).join("/");
            setTimeout(function () {
              self.videoOfTension = self.domSanitizer.bypassSecurityTrustResourceUrl(`${self._ConfigService.getCentralFileUrl() + newFileName[0] + "/"}${encodeURIComponent(joinedPath)}`);
            }, 1000);
          } else {
            this._ConfigService.showToast("error", "File Not Available");
            this.videoOfTension = null;
          }
        } else {
          console.error(response);
        }
      }
    }
  }

  unsubscribe() {
    // Unsubscribe from all subscriptions in the array
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  getData(e) {
    console.log(e);
    this.isFilter = true;
    console.log(this.dropDownPacketData.options);
    //  this.packetData[0].packetId = this.finalSelection =this.shownSelection;

    //  let target = ;
    //  let temp:any = {}
    //  for(let item of (this.data)){
    //      if(item.packetId == target){
    //          temp = item
    //          break
    //      }
    //  }

    this.shownSelection = this.finalSelection;
    console.log(this.dropDownPacketData.selectedData);
    console.log("temp", this.shownSelection);
    console.log(this.finalSelection);
    this.selfIssuePacketId = this.finalSelection;
    const desiredId = this.finalSelection;

    // const desiredObject = [
    //   this.packetData.find((obj) => obj.packetId === desiredId),
    // ];
    const desiredObject = [this.dropDownCopyPacketData.find(obj => obj.packetId === desiredId)];
    console.log(desiredObject);
    this.packetData = desiredObject;
    console.log(desiredObject);
    this.packetData = desiredObject;

    console.log(this.packetData);

    console.log(this.wholeDataOfCompleted);
    console.log(this.selectedPacketNoInCompleted);

    // const desiredPacketId = "10645-12-DIAVIK#57";

    if (!!this.packetData[0].inputDetails) {
      const filteredData = this.wholeDataOfCompleted.filter(item => item.packetId === this.selectedPacketNoInCompleted);

      this.rvCheckStatus = filteredData[0].inputDetails[filteredData[0].inputDetails.length - 1].paramSValue;
      console.log(this.rvCheckStatus);
      this.rvVerify = this.rvCheckStatus;
    }

    this.fetchVideoOfTension();
  }

  async remarkModal() {
    console.log("-------onUpdate......");
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "rvVerify",
      componentProps: { id: 1, forPage: "rvVerifyRemark" },
    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      if (res.data) {
        console.log(res.data);
        this.remark = res.data.remark;
      }
    });
  }
}
