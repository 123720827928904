import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { AppState } from 'src/app/reducers';

@Component({
  selector: 'app-action-operations-popup',
  templateUrl: './action-operations-popup.component.html',
  styleUrls: ['./action-operations-popup.component.scss'],
})
export class ActionOperationsPopupComponent implements OnInit {

  constructor(
    private popoverController: PopoverController,
    public navParams: NavParams,
    private store: Store<AppState>,
  ) { }

  isFixing:boolean = false;
  isMakable:boolean[] = [];
  showWeights: boolean = false;
  selectedRows: any[] = [];
  title:string = 'Fixing';
  weightData: any = {};
  actionId: any;
  actionInfo: any;

  ngOnInit() {
    this.navParams.data;
    if(this.navParams.data.showWeights){
      this.showWeights = true;
      this.selectedRows = this.navParams.data.selectedRows;
      this.actionId = this.navParams.data.actionId;
      this.actionInfo = this.navParams.data.actionInfo;
      for(let i=0; i<this.selectedRows?.length; i++){
        this.weightData[this.selectedRows[i].id] = this.selectedRows[i].weight;
      }
      if (this.navParams?.data?.title)
      this.title = this.navParams.data.title;
    }
    if (this.navParams?.data?.actionInfo) {
      this.title = this.navParams.data.actionInfo.name
      
    }
  }


  closeModal(data?:any){
    if(data && this.showWeights)
    this.saveWeights();
    if(data)
    this.popoverController.dismiss(this.isFixing);
    else
    this.popoverController.dismiss();
  }

  checkboxChange(event:any){
    this.isFixing = event.target.checked;
  }

  makablecheckboxChange(event:any, index){
    this.isMakable[index] = event.target.checked;
    console.log(index)
  }
  saveWeights(){
    const listOfAllPackets = this.selectedRows.map((row, index) => {
      if (this.weightData[row.id]) {
        return {
          packetId: row.id,
          isMarkAsPolish: this.isMakable[index] ?? false,
          listOfParameter: [
            {
              parameterName: "weight",
              parameterValue: this.weightData[row.id]
            }
          ]
        };
      }
      return null;
    }).filter(packet => packet !== null);


    if(!listOfAllPackets?.length){
      return;
    }
    const parameterDetails = {
      listOfAllPackets
    };
    console.log(parameterDetails);
    this.store.dispatch(
      masterDataActions.saveStoneParamater({
        parameterDetails,
        actionId: this.actionId,
        formType: "JSON",
        barcodeObj: {},
        actionInfo: this.actionInfo,
      })
    );
  }
}
