import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AccordionModule } from 'ngx-accordion';
import { ParamhistoryComponent } from './paramhistory.component';


@NgModule({
  imports: [
    CommonModule,
    AccordionModule,
    IonicModule,
  ],
  declarations: [
    ParamhistoryComponent,
  ],
  entryComponents: [
    ParamhistoryComponent,
  ],
  exports: [ParamhistoryComponent],
  providers: []
})
export class ParamhistoryModule { }
