import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NavParams } from '@ionic/angular';

export interface popoverProps{
  template:TemplateRef<any>,
  alignment:'start' | 'center' | 'end',
  triggerId:string,
  side:'top' | 'right' | 'bottom' | 'left' | 'start' | 'end',
}

@Component({
  selector: 'app-dynamic-popover',
  templateUrl: './dynamic-popover.component.html',
  styleUrls: ['./dynamic-popover.component.scss'],
})


export class DynamicPopoverComponent implements OnInit {

  popoverProps:popoverProps

  constructor(private navParams:NavParams) {
    this.popoverProps = this.navParams.get('data')
   }

  ngOnInit() {

    console.log(this.popoverProps)

  }

}
