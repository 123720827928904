import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { DynamicModalComponent } from 'src/app/transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-partyconfig',
  templateUrl: './partyconfig.component.html',
  styleUrls: ['./partyconfig.component.scss'],
})
export class PartyconfigComponent implements OnInit {
  @ViewChild('partyconfigmodal') partyconfigmodal: ElementRef;
  @ViewChild('detailModal') detailModal: ElementRef;

  columns :any[] = [
    { name: 'Action Name', prop: 'jobProcessName'},
    { name: 'Account Numbers', prop: 'accountNumbers'},
    { name: 'createdBy', prop: 'createdBy'},
  ];

  detailColumns: any[] = [
    { name: 'Account No', prop: 'accountNo' },
    { name: "Active", prop: "isActive", columnTemplate: "isActive" },
  ];

  companyOptions: any;
  jobOptions: any;
  dataSource: any = [];
  selectedCompany: any;
  selectedJobProcess: any;
  accountNo: string;
  refreshGrid: boolean = false;
  isActive: boolean = false; // New property for toggle button
  selectedDetail: any = {}; // Property to hold selected row details
  accountData :any = {}; // Property to hold
  selectedRow: any;



  constructor(
    public httpService: HttpService,
    public http: HttpClient,
    private modalCtrl: ModalController,
    private loaderService: LoaderService,
    public configService: ConfigService,
    private alertCtrl: AlertController

  ) { }

  ngOnInit() {
    this.getAllCompanyData();
    
  }


  async getAllCompanyData() {
    try {
      
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "tbl-company/findAll").toPromise();
      
      if (!!data) {
        this.companyOptions = data?.data;
      }
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
    finally {
      
    }
  }


  async onCompanySelection(event: any) {
    this.selectedCompany = event;
    if (!event) {
      this.jobOptions = [];
      this.dataSource = [];
      return;
    }
    if (!!event) {
      try {
        
        const data = await this.httpService.getDataWithoutPayloadForGet(true, `job-process/getJobProcessOnBasisOfCompany?partyId=${event}`).toPromise();
        
        if (!!data) {
          this.jobOptions = data?.data;
          this.fetchGridData(event);
        }
      } catch (error) {
        console.error("Error fetching worker data:", error);
      }
      finally {
        
      }
    }
    console.log("jobOptions", this.jobOptions);
  }

  async fetchGridData(event:any) {

    try {
      
      let endpoint: string = `job-process/getJobProcessAndAccountsByCompany?partyId=${event}`;
      const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
    
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
  
        if (resultCode === 200) {
          console.log(res.data)
          if (res.data.length === 0) {
            this.configService.showToast('error', 'No Data Available');
          }
          this.dataSource = res.data;
  
          } else {
            this.configService.showToast('error', res.error);
          }
      }
    } catch (error) {
      // console.error('An error occurred:', error);
      this.configService.showToast('error', 'Something went wrong!');
    } finally {
      
    }
  }

  async onSave() {
    const payload = {
      accountNo: this.accountNo,
      jobProcessId: this.selectedJobProcess,
      isActive: this.isActive ? 1 : 0
    };
    console.log("payload",payload)
    try {
      
      const res = await this.httpService.postDataUsingPromise(true, 'job-process-account/save', payload)
      
      if (res && res.statusCode === 200) {
        this.configService.showToast('success', 'Data saved successfully');
        this.modalCtrl.dismiss();
        this.fetchGridData(this.selectedCompany); 
        this.refreshGrid = true;

      
      } else {
        this.configService.showToast('error', res.error || 'Error saving data');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      this.configService.showToast('error', 'Something went wrong!');
    } finally {
      
    }
  }



  async openPartConfigModal(event){
    this.selectedJobProcess = null;
    this.accountNo = null;
    const modal = await this.modalCtrl.create({
      
      component: DynamicModalComponent,
      componentProps: {
        template:this.partyconfigmodal,
      },
      cssClass: 'partyconfigmodal',
    });
    await modal.present();
  }

  closeModal(){
    // this.selectedJobProcess = null;
    this.modalCtrl.dismiss();
    this.refreshGrid = true;

  } 


  onSelectionChange(event){
    this.selectedRow = event.row;
    if(!!this.selectedRow){
      this.openDetailModal(event);
    }
  }
  async openDetailModal(event:any) {
    this.selectedDetail = event;
    // if(this.selectedDetail.)
    const modal = await this.modalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.detailModal,
        accountData: [...this.selectedDetail?.row?.accountData],  
      },
      cssClass: 'detailModal',
    });

    modal.onDidDismiss().then((d: any) => {
      this.fetchGridData(this.selectedCompany); 
    });

  return await modal.present();
  
}
async isActiveChange(event: any) {

  const { data } = event.row;

  const alert = await this.alertCtrl.create({
    header: 'Warning',
    message: `Do you want to ${data?.isActive ? 'deactivate' : 'activate'} this Account No?`,
    cssClass: "planAlertBox",
    buttons: [
      {
        text: 'No',
        role: 'cancel',
        handler: () => {
          // data.isActive = !data.isActive;
           this.modalCtrl.dismiss();
        },
      },
      {
        text: 'Yes',
        handler: async () => {
          try {
            
            const res: any = await this.httpService.getDataWithoutPayloadForGet(true, `job-process-account/changeAccountStatus?accountId=${event.data.accountId}`).toPromise();
            
            if (res && res.statusCode === 200) {
              this.configService.showToast('success', 'Status updated successfully');
              this.fetchGridData(this.selectedCompany);
            } else {
              this.configService.showToast('error', res.error || 'Error updating status');
            }
          } catch (error) {
            console.error('An error occurred:', error);
            this.configService.showToast('error', 'Something went wrong!');
          } finally {
            
          }
        },
      },
    ],
  });

  await alert.present();
}

}
