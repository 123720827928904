import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { TrybemodalComponent } from '../components/trybemodal/trybemodal.component';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { PopoverUserComponent } from '../components/popover-user/popover-user.component';
import * as moment from 'moment';
import { ConfigService } from '../services/config/config.service';
import { AppState } from '../reducers';
import { Store, select } from '@ngrx/store';
import { getCurrentAction } from '../tempDataStore/tempData.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tally-manager-view',
  templateUrl: './tally-manager-view.component.html',
  styleUrls: ['./tally-manager-view.component.scss'],
})
export class TallyManagerViewComponent implements OnInit {
  dataSource:any;
  columns:any;
  VoucherLabel: any;
  showModal = false;
  columns2:any;
 currentDate = new Date();
  fromDate:any=moment().format('YYYY-MM-DD');
  toDate:any=moment().format('YYYY-MM-DD');
  packetDataSource:any;
  VoucherId: any;
  voucherCreatedDate:any
  departmentName: string = '';
  actionName:any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  constructor(
    public httpService:HttpService,
    public modalCtrl: ModalController,
    public popUpCtrl:PopoverController,
    public config:ConfigService,
    private store: Store<AppState>,
    public _ConfigService: ConfigService,

  ) {
    this.columns = [
      { name: "Dept", prop: "departmentName" },
      { name: "isMatch", prop: "matched",columnTemplate: 'chipTemplate' },
      { name: "Voucher", prop: "voucherLabel" },
      { name: "CreatedDate", prop: "createdDate" },

    ]

    this.columns2 = [
      { name: "Packet", prop: "packetName" },
      { name: "Status", prop: "matchedShowValue" },
      { name: "Department Name", prop: "currDept" },
      { name: "User", prop: "currUser" },
      { name: "Voucher ", prop: "voucherLabel" },
      { name: "Reference voucher", prop: "refVoucherLabel" }
    ]
   }

  ngOnInit() {
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.actionName = action?.name;
          if(this.actionName?.toLowerCase() == 'admin tally'){
            this.columns2.push(
              { name: "User", prop: "currUser" },
              { name: "Matched", prop: "matchedShowValue", columnTemplate: 'chipTemplate' }
            );
          }
        }
        this.fetchGridData();
      });
  }


  onSelectionChanged(event){}

   openModal(data) {
    this.showModal = true;
    this.httpService.getDataWithoutPayloadForGet(true, `tally-stock-logs/getByVoucherId?voucherId=${data}`).subscribe(data => {
      this.packetDataSource = (data.data || []).map((dt: any) => ({
        ...dt,
        matchedShowValue: dt.isMatch === 1 ? 'Matched' : dt.isMatch === 0 ? 'Out Of Department' : 'Not Scanned'
      }));
    });
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  closeModal(event?: MouseEvent) {
    this.showModal = false;
    if (event && event.target && event.target['classList'] && event.target['classList'].contains('modal-overlay')) {
      this.showModal = false;
    }
  }

  VoucherModal(event){
    this.VoucherLabel = event?.data?.voucherLabel;
    this.VoucherId = event?.data?.voucherId;
    this.voucherCreatedDate = moment(event?.data?.createdDate).format('DD-MM-YYYY');
    this.departmentName = event?.data?.departmentName || '';
    this.openModal(this.VoucherId);
  }


  fetchGridData(){
    this.httpService.getDataWithoutPayloadForGet(true, `tally-stock-logs/getVoucherWiseLogsOfCurrDate?fromDate=${this.fromDate}&toDate=${this.toDate}`).subscribe(
      (data) => {
        console.log(data);
        if(data.statusCode ===400){
          this.config.showToast(false,data?.message)
        }
        else{
          this.dataSource = data.data;
        }
      },
      (error) => {
        console.error('Error:', error);
    
      }
    );
  }

  downloadExcel(){
    let fileName = `${this.VoucherLabel}_${this.voucherCreatedDate}`
    let excelData = this.packetDataSource?.map((pds,i) => {
      let ex = { 
        "sr No" : i + 1,
        'Packet' : pds.packetName,
        'Status' : pds.matchedShowValue,
        'Department Name' : pds.currDept,
        'User' : pds.currUser
      }
      return ex;
    })
    this._ConfigService.createExcel(excelData, fileName);
  }
  
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
