import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TempDataState } from "./tempData.state";


// debugger
export const selectTempDataState = createFeatureSelector<TempDataState>(
    'tempData',
);

export const getCurrentAction = createSelector(selectTempDataState, state => {
    return state.currentAction;
});

export const getCurrentSearchData = createSelector(selectTempDataState, state => {
    return state.currentSearchData;
});
export const getCurrentRowData = createSelector(selectTempDataState, state => {
    return state.rowData;
});
export const getChatData = createSelector(selectTempDataState, state => {
    return state.chatData;
});
export const getidsForOrderForm = createSelector(selectTempDataState, state => {
    return state.ids;
});