import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ScannerinputComponent } from './scannerinput.component';



@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ScannerinputComponent,
  ],
  entryComponents: [
    ScannerinputComponent,
  ],
  exports: [ScannerinputComponent],
  providers: []
})
export class ScannerinputModule { }
