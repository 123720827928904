import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { ConfigService } from "../services/config/config.service";
import { LoaderService } from "../services/loader/loader.service";
import { Store, select } from "@ngrx/store";
import { ModalController, PopoverController } from "@ionic/angular";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { takeUntil } from "rxjs/operators";
import * as XLSX from "xlsx";
import { Subject, Subscription } from "rxjs";
import { HomeActions } from "../home/state/home.action-type";
import { getCompletedPacketsData, getPendingPacketsData, getUrlsOfStnFilesfromIdsSelector } from "../home/state/home.selector";
import { writeXLSX } from "xlsx";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import JSZipUtils from "jszip-utils";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { PopoverUserComponent } from "../components/popover-user/popover-user.component";
import { printData } from "../masterDataStore/master.data";

@Component({
  selector: "app-dbc-scanning",
  templateUrl: "./dbc-scanning.component.html",
  styleUrls: ["./dbc-scanning.component.scss"],
})
export class DbcScanningComponent implements OnInit, OnDestroy {
  columns: any[];
  private unsubscribe$: Subject<any> = new Subject<any>();
  private urlsListSelector$: Subject<any> = new Subject<any>();
  dbcActionData: any;
  private subscriptions: Subscription[] = [];
  subscription: any;
  actionStatus: string = "";
  addDataForExcel: any;
  excelDataSource: any;
  dataSource: any;
  isCompletedData: any;
  isVoucherLimitExceded: boolean = false;
  selectedRows: any = [];
  selectedRow: any = [];
  infoToPrint: Object = {};
  printData: any = printData;
  packetIdsPayload: any;
  listOfUrlsForStl: any[] = [];
  trybeUrl: any;
  selectedPartyId: any;
  remarkList: any;
  centralBaseUrl: any;
  private latestRes: any[] = [];
  refreshGrid: boolean = false;
  private destroy$ = new Subject<void>();
  constructor(
    private httpService: HttpService,
    private modalController: ModalController,
    private _ConfigService: ConfigService,
    public loaderService: LoaderService,
    public store: Store,
    public popoverController: PopoverController,
    private modalCtrl: ModalController,
    public configService: ConfigService
  ) {
    this.trybeUrl = this.configService.getTrybeBaseUrl();
    this.centralBaseUrl = this.configService.getCentralFileUrl();
  }

  ngOnInit() {
    this.fetchRemarks();
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          console.log("Selected action");
          console.log(action);
          this.dbcActionData = action;
          this.actionStatus = action.status;
          console.log(this.actionStatus);
        }
      });

    this.columns = [
      { name: "Lot No", prop: "lotNo", columnTemplate: "lot-svg-template" },
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      { name: "Current Action", prop: "currActionName" },
      { name: "Dbc Remarks", prop: "orderRemark" },
    ];

    this.store.dispatch(
      HomeActions.getActionNStatusWisePacketList({
        actionId: 145,
        status: this.dbcActionData.status,
      })
    );

    if (this.actionStatus == "Pending") {
      if (this.dbcActionData.status == "Pending") {
        this.subscriptions.push(
          this.store
            .pipe(select(getPendingPacketsData(this.dbcActionData.id)))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
              if (data) {
                console.log(data);
                this.dataSource = data;
              }
            })
        );
      }
    } else if (this.dbcActionData.status == "Completed") {
      this.subscriptions.push(
        this.store
          .pipe(select(getCompletedPacketsData(this.dbcActionData.id)))
          .pipe(takeUntil(this.unsubscribe$))

          .subscribe(data => {
            if (data) {
              console.log(data);
              this.dataSource = data;
              this.isCompletedData = data;
              console.log(this.isCompletedData);
            }
            if (this.actionStatus == "Completed") {
              // this.getExcelData(this.isCompletedData);
              this.unsubscribe();
            }
          })
      );
    }

    this.store.pipe(select(getUrlsOfStnFilesfromIdsSelector), takeUntil(this.urlsListSelector$)).subscribe((res: any) => {
      if (!!res && !!res?.data) {
        if (!!res.packetNotFound) {
          this.configService.showToast("true", !!res.packetNotFound ? res.packetNotFound : "Packet Not Found");
        } else if (JSON.stringify(res.data) !== JSON.stringify(this.latestRes)) {
          this.urlsListSelector$.next(true);
          this.listOfUrlsForStl = res.data;
          this.latestRes = res.data;
          this.listOfUrlsForStl = Object.values(this.latestRes);
          this.createZip(this.listOfUrlsForStl);
        }
      }
    });
  }

  onSelectionChanged(event) {
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    console.log(event);
    console.log(this.selectedRows);
    console.log(this.selectedRow);
    if (this.selectedRows.length >= 20) {
      this.isVoucherLimitExceded == true;
    }
  }

  forCallingCompletedAction() {
    this.subscriptions.push(
      this.store
        .pipe(select(getCompletedPacketsData(this.dbcActionData.id)))
        .pipe(takeUntil(this.unsubscribe$))

        .subscribe(data => {
          if (data) {
            console.log(data);
            this.dataSource = data;
            this.isCompletedData = data;
          }
        })
    );
  }

  async fetchRemarks() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "third-party-remark/findAll");
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.remarkList = [];
      this.remarkList = response.data;
    } else {
      console.error(response);
    }
  }

  async openPartyModal() {
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "addpartyModal",
      componentProps: { forPage: "partyWiseDropdown" },
    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      if (res.data) {
        this.selectedPartyId = res.data;
        this.createVoucher();
      }
    });
  }

  async createVoucher() {
    this.packetIdsPayload = this.selectedRows.map(a => a.id);
    console.log("send to xray and spectrum dept");
    this.selectedRows = this.selectedRows.filter((obj, index, self) => index === self.findIndex(o => o.id === obj.id));
    let payload = {
      packet: this.selectedRows.map(row => ({
        id: row.id,
        remarkId: row.cvdStatus.id,
      })),
      partyID: this.selectedPartyId[0],
    };
    let response = await this.httpService.postDataUsingPromise(true, "voucher/makeVoucherForDbc", payload);
    console.log(response);

    this.infoToPrint["processRemark"] = "DBC Voucher";
    this.infoToPrint["transRemark"] = "DBC Voucher";
    this.infoToPrint["data"] = {};
    this.infoToPrint["data"]["packets"] = this.selectedRows;
    let totalCts = 0;
    let pktIds = this.selectedRows.map(x => {
      totalCts += x.cts;
      return x.id;
    });
    this.infoToPrint["data"]["totalCts"] = totalCts;
    this.infoToPrint["data"]["totalPackets"] = this.selectedRows.length;

    this.infoToPrint["voucherDate"] = new Date(response.data.voucherDate || null).toLocaleDateString("en-GB");
    this.infoToPrint["issueDate"] = new Date(response.data.issueDate || null).toLocaleDateString("en-GB");
    this.infoToPrint["voucherNo"] = response.data.voucherLabel;
    this.infoToPrint["jangadNo"] = response.data.jangadNo;
    this.infoToPrint["issueNo"] = response.data.voucherLabel;
    this.infoToPrint["fromUser"] = response.data.from;
    this.infoToPrint["toUser"] = response.data.to;
    this.infoToPrint["address"] = response.data.address;
    this.infoToPrint["issueFor"] = `DBC Voucher`;
    this.printData = JSON.parse(JSON.stringify(this.infoToPrint));

    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      this.saveInputPrcocessapi();
      let data = response.message;
      console.log(data);

      let voucherData = {
        data: response.data,
      };
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: response.message,
          data: voucherData,
        },
        cssClass: "modalprint",
      });
      modal.onDidDismiss().then(dataReturned => {
        if (dataReturned !== null) {
          console.log(dataReturned);
        }
      });
      await modal.present();

      // this.showModal();
      // this._ConfigService.showToast('success', data);
    } else {
      console.error(response);
      this._ConfigService.showToast("error", response.error.message);
    }
  }

  saveInputPrcocessapi() {
    console.log(this.packetIdsPayload);
    let listOfParameter = [];
    let isDbcVoucher = {
      parameterName: "isDbcVoucher",
      parameterValue: "yes",
    };

    let barcodeObj = {
      packetId: this.packetIdsPayload.toString(),
    };
    console.log(barcodeObj);
    listOfParameter.push(isDbcVoucher);

    let formType = "Multipart";

    let listOfAllPackets = [];

    // Iterate over each packet ID
    for (let i = 0; i < this.packetIdsPayload.length; i++) {
      let packetId = this.packetIdsPayload[i];

      let packetObj = {
        packetId: packetId.toString(),
        listOfParameter,
      };

      listOfAllPackets.push(packetObj);
    }

    let obj = {
      listOfAllPackets,
    };

    console.log(obj);
    this.store.dispatch(
      masterDataActions.saveStoneParamater({
        parameterDetails: obj,
        actionId: this.dbcActionData.id,
        formType: "JSON",
        barcodeObj: barcodeObj,
        actionInfo: this.dbcActionData.name,
      })
    );
  }

  async getExcelData(data) {
    if (!!data) {
      console.log(data);
      const payloadForExcel = data.map(obj => obj.id);
      console.log(payloadForExcel);
      let payload = { packetIds: payloadForExcel };
      let response = await this.httpService.postDataUsingPromise(true, "packet/getDoppingValueOnBasisOfPacketIds", payload);
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        let data = response.message;
        this.excelDataSource = response.data;
      } else {
        console.error(response);

        this._ConfigService.showToast("error", response.error.message);
      }
    }
  }

  async exportDataToExcel() {
    await this.getExcelData(this.selectedRows);
    const fileName = "export.xlsx"; // Specify the desired file name.
    let arrayOfIds: any = [];
    this.selectedRows.map((list: any) => {
      if (!!list.id) {
        arrayOfIds.push(list.id);
      }
    });
    console.log(this.dataSource, arrayOfIds);
    console.log("pppp");
    console.log(arrayOfIds);
    this.store.dispatch(HomeActions.getUrlsOfStnFilesfromIds({ arrayOfIds: arrayOfIds }));

    this._ConfigService.createExcel(this.excelDataSource, fileName);
    // this.urlForStn(arrayOfIds);
  }

  ngOnDestroy() {
    this.store.dispatch(HomeActions.clearUrlsOfStnFilesfromIds());
    this.destroy$.next();
    this.destroy$.complete();
  }

  async createZip(files: any[]) {
    console.log(files);
    const zip = new JSZip();
    const name = "Stn.zip";
    let count = 0;

    console.log(files.length);

    for (const item of files) {
      try {
        console.log("url item");
        console.log(item);
        let fileName = item.fileName;
        let filePath = item.filePath;
        filePath = filePath.length > 0 ? filePath : "";
        let newFileName = filePath[0]?.split("/");
        let joinedPath = newFileName?.slice(1)?.join("/");
        const file = await JSZipUtils.getBinaryContent(this.centralBaseUrl + newFileName?.[0] + "/" + encodeURIComponent(joinedPath));
        zip.file(`${fileName}.stn`, file, { binary: true });
        count++;
        console.log(count);
        this.refreshGrid = true;
        setTimeout(() => {
          this.refreshGrid = false;
        }, 1000);
        console.log(files.length);
      } catch (err) {
        console.log(err);
      }
    }

    if (count === files.length) {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, name);
      });
    }

    console.log(count);
  }

  unsubscribe() {
    // Unsubscribe from all subscriptions in the array
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  async showModal() {
    const modal = await this.modalController.create({
      component: TrybemodalComponent,
      componentProps: {
        message: "Voucher Created Succesfully",
        data: "yes",
      },
      cssClass: "trybe_modal_1",
    });
    modal.onDidDismiss().then(dataReturned => {
      if (dataReturned !== null) {
        console.log(dataReturned);
      }
    });
    return await modal.present();
  }
}
