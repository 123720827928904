import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponentModule } from 'src/app/components/header/header.module';
import { FooterModule } from 'src/app/footer/footer.module';
import { DataGridComponentModule } from 'src/app/components/data-grid/data-grid.module';
import { SingleMultiDropdownModule } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.module';
import { SingleMultiDropdownComponent } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.component';
import { WagesMasterComponent } from './wages-master.component';
import { WidthAlertModule } from 'src/app/width-alert/width-alert.module';


@NgModule({
    declarations: [WagesMasterComponent],
    exports: [WagesMasterComponent],
    providers: [],
    imports: [
        CommonModule,
        HeaderComponentModule,
        IonicModule,
        FooterModule,
        DataGridComponentModule,
        WidthAlertModule,
        SingleMultiDropdownModule,
    ]
})
export class WagesMasterModule {}
