import { Component, Input, OnInit } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { ConfigService } from "../services/config/config.service";
import { AppState } from "../reducers";
import { Store, select } from "@ngrx/store";
import { getCurrentAction, getCurrentRowData } from "../tempDataStore/tempData.selector";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import * as JSZip from "jszip";
import { HttpClient } from "@angular/common/http";
import { LoaderService } from "../services/loader/loader.service";

@Component({
  selector: "app-old-file-download",
  templateUrl: "./old-file-download.component.html",
  styleUrls: ["./old-file-download.component.scss"],
})
export class OldFileDownloadComponent implements OnInit {
  rowData: any;
  @Input() data: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  @Input() packetData: any;
  filesDetails: any;
  fileData = {};
  newFileData = [];
  allfileDownload: any;
  currentActionId: any;
  currentActionName: any;
  centralFileUrl: string;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    public loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private http: HttpClient
  ) {}
  ngOnInit() {
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.currentActionName = action.name;
          this.currentActionId = action.id;
        }
      });
    this.packetData = this.data?.packetData;
    console.log(this.packetData);
    this.fetchFilesDetail();
  }
  getFileName(link: any) {
    return link.split("/")[link.split("/")?.length - 1];
  }
  closeModal() {
    this.modalController.dismiss();
  }
  downloadAllVersion(fileData: any) {
    console.log(fileData);
    for (const key in fileData.value) {
      this.downloadFileVersion(fileData.value[key]);
      // fileData.value[key].map((dt:any) => {
      //   this.downloadFileVersion(dt);
      // });
    }
  }
  downloadFileVersion(fileUrl: string) {
    console.log(fileUrl[0]);
    console.log("==-------");
    const fileName = fileUrl[0];
    this.centralFileUrl = this.configService.getCentralFileUrl();
    let newUrl = fileName.split("/");
    let joinedPath = newUrl.slice(1).join("/");
    window.open(`${this.centralFileUrl}${newUrl[0]}/${encodeURIComponent(joinedPath)}`, "_blank");
    // this.http.get(this.configService.getCentralFileUrl() + fileUrl, { responseType: "blob" }).subscribe(
    //   (response: Blob) => {
    //     const blob = new Blob([response]);
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = fileName;
    //     link.click();
    //   },
    //   error => {
    //     // console.error("Failed to download file:", fileUrl, error);
    //   }
    // );
  }
  downloadFile(link: any) {
    this.httpService.downloadFileFromCentral(link);
  }
  async createZipArchive() {
    const zip = new JSZip();
    const listOfAllFiles = this.allfileDownload;
    const prefix = this.configService.getCentralFileUrl();
    for (const filePath of listOfAllFiles) {
      const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
      const prefixedFileName = prefix + fileName;
      const response = await this.http.get(prefix + filePath, { responseType: "arraybuffer" }).toPromise();
      zip.file(prefixedFileName, response);
    }
    const content = await zip.generateAsync({ type: "blob" });
    const blob = new Blob([content]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "files.zip";
    link.click();
  }
  // santtise
  // downloadFile(link: any) {
  //   if (typeof link === 'string' && link.startsWith('http')) {
  //     const sanitizedUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  //     this.downloadSanitizedFile(sanitizedUrl);
  //   } else {
  //     console.error('Invalid URL format.');
  //   }
  // }
  // downloadSanitizedFile(sanitizedUrl: SafeResourceUrl) {
  //   const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, sanitizedUrl);
  //   if (url) {
  //     window.open(url, '_blank');
  //   } else {
  //     console.error('Failed to sanitize the URL.');
  //   }
  // }
  async fetchFilesDetail() {
    let endpoint: string = `processActionInput/getFilesPathOfPacket?packetId=${this.packetData?.id}`;
    if (this.data?.virtualAction) {
      endpoint += `&actionId=${this.data.virtualAction}`;
    }
    console.log(endpoint);
    // let test = {
    //   filePaths: {
    //     "kps Rough Assortment": {
    //       "Qc Check And Verify": {
    //         "1": ["uploads/EKG077/qcCheckAndVerify/1/EKG077.cap"],
    //       },
    //     },
    //   },
    //   listOfAllFiles: ["uploads/EKG077/qcCheckAndVerify/1/EKG077.cap", "uploads/EKG077/qcCheckAndVerify/1/EKG077.cap"],
    // };
    // this.fileData = test?.filePaths;
    let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.filesDetails = response.data;
      this.fileData = this.filesDetails?.filePaths;
      this.newFileData = this.transformData(this.fileData)
      this.allfileDownload = this.filesDetails?.listOfAllFiles;
    } else {
      console.error(response);
    }
  }
  transformData(data: any) {
    const result = [];
    for (const deptKey of Object.keys(data)) {
      const processes = [];
      for (const procKey of Object.keys(data[deptKey])) {
        const versions = [];
        for (const verKey of Object.keys(data[deptKey][procKey])) {
          versions.push({ key: verKey, value: data[deptKey][procKey][verKey] });
        }
        processes.push({ key: procKey, versions: versions });
      }
      result.push({ key: deptKey, processes: processes });
    }
    return result;
  }
}
