import { createAction, props } from "@ngrx/store";

export const setCurrentAction = createAction(
    '[Temp Data] setCurrentAction',
    props<{ actionData: any }>(),
);

export const resetCurrentAction = createAction(
    '[Temp Data] delete currentAction'
);
export const setCurrentSearch = createAction(
    '[Temp Data] setCurrentSearchPage',
    props<{ searchData: any }>(),
);

export const resetCurrentSearch = createAction(
    '[Temp Data] delete currentSearchPage'
);
export const saveCurrentRowData = createAction(
    '[Temp Data] Save Current Row Data',
    props<{ rowData: any }>(),
);
export const removeCurrentRowData = createAction(
    '[Temp Data] Remove Current Row Data',
); 
export const saveConversation = createAction(
    '[Temp Data] Save Chat Data',
    props<{ chatData: any }>(),
);
export const removeConversation = createAction(
    '[Temp Data] Remove Chat Data',
); 
export const addOrderRemarkDataToStore = createAction(
    '[Temp Data] add order form DropDownDataToStore',
    props<{ ids: any }>(),
); 

