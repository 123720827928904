import { routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { authReducer, AuthState, initialAuthState } from '../authStore';
import { autoReceiveReducer } from '../components/actions/auto-receive/state/auto-receive.reducer';
import { AutoReceiveState, initialAutoReceiveState } from '../components/actions/auto-receive/state/auto-receive.state';
import { withMeReducer } from '../components/actions/issue/state/issue.reducers';
import { initialWithMeState, WithMeState } from '../components/actions/issue/state/issue.state';
import { initialMyVirtualTaskState, MyVirtualTaskState } from '../components/my-virtual-task/state/my-virtual-task.state';
import { myVirtualTaskReducer } from '../components/my-virtual-task/state/my-virtual.task.reducers';
import { annotationReducer } from '../components/qc-annotation/state/qc-annotatation.reducers';
import { initialQCAnnotationState, QCAnnotationState } from '../components/qc-annotation/state/qc-annotation.state';
import { CheckerState, initialChekerState } from '../components/trybe-checker/state/checker.state';
import { chekerReducer } from '../components/trybe-checker/state/cheker.reducer';
import { signerReducer } from '../components/trybesigner/state/signer.reducer';
import { initialSignerState, SignerState } from '../components/trybesigner/state/signer.state';
import { dashboardReducer, DashboardState, initialDashboardState } from '../home/state/home.reducer';
import { masterDataReducer } from '../masterDataStore/masterData.reducers';
import { initialMasterDataState, MasterDataState } from '../masterDataStore/masterData.state';
import { tempDataReducer } from '../tempDataStore/tempData.reducers';
import { initialTempDataState, TempDataState } from '../tempDataStore/tempData.state';

export interface AppState {
    router;
    auth: AuthState;
    dashboard: DashboardState;
    withme: WithMeState;
    masterData: MasterDataState,
    tempData: TempDataState,
    auto_receive: AutoReceiveState,
    signer: SignerState,
    checker: CheckerState,
    annotation: QCAnnotationState
    mytask: MyVirtualTaskState
}

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    withme: withMeReducer,
    masterData: masterDataReducer,
    tempData: tempDataReducer,
    auto_receive: autoReceiveReducer,
    signer: signerReducer,
    checker: chekerReducer,
    annotation: annotationReducer,
    mytask: myVirtualTaskReducer
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        // console.log('state before: ', state);
        // console.log('action', action);
        if (action.type === 'Logout') {
            const stateData = { ...state };
            stateData.auth = initialAuthState;
            stateData.dashboard = initialDashboardState;
            stateData.withme = initialWithMeState;
            stateData.masterData = initialMasterDataState;
            stateData.tempData = initialTempDataState;
            stateData.auto_receive = initialAutoReceiveState;
            stateData.signer = initialSignerState;
            stateData.checker = initialChekerState;
            stateData.annotation = initialQCAnnotationState;
            stateData.mytask = initialMyVirtualTaskState;
            return reducer(stateData, action);
        } else {
            return reducer(state, action);
        }
    };
}

export function resetOnSignOut(
    reducer: ActionReducer<any>,
): ActionReducer<any> {
    return (state, action) => {
        if (action.type === 'Logout') {
            const stateData = { ...state };
            stateData.auth = initialAuthState;
            stateData.dashboard = initialDashboardState;
            stateData.withme = initialWithMeState;
            stateData.masterData = initialMasterDataState;
            stateData.tempData = initialTempDataState;
            stateData.auto_receive = initialAutoReceiveState;
            stateData.signer = initialSignerState;
            stateData.checker = initialChekerState;
            stateData.annotation = initialQCAnnotationState;
            stateData.mytask = initialMyVirtualTaskState;
            return reducer(stateData, action);
        } else {
            return reducer(state, action);
        }
    };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [logger]
    : [resetOnSignOut];