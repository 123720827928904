import { createReducer, on } from "@ngrx/store";
import { AutoReceiveAction } from "./auto-receive.action.type";
import { initialAutoReceiveState } from "./auto-receive.state";

export const autoReceiveReducer = createReducer(
    initialAutoReceiveState,
    on(AutoReceiveAction.savePacketDetails, (state, action) => {
        console.log('AutoReceu');
        console.log(action);
        return {
            ...state,
            scannedPacketDetails: action.scannedPacketDetails
        }
        
    }),

    on(AutoReceiveAction.saveAutoReceivePacket, (state, action) => {
        return {
            ...state,
            saveAutoReceivePacket: action.saveAutoReceive
        };
    })
)

