import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { getTotalUsingKeyPipe } from './get-total-using-key.pipe';



@NgModule({
  declarations: [getTotalUsingKeyPipe],
  imports: [
    CommonModule
  ],
  exports: [getTotalUsingKeyPipe]
})
export class getTotalUsingKeyPipeModule { }
