import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AccordionModule } from 'ngx-accordion';
import { FormlyFieldFile } from '../file-input/file-input.component';
import { FileValueAccessor } from '../file-input/file-value-accessor';
import { FormlyWrapperComponent } from '../formly-wrapper/formly-wrapper.component';
import { NgxSearchSelectComponent } from '../ngx-search-select/ngx-search-select.component';
import { ParamhistoryModule } from '../paramhistory/paramhistory.module';
import { NgxFormComponent } from './ngx-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PlanInfoGridModule } from '../plan-info-grid/plan-info-grid.module';
import { FormlyFieldAddBtn } from './formly-field-add-button.component';
import { FormlyFieldDeleteBtn } from './formly-field-delete-button.component copy';

@NgModule({
  declarations: [
    NgxFormComponent,
    FileValueAccessor,
    FormlyFieldFile,
    NgxSearchSelectComponent,
    FormlyWrapperComponent,
    FormlyFieldAddBtn,
    FormlyFieldDeleteBtn,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgSelectModule,
    ReactiveFormsModule,
    CdkAccordionModule,
    AccordionModule,
    ParamhistoryModule,
    PlanInfoGridModule,
    FormlyModule.forRoot({
      validationMessages: [
        // { name: 'required', message: 'This field is required' },
        { name: 'required', message: '' },
      ],
      types: [
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: "searchSelect", component: NgxSearchSelectComponent, wrappers: ['form-field'] },
        {
          name: 'add-button',
          component: FormlyFieldAddBtn,
          wrappers: [''],
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button',
            },
          },
        },
        {
          name: 'delete-button',
          component: FormlyFieldDeleteBtn,
          wrappers: [''],
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button',
            },
          },
        },
      ],
      wrappers: [
        { name: 'panel', component: FormlyWrapperComponent },
      ],
    }),
    FormlyMaterialModule
  ],
  entryComponents: [
    NgxFormComponent
  ],
  exports: [NgxFormComponent],
  providers: [],
})
export class NgxFormModule { }
