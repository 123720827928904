import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { ConfigService } from "../services/config/config.service";
import { LoaderService } from "../services/loader/loader.service";
import { AppState } from "../reducers";
import { select, Store } from "@ngrx/store";
import { ScannerinputComponent } from "../components/scannerinput/scannerinput.component";
import { MultipleBarcodeScannerComponent } from "../components/multiple-barcode-scanner/multiple-barcode-scanner.component";
import { takeUntil } from "rxjs/operators";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { Subject } from "rxjs";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import { StaticInfo } from "src/environments/environment";
import * as moment from "moment";
import { printData } from "../masterDataStore/master.data";
import { ACTIONNAMES } from "../services/endpoint.enum";
import { getAllTrybeDepartments } from "../masterDataStore/masterData.selector";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { DynamicModalComponent } from "./dynamic-modal/dynamic-modal.component";
import { HttpClient } from "@angular/common/http";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: "app-transfer-packet-to-dispatch",
  templateUrl: "./transfer-packet-to-dispatch.component.html",
  styleUrls: ["./transfer-packet-to-dispatch.component.scss"],
})
export class TransferPacketToDispatchComponent implements OnInit {
  @ViewChild("dispatchmodalContent") dispatchmodalContent: ElementRef;
  dataSource: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  allDataSource: any = [];
  refreshGrid: boolean = false;
  columns: any;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  segment: any = "Polish Department";
  barcodeInputList: any = [];
  multipleSelectedData: any = [];
  actionName: any;
  selectDepartment: any = [];
  selectRoles: any = [];
  infoToPrint: Object = {};
  dispatchData: any;
  printData: any = printData;
  voucherDto: any;
  dispatchLossData: any;
  currid: any;
  deptDropdownData: any = {
    //department Dropdown
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],

    propertName: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownData: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: this.selectRoles,
    propertName: "roleName",
    modeFlag: "single", //single/multiple
  };

  public modifiedGridObject: any;
  showDownload: boolean = true;
  showUploadFile: boolean = true;
  pageSize: number = 50;

  private pageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };

  isPagingDisable = true;

  pageNumber: number = 0;
  gridData: any = [];
  gridDataCount: number = 0;
  dispatchcsvrowData: any;
  dispatchpopupColumn: any[] = [
    { name: "File Name", prop: "file" },
    { name: "Created By", prop: "createdBy" },
    { name: "CreatedDate", prop: "createdDate" },
    { name: "Status", prop: "status", columnTemplate: "file-upload-status" },
    // { name: 'Packet ID', prop: 'packetId'},
  ];

  // @ViewChild('dispatchmodalContent')
  @ViewChild("fileInput") fileInput: ElementRef<any>;
  selectedDipatchDepartment: string | null = null;
  dispatchErrorMsg: string = "";
  readonly csvRegex = /\.csv$/i;
  readonly excelRegex = /\.xlsx$/i;
  rowindex = [];
  conditionsToDisable: any = {
    notEqual: {},
  };
  scanned: boolean = false;
  constructor(
    public httpService: HttpService,
    public http: HttpClient,
    private modalCtrl: ModalController,
    private loaderService: LoaderService,
    private configService: ConfigService,
    private store: Store,
    private modalcontroller: ModalController
  ) {
    this.columns = [
      { name: "Packet Id", prop: "packetId", columnTemplate: "lot-svg-template" },
      { name: "barcodeNo", prop: "barcodeNo" },
      { name: "Lot No", prop: "lotNo" },
      { name: "packetLabel", prop: "packetLabel" },
      // { name: 'Value', prop: 'amount' },
      // { name: 'Loss', prop: 'loss' },
      // { name: 'RD', prop: 'roughCutWt' },
      // { name: 'Last Packet', prop: 'isLastPacket' },
    ];
  }

  ngOnInit() {
    this.fetchGridData();
    // this.dispatchCsvGridData();
    this.infoToPrint = {};
    this.infoToPrint = {
      company: StaticInfo["company"], //Static Name
      address: StaticInfo["address"], //Static Address
      issueFor: null,
      issueDate: null, //Jangad Date
      issueNo: null, //Jnd No,
      stoneType: StaticInfo["stoneType"],
      senderNotes: StaticInfo["senderNotes"],
      processRemark: StaticInfo["processRemark"],
      subProcessRemark: StaticInfo["subProcessRemark"],
      data: {},
    };
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.actionName = action?.name;
        }
      });
    this.store.dispatch(masterDataActions.getRolesForUser());
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));

    this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
      if (data) {
        this.deptDropdownData["options"] = data;
      }
    });
  }

  segmentChanged(event) {
    this.segment = event.detail.value;
    this.deptDropdownData.selectedData = [];
    if (this.segment == "Polish Department") {
      this.conditionsToDisable.notEqual["isStoneOk"] = 1;
    } else {
      this.conditionsToDisable.notEqual["isStoneOk"] = 0;
      // delete this.conditionsToDisable.notEqual['isStoneOk']
    }
    this.dataSource = this.allDataSource?.filter((dt: any) => (this.segment == "Polish Department" ? dt.packetType == 3 : dt.packetType != 3));
  }
  onSelectionChanged(evt) {
    console.log(evt);
    this.multipleSelectedData = evt.selectedRowsData;
  }

  async dropDownSelection(ev: any, type?: string) {
    let property = ev.property;
    if (property == this.deptDropdownData["propertName"]) {
      // department dropdown
      this.onDepartmentSelection(ev);
    }
  }

  onDepartmentSelection(ev) {
    let selected = ev.arrayList;
    if (selected.length > 0) {
      this.deptDropdownData["selectedData"] = selected; //ev.name;
      this.deptDropdownData["selectedObj"] = selected[0]; //ev;
      this.selectDepartment = selected; //ev.name;
      let selDepartment = this.deptDropdownData["options"].filter(d => {
        return d.id == selected[0].id;
      });
      this.selectRoles = [];
      this.selectRoles = selDepartment[0].receiveRole;
    } else {
      this.selectRoles = [];
      this.selectDepartment = selected; //ev.name;
      this.deptDropdownData["selectedObj"] = {};

      this.dropDownData["selectedData"] = this.selectRoles;
      this.deptDropdownData["selectedData"] = [];


    }
  }

  async openBarcodeScannerModal() {
    this.refreshGrid = true;
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
      componentProps: {
        Data: this.dataSource,
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      
      let payload = {
        packetId: this.barcodeInputList,
      };
      
      this.dataSource = this.allDataSource
        ?.filter((dt: any) => (this.segment == "Polish Department" ? dt.packetType == 3 : dt.packetType != 3))
        .filter((dt: any) => 
    this.barcodeInputList?.some(barcode =>
      [dt.packetId, dt.packetLabel, dt.barcodeNo].includes(barcode)
    )
  );
    
    }
    this.scanned = true;
    this.rowindex = this.dataSource.map((d, index: number) => index);
    setTimeout(() => {
      this.scanned = false;
    }, 1000);
  }

  async fetchGridData() {
    let payload = {
      isDispatchPage: true,
    };

    try {
      const res: any = await this.httpService.postDataUsingPromise(true, "packet/transferPacketFetch", payload);
      this.allDataSource = res?.data.packetData || [];
      if (this.segment == "Polish Department") {
        this.conditionsToDisable.notEqual["isStoneOk"] = 1;
      } else {
        delete this.conditionsToDisable.notEqual["isStoneOk"];
      }

      this.dataSource = this.allDataSource.filter((dt: any) => (this.segment == "Polish Department" ? dt.packetType == 3 : dt.packetType != 3));
      //   this.segment == 'Polish Department' ? dt.packetType == 3 : dt.packetType != 3);
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("error", error.message || "Something went wrong!");
    } finally {
    }
  }

  async clearBarcode() {
    this.scanned = false;
    this.rowindex = [];
      setTimeout(() => {
        this.scanned = true;
    this.rowindex = [];
    
    }, 1000);
    this.rowindex = [];
  
   this.multipleSelectedData = [];
   this.scannerComponet.onResetForm();
    await this.fetchGridData();
 
  }

  async onTransfer() {
    console.log(this.selectRoles);
    let id: any[];
    if (!!this.barcodeInputList && this.barcodeInputList.length >= 1) {
      id = this.dataSource.map(dt => dt.id);
    } else if (this.multipleSelectedData && this.multipleSelectedData.length > 0) {
      console.log(this.multipleSelectedData);
      id = this.multipleSelectedData.map(dt => dt.id);
    } else {
      this.configService.showToast("error", "Id Is Not Present");
    }

    let payload;
    let endpoint;
    if (this.actionName != "Dispatch Packet") {
      payload = {
        packetId: id,
      };
      endpoint = "packet/transferPacketToInternalDispatch";
    } else {
      let selPacketID = id;
      let selDepartmentID = !!this.deptDropdownData["selectedObj"] ? this.deptDropdownData["selectedObj"].id : null;
      // let selRoleID = !!this.selectRoles[0] ? this.selectRoles[0].id : null;
      payload = {
        toDepartment: selDepartmentID,
        toRole: this.selectRoles,
        listOfPacketIds: selPacketID,
      };
      endpoint = "packet/transfer";
    }
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, endpoint, payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.deptDropdownData.selectedData = [];
      this.infoToPrint["data"]["packets"] = this.multipleSelectedData;
      let totalCts = 0;
      let pktIds = this.multipleSelectedData.map(x => {
        totalCts += x.cts;
        return x.id;
      });
      this.refreshGrid = true;
      this.fetchGridData();
      await this.setDispatchVoucherData(response.data);
      let voucherData = {
        data: response.data,
      };
      if (!!response.data && response.data?.dispatchVoucher.length > 0) {
        let baseURL = this.configService.getTrybeBaseUrl();
        if (response?.data?.lastPartReturnPath) window.open(baseURL + response?.data?.lastPartReturnPath, "_blank");
        if (response?.data?.partReturn) {
          setTimeout(() => {
            const window2 = window.open(baseURL + response?.data?.partReturn, "_blank");
            console.log("hello");
            console.log(baseURL);
            if (window2) {
              window2.focus();
            }
          }, 500);
        }

        if (response?.data?.combinePartPath) {
          setTimeout(() => {
            const window2 = window.open(baseURL + response?.data?.combinePartPath, "_blank");

            console.log(baseURL);
            if (window2) {
              window2.focus();
            }
          }, 500);
        }
        if (response?.data?.newDispatchPath) {
          setTimeout(() => {
            const window2 = window.open(baseURL + response?.data?.newDispatchPath, "_blank");

            console.log(baseURL);
            if (window2) {
              window2.focus();
            }
          }, 500);
        }
      }
      const modal = await this.modalcontroller.create({
        component: TrybemodalComponent,
        componentProps: {
          message: response.message,
          data: voucherData,
          dispatchData: this.dispatchData,
          voucherDto: this.voucherDto,
          printData: this.printData,
          dispatchLossData: this.dispatchLossData ? this.dispatchLossData : null,
        },
        cssClass: "modalprint",
      });
      modal.onDidDismiss().then(dataReturned => {
        if (dataReturned !== null) {
          console.log(dataReturned);
        }
      });
      return await modal.present();

      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.message || "Something went wrong");
      // 
    }
  }

  setDispatchVoucherData(data) {
    this.infoToPrint["voucherDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
    this.infoToPrint["issueDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
    this.infoToPrint["voucherNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["jangadNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["issueNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["fromCompany"] = data?.fromCompany;
    this.infoToPrint["toCompany"] = data?.toCompany;
    this.infoToPrint["issueFor"] = `Transfer`;
    this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
    let voucherData = {
      data: data,
    };

    // let isLoss;
    if (!!data && data?.dispatchVoucher.length > 0) {
      this.dispatchData = data?.dispatchVoucher;
      this.voucherDto = data?.voucherDto;
      // this.infoToPrint['fromCompany'] = this.dispatchData[0].fromCompany;
      // this.infoToPrint['toCompany'] = this.dispatchData[0].toCompany;
      this.infoToPrint["voucherId"] = this.dispatchData[0].voucherId;
      this.infoToPrint["TocompanyName"] = this.dispatchData[0].partyName;
      this.infoToPrint["date"] = moment.utc(this.dispatchData[0].Date).local().format("DD-MMM-YYYY");
      this.infoToPrint["ttlPkt"] = this.dispatchData.length;
      let rctsttl = 0;
      let ctsttl = 0,
        newTotalRgCutWt = 0,
        totalLoss = 0,
        totalOutStandingWt = 0,
        totalValue = 0;
      this.dispatchData.map(d => {
        if (d.originalWt) ctsttl += d.originalWt;
        if (d.wt) rctsttl += d.wt;
        if (d.roughCutWt) newTotalRgCutWt += Number(d.roughCutWt);
        if (d.loss) totalLoss += Number(d.loss);
        if (d.outStandingWt) totalOutStandingWt += Number(d.outStandingWt);
        if (d.crtValue) totalValue += Math.round(d.crtValue * 1000) / 1000;
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.infoToPrint["owtttl"] = ctsttl;
      this.infoToPrint["rctsttl"] = rctsttl;
      this.infoToPrint["newTotalRgCutWt"] = newTotalRgCutWt;
      this.infoToPrint["totalLoss"] = totalLoss;
      this.infoToPrint["totalOutStandingWt"] = totalOutStandingWt;
      this.infoToPrint["totalValue"] = totalValue;
      this.dispatchLossData = this.dispatchData?.filter(d => d.loss != null);
      // isLoss = (this.dispatchLossData !== undefined)
      this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
    }
  }

  async handleFileInput(event) {
    // debugger
    // console.log(event);
    // 
    let file = event.target.files[0];
    if (!this.validateFile(file?.name)) {
      // 
      return;
    }
    const reader = new FileReader();
    const formatData = new FormData();
    console.log(formatData);
    formatData.append("file", file);
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
      // this.currid = event.row.data.id;
    }
    console.log(formatData);
    this.httpService.saveDataToDB(true, `files/upload?type=dispatch`, formatData, "Multipart").subscribe(
      async (response: any) => {
        this.dispatchCsvGridData();
        if (response.statusCode == 200) {
          if (response.data) {
            // 

            // this.configService.showToast('success', "File Uploaded Successfully");
            this.deptDropdownData.selectedData = [];
            this.infoToPrint["data"]["packets"] = this.multipleSelectedData;
            let totalCts = 0;
            let pktIds = this.multipleSelectedData.map(x => {
              totalCts += x.cts;
              return x.id;
            });
            this.refreshGrid = true;
            let voucherData = {
              data: response.data,
            };

            // if(response.data.isError){

            //   this.configService.showToast ('error',' Upload Valid File' );

            //    let filepath = response.data.path;
            //    let newFileName = filepath.split("/")
            //    let joinedPath = newFileName.slice(1).join('/');
            //    console.log(this.configService.getCentralFileUrl() +filepath);
            //   // window.open(this.configService.getCentralFileUrl() + newFileName[0] + "/" + encodeURIComponent(joinedPath),'download');
            //    this.http.get(this.configService.getCentralFileUrl() + newFileName[0] + "/" + encodeURIComponent(joinedPath), { responseType: 'blob' }).subscribe(
            //    (response: Blob) => {
            //      const blob = new Blob([response]);
            //      const url = window.URL.createObjectURL(blob);
            //      const link = document.createElement('a');
            //      link.href = url;
            //      link.download = filepath;
            //      link.click();

            //    },
            //    (error) => {
            //      console.error('Failed to download file:', filepath, error);
            //    }
            //  );
            // }
            // else if(response.data.isError === false){
            //   this.configService.showToast('success', "File Uploaded Successfully");
            //   // this.openTransferModal();

            // }
            // this.polishTransfer()
          }
        } else {
          this.configService.showToast("error", response.error.message || "Something went wrong!");

          this.dispatchCsvGridData();
          // console.log(response,'response')
        }
      },
      (err: any) => {
        this.configService.showToast("error", err.error.message || "Something went wrong!");
        // console.log(err,'error')
      }
    );
  }

  async polishTransfer() {
    let selDepartmentID = !!this.deptDropdownData["selectedObj"] ? this.deptDropdownData["selectedObj"].id : null;
    let payload = {
      toDepartment: selDepartmentID,
      toRole: this.selectRoles,
      listOfPacketIds: this.multipleSelectedData.map(pkt => pkt.id),
    };
    let endpoint = "packet/transfer";

    let response = await this.httpService.postDataUsingPromise(true, endpoint, payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.deptDropdownData.selectedData = [];
      this.infoToPrint["data"]["packets"] = this.multipleSelectedData;
      let totalCts = 0;
      let pktIds = this.multipleSelectedData.map(x => {
        totalCts += x.cts;
        return x.id;
      });
      this.refreshGrid = true;
      if (!!response.data && response.data?.dispatchVoucher.length > 0) {
        let baseURL = this.configService.getTrybeBaseUrl();
        if (response?.data?.lastPartReturnPath) window.open(baseURL + response?.data?.lastPartReturnPath, "_blank");
        if (response?.data?.partReturn) {
          setTimeout(() => {
            const window2 = window.open(baseURL + response?.data?.partReturn, "_blank");
            console.log("hello");
            console.log(baseURL);
            if (window2) {
              window2.focus();
            }
          }, 500);
        }

        if (response?.data?.combinePartPath) {
          setTimeout(() => {
            const window2 = window.open(baseURL + response?.data?.combinePartPath, "_blank");

            console.log(baseURL);
            if (window2) {
              window2.focus();
            }
          }, 500);
        }
        if (response?.data?.newDispatchPath) {
          setTimeout(() => {
            const window2 = window.open(baseURL + response?.data?.newDispatchPath, "_blank");

            console.log(baseURL);
            if (window2) {
              window2.focus();
            }
          }, 500);
        }
      }
      await this.fetchGridData();
      await this.setDispatchVoucherData(response.data);
      let voucherData = {
        data: response.data,
      };
      this.closeModal();
      const modal = await this.modalcontroller.create({
        component: TrybemodalComponent,
        componentProps: {
          message: response.message,
          data: voucherData,
          dispatchData: this.dispatchData,
          voucherDto: this.voucherDto,
          printData: this.printData,
          dispatchLossData: this.dispatchLossData ? this.dispatchLossData : null,
        },
        cssClass: "modalprint",
      });
      modal.onDidDismiss().then(dataReturned => {
        this.fetchGridData();
        if (dataReturned !== null) {
          console.log(dataReturned);
        }
      });
      return await modal.present();

      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      // 
      this.configService.showToast("error", response.error || "Something went wrong");
    }
    this.dispatchErrorMsg = "";
  }

  validateDispatchForm() {
    let isValid = true;
    let msg = "";
    if (!this.selectedDipatchDepartment) {
      msg += "Department is required \n";
    }

    if (msg) {
      this.configService.showToast("warning", msg);
      return false;
    }
    return isValid;
  }

  validateFile(fileName: string) {
    let isValid = true;
    let errorMsg = "";

    if (!this.csvRegex.test(fileName) && !this.excelRegex.test(fileName)) {
      isValid = false;
      errorMsg = "File must be in .csv or .xlsx";
    }
    if (errorMsg) {
      this.configService.showToast("error", errorMsg);
    }
    // 
    return isValid;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onDepartmentChange(event) {
    if (!event) {
      this.selectRoles = "";
      return;
    }
    this.selectRoles = event.receiveRole;
  }

  // async openTransferModal(){

  //   this.selectRoles =''
  //   this.dispatchErrorMsg =''
  //   this.selectedDipatchDepartment=null

  //   const modal = await this.modalCtrl.create({
  //     component: DynamicModalComponent,
  //     componentProps: {
  //       template:this.dispatchModal,
  //       class:'dispatch-modal'
  //     },
  //     cssClass: 'dynamic-modal',
  //   });
  //   await modal.present();

  // }

  async openCsvModal() {
    this.dispatchCsvGridData();
    const modal = await this.modalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.dispatchmodalContent,
        //  class:'scrool'
      },
      cssClass: "openCsvModal",
    });
    await modal.present();
  }

  dispatchCsvGridData() {
    // 
    let endpoint: string = `files?type=dispatch/?limit=500`;
    this.httpService.fetchData(true, endpoint).subscribe(
      (res: any) => {
        if (res) {
          console.log(res);
          let resultCode: number = res.statusCode;
          let data: any = res.data;
          console.log(data);
          if (resultCode == 200) {
            console.log(res.data);
            if (res.data.length == 0) {
              this.configService.showToast("Error", "No Data Available");
            }
            this.dispatchcsvrowData = res.data;
          } else {
            this.configService.showToast("Error", res.error);
          }
        }
      },
      error => {
        console.error("An error occurred:", error);
        this.configService.showToast("Error", "Something went wrong!");
      },
      () => {
        // 
      }
    );
  }

  downloadCsvFile(event: any) {
    const fileName = event?.row?.data?.file || "";
    if (!fileName) {
      this.configService.showToast("error", "File does not exist!");
      return;
    }
    let newFileName = fileName.split("/");
    let joinedPath = newFileName.slice(1).join("/");
    console.log(this.configService.getCentralFileUrl() + fileName);
    // window.open(this.configService.getCentralFileUrl() + newFileName[0] + "/" + encodeURIComponent(joinedPath),'download');
    this.http.get(this.configService.getCentralFileUrl() + newFileName[0] + "/" + encodeURIComponent(joinedPath), { responseType: "blob" }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
      },
      error => {
        console.error("Failed to download file:", fileName, error);
        this.configService.showToast("error", "Failed to download!");
      }
    );
  }
}
