import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QCAnnotationState } from "./qc-annotation.state";

export const selectQCAnnotationState = createFeatureSelector<QCAnnotationState>(
    'annotation',
);
export const getLoaderState = createSelector(selectQCAnnotationState, state => {
    return state.isProcessing;
});
export const getSavedResponseData = createSelector(selectQCAnnotationState, state => {
    return state.saveDetailsFormResponse;
});
export const isShowLoader = createSelector(selectQCAnnotationState, state => {
    return state.isUpdateLoading;
});

export const getUpdatedSuceesData = createSelector(selectQCAnnotationState, state => {
    return state.updateDetailSuccess;
});
export const getUpdateDetailFailureData = createSelector(selectQCAnnotationState, state => {
    return state.updateFailure;
});

export const getPlansDoneBySuratChecker = createSelector(selectQCAnnotationState, state => {
    return state.detailsOfPlansDoneBySuratChecker;
});
