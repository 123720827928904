import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponentModule } from 'src/app/components/header/header.module';
import { FooterModule } from 'src/app/footer/footer.module';
import { DataGridComponentModule } from 'src/app/components/data-grid/data-grid.module';
import { SingleMultiDropdownModule } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.module';
import { SingleMultiDropdownComponent } from 'src/app/components/single-multi-dropdown/single-multi-dropdown.component';
import { WidthAlertModule } from '../../width-alert/width-alert.module';
import { ReprintDispatchVoucherComponent } from './reprint-dispatch-voucher.component';
import { ScannerinputModule } from '../scannerinput/scannerinput.module';

@NgModule({
  declarations: [ReprintDispatchVoucherComponent],
  imports: [
    CommonModule,
    HeaderComponentModule,
    IonicModule,
    FooterModule,
    DataGridComponentModule,
    WidthAlertModule,
    SingleMultiDropdownModule,
    ScannerinputModule

  ],
  exports: [ReprintDispatchVoucherComponent],
  entryComponents: [SingleMultiDropdownComponent],
  providers: [],
})


export class ReprintDispatchModule{

}