import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WidthAlertModule } from 'src/app/width-alert/width-alert.module';
import { HeaderComponentModule } from '../header/header.module';
import { DataGridComponentModule } from '../data-grid/data-grid.module';
import { NewDropDownModule } from '../new-drop-down/new-drop-down.module';
import { ViewvirtualtaskComponent } from './viewvirtualtask.component';
import { ScannerinputModule } from '../scannerinput/scannerinput.module';


@NgModule({
  imports: [
    IonicModule,
    HeaderComponentModule,
    WidthAlertModule,
    ScannerinputModule,
    CommonModule,
    DataGridComponentModule,
    FormsModule
  ],
  declarations: [ViewvirtualtaskComponent]
})
export class ViewvirtualtaskModule {}