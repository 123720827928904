import { createAction, props } from '@ngrx/store';
import { PdParam, SignerPlans } from './signer.data';

export const changePlanOncheck = createAction(
  '[Signer] change Plans on Parent Chk Change',
  props<{ plan: any; isChecked: boolean }>()
);
export const removeSelectedPlans = createAction(
  '[Signer] Remove current selected Plans'
);
export const getProgramByPacketIdAndPlanStoneId = createAction(
  '[Signer] Get Programs',
  props<{ packetId: any; planStoneId: any }>()
);
export const savePrograms = createAction(
  '[Signer] Programs Saved',
  props<{ packetPrograms: any }>()
);
export const getPacketPlans = createAction(
  '[Signer] Get Packet Plans from Packet ID',
  props<{ packetIdOb: any }>()
);

export const findPacketPlanStoneByPacketIdForSmartRecut = createAction(
  '[Signer] Get find Packet Plan Stone By PacketId For SmartRecut Packet ID',
  props<{ packetIdOb: any,reviewForSmartRecut }>()
);


export const savePacketPlans = createAction(
  '[Signer] Save Packet Plans',
  props<{ packetPlans: []; packetPlanData: any }>()
);

export const getPacketPlansForSpectrum = createAction(
  '[Signer] Get Packet Plans from Packet ID For Spectrum',
  props<{ packetId: any }>()
);

export const savePacketPlansForSpectrum = createAction(
  '[Signer] Save Packet Plans For Spectrum',
  props<{
    packetPlans: [], checkerId
    : any, reviewByCheckerActionId: any, pdFile?: any, otherFile?: any
  }>()
);

export const removeSavePacketPlans = createAction(
  '[Signer] Remove All Packet Plans from store'
);
export const getManualPacketPlan = createAction(
  '[Signer] Get Manual Packet Plans from Packet ID',
  props<{ packetIdOb: any }>()
);

export const saveManualPacketPlans = createAction(
  '[Signer] Save Manual Packet Plans',
  props<{ packetPlans: [] }>()
);
export const removeSaveManualPacketPlans = createAction(
  '[Signer] Remove All Manual Packet Plans from store'
);
export const showSelectedPlan = createAction(
  '[Signer] Show Selected Packet Plans by plan id',
  props<{ packetID: number; planID: number }>()
);
export const showAllSelPlan = createAction(
  '[Signer] Show All Selected Packet Plans',
  props<{ packetID: number; planIDs: any }>()
);
export const showSelectedProgram = createAction(
  '[Signer] Show Selected Program to Packet Plans',
  props<{
    packetID: number;
    planStoneID: number;
    program: string;
    programID: number;
  }>()
);
export const updatePacketPlan = createAction(
  '[Signer] Update Packet Plans',
  props<{ updateData: any }>()
);
export const savePacketPlan = createAction(
  '[Signer] save Packet Plans Data',
  props<{ updatedData: any }>()
);

export const deletePacketPlan = createAction(
  '[Signer] Delete Packet Plans',
  props<{ packetPlanID: number; packetIdOb: any }>()
);
export const deletePartPacketPlan = createAction(
  '[Signer] Delete Packet part Plans',
  props<{ id: number; packetIdOb: any }>()
);
// export const saveProcessParameter = createAction(
//     '[WithMe] Users by Action saved',
//     props<{ processnoteByActionID: any }>()
// );
export const updateDropDownParam = createAction(
  '[Signer] Update DropDown Parameter in Packet Plans stone data',
  props<{ updateData: any }>()
);
export const updateFLCLClAInPacketPlanStone = createAction(
  '[Signer] Update Fluorescence,Color,Clarity in Packet Plans',
  props<{ updateData: any; packetIdObj: any; parameters: any }>()
);

export const uploadFileToPacketPlans = createAction(
  '[Signer] upload file to Packet/Packet Plans',
  props<{ postData: any; param: any }>()
);
// export const uploadXpsFileToPacketPlans = createAction(
//     '[Signer] upload XPS file to Packet/Packet Plans',
//     props<{ postData: any }>(),
// );
// export const getUploadedFile = createAction(
//     '[Signer] get/fetch uploaded file ',
//     props<{ param: any }>(),
// );
export const saveUploadedFile = createAction(
  '[Signer] save upload file',
  props<{ file: any; param: any }>()
);

export const savePDParameterPacketPlanStone = createAction(
  '[Signer] save PD Parameter to Packet plan Stone',
  props<{ pdPayload: PdParam[]; packetIdObj: { packetId: string } }>()
);

export const changePDsavedFlag = createAction(
  '[Signer] update pd flag',
  props<{ packetPlanStoneId: any }>()
);

export const addRemarkForPacketPlan = createAction(
  '[Signer] Add Remark For Packet Plan',
  props<{
    remark: any;
    Grade: any;
    listOfPacketPlaneIdList: number[];
    packetIdObj: any;
    refresh: boolean;
  }>()
);

export const saveRemarksForPacketPlan = createAction(
  '[Signer] Save Remark For Packet Plan',
  props<{ remark: any; Grade: any }>()
);

export const saveRemarksOnOrder = createAction(
  '[Signer] Save Remarks On Packet Plan Stones',
  props<{ remark: any; orderId: any; pktPlanStoneId: any }>()
);
export const savePktPlanRemarks = createAction(
  '[Signer] Save Remarks On Packet Plan Stones orders',
  props<{ pktPlanStoneId: any ; orders:any}>()
);
export const orderMarkedOnPlanStone = createAction(
  '[Signer] Marked order selected',
  props<{ orderId: any; pktPlanStoneId: any }>()
);



export const addRemarkForPacketPlandrawer = createAction(
  '[Signer] Add Remark For Packet Plan drawer',
  props<{ remark: any; packetplanStoneID: any[] }>()
);

export const saveRemarksForPacketPlanDrawer = createAction(
  '[Signer] Save Remark For Packet Plan Drawer',
  props<{ remark: any }>()
);

export const addRemarkForPacketPlandrawerFinalTab = createAction(
  '[Signer] Add Remark For Packet Plan drawer Final Tab',
  props<{ remark: any; packetplanStoneID: any[]; noOfCut: any }>()
);

export const saveRemarksForPacketPlanDrawerFinalTab = createAction(
  '[Signer] Save Remark For Packet Plan Drawer Final Tab',
  props<{ remark: any }>()
);

export const saveSignerBestPlans = createAction(
  '[Signer] Save(server) Signer Best Plans',
  props<{ payload: any; pktData: any }>()
);
export const getSignerBestPlans = createAction(
  '[Signer] Fetch Signer Best Plans',
  props<{ pktData: any; message: string }>()
);
export const saveSignerBestPlansToStore = createAction(
  '[Signer] Store Signer Best Plans',
  props<{ signerBestPlans: SignerPlans[] }>()
);
export const setPacketPlanToIsFinal = createAction(
  '[Signer] set packet final plan',
  props<{ payload: any; }>()
);

export const selectPacketPlansForCheckerView = createAction(
  '[Signer] select packet plan for checker',
  props<{ planPriority: any; id: any, userId: any, packetId: any }>()
);



export const findAllTheBidSubmittedByUser = createAction(
  '[Signer] find all submitted plans by use for packet',
  props<{ packetId: any; userid: any }>()
);
export const saveAllTheBidSubmittedByUser = createAction(
  '[Signer]find all submitted plans by use for packet',
  props<{ plans: any }>()
);
export const copyPacketPlan = createAction(
  '[Signer] copy Packet Plan and its child',
  props<{ planData: any; packetPlanId: any }>()
);

// *********************
export const addDeptToReviewStone = createAction(
  '[Signer] add Dept To ReviewStone',
  props<{ payload: any; tabId: any }>()
);

export const addReviewStoneData = createAction(
  '[Signer] add review stone data',
  props<{ payload: any; storePayload: any; tabId: any }>()
);

export const saveFileDetails = createAction(
  '[Signer] save file details',
  props<{ payload: any; tabId: any }>()
);

export const getFileDetails = createAction(
  '[Signer] Get file details',
  props<{ payload: any; tabId: any }>()
);
// export const saveReviewStoneData = createAction(
//     '[Signer] Save review stone data',
//     props<{ reviewData: any }>(),
// )

export const deleteDrawerDetails = createAction(
  '[Signer] Delete file details',
  props<{ payload: any; tabId: any }>()
);

export const getOrderForm = createAction('[Signer] Get Order Form');
export const saveOrderForm = createAction(
  '[Signer] save Order Form',
  props<{ orderForm: [] }>()
);

export const setSelectedEcsvOption = createAction(
  '[Signer] Set selectedEcsvOption Value',
  props<{ value: any }>()
);

export const savePacketPlansForReconfirmBidPlan = createAction(
  '[Packet Plan] Save Packet Plans for reconfirm bid plan',
   props<{ reconfirmBidData: { packetPlans: any; planData: any } }>()
);


export const getSelectedEcsvValue = createAction('[Signer] Get selectedEcsvOption Value');
