import { packetDetails } from "./auto-receive.data";

// debugger
export interface AutoReceiveState {
    scannedPacketDetails: packetDetails[];
    saveAutoReceivePacket : any
}

export const initialAutoReceiveState: AutoReceiveState = {
    scannedPacketDetails: [],
    saveAutoReceivePacket : [],

};

