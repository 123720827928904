import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, NavParams } from '@ionic/angular';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HeaderComponent } from './header.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AutocompleteLibModule,
  ],
  declarations: [
    HeaderComponent,
  ],
  entryComponents: [
    HeaderComponent,
  ],
  exports: [HeaderComponent],
  providers: [NavParams]
})
export class HeaderComponentModule { }
