import { Component, OnInit } from "@angular/core";
import { DynamicDrawerService } from "src/app/services/dynamicdrawer.service/dynamicdrawer.service.service";
import { Cls_DynamicDialogConfig } from "src/app/tempDataStore/tempData.model";

@Component({
  selector: "app-dynamicdrawer",
  templateUrl: "./dynamicdrawer.component.html",
  styleUrls: ["./dynamicdrawer.component.scss"],
})
export class DynamicdrawerComponent implements OnInit {
  constructor(public DynamicDrawerService: DynamicDrawerService) {}

  ngOnInit() {}

  onClose() {
    const tempModal = new Cls_DynamicDialogConfig();
    tempModal.innerContent = null;
    tempModal.isOpen = false;
    this.DynamicDrawerService.dynamicDrawerConfig.next(tempModal);
  }

  //use this where we want to call drawer
  // openDrawer() {
  //   const tempModal = new Cls_DynamicDialogConfig();
  //   tempModal.isOpen = true;
  //   tempModal.innerContent = this.dynamicTemplate;
  //   tempModal.drawerClass = "global-drawer";
  //   this.DynamicDrawerService.openDrawer(tempModal);
  // }
}
