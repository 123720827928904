import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import { FetchAllStoneParameter } from '../masterDataStore/masterData.selector';
import { AppState } from '../reducers';
import { operators, routeMasterAPIs } from '../routemaster/state/routemaster.data';
import { ConfigService } from '../services/config/config.service';
import { HttpService } from '../services/http/http.service';
import { LoaderService } from '../services/loader/loader.service';
@Component({
  selector: 'app-routeselection',
  templateUrl: './routeselection.component.html',
  styleUrls: ['./routeselection.component.scss'],
})
export class RouteselectionComponent implements OnInit {
  @Input() gridData: any[] = [];
  @Output() getCriteriaDetail: EventEmitter<any> = new EventEmitter();
  dropDownRouteSelection: any = {
    name: 'Route Type',
    placeText: 'Select',
    options: [],
    selectedData: [],
    propertName: 'routeDept',
    modeFlag: 'single', //single/multiple
  };
  routeMasterData: any;
  private suscriptionParam: Subscription;
  rootSelection: any;
  event: any;
  routeSelectionData: any
  criterion: any = [];
  disable: boolean = true;
  criteriondata: any = {};
  myJSON: any;
  parameters = [];
  showSavebutton: boolean = true;
  routeSelectionId: any;
  btn: boolean = true;
  // myJSON: any;
  public currentView: string = "packetRouterSelection";
  columns: any;
  weightage: any;
  public defaultWeightagePlaceMessage: string = 'eg. 100,50';
  public weightagePlaceMessage: string = "";
  dropDownVal: any;
  criterionJSONData: any;
  routeSelectionForm: any = {
    id: null,
    criterion: null,
    criterionArray: null,
    weightage: null,//number
    routeName: null,//array
  };
  page: string = "Route Selection";
  pageSize: number = 50;

  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    private configService: ConfigService,
    private alertController: AlertController,
    public modalCtrl: ModalController,
    private store: Store<AppState>,
  ) {

  }

  ngOnChanges(changes: any): void {
    console.log(changes)
  }

  async ngOnInit() {
    console.log(this.gridData)
    this.store.dispatch(masterDataActions.getAllStoneMaster());
    this.suscriptionParam = this.store.pipe(select(FetchAllStoneParameter)).subscribe(data => {
      if (data) {
        this.parameters = [];
        data.forEach((curr: any, k: number) => {
          let paramOb: object = {};
          // paramOb = { id: curr.id, parameter: curr.masterName },
          paramOb = { id: !!curr.id ? curr.id : k + 1, parameter: curr.parameters, isAbsolute: curr.isAbsolute },
            this.parameters.push(paramOb);
          // console.log(paramOb);
        });
      }
    });

    this.columns = [
      { name: "Route Name", prop: "routeName" },
      { name: "Route type ", prop: "routeType" },
      { name: "Weightage", prop: "weightage" },
    ];


    // this.addCriteria(this.event);
    this.getRouteNAmerData();
  }
  dropDownSelection(evt: any, operator: any) {
    console.log('dropDownSelection');
    console.log('dropDownSelection');
    console.log(evt);
    let selectedArrayList = evt.arrayList;
    if (evt.property == "routeDept") {
      this.dropDownRouteSelection['selectedData'] = selectedArrayList;

      this.dropDownVal = selectedArrayList
    }
    this.routeSelectionForm['routeName'] = selectedArrayList;
    this.saveBtnValidation();
  }
  onWeightageChange(evt) {
    console.log("weightage");
    this.weightage = evt && evt.detail ? evt.detail.value : null;
    console.log(this.weightage);
    this.routeSelectionForm['weightage'] = this.weightage;
    this.saveBtnValidation();

  }
  onSelectionChanged(evt) {
    console.log(evt);

    this.routeSelectionId = evt.row.routeSelectionId
    console.log(this.routeSelectionId);


  }
  saveNewRouteData() {

    console.log('saveNewRouteData');
    this.myJSON = {};
    console.log(this.criterion);
    console.log(this.criterion);
    console.log(this.weightage);
    console.log(this.dropDownVal);
    let payload: any = [];
    let obj: any = {};
    this.criterionJSONData = {};
    this.criterion.forEach((r: any) => {
      if (r.isAbsolute == 0) {
        if (this.criterionJSONData[r.operator] == undefined) {
          this.criterionJSONData[r.operator] = {};//do not empty it every time
          this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
        } else {
          this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
        }
      } else {
        console.log(r.operator, r.paramValue);
        //dropdown
        if (r.operator && (r.operator == 'equals' || r.operator == 'notEqual')) {
          if (this.criterionJSONData[r.operator] == undefined) {
            this.criterionJSONData[r.operator] = {};//do not empty it every time
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
            // this.criterionJSONData[r.operator]['paramValueText'] = r.paramValueText;
          } else {
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
            // this.criterionJSONData[r.operator]['paramValueText'] = r.paramValueText;
          }
        } else {
          if (this.criterionJSONData[r.operator] == undefined) {
            this.criterionJSONData[r.operator] = {};//do not empty it every time
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
          } else {
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
          }
        }
      }
    });
    obj['criterion'] = JSON.stringify(this.criterionJSONData);
    obj['routeId'] = this.dropDownVal.length > 0 ? this.dropDownVal[0].id : null;
    obj['weightage'] = this.weightage;
    obj['id'] = this.routeSelectionId;

    payload.push(obj);
    console.log(obj)
    console.log(payload);
    

    this.httpService.saveData(true, routeMasterAPIs.saveUpdateRouteSelectionEndpoint, payload).subscribe((result: any) => {
      console.log('%cPacket-Route Save......', 'color:orange;font-size:18px;');
      console.log(result);
      this.httpService.getErrorAndDisplayIt(result);
      if (result.statusCode == 200) {
        this.reloadData();
        // this.getRouteSelectionData();
        // console.log('Success');
        // this.routeName = null;
        // this.weightage = null;
        // this.isDefault = false;
        // // this.isNewJSON = true;
        // this.routeJSONData = {};
        // this.departementCriteriaTableData = [];
        // payload = {};
        // this.routeFormJSON = {
        //   routeName: this.routeName,
        //   weightage: this.weightage,
        //   isDefault: this.isDefault,
        //   routeJSONData: this.routeJSONData
        // };
        // this.tempId = 0;
        // if (this.suscriptionParam2) {
        //   this.suscriptionParam2.unsubscribe();
        // }
        
        this.configService.showToast("success", result.message);
        this.weightage = ''
        this.dropDownRouteSelection['selectedData'] = '';
        this.criterion = [];
      }
      else {
        console.log(result);
        console.error('Error');
        
        // this.configService.showToast("error", d.message);
      }
    });
    this.currentView = "packetRouterSelection"
  }
  openRouteSelectionForm() {
    this.showSavebutton = true;
    this.currentView = "packetSelectionForm";
    this.criteriondata = {}
    this.weightage = "";
    this.dropDownRouteSelection["selectedData"] = "";

  }
  editRoute(evt) {

    this.showSavebutton = false;
    this.btn = false;
    console.log('%ceditRoute Route evt', "color:green;fot-size:14px");
    let rowData: any = evt.row.data;
    // let selData: any = evt.row.key;
    console.log(rowData);
    this.routeSelectionId = rowData.routeSelectionId;
    console.log(this.routeSelectionId);
    if (rowData) {
      this.currentView = 'packetSelectionForm';
      let ID: number = rowData.id;
      let routeTypeId: number = !!rowData.routeTypeId ? rowData.routeTypeId : null;
      // this.routeName = rowData.name;
      this.weightage = rowData.weightage;
      let criterion: any = !!rowData.criterion ? JSON.parse(rowData.criterion) : null;
      console.log(criterion);
      /*let criterianKeys= Object.keys(criterion)
      this.criteriondata = criterianKeys.map(operator=>{
        let operatorObj: any = operators.filter(d => d.operator == operator)[0];
        let obj={
          "operator": operator,
          "operatorText": operatorObj.title,
  
        };
  
        for (let parameter in criterion[operator]) {
          obj['paramName'] = parameter;
         let paramValue: any = criterion[operator][parameter];
            let typeOfVar: any = this.httpService.checkType(paramValue);
         if (paramValue && typeOfVar == 'object') {
          obj['paramValue'] = paramValue.values.join();
          obj['paramStrValue'] = paramValue.values.join();
        } else {
          //string, number
          obj['paramValue'] = paramValue;
          obj['paramStrValue'] = paramValue.values.join();
  
        }  
        }
        return obj;
      })*/
      this.criteriondata = this.getCriterionData(rowData);
      console.log(this.criteriondata);

      // this.criteriondata = {
      //   "paramName": ,
      //   "isAbsolute": 0,
      //   "operator": "equals",
      //   "paramValue": "5",
      //   "paramStrValue": "5",
      //   "tempId": 1,
      //   "operatorText": "Equals-To"
      // }


      let selRouteArr = [{ id: rowData.routeMasterId, routeDept: rowData.routeName }]
      this.dropDownRouteSelection["selectedData"] = selRouteArr;
      console.log(selRouteArr);
      this.routeSelectionForm['criterion'] = criterion;
      this.routeSelectionForm['criterionArray'] = this.criteriondata;
      this.routeSelectionForm['weightage'] = this.weightage;
      this.routeSelectionForm['routeName'] = selRouteArr;
      this.routeSelectionForm['id'] = this.routeSelectionId;
      console.log(this.routeSelectionForm);
    }
    this.saveBtnValidation();
  }
  getCriterionData(rowData) {
    console.log('getCriterionData');
    let criterionData: any = [];
    let criterion: any = !!rowData.criterion ? JSON.parse(rowData.criterion) : null;
    console.log(criterion);
    for (let operator in criterion) {
      let operatorObj: any = operators.filter(d => d.operator == operator)[0];
      console.log(operatorObj);
      if (operatorObj != null) {
        let criteriaObj = JSON.parse(JSON.stringify({}));
        criteriaObj['operator'] = operator;
        criteriaObj['operatorText'] = operatorObj.title;
        // let object:any= this.httpService.checkTypeOfVariable(criterion)
        for (let parameter in criterion[operator]) {
          let paramObj: any = this.parameters.filter(d => d.parameter == parameter)[0];
          let isAbsolute = !!paramObj ? parseInt(paramObj.isAbsolute) : null;
          criteriaObj['isAbsolute'] = isAbsolute;
          console.log(isAbsolute);
          criteriaObj['paramName'] = "";
          criteriaObj['paramName'] = parameter;
          let paramValue: any = criterion[operator][parameter];
          console.log(parameter, operator, paramValue);
          if (isAbsolute === 0) {
            if (operator && (operator == 'equals' || operator == 'notEqual')) {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = paramValue.paramValue;
            } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
              if (operator == 'range') {
                criteriaObj['paramValue'] = paramValue;
                criteriaObj['paramStrValue'] = `${paramValue.lowerLimit} - ${paramValue.upperLimit}`;
              } else {
                criteriaObj['paramValue'] = paramValue;
                criteriaObj['paramStrValue'] = `${paramValue.value.lowerPriorityLimitId} - ${paramValue.value.upperPriorityLimitId}`;
                criteriaObj['paramNameId'] = paramValue.paramNameId;
              }
            } else if (operator && (operator == 'in' || operator == 'notIn')) {
              criteriaObj['paramValue'] = paramValue;
              criteriaObj['paramStrValue'] = paramValue.values.join();
              criteriaObj['paramNameId'] = paramValue.paramNameId;
            }
            // console.log(this.criteriaFormJSON, this.rangeParamStrValue,);
          } else {
            if (operator && (operator == 'equals' || operator == 'notEqual')) {
              // let paramValueObj = this.dropDownFromData.options.filter(p => p.id == paramValue)[0];
              // console.log(this.dropDownFromData.options, paramValueObj, paramValue);
              criteriaObj['paramValue'] = paramValue.paramValue;
              // criteriaObj['paramStrValue'] = paramValue.paramValue;
              criteriaObj['paramStrValue'] = paramValue.paramValueText;
            } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
              console.log(operator);
              console.log(paramValue);
              if (operator == 'range') {
                criteriaObj['paramValue'] = paramValue;
                criteriaObj['paramStrValue'] = `${paramValue.lowerLimitText}-${paramValue.upperLimitText}`;
                // criteriaObj['paramNameId'] = paramValue.paramNameId;
                criteriaObj['paramNameId'] = paramValue.paramNameId;
              } else {
                criteriaObj['paramValue'] = paramValue;
                criteriaObj['paramStrValue'] = `${paramValue.value.lowerPriorityLimitText}-${paramValue.value.upperPriorityLimitText}`;
                criteriaObj['paramNameId'] = paramValue.paramNameId;
              }
            } else if (operator && (operator == 'in' || operator == 'notIn')) {
              if (operator == 'notIn') {
                criteriaObj['paramValue'] = paramValue;
                criteriaObj['paramStrValue'] = paramValue.valuesText.join();
                criteriaObj['paramNameId'] = paramValue.paramNameId;
              } else {
                criteriaObj['paramValue'] = paramValue;
                criteriaObj['paramStrValue'] = paramValue.valuesText.join();
                criteriaObj['paramNameId'] = paramValue.paramNameId;
              }
            }
          }

          criterionData.push(JSON.parse(JSON.stringify(criteriaObj)));
        }
      }
    }
    return criterionData;
  }
  deleteRoute(evt) {
    let rowData: any = evt.row.data;
    if (rowData) {
      let ID: number = rowData.routeSelectionId;
      let deleteAPIEndpoint: string = `${routeMasterAPIs.deleteSelectionRouteEndpoint}${ID}`
      
      this.httpService.deleteData(true, deleteAPIEndpoint).subscribe((result: any) => {
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          this.reloadData();
          
          this.configService.showToast("success", result.message);
        } else {
          
          // this.configService.showToast("error", d.message);
        }
      });
    }
  }
  onPaginationChange(evt) {

  }
  //  getRouteSelectionData() {
  //   this.httpService.fetchData(true, routeMasterAPIs.routeSelectionEndPoint).subscribe(response => {
  //     console.log('getRouterSelectionData');
  //     console.log(response);
  //     this.httpService.getErrorAndDisplayIt(response);
  //     if (response.statusCode === 200) {
  //       this.routeSelectionData = response.data;
  //       if (!!this.selectionId && this.selectionId != null) {
  //         console.log(this.routeSelectionData);
  //         this.routeSelectionData.filter((id)=>{
  //           if(this.selectionId==event){
  //         })
  //       }
  //     } else {
  //       console.error(response);
  //     }
  //     console.log(this.routeSelectionData);
  //     return this.routeSelectionData
  //   });
  // }
  getRouteNAmerData() {
    this.httpService.fetchData(true, routeMasterAPIs.routeMasterEndPoint).subscribe(response => {
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.routeMasterData = response.data;
      } else {
        console.error(response);
      }
      this.rootSelection = [];
      this.routeMasterData.forEach((curr: any, k: number) => {
        let obj1: object = {}
        obj1 = { id: curr.id, routeDept: curr.name };
        // obj1 = { id: curr.routeMasterId, routeDept: curr.routeName };
        this.rootSelection.push(obj1);
        // console.log(this.rootSelection);


      });
      this.dropDownRouteSelection['options'] = this.rootSelection;
    });
  }

  addCriteria(event) {
    console.log(event);
    this.criterion = [];
    this.criterion = event;

    this.routeSelectionForm['criterionArray'] = this.criterion;
    this.saveBtnValidation();
  }
  saveBtnValidation() {
    console.log('saveBtnValidation');
    let weightage = this.routeSelectionForm['weightage'];
    let routeName = this.routeSelectionForm['routeName'];
    let criterionArr = this.routeSelectionForm['criterionArray'] || [];

    console.log(criterionArr);

    if ((weightage == null || weightage == "") || (routeName == null || routeName == "") || (criterionArr.length === 0)) {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
  }
  backToRouteMaster() {
    this.currentView = "packetRouterSelection";
  }
  updateRouteData() {
    console.log('updateNewRouteData');

    this.myJSON = {};
    console.log(this.criterion);
    console.log(this.criterion);
    console.log(this.weightage);
    console.log(this.dropDownVal);
    console.log(this.routeSelectionForm);

    let payload: any = [];
    let obj: any = {};
    this.criterionJSONData = {};
    if (this.routeSelectionForm['criterionArray'].length == 0) {
      this.routeSelectionForm['criterionArray'] = this.criterion;
    }
    this.routeSelectionForm['criterionArray'].forEach((r: any) => {
      if (r.isAbsolute == 0) {
        if (this.criterionJSONData[r.operator] == undefined) {
          this.criterionJSONData[r.operator] = {};//do not empty it every time
          this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
        } else {
          this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
        }
      } else {
        console.log(r.operator, r.paramValue);
        //dropdown
        if (r.operator && (r.operator == 'equals' || r.operator == 'notEqual')) {
          if (this.criterionJSONData[r.operator] == undefined) {
            this.criterionJSONData[r.operator] = {};//do not empty it every time
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
            // this.criterionJSONData[r.operator]['paramValueText'] = r.paramValueText;
          } else {
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
            // this.criterionJSONData[r.operator]['paramValueText'] = r.paramValueText;
          }
        } else {
          if (this.criterionJSONData[r.operator] == undefined) {
            this.criterionJSONData[r.operator] = {};//do not empty it every time
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
          } else {
            this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
          }

        }
      }
    });
    obj['criterion'] = JSON.stringify(this.criterionJSONData);
    obj['routeId'] = !!this.routeSelectionForm['routeName'] ? this.routeSelectionForm['routeName'][0].id : null;
    obj['weightage'] = this.routeSelectionForm['weightage'];
    obj['id'] = this.routeSelectionForm['id'];

    payload.push(obj);
    console.log(payload);
    // return;
    
    this.httpService.saveData(true, routeMasterAPIs.saveUpdateRouteSelectionEndpoint, payload).subscribe((result: any) => {
      console.log('%cPacket-Route Save......', 'color:orange;font-size:18px;');
      console.log(result);
      this.httpService.getErrorAndDisplayIt(result);
      if (result.statusCode == 200) {
        // this.getRouteSelectionData();
        this.reloadData();
        this.routeSelectionForm = {
          criterion: null,
          criterionArray: null,
          weightage: null,//number
          routeName: null,//array
        };
        // console.log('Success');
        // this.routeName = null;
        // this.weightage = null;
        // this.isDefault = false;
        // // this.isNewJSON = true;
        // this.routeJSONData = {};
        // this.departementCriteriaTableData = [];
        // payload = {};
        // this.routeFormJSON = {
        //   routeName: this.routeName,
        //   weightage: this.weightage,
        //   isDefault: this.isDefault,
        //   routeJSONData: this.routeJSONData
        // };
        // this.tempId = 0;
        // if (this.suscriptionParam2) {
        //   this.suscriptionParam2.unsubscribe();
        // }
        
        this.configService.showToast("success", result.message);
        this.weightage = ''
        this.dropDownRouteSelection['selectedData'] = '';
        this.criterion = [];
      }
      else {
        console.log(result);
        console.error('Error');
        
        // this.configService.showToast("error", d.message);
      }
    });
    this.currentView = "packetRouterSelection"
  }


  reloadData() {
    this.getCriteriaDetail.emit()
  }
}
