import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import Query from "devextreme/data/query";
import { takeUntil } from 'rxjs/operators';
import { getOutgoingPacketsData } from 'src/app/home/state/home.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { saveCurrentRowData } from 'src/app/tempDataStore/tempData.action';
import { TrybemodalComponent } from '../../trybemodal/trybemodal.component';
import { VoucherwisepacketComponent } from '../../voucherwisepacket/voucherwisepacket.component';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { StaticInfo } from 'src/environments/environment';
// import { infoToPrint } from 'src/app/masterDataStore/master.data';
import { authUser } from 'src/app/authStore/auth.selector';

@Component({
  selector: 'app-outgoing',
  templateUrl: './outgoing.component.html',
  styleUrls: ['./outgoing.component.scss'],
})
export class OutgoingComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  allMode: string;
  checkBoxesMode: string;
  @ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;
  public outGoingGridSource: any = [];
  public totalOutgoingStones: number = 0;
  columns;
  refreshGrid=false;
  voucherid: any;
  openVoucher: boolean;
  isThirdParty:boolean = false;
  public pageSize: number = 10;
  public selectedRows: any = [];
  public outgoingPageInfo: any = {
    limit: this.pageSize,
    page: 0
  }
  dataSource: any = [];
  masterData:[];
  modifiedDataSource: any;
  totalOutgoingStonesCount: number = 0;
  public pageNumber: number = 0;
  private unsubscribe$: Subject<any> = new Subject<any>();
  // transferinfoToPrint: any = infoToPrint;
  dispatchData: any = [];
  voucherDto:any;
  isDisabled:boolean = false;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private navCtrl: NavController,
    // private storage: Storage
  ) {
    this.allMode = 'allPages';
  }

 
  infoToPrint: any = {
    "company": StaticInfo['company'],
    "address": StaticInfo['address'],
    "issueFor": "Outgoing",
    "issueDate": "09/11/2023",
    "issueNo": "TI-2106",
    stoneType: StaticInfo['stoneType'],
    senderNotes: StaticInfo['senderNotes'],
    processRemark: StaticInfo['processRemark'],
    subProcessRemark: StaticInfo['subProcessRemark'],
    "data": {

    },
    "fromUser": "DI 2",
    "fromDept": "",
    "fromCompany": "surat-dev",
    "toUser": "DI Manager",
    "toDept": "",
    "toCompany": "",
    "transRemark": "Outgoing",
    "voucherDate": "09/11/2023",
    "voucherNo": "TI-2106",
    "jangadNo": "TI-2106"
};
//  infoToPrint: any
  ngOnInit() {
    this.fetchGridData();
    this.columns = [
      { name: "Voucher No", prop: "voucherLabel" },
      { name: "Total Packets", prop: "totalCount" },
      { name: "Action Name", prop: "actionName" },
      // { name: "From Dept", prop: "fromDept" },
      { name: "To Dept", prop: "toDept" },
      { name: "Voucher Date", prop: "createdDate" },
      { name: "To User", prop: "toUser" },
      { name: "Location", prop: "location" }

    ];
    this.subscriptions.push(
      this.store.pipe(select(authUser)).subscribe((data: any) => {
        if (data) {
          this.infoToPrint['fromUser'] = data.fullName ?? '';
        }
      })
    );
  
    // let grid = this.dataGrid.instance;
    // new ResizeObserver((entries: any) => {
    //   grid.updateDimensions();
    // }).observe(grid.element());

    // this.setPaginationToDxGrid();
    // this.httpService.currentDataObj.pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
    //   if (data) {
    //     this.outGoingGridSource = data.packetData;
    //     this.totalOutgoingStones = data.countOfTotalPackets;
    //   }
    // })
    // this.store.pipe(select(getShowDatas)).subscribe(data => {
    //   console.log('data');
    //   console.log(data);
    // })

    // this.store.pipe(state => state["auth"].stoneName);
    // this.setPaginationToOutgoingDxGrid();
    // this.httpService.currentOutgoingGridData$.subscribe(data => {
    //   if (data) {
    //     this.outGoingGridSource = data.vouchersList;
    //     this.masterData = data.vouchersList;
    //     this.totalOutgoingStonesCount = data.totalCount;
    //   }
    // });
  }

  customizeColumns(columns) {
    columns[0].width = 20;
  }

  fetchGridData(){
    this.setPaginationToOutgoingDxGrid();
    this.store.pipe(select(getOutgoingPacketsData)).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (data) {
        this.outGoingGridSource = data;
        this.totalOutgoingStones = data.length;
      }
      this.isDisabled=true;
       this.httpService.currentOutgoingGridData$.subscribe(data => {
           if (data) {
             this.outGoingGridSource = data.vouchersList;
             this.masterData = data.vouchersList;
             this.totalOutgoingStonesCount = data.totalCount;
           }
         });
    });

  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  backIt() {
    console.warn('Back button');
    // this.loc.back();
    //history.back();
    this.navCtrl.pop();
  }
  async logout() {
    console.log('log out fromoutgoing page and trybe app');
  }

  onSelectionChanged(event) {
    console.log(event)
    event.row = event.selectedRowsData[0];
    console.log("onSelectionChanged event ", event);
    this.selectedRows = event.selectedRowsData
    console.log(event.row);
    this.selectedRows = event.selectedRowsData;
    this.isThirdParty = this.selectedRows[0]?.isThirdParty;
    this.infoToPrint['issueNo'] = this.selectedRows[0]?.voucherLabel ?? '';
    this.infoToPrint['fromDept'] = this.selectedRows[0]?.fromDept ?? '';
    this.infoToPrint['toDept'] = this.selectedRows[0]?.toDept ?? '';
    this.infoToPrint['voucherDate'] = this.selectedRows[0]?.createdDate?.slice(0, 10) ?? '';
    this.infoToPrint['toUser'] = this.selectedRows[0]?.toUser;
    // this.infoToPrint['fromUser'] ='';
    this.infoToPrint['voucherDate'] = this.selectedRows[0]?.createdDate?.slice(0, 10) ?? '';

    this.infoToPrint['jangadNo'] = this.selectedRows[0]?.voucherLabel ?? '';
    this.infoToPrint['voucherNo'] = this.selectedRows[0]?.voucherLabel ?? '';
    this.infoToPrint['issueDate'] = this.selectedRows[0]?.createdDate?.slice(0, 10) ?? '';
    this.infoToPrint['issueNo'] = this.selectedRows[0]?.voucherLabel ?? '';
    this.infoToPrint['issueDate'] = this.selectedRows[0]?.createdDate ?? '';

    if (this.selectedRows.length > 0) {
      this.voucherid = event.row.voucherId
      this.store.dispatch(
        saveCurrentRowData({ rowData: event.selectedRowsData })
      );
      this.voucherDto = event.selectedRowsData?.[0];
      this.getAllPacketData(this.voucherid)
      // this.openVoucherModal();
    }
  }
  async openVoucherModal(packetData) {
    const modal = await this.modalController.create({
      component: VoucherwisepacketComponent,
      cssClass: 'extraLargePage-for-voucher',
      componentProps: {
        "message": "Voucherid",
        "packetInfo": packetData,
        "voucherDto": this.voucherDto,
        "isThirdParty" : this.isThirdParty
      },
    });
    

    modal.onDidDismiss().then((d: any) => this.handleModalDismissforVoucher(d,null));

    return await modal.present();
  }
  handleModalDismissforVoucher(d: any,evt): any {
    console.log("closingvoucher modal");
    console.log(evt);
    // this.refreshGrid=true
    setTimeout(() => {
      this.refreshGrid = false;
    }, 500);
  }



  async getAllPacketData(voucherID?: string) {
    let size = 1000;
    let page = 0;
    let endpoint: string = `packet/getPacketsListForVoucher?voucherId=${voucherID}&limit=${size}&page=${page}`;
    try {
      const response = await this.httpService.getDataWithoutPayloadForGet(true, endpoint).toPromise();
      if (response.statusCode === 200) {
        console.log(response.data.packetsList);
        this.infoToPrint['data']['packets'] = response.data.packetsList;
        let totalCts = 0;
        let pktIds = response.data.packetsList.map((x) => {
          totalCts += x.cts;
          return x.id;
        });
        this.infoToPrint['process'] = response.data.packetsList[0].displayName;
        this.infoToPrint['fromCompany'] = response?.data?.fromCompany;
        this.infoToPrint['toCompany'] = response?.data?.toCompany;
        this.infoToPrint['data']['totalCts'] = totalCts;
        this.infoToPrint['data']['totalPackets'] = response?.data?.totalCount;
        // this.printData['data']['packets'] = response.data.packetsList; 
        response.data.packetsList.map(pl => pl.toUser = this.selectedRows[0]?.toUser )
        // const printRes = await this.httpService.getDataWithoutPayloadForGet(true, `packet/getPrintDataForDispatch?voucherId=${voucherID}`).toPromise();
        // if(printRes?.data?.dispatchVoucher){
        //   this.dispatchData = printRes?.data?.dispatchVoucher;
        //   this.transferPrintData['voucherNo'] = this.voucherDto.voucherLabel;
        //   this.transferPrintData['jangadNo'] = this.voucherDto.voucherLabel;
        //   this.transferPrintData['issueNo'] = this.voucherDto.voucherLabel;
        //   this.transferPrintData['issueFor'] = `Transfer`;
        //   this.transferPrintData['fromCompany'] = this.dispatchData[0].fromCompany;
        //   this.transferPrintData['toCompany'] = this.dispatchData[0].toCompany;
        //   this.transferPrintData['voucherId'] = this.dispatchData[0].voucherId;
        //   this.transferPrintData['TocompanyName'] = this.dispatchData[0].partyName;
        //   this.transferPrintData['date'] = moment
        //     .utc(this.dispatchData[0].Date)
        //     .local()
        //     .format('DD-MMM-YYYY');
        //   this.transferPrintData['ttlPkt'] = this.dispatchData.length;
        //   let rctsttl = 0;
        //   let ctsttl = 0;
        //   this.dispatchData.map((d) => {
        //     if (d.originalWt) ctsttl += d.originalWt;
        //     if(d.wt) rctsttl += d.wt;
        //     // d['lotNo'] = d.currPacketId.split('#')[0];
        //   });
        //   this.transferPrintData['owtttl'] = ctsttl
        //   this.transferPrintData['rctsttl'] = rctsttl;
        // }
        await   this.openVoucherModal(response.data.packetsList)
      } else {
        console.error(response);
        this.configService.showToast('error', response.error.message);
      }
    } catch (error) {
      console.error('Error fetching worker data:', error);
    }
  }
  


  // receivePacket() {
  //   let pktIds = this.selectedRows.map(x => x.id);
  //   let receivePackets = {
  //     listOfPacketIds: pktIds
  //   }
  //   this.httpService.receive(receivePackets).subscribe(res => {
  //     console.log(res)
  //     this.configService.showToast("success", res.message);
  //     this.store.dispatch(
  //       PacketActions.getIncomingPackets(),
  //     );
  //   }, err => {
  //     this.configService.showToast("error", err.message);
  //   })

  // }

  customDateTime(data) {
    console.log(data)
    if (!!data.inDate) {
      return moment.utc(data.inDate).local().format('DD-MMM-YYYY hh:mm a');
    }
    if (!!data.outDate) {
      return moment.utc(data.outDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

  getShortTitle(str) {
    var newStr = str.split(' ').reduce((result, currentWord) =>
      result + currentWord.charAt(0).toUpperCase(), '');
    return newStr;
  }

  async remove(event) {
    console.log(event)

    let data = event.row.data
    let voucherId = data.voucherId;
    console.log(voucherId);
    // let limit = { limit: 500, page: 1 };
    // await this.httpService.showLoader();
    if (!!voucherId) {
      // this.store.dispatch(masterDataActions.removeIssuedPacketVoucherwise({ voucherId: voucherId }));
       this.httpService.removeIssuedPacketvoucherwise(voucherId).subscribe((d: any) => {
        console.log(d);
        if (d.statusCode == 200) {
          let limit = { limit: 50, page: 0 };
          console.log('Success Packet Received');
          this.setPaginationToOutgoingDxGrid();
          this.store.dispatch(HomeActions.getReceivedPackets(limit));
          this.store.dispatch(HomeActions.getOutgoingPackets(limit));
          // this.httpService.dismissLoader();
          
          // this.showModal(true, d.message);
         this.configService.showToast("success", d.message);
        } else {
          console.log(d);
          console.error('Error in Packet Delete');
          // this.httpService.dismissLoader();
          
          // this.showModal(false, d.message);
          this.configService.showToast("error", d.message);
        }
      });
    } else {
      // await this.showModal(false, "PacketId or VoucherId is missng");
    }
    // this.httpService.removeIssuedPacket(packetId, voucherId).pipe(takeUntil(this.unsubscribe$)).subscribe(async result => {
    //   if (result) {
    //     if (result.statusCode === 200) {
    //       console.log('...pakce4t Removal result....');
    //       console.log(result);
    //       await this.setPaginationToDxGrid();
    //       await this.store.dispatch(HomeActions.getReceivedPackets(limit));
    //       await this.httpService.dismissLoader();
    //       await this.showModal(true, result.message);
    //     } else {
    //       await this.httpService.dismissLoader();
    //       await this.showModal(false, result.message);
    //     }
    //   }
    // })
  }
 
  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case 'paging.pageSize':
        let size = evt.value;
        this.outgoingPageInfo['limit'] = size;
        this.setPaginationToOutgoingDxGrid();
        break;
      case 'paging.pageIndex':
        let pageIndex = evt.value;
        this.outgoingPageInfo['page'] = pageIndex;
        this.setPaginationToOutgoingDxGrid();
        break;
    }
  }
  async setPaginationToOutgoingDxGrid() {
    this.modifiedDataSource = new CustomStore({
      key: "voucherId",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // if (!!loadOptions.filter) {
        //   var params = ''; 
        //   params += '&filter=' + JSON.stringify(loadOptions.filter); // <- Add this line           
        // }  
        const filterObject = {};
        if (!!loadOptions.filter) {
          var params = loadOptions.filter;
          console.log(params);
          for (let i = 0; i < params.length; i++) {
            if (typeof (params[0]) == 'string') {
              const key = params[0];
              const value = params[2];
              if (key && value) {
                filterObject[key] = value;
              }
            } else if (Array.isArray(params[i])) {
              const key = params[i][0];
              const value = params[i][2];
              if (key && value) {
                filterObject[key] = value;
              }
            }
            console.log(filterObject);
          }
        }
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.outgoingPageInfo['limit'];
          } else {
            this.outgoingPageInfo['limit'] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.outgoingPageInfo['page'] = this.pageNumber;
          let response: any = await this.httpService.fetchOutgoingGridData(this.outgoingPageInfo, filterObject);
          this.httpService.updateOutgoingGridData(response.data);
          return {
            data: Query(this.outGoingGridSource)
              .toArray(),
            totalCount: this.totalOutgoingStonesCount,
            groupCount: this.totalOutgoingStonesCount
          };
        } else {
          return {
            data: this.outGoingGridSource,
            totalCount: this.totalOutgoingStonesCount,
            groupCount: this.totalOutgoingStonesCount
          }
        }
      }
    });
  }
  // async setPaginationToDxGrid() {
  //   console.log('setPaginationToDxGrid');
  //   this.modifiedDataSource = new CustomStore({
  //     key: "id",
  //     load: async (loadOptions: any) => {
  //       console.log("loadOptions ", loadOptions);
  //       if (!!loadOptions.take) {
  //         if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
  //           loadOptions.take = this.pageInfo['limit'];
  //         } else {
  //           this.pageInfo['limit'] = loadOptions.take;
  //         }
  //         this.pageNumber = loadOptions.skip / loadOptions.take;
  //         this.pageInfo['page'] = this.pageNumber;
  //         await this.getOutgoingGridData(function (state, message) {
  //           if (state) {
  //             console.log('getOutgoingGridData data loaded successfully');
  //           } else {
  //             console.log('Something went wrong to load getOutgoingGridData data');
  //             console.error(message);
  //           }
  //         });
  //         return {
  //           data: query(this.outGoingGridSource)
  //             .toArray(),
  //           totalCount: this.totalOutgoingStones,
  //           groupCount: this.totalOutgoingStones
  //         };
  //       } else {
  //         return {
  //           data: this.outGoingGridSource,
  //           totalCount: this.totalOutgoingStones,
  //           groupCount: this.totalOutgoingStones
  //         }
  //       }
  //     }
  //   });
  //   console.log(this.modifiedDataSource);
  // }
  // async getOutgoingGridData(callbackToParent) {
  //   console.log('%cDebugging................', 'color:green;border:2px solid red;');
  //   let response: any;
  //   response = await this.httpService.fetchOutgoingGridData(this.pageInfo);
  //   this.outGoingGridSource = [], this.totalOutgoingStones = 0;
  //   if (response.statusCode == 200) {
  //     this.outGoingGridSource = response.data.packetData;
  //     this.totalOutgoingStones = response.data.countOfTotalPackets;
  //     this.httpService.updateOutgoingGridData(response.data);
  //     console.log(response.daaata);
      
  //     callbackToParent(true, response.message);
  //   } else {
  //     let test = {
  //       totalCount: 0,
  //       vouchersList: [],
  //     }
  //     this.outGoingGridSource = this.outGoingGridSource;
  //     this.totalOutgoingStones = this.totalOutgoingStones;
  //     this.httpService.updateOutgoingGridData(test);
  //     callbackToParent(false, response.message);
  //   }
  //   await this.httpService.getOutgoingPackets(this.pageInfo).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
  //     if (res) {
  //       console.log('resOGGGGGGGGGGGGGGGGGGGGGGGGGGGGPage');
  //       console.log(res);
  //       if (res.statusCode == 200) {
  //         this.outGoingGridSource = res.data.packetData;
  //         this.totalOutgoingStones = res.data.countOfTotalPackets;
  //         callbackToParent(true, res.message);
  //       } else {
  //         this.outGoingGridSource = this.outGoingGridSource;
  //         this.totalOutgoingStones = this.totalOutgoingStones;
  //         callbackToParent(false, res.message);
  //       }
  //     }
  //   });
  // }
  ngOnDestroy() {
    console.log('ngOnDestroy............');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  async showModal(state, message) {
    if (state) {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          "message": message
        },
        cssClass: 'trybe_modal_1'
      });
      modal.onDidDismiss().then((d: any) => this.handleOgModalDismiss(d));
      return await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          "message": message
        },
        cssClass: 'trybe_modal_0'
      });
      return await modal.present();
    }
  }
  handleOgModalDismiss(param) {
    console.log('handleOgModalDismiss');
    console.log(param);
  }

}

