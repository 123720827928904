import { PacketId } from "./checker.data";

export interface CheckerState {
    packetPlanStones: any;
    selectPlans: any;
    finalPlans: any;
    plansAccToUser: any;
    cuts: any[];
    shapes: any[];
    clarities: any[];
    colors: any[];
    stoneRemarks: any[];
    plansData: any[];
}

export const initialChekerState: CheckerState = {
    packetPlanStones: [],
    selectPlans: [],
    finalPlans: [],
    plansAccToUser: [],
    cuts: [],
    shapes: [],
    clarities: [],
    colors: [],
    stoneRemarks: [],
    plansData: []
};