import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController} from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { AppState } from 'src/app/reducers';
import CustomStore from "devextreme/data/custom_store";
import { HttpService } from 'src/app/services/http/http.service';

import Query from "devextreme/data/query";
import { ConfigService } from 'src/app/services/config/config.service';
import { LockChanges } from '@ngrx/store-devtools/src/actions';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { ScannerinputComponent } from '../scannerinput/scannerinput.component';
import { Subject, Subscription } from 'rxjs';
import { getReceivedPacketsData } from 'src/app/home/state/home.selector';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { getCurrentAction, getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';
import { takeUntil } from 'rxjs/operators';
import { getCheckerAllPacketPlans, getFinalPlanData, getSelectedPlanData } from '../trybe-checker/state/checker.selector';
import { getPlansDoneBySuratChecker } from '../qc-annotation/state/qc-annotation.selectors';
import { QCActions } from '../qc-annotation/state/qc-annotation.action.type';
import { saveCurrentRowData } from 'src/app/tempDataStore/tempData.action';
import { saveFinalPlanData } from '../trybe-checker/state/checker.action';
import { saveFinalPlans } from 'src/app/home/state/home.action';
import { chekerAction } from '../trybe-checker/state/checker.action.type';
import { TrybeCheckerComponent } from '../trybe-checker/trybe-checker.component';

@Component({
  selector: 'app-final-planning-review',
  templateUrl: './final-planning-review.component.html',
  styleUrls: ['./final-planning-review.component.scss'],
})
export class FinalPlanningReviewComponent implements OnInit {
  columns=[];
  private subscriptions: Subscription[] = [];
  private unsubscribe$: Subject<any> = new Subject<any>();
  dataSource=[];
  rowData:any;
  selectedPlans: any = [];
  packetPlans:any=[];
  currActionData: any;
 rowDatas:any;
  selectedRows: any = [];
  actionName;
  selectedRow: any;
  currHistory={
    "id": 2340,
    "packetId": "2200036-09-SELECT-AF#114",
    "userName": "Surat Checker",
    "actionId": 277,
    "actionName": "Final Planning",
    "processActionInputId": "4771",
    "historyStatus": "Completed",
    "inputDetails": [
        {
            "id": 4025,
            "piId": 4771,
            "paramName": "Final Planning",
            "paramSValue": null,
            "paramIValue": 34598,
            "paramDValue": null,
            "paramBValue": null,
            "url": null,
            "paramType": "I",
            "createdDate": "2022-10-14T10:16:00.000Z",
            "createdBy": "2-botswana-dev-bsc1",
            "modifiedDate": null,
            "modifiedBy": null,
            "paramValue": null
        }
    ],
    "showEdit": false
}


  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    public httpService: HttpService,
    public configService: ConfigService,
    private store: Store<AppState>,
    public alertController: AlertController,
    private modalController: ModalController,
  ) {

    this.subscriptions.push(this.store.pipe(select(getPlansDoneBySuratChecker)).subscribe(data => {
      if (data) {
       this.dataSource=data;
      }
    }));

    this.store.pipe(select(getSelectedPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      if (plan) {
        console.log('Selected plan');
        console.log(plan);
        this.selectedPlans = plan;
        console.log(this.selectedPlans);
      }
    });

    console.log("this is checker");
    this.packetPlans = this.packetPlans;
    this.rowData = this.rowData;

    console.log(this.packetPlans, "hey");
    console.log(this.rowData, "rowdata");
    this.store.pipe(select(getCurrentAction)).pipe(takeUntil(this.unsubscribe$)).subscribe((action: any) => {
      if (action) {
        console.log('Selected action');
        console.log(action);
        this.currActionData = action;
      }
    });

    this.columns = [
      { name: 'Packet ID', prop: 'packetId' },
      // { name: 'Cts', prop: 'cts' },
      { name: 'Pkt Grade', prop: 'pktGrade' },
      { name: 'Lot No', prop: 'lotNo' },
      { name: 'Status', prop: 'pktStatus' },
      { name: 'PredLimit', prop: 'predLimit' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'OutwardDate', prop: 'outDate' }
    ];
   }

  ngOnInit() {
 
    this.store.dispatch(
      saveCurrentRowData({ rowData: this.selectedRow})
    );
    this.store.dispatch(QCActions.getAllPacketWhoseFinalPlanningIsDoneBYSuratChecker());
    this.store.pipe(select(getCurrentRowData)).pipe(takeUntil(this.unsubscribe$)).subscribe((rd: any) => {
      if (rd) {
        console.log('getCurrentRowData');
        
        console.log(rd);
        this.rowDatas = rd;
        this.rowData = rd[0];
      }
    });
    
      // 
    
    
  }

  
 async showCheckerFinalPlan(currHistory) { 
    if (this.selectedRow.id) {
      this.store.dispatch(HomeActions.getPacketPlanStoneIfIsFinal({ packetId: this.selectedRow.id }))
      const popover = await this.modalController.create({
        component: TrybeCheckerComponent,
        cssClass: 'trybeCheckerPage',
        componentProps: { packetId: this.selectedRow.id, status: 'Completed', msg: 'ViewOnlyChecker', isConfirm:true },
      });
      await popover.present();
      popover.onDidDismiss()
        .then((res) => {
          if (res.data) {

          }
        });
      // this.router.navigate(['/trybechecker'], { queryParams: { packetId: this.selectedRow.id, status: 'Completed', msg: 'ViewOnlyChecker', isConfirm:true} });
    }
  }
  async onSelectionChanged(event) {
    console.log(this.selectedRow);
    console.log(this.selectedRow);
    
    this.store.pipe(select(getSelectedPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      if (plan) {
        console.log('Selected plan');
        console.log(plan);
        this.selectedPlans = plan;
        console.log(this.selectedPlans);
    
      }
    });
   
    // console.log(event)
    event.row = event.selectedRowsData[0];
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.selectedRowsData[0];
    // this.store.dispatch(
    //   saveFinalPlans({ plans: this.selectedRow, packetId:this.selectedRow.id})
      
    //   );
    
      
    
    if (this.selectedRow.id) {
     
      this.store.dispatch(HomeActions.getPacketPlanStoneIfIsFinal({ packetId: this.selectedRow.id }))
      const popover = await this.modalController.create({
        component: TrybeCheckerComponent,
        cssClass: 'trybeCheckerPage',
        componentProps: { packetId: this.selectedRow.id, status: 'Completed', msg: 'ViewOnlyChecker', isConfirm:true },
      });
      await popover.present();
      popover.onDidDismiss()
        .then((res) => {
          if (res.data) {
  
          }
        });
      // this.router.navigate(['/trybechecker'], { queryParams: { packetId: this.selectedRow.id, status: 'Done', msg: 'ViewOnlyChecker' } });
    }
    
    }
    
   
    }
  

  

