import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConfigService } from '../services/config/config.service';
import { HttpService } from '../services/http/http.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-receive-for-party',
  templateUrl: './receive-for-party.component.html',
  styleUrls: ['./receive-for-party.component.scss'],
})
export class ReceiveForPartyComponent implements OnInit {
  barcode: string;
  columns: any;
  remarkList: any;
  isUpdateDisable: boolean = true;
  dataSource: any;
  dropDownRemarkData: any = {
    name: 'Remark',
    placeText: 'Select Remark',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };
  dropdown: any = {};
  payload: any = [];
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private modalController: ModalController,
    private configService: ConfigService
  ) {
    this.columns = [
      { name: 'lotNo', prop: 'lotNo' },
      { name: 'packetId', prop: 'packetId' },
      { name: 'cts', prop: 'cts' },
      { name: 'pktGrade', prop: 'pktGrade' },
      { name: 'barcodeNo', prop: 'barcodeNo' },
      { name: 'Default Remark Value', prop: 'remark' },
    ];
  }

  ngOnInit() {
    this.fecthRemarkData();
  }

  barcodeDetection(event) {
    this.barcode = event.toUpperCase();
    this.gridData(this.barcode);
  }

  onSelectionChanged(event) {
    console.log(event);
  }
  clearBarcode() {}

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable =
      Object.keys(this.dropdown).length === 0 ? true : false;
  }

  dropDownSelection(evt: any) {
    console.log('dropDownSelection');
    console.log(evt);
    if (evt.property == 'Remark') {
      this.dataSource.forEach((item) => {
        item.cvdStatus = evt?.arrayList[0]?.name;
      });
      this.setValuesOfDropdown(evt);
    }
  }

  fecthRemarkData() {
    let endpoint: string = `dbc-party-master/getRemarksOfDBCList`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        console.log(d);
        if (resultCode == 200) {
          this.dropDownRemarkData['options'] = res?.data;
          this.remarkList = res?.data;
        } else {
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

  onReceive() {
    const uniquePackets = {};

    this.dataSource.forEach((id) => {
      const packetId = id?.id;
      const remarks = id?.cvdStatus;

      if (!uniquePackets[packetId]) {
        uniquePackets[packetId] = {
          packetId,
          remarks,
        };
      }
    });

    this.payload = Object.values(uniquePackets);
    this.onReceiveapi(this.payload);
  }

  gridData(barcode: string) {
    if (!!barcode) {
      let endpoint: string = `packet/getPacketListByDBCVoucherLabel?dbcVoucherLabel=${barcode}`;
      this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
        if (res) {
          let resultCode: number = res.statusCode;
          let d: any = res.data;
          console.log(d);
          if (resultCode == 200) {
            this.dataSource = res?.data || [];
          } else {
            this.configService.showToast('Error', res.error);
          }
        }
      });
    }
  }

  async onReceiveapi(payload) {
    if (payload) {
      let response = await this.httpService.postDataUsingPromise(
        true,
        'packet/returnPacketsAndMarkRemarks',
        payload
      );
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.dropDownRemarkData.selectedData='';
        this.barcode = null;
        this.dataSource=[];
        this.configService.showToast('success', response.data);
      } else {
        console.error(response);
        this.configService.showToast('error', response.error.message);
      }
    }
  }
}
