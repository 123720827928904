import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponentModule } from '../components/header/header.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { ProcessactionconfigComponent } from './processactionconfig.component';
import { FooterModule } from '../footer/footer.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { SingleMultiDropdownModule } from '../components/single-multi-dropdown/single-multi-dropdown.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';
import { FilterModule } from "../pipes/filter/filter.module";



@NgModule({
    declarations: [ProcessactionconfigComponent],
    imports: [
        CommonModule,
        HeaderComponentModule,
        IonicModule,
        WidthAlertModule,
        FormsModule,
        FooterModule,
        DataGridComponentModule,
        SingleMultiDropdownModule,
        WidthAlertModule,
        DropdownModule,
        SingleMultiDropdownModule,
        FilterModule
    ]
})
export class ProcessActionConfigModule { }
