import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
//import { ConfigService } from '../service/config.service';
import { ModalController, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { from, throwError } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, tap } from "rxjs/operators";
import { myVirtualTaskActions } from "src/app/components/my-virtual-task/state/my-virtual-task.actions.type";
import { virtualAPIs } from "src/app/components/my-virtual-task/state/my-virtual-task.data";
import { chekerAction } from "src/app/components/trybe-checker/state/checker.action.type";
import { TrybemodalComponent } from "src/app/components/trybemodal/trybemodal.component";
import { LoaderService } from "src/app/services/loader/loader.service";
import { HttpService } from "../../services/http/http.service";
import { DashboardState } from "../state/home.reducer";
import { saveAllUsers } from "./home.action";
import { HomeActions } from "./home.action-type";

@Injectable()
export class HomeEffects {
  constructor(
    private navCtrl: NavController,
    private actions: Actions,
    public httpService: HttpService,
    private loaderService: LoaderService,
    private store: Store<DashboardState>,
    public router: Router,
    private storage: Storage,
    private modalController: ModalController
  ) {
    console.log("***");
  }

  getAllStoneOrPacketActionst$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getAllStoneActions),
      concatMap(action =>
        from(this.httpService.fetchUserActionsbyStone([])).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((res: any) => {
        if (!!res.error && res.error.length > 0) {
          console.log(res.error);
        }
        // this.httpService.dismissLoader()
      }),
      map((data: any) => {
        if (!!data.error && data.error.length > 0) {
          data = [];
        }
        if (!data.data) {
          data = [];
        }
        return HomeActions.saveAllStoneActions({ allStoneActions: data });
      })
    )
  );
  getAllUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getAllUsers),
      concatMap(action =>
        from(
          this.httpService.getUsersAMachineByActionId(
            null,
            {
              listOfPacketIds: [],
            },
            0
          )
        ).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((res: any) => {
        console.log(res);
        // this.httpService.dismissLoader()
      }),
      map((data: any) => {
        if (!!data.error && data.error.length > 0) {
          data = [];
        }
        return saveAllUsers({ allUsers: data });
      })
    )
  );
  getIncomingPackets$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getIncomingPackets),
      tap((response: any) => {}),
      concatMap(action =>
        from(this.httpService.getIncomingPackets(action)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode === 200) {
              this.httpService.updateIncomingGridData(res.data);
            }
            return res.data;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader()
      }),
      map((data: any) => {
        if (!!data && !!data.error) {
          data = {};
        }
        return HomeActions.saveIncomingPackets({ incomingStones: data.vouchersList, totalIncomingStones: data.totalCount });
      })
    )
  );

  getIncomingPacketVoucherWise$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getIncomingPacketsVoucherwisePacket),
      tap(x => {}),

      concatMap(action =>
        from(this.httpService.getIncomingStonesVoucherwisePacket(action.voucherId, action)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        console.log(response);
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return HomeActions.saveIncomingPacketsVoucherwise({ incomingStonesVoucherwise: data.packetsList });
      })
    )
  );

  getOutgoingPackets$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getOutgoingPackets),
      concatMap(action =>
        from(this.httpService.getCurrentOutgoingPackets(action)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            console.log(res);
            if (res.statusCode === 200) {
              this.httpService.updateOutgoingGridData(res.data);
            }
            return res.data;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader()
      }),
      map((data: any) => {
        if (!!data && !!data.error && data.error.length > 0) {
          data = [];
        }
        return HomeActions.saveOutgoingPackets({ outgoingStones: data.vouchersList, totalOutgoingStones: data.totalCount });
      })
    )
  );

  getReceivedPackets$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getReceivedPackets),
      concatMap(action =>
        from(this.httpService.fetchReceivedPackets(action.limit, null)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode === 200) {
              this.httpService.updateWithMeGridData(res.data);
            }
            return res.data;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        if (!!data && !!data.error && data.error.length > 0) {
          data = [];
        }
        return HomeActions.saveReceivedPackets({ receivedStones: data.packetData, totalReceivedStones: data.countOfTotalPackets });
      })
    )
  );

  issueVirtualPacket$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.issueVirtualPacket),
      tap(async (res: any) => {
        //await this.httpService.showLoader()
      }),
      concatMap(action =>
        from(this.httpService.issueVirtualPacket(action.packetDetails)).pipe(
          map(async res => {
            this.httpService.getErrorAndDisplayIt(res);
            let resData = {
              data: res.data,
            };

            if (res.statusCode === 200) {
              //await this.httpService.dismissLoader();
              // this.store.dispatch(HomeActions.getUpdatedReceivedPackets({ packetIDs: action.packetDetails.listOfPacketIds }));
              await this.showModal(true, res.message, resData);
            } else {
              //await this.httpService.dismissLoader();
              // await this.showModal(false, res.message, resData);
            }
            return !!res.data ? res.data : {};
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap(async (res: any) => {
        // await this.httpService.dismissLoader();
      }),
      mergeMap((dta: any) => {
        // let limit = { limit: 500, page: 1 };
        return [myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null })];
      })
    )
  );
  issuePacket$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.issuePacket), //Not using it
      tap((res: any) => {
        //await this.httpService.showLoader()
      }),
      concatMap(action =>
        from(this.httpService.issuePacket(action.packetDetails, action.isProcessVirtual)).pipe(
          map(res => {
            let resData = {
              data: res.data,
            };

            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode === 200) {
              //await this.httpService.dismissLoader();
              this.store.dispatch(HomeActions.getUpdatedReceivedPackets({ packetIDs: action.packetDetails.listOfPacketIds }));
              this.showModal(true, res.message, resData);
            } else {
              //await this.httpService.dismissLoader();
              // await this.showModal(false, res.message, resData);
            }
            return action;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((res: any) => {
        // await this.httpService.dismissLoader();
      }),
      mergeMap((dta: any) => {
        console.log("...mergeMap...");
        console.log(dta);
        console.log(dta.pageInfo);
        let limit = { limit: 50, page: 0 };
        return [
          HomeActions.getOutgoingPackets({ limit: limit }),
          HomeActions.getPacketCount(),
          HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
          HomeActions.getReceivedPackets({ limit: dta.pageInfo }),
        ];
      })
    )
  );
  getPacketCount$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getPacketCount),
      concatMap(action =>
        from(this.httpService.fetchPacketCount()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        if (!!data.error && data.error.length > 0) {
          data = [];
        }
        return HomeActions.savePacketCount({ packetCount: data });
      })
    )
  );
  getUserActions$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getUserActions),
      concatMap(action =>
        from(
          // this.httpService.fetchUserActions()
          this.httpService.fetchUserActionsByUserId(action.userId)
        ).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        if (!!data.error && data.error.length > 0) {
          data = [];
        }
        return HomeActions.saveUserActions({ userActions: data });
      })
    )
  );

  getLockedPackets$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getLockedPackets),
      concatMap(action =>
        from(
          // this.httpService.fetchUserActions()
          this.httpService.fetchLockedPacketByDeptId()
        ).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        if (!!data?.error && data?.error.length > 0) {
          data = [];
        }
        return HomeActions.saveLockedPackets({ lockedPacket: data });
      })
    )
  );

  getActionWisePendingAndCompletedPacketsCountList$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getActionWisePendingAndCompletedPacketsCountList),
      concatMap(action =>
        from(this.httpService.getActionWisePendingAndCompletedPacketsCountList()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        if (!!data && !!data.error && data.error.length > 0) {
          data = [];
        }
        return HomeActions.saveActionWisePacketStatusList({ actionsStatusList: data });
      })
    )
  );

  getActionWisePacketList$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getActionWisePacketList),
      concatMap(action =>
        from(this.httpService.getActionWisePacketList(action.actionId)).pipe(
          map(res => {
            console.log("%cDEBUGGING1------", "color:orange;font-weight:bold;");
            console.log(res, res.data);
            this.httpService.getErrorAndDisplayIt(res);
            return {
              packetData: res.data,
              actionId: action.actionId,
            };
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        return HomeActions.saveActionWisePacketList({ actionPacketList: data.packetData, actionId: data.actionId });
      })
    )
  );

  getActionNStatusWisePacketList$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getActionNStatusWisePacketList),
      concatMap(action =>
        from(this.httpService.getActionAndStatusWisePacketList(action.actionId, action.status)).pipe(
          map(res => {
            console.log("%cDEBUGGING2------", "color:orange;font-weight:bold;");
            console.log(res, res.data);
            this.httpService.getErrorAndDisplayIt(res);
            return {
              packetData: res.data,
              actionId: action.actionId,
            };
          }),
          catchError(error => {
            //
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        return HomeActions.saveActionNStatusWisePacketList({ actionPacketList: data.packetData, actionId: data.actionId });
      })
    )
  );

  submitParameter$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.submitParameter),
      tap((res: any) => {
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.fetchReceivedPacketsByParameter(action.actionId, action.userId)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((res: any) => {
        // this.httpService.dismissLoader();
      }),
      mergeMap((dta: any) => {
        console.log(dta["__zone_symbol__value"]);
        let withMeStones = !!dta["__zone_symbol__value"] ? dta["__zone_symbol__value"] : dta;
        return [
          HomeActions.saveReceivedPackets({ receivedStones: withMeStones, totalReceivedStones: withMeStones.length }),
          // HomeActions.getReceivedPackets(),
          // HomeActions.getPacketCount(),
          // HomeActions.getActionWisePendingAndCompletedPacketsCountList()
        ];
      })
    )
  );

  askForPackets$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.requestForStone),
      tap(d => {
        console.log("Show Loader for Rquest Stone");

        //
      }),
      concatMap(action =>
        from(
          this.httpService.askForStone(action.data).pipe(
            map(res => {
              this.httpService.getErrorAndDisplayIt(res);
              if (res.statusCode === 200) {
                this.showModal(true, res.message, {});
              } else {
                // this.showModal(false, res.message, {})
              }
              return res.data;
            }),
            catchError(error => {
              this.httpService.getErrorAndDisplayIt(error);
              return throwError(error);
            })
          )
        )
      ),
      tap((res: any) => {
        // setTimeout(() => {
        //
        // }, 500);
        console.log("Hide Loader for Rquest Stone");
      }),
      mergeMap((d: any) => {
        let pageInfo = {
          limit: 50,
          page: 0,
        };
        return [HomeActions.getIncomingPackets(pageInfo)];
      })
    )
  );
  async showModal(state, message, data) {
    if (state) {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
          data: data,
        },
        cssClass: "trybe_modal_print",
      });
      modal.onDidDismiss().then(dataReturned => {
        if (dataReturned !== null) {
          console.log(dataReturned);
        }
      });
      return await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      return await modal.present();
    }
  }

  getPacketPlanStoneIfIsFinal$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getPacketPlanStoneIfIsFinal),

      concatMap(action => {
        return this.httpService.getPacketPlanStoneIfIsFinal(action.packetId).pipe(
          map(res => {
            let resultData = res.data;
            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode == 200) {
              //this.httpService.dismissLoader();
            }
            let packetPlans: any = resultData;
            let newPacketPlans = packetPlans.map((d: any) => {
              let newPacketPlanStones: any = [];
              let newOb = { ...d };
              let seqNum = newOb["seqNo"];
              if (!!d.packetPlanStones) {
                newPacketPlanStones = d.packetPlanStones.map((m: any, i: any) => {
                  let obj = { ...m };
                  obj["selected"] = obj["selected"] ? obj["selected"] : false;
                  if (!obj["fluorescence"]) {
                    obj["fluorescence"] = null;
                  }
                  return obj;
                });
              }
              newOb["seqNo"] = isNaN(seqNum) ? seqNum : "";
              newOb["selected"] = newOb["selected"] ? newOb["selected"] : false;
              newOb["isExpand"] = newOb["selected"] ? newOb["selected"] : true;
              return newOb;
            });
            return !!newPacketPlans ? { resultData: newPacketPlans, packetId: action.packetId } : {};
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        );
      }),
      mergeMap((data: any) => {
        return [
          HomeActions.saveFinalPlans({
            plans: data.resultData,
            packetId: data.packetId,
          }),
          chekerAction.saveFinalPlanData({
            packetPlanstones: data.resultData,
          }),
        ];
      })
    )
  );

  getUrlsOfStnFilesfromIds$ = createEffect(() =>
    this.actions.pipe(
      ofType(HomeActions.getUrlsOfStnFilesfromIds),
      tap(d => {
        console.log("Show Loader for Rquest Stone");

        //
      }),
      concatMap(action =>
        from(
          this.httpService.getUrlsOfStnFilesfromIds(action.arrayOfIds).pipe(
            map(res => {
              this.httpService.getErrorAndDisplayIt(res);
              // if (res.statusCode === 200) {
              //     this.showModal(true, res.message, {})
              // } else {
              //     // this.showModal(false, res.message, {})
              // }
              console.log("res for stn");
              console.log(Object.values(res.data));
              return res.data;
            }),
            catchError(error => {
              this.httpService.getErrorAndDisplayIt(error);
              return throwError(error);
            })
          )
        )
      ),
      tap((res: any) => {
        // setTimeout(() => {
        //
        // }, 500);
        console.log("Hide Loader for Rquest Stone");
      }),
      filter(data => !!data),
      map(data => HomeActions.saveUrlsOfStnFilesfromIds({ arrayOfUrls: data }))
      // mergeMap((d: any) => {
      //     let pageInfo = {
      //         limit: 50,
      //         page: 0
      //     }
      //     return [
      //         HomeActions.getIncomingPackets(pageInfo)
      //     ];
      // })
    )
  );
}
