import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderComponentModule } from '../components/header/header.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { SingleMultiDropdownModule } from '../components/single-multi-dropdown/single-multi-dropdown.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { DxDataGridModule, DxPopoverModule } from 'devextreme-angular';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';
import { PacketRepairingComponent } from './packet-repairing.component';
import { NewDropDownComponent } from '../components/new-drop-down/new-drop-down.component';
import { NewDropDownModule } from '../components/new-drop-down/new-drop-down.module';
import { TrybeCardModule } from '../components/trybecard/trybecard.module';
import { PrintskeltonModule } from '../printskelton/printskelton.module';
import { PrintPageModule } from '../components/print-page/print-page.module';

@NgModule({
  declarations: [PacketRepairingComponent],
  entryComponents: [],
  exports: [PacketRepairingComponent],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DxDataGridModule,
    DataGridComponentModule,
    WidthAlertModule,
    ScannerinputModule,
    DxPopoverModule,
    IonicModule,
    HeaderComponentModule,
    SingleMultiDropdownModule,
    NewDropDownModule,
    TrybeCardModule,
    PrintskeltonModule,
    PrintPageModule,

  ],
})
export class PacketRepairingodule {}
