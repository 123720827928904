import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { twoDecimalvaluesPipe } from './two-decimal-values.pipe';



@NgModule({
  declarations: [twoDecimalvaluesPipe],
  imports: [
    CommonModule
  ],
  exports: [twoDecimalvaluesPipe]
})
export class twoDecimalvaluesPipeModule { }
