import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConfigService } from "../services/config/config.service";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { LoaderService } from "../services/loader/loader.service";
const BY_PASS_TOKEN = [
  "api/price_policy/calculatePriceES"
]
@Injectable()
export class MainInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService, private router: Router, private loaderService: LoaderService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.showSpinner();
    const isByPass = BY_PASS_TOKEN.find(el => request.url.includes(el));
     const token = localStorage.getItem("token");
    console.log(token);
        if (token && !isByPass) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError(error => {
        const errorMessage: string = error?.error?.message || error?.error?.error;
        switch (error?.status) {
          case 401:
            this.configService.showToast("error", "Error");
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            this.router.navigate(["/login"]);
            window.location.reload();
            break;
          case 404:
            this.configService.showToast("error", errorMessage);
            break;
          default:
            this.configService.showToast("error", errorMessage);
        }
        this.loaderService.hideSpinner();
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.hideSpinner();
      })
    );
  }
}
