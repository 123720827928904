import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { HeaderComponentModule } from '../components/header/header.module';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgMentionsModule } from 'ng-mentions';
import { MentionModule } from 'angular-mentions';
import { LabfinalcheckComponent } from './labfinalcheck.component';
import { WidthAlertComponent } from '../width-alert/width-alert.component';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { SingleMultiListModule } from '../single-multi-list/single-multi-list.module';
import { SingleMultiDropdownModule } from "../components/single-multi-dropdown/single-multi-dropdown.module";
import { ColorInspectionModule } from '../color-inspection/color-inspection.module';




@NgModule({
    declarations: [LabfinalcheckComponent],
    exports: [LabfinalcheckComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        WidthAlertModule,
        DataGridComponentModule,
        HeaderComponentModule,
        SingleMultiListModule,
        ColorInspectionModule,
        SingleMultiDropdownModule
    ]
})
export class LabFinalCheckModule { }
