import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { LoadingController, ToastController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, from } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { routeMasterAPIs } from "src/app/packet-route-master/state/packet-route-master.data";
import { AppState } from "src/app/reducers";
import { environment } from "../../../environments/environment";
import { ConfigService } from "../config/config.service";
import { ACTIONNAMES } from "../endpoint.enum";
// import { WithMeGridData } from "../../components/actions/issue/state/issue.data";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  loader: any;
  latticeUrl = "";
  trybeUrl = "";
  ACTIONNAMES = ACTIONNAMES;
  centralFileUrl = "";
  PricingUrl = "";
  latticeProdURL = "";
  token: string = "";
  loading: any;
  fromLocation = environment.fromLocation;
  latToken: string = "";
  //isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public isAuthenticated = new BehaviorSubject<any>(false);
  public isAuthenticated$ = this.isAuthenticated.asObservable();

  public userStored = new BehaviorSubject<any>(false);
  public userStored$ = this.userStored.asObservable();
  public incomingGridData: any = {
    totalCount: 0,
    vouchersList: [],
  };
  public getVoucherGridData: any = {
    totalCount: 0,
    vouchersList: [],
  };
  public incomingGridDataStore = new BehaviorSubject<any>(this.incomingGridData);
  public getVoucherDataStore = new BehaviorSubject<any>(this.getVoucherGridData);
  public currentIncomingGridData$ = this.incomingGridDataStore.asObservable();
  public getCurrentVoucherGridData$ = this.getVoucherDataStore.asObservable();
  public outgoingGridData: any = {
    totalCount: 0,
    vouchersList: [],
  };
  public dataObj: any = {
    countOfTotalPackets: 0,
    packetData: [],
  };
  public getPacketReportGridData: any = {
    totalCount: 0,
    packetData: [],
  };
  public getWagesMasterData: any = {
    totalCount: 0,
    wagesRuleMasterList: [],
  };
  private outgoingDataStore = new BehaviorSubject<any>(this.outgoingGridData);
  public currentOutgoingGridData$ = this.outgoingDataStore.asObservable();
  private getPacketReportUiDataStore = new BehaviorSubject<any>(this.getPacketReportGridData);
  private getWagesMasterStore = new BehaviorSubject<any>(this.getWagesMasterData);
  public currentPacketReportUi$ = this.getPacketReportUiDataStore.asObservable();
  public currentWagesMaster$ = this.getWagesMasterStore.asObservable();
  public totalincomingPackets = 0;
  public incomingPackets = [];
  public withMeGridData: any = {
    countOfTotalPackets: 0,
    packetData: [],
  };
  public withMeGridDataStore = new BehaviorSubject<any>(this.withMeGridData);
  public currentWithMeGridData$ = this.withMeGridDataStore.asObservable();
  public totalWithMePackets = 0;
  public withMePackets = [];
  public headers: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  public headers2: any = {
    //for file upload
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };
  public tranferReturnAssignGridData: any = {
    packetData: [],
    countOfTotalPackets: 0,
  };
  public tranferReturnAssignGridDataStore = new BehaviorSubject<any>(this.tranferReturnAssignGridData);
  public currenttranferReturnAssignGridData$ = this.tranferReturnAssignGridDataStore.asObservable();

  public islatticeAuthenticated = new BehaviorSubject<any>(false);
  public islatticeAuthenticated$ = this.islatticeAuthenticated.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private storage: Storage,
    private toastCtrl: ToastController,
    public configService: ConfigService,
    private loadingCtrl: LoadingController,
    private fireStore: AngularFirestore
  ) {
    this.getLatticeBaseUrl();
    this.getTrybeBaseUrl();
    this.getCentralFileUrl();
    this.getPricingUrl();
    this.getLatticeProdUrl();
    this.isAuthenticated$.subscribe(res => {
      console.log("------isAuthenticated------");
      console.log(res);
      if (res) {
        // this.token = localStorage.getItem("token");
        this.loadToken();
        this.userStored.next(true);
        // this.logout();
        if (this.router.url != "/home") {
          this.router.navigate(["/home"], { replaceUrl: true });
          console.log("%cauthentication approved and going to home/dasboard page", "color:green;font-size:16px");
        }
      } else {
        this.token = "";
        this.logout();
        console.log("%cauthentication failed", "color:red;font-size:16px");
        //this.router.navigateByUrl('/login');
      }
    });
    this.islatticeAuthenticated$.subscribe(result => {
      if (result) {
        this.loadLatToken();
      } else {
        this.latToken = "";
        // console.log('%cauthentication failed', 'color:red;font-size:16px');
      }
    });
  }

  async getLatticeBaseUrl() {
    this.latticeUrl = await this.configService.getBaseUrl();
  }

  async getTrybeBaseUrl() {
    this.trybeUrl = await this.configService.getTrybeBaseUrl();
  }

  async getCentralFileUrl() {
    this.centralFileUrl = await this.configService.getCentralFileUrl();
  }

  async getPricingUrl() {
    this.PricingUrl = this.configService.getPricingUrl();
  }

  async getLatticeProdUrl() {
    this.latticeProdURL = await this.configService.getBaseLatticeProdUrl();
  }
  async loadToken() {
    // let token = await this.storage.get('token');
    let token = await localStorage.getItem("token");
    if (token) {
      this.token = token;
    }
  }
  async loadLatToken() {
    this.latToken = await this.storage.get("latToken");
  }
  async showToast(status, message) {
    if (status == "success") {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        // color: 'success',
        cssClass: "trybe-toast-success",
      });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        // color: 'danger',
        cssClass: "trybe-toast-error",
      });
      await toast.present();
    }
  }

  async logout() {
    // this.userStored.next(false);
    this.store.dispatch({ type: "Logout" });
    if (!!this.storage["_db"]) {
      await this.storage.clear();
      this.router.navigate(["/login"]);
    }
  }

  async handleError(errorRes: HttpErrorResponse) {
    let error = errorRes.error;
    console.log(error);
    let msg = "";
    if (!!error) {
      if (error.statusCode === 401) {
        console.log("401");
        msg = error.message;
        return { error: msg };
      } else if (error.statusCode === 422) {
        msg = error.message;
        return { error: msg };
      } else if (errorRes.statusText === "Unknown Error" || errorRes.message.indexOf("Http failure response") > -1) {
        console.log("%c" + errorRes.message + "", "color:red;");
        console.log(this.store);
        console.log(this.storage);
        // this.showToast('error', 'Service/Server Unavailable')
        // this.store.dispatch({ type: 'Logout' });
        // await this.storage.clear();
        // this.router.navigate(['/login'])
        // await this.logout();
        return { error: errorRes.message };
      } else {
        msg = "Something went wrong. Please try again!";
        //this.showError('Something went wrong. Please try again!');
        return { error: msg };
      }
    }
  }
  async getErrorAndDisplayIt(e: any) {
    console.log(e);
    let errMessage = !!e.message ? e.message : !!e.error && !!e.error.message ? e.error.message : "Something went wrong.";
    if (e && !!e.status && e.status != 200) {
      console.error(e);
      if (e && !!e.status && e.status == 401) {
        this.logout();
        return;
      }
      this.showToast("error", errMessage);
    }
    if (e && !!e.statusCode && e.statusCode != 200) {
      console.error(e);
      if (e && !!e.statusCode && e.statusCode == 401) {
        this.logout();
        return;
      }
      this.showToast("error", errMessage);
    }
  }

  async showLoader() {
    // const loading = await this.loadingCtrl.create({
    //   message: 'Please Wait..',
    // });
    // await loading.present();
    this.loader = await this.loadingCtrl.create({
      message: "Please wait...",
    });
    this.loader.present();
  }

  async dismissLoader() {
    this.loader?.dismiss()?.catch(() => {});
  }

  async getPlanTypes() {
    return [
      { value: 3, label: "Rough Return" }, // 1 for backend
      { value: 2, label: "Final Planning" }, // 2 for backend
      { value: 6, label: "Intermediate Planning" }, // 1 for backend
      { value: 4, label: "Tops" }, // 4 for backend
      { value: 5, label: "By Product" }, // 5 for backend
    ];
  }

  login(userName, password): Observable<any> {
    let jsonObj = {
      username: userName,
      password: password,
      companyName: this.fromLocation,
      appName: "trybe",
    };
    return this.http.post(this.trybeUrl + "auth/login", jsonObj).pipe(shareReplay());
  }
  loginOnLattice(userObj: any): Observable<any> {
    return this.http.post(this.latticeProdURL + "user-service/api/user/login", userObj).pipe(shareReplay());
  }
  getUserById(userId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "users/findByUserID?userId=" + userId, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  

  getIncomingPackets(data: any, params: any = null): Observable<any> {
   
    let payload: any = {};
    let size = 0,
      pageNumber = 0;
    if (!!data && !!data.limit) {
      size = data.limit;
    }
    if (!!data && !!data.limit) {
      pageNumber = data.page;
    }
    return this.http
      .post(this.trybeUrl + "packet/getIncomingVouchersListForUser?limit=" + size + "&page=" + pageNumber, params, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  updateIncomingGridData(newData: any) {
    this.incomingGridDataStore.next(newData);
  }
  updateVoucherGridData(newData: any) {
    this.getVoucherDataStore.next(newData);
  }
  updateOutgoingGridData(newData: any) {
    this.outgoingDataStore.next(newData);
  }
  updateWithMeGridData(newData: any) {
    this.withMeGridDataStore.next(newData);
  }
  updateTranferReturnAssignGridData(newData: any) {
    this.tranferReturnAssignGridDataStore.next(newData);
  }
  updateGetReportPacketDataForUi(newData: any) {
    this.getPacketReportUiDataStore.next(newData);
  }
  updateWagesMasterData(newData: any) {
    this.getWagesMasterStore.next(newData);
  }
  getOutgoingPackets(data): Observable<any> {
   
    let payload: any = {};
    let size = 0,
      pageNumber = 0;
    if (!!data && !!data.limit) {
      size = data.limit;
    }
    if (!!data && !!data.limit) {
      pageNumber = data.page;
    }
    return this.http
      .post(this.trybeUrl + "packet/getOutgoingPackets?limit=" + size + "&page=" + pageNumber, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  async fetchIncomingGridData(data: any, params: any = null) {
    try {
     
      let size = 20,
        pageNumber = 0;
      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }

      let url = "packet/getIncomingVouchersListForUser?limit=" + size + "&page=" + pageNumber;

      const response = await this.http.post(this.trybeUrl + url, params, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }
  async getIncomingStones(data: any) {
    try {
     
      let size = 20,
        pageNumber = 0,
        payload = data;
      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }
      const response = await this.http.post(this.trybeUrl + "packet/getIncomingPackets?limit=" + size + "&page=" + pageNumber, payload, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }
  async getOutgoingStones(data) {
    try {
     
      let size = 0,
        pageNumber = 0;
      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }
      const response = await this.http.post(this.trybeUrl + "packet/getOutgoingPackets?limit=" + size + "&page=" + pageNumber, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  fetchUserActions(): Observable<any> {
   

    // return this.http.get(this.trybeUrl + "users/getUerActions", {
    return this.http
      .get(this.trybeUrl + "users/getUserActions", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchListOfActions(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "users/fetchAllActionsByUserId", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchUserActionsByUserId(userId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "users/fetchAllActionOnBasisOfUser", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchLockedPacketByDeptId(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "packet/getHoldPacketForCurrDept", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchHistoryDataByPacketId(packetId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "processActionHistoryLog/findHistoriesByPacketIdAndDept/" + packetId, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  removePacketPlan(packetId): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `packetPlan/deletePacketPlanAndAllItsStoneUsingPacketPlanID/${packetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  removePartPacketPlan(id): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `packetPlan/removePacketPlanStoneUsingId/${id}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  addRemarkForPacketPlan(Remark, Grade, listOfPacketPlaneIdList): Observable<any> {
   
    let jsonObj = {
      remark: Remark,
      grade: Grade,
      listOfPacketPlaneIdList: listOfPacketPlaneIdList,
    };
    return this.http
      .post(this.trybeUrl + "packetPlanStone/addRemarkForPacketPlanStone", jsonObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  addRemarkForPacketPlanDrawer(Remark, packetPlanStoneID): Observable<any> {
   
    let jsonObj = {
      remark: Remark,
      listOfPacketPlaneIdList: packetPlanStoneID,
    };
    console.log(jsonObj);
    return this.http
      .post(this.trybeUrl + "packetPlanStone/addRemarkOnPacketPlanStone", jsonObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  addRemarkForPacketPlanDrawerFinalTab(Remark, packetPlanID, noOfCut): Observable<any> {
   
    let jsonObj = {
      noOfCuts: noOfCut,
      remark: Remark,
      id: packetPlanID,
    };
    console.log(jsonObj);
    return this.http
      .post(this.trybeUrl + "packetPlanStone/updateRemarkAndNoOfCuts", jsonObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getUserStoneActions(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "users/fetchUserStoneActions", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getActionFormsByIds(actionIds): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "users/getActionFormsByIds", actionIds, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchUserActionsbyStone(listOfPktIDs): Observable<any> {
   
    let jsonObj = {
      listOfPacketIds: listOfPktIDs,
    };
    return this.http
      .post(this.trybeUrl + "packet/fetchActionsByStonesAndUser", jsonObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchNextActionForVirtualTasks(listOfPktIDs): Observable<any> {
   
    let jsonObj = {
      listOfPacketIds: listOfPktIDs,
    };
    return this.http
      .post(this.trybeUrl + "packet/fetchStoneActionbyVirtualStatus", jsonObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  /*getUsersByDepartment(deptId): Observable<any> {
   
    // return this.http.get(this.trybeUrl + "users/getUsersByDepartment/" + deptId, {
    return this.http.get(this.trybeUrl + "users/findUserByActionId/" + deptId, {
      headers: new HttpHeaders(this.headers),
    }).pipe(shareReplay());

  }*/
  // getUsersAMachineByActionId(actionID): Observable<any> {
  //  
  //   return this.http.get(this.trybeUrl + "users/findUserAndItsMachineByActionId/" + actionID, {
  //     headers: new HttpHeaders(this.headers),
  //   }).pipe(shareReplay());

  // }
  getUsersAMachineByActionId(actionID, selectedPacketIds, isVirtual: number): Observable<any> {
    let apiEndPoint: string = "",
      endParameter = "?actionId=" + actionID;
    let apiForNormalProcessUser: string = "users/findUserAndItsMachineByActionId",
      apiForVirtualProcessUser: string = "users/findUserAndItsMachineByVirtualActionId";
    if (!!actionID) {
      endParameter = endParameter;
    } else {
      endParameter = "";
    }
    if (isVirtual && isVirtual === 1) {
      apiEndPoint = apiForVirtualProcessUser + "" + endParameter;
    } else {
      apiEndPoint = apiForNormalProcessUser + "" + endParameter;
    }

   
    return this.http
      .post(this.trybeUrl + "" + apiEndPoint, selectedPacketIds, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getUsersAMachineByVirtualActionId(actionID, selectedPacketIds): Observable<any> {
    let endParameter = "?actionId=" + actionID;
    if (!!actionID) {
      endParameter = endParameter;
    } else {
      endParameter = "";
    }
   
    return this.http
      .post(this.trybeUrl + "users/findUserAndItsMachineByVirtualActionId" + endParameter, selectedPacketIds, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  receive(listOfPktIds: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/receive", listOfPktIds, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  receiveVoucherwise(listOfVouIds: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/receivePacketthroughVoucher", listOfVouIds, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  reject(listOfPktIds: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/reject", listOfPktIds, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  issuePacket(issueObj: any, isProcessVirtual: any, page?: string): Observable<any> {
   
    let endpoint = "packet/issue";
    let queryparam: string = "?isVirtual=1";
    if (isProcessVirtual == 1) {
      endpoint = endpoint + "" + queryparam;
    }
    if (page == "Spectrum") endpoint = "packet/assignForCheckerReview";
    return this.http
      .post(this.trybeUrl + "" + endpoint, issueObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  issueVirtualPacket(issueObj: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "virtual-task/saveVirtualTask", issueObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchReceivedPackets(data: any, payload: any): Observable<any> {
    console.log("fetchReceivedPackets");
    console.log(data);
   
    let size = 100,
      pageNumber = 0;
    if (!!data && !!data.limit) {
      size = data.limit;
    }
    if (!!data && !!data.page) {
      pageNumber = data.page;
    }
    return this.http
      .post(this.trybeUrl + "packet/getReceivedPackets?limit=" + size + "&page=" + pageNumber, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
    //
    // let payload = {};
    // return this.http
    //   .post(this.trybeUrl + 'packet/getReceivedPackets?limit=' + data.limit + '&page=', payload, {
    //     headers: new HttpHeaders(this.headers),
    //   })
    //   .pipe(shareReplay());
  }
async fetchWithMeGridData(data: any, payload: any, pageName?: any) {
  try {
    let size = 20, 
        pageNumber = 0, 
        url: string;
    
    if (data && data.limit) {
      size = data.limit;
    }
    if (data && data.page) {
      pageNumber = data.page;
    
    }

    if (pageName === ACTIONNAMES.generateReport) {
      url = `${this.trybeUrl}generate-reports?limit=${size}&page=${pageNumber}`;
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } else {
      if ((pageName === "Transfer") || pageName === ACTIONNAMES.managerTrasnfer || pageName === ACTIONNAMES.return || pageName === ACTIONNAMES.thirdPartyTrf) {
        url = `${this.trybeUrl}packet/transferPacketFetch?limit=${size}&page=${pageNumber}`;
      } else {
        url = `${this.trybeUrl}packet/getReceivedPackets?limit=${size}&page=${pageNumber}`;
      }
      const response = await this.http.post(url, payload, { headers: this.headers }).toPromise();
      return response;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

  fetchPacketCount(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "packet/getResponsibleUserPacketCount", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getUsersByDept(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "users/getUsersByDepartment/7", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  //Outgoing Stones
  getCurrentOutgoingPackets(data: any, params: any = null): Observable<any> {
   
    let payload: any = {};
    return this.http
      .post(this.trybeUrl + "packet/getOutGoingPacketVouchers?limit=" + data.limit + "&page=", params, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  async fetchOutgoingGridData(data: any, params: any = null) {
    try {
     
      let size = 20,
        pageNumber = 0;
      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }

      let url = "packet/getOutGoingPacketVouchers?limit=" + size + "&page=" + pageNumber;
      // if(!!params){
      //   url = url+params
      // }

      const response = await this.http.post(this.trybeUrl + url, params, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  //remove Outgoing stones
  removeIssuedPacket(packetId, voucherId): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `packet/deleteIssue/${packetId}/${voucherId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  //remove Outgoing stones Voucher wise
  removeIssuedPacketvoucherwise(voucherId): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `packet/deleteIssue/${voucherId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  //Delete Item from Completed Fluorescence Outgoing stones
  deletePacket(packetId, actionProcessIds): Observable<any> {
    let jsonObj = {
      packetId: packetId, //1
      listOfProcessActionInputId: actionProcessIds, //[3, 4, 5, 6 ]
    };
   
    return this.http
      .post(this.trybeUrl + "/processActionInput/deleteProcessActionInputFromPacketId", jsonObj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  findByStoneParameterName(paramArrayObj): Observable<any> {
    let obj = {
      listOfStoneParameterName: paramArrayObj,
    };
   
    return this.http
      .post(this.trybeUrl + `packet/findStoneParameterValueByName`, obj, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  saveStoneParamater(data, actionId, formType, isUpdate?): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    if (formType == "Multipart") {
      httpOptions = {
        headers: new HttpHeaders({
          enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
          Authorization: this.token,
        }),
      };
    }
    return this.http.post(this.trybeUrl + `processActionInput/${isUpdate ? "editProccessActionInputFromPacketId" : "inputReceiverByActionId"}/${actionId}`, data, httpOptions).pipe(shareReplay());
  }

  removeParam(actionInputsData): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `processActionInput/deleteProcessActionInputFromPacketId`, actionInputsData, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getActionWisePendingAndCompletedPacketsCountList(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "packet/getActionWisePendingAndCompletedPacketsCount", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getActionWisePacketList(actionId, payload?): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packet/getPacketByActionAndStatus?actionId=${actionId}&status=`, payload || null, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  getActionAndStatusWisePacketList(actionId, status, payload?): Observable<any> {
    let actionStatus = !!status ? status : "";
   
    return this.http
      .post(this.trybeUrl + `packet/getPacketByActionAndStatus?actionId=${actionId}&status=${actionStatus}`, payload || null, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getPdParamsByStoneId(stoneId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `premium-discount/getPremiumDiscountOnBasisOfPacketPlanStoneId/${stoneId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAllParametersForSingleStone(transType): Observable<any> {
    console.log("getAllParametersForSingleStone");
    console.log(this.latToken);

   
    return this.http
      .get(
        this.trybeUrl +
          // `master-service/api/company_sspd_settings/findAllByCompany?transType=${transType}`,
          `company-sspd-settings/findAllByCompany?transType=${transType}`,

        {
          headers: new HttpHeaders(this.headers),
        }
      )
      .pipe(shareReplay());
  }

  getStockStone(): Observable<any> {
    this.headers.Authorization = this.latToken;
    return this.http
      .get(this.latticeProdURL + `master-service/api/stone/findInStockByUserId`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  updateStoneParamater(data, actionId, formType): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    if (formType == "Multipart") {
      httpOptions = {
        headers: new HttpHeaders({
          enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
          Authorization: this.token,
        }),
      };
    }
    return this.http.post(this.trybeUrl + `processActionInput/updateInputParameters/${actionId}`, data, httpOptions).pipe(shareReplay());
  }

  fetchPacketDetailsById(packetId: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packet/getPacketDetailsFromPacketIdForAutoReceive`, packetId, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchTrybeDepartments(payload): Observable<any> {
   
    // return this.http.get(this.latticeUrl + `user-service/api/department/findAllByAppName?appName=trybe`, {
    return this.http
      .post(this.trybeUrl + `users/fetchAllDepartmentByAppName/trybe`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchRoles(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `tbl-role/roledetails?appsName=trybe`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  transferPacket(postData: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/transfer", postData, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  fetchReceivedPacketsByParameter(actionId, userId): Observable<any> {
    let endParameter = "?actionId=" + actionId;
    let payload: any = {};
    if (!!actionId) {
      endParameter = endParameter;
    } else if (!!userId) {
      endParameter = "?userId=" + userId;
    }
   
    return this.http
      .post(this.trybeUrl + `packet/getReceivedPackets${endParameter}`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  //sortData Functions

  sortStrings(data: any, key: string, sortType: string) {
    console.log(data);
    if (sortType.toUpperCase() === "ASC") {
      data.sort(function (a: any, b: any) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b.name) return 1;
        return 0;
      });
    } else {
      data.sort(function (a: any, b: any) {
        if (a[key] < b[key]) return 1;
        if (a[key] > b.name) return -1;
        return 0;
      });
    }
    return data;
  }

  sortNumbers(data: any, key: string, sortType: string, page: string) {
    if (page == "checker") {
      data = [...data];
    }

    if (sortType.toUpperCase() === "ASC") {
      data.sort(function (a: any, b: any) {
        return a[key] - b[key];
      });
    } else {
      data.sort(function (a: any, b: any) {
        return b[key] - a[key];
      });
    }
    console.log(data);
    return data;
  }
  //check type of a variable
  checkType(variable: any) {
    let typeString: string = Object.prototype.toString.call(variable).split(" ")[1];
    let typeOfVar = typeString.substring(0, typeString.length - 1).toLowerCase();
    return typeOfVar;
  }
  getPacketMovementByPacketId(data): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packet/getPacketDetailsAndMovementAndHistoryAndProcessActionInputDetailsFromPacketId`, data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getWorkquality(data: any): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };
    return this.http.post(this.trybeUrl + `workQualityMaster/uploadWorkQualityMasterCsv`, data, httpOptions).pipe(shareReplay());
  }

  fetchworkqualityData(data): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `workQualityMaster/findAll?limit=${data.limit}&page=${data.page}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  fetchChekerPlanData(data): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlanStone/findAllByPacketIdOnBasisOfIsSelectedDeptWise/${data.packetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  saveChekerPlanData(data): Observable<any> {
   
    console.log(this.token);
    console.log(data.packetplanId);

    return this.http
      .post(
        this.trybeUrl + `packetPlan/setPacketPlanToIsFinal/${data.packetPlanId}`,
        {},
        {
          headers: new HttpHeaders(this.headers),
        }
      )
      .pipe(shareReplay());
  }

  fetchPacketPlansByPacketId(data: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlanStone/findAllByPacketId`, data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  findPacketPlanStoneByPacketIdForSmartRecut(data: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlanStone/findPacketPlanStoneByPacketIdForSmartRecut`, data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchManualPacketPlansByPacketId(data: any): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlanStone/findPacketPlanSelectedOnPacket?packetId=${data}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchPacketByPacketIdForSpectrum(packetID: any): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `plan-review-after-checker/getPlanData/${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  uploadECSVFile(data, packetID, formType, isAppend, actionName?: string): Observable<any> {
    let url = isAppend ? this.trybeUrl + `packetPlan/uploadPacketAdvisorCsv?isAppend=1&packetId=${packetID}` : this.trybeUrl + `packetPlan/uploadPacketAdvisorCsv?packetId=${packetID}`;

    if (actionName == "Smart Recut Planning" || actionName == this.ACTIONNAMES.anyCut) url = this.trybeUrl + `packetPlan/ecsvFromSmartRecutFile/${packetID}`;

    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };

    if (formType == "Multipart") {
      httpOptions = {
        headers: new HttpHeaders({
          enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
          Authorization: this.token,
        }),
      };
    }

    return this.http.post(url, data, httpOptions).pipe(shareReplay());
  }

  // https://trybe-dev.atishae.com/damageMaster/findAll
  // fetchAllDamageMaster(): Observable<any> {
  //  
  //   return this.http.get(this.trybeUrl + `damageMaster/findAll`, {
  //     headers: new HttpHeaders(this.headers)
  //   }).pipe(shareReplay());
  // }
  // fetchAllWorkQualities(): Observable<any> {
  //  
  //   return this.http.get(this.trybeUrl + `workQualityMaster/findAll`, {
  //     headers: new HttpHeaders(this.headers)
  //   }).pipe(shareReplay());
  // }

  fetchProcessNoteKeyByActionId(data: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `getParameterValueUsingActionIdAndParameterName`, data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchProgramByPacketIdAndPlanStoneId(packetId, planStoneId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "packetPlanProgram/findByPlanStone?planStoneId=" + planStoneId + "&packetId=" + packetId, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  updateDetailsInPacketPlan(data): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `packetPlan/UpdatePacketPlan`, data, httpOptions).pipe(shareReplay());
  }
  fetchAllDamageMaster(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `damageMaster/findAllGroupByDamageName`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  fetchAllWorkQualities(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `workQualityMaster/findAllGroupByRemark`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  createNewPackets(postData: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/createNewStoneAfterBifurcationForMultiplePacket", postData, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  setWinnerPlan(packetPlanID: number): Observable<any> {
   
    let api = "packetPlan/setPacketPlanToIsSelected/" + packetPlanID;
    return this.http
      .post(
        this.trybeUrl + api,
        {},
        {
          headers: new HttpHeaders(this.headers),
        }
      )
      .pipe(shareReplay());
  }

  fetchSetPacketPlan(pakcetId: number): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlan/getPacketPlanStone/${pakcetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getPacketPlanStoneIfIsFinal(pakcetId: number): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlan/getPacketPlanStoneIfIsFinal/${pakcetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  addNewPacketsAfterBifurcation(data: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `packet/createNewStoneAfterBifurcationForMultiplePacket`, data, httpOptions).pipe(shareReplay());
  }

  updateFluCLORClarityInPacketPlan(data: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `packetPlanStone/updateFloursenceClarityColorFromPacketId`, data, httpOptions).pipe(shareReplay());
  }

  updateFLCLClAFromPacketIdForAllSelectedPlan(data: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `packetPlanStone/updateFloursenceClarityColorFromPacketIdForAllSelectedPlan`, data, httpOptions).pipe(shareReplay());
  }

  uploadXpsFileToServer(data): Observable<any> {
    let endParameter = "";
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    }; //Multipart FILE
    return this.http.post(this.trybeUrl + `packetPlanStone/uploadXpsOrHtmlFile`, data, httpOptions).pipe(shareReplay());
  }

  uploadFileToServer(data, param): Observable<any> {
    let endParameter = "";
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    }; //Multipart FILE
    if (param.for == "uploadXpsOrHtmlFile") {
      return this.http.post(this.trybeUrl + `packetPlanStone/uploadXpsOrHtmlFile`, data, httpOptions).pipe(shareReplay());
    } else if (param.for == "uploadCapFile") {
      if (!!param.packetID) {
        endParameter = param.packetID;
      }
      return this.http.post(this.trybeUrl + `packetPlan/uploadCapFile/${endParameter}`, data, httpOptions).pipe(shareReplay());
    }
  }

  uploadSignerPDF(data, packetId): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };
    return this.http.patch(this.trybeUrl + `plan-review-after-checker/addSignerPdfFile/${packetId}`, data, httpOptions).pipe(shareReplay());
  }

  uploadSignerOtherFile(data, packetId): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };
    return this.http.patch(this.trybeUrl + `plan-review-after-checker/addSignerOtherFile/${packetId}`, data, httpOptions).pipe(shareReplay());
  }

  fetchUploadedFile(param: any): Observable<any> {
    if (param.for == "uploadXpsOrHtmlFile") {
     
      return this.http
        .get(this.trybeUrl + `packetPlanStone/getUploadedXpsOrHtmlFile/${param.packetPlanStoneId}`, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } else if (param.for == "uploadCapFile") {
     
      return this.http
        .get(this.trybeUrl + `packetPlan/getUploadedCapFile/${param.packetID}`, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    }
  }
  savePDParameterPacketPlan(data: any): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `premium-discount/save`, data, httpOptions).pipe(shareReplay());
  }
  // /packetPlanStone/findAllByPacketIdOnBasisOfIsSelected/2122

  saveSignerBestPlans(postData): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlan/setPacketPlanToIsSelected`, postData, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  fetchSignerPlans(packetPlanId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlanStone/findAllByPacketIdOnBasisOfIsSelected/${packetPlanId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getUserSubmittedPlans(userId, payload): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlanStone/findAllPacketPlanAccordingToUser?userId=${userId}`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  setPacketPlanToIsFinal(data): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlan/setPacketToFinalOrSetToIsSelected`, data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  setCheckerPlanFinal(packetPlanId): Observable<any> {
   
    return this.http
      .post(
        this.trybeUrl + `packetPlan/setPacketPlanToIsFinal/${packetPlanId}`,
        {},
        {
          headers: new HttpHeaders(this.headers),
        }
      )
      .pipe(shareReplay());
  }

  selectPacketPlansForCheckerView(data): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlan/selectPacketPlans`, data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  findAllTheBidSubmittedByUser(packetId, userId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlanStone/findAllTheBidSubmittedByUser?packetId=${packetId}` + `&userId=${userId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  copyPacketPlan(planData, packetId): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packetPlan/copyPacketPlanAndItsChild/${packetId}`, planData, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getUsersByPacketID(packetID): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packet/return/${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAnnotaionDetailsByPacketId(packetID): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `qc-packet-images/getAllImagesOnBasisOfPacketId?packetId=${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAllAnnotationOnBasisOfUserId(packetID): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `qc-annotation-logs/getAllAnnotationOnBasisOfUserId?packetId=${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAnnotationqueriesLogs(packetID): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `qc-annotation-logs/getAllAnnotation?packetId=${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAllAnnotationLogs(packetID): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `qc-annotation-logs/getAllAnnotation?packetId=${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchAutoReceivePacket(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packet/getAutoReceivePacket`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchBarcode(payload): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `packet/getBarcodeForPacket`, payload, httpOptions).pipe(shareReplay());
  }

  async getHowMuchTimePacketHasTakenInEachDept(payload, noOfRecords, pageNumber) {
    try {
     
      const response = await this.http
        .post(this.trybeUrl + `report/getHowMuchTimePacketHasTakenInEachDept?limit=${noOfRecords}&offset=${pageNumber}`, payload, { headers: new HttpHeaders(this.headers) })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadImages(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };

    try {
     
      const response = await this.http.post(this.trybeUrl + `upload`, data, httpOptions).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchReportByUsers(data, pageinfo) {
    try {
     
      let size = 20,
        pageNumber = 0;
      if (!!pageinfo && !!pageinfo.limit) {
        size = pageinfo.limit;
      }
      if (!!pageinfo && !!pageinfo.limit) {
        pageNumber = pageinfo.offset;
      }

      const response = await this.http.post(this.trybeUrl + "report/getPacketCountForEachUser?limit=" + size + "&offset=" + pageNumber, data, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchReportByDept(data, pageinfo) {
    try {
     
      let size = 20,
        pageNumber = 0;
      if (!!pageinfo && !!pageinfo.limit) {
        size = pageinfo.limit;
      }
      if (!!pageinfo && !!pageinfo.limit) {
        pageNumber = pageinfo.offset;
      }

      const response = await this.http.post(this.trybeUrl + "report/getPacketCountInEachDept?limit=" + size + "&offset=" + pageNumber, data, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  saveDetailsFormData(postData): Observable<any> {
   
    console.log("saveDetailsFormData-SERVICESSSSSSSSSSSSSSSSSS");
    console.log(postData);
    if (!!postData && !!postData.data && !!postData.data.id) {
      //Update
      return this.http
        .post(this.trybeUrl + `qc-action-input-details/saveQcDetails?packetId=${postData.packetId}`, postData.data, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } else {
      //save/create
      return this.http
        .post(this.trybeUrl + `qc-action-input-details/saveQcDetails?packetId=${postData.packetId}`, postData.data, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    }
  }

  async deleteCommentAnnotation(annotationId) {
    try {
     
      const response = await this.http
        .delete(this.trybeUrl + `qc-annotation-logs/remove/${annotationId}`, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteAnnoationById(annotationDetailsId) {
    //
    // return this.http.delete(this.trybeUrl + `qc-annotation/delete/${annotationDetailsId}`, {
    //   headers: new HttpHeaders(this.headers)
    // }).pipe(shareReplay());
    try {
     
      const response = await this.http
        .delete(this.trybeUrl + `qc-annotation/delete/${annotationDetailsId}`, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteAnnotatedImage(imageId) {
    try {
     
      const response = await this.http
        .delete(this.trybeUrl + `qc-packet-images/delete/${imageId}`, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveAnnotatedImage(data, packetId) {
    try {
     
      const response = await this.http.post(this.trybeUrl + "qc-packet-images/saveAnnotatedImage?packetId=" + packetId, data, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveComments(data) {
    try {
     
      const response = await this.http
        .post(this.trybeUrl + "qc-annotation-logs/saveComments", data, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  getAnnotatedImageFormDetailsAndInclusionDetails(packetId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "qc-action-input/getAnnotatedImageFormDetailsAndInclusionDetails?packetId=" + packetId, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async fetchDetailsFormData(packetId) {
    try {
     
      const response = await this.http.get(this.trybeUrl + `qc-action-input/getAnnotatedImageFormDetailsAndInclusionDetails?packetId=${packetId}`, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  getCapFileForAnnoation(packetId) {
   
    return this.http
      .get(this.trybeUrl + `qc-action-input/getCapFileUrl?packetId=${packetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async uploadCapFile(data, packetId) {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };

    try {
     
      const response = await this.http.post(this.trybeUrl + `qc-action-input/saveCapFile?packetId=${packetId}`, data, httpOptions).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadChatFile(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };

    try {
     
      const response = await this.http.post(this.trybeUrl + `upload`, data, httpOptions).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async closeAnnotations(packetId) {
    try {
     
      const response = await this.http.get(this.trybeUrl + `qc-annotation-logs/closeAllAnnotation?packetId=${packetId}`, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async specialIssues(data) {
    try {
     
      const response = await this.http
        .post(this.trybeUrl + "packet/specialIssue", data, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  fetchPreplanningSummary(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packet/getAllThePacketIssuedByQcPlanner`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async onConfirmPlan(packetId) {
    try {
     
      console.log(this.headers);
      const response = await this.http.post(this.trybeUrl + `packet/transferPacketToBotswanaChecker?packetId=${packetId}`, null, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  getAllPacketWhoseFinalPlanningIsDoneBYSuratChecker(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packet/getAllPAcketWhoseFinalPlanningIsDoneBySuratChecker`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  sendChat(chatData): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `botswana-checker-chat/save`, chatData, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchChatConv(packetId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `botswana-checker-chat/findByPacketId/${packetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  askForStone(payload: any): Observable<any> {
   
    console.log(payload);
    return this.http
      .post(this.trybeUrl + `packet/issuingApacketfromManger`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getIncomingStonesVoucherwisePacket(voucherId, data): Observable<any> {
   
    let payload: any = {};
    let size = 500,
      pageNumber = 0;
    if (!!data && !!data.limit) {
      size = data.limit;
    }
    if (!!data && !!data.limit) {
      pageNumber = data.page;
    }
    return this.http
      .get(this.trybeUrl + `packet/getPacketsListForVoucher?voucherId=${voucherId}&limit=` + size + "&page=" + pageNumber, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  fetchReworkProcess(payload: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packet/getIsDuplicateAction`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  checkIfUserIsManager(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `users/checkUserIsManager`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }
  saveDataToDB(isTrybe: boolean, endpoint: string, payload: any, formType: any): Observable<any> {
    //POST Method with file save
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    if (formType == "Multipart") {
      httpOptions = {
        headers: new HttpHeaders({
          enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
          Authorization: this.token,
        }),
      };
    }
    return this.http.post(api, payload, httpOptions).pipe(shareReplay());
  }
  saveData(isTrybe: boolean, endpoint: string, payload: any): Observable<any> {
    //POST Method without file save
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(api, payload, httpOptions).pipe(shareReplay());
  }
  updateData(isTrybe: boolean, endpoint: string, payload: any): Observable<any> {
    //PUT Method
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.put(api, payload, httpOptions).pipe(shareReplay());
  }
  patchData(isTrybe: boolean, endpoint: string, payload: any): Observable<any> {
    //PUT Method
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.patch(api, payload, httpOptions).pipe(shareReplay());
  }
  deleteData(isTrybe: boolean, endpoint: string): Observable<any> {
    //Delete Method without file save
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.delete(api, httpOptions).pipe(shareReplay());
  }
  deleteDataOnPacketPlan(isTrybe: boolean, endpoint: string): Observable<any> {
    //Delete Method without file save
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.delete(api, httpOptions).pipe(shareReplay());
  }
  fetchData(isTrybe: boolean, endpoint: string): Observable<any> {
    let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
    let api: string = `${siteURL}${endpoint}`;
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    // console.log(api, this.headers);
    console.log(this.headers);
    return this.http.get(api, httpOptions).pipe(shareReplay());
  }

  fetchDataUsingPost(isTrybe: boolean, endpoint: string): Observable<any> {
    let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
    let api: string = `${siteURL}${endpoint}`;
   

    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    // console.log(api, this.headers);
    console.log(this.headers);
    return this.http.post(api, httpOptions).pipe(shareReplay());
  }

  deleteAssignedVirtualTask(virtualId): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `virtual-task/deleteinVirtualandActionHistorylog/${virtualId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getData(isTrybe: boolean, endpoint: string, payload: any): Observable<any> {
    let siteURL: string = "",
      api: string = "";
   
    if (!this.token) return this.http.get("");
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(api, payload, httpOptions).pipe(shareReplay());
  }

  getDataWithoutPayload(isTrybe: boolean, endpoint: string): Observable<any> {
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(api, httpOptions).pipe(shareReplay());
  }

  getDataWithoutPayloadForGet(isTrybe: boolean, endpoint: string): Observable<any> {
    let siteURL: string = "",
      api: string = "";
   
    siteURL = isTrybe ? this.trybeUrl : this.latticeUrl;
    api = siteURL + "" + endpoint;
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(api, httpOptions).pipe(shareReplay());
  }

  async getFilesFromFileSystem(payload: any) {
    try {
     
      const response = await this.http
        .post(this.trybeUrl + `packet/getFilesFromFileSystem`, payload, {
          headers: this.headers,
        })
        .toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }
  async postDataUsingPromise(isTrybe: boolean, endpoint: string, payload) {
    try {
      let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
     
      let api: string = `${siteURL}${endpoint}`;
      let httpOptions = {
        headers: new HttpHeaders(this.headers),
      };
      const response = await this.http.post(api, payload, httpOptions).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }
async getDataUsingPromise(isTrybe: boolean, endpoint: string) {
  try {
    let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
    let api: string = `${siteURL}${endpoint}`;

    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };

    const response = await this.http.get(api, httpOptions).toPromise();
    return response;
  } catch (error) {
    return await error;
  }
}

  async uploadXmlFileForplanning(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };

    try {
     
      const response = await this.http.post(this.trybeUrl + `packetPlanStone/uploadXpsOrHtmlFile`, data, httpOptions).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadJpgFileForplanning(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };

    try {
     
      const response = await this.http.post(this.trybeUrl + `packetPlanStone/uploadImageFile`, data, httpOptions).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadSpectrumFileForPlanning(data, packedid) {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA",
        Authorization: this.token,
      }),
    };

    try {
     
      const response = await this.http.post(this.trybeUrl + `/plan-review-after-checker/addSignerPdfFile/${packedid}`, data, httpOptions).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDataForFinalDrawer(id) {
    try {
     
      console.log(this.headers);
      const response = await this.http.post(this.trybeUrl + `packetPlanStone/getRemarkAndNoOfCuts/${id}`, null, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async sendDataForMarkAsSold(payload) {
    try {
     
      console.log(this.headers);
      const response = await this.http
        .post(this.trybeUrl + `packetPlanStone/markStoneAsMakable`, payload, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchDataUsingPromise(isTrybe: boolean, endpoint: string) {
    try {
      let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
      this.headers.Authorization = await localStorage.getItem("token");
      let api: string = `${siteURL}${endpoint}`;
      let httpOptions = {
        headers: new HttpHeaders(this.headers),
      };
      const response = await this.http.get(api, httpOptions).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  async fetchDataOfTensionFile(isTrybe: boolean, endpoint: string) {
    try {
      let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
      this.headers.Authorization = await localStorage.getItem("token");
      let api: string = `${siteURL}${endpoint}`;
      let httpOptions = {
        headers: new HttpHeaders(this.headers),
      };
      const response = await this.http.get(api, httpOptions).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  getAllStoneMaster(): Observable<any> {
   
    return (
      this.http
        // .get(this.latticeUrl + 'master-service/api/stone-parameter-name/findAll', {
        .get(this.trybeUrl + routeMasterAPIs.parametersEndpoint, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay())
    );
  }
  // ****************************************
  getAllUsers(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "users/getUsersOfTrybe", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async getFileCopy(payload: any) {
    try {
     
      const response = await this.http
        .post(`http://192.168.7.217:3000/fileCopy`, payload, {
          headers: this.headers,
        })
        .toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }
  getStoneMasterDetailById(id): Observable<any> {
    try {
     
      return this.http
        .get(this.latticeUrl + "stone-parameter-value/findByStoneParameterNameId?id=" + id, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }
  // async fetchDataUsingPromise(isTrybe: boolean, endpoint: string) {
  //   try {
  //     let siteURL: string = isTrybe ? this.trybeUrl : this.latticeUrl;
  //    
  //     let api: string = `${siteURL}${endpoint}`;
  //     let httpOptions = {
  //       headers: new HttpHeaders(this.headers),
  //     };
  //     const response = await this.http.get(api, httpOptions).toPromise();
  //     return response;
  //   }
  //   catch (error) {
  //     return await error;
  //   }
  // }

  async findIsLaserMarkingDone(packetId) {
    try {
     
      const response = await this.http.get(this.trybeUrl + `packetPlan/findIsLaserMarkingDone?packetId=${packetId}`, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  deleteDepartmentRuleConfig(id): Observable<any> {
   
    console.log(this.token);

    return this.http
      .post(
        this.trybeUrl + `department-rule-config/remove//${id}`,
        {},
        {
          headers: new HttpHeaders(this.headers),
        }
      )
      .pipe(shareReplay());
  }

  public getUserData(userName: string): Observable<any> {
    return from(
      this.fireStore
        .collection("User")
        .doc(userName)
        .valueChanges()
        .pipe(
          map(data => {
            console.log(data);
            return data;
          })
        )
    );
  }

  public removeUserDocument(userName: string) {
    const userDocRef = this.fireStore.collection("User").doc(userName);

    userDocRef
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch(error => {
        console.error("Error removing document: ", error);
      });
  }

  public getChatMessages(collection: string, collectionId: string): Observable<any> {
    console.log(collection, collectionId, typeof collectionId, typeof collection);
    return from(
      this.fireStore
        .collection(collection)
        .doc(collectionId)
        .collection("chat", res => res.orderBy("timestamp", "asc"))
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(data => {
              const object = data.payload.doc.data();
              object.id = data.payload.doc.id;
              return object;
            });

            // return d.forEach((doc) => {
            //   return { id: doc..id, ...doc.data() }
            // })
          })
        )
    );
  }

  // kept this for observale
  public getChatMessageswithoutDocsId(collection: string, collectionId: string): Observable<any> {
    console.log(collection, collectionId, typeof collectionId, typeof collection);
    return from(
      this.fireStore
        .collection(collection)
        .doc(collectionId)
        .collection("chat", res => res.orderBy("timestamp", "asc"))
        .valueChanges()
        .pipe(shareReplay())
    );
  }

  reviewStoneById(payload: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `review-packet-plan-stone/save`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getPlansAccordingToUsers(id, packedid): Observable<any> {
    try {
     
      return this.http
        .get(this.trybeUrl + `packetPlanStone/findAllPacketPlanAccordingToSigner?userId=` + id + "&packetId=" + packedid, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  fetchOrderForms(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + "order-form/getAll", {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async getSubManagerWorker() {
    try {
     
      const response = await this.http
        .get(this.trybeUrl + "users/getUserListForQcsSubManager", {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  getUrlsOfStnFilesfromIds(arrayOfIds: any): Observable<any> {
   
    return this.http
      .post(
        this.trybeUrl + "processActionInput/getFileThroughCentralSystemForStn",
        { packetIds: arrayOfIds },
        {
          headers: new HttpHeaders(this.headers),
        }
      )
      .pipe(shareReplay());
  }

  getRouteDetailsOfPacket(id: number): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `route-master/getRouteDataOnBasisOfPacketId/${id}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchLabParameters(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `stone-parameter-value/getLabValues`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchColorParameters(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `stone-parameter-value/getColorValues`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchFancyParameters(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `stone-parameter-value/getFancyValues`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchColorShadeParameters(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `stone-parameter-value/getColorShadeValues`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchUserStoneActionsForTransfer(payload: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packet/fetchUserStoneActionsForTransfer`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getLockCriterias(): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `manager-permission-required`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  saveLockCriteria(payload: any, isUpdate?: boolean, id?: any): Observable<any> {
   
    return this.http[isUpdate ? "patch" : "post"](this.trybeUrl + `manager-permission-required/${isUpdate ? id : "save"}`, payload, {
      headers: new HttpHeaders(this.headers),
    }).pipe(shareReplay());
  }

  deleteLockCriteria(id: number): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `manager-permission-required/${id}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  downloadFileFromCentral(url: string) {
    let newUrl = url.split("/");
    let joinedPath = newUrl.slice(1).join("/");
    window.open(`${this.centralFileUrl}${newUrl[0]}/${encodeURIComponent(joinedPath)}`, "_blank");
  }

  fetchCentralData(url: string, payload: any): Observable<any> {
   
    return this.http
      .post(this.centralFileUrl + `${url}`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  fetchVision360File(payload: any): Observable<any> {
   
    return this.http
      .post(this.centralFileUrl + `getVision360File`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  removeIssuedPacketForAutoReceive(packetID): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `packet/deleteAutoReceiveIssue/${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getReturnAccordingToPerson(userId): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packet/returnAccordingToPerson/${userId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  findAllByPacketIdOnBasisOfIsSelectedOfWinning(packetID): Observable<any> {
   
    return this.http
      .get(this.trybeUrl + `packetPlanStone/findAllByPacketIdOnBasisOfIsSelectedOfWinning/${packetID}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getPrincing(url: any, payload: any): Observable<any> {
    let project = environment.project
    if(project == "kg") {
      // let obj = {
      //   "pricingRequestList" : payload,
      //   "startChain" : "MPM",
      //   "basePdChain" : "MPM BASE_PD",
      //   "mixPriceChain" : "MPM MIX",
      //   "type":"MANUFACTURING"
      // }
      return this.http.post(this.PricingUrl, payload).pipe(shareReplay());
    } else {
      return this.http.post(this.PricingUrl, payload).pipe(shareReplay());
    }
    
  }

  getNewCutValue(payload: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `new-cut-master/getByMatchingCriteria`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getNewCutValueOnFinalInspection(payload: any): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + `packet-parameter-on-each-dept/getByMatchingCriteria`, payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getUserRoleManager() {
   
    return this.http
      .get(this.trybeUrl + `users/getAllUserWhoHasRoleManagerTransfer`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async getPDCatValueFromNatts(nattsId) {
    try {
     
      const response = await this.http
        .get(this.trybeUrl + `packetPlan/getPdCat/${nattsId}`, {
          headers: this.headers,
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  getUserMachineOnBasisOfProcessName(payload) {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `userMachine/getUserMachineOnBasisOfProcessName`, payload, httpOptions).pipe(shareReplay());
  }

  async getDepthByShapeId(shapeId) {
    try {
     
      const response = await this.http.get(this.trybeUrl + `master-handler/getDepthByShapeId?shapeId=${shapeId}`, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  getPacketReport() {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(this.trybeUrl + "packet/getPacketReport", httpOptions).pipe(shareReplay());
  }

  getStockReport() {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(this.trybeUrl + "packet/getStockReport", httpOptions).pipe(shareReplay());
  }

  getQcReport() {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(this.trybeUrl + "packet/generateQcReport", httpOptions).pipe(shareReplay());
  }

  getgeneratePlanDataForEachDept(payload): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/getReportForPacketParameter", payload, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async getRatioByShapeId(shapeId) {
    try {
     
      const response = await this.http.get(this.trybeUrl + `master-handler/getRatioByShapeId?shapeId=${shapeId}`, { headers: this.headers }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  assignPropertyAfterRecut(data): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "packet/assignNewPropertyAfterRecut", data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  updatePlannerGrade(data): Observable<any> {
   
    return this.http
      .post(this.trybeUrl + "users/upgradeGrade", data, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  async getPacketReportForUi(data: any, payload: any) {
    try {
     
      let size = 20,
        pageNumber = 0;
      let postData = !!payload ? payload : null;

      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }

      let url = "packet/getPacketReportForUi?limit=" + size + "&page=" + pageNumber;
      // if(!!params){
      //   url = url+params
      // }

      const response = await this.http.post(this.trybeUrl + url, postData, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  async getReprintPacketVoucher(data: any, params: any = null, payload) {
    try {
     
      let size = 20,
        pageNumber = 0;
      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }

      let url = "voucher/getVoucherList?limit=" + size + "&page=" + pageNumber;
      // if(!!params){
      //   url = url+params
      // }

      const response = await this.http.post(this.trybeUrl + url, payload, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  async getDataForWagesMaster(data: any, payload: any = null) {
    try {
     
      let size = 20,
        pageNumber = 0;
      if (!!data && !!data.limit) {
        size = data.limit;
      }
      if (!!data && !!data.limit) {
        pageNumber = data.page;
      }
      // let url =
      //   'packet/getPacketReportForUi?limit=' +
      //   size +
      //   '&page=' +
      //   pageNumber;
      let url;
      // if (isWageMaster) {
      url = "wages/getWagesRuleMaster?limit=" + size + "&page=" + pageNumber;
      // } else {
      //   url = 'wages/getWagesRuleMaster?limit=' + size + '&page=' + pageNumber;
      // }
      // if(!!params){
      //   url = url+params
      // }
      const response = await this.http.post(this.trybeUrl + url, payload, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }

  removePacketBifurcation(packetId): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `packet/removeNewStoneAndItsProperty/${packetId}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAllPackets(payload,actionName): Observable<any> {
    let url;
    if (actionName === ACTIONNAMES.LOSS_LEDGER) {
      url = `packet-weight-ledger/getLossLedger`
    }
    else {
      url = `packet/getAllRefPacket`
    }
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + url, payload, httpOptions).pipe(shareReplay());
  }

  updatePackets(payload): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `packet/modifyPacketsRghCutWt`, payload, httpOptions).pipe(shareReplay());
  }

  scanByBoard(payload): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + ``, payload, httpOptions).pipe(shareReplay());
  }

  addUpdatePartyMaster(payload): Observable<any> {
   
    let httpOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(this.trybeUrl + `tbl-company/save`, payload, httpOptions).pipe(shareReplay());
  }

  deleteUser(id: number): Observable<any> {
   
    return this.http
      .delete(this.trybeUrl + `user/${id}`, {
        headers: new HttpHeaders(this.headers),
      })
      .pipe(shareReplay());
  }

  getAllMasterParameters(): Observable<any> {
    try {
     
      return this.http
        .get(this.trybeUrl + "stone-parameter-master-name/findAll ", {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  postAddUpdateMasterParameters(payload: any): Observable<any> {
    try {
     
      return this.http
        .post(this.trybeUrl + "stone-parameter-master-name/addUpdate", payload, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }
  postAddUpdateParameters(payload: any): Observable<any> {
    try {
     
      return this.http
        .post(this.trybeUrl + "stone-parameter-value/addUpdate", payload, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  postDisableParameters(payload: any): Observable<any> {
    try {
     
      return this.http
        .post(this.trybeUrl + "stone-parameter-value/setDisableParameterValue", payload, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  //parameter masters

  getChildParameterDataByMasterId(ID: number): Observable<any> {
    try {
     
      return this.http
        .get(this.trybeUrl + `stone-parameter-value/findByStoneParameterNameId?id=${ID}`, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  postChildParameterData(payload: any): Observable<any> {
    try {
     
      return this.http
        .post(this.trybeUrl + "stone-parameter-value/addUpdate", payload, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  postDisableChildParameter(payload: any): Observable<any> {
    try {
     
      return this.http
        .post(this.trybeUrl + "stone-parameter-value/setDisableParameterValue", payload, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  getMisMachedPacketbyVoucherId(voucherId): Observable<any> {
    try {
     
      return this.http
        .get(this.trybeUrl + "tally-stock-logs/getNotMatchedPackets?voucherId=" + voucherId, {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  getKgPreVirtualPlanningStatusReportForUi(): Observable<any> {
    try {
     
      return this.http
        .get(this.trybeUrl + "packet/kgPreVirtualPlanningStatusReportForUi", {
          headers: new HttpHeaders(this.headers),
        })
        .pipe(shareReplay());
    } catch (error) {
      return error;
    }
  }

  getProcessesByDepartmentID(depthID: number): Observable<any> {
    return this.http.get(this.trybeUrl + `packet/nextActionOnBasisOfDept/${depthID}`, {
      headers: new HttpHeaders(this.headers),
    });
  }
}
