import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { ModalController } from '@ionic/angular';
import { AppState } from '../reducers';
import { Store, select } from '@ngrx/store';
import { authUser } from '../authStore/auth.selector';
import { getFinalPlanBYPacketID } from '../home/state/home.selector';
import { HomeActions } from '../home/state/home.action-type';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { saveCurrentRowData } from '../tempDataStore/tempData.action';
import { SignerAction } from '../components/trybesigner/state/signer.action.type';
import { getAllPacketPlans } from '../components/trybesigner/state/signer.selector';

@Component({
  selector: 'app-completed-packet-history',
  templateUrl: './completed-packet-history.component.html',
  styleUrls: ['./completed-packet-history.component.scss'],
})
export class CompletedPacketHistoryComponent implements OnInit {
  columns:any;
  dataSource:any;
  userId: number;
  selectedRows: any;
  private subscriptions: Subscription[] = [];
  selectedRow: any;
  selectorSubscription: Subscription;
  refreshGrid: boolean;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private modalController: ModalController,
  ) {
    this.columns = [
      { name: 'Lot No', prop: 'lotNo' },
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      // { name: 'Status', prop: 'historyStatus' },
      { name: 'PredLimit', prop: 'predLimit' },
      { name: 'Action', prop: 'actionName' },
      { name: 'Last Action status', prop: 'historyStatus' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'CreatedDate', prop: 'createdDate' },
    ];
   }

  ngOnInit() {
    this.store.pipe(select(authUser)).subscribe((user) => {
      if (!!user) {
        this.userId = user.id || user.userId;
      }
    });
    this.getPacketData()
  }


  onSelectionChanged(event){
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    this.store.dispatch(
      saveCurrentRowData({ rowData: event.selectedRowsData })
    );
    if(
      !!this.selectedRow &&
      this.selectedRow.historyStatus == 'Completed' &&
      this.selectedRow.actionName == 'Final Planning'
    ) {
  
       this.store
        .pipe(select(getFinalPlanBYPacketID(this.selectedRow.id)))
        .subscribe((data: any) => {
          console.log(data);
          this.store.dispatch(
            HomeActions.getPacketPlanStoneIfIsFinal({
              packetId: this.selectedRow.id,
            })
          );
            this.refreshGrid = true;
            setTimeout(() => {
              this.refreshGrid = false;
            }, 1000);
            if (!!this.selectedRow && !!this.selectedRow.id) {
              this.router.navigate(['/newchecker'], {
                queryParams: { finalPlan: true },
              }); 
            }
          
        });
    
    }
    else if (
      this.selectedRow.historyStatus == 'Completed' &&
      this.selectedRow.actionName == "Planning"){
          let endpoint: string = `packetPlanStone/getPlanningForDonePacket/${this.selectedRow?.id}`;
          this.httpService.fetchData(true,endpoint).subscribe((res: any) => {
            if (res?.statusCode == 200){
              if(res?.data?.packetPlans[0].isFinalPlanningDone==1){
                  this.openCheckerView()
              } 
              else{
                this.openPlanningView()
              }
          }
        });
      
      }
  }
  openPlanningView() {
    // this.router.navigate(['/planning']);
  }
  openCheckerView() {
    this.router.navigate(['/newchecker'], {
      queryParams: { finalPlanWithTabs: true },
    });
  }

  async onPlanChange(data: any) {
    console.log(data);
    console.log(' onPlanClick ');
    console.log(data);
    console.log(this.selectedRow);
    let packetID = this.selectedRow.id;
    await this.router.navigate(['/planning']);
  
      }

  getPacketData(){
    if(this.userId){
      let endpoint: string = `processActionHistoryLog/findHistoriesByUserId/${this.userId}`;
      this.httpService.fetchData(true,endpoint).subscribe((res: any) => {
        if (res?.statusCode == 200) 
        this.dataSource = res.data;
    });
  }
}
}
