import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WorkQualityMasterComponent } from './work-quality-master.component';
import { NgxFormModule } from '../../ngx-form/ngx-form.module';
import { DataGridComponentModule } from '../../data-grid/data-grid.module';
import { HeaderComponentModule } from '../../header/header.module';

@NgModule({
  imports: [CommonModule, 
    IonicModule,
    FormsModule,
    NgxFormModule,
    DataGridComponentModule,
    HeaderComponentModule,
    ReactiveFormsModule
  ],
  declarations: [WorkQualityMasterComponent],
  entryComponents: [WorkQualityMasterComponent],
  exports: [WorkQualityMasterComponent],
  providers: [],
})
export class WorkQualityMasterModule {}
