import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DxDataGridModule, DxLoadPanelModule, DxSelectBoxModule, DxTagBoxModule, DxValidatorModule } from 'devextreme-angular';
import { DataGridComponent } from './data-grid.component';
import { DxTreeListModule, DxTreeListComponent } from "devextreme-angular";
import { DxCheckBoxModule } from 'devextreme-angular';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DxDataGridModule,
    DxTreeListModule,
    DxLoadPanelModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxCheckBoxModule,
    DxValidatorModule
  ],
  declarations: [
    DataGridComponent
  ],
  entryComponents: [
    DataGridComponent
  ],
  exports:[DataGridComponent],
  providers: []
})
export class DataGridComponentModule { }