import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PacketwiseapprovallistComponent } from './components/packetwiseapprovallist/packetwiseapprovallist.component';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'actionsWindow', loadChildren: () => import('../app/actions-window/actions-window.module').then(m => m.ActionsWindowPageModule) },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'loader',
    loadChildren: () => import('./loader/loader.module').then(m => m.LoaderPageModule)
  },
  
  { path: 'packetwiseapprovallist', component: PacketwiseapprovallistComponent },
  
  {
    path: 'trybesigner',
    loadChildren: () => import('./components/trybesigner/trybesigner.module').then(m => m.TrybesignerModule)
  },
  {
    path: 'PD',
    loadChildren: () => import('./components/pd/pd.module').then(m => m.PdModule)
  },
  {
    path: 'trybechecker',
    loadChildren: () => import('./components/trybe-checker/trybe-checker.module').then(m => m.TrybeCheckerComponentModule)
  },
  {
    path: 'qcannotation',
    loadChildren: () => import('./components/qc-annotation/qc-annotation.module').then(m => m.QcAnnotationModule)
  },
  {
    path: 'qcpreplanning',
    loadChildren: () => import('./qc-preplanning/qc-preplanning.module').then(m => m.QcPreplanningModule)
  },
  {
    path: 'preplanningComments',
    loadChildren: () => import('./preplanning-comments/preplanning-comments.module').then(m => m.PreplanningCommentsModule)
  },
  {
    path: 'virtualTask',
    loadChildren: () => import('./components/my-virtual-task/my-virtual-task.module').then(m => m.MyVirtualTaskModule)
  },
  {
    path: 'packetRouteMaster',
    loadChildren: () => import('./packet-route-master/packet-route-master.module').then(m => m.PacketRouteMasterModule)
  },
  {
    path: 'routeMaster',
    loadChildren: () => import('./routemaster/routemaster.module').then(m => m.RoutemasterModule)
  },
  {
    path: 'planning',
    loadChildren: () => import('./planning/planning.module').then(m => m.PlanningPageModule)
  },
  {
    path: 'newchecker',
    loadChildren: () => import('./newchecker/newchecker.module').then( m => m.NewcheckerPageModule)
  },
  {
    path: 'headchecker',
    loadChildren: () => import('./plan-review-by-head-checker/plan-review-by-head-checker.component.module').then( m => m.PlanReviewByHeadCheckerModule),
  },
  {
    path: 'allwithmedata',
    loadChildren: () => import('./allwithmedata/allwithmedata.module').then( m => m.AllwithmedataPageModule)
  },
  {
    path: 'advisorParamMap',
    loadChildren: () => import('./advisor-param-mapping/advisor-param-mapping.module').then( m => m.AdvisorParamMappingModule)
  },
  {
    path: 'manualfinalplanningpage',
    loadChildren: () => import('./manualfinalplanningpage/manualfinalplanningpage.module').then( m => m.ManualfinalplanningpagePageModule)
  },
  {
    path: 'wages-add',
    loadChildren: () => import('../app/components/wages-add/wages-add.module').then( m => m.WagesAddPageModule)
  },
  {
    path: 'parameter-master',
    loadChildren: () => import('./components/masters/parameter-master/parameter-master.module').then( m => m.ParameterMasterModule)
  },
  {
    path: 'lock-master/virtualTask',
    loadChildren: () => import('./components/dynamic-lock-master/dynamic-lock-master.module').then( m => m.DynamicLockMasterModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
