import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-single-multi-list',
  templateUrl: './single-multi-list.component.html',
  styleUrls: ['./single-multi-list.component.scss'],
})
export class SingleMultiListComponent implements OnInit {

  @Input() items: any = [];
  @Input() selectedItem: any;
  @Input() property: string;
  @Input() name: string;//optional
  @Input() isMultiple: boolean;
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

  @Input() isDisable: any;
  @Input() keyName: any;
  @Input() class: any;
  public itemIds: any;
  public allItems: any = [];
  searchdropdownItems: string;
  selectedItems: any[] = [];
  isAllSelected: boolean = false;
  isAnyItemsSelected: boolean = false;
  isSearchable: boolean = true;
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    console.log('ngOnInit');
    console.log(this.selectedItem);
    this.allItems = [...this.items];
    let idObj: object = {};
    let ids: number[] = this.selectedItem.map(d => {
      idObj[d.id] = d.id;
      return d.id;
    });
    ids = Object.values(idObj);
    this.makeSelectionChange(ids);

  }


  onToggleAll(e: any, items: any) {
    this.searchdropdownItems = "";
    // for (let i = 0; i < this.items.length; i++) {
    //   this.items[i].checked = e.detail.checked;
    // }
    if (e.detail.checked) {
      this.isAllSelected = true;
      this.onSelectAll(items);
    } else {
      this.isAllSelected = false;
      this.onDeSelectAll(items);
    }
  }
  onSelectAll(items: any) {
    this.searchdropdownItems = "";
    this.itemIds = [];
    items.forEach(el => {
      this.itemIds.push(el.id || el.ID);
    });
    let returnMap = {
      property: this.property,
      idList: this.itemIds,
      selectedArray: items,
    }
    if (!!this.name) {
      returnMap['name'] = this.name;
    }
    this.makeSelectionChange(this.itemIds);
    // if (!this.isMultiple) {
    //   this.onSelectionChange.emit(this.selectedItem.map(res => res[this.keyName])[0])
    // } else {
    this.onSelectionChange.emit(returnMap);
    // }
  }
  onDeSelectAll(items: any) {
    this.searchdropdownItems = "";
    this.itemIds = [];
    let returnMap = {
      property: this.property,
      idList: this.itemIds,
      selectedArray: this.selectedItems,
    }
    if (!!this.name) {
      returnMap['name'] = this.name;
    }
    this.makeSelectionChange(this.itemIds);
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    this.onSelectionChange.emit(returnMap);
  }
  onItemChange(e, currentData) {
    this.searchdropdownItems = "";
    console.log('onItemChange');
    console.log(e, e.detail.checked);
    this.itemIds = [];
    if (!this.isMultiple) {
      console.log(this.isMultiple);
      if (e.detail.checked) {
        this.selectedItems = [];
        this.selectedItems.push(currentData);
      } else {
        let currRecordIndex = this.selectedItems.findIndex((i: any) => i.id == currentData.id);
        if (currRecordIndex > -1) {
          this.selectedItems.splice(currRecordIndex, 1);
        }
      }
      // this.makeSelectionChange(this.selectedItem);
    } else {
      if (e.detail.checked) {
        this.selectedItems.push(currentData);
      } else {
        let currRecordIndex = this.selectedItems.findIndex((i: any) => i.id == currentData.id);
        if (currRecordIndex > -1) {
          this.selectedItems.splice(currRecordIndex, 1);
        }
      }
    }
    this.selectedItems.forEach(el => {
      this.itemIds.push(el.id);
    });
    // this.makeSelectionChange(this.itemIds);
    let returnMap: object = {
      property: this.property,
      idList: this.itemIds,
      selectedArray: [...this.selectedItems].map(d => {
        let obj = {
          id: d.id
        }
        obj[this.property] = d[this.property];
        return obj;
      }),
    }
    if (!!this.name) {
      returnMap['name'] = this.name;
    }

    console.log(this.isMultiple, currentData, this.selectedItems, this.itemIds);
    // if (!this.isMultiple) {
    //   this.onSelectionChange.emit(this.selectedItem.map(res => res[this.keyName])[0])
    // } else {
    //   this.onSelectionChange.emit(obj);
    // }
    this.onSelectionChange.emit(returnMap);
    // if (!this.isAllSelected) {
    //   if (e.detail.checked) {
    //     this.onSelect(currentData);
    //   } else {
    //     this.onDeSelect(currentData);
    //   }
    // }
  }
  /* onSelect(item: any) {
     this.itemIds = [];
     if (!this.selectedItem || this.selectedItem.length === 0) {
       this.selectedItem.push(item);
     }
     this.selectedItem.forEach(el => {
       this.itemIds.push(el.id);
     });
     let obj = {
       property: this.property,
       idList: this.itemIds,
       selectedArray: this.selectedItem,
     }
     console.log(this.itemIds, this.selectedItem, obj);
     //  this.selected = this.ids.map(res => res[this.selectedKey]);
     if (!this.isMultiple) {
       this.onSelectionChange.emit(this.selectedItem.map(res => res[this.keyName])[0])
     } else {
       this.onSelectionChange.emit(obj);
     }
   }
   onDeSelect(item: any) {
     this.itemIds = [];
     this.selectedItem.forEach(el => {
       this.itemIds.push(el.id);
     });
     let obj = {
       property: this.property,
       idList: this.itemIds,
       arrayList: this.selectedItem,
     }
     console.log(this.itemIds, this.selectedItem, obj);
     if (!this.isMultiple) {
       this.onSelectionChange.emit(this.selectedItem.map(res => res[this.keyName])[0])
     } else {
       this.onSelectionChange.emit(obj);
     }
   }
   getAllValues() {
     console.log('getAllValues');
     console.log(this.items);
     console.log(this.selectedItem);
   }*/
  makeSelectionChange(selectIds) {
    this.searchdropdownItems = "";
    console.log('makeSelectionChange');
    console.log(selectIds);
    let ids = [];
    ids = selectIds;
    if (!this.isMultiple) {
      this.allItems = this.allItems.map(d => {
        if (ids.indexOf(d.id) > -1) {
          d.checked = true;
        } else {
          d.checked = false;
        }
        return d;
      });
    } else {
      this.allItems = this.allItems.map(d => {
        if (ids.indexOf(d.id) > -1) {
          d.checked = true;
        } else {
          d.checked = false;
        }
        return d;
      });
      let selectAll: boolean = this.allItems.every(function (item: any) {
        return item.checked == true;
      });
      let trueIndex = this.allItems.findIndex(item => item.checked == true);
      if (selectAll) {
        this.isAllSelected = true;
        // this.isAnyItemsSelected = true;
      } else {
        this.isAllSelected = false;
        // this.isAnyItemsSelected = true;
      }
      if (trueIndex > -1) {
        this.isAnyItemsSelected = true;
      } else {
        this.isAnyItemsSelected = false;
      }
    }
    console.log('isAllSelected');
    console.log(this.isAllSelected);
    console.log('isAnyItemsSelected');
    console.log(this.isAnyItemsSelected);
  }
  // ngAfterContentChecked() {
  //   console.log('---ngAfterContentChecked---');
  // }
  ngAfterViewInit() {
    console.log('---ngAfterViewInit---');
    
  }
  // ngAfterViewChecked() {
  //   console.log('---ngAfterViewChecked---');
  //   
  // }
  ngOnDestroy() {
    console.log('single-,multi-list---ngOnDestroy');
  }
}
