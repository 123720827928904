import { createReducer, on } from "@ngrx/store";
import { masterDataActions } from "./masterData.action-type";
import { initialMasterDataState } from "./masterData.state";

export const masterDataReducer = createReducer(
    initialMasterDataState,
    on(masterDataActions.saveParametersList, (state, action) => {
        console.log(action);
        let paramList = action.paramList
        let paramsListArrayobj = {};
        let newArray = {}
        if (!!paramList && paramList.length > 0) {
            paramList.forEach((x: any) => {
                let obj = {}
                if (x.parameterName && x.paramValues) {
                    paramsListArrayobj[x.parameterName] = x.paramValues
                }
                //newArray.push(obj)
            })
            console.log(paramsListArrayobj);
        }
        return {
            ...state,
            parameters: paramsListArrayobj
        }
    }),
    on(masterDataActions.saveParameterHistory, (state, action) => {
        return {
            ...state,
            parameterHistory: action.parameterHistory
        };
    }),
    // on(masterDataActions.parameterSaved ,(state, action) => {

    //     return {
    //         ...state,
    //         masterData:
    //     }
    // }),
    on(masterDataActions.saveTrybeDepartments, (state, action) => {
        return {
            ...state,
            trybeDepartments: action.trybeDepartments
        };
    }),
    on(masterDataActions.clearTrybeDepartments, (state, action) => {
        return {
            ...state,
            trybeDepartments: []
        };
    }),
    on(masterDataActions.saveRolesForUser, (state, action) => {
        return {
            ...state,
            trybeRoles: action.trybeRoles
        };
    }),
    on(masterDataActions.saveAllQualtiyMaster, (state, action) => {
        return {
            ...state,
            qualityMasterData: action.qualityMasterData
        };
    }),
    on(masterDataActions.saveAllDamageMaster, (state, action) => {
        return {
            ...state,
            damageMasterData: action.damageMasterData
        };
    }),
    on(masterDataActions.saveParametersByTransType, (state, action) => {
        return {
            ...state,
            pdStaticParametrs: action.parametrsData
        };
    }),

    on(masterDataActions.saveUsersByPacketId, (state, action) => {
        return {
            ...state,
            usersbyPacketId: action.usersbyPacketId
        };
    }),
    on(masterDataActions.removeUsersByPacketId, (state, action) => {
        return {
            ...state,
            usersbyPacketId: []
        };
    }),
    on(masterDataActions.saveDetailsSuccess, (state, action) => {
        return {
            ...state,
            saveDetailFormObj: action.success
        };
    }),
    on(masterDataActions.saveDetailsFailure, (state, action) => {
        return {
            ...state,
            saveDetailFormObj: action.error
        };
    }),
    on(masterDataActions.saveOrignalAnnotationImage, (state, action) => {
        return {
            ...state,
            saveDetailFormObj: action.originalAnnotationImage
        };
    }),
    on(masterDataActions.saveDetailsOfAnnotation, (state, action) => {
        return {
            ...state,
            DetailsOfAnnotation: action.detailByPacketId
        };
    }),
    on(masterDataActions.savePreplanningSummary, (state, action) => {
        return {
            ...state,
            DetailsOfPrePlanningSummary: action.prePlanningSummary
        };
    }),
    on(masterDataActions.saveAllStoneMaster, (state, action) => {
        return {
            ...state,
            stoneParameterMaster: action.allStoneMaster
        };
    }),
    on(masterDataActions.saveStoneMasterDetailById, (state, action) => {
        return {
            ...state,
            stoneParameterMasterById: action.allStoneMasterDetailById
        };
    }),
    on(masterDataActions.saveAllUsers, (state, action) => {
        return {
            ...state,
            allUsers: action.usersOfTrybe
        };
    }),
    on(masterDataActions.saveLabParameterData, (state, action) => {
        console.log(action);
        return {
            ...state,
            labParameterData: action.LabParameterData
        };
    }),
    on(masterDataActions.saveColorValues, (state, action) => {
        console.log(action);
        return {
            ...state,
            ColorData: action.ColorData
        };
    }),

    on(masterDataActions.saveFancyValues, (state, action) => {
        console.log(action);
        return {
            ...state,
            FancyData: action.FancyData
        };
    }),

    on(masterDataActions.saveColorShade, (state, action) => {
        console.log(action);
        return {
            ...state,
            ColorShadeData: action.ColorShadeData
        };
    }),
)