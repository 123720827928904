import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services/loader/loader.service';
import { ConfigService } from '../services/config/config.service';
import { ModalController } from '@ionic/angular';
import { HttpService } from '../services/http/http.service';
import { AppState } from '../reducers';
import { Store, select } from '@ngrx/store';
import { getCurrentAction, getCurrentRowData } from '../tempDataStore/tempData.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-labfinalcheck',
  templateUrl: './labfinalcheck.component.html',
  styleUrls: ['./labfinalcheck.component.scss'],
})
export class LabfinalcheckComponent implements OnInit {
  actionName: any;
  actionId: any;
  headerNameFromKey: any = {
    TOTAL_DEPTH_MM: 'TOT DEPTH MM',
    TOTAL_DEPTH_PC: 'TOT DEPTH',
    DIAMETER_MM: 'DIAMETER',
    TABLE_MM: 'TABLE MM',
    TABLE_PC: 'TABLE',
    CROWN_ANGLE_DEG: 'CR ANGLE',
    CROWN_HEIGHT_PC: 'CR HGT',
    PAVILION_ANGLE_DEG: 'PAV ANGLE',
    STAR_RATIO_PC: 'STAR LENGTH',
    GIRDLE_WIDE_BEZEL: 'G.BZL',
    GIRDLE_WIDE_BONE: 'GIRDLE BONE',
    GIRDLE_THICKNESS_MAX_GIA_ROUNDED: 'GIRDLE N',
    GIRDLE_THICKNESS_MIN_GIA_ROUNDED: 'GIRDLE N',
    PAVILION_DEPTH_PC: 'PAV HGT',
    GIRDLE_NARROW: 'GIRDLE',
    CULET_PC: 'CULET SIZE',
    LENGTH_GIRDLE_FACET: 'LOWER HALVES',
  };
  tableHeaders: string[] = [
    // 'Column 1', 'Column 2', 'Column 3'
  ];
  rowHeaders: string[] = [
    // 'IdealOut', 
    'Avg', 
    'Min',
    'Max',
    'Symmetry'
    // 'Deviation',
  ];
  tableData: any = [
    // ['Data 1', 'Row 1 Data 2', 'Row 1 Data 3'],
    // ['Row 2 Data 1', 'Row 2 Data 2', 'Row 2 Data 3'],
    // ['Row 3 Data 1', 'Row 3 Data 2', 'Row 3 Data 3'],
    // ['Row 3 Data 1', 'Row 3 Data 2', 'Row 3 Data 3'],
    // ['Row 3 Data 1', 'Row 3 Data 2', 'Row 3 Data 3'],
    // ['Row 3 Data 1', 'Row 3 Data 2', 'Row 3 Data 3'],

    // Add more rows and data as needed
  ];
  actionStatus: any;
  isUpdateDisable: boolean = true;
  private unsubscribe$: Subject<any> = new Subject<any>();
  currRowData: any;
  dropdown: any = {};
  dropDownShapeData: any = {
    name: 'shape',
    placeText: 'shape Selection',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };
  heliumFileData: any;
  packetId: any;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private modalCtl: ModalController,
    private configService: ConfigService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() { 
  this.store
    .pipe(select(getCurrentAction))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((action: any) => {
      if (action) {
        this.actionName = action?.name;
        this.actionId = action?.id;
        this.actionStatus = action?.status;
      }
    });

  this.store
    .pipe(select(getCurrentRowData))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((rd: any) => {
      if (rd) {
        this.packetId = rd[0].id
        this.currRowData = rd[0];
      }
    });
    
    this.getHeliumFileData()
  }

  dropDownSelection(evt: any, plan: any) {
    if (evt.property == 'shape') {
      this.setValuesOfDropdown(evt);
    }
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    this.isUpdateDisable =
      Object.keys(this.dropdown).length === 0 ? true : false;
  }


 async getHeliumFileData() {
  if(this.packetId){
    let endpoint: string = `processActionInput/getHeliumPropertiesByPacketId?packetId=${this.packetId}`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        if (resultCode == 200) {
          this.heliumFileData = res.data;
          this.tableData = [{},{},{},{}];
          this.tableHeaders = [];
          for(const key in (res?.data || {})){
            const value = res.data[key];

            let headKey = key.replace(/(_MIN|_MAX|_SYMMETRY)$/, '');

            if(!this.tableHeaders.includes(headKey))
            this.tableHeaders.push(headKey);

            if(key.endsWith('_MIN')){
              this.tableData[1][headKey] = value;
            } else
            if(key.endsWith('_MAX')){
              this.tableData[2][headKey] = value;
            } else
            if(key.endsWith('_SYMMETRY')){
              this.tableData[3][headKey] = value;
            } else {
              this.tableData[0][headKey] = value;
            }
          }
        } else {
          this.configService.showToast('Error', (res?.error || 'Something went wrong!'));
        }
      }
    });
  }
 }
}
