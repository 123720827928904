import { Component, OnInit } from '@angular/core';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { HttpService } from '../services/http/http.service';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-wages-ledger',
  templateUrl: './wages-ledger.component.html',
  styleUrls: ['./wages-ledger.component.scss'],
})
export class WagesLedgerComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  columns: any;
  dataSource: any;
  summaryData: any[] = [];
  pageSize: 20;
  public totalSummary = [
    {
      fieldName: 'amount',
      summaryType: 'sum',
      name: 'Amount',
    },
    {
      fieldName: 'quantity',
      summaryType: 'sum',
      name: 'quantity',
    },
    {
      fieldName: 'rate',
      summaryType: 'sum',
      name: 'Rate',
    },
  ];
  showGrid: boolean = false;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    public configService: ConfigService,
    public loaderService:LoaderService
  ) {}

  ngOnInit() {
    this.columns = [
      { name: 'ActivityDate', prop: 'ActivityDate' },
      { name: 'Worker', prop: 'userName' },
      { name: 'Action', prop: 'actionName' },
      { name: 'PacketID', prop: 'packetId' },
      { name: 'Qty', prop: 'quantity' },
      { name: 'UOM', prop: 'unitOfMeasurement' },
      // { name: 'Wage Type', prop: 'wagesType' },
      { name: 'Rate', prop: 'rate' },
      { name: 'Amount', prop: 'amount' },
    ];
  }

  checkDates() {
    const fromDateObj = new Date(this.fromDate);
    const toDateObj = new Date(this.toDate);

    if (fromDateObj.getTime() === toDateObj.getTime()) {
      this.configService.showToast('Error', 'To And From Date Cant Be Same');
      this.toDate = null;
      this.fromDate = null;
    } else {
      this.showGrid = true;
      this.fetchGridData();
    }
  }

  async computeWages() {
    let endpoint: string = `wages/computeWages?fromDate=${this.fromDate}&toDate=${this.toDate}`;
    
     this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
        if (resultCode == 200) {
       
          console.log(res.data);
          this.fetchGridData();
          if (res?.data?.length == 0) {
            this.configService.showToast('Error', 'No Data Available');
          }
          this.dataSource = res.data.wagesActivitList;
        } else {
          this.fileDownload()
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

  Reset() {
    this.dataSource = [];
    this.toDate = null;
    this.fromDate = null;
  }

  async fetchGridData() {
    let endpoint: string = `wages-activity-log/getByDate?fromDate=${this.fromDate}&toDate=${this.toDate}`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
        if (resultCode == 200) {
          console.log(res.data);
          if (res.data.length == 0) {
            this.configService.showToast('Error', 'No Data Available');
          }
          this.dataSource = res.data.wagesActivitList;
          let amountTotal = 0;
          res.data.wagesActivitList.map((dt: any) => {
            amountTotal += dt?.amount || 0;
          });
          const formattedAmountTotal = amountTotal.toFixed(3);
          this.summaryData = [
            {
              prop: 'amount',
              value: `Total : ${formattedAmountTotal}`,
            },
          ];
        } else {
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

 async fileDownload(){
      if (this.fromDate && this.toDate) {   
          let response: any = await this.httpService.fetchDataOfTensionFile(
            true,
            `packet/downloadTransactionLog?fromDate=${this.fromDate}&toDate=${this.toDate}`
          );
          console.log(response);
          const self = this;
          if (response.statusCode === 200) {
            console.log(response.data);
           if(response.data){
            window.open(this.configService.getTrybeBaseUrl() + response.data)
           }
          } else {
            console.error(response);
          }
        
      }
    }
}
