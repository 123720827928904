import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MasterState } from "./masters.interface";

const masterSelector = createFeatureSelector<MasterState>('MASTERS');

export const selectMasterParameters = createSelector(
  masterSelector,
  (state: MasterState) => state.MASTER_PARAMETERS
);
export const selectChildParameters = createSelector(
  masterSelector,
  (state: MasterState) => state.CHILD_PARAMETERS
);