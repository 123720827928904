import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { getLockedPacketData } from "src/app/home/state/home.selector";
import { ConfigService } from "src/app/services/config/config.service";
import { ACTIONNAMES } from "src/app/services/endpoint.enum";
import { getCurrentAction } from "src/app/tempDataStore/tempData.selector";

@Component({
  selector: "app-multiple-barcode-scanner",
  templateUrl: "./multiple-barcode-scanner.component.html",
  styleUrls: ["./multiple-barcode-scanner.component.scss"],
})
export class MultipleBarcodeScannerComponent implements OnInit, AfterViewInit {
  barcode: string = "";
  private subscriptions: Subscription[] = [];
  rowData: { barcode: string }[] = [];
  columns: { name: string; prop: string }[] = [{ name: "Barcode", prop: "barcode" }];

  @ViewChild("barcodeScanField", { static: false }) barcodeScanField;
  lockedPacketData: any;
  curAction: any;
  @Input() Data: any;

  constructor(private modalCtrl: ModalController, private configService: ConfigService, private store: Store) {}

  ngOnInit() {
    console.log(this.Data);
    this.subscriptions.push(
      this.store.pipe(select(getLockedPacketData)).subscribe(data => {
        if (!!data && data.length > 0) console.log(data);

        this.lockedPacketData = data;
      })
    );

    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(async (data: any) => {
          if (data) {
            this.curAction = data?.name;
          }
        })
    );
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  setFocus() {
    setTimeout(() => {
      this.barcodeScanField.setFocus();
    }, 500);
  }

  searchBarcodeFunction(event: any) {
    this.barcode = this.barcodeScanField.value;
    if (this.rowData.length == 1 && this.curAction == "Packet Repairing") {
      this.configService.showToast("error", "Can't Add More Than One Packet");
      this.barcodeScanField.value = "";
    }
    if (this.curAction == "Dispatch Packet") {
      let newData = this.Data;
      let polish = newData.filter(e => e.packetType == 3);
      if (polish.length == 0) {
        this.rowData.splice(0, 0, { barcode: this.barcode });
        this.barcodeScanField.value = "";
      } else {
        newData = newData.filter(e => e.isStoneOk == 1 && e.isHold != 1);
        let find = newData.find(e => (e.packetLabel !== undefined && e.packetLabel === this.barcode) || (e.packetId !== undefined && e.packetId === this.barcode));
        if (find) {
          this.rowData.splice(0, 0, { barcode: this.barcode });
          this.barcodeScanField.value = "";
        } else {
          this.configService.showToast("error", "Packet not available to  select");
          this.barcodeScanField.value = "";
          return;
        }
      }
    } else {
      if (this.Data?.length && !this.Data.find((dt: any) => dt.barcodeNo === this.barcode || dt.packetId === this.barcode)) {
        this.barcode = "";
        event.target.value = "";
        this.configService.showToast("error", "Packet not available in selected tab");
        this.barcodeScanField.value = "";
        return;
      }

      setTimeout(() => {
        if (this.barcode && this.barcode.length > 0 && this.rowData.filter(data => data.barcode === this.barcode).length === 0) {
          const isBarcodeLocked = this.lockedPacketData.some(data => data.barcodeNo === this.barcode || data.barcode === this.barcode);
          if (!!this.Data) {
            //  this.barcode =  this.Data?.filter((dt:any)=>dt.packetId === this.barcode || dt.barcodeNo === this.barcode).map((dt: any) => dt.packetId)
            this.barcode = this.Data?.filter((dt: any) => dt.packetId === this.barcode || dt.barcodeNo === this.barcode).map((dt: any) => dt.packetId || dt.barcodeNo);
          }
          if (
            isBarcodeLocked &&
            !(this.curAction === ACTIONNAMES.retunToSafe || this.curAction === "Dispatch Packet" || this.curAction === ACTIONNAMES.tallyStock || this.curAction === ACTIONNAMES.tallyMismatchedStock)
          ) {
            this.configService.showToast("error", "This Packet is locked");
            event.target.value = "";
          } else {
            this.rowData.splice(0, 0, { barcode: this.barcode });
          }
          // this.rowData.splice(0, 0, {
          //   barcode: this.barcode,
          // });
        }
        this.barcodeScanField.value = "";
      }, 200);
    }
  }

  deleteBarcode(event: any) {
    this.rowData.splice(event.row.rowIndex, 1);
  }

  cancelModal() {
    this.modalCtrl.dismiss(null, "cancel");
    this.rowData = [];
  }

  confirm() {
    this.modalCtrl.dismiss(this.rowData, "done");
  }

  clearInput() {
    this.barcode = "";
  }
}
