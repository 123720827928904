import { createAction, props } from "@ngrx/store";

export const getParametersList = createAction(
    '[Master Data] Get Parameters List',
    props<{ paramList: any }>(),
);

export const saveParametersList = createAction(
    '[Master Data] Save Parameters List',
    props<{ paramList: [] }>(),
);

export const saveStoneParamater = createAction(
    '[Master Data] Save stone parameter',
    // props<{ parameterDetails: parameterDetails, actionId: number }>(),
    props<{ parameterDetails: any, actionId: number, formType: string, barcodeObj: any, actionInfo: any }>(),
);

export const removeStoneParamater = createAction(
    '[Master Data] remove stone parameter',
    props<{ parameterDetails: any, actionId: number }>(),
);

export const getParameterHistory = createAction(
    '[Master Data] Get history list',
    props<{ packetId: any, currUserFullName: string }>(),
);

export const saveParameterHistory = createAction(
    '[Master Data] Save history list',
    props<{ parameterHistory: [] }>(),
);
export const updateStoneParamater = createAction(
    '[Master Data] stone parameter history updated',
    // props<{ parameterDetails: parameterDetails, actionId: number }>(),
    props<{ packetId: number, parameterDetails: any, actionId: number, formType: string, currUserFullName: string }>(),
);

export const receivePacket = createAction(
    '[Master Data] stone receive',
    props<{ payload: any, modalData: any }>(),
);

export const receivePacketVoucherwise = createAction(
    '[Master Data] stone receive Voucher wise',
    props<{ param: any }>(),
);

export const rejectPacket = createAction(
    '[Master Data] stone reject',
    props<{ payload: any, modalData: any }>(),
);

export const removeIssuedPacket = createAction(
    '[Master Data] stone remove from Outgoing ',
    props<{ packetId: any, voucherId: any }>(),
);
export const removeIssuedPacketVoucherwise = createAction(
    '[Master Data] stone remove from Outgoing ',
    props<{ voucherId: any }>(),
);

export const getTrybeDepartments = createAction(
    '[Master Data] Get department list',
    props<{ packetIds: any }>(),
);

export const saveTrybeDepartments = createAction(
    '[Master Data] Save department list',
    props<{ trybeDepartments: [] }>(),
);
export const clearTrybeDepartments = createAction(
    '[Master Data] Clear department list',
);
export const getRolesForUser = createAction(
    '[Master Data] Get roles list',
);

export const saveRolesForUser = createAction(
    '[Master Data] Save roles list',
    props<{ trybeRoles: [] }>(),
);
export const transferStone = createAction(
    '[Master Data] Transfer stone',
    // props<{ parameterDetails: parameterDetails, actionId: number }>(),
    props<{ postData: any }>(),
);
export const ecsvUpload = createAction(
    '[Master Data] Upload ecsv Packet Plans',
    props<{ parameterDetails: any, packetID: number, actionId: number, formType: string, barcodeObj: any, actionInfo: any, isAppend:boolean, packetString?:string, isGetPacketPlansCalled?:boolean, isFromPlanningScreen?:boolean, currentUserId?:number }>(),
);

export const getAllQualtiyMaster = createAction(
    '[Master Data] Get All Qualtiy Master',
);
export const getAllDamageMaster = createAction(
    '[Master Data] Get Damage Remark',
);
export const saveAllQualtiyMaster = createAction(
    '[Master Data] Save All Qualtiy Master',
    props<{ qualityMasterData: any }>(),
);
export const saveAllDamageMaster = createAction(
    '[Master Data] Save Damage Remark',
    props<{ damageMasterData: any }>(),
);
export const createNewPackets = createAction(
    '[Master Data] Create New Packets/Bifurcate Packets',
    props<{ payload: any }>(),
);
export const getAllParametersByTransType = createAction(
    '[Master Data] get parameters by TransType',
    props<{ transType: string }>(),
);
export const saveParametersByTransType = createAction(
    '[Master Data] save parameters by TransType',
    props<{ parametrsData: any }>(),
);
export const getUsersByPacketId = createAction(
    '[Master Data] Get Users by packetId',
    props<{ packetID: any }>()
);

export const saveUsersByPacketId = createAction(
    '[Master Data] Save Users by PacketId',
    props<{ usersbyPacketId: any }>()
);
export const removeUsersByPacketId = createAction(
    '[Master Data] Remove Users by PacketId'
);
export const saveDetailsFormData = createAction(
    '[Master Data] saveDetailsFormData',
    props<{ postData: any }>()
);
export const saveDetailsSuccess = createAction(
    '[Master Data] saveDetailsFormData Success',
    props<{ success: any }>()
);
export const saveDetailsFailure = createAction(
    '[Master Data] saveDetailsFormData Failure',
    props<{ error: any }>()
);
export const saveOrignalAnnotationImage = createAction(
    '[Master Data] save original annotaation image store',
    props<{ originalAnnotationImage: any }>()
);
export const getDetailsOfAnnotation = createAction(
    '[Master Data] show details of annotation by PacketId',
    props<{ packetID: any }>()
);
export const saveDetailsOfAnnotation = createAction(
    '[Master Data] save details of annotation by PacketId',
    props<{ detailByPacketId: any }>()
);
export const getPreplanningSummary = createAction(
    '[Master Data] show details of preplanning summary',
    // props<{ packetID: any }>()
);
export const savePreplanningSummary = createAction(
    '[Master Data] save details of  preplanning summary',
    props<{ prePlanningSummary: any }>()
);
export const getAllStoneMaster = createAction(
    '[Master Data] show All Stone Master',
);
export const saveAllStoneMaster = createAction(
    '[Master Data] save All Stone Master',
    props<{ allStoneMaster: any }>()
);
export const getStoneMasterDetailById = createAction(
    '[Master Data] show All Stone Master by id',
    props<{ payload: any }>()
);
export const saveStoneMasterDetailById = createAction(
    '[Master Data] save All Stone Master by id',
    props<{ allStoneMasterDetailById: any }>()
);

export const getAllUsers = createAction(
    '[Master Data] get all users',
    // props<{ url: string }>()
);
export const saveAllUsers = createAction(
    '[Master Data] save all users',
    props<{ usersOfTrybe: any }>()
);

export const getAllLabParametersData = createAction(
    '[Master Data] Get LabParameters Lists',
);

export const saveLabParameterData = createAction(
    '[Master Data] save data of Lab Parameter',
    props<{ LabParameterData: any }>()
);
export const getAllColorValues = createAction(
    '[Master Data] Get color Lists',
);
export const saveColorValues = createAction(
    '[Master Data] save data of Color Values',
    props<{ ColorData: any }>()
);
export const getAllFancyValues = createAction(
    '[Master Data] Get fancy Lists',
);
export const saveFancyValues = createAction(
    '[Master Data] save data of fancy Values',
    props<{ FancyData: any }>()
);

export const getAllColorShade = createAction(
    '[Master Data] Get color shade Lists',
);

export const saveColorShade= createAction(
    '[Master Data] save data of Color  shade Values',
    props<{ ColorShadeData: any}>()
);
