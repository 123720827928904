import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-planning-modal',
  templateUrl: './planning-modal.component.html',
  styleUrls: ['./planning-modal.component.scss'],
})
export class PlanningModalComponent implements OnInit {
  recutVal;
  constructor( private modalController: ModalController,
    private navParam: NavParams) { 
  }

  ngOnInit() {}

  closeModal() {
    let data = {recutVal:this.recutVal}
    this.modalController.dismiss(data);
  }

  onSaveClick(){

  }

  // onSelection(event) {
  //   console.log(event.detail.value)
  //   console.log(this.recutVal);    
  //  // this.recutVal = event.detail.value;
  // }


}
