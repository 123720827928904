import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WidthAlertModule } from 'src/app/width-alert/width-alert.module';
import { HeaderComponentModule } from '../components/header/header.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { OpinionApprovalMasterComponent } from './opinion-approval-master.component';
import { NewDropDownModule } from '../components/new-drop-down/new-drop-down.module';
import { FilterModule } from "../pipes/filter/filter.module";


@NgModule({
    declarations: [OpinionApprovalMasterComponent],
    imports: [
        CommonModule,
        FormsModule,
        HeaderComponentModule,
        IonicModule,
        DataGridComponentModule,
        WidthAlertModule,
        NewDropDownModule,
        FilterModule
    ]
})
export class OppinionApprovalMasterModule {}
