import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routeMasterAPIs } from 'src/app/packet-route-master/state/packet-route-master.data';
import { LoaderService } from '../services/loader/loader.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { HttpService } from '../services/http/http.service';
import { ConfigService } from '../services/config/config.service';
import { log } from 'console';


@Component({
  selector: 'app-opinion-approval-master',
  templateUrl: './opinion-approval-master.component.html',
  styleUrls: ['./opinion-approval-master.component.scss'],
})
export class OpinionApprovalMasterComponent implements OnInit {
  showGrid: boolean = true;
  isEdit: boolean = false;
  columns: any;
  dataSource: any;
  actionID: any
  actionOptions: any = [{
    id: 1, name: 'Checker'
  }, {
    id: 2, name: 'Admin'
  }, {
    id: 3, name: 'HOD'
  }]
  criteriaParameterHead = [];
  criteriaData: any[] = [{
    from: null, to: null, approvals: {
      Approval1: null,
      Approval2: null,
      Approval3: null
    }
  }];
  criteriaList: {};
  criteriaParameterLists = [];
  saveJsonPayload = {
    in: {},
    range: {},
    equals: {},
    uiJson: {}
  };
  selectedCriteria: any;
  formData: any = {
    tableVal: {
      lowerLimit: '',
      upperLimit: '',
    },
    girdle: {
      lowerLimit: '',
      upperLimit: '',
    },
    widthMM: {
      lowerLimit: '',
      upperLimit: '',
    },
    lengthMM: {
      lowerLimit: '',
      upperLimit: '',
    },
    culet: {
      lowerLimit: '',
      upperLimit: '',
    },
    diameter: {
      lowerLimit: '',
      upperLimit: '',
    },
    currwt: {
      lowerLimit: '',
      upperLimit: '',
    },
    ratio: {
      lowerLimit: '',
      upperLimit: '',
    },
    height: {
      lowerLimit: '',
      upperLimit: '',
    },
    crHeight: {
      lowerLimit: '',
      upperLimit: '',
    },
    crAngle: {
      lowerLimit: '',
      upperLimit: '',
    },
    pavHeight: {
      lowerLimit: '',
      upperLimit: '',
    },
    pavAngle: {
      lowerLimit: '',
      upperLimit: '',
    },
  };
  showCriteriaBox: boolean = false;
  criterialistData: any[];
  Proportion = [
    { name: 'EXCELENT', id: 1, paramNameId: 1, checked: false },
    { name: 'V.GOOD', id: 2, paramNameId: 2, checked: false },
    { name: 'FAIR', id: 3, paramNameId: 3, checked: false },
    { name: 'POOR', id: 4, paramNameId: 4, checked: false },
  ];
  tinch = [
    { name: 'N/A', id: 1, paramNameId: 1, checked: false },
    { name: 'LIGHT BROWN', id: 2, paramNameId: 2, checked: false },
    { name: 'BROWN', id: 3, paramNameId: 3, checked: false },
    { name: 'DARKBROWN', id: 4, paramNameId: 4, checked: false },
    { name: 'DARK LC', id: 5, paramNameId: 4, checked: false },
    { name: 'MIX', id: 6, paramNameId: 4, checked: false },
  ];
  topOpens = [
    { name: 'N/A', id: 1, paramNameId: 1, checked: false },
    { name: 'LIGHT', id: 2, paramNameId: 2, checked: false },
    { name: 'HEAVY', id: 3, paramNameId: 3, checked: false },
    { name: 'STRONG', id: 4, paramNameId: 4, checked: false },
  ];
  BotOpens = [
    { name: 'N/A', id: 1, paramNameId: 1, checked: false },
    { name: 'LIGHT', id: 2, paramNameId: 2, checked: false },
    { name: 'HEAVY', id: 3, paramNameId: 3, checked: false },
    { name: 'STRONG', id: 4, paramNameId: 4, checked: false },
  ];
  selectedRow: any;
  UpdatePacketCriteria: any;
  id: any;
  allCheckedParams:any = {};

  constructor(
    private loaderService: LoaderService,
    private store: Store<AppState>,
    private httpService: HttpService,
    private _ConfigService: ConfigService,
    private route: ActivatedRoute
  ) {
    this.columns = [
      { name: 'Name', prop: 'name' },
      { name: 'Created By', prop: 'createdBy' },
      { name: 'CreatedDate', prop: 'createdDate' },
    ]
  }

  ngOnInit() {
    this.fetchRequiredParameterValues();
    this.fetchData();
  }


  onAdd() {
    this.showGrid = false;
    this.isEdit = false;
    this.emptyData()
    this.onClearCriteriaParameterLists();
  }

  async onEdit(event) {
    this.allCheckedParams = {}
    console.log(event.row.data);
    this.id = event.row.data.id
    this.selectedRow = event.row.data;
    if (!!this.selectedRow?.criteria) {
      this.saveJsonPayload = JSON.parse(this.selectedRow?.criteria);
      const keyToChange = {
        colorId: 'COLOR',
        cutId: 'CUT',
        clarityId: 'Clarity',
        fluorescenceId: 'Fluorescence',
        natts: 'NATTS',
        mLab: 'Lab',
        luster: 'Luster',
        bottomOpen: 'BOTTOM OPEN',
        topOpen: 'TOP OPEN',
        height: 'DEPTH',
        polishId: 'Polish',
        shapeId: 'SHAPE',
        symmetryId: 'Symmetry',
        tinch: 'Tinch',
      };
      const modifiedObject = {};
      if (this.saveJsonPayload?.in) {
        for (const [key, value] of Object?.entries(this.saveJsonPayload?.in)) {
          modifiedObject[keyToChange[key]] = value;
        }
      }
      this.saveJsonPayload.in = modifiedObject;
      if (this.criteriaParameterLists.length > 0) {
        this.onClearCriteriaParameterLists()
        await this.criteriaParameterLists.map((cpl) => {
          if (cpl?.parameterName) {
            if (this.saveJsonPayload?.in?.[cpl?.parameterName]) {
              cpl?.paramValues.map((pv) => {
                this.saveJsonPayload?.in?.[cpl?.parameterName]?.[
                  'value'
                ].map((v) => {
                  if (pv.id == v) {
                    pv.isSelected = true;
                  }
                });
              });
            }
          }
        });
      }
      let parse = JSON.parse(this.selectedRow?.criteria);
      this.criteriaData = [parse.uiJson]
    }

    if (!!this.saveJsonPayload?.range) {
      this.addRange(this.saveJsonPayload?.range);
    }
    this.isEdit = true
    this.showGrid = false;
    this.allCheckedParamsCheck(this.criteriaParameterLists)
  }

  addRange(value) {
    this.formData['tableVal'] = value['tableVal'] ?? '-';
    this.formData['crAngle'] = value['crAngle'] ?? '-';
    this.formData['crHeight'] = value['crHeight'] ?? '-';
    this.formData['culet'] = value['culet'] ?? '-';
    this.formData['height'] = value['height'] ?? '-';
    this.formData['diameter'] = value['diameter'] ?? '-';
    this.formData['girdle'] = value['girdle'] ?? '-';
    this.formData['ratio'] = value['ratio'] ?? '-';
    this.formData['lengthMM'] = value['lengthMM'] ?? '-';
    this.formData['pavAngle'] = value['pavAngle'] ?? '-';
    this.formData['pavHeight'] = value['pavHeight'] ?? '-';
    this.formData['widthMM'] = value['widthMM'] ?? '-';
    this.formData['currentCts'] = value['currentCts'] ?? '-';
  }

  onClearCriteriaParameterLists() {
    this.criteriaParameterLists.map((cpl) => {
      if (cpl?.parameterName) {
        cpl?.paramValues.map((pv) => {
          pv.isSelected = false;
        });
      }
    });
  }

  onSelectionChanged(event) {

  }

  delete(event) {
    const id = event.row.data.id;
    this.httpService.deleteData(true, `opinion-approval/remove/${id}`).subscribe(
      (res) => {
        if (res.statusCode === 200) {
          this.fetchData();
          this._ConfigService.showToast("success", 'Data Deleted Successfully!');
        } else {
          this._ConfigService.showToast("error", 'Error In Deleting Data!');
        }
      },
      (error) => {
        console.error('Error deleting data:', error);
        this._ConfigService.showToast("error", 'Error In Deleting Data!');
      }
    );
  }
  onBack() {
    this.showGrid = true;
    this.emptyData()
    this.onClearCriteriaParameterLists();
    this.allCheckedParams={}
  }


  addCriteria() {
    this.criteriaData.push({ from: null, to: null, approvals: {} });

  }

  Delete(index) {
    if (index == 0) return
    if (index > -1 && index < this.criteriaData.length) {
      this.criteriaData.splice(index, 1);
    }
  }

  keyUp(event: any) {
    const pattern = /[0-9\+\ ]/;
    let inputChar = String.fromCharCode(event.key);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  async onSelectParamater(parameter, value) {
    if (
      !!this.criteriaParameterLists &&
      this.criteriaParameterLists.length > 0
    ) {

      //range logic

      // let startIndex, endIndex;
      // this.criteriaParameterLists.map((cpl) => {

      //   if (cpl.parameterName === parameter.parameterName) {
      //     if (cpl?.startIndex != null && cpl?.endIndex != null) {
      //       cpl.startIndex = startIndex = null;
      //       cpl.endIndex = endIndex = null;
      //     }
      //     if (cpl?.startIndex != null && cpl?.endIndex == null) {
      //       let index = cpl?.paramValues?.findIndex(
      //         (val) => val.id === value.id
      //       );
      //       if (index > -1) {
      //         cpl.endIndex = endIndex = index;
      //       }
      //     } else if (cpl?.endIndex != null && endIndex == null) {
      //       endIndex = cpl.endIndex;
      //     }
      //     if (cpl?.startIndex == null) {
      //       let index = cpl?.paramValues?.findIndex(
      //         (val) => val.id === value.id
      //       );
      //       if (index > -1) {
      //         cpl.startIndex = startIndex = index;
      //       }
      //     } else if (startIndex == null) {
      //       startIndex = cpl.startIndex;
      //     }
      //     if (startIndex != null && endIndex == null) {
      //       cpl?.paramValues?.map((pv, i) => (pv.isSelected = false));
      //       cpl.paramValues[startIndex].isSelected = true;
      //     } else if (startIndex != null && endIndex != null) {
      //       cpl?.paramValues?.map((pv, i) => {
      //         pv.isSelected = false;
      //         if (i >= startIndex && i <= endIndex) {
      //           pv.isSelected = !pv.isSelected;
      //         }
      //       });
      //     }
      //   }
      // });
      // normal select
      const selectedParamDoc = this.criteriaParameterLists.find((el: any) => el['parameterNameId'] === value['paramNameId'])
      const selectedParamDocValues = selectedParamDoc.paramValues.find((el: any) => el.id === value?.id)
      selectedParamDocValues.isSelected = !selectedParamDocValues.isSelected
    }
    this.allCheckedParamsCheck(this.criteriaParameterLists)
  }

  fetchRequiredParameterValues() {
    let payloadObj: object = {
      names: [
        'Color',
        'Shape',
        'Fluorescence',
        'Clarity',
        'Polish',
        'Symmetry',
        'Tinch',
        'Cut',
        'Milkyness',
        'Proportion',
        'Lab',
        'Luster',
        'Natts',
        'Tension Type',
        'TOP OPEN',
        'BOTTOM OPEN',
      ],
    };
    // let payloadObj: object = {
    //   names: this.parameterNames
    // };
    this.httpService
      .getData(true, routeMasterAPIs.parameterValuesEndpoint, payloadObj)
      .subscribe((result) => {
        if (result.statusCode === 200) {
          this.criteriaParameterLists = result?.data.map((dt) => {
            return {
              ...dt,
              startIndex: null,
              endIndex: null,
              paramValues: dt?.paramValues?.map((pv) => {
                return { ...pv, isSelected: false };
              }),
            };
          });
          this.criteriaParameterHead = result?.data.map(
            (dt) => dt.parameterName
          );

          this.criterialistData = [];
          this.criteriaList = {};
          result.data.forEach((d) => {
            let obj = {
              paramId: d.parameterNameId,
              property: 'name',
              list: d.paramValues, //[]
              selected: [],
              isMultiple: true,
              name: d.parameterName,
            };
            this.criteriaList[d.parameterName] = d.parameterNameId;
            // if (d.parameterName == 'Color') {
            //   this.colorList['name'] = 'Color';
            //   this.colorList['property'] = 'name';
            //   this.colorList['list'] = d.paramValues;
            // } else if (d.parameterName == 'Shape') {
            //   this.shapeList['name'] = 'Shape';
            //   this.shapeList['property'] = 'name';
            //   this.shapeList['list'] = d.paramValues;
            // } else {

            // }
            this.criterialistData.push(obj);
          });
          let obj = {
            paramId: null,
            property: 'name',
            list: this.Proportion, //[]
            selected: [],
            isMultiple: true,
            name: 'Proportion',
          };
          this.criterialistData.push(obj);
          let obj2 = {
            paramId: null,
            property: 'name',
            list: this.tinch, //[]
            selected: [],
            isMultiple: true,
            name: 'Tinch',
          };
          this.criterialistData.push(obj2);
          let obj3 = {
            paramId: null,
            property: 'name',
            list: this.topOpens, //[]
            selected: [],
            isMultiple: true,
            name: 'TopOpens',
          };
          this.criterialistData.push(obj3);
          let obj4 = {
            paramId: null,
            property: 'name',
            list: this.BotOpens, //[]
            selected: [],
            isMultiple: true,
            name: 'BotOpens',
          };
          this.criterialistData.push(obj4);
        } else {
          this.httpService.getErrorAndDisplayIt(result);
        }
      });
  }

  emptyData() {
    this.criteriaData = [{
      from: null, to: null, approvals: {
        Approval1: null,
        Approval2: null,
        Approval3: null
      }
    }];
    this.formData = {
      tableVal: {
        lowerLimit: '',
        upperLimit: '',
      },
      girdle: {
        lowerLimit: '',
        upperLimit: '',
      },
      widthMM: {
        lowerLimit: '',
        upperLimit: '',
      },
      lengthMM: {
        lowerLimit: '',
        upperLimit: '',
      },
      culet: {
        lowerLimit: '',
        upperLimit: '',
      },
      diameter: {
        lowerLimit: '',
        upperLimit: '',
      },
      ratio: {
        lowerLimit: '',
        upperLimit: '',
      },
      height: {
        lowerLimit: '',
        upperLimit: '',
      },
      crHeight: {
        lowerLimit: '',
        upperLimit: '',
      },
      crAngle: {
        lowerLimit: '',
        upperLimit: '',
      },
      pavHeight: {
        lowerLimit: '',
        upperLimit: '',
      },
      pavAngle: {
        lowerLimit: '',
        upperLimit: '',
      },
      currentCts: {
        lowerLimit: '',
        upperLimit: '',
      },
    };
  }


  async fetchData() {
    const res = await this.httpService.fetchDataUsingPromise(true, 'opinion-approval/findAll');
    if (res?.data?.length) {
      this.dataSource = res.data;
    }
    else
      this._ConfigService.showToast("error", ('No data found!'));
  }


  async onSave(type: any) {
    let firstIndexEncountered = false;
    this.saveJsonPayload.range = this.formData;
    await this.processSelectedParameters()
    console.log(this.criteriaData);
    const keyToChange = {
      COLOR: 'colorId',
      Clarity: 'clarityId',
      Fluorescence: 'fluorescenceId',
      NATTS: 'natts',
      CUT: 'cutId',
      Lab: 'mLab',
      Luster: 'luster',
      'BOTTOM OPEN': 'bottomOpen',
      'TOP OPEN': 'topOpen',
      DEPTH: 'height',
      Polish: 'polishId',
      SHAPE: 'shapeId',
      Symmetry: 'symmetryId',
      Tinch: 'tinch',
    };
    const modifiedObject = {};

    for (const [key, value] of Object.entries(this.saveJsonPayload.in)) {
      modifiedObject[keyToChange[key]] = value;
    }

    this.saveJsonPayload.in = { ...this.saveJsonPayload.in, ...modifiedObject };
    let payload = [];


    this.criteriaData.map((cd: any) => {
      this.saveJsonPayload.uiJson = cd
      this.saveJsonPayload.range['weightRange'] = {
        'from': cd.from,
        'to': cd.to,
      };
      const item = {
        criteria: JSON.stringify(this.saveJsonPayload),
        "approval": JSON.stringify([cd.approvals]),
      };
      if (type === 'update' && !firstIndexEncountered) {
        item['id'] = this.id;
        firstIndexEncountered = true;
      }
      payload.push(item);
    });

    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(
      true,
      'opinion-approval/save',
      payload
    );
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.onBack();
      this.fetchData();
      let data = response.message;
      this._ConfigService.showToast('success', data);
    } else {
      console.error(response);
      this._ConfigService.showToast('error', response.error.message);
    }
  }

  onCheckBoxChange(isSelected:boolean,variable:string){
    console.log(isSelected)
   const parameterList = this.criteriaParameterLists.find((el)=>el.parameterName === variable)
   parameterList.paramValues.map((el)=>{
      el.isSelected = isSelected
      return el
    })
  }
  
  onSearchChange(event,parameterName){
    const {value}= event.target
    const criteria = this.criteriaParameterLists.find((el)=>el.parameterName === parameterName)
    criteria.searchText = value
  }
  
  allCheckedParamsCheck(criteriaParameterLists:any){
    criteriaParameterLists.map((cp:any)=>{
      if(cp.paramValues.every((pv:any)=>pv.isSelected))
        this.allCheckedParams[cp.parameterName]=true;
      else
      this.allCheckedParams[cp.parameterName]=false;
    })
  }

 async processSelectedParameters(){
    let selectedValues = this.criteriaParameterLists.map((cpl) => ({
      ...cpl,
      paramValues: cpl.paramValues.filter((pv) => pv.isSelected),
    }));
    this.selectedCriteria = selectedValues.filter(
      (item) => item.paramValues.length > 0
    );
    console.log(selectedValues)

    let newArray = [];
    this.selectedCriteria = await this.selectedCriteria.map((x) => {
      if (x.parameterName) {
        let obj = {};
        obj[x.parameterName] = {
          value: [],
        };
        let valuesArray = x.paramValues.map((y) => y.id);
        obj[x.parameterName]['value'] = valuesArray;
        newArray.push(obj);
      }
    });
    let newObject = {};

    newArray.forEach((obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      if (!newObject[key]) {
        newObject[key] = value;
      } else {
        newObject[key].value = newObject[key].value.concat(value.value);
      }
    });
    console.log(newObject)
    this.saveJsonPayload.in = newObject;
  }

}
