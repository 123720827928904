import { Component, OnInit, ViewChild } from "@angular/core";
import { Store, select } from "@ngrx/store";
import CustomStore from "devextreme/data/custom_store";
import { Observable, Subscription } from "rxjs";
import Query from "devextreme/data/query";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";
import { getAllRolesOfUser, getAllTrybeDepartments } from "src/app/masterDataStore/masterData.selector";
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/services/http/http.service";
import { ScannerinputComponent } from "../scannerinput/scannerinput.component";
import { MultipleBarcodeScannerComponent } from "../multiple-barcode-scanner/multiple-barcode-scanner.component";
import { ModalController } from "@ionic/angular";
import { HomeActions } from "src/app/home/state/home.action-type";
import { TrybemodalComponent } from "../trybemodal/trybemodal.component";
import * as moment from "moment";
import { printData } from "src/app/masterDataStore/master.data";
import { authUser } from "src/app/authStore/auth.selector";
import { StaticInfo, environment } from "src/environments/environment";
import { ENDPOINTS } from "src/app/services/endpoint.enum";

@Component({
  selector: "app-third-party-transfer",
  templateUrl: "./third-party-transfer.component.html",
  styleUrls: ["./third-party-transfer.component.scss"],
})
export class ThirdPartyTransferComponent implements OnInit {
  tranferGridColumns: any = [
    { name: "Lot No", prop: "lotNo", columnTemplate: "lot-svg-template" },
    { name: "Packet ID", prop: "packetId" },
    { name: "Cts", prop: "cts" },
    { name: "InwardDate", prop: "inDate" },
    { name: "CreatedDate", prop: "createdDate" },
  ];

  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;

  transferGridPage = "Tranfer";
  stonesForTransfer: any = [];
  transferGridpageSize = 50;
  rowsIndex: any = [];
  printData: any = printData;
  scanned: boolean;
  refreshGrid: boolean = false;
  isThirdParty: boolean = false;
  tableDataCount: number | string = 0;
  inputBarcode;
  deptDropdownData: any = {
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],

    propertName: "name",
    modeFlag: "single", //single/multiple
  };
  selectRoles: any = [];
  selectStoneAction: any = [];
  roleDropDownData: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: this.selectRoles,
    propertName: "roleName",
    modeFlag: "single", //single/multiple
  };
  actionDropDownData: any = {
    name: "Action",
    placeText: "Select Action",
    options: [],
    selectedData: this.selectStoneAction,
    propertName: "actionName",
    modeFlag: "single", //single/multiple
  };
  listOfActionsByStone: Observable<any>;
  pageInfo = { limit: this.transferGridpageSize, page: 0 };
  pageNumber: number = 0;
  gridDataCount: number = 0;
  gridData: any = [];
  infoToPrint: Object = {};
  thirdPartyFilePath: any;
  dispatchData: any;
  modifiedGridObject: any;
  private subscriptions: Subscription[] = [];
  selectedRows: any = [];
  selectedRow: any;
  selectedDepartment: any;
  voucherDto: any;
  selectedRole: any;
  barcodeInputList: string[] = [];
  selectedAction: any;
  dispatchLossData: any;
  constructor(public httpService: HttpService, private store: Store<AppState>, private modalCtrl: ModalController, private _ConfigService: ConfigService) {}

  ngOnInit() {
    this.infoToPrint = {};
    this.infoToPrint = {
      // company: StaticInfo['company'], //Static Name
      address: StaticInfo["address"], //Static Address
      issueFor: null,
      issueDate: null, //Jangad Date
      issueNo: null, //Jnd No,
      stoneType: StaticInfo["stoneType"],
      senderNotes: StaticInfo["senderNotes"],
      processRemark: StaticInfo["processRemark"],
      subProcessRemark: StaticInfo["subProcessRemark"],
      data: {},
    };
    this.subscriptions.push(
      this.store.pipe(select(authUser)).subscribe((data: any) => {
        if (data) {
          console.log("user details");
          console.log(data);
          this.infoToPrint["fromUser"] = data.fullName ?? "";
          // this.infoToPrint['fromDept'] = data.departmentName ?? '';
          // this.infoToPrint['fromCompany'] = data.companyName ?? '';
          // this.infoToPrint['company'] =
          //   environment.fromLocation ?? data.companyName;
          this.infoToPrint["issueFor"] = `${data.departmentName ?? "-"} Issue`;
        }
      })
    );
    this.getStoneActionForTransfer();
    this.httpService.currenttranferReturnAssignGridData$.subscribe(data => {
      if (data) {
        this.gridDataCount = Number(data.countOfTotalPackets);
        this.gridData = data.packetData;
        this.tableDataCount = Number(data.countOfTotalPackets);
      }
    });
    this.getFreshDataAndSetPaginationToGrid();
    this.store.dispatch(masterDataActions.getRolesForUser());
    // this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.subscriptions.push(
      this.store.pipe(select(getAllRolesOfUser)).subscribe(data => {
        if (data) {
          this.roleDropDownData["options"] = data;
        }
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
        if (data) {
          this.deptDropdownData["options"] = data;
          if (this.selectedDepartment && data?.filter((dt: any) => dt.id == this.selectedDepartment.id)?.length) this.getStoneActionForTransfer();
          else this.selectedDepartment = undefined;
        }
      })
    );
  }

  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.pageInfo["limit"] = size;
        this.getFreshDataAndSetPaginationToGrid();
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.pageInfo["page"] = pageIndex;
        this.getFreshDataAndSetPaginationToGrid();
        break;
    }
  }
  async getFreshDataAndSetPaginationToGrid() {
    console.log("setPaginationToDxGrid- Grid Data");
    //tranfer Grid, ReturnGrid common function for both grid.
    let payload = null;
    if (!!this.inputBarcode) {
      payload = { packetIds: this.inputBarcode }; //toFiltter grid Data by barcodeInputs
    } else if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
      payload = { packetIds: this.barcodeInputList };
    }
    this.modifiedGridObject = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo["limit"];
          } else {
            this.pageInfo["limit"] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.pageInfo["page"] = this.pageNumber;
          // const response = await this.httpService.postDataUsingPromise(true, ENDPOINTS.transferPacketFetch, payload);
          let response: any = await this.httpService.fetchWithMeGridData(this.pageInfo, payload, "Third-Party Transfer");
          console.error("------------Debug");
          console.log(response, this.gridData, this.gridDataCount);
          if (response.statusCode == 200) {
            const data = {
              countOfTotalPackets: response?.data?.countOfTotalPackets || 0,
              packetData: response.data.packetData || [],
            };
            this.httpService.updateTranferReturnAssignGridData(data);
          } else {
            console.error(response);
          }
          if (this.barcodeInputList && this.barcodeInputList.length > 0) {
            this.scanned = true;
            this.rowsIndex = this.gridData.map((d, index: number) => index);
            setTimeout(() => {
              this.scanned = false;
            }, 1000);
          }
          return {
            data: Query(this.gridData).toArray(),
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        } else {
          if (this.barcodeInputList && this.barcodeInputList.length > 0) {
            this.scanned = true;
            this.rowsIndex = this.gridData.map((d, index: number) => index);
            setTimeout(() => {
              this.scanned = false;
            }, 1000);
          }
          return {
            data: this.gridData,
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        }
      },
    });
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      console.log(this.barcodeInputList);
      this.getFreshDataAndSetPaginationToGrid();
    }
  }

  async onSelectionChanged(event: any) {
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    this.actionDropDownData["selectedData"] = [];
    this.roleDropDownData["selectedData"] = [];
    this.deptDropdownData["selectedData"] = [];
    if (this.selectedRow?.id) {
      this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: [this.selectedRow.id] }));
    }
  }

  async dropDownSelection(event: any) {
    if (event?.arrayList?.[0]) {
      this.selectedDepartment = event.arrayList[0];
      if (this.selectedRow) {
        let selDepartment = this.deptDropdownData["options"].filter(d => {
          return d.id == this.selectedDepartment?.id;
        });
        this.isThirdParty = selDepartment?.isThirdParty ? selDepartment?.isThirdParty : false;
        this.selectedRole = this.roleDropDownData["options"].filter(d => {
          return d.id == selDepartment[0]?.receiveRole;
        });
        if (!!this.selectedRole) {
          this.roleDropDownData["selectedData"] = this.selectedRole;
        }
        this.getStoneActionForTransfer();
      }
    } else {
      this.selectedDepartment = undefined;
      this.selectedAction = undefined;
      this.listOfActionsByStone = undefined;
    }
  }

  async getStoneActionForTransfer() {
    if (!this.selectedDepartment?.id) return;

    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, `packet/nextActionOnBasisOfDept/${this.selectedDepartment.id}`).toPromise();
      this.actionDropDownData["options"] = data.data;
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  selectAction(action: any) {
    if (action?.id) this.selectedAction = action;
    else this.selectedAction = undefined;
  }

  roleDropDownSelection(event: any) {
    if (event?.arrayList?.[0]) this.selectedRole = event.arrayList[0];
    else this.selectedRole = undefined;
  }

  actionDropDownSelection(event: any) {
    if (event?.arrayList?.[0]) {
      this.selectStoneAction = event.arrayList[0];
      this.selectedAction = event.arrayList[0];
    } else this.selectStoneAction = undefined;
  }

  transfer() {
    let selectedRowIds: any;
    if (!!this.selectedRows && this.selectedRows?.length > 0) {
      selectedRowIds = this.selectedRows.map(sr => sr.id);
    }
    let role;
    if (!!this.selectedDepartment) {
      let selDepartment = this.deptDropdownData?.["options"].filter(d => {
        return d.id == this.selectedDepartment?.id;
      });
      if (!!selDepartment) {
        role = selDepartment[0]?.receiveRole;
      }
    }

    const payload = {
      toDepartment: this.selectedDepartment.id,
      toRole: role,
      thirdPartyActionId: this.selectedAction.id,
      listOfPacketIds: selectedRowIds,
    };
    this.httpService.transferPacket(payload).subscribe((data: any) => {
      if (data.statusCode == 200) {
        console.log("api data----?>", data?.data);
        this.infoToPrint["processRemark"] = "Transfer";
        this.infoToPrint["transRemark"] = "Transfer";
        this.infoToPrint["data"] = {};
        this.infoToPrint["data"]["packets"] = this.selectedRows;
        this.thirdPartyFilePath = data?.data?.thirdartyPacketPath;
        if (this.thirdPartyFilePath) {
          window.open(this._ConfigService.baseURL + this.thirdPartyFilePath);
        }
        let totalCts = 0;
        let pktIds = this.selectedRows.map(x => {
          totalCts += x.cts;
          return x.id;
        });
        this.infoToPrint["data"]["totalCts"] = totalCts;
        this.infoToPrint["data"]["totalPackets"] = this.selectedRows.length;
        this.infoToPrint["voucherDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
        this.infoToPrint["issueDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
        let voucherData = {
          data: data.data,
        };
        this.voucherDto = data?.data?.voucherDto;
        this.infoToPrint['fromDept'] = data?.data?.fromDept?.name;
        this.infoToPrint['toDept'] = data?.data?.toDept?.name;
        this.infoToPrint['voucherNo'] = data?.data?.voucherDto?.voucherLabel;
        this.infoToPrint['jangadNo'] = data?.data?.voucherDto?.voucherLabel;
        this.infoToPrint['issueNo'] = data?.data?.voucherDto?.voucherLabel;
        this.infoToPrint['fromCompany'] = data?.data?.fromCompany;
        this.infoToPrint['toCompany'] = data?.data?.toCompany;
        this.infoToPrint['issueFor'] = `Transfer`;
        this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
        if (data?.data?.dispatchVoucher.length > 0 && !this.isThirdParty) {
          this.dispatchData = data?.data?.dispatchVoucher;
          // this.infoToPrint['fromCompany'] = this.dispatchData?.[0]?.fromCompany;
          // this.infoToPrint['toCompany'] = this.dispatchData?.[0]?.toCompany;
          this.infoToPrint["voucherId"] = this.dispatchData?.[0]?.voucherId;
          this.infoToPrint["TocompanyName"] = this.dispatchData?.[0]?.partyName;
          this.infoToPrint["date"] = moment.utc(this.dispatchData?.[0]?.Date).local().format("DD-MMM-YYYY");
          this.infoToPrint["ttlPkt"] = this.dispatchData?.length;
          let rctsttl = 0;
          let ctsttl = 0;
          this.dispatchData?.map(d => {
            if (d.originalWt) ctsttl += d.originalWt;
            if (d.wt) rctsttl += d.wt;
            // d['lotNo'] = d.currPacketId.split('#')[0];
          });
          this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
          this.dispatchLossData = this.dispatchData?.filter(d => d.loss != null);
        }

        this.onTransferPacketSuccess(async () => {
          const modal = await this.modalCtrl.create({
            component: TrybemodalComponent,
            componentProps: {
              message: data.message,
              data: voucherData,
              dispatchData: this.dispatchData,
              voucherDto: this.voucherDto,
              printData: this.printData,
              dispatchLossData: this.dispatchLossData ? this.dispatchLossData : null,
            },
            cssClass: "modalprint",
          });
          modal.onDidDismiss().then(dataReturned => {
            if (dataReturned !== null) {
              console.log(dataReturned);
            }
          });
          return await modal.present();
        });
        // this._ConfigService.showToast("success", (data?.message || 'Packet transfered successfully'));
        this.getFreshDataAndSetPaginationToGrid();

        this.selectedAction = undefined;
        this.selectedRow = undefined;
      } else this._ConfigService.showToast("error", data?.message || "Something went wrong");
    });
  }

  onTransferPacketSuccess(afterSucces) {
    // HomeActions.getReceivedPackets(param),
    //       HomeActions.getPacketCount(),
    //       HomeActions.getOutgoingPackets(param),
    this.getFreshDataAndSetPaginationToGrid(); //for Refreshing Data of Transfer grid/return Grid
    let limit = { limit: 50, page: 0 };
    // this.store.dispatch(HomeActions.getReceivedPackets(limit));
    // this.store.dispatch(HomeActions.getOutgoingPackets(limit));
    this.store.dispatch(HomeActions.getPacketCount());
    this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
    this.deptDropdownData["selectedData"] = [];
    // this.dropDownData['selectedData'] = [];
    this.selectRoles = [];
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
    //
    afterSucces();
  }

  packetScanned(barcode) {
    this.inputBarcode = !!barcode ? barcode : "";
    this.pageInfo = this.pageInfo;
    this.getFreshDataAndSetPaginationToGrid();

    this.scanned = true;
    setTimeout(() => {
      this.scanned = false;
    }, 1000);
  }

  clearInputBarcode() {
    this.scannerComponet.onResetForm();
    this.inputBarcode = "";
    this.selectedRows = [];
    this.selectedRow = {};
    this.selectedRows = [];
    this.inputBarcode = null;
    this.barcodeInputList = [];
    this.rowsIndex = [];
    this.getFreshDataAndSetPaginationToGrid();
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }
}
