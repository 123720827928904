import { Component, HostListener, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController, ModalController, PopoverController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { Store, select } from "@ngrx/store";
import { LoadOptions } from "devextreme/data";

import { DxDataGridComponent } from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { filter, first, take, takeUntil } from "rxjs/operators";
import { actions, authUser } from "src/app/authStore/auth.selector";
import { HomeActions } from "src/app/home/state/home.action-type";
import {
  getAllStoneActionsMap,
  getCompletedPacketsData,
  getFinalPlanBYPacketID,
  getPacketDataByActionId,
  getPendingPacketsData,
  getReceivedPacketsData,
  getReceivedPacketsDataLen,
} from "src/app/home/state/home.selector";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";
import { getAllRolesOfUser, getAllTrybeDepartments, getPacketParameterHistoryData, getParamsValuesListByName, getUsersDataByPacketId } from "src/app/masterDataStore/masterData.selector";
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { saveCurrentRowData } from "src/app/tempDataStore/tempData.action";
import { tempDataActions } from "src/app/tempDataStore/tempData.action-type";
import { getCurrentAction, getCurrentSearchData } from "src/app/tempDataStore/tempData.selector";
import { StaticInfo, environment } from "src/environments/environment";
import { printData } from "../../../masterDataStore/master.data";
import { NgxFormComponent } from "../../ngx-form/ngx-form.component";
import { ScannerinputComponent } from "../../scannerinput/scannerinput.component";
import { getFinalPlanData } from "../../trybe-checker/state/checker.selector";
import { TrybemodalComponent } from "../../trybemodal/trybemodal.component";
import { SignerAction } from "../../trybesigner/state/signer.action.type";
import { getAllPacketPlans } from "../../trybesigner/state/signer.selector";
import { AutoReceiveAction } from "../auto-receive/state/auto-receive.action.type";
import { getAutoReceivePacketSelector, getScannedPacketData } from "../auto-receive/state/auto.receive.selector";
import { FinalInspectionComponent } from "../../final-inspection/final-inspection.component";
import { ColorInspectionComponent } from "src/app/color-inspection/color-inspection.component";
import { LabfinalcheckComponent } from "src/app/labfinalcheck/labfinalcheck.component";
import { ChatAlertModelComponent } from "../../chat-alert-model/chat-alert-model.component";
import { FileDownloadComponent } from "src/app/file-download/file-download.component";
import { PopoverUserComponent } from "../../popover-user/popover-user.component";
import { MultipleBarcodeScannerComponent } from "../../multiple-barcode-scanner/multiple-barcode-scanner.component";
import * as JsBarcode from "jsbarcode";
import { DatePipe } from "@angular/common";
import { PlanningGridComponent } from "../../planning-grid/planning-grid.component";
import { ActionOperationsPopupComponent } from "./action-operations-popup/action-operations-popup.component";
import * as moment from "moment";
import { OpinionMasterComponent } from "src/app/opinion-master/opinion-master.component";
import { ACTIONNAMES, ENDPOINTS, REPORTS } from "src/app/services/endpoint.enum";
import { findPacketPlanStoneByPacketIdForSmartRecut, savePacketPlansForReconfirmBidPlan } from "../../trybesigner/state/signer.actions";
import { HttpClient } from "@angular/common/http";
import { OldFileDownloadComponent } from "src/app/old-file-download/old-file-download.component";
import { OpinionViewHistoryComponent } from "src/app/opinion-screen/opinion-view-history/opinion-view-history.component";
import { OpinionScreenComponent } from "src/app/opinion-screen/opinion-screen.component";

@Component({
  selector: "action-operations",
  templateUrl: "./action-operations.component.html",
  styleUrls: ["./action-operations.component.scss"],
})
export class ActionOperationsComponent implements OnInit {
  private dataLoaded$ = new BehaviorSubject<boolean>(false);
  private isPlanLoaded$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject<any>();
  private subscriptions: Subscription[] = [];
  loggedinUser$: Observable<any>;

  public BIFURCATION = ["Edit Bifurcated Packets", "3P Cutting", "Rough Cutting", "4P Process", ACTIONNAMES.recut];
  curUserFulName: string = "";
  curUserRoles: any = [];
  allMode: string;
  checkBoxesMode: string;
  @ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  @ViewChild(NgxFormComponent)
  private ngrxFormComponet!: NgxFormComponent;
  columns;
  GenerateColumns;
  tranferGridColumns: any;
  selectedRow: any;
  mainObject: any;
  intermediatePlansForRecut: boolean;
  processIsHold: boolean = false;
  totalIncomingStones: number;
  dataSource: any = [];
  allLotsData = [];
  allAutoReceiveData: any = [];
  masterStoneTransferData = [];
  selectedRole: any;
  pageSize = 50;
  page = "Issue";
  transferGridpageSize = 10;
  transferGridPage = "Tranfer";
  transferGridDataCount: number = 0;
  fluorescenceIdList = [];
  selectedFluorescence: any;
  fluorescence: any = [];
  colors: any = [];
  shapes: any = [];
  purities: any = [];
  actionUsers: any = [];
  autoReceive: any = [];
  selectedTension: any;
  tensions: any = [];
  status: string;
  actionId: number;
  actionName: string;
  actionInfo: any;
  gridId: string = "actionGrid";
  showSubmit: boolean = true;
  test: boolean = false;
  public selectedUser: string = "";
  currSelectedMachine: any = [];
  showRemoveButton: boolean = false;
  refreshField: boolean = false;
  resetForm: boolean = false;
  reloadForm: boolean = false;
  showDrawer: boolean = false;
  formJson: any;
  userId: number;
  machinesByUser: any;
  mainFormJson: any;
  showAccordion: any;
  fromJsonInputs: any;
  isStoneSelected: boolean = false;
  refreshGrid: boolean = false;
  paramSelectionList = {};
  isTrasferDispatch: boolean = false;
  scanned: boolean;
  scannedData: any = [];
  isScanned: boolean = false;
  scannedPacketDetails$: Observable<any>;
  actionUsers$: Observable<any>;
  formsByActionId: any = {};
  selectedRows: any = [];
  showReceiveBtn: boolean = false;
  returnDisabled: boolean = true;
  rowsIndex: any = [];
  searchedPageName: string;
  clearSearchBox: string = "";
  packetStoneArrayCopy = [];
  dispatchData: any;
  dispatchLossData: any;
  isLoss: boolean = false;
  voucherDto: any;
  reportEnum = REPORTS;
  fromDate: any = moment().format("YYYY-MM-DD");
  toDate: any = moment().format("YYYY-MM-DD");
  maxToDate: string;
  minFromDate: string;
  // TransferStones
  inputBarcodeOfATransferStone: any = "";
  barcodeInputList: string[] = [];
  stonesForTransfer: any = [];
  gridData: any = [];
  gridDataCount: number = 0;
  originalPktWt: any;
  originalPcktLabel: any;
  roles = [];
  // roleDropdown
  generateReport: any = null;
  ACTIONNAMES: any = ACTIONNAMES;
  propertyName = "roleName";
  selectRoles: any = [];
  selectedManager: any = [];
  selectDepartment: any = [];
  userDropdownData: any = {
    //department Dropdown
    name: "Users",
    placeText: "Select Users",
    options: [],
    selectedData: [],

    propertName: "name",
    modeFlag: "single", //single/multiple
  };
  deptDropdownData: any = {
    //department Dropdown
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],

    propertName: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownData: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: this.selectRoles,
    propertName: "roleName",
    modeFlag: "single", //single/multiple
  };
  reportDropDownData: any = {
    name: "Report",
    placeText: "Select Report",
    options: [],
    selectedData: [],
    propertName: "reportName",
    modeFlag: "single", //single/multiple
  };
  packetHistories: any = [];
  packetPlans: any = [];
  winnerPlan: any = [];
  sendData: any;
  selectedPersonId: number;
  public keyword = "fullName";
  currActionData: any;
  selectorSubscription: Subscription;
  modalCtrl: any;
  tabName: string = "Pending";
  modifiedGridObject: any;
  public processGridPageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  public transferGridPageInfo: any = {
    limit: this.transferGridpageSize,
    page: 0,
  };
  public returnGridPageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  pageNumber: number = 1;
  pageInfo = { limit: 50, page: 0 };
  printData: any = printData;
  infoToPrint: Object = {};
  currentUserDept: string = "";
  departmentId;
  yehudaData: any;
  acknowledgementData: any;
  acknowdlegmentval: any;
  finaInspectionData: any;
  allMessages: any;
  userName: any;
  actionFormType: any = {};
  chatContentForPopup: any;
  isAlertModalopened: boolean = false;
  isPreviouslyPlanUploaded: boolean = false;
  selectedEcsvOption: any;
  formdataObjForFurtherProcess: any;
  users;
  currActionName: any;
  roleTab: boolean = false;
  packetCounts: any;
  isshowFromTo: boolean = false;
  isDisabled: boolean = false;
  reportDeptId: string = null;
  typeId: any;
  selectedReportLot = '';
  authUserData; 

  ReportOptions = [];

  kgReportDeptOption = [
    { id: 1, name: "Facet Dispatch" },
    {id: 2, name:"kgho Dispatch"}
  ]

  kpReportDeptOption = [
    { id: 1, name: "kps Dispatch" },
    { id: 2, name:"DISPATCH" }
  ]

  TypeOptions = [
    { id: "dispatch", value: 0 },
    { id: "checkerBid", value: 1 },
    { id: "signersBid", value: 2}
  ];
  arrayOfFilterActionWise: string[] = [
    ACTIONNAMES.thirdPartyTrf,
    ACTIONNAMES.transfer,
    ACTIONNAMES.return,
    ACTIONNAMES.managerTrasnfer,
    ACTIONNAMES.WITH_ME,
    ACTIONNAMES.FETCH_BARCODE,
    ACTIONNAMES.generateReport,
    ACTIONNAMES.packetReparing,
    ACTIONNAMES.responsibleUsers,
  ];
  ischecked: any;
  selectedRoleObj: any;
  filterPayload: any;
  project: string;
  // @HostListener('document:keydown', ['$event'])
  // handleKeyDown(event: KeyboardEvent) {
  //   // Check if Ctrl + P is pressed (event.ctrlKey for Windows/Linux, event.metaKey for Mac)
  //   if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
  //     event.preventDefault(); // Prevent the default browser print action
  //     this.handleCtrlP(); // Call your function
  //   }
  // }
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private storage: Storage,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    public popoverController: PopoverController,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    this.allMode = "allPages";
    this.configService.backClickedEvent.subscribe((data: string) => {
      this.refreshGrid = true;
    });
  }

  handleCtrlP() {
    console.log("Ctrl + P pressed! Call your function here.");
    if (this.printData.length > 0) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write('</head><body style="margin: 0;">');
      for (const data of this.selectedRows) {
        // const canvas = document.createElement('canvas');
        // const containerDiv = document.createElement('div');
        // containerDiv.style.display = 'flex';
        // containerDiv.style.flexDirection = 'column';
        // containerDiv.style.alignItems = 'center';
        // containerDiv.style.justifyContent = 'center';
        // containerDiv.style.fontWeight = '900';
        // containerDiv.style.fontFamily = 'monospace';
        // containerDiv.style.padding = '3px';
        // containerDiv.appendChild(canvas);
      }
    }
  }

  async ngOnInit() {
    console.log(this.searchedPageName);
    await this.getUsers();
    this.infoToPrint = {};
    this.infoToPrint = {
      company: StaticInfo["company"], //Static Name
      address: StaticInfo["address"], //Static Address
      issueFor: null,
      issueDate: null, //Jangad Date
      issueNo: null, //Jnd No,
      stoneType: StaticInfo["stoneType"],
      senderNotes: StaticInfo["senderNotes"],
      processRemark: StaticInfo["processRemark"],
      subProcessRemark: StaticInfo["subProcessRemark"],
      data: {},
    };
    this.subscriptions.push(
      this.store.pipe(select(getUsersDataByPacketId)).subscribe(data => {
        if (data) {
          console.log(data);
          // let selectedUsers = this.actionUsers.filter(a => a.isSelected == true);
          this.actionUsers = data;
          console.log(this.actionUsers);
          if (this.actionUsers && this.actionUsers.length == 1) {
            this.selectedUser = !!this.actionUsers[0] ? this.actionUsers[0].fullName : "";
            this.selectedPersonId = !!this.actionUsers[0] ? this.actionUsers[0].id : null;
            console.log(this.selectedPersonId);
            this.infoToPrint["toUser"] = this.selectedUser ?? "-";
            this.infoToPrint["toDept"] = null ?? this.currentUserDept;
          } else {
            this.selectedUser = "";
          }
        }
      })
    );
    console.log("OnInit...........");

    this.subscriptions.push(
      this.store.pipe(select(authUser)).subscribe((data: any) => {
        if (data) {
          this.authUserData = data;
          console.log("user details");
          console.log(data);
          data?.listOfActions?.map(action => {
            if (action.listOfForms?.length && action.listOfForms?.[0]?.formData) {
              this.actionFormType[action.actionName] = JSON.parse(action.listOfForms[0]?.formData)?.form?.[0]?.key;
            }
          });
          this.userName = data.fullName;
          this.curUserFulName = data.fullName;
          console.log(this.curUserFulName);
          if (!!data.listOfRoles) {
            this.curUserRoles = data.listOfRoles.map((d: any) => d.roleName);
          }
          this.infoToPrint["fromUser"] = data.fullName ?? "";
          this.infoToPrint["fromDept"] = data.departmentName ?? "";
          this.currentUserDept = data.departmentName ?? "";
          this.departmentId = data.departmentId;
          this.infoToPrint["fromCompany"] = data.companyName ?? "";
          this.infoToPrint["company"] = environment.fromLocation ?? data.companyName;
          this.infoToPrint["issueFor"] = `${data.departmentName ?? "-"} Issue`;
          // this.formsByActionId = {}
          // data.forEach((d: any) => {
          //   if (this.formsByActionId[d.id] == undefined) {
          //     this.formsByActionId[d.id] = {};
          //     this.formsByActionId[d.id] = d;
          //   }
          // });
        }
      })
    );

    if (environment.project === "kps") {
      // this.reportDropDownData.options = Object.values(REPORTS);
      this.reportDropDownData.options = [
        REPORTS.PACKET_REPORT,
        REPORTS.STOCK_REPORT,
        REPORTS.PACKET_PLAN_REPORT,
        // REPORTS.Facet_Dispatch,
        REPORTS.Yehuda_Report,
        REPORTS.signer_checker_Parameter_report,
        REPORTS.stock_Report_without_parameter,
        REPORTS.MANUAL_COLOR_FILL_REPORT,
      ];
      this.ReportOptions = this.kpReportDeptOption;
    } else if (environment.project === "kg") {
      this.reportDropDownData.options = [
        REPORTS.STOCK_REPORT,
        REPORTS.PACKET_PLAN_REPORT,
        REPORTS.Facets_Yehuda_Report,
        // REPORTS.Facet_Dispatch,
        REPORTS.VIRTUAL_TASK_Report,
        REPORTS.CSFCReport,
        // REPORTS.stock_Report_without_parameter,
        // REPORTS.Facets_Outstanding_Report,
        REPORTS.DNAReport,
        REPORTS.Packet_Status_And_Details_Report,
        // REPORTS.Bid_Prediction_Report,
        // REPORTS.Bid_Prediction_Status_Report,
        REPORTS.Signer_Wages_Report,
        REPORTS.Checker_Bid_Report,
        REPORTS.facet_signer_checker_Parameter_report
      ];
      this.ReportOptions = this.kgReportDeptOption;
    }

    this.columns = [
      { name: "Lot No", prop: "lotNo" },
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      // { name: 'Status', prop: 'historyStatus' },
      { name: "PredLimit", prop: "predLimit" },
      { name: "Last Action", prop: "lastActionName" },
      { name: "Last Action status", prop: "lastActionStatus" },
      { name: "InwardDate", prop: "inDate" },
      { name: "CreatedDate", prop: "createdDate" },
      { name: "Status", prop: "status" },
    ];

    this.GenerateColumns = [
      { name: "Report Of", prop: "reportOf" },
      { name: "Created By", prop: "createdBy" },
      { name: "CreatedDate", prop: "createdDate" },
      { name: "fromDate", prop: "fromDate" },
      { name: "toDate", prop: "toDate" },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      // { name: 'Status', prop: 'historyStatus' },
      { name: "Department Name", prop: "department" },
      { name: "FileName", prop: "fileName" },
      { name: "Status", prop: "status", columnTemplate: "file-upload-status" },
    ];
    this.tranferGridColumns = [
      { name: "Lot No", prop: "lotNo", columnTemplate: "lot-svg-template" },
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      // { name: 'Status', prop: 'historyStatus' },
      // { name: "InwardDate", prop: "inwardDate" },
      // { name: "CreatedDate", prop: "createdDate" },
      { name: "Last Action", prop: "lastActionName" },
      { name: "Last Action Date", prop: "actionModifiedDate" },
    ];
    this.subscriptions.push(
      this.store.pipe(select(actions)).subscribe(data => {
        if (data) {
          this.formsByActionId = {};
          data.forEach((d: any) => {
            if (this.formsByActionId[d.id] == undefined) {
              this.formsByActionId[d.id] = {};
              this.formsByActionId[d.id] = d;
            }
          });
        }
      })
    );
    this.store
      .pipe(select(getReceivedPacketsDataLen))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        console.log(data);
        this.packetCounts = data;
      });
    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(async (data: any) => {
          if (data) {
            console.log(data);
            this.currActionName = data?.name;
            this.status = data.status;
            this.actionId = data.id;
            this.actionName = data.name;
            this.actionInfo = data;
            if (data.actionForm) {
              let formDataObject = JSON.parse(data.actionForm);
              console.log(formDataObject);
              let parsedFormData: any = formDataObject.form;
              console.log(parsedFormData);
              console.log(formDataObject);
              console.log(this.actionName);
              if (this.actionName == "further process") {
                this.formdataObjForFurtherProcess = formDataObject;
              }
              //this.formJson = parsedFormData
              this.fromJsonInputs = formDataObject.inputJson;
              let fdata = parsedFormData.map(field => {
                if (field.type == "select" || "searchSelect") {
                  let paramName = field.key;
                  console.log(paramName);
                  this.subscriptions.push(
                    this.store.pipe(select(getParamsValuesListByName(paramName))).subscribe(data => {
                      if (data) {
                        this.paramSelectionList[paramName] = data;
                        console.log(data);
                        let newData = data.map(x => {
                          let newobj = {
                            label: x.name,
                            value: x.id,
                          };
                          return newobj;
                        });
                        // console.log(JSON.stringify(newData))
                        //add flur in select options
                        field.templateOptions.options = newData;
                        console.log(newData);
                      }
                    })
                  );
                }
                return field;
              });
              this.formJson = fdata;
              this.mainFormJson = fdata;
            }

            if (this.status == "Pending") {
              this.subscriptions.push(
                this.store.pipe(select(getPendingPacketsData(this.actionId))).subscribe(data => {
                  if (data) {
                    this.dataSource = data;
                  }
                })
              );
            } else if (this.status == "Completed") {
              this.subscriptions.push(
                this.store.pipe(select(getCompletedPacketsData(this.actionId))).subscribe(data => {
                  if (data) {
                    this.dataSource = data;
                  }
                })
              );
            } else {
              this.subscriptions.push(
                this.store.pipe(select(getPacketDataByActionId(this.actionId))).subscribe(data => {
                  if (data) {
                    this.dataSource = data;
                  }
                })
              );
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentSearchData))
        .pipe(
          filter(d => !!d),
          first()
        )
        .subscribe((d: any) => {
          if (d) {
            console.log(d);
            this.searchedPageName = d.name;
            this.actionInfo = d;
            if (this.searchedPageName === ACTIONNAMES.PrevuniversalOpinionView || this.searchedPageName === ACTIONNAMES.RECONFIRM_FINAL_PLAN) {
              this.processIsHold = true;
            } else this.processIsHold = false;
          }
        })
    );

    if (this.searchedPageName === ACTIONNAMES.generateReport) {
      this.refresh();
    }
    this.subscriptions.push(
      this.store.pipe(select(getAllStoneActionsMap)).subscribe(dt => {
        if (dt) {
          this.currActionData = dt;
        }
      })
    );
    if (this.searchedPageName == "Transfer" || this.searchedPageName == "Manager Transfer" || this.searchedPageName == "Return" || this.searchedPageName == 'Generate Reports') {
      console.log(".............Transfer Page........");
      let limit = { limit: 50, page: 1 };
      if (this.searchedPageName !== ACTIONNAMES.return && this.searchedPageName !== ACTIONNAMES.managerTrasnfer && this.searchedPageName !== ACTIONNAMES.transfer)
        this.store.dispatch(HomeActions.getReceivedPackets(limit));

      // this.subscriptions.push(
      //   this.store.pipe(select(getReceivedPacketsData)).subscribe((data) => {
      //     if (data) {
      //       console.log(data);
      //       this.masterStoneTransferData = data;
      //       this.stonesForTransfer = data;
      //       this.gridData = data;
      //     }
      //   })
      // );
      this.pageInfo = this.transferGridPageInfo;
      if (this.searchedPageName !== ACTIONNAMES.return && this.searchedPageName !== ACTIONNAMES.managerTrasnfer && this.searchedPageName !== ACTIONNAMES.transfer) {
        this.getFreshDataAndSetPaginationToGrid(); //get fresh data from server and update grid Data of tranfer grid and return grid
      }
      this.httpService.currenttranferReturnAssignGridData$.subscribe(data => {
        if (data) {
          console.error("currenttranferReturnAssignGridData$");
          console.log(data);
          //transfer and return grid data///get fresh data from server and update grid Data of tranfer grid and return grid
          this.gridDataCount = Number(data.countOfTotalPackets || data.count);
          this.gridData = data.packetData;
        }
      });
      if (this.searchedPageName == "Transfer") {
        this.store.dispatch(masterDataActions.getRolesForUser());
        // this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
        this.store.dispatch(masterDataActions.clearTrybeDepartments());
        this.subscriptions.push(
          this.store.pipe(select(getAllRolesOfUser)).subscribe(data => {
            if (data) {
              console.log(data);
              this.dropDownData["options"] = data;
            }
          })
        );
        this.subscriptions.push(
          this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
            if (data) {
              this.deptDropdownData["options"] = data;
            }
          })
        );
      } else if (this.searchedPageName === "Manager Transfer") {
        this.httpService.getUserRoleManager().subscribe((res: any) => {
          if (res?.data) {
            this.userDropdownData["options"] = res?.data.map((v: any) => {
              return { ...v, name: v.fullName };
            });
          }
        });
        console.log(this.dropDownData["options"]);
        console.log(this.deptDropdownData["options"]);
      } else if (this.searchedPageName == ACTIONNAMES.return) {
        console.log("---------Return-----------");
        this.pageInfo = this.returnGridPageInfo;
        this.columns = [
          { name: "Lot No", prop: "lotNo" },
          { name: "Packet ID", prop: "packetId" },
          { name: "Cts", prop: "cts" },
          { name: "PredLimit", prop: "predLimit" },
          { name: "Last Action", prop: "lastActionName" },
          { name: "Last Action status", prop: "lastActionStatus" },
          { name: "Last Action Date", prop: "actionModifiedDate" },
        ];
        // this.getFreshDataAndSetPaginationToGrid(); //get fresh data from server and update grid Data of tranfer grid and return grid
        // this.httpService.currenttranferReturnAssignGridData$.subscribe((data) => {
        //   if (data) {
        //     //transfer and return grid data///get fresh data from server and update grid Data of tranfer grid and return grid
        //     this.gridDataCount = data.countOfTotalPackets;
        //     this.gridData = data.packetData;
        //   }
        // });
      }
    } else if (this.searchedPageName == "Planning") {
      console.log("integrating api here");
      // this.subscriptions.push(this.store.pipe(select(getAllPacketPlans)).subscribe(data => {
      //   if (data) {
      //     console.log('Planning');
      //     console.log(data);
      //     this.packetPlans = data;
      //   }
      // }));
      // this.status = 'Pending';
      // this.dataSource = [{
      //   "id": 2100,
      //   "packetId": "9776-09-FCT4-6#000",
      //   "cts": 1.075,
      //   "inDate": "2021-09-30T10:42:28.000Z",
      //   "lotNo": "9776-09-FLT4-6",
      //   "outDate": "2021-10-15T00:00:00.000Z",
      //   "pktGrade": "C",
      //   "pktStatus": "R",
      //   "pktNo": "105",
      //   "predLimit": 2,
      //   "currAction": 123,
      //   "currState": 2,
      //   "currDept": 7,
      //   "currLocation": 6,
      //   "userId": 118,
      //   "responsibleUserId": 118,
      //   "createdDate": "2022-03-21T08:17:01.000Z",
      //   "createdBy": "2-trybe-di1",
      //   "modifiedDate": "2022-03-31T11:00:28.000Z",
      //   "modifiedBy": "2-trybe-di1",
      //   "currActionName": "In Coming",
      //   "currStateName": "Received"
      // }]
      console.log("------------AO.........");
      console.log(this.status);
      console.log(this.dataSource);
    } else if (this.searchedPageName == "Auto Receive" || this.BIFURCATION.includes(this.searchedPageName)) {
      if (this.searchedPageName == "Auto Receive") {
        this.columns = [
          { name: "Lot No", prop: "lotNo" },
          { name: "Packet ID", prop: "packetId" },
          { name: "Cts", prop: "cts" },
          // { name: "Pred Limit", prop: "predLimit" },
          { name: "Auto Receive From Action", prop: "currActionName" },
          // { name: "InwardDate", prop: "inDate" },
          { name: "From User", prop: "fromUser" },
          { name: "Action assigned date", prop: "createdDate" },
        ];
      } else if (this.BIFURCATION.includes(this.searchedPageName)) {
        this.columns = [
          { name: "Lot No", prop: "lotNo" },
          { name: "Packet ID", prop: "packetId" },
          { name: "Cts", prop: "cts" },
          { name: "Pred Limit", prop: "predLimit" },
          { name: "Auto Receive From Action", prop: "currActionName" },
          { name: "InwardDate", prop: "inDate" },
          { name: "From User", prop: "fromUser" },
          { name: "CreatedDate", prop: "createdDate" },
        ];
      }
      if (this.searchedPageName == "Auto Receive" || this.searchedPageName == "Rough Cutting") {
        this.dataSource = [];
        this.store.dispatch(AutoReceiveAction.getAutoReceivePacket());
        this.subscriptions.push(
          this.store.pipe(select(getAutoReceivePacketSelector)).subscribe(data => {
            if (data) {
              console.log(data);
              if (this.searchedPageName == ACTIONNAMES.autoReceive) {
                this.dataSource = data.map(item => ({
                  ...item,
                  createdDate: moment(item.createdDate).format("DD-MMM-YYYY hh:mm a"),
                }));
              } else {
                this.dataSource = data;
              }
              if (!this.allAutoReceiveData.length) this.allAutoReceiveData = data;
            }
          })
        );
      }
      this.store.pipe(select(getFinalPlanData)).subscribe((data: any) => {
        if (data) {
          this.isPlanLoaded$.next(data.length !== 0);
          this.winnerPlan = data;
          console.log(this.winnerPlan);

          if (this.selectedRow?.id && (this.selectedRow?.currActionName == "Cutting" || this.BIFURCATION.includes(this.searchedPageName))) {
            this.showDynamicFormOnStoneSelection();
          }
        }
      });
      this.isPlanLoaded$.pipe(take(1)).subscribe(isPlan => {
        if (isPlan) {
          console.log("isPlan");
          console.log(isPlan);
          this.showActionFormAsperData();
        }
      });
    } else if (this.searchedPageName == "Fetch Barcode" || this.searchedPageName == "Review Packet Files") {
      this.columns = [
        { name: "Lot No", prop: "lotNo" },
        { name: "Packet ID", prop: "packetId" },
        { name: "Cts", prop: "cts" },
        { name: "Pred Limit", prop: "predLimit" },
        // { name: 'Auto Receive From Action', prop: 'currActionName' },
        { name: "InwardDate", prop: "inDate" },
        { name: "CreatedDate", prop: "createdDate" },
      ];
    } else if (this.searchedPageName == "First Allocation") {
      this.setFirstAllocationData();
    } else if (this.searchedPageName == ACTIONNAMES.mistakeEntry) {
      this.getMistakeEntryData();
    }

    this.subscriptions.push(
      this.store.pipe(select(getPacketParameterHistoryData)).subscribe(history => {
        if (history) {
          console.log(history);
          this.packetHistories = [];
          for (let index = 0; index < history.length; index++) {
            const d = history[index];
            if (d.historyStatus == "Completed") {
              this.packetHistories.push(d);
            }
          }
        }
      })
    );
  }
  segmentChanged(e: any) {
    this.tabName = e.detail.value;
    console.log("segmentChanged");
    console.log(this.tabName);
    console.log(this.actionId);
    this.store.dispatch(
      HomeActions.getActionNStatusWisePacketList({
        actionId: this.actionId,
        status: this.tabName,
      })
    );
  }
  async dropDownSelection(ev: any, type?: string) {
    if (ev.property == "Report" && ev.arrayList.length == 0) {
      // this.isshowFromTo = false
    }
    let endpoint = "";
    if (type == "managerUser") {
      this.selectedManager = ev.arrayList;
    }

    // if(ev.arrayList[0] == this.reportEnum.ALL_ACTION_PARAMETERS_REPORT){
    //   window.open(environment.AllActionParameterReport);
    // }
    // else if (ev.arrayList[0] == this.reportEnum.lot_report) {
    //   if(environment.project == 'kps'){
    //     window.open(environment.LotReport);
    //   }else if(environment.project == 'kg'){
    //     endpoint = `packet/kgLotwiseReport`
    //     this.getReportURL(endpoint)
    //   }
    // }
    // else if (ev.arrayList[0] == this.reportEnum.curr_Dept_Param_report) {
    //   if(environment.project == 'kps'){
    //     window.open(environment.currDeptParamReport);
    //   }else if(environment.project == 'kg'){
    //     endpoint = `packet/kgCurDeptParameterReport`
    //     this.getReportURL(endpoint)
    //   }
    // }
    else if (ev.arrayList[0] == this.reportEnum.signer_checker_Parameter_report) {
      if (environment.project == "kps") {
        // this.isshowFromTo = true;
        endpoint = `packet/getPlannerCheckerPlans?fromDate=${this.fromDate}&toDate=${this.toDate}`;
      } else if (environment.project == "kg") {
        endpoint = `packet/kgSignerCheckerParameterReport`;
      }
    } else if (ev.arrayList[0] == this.reportEnum.PACKET_REPORT) {
      if (environment.project == "kps") {
        this.getpacketReports();
      } else if (environment.project == "kg") {
        endpoint = `packet/kgPreVirtualPlanningStatusReport`;
      }
      // window.open(environment.PrevirtualPlanningStatusReport);
    }
    // else if (ev.arrayList[0] == this.reportEnum.Yehuda_Report) {
    //   // window.open(environment.YehudaReport);
    //   if(environment.project == 'kps'){
    //     window.open(environment.YehudaReport);
    //   }else if(environment.project == 'kg'){
    //     endpoint = `packet/YehudaDataReport`
    //     this.getReportURL(endpoint)
    //   }
    // }
    // else if (ev.arrayList[0] == this.reportEnum.Color_REPORT) {
    //   if(environment.project == 'kps'){
    //     window.open(environment.ColorReport);
    //   }else if(environment.project == 'kg'){
    //     endpoint = `packet/kgManualColorFillReport`
    //     this.getReportURL(endpoint)
    //   }
    //   // this.getpacketReports();
    // }
    else if (ev.arrayList[0] == this.reportEnum.STOCK_REPORT) {
      // window.open(environment.StockReportUrl);
      // this.generateStockReport();
      if (environment.project == "kps") {
      } else if (environment.project == "kg") {
        endpoint = `packet/kgCurDeptParameterReport`;
      }
    }
    //  else if (ev.arrayList[0] == this.reportEnum.QC_REPORT) {
    //     this.generateQcReport();
    //   }
    else if (ev.arrayList[0] == this.reportEnum.PACKET_PLAN_REPORT) {
      // this.isshowFromTo = true;
      // window.open(environment.PacketPlanReportUrl);
      // this.generatePlanDataForEachDept();
    }
    console.log("dropDownSelection....");
    console.log(ev);
    // this.infoToPrint['toUser'] = null ?? ;
    if (ev && ev.arrayList.length >= 1) {
      this.infoToPrint["toDept"] = ev.arrayList[0].name;
    }
    let property = ev.property;
    let idList = ev.idList;
    let selected = ev.arrayList;
    if (property == this.deptDropdownData["propertName"]) {
      // department dropdown
      this.onDepartmentSelection(ev);
    } else if (property == this.dropDownData["propertName"]) {
      // role dropdown event
      if (selected.length > 0) {
        this.selectRoles = selected;
      } else {
        this.selectRoles = selected;
      }
    }
    console.log(this.selectRoles);
    if (this.selectRoles && this.selectRoles.length >= 1) {
      this.infoToPrint["toUser"] = this.selectRoles[0].roleName;
    }
  }

  selectionFieldClicked(event: any) {
    let parentElement: HTMLElement = event.target.parentElement.parentElement;
    if (event.target.innerHTML === event.target.innerText) {
      parentElement = parentElement.parentElement;
    }
    const children: HTMLCollection = parentElement?.children;
    const dropdownListElement: HTMLElement = children[1] as HTMLElement;
    if (dropdownListElement && !dropdownListElement.hidden) {
      const inputFieldContainer: HTMLElement = dropdownListElement.children[0] as HTMLElement;
      const inputField: HTMLElement = inputFieldContainer.querySelector("li > input") as HTMLElement;
      inputField?.focus();
    }
  }

  onBackButtonClick() {
    console.log("Back Button...");
    if (this.searchedPageName == "Return") {
      this.store.dispatch(masterDataActions.removeUsersByPacketId());
    }
  }
  //scanner code start
  async barcodeDetection(barcode: any) {
    this.dataLoaded$.next(false);
    // barcode = "9694-08-COMMDI#36"
    console.log("%cTestRaju barcodeDetection", "color:green;font-weight:bold;");
    console.log(barcode);
    if (barcode == undefined || barcode == "") {
      this.showModal(false, "No barcode/Packet Id detected", {});
      return;
    }
    let actionForm: any;
    this.dataSource = [];
    this.rowsIndex = [];
    this.status = "";
    let packetId = {
      packetId: barcode,
    };
    console.log(barcode);
    console.log(packetId);

    // this.httpService.showLoader();
    await this.store.dispatch(AutoReceiveAction.getScannedPacketDetails({ packetIdObj: packetId }));

    this.scannedData = [];
    // scannedPacketDetails

    let n = await this.store
      .pipe(select(getScannedPacketData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(packet => {
        if (packet.length > 0) {
          this.dataLoaded$.next(true);

          this.unsubscribe$.complete();
          console.log("sc packet Details");

          console.log(packet);
          this.store.dispatch(saveCurrentRowData({ rowData: packet[0] }));
          this.scannedData = packet;
          this.dataSource = [];
          this.selectedRows = [];
          this.rowsIndex = [];
          packet.forEach((d: any, k) => {
            let index = k; //for single stone
            let obj = { ...d };
            if (!!obj.inputDetails && obj.inputDetails.length > 0 && obj.historyStatus == "Completed") {
              // obj.inputDetails = null
              this.status = obj.historyStatus;
            } else {
              this.status = "Pending";
            }
            this.dataSource.push(obj);
            this.selectedRow = obj;
            this.selectedRows.push(this.dataSource[index]);
            this.rowsIndex.push(index);
            if (!!this.formsByActionId[d.currAction]) {
              if (!this.formsByActionId[d.currAction].listOfForms[0]) {
                this.showModal(false, "No action form found.", {});
                return;
              }
              if (this.searchedPageName == "Edit Bifurcated Packets") {
                let cuttingId;
                for (const key in this.formsByActionId) {
                  const action = this.formsByActionId[key];
                  if (action.actionName === "Cutting") {
                    actionForm = action?.listOfForms[0];
                  }
                }
                // let cuttingId = this.formsByActionId?.find(fbai => fbai.actionName == 'Cutting')
                // actionForm = this.formsByActionId[cuttingId].listOfForms[0];
              } else actionForm = this.formsByActionId[d.currAction].listOfForms[0];
              console.log(actionForm);
              // this.status = "";
              this.actionId = d.currAction;
              this.actionName = actionForm.name;
              console.log("sccanneddddddd");
              // console.log(winnerPlan);
              if (this.actionName == "Cutting" || this.BIFURCATION.includes(this.searchedPageName)) {
                this.store.dispatch(
                  HomeActions.getPacketPlanStoneIfIsFinal({
                    packetId: this.selectedRow.id,
                  })
                );
                this.store.pipe(select(getFinalPlanData)).subscribe((data: any) => {
                  if (data) {
                    this.winnerPlan = data;
                  }
                });
              }
              let formDataObject = JSON.parse(actionForm.formData);
              let parsedFormData: any = formDataObject.form;
              this.fromJsonInputs = formDataObject.inputJson;
              let fdata = parsedFormData.map(field => {
                if (field.type == "select" || field.type == "searchSelect") {
                  let paramName = field.key;
                  console.log(paramName);
                  this.subscriptions.push(
                    this.store.pipe(select(getParamsValuesListByName(paramName))).subscribe(data => {
                      if (data) {
                        this.paramSelectionList[paramName] = data;
                        console.log(data);
                        let newData = data.map(x => {
                          let newobj = {
                            label: x.name,
                            value: x.id,
                          };
                          return newobj;
                        });
                        console.log(JSON.stringify(newData));
                        //add flur in select options
                        field.templateOptions.options = newData;
                      }
                    })
                  );
                }
                return field;
              });
              this.formJson = fdata;
              this.mainFormJson = fdata;
            }
          });
        }
      });

    // n.unsubscribe()

    // // return
    this.dataLoaded$.subscribe(res => {
      console.log(res);
      if (res) {
        this.refreshGrid = true;
        this.scanned = true;
        this.isScanned = true;
        setTimeout(() => {
          this.refreshGrid = false;
          this.scanned = false;
          if (this.dataSource.length > 0) {
            setTimeout(() => {
              console.log(this.currActionName);
              if (
                this.actionName !== "Common tick mark" &&
                !(this.actionFormType[this.selectedRow?.currActionName]?.toLowerCase() === "istrue" || this.actionFormType[this.selectedRow?.currActionName]?.toLowerCase() === "weight")
              ) {
                this.showActionFormAsperData();
              }
            }, 100);
          } else if (this.scannedData.length === 0) {
            this.showModal(false, "This Packet has already been AutoReceived.", {});
          } else {
            this.showModal(false, "Invalid barcode/Packet Id, no record found.", {});
          }
          this.isScanned = false;
        }, 1000);
      }
    });
  }

  async packetScanned(evt) {
    if (this.searchedPageName === "Review Packet Files") {
      await this.getPacketFiles(evt);
      return;
    } else if (this.searchedPageName === "First Allocation") {
      console.log(evt);
      let payload = {
        packetId: [evt],
      };
      await this.fetchFirstAllocationData(payload);
      return;
    }
    if (this.searchedPageName === "Fetch Barcode" || this.searchedPageName === "Edit Bifurcated Packets") {
      let payload = {
        packetId: evt,
      };
      this.dataSource = [];

      this.httpService.fetchBarcode(payload).subscribe(
        (res: any) => {
          this.dataSource = res?.data || [];

          if (res.statusCode === 200) {
            if (!this.dataSource?.length) this.configService.showToast("error", "No data found!");
          } else {
            this.configService.showToast("error", res.error.message || "Something went wrong!");
          }
        },
        (err: any) => {
          this.configService.showToast("error", err.message || "Something went wrong!");
        }
      );
    } else if ((this.searchedPageName || "").toLowerCase() === "reconfirm final plan" || this.searchedPageName === "Review For Smart Recut") {
      if (!evt) return;
      let payload = {
        packetId: evt,
      };

      const res = await this.httpService.postDataUsingPromise(true, "packetPlanStone/findAllByPacketIdOnAnyPacket", payload);

      if (res?.data) {
        this.dataSource = [res.data];
      } else {
        this.configService.showToast("error", "No data found!");
      }
    }
      else if (this.searchedPageName===ACTIONNAMES.Reconfirm_Bid_plan) {
      if (!evt) return;
      let payload = {
        packetId: evt,
      };

      const res = await this.httpService.postDataUsingPromise(true, `packetPlan/getPacketDataOfSigner`,payload);
      if (res?.data) {
       this.dataSource = [{ ...res.data.packetData, isCheckerView: res.data.isCheckerView }];

      } else {
        this.configService.showToast("error", "No data found!");
      }
    }
    else if (this.searchedPageName === ACTIONNAMES.PrevuniversalOpinionView) {
      if (!evt) return;
      let payload = {
        packetId: evt,
      };

      const res = await this.httpService.postDataUsingPromise(true, ENDPOINTS.findAllByPacketIdOnAnyPacket, payload);

      // if (res?.data && res.statusCode == 200)  this.viewHistory(res?.data.id);
      if (res?.data && res.statusCode == 200)  this.lockModal(res?.data, true);
      else this.configService.showToast("error", "No data found!");
    } else if (this.searchedPageName === ACTIONNAMES.UNIVERSAL_OPINION_VIEW) {
      if (!evt) return;
      let payload = {
        packetId: evt,
      };

      const res = await this.httpService.postDataUsingPromise(true, ENDPOINTS.findAllByPacketIdOnAnyPacket, payload);

      // if (res?.data && res.statusCode == 200)  this.viewHistory(res?.data.id);
      if (res?.data && res.statusCode == 200)  this.newLockModal(res?.data, true);
      else this.configService.showToast("error", "No data found!");
    }
  }
  clearBarcode(isClear: boolean, allAutoReceiveData?: any) {
    if (!!isClear) {
      this.ngrxFormComponet.closeDrawer();
      this.selectedRow = {};
      this.selectedRows = [];
      this.rowsIndex = [];
      this.dataSource = [];
      if (allAutoReceiveData?.length) this.dataSource = allAutoReceiveData;
      this.refreshGrid = true;
      this.isScanned = false;
      this.status = "";

      if (!!this.scannerComponet) {
        this.scannerComponet.onResetForm();
      }
      setTimeout(() => {
        this.refreshGrid = false;
        // this.closeDynamicForm();
      }, 1000);
    }
  }

  setFirstAllocationData() {
    this.columns = [
      { name: "Lot No", prop: "lotNo" },
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      { name: "PredLimit", prop: "predLimit" },
      { name: "Winning Department", prop: "winningDept" },
      { name: "Polish Grade", prop: "polishGrade" },
      { name: "InwardDate", prop: "inDate" },
      { name: "CreatedDate", prop: "createdDate" },
    ];
    this.fetchFirstAllocationData(null);
  }

  async fetchFirstAllocationData(payload) {
    try {
      const res: any = await this.httpService.postDataUsingPromise(true, "packet/listOfPacketWhoseBidAreCompleted", payload);
      this.dataSource = res?.data || [];
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
    }
  }

  clearPackets(isClear: boolean) {
    console.log("cancelling");
    if (!!isClear) {
      this.ngrxFormComponet.closeDrawer();
      this.selectedRow = {};
      if (this.searchedPageName == "First Allocation") {
        this.fetchFirstAllocationData(null);
      }
      if (this.arrayOfFilterActionWise.includes(this.currActionName)) {
        this.filterForActionWise(null);
      }
      this.selectedRows = [];
      this.rowsIndex = [];
      // this.dataSource = [];
      this.refreshGrid = true;
      this.isScanned = false;
      // this.status = "";
      if (!!this.scannerComponet) {
        this.scannerComponet.onResetForm();
      }
      setTimeout(() => {
        this.refreshGrid = false;
        // this.closeDynamicForm();
      }, 1000);
    }
  }

  async getPacketPlans(packetId) {
    return new Promise((resolve, reject) => {
      this.httpService.getPacketPlanStoneIfIsFinal(packetId).subscribe(res => {
        console.log(res);

        if (res && res.data != "no data found") {
          console.log(res.data);

          resolve(res.data);
        } else {
          reject([]);
        }
      });
    });
  }

  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  async infoFunction(event: any) {
    if (this.searchedPageName == "First Allocation") {
      let data = event.row.data;
      let packetId = data.id;
      console.log(packetId);
      let packetPlans = [];
      if (!!packetId) {
        this.httpService.findAllByPacketIdOnBasisOfIsSelectedOfWinning(packetId).subscribe(async (d: any) => {
          if (d.statusCode == 200) {
            if (!!d.data && d?.data?.packetPlans.length) {
              packetPlans = d.data?.packetPlans[0]?.packetPlanStones;

              const Modal = await this.modalController.create({
                component: FinalInspectionComponent,
                cssClass: "finalInspection",
                componentProps: {
                  data: {
                    fromPage: "First Allocation",
                    packetPlans: packetPlans,
                  },
                },
              });
              Modal.onDidDismiss().then((d: any) => this.handleModalDismisssFinalInspection(d));
              return await Modal.present();
            }
          } else {
            this.configService.showToast("error", d.message);
          }
        });
      }
    } else if (event?.row?.data?.id) {
      this.httpService.getData(true, `packet/getOrderOnBasisOfPacketId/${event?.row?.data?.id}`, {}).subscribe(async (res: any) => {
        const roughCriteriaData = res.data.map((dt: any) => {
          if (!dt?.uiJson)
            return {
              orderNo: dt?.orderNo,
              orderDate: dt?.orderDate,
            };
          const uiJson = JSON.parse(dt?.uiJson);
          return {
            ...uiJson,
            Shape: uiJson?.dropdownJson?.Shape?.datas?.map((sdt: any) => sdt.name).join(", "),
            Color: uiJson?.dropdownJson?.Color?.datas?.map((sdt: any) => sdt.name).join(", "),
            Clarity: uiJson?.dropdownJson?.Clarity?.datas?.map((sdt: any) => sdt.name).join(", "),
            Propagation: uiJson?.dropdownJson?.Propagation?.datas?.map((sdt: any) => sdt.name).join(", "),
            CUT: uiJson?.dropdownJson?.CUT?.datas?.map((sdt: any) => sdt.name).join(", "),
            Polish: uiJson?.dropdownJson?.Polish?.datas?.map((sdt: any) => sdt.name).join(", "),
            Symmetry: uiJson?.dropdownJson?.Symmetry?.datas?.map((sdt: any) => sdt.name).join(", "),
            Fluorescence: uiJson?.dropdownJson?.Fluorescence?.datas?.map((sdt: any) => sdt.name).join(", "),
            Tinch: uiJson?.dropdownJson?.Tinch?.datas?.map((sdt: any) => sdt.name).join(", "),
            Natts: uiJson?.dropdownJson?.Natts?.datas?.map((sdt: any) => sdt.name).join(", "),
            Milkyness: uiJson?.dropdownJson?.Milkyness?.datas?.map((sdt: any) => sdt.name).join(", "),
            Topsopens: uiJson?.dropdownJson?.Topsopens?.datas?.map((sdt: any) => sdt.name).join(", "),
            Botsopen: uiJson?.dropdownJson?.Botsopen?.datas?.map((sdt: any) => sdt.name).join(", "),
            perpieceRate: uiJson?.perpieceRate?.from,
            sizeRange: (uiJson?.sizeRange?.from || "") + "-" + (uiJson?.sizeRange?.to || ""),
            diameterRatio:
              uiJson?.diameter?.from || uiJson?.diameter?.to ? (uiJson?.diameter?.from || "") + "-" + (uiJson?.diameter?.to || "") : (uiJson?.ratio?.from || "") + "-" + (uiJson?.ratio?.to || ""),
            table: (uiJson?.table?.from || "") + "-" + (uiJson?.table?.to || ""),
            cheight: (uiJson?.cheight?.from || "") + "-" + (uiJson?.cheight?.to || ""),
            girdle: (uiJson?.girdle?.from || "") + "-" + (uiJson?.girdle?.to || ""),
            cangle: (uiJson?.cangle?.from || "") + "-" + (uiJson?.cangle?.to || ""),
            culet: (uiJson?.culet?.from || "") + "-" + (uiJson?.culet?.to || ""),
            depth: (uiJson?.depth?.from || "") + "-" + (uiJson?.depth?.to || ""),
            pdepth: (uiJson?.pdepth?.from || "") + "-" + (uiJson?.pdepth?.to || ""),
            pangle: (uiJson?.pangle?.from || "") + "-" + (uiJson?.pangle?.to || ""),
            remark: uiJson?.remark,
            orderNo: dt?.orderNo,
            orderDate: dt?.orderDate,
          };
        });
        if (roughCriteriaData?.length) {
          const popover = await this.popoverController.create({
            component: PopoverUserComponent,
            cssClass: "planningRowOrderspopoup",
            componentProps: {
              forPage: "roughCriteria",
              roughCriteriaData,
            },
          });
          await popover.present();
        }
      });
    } else {
      console.log("-->>>>");
    }
  }

  async showActionFormAsperData() {
    console.log(this.formJson);
    console.log("show dynamic form as per data");
    if (!!this.selectedRow && this.winnerPlan) {
      if ((this.actionName == "Cutting" || this.BIFURCATION.includes(this.searchedPageName)) && !!this.formJson) {
        let packetPlans: any = this.winnerPlan;
        console.log(packetPlans);
        let packetPlansStones = [];
        if (packetPlans) {
          packetPlansStones = packetPlans?.[0]?.["packetPlanStones"];
          if (packetPlansStones) {
            packetPlansStones = await packetPlansStones
              .map((e, index) => {
                let obj = { ...e };
                console.log(e);
                let planTag = this.getHexValue(index);
                let exppol = e.expPol;
                let rhtwt = e.sawRghWgt;
                let shape = e.shape;
                let newobj = {
                  label: planTag + "    " + rhtwt + "    " + shape + "    " + exppol,
                  value: obj.id,
                  disabled: obj.inActive ? true : false,
                  isDone: e.isDone,
                  inActive: e.inActive,
                };
                return newobj;
              })
              .filter(e => e.inActive == null);

            packetPlansStones = packetPlansStones?.filter(pkt =>
              this.searchedPageName == "Edit Bifurcated Packets" ? pkt : this.searchedPageName == "4P Process" ? pkt.value == this.selectedRow.packetPlanStoneId : !pkt.isDone
            );
          }
        }
        this.formJson = await this.generateDynamicFormAccoringPlans(this.mainFormJson, packetPlansStones);
        this.showAccordion = true;
        this.reloadForm = true;
        setTimeout(() => {
          this.reloadForm = false;
        }, 500);
      }
      if (!!this.selectedRow && !!this.selectedRow.inputDetails && this.selectedRow.inputDetails.length > 0 && this.selectedRow.historyStatus == "Completed") {
        console.log(this.selectedRow.inputDetails);
        console.log(this.formJson);
        // let modifiedFormJson = this.formJson.filter(d => d.key != "file");// return form array without attachment file
        if (!!this.formJson) {
          this.formJson.forEach((d: any) => {
            if (d.hideExpression == false) {
              d.hideExpression = true;
            }
          }); // return form array without attachment file
        }

        // let modifiedFormJson = this.formJson.length > 1 ? this.formJson.filter(d => d.key != "file") : this.formJson;// return form array without attachment file

        this.formJson?.map(f => {
          let paramName = f.key;
          console.log(paramName);
          // if (paramName == 'Tension') {//remove static Code Raju
          //   paramName = 'Tension Mapping';
          // }
          let inputVal = this.selectedRow.inputDetails.find(i => {
            console.log(i.paramName);

            if (i.paramName == paramName) {
              return i;
            }
          });
          console.log(inputVal);
          if (inputVal) {
            inputVal = inputVal.paramIValue || inputVal.paramBValue || inputVal.paramDValue || inputVal.paramSValue;
            //for selection input
            if (f.type == "select") {
              let selectedData = this.paramSelectionList[paramName].filter(x => x.id == inputVal)[0];
              f.defaultValue = selectedData.id;
            } else {
              f.defaultValue = inputVal;
            }
          }
          return f;
        });

        this.refreshField = true;
        setTimeout(() => {
          this.refreshField = false;
        }, 500);
        console.log(this.formJson);
      } else {
        if (!!this.formJson) {
          this.formJson = this.formJson.map(d => {
            let m = { ...d };
            if (m.key == "file") {
              const onInit = field => {
                // console.log('onInit');
                // console.log(field);
              };
              // const onChange = (field) => {
              //   console.log('onChange');
              //   console.log(field);
              // }
              if (m.hooks) {
                m.hooks["onInit"] = onInit;
                // m.hooks['onChanges'] = onChange;
              }
            }
            return m;
          });
        }
        console.log(this.formJson);
        if (this.searchedPageName != "Edit Bifurcated Packets") {
          this.resetForm = true;
          setTimeout(() => {
            this.resetForm = false;
          }, 500);
        }
      }
      this.showDrawer = true;
      setTimeout(() => {
        this.showDrawer = false;
      }, 500);
      // console.log('.....Test_Dev.....');
      // cthis.usersonsole.log(this.selectedRow, this.winnerPlan, this.actionName);
      // console.log(this.winnerPlan);
    }
    // setTimeout(() => {
    //   this.httpService.dismissLoader();
    // }, 500);
  }

  async getUsers() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "users/getUsersListByDepartment").toPromise();
      this.users = data.data;
      console.log(this.users);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  async getMistakeEntryData() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "packet/getPacketOfCurrDept").toPromise();
      this.dataSource = data?.data || [];
    } catch (error) {
      // console.error('Error fetching worker data:', error);
    }
  }

  async getPacketsByUsers(selectedPersonId) {
    this.stonesForTransfer = [];
    this.modifiedGridObject = [];
    try {
      const data = await this.httpService.getReturnAccordingToPerson(this.selectedPersonId).toPromise();
      if (data.data && data.data.length > 0) {
        this.stonesForTransfer = data.data;
      } else {
        this.stonesForTransfer = [];
      }
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 1000);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }
  // scanner code end

  async sendStoneTounProcessedPacket() {
    const selectedIds = this.selectedRows.map((dt: any) => dt.id);
    if (!selectedIds?.length) return;

    let payload = {
      packetIds: selectedIds,
    };
    let response = await this.httpService.postDataUsingPromise(true, ENDPOINTS.MarkPacketAsUnproccessedForAutoReceive, payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.store.dispatch(AutoReceiveAction.getAutoReceivePacket());
      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  async AR_unproccess() {
    const alert = await this.alertCtrl.create({
      header: "Are you sure you want to Unproccess?",
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: "planAlertBox",
      buttons: [
        {
          text: "NO",
          handler: (d: any) => {
            // console.log('Closed', d);
          },
        },
        {
          text: "YES",
          handler: (d: any) => {
            this.sendStoneTounProcessedPacket();
          },
        },
      ],
    });
    return await alert.present();
  }

  async AR_receive() {
    let actionName = this.actionFormType[this.selectedRow.currActionName]?.toLowerCase();
    if (!(this.selectedRows.filter((dt: any) => this.actionFormType[dt.currActionName]?.toLowerCase() == actionName)?.length == this.selectedRows?.length)) {
      this.configService.showToast("error", "Please select data with same action");
    } else if (actionName == "istrue") {
      this.actionId = this.selectedRows[0]?.currAction;
      const popover = await this.popoverController.create({
        component: ActionOperationsPopupComponent,
        cssClass: "w-50",
        componentProps: {
          actionInfo: this.actionInfo,
        },
      });
      await popover.present();
      popover.onDidDismiss().then(async res => {
        if (res?.data !== undefined) {
          // let barcodeObj = {
          //   packetId: this.selectedRows.map((dt:any)=>dt.packetId),
          // };
          // this.store.dispatch(
          //   masterDataActions.saveStoneParamater({
          //     parameterDetails: obj,
          //     actionId: this.actionId,
          //     formType: '',
          //     barcodeObj: barcodeObj,
          //     actionInfo: this.actionInfo,
          //   })
          // );

          const listOfAllPackets = this.selectedRows.map((dt: any) => {
            return {
              packetId: dt.id,
              listOfParameter: [
                {
                  parameterName: "Fixing",
                  parameterValue: true,
                },
              ],
            };
          });
          let payload = {
            listOfAllPackets,
            // actionId: this.actionId,
          };

          let response = await this.httpService.postDataUsingPromise(true, `processActionInput/inputReceiverByActionId/${this.actionId}`, payload);

          if (response?.statusCode == 200) {
            this.configService.showToast("success", "Parametere Saved Successfully");
          } else {
            this.configService.showToast("Error", response.message || "Something went wrong!");
          }
        }
      });
    } else {
      this.actionId = this.selectedRows[0]?.currAction;
      const popover = await this.popoverController.create({
        component: ActionOperationsPopupComponent,
        cssClass: "w-50",
        componentProps: {
          title: "Bulk Receive",
          selectedRows: this.selectedRows,
          showWeights: true,
          actionId: this.selectedRow?.currAction || this.actionId,
          actionInfo: this.actionInfo,
        },
      });
      await popover.present();
    }
  }

  async onSelectionChanged(event: any) {
    // console.log('....deubgging......');
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    // if(this.searchedPageName === 'Edit Bifurcated Packets'){
    //   if(this.selectedRow?.currAction == 446){
    //     this.actionName = 'Cutting';
    //   }
    // }
    if (this.currActionName == "Edit Bifurcated Packets") {
      this.getFirstParameter();
    }
    if ((this.searchedPageName === ACTIONNAMES.recut || this.BIFURCATION.includes(this.searchedPageName)) && this.selectedRow?.originalLabel) {
      const payload = {
        originalLabel: this.selectedRow.originalLabel,
        packetId: this.selectedRow.id,
      };
      const res = await this.httpService.postDataUsingPromise(true, ENDPOINTS.getIntermediatePlans, payload);
      this.intermediatePlansForRecut = res?.data;
    }
    if (this.searchedPageName === "Fetch Barcode" || this.searchedPageName === "Review Packet Files") return;
    console.log(this.selectedRows);
    this.store
      .pipe(select(getFinalPlanData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data) {
          this.packetPlans = data || [];
          setTimeout(() => {
            this.packetPlans = data || [];
          }, 0);
        }
      });
    if (!this.selectedRows?.length && this.searchedPageName === "Auto Receive") {
      this.showReceiveBtn = false;
    }
    if (
      this.searchedPageName !== "Auto Receive" &&
      this.searchedPageName !== ACTIONNAMES.recut &&
      this.searchedPageName !== "Transfer" &&
      this.searchedPageName !== "Return" &&
      this.searchedPageName !== "Edit Bifurcated Packets" &&
      this.searchedPageName !== "Rought Cutting"
    ) {
      this.fetchChatMessages(this?.selectedRow?.id);
    }
    if (this.actionName == "CFSP" && this.selectedRow) {
      this.getYehudaDetails(this.selectedRow?.id);
    }
    if (this.actionName == "further process") {
      (await this.getDropDownValueOfAcknowledgement()).subscribe((res: any) => {
        if (res) {
          console.log("change Status");
          console.log(res);
          let resultCode: number = res.statusCode;
          console.log(resultCode);
          let d: any = res.data;
          console.log(d);
          if (resultCode == 200) {
            this.acknowledgementData = res.data;
            console.log(this.acknowledgementData);
            if (this.acknowledgementData) {
              console.log(this.acknowledgementData);
              let newData = this.acknowledgementData.map(x => {
                let newobj = {
                  label: x.actionName,
                  value: x.id,
                };
                return newobj;
              });
              console.log(newData);
              // console.log(JSON.stringify(newData))
              //add flur in select options
              console.log(newData);
              this.formdataObjForFurtherProcess.form[0].templateOptions.options = newData;
              console.log(newData);
            }
          }

          return res.data;
        }
      });
    } else if (this.actionName == "Final Inspection" && this.selectedRow) {
      this.finalInspectionModal();
    } else if (this.actionName == "Color Check" && this.selectedRow) {
      this.ColorCheckModel();
    } else if (this.actionName == "Final Check" && this.selectedRow) {
      this.labfinaCheckModel();
    } else if (this.actionName === "Review By Checker" && this.selectedRow) {
      this.router.navigate(["/planning"], {
        queryParams: { isPage: "reviewByChecker" },
      });
    } else if (this.actionName == "Spectrum" && this.selectedRow) {
      this.router.navigate(["/planning"], {
        queryParams: { isPage: "Spectrum" },
      });
    } else if (this.actionName?.toLowerCase() == "reconfirm final plan") {
      this.router.navigate(["/headchecker"]);
    }
    
else if (this.actionName === ACTIONNAMES.Reconfirm_Bid_plan) {
  let payload = {
    packetId: this.selectedRow.packetId,
  };

  const res = await this.httpService.postDataUsingPromise(true, `packetPlanStone/plansByFinalPlanning`, payload);
      if (res?.statusCode === 200) {
    if (res.data?.checkerView) {
      await this.router.navigate(["/headchecker"]);
    } else {
      console.log(res.data);
      try {
        await this.store.dispatch(savePacketPlansForReconfirmBidPlan({
          reconfirmBidData: { 
            packetPlans: res.data.packetPlans, 
            planData: res.data.plansData        
          }
        }));
        await    this.router.navigate(["/planning"], {
        queryParams: { isPage: "ReconfirmBidPlan" },
      });
      } catch (error) {
        console.error("Error in savePacketPlans:", error);
        this.configService.showToast('error', 'Failed to save packet plans.');
      }
    }
  } else {
    console.error("Unexpected response status:", res?.status);
    this.configService.showToast('error', 'Failed to get a valid response.');
  }
}


    if (this.selectedRows.length != 0 && this.searchedPageName.toLowerCase() == "transfer") {
      let packetIds = this.selectedRows.map((x: any) => x.id);
      let payload: any = { packetIds: packetIds };
      if (packetIds?.length) this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: payload }));
    } else if (this.searchedPageName.toLowerCase() == "transfer") {
      this.store.dispatch(masterDataActions.clearTrybeDepartments());
      this.deptDropdownData.selectedData = [];
      this.dropDownData.selectedData = [];
    }

    if (this.router.url !== "/newchecker") {
      this.store.dispatch(saveCurrentRowData({ rowData: event.selectedRowsData }));
    }

    if (this.actionName === "Review For Smart Recut") {
      if (!!this.selectedRow) {
        let barcodeObj = { packetId: this.selectedRow.packetId };
        this.store.dispatch(findPacketPlanStoneByPacketIdForSmartRecut({ packetIdOb: barcodeObj, reviewForSmartRecut: 1 }));
        this.router.navigate(["/planning"], {
          queryParams: { isPage: "Smart Recut Planning", values: "Review Smart Recut" },
        });
      }
    }
    if (!!this.selectedRow) {
      if (event.selectedRowsData.length > 0) {
        this.isStoneSelected = true;
      } else {
        this.isStoneSelected = false;
      }
    }
    let info = {
      rowData: this.selectedRow,
      status: "Initial",
      parameterList: this.paramSelectionList,
      className: "ecsvModal",
      history: this.packetHistories,
    };
    console.log(info);
    if (
      !!this.selectedRow &&
      this.selectedRow.historyStatus == "Pending" &&
      (this.selectedRow.currActionName === "Planning" ||
        this.selectedRow.currActionName === "Manual Final Planning" ||
        this.selectedRow.currActionName === "Smart Recut Planning" ||
        this.selectedRow.currActionName === ACTIONNAMES.anyCut ||
        this.selectedRow.currActionName === "Any Cut Planning")
    ) {
      console.log(this.selectedRow.id);
      this.store.dispatch(SignerAction.getManualPacketPlan({ packetIdOb: this.selectedRow.id }));
      if (this.selectedRow.currActionName === "Planning") {
        let isLaserMarking = await this.isLaserMarkingDone(this.selectedRow?.id);

        this.httpService.getData(true, `packet/getOrderOnBasisOfPacketId/${this.selectedRow.id}`, {}).subscribe(async (res: any) => {
          const roughCriteriaData = res.data?.map((dt: any) => {
            if (!dt?.uiJson)
              return {
                orderNo: dt?.orderNo,
                orderDate: dt?.orderDate,
              };
            const uiJson = JSON.parse(dt?.uiJson);
            return {
              ...uiJson,
              Shape: uiJson?.dropdownJson?.Shape?.datas?.map((sdt: any) => sdt.name).join(", "),
              Color: uiJson?.dropdownJson?.Color?.datas?.map((sdt: any) => sdt.name).join(", "),
              Clarity: uiJson?.dropdownJson?.Clarity?.datas?.map((sdt: any) => sdt.name).join(", "),
              Propagation: uiJson?.dropdownJson?.Propagation?.datas?.map((sdt: any) => sdt.name).join(", "),
              CUT: uiJson?.dropdownJson?.CUT?.datas?.map((sdt: any) => sdt.name).join(", "),
              Polish: uiJson?.dropdownJson?.Polish?.datas?.map((sdt: any) => sdt.name).join(", "),
              Symmetry: uiJson?.dropdownJson?.Symmetry?.datas?.map((sdt: any) => sdt.name).join(", "),
              Fluorescence: uiJson?.dropdownJson?.Fluorescence?.datas?.map((sdt: any) => sdt.name).join(", "),
              Tinch: uiJson?.dropdownJson?.Tinch?.datas?.map((sdt: any) => sdt.name).join(", "),
              Natts: uiJson?.dropdownJson?.Natts?.datas?.map((sdt: any) => sdt.name).join(", "),
              Milkyness: uiJson?.dropdownJson?.Milkyness?.datas?.map((sdt: any) => sdt.name).join(", "),
              Topsopens: uiJson?.dropdownJson?.Topsopens?.datas?.map((sdt: any) => sdt.name).join(", "),
              Botsopen: uiJson?.dropdownJson?.Botsopen?.datas?.map((sdt: any) => sdt.name).join(", "),
              perpieceRate: uiJson?.perpieceRate?.from,
              sizeRange: (uiJson?.sizeRange?.from || "") + "-" + (uiJson?.sizeRange?.to || ""),
              diameterRatio:
                uiJson?.diameter?.from || uiJson?.diameter?.to ? (uiJson?.diameter?.from || "") + "-" + (uiJson?.diameter?.to || "") : (uiJson?.ratio?.from || "") + "-" + (uiJson?.ratio?.to || ""),
              table: (uiJson?.table?.from || "") + "-" + (uiJson?.table?.to || ""),
              cheight: (uiJson?.cheight?.from || "") + "-" + (uiJson?.cheight?.to || ""),
              girdle: (uiJson?.girdle?.from || "") + "-" + (uiJson?.girdle?.to || ""),
              cangle: (uiJson?.cangle?.from || "") + "-" + (uiJson?.cangle?.to || ""),
              culet: (uiJson?.culet?.from || "") + "-" + (uiJson?.culet?.to || ""),
              depth: (uiJson?.depth?.from || "") + "-" + (uiJson?.depth?.to || ""),
              pdepth: (uiJson?.pdepth?.from || "") + "-" + (uiJson?.pdepth?.to || ""),
              pangle: (uiJson?.pangle?.from || "") + "-" + (uiJson?.pangle?.to || ""),
              remark: uiJson?.remark,
              orderNo: dt?.orderNo,
              orderDate: dt?.orderDate,
            };
          });
          if (roughCriteriaData?.length) {
            const popover = await this.popoverController.create({
              component: PopoverUserComponent,
              cssClass: "planningRowOrderspopoup",
              componentProps: {
                forPage: "roughCriteria",
                roughCriteriaData,
              },
            });
            await popover.present();
            popover.onDidDismiss().then(res => {
              if (isLaserMarking === true && this.isPreviouslyPlanUploaded == false) {
                this.showModal(true, "e-csv Upload", info);
              } else if (isLaserMarking === true && this.isPreviouslyPlanUploaded == true) {
                this.isPreviousPlantrue();
              } else {
                console.log(isLaserMarking?.message);
                this.configService.showToast("error", "This Packet Is Not Yet Issued To Laser Marking");
              }
            });
          } else {
            if (isLaserMarking === true && this.isPreviouslyPlanUploaded == false) {
              this.showModal(true, "e-csv Upload", info);
            } else if (isLaserMarking === true && this.isPreviouslyPlanUploaded == true) {
              this.isPreviousPlantrue();
            } else {
              this.configService.showToast("error", "This Packet Is Not Yet Issued To Laser Marking");
            }
          }
        });
      } else if (
        this.selectedRow.currActionName === "Manual Final Planning" ||
        this.selectedRow.currActionName === "Smart Recut Planning" ||
        this.selectedRow.currActionName === ACTIONNAMES.anyCut ||
        this.selectedRow.currActionName === "Any Cut Planning"
      ) {
        this.showModal(true, "e-csv Upload", info);
      }
    } else if (!!this.selectedRow && this.selectedRow.historyStatus == "Completed" && this.selectedRow.currActionName == "Final Planning") {
      this.selectorSubscription = this.store.pipe(select(getFinalPlanBYPacketID(this.selectedRow.id))).subscribe((data: any) => {
        console.log(data);
        if (data && !data.finalPlan && !!this.selectedRow && !!this.selectedRow.id) {
          this.store.dispatch(
            HomeActions.getPacketPlanStoneIfIsFinal({
              packetId: this.selectedRow.id,
            })
          );
        } else {
          this.refreshGrid = true;
          setTimeout(() => {
            this.refreshGrid = false;
          }, 1000);
          if (!!this.selectedRow && !!this.selectedRow.id) {
            this.router.navigate(["/newchecker"], {
              queryParams: { finalPlan: true },
            }); // commenting this line for now, will uncomment to show read only data
            // this.router.navigate(['/trybechecker'], { queryParams: { packetId: this.selectedRow.id, status: this.selectedRow.historyStatus, test: true } });
          }
        }
      });
    } else if (!!this.selectedRow && this.selectedRow.historyStatus == "Pending" && (this.selectedRow.currActionName == "Final Planning" || this.selectedRow.currActionName == "Planning review")) {
      if (this.selectedRow.currActionName == "Final Planning") {
        if (this.isPreviouslyPlanUploaded == false) {
          this.showModal(true, "e-csv Upload", info);
        } else if (this.isPreviouslyPlanUploaded == true) {
          this.isPreviousPlantrue();
        }
      } else {
        this.store.dispatch(
          HomeActions.getPacketPlanStoneIfIsFinal({
            packetId: this.selectedRow.id,
          })
        );
        // this.router.navigate(['/trybechecker'], { queryParams: { packetId: this.selectedRow.id, status: this.selectedRow.historyStatus, msg: 'Checker', isConfirm: true } });
        // await this.router.navigate(['/trybechecker'], { queryParams: { packetId: this.selectedRow.id } });
        this.router.navigate(["/newchecker"], {
          queryParams: { finalPlan: false },
        });
        // this.store.dispatch(
        //   SignerAction.getPacketPlans({ packetIdOb: d.data.barcodeObj })
        // );
      }
    // } else if (this.searchedPageName === ACTIONNAMES.PrevuniversalOpinionView) {
    //   debugger
    //   if (this.selectedRow) this.viewHistory(this.selectedRow.id);
    //   return;
    } else if (
      (this.searchedPageName == "Auto Receive" ||
        this.searchedPageName === ACTIONNAMES.recut ||
        this.BIFURCATION.includes(this.searchedPageName) ||
        this.actionFormType[this.selectedRow?.currActionName]?.toLowerCase() == "istrue" ||
        this.actionFormType[this.selectedRow?.currActionName]?.toLowerCase() == "weight") &&
      !!this.selectedRow
    ) {
      if (
        this.actionFormType[this.selectedRow?.currActionName]?.toLowerCase() == "istrue" ||
        this.actionFormType[this.selectedRow?.currActionName]?.toLowerCase() == "weight"
        // this.selectedRows.filter((dt: any) => dt.currActionName == 'Fixing')
        //   ?.length
        // ||
        // !(this.searchedPageName===ACTIONNAMES.recut || this.BIFURCATION.includes(this.searchedPageName))
      ) {
        this.showReceiveBtn = true;
      } else {
        this.showReceiveBtn = false;
        if (this.selectedRow.currActionName == "Cutting" || this.BIFURCATION.includes(this.searchedPageName)) {
          this.store.dispatch(HomeActions.getPacketPlanStoneIfIsFinal({ packetId: this.selectedRow.id }));
        } else {
          this.showDynamicFormOnStoneSelection();
        }
      }
    } else if (!!this.selectedRow && this.selectedRow.historyStatus == "Pending" && this.selectedRow.currActionName == "QC Check & Annotation") {
      this.openQCAnnotation(false);
    } else if (!!this.selectedRow && this.selectedRow.historyStatus == "Completed" && this.selectedRow.currActionName == "QC Check & Annotation") {
      console.log(this.selectedRow);
      this.openQCAnnotation(true);
    } else if (!!this.selectedRow && this.selectedRow.historyStatus == "Pending" && this.selectedRow.currActionName == "Pre Planning") {
      this.openPreplanning(false);
    } else if (!!this.selectedRow && this.selectedRow.historyStatus == "Completed" && this.selectedRow.currActionName == "Pre Planning") {
      console.log(this.selectedRow);
      this.openPreplanning(true);
    } else if (
      this.actionName != "Final Inspection" &&
      this.actionName != "Color Check" &&
      this.actionName != "Return" &&
      this.actionName != "Transfer" &&
      this.actionName != "Manager Transfer" &&
      this.actionName != "First Allocation" &&
      this.actionName != ACTIONNAMES.mistakeEntry &&
      this.actionName != ACTIONNAMES.verifyRepairingAsking &&
      this.actionName != ACTIONNAMES.generateReport
    ) {
      this.showActionFormAsperData();
      console.log("coming here");
    }
    if (this.selectedRow && (this.actionName == ACTIONNAMES.mistakeEntry)) {
      this.lockModal(this.selectedRow);
    } else if (this.selectedRow && (this.actionName == ACTIONNAMES.verifyRepairingAsking)) {
      this.newLockModal(this.selectedRow);
    }
    else if (this.actionName == ACTIONNAMES.intermediatePlanning) {
      // this.store.dispatch(
      //   HomeActions.getPacketPlanStoneIfIsFinal({
      //     packetId: this.selectedRow.id,
      //   })
      // );
      this.router.navigate(["/planning"], {
        queryParams: { isPage: "intermediatePlanning" },
      });
    } else if (this.actionName == ACTIONNAMES.MAKEABLE_PLANNING) {
      this.router.navigate(["/planning"], {
        queryParams: { isPage: ACTIONNAMES.MAKEABLE_PLANNING },
      });
    }
    if (!!event && !!event.row && !!event.row.packetId && this.selectedRow.historyStatus == "Completed" && this.selectedRow.currActionName === "Planning") {
      let obj = {
        packetId: event.row.packetId,
      };
      console.log(obj);
      // ;
      this.store.dispatch(SignerAction.getPacketPlans({ packetIdOb: obj }));
      this.subscriptions.push(
        this.store.pipe(select(getAllPacketPlans)).subscribe(data => {
          if (data) {
            let selRow = { ...this.selectedRow };
            let iDetails = this.selectedRow.inputDetails.map(param => {
              let m = { ...param };
              data.forEach(d => {
                if (m.paramIValue == d.id) {
                  console.log("BidAmt....");
                  m["bidAmt"] = d.bidAmt;
                }
              });
              return m;
            });
            selRow["inputDetails"] = iDetails;
            this.selectedRow = selRow;
          }
        })
      );
    } else {
      this.store.dispatch(SignerAction.removeSavePacketPlans());
    }
    if (this.searchedPageName == "Return") {
      console.log(this.searchedPageName);
      console.log(this.searchedPageName);
      console.log(this.departmentId);
      //let selectedUsers = this.users.filter(a => a.isSelected == true);
      console.log(this.selectedUser);
      if (!!this.selectedUser) {
        if (!!this.selectedRow) {
        }
      }
      // else {
      //   this.configService.showToast("error", "Please Select User.");
      // }
      // if (!!this.selectedRow) {
      //   this.store.dispatch(masterDataActions.getUsersByPacketId({ packetID: this.selectedRow.id }));
      //   let selectedUsers = this.actionUsers.filter(a => a.isSelected == true);
      //   console.log(selectedUsers);
      //   console.log(selectedUsers.length);

      //   if (selectedUsers && selectedUsers.length == 1) {
      //     this.selectedUser = !!selectedUsers[0] ? selectedUsers[0].fullName : "";
      //     this.selectEvent(selectedUsers[0]);
      //   }
      //   //  else  {
      //   //  let selectedUsers = this.actionUsers.filter(a => a.isSelected == true);
      //   //   this.selectedUser = "cutting";
      //   //    console.log("not going");
      //   // }
      // } else {
      //   this.store.dispatch(masterDataActions.removeUsersByPacketId());
      // }
    }
  }

  FA_transfer() {
    if (!this.selectedRows?.length) return;
    const payload = {
      packetIds: this.selectedRows?.map((dt: any) => dt?.id),
    };
    this.httpService.saveData(true, "packet/transferPacketToChecker", payload).subscribe(async (res: any) => {
      if (res.statusCode === 200 && !!res?.data) {
        await this.setDispatchVoucherData(res.data);
        let voucherData = {
          data: res.data,
        };
        if (!!res.data && res.data?.dispatchVoucher.length > 0) {
        }
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: res.message,
            data: voucherData,
            dispatchData: this.dispatchData,
            voucherDto: this.voucherDto,
            printData: this.printData,
            dispatchLossData: this.dispatchLossData ? this.dispatchLossData : null,
            selectedRows: this.selectedRows,
            actionName: this.actionName,
          },
          cssClass: "modalprint",
        });
        modal.onDidDismiss().then(dataReturned => {
          this.fetchFirstAllocationData(null);
          this.selectedRows = [];
        });
        return await modal.present();
        // this.configService.showToast('success', res.message);
      } else {
        this.configService.showToast("error", res.error.message || "Something went wrong!");
      }
    });
  }

  closeDynamicForm() {
    // this.isStoneSelected = false;
    // this.selectedRow = {};
    // this.rowsIndex = [];
    // this.isStoneSelected = false;
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  remove(event?) {
    let planInputArray = [];
    console.log("remove...........");
    console.log(this.actionInfo);
    // if (this.actionId == 149) {
    //   this.store.dispatch(
    //     masterDataActions.removeStoneParamater({
    //       parameterDetails: data,
    //       actionId: this.actionId,
    //     })
    //   );
    // }

    let paramsNameList = this.fromJsonInputs.listOfParameter.map(x => {
      return x.parameterName;
    });

    const actionform = JSON.parse(this.actionInfo?.actionForm);
    let isFile = false;
    actionform?.form?.map((dt: any) => {
      if (dt.key == "file") isFile = true;
    });

    if (!isFile && event) {
      const listOfParameter = [];
      for (const key in event) {
        listOfParameter.push({
          parameterName: key,
          parameterValue: event[key],
        });
      }
      const listOfProcessActionInputId = this.selectedRow.inputDetails.map(x => {
        if (x.paramName) {
          let isExist = paramsNameList.find(e => e === x.paramName);
          if (isExist) {
            return x.piId;
          }
        }
      });
      const payload = {
        packetId: this.selectedRow.id,
        listOfAllPackets: [
          {
            packetId: this.selectedRow.id,
            listOfParameter,
          },
        ],
        listOfProcessActionInputId,
      };
      this.httpService.saveStoneParamater(payload, this.actionId, "JSON", true).subscribe(res => {
        if (res.statusCode === 200 || res.statusCode === 201) {
          this.configService.showToast("success", res.message || "Packet Created Succesfully");
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: this.actionId,
              status: this.status,
            })
          );
        } else {
          this.configService.showToast("error", res.message || "Something went wrong!");
        }
      });
      return;
    }
    if (!!this.selectedRow && !!this.selectedRow.inputDetails && this.selectedRow.inputDetails.length > 0) {
      let inputArray = this.selectedRow.inputDetails.map(x => {
        if (x.paramName) {
          let isExist = paramsNameList.find(e => e === x.paramName);
          if (isExist) {
            return x.piId;
          }
          // planInputArray.push(x.piId);
        }
      });
      inputArray = inputArray.filter(d => d != undefined);
      // planInputArray = planInputArray.filter((d) => d != undefined);
      var data = {
        packetId: this.selectedRow.id,
        listOfProcessActionInputId: this.actionInfo.name == "Planning" ? planInputArray : inputArray,
      };
      // return;
      if (!!this.actionId && !!data["listOfProcessActionInputId"] && data["listOfProcessActionInputId"].length > 0) {
        this.store.dispatch(
          masterDataActions.removeStoneParamater({
            parameterDetails: data,
            actionId: this.actionId,
          })
        );
      }
      this.resetForm = true;

      setTimeout(() => {
        this.resetForm = false;
      }, 500);
    }
  }

  ngOnDestroy() {
    console.log("%cActionOperations Compo Destroyed1", "color:green;font-size:20px");
    // this.store.dispatch(tempDataActions.resetCurrentAction());
    this.store.dispatch(tempDataActions.resetCurrentSearch());
    this.store.dispatch(tempDataActions.removeCurrentRowData());
    this.store.dispatch(masterDataActions.removeUsersByPacketId());
    this.subscriptions.forEach(subscription => {
      if (!!subscription) {
        subscription.unsubscribe();
      }
    });
    this.selectedUser = "";
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async getRoles() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "packet/getDeptRoleOnBasisOfCurrentUser").toPromise();
      this.roles = data.data;
      this.selectedRole = data.data.roleName;
      this.selectRole(data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  }
  async formSubmitted(formData: any) {
    console.log(this.actionName);
    let formType = "JSON";
    console.log(formData);
    if (this.actionName == "further process") {
      this.acknowdlegmentval == formData;
    }
    console.log(this.fromJsonInputs);
    let fromJsonInputsModified = { ...this.fromJsonInputs };
    let cuttingListOfParameter = [];
    let bifurcatePayload = {
      stones: [],
    };
    bifurcatePayload["packetId"] = this.selectedRow.id;
    // bifurcatePayload['packetPlanStoneId'] = this.selectedRow.id;
    //bifurcatePayload['stones'] = [];
    if (this.actionName == "Cutting" || this.BIFURCATION.includes(this.searchedPageName)) {
      if (
        this.searchedPageName == "Auto Receive" ||
        this.searchedPageName === ACTIONNAMES.recut ||
        this.searchedPageName == "3P Cutting" ||
        this.searchedPageName == "Rough Cutting" ||
        this.searchedPageName == "4P Process"
      ) {
        let changedFormData = Object.values(formData).map((d, i) => {
          let no = i + 1;
          let keyPlanType = "Packet-Plan-Type-" + no;
          let keyPlan = "Packet-Plan-StoneId-" + no;
          let m = JSON.parse(JSON.stringify(d));
          if (m[keyPlanType] === 0) {
            m[keyPlan] = 0;
          }
          return m;
        });
        changedFormData.map(e => {
          console.log(e);
          let newObj = {};
          for (let [key, value] of Object.entries(e)) {
            console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
            let planType: any;
            if (key.indexOf("NFC-Code") > -1) {
              newObj["nfcCode"] = value;
            }
            if (key.indexOf("Packet-Weight") > -1) {
              newObj["cts"] = value;
            }
            if (key.indexOf("Loss-Weight") > -1) {
              newObj["lossWeight"] = value;
            }
            if (key.indexOf("Packet-Plan-StoneId") > -1) {
              newObj["packetPlanStoneId"] = value == 0 ? null : value;
            }
            if (key.indexOf("Packet-Plan-Type") > -1) {
              if (value == 3) value = 1; // send 1 as value for Rough Return (Rough return and Intermadiate planninghas same value 1 for backend)
              newObj["packetType"] = value;
            }
          }
          bifurcatePayload.stones.push(newObj);
        });
        console.log("bifurcatePayload");
        console.log(bifurcatePayload);
        this.httpService.addNewPacketsAfterBifurcation(bifurcatePayload).subscribe(res => {
          if (res) {
            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode === 200) {
              this.popup(res);
              // this.httpService.showToast("success", res.message);
              console.log(res.data + "popup________________");
              this.clearBarcode(true);
              this.autoReceiveData();
            } else {
              this.showModal(false, res.message, {});
              this.clearBarcode(true);
              this.autoReceiveData();
            }
          }
        });
      } else if (this.searchedPageName == "Edit Bifurcated Packets") {
        let apiPayload = [];
        let editBifurcationPaylod = {};
        // editBifurcationPaylod['packetLabel'] = this.selectedRow.packetLabel;

        let changedFormData = Object.values(formData).map((d, i) => {
          let m = JSON.parse(JSON.stringify(d));
          return m;
        });
        let newAddedPkt = changedFormData.filter(e => {
          for (let [key, value] of Object.entries(e)) {
            if (key.indexOf("Packet-Plan-Type") > -1) {
              return e;
            }
          }
        });
        let editedPkt = changedFormData.filter(e => {
          if (Object.keys(e).length < 2) {
            return e;
          }
          // for (let [key, value] of Object.entries(e)) {
          //   if (key.indexOf('Packet-Plan-Type') == -1) {
          //      return e;
          //   }
          // }
        });
        if (editedPkt.length > 0) {
          editedPkt.map((e, i) => {
            for (let [key, value] of Object.entries(e)) {
              if (key.indexOf("Packet-Weight") > -1) {
                // editBifurcationPaylod['packetLabel'] = this.dataSource[i]['packetLabel']
                //  editBifurcationPaylod['wt'] = value;
                apiPayload = [...apiPayload, { packetLabel: this.dataSource[i]["packetLabel"], wt: value }];
              }
            }
          });

          let response = await this.httpService.postDataUsingPromise(true, `packet/modifiyPacketRghWt`, apiPayload);

          if (response?.statusCode == 200) {
            this.configService.showToast("success", "Packet Updated Successfully");
            this.selectedRow.cts = editBifurcationPaylod["wt"];
          } else {
            this.configService.showToast("Error", response.message || "Something went wrong!");
          }
          if (newAddedPkt.length > 0) {
            newAddedPkt.map(e => {
              console.log(e);
              let newObj = {};
              for (let [key, value] of Object.entries(e)) {
                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                let planType: any;
                if (key.indexOf("NFC-Code") > -1) {
                  newObj["nfcCode"] = value;
                }
                if (key.indexOf("Packet-Weight") > -1) {
                  newObj["cts"] = value;
                }
                if (key.indexOf("Loss-Weight") > -1) {
                  newObj["lossWeight"] = value;
                }
                if (key.indexOf("Packet-Plan-StoneId") > -1) {
                  newObj["packetPlanStoneId"] = value == 0 ? null : value;
                }
                if (key.indexOf("Packet-Plan-Type") > -1) {
                  if (value == 3 || value == 0) value = 1; // send 1 as value for Rough Return and By product.....(Rough return, Intermadiate planning and By product has same value 1 for backend)
                  newObj["packetType"] = value;
                }
              }
              bifurcatePayload.stones.push(newObj);
            });
            console.log("bifurcatePayload");
            console.log(bifurcatePayload);
            this.httpService.addNewPacketsAfterBifurcation(bifurcatePayload).subscribe(res => {
              if (res) {
                this.httpService.getErrorAndDisplayIt(res);
                if (res.statusCode === 200) {
                  // this.popup(res);
                  // this.httpService.showToast("success", res.message);
                  console.log(res.data + "popup________________");
                  this.clearBarcode(true);
                  // this.autoReceiveData();
                } else {
                  // this.showModal(false, res.message, {});
                  this.clearBarcode(true);
                  // this.autoReceiveData();
                }
              }
            });
          }
        }
      }
    } else {
      fromJsonInputsModified.listOfParameter = fromJsonInputsModified.listOfParameter.map(x => {
        const xData = { ...x };
        let paramName = xData.parameterName;
        if (formData[paramName]) {
          xData.parameterValue = formData[paramName];
        }
        let packet1 = xData.Packet1;
        let packet2 = xData.Packet2;
        if (!!packet1 && formData["Packet1"]) {
          let f1 = formData["Packet1"];
          packet1.forEach((p1: any) => {
            let paramName = p1.parameterName;
            if (f1[paramName]) {
              p1.parameterValue = f1[paramName];
              cuttingListOfParameter.push(p1);
            }
          });
        }
        if (!!packet2 && formData["Packet2"]) {
          let f2 = formData["Packet2"];
          packet2.forEach((p2: any) => {
            let paramName = p2.parameterName;
            if (f2[paramName]) {
              p2.parameterValue = f2[paramName];
              cuttingListOfParameter.push(p2);
            }
          });
        }
        return { ...xData };
      });
      fromJsonInputsModified.packetId = this.selectedRow.id;
      let barcode = this.selectedRow.packetId;
      if (
        (this.searchedPageName == "Auto Receive" && this.selectedRow.currActionName === "Cutting") ||
        this.BIFURCATION.includes(this.searchedPageName) ||
        this.searchedPageName === ACTIONNAMES.recut
      ) {
        console.log(cuttingListOfParameter);
        console.log(this.selectedRow.id);
        fromJsonInputsModified.listOfParameter = cuttingListOfParameter;
      } else if (this.actionName == "further process") {
        fromJsonInputsModified.listOfParameter = [
          {
            parameterName: "NextAction",
            parameterValue: formData.Acknowledgement,
          },
        ];
        this.sendNextActionForAcknowledgement(this.selectedRow.id, formData);
      } else {
        fromJsonInputsModified.listOfParameter = fromJsonInputsModified.listOfParameter;
      }
      let obj = {
        listOfAllPackets: [],
      };
      let barcodeObj = {
        packetId: barcode,
      };
      const formatData = new FormData();
      if (!!this.scannerComponet) {
        this.scannerComponet.onResetForm();
      }
      console.log(fromJsonInputsModified);
      console.log(bifurcatePayload);
      // return;
      if (formData["file"]) {
        console.log(formData["file"]);
        formType = "Multipart";
        let file = formData["file"]["0"];
        formatData.append("file", file);
        let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(d => d.parameterValue != "");
        fromJsonInputsModified.listOfParameter = newListOfParameters;
        obj["listOfAllPackets"].push(fromJsonInputsModified);
        // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
        formatData.append("listOfAllPackets", JSON.stringify(obj["listOfAllPackets"]));
        console.log(obj["listOfAllPackets"]);
        console.log(formatData);
        console.log(formatData.append.length);
        console.log(this.actionName);
        if (
          this.actionName == "RACO files" ||
          this.actionName == "Raco Of AnyCut" ||
          this.actionName == "RACO after Auto-Table" ||
          this.actionName == "RACO after Table/Dassa" ||
          this.actionName == "RACO after Rough Mec" ||
          this.actionName == "RACO after Final Mec" ||
          this.actionName == "RACO after Maxi Bruting"
        ) {
          console.log(this.actionName);
          this.selfIssueAndReceiveForRaacoAcknowledgement(obj["listOfAllPackets"]);
        }
        console.log(this.actionName);

        // return;
        this.store.dispatch(
          masterDataActions.saveStoneParamater({
            parameterDetails: formatData,
            actionId: this.actionId,
            formType: formType,
            barcodeObj: barcodeObj,
            actionInfo: this.actionInfo,
          })
        );
      } else {
        obj["listOfAllPackets"].push(fromJsonInputsModified);
        this.store.dispatch(
          masterDataActions.saveStoneParamater({
            parameterDetails: obj,
            actionId: this.searchedPageName == "Auto Receive" || this.searchedPageName == "Rough Cutting" ? this.selectedRow?.currAction || this.actionId : this.actionId,
            formType: formType,
            barcodeObj: barcodeObj,
            actionInfo: this.actionInfo,
          })
        );
      }
    }

    // this.store.dispatch(masterDataActions.createNewPackets({payload:}))
    // let selectedData: any
    // if (this.actionName == 'Fluorescence') {
    //   selectedData = this.fluorescence.filter(x => x.id == data.fluorescence)[0];
    //   console.log(selectedData);
    // }

    // let fData = {
    //   packetId: this.selectedRow.id,
    //   parameterValue: selectedData.id,
    //   parameterName: this.actionName,
    //   pName: selectedData.name
    // }

    // console.log(fData)
    // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: fData, actionId: this.actionId }));
    // this.resetForm = true;
    // this.httpService.showLoader();
    // if (!!barcode) {
    //   this.store.dispatch(
    //     AutoReceiveAction.getScannedPacketDetails({ packetIdObj: barcodeObj }),
    //   );
    // }

    setTimeout(() => {
      this.resetForm = false;
      if ((this.searchedPageName == "Auto Receive" || this.searchedPageName == "Rough Cutting") && this.dataSource.length === 0) {
        this.status = undefined;
        let limit = { limit: 2000, page: 1 };

        this.store.dispatch(HomeActions.getReceivedPackets(limit));
      } else if (this.searchedPageName == "4P Process") {
        this.store.dispatch(
          HomeActions.getActionNStatusWisePacketList({
            actionId: this.actionId,
            status: this.tabName,
          })
        );
      }
      console.log("%cIssue detection", "color:orange;font-size:2rem;");
      console.log(this.formJson);
      console.log(this.showSubmit);
      console.log(this.selectedRow);

      // if (this.searchedPageName == "Planning" && this.dataSource.length !== 0) {
      //   this.router.navigate(['/trybesigner']);
      // }
    }, 500);
  }

  getYehudaDetails(id) {
    if (id) {
      let endpoint: string = `packet/getYehudaDetailsForPacket/${id}`;
      this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
        if (res) {
          console.log("change Status");
          console.log(res);
          let resultCode: number = res.statusCode;
          console.log(resultCode);
          let d: any = res.data;
          console.log(d);
          if (resultCode == 200) {
            this.yehudaData = res.data;
            console.log(this.yehudaData);
          } else {
          }
        }
      });
    }
  }

  getFinalInspectionData(id) {
    return new Promise((resolve, reject) => {
      if (id) {
        let endpoint: string = `packet-parameter-on-each-dept/getDataForFinalInception?packetId=${id}`;
        this.httpService.fetchData(true, endpoint).subscribe(
          (res: any) => {
            resolve(null);
            if (res) {
              let resultCode: number = res.statusCode;
              console.log(resultCode);
              let d: any = res.data;
              console.log(d);
              if (resultCode == 200) {
                this.finaInspectionData = res.data;
              }
            }
          },
          err => {
            reject();
          }
        );
      } else {
        reject();
      }
    });
  }

  removed(event?) {
    this.remove(event);
  }
  async onPlanChange(evt: any) {
    console.log(" onPlanClick ");
    console.log(evt);
    console.log(this.selectedRow);
    let packetID = this.selectedRow.id;
    let planID = evt.paramIValue;
    if (!!planID) {
      await this.store.dispatch(SignerAction.showSelectedPlan({ packetID: packetID, planID: planID }));
    } else {
      await this.store.dispatch(SignerAction.showAllSelPlan({ packetID: packetID, planIDs: evt }));
    }

    // await this.router.navigate(['/trybesigner']);
    await this.router.navigate(["/planning"]);
  }
  async transferGridPacketScanned(barcode) {
    this.inputBarcodeOfATransferStone = !!barcode ? barcode.trim() : "";

    // if (this.inputBarcodeOfATransferStone) {
    // let payload = {
    //   "packetId": this.inputBarcodeOfATransferStone

    // }
    this.pageInfo = this.transferGridPageInfo;
    this.getFreshDataAndSetPaginationToGrid();
    // await this.httpService.showLoader();
    //
    // this.httpService.fetchReceivedPackets(this.transferGridPageInfo, payload).subscribe((response) => {
    //   if (response.statusCode == 200) {
    //     this.stonesForTransfer = response.data.packetData;
    //     if (this.stonesForTransfer.length == 0) {
    //       this.showModal(false, 'Invalid barcode/Packet Id, no records found!', {});
    //       return;
    //     }
    //   } else {
    //     this.stonesForTransfer = this.stonesForTransfer;
    //     console.log(response);
    //   }
    //   this.httpService.getErrorAndDisplayIt(response);
    // })
    // this.stonesForTransfer = []

    // await this.httpService.dismissLoader();
    //
    this.scanned = true;
    setTimeout(() => {
      this.scanned = false;
    }, 1000);
    // let selectedBarcodes: [] = this.inputBarcodeOfATransferStone
    //   .split(',')
    //   .filter((d) => d !== '');
    // console.log('packetScanned');
    // console.log(selectedBarcodes);
    // if (selectedBarcodes == undefined || selectedBarcodes.length === 0) {
    //   this.showModal(false, 'No barcode/Packet Id detected.', {});
    //   return;
    // }
    // this.rowsIndex = [];
    // for (let i = 0; i < selectedBarcodes.length; i++) {
    //   let el: any = selectedBarcodes[i];
    //   console.log(el);
    //   let index: number = this.stonesForTransfer.findIndex(
    //     (item) => item.packetId == el.trim()
    //   );
    //   this.rowsIndex.push(index);
    //   if (index > -1) {
    //     this.selectedRows.push(this.stonesForTransfer[index]);
    //   }
    // }
    // console.log(this.selectedRows);

    // if (this.rowsIndex == undefined || this.rowsIndex.length === 0) {
    //   this.showModal(false, 'Invalid barcode/Packet Id, no records found!', {});
    //   return;
    // }
    // this.scanned = true;
    // setTimeout(() => {
    //   this.scanned = false;
    // }, 1000);
    // }
  }

  async returnGridPacketScanned(barcode) {
    this.inputBarcodeOfATransferStone = !!barcode ? barcode.trim() : "";

    this.pageInfo = this.returnGridPageInfo;
    this.getFreshDataAndSetPaginationToGrid();
    this.scanned = true;
    setTimeout(() => {
      this.scanned = false;
    }, 1000);
  }

  clearTranferInputBarcode() {
    // this.scannerComponet.onResetForm();
    this.inputBarcodeOfATransferStone = "";
    this.selectedRows = [];
    this.rowsIndex = [];
    this.barcodeInputList = [];
    this.getFreshDataAndSetPaginationToGrid(); //tranferGrid Data refreshed;
    this.refreshGrid = true;
    // this.stonesForTransfer = this.masterStoneTransferData;
    // this.isStoneSelected = false;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }
  onDepartmentSelection(ev) {
    let selected = ev.arrayList;
    if (selected.length > 0) {
      this.deptDropdownData["selectedData"] = selected; //ev.name;
      this.deptDropdownData["selectedObj"] = selected[0]; //ev;
      this.selectDepartment = selected; //ev.name;
      let selDepartment = this.deptDropdownData["options"].filter(d => {
        return d.id == selected[0].id;
      });
      this.selectRoles = [];
      this.selectRoles = this.dropDownData["options"].filter(d => {
        return d.id == selDepartment[0].receiveRole;
      });
      this.dropDownData["selectedData"] = this.selectRoles;
    } else {
      this.selectRoles = [];
      this.selectDepartment = selected; //ev.name;
      this.deptDropdownData["selectedObj"] = {};
      this.dropDownData["selectedData"] = this.selectRoles;
    }
  }
  async onTransferStones() {
    this.infoToPrint["processRemark"] = "Transfer";
    this.infoToPrint["transRemark"] = "Transfer";
    this.infoToPrint["data"] = {};
    this.infoToPrint["data"]["packets"] = this.selectedRows;
    let totalCts = 0;
    let pktIds = this.selectedRows.map(x => {
      totalCts += x.cts;
      return x.id;
    });
    this.infoToPrint["data"]["totalCts"] = totalCts;
    this.infoToPrint["data"]["totalPackets"] = this.selectedRows.length;
    console.log(this.infoToPrint);

    console.log("%conTransferStones click", "color:green:font-weight:bold");

    this.scannerComponet.onResetForm();
    this.refreshGrid = true;
    let selPacketID = this.selectedRows.map(p => p.id);
    let selDepartmentID = !!this.deptDropdownData["selectedObj"] ? this.deptDropdownData["selectedObj"].id : null;
    let selRoleID = !!this.selectRoles[0] ? this.selectRoles[0].id : null;
    let transferData = {
      toDepartment: selDepartmentID,
      toRole: selRoleID,
      listOfPacketIds: selPacketID,
    };
    if (selPacketID.length > 0 && !!selDepartmentID && !!selRoleID) {
      // this.store.dispatch(transferStone({ postData: transferData }));//Due to Pagination we face issue(GridUpdate), so we are not using NRX Store here

      this.httpService.transferPacket(transferData).subscribe(async res => {
        if (res && !!res?.data) {
          await this.setDispatchVoucherData(res.data);
          let voucherData = {
            data: res.data,
          };
          if (!!res.data && res.data?.dispatchVoucher.length > 0) {
            let baseURL = this.configService.getTrybeBaseUrl();
            if (res?.data?.lastPartReturnPath) window.open(baseURL + res?.data?.lastPartReturnPath, "_blank");

            if (res?.data?.partReturn) {
              setTimeout(() => {
                const window2 = window.open(baseURL + res?.data?.partReturn, "_blank");
                console.log("hello");
                console.log(baseURL);
                if (window2) {
                  window2.focus();
                }
              }, 500);
            }

            if (res?.data?.combinePartPath) {
              setTimeout(() => {
                const window2 = window.open(baseURL + res?.data?.combinePartPath, "_blank");

                console.log(baseURL);
                if (window2) {
                  window2.focus();
                }
              }, 500);
            }
            if (res?.data?.newDispatchPath) {
              setTimeout(() => {
                const window2 = window.open(baseURL + res?.data?.newDispatchPath, "_blank");

                console.log(baseURL);
                if (window2) {
                  window2.focus();
                }
              }, 500);
            }
          }
          if (res.statusCode === 200) {
            this.onTransferPacketSuccess(async () => {
              const modal = await this.modalController.create({
                component: TrybemodalComponent,
                componentProps: {
                  message: res.message,
                  data: voucherData,
                  dispatchData: this.dispatchData,
                  voucherDto: this.voucherDto,
                  printData: this.printData,
                  dispatchLossData: this.dispatchLossData ? this.dispatchLossData : null,
                },
                cssClass: "modalprint",
              });
              modal.onDidDismiss().then(dataReturned => {
                if (dataReturned !== null) {
                  console.log(dataReturned);
                }
              });
              return await modal.present();
            });
          } else {
          }
          this.httpService.getErrorAndDisplayIt(res);
        } else {
          this.configService.showToast("error", res.message || "Something went wrong!");

          this.getFreshDataAndSetPaginationToGrid();
        }
      });
    }
  }

  onTransferPacketSuccess(afterSucces) {
    // HomeActions.getReceivedPackets(param),
    //       HomeActions.getPacketCount(),
    //       HomeActions.getOutgoingPackets(param),
    this.getFreshDataAndSetPaginationToGrid(); //for Refreshing Data of Transfer grid/return Grid
    let limit = { limit: 50, page: 0 };
    this.store.dispatch(HomeActions.getReceivedPackets(limit));
    this.store.dispatch(HomeActions.getOutgoingPackets(limit));
    // this.store.dispatch(HomeActions.getPacketCount());
    this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
    this.deptDropdownData["selectedData"] = [];
    this.dropDownData["selectedData"] = [];
    this.selectRoles = [];
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);

    afterSucces();
  }

  async managerTransfer() {
    const payload = {
      toDepartment: this.departmentId,
      toUser: this.selectedManager?.[0]?.id,
      listOfPacketIds: this.selectedRows.map(p => p.id),
    };

    const response = await this.httpService.postDataUsingPromise(true, "packet/transfer", payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.getFreshDataAndSetPaginationToGrid();
      this.userDropdownData.selectedData = [];
      this.configService.showToast("success", response.message);
    } else {
      // console.error(response);
    }
  }

  async onTransferStonesClick() {
    if (this.searchedPageName === "Manager Transfer") {
      this.managerTransfer();
      return;
    }
    let payload = {
      packetIds: this.selectedRows.map(p => p.id),
      nextDept: !!this.deptDropdownData["selectedObj"] ? this.deptDropdownData["selectedObj"].id : null,
    };

    let response = await this.httpService.postDataUsingPromise(true, "users/fetchAllDepartmentByAppName/trybe", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data: any = Object.prototype.toString.call(response.data).replace("[object ", "");
      console.log(data);
      if (data.toLowerCase().includes("object")) {
        console.log("object");
        this.configService.showToast("success", response.data.msg);
        this.httpService.getErrorAndDisplayIt(response);
      } else {
        console.log("Array");
        // let selectedElment= response.data[0];
        this.onTransferStones();
      }
    } else {
      this.configService.showToast("error", response.message || "Something went wrong!");
      console.error(response);
    }

    this.modifiedGridObject = [];
  }
  // showActForm Func END
  async showModal(state: boolean, message: string, data: any, isAppend?: boolean) {
    // let isFormDisabled = false;
    // if (this.actionName === "Final Planning") {
    //   isFormDisabled = true;
    // }
    let append = isAppend;
    this.refreshGrid = false;
    if (state) {
      if (Object.keys(data).length !== 0 && !!data.className) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
            // isFormDisabled: isFormDisabled,
          },
          cssClass: data.className,
        });
        modal.onDidDismiss().then(d => {
          console.log(d);
          this.handleModalDismiss(d, append);
        });
        return await modal.present();
      } else {
        if (Object.keys(data).length !== 0 && !!data.actionId) {
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: message,
              data: data,
            },
            cssClass: "trybe_modal_1",
          });
          modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        } else {
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: message,
            },
            cssClass: "trybe_modal_1",
          });
          modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
          return await modal.present();
        }
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      return await modal.present();
    }
  }

  handleModalDismiss(data: any, isAppend?: boolean) {
    console.log("handleModalDismiss");
    console.log(data);
    let append = isAppend;
    if (!!data && !!data.data && data.data.status == "ecsv file submitted") {
      console.log("Form Submitted");
      console.log(data.data);
      // this.formSubmitted(data.data);
      this.ecsvUploadWithOtherDetails(data.data, append);
    } else {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 1000);
    }
  }

  async ecsvUploadWithOtherDetails(formData, isAppend?: boolean) {
    let append = isAppend;
    let formType = "JSON";
    console.log(formData);
    console.log(this.fromJsonInputs);
    let fromJsonInputsModified = { ...this.fromJsonInputs };

    fromJsonInputsModified.listOfParameter = fromJsonInputsModified.listOfParameter.map(x => {
      const xData = { ...x };
      let paramName = xData.parameterName;
      console.log(paramName);
      if (formData[paramName]) {
        xData.parameterValue = formData[paramName];
      }
      let packet1 = xData.packet1;
      let packet2 = xData.packet2;
      if (!!packet1 && formData["packet1"]) {
        let f1 = formData["packet1"];
        packet1.forEach((p1: any) => {
          let paramName = p1.parameterName;
          if (f1[paramName]) {
            p1.parameterValue = f1[paramName];
          }
        });
      }
      if (!!packet2 && formData["packet2"]) {
        let f2 = formData["packet2"];
        packet2.forEach((p2: any) => {
          let paramName = p2.parameterName;
          if (f2[paramName]) {
            p2.parameterValue = f2[paramName];
          }
        });
      }
      return { ...xData };
    });
    fromJsonInputsModified.packetId = this.selectedRow.id;
    let barcode = this.selectedRow.packetId;

    let obj = {
      listOfAllPackets: [],
    };
    let barcodeObj = {
      packetId: barcode,
    };
    const formatData = new FormData();
    if (!!this.scannerComponet) {
      this.scannerComponet.onResetForm();
    }
    // return;
    if (formData["file"]) {
      console.log(formData["file"]);
      formType = "Multipart";
      let file = formData["file"]["0"];
      formatData.append("file", file);
      let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(d => d.parameterValue != "");
      fromJsonInputsModified.listOfParameter = newListOfParameters;
      obj["listOfAllPackets"].push(fromJsonInputsModified);
      // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
      let userId = this.authUserData?.id ? this.authUserData?.id : this.authUserData?.userId
      formatData.append("listOfAllPackets", JSON.stringify(obj["listOfAllPackets"]));
      await this.store.dispatch(
        masterDataActions.ecsvUpload({
          parameterDetails: formatData,
          packetID: this.selectedRow.id,
          actionId: this.actionId,
          formType: formType,
          barcodeObj: barcodeObj,
          actionInfo: this.actionInfo,
          isAppend: append || false
        })
      );
    } else {
      let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(d => d.parameterValue != "");
      fromJsonInputsModified.listOfParameter = newListOfParameters;
      obj["listOfAllPackets"].push(fromJsonInputsModified);
      // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
      formatData.append("listOfAllPackets", JSON.stringify(obj["listOfAllPackets"]));
      console.log(formatData, this.selectedRow.id, this.actionId, barcodeObj, this.actionInfo);
      let userId = this.authUserData?.id ? this.authUserData?.id : this.authUserData?.userId
      await this.store.dispatch(
        masterDataActions.ecsvUpload({
          parameterDetails: formatData,
          packetID: this.selectedRow.id,
          actionId: this.actionId,
          formType: "Multipart",
          barcodeObj: barcodeObj,
          actionInfo: this.actionInfo,
          isAppend: append || false
        })
      );
      // if (this.actionName === 'Final Planning') {
      //   await this.router.navigate(['/newchecker']);
      // }

      // obj['listOfAllPackets'].push(fromJsonInputsModified);
      // console.log(obj);
      // return;
      // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: formType, barcodeObj: barcodeObj }));
    }

    setTimeout(() => {
      this.resetForm = false;
      if ((this.searchedPageName == "Auto Receive" || this.searchedPageName == "Rough Cutting") && this.dataSource.length === 0) {
        this.status = undefined;
        let limit = { limit: 2000, page: 1 };
        this.store.dispatch(HomeActions.getReceivedPackets(limit));
      }
      console.log("%cECSV", "color:orange;font-size:2rem;");
    }, 500);
  }
  async onModalDismiss(d: any) {
    console.log("onModalDismiss.....");
    console.log(d);
    this.refreshGrid = true;
    // if (!!d.data && d.data.actionId == 149) {
    //   await this.store.dispatch(masterDataActions.getPacketPlans({ packetIdOb: d.data.barcodeObj }));
    //   await this.router.navigate(['/trybesigner']);
    // }
  }
  async switchToRoleTab(event) {
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 0);
    this.ischecked = event.detail.checked;
    if (this.ischecked) {
      this.returnDisabled = false;
      this.getFreshDataAndSetPaginationToGrid();
      this.selectedUser = "";
      this.getRoles();
    }
    this.roleTab = true;
    if (this.ischecked == false) {
      this.roleTab = false;
      this.modifiedGridObject = [];
    }
    // else{
    //   this.messageTab=true;
    // }
  }

  async generateDynamicFormAccoringPlans(formJson, packetPlans, passedLength?: any) {
    console.log(packetPlans);
    console.log(formJson, packetPlans);
    let formJsonArray = [];
    const mainObject = { ...formJson[0] };
    this.mainObject = { ...mainObject };
    const key = `${mainObject.key}`;
    const label = `${mainObject.templateOptions.label}`;
    const fieldGroup = mainObject.fieldGroup;
    console.log(mainObject, key, label, fieldGroup);
    //const length = packetPlans.length;
    const length = this.searchedPageName === "Edit Bifurcated Packets" ? this.dataSource.length : this.searchedPageName === "4P Process" ? 1 : 2;
    let response = await this.httpService.fetchDataUsingPromise(true, `packet/checkFinalPlanIsAvaible?packetId=${this.selectedRow.id}`);
    let planType;
    if (!!response && response.statusCode == 200) {
      if (!response?.data) {
        planType = (await this.httpService.getPlanTypes()).filter(gpt => gpt.label == "Intermediate Planning" || gpt.label == "By Product" || gpt.label == "Rough Return");
      } else {
        planType = await this.httpService.getPlanTypes();
      }
    } else {
      console.error(response);
    }

    console.log(label);
    for (let i = 0; i < length; i++) {
      let newObj = mainObject;
      let objMainKey = `${key} ${i + 1}`;
      newObj["key"] = objMainKey;
      let newLable = `${label} ${i + 1}`;
      // newObj['templateOptions']['label'] = newLable
      let newLableObj = {
        label: newLable,
      };
      let roughRetunId;
      if (this.searchedPageName == "4P Process") {
        roughRetunId = (await this.httpService.getPlanTypes()).find(x => x.label == "Final Planning").value;
      } else {
        roughRetunId = (await this.httpService.getPlanTypes()).find(x => x.label == "Rough Return").value;
      }

      let newFieldGroudp = await [...fieldGroup].map(x => {
        let l = { ...x };
        l["key"] = `${l.key}-${i + 1}`;
        if (x.type == "select" && x.key == "Packet-Plan-Type") {
          l["templateOptions"]["options"] = planType;
        } else if (x.type == "select" && x.key == "Packet-Plan-StoneId") {
          l["templateOptions"]["options"] = this.packetStoneArrayCopy = packetPlans;
          console.log(packetPlans);
        }

        if (this.searchedPageName == "4P Process") {
          if (x.type == "select" && x.key == "Packet-Plan-Type") {
            if (this.dataSource[i]?.packetType == 1 && this.dataSource[i]?.packetPlanStoneId != null) {
              l["defaultValue"] = roughRetunId;
            } else {
              l["defaultValue"] = this.dataSource[i]?.packetType;
            }
            l["templateOptions"]["disabled"] = true;
          } else if (x.type == "select" && x.key == "Packet-Plan-StoneId") {
            l["defaultValue"] = this.dataSource[i]?.packetPlanStoneId;
            l["templateOptions"]["disabled"] = true;
          }
        }

        if (this.searchedPageName == "Edit Bifurcated Packets") {
          if (x.type == "select" && x.key == "Packet-Plan-Type") {
            if (this.dataSource[i]?.packetType == 1 && this.dataSource[i]?.packetPlanStoneId != null) {
              l["defaultValue"] = roughRetunId;
            } else {
              l["defaultValue"] = this.dataSource[i]?.packetType;
            }
            l["templateOptions"]["disabled"] = true;
          } else if (x.type == "input" && x.key == "Packet-Weight") {
            l["defaultValue"] = this.dataSource[i]?.cts;
          } else if (x.type == "input" && x.key == "Packet-NFC-Code") {
            l["defaultValue"] = this.dataSource[i]?.nfcCode;
            l["templateOptions"]["disabled"] = true;
          } else if (x.type == "select" && x.key == "Packet-Plan-StoneId") {
            l["defaultValue"] = this.dataSource[i]?.packetPlanStoneId;
            l["templateOptions"]["disabled"] = true;
          }
        }
        return l;
      });
      formJsonArray.push({
        ...newObj,
        templateOptions: newLableObj,
        fieldGroup: newFieldGroudp,
      });
      // if(packetPlans?.length){
      //   formJsonArray = formJsonArray.map((dt:any)=>{
      //     dt.fieldGroup = dt.fieldGroup.map((fg:any)=>{
      //       if(fg?.key?.includes('Packet-Plan-StoneId') && !fg?.templateOptions?.options)
      //       fg.templateOptions.options = packetPlans;
      //       return fg;
      //     });
      //     return dt;
      //   })
      // }
    }
    return formJsonArray;
  }
  async showCheckerFinalPlan(currHistory) {
    console.log("showCheckerFinalPlan");
    if (!!this.selectedRow && !!this.selectedRow.id) {
      this.store.dispatch(
        HomeActions.getPacketPlanStoneIfIsFinal({
          packetId: this.selectedRow.id,
        })
      );

      const popover = await this.popoverController.create({
        component: PlanningGridComponent,
        cssClass: "planningRowOrderspopoup p-none",
        componentProps: {
          // page:'isPageValue',
          packetPlans: this.winnerPlan,
          // allData:"allData",
          allowAction: false,
          showClosePopup: true,
          getifDraweropen: "getifDraweropen",
          // onPlanSelection:"onPlanSelection($event)",
          selectedPacket: this.selectedRow,
          // isParentCheckbox:"isParentCheckbox($event)",
          // uploadJPG:"uploadJPG($event)",
          // onNextBtnevent:"onNextBtnFunction($event)",
          // drawerFrom:"drawerFrom"
        },
      });
      await popover.present();

      // this.router.navigate(['/trybechecker'], {
      //   queryParams: {
      //     packetId: this.selectedRow.id,
      //     status: 'Done',
      //     msg: 'ViewOnlyChecker',
      //   },
      // });
    }
  }
  selectUser(userId) {
    this.actionUsers.map(a => (a.isSelected = false));
    this.userId = userId;
    this.actionUsers.map(a => {
      if (a.id == userId) {
        a.isSelected = true;
      }
      return a;
    });
  }

  async issuePacket() {
    console.log(this.selectedRoleObj);
    this.infoToPrint["processRemark"] = "Return";
    this.infoToPrint["transRemark"] = "Return";
    this.infoToPrint["data"] = {};
    this.infoToPrint["data"]["packets"] = this.selectedRows;
    let totalCts = 0;
    let pktIds = this.selectedRows.map(x => {
      totalCts += x.cts;
      return x.id;
    });
    this.infoToPrint["data"]["totalCts"] = totalCts;
    this.infoToPrint["data"]["totalPackets"] = this.selectedRows.length;
    let issueObj = {
      listOfPacketIds: pktIds,
      actionId: this.actionId,
      toUser: this.selectedPersonId,
      toRole : this.roleTab ? this.selectedRoleObj.id : null
      // machineId: this.machineId
    };
    let isProcessVirtual: number = 0;
    console.log(this.selectedPersonId);
    // this.store.dispatch(HomeActions.issuePacket({ packetDetails: issueObj, isProcessVirtual: isProcessVirtual, pageInfo: this.pageInfo }));
    // this.httpService.issuePacket()
    // this.selectEvent(event)

    this.httpService.issuePacket(issueObj, isProcessVirtual).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.infoToPrint["voucherDate"] = new Date(res?.data?.voucherDate).toLocaleDateString("en-GB");
        this.infoToPrint["issueDate"] = new Date(res?.data?.voucherDate).toLocaleDateString("en-GB");
        this.infoToPrint["voucherNo"] = res?.data?.voucherLabel;
        this.infoToPrint["jangadNo"] = res?.data?.voucherLabel;
        this.infoToPrint["issueNo"] = res?.data?.voucherLabel;
        this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
        let voucherData = {
          data: res.data,
        };
        this.httpService.getErrorAndDisplayIt(res);
        if (res.statusCode === 200) {
          this.onIssuedSuccess(async () => {
            const modal = await this.modalController.create({
              component: TrybemodalComponent,
              componentProps: {
                message: res.message,
                data: voucherData,
              },
              cssClass: "modalprint",
            });
            modal.onDidDismiss().then(dataReturned => {
              if (dataReturned !== null) {
                console.log(dataReturned);
                this.getPacketsByUsers(this.selectedPersonId);
              }
            });
            return await modal.present();
          });
        } else {
          // this.showModal(false, res.message, null);
        }
      }
    });

    this.modifiedGridObject = [];
  }
  onIssuedSuccess(afterSucces) {
    // this.store.dispatch(HomeActions.getUpdatedReceivedPackets({ packetIDs: packetIds }));
    this.getFreshDataAndSetPaginationToGrid();
    let limit = { limit: 50, page: 0 };
    this.store.dispatch(HomeActions.getOutgoingPackets(limit));
    this.store.dispatch(HomeActions.getReceivedPackets(limit));
    this.store.dispatch(HomeActions.getPacketCount());
    this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
    // this.reset();
    // this.clearData = "";

    afterSucces();
  }

  selectEvent(item: any) {
    this.returnDisabled = false;
    console.log("selectEvent", item);
    this.selectedPersonId = item.id || item.userId;
    this.selectedUser = item.fullName;
    console.log(this.selectedPersonId);
    this.infoToPrint["toUser"] = item.fullName ?? "";
    this.infoToPrint["toDept"] = item.departmentName ?? this.currentUserDept;
    this.infoToPrint["toCompany"] = null ?? "";

    if (this.selectedPersonId) {
      this.getPacketsByUsers(this.selectedPersonId);
    }
  }

  async isLaserMarkingDone(id) {
    let res: any;
    res = await this.httpService.findIsLaserMarkingDone(id);

    if (!!res && res.statusCode == 200) {
      this.isPreviouslyPlanUploaded = res?.data?.isPlanUploaded;
      console.log(res);
      return res?.data?.isLaserMarkingDone;
    }
    let data = res;
    if (res.status == 401) {
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    }
  }

  onClickClearSelection() {
    console.log("onClickClearSelection");
    this.machinesByUser.map(m => {
      console.log(m);
      if (m.id) {
        m.isSelected = false;
      }
      return m;
    });
  }
  clearInput(event: any) {
    this.returnDisabled = true;
  }
  onChangeSearch(val: any) {
    this.returnDisabled = true;
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  onFocused(e) {
    // do something when input is focused
  }

  async popup(data) {
    console.log("hiiiiiiii");
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: "bifurcationpopup",
      componentProps: {
        data: { status: "bifurcationPopup", data: data },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  async handleModalDismisss(d) {
    console.log(d);
  }

  async finalInspectionModal() {
    await this.getFinalInspectionData(this.selectedRow?.id);
    const Modal = await this.modalController.create({
      component: FinalInspectionComponent,
      cssClass: "finalInspection",
      componentProps: {
        data: { data: this.finaInspectionData },
      },
    });
    if (!!this.finaInspectionData) {
    }
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisssFinalInspection(d));
    return await Modal.present();
  }

  async ColorCheckModel() {
    const Modal = await this.modalController.create({
      component: ColorInspectionComponent,
      cssClass: "ColorCheckModel",
      componentProps: {
        data: { data: "ColorModel" },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisssFinalInspection(d));
    return await Modal.present();
  }

  async labfinaCheckModel() {
    const Modal = await this.modalController.create({
      component: LabfinalcheckComponent,
      cssClass: "labFinalCheck",
      componentProps: {
        data: { data: "finalcheck" },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisssFinalInspection(d));
    return await Modal.present();
  }

  async handleModalDismisssFinalInspection(d) {
    if (d.data) {
      console.log(d);
    }
    console.log("closing modal");
  }

  async autoReceiveData() {
    if (this.searchedPageName == "Auto Receive" || this.searchedPageName == "Rough Cutting") {
      this.dataSource = [];
      // this.store.dispatch(AutoReceiveAction.getAutoReceivePacket());
      this.subscriptions.push(
        this.store.pipe(select(getAutoReceivePacketSelector)).subscribe(data => {
          if (data) {
            console.log(data);
            this.dataSource = data;
          }
        })
      );
    }
  }
  showDynamicFormOnStoneSelection() {
    console.log("showDynamicFormOnStoneSelection");
    this.isPlanLoaded$.next(false);
    let rowData = this.selectedRow;
    console.log(this.actionName);
    console.log(rowData.currActionName);

    // if (rowData.currActionName == "Cutting" || this.BIFURCATION.includes(this.searchedPageName)) {
    //   this.store.dispatch(HomeActions.getPacketPlanStoneIfIsFinal({ packetId: rowData.id }));

    //   this.store.pipe(select(getFinalPlanData)).subscribe((data: any) => {
    //     if (data) {
    //       this.isPlanLoaded$.next(data.length !== 0);
    //       this.winnerPlan = data;
    //       console.log(this.winnerPlan);
    //     }
    //   });
    // }
    console.log(this.formsByActionId);
    if (!!this.formsByActionId[rowData.currAction]) {
      if (!this.formsByActionId[rowData.currAction]?.listOfForms?.[0] && this.searchedPageName != "Edit Bifurcated Packets") {
        this.showModal(false, "No action form found.", {});
        return;
      }
      let actionForm: any;
      if (this.searchedPageName == "Edit Bifurcated Packets") {
        // let cuttingId = this.formsByActionId?.find(fbai => fbai.actionName == 'Cutting')
        let cuttingId;
        for (const key in this.formsByActionId) {
          const action = this.formsByActionId[key];
          if (action.actionName === "Cutting") {
            actionForm = action?.listOfForms[0];
          }
        }
        // actionForm = this.formsByActionId[cuttingId].listOfForms[0];
      } else actionForm = this.formsByActionId[rowData.currAction].listOfForms[0];
      console.log(actionForm);
      this.actionName = actionForm.name;
      let formDataObject = JSON.parse(actionForm.formData);
      let parsedFormData: any = formDataObject.form;
      this.fromJsonInputs = formDataObject.inputJson;
      let fdata = parsedFormData.map(field => {
        if (field.type == "select" || field.type == "searchSelect") {
          let paramName = field.key;
          console.log(paramName);

          this.subscriptions.push(
            this.store.pipe(select(getParamsValuesListByName(paramName))).subscribe(data => {
              if (data) {
                this.paramSelectionList[paramName] = data;
                console.log(data);
                let newData = data.map(x => {
                  let newobj = {
                    label: x.name,
                    value: x.id,
                  };
                  return newobj;
                });
                console.log(JSON.stringify(newData));
                //add flur in select options
                field.templateOptions.options = newData;
              }
            })
          );
        }
        return field;
      });
      this.formJson = fdata;
      this.mainFormJson = fdata;
    }
    console.log(rowData, this.formJson, this.actionName, this.winnerPlan);
    this.showActionFormAsperData();
    // this.isPlanLoaded$.subscribe((isPlan) => {
    //   if (isPlan) {
    //     console.log('isPlan');
    //     console.log(isPlan);
    //     this.showActionFormAsperData();
    //   }
    // });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  async openQCAnnotation(state: boolean) {
    console.log("openQCAnnotation");
    if (!state) {
      let modal = document.getElementsByTagName("ion-modal")[0];
      if (!!modal && modal.classList.contains("hide-modal")) {
        modal.classList.remove("hide-modal");
      }
      this.router.navigate(["/qcannotation"], {
        queryParams: {
          packetId: this.selectedRow.id,
          status: "Pending",
          test: true,
        },
      });
    } else {
      this.router.navigate(["/qcannotation"], {
        queryParams: {
          packetId: this.selectedRow.id,
          status: "Completed",
          test: true,
        },
      });
    }
  }
  async openPreplanning(state: boolean) {
    console.log("openPreplanning");
    if (!state) {
      let modal = document.getElementsByTagName("ion-modal")[0];
      if (!!modal && modal.classList.contains("hide-modal")) {
        modal.classList.remove("hide-modal");
      }
      this.router.navigate(["/qcpreplanning"], {
        queryParams: {
          packetId: this.selectedRow.id,
          status: "Pending",
          test: true,
        },
      });
    } else {
      this.router.navigate(["/qcpreplanning"], {
        queryParams: {
          packetId: this.selectedRow.id,
          status: "Completed",
          test: true,
        },
      });
    }
  }

  async lockModal(data: any, viewOnly?: boolean) {
    const Modal = await this.modalController.create({
      component: OpinionMasterComponent,
      cssClass: "opinion-screen-modal",
      componentProps: {
        data: { status: "lockModal", data: data, actionName: this.actionName, viewOnly },
      },
    });
    Modal.onDidDismiss().then((d: any) => {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 0);
    });
    return await Modal.present();
  }

    async newLockModal(data: any, viewOnly?: boolean) {
    const Modal = await this.modalController.create({
      component: OpinionScreenComponent,
      cssClass: "newopinion-screen-modal",
      componentProps: {
        data: { status: "lockModal", data: data, actionName: this.actionName, viewOnly, isheader:false },
      },
    });
    Modal.onDidDismiss().then((d: any) => {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 0);
    });
    return await Modal.present();
  }
    async viewHistory(selectedRow:any){ 
      const historyData = await this.httpService.fetchDataUsingPromise(true, `opinion-plans/getOpinionPlanHistory?packetId=${selectedRow}`);
      if(historyData.data.length){

        historyData.data = historyData.data.map((dt:any, i:number) => {
          dt.plans = dt.plans.map((pdt:any)=>{
            if(this.project == 'kg'){
              if(pdt.pricingResponse){
                pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
                let finalPrice = 0;
                finalPrice = Math.max(pdt.pricingResponse['MPM BASE_PD'], pdt.pricingResponse['MIX PRICE']);
                pdt.kgRate = Math.round(finalPrice * Number(pdt.expPol) * 100) / 100;
                pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
              }
            } else
            if(this.project == 'kps'){
              if(pdt.pricingResponse){
                pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
                pdt.kgRate = pdt.pricingResponse.stone_value;
                pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
                pdt.assort = pdt.pricingResponse.A_ASTNO;
                pdt.trend = pdt.pricingResponse.trend;
              }
            }
            pdt.pricingResponse = JSON.stringify(pdt.pricingResponse);
            return pdt;
          });
          let newStonePRice = dt.plans.map(e => e["net_stone_value"])
          let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
          dt.netStoneValues = newPrice;
          if(dt.mistakeOffList){
            dt = {
              ...dt,
              plans: dt.plans.map((pdt:any)=>{
                return {
                  ...pdt,
                  ...JSON.parse(dt.mistakeOffList)[0]
                }
              })
            };
          };
          return dt;
        });

        const popover = await this.popoverController.create({  
          component: OpinionViewHistoryComponent,  
          cssClass: 'planningRowOrderspopoup',  
          componentProps: { historyData: historyData.data },
        });
        await popover.present();
      } else {
        this.configService.showToast('error', 'No history data found!');
      }
    }


  async getFreshDataAndSetPaginationToGrid() {
    console.log("setPaginationToDxGrid- Grid Data");
    //tranfer Grid, ReturnGrid common function for both grid.
    let payload = null;
    // if (!!this.inputBarcodeOfATransferStone) {
    //   if (this.searchedPageName === "Transfer" || this.searchedPageName === ACTIONNAMES.managerTrasnfer) {
    //     payload = { packetIds: [this.inputBarcodeOfATransferStone] };
    //   } else payload = { packetId: this.inputBarcodeOfATransferStone }; //toFiltter grid Data by barcodeInputs
    // } else if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
    //   if (this.searchedPageName === "Transfer" || this.searchedPageName === ACTIONNAMES.managerTrasnfer) {
    //     payload = { packetIds: this.barcodeInputList };
    //   } else payload = { packetId: this.barcodeInputList };
    // }
    this.modifiedGridObject = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        let dataToFilter: any = [];
        if (!!loadOptions.filter) {
          ["filter"].forEach(function (i) {
            const optionValue = loadOptions[i as keyof LoadOptions];
            if (i in loadOptions) {
              console.log(loadOptions[i]);
              dataToFilter.push(loadOptions[i]);
              console.log(dataToFilter);
            }
          });
          if (Array.isArray(dataToFilter[0][0])) {
            dataToFilter[0].forEach((info, index) => {
              if (index % 2 === 0) {
                this.filterPayload.filter[info[0]] = info[2];
              }
            });
          } else {
            this.filterPayload.filter = {
              [dataToFilter[0][0]]: dataToFilter[0][2],
            };
          }
          console.log(this.filterPayload);
        } else {
          this.filterPayload = {};
          payload = null
        }
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo["limit"];
          } else {
            this.pageInfo["limit"] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.pageInfo["page"] = this.pageNumber;
          if (!!Object.keys(this.filterPayload).length) {
            payload = this.filterPayload;
          } else if (!!this.inputBarcodeOfATransferStone) {
            if (this.searchedPageName === "Transfer" || this.searchedPageName === ACTIONNAMES.managerTrasnfer) {
              payload = { packetIds: [this.inputBarcodeOfATransferStone] };
            } else payload = { packetId: this.inputBarcodeOfATransferStone }; //toFiltter grid Data by barcodeInputs
          } else if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
            if (this.searchedPageName === "Transfer" || this.searchedPageName === ACTIONNAMES.managerTrasnfer) {
              payload = { packetIds: this.barcodeInputList };
            } else payload = { packetId: this.barcodeInputList };
          }
            else {
            payload = null;
          }
          let response: any;
          // if(this.searchedPageName==="Transfer" || this.searchedPageName === ACTIONNAMES.managerTrasnfer){
          //   response = await this.httpService.postDataUsingPromise(true, ENDPOINTS.transferPacketFetch, payload);
          // } else {
          response = await this.httpService.fetchWithMeGridData(this.pageInfo, payload, this.searchedPageName);
          // }
          console.error("------------Debug");
          console.log(response, this.gridData, this.gridDataCount);
          if (response.statusCode == 200) {
            this.isDisabled = true;
            this.packetCounts = response?.data?.countOfTotalPackets || 0;
            // if(response.data?.length)
            if (this.searchedPageName === "Transfer" || this.searchedPageName === ACTIONNAMES.managerTrasnfer) {
              if (this.barcodeInputList.length >= 0 && response.data.packetData?.length < this.barcodeInputList.length) {
                this.configService.showToast("error", "Packet Not Found");
              }
              const data = {
                countOfTotalPackets: response?.data?.countOfTotalPackets || 0,
                packetData: response.data.packetData || [],
              };
              this.httpService.updateTranferReturnAssignGridData(data);
            } else {
              this.httpService.updateTranferReturnAssignGridData(response.data);
            }
          } else {
            console.error(response);
          }
          if (this.barcodeInputList && this.barcodeInputList.length > 0) {
            this.scanned = true;
            this.rowsIndex = this.gridData.map((d, index: number) => index);
            setTimeout(() => {
              this.scanned = false;
            }, 1000);
          }

          return {
            data: Query(this.gridData).toArray(),
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        } else {
          if (this.barcodeInputList && this.barcodeInputList.length > 0) {
            this.scanned = true;
            this.rowsIndex = this.gridData.map((d, index: number) => index);
            setTimeout(() => {
              this.scanned = false;
            }, 1000);
          }
          return {
            data: this.gridData,
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        }
      },
    });
  }
  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.pageInfo["limit"] = size;
        this.getFreshDataAndSetPaginationToGrid();
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.pageInfo["page"] = pageIndex;
        this.getFreshDataAndSetPaginationToGrid();
        break;
    }
  }

  async selfIssueAndReceiveForRaacoAcknowledgement(packetid) {
    console.log(packetid);

    let payload = {
      packetIds: [packetid[0]?.packetId],
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet/selfIssueAndReceiveForAcknowLedgement", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }
  async getDropDownValueOfAcknowledgement() {
    console.log(this.selectedRow);
    let endpoint: string = `tbl-action/acknowledgementActionsList/${this?.selectedRow?.id}`;
    return this.httpService.fetchData(true, endpoint);
  }

  async sendNextActionForAcknowledgement(packetID, formValue) {
    let payload = {
      nextActionId: formValue.Acknowledgement,
      packetId: packetID,
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet-next-action/save", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data = response.message;
      console.log(data);
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  selectRole(evt: any) {
    console.log("selectRole");
    console.log(evt);
    this.selectedRoleObj = evt;
  }

  async fetchChatMessages(ID) {
    return;
    this.httpService?.getChatMessages("Packet", ID?.toString())?.subscribe(d => {
      this.allMessages = d;
      console.log(this.allMessages);
      if (this.allMessages && this.allMessages.length > 0) {
        this.createContentForPopup();
      }
    });
  }

  async createContentForPopup() {
    const userNameToMatch = this.userName;
    this.chatContentForPopup = this.allMessages?.filter((messageObj: any) => messageObj?.message.includes(`@${userNameToMatch}`));
    if (this.chatContentForPopup.length > 0) {
      await this.chatAlertComponent();
      console.log("renderMentionsPopup emitted---------");
      this.isAlertModalopened = true;
    }
  }

  async chatAlertComponent() {
    console.log(this.chatContentForPopup);
    if (this.chatContentForPopup && this.chatContentForPopup.length) {
      const Modal = await this.modalController.create({
        component: ChatAlertModelComponent,
        cssClass: "chatAlertModel",
        componentProps: {
          data: this.chatContentForPopup,
        },
      });
      Modal.onDidDismiss().then((d: any) => this.handleModalDismissOfAlert(d));
      return await Modal.present();
    }
    return undefined;
  }

  async handleModalDismissOfAlert(d) {
    this.isAlertModalopened = false;
    this.chatContentForPopup = [];
    if (d.data) {
      console.log(d);
    }
    console.log("closing modal");
  }

  async openReviewDownload(data) {
    const Modal = await this.modalController.create({
      component: FileDownloadComponent,
      cssClass: "DownloadFileModal",
      componentProps: {
        data: { data: "fileDownload", packetData: data },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissOfDownload(d));
    return await Modal.present();
  }
  async openDownloadModal(data) {
    const Modal = await this.modalController.create({
      component: OldFileDownloadComponent,
      cssClass: "DownloadFileModal",
      componentProps: {
        data: { data: "fileDownload", packetData: data },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissOfDownload(d));
    return await Modal.present();
  }

  async handleModalDismissOfDownload(d) {
    if (d.data) {
      console.log(d);
    }
    console.log("closing modal");
  }

  printFunction(event: any) {
    console.log(event);
    if (this.searchedPageName === this.ACTIONNAMES.FETCH_BARCODE) {
      if (event?.row?.data) {
        console.log(event.row.data);
        console.log(this.selectedRows);
        try {
          this.getBarcodeToPrint(this.selectedRows);
        } catch (error) {
          // this.selectedRows = SELECTED_ROWS_BEFORE;
        }
      }
    }
  }

  getBarcodeToPrint(data: any[]) {
    const barcodeList = [];
    let content = ``;

    if (!data?.length) {
      this.configService.showToast("error", "No data found!");
      return;
    }
    data.forEach(packet => {
      if (!packet) {
        return;
      }
      let packetLabel = packet.packetLabel;
      let pktNo = packetLabel.replace(packet.lotNo, "").slice(1);

      // const match =
      // const index = match.index;
      // let pktNo = [packetLabel.slice(0, index), packetLabel.slice(index + 1)];
      let barcode = packet.lotNo + "#" + packet.packetLabel;
      let packetData = `

O
Q133,13
q540
S4
D15
ZT
JF



N
A540,105,2,4,1,1,N,"${packet.lotNo}"
A540,18,1,3,1,1,N,"KGHO"
A200,105,2,3,1,1,N,"${pktNo}"
A90,105,2,3,1,1,N,"${packet.grade}"
B510,80,2,1,2,0,35,N,"${packet.barcodeNo}"
A500,40,2,3,1,1,N,"NON DTC"
A280,40,2,3,1,1,N,"${packet.Clarity ? packet.Clarity : "-"}"
A210,40,2,3,1,1,N,"  ${packet.cts}"
A500,20,2,3,1,1,N,"${packet.checkerName}"
A210,20,2,3,1,1,N,"  ${packet.expPol ? packet.expPol : "-"}"
P1\n`;
      content += packetData;

      let barcodeText1 = `${packet.lotNo} ${packet.packetLabel}`;
      let barcodeText2 = `${packet.cts} ${packet.Clarity} ${this.datePipe.transform(packet.inDate, "dd-MMM-yyyy")}`;
      barcodeList.push({
        barcode: barcode,
        barcodeText1: barcodeText1,
        barcodeText2: barcodeText2,
      });
    });

    if (content === ``) {
      this.configService.showToast("error", "No valid data found!");
      return;
    }

    const a = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = "LotBarcode" + ".txt";
    a.click();
  }

  downloadFile(event) {
    if (this.searchedPageName === ACTIONNAMES.generateReport) {
      if (event.row.data.fileName) {
        let fileName = event?.row?.data?.fileName;
        let newFileName = fileName.split("/");
        let joinedPath = newFileName.slice(1).join("/");
        window.open(this.configService.getCentralFileUrl() + newFileName[0] + "/" + encodeURIComponent(joinedPath), "download");
        //  this.http.get(this.configService.getCentralFileUrl() +newFileName[0] + "/" + encodeURIComponent(joinedPath), { responseType: 'blob' }).subscribe(
        //    (response: Blob) => {
        //      const blob = new Blob([response]);
        //      const url = window.URL.createObjectURL(blob);
        //      const link = document.createElement('a');
        //      link.href = url;
        //      link.download = fileName;
        //      link.click();
        //    },
        //    (error) => {
        //      console.error('Failed to download file:', fileName, error);
        //    }
        //  );
      } else {
        this.configService.showToast("error", "No File Is Present For Download");
      }
    } else {
      this.openDownloadModal(event.row.data);
    }
  }

  async openBarcodeScannerModal(type?: string) {
    const modal = await this.modalController.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      if (type === "autoReceive") {
        this.barcodeDetection(this.barcodeInputList);
        return;
      }
      console.log(this.currActionName);
      this.stonesForTransfer = [];
      let payload = { packetId: this.barcodeInputList };
      if (this.searchedPageName == "First Allocation") {
        this.fetchFirstAllocationData(payload);
      } else if (this.arrayOfFilterActionWise.includes(this.currActionName)) {
        this.getFreshDataAndSetPaginationToGrid();
      } else {
        this.filterForActionWise(this.barcodeInputList);
      }
    }
  }

  filterForActionWise(barcode) {
    let payload = {
      packetIds: barcode,
    };
    if (!!this.barcodeInputList) {
      this.httpService.getActionAndStatusWisePacketList(this.actionId, this.status, payload).subscribe((d: any) => {
        if (d.statusCode == 200) {
          this.dataSource = d.data;
          this.configService.showToast("success", d.message);
        } else {
          this.configService.showToast("error", d.message);
        }
      });
    }
  }

  async isPreviousPlantrue() {
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: "lockModal",
      componentProps: {
        data: { status: "isPreviousPlantrue" },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissForPreviousPlan(d));
    return await Modal.present();
  }

  async handleModalDismissForPreviousPlan(data) {
    if (!!data) {
      this.selectedEcsvOption = data.data;
      console.log(this.selectedEcsvOption);
      if (this.selectedEcsvOption === "newFile") {
        let info = {
          rowData: this.selectedRow,
          status: "Initial",
          parameterList: this.paramSelectionList,
          className: "ecsvModal",
          history: this.packetHistories,
        };
        console.log(info);
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
        this.showModal(true, "e-csv Upload", info);
      } else if (this.selectedEcsvOption === "oldData") {
        this.store.dispatch(
          SignerAction.getPacketPlans({
            packetIdOb: {
              packetId: this.selectedRow?.packetId,
            },
          })
        );
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));

        await this.router.navigate(["/planning"]);
      } else if (this.selectedEcsvOption === "appendData") {
        let info = {
          rowData: this.selectedRow,
          status: "Initial",
          parameterList: this.paramSelectionList,
          className: "ecsvModal",
          history: this.packetHistories,
        };
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
        this.showModal(true, "e-csv Upload", info, true);
      }
    }
  }

  async DeleteAutoReceive(event) {
    let data = event.row.data;
    let packetId = data.id;
    console.log(packetId);
    if (!!packetId) {
      this.httpService.removeIssuedPacketForAutoReceive(packetId).subscribe((d: any) => {
        console.log(d);
        if (d.statusCode == 200) {
          this.configService.showToast("success", d.message);
          this.store.dispatch(AutoReceiveAction.getAutoReceivePacket());
        } else {
          this.configService.showToast("error", d.message);
        }
      });
    }
  }

  FB_print() {
    if (environment.project === "kps") {
      if (!this.selectedRows?.length) return;
      const allChild = [];
      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write('</head><body style="margin: 0;">');
      for (const data of this.selectedRows) {
        const textToShow = {};
        const keyToShow = [
          "packetLabel",
          "lotNo",
          "cts",
          "checkerName",
          "inDate",
          "SHAPE",
          "Clarity",
          "CUT",
          "expPol",
          "COLOR",
          "accountNo",
          // 'purity',
          // 'sieve',
          // 'polishGrade',
        ];
        let barcode = "";
        if (data?.barcodeNo) barcode = data.barcodeNo;

        keyToShow.map((d: any) => {
          if (data[d]) {
            textToShow[d] = data[d];
          }
        });

        const canvas = document.createElement("canvas");

        JsBarcode(canvas, barcode, {
          format: "CODE128",
          displayValue: false,
          text: ``,
        });

        canvas.style.margin = "0";
        canvas.style.padding = "0";
        canvas.style.border = "none";
        canvas.style.width = "35%";
        canvas.style.height = "50px";

        const containerDiv = document.createElement("div");
        containerDiv.style.display = "flex";
        containerDiv.style.flexDirection = "column";
        containerDiv.style.alignItems = "center";
        containerDiv.style.justifyContent = "center";
        containerDiv.style.fontWeight = "900";
        containerDiv.style.fontFamily = "monospace";
        containerDiv.style.padding = "3px";

        containerDiv.appendChild(canvas);

        const innerDiv = document.createElement("div");
        innerDiv.style.breakAfter = "page";
        innerDiv.style.fontSize = "18px";
        innerDiv.style.display = "flex";
        innerDiv.style.alignItems = "center";
        innerDiv.style.flexDirection = "column";

        const paragraph1 = document.createElement("p");
        paragraph1.style.margin = "2px 0";
        paragraph1.textContent = `${textToShow?.[keyToShow[0]]} \xa0\xa0\xa0\xa0\xa0 ${textToShow?.[keyToShow[1]]} \xa0\xa0 ${textToShow?.[keyToShow[2]]}`;

        const paragraph2 = document.createElement("p");
        paragraph2.style.margin = "2px 0";
        const value3 = textToShow?.[keyToShow[3]] ?? "";
        const value4 = `${this.datePipe.transform(textToShow?.[keyToShow[4]], "dd-MMM")}`;
        const value5 = textToShow?.[keyToShow[5]] ?? "";
        const value6 = textToShow?.[keyToShow[6]] ?? "";
        const value7 = textToShow?.[keyToShow[7]] ?? "";
        const value8 = textToShow?.[keyToShow[8]] ?? "";
        const value9 = textToShow?.[keyToShow[9]] ?? "";
        const value10 = textToShow?.[keyToShow[10]] ?? "";
        paragraph2.textContent = `${value3} \xa0 ${value4} `;

        const paragraph3 = document.createElement("p");
        paragraph3.style.margin = "1px 0";
        paragraph3.textContent = `${value8} \xa0 ${value9} ${value6} \xa0  ${value5} \xa0 ${value10}`;

        innerDiv.appendChild(paragraph1);
        innerDiv.appendChild(paragraph3);
        innerDiv.appendChild(paragraph2);

        containerDiv.appendChild(innerDiv);

        printWindow.document.body.appendChild(containerDiv);

        allChild.push(containerDiv);
      }
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      allChild.map((containerDiv: any) => {
        printWindow.document.body.removeChild(containerDiv);
      });
      printWindow.close();
    } else if (environment.project === "kg") {
      this.getBarcodeToPrint(this.selectedRows);
    }
  }

  getpacketReports() {
    this.httpService.getPacketReport().subscribe(
      (res: any) => {
        let centralUrl = this.configService.getCentralFileUrl();
        window.open(centralUrl + res.data, "_blank");
      },
      (err: any) => {
        this.configService.showToast("error", err.message || "Something went wrong!");
      }
    );
  }

  generateStockReport() {
    this.httpService.getStockReport().subscribe(
      (res: any) => {
        // let baseURL = this.configService.getTrybeBaseUrl();
        let centralUrl = this.configService.getCentralFileUrl();
        window.open(centralUrl + res.data, "_blank");
      },
      (err: any) => {
        this.configService.showToast("error", err.message || "Something went wrong!");
      }
    );
  }

  generateQcReport() {
    this.httpService.getQcReport().subscribe(
      (res: any) => {
        let baseURL = this.configService.getTrybeBaseUrl();
        window.open(baseURL + res.data, "_blank");
      },
      (err: any) => {
        this.configService.showToast("error", err.message || "Something went wrong!");
      }
    );
  }

  generatePlanDataForEachDept() {
    let payload = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      deptNames: this.reportDeptId,
    };

    this.httpService.getgeneratePlanDataForEachDept(payload).subscribe(
      (res: any) => {
        // let baseURL = this.configService.getTrybeBaseUrl();
        let centralUrl = this.configService.getCentralFileUrl();
        window.open(centralUrl + res.data, "_blank");
      },
      (err: any) => {
        this.configService.showToast("error", err.message || "Something went wrong!");
      }
    );
  }

  async getFirstParameter() {
    if (this.selectedRow?.refPacketId) {
      try {
        const data = await this.httpService.getDataWithoutPayloadForGet(true, `packet/getFirstParameter/${this.selectedRow?.refPacketId}`).toPromise();
        if (!!data) {
          console.log(data.data);
          this.originalPcktLabel = data?.data?.packetId;
          this.originalPktWt = data?.data?.carat;
        }
      } catch (error) {
        console.error("Error fetching worker data:", error);
      }
    }
  }

  setDispatchVoucherData(data) {
    this.infoToPrint["voucherDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
    this.infoToPrint["issueDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
    this.infoToPrint["voucherNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["jangadNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["issueNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["fromCompany"] = data?.fromCompany;
    this.infoToPrint["toCompany"] = data?.toCompany;
    this.infoToPrint["issueFor"] = `Transfer`;
    this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
    let voucherData = {
      data: data,
    };
    this.isTrasferDispatch = false;
    // let isLoss;
    if (!!data && data?.dispatchVoucher.length > 0) {
      this.dispatchData = data?.dispatchVoucher;
      this.voucherDto = data?.voucherDto;
      // this.infoToPrint['fromCompany'] = this.dispatchData[0].fromCompany;
      // this.infoToPrint['toCompany'] = this.dispatchData[0].toCompany;
      this.infoToPrint["voucherId"] = this.dispatchData[0].voucherId;
      this.infoToPrint["TocompanyName"] = this.dispatchData[0].partyName;
      this.infoToPrint["date"] = moment.utc(this.dispatchData[0].Date).local().format("DD-MMM-YYYY");
      this.infoToPrint["ttlPkt"] = this.dispatchData.length;
      let rctsttl = 0;
      let ctsttl = 0,
        newTotalRgCutWt = 0,
        totalLoss = 0,
        totalOutStandingWt = 0,
        totalValue = 0;
      this.dispatchData.map(d => {
        if (d.originalWt) ctsttl += d.originalWt;
        if (d.wt) rctsttl += d.wt;
        if (d.roughCutWt) newTotalRgCutWt += Number(d.roughCutWt);
        if (d.loss) totalLoss += Number(d.loss);
        if (d.outStandingWt) totalOutStandingWt += Number(d.outStandingWt);
        if (d.crtValue) totalValue += Number(d.crtValue);
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.infoToPrint["owtttl"] = ctsttl;
      this.infoToPrint["rctsttl"] = rctsttl;
      this.infoToPrint["newTotalRgCutWt"] = newTotalRgCutWt;
      this.infoToPrint["totalLoss"] = totalLoss;
      this.infoToPrint["totalOutStandingWt"] = totalOutStandingWt;
      this.infoToPrint["totalValue"] = totalValue;
      this.dispatchLossData = this.dispatchData?.filter(d => d.loss != null);
      // isLoss = (this.dispatchLossData !== undefined)
      this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
      this.isTrasferDispatch = true;
    }
  }

  async getPacketFiles(packetBarcode) {
    try {
      // const data = await this.httpService
      //   .getDataWithoutPayloadForGet(true, `packet/getfilesPathFromCentral?packetBarcode=${packetBarcode}`)
      //   .toPromise();
      let payload = {
        packetBarcode: packetBarcode,
      };
      console.log(payload);
      const data = await this.httpService.postDataUsingPromise(true, "packet/getfilesPathFromCentral", payload);
      // console.log(response);
      if (!!data.data) {
        console.log(data.data);
        this.openReviewDownload(data?.data);
      }
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  async getReportURL(APIendpoint) {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, APIendpoint).toPromise();
      if (!!data) {
        // let baseURL = this.configService.getTrybeBaseUrl();
        let centralUrl = this.configService.getCentralFileUrl();
        window.open(centralUrl + data.data, "_blank");
      }
    } catch (error) {
      console.error("Error getting report data:", error);

      this.configService.showToast("error", error?.message || "Something went wrong!");
    }
  }

  async getReportForPacketParameter(APIendpoint) {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, APIendpoint).toPromise();
      if (!!data) {
        // let baseURL = this.configService.getTrybeBaseUrl();
        let centralUrl = this.configService.getCentralFileUrl();
        window.open(centralUrl + data.data, "_blank");
      }
    } catch (error) {
      console.error("Error getting report data:", error);

      this.configService.showToast("error", error?.message || "Something went wrong!");
    }
  }

  onGenerateReportChanges(event) {
    console.log(event);
    this.selectedReportLot = null;
    // if (event == this.reportEnum.signer_checker_Parameter_report ) {
    //   this.isshowFromTo = true;
    // }else if (event == this.reportEnum.facet_signer_checker_Parameter_report) {
    //   this.isshowFromTo = true;
    // }else if (event == this.reportEnum.PACKET_PLAN_REPORT) {
    //   this.isshowFromTo = true;
    // } else if (event == this.reportEnum.DNAReport) {
    //   this.isshowFromTo = true;
    // } else if (event == this.reportEnum.Checker_Bid_Report) {
    //   this.isshowFromTo = true;
    // } else {
    //   this.isshowFromTo = false;
    //   this.reportDeptId = null;
    //   this.typeId = null;
    // }
    if(event == this.reportEnum.signer_checker_Parameter_report || 
      event == this.reportEnum.facet_signer_checker_Parameter_report ||
      event == this.reportEnum.PACKET_PLAN_REPORT ||
      event == this.reportEnum.DNAReport ||
      event == this.reportEnum.Checker_Bid_Report ||
      event == this.reportEnum.Signer_Wages_Report
    ){
      this.isshowFromTo = true;
    }else{
      this.isshowFromTo = false;
      this.reportDeptId = null;
      this.typeId = null;
      this.selectedReportLot = null;
    }

    if(event == this.reportEnum.CSFCReport || event == this.reportEnum.facet_signer_checker_Parameter_report){
      if(this.allLotsData.length == 0){
        this.getAllLots();
      }
    }
  }

  fetchReports() {
    let endpoint;
    console.log(this.generateReport);
    if (this.generateReport == this.reportEnum.signer_checker_Parameter_report) {
      this.isshowFromTo = true;
      if (environment.project == "kps") {
        endpoint = `packet/getPlannerCheckerPlans?fromDate=${this.fromDate}&toDate=${this.toDate}`;
        this.getReportURL(endpoint);
      } else if (environment.project == "kg") {
        endpoint = `packet/kgSignerCheckerParameterReport`;
        this.getReportURL(endpoint);
      }
    } else if (this.generateReport == this.reportEnum.PACKET_REPORT) {
      if (environment.project == "kps") {
        this.getpacketReports();
      } else if (environment.project == "kg") {
        endpoint = `packet/kgPreVirtualPlanningStatusReport`;
        this.getReportURL(endpoint);
      }
    } else if (this.generateReport == this.reportEnum.STOCK_REPORT) {
      if (environment.project == "kps") {
        this.generateStockReport();
      } else if (environment.project == "kg") {
        endpoint = `packet/kgCurDeptParameterReport`;
        this.getReportURL(endpoint);
      }
    } else if (this.generateReport == this.reportEnum.PACKET_PLAN_REPORT) {
      this.isshowFromTo = true;
      this.generatePlanDataForEachDept();
    }
  }

  async fetchReportsNew() {
    let payload;

    if (this.fromDate && this.toDate) {
      const maxToDate = moment(this.fromDate).add(1, 'month');
      if (moment(this.toDate).isAfter(maxToDate)) {
        this.configService.showToast("error", "Please Select Within 1 Month Range");
        return;  
      }
    } else if (this.fromDate || this.toDate) {
      this.configService.showToast("error", "Please select both From and To dates within a 1-month range");
      return;
    }

    // if (this.generateReport === this.reportEnum.PACKET_PLAN_REPORT) {
    //   payload = {
    //     reportOf: this.generateReport,
    //     fromDate: this.fromDate,
    //     toDate: this.toDate,
    //     deptNames: this.reportDeptId,
    //   };
    // } else if (this.generateReport === this.reportEnum.signer_checker_Parameter_report) {
    //   payload = {
    //     reportOf: this.generateReport,
    //     fromDate: this.fromDate,
    //     toDate: this.toDate,
    //     reportType: this.typeId,
    //   };
    // } else if (this.generateReport === this.reportEnum.DNAReport) {
    //   payload = {
    //     reportOf: this.generateReport,
    //     fromDate: this.fromDate,
    //     toDate: this.toDate,
    //   };
    // } 
    // else if (this.generateReport === this.reportEnum.Checker_Bid_Report) {
    //   payload = {
    //     reportOf: this.generateReport,
    //     fromDate: this.fromDate,
    //     toDate: this.toDate,
    //   };
    // } else if (this.generateReport === this.reportEnum.facet_signer_checker_Parameter_report) {
    //   payload = {
    //     reportOf: this.generateReport,
    //     fromDate: this.fromDate,
    //     toDate: this.toDate,
    //     reportType: this.typeId,
    //   };
    // } else {
    //   payload = {
    //     reportOf: this.generateReport,
    //   };
    // }

    if (this.generateReport === this.reportEnum.PACKET_PLAN_REPORT) {
        payload = {
          reportOf: this.generateReport,
          fromDate: this.fromDate,
          toDate: this.toDate,
          deptNames: this.reportDeptId,
        };
    } else if (this.generateReport === this.reportEnum.signer_checker_Parameter_report || this.generateReport === this.reportEnum.facet_signer_checker_Parameter_report) {
        payload = {
          reportOf: this.generateReport,
          fromDate: this.fromDate,
          toDate: this.toDate,
          reportType: this.typeId,
        }
    }  else if (
      this.generateReport === this.reportEnum.PACKET_PLAN_REPORT ||
      this.generateReport === this.reportEnum.DNAReport ||
      this.generateReport === this.reportEnum.Checker_Bid_Report ||
      this.generateReport === this.reportEnum.Signer_Wages_Report
    ) {
        payload = {
          reportOf: this.generateReport,
          fromDate: this.fromDate,
          toDate: this.toDate,
        };
    }
    else {
        payload = {
          reportOf: this.generateReport,
        };
      }
    if(this.generateReport === this.reportEnum.CSFCReport || this.reportEnum.facet_signer_checker_Parameter_report){
      payload['lotNo'] = this.selectedReportLot? this.selectedReportLot : null;
    }
    payload['env'] = environment.project;
    console.log("payload for reports", payload);
    let response = await this.httpService.postDataUsingPromise(true, "generate-reports/createUpdate", payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.refresh();
      this.store.dispatch(AutoReceiveAction.getAutoReceivePacket());
      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);

      this.configService.showToast("error", response.error.message);
    }
  }

  async refresh() {
    this.getFreshDataAndSetPaginationToGrid();
  }

  async getAllLots(){
    try{
      const data =  await this.httpService.getDataWithoutPayloadForGet(true,"packet/getAllLots").toPromise();
      this.allLotsData = data.data;
    }catch(error){
      console.error("Error while getting lots:",error);
    }
  }

  

}
