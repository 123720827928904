import { Component, Input, OnInit } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { ConfigService } from "../services/config/config.service";
import { AppState } from "../reducers";
import { Store, select } from "@ngrx/store";
import { getCurrentAction, getCurrentRowData } from "../tempDataStore/tempData.selector";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import * as JSZip from "jszip";
import { HttpClient } from "@angular/common/http";
import { LoaderService } from "../services/loader/loader.service";

@Component({
  selector: "app-file-download",
  templateUrl: "./file-download.component.html",
  styleUrls: ["./file-download.component.scss"],
})
export class FileDownloadComponent implements OnInit {
  rowData: any;
  @Input() data: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  @Input() packetData: any;
  filesDetails: any;
  fileData = {};
  filePaths = {};
  allfileDownload: any;
  currentActionId: any;
  currentActionName: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    public loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.currentActionName = action.name;
          this.currentActionId = action.id;
        }
      });
    this.packetData = this.data?.packetData;
    console.log(this.packetData);
    if (this.currentActionName == "Review Packet Files") {
      this.fileData = this.data?.packetData;
      for (const key in this.data?.packetData?.filePaths || {}) {
        let data = [];
        for (const versionKey in this.data.packetData.filePaths[key]) {
          data.push({
            version: versionKey,
            link: this.data.packetData.filePaths[key][versionKey],
            fileName: this.getFileName(this.data.packetData.filePaths[key][versionKey]),
          });
        }
        this.filePaths[key] = data;
      }
      this.filesDetails = this.data?.packetData;
      this.allfileDownload = [];
      for (let key in this.filesDetails) {
        for (let val of this.filesDetails?.[key]) {
          this.allfileDownload?.push(val);
        }
      }
    } else this.fetchFilesDetail();
  }

  getFileName(link: any) {
    try {
      // link = link[Object.keys(link)[Object.keys(link).length - 1]];
      // link = link?.[link?.length - 1];
      // console.log('link.split("/")[link.split("/")?.length - 1]', link.split("/"), link.split("/")[link.split("/")?.length - 1]);
      return link.split("/")[link.split("/")?.length - 1];
    } catch (error) {
      return "";
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  downloadAllVersion(fileData: any) {
    // fileData.value = fileData.value[Object.keys(fileData.value)[Object.keys(fileData.value).length - 1]];
    // link = link?.[link?.length - 1];

    console.log(fileData);
    // if (this.currentActionName != "Review Packet Files") {
    // for (const key in fileData.value) {
    // this.downloadFileVersion(fileData.value);
    fileData.map((dt: any) => {
      this.downloadFile(dt.link);
    });
    // }
    // } else {
    // this.downloadFileVersion(fileData.value);
    // }
  }

  downloadFileVersion(fileUrl) {
    if (!fileUrl?.[0]) {
      this.configService.showToast("error", "File does not exist!");
      return;
    }
    let fileName;
    if (typeof fileUrl === "string") {
      fileName = fileUrl;
    } else fileName = fileUrl[0];
    let newFileName = fileName.split("/");
    let joinedPath = newFileName.slice(1).join("/");
    console.log(this.configService.getCentralFileUrl() + fileUrl);
    window.open(this.configService.getCentralFileUrl() + newFileName[0] + "/" + encodeURIComponent(joinedPath), "download");
    this.http.get(this.configService.getCentralFileUrl() + fileUrl, { responseType: "blob" }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
      },
      error => {
        console.error("Failed to download file:", fileUrl, error);
      }
    );
  }

  isArray(process: any) {
    if (Array.isArray(process.value)) {
      return true;
    } else return false;
  }

  downloadFile(link: any) {
    console.log(link);
    this.httpService.downloadFileFromCentral(link);
  }

  async createZipArchive() {
    const zip = new JSZip();
    const listOfAllFiles = this.allfileDownload;
    const prefix = this.configService.getCentralFileUrl();
    for (const filePath of listOfAllFiles) {
      const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
      const prefixedFileName = prefix + fileName;
      const response = await this.http.get(prefix + filePath, { responseType: "arraybuffer" }).toPromise();
      zip.file(prefixedFileName, response);
    }

    const content = await zip.generateAsync({ type: "blob" });

    const blob = new Blob([content]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "files.zip";

    link.click();
  }

  // santtise
  // downloadFile(link: any) {
  //   if (typeof link === 'string' && link.startsWith('http')) {
  //     const sanitizedUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  //     this.downloadSanitizedFile(sanitizedUrl);
  //   } else {
  //     console.error('Invalid URL format.');
  //   }
  // }

  // downloadSanitizedFile(sanitizedUrl: SafeResourceUrl) {
  //   const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, sanitizedUrl);
  //   if (url) {
  //     window.open(url, '_blank');
  //   } else {
  //     console.error('Failed to sanitize the URL.');
  //   }
  // }

  async fetchFilesDetail() {
    let endpoint: string = `processActionInput/getFilesPathOfPacket?packetId=${this.packetData?.id}`;
    if (this.data?.virtualAction) {
      endpoint += `&actionId=${this.data.virtualAction}`;
    }
    // else if (this.currentActionName=='Review Packet Files'){
    //   endpoint+=`&actionId=${this.currentActionId}`;
    // }
    console.log(endpoint);
    let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.filesDetails = response.data;
      this.fileData = this.findSpecificPatternKeys(this.filesDetails.filePaths);
      if (Object.keys(this.fileData).length == 0) {
        this.configService.showToast("error", "File does not exist!");
      }
      this.allfileDownload = this.filesDetails?.listOfAllFiles;
    } else {
      console.error(response);
    }
  }

  findSpecificPatternKeys(obj) {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          let containsPattern = false;
          for (const nestedKey in value) {
            if (value.hasOwnProperty(nestedKey) && Array.isArray(value[nestedKey])) {
              containsPattern = true;
              break;
            }
          }

          if (containsPattern) {
            result[key] = value;
          } else {
            const nestedResult = this.findSpecificPatternKeys(value);
            if (Object.keys(nestedResult).length > 0) {
              result[key] = nestedResult;
            }
          }
        }
      }
    }
    return result;
  }
}
