import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { HeaderComponentModule } from '../components/header/header.module';
import { PacketchatComponent } from './packetchat.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgMentionsModule } from 'ng-mentions';
import { MentionModule } from 'angular-mentions';
import { ChatAlertModelComponent } from '../components/chat-alert-model/chat-alert-model.component';
import { ChatAlertModule } from '../components/chat-alert-model/chat-alert.module';




@NgModule({
  declarations: [PacketchatComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    DataGridComponentModule,
    HeaderComponentModule,
    NgSelectModule,
    MentionModule,
    ChatAlertModule
  ],
  exports: [PacketchatComponent],
})
export class PacketchatModule { }
