import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PacketInwardService } from "../services/packet-inward.service";
import { ConfigService } from "src/app/services/config/config.service";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { selectPacketInward } from "../state-management/packet-inward/packet-inward.selector";
import { map } from "rxjs/operators";
import { GET_packetInwardList } from "../state-management/packet-inward/packet-inward.action";
import { packetInward } from "../state-management/packet-inward/packet-inward.interface";
import { DatePipe } from "@angular/common";
import * as JsBarcode from "jsbarcode";
import { LoaderService } from "src/app/services/loader/loader.service";
import * as moment from "moment";
import { DynamicModalComponent } from "src/app/transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component";
import { ModalController } from "@ionic/angular";
import { HttpService } from "src/app/services/http/http.service";

@Component({
  selector: "app-packet-inward",
  templateUrl: "./packet-inward.component.html",
  styleUrls: ["./packet-inward.component.scss"],
})
export class PacketInwardComponent implements OnInit {
  @ViewChild("modalContent") modalContent: ElementRef;
  columns = [
    { name: "File Name", prop: "fileName" },
    { name: "Date & Time", prop: "createdDate" },
    { name: "By", prop: "createdBy" },
    { name: "Status", prop: "status", columnTemplate: "chipTemplate" },
  ];
  mode: string = "single";
  page = "Import";
  pageSize = 50;
  selectedCompany: any = null;
  selectedAccNumber: any = null;
  selectedJobInProcess: any = null;
  tableData: Observable<any>;
  fromDate: any = moment().format("YYYY-MM-DD");
  toDate: any = moment().format("YYYY-MM-DD");
  companyOptions: any;
  jobOptions: any;
  accountOptions: any;

  constructor(
    private packetInwardService: PacketInwardService,
    private _ConfigService: ConfigService,
    private store: Store<packetInward>,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private http: HttpService,
    private ModalCtrl: ModalController
  ) {}

  async ngOnInit() {
    this.getAllCompanyData();
    await this.packetInwardService.loadToken();
    this.getTableData();
  }

  go() {
    
    this.store.dispatch(GET_packetInwardList({ Payload: { fromDate: this.fromDate, toDate: this.toDate } }));
  }

  async getTableData() {
    
    this.store.dispatch(GET_packetInwardList({ Payload: { fromDate: this.fromDate, toDate: this.toDate } }));
    this.tableData = this.store.select(selectPacketInward).pipe(
      map((data: any) => {
        
        return data.packetInword
          .map((dt: any) => {
            return {
              ...dt,
              createdDate: this.datePipe.transform(dt.createdDate, "dd-MMM-yyyy hh:mm a"),
              fileName: dt?.file?.split("_")?.[1] ? dt?.file?.split("_").slice(1).join("_") : dt.file,
            };
          })
          .sort((a: any, b: any) => Date.parse(b.createdDate) - Date.parse(a.createdDate));
      })
    );
  }
  refreshTableData() {
    this.store.dispatch(GET_packetInwardList({ Payload: { fromDate: this.fromDate, toDate: this.toDate } }));
  }

  async fileUpload(fileInput: any) {
    if (!fileInput?.files?.length) return;
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);
    formData.append("jobProcessId", this.selectedJobInProcess);
    formData.append("accountNo", this.selectedAccNumber);
    formData.append("jobPartyId", this.selectedCompany);
    const res = await this.packetInwardService.uploadPacketFile(formData, this.selectedJobInProcess, this.selectedAccNumber, this.selectedCompany);
    if (res.statusCode == 200) {
      this.store.dispatch(GET_packetInwardList({ Payload: { fromDate: this.fromDate, toDate: this.toDate } }));
      this._ConfigService.showToast("success", res.data || "File saved successfully");
    } else this._ConfigService.showToast("error", res?.error?.message || "Something went wrong");
  }

  async downloadFile(event: any) {
    const fileName = event?.row?.data?.file || "";
    await this.packetInwardService.downloadPacketFile(fileName);
  }

  printBarcode(event: any) {
    const packetDetails = event?.row?.data?.packetDetails;
    const accountNo = event?.row?.data?.accountNo || "";
    if (!packetDetails || Object.keys(packetDetails).length === 0) {
      this._ConfigService.showToast("error", "No data found!");
      return;
    }
    const allChild = [];
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write('</head><body style="margin: 0;">');
    for (const key in packetDetails) {
      const canvas = document.createElement("canvas");
      const textToShow = {};
      const keyToShow = ["inwardDate", "pktNo", "lotNo", "carat", "purity", "sieve", "accountNo"];
      let barcode = "";

      packetDetails[key].map((data: any) => {
        if (data?.barcode) barcode = data.barcode;
        if (keyToShow.includes(data.paramName)) textToShow[data.paramName] = data.paramValue;
      });

      // if (accountNo) {
      textToShow[keyToShow[6]] = accountNo;
      // }

      JsBarcode(canvas, barcode, {
        format: "CODE128",
        displayValue: false,
        text: `
          ${textToShow?.[keyToShow[0]]}\t${textToShow?.[keyToShow[1]]}\t${textToShow?.[keyToShow[2]]}\n${textToShow?.[keyToShow[3]]}\t${textToShow?.[keyToShow[4]]}\t${textToShow?.[keyToShow[5]]}\t${
          textToShow?.[keyToShow[6]]
        }
        `,
      });
      canvas.style.margin = "0";
      canvas.style.padding = "0";
      canvas.style.border = "none";
      canvas.style.width = "35%";
      canvas.style.height = "50px";

      const containerDiv = document.createElement("div");
      containerDiv.style.display = "flex";
      containerDiv.style.flexDirection = "column";
      containerDiv.style.alignItems = "center";
      containerDiv.style.justifyContent = "center";
      containerDiv.style.fontWeight = "900";
      containerDiv.style.fontFamily = "monospace";
      containerDiv.style.padding = "3px";

      containerDiv.appendChild(canvas);

      const innerDiv = document.createElement("div");
      innerDiv.style.breakAfter = "page";
      innerDiv.style.fontSize = "18px";
      innerDiv.style.display = "flex";
      innerDiv.style.alignItems = "center";
      innerDiv.style.flexDirection = "column";

      const paragraph1 = document.createElement("p");
      paragraph1.style.margin = "2px 0";
      paragraph1.textContent = `${this.datePipe.transform(textToShow?.[keyToShow[0]], "dd-MMM-yyyy")}  ${textToShow?.[keyToShow[1]]}  ${textToShow?.[keyToShow[2]]}`;

      const paragraph2 = document.createElement("p");
      paragraph2.style.margin = "2px 0";
      paragraph2.textContent = `${textToShow?.[keyToShow[3]]}  ${textToShow?.[keyToShow[4]]}  ${textToShow?.[keyToShow[5]]} ${textToShow?.[keyToShow[6]]}`;

      innerDiv.appendChild(paragraph1);
      innerDiv.appendChild(paragraph2);

      containerDiv.appendChild(innerDiv);

      printWindow.document.body.appendChild(containerDiv);

      allChild.push(containerDiv);
    }
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    allChild.map((containerDiv: any) => {
      printWindow.document.body.removeChild(containerDiv);
    });
    printWindow.close();
  }

  async openCsvModal() {
    const modal = await this.ModalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.modalContent,
        class: "scrool",
      },
      cssClass: "packetInwardModal",
    });
    await modal.present();
  }

  async getAllCompanyData() {
    try {
      
      const data = await this.http.getDataWithoutPayloadForGet(true, "tbl-company/findAll").toPromise();
      
      if (!!data) {
        this.companyOptions = data?.data;
      }
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  async onCompanySelection(event) {
    if (!!event) {
      try {
        
        const data = await this.http.getDataWithoutPayloadForGet(true, `job-process/getJobProcessOnBasisOfCompany?partyId=${event}`).toPromise();
        
        if (!!data) {
          this.jobOptions = data?.data;
        }
      } catch (error) {
        console.error("Error fetching worker data:", error);
      }
    }
  }

  async onJobSelection(event) {
    if (!!event) {
      try {
        
        const data = await this.http.getDataWithoutPayloadForGet(true, `job-process-account/getJobProcessAccountNo?jobProcessID=${event}`).toPromise();
        
        if (!!data) {
          this.accountOptions = data?.data;
        }
      } catch (error) {
        console.error("Error fetching worker data:", error);
      }
    }
  }
}
