import { Component, OnInit } from "@angular/core";
import Query from "devextreme/data/query";
import { Store, select } from "@ngrx/store";
import { getAllRolesOfUser, getAllTrybeDepartments } from "../masterDataStore/masterData.selector";
import { LoaderService } from "../services/loader/loader.service";
import { ConfigService } from "../services/config/config.service";
import { AlertController, ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { HttpService } from "../services/http/http.service";
import { AppState } from "../reducers";
import { Subscription } from "rxjs";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import CustomStore from "devextreme/data/custom_store";
import { HomeActions } from "../home/state/home.action-type";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import { ScannerinputComponent } from "../components/scannerinput/scannerinput.component";
import { authUser } from "../authStore/auth.selector";

@Component({
  selector: "app-assignsubmanager",
  templateUrl: "./assignsubmanager.component.html",
  styleUrls: ["./assignsubmanager.component.scss"],
})
export class AssignsubmanagerComponent implements OnInit {
  selectRoles: any = [];
  defaultDeptSelection;
  selectDepartment: any = [];
  tranferGridColumns: any;
  selectedRows: any = [];
  selectedRow: any = [];
  transferGridpageSize = 100;
  private scannerComponet!: ScannerinputComponent;
  transferGridPage = "Tranfer";
  masterStoneTransferData = [];
  pageNumber: number = 0;
  rowsIndex: any;

  inputBarcodeOfATransferStone: any = "";
  stonesForTransfer: any = [];
  gridData: any = [];
  currUserDeptId: any;
  pageInfo = { limit: 20, page: 0 };
  gridDataCount: number = 0;
  transferGridDataCount: number = 0;
  private subscriptions: Subscription[] = [];
  modifiedGridObject: any;

  deptDropdownData: any = {
    //department Dropdown
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],

    propertName: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownData: any = {
    name: "Role",
    placeText: "Select Role",
    options: [],
    selectedData: this.selectRoles,
    propertName: "roleName",
    modeFlag: "single", //single/multiple
  };
  public transferGridPageInfo: any = {
    limit: this.transferGridpageSize,
    page: 0,
  };
  refreshGrid: boolean = false;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.store.pipe(select(authUser)).subscribe(user => {
      if (!!user) {
        console.log(user);
        this.currUserDeptId = user.departmentId;
      }
    });

    this.store.dispatch(masterDataActions.getRolesForUser());
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.tranferGridColumns = [
      { name: "Lot No", prop: "lotNo" },
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      // { name: 'Status', prop: 'historyStatus' },
      { name: "InwardDate", prop: "inDate" },
    ];
    this.pageInfo = this.transferGridPageInfo;
    this.getFreshDataAndSetPaginationToGrid(); //get fresh data from server and update grid Data of tranfer grid and return grid
    this.httpService.currenttranferReturnAssignGridData$.subscribe(data => {
      if (data) {
        console.error("currenttranferReturnAssignGridData$");
        console.log(data);
        //transfer and return grid data///get fresh data from server and update grid Data of tranfer grid and return grid
        this.gridDataCount = data.countOfTotalPackets;
        this.gridData = data.packetData;
      }
    });

    this.subscriptions.push(
      this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
        if (data) {
          console.log(data);
          this.defaultDeptSelection = data.filter(obj => obj.id === this.currUserDeptId);
          this.deptDropdownData.selectedData = this.defaultDeptSelection;

          // this.deptDropdownData['options'] = data;
        }
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(getAllRolesOfUser)).subscribe(data => {
        if (data) {
          this.dropDownData["options"] = data;
        }
      })
    );
  }

  assignManagerPacketScanned(evt) {
    console.log(evt);
  }

  clearTranferInputBarcode() {
    console.log("empty");
  }

  onDepartmentSelection(ev) {
    let selected = this.defaultDeptSelection;
    if (selected.length > 0) {
      this.deptDropdownData["selectedData"] = selected; //ev.name;
      this.deptDropdownData["selectedObj"] = selected[0]; //ev;
      this.selectDepartment = selected; //ev.name;
      let selDepartment = this.deptDropdownData["options"].filter(d => {
        return d.id == selected[0].id;
      });
      this.selectRoles = [];
      if (!!this.selectRoles) {
        this.selectRoles = this.dropDownData["options"].filter(d => {
          return d.id == selDepartment[0].receiveRole;
        });
      }
      this.dropDownData["selectedData"] = this.selectRoles;
    } else {
      this.selectRoles = [];
      this.selectDepartment = selected; //ev.name;
      this.deptDropdownData["selectedObj"] = {};
      this.dropDownData["selectedData"] = this.selectRoles;
    }
  }

  onSelectionChanged(event: any) {
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.row;
    console.log(this.selectedRows);
  }

  async dropDownSelection(ev) {
    console.log("dropDownSelection....");
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let selected = ev.arrayList;
    if (property == this.deptDropdownData["propertName"]) {
      // department dropdown
      this.onDepartmentSelection(ev);
    } else if (property == this.dropDownData["propertName"]) {
      // role dropdown event
      if (selected.length > 0) {
        this.selectRoles = selected;
      } else {
        this.selectRoles = selected;
      }
    }
    console.log(this.selectRoles);
  }

  async getFreshDataAndSetPaginationToGrid() {
    console.log("setPaginationToDxGrid- Grid Data");
    //tranfer Grid, ReturnGrid common function for both grid.
    let payload = null;
    if (!!this.inputBarcodeOfATransferStone) {
      payload = { packetId: this.inputBarcodeOfATransferStone }; //toFiltter grid Data by barcodeInputs
    }
    this.modifiedGridObject = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo["limit"];
          } else {
            this.pageInfo["limit"] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.pageInfo["page"] = this.pageNumber;
          let response: any = await this.httpService.fetchWithMeGridData(this.pageInfo, payload);
          console.log(response, this.gridData, this.gridDataCount);
          if (response.statusCode == 200) {
            this.httpService.updateTranferReturnAssignGridData(response.data);
          } else {
            console.error(response);
          }
          return {
            data: Query(this.gridData).toArray(),
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        } else {
          return {
            data: this.gridData,
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount,
          };
        }
      },
    });
  }

  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case "paging.pageSize":
        let size = evt.value;
        this.pageInfo["limit"] = size;
        this.getFreshDataAndSetPaginationToGrid();
        break;
      case "paging.pageIndex":
        let pageIndex = evt.value;
        this.pageInfo["page"] = pageIndex;
        this.getFreshDataAndSetPaginationToGrid();
        break;
    }
  }

  onTransferPacketSuccess(afterSucces) {
    this.getFreshDataAndSetPaginationToGrid(); //for Refreshing Data of Transfer grid/return Grid
    let limit = { limit: 50, page: 0 };
    this.store.dispatch(HomeActions.getReceivedPackets(limit));
    // this.store.dispatch(HomeActions.getOutgoingPackets(limit));
    this.store.dispatch(HomeActions.getPacketCount());
    this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
    this.deptDropdownData["selectedData"] = [];
    this.dropDownData["selectedData"] = [];
    this.selectRoles = [];
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);

    afterSucces();
  }

  async onTransferStones() {
    console.log(this.deptDropdownData);

    let totalCts = 0;
    let pktIds = this.selectedRows.map(x => {
      totalCts += x.cts;
      return x.id;
    });

    console.log("%conTransferStones click", "color:green:font-weight:bold");

    // this.scannerComponet.onResetForm();
    this.refreshGrid = true;
    let selPacketID = this.selectedRows.map(p => p.id);
    let selDepartmentID = !!this.deptDropdownData["selectedData"] ? this.deptDropdownData["selectedData"][0].id : null;
    let selRoleID = !!this.selectRoles[0] ? this.selectRoles[0].id : null;
    let transferData = {
      toDepartment: selDepartmentID,
      toRole: selRoleID,
      listOfPacketIds: selPacketID,
    };
    console.log(transferData);
    if (selPacketID.length > 0 && !!selDepartmentID && !!selRoleID) {
      // this.store.dispatch(transferStone({ postData: transferData }));//Due to Pagination we face issue(GridUpdate), so we are not using NRX Store here

      this.httpService.transferPacket(transferData).subscribe(res => {
        if (res) {
          let voucherData = {
            data: res.data,
          };
          if (res.statusCode === 200) {
            this.onTransferPacketSuccess(async () => {
              const modal = await this.modalController.create({
                component: TrybemodalComponent,
                componentProps: {
                  message: res.message,
                  data: voucherData,
                },
                cssClass: "modalprint",
              });
              modal.onDidDismiss().then(dataReturned => {
                if (dataReturned !== null) {
                  console.log(dataReturned);
                }
              });
              return await modal.present();
            });
          } else {
          }
          this.httpService.getErrorAndDisplayIt(res);
        }
      });
    }
  }

  async onissueStonesClick() {
    console.log(this.deptDropdownData);

    console.log("onTransferStonesClick");
    let payload = {
      packetIds: this.selectedRows.map(p => p.id),
      nextDept: !!this.deptDropdownData["selectedObj"] ? this.deptDropdownData["selectedObj"].id : null,
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "users/fetchAllDepartmentByAppName/trybe", payload);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log(response.data);
      let data: any = Object.prototype.toString.call(response.data).replace("[object ", "");
      console.log(data);
      if (data.toLowerCase().includes("object")) {
        console.log("object");
        this.configService.showToast("success", response.data.msg);
        this.httpService.getErrorAndDisplayIt(response);
      } else {
        console.log("Array");
        // let selectedElment= response.data[0];
        this.onTransferStones();
      }
    } else {
      console.error(response);
    }
  }
}
