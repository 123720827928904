import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import { Subscription } from "rxjs";
import { authUser } from "src/app/authStore/auth.selector";
import { getAllTrybeDepartments } from "src/app/masterDataStore/masterData.selector";
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";

@Component({
  selector: "app-user-management-popups",
  templateUrl: "./user-management-popups.component.html",
  styleUrls: ["./user-management-popups.component.scss"],
})
export class UserManagementPopupsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  private subscriptions: Subscription[] = [];
  department: any;
  deptList: any;
  constructor(private modalController: ModalController, private configService: ConfigService, public httpService: HttpService, private store: Store<AppState>, public loaderService: LoaderService) {}
  userData: any;
  subScription = new Subscription();
  username: any;
  password: any;
  receiveRoles:any;
  roleList: any[];
  selectedManagerRoles: any;
  selectedStockControllerRole: any | null = null;
  selectedDepartment: any | null = null;
  isStockControllerActive: boolean = true;
  gradeList: any[];
  selectedGradeList: any;
  isAutoReceive: boolean = false;
  isManagerActive: boolean = true;
  isVariablePay: boolean = true;
  selectedTab = "";
  stockControllerEditMode: boolean = false;
  managerEditMode: boolean = false;
  workerEditMode: boolean = false;

  isReadOnly: boolean = false;

  selectedRoles: any;
  isWorkerActive: boolean = true;

  workerDataArray: any[] = [];
  managerDataArray: any[] = [];

  ngOnInit() {
    const subs = this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        this.userData = d;
      }
    });

    this.subScription.add(subs);
    this.roleList = this.data?.roles;
    this.gradeList = this.data?.grades;
    this.department = this.data?.department;
    this.selectedTab = this.data?.selectedTab;
    this.isReadOnly = this.data?.isReadOnly;
    this.receiveRoles = this.data?.receiveRoles; 

    if (!this.data.rowData) return;
    const { rowData } = this.data;
    switch (this.data.forPage) {
      case "stock controller":
        this.username = rowData?.fullName;
        this.isStockControllerActive = rowData.isActive ? true : false;
        this.password = rowData?.password || "";
        this.selectedStockControllerRole = rowData?.roleId;
        this.stockControllerEditMode = true;
        break;
      case "manager":
        this.username = rowData?.fullName;
        this.isManagerActive = rowData.isActive ? true : false;
        this.password = rowData?.password || "";
        this.selectedManagerRoles = rowData?.roleId;
        this.isVariablePay = rowData?.isVariablePay;
        this.department = rowData?.departmentId[0];
        this.managerEditMode = true;
        break;
      case "worker":
        this.username = rowData?.fullName;
        this.isWorkerActive = rowData.isActive ? true : false;
        (this.isAutoReceive = rowData?.isAutoReceive), (this.isVariablePay = rowData?.isVariablePay), (this.password = rowData?.password || "");
        this.selectedRoles = rowData?.roleId;
        this.department = rowData?.departmentId[0];
        this.selectedGradeList = rowData?.gradeId;
        this.workerEditMode = true;
        break;
    }
    this.fetchTrybeDepartments();
  }

  fetchTrybeDepartments() {
    this.httpService.fetchTrybeDepartments([]).subscribe(
      (res: any) => {
        this.deptList = res?.data || [];
      },
      (e: any) => {}
    );
  }
  onStockControllerClose() {
    this.closeModal(null);
  }

  validateStockController() {
    let isValid = true;
    let msg = "";

    if (!this.username) {
      msg += "User Name is Required \n";
    }
    if (!this.password) {
      msg += "Password is Required \n";
    }
    if (!this.selectedStockControllerRole) {
      msg += "Role is Required \n";
    }
    if (msg) {
      this.configService.showToast("error", msg);
      return !isValid;
    }
    return isValid;
  }

  validateManager() {
    let isValid = true;
    let msg = "";

    if (!this.username) {
      msg += "User Name is Required \n";
    }
    if (!this.password) {
      msg += "Password is Required \n";
    }
    if (!this.selectedManagerRoles.length) {
      msg += "Role is Required \n";
    }
    if (msg) {
      this.configService.showToast("error", msg);
      return !isValid;
    }
    return isValid;
  }

  validateWorker() {
    let isValid = true;
    let msg = "";

    if (!this.username) {
      msg += "User Name is Required \n";
    }
    if (!this.password) {
      msg += "Password is Required \n";
    }
    if (!this.selectedRoles.length) {
      msg += "Role is Required \n";
    }
    if (msg) {
      this.configService.showToast("error", msg);
      return !isValid;
    }
    return isValid;
  }

  closeModal(data: any) {
    this.modalController.dismiss(data);
  }

  addStockController() {
    if (!this.validateStockController()) return;
    let departmentArray = Array.isArray(this.department) ? this.department : [this.department];
    let StockControllerPayload: any[] = [
      {
        fullName: this.username,
        password: this.password,
        departmentId: departmentArray,
        roleId: this.stockControllerEditMode ? this.selectedStockControllerRole : [...this.selectedStockControllerRole , this.receiveRoles.receiveRole ],
        isActive: this.isStockControllerActive ? 1 : 0,
        designation: "stock controller",
        company: [this.userData.companyId],
      },
    ];
    console.log("payload",StockControllerPayload)

    if (this.stockControllerEditMode) {
      StockControllerPayload[0] = { ...StockControllerPayload[0], id: this.data.rowData.id , departmentId : Array.isArray(this.data.rowData.departmentId) ? this.data.rowData.departmentId : [this.data.rowData.departmentId]  };
      this.onUserSave(StockControllerPayload);
      this.closeModal(StockControllerPayload);
      return;
    }
    this.onUserSave(StockControllerPayload);
  }

  addManager() {
    if (!this.validateManager()) return;
    let departmentArray = Array.isArray(this.department) ? this.department : [this.department];
    let managerPayload: any[] = [
      {
        fullName: this.username,
        departmentId: departmentArray,
        password: this.password,
        roleId: this.selectedManagerRoles,
        isActive: this.isManagerActive ? 1 : 0,
        isVariablePay: this.isVariablePay ? 1 : 0,
        designation: "manager",
        company: [this.userData.companyId],
      },
    ];
    if (this.managerEditMode) {
      managerPayload[0] = { ...managerPayload[0], id: this.data.rowData.id };
      this.onUserSave(managerPayload);
      this.closeModal(managerPayload);
      return;
    }
    this.onUserSave(managerPayload);
  }

  addWorker() {
    if (!this.validateWorker()) return;
    let departmentArray = Array.isArray(this.department) ? this.department : [this.department];
    let workerPayload: any[] = [
      {
        fullName: this.username,
        departmentId: departmentArray,
        password: this.password,
        roleId: this.selectedRoles,
        gradeId: this.selectedGradeList,
        isActive: this.isWorkerActive ? 1 : 0,
        isAutoReceive: this.isAutoReceive,
        isVariablePay: this.isVariablePay ? 1 : 0,
        designation: "worker",
        company: [this.userData.companyId],
      },
    ];
    // console.log(" add worker payload",workerPayload)
    if (this.workerEditMode) {
      workerPayload[0] = { ...workerPayload[0], id: this.data.rowData.id };
      this.onUserSave(workerPayload);
      this.closeModal(workerPayload);
      return;
    }
    this.onUserSave(workerPayload);
  }

  async onUserSave(payload) {
    let response = await this.httpService.postDataUsingPromise(true, `users/saveUpdateUser`, payload);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.configService.showToast("success", " User saved successfully");
      payload[0].id = response.data[0].id;
      this.closeModal(payload);
    } else {
      if (response.message.includes("User Found")) this.configService.showToast("error", "User already exist");
      else this.configService.showToast("error", "Something went wrong");
    }
  }

  ngOnDestroy() {
    this.subScription.unsubscribe();
  }
}
