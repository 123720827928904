import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { select, Store } from '@ngrx/store';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import CustomStore from 'devextreme/data/custom_store';
import Query from 'devextreme/data/query';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actions, authUser, getIfUserIsManager } from 'src/app/authStore/auth.selector';
import { issueVirtualPacket } from 'src/app/home/state/home.action';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { getAllRolesOfUser } from 'src/app/masterDataStore/masterData.selector';
//import { ReceivedStones } from 'src/app/models/incoming-stone.models';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ProcessActionComponent } from '../../processAction';
import { ScannerinputComponent } from '../../scannerinput/scannerinput.component';
import { IssueAction } from '../issue/state/issue.action.type';
import { getActionsByVirtualPacketData, getUsersByVirtualActionData } from '../issue/state/issue.selector';

@Component({
  selector: 'app-assign-virtual-tasks',
  templateUrl: './assign-virtual-tasks.component.html',
  styleUrls: ['./assign-virtual-tasks.component.scss'],
})

export class AssignVirtualTasksComponent implements ProcessActionComponent {
  @ViewChild('auto') auto: AutocompleteComponent;

  @Input() data: any;
  @Input() actionName: any;
  @Input() toRole: any;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  columns = [];
  loggedinUser$: Observable<any>;
  issueGridDataSource: any = [];
  masterData = [];
  listOfActions = [];
  selectedRows: any = [];
  selectedRow: any = {};
  listOfActionsByStone: any = [];
  private unsubscribe$: Subject<any> = new Subject<any>();
  isStoneSelected: boolean = false;
  actionUsers: any = [];
  actionId: number;
  defaultUserRoleRadioValue: string = "User";
  // actionName:any;
  userId: number;
  machineId: number;
  deptId: any;
  test: boolean = true;
  actions: any = [];
  machinesByUser: any;
  currSelectedMachine: any = [];
  sendData: any = {};
  initialValue = "";
  openModalActionId: any;
  isManager: boolean = false;
  openmodal: boolean = false;
  public selectedUser: string = '';
  public keyword = 'fullName';
  igSuscription: Subscription;
  igSuscription2: Subscription;
  igSuscription3: Subscription;
  pageSize = 50;
  page = "Issue"
  roledata = [];
  isModal$: Observable<any>;
  selectedProcess: any;
  clearData: string = '';
  selectedPacketIds = [];
  dataFromModal = {};
  barcodeInputs: any = "";
  private subscriptions: Subscription[] = [];
  processNotes: any = {};
  refreshGrid: boolean = false;
  actionInfo: any;
  proActionName: string = "";
  processNote: string = "";
  processTitle: string = "Process";
  selectRoles: any = [];
  selectDepartment: any = [];
  roleDropDownData: any = {
    name: 'Role',
    placeText: 'Select Role',
    options: [],
    selectedData: this.selectRoles,
    propertName: 'roleName',
    modeFlag: 'single', //single/multiple
  };
  pageNumber: number = 0;
  public assignTaskGridPageInfo: any = {
    limit: this.pageSize,
    page: 0
  };
  gridData: any = [];
  gridDataCount: number = 0;
  modifiedGridObject: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private storage: Storage
  ) {

    // this.subscriptions.push(this.store.pipe(select(getReceivedPacketsData)).subscribe(data => {
    //   if (data) {
    //     this.issueGridDataSource = data;
    //     this.masterData = data;
    //   }
    // }));

    this.loggedinUser$ = this.store.pipe(select(authUser))
    this.columns = [
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      { name: "Pkt Grade", prop: "pktGrade" },
      { name: "Lot No", prop: "lotNo" },
      // { name: "Status", prop: "pktStatus" },
      { name: "PredLimit", prop: "predLimit" },
      { name: "InwardDate", prop: "inDate" },
      { name: "OutwardDate", prop: "outDate" },
      { name: "Location", prop: "location" }
    ]
  }

  ngOnInit() {
    // let limit = { limit: this.pageSize, page: 0 };
    this.getFreshDataAndSetPaginationToGrid();
    this.httpService.currenttranferReturnAssignGridData$.subscribe(data => {
      if (data) {
        console.error('currenttranferReturnAssignGridData-ASSIGN$');
        console.log(data);
        //transfer and return grid data///get fresh data from server and update grid Data of tranfer grid and return grid
        this.gridDataCount = data.countOfTotalPackets;
        this.gridData = data.packetData;
      }
    });
    // this.store.dispatch(HomeActions.getReceivedPackets({ limit }));
    this.store.pipe(select(actions)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      if (data) {
        this.listOfActions = data;
      }
    });
    this.store.pipe(select(authUser)).subscribe(user => {
      if (!!user) {
        console.log(user.listOfActions);
        this.openModalActionId = user.listOfActions;
      }
    })
    this.store.pipe(select(getIfUserIsManager)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      if (data) {
        console.log(data);
        this.isManager = data;
      }
    });
    this.store.dispatch(masterDataActions.getRolesForUser());
    // this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.subscriptions.push(
      this.store.pipe(select(getAllRolesOfUser)).subscribe((data) => {
        if (data) {
          this.roleDropDownData['options'] = data;
        }
      })
    );

    this.loggedinUser$ = this.store.pipe(select(authUser));
    this.populateActions();
    this.populateUsers();
    this.selectedProcess = "";

  }

  onSelectionChanged(event) {
    this.machinesByUser = [];
    // if (this.actionUsers.length != 1) {
    //   this.selectedUser = '';
    // }
    // this.formJson=[];
    // console.log(event)
    this.selectedRows = event.selectedRowsData;
    this.selectedRow = event.selectedRowsData[0];
    if (event.selectedRowsData.length > 0) {
      this.isStoneSelected = true;
      // this.startWith = this.selectedRow['dataAsPer'];
    } else {
      this.isStoneSelected = false;

    }
    let pktIds = this.selectedRows.map(x => x.id);
    //changing
    console.log(pktIds);
    this.selectedPacketIds = pktIds;

    //this.openmodal = false;
    console.log(this.isStoneSelected);


    if (event.selectedRowsData.length > 0) {
      this.isStoneSelected = true;

    } else {
      this.isStoneSelected = false;
      this.actionUsers = [];
      this.selectedUser = "";
      // this.selectAction(undefined);


    }
    this.userId = this.isStoneSelected ? this.userId : null;
    if (this.isStoneSelected && pktIds.length !== 0) {
      this.store.dispatch(IssueAction.fetchNextActionForAssignVirtualTasks({ packetIDs: pktIds }));
      this.populateActions();
    } else {
      //this.store.dispatch(IssueAction.unSelectionOfPacket());
      // this.populateActions();

    }
    // this.populateUsers();
    console.log(this.selectedRow);
    console.log(this.selectedRows);
  }
  onUserRoleRadioSelectionChange(evt: any) {
    console.log('onUserRoleRadioSelectionChange');
    console.log(evt);
    if (this.defaultUserRoleRadioValue && this.defaultUserRoleRadioValue == 'Role') {
      this.selectedUser = "";
      this.userId = null
    } else {
      this.selectRoles = [];
      this.populateUsers();
    }
  }
  autoSelectSingleValues(data: any) {
    this.listOfActionsByStone = data.map((item) =>
      Object.assign({}, item, { isSelected: true })
    )
    let actionId = this.listOfActionsByStone[0].id
    this.selectAction(actionId);
  }

  populateActions() {
    console.log('....populateActions....');
    if (!!this.igSuscription) {
      this.igSuscription.unsubscribe();
    }
    this.igSuscription = this.store.pipe(select(getActionsByVirtualPacketData)).subscribe(data => {
      if (data) {
        console.log(data);
        this.actions = data.map((x: any) => x.actionName);
        // let except = ['With Me', 'Packet Tracking', 'Fluorescence', 'Work Quality Master', 'Rough Inward'];
        // data = data.filter((x: any) => except.indexOf(x.actionName) === -1);
        // this.listOfActionsByStone = data;
        console.log(this.actions);
        if (this.actions.length === 1) {
          this.selectedProcess = this.actions[0];
        }
        else {
          this.selectedProcess = [];
        }

        if (data.length === 1) {
          this.autoSelectSingleValues(data);
        } else if (data.length > 1) {
          this.listOfActionsByStone = data.map((item) => {
            if (!item.hasOwnProperty('isSelected')) {
              return Object.assign({}, item, { isSelected: false });
            } else {
              return item;
            }
          }
          )
          this.selectAction(null);
        } else {
          this.selectAction(null);
        }
      }
    });
  }

  async selectAction(actionId) {
    console.log('selectAction');
    // this.openmodal = false;
    // if (!!actionId) {
    //   let x = this.openModalActionId.filter((element: any) => {
    //     return element.id == actionId;
    //   })
    //   if (x.length > 0) {
    //     this.openmodal = true;
    //   }
    //   else {
    //     this.openmodal = false;
    //   }
    // }
    this.machinesByUser = [];
    this.actionId = actionId;
    console.log(actionId);

    this.listOfActionsByStone = this.listOfActionsByStone.map((item) => {
      let l = { ...item }
      if (!!l.hasOwnProperty('isSelected') && l.id == actionId) {
        l.isSelected = true
      } else {
        l.isSelected = false
      }
      return l;
    });
    let pktIds = this.selectedRows.map(x => x.id);
    let packets = {
      "listOfPacketIds": pktIds
    };
    this.selectedUser = ''
    this.store.dispatch(
      IssueAction.getUsersByVirtualAction({ actionId: actionId, packetIDs: packets }),
    );
    // this.showProcessNotes(actionId);

  }

  onClickClearSelection() {
    console.log('onClickClearSelection');
    this.machinesByUser.map(m => {
      console.log(m)
      if (m.id) {
        m.isSelected = false;
      }
      return m
    });
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }


  populateUsers() {
    console.log('--')
    //this.selectedUser = '' ;
    // this.selectEvent('')
    // debugger
    if (!!this.igSuscription) {
      this.igSuscription.unsubscribe();
    }
    this.igSuscription2 = this.store.pipe(select(getUsersByVirtualActionData)).subscribe((d) => {
      if (d) {
        this.actionUsers = JSON.parse(JSON.stringify(d));
        this.selectedUser = "";
        // this.auto.clear();     
        console.log(this.actionUsers);
        if (this.actionUsers.length > 0 && this.actionUsers.length === 1) {
          let userData = this.actionUsers[0];
          this.selectedUser = userData.fullName;
          //  this.selectedProcess=this.listOfActionsByStone[0].actionName;
          // console.log(this.selectedProcess);

          userData['disabled'] = true;
          this.selectEvent(userData);
          this.sendData['toUser'] = userData;
        } else if (this.actionUsers.length > 1) {
          this.actionUsers = this.actionUsers.map((item) => {
            item['disabled'] = false;
            if (!item.hasOwnProperty('isSelected')) {
              return Object.assign({}, item, { isSelected: false });
            } else {
              return item;
            }
          });

          let selectedUsers = this.actionUsers.filter(a => a.isSelected == true);
          if (selectedUsers.length == 1) {
            this.selectedUser = selectedUsers[0].fullName;
            console.log(this.selectedUser);
            this.selectEvent(selectedUsers[0]);
          } else {
            // this.auto.clear();
            this.selectedUser = "";
          }
        }
      }
    });
  }
  selectUser(userId) {
    this.actionUsers.map(a => a.isSelected = false)
    this.userId = userId;
    this.actionUsers.map(a => {
      if (a.id == userId) {
        a.isSelected = true
      }
      return a
    })
  }


  selectEvent(item: any) {
    // for user
    console.log("selectEvent", item)
    item.isSelected = false
    // do something with selected item
    this.userId = item.id;
    this.selectedUser = item.fullName;
    console.log(this.selectedUser);
    this.machinesByUser = item.machineList;
    console.log(this.machinesByUser);
    console.log(this.machinesByUser.length);
    this.machinesByUser.map((m: any) => m.isSelected = false);
    this.currSelectedMachine = [];
    this.sendData['toUser'] = item;
    //debugger
    // this.processService.setActionName(
    //   item.actionName,
    //   item.id,
    //   !!item.voucherType ? item.voucherType : '',
    //   item.listOfActionPermissions
    // )
    // this.router.navigate(['actionsWindow'])
    // this.showMachines();
  }

  selectEventProcess(item: any) {
    console.log("selectEvent", item)
    console.log(this.actions);

  }

  showMachines() {
    if (this.selectedUser && this.machinesByUser.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  selectedMachine(id: any) {
    console.log('selectedMachine');
    console.log(id);
  }
  issuePacket() {
    let selRoleID = !!this.selectRoles[0] ? this.selectRoles[0].id : undefined;

    let pktIds = this.selectedRows.map(x => x.id);
    console.log(pktIds);

    let issueObj = {
      // packetId: pktIds[0],
      // actionId: this.actionId,
      // machineId: this.machineId
    }
    if (pktIds.length > 0) {
      issueObj['packetId'] = pktIds;
    }

    if (!!this.actionId) {
      issueObj['actionId'] = this.actionId;
    }

    if (this.defaultUserRoleRadioValue == 'Role' || selRoleID) {
      issueObj['toRole'] = selRoleID;
    }
    else if (this.defaultUserRoleRadioValue == 'User') {
      issueObj['toUserId'] = this.userId
    } else {
      console.log('---')
    }
    console.log(this.selectedRows);
    console.log(issueObj);
    this.store.dispatch(issueVirtualPacket({ packetDetails: issueObj }));
    // this.httpService.issueVirtualPacket(issueObj).subscribe(res=>{
    //   this.httpService.getErrorAndDisplayIt(res);
    //   if (res && res.statusCode===200) {

    //   } else {

    //   }
    // });
    // ).pipe(
    //     map(async res => {
    //         this.httpService.getErrorAndDisplayIt(res);
    this.reset();
    this.clearData = "";
  }

  reset() {
    // this.scannerComponet.onResetForm();
    this.barcodeInputs = '';
    this.userId = null;
    this.actionUsers = [];
    this.selectedUser = "";
    this.selectedRow = null;
    this.roleDropDownData['selectedData'] = [];
    this.selectedProcess = "";
    this.selectedRows = [];
    this.machinesByUser.map((m) => m.isSelected = false);
    this.actionId = null;
    this.listOfActionsByStone.map((l) => l.isSelected = false);
    this.isStoneSelected = false
    if (this.selectedRows.length === 0) {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 500);
    }

  }

  async dropDownSelection(ev) {
    console.log('dropDownSelection....');
    console.log(ev);

    let property = ev.property;
    let selected = ev.arrayList;
    if (property == this.roleDropDownData['propertName']) {
      // role dropdown event
      if (selected.length > 0) {
        this.selectRoles = selected;
      } else {
        this.selectRoles = selected;
      }
    }
    console.log(this.roleDropDownData.selectedData);
    this.roledata = this.roleDropDownData.selectedData;
    console.log(this.roledata);
    console.log(this.selectRoles);
  }
  async getFreshDataAndSetPaginationToGrid() {
    console.log('setPaginationToDxGrid- Grid Data');
    //tranfer Grid, ReturnGrid
    let payload = null;
    // if (!!this.barcodeInputs) {
    //   payload = { "packetId": this.barcodeInputs.trim() };  //toFiltter grid Data by barcodeInputs
    // }
    this.modifiedGridObject = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.assignTaskGridPageInfo['limit'];
          } else {
            this.assignTaskGridPageInfo['limit'] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.assignTaskGridPageInfo['page'] = this.pageNumber;
          console.log(this.assignTaskGridPageInfo);
          let response: any = await this.httpService.fetchWithMeGridData(this.assignTaskGridPageInfo, payload);
          console.error('------------ASIGN');
          console.log(response, this.gridData, this.gridDataCount);
          if (response.statusCode == 200) {
            this.httpService.updateTranferReturnAssignGridData(response.data);
          } else {
            console.error(response);
          }
          return {
            data: Query(this.gridData)
              .toArray(),
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount
          };
        } else {
          return {
            data: this.gridData,
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount
          }
        }
      }
    });
  }
  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case 'paging.pageSize':
        let size = evt.value;
        this.assignTaskGridPageInfo['limit'] = size;
        this.getFreshDataAndSetPaginationToGrid();
        break;
      case 'paging.pageIndex':
        let pageIndex = evt.value;
        this.assignTaskGridPageInfo['page'] = pageIndex;
        this.getFreshDataAndSetPaginationToGrid();
        break;
    }
  }

  ngOnDestroy() {
    if (!!this.igSuscription) {
      this.igSuscription.unsubscribe();
    }
    if (!!this.igSuscription2) {
      this.igSuscription2.unsubscribe();
    }

  }
}
