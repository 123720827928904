import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { select, Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";
import themes from "devextreme/ui/themes";
import { Observable, Subscription } from 'rxjs';
import { authUser } from 'src/app/authStore/auth.selector';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { getIncomingPacketsData, getIncomingPacketsLen } from 'src/app/home/state/home.selector';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { IncomingStones } from 'src/app/models/incoming-stone.models';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ScannerinputComponent } from '../../scannerinput/scannerinput.component';
import { TrybemodalComponent } from '../../trybemodal/trybemodal.component';
import * as moment from "moment";
import { IonInfiniteScroll } from '@ionic/angular';
import {
  getAllTrybeDepartments
} from 'src/app/masterDataStore/masterData.selector';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-stone-time-tracking-report',
  templateUrl: './stone-time-tracking-report.component.html',
  styleUrls: ['./stone-time-tracking-report.component.scss'],
})
export class StoneTimeTrackingReportComponent implements OnInit {
  columns;
  loggedinUser$: Observable<any>;
  // fromDate = moment().startOf('month').format('YYYY-MM-DD');
  fromDate = moment('01/01/2021').format('YYYY-MM-DD')
  toDate = moment().format('YYYY-MM-DD')
  userData: any;
  public pageSize: any = 20;
  pageNumber = 0;
  reportData = [];
  noRecords:boolean = false;
  refreshGrid: boolean = false;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  departments = [];
  ids = [];
  selectedDepatment;
  packetId = '';

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private storage: Storage,
    private loaderService:LoaderService,
    private modalController: ModalController,
    private navCtrl: NavController,
  ) {
    this.columns = [
      { name: "Packet ID", prop: "packetId" },
      { name: "From Dept", prop: "fromDept" },
      { name: "To Dept", prop: "toDept" },
      { name: "Total Days", prop: "totalNoOfDays" },
      // {name:"FromDate", prop:"fromDate"}
    ]

    this.store.dispatch(masterDataActions.getTrybeDepartments({packetIds:null}));
    this.store.pipe(select(getAllTrybeDepartments)).subscribe((data) => {
      if (data) {

        this.departments = data
      }
    })
  }

  async dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let selected = ev.arrayList;
    console.log(idList)
    if (idList.length > 0) {
      this.selectedDepatment = idList[0]
    } else {
      this.selectedDepatment;
    }
  }

  async ngOnInit() {

    this.loggedinUser$ = this.store.pipe(select(authUser));
    console.log(themes.current());

    this.store.pipe(select(authUser)).subscribe(userData => {
      console.log('......authUser...');
      console.log(userData);
      this.userData = userData
      if (userData != null) {

      }
    })

    await this.fetchReport(null)
  }

  async resetPagination() {
    this.pageNumber = 0
  }

  async fetchReport(scroll) {
    this.noRecords = false
    let obj = {
      "fromDate": this.fromDate,
      "toDate": this.toDate
    }

    if (this.selectedDepatment) {
      obj["dept"] = this.selectedDepatment
    }

    if (this.packetId) {
      this.packetId = this.packetId.trim()
      obj["packetId"] = this.packetId
    }

    let userId = this.userData.userId || this.userData.id;
    // await this.httpService.showLoader();
    

    let res: any;
    res = await this.httpService.getHowMuchTimePacketHasTakenInEachDept(obj, this.pageSize, this.pageNumber)
    
    // await this.httpService.dismissLoader()
    if (res.data) {
      let resData = res.data.reportData
      if (resData.length == 0) {
        this.reportData = [];
        this.noRecords = true
        if (scroll) {
          scroll.target.disabled = true;
        }
      } else {
        if (scroll) {
          Array.prototype.push.apply(this.reportData, resData);
          scroll.target.complete();
        } else {
          this.reportData = resData
        }
      }
      if (this.reportData.length == 4000) {
        scroll.target.disabled = true;
      }
    } else {
      scroll.target.disabled = true;
    }

  }

  async reset() {
    this.fromDate = moment().startOf('month').format('YYYY-MM-DD');
    this.toDate = moment().format('YYYY-MM-DD')
    this.selectedDepatment = null;
    this.ids = [];
    this.packetId = ''
    this.fetchReport(null)
  }

  infiniteScrollFn(event) {
    this.pageNumber += 1;
    console.log(this.pageNumber)
    console.log("infiniteScroll", event);
    setTimeout(() => {
      console.log('Done');
      event.target.complete();

      this.fetchReport(event)
    }, 500);
  }
}
