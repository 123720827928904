import { createAction, props } from "@ngrx/store";
import { MyVirtualTask } from "./my-virtual-task.data";


export const fetchMyVirtualTasks = createAction(
    '[myVirtualTask] Fetch my virtual task',
    props<{ api:string, Payload: any }>()
);

export const saveMyVirtualTasks = createAction(
    '[myVirtualTask] save my virtual task to store',
    props<{ myVirtualtask: MyVirtualTask }>()
);
export const deleteAssignedTask = createAction(
    '[myVirtualTask] remove virtual assigned task',
    props<{virtualId: any }>(),
);
export const fetchFilterMyVirtualTasks = createAction(
    '[myVirtualTask] Fetch filter my virtual task',
    props<{ filterapi:string, filterPayload: any }>()
);

export const saveFilterMyVirtualTasks = createAction(
    '[myVirtualTask] save filter my virtual task to store',
    props<{ myFilterVirtualtask: MyVirtualTask,api:any }>()
);