import { Component, OnInit, TemplateRef } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss'],
})
export class DynamicModalComponent implements OnInit {

  constructor(private navParams:NavParams) {
   }

   template:TemplateRef<any>
   class:string=''
  ngOnInit() {
    this.template = this.navParams.get('template')
    this.class = this.navParams.get('class')
  }

}
