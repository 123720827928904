import { Component, OnInit } from '@angular/core';
import { ScannerinputComponent } from '../components/scannerinput/scannerinput.component';
import { HttpService } from '../services/http/http.service';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { TrybemodalComponent } from '../components/trybemodal/trybemodal.component';
import { authUser } from '../authStore/auth.selector';
import { MultipleBarcodeScannerComponent } from '../components/multiple-barcode-scanner/multiple-barcode-scanner.component';

@Component({
  selector: 'app-return-to-safe',
  templateUrl: './return-to-safe.component.html',
  styleUrls: ['./return-to-safe.component.scss'],
})
export class ReturnToSafeComponent implements OnInit {
  dropdown: any = {};
  private scannerComponet!: ScannerinputComponent;
  isUpdateDisable: boolean = true;
  isDataAddedOnce: boolean = false;
  dropDownWorkerData: any = {
    name: 'Worker',
    placeText: 'Select Worker ',
    options: [],
    selectedData: [],
    key: 'fullName',
    modeFlag: 'single', //single/multiple
  };
  isStartTally: boolean = true;
  barcodeInputs: any;
  dataSource: any = [];
  columns: any;
  isOutGoingNotEmpty: boolean = false;
  showStartTally: boolean = true;
  workerData: any;
  workerName: any;
  barcodeValue: any;
  workerId: any;
  currUserDeptId: number;
  tallyStatus: any;
  voucherStatus: any;
  voucherFailStatus: any;
  isStart: boolean = false;
  barcodeInputList: any;

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private loaderService: LoaderService
  ) {}

  async ngOnInit() {
    // await this.getTallyStatus();
    console.log(this.tallyStatus);
    this.isStartTally = !this.tallyStatus;
    // if (this.tallyStatus) {
    //   await this.getWorkers();
    //   console.log(this.workerData);
    //   this.dropDownWorkerData['options'] = this.workerData;
    // }
    this.store.pipe(select(authUser)).subscribe((user) => {
      if (!!user) {
        console.log(user);
        this.currUserDeptId = user?.departmentId;
      }
    });
    this.columns = [{ name: 'packetID', prop: 'packetID' }];
    // this.IsOutgoingISEmpty();
  }

  async startTally() {
    this.isStart = !this.isStart;
    await this.getWorkers();
    console.log(this.workerData);
    this.dropDownWorkerData['options'] = this.workerData;
    // this.showStartTally = !this.isStartTally;
    // this.isStartTally = !this.isStartTally;
    console.log(this.isStartTally);
    if (this.isOutGoingNotEmpty == true) {
      this.configService.showToast(
        false,
        'To Start Tallying The Stock First Clear OutGoing'
      );
    } else {
      let statusParam = this.isStartTally ? '1' : '0';
      this.showStartTally = !this.isStartTally;
      this.isStartTally = !this.isStartTally;
      this.tallyStatus = !this.tallyStatus;
      let payload = null;
      let response = await this.httpService.postDataUsingPromise(
        true,
        `tbl-department/changeStatusOfTallyStock?status=${statusParam}`,
        payload
      );
      console.log(response);

      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.dropDownWorkerData['options'] = this.workerData;
        let data = response.data;
        console.log(data);
      } else {
        console.error(response);
      }
    }
  }

  dropDownSelection(evt: any, plan: any) {
    console.log('dropDownSelection');
    console.log(evt);
    if (evt.property == 'Worker') {
      this.workerName = evt?.arrayList[0]?.fullName;
      this.workerId = evt?.arrayList[0]?.id;
      this.setValuesOfDropdown(evt);
    }
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable =
      Object.keys(this.dropdown).length === 0 ? true : false;
  }

  async barcodeDetection(barcode) {
    if (barcode == '' || barcode == null) {
      return;
    }
    // } else if (!this.isStartTally) {

      if (
        this.dataSource.filter((data) => data.packetID === barcode).length === 0
      ) {
        this.dataSource.push({
          id: this.dataSource.length + 1,
          packetID: barcode,
          worker: this.workerName,
          workerId: this.workerId,
        });
      }
    
    // this.dropDownWorkerData["selectedData"] = {};
    // this.workerName = '';
    // }
  }

  clearBarcode() {
    console.log('hii');
    this.barcodeInputs = '';
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: 'multiplBarcodeScanner',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'done') {
      this.barcodeInputList = data.map((barcode) => barcode.barcode);
      console.log(this.barcodeInputList);
     this.barcodeInputList.forEach((barcodeItem) => {
        if (this.dataSource.filter((data) => data.packetID === barcodeItem).length === 0) {
          this.dataSource.push({
            id: this.dataSource.length + 1,
            packetID: barcodeItem,
          });
        }
      });
  }
}
  onSelectionChanged(event) {
    console.log('onSelectionChanged');
    console.log(event);
  }

  onCancel() {
    this.dataSource = [];
  }

  onDelete(event) {
    console.log(this.dataSource);
    const idToRemove = event.row.data.id;

    const indexToRemove = this.dataSource.findIndex(
      (item) => item.id === idToRemove
    );

    if (indexToRemove !== -1) {
      this.dataSource.splice(indexToRemove, 1);
    }
  }

  async IsOutgoingISEmpty() {
    this.httpService
      .getDataWithoutPayloadForGet(
        true,
        'packet/checkTheStatusOfOutcomingPackets'
      )
      .subscribe((data) => (this.isOutGoingNotEmpty = data.data));
  }

  async getWorkers() {
    try {
      const data = await this.httpService
        .getDataWithoutPayloadForGet(true, 'users/getUsersListByDepartment')
        .toPromise();
      this.workerData = data.data;
      console.log(this.workerData);
    } catch (error) {
      console.error('Error fetching worker data:', error);
    }
  }

  async getTallyStatus() {
    try {
      const data = await this.httpService
        .getDataWithoutPayloadForGet(true, 'tbl-department/getTallyStatus')
        .toPromise();
      this.tallyStatus = data.data;
      console.log(this.tallyStatus);
    } catch (error) {
      console.error('Error fetching worker data:', error);
    }
  }

  async onAdd() {
    const packetIds = [];
    this.dataSource.map((a: any) => {
      packetIds.push(a.packetID);
    });
    let payload = {
      packetIds,
    };
    console.log(payload);

    let response = await this.httpService.postDataUsingPromise(
      true,
      'tally-stock-logs/saveTallyStock',
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data = response.message;
      this.dataSource = [];
      this.configService.showToast('success', data);
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  }

  async onVoucher() {
    if (this.tallyStatus) {
      this.startTally();
    }
    let response = await this.httpService.postDataUsingPromise(
      true,
      'tally-stock-logs/updateVoucherIdAndCheckPacketMatched',
      null
    );
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.voucherStatus = response.data.packetsMatchedStatus;
      if (this.voucherStatus == true) {
        this.dataSource = [];
        this.showModalOnVoucherResponse('IsManagerTallyVoucherSuccessFull');
        // this.configService.showToast(true, "Voucher Created Successfully");
      } else {
        this.showModalOnVoucherResponse('IsManagerTallyVoucherFails');
      }
      this.dropDownWorkerData['selectedData'] = {};
      this.workerName = '';
    } else if (response.err) {
      this.configService.showToast(false, response.err);
    }
    this.isDataAddedOnce = false;
  }

  async showModalOnVoucherResponse(status: string) {
    const Modal = await this.modalCtrl.create({
      component: TrybemodalComponent,
      cssClass: 'modalprint',
      componentProps: {
        data: { status: status },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
    return await Modal.present();
  }

  async onModalDismiss(d: any) {
    console.log('onModalDismiss.....');
    console.log(d);
    if (!!d.data) {
      this.voucherFailStatus = d.data;
      if (this.voucherFailStatus == 'startTally') {
        this.dataSource = [];
      }
    }
  }
}
