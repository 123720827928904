import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { getIncomingPacketsDataVoucherWise } from 'src/app/home/state/home.selector';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';
import { getCurrentAction, getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';
import { printData } from 'src/app/masterDataStore/master.data';
import * as moment from 'moment';
import { ACTIONNAMES } from 'src/app/services/endpoint.enum';
import { LoaderService } from 'src/app/services/loader/loader.service';


@Component({
  selector: 'app-voucherwisepacket',
  templateUrl: './voucherwisepacket.component.html',
  styleUrls: ['./voucherwisepacket.component.scss'],
})
export class VoucherwisepacketComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();
  dataSource: any = [];
  modifiedDataSource: any;
  columns;
  packet: [];
  voucherLabel: any;
  igSuscription: Subscription;
  igSuscription2: Subscription;
  igSuscription3: Subscription;
  igSuscription4: Subscription;
  voucherid;
  showbtn;
  selectedRows: any = [];
  selVourcherData: any;
  user: any;
  @Input() packetInfo: any;
  @Input() isThirdParty:boolean
  isPermission: boolean = false;
  roles: any;
  currUserFullName: any = "";
  incomingGridPageInfo: any = {};
  summaryData: any[] = [];
  parameters: any = {};
  transferPrintData: any = printData;
  dispatchData: any = [];
  voucherDto:any;
  dispatchLossData:any
  isTrasferDispatch: boolean = false;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private storage: Storage,
    private modalController: ModalController,
    private navCtrl: NavController,
    private navParams: NavParams,
    private loaderService: LoaderService,
  ) {
    this.voucherDto = this.navParams.get('voucherDto');
  }

  ngOnInit() {
    this.dataSource = this.packetInfo
    console.log('object------');
    let resData = this.navParams.data
    if (resData && resData.d) {
      this.selVourcherData = resData.d
      this.selVourcherData = !!resData && !!resData.d ? [resData.d] : [];
    }
    if (resData.incomingGridPageInfo) {
      this.incomingGridPageInfo = resData.incomingGridPageInfo;
    }
    this.igSuscription3 = this.store.pipe(select(authUser)).subscribe(userData => {
      console.log('......authUser...');
      console.log(userData);
      if (userData != null) {
        this.currUserFullName = userData.fullName;
        console.log(userData);
        this.user = userData
        if (this.user.roles) {
          this.roles = this.user.roles.join();
        }
        if (this.user.listOfRoles) {
          this.roles = this.user.listOfRoles.map(d => {
            return d.roleName;
          }).join();
        }
        if (this.roles.includes('Manager') || this.roles.includes('Operator')) {
          this.isPermission = true;
        } else {
          this.isPermission = false;
        }
        // this.mode = this.isPermission ? 'single' : 'multiple';
      }
    });
    this.igSuscription = this.store.pipe(select(getCurrentRowData)).subscribe((rd: any) => {
      console.log(rd);

      if (rd && rd.length > 0) {
        console.log(rd);
        console.log('getCurrentRowData');
        this.voucherid = rd[0].voucherId;
        this.voucherLabel = rd[0].voucherLabel;
        // this.store.dispatch(HomeActions.getIncomingPacketsVoucherwisePacket({ voucherId: this.voucherid }));
        console.log(this.voucherid);
      }
    });


    // this.igSuscription2 = this.store.pipe(select(getIncomingPacketsDataVoucherWise)).subscribe(data => {
    //   if (data) {
    //     console.log(data);
    //     this.dataSource = data;
    //     let totalCts = 0;
    //     (this.dataSource || []).map((dt:any)=>{
    //       totalCts += (dt?.cts || 0);
    //     })
    //     this.summaryData = [
    //       {
    //         prop: 'cts',
    //         value: `Total CTS : ${totalCts}`,
    //       }
    //     ]
    //   }
    // });


    this.columns = [
      { name: "Lot No", prop: "lotNo" },
      { name: "Packet ID", prop: "packetId" },
      { name: "Cts", prop: "cts" },
      // { name: "Pkt Grade", prop: "pktGrade" },
      // { name: "Status", prop: "pktStatus" },
      { name: "From", prop: "fromDept" },
      { name: "Last Action", prop: "fromActionName" },
      { name: "ToUser", prop: "toUser" },
      // { name: "PredLimit", prop: "predLimit" },
      // { name: "InwardDate", prop: "inDate" },
      // { name: "Location", prop: "location" }

    ]
    this.igSuscription4 = this.store.pipe(select(getCurrentAction)).pipe(takeUntil(this.unsubscribe$)).subscribe((action: any) => {
      if (action) {
        if (action.name == "Outgoing" || action.name == ACTIONNAMES.Sales_Return_Voucher || action.name == ACTIONNAMES.Self_Sales_Return_Voucher) {
          this.showbtn = false;
          console.log(this.showbtn);
        }
        else {
          this.showbtn = true;
        }
      }
    });



  }
  async receiveOrRejectPacket(action) {
    let info = {
      rowData: this.selectedRows,
      status: 'WorkQualityMaster'
    }
    console.log(this.user);
    let voucher = this.selVourcherData[0];
    let packetUserFullName = voucher.fromUser;
    let packetFromAction = voucher.lastAction;

    console.log(this.selectedRows);
    let vouIds = this.selVourcherData.map((x: any) => x.voucherId);
    let voucherNeedToReceive = {
      voucherList: vouIds,
    };
    console.log(voucherNeedToReceive);
    console.log(action);
    console.log(this.user);
    this.parameters['payload'] = voucherNeedToReceive;
    this.parameters['incomingGridPageInfo'] = this.incomingGridPageInfo;
    if (this.isPermission && ((!!packetFromAction && packetFromAction.toLowerCase() != 'return') || (!!packetUserFullName && this.currUserFullName != packetUserFullName)) && this.user.departmentName != 'Botswana A1 Department') {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          "message": "Work Quality",
          'data': info,
          'transactionType': action
        },
        cssClass: 'workQualityMaster'
      });
      modal.onDidDismiss().then((d) => {
        this.handleModalDismiss(d);
      });
      return await modal.present();
    }
    else {
      if (action == 'Receive') {
        this.closeModal(null);
        console.log("receive btn is coming here");
        this.store.dispatch(masterDataActions.receivePacketVoucherwise({ param: this.parameters }));
        // await this.receivePacketFromUser(packetNeedToReceive);
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            "message": "Work Quality",
            'data': info,
            'transactionType': action
          },
          cssClass: 'workQualityMaster'
        });
        modal.onDidDismiss().then((d) => {
          this.handleModalDismiss(d);
        });
        return await modal.present();
      }
    }
  }

  async printVoucher(){
    
    const printRes = await this.httpService.getDataWithoutPayloadForGet(true, `packet/getPrintDataForDispatch?voucherId=${this.voucherid}`).toPromise();
    
        if(printRes?.data?.dispatchVoucher.length > 0 && !this.isThirdParty){
          this.dispatchData = printRes?.data?.dispatchVoucher;
          this.transferPrintData['voucherNo'] = this.voucherDto.voucherLabel;
          this.transferPrintData['jangadNo'] = this.voucherDto.voucherLabel;
          this.transferPrintData['issueNo'] = this.voucherDto.voucherLabel;
          this.transferPrintData['issueFor'] = `Transfer`;
          this.transferPrintData['fromCompany'] = this.dispatchData[0].fromCompany;
          this.transferPrintData['toCompany'] = this.dispatchData[0].toCompany;
          this.transferPrintData['voucherId'] = this.dispatchData[0].voucherId;
          this.transferPrintData['TocompanyName'] = this.dispatchData[0].partyName;
          this.transferPrintData['date'] = moment
            .utc(this.dispatchData[0].Date)
            .local()
            .format('DD-MMM-YYYY');
          this.transferPrintData['ttlPkt'] = this.dispatchData.length;
          let rctsttl = 0;
          let ctsttl = 0;
          this.dispatchData.map((d) => {
            if (d.originalWt) ctsttl += d.originalWt;
            if(d.wt) rctsttl += d.wt;
            // d['lotNo'] = d.currPacketId.split('#')[0];
          });
          this.transferPrintData['owtttl'] = ctsttl
          this.transferPrintData['rctsttl'] = rctsttl;

          this.dispatchLossData = this.dispatchData?.filter(d => (d.loss != null))
        }
          // isLoss = (this.dispatchLossData !== undefined)
          this.isTrasferDispatch = true;
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: printRes.message,
              data: this.voucherDto,
              dispatchData : this.dispatchData,
              voucherDto : this.voucherDto,
              printData : this.transferPrintData,
              dispatchLossData : this.dispatchLossData? this.dispatchLossData : null
            },
            cssClass: 'modalprint',
          });
          modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null) {
              console.log(dataReturned);
            }
          });
          return await modal.present();

    // const printContent = document.getElementById("printPage");
    // // const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    // // WindowPrt.document.write(printContent.innerHTML);
    // // WindowPrt.document.close();
    // // WindowPrt.focus();
    // // debugger;
    // // WindowPrt.print();
    // // WindowPrt.close();
    // // window.print();return;

    // if (printContent) {
    //   // Check if the element with id "printPage" exists
    
    //   // Create a new window for printing
    //   const printWindow = window.open("", "_blank");
    
    //   if (printWindow) {
    //     // Write the HTML content to the new window with inline styles
    //     printWindow.document.write(`
    //       <!DOCTYPE html>
    //       <html>
    //       <head>
    //         <title>Print Page</title>
    //         <style>
    //           .flex-center-between{
    //             display:flex;
    //             align-items:center;
    //             justify-content:space-between;
    //           }
    //           .header-text{
    //             font-weight:8px;
    //             color:rgb(168, 163, 163)
    //           }
    //           .float-right {
    //             float: right;
    //           }
    //           .nowrap{
    //             text-wrap:nowrap;
    //           }


    //           .logo_img{
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //           }
              
    //           .center{
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //           }
              
    //           hr.initial {
    //             border-top: 1px dotted #000;
    //           }
              
    //           hr.signature {
    //             // width: 200px;
    //             border-top: 0.25px solid #000;
    //             // border-width: 0.5px;
    //           }
              
    //           .fs{
    //             font-weight: normal;
    //             font-size: small;
    //           }
                
    //           .space{
    //             min-height: 30px;
    //           }
              
              
    //           .column{
    //             border: 1px solid #000;
    //           }
              
    //           .br{
    //             border-right: 1px solid #000;
    //           }
              
    //           .bb{
    //             border-bottom: 1px solid #000;
    //           }
              
    //           .bt{
    //             border-top: 2px solid #000;
    //           }
    //           .fs-12{
    //             // font-weight: 10;
    //             font-size: 10px;
    //           }
              
    //           .fs-10{
    //             font-size: 8px;
    //           }
              
    //           span.tc {
    //             text-align: center;
    //           }
              
    //           .pr{
    //             padding-right: 70px;
    //           }
              
    //           .col-fs{
    //             font-size: 11px;
    //           }
              
    //           .print-page {
    //             page-break-before: always;
    //           }
    //           .fs-12 {
    //             font-size: 12px;
    //           }
    //           .tac {
    //             text-align: center;
    //           }
    //           .ion-grid{
    //             display: flex;
    //             justify-content: space-between;
    //             padding: 10px; 
    //           }
    //           .ion-row{
    //             display: flex;
    //             width: 100%;
    //           }
    //           .ion-col{
    //             flex: 1;
    //           } 
    //           table {
    //             width: 100%;
    //             border-collapse: collapse;
    //           }
    //           th, td {
    //             border: 1px solid black;
    //             border-collapse: collapse;
    //             text-align: center;
    //             padding : 2px
    //           }
    //           body * {
    //             visibility: hidden;
    //         }
    //           @media print {
    //             body * {
    //               visibility: visible;
    //           }
    //         }
    //         </style>
    //       </head>
    //       <body>
    //         ${printContent.innerHTML}
    //       </body>
    //       </html>
    //     `);
    
    //     printWindow.document.close();
    
    //     printWindow.print();
    //     printWindow.close();
    //   } else {
    //     console.error("Failed to open print window");
    //   }
    // } else {
    //   window.print();
    // }
  }
  closeModal(par) {
    this.modalController.dismiss(par)
  }
  async Receive() {
    console.log("receive btn");
    console.log(this.selVourcherData);
    let voucherNeedToReceive = {
      voucherList: [this.voucherid],
    };
    console.log(voucherNeedToReceive);
    console.log(this.isPermission);
    this.receiveOrRejectPacket('Receive')
    // return;
    // await this.store.dispatch(masterDataActions.receivePacketVoucherwise({ payload: voucherNeedToReceive }));


  }

  onSelectionChanged(event) {
    event.row = event.selectedRowsData[0];
    this.selectedRows = event.selectedRowsData
    console.log(event.row);

  }
  async handleModalDismiss(d) {
    console.log('handleModalDismiss Incoming Page');
    this.closeModal(null);
    if (!!d && !!d.data && d.data.status == "WorkQualitySubmitted") {
      let vouIds = this.selVourcherData.map((x: any) => x.voucherId);
      // this.sendData['data'] = this.selectedRows;
      // this.sendData['packetIds'] = this.selectedRows.map(d => d.packetId);
      let workQuality = d.data.remark;
      let damageRemark = d.data.damageName;
      let voucherNeedToReceive = {
        voucherList: vouIds,
      };
      if (!!workQuality && !!workQuality.id) {
        voucherNeedToReceive['workQualityMasterId'] = workQuality.id;
      }
      if (!!damageRemark && !!damageRemark.id) {
        voucherNeedToReceive['damageMasterId'] = damageRemark.id;
      }
      console.log(d.data.transactionType);
      console.log(voucherNeedToReceive);
      this.parameters['payload'] = voucherNeedToReceive;
      this.parameters['incomingGridPageInfo'] = this.incomingGridPageInfo;
      if (d.data.transactionType == 'Receive') {
        this.store.dispatch(masterDataActions.receivePacketVoucherwise({ param: this.parameters }));
        // await this.receivePacketFromUser(packetNeedToReceive)
      } else if (d.data.transactionType == 'Reject') {
        //currently hide to change this store code
        this.store.dispatch(masterDataActions.rejectPacket({ payload: voucherNeedToReceive, modalData: {} }));
        // await this.rejectPackeToUser(packetNeedToReceive);
      } else {
        console.log('none')
      }
      // return

    }
  }
  ngOnDestroy() {
    if (!!this.igSuscription) {
      this.igSuscription.unsubscribe();
    }
    if (!!this.igSuscription2) {
      this.igSuscription2.unsubscribe();
    }
    if (!!this.igSuscription3) {
      this.igSuscription3.unsubscribe();
    }
    if (!!this.igSuscription4) {
      this.igSuscription4.unsubscribe();
    }
  }


}
