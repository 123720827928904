import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderComponentModule } from '../components/header/header.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';

import { SingleMultiDropdownModule } from "../components/single-multi-dropdown/single-multi-dropdown.module";
import { CompletedPacketHistoryComponent } from './completed-packet-history.component';
import { ScannerinputModule } from '../components/scannerinput/scannerinput.module';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';

@NgModule({
    declarations: [
        CompletedPacketHistoryComponent
    ],
    entryComponents: [],
    exports: [CompletedPacketHistoryComponent],
    providers: [],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        WidthAlertModule,
        IonicModule,
        ScannerinputModule,
        DataGridComponentModule,
        HeaderComponentModule,
        SingleMultiDropdownModule,
    ]
})
export class CompletedPacketHistoryModule { }
