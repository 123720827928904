import { Component, DebugElement, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { getAllTrybeDepartments, getParamsValuesListByName } from "../masterDataStore/masterData.selector";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { AppState } from "../reducers";
import { HttpService } from "../services/http/http.service";
import { ConfigService } from "../services/config/config.service";
import { routeMasterAPIs } from "../packet-route-master/state/packet-route-master.data";
import { PopoverController } from "@ionic/angular";
import { PopoverUserComponent } from "../components/popover-user/popover-user.component";
import { LoaderService } from "../services/loader/loader.service";
import { SignerAction } from "../components/trybesigner/state/signer.action.type";
import { getOrderForm } from "../components/trybesigner/state/signer.selector";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-orderform",
  templateUrl: "./orderform.component.html",
  styleUrls: ["./orderform.component.scss"],
})
export class OrderformComponent implements OnInit {
  public selectedTab;
  dropdown: any = {};
  private unsubscribe$: Subject<any> = new Subject<any>();
  processes;
  dropdownJSONarray: any;
  filterDropdownarray: any;
  departments;
  keyOnClose: any;
  roughYehuda: any;
  newSaveInJson;
  criteria;
  updateId: number;
  isEditRoute: boolean = false;
  columns;
  orderFormManagers: any = [];
  rateTypes: any = [];
  uiJsonForEdit: any;
  gridData;
  filterDropdownPayload;
  filteredDatas;
  public currentView: string = "orderFormGrid";
  dropdownDataToPush;
  preDefinedDropDownJSON = {
    Color: {
      datas: [],
      isData: false,
    },
    Clarity: {
      datas: [],
      isData: false,
    },
    Fluorescence: {
      datas: [],
      isData: false,
    },
    Milkyness: {
      datas: [],
      isData: false,
    },
    Natts: {
      datas: [],
      isData: false,
    },
    Polish: {
      datas: [],
      isData: false,
    },
    Shape: {
      datas: [],
      isData: false,
    },

    Symmetry: {
      datas: [],
      isData: false,
    },

    CUT: {
      datas: [],
      isData: false,
    },

    Tinch: {
      datas: [],
      isData: false,
    },
    Topsopens: {
      datas: [],
      isData: false,
    },

    Botsopen: {
      datas: [],
      isData: false,
    },
  };
  formData: any = {
    orderNumber: "",
    orderDate: "",
    targetDate: "",
    priority: "",
    pieces: "",
    origin: "",
    cts: "",
    clientName: "",
    sizeRange: {
      from: "",
      to: "",
    },
    avgGroupRate: {
      from: "",
      to: "",
    },
    perpieceRate: {
      from: "",
      to: "",
    },
    table: {
      from: "",
      to: "",
    },
    roughWeight: {
      from: "",
      to: "",
    },
    girdle: {
      from: "",
      to: "",
    },
    width: {
      from: "",
      to: "",
    },
    length: {
      from: "",
      to: "",
    },
    culet: {
      from: "",
      to: "",
    },
    diameter: {
      from: "",
      to: "",
    },
    lOrW: {
      from: "",
      to: "",
    },
    depth: {
      from: "",
      to: "",
    },
    cheight: {
      from: "",
      to: "",
    },
    cangle: {
      from: "",
      to: "",
    },
    pdepth: {
      from: "",
      to: "",
    },
    pangle: {
      from: "",
      to: "",
    },
    remark: "",
    roughRemark: "",
    groupType: "",
    dropdownJson: this.preDefinedDropDownJSON,
  };
  opinionOfUser = [];

  // ********************************************
  // formDataRough = {
  //   roughCriteriaDetails: {
  //     origin : '',
  //     roughWeight : {
  //       from : '',
  //       to: ''
  //     },
  //     totalValuePerCarat : {
  //       from : '',
  //       to: ''
  //     },
  //     pricePerCarat : {
  //       from:'',
  //       to : ''
  //     },
  //     clarity : '',
  //   },
  //   stoneRange : {
  //     manual : {
  //       colorSelection : [],
  //       fluorescence: [],
  //       luster : '',
  //       tinch : '',
  //       tensionType:''
  //     },
  //     yehuda : {
  //       colorSelection : [],
  //       flourSelection: [],
  //       luster : '',
  //       tinch : '',
  //       tensionType:''
  //     }
  //   },
  //   matchOrder : {
  //   inDept : [{
  //     id: '',
  //     name : ''
  //   }],
  //   inProcess: [
  //     {
  //       id: '',
  //       name : ''
  //     }
  //   ]
  //   },
  //   remarks:''
  // }

  isUpdateDisable: boolean = true;
  dropDownDeptData: any = {
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],
    key: "department",
    modeFlag: "multiple", //single/multiple
  };

  dropDownClarityFromData: any = {
    name: "From Clarity",
    placeText: "From Clarity",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownClarityToData: any = {
    name: "To Clarity",
    placeText: "To Clarity",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownTinchFromData: any = {
    name: "From Tinch",
    placeText: "From Tinch",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownTinchToData: any = {
    name: "To Tinch",
    placeText: "To Tinch",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownLusterFromData: any = {
    name: "From Luster",
    placeText: "From Luster",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownLusterToData: any = {
    name: "To Luster",
    placeText: "To Luster",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownTensionFromData: any = {
    name: "From Tension",
    placeText: "From Tension",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownTensionToData: any = {
    name: "To Tension",
    placeText: "To Tension",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownColorFromData: any = {
    name: "From Color",
    placeText: "From Color",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownColorToData: any = {
    name: "To Color",
    placeText: "To Color",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownFLFromData: any = {
    name: "From Fluorescence",
    placeText: "From Fluor.",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownFLToData: any = {
    name: "To Fluorescence",
    placeText: "To Fluor.",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownProcessData: any = {
    name: "Process",
    placeText: "Select Process",
    options: [],
    selectedData: [],
    key: "process",
    modeFlag: "multiple", //single/multiple
  };

  saveInJsonPayload = {
    in: {
      Color: [],
      Clarity: [],
      Fluorescence: [],
      Milkyness: [],
      Natts: [],
      Polish: [],
      Shape: [],
      Symmetry: [],
      CUT: [],
      Tinch: [],
      Topsopens: [],
      Botsopen: [],
    },
  };
  saveRangeJsonPayload;
  finalsaveJsonPayload;
  selectedCriteriaarray;
  criteriaValForView;
  finalRoughtCriteriaJson: any;
  uiJsonOfRoughtCriteria: any = {
    yehuda: "",
  };
  selectedOrderRemark: any;
  uiJsonForEditRough: any;

  constructor(
    private store: Store<AppState>,
    private httpService: HttpService,
    private _ConfigService: ConfigService,
    public popoverController: PopoverController,
    public loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.selectedTab = "PolishCriteria";
    this.fetchAllActions();
    this.store.dispatch(SignerAction.getOrderForm());
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
    this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
      if (data) {
        console.log("AllTrybeDepartments");
        console.log(data);
        this.departments = [];
        data.forEach((curr: any, k: number) => {
          let obj1: object = {};
          obj1 = { id: curr.id, department: curr.name };
          this.departments.push(obj1);
        });
        // this.drawerData['departments'] = this.departments;
        this.dropDownDeptData["options"] = this.departments;
        console.log(this.departments);
      }
    });

    this.store.pipe(select(getOrderForm)).subscribe(data => {
      if (data) {
        console.log(data);
        this.gridData = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName("Color")), takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        //this.dropDownColorData.options = data;
        this.dropDownColorFromData.options = data;
        this.dropDownColorToData.options = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName("Fluorescence")), takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        //this.dropDownFLData.options = data;
        this.dropDownFLFromData.options = data;
        this.dropDownFLToData.options = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName("Purity")), takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        //this.dropDownFLData.options = data;
        this.dropDownClarityFromData.options = data;
        this.dropDownClarityToData.options = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName("Tinch")), takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        //this.dropDownFLData.options = data;
        this.dropDownTinchFromData.options = data;
        this.dropDownTinchToData.options = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName("Luster")), takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        //this.dropDownFLData.options = data;
        this.dropDownLusterFromData.options = data;
        this.dropDownLusterToData.options = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName("Tension Mapping"))).subscribe(data => {
      if (!!data) {
        this.dropDownTensionFromData.options = data;
        this.dropDownTensionToData.options = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName("Color"))).subscribe(data => {
      if (!!data) {
        this.dropDownColorFromData.options = data;
        this.dropDownColorToData.options = data;
      }
    });

    this.columns = [
      { name: "order Number", prop: "orderNo" },
      { name: "order Name", prop: "orderName" },
      { name: "Rate Type", prop: "rateType" },
      { name: "OrderDate", prop: "orderDate" },
      { name: "Target Date", prop: "targetDate" },
      { name: "CreatedDate", prop: "createdDate" },
      { name: "Priority", prop: "priority" },
      { name: "Pieces", prop: "pcs" },
      { name: "Cts", prop: "cts" },
      { name: "createdBy", prop: "createdBy" },
    ];

    this.getOrderFormManagers();
    this.getRateType();
  }

  getOrderFormManagers() {
    this.httpService.fetchData(true, "users/getOrderFormManagers").subscribe((res: any) => {
      if (res?.statusCode == 200) console.log(this.orderFormManagers);
      this.orderFormManagers = res?.data || [];
      this.selectedOrderRemark = res?.data?.[0]?.fullName;
      console.log(this.selectedOrderRemark);
    });
  }
  getRateType() {
    this.httpService.fetchData(true, "order-form/getRateType").subscribe((res: any) => {
      if (res?.statusCode == 200) this.rateTypes = res?.data || [];
    });
  }

  onNextClick() {
    if (this.selectedTab == "PolishCriteria") {
      this.selectedTab = "RoughCriteria";
    }
  }

  segmentChanged(event) {
    console.log(event);
    console.log(this.selectedTab);
    console.log(this.uiJsonOfRoughtCriteria);
  }

  async fetchAllActions() {
    console.log("fetchAllActions");
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getActionsEndPoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log("%cActions----------", "color:green;font-weight:bold;font-size:1rem;");
      let data: Array<[]> = response.data;
      this.processes = data.map((d: any) => {
        let { id, actionName } = d;
        return { id: id, process: actionName };
      });
      this.processes = this.processes.filter(na => na.process != null);
      this.dropDownProcessData["options"] = [];
      this.dropDownProcessData["options"] = this.processes;
      // this.drawerData['processes'] = this.processes;
      console.log(this.processes);
    } else {
      console.error(response);
    }
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    if (this.selectedTab == "RoughCriteria") {
      this.uiJsonOfRoughtCriteria = this.dropdown;
      console.log(this.uiJsonOfRoughtCriteria);
    }
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }

  dropDownSelection(evt: any, plan: any) {
    console.log("dropDownSelection");
    console.log(evt);
    if (evt.property == "Department") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "Process") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "From Clarity") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "To Clarity") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "From Tinch") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "To Tinch") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "From Luster") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "To Luster") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "From Tension") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "To Tension") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "From Color") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "To Color") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "From Fluorescence") {
      this.setValuesOfDropdown(evt);
    } else if (evt.property == "To Fluorescence") {
      this.setValuesOfDropdown(evt);
    }
  }

  onSelectionChanged(event) {
    console.log(event);
  }

  async updateOrderData() {
    for (let i = 0; i < this.gridData.length; i++) {
      let rowData = this.gridData[i];
      // if (rowData.id == 207) {
      console.log(rowData);
      this.updateId = rowData.id;
      this.uiJsonForEdit = JSON.parse(rowData.uiJson);
      this.uiJsonForEditRough = JSON.parse(rowData.roughUIJson);
      // this.currentView = 'orderSelectionForm';
      // this.selectedTab = 'PolishCriteria';
      this.formData = {
        orderNumber: this.uiJsonForEdit.orderNumber,
        orderDate: this.uiJsonForEdit.orderDate,
        targetDate: this.uiJsonForEdit.targetDate,
        origin: this.uiJsonForEdit.origin,
        priority: this.uiJsonForEdit.priority,
        pieces: this.uiJsonForEdit.pieces,
        yehuda: this.uiJsonForEditRough?.yehuda,
        cts: this.uiJsonForEdit.cts,
        rateType: this.uiJsonForEdit.rateType,
        groupType: this.uiJsonForEdit.groupType,
        clientName: this.uiJsonForEdit.clientName,
        roughRemark: this.uiJsonForEdit.roughRemark,
        sizeRange: {
          from: this.uiJsonForEdit.sizeRange.from,
          to: this.uiJsonForEdit.sizeRange.to,
        },
        avgGroupRate: {
          from: this.uiJsonForEdit.avgGroupRate.from,
          to: this.uiJsonForEdit.avgGroupRate.to,
        },
        perpieceRate: {
          from: this.uiJsonForEdit.perpieceRate.from,
          to: this.uiJsonForEdit.perpieceRate.to,
        },
        table: {
          from: this.uiJsonForEdit.table.from,
          to: this.uiJsonForEdit.table.to,
        },
        girdle: {
          from: this.uiJsonForEdit.girdle.from,
          to: this.uiJsonForEdit.girdle.to,
        },
        culet: {
          from: this.uiJsonForEdit.culet.from,
          to: this.uiJsonForEdit.culet.to,
        },
        diameter: {
          from: this.uiJsonForEdit.diameter.from,
          to: this.uiJsonForEdit.diameter.to,
        },
        lOrW: {
          from: this.uiJsonForEdit.lOrW.from,
          to: this.uiJsonForEdit.lOrW.to,
        },
        depth: {
          from: this.uiJsonForEdit.depth.from,
          to: this.uiJsonForEdit.depth.to,
        },
        cheight: {
          from: this.uiJsonForEdit.cheight.from,
          to: this.uiJsonForEdit.cheight.to,
        },
        cangle: {
          from: this.uiJsonForEdit.cangle.from,
          to: this.uiJsonForEdit.cangle.to,
        },
        pdepth: {
          from: this.uiJsonForEdit.pdepth.from,
          to: this.uiJsonForEdit.pdepth.to,
        },
        pangle: {
          from: this.uiJsonForEdit.pangle.from,
          to: this.uiJsonForEdit.pangle.to,
        },
        roughWeight: {
          from: this.uiJsonForEdit?.roughWeight?.from,
          to: this.uiJsonForEdit?.roughWeight?.to,
        },
        remark: this.uiJsonForEdit.remark,
        dropdownJson: this.uiJsonForEdit.dropdownJson,
        opinionOfUserId: this.uiJsonForEdit?.opinionOfUserId,
      };
      this.opinionOfUser = this.orderFormManagers.filter((dt: any) => dt.id == this.uiJsonForEdit?.opinionOfUserId);
      console.log(this.uiJsonForEdit.dropdownJson);
      this.preDefinedDropDownJSON = this.uiJsonForEdit.dropdownJson;
      this.dropdownJSONarray = [this.uiJsonForEdit.dropdownJson];
      for (const key in this.uiJsonForEdit?.dropdownJson || {}) {
        this.dropdownJSONarray.push({
          key: key,
          value: this.uiJsonForEdit?.dropdownJson[key],
        });
      }
      this.formData.yehuda = this.uiJsonForEdit?.["yehuda"];

      let dataArray = Object.keys(this.uiJsonForEdit.dropdownJson).map(key => {
        return {
          key: key,
          value: this.uiJsonForEdit.dropdownJson[key],
        };
      });
      console.log(dataArray);
      console.log(this.formData);
      this.filterDropdownarray = dataArray.filter(item => item.value.isData === true);
      console.log(this.filterDropdownarray);
      console.log(this.preDefinedDropDownJSON);
      if (this.isEditRoute) {
        let parsedRoughJson = JSON.parse(rowData.roughUIJson);
        console.log(parsedRoughJson);
        if (!!parsedRoughJson) {
          this.dropDownColorFromData["selectedData"] = [parsedRoughJson?.["From Color"]];
          this.dropDownColorToData["selectedData"] = [parsedRoughJson?.["To Color"]];
          this.dropDownFLFromData["selectedData"] = [parsedRoughJson?.["From Fluorescence"]];
          this.dropDownFLToData["selectedData"] = [parsedRoughJson?.["To Fluorescence"]];
          this.dropDownClarityFromData["selectedData"] = [parsedRoughJson?.["From Clarity"]];
          this.dropDownClarityToData["selectedData"] = [parsedRoughJson?.["To Clarity"]];
          this.dropDownTinchFromData["selectedData"] = [parsedRoughJson?.["From Tinch"]];
          this.dropDownTinchToData["selectedData"] = [parsedRoughJson?.["To Tinch"]];
          this.dropDownLusterFromData["selectedData"] = [parsedRoughJson?.["From Luster"]];
          this.dropDownLusterToData["selectedData"] = [parsedRoughJson?.["To Luster"]];
          this.dropDownTensionFromData["selectedData"] = [parsedRoughJson?.["From Tension"]];
          this.dropDownTensionToData["selectedData"] = [parsedRoughJson?.["To Tension"]];

          this.dropdown["From Color"] = parsedRoughJson?.["From Color"];
          this.dropdown["To Color"] = parsedRoughJson?.["To Color"];
          this.dropdown["From Fluorescence"] = parsedRoughJson?.["From Fluorescence"];
          this.dropdown["To Fluorescence"] = parsedRoughJson?.["To Fluorescence"];
          this.dropdown["From Clarity"] = parsedRoughJson?.["From Clarity"];
          this.dropdown["To Clarity"] = parsedRoughJson?.["To Clarity"];
          this.dropdown["From Tinch"] = parsedRoughJson?.["From Tinch"];
          this.dropdown["To Tinch"] = parsedRoughJson?.["To Tinch"];
          this.dropdown["From Luster"] = parsedRoughJson?.["From Luster"];
          this.dropdown["To Luster"] = parsedRoughJson?.["To Luster"];
          this.dropdown["From Tension"] = parsedRoughJson?.["From Tension"];
          this.dropdown["To Tension"] = parsedRoughJson?.["To Tension"];
        }
      }
      this.saveRangeJsonPayload = {
        priorityRange: {
          Color: {
            paramNameId: null,
            value: {
              lowerPriorityLimitId: this.dropdown["From Color"]?.id,
              upperPriorityLimitId: this.dropdown["To Color"]?.id,
            },
          },
          Clarity: {
            paramNameId: null,
            value: {
              lowerPriorityLimitId: this.dropdown["From Clarity"]?.id,
              upperPriorityLimitId: this.dropdown["To Clarity"]?.id,
            },
          },
          Luster: {
            paramNameId: null,
            value: {
              lowerPriorityLimitId: this.dropdown["From Luster"]?.id,
              upperPriorityLimitId: this.dropdown["To Luster"]?.id,
            },
          },
          "Tension Mapping": {
            paramNameId: null,
            value: {
              lowerPriorityLimitId: this.dropdown["From Tension"]?.id,
              upperPriorityLimitId: this.dropdown["To Tension"]?.id,
            },
          },
          Fluorescence: {
            paramNameId: null,
            value: {
              lowerPriorityLimitId: this.dropdown["From Fluorescence"]?.id,
              upperPriorityLimitId: this.dropdown["To Fluorescence"]?.id,
            },
          },
          Tinch: {
            paramNameId: null,
            value: {
              lowerPriorityLimitId: this.dropdown["From Tinch"]?.id,
              upperPriorityLimitId: this.dropdown["To Tinch"]?.id,
            },
          },
        },
        range: {
          expPol: {
            lowerLimit: this.formData.sizeRange.from,
            upperLimit: this.formData.sizeRange.to,
          },
          avgGroupRate: {
            lowerLimit: this.formData.avgGroupRate.from,
            upperLimit: this.formData.avgGroupRate.to,
          },
          perPieceRate: {
            lowerLimit: this.formData.perpieceRate.from,
            upperLimit: this.formData.perpieceRate.to,
          },
          tableVal: {
            lowerLimit: this.formData.table.from,
            upperLimit: this.formData.table.to,
          },
          girdle: {
            lowerLimit: this.formData.girdle.from,
            upperLimit: this.formData.girdle.to,
          },
          culet: {
            lowerLimit: this.formData.culet.from,
            upperLimit: this.formData.culet.to,
          },
          diameter: {
            lowerLimit: this.formData.diameter.from,
            upperLimit: this.formData.diameter.to,
          },
          lOrW: {
            lowerLimit: this.formData.lOrW.from,
            upperLimit: this.formData.lOrW.to,
          },
          height: {
            lowerLimit: this.formData.depth.from,
            upperLimit: this.formData.depth.to,
          },
          crHeight: {
            lowerLimit: this.formData.cheight.from,
            upperLimit: this.formData.cheight.to,
          },
          crAngle: {
            lowerLimit: this.formData.cangle.from,
            upperLimit: this.formData.cangle.to,
          },
          pavHeight: {
            lowerLimit: this.formData.pdepth.from,
            upperLimit: this.formData.pdepth.to,
          },
          pavAngle: {
            lowerLimit: this.formData.pangle.from,
            upperLimit: this.formData.pangle.to,
          },
        },
      };
      //  code to give value null is no value is present
      for (let key in this.saveRangeJsonPayload.range) {
        if (!this.saveRangeJsonPayload.range[key].lowerLimit && !this.saveRangeJsonPayload.range[key].upperLimit) {
          this.saveRangeJsonPayload.range[key] = null;
        }
      }
      this.filterDropdownPayload = {};
      Object.entries(this.preDefinedDropDownJSON).forEach(([key, value]) => {
        if (value.isData) {
          this.filterDropdownPayload[key] = value.datas.map(item => item.id);
        }
      });
      console.log(this.filterDropdownPayload);
      console.log(this.preDefinedDropDownJSON);
      Object.keys(this.saveInJsonPayload.in).forEach(key => {
        this.saveInJsonPayload.in[key] = [];
      });
      Object.entries(this.filterDropdownPayload).forEach(([key, value]) => {
        console.log(value);
        console.log(key);
        if (this.saveInJsonPayload.in.hasOwnProperty(key)) {
          Array.prototype.push.apply(this.saveInJsonPayload.in[key], value);
        }
        console.log(this.saveInJsonPayload);
      });
      this.newSaveInJson = { in: {} };
      const keys = Object.keys(this.saveInJsonPayload.in);
      console.log(keys);
      for (let key of keys) {
        let newKey = `${key.toLowerCase()}Id`;
        this.newSaveInJson.in[newKey] = this.saveInJsonPayload.in[key];
      }
      console.log(this.saveInJsonPayload);
      console.log(this.newSaveInJson);
      this.finalsaveJsonPayload = {
        ...this.newSaveInJson,
        ...this.saveRangeJsonPayload,
      };
      await this.updateOrder(this.finalsaveJsonPayload);
      // }
    }
  }

  updateOrder(finalUpdateJsonPayload) {
    console.log(this.updateId);
    let payload = {
      id: this.updateId,
      priority: this.formData.priority,
      clientId: 1,
      clientName: this.formData.clientName,
      orderName: this.formData.orderNumber,
      criteria: JSON.stringify(finalUpdateJsonPayload),
      cts: this.formData.cts,
      orderDate: this.formData.orderDate,
      pcs: this.formData.pieces,
      priceAndSizeRange: "hjcx",
      opinionOfUserId: this.formData["opinionOfUserId"],
      groupType: this.formData.groupType,
      perPieceRate: this.formData.perpieceRate.from,
      rateType: this.formData?.rateType?.[0],
      origin: this.formData?.origin,
      roughWeight: JSON.stringify(this.formData.roughWeight),
      roughRemarks: this.formData.roughRemark,
      remark: this.formData.remark,
      roughCriteria: JSON.stringify(this.finalRoughtCriteriaJson),
      targetDate: this.formData.targetDate,
      uiJson: JSON.stringify(this.formData),
      roughUIJson: JSON.stringify(this.uiJsonOfRoughtCriteria),
    };
    console.log(payload);
    this.httpService.saveData(true, "order-form/save", payload).subscribe((result: any) => {
      console.log("%cPacket-order Save......", "color:orange;font-size:18px;");
      console.log(result);
      this.httpService.getErrorAndDisplayIt(result);
      if (result.statusCode == 200) {
        // this.finalsaveJsonPayload = {}
        // Object.keys(this.saveInJsonPayload.in).forEach((key) => {
        //   this.saveInJsonPayload.in[key] = [];
        // });
        console.log("Success");
      } else {
        console.log(result);
        console.error("Error");
      }
    });
  }

  editOrder(event) {
    this.isEditRoute = true;
    let rowData: any = event.row.data;
    console.log(rowData);
    console.log(rowData.roughUIJson);
    if (rowData) {
      this.updateId = rowData.id;
      this.uiJsonForEdit = JSON.parse(rowData.uiJson);
      this.uiJsonForEditRough = JSON.parse(rowData.roughUIJson);
      this.currentView = "orderSelectionForm";
      this.selectedTab = "PolishCriteria";
      this.formData = {
        orderNumber: this.uiJsonForEdit?.orderNumber,
        orderDate: this.uiJsonForEdit?.orderDate,
        targetDate: this.uiJsonForEdit?.targetDate,
        origin: this.uiJsonForEdit?.origin,
        priority: this.uiJsonForEdit?.priority,
        pieces: this.uiJsonForEdit?.pieces,
        yehuda: this.uiJsonForEditRough?.yehuda,
        cts: this.uiJsonForEdit?.cts,
        rateType: this.uiJsonForEdit?.rateType,
        groupType: this.uiJsonForEdit?.groupType,
        clientName: this.uiJsonForEdit?.clientName,
        roughRemark: this.uiJsonForEdit?.roughRemark,
        sizeRange: {
          from: this.uiJsonForEdit?.sizeRange?.from,
          to: this.uiJsonForEdit?.sizeRange?.to,
        },
        avgGroupRate: {
          from: this.uiJsonForEdit?.avgGroupRate?.from,
          to: this.uiJsonForEdit?.avgGroupRate?.to,
        },
        perpieceRate: {
          from: this.uiJsonForEdit?.perpieceRate?.from,
          to: this.uiJsonForEdit?.perpieceRate?.to,
        },
        table: {
          from: this.uiJsonForEdit?.table?.from,
          to: this.uiJsonForEdit?.table?.to,
        },
        girdle: {
          from: this.uiJsonForEdit?.girdle?.from,
          to: this.uiJsonForEdit?.girdle?.to,
        },
        culet: {
          from: this.uiJsonForEdit?.culet?.from,
          to: this.uiJsonForEdit?.culet?.to,
        },
        diameter: {
          from: this.uiJsonForEdit?.diameter?.from,
          to: this.uiJsonForEdit?.diameter?.to,
        },
        lOrW: {
          from: this.uiJsonForEdit?.lOrW?.from,
          to: this.uiJsonForEdit?.lOrW?.to,
        },
        depth: {
          from: this.uiJsonForEdit.depth.from,
          to: this.uiJsonForEdit.depth.to,
        },
        width: {
          from: this.uiJsonForEdit?.width?.from,
          to: this.uiJsonForEdit?.width?.to,
        },
        length: {
          from: this.uiJsonForEdit?.length?.from,
          to: this.uiJsonForEdit?.length?.to,
        },
        cheight: {
          from: this.uiJsonForEdit?.cheight?.from,
          to: this.uiJsonForEdit?.cheight?.to,
        },
        cangle: {
          from: this.uiJsonForEdit?.cangle?.from,
          to: this.uiJsonForEdit?.cangle?.to,
        },
        pdepth: {
          from: this.uiJsonForEdit?.pdepth?.from,
          to: this.uiJsonForEdit.pdepth.to,
        },
        pangle: {
          from: this.uiJsonForEdit?.pangle?.from,
          to: this.uiJsonForEdit?.pangle?.to,
        },
        roughWeight: {
          from: this.uiJsonForEdit?.roughWeight?.from,
          to: this.uiJsonForEdit?.roughWeight?.to,
        },
        remark: this.uiJsonForEdit?.remark,
        dropdownJson: this.uiJsonForEdit?.dropdownJson,
        opinionOfUserId: this.uiJsonForEdit?.opinionOfUserId,
      };
      this.opinionOfUser = this.orderFormManagers.filter((dt: any) => dt.id == this.uiJsonForEdit?.opinionOfUserId);
      console.log(this.uiJsonForEdit.dropdownJson);
      this.preDefinedDropDownJSON = this.uiJsonForEdit.dropdownJson;
      this.dropdownJSONarray = [this.uiJsonForEdit.dropdownJson];
      for (const key in this.uiJsonForEdit?.dropdownJson || {}) {
        this.dropdownJSONarray.push({
          key: key,
          value: this.uiJsonForEdit?.dropdownJson[key],
        });
      }
      this.formData.yehuda = this.uiJsonForEdit?.["yehuda"];

      let dataArray = Object.keys(this.uiJsonForEdit.dropdownJson).map(key => {
        return {
          key: key,
          value: this.uiJsonForEdit.dropdownJson[key],
        };
      });
      console.log(dataArray);
      console.log(this.formData);
      this.filterDropdownarray = dataArray.filter(item => item.value.isData === true);
      console.log(this.filterDropdownarray);
      console.log(this.preDefinedDropDownJSON);
      if (this.isEditRoute) {
        let parsedRoughJson = JSON.parse(rowData.roughUIJson);
        console.log(parsedRoughJson);
        if (!!parsedRoughJson) {
          this.dropDownColorFromData["selectedData"] = [parsedRoughJson?.["From Color"]];
          this.dropDownColorToData["selectedData"] = [parsedRoughJson?.["To Color"]];
          this.dropDownFLFromData["selectedData"] = [parsedRoughJson?.["From Fluorescence"]];
          this.dropDownFLToData["selectedData"] = [parsedRoughJson?.["To Fluorescence"]];
          this.dropDownClarityFromData["selectedData"] = [parsedRoughJson?.["From Clarity"]];
          this.dropDownClarityToData["selectedData"] = [parsedRoughJson?.["To Clarity"]];
          this.dropDownTinchFromData["selectedData"] = [parsedRoughJson?.["From Tinch"]];
          this.dropDownTinchToData["selectedData"] = [parsedRoughJson?.["To Tinch"]];
          this.dropDownLusterFromData["selectedData"] = [parsedRoughJson?.["From Luster"]];
          this.dropDownLusterToData["selectedData"] = [parsedRoughJson?.["To Luster"]];
          this.dropDownTensionFromData["selectedData"] = [parsedRoughJson?.["From Tension"]];
          this.dropDownTensionToData["selectedData"] = [parsedRoughJson?.["To Tension"]];

          this.dropdown["From Color"] = parsedRoughJson?.["From Color"];
          this.dropdown["To Color"] = parsedRoughJson?.["To Color"];
          this.dropdown["From Fluorescence"] = parsedRoughJson?.["From Fluorescence"];
          this.dropdown["To Fluorescence"] = parsedRoughJson?.["To Fluorescence"];
          this.dropdown["From Clarity"] = parsedRoughJson?.["From Clarity"];
          this.dropdown["To Clarity"] = parsedRoughJson?.["To Clarity"];
          this.dropdown["From Tinch"] = parsedRoughJson?.["From Tinch"];
          this.dropdown["To Tinch"] = parsedRoughJson?.["To Tinch"];
          this.dropdown["From Luster"] = parsedRoughJson?.["From Luster"];
          this.dropdown["To Luster"] = parsedRoughJson?.["To Luster"];
          this.dropdown["From Tension"] = parsedRoughJson?.["From Tension"];
          this.dropdown["To Tension"] = parsedRoughJson?.["To Tension"];
        }
      }
    }
  }

  deleteOrder(evt) {
    console.log("deleting");
    let rowData: any = evt.row.data;
    console.log(rowData);
    if (rowData) {
      let ID: number = rowData.id;
      let deleteAPIEndpoint: string = `${"order-form/delete/"}${ID}`;

      this.httpService.deleteData(true, deleteAPIEndpoint).subscribe((result: any) => {
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          // this.store.dispatch(SignerAction.getAllOrderForm()); //refreshing data after deleted
          this.store.dispatch(SignerAction.getOrderForm());

          this._ConfigService.showToast("success", result.message);
        } else {
          this._ConfigService.showToast("error", "Error In Deleting Selected Order Form");
        }
      });
    }
  }

  async handleDivClick(a) {
    console.log(a);
    this.criteriaValForView = "";
    if (!!this.dropdownJSONarray) {
      this.selectedCriteriaarray = this.dropdownJSONarray;
      console.log(this.dropdownJSONarray);

      this.criteriaValForView = this.dropdownJSONarray.find(obj => obj.key === a)?.value.datas;
      console.log(this.criteriaValForView);
    }

    // to empty array of in payload
    Object.keys(this.saveInJsonPayload.in).forEach(key => {
      this.saveInJsonPayload.in[key] = [];
    });
    // to empty array of in payload
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "orderFormCriteriaModal",
      componentProps: {
        dropdownparameter: a,
        forPage: "OrderFormCriteria",
        selectedarrayOnBasicOfCriteria: this.dropdownJSONarray,
      },

      // event: ev,
      // translucent: true
    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      if (res.data) {
        console.log(res.data);

        this.criteria = res.data.criteria;
        console.log(this.criteria);
        if (!!this.preDefinedDropDownJSON[this.criteria].datas) {
          this.preDefinedDropDownJSON[this.criteria].datas = res.data.selectDatas;
          if (res.data.selectDatas.length > 0) {
            console.log("getting true");

            this.preDefinedDropDownJSON[this.criteria].isData = true;
          } else {
            console.log("getting false");
            this.preDefinedDropDownJSON[this.criteria].isData = false;
          }
          console.log(res.data.selectDatas);
          this.dropdownDataToPush = res.data.selectDatas;
        }

        console.log(this.preDefinedDropDownJSON);
        this.dropdownJSONarray = Object.keys(this.preDefinedDropDownJSON).map(key => {
          return {
            key: key,
            value: this.preDefinedDropDownJSON[key],
          };
        });
        console.log(this.dropdownJSONarray);
        this.filterDropdownarray = this.dropdownJSONarray.filter(item => item.value.isData === true);
        console.log(this.filterDropdownarray);
        if (!!this.dropdownDataToPush) {
          // this.saveJsonPayload["in"].Color.push(1);
          // this.saveJsonPayload["in"].Shape.push(1);
        }
      }
    });
  }

  onSaveClick(param) {
    // this.filteredDatas=this.dropdownDataToPush
    this.saveRangeJsonPayload = {
      priorityRange: {
        Color: {
          paramNameId: null,
          value: {
            lowerPriorityLimitId: this.dropdown["From Color"]?.id,
            upperPriorityLimitId: this.dropdown["To Color"]?.id,
          },
        },
        Clarity: {
          paramNameId: null,
          value: {
            lowerPriorityLimitId: this.dropdown["From Clarity"]?.id,
            upperPriorityLimitId: this.dropdown["To Clarity"]?.id,
          },
        },
        Luster: {
          paramNameId: null,
          value: {
            lowerPriorityLimitId: this.dropdown["From Luster"]?.id,
            upperPriorityLimitId: this.dropdown["To Luster"]?.id,
          },
        },
        "Tension Mapping": {
          paramNameId: null,
          value: {
            lowerPriorityLimitId: this.dropdown["From Tension"]?.id,
            upperPriorityLimitId: this.dropdown["To Tension"]?.id,
          },
        },
        Fluorescence: {
          paramNameId: null,
          value: {
            lowerPriorityLimitId: this.dropdown["From Fluorescence"]?.id,
            upperPriorityLimitId: this.dropdown["To Fluorescence"]?.id,
          },
        },
        Tinch: {
          paramNameId: null,
          value: {
            lowerPriorityLimitId: this.dropdown["From Tinch"]?.id,
            upperPriorityLimitId: this.dropdown["To Tinch"]?.id,
          },
        },
      },
      range: {
        expPol: {
          lowerLimit: this.formData.sizeRange.from,
          upperLimit: this.formData.sizeRange.to,
        },
        avgGroupRate: {
          lowerLimit: this.formData.avgGroupRate.from,
          upperLimit: this.formData.avgGroupRate.to,
        },
        perPieceRate: {
          lowerLimit: this.formData.perpieceRate.from,
          upperLimit: this.formData.perpieceRate.to,
        },
        tableVal: {
          lowerLimit: this.formData.table.from,
          upperLimit: this.formData.table.to,
        },
        lengthMM: {
          lowerLimit: this.formData.length.from,
          upperLimit: this.formData.length.to,
        },
        widthMM: {
          lowerLimit: this.formData.width.from,
          upperLimit: this.formData.width.to,
        },
        girdle: {
          lowerLimit: this.formData.girdle.from,
          upperLimit: this.formData.girdle.to,
        },
        culet: {
          lowerLimit: this.formData.culet.from,
          upperLimit: this.formData.culet.to,
        },
        diameter: {
          lowerLimit: this.formData.diameter.from,
          upperLimit: this.formData.diameter.to,
        },
        lOrW: {
          lowerLimit: this.formData.lOrW.from,
          upperLimit: this.formData.lOrW.to,
        },
        height: {
          lowerLimit: this.formData.depth.from,
          upperLimit: this.formData.depth.to,
        },
        crHeight: {
          lowerLimit: this.formData.cheight.from,
          upperLimit: this.formData.cheight.to,
        },
        crAngle: {
          lowerLimit: this.formData.cangle.from,
          upperLimit: this.formData.cangle.to,
        },
        pavHeight: {
          lowerLimit: this.formData.pdepth.from,
          upperLimit: this.formData.pdepth.to,
        },
        pavAngle: {
          lowerLimit: this.formData.pangle.from,
          upperLimit: this.formData.pangle.to,
        },
      },
    };
    //  code to give value null is no value is present
    for (let key in this.saveRangeJsonPayload.range) {
      if (!this.saveRangeJsonPayload.range[key].lowerLimit && !this.saveRangeJsonPayload.range[key].upperLimit) {
        this.saveRangeJsonPayload.range[key] = null;
      }
    }
    this.filterDropdownPayload = {};
    Object.entries(this.preDefinedDropDownJSON).forEach(([key, value]) => {
      if (value.isData) {
        this.filterDropdownPayload[key] = value.datas.map(item => item.id);
      }
    });
    console.log(this.filterDropdownPayload);
    console.log(this.preDefinedDropDownJSON);
    Object.keys(this.saveInJsonPayload.in).forEach(key => {
      this.saveInJsonPayload.in[key] = [];
    });
    Object.entries(this.filterDropdownPayload).forEach(([key, value]) => {
      console.log(value);
      console.log(key);

      if (this.saveInJsonPayload.in.hasOwnProperty(key)) {
        Array.prototype.push.apply(this.saveInJsonPayload.in[key], value);
      }
      console.log(this.saveInJsonPayload);
    });
    this.newSaveInJson = { in: {} };
    const keys = Object.keys(this.saveInJsonPayload.in);
    console.log(keys);
    for (let key of keys) {
      let newKey = `${key.toLowerCase()}Id`;
      this.newSaveInJson.in[newKey] = this.saveInJsonPayload.in[key];
    }
    console.log(this.saveInJsonPayload);
    console.log(this.newSaveInJson);
    this.finalsaveJsonPayload = {
      ...this.newSaveInJson,
      ...this.saveRangeJsonPayload,
    };
    if (param == "save") {
      console.log(this.finalsaveJsonPayload);
      console.log(this.selectedTab);
      if (this.selectedTab == "RoughCriteria") {
        this.finalRoughtCriteriaJson = this.saveRangeJsonPayload;
        this.finalRoughtCriteriaJson.equals = {
          "yehuda -Color": {
            paramNameId: null,
            paramValue: this.roughYehuda,
          },
        };
        delete this.finalRoughtCriteriaJson["range"];
      }
      this.saveOrderFormApi(this.finalsaveJsonPayload);
    } else if (param == "update") {
      if (this.selectedTab == "RoughCriteria") {
        this.finalRoughtCriteriaJson = this.saveRangeJsonPayload;
        this.finalRoughtCriteriaJson.equals = {
          "yehuda -Color": {
            paramNameId: null,
            paramValue: this.roughYehuda,
          },
        };
        delete this.finalRoughtCriteriaJson["range"];
      }
      this.onUpdateClick(this.finalsaveJsonPayload);
    }
  }

  onUpdateClick(finalUpdateJsonPayload) {
    console.log(this.updateId);
    let payload = {
      id: this.updateId,
      priority: this.formData.priority,
      clientId: 1,
      clientName: this.formData.clientName,
      orderName: this.formData.orderNumber,
      criteria: JSON.stringify(finalUpdateJsonPayload),
      cts: this.formData.cts,
      orderDate: this.formData.orderDate,
      pcs: this.formData.pieces,
      priceAndSizeRange: "hjcx",
      opinionOfUserId: this.formData["opinionOfUserId"],
      groupType: this.formData.groupType,
      perPieceRate: this.formData.perpieceRate.from,
      rateType: this.formData?.rateType?.[0],
      origin: this.formData?.origin,
      roughWeight: JSON.stringify(this.formData.roughWeight),
      roughRemarks: this.formData.roughRemark,
      remark: this.formData.remark,
      roughCriteria: JSON.stringify(this.finalRoughtCriteriaJson),
      targetDate: this.formData.targetDate,
      uiJson: JSON.stringify(this.formData),
      roughUIJson: JSON.stringify(this.uiJsonOfRoughtCriteria),
    };

    console.log(payload);
    this.httpService.saveData(true, "order-form/save", payload).subscribe((result: any) => {
      console.log("%cPacket-order Save......", "color:orange;font-size:18px;");
      console.log(result);
      this.httpService.getErrorAndDisplayIt(result);
      if (result.statusCode == 200) {
        console.log("Success");
        this.store.dispatch(SignerAction.getOrderForm());
        this.currentView = "orderFormGrid";
        this.emptyFormData();
        this.dropdownJSONarray = null;
        this.isEditRoute = false;
      } else {
        console.log(result);
        console.error("Error");
      }
    });
  }
  onClosePress(data, key) {
    Object.keys(this.saveInJsonPayload.in).forEach(key => {
      this.saveInJsonPayload.in[key] = [];
    });

    console.log(data);
    console.log(key);
    console.log(this.preDefinedDropDownJSON);
    if (this.preDefinedDropDownJSON && this.preDefinedDropDownJSON[key] && this.preDefinedDropDownJSON[key].isData) {
      this.filteredDatas = this.preDefinedDropDownJSON[key].datas.filter(item => item.id !== data.id);
      console.log(this.filteredDatas);
      if (this.filteredDatas.length === 0) {
        console.log("coming here");
        this.preDefinedDropDownJSON[key].isData = false;
      }
      this.preDefinedDropDownJSON[key].datas = this.filteredDatas;
      console.log(this.preDefinedDropDownJSON);
    }
  }

  openOrderForm() {
    this.selectedTab = "PolishCriteria";
    this.currentView = "orderSelectionForm";
  }

  saveOrderFormApi(finalSaveJsonPayload) {
    if (finalSaveJsonPayload?.priorityRange) delete finalSaveJsonPayload.priorityRange;
    this.uiJsonOfRoughtCriteria.yehuda = this.formData?.yehuda;
    let payload = {
      priority: this.formData.priority,
      clientId: 1,
      clientName: this.formData.clientName,
      orderName: this.formData.orderNumber,
      opinionOfUserId: this.formData["opinionOfUserId"],
      groupType: this.formData.groupType,
      perPieceRate: this.formData.perpieceRate.from,
      criteria: JSON.stringify(finalSaveJsonPayload),
      cts: this.formData.cts,
      rateType: this.formData?.rateType?.[0],
      orderDate: this.formData.orderDate,
      pcs: this.formData.pieces,
      priceAndSizeRange: "hjcx",
      remark: this.formData.remark,
      targetDate: this.formData.targetDate,
      uiJson: JSON.stringify(this.formData),
      origin: this.formData?.origin,
      roughWeight: JSON.stringify(this.formData.roughWeight),
      roughCriteria: JSON.stringify(this.finalRoughtCriteriaJson),
      roughRemarks: this.formData.roughRemark,
      roughUIJson: JSON.stringify(this.uiJsonOfRoughtCriteria),
    };

    this.httpService.saveData(true, "order-form/save", payload).subscribe((result: any) => {
      this.httpService.getErrorAndDisplayIt(result);
      if (result.statusCode == 200) {
        this._ConfigService.showToast("success", result.message);
        this.store.dispatch(SignerAction.getOrderForm());
        this.currentView = "orderFormGrid";
        this.emptyFormData();
        this.dropdownJSONarray = null;
        this.isEditRoute = false;
      } else {
        this._ConfigService.showToast("error", result.error);
      }
    });
  }

  keyUp(event: any) {
    const pattern = /[0-9\+\ ]/;
    let inputChar = String.fromCharCode(event.key);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  emptyFormData() {
    this.preDefinedDropDownJSON = {
      Color: {
        datas: [],
        isData: false,
      },
      Clarity: {
        datas: [],
        isData: false,
      },
      Fluorescence: {
        datas: [],
        isData: false,
      },
      Milkyness: {
        datas: [],
        isData: false,
      },
      Natts: {
        datas: [],
        isData: false,
      },
      Polish: {
        datas: [],
        isData: false,
      },
      Shape: {
        datas: [],
        isData: false,
      },

      Symmetry: {
        datas: [],
        isData: false,
      },

      CUT: {
        datas: [],
        isData: false,
      },

      Tinch: {
        datas: [],
        isData: false,
      },
      Topsopens: {
        datas: [],
        isData: false,
      },

      Botsopen: {
        datas: [],
        isData: false,
      },
    };
    this.filterDropdownarray = [];
    this.formData = {
      orderNumber: null,
      orderDate: "",
      targetDate: "",
      priority: "",
      pieces: "",
      cts: "",
      sizeRange: {
        from: "",
        to: "",
      },
      avgGroupRate: {
        from: "",
        to: "",
      },
      perpieceRate: {
        from: "",
        to: "",
      },
      table: {
        from: "",
        to: "",
      },
      roughWeight: {
        from: "",
        to: "",
      },
      girdle: {
        from: "",
        to: "",
      },
      width: {
        from: "",
        to: "",
      },
      length: {
        from: "",
        to: "",
      },
      culet: {
        from: "",
        to: "",
      },
      diameter: {
        from: "",
        to: "",
      },
      lOrW: {
        from: "",
        to: "",
      },
      depth: {
        from: "",
        to: "",
      },
      cheight: {
        from: "",
        to: "",
      },
      cangle: {
        from: "",
        to: "",
      },
      pdepth: {
        from: "",
        to: "",
      },
      pangle: {
        from: "",
        to: "",
      },
      remark: "",
      dropdownJson: this.preDefinedDropDownJSON,
    };
  }
}
