import { Component, OnInit } from '@angular/core';
import { AppState } from '../reducers';
import { ModalController } from '@ionic/angular';
import { HttpService } from '../services/http/http.service';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dbcmakeexcel',
  templateUrl: './dbcmakeexcel.component.html',
  styleUrls: ['./dbcmakeexcel.component.scss'],
})
export class DbcmakeexcelComponent implements OnInit {
  columns: any[];
  id: any;
  dataSource:any;
  selectedRows: any;
  selectedRow: any;
  excelDataSource: any;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    public httpService: HttpService,
    private configService: ConfigService,
    private loaderService: LoaderService,
  ) {
    this.columns = [
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      { name: 'packet Num', prop: 'pktNo' },
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'Current Action', prop: 'currActionName' },
    ];
  }

  ngOnInit() {
    this.fecthGridData()
  }



  barcodeDetection(event) {
    console.log(event);
  }

  clearBarcode() {}

  onSelectionChanged(event) {
    console.log(event);
    this.selectedRows = event?.selectedRowsData;
    this.selectedRow = event?.selectedRowsData[0];
  }

  fecthGridData() {
    let endpoint: string = `packet/getPacketsAvaiableInDbc`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log('change Status');
        console.log(res);
        let resultCode: number = res.statusCode;
        console.log(resultCode);
        let d: any = res.data;
        console.log(d);
        if (resultCode == 200) {
          this.dataSource = res.data;
          this.getExcelData(this.dataSource);
        } else {
          this.configService.showToast('Error', res.error);
        }
      }
    });
  }

  async getExcelData(data) {
    if (!!data) {
      console.log(data);
      const payloadForExcel = data.map((obj) => obj.id);
      console.log(payloadForExcel);
      let payload = { packetIds: payloadForExcel };
      let response = await this.httpService.postDataUsingPromise(
        true,
        'packet/getDoppingValueOnBasisOfPacketIds',
        payload
      );
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        console.log(response.data);
        let data = response.message;
        console.log(data);
        this.excelDataSource = response.data;
        console.log(this.excelDataSource);

      } else {
        console.error(response);
        this.configService.showToast('error', response.error.message);
      }
    }
  }

  exportDataToExcel(): void {

    const fileName = 'export.xlsx'; // Specify the desired file name.
    const arrayOfIds = this.dataSource
  .filter((list) => !!list.id)
  .map((list) => list.id);
    console.log(this.dataSource, arrayOfIds)
    this.configService.createExcel(this.excelDataSource, fileName);
  }

}
