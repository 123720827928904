import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintPageComponent } from './print-page.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PrintPageComponent],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule
  ],
  exports:[PrintPageComponent],
})
export class PrintPageModule { }
