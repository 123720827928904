import { Injectable, TemplateRef } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { Cls_DynamicDialogConfig } from "src/app/tempDataStore/tempData.model";

@Injectable({
  providedIn: "root",
})
export class DynamicDrawerService {
  constructor(private menuController: MenuController) {}

  // Drawer Content
  dynamicDrawerConfig = new BehaviorSubject<Cls_DynamicDialogConfig>({
    innerContent: null,
    isOpen: false,
  });

  async openDrawer(config: Cls_DynamicDialogConfig) {
    this.dynamicDrawerConfig.next(config);
    this.dynamicDrawerConfig.subscribe(async (res: Cls_DynamicDialogConfig) => {
      const { isOpen } = res;
      if (isOpen) await this.menuController.toggle("menu");
    });
  }

  async closeDrawer() {
    await this.menuController.close("menu");
  }

  get drawerConfig(): Cls_DynamicDialogConfig {
    return this.dynamicDrawerConfig.value;
  }
}
