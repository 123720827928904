import { UsersByAction, UsersByVirtualAction } from "./issue.data";

// debugger
export interface WithMeState {
    startFrom: any;
    usersByAction: UsersByAction[];
    usersByVirtualAction: UsersByVirtualAction[];
    
    actionsByPacket: any;
    actionsByVirtualPacket:any;
    processnoteByActionID: any;
    // machinesByUser: MachinesByUser[]
}

export const initialWithMeState: WithMeState = {
    startFrom: '',
    actionsByPacket: [],
    actionsByVirtualPacket:[],
    usersByAction: [],
    usersByVirtualAction:[],
    processnoteByActionID: [],
    // machinesByUser: []
};
