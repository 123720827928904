import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-packet-route-display-drawer',
  templateUrl: './packet-route-display-drawer.component.html',
  styleUrls: ['./packet-route-display-drawer.component.scss'],
})

export class PacketRouteDisplayDrawerComponent implements OnInit {

  @Input() packetDetails: any;

  @Output() closeDrawerEvent: EventEmitter<any> = new EventEmitter();

  packetId: string;
  packetRoute: any[];

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
  ) {}

  ngOnInit() {}

  closeDrawer($event: Event) {
    this.closeDrawerEvent.emit($event);
  }

  drawerOpened(){
    this.packetId = this.packetDetails.packetId;
    // this.packetRoute = [
    //   {
    //     deptId: 1,
    //     name: "Test Dept 1",
    //     status: 1
    //   },
    //   {
    //     deptId: 2,
    //     name: "Test Dept 2",
    //     status: 1
    //   },
    //   {
    //     deptId: 3,
    //     name: "Test Dept 3",
    //     status: 2
    //   },
    //   {
    //     deptId: 4,
    //     name: "Test Dept 4",
    //     status: 3
    //   },
    //   {
    //     deptId: 5,
    //     name: "Test Dept 5",
    //     status: 3
    //   },
    // ]
    this.httpService.getRouteDetailsOfPacket(this.packetDetails.id).subscribe((res) => {
      if (res.statusCode == 200) {
        this.packetRoute = res.data.sort((obj1, obj2) => obj1.status - obj2.status);
      } else {
        this.configService.showToast("error", "Failed to Get the Packet Route");
      }
    })
  }
}