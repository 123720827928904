import { childParameterReducer, masterParameterReducer } from "./masters.reducers";

  export interface Int_GET_MasterParameter {
    masterList: any[];
    isLoaded: boolean;
  }

  export interface Int_GET_ChildParameters {
    parameters: any;
    isLoaded: boolean;
  }

  export interface MasterState {
    MASTER_PARAMETERS: Int_GET_MasterParameter;
    CHILD_PARAMETERS: Int_GET_ChildParameters;
  }
  
  export const masterReducer = {
    MASTER_PARAMETERS: masterParameterReducer,
    CHILD_PARAMETERS:childParameterReducer
  };