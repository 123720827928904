import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderComponentModule } from '../components/header/header.module';
import { WidthAlertModule } from '../width-alert/width-alert.module';
import { ColorInspectionComponent } from './color-inspection.component';
import { SingleMultiDropdownModule } from "../components/single-multi-dropdown/single-multi-dropdown.module";

@NgModule({
    declarations: [
        ColorInspectionComponent
    ],
    entryComponents: [],
    exports: [ColorInspectionComponent],
    providers: [],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        WidthAlertModule,
        IonicModule,
        HeaderComponentModule,
        SingleMultiDropdownModule,
    ]
})
export class ColorInspectionModule { }
