import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { packetCounts } from '../home/state/home.selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ParamHistoryGridComponent } from '../components/param-history-grid/param-history-grid.component';

@Component({
  selector: 'app-dept-wise-parameters',
  templateUrl: './dept-wise-parameters.component.html',
  styleUrls: ['./dept-wise-parameters.component.scss'],
})
export class DeptWiseParametersComponent implements OnInit {

  count: number | string;
  columns: object[] = [
    { name: "Lot No", prop: "lotNo",columnTemplate:'lot-svg-template' },
    { name: "Packet ID", prop: "packetId" },
    { name: "user", prop: "fullName" },
    { name: "Current Action", prop: "actionName" },
  ];
  dataSource: any;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private modalController: ModalController,
  ) { }



  ngOnInit() {
    this.getYehudaDetails();
    this.store.pipe(select(packetCounts)).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      if(data){
        this.count=data?.[0]?.count;
      }
    });
  }

  onSelectionChanged(event: any){
    const packetId = event?.row?.id;
    // const packetId = 2; // test
    let endpoint: string = `packet-parameter-on-each-dept/findPacketParametersEachDeptWise/${packetId}`;
    this.httpService.fetchData(true,endpoint).subscribe(async (res: any) => {
      if (res?.statusCode == 200 && res?.data?.data){
        const Modal = await this.modalController.create({
          component: ParamHistoryGridComponent,
          cssClass: ['finalInspection'],
          componentProps: {
            data: {data:  res.data.data, id: packetId},
          },
        });
        await Modal.present();
      }
    });
  }

  getYehudaDetails(){
    let endpoint: string = `packet/getPacketLocation`;
    this.httpService.getData(true,endpoint,{}).subscribe((res: any) => {
      if (res?.statusCode == 200) 
      this.dataSource = res.data;
    });
  }

}
