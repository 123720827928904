import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private loaderCount = new BehaviorSubject<number>(0);
  loaderCount$ = this.loaderCount.asObservable();
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  constructor() {}

  showSpinner() {
    this.loaderCount.next(this.loaderCount.value + 1);
    // this._loading.next(true);
  }
  hideSpinner() {
    if (this.loaderCount.value > 0) {
      this.loaderCount.next(this.loaderCount.value - 1);
    }
  }
}
