import { createFeatureSelector, createSelector } from "@ngrx/store";
import { saveAutoReceivePacket } from "./auto-receive.action";
import { AutoReceiveState } from "./auto-receive.state";


// debugger
export const selectAutoReceiveState = createFeatureSelector<AutoReceiveState>(
    'auto_receive',
);

export const getScannedPacketData = createSelector(selectAutoReceiveState, state => {
    return state.scannedPacketDetails;
});
export const getAutoReceivePacketSelector = createSelector(selectAutoReceiveState, state => {
    return state.saveAutoReceivePacket;
});