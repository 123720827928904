import { createReducer, on } from "@ngrx/store";
import { QCActions } from "./qc-annotation.action.type";
import { initialQCAnnotationState } from "./qc-annotation.state";

export const annotationReducer = createReducer(
    initialQCAnnotationState,
    on(QCActions.saveDetailsFormData, (state, action) => {
        return {
            ...state,
            isProcessing: true
        };
    }),
    on(QCActions.saveDetailsFormResponse, (state, action) => {
        return {
            ...state,
            isProcessing: false,
            saveDetailsFormResponse: action.response
        };
    }),
    on(QCActions.removesaveDetailsFormResponse, (state, action) => {
        return {
            ...state,
            isProcessing: false,
            saveDetailsFormResponse: null
        };
    }),
    on(QCActions.updateDetailsFormData, (state, action) => {
        return {
            ...state,
            isUpdateLoading: true
        }
    }),
    on(QCActions.updateDetailsFormDataSuccess, (state, action) => {
        return {
            ...state,
            isUpdateLoading: false,
            updateDetailSuccess: action.success,
        }
    }),
    on(QCActions.updateDetailsFormDataFailure, (state, action) => {
        return {
            ...state,
            isUpdateLoading: false,
            updateFailure: action.error
        }
    }),
    on(QCActions.removeUpdateDetailsFormDataSuccess, (state, action) => {
        return {
            ...state,
            isUpdateLoading: false,
            updateDetailSuccess: null
        }
    }),
    on(QCActions.saveAllPacketWhoseFinalPlanningIsDoneBYSuratChecker, (state, action) => {
        return {
            ...state,
            detailsOfPlansDoneBySuratChecker: action.getPlansWhoseFinalPlanningIsDoneBYSuratChecker
        };
    })
    


)