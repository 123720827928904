import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader/loader.service';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { ConfigService } from '../services/config/config.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import Query from 'devextreme/data/query';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import { MultipleBarcodeScannerComponent } from '../components/multiple-barcode-scanner/multiple-barcode-scanner.component';
import { ScannerinputComponent } from '../components/scannerinput/scannerinput.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-live-packet-report',
  templateUrl: './live-packet-report.component.html',
  styleUrls: ['./live-packet-report.component.scss'],
})
export class LivePacketReportComponent implements OnInit {
  columns: any;
  project: string = environment.project;
  public dataSource: any = [];
  public pageNumber: number = 1;
  public pageSize: number = 50;
  dataLoaded = false;
  public PageInfo: any = {
    limit: this.pageSize,
    page: 0,
  };
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  modifiedDataSource: any;
  count: number = 50;
  packetId: any;
  payload: { packetId: any };
  masterData = [];
  filterPayload: any = { filter: {} };
  barcodeInputs: any = '';
  scanned: boolean;
  refreshGrid: boolean = false;
  barcodeInputList: string[] = [];
  selectedRows: any = [];
  isStoneSelected: boolean = false;
  rowsIndex: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    private modalCtrl: ModalController,
    private loaderService: LoaderService,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private navCtrl: NavController
  ) {}

  async ngOnInit() {
    // this.httpService
    // .fetchData(true, 'packet/getPacketReportForUi?limit=10&page=1')
    // .subscribe((res: any) => {
    //   console.log(res);
    //   this.dataSource = res?.data;
    //   this.count = 100;
    this.setPagination();
    //   debugger
    // });
    if (this.project == 'kg') {
      this.columns = [
        { name: 'Lot No', prop: 'LotNo', columnTemplate: 'lot-svg-template' },
        { name: 'Packet', prop: 'PacketID' },
        { name: 'CTG', prop: 'Ctg' },
        // { name: "From Dept", prop: "fromDept" },
        { name: 'DBC', prop: 'DBCFiles' },
        { name: 'QC Check', prop: 'QcCheckAndVerify' },
        { name: 'Planning type', prop: 'PlanType' },
        {
          name: 'Rough Diamond Planning 1 Assign Date',
          prop: 'Rough_Diamond_Planning_1_Assign_Date',
        },
        {
          name: 'Rough Diamond Planning 1 Done Date',
          prop: 'Rough_Diamond_Planning_1_Done_Date',
        },
        {
          name: 'Rough Diamond Planning 1 User',
          prop: 'Rough_Diamond_Planning_1_User',
        },
        {
          name: 'Rough Diamond Planning 2 Assign Date',
          prop: 'Rough_Diamond_Planning_2_Assign_Date',
        },
        {
          name: 'Rough Diamond Planning 2 Done Date',
          prop: 'Rough_Diamond_Planning_2_Done_Date',
        },
        {
          name: 'Rough Diamond Planning 2 User',
          prop: 'Rough_Diamond_Planning_2_User',
        },
      ];

      const res: any = await this.httpService
        .getKgPreVirtualPlanningStatusReportForUi()
        .toPromise();
      if (!!res.data) {
        this.modifiedDataSource = null
        this.dataSource = res.data?.data;
        let filePath = res.data?.uploadedFilePath
        let newFileName = filePath.split("/")
        let joinedPath = newFileName.slice(1).join('/');
        window.open(this.configService.getCentralFileUrl() + newFileName[0] + "/" +encodeURIComponent(joinedPath),'download');
      }
    } else {
      this.columns = [
        { name: 'Lot No', prop: 'lotNo', columnTemplate: 'lot-svg-template' },
        { name: 'Packet', prop: 'packetId' },
        { name: 'CTG', prop: 'ctg' },
        // { name: "From Dept", prop: "fromDept" },
        { name: 'DBC', prop: 'dbc' },
        { name: 'QC Check', prop: 'qcCheck' },
        { name: 'Planning type', prop: 'type' },
        { name: 'Tia Planning Dept', prop: 'tiaPlanning' },
        { name: 'Vardhan Planning Dept', prop: 'vardhanPlanning' },
        { name: 'Icchapore Planning Dept', prop: 'ichaporePlanning' },
        { name: 'Checker Final Plan', prop: 'finalPlanning' },
      ];

      this.httpService.currentPacketReportUi$.subscribe((data) => {
        if (data && data.packetDetails) {
          this.dataLoaded = true;
          this.count = data.totalCount;

          // const searchedPacketIds = this.barcodeInputList || [this.barcodeInputs];
          // this.dataSource = data.packetDetails.filter(detail => searchedPacketIds.includes(detail.packetId));

          this.dataSource = data.packetDetails.map((detail) => ({
            ...detail,
            type: 'virtual',
            vardhanPlanning: detail['Vardhan Department-Planning']
              ? detail['Vardhan Department-Planning'] === 1
                ? 'completed'
                : detail.vardhanPlanning
              : '-',
            qcCheck: detail['QC-Qc Check And Verify']
              ? detail['QC-Qc Check And Verify'] === 1
                ? 'completed'
                : detail.vardhanPlanning
              : '-',
            ichaporePlanning: detail['ichapur Planning Department-Planning']
              ? detail['ichapur Planning Department-Planning'] === 1
                ? 'completed'
                : detail.ichaporePlanning
              : '-',
            tiaPlanning: detail['tieaenterprise planning department-Planning ']
              ? detail['tieaenterprise planning department-Planning '] === 1
                ? 'completed'
                : detail.tiaPlanning
              : '-',
          }));
        }
      });
    }
  }

  async packetScanned(barcode) {
    this.barcodeInputs = !!barcode ? barcode.trim() : null;
    this.PageInfo = {
      limit: 10,
      page: 0,
    };
    this.setPagination();
    this.scanned = true;
    setTimeout(() => {
      this.scanned = false;
    }, 1000);
  }

  cancel() {
    this.payload = null;
    this.scannerComponet.onResetForm();
    this.barcodeInputs = '';
    this.selectedRows = [];
    this.rowsIndex = [];
    this.barcodeInputList = [];
    this.setPagination();
    this.refreshGrid = true;
    // this.isStoneSelected = false;
    this.dataSource = this.masterData;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: 'multiplBarcodeScanner',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'done') {
      this.barcodeInputList = data.map((barcode) => barcode.barcode);
      this.setPagination();
    }
  }

  onSelectionChanged(event) {
    // this.selectedRows = event.selectedRowsData;
  }

  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case 'paging.pageSize':
        let size = evt.value;
        this.PageInfo['limit'] = size;
        this.setPagination();
        break;
      case 'paging.pageIndex':
        let pageIndex = evt.value;
        this.PageInfo['page'] = pageIndex;
        this.setPagination();
        break;
    }
  }

  async setPagination() {
    let payload = null;

    if (!!this.barcodeInputs) {
      this.payload = { packetId: this.barcodeInputs.trim() }; //toFiltter grid Data by barcodeInputs
      // console.log("payload" , this.payload);
    } else if (!!this.barcodeInputList && this.barcodeInputList.length > 0) {
      // console.log(this.barcodeInputList);
      this.payload = { packetId: this.barcodeInputList };
    }

    this.modifiedDataSource = new CustomStore({
      key: 'packetId',
      load: async (loadOptions: any) => {
        console.log('loadOptions ', loadOptions);
        let dataToFilter: any = [];
        if (!!loadOptions.filter) {
          ['filter'].forEach(function (i) {
            const optionValue = loadOptions[i as keyof LoadOptions];
            if (i in loadOptions) {
              console.log(loadOptions[i]);
              dataToFilter.push(loadOptions[i]);
              console.log(dataToFilter);
            }
          });
          if (Array.isArray(dataToFilter[0][0])) {
            dataToFilter[0].forEach((info, index) => {
              if (index % 2 === 0) {
                this.filterPayload.filter[info[0]] = info[2];
              }
            });
          } else {
            this.filterPayload.filter = {
              [dataToFilter[0][0]]: dataToFilter[0][2],
            };
          }
          console.log(this.filterPayload);
        } else {
          this.filterPayload = { filter: {} };
        }

        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.PageInfo['limit'];
          } else {
            this.PageInfo['limit'] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.PageInfo['page'] = this.pageNumber;
          // if(this.dataSource.length == 0){

          let response: any = await this.httpService.getPacketReportForUi(
            this.PageInfo,
            // this.filterPayload
            this.payload
          );
          // console.log(this.payload)
          this.httpService.updateGetReportPacketDataForUi(response.data);
          // }

          return {
            data: Query(this.dataSource).toArray(),
            totalCount: this.count,
            groupCount: this.count,
          };
        } else {
          return {
            data: this.dataSource,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }
}
