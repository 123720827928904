import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import { FetchAllStoneParameter, FetchAllStoneParameterById } from '../masterDataStore/masterData.selector';
import { operators, preDefinedRouteJSON, routeMasterAPIs, RouteMasterData, routeTypes } from '../packet-route-master/state/packet-route-master.data';
import { AppState } from '../reducers';
import { ConfigService } from '../services/config/config.service';
import { HttpService } from '../services/http/http.service';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-route-criteria',
  templateUrl: './route-criteria.component.html',
  styleUrls: ['./route-criteria.component.scss'],
})
export class RouteCriteriaComponent implements OnInit {
  @Input() pageName: string;
  // @Input() criteriondata:[];
  @Input() message: any;
  @Input() hideParameterRow?: boolean;
  @Input() hideActionCol?: boolean;
  @Input() hideSubInput?: boolean;
  @Input() criteriondata: string[];
  @Input() resultCriterionData: any;
  @Input() criteriaTitle: string = '';
  @Output() saveFunction: EventEmitter<any> = new EventEmitter();
  public currentView: string = "packetRouterMaster";
  public formValues: any = null;
  public routeName: string = null;
  public weightage: number = null;
  public defaultWeightagePlaceMessage: string = 'eg. 100,50';
  public weightagePlaceMessage: string = "";
  private isDefault: boolean = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  //routemaster Data
  public routeMasterData: RouteMasterData[];
  public allMode: any;
  public selectedOperator: any;
  public columns: any;
  public id: any;
  public inputParamValue: any;
  inputFromParamValue: any;
  inputToParamValue: any;
  public parameter: any;
  public page: string = 'Route Master';
  public pageSize: number = 10;
  // v14.16.1.
  dropDownRouteTypeData: any = {
    name: 'Route Type',
    placeText: 'Select',
    options: routeTypes,
    selectedData: [{ id: 1, routeType: 'Department' }],
    propertName: 'routeType',
    modeFlag: 'single', //single/multiple
  };
  dropDownParameterData: any = {
    name: 'Parameter',
    placeText: 'Select',
    options: [],
    selectedData: [],
    propertName: 'parameter',
    modeFlag: 'single', //single/multiple
  };
  dropDownFromData: any = {
    name: 'From',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'fromParameter',
    modeFlag: 'single', //single/multiple
  };
  dropDownToData: any = {
    name: 'To',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'toParameter',
    modeFlag: 'single', //single/multiple
  };
  dropDownOperatorData: any = {
    name: 'Operator',
    placeText: 'Select',
    options: operators,
    selectedData: [],
    propertName: 'title',
    modeFlag: 'single', //single/multiple
  };
  dropDownInData: any = {
    name: 'In',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'name',
    modeFlag: null, //single/multiple
  };
  dropDownNotInData: any = {
    name: 'NotIn',
    placeText: 'Select Value',
    options: [],
    selectedData: [],
    propertName: 'name',
    modeFlag: null, //single/multiple
  };
  public parameters: any = [];
  public fromParameter: any = [];//from parameter for Equals & not Equals & range and priority range 
  public toParameter: any = [];//from parameter for range and priority range 
  // public isInputRequire: boolean = false;
  public criterionJSONData: any = {};
  public routeJSONData: any = {};
  public isAddDisabled: boolean = true;
  public routeCriteriaTableData: any = [];
  public selectedParameter: any;
  public selectedParameterValues: any;//multiple selected value
  public selectedFromParamValue: any;
  public selectedToParamValue: any;
  public selectedInNotInParamValue: any;//string commaseparated
  public currentOperatorObj: any;
  private selectJSONForm: any = null;
  public isDisableSaveRoute: boolean = true;
  routeFormJSON: any = {
    routeName: this.routeName,
    weightage: this.weightage,
    isDefault: this.isDefault,
    routeJSONData: {}
  };
  private suscriptionParam: Subscription;
  private suscriptionParam2: Subscription;
  public isInputDropdown: number = null;//0 for in dropdown and 1 for input box
  public criteriaFormJSON: any = {//test
    paramName: null,
    isAbsolute: null,
    operator: null,
    paramValue: null
  };
  private tempId: number = 0
  private currRowIndex: number = -1;
  rangeParamStrValue: string = "";
  private currentCriteriaRowData: any = null;
  public isEditable: boolean = false;
  public isParameterDisabled: boolean = false;
  public isOperatorDisabled: boolean = false;
  public isEditRoute: boolean = false;
  public hideSubmit: boolean = false;
  // public message: string;
  public criterion: unknown;
  public editMode: boolean = false;


  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    private configService: ConfigService,
    private alertController: AlertController,
    private store: Store<AppState>,
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.criteriondata);
    console.log("SSSSSSSSSSSSSSSSS", this.resultCriterionData, this.message, this.criteriaTitle)
    this.store.dispatch(masterDataActions.getAllStoneMaster());
    this.suscriptionParam = this.store.pipe(select(FetchAllStoneParameter)).subscribe(data => {
      if (data) {
        this.parameters = [];
        data.forEach((curr: any, k: number) => {
          let paramOb: object = {};
          // paramOb = { id: curr.id, parameter: curr.masterName },
          paramOb = { id: !!curr.id ? curr.id : k + 1, parameter: curr.parameters, isAbsolute: curr.isAbsolute },
            this.parameters.push(paramOb);
          // console.log(paramOb);
        });
        this.dropDownParameterData['options'] = this.parameters;
      }
    });
    if (this.pageName != 'routeSelection') {
      if (!!this.resultCriterionData && this.message == 'editCriteria') {
        this.hideSubmit = false;
        this.editMode = true;
        console.log(this.resultCriterionData);
        this.routeCriteriaTableData = [];
        this.routeCriteriaTableData = this.resultCriterionData
      }
      if (!!this.resultCriterionData && this.message == 'viewCriteria') {
        console.log(this.resultCriterionData);
        this.routeCriteriaTableData = [];
        this.routeCriteriaTableData = this.resultCriterionData;
      }
      console.log(this.message);
      console.log(this.criterion);
    }
    if (this.message == 'viewCriteria') {
      this.hideSubmit = true;
    } else {
      this.hideSubmit = false;
    }
    if (this.pageName == 'routeSelection' && this.criteriondata && this.criteriondata.length > 0) {
      console.log(this.criteriondata);
      this.routeCriteriaTableData = [];
      this.routeCriteriaTableData = this.criteriondata;
    }
  }
  checkAddParameterFormValidity() {
    console.log('checkAddParameterFormValidity');
    let operatorVal = !!this.currentOperatorObj && !!this.currentOperatorObj.operator ? this.currentOperatorObj.operator.toLowerCase() : "";
    let routeParamValues = [];
    console.log(operatorVal);
    if (this.criteriaFormJSON.isAbsolute === 0) {
      //inputboxes validation start
      if (operatorVal.includes('equal')) {
        Object.keys(this.criteriaFormJSON).forEach(key => {
          if (key == 'paramValue') {
            if (this.criteriaFormJSON[key]) {
              Object.keys(this.criteriaFormJSON[key]).forEach((ke: any) => {
                routeParamValues.push(this.criteriaFormJSON[key][ke]);
              });
            } else {
              routeParamValues.push(this.criteriaFormJSON[key]);
            }

          } else {
            routeParamValues.push(this.criteriaFormJSON[key]);
          }
        });
        console.log(routeParamValues);
        let falsyValues: any = routeParamValues.filter(d => d === null);
        console.log(falsyValues);
        if (routeParamValues.length === 0 || routeParamValues.includes('') || routeParamValues.includes('null') || routeParamValues.includes('undefined') || routeParamValues.includes(undefined) || falsyValues.length > 1) {
          this.isAddDisabled = true;
        } else {
          this.isAddDisabled = false;
        }
      } else if (operatorVal.includes('range')) {
        Object.keys(this.criteriaFormJSON).forEach(key => {
          console.log(key);
          if (key == 'paramValue') {
            Object.keys(this.criteriaFormJSON[key]).forEach((ke: any) => {
              console.log(ke, this.criteriaFormJSON[key][ke]);
              if (this.criteriaFormJSON.operator == 'priorityRange') {
                if (ke == 'paramNameId') {
                  routeParamValues.push('paramNameId');
                } else {
                  routeParamValues.push(this.criteriaFormJSON[key][ke]);
                }
              } else {
                routeParamValues.push(this.criteriaFormJSON[key][ke]);
              }

            });

          } else {
            routeParamValues.push(this.criteriaFormJSON[key]);
          }
        });
        console.log(routeParamValues);
        let falsyValues: any = routeParamValues.filter(d => d === null);
        console.log(falsyValues);
        if (routeParamValues.length === 0 || routeParamValues.includes('') || routeParamValues.includes('null') || routeParamValues.includes('undefined') || routeParamValues.includes(undefined) || falsyValues.length > 1) {
          this.isAddDisabled = true;
        } else {
          this.isAddDisabled = false;
        }
      } else if (operatorVal.includes('in')) {
        Object.keys(this.criteriaFormJSON).forEach(key => {
          console.log(key);
          if (key == 'paramValue') {
            Object.keys(this.criteriaFormJSON[key]).forEach((ke: any) => {
              console.log(ke, this.criteriaFormJSON[key][ke]);
              if (this.criteriaFormJSON.operator == 'in' || this.criteriaFormJSON.operator == 'notIn') {
                if (ke == 'paramNameId') {
                  routeParamValues.push('paramNameId');//Need to change later if there is ParamNaameId Available
                } else {
                  if (this.criteriaFormJSON[key][ke].length == 0) {
                    routeParamValues.push(null);
                  }
                }
              } else {
                routeParamValues.push(this.criteriaFormJSON[key][ke]);
              }
            });
          } else {
            routeParamValues.push(this.criteriaFormJSON[key]);
          }
        });
        console.log(routeParamValues);
        let falsyValues: any = routeParamValues.filter(d => d === null);
        console.log(falsyValues);
        if (routeParamValues.length === 0 || routeParamValues.includes('') || routeParamValues.includes('null') || routeParamValues.includes('undefined') || routeParamValues.includes(undefined) || falsyValues.length > 1) {
          this.isAddDisabled = true;
        } else {
          this.isAddDisabled = false;
        }
      } else {
        this.isAddDisabled = true;
      }
      //inputboxes validation End
    } else {
      //dropdowns
      //dropdowns validation start
      if (operatorVal.includes('equal')) {
        Object.keys(this.criteriaFormJSON).forEach(key => {
          if (key == 'paramValue') {
            if (this.criteriaFormJSON[key]) {
              Object.keys(this.criteriaFormJSON[key]).forEach((ke: any) => {
                routeParamValues.push(this.criteriaFormJSON[key][ke]);
              });
            } else {
              routeParamValues.push(this.criteriaFormJSON[key]);
            }

          } else {
            routeParamValues.push(this.criteriaFormJSON[key]);
          }
        });
        console.log(routeParamValues);
        let falsyValues: any = routeParamValues.filter(d => d === null);
        console.log(falsyValues);
        if (routeParamValues.length === 0 || routeParamValues.includes('') || routeParamValues.includes('null') || routeParamValues.includes('undefined') || routeParamValues.includes(undefined) || falsyValues.length > 1) {
          this.isAddDisabled = true;
        } else {
          this.isAddDisabled = false;
        }
      } else if (operatorVal.includes('range')) {
        Object.keys(this.criteriaFormJSON).forEach(key => {
          console.log(key);
          if (key == 'paramValue') {
            Object.keys(this.criteriaFormJSON[key]).forEach((ke: any) => {
              console.log(ke, this.criteriaFormJSON[key][ke]);
              if (this.criteriaFormJSON.operator == 'priorityRange') {
                if (ke == 'paramNameId') {
                  routeParamValues.push('paramNameId');
                } else {
                  routeParamValues.push(this.criteriaFormJSON[key][ke]);
                }
              } else {
                routeParamValues.push(this.criteriaFormJSON[key][ke]);
              }

            });

          } else {
            routeParamValues.push(this.criteriaFormJSON[key]);
          }
        });
        console.log(routeParamValues);
        let falsyValues: any = routeParamValues.filter(d => d === null);
        console.log(falsyValues);
        if (routeParamValues.length === 0 || routeParamValues.includes('') || routeParamValues.includes('null') || routeParamValues.includes('undefined') || routeParamValues.includes(undefined) || falsyValues.length > 1) {
          this.isAddDisabled = true;
        } else {
          this.isAddDisabled = false;
        }
      } else if (operatorVal.includes('in')) {
        Object.keys(this.criteriaFormJSON).forEach(key => {
          console.log(key);
          if (key == 'paramValue') {
            Object.keys(this.criteriaFormJSON[key]).forEach((ke: any) => {
              console.log(ke, this.criteriaFormJSON[key][ke]);
              if (this.criteriaFormJSON.operator == 'in') {
                if (ke == 'paramNameId') {
                  routeParamValues.push('paramNameId');//Need to change later if there is ParamNaameId Available
                } else {
                  if (this.criteriaFormJSON[key][ke].length == 0) {
                    routeParamValues.push(null);
                  }
                }
              } else {
                if (this.criteriaFormJSON.operator == 'notIn') {
                  if (ke == 'paramNameId') {
                    routeParamValues.push('paramNameId');//Need to change later if there is ParamNaameId Available
                  } else {
                    if (this.criteriaFormJSON[key][ke].length == 0) {
                      routeParamValues.push(null);
                    }
                  }
                } else {
                  routeParamValues.push(this.criteriaFormJSON[key][ke]);
                }
              }
            });
          } else {
            routeParamValues.push(this.criteriaFormJSON[key]);
          }
        });
        let falsyValues: any = routeParamValues.filter(d => d === null);
        console.log(falsyValues);
        console.log(routeParamValues);
        if (routeParamValues.length === 0 || routeParamValues.includes('') || routeParamValues.includes('null') || routeParamValues.includes('undefined') || routeParamValues.includes(undefined) || falsyValues.length > 1) {
          this.isAddDisabled = true;
        } else {
          this.isAddDisabled = false;
        }
      } else {
        this.isAddDisabled = true;
      }
      //dropdowns validation End
    }
    console.log(routeParamValues, this.isAddDisabled);
  }
  addCriteria() {
    /*console.log('addUpdateCriteria.....');
    console.log(this.isEditable);
    console.log(this.selectedParameter, this.selectedOperator, this.currentOperatorObj);
    console.log(this.routeCriteriaTableData);
    console.log(operators);
    console.log(preDefinedRouteJSON);
    console.log(this.routeJSONData);
    // if (!this.isEditable) {
    console.log('Add new Records Mode');
    console.log(this.currRowIndex);*/
    this.tempId += 1;
    this.criteriaFormJSON['tempId'] = this.tempId;
    this.criteriaFormJSON['operatorText'] = this.currentOperatorObj.title;
    // let operator: string = this.criteriaFormJSON && this.criteriaFormJSON.operator ? this.criteriaFormJSON.operator.toLowerCase() : ""
    // if (operator.includes('range') && this.criteriaFormJSON.isAbsolute == 1) {
    //   this.criteriaFormJSON['paramStrValue'] = this.rangeParamStrValue;
    // }
    // if (operator.includes('range') && this.criteriaFormJSON.isAbsolute == 0) {
    //   this.criteriaFormJSON['paramStrValue'] = `${this.inputFromParamValue} - ${this.inputToParamValue}`;
    // }

    // let existingCriteriaObj = this.routeCriteriaTableData.filter((d: any) => d.paramName == this.criteriaFormJSON['paramName'] && d.operator == this.criteriaFormJSON['operator']);
    let index = this.routeCriteriaTableData.findIndex((d: any) => d.paramName == this.criteriaFormJSON['paramName'] && d.operator == this.criteriaFormJSON['operator']);
    // console.log(index);
    if (this.currRowIndex !== -1) {
      this.routeCriteriaTableData[index] = this.criteriaFormJSON;
    } else {
      this.routeCriteriaTableData.push(this.criteriaFormJSON);
    }
    this.saveFunction.emit(this.routeCriteriaTableData);

    // } else {
    //   console.log('Editable Mode');
    // }

    // if (this.routeCriteriaTableData) {

    // }
    console.log(this.routeCriteriaTableData);
    // setTimeout(() => {
    this.dropDownParameterData['selectedData'] = [];
    this.dropDownOperatorData['selectedData'] = [];
    this.selectedParameter = null;
    this.selectedOperator = null;
    this.currentOperatorObj = null;
    this.rangeParamStrValue = "";
    this.criteriaFormJSON = {
      paramName: null,
      paramValue: null,
      operator: null,
      isAbsolute: null
    }
    this.emptyParamOperatorBasedFrom();
    this.checkAddParameterFormValidity();
    // this.checkSaveRouteFormValidity();
    this.isEditable = false;
    this.isParameterDisabled = false;
    this.isOperatorDisabled = false;
    // }, 200);
    this.hideSubmit = false;
    this.currRowIndex = -1;
  }
  async editCriteria(idx) {
    console.log('===========editCriteria===========');
    console.log(idx);
    console.log(this.currRowIndex);
    this.currRowIndex = idx;
    this.isEditable = true;
    let rowData = this.routeCriteriaTableData[idx];
    this.criteriaFormJSON = {//test
      paramName: null,
      isAbsolute: null,
      operator: null,
      paramValue: null
    };
    this.dropDownParameterData['selectedData'] = [];
    this.dropDownOperatorData['selectedData'] = [];
    this.emptyParamOperatorBasedFrom();
    this.currentCriteriaRowData = !!rowData ? rowData : null;
    console.log(this.routeCriteriaTableData);
    if (this.currentCriteriaRowData) {
      this.populateAndOpenEditCriteriaForm();
    }
  }
  populateAndOpenEditCriteriaForm() {
    console.log('===========populateAndOpenEditCriteriaForm==============');
    console.log(this.currentCriteriaRowData);
    console.log(this.criteriaFormJSON);
    this.criteriaFormJSON = this.currentCriteriaRowData;
    let isInputHTML = this.currentCriteriaRowData.isAbsolute;
    let paramName = this.currentCriteriaRowData.paramName;
    let parameterArr = this.dropDownParameterData.options.filter(p => p.parameter == paramName);
    let paramValue = this.currentCriteriaRowData.paramValue;
    this.dropDownParameterData['selectedData'] = parameterArr;
    this.selectedParameter = parameterArr[0];
    // this.isParameterDisabled = true;
    let operator = this.currentCriteriaRowData.operator;
    let operatorArr = operators.filter(o => o.operator == operator);
    this.dropDownOperatorData['selectedData'] = operatorArr;
    this.selectedOperator = operatorArr[0];
    this.currentOperatorObj = operatorArr[0];
    // this.isOperatorDisabled = true;
    console.log(isInputHTML, operatorArr, parameterArr, paramValue);


    console.log(this.criteriaFormJSON, this.dropDownParameterData['selectedData'], this.dropDownOperatorData['selectedData']);
    console.log("%c" + isInputHTML, "color:green;font-size:14px");
    console.log("%c" + operator, "color:green;font-size:14px");
    console.log(paramValue);
    let payloadObj: any = { names: [paramName] };
    this.store.dispatch(masterDataActions.getStoneMasterDetailById({ payload: payloadObj }));
    this.paramterTypeFromTo();
    // ==============EDIT CriteriaForm population START=============
    if (isInputHTML === 0) {
      if (operator && (operator == 'equals' || operator == 'notEqual')) {
        this.inputParamValue = paramValue.paramValue;
      }
      else if (operator && (operator == 'range' || operator == 'priorityRange')) {
        if (operator == 'range') {
          this.inputFromParamValue = paramValue.lowerLimit;
          this.inputToParamValue = paramValue.upperLimit;
        } else {
          this.inputFromParamValue = paramValue.value.lowerPriorityLimitId;
          this.inputToParamValue = paramValue.value.upperPriorityLimitId;
        }
      } else if (operator && (operator == 'in' || operator == 'notIn')) {
        this.selectedInNotInParamValue = this.currentCriteriaRowData.paramStrValue;
      }
      // console.log(this.criteriaFormJSON, this.rangeParamStrValue,);
    } else {
      if (operator && (operator == 'equals' || operator == 'notEqual')) {
        this.dropDownFromData['selectedData'] = [{ id: paramValue.paramValue, fromParameter: paramValue.paramValueText }];
        // this.selectedFromParamValue = { id: paramValue.id, fromParameter: paramValue.paramValue };
        this.selectedFromParamValue = { id: paramValue.paramValue, fromParameter: paramValue.paramValueText };
        // this.criteriaFormJSON['paramValue'] = { id: paramNameObj.id, paramValue: paramValueId,paramValueText:paramValue };
      } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
        console.log(operator);
        console.log(paramValue);
        if (operator == 'range') {
          // let currInNotInData: any = paramValue.values.map((id: any, k: number) => {
          //   let values = paramValue.valuesText[k], obj = {};
          //   obj = {
          //     id: id, name: values
          //   }
          //   return obj;
          // });
          this.dropDownFromData['selectedData'] = [{ id: paramValue.lowerLimit, fromParameter: paramValue.lowerLimitText }];
          this.selectedFromParamValue = { id: paramValue.lowerLimit, fromParameter: paramValue.lowerLimitText };
          this.dropDownToData['selectedData'] = [{ id: paramValue.upperLimit, toParameter: paramValue.upperLimitText }];
          this.selectedToParamValue = { id: paramValue.upperLimit, toParameter: paramValue.upperLimitText };
        } else {
          // let currInNotInData: any = paramValue.values.map((id: any, k: number) => {
          //   let values = paramValue.valuesText[k], obj = {};
          //   obj = {
          //     id: id, name: values
          //   }
          //   return obj;
          // });
          let fromParamValueObj: Object = { id: paramValue.value.lowerPriorityLimitId, fromParameter: paramValue.value.lowerPriorityLimitText }
          let toParamValueObj: Object = { id: paramValue.value.upperPriorityLimitId, toParameter: paramValue.value.upperPriorityLimitText }
          this.dropDownFromData['selectedData'] = [fromParamValueObj];
          this.selectedFromParamValue = fromParamValueObj;
          this.dropDownToData['selectedData'] = [toParamValueObj];
          this.selectedToParamValue = toParamValueObj;
        }
      } else if (operator && (operator == 'in' || operator == 'notIn')) {
        let currInNotInData: any = paramValue.values.map((id: any, k: number) => {
          let values = paramValue.valuesText[k], obj = {};
          obj = {
            id: id, name: values
          }
          return obj;
        });
        this.selectedInNotInParamValue = paramValue.valuesText.join();
        if (operator == 'notIn') {
          // let paramValueArr = this.dropDownNotInData.options.filter(p => paramValue.values.indexOf(p.id) > -1);
          this.dropDownNotInData['selectedData'] = currInNotInData;
          // console.log(operator, paramValueArr, this.dropDownInData['selectedData']);
        } else {
          // let paramValueArr = this.dropDownInData.options.filter(p => paramValue.values.indexOf(p.id) > -1);
          this.dropDownInData['selectedData'] = currInNotInData;
          // console.log(operator, paramValueArr, this.dropDownInData['selectedData']);
        }
      }
    }
    this.checkAddParameterFormValidity();
    // this.checkSaveRouteFormValidity();
    // ==============EDIT CriteriaForm population END=============
  }
  async removeCriteria(idx) {
    console.log('removeCriteria');
    console.log(idx);
    const alert = await this.alertController.create({
      header: 'Are you sure do you want to delete the row?',
      // subHeader: 'Subtitle for alert',
      // message: 'Are you sure doyou want to delete the row?',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'CLOSE',
        handler: (d: any) => {
          console.log('Closed', d);
        }
      },
      {
        text: 'OK',
        handler: (d: any) => {
          console.log('-----------temp-----------');
          this.routeCriteriaTableData.splice(idx, 1);
          this.hideSubmit = false;
          // this.routeCriteriaTableData = this.routeCriteriaTableData.filter(d => d.tempId != id);
        }
      }]
    });
    await alert.present();
  }
  onDismisOfUserPushOver(evt) {
    console.log('onDismisOfUserPushOver');
    console.log(evt);
  }
  //Get Data of Route Master Grid
  getRoutemasterData() {
    this.httpService.fetchData(true, routeMasterAPIs.routeMasterEndPoint).subscribe(response => {
      // console.log('getRoutemasterData');
      // console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.routeMasterData = response.data;
      } else {
        console.error(response);
      }
      console.log(this.routeMasterData);
    });
  }
  dropDownSelection(evt: any, operator: any) {
    // console.log('dropDownSelection');
    // console.log(evt);
    let selectedArrayList = evt.arrayList;
    if (evt.property == "routeType") {
      this.dropDownRouteTypeData['selectedData'] = selectedArrayList;
      this.routeFormJSON['routeType'] = selectedArrayList;
    } else if (evt.property == "parameter") {
      this.dropDownParameterData['selectedData'] = selectedArrayList;
      if (selectedArrayList.length > 0) {
        this.selectedParameter = !!selectedArrayList[0] ? selectedArrayList[0] : null;
        this.criteriaFormJSON['paramName'] = this.selectedParameter.parameter;
      } else {
        console.log(selectedArrayList);
        this.selectedParameter = null;
        this.criteriaFormJSON['paramName'] = this.selectedParameter;
      }
      this.criteriaFormJSON['paramNameObj'] = { id: null, name: this.criteriaFormJSON['paramName'] };
      this.rangeParamStrValue = "";
      if (this.selectedParameter) {
        this.isInputDropdown = null;
        let currSelctedParamObj = this.parameters.filter((p: any) => p.parameter == this.selectedParameter.parameter)[0];
        if (currSelctedParamObj) {
          this.isInputDropdown = currSelctedParamObj.isAbsolute != null ? parseInt(currSelctedParamObj.isAbsolute) : null;
          this.criteriaFormJSON['isAbsolute'] = this.isInputDropdown;
        }
        let payloadObj: object = {
          names: [this.selectedParameter.parameter]
        };
        this.store.dispatch(masterDataActions.getStoneMasterDetailById({ payload: payloadObj }));
        this.paramterTypeFromTo();
        console.log(currSelctedParamObj, this.isInputDropdown);
      }
      this.checkAddParameterFormValidity();
      console.log(this.criteriaFormJSON);
    } else if (evt.property == 'title') {
      //operator dropdown selectedArrayList
      // this.ngOnChanges();
      if (selectedArrayList.length > 0) {
        this.selectedOperator = selectedArrayList[0];
      } else {
        this.selectedOperator = null;
        console.log(selectedArrayList);
      }
      this.setOperatorObject();
      console.log(this.currentOperatorObj);
      this.criteriaFormJSON['operator'] = null;
      this.rangeParamStrValue = "";
      if (this.currentOperatorObj) {
        let operator: string = this.currentOperatorObj.operator;
        this.criteriaFormJSON['operator'] = operator;
        this.selectJSONForm = JSON.parse(JSON.stringify(preDefinedRouteJSON[operator]));
        this.criteriaFormJSON['paramValue'] = {};
        console.log(operator);
        console.log(this.isInputDropdown);
        console.log(this.selectJSONForm);
        if (this.selectJSONForm) {
          Object.keys(this.selectJSONForm).forEach(d => {
            if (this.isInputDropdown === 0) {
              if (operator && (operator == 'equals' || operator == 'notEqual')) {
                // delete this.selectJSONForm["paramValueText"];
                delete this.selectJSONForm["paramName"]["paramValueText"];
              } else if (operator && operator == 'range') {
                delete this.selectJSONForm["paramName"]["lowerLimitText"];
                delete this.selectJSONForm["paramName"]["upperLimitText"];
              } else if (operator && operator == 'priorityRange') {
                delete this.selectJSONForm["paramName"]["value"]["lowerPriorityLimitText"];
                delete this.selectJSONForm["paramName"]["value"]["upperPriorityLimitText"];
              } else if (operator && (operator == 'in' || operator == 'notIn')) {
                delete this.selectJSONForm["paramName"]["valuesText"];
              }
            }
            console.log(d, this.selectJSONForm[d]);
            // this.routeJSONData[operator][this.selectedParameter.parameter]=this.selectJSONForm[d];
            this.criteriaFormJSON['paramValue'] = this.selectJSONForm[d];

          });
          console.log('----------------------');
          console.log(preDefinedRouteJSON[operator], this.criteriaFormJSON['paramValue']);
          // this.routeJSONData[operator][this.selectedParameter.parameter] = null;
        }
        console.log(this.criteriaFormJSON);
      }

      this.emptyParamOperatorBasedFrom();
      this.checkAddParameterFormValidity();
      console.log(this.criteriaFormJSON);

    } else if (evt.property == "fromParameter") {
      if (selectedArrayList.length > 0) {
        this.selectedFromParamValue = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      } else {
        console.log(selectedArrayList);
        this.selectedFromParamValue = null;
      }
      this.onParamDropDownValueChange('fromParameter', this.selectedFromParamValue);
      this.checkAddParameterFormValidity();
    } else if (evt.property == "toParameter") {
      if (selectedArrayList.length > 0) {
        this.selectedToParamValue = !!selectedArrayList[0] ? selectedArrayList[0] : null;
      } else {
        console.log(selectedArrayList);
        this.selectedToParamValue = null;
      }
      this.onParamDropDownValueChange('toParameter', this.selectedToParamValue);
      this.checkAddParameterFormValidity();
    } else if (evt.property == "name") { //in/NotIn
      this.selectedParameterValues = selectedArrayList;
      this.onParamDropDownValueChange('in_notIn', evt);
      this.checkAddParameterFormValidity();
    } else {
      console.log('no dropdown change');
    }
  }
  setOperatorObject() {
    console.log('setOperatorObject');
    console.log(this.selectedOperator, operators);
    this.currentOperatorObj = null;
    if (this.selectedOperator && !!this.selectedOperator.title) {
      this.currentOperatorObj = operators.filter(op => op.title == this.selectedOperator.title)[0];
    }
    console.log(this.currentOperatorObj);
  }
  emptyParamOperatorBasedFrom() {
    console.log('emptyParamOperatorBasedFrom');
    if (this.criteriaFormJSON.isAbsolute === 0) {
      this.inputParamValue = null;
      this.inputParamValue = null;
      this.inputFromParamValue = null;
      this.inputToParamValue = null;
      this.selectedInNotInParamValue = null;
    } else {
      this.selectedFromParamValue = null;
      this.selectedToParamValue = null;
      this.selectedInNotInParamValue = null;
      // this.selectedParameterValues = null;
      this.dropDownFromData['selectedData'] = [];
      this.dropDownToData['selectedData'] = [];
      this.dropDownInData['selectedData'] = [];
      this.dropDownNotInData['selectedData'] = [];
    }

  }
  paramterTypeFromTo() {
    this.suscriptionParam2 = this.store.pipe(select(FetchAllStoneParameterById)).subscribe(data => {
      if (data) {
        this.criteriaFormJSON['paramNameObj'] = { id: null, name: this.criteriaFormJSON['paramName'] };
        console.log('paramterTypeFromTo');
        console.log(data);
        if (data.length === 1) {
          this.fromParameter = [];
          this.toParameter = []
          let paramValues: any = data[0].paramValues;
          let parameterName: any = data[0].parameterName;
          let parameterId: any = data[0].parameterNameId;
          this.criteriaFormJSON['paramNameObj'] = { id: data[0].parameterNameId, name: data[0].parameterName };
          paramValues.forEach((curr: any, k: number) => {
            let obj1: object = {}, obj2: object = {};
            obj1 = { id: curr.id, fromParameter: curr.name };
            obj2 = { id: curr.id, toParameter: curr.name };
            this.fromParameter.push(obj1);
            this.toParameter.push(obj2);
            //  console.log(obj1);
          });
          this.dropDownFromData['options'] = this.fromParameter;
          this.dropDownToData['options'] = this.toParameter;
          this.dropDownInData['options'] = paramValues;
          this.dropDownNotInData['options'] = paramValues;
        } else {
          // if (data.length === 0) {
          // } else {
          // }
          // multiple Paramvalues
          // console.log(data);
        }

      }
    });
  }
  onInputParamValueChange(evt: any) {
    console.log('onParamValueChange');
    let operator: string = this.criteriaFormJSON.operator;
    if (operator && (operator == 'equals' || operator == 'notEqual')) {
      let value: string = !!this.inputParamValue ? this.inputParamValue.trim() : "";
      this.criteriaFormJSON['paramStrValue'] = value;
      // this.criteriaFormJSON['paramValue'] = value;
      this.criteriaFormJSON['paramValue'] = { id: null, paramValue: value };
    } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
      let fromParamValue = !!this.inputFromParamValue ? this.inputFromParamValue.trim() : "";
      let toParamValue = !!this.inputToParamValue ? this.inputToParamValue.trim() : "";
      this.criteriaFormJSON['paramStrValue'] = `${fromParamValue} - ${toParamValue}`;
      Object.keys(this.criteriaFormJSON['paramValue']).forEach(op => {
        console.log(operator);
        if (operator == 'range') {
          if (op == 'lowerLimit') {
            this.criteriaFormJSON['paramValue'][op] = fromParamValue;
          } else {
            this.criteriaFormJSON['paramValue'][op] = toParamValue;
          }
        } else {
          if (operator == 'priorityRange' && this.criteriaFormJSON['paramValue'][op]) {
            Object.keys(this.criteriaFormJSON['paramValue'][op]).forEach(key => {
              if (key == 'lowerPriorityLimitId') {
                this.criteriaFormJSON['paramValue'][op][key] = fromParamValue;
              } else {
                this.criteriaFormJSON['paramValue'][op][key] = toParamValue;
              }
            });
          } else {
            this.criteriaFormJSON['paramValue'][op] = null;
          }
        }
      });
    } else if (operator && (operator == 'in' || operator == 'notIn')) {
      let selectedInNotStringval = this.selectedInNotInParamValue ? this.selectedInNotInParamValue.trim() : ""
      let selectedInNotInArrayValue = selectedInNotStringval.split(',');
      this.criteriaFormJSON['paramStrValue'] = selectedInNotStringval;
      Object.keys(this.criteriaFormJSON['paramValue']).forEach(paramkey => {
        console.log(paramkey);
        if (paramkey == 'values') {
          this.criteriaFormJSON['paramValue'][paramkey] = selectedInNotInArrayValue;
        } else {
          this.criteriaFormJSON['paramValue'][paramkey] = null;
        }
      });
      console.log(selectedInNotInArrayValue);
    }
    console.log(this.criteriaFormJSON, this.rangeParamStrValue);
    this.checkAddParameterFormValidity();
  }
  onParamDropDownValueChange(dropdownName: string, valueObj: any) {
    let operator: string = this.criteriaFormJSON.operator;
    let paramNameObj = this.criteriaFormJSON['paramNameObj'];
    console.log('onParamDropDownValueChange');
    console.log(dropdownName);
    console.log(this.selectedFromParamValue);
    console.log(this.selectedToParamValue);
    console.log(this.criteriaFormJSON);
    console.log(paramNameObj);
    if (dropdownName == 'fromParameter') {
      if (operator && (operator == 'equals' || operator == 'notEqual')) {
        this.criteriaFormJSON['paramStrValue'] = this.selectedFromParamValue && this.selectedFromParamValue.fromParameter ? this.selectedFromParamValue.fromParameter : "-";
        let paramValueId = this.selectedFromParamValue && this.selectedFromParamValue.id ? this.selectedFromParamValue.id : null;
        let paramValue = this.selectedFromParamValue && this.selectedFromParamValue.fromParameter ? this.selectedFromParamValue.fromParameter : null;
        // this.criteriaFormJSON['paramValueText'] = paramValue;
        this.criteriaFormJSON['paramValue'] = { id: paramNameObj.id, paramValue: paramValueId, paramValueText: paramValue };
      } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
        // this.rangeParamStrValue += this.selectedFromParamValue.fromParameter + "-";
        // this.rangeParamStrValue += !!this.selectedFromParamValue && this.selectedFromParamValue.fromParameter ? this.selectedFromParamValue.fromParameter + " - " : "";
        let fromParamStrVal = this.selectedFromParamValue && this.selectedFromParamValue.fromParameter ? this.selectedFromParamValue.fromParameter : "-";
        let toParamStrVal = this.selectedToParamValue && this.selectedToParamValue.toParameter ? this.selectedToParamValue.toParameter : "-";
        this.criteriaFormJSON['paramStrValue'] = `${fromParamStrVal} - ${toParamStrVal}`;
        Object.keys(this.criteriaFormJSON['paramValue']).forEach(op => {
          console.log(operator);
          if (operator == 'range') {
            if (op == 'lowerLimit') {
              this.criteriaFormJSON['paramValue'][op] = this.selectedFromParamValue.id;
            }
            if (op == 'lowerLimitText') {
              this.criteriaFormJSON['paramValue'][op] = this.selectedFromParamValue.fromParameter;
            }
            if (op == 'paramNameId') {
              this.criteriaFormJSON['paramValue'][op] = paramNameObj.id;
            }
          } else {
            if (operator == 'priorityRange' && this.criteriaFormJSON['paramValue'][op]) {
              Object.keys(this.criteriaFormJSON['paramValue'][op]).forEach(key => {
                if (key == 'lowerPriorityLimitId') {
                  console.log(key);
                  this.criteriaFormJSON['paramValue'][op][key] = this.selectedFromParamValue.id;
                }
                if (key == 'lowerPriorityLimitText') {
                  this.criteriaFormJSON['paramValue'][op][key] = this.selectedFromParamValue.fromParameter;
                }
              });
            } else {
              this.criteriaFormJSON['paramValue'][op] = paramNameObj.id;//paramId not available
            }
          }
        });
      }
    } else if (dropdownName == 'toParameter') {
      if (operator && (operator == 'range' || operator == 'priorityRange')) {
        let fromParamStrVal = this.selectedFromParamValue && this.selectedFromParamValue.fromParameter ? this.selectedFromParamValue.fromParameter : "-";
        let toParamStrVal = this.selectedToParamValue && this.selectedToParamValue.toParameter ? this.selectedToParamValue.toParameter : "-";
        this.criteriaFormJSON['paramStrValue'] = `${fromParamStrVal} - ${toParamStrVal}`;
        Object.keys(this.criteriaFormJSON['paramValue']).forEach(op => {
          // this.criteriaFormJSON['paramStrValue'] = this.rangeParamStrValue;
          console.log(operator);
          if (operator == 'range') {
            if (op == 'upperLimit') {
              console.log(op);
              this.criteriaFormJSON['paramValue'][op] = this.selectedToParamValue.id;
            }
            if (op == 'upperLimitText') {
              this.criteriaFormJSON['paramValue'][op] = this.selectedToParamValue.toParameter;
            }
            if (op == 'paramNameId') {
              this.criteriaFormJSON['paramValue'][op] = paramNameObj.id;
            }
          } else {
            if (operator == 'priorityRange' && this.criteriaFormJSON['paramValue'][op]) {
              Object.keys(this.criteriaFormJSON['paramValue'][op]).forEach(key => {
                if (key == 'upperPriorityLimitId') {
                  this.criteriaFormJSON['paramValue'][op][key] = this.selectedToParamValue.id;
                }
                if (key == 'upperPriorityLimitText') {
                  this.criteriaFormJSON['paramValue'][op][key] = this.selectedToParamValue.toParameter;
                }
              });
            } else {
              this.criteriaFormJSON['paramValue'][op] = paramNameObj.id;//paramId not available
            }
          }
        });
      }
    } else if (dropdownName == 'in_notIn') {
      if (operator && (operator == 'in' || operator == 'notIn')) {
        let selectedInNotInValues = valueObj.arrayList.map(v => v.name).join();
        this.criteriaFormJSON['paramStrValue'] = selectedInNotInValues;
        Object.keys(this.criteriaFormJSON['paramValue']).forEach(paramkey => {
          console.log(paramkey);
          if (paramkey == 'values') {
            this.criteriaFormJSON['paramValue'][paramkey] = valueObj.idList;
          }
          if (paramkey == 'valuesText') {
            this.criteriaFormJSON['paramValue'][paramkey] = valueObj.arrayList.map(v => v.name);
          }
          if (paramkey == 'paramNameId') {
            this.criteriaFormJSON['paramValue'][paramkey] = paramNameObj.id;
          }
        });
        console.log(selectedInNotInValues, valueObj.idList);
      }
    }

    console.log(this.criteriaFormJSON, this.rangeParamStrValue);
    this.checkAddParameterFormValidity();
  }
  onParameterInputChange(evt) {//Currenly not in use
    console.log('onParameterChangeValue');
    console.log(evt);
    // this.selectedParameter = !!evt && evt.detail && evt.detail.value ? evt.detail.value  : null;
  }
  eventOnkeyPress(evt) {
    console.log('eventOnkeyPress');
    console.log(evt);
    if (evt.key == 'Enter') {
      this.addCriteria();
    }
  }
  createAllTextValuesForDropdownValues(rowData) {
    console.log('===========populateAndOpenEditCriteriaForm==============');
    console.log(this.currentCriteriaRowData);
    console.log(this.criteriaFormJSON);
    this.criteriaFormJSON = this.currentCriteriaRowData;
    let isInputHTML: number = this.currentCriteriaRowData.isAbsolute;
    let paramName = this.currentCriteriaRowData.paramName;
    let parameterArr = this.dropDownParameterData.options.filter(p => p.parameter == paramName);
    let paramValue = this.currentCriteriaRowData.paramValue;
    this.dropDownParameterData['selectedData'] = parameterArr;
    this.selectedParameter = parameterArr[0];
    // this.isParameterDisabled = true;
    let operator = this.currentCriteriaRowData.operator;
    let operatorArr = operators.filter(o => o.operator == operator);
    this.dropDownOperatorData['selectedData'] = operatorArr;
    this.selectedOperator = operatorArr[0];
    this.currentOperatorObj = operatorArr[0];
    // this.isOperatorDisabled = true;
    console.log(isInputHTML, operatorArr, parameterArr, paramValue);


    console.log(this.criteriaFormJSON, this.dropDownParameterData['selectedData'], this.dropDownOperatorData['selectedData']);
    console.log("%c" + isInputHTML, "color:green;font-size:14px");
    console.log("%c" + operator, "color:green;font-size:14px");
    console.log(paramValue);
    let payloadObj: any = { names: [paramName] };
    this.store.dispatch(masterDataActions.getStoneMasterDetailById({ payload: payloadObj }));
    this.paramterTypeFromTo();
    // ==============EDIT CriteriaForm population START=============
    if (isInputHTML === 0) {
      if (operator && (operator == 'equals' || operator == 'notEqual')) {
        this.inputParamValue = paramValue.paramValue;
      }
      else if (operator && (operator == 'range' || operator == 'priorityRange')) {
        if (operator == 'range') {
          this.inputFromParamValue = paramValue.lowerLimit;
          this.inputToParamValue = paramValue.upperLimit;
        } else {
          this.inputFromParamValue = paramValue.value.lowerPriorityLimitId;
          this.inputToParamValue = paramValue.value.upperPriorityLimitId;
        }
      } else if (operator && (operator == 'in' || operator == 'notIn')) {
        this.selectedInNotInParamValue = this.currentCriteriaRowData.paramStrValue;
      }
      // console.log(this.criteriaFormJSON, this.rangeParamStrValue,);
    } else {
      if (operator && (operator == 'equals' || operator == 'notEqual')) {
        this.dropDownFromData['selectedData'] = [{ id: paramValue.id, fromParameter: paramValue.paramValue }];
        this.selectedFromParamValue = { id: paramValue.id, fromParameter: paramValue.paramValue };
      } else if (operator && (operator == 'range' || operator == 'priorityRange')) {
        console.log(operator);
        console.log(paramValue);
        if (operator == 'range') {
          // let currInNotInData: any = paramValue.values.map((id: any, k: number) => {
          //   let values = paramValue.valuesText[k], obj = {};
          //   obj = {
          //     id: id, name: values
          //   }
          //   return obj;
          // });
          this.dropDownFromData['selectedData'] = [{ id: paramValue.lowerLimit, fromParameter: paramValue.lowerLimitText }];
          this.selectedFromParamValue = { id: paramValue.lowerLimit, fromParameter: paramValue.lowerLimitText };
          this.dropDownToData['selectedData'] = [{ id: paramValue.upperLimit, toParameter: paramValue.upperLimitText }];
          this.selectedToParamValue = { id: paramValue.upperLimit, toParameter: paramValue.upperLimitText };
        } else {
          // let currInNotInData: any = paramValue.values.map((id: any, k: number) => {
          //   let values = paramValue.valuesText[k], obj = {};
          //   obj = {
          //     id: id, name: values
          //   }
          //   return obj;
          // });
          let fromParamValueObj: Object = { id: paramValue.value.lowerPriorityLimitId, fromParameter: paramValue.value.lowerPriorityLimitText }
          let toParamValueObj: Object = { id: paramValue.value.upperPriorityLimitId, toParameter: paramValue.value.upperPriorityLimitText }
          this.dropDownFromData['selectedData'] = [fromParamValueObj];
          this.selectedFromParamValue = fromParamValueObj;
          this.dropDownToData['selectedData'] = [toParamValueObj];
          this.selectedToParamValue = toParamValueObj;
        }
      } else if (operator && (operator == 'in' || operator == 'notIn')) {
        let currInNotInData: any = paramValue.values.map((id: any, k: number) => {
          let values = paramValue.valuesText[k], obj = {};
          obj = {
            id: id, name: values
          }
          return obj;
        });
        this.selectedInNotInParamValue = paramValue.valuesText.join();
        if (operator == 'notIn') {
          // let paramValueArr = this.dropDownNotInData.options.filter(p => paramValue.values.indexOf(p.id) > -1);
          this.dropDownNotInData['selectedData'] = currInNotInData;
          // console.log(operator, paramValueArr, this.dropDownInData['selectedData']);
        } else {
          // let paramValueArr = this.dropDownInData.options.filter(p => paramValue.values.indexOf(p.id) > -1);
          this.dropDownInData['selectedData'] = currInNotInData;
          // console.log(operator, paramValueArr, this.dropDownInData['selectedData']);
        }
      }
    }
  }
  closeModal(param: any) {
    if (!!param) {
      this.modalCtrl.dismiss(param);
    } else {
      this.modalCtrl.dismiss();
    }
  }
  submitCriteria() {
    console.log('submitCriteria');
    /* this.criterionJSONData = {};
     this.routeCriteriaTableData.forEach((r: any) => {
       if (r.isAbsolute == 0) {
         if (this.criterionJSONData[r.operator] == undefined) {
           this.criterionJSONData[r.operator] = {};//do not empty it every time
           this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
         } else {
           this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
         }
       } else {
         console.log(r.operator, r.paramValue);
         //dropdown
         if (r.operator && (r.operator == 'equals' || r.operator == 'notEqual')) {
           if (this.criterionJSONData[r.operator] == undefined) {
             this.criterionJSONData[r.operator] = {};//do not empty it every time
             this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
             this.criterionJSONData[r.operator]['paramValueText'] = r.paramValueText;
           } else {
             this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
             this.criterionJSONData[r.operator]['paramValueText'] = r.paramValueText;
           }
         } else {
           if (this.criterionJSONData[r.operator] == undefined) {
             this.criterionJSONData[r.operator] = {};//do not empty it every time
             this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
           } else {
             this.criterionJSONData[r.operator][r.paramName] = r.paramValue;
           }
         }
       }
     });*/
    this.closeModal(this.routeCriteriaTableData);
  }
}
