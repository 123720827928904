import { Component, DebugElement, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { authUser } from 'src/app/authStore/auth.selector';
import { getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { HttpService } from 'src/app/services/http/http.service';
import { ProcessService } from 'src/app/services/process/process.service';
import { getClarities, getColors, getCuts, getShapes, getStoneRemarks } from '../trybesigner/state/signer.selector';
import { getClaritiesForChecker, getColorsForChecker, getCutsForChecker, getShapesForChecker, getStoneRemarksForChecker } from '../trybe-checker/state/checker.selector';
import { tempDataActions } from 'src/app/tempDataStore/tempData.action-type';
import { getCurrentRowData, getidsForOrderForm } from 'src/app/tempDataStore/tempData.selector';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popover-user',
  templateUrl: './popover-user.component.html',
  styleUrls: ['./popover-user.component.scss'],
})
export class PopoverUserComponent implements OnInit {
  loggedinUser$: Observable<any>;
  public forPage: string = '';
  roughCriteriaData: any = [];
  isPlanSelected: boolean = false;
  gridRow: any = {};
  enableSawRghWgt: boolean = false;
  isPopupDisabled: boolean = false;
  planDone: string = '';
  public selectedobj: any = {};
  currPacketPlanStone:any;
  @Input() property: string;
  public id: any;
  public pageForFilter: any;
  public packetid: any;
  allItems: any;
  public progRemarkName: string = '';
  public progRemarkId: number = 0;
  dropdown: any = {};
  public progR: string = '';
  public progRId: string = '';
  public grades: any = [];
  public oldGrades: any = [];
  public itemIds: any;
  isUpdateDisable: boolean = true;
  orderColorDropdown: any;
  orderFormSavebtn: boolean = true;
  orderShapeDropdown: any;
  orderClarityDropdown: any;
  environment: any = environment;
  nattsDropdown: any;
  lusterDropdown: any;
  labDropdown: any;
  polDropdown: any;
  symmetryDropdown: any;
  depthDropdown: any;
  ratioDropdown: any;
  topOpenDropdown: any;
  bottomOpenDropdown: any;
  planningReviewUpdated: any = {};
  tinchMaster: any[];
  cutMaster: any[];
  planningReviewResponse: any = {};
  orderFlDropdown: any;
  public selectedGrades: any = [];
  PRPS_colSize: number =  environment.project == 'kps' ? 0.8 : 0.8571;
  dropDownRemarkData: any = {
    name: 'Program Remark',
    options: [{ id: 0, name: 'Test Remark1' }, { id: 1, name: 'Test Remark2' }],
    selectedData: [],
    placeText: 'Select Remark',
    modeFlag: 'single',
    key: 'name'
  }
  dropDownStoneRemarkData: any = {
    name: 'Remark',
    options: [{ id: 0, name: 'Test Remark1' }, { id: 1, name: 'Test Remark2' }],
    selectedData: [],
    placeText: 'Select Remark',
    modeFlag: 'single',
    key: 'name'
  }
  dropDownFLData: any = {
    name: 'Fluorescence',
    placeText: 'FL',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLData: any = {
    name: 'Color',
    placeText: 'Color',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCLAData: any = {
    name: 'Clarity',
    placeText: 'Clarity',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownShapeData: any = {
    name: 'Shape',
    placeText: 'Shape',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownCutData: any = {
    name: 'Cut',
    placeText: 'CUT',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  dropDownPartyData: any = {
    name: 'Party',
    placeText: 'Select Party',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single',//single/multiple
  };
  selectProgramRemark: any = {};
  isDisableSubmit: boolean = true;
  selectedItems: any[] = [];
  isRemarkDisableSubmit: boolean = true;
  DropdownParamForOrderForm: any
  viewdropdownParamForOrderForm: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  isDisable_PlanFilter: boolean = false;
  searchdropdownItems: string;
  cut$: Observable<any>;
  isAnyItemsSelected: boolean = false;
  shape$: Observable<any>;
  clarity$: Observable<any>;
  color$: Observable<any>;
  stoneRemark$: Observable<any>;
  filterBy: any = {};
  name: any;
  idForView = [];
  popUpFrom: any = {
    selectedData: [],
  };
  popUpTo: any = {
    selectedData: [],
  };
  popupFrom:any;
  popupTo:any;
  rvVerifyRemark;
  ischeckedorderForm: any;
  packetPlanRemarks: any = [];
  @Input() defaultFilterData: any;
  partyId: any;
  highestOptionLength: number = 0;
  currPacketID: any;
  yehudaData: any;
  yehudaTitles: any = [];
  APM_Data: any = {
    parameter: null,
    advisorParameter: null,
    trybeParameter: null,
    tableData: [],
  };
  orderNattsDropdown: any;
  orderMilkynesssDropdown: any;
  orderPolishDropdown: any;
  orderSymmDropdown: any;
  orderTinchDropdown: any;
  
  searchClarityText: ""
  searchNattsText: ""
  searchColorText: ""
  searchFLText: ""
  searchTinchText: ""
  searchLusterText: ""
  searchShapeText: ""
  searchLabText: ""
  searchCutText: ""
  searchPolText: ""
  searchSymmText: ""
  searchDepthText: ""
  searchRatioText: "" 
  searchTopOpenText: ""
  searchBottomOpenText: ""

  constructor(
    private store: Store<AppState>,
    public router: Router,
    public httpService: HttpService,
    public processService: ProcessService,
    public storage: Storage,
    private navParams: NavParams,
    public configService: ConfigService,
    private http: HttpClient,
    public popoverController: PopoverController
  ) {
    this.store.pipe(select(getParamsValuesListByName('Remarks'))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.packetPlanRemarks = data;
        this.dropDownRemarkData.options = data;
      }
    });
  }

  ngOnInit() {

    this.store.pipe(select(getidsForOrderForm)).subscribe((data) => {
      if (data) {
        this.idForView = data;
      }
    })
    this.selectProgramRemark = {},
      this.selectedGrades = [];
    this.store.pipe(select(getParamsValuesListByName('Fluorescence'))).subscribe(data => {
      if (!!data) {
        this.orderFlDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.fluorescence)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        this.dropDownFLData.options = data;
        if (this.highestOptionLength < data.length)
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Color'))).subscribe(data => {
      if (!!data) {
        this.orderColorDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.color)
              return { ...item, isChecked: true };
   
          }
          return { ...item, isChecked: false }
        });
        this.dropDownCLData.options = data;
        if (this.highestOptionLength < data.length)
          this.highestOptionLength = data.length;
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Shape'))).subscribe(data => {
      if (!!data) {
        this.orderShapeDropdown = data.map((item) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.shape){
              return { ...item, isChecked: true };
            }
          }
          return { ...item, isChecked: false }
        });
        this.dropDownShapeData.options = data;
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })
    this.store.pipe(select(getParamsValuesListByName('NATTS'))).subscribe(data => {
      if (!!data) {
        this.orderNattsDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.shape)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Milkyness'))).subscribe(data => {
      if (!!data) {
        this.orderMilkynesssDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.shape)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
          
        });
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Polish'))).subscribe(data => {
      if (!!data) {
        this.orderPolishDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.shape)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Symmetry'))).subscribe(data => {
      if (!!data) {
        this.orderSymmDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.shape)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
      }
    })
    this.store.pipe(select(getParamsValuesListByName('Tinch'))).subscribe(data => {
      if (!!data) {
        this.orderTinchDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.shape)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
      }
    })

    this.store.pipe(select(getParamsValuesListByName('CLARITY'))).subscribe(data => {
      if (!!data) {
        this.orderClarityDropdown = data.map(item => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.clarity)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        this.dropDownCLAData.options = data;
        if (this.highestOptionLength < data.length)
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Tinch'))).subscribe(data => {
      if (data) {
        this.tinchMaster = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.tinchName)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false };
        }
          // ({ ...item, isChecked: false })
        );
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store
      .pipe(select(getCurrentRowData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rd: any) => {
        if (rd) {
          this.currPacketID = rd[0]?.id;
        }
      });


    this.store.pipe(select(getParamsValuesListByName('CUT'))).subscribe(data => {
      if (data) {
        this.cutMaster = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.cut)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('NATTS'))).subscribe(data => {
      if (data) {
        this.nattsDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.nattsName)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('LUSTER'))).subscribe(data => {
      if (data) {
        this.lusterDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.lusterName)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Lab'))).subscribe(data => {
      if (data) {
        this.labDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.labName)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Polish'))).subscribe(data => {
      if (data) {
        this.polDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.pol)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('Symmetry'))).subscribe(data => {
      if (data) {
        this.symmetryDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.symmetry)
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('DEPTH'))).subscribe(async (data) => {
      if (data) {
        this.depthDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            const range = item.name.match(/[0-9]+\.[0-9]+/g);
            // if(item.name == this.gridRow?.depthPercentage)
            if ((Number(range?.[0]) <= Number(this.gridRow?.depthPercentage)) && (Number(range?.[1]) >= Number(this.gridRow?.depthPercentage)))
              return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }

      
      const shapeId = this.orderShapeDropdown.find((item) => {
        if (this.forPage == 'planningRowParameterSelection') {
          return (item.name == this.gridRow?.shape)
        }
      })?.id;
      let r = await this.getDepthByShapeId(shapeId || this.gridRow?.shapeId);
      if (!!r?.data?.length) {
        this.depthDropdown = r.data.map((el:any) => {
          el.name = el.depth;
          if (this.forPage == 'planningRowParameterSelection') {
            const range = el.name.match(/[0-9]+\.[0-9]+/g);
            // if(item.name == this.gridRow?.depthPercentage)
            if ((Number(range?.[0]) <= Number(this.gridRow?.depthPercentage)) && (Number(range?.[1]) >= Number(this.gridRow?.depthPercentage)))
              return { ...el, isChecked: true };
          }
          return el
        }
        );
      }else{
        this.depthDropdown = []
      }
    })

    this.store.pipe(select(getParamsValuesListByName('TOP OPEN'))).subscribe(data => {
      if (data) {
        let isChecked = false;
        this.topOpenDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if(item.id == this.gridRow?.topOpen){
              isChecked = true;
              return { ...item, isChecked: true };
            }
            if (item.name == this.gridRow?.topOpenName) {
              isChecked = true;
              return { ...item, isChecked: true };
            }
            const isTopOpenVal : boolean = (this.gridRow?.topOpenName || this.gridRow?.topOpen)? true : false;
            if (item.name == "N/A" && !isChecked && !isTopOpenVal) {
              isChecked = true;
              return { ...item, isChecked: true };
            }
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    this.store.pipe(select(getParamsValuesListByName('RATIO'))).subscribe(async (data) => {
      if (data?.length) {
        let isChecked = false;
        this.ratioDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if (item.name == this.gridRow?.lOrW) {
              isChecked = true;
              return { ...item, isChecked: true };
            }
            if (item.name == "N/A" && !isChecked && !this.gridRow?.lOrW) {
              isChecked = true;
              return { ...item, isChecked: true };
            }
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
      
      const shapeId = this.orderShapeDropdown.find((item) => {
        if (this.forPage == 'planningRowParameterSelection') {
          return (item.name == this.gridRow?.shape)
        }
      })?.id;
      let r = await this.getRatioByShapeId(shapeId || this.gridRow?.shapeId);
      if (!!r?.data?.length) {
        this.ratioDropdown = r.data.map((el:any) => {
          el.name = el.ratio;
          if (this.forPage == 'planningRowParameterSelection') {
            const range = el.name.match(/[0-9]+\.[0-9]+/g);
            // if(item.name == this.gridRow?.depthPercentage)
            if ((Number(range?.[0]) <= Number(this.gridRow?.lOrW)) && (Number(range?.[1]) >= Number(this.gridRow?.lOrW)))
              return { ...el, isChecked: true };
          }
          return el
        }
        );
      }else{
        this.ratioDropdown = []
      }
    })

    this.store.pipe(select(getParamsValuesListByName('BOTTOM OPEN'))).subscribe(data => {
      if (data) {
        let isChecked = false;
        this.bottomOpenDropdown = data.map((item: any) => {
          if (this.forPage == 'planningRowParameterSelection') {
            if(item.id == this.gridRow?.bottomOpen){
              isChecked = true;
              return { ...item, isChecked: true };
            }
            if (item.name == this.gridRow?.bottomOpenName) {
              isChecked = true;
              return { ...item, isChecked: true };
            }
            const isBottomOpenVal: boolean = (this.gridRow?.bottomOpenName || this.gridRow?.bottomOpen) ? true : false
            if (item.name == "N/A" && !isChecked && !isBottomOpenVal) {
              isChecked = true;
              return { ...item, isChecked: true };
            }
          }
          return { ...item, isChecked: false }
        });
        if (this.highestOptionLength < data.length && this.forPage == 'planningRowParameterSelection')
          this.highestOptionLength = data.length;
      }
    })

    if (this.forPage == 'partyWiseDropdown')
      this.fetchAllParty();

    let d = this.navParams.data;
    if (!!this.navParams.data && this.navParams.data.hasOwnProperty('pageForFilter')) {
      this.pageForFilter = this.navParams.data.pageForFilter;
    }
    if (!!this.navParams.data && this.navParams.data.hasOwnProperty('dropdownparameter')) {
      this.DropdownParamForOrderForm = this.navParams.data.dropdownparameter;
      console.log(this.DropdownParamForOrderForm);

    }
    if (!!this.navParams.data && this.navParams.data.hasOwnProperty('selectedarrayOnBasicOfCriteria')) {
      if (!!this.navParams.data.selectedarrayOnBasicOfCriteria) {
        this.viewdropdownParamForOrderForm = this.navParams.data.selectedarrayOnBasicOfCriteria;
        console.log(this.viewdropdownParamForOrderForm);
        this.idForView = this.viewdropdownParamForOrderForm.map(obj => obj.id);
        if (this.idForView.length) {
          this.store.dispatch(tempDataActions.addOrderRemarkDataToStore({ ids: this.idForView }))
        }

      }

    }

    this.id = d.ID;
    this.packetid = d.packetId;
    this.selectedobj = d.selectedDatas;
    this.currPacketPlanStone = d.currPacketPlanStone;
    this.progRemarkName = !!d.name ? d.name : "";
    this.progRemarkId = !!d.id ? d.id : "";
    this.forPage = !!d.forPage ? d.forPage : "";
    this.roughCriteriaData = d?.roughCriteriaData || []
    this.gridRow = d?.gridRow || {};
    this.enableSawRghWgt = d?.enableSawRghWgt;
    this.planDone = d?.planDone || '';
    this.grades = !!d.grades ? d.grades : []
    if (this.forPage == 'Planning')
      this.isPlanSelected = d.isPlanSelected;
    this.grades = this.grades.filter((d: any) => {
      if (!!d.name && d.name != 'All') {
        d.checked = false;
        return d;
      }
    }) || [];
    if (!!this.selectedobj) {
      //==========OnClick Remark in Plan grid START (UPDATE)==========
      // this.dropDownRemarkData.selectedData = [{ id: '', name: this.selectedobj.remarkVal }];
      this.dropDownRemarkData.selectedData = this.packetPlanRemarks.filter(r => r.name === this.selectedobj.remarkVal);
      this.isRemarkDisableSubmit = false;
      this.grades = this.grades.filter((d: any) => {
        if (!!d.name && d.name == this.selectedobj.piece) {
          d.checked = true;
          return d;
        }
      });
      this.selectedGrades = JSON.parse(JSON.stringify(this.grades));
      //==========OnClick Remark in Plan grid END==========
    }

    if (this.forPage == 'viewMoreYehudaData') {
      this.getYehudaDetails(this.currPacketID)
    }

    if (this.pageForFilter === "planning") {
      this.cut$ = this.store.pipe(select(getCuts));
      this.shape$ = this.store.pipe(select(getShapes));
      this.clarity$ = this.store.pipe(select(getClarities));
      this.color$ = this.store.pipe(select(getColors));
      this.stoneRemark$ = this.store.pipe(select(getStoneRemarks));
    }

    if (this.pageForFilter === "checker") {
      this.cut$ = this.store.pipe(select(getCutsForChecker));
      this.shape$ = this.store.pipe(select(getShapesForChecker));
      this.clarity$ = this.store.pipe(select(getClaritiesForChecker));
      this.color$ = this.store.pipe(select(getColorsForChecker));
      this.stoneRemark$ = this.store.pipe(select(getStoneRemarksForChecker));
    }

    if (this.forPage == 'advisorParameterMapping') {
      this.cut$ = this.store.pipe(select(getParamsValuesListByName('CUT')));
      this.shape$ = this.store.pipe(select(getParamsValuesListByName('Shape')));
    }



    if (this.defaultFilterData) {
      for (let prop in this.defaultFilterData) {
        let val: any = this.defaultFilterData[prop] ?? [];
        let obj: any = {
          property: prop,
          arrayList: val
        }
        if (prop == 'Shape') {
          this.dropDownShapeData['selectedData'] = val;
        } else if (prop == 'Color') {
          this.dropDownCLData['selectedData'] = val;
        } else if (prop == 'Clarity') {
          this.dropDownCLAData['selectedData'] = val;
        } else if (prop == 'Cut') {
          this.dropDownCutData['selectedData'] = val;
        } else if (prop == 'Remark') {
          this.dropDownStoneRemarkData['selectedData'] = val;
        }
        this.populateObj(obj);
      }

    }

    // this.grades.push({ id: 1, name: 'A' });
    // this.grades.push({ id: 2, name: 'B' });
    this.loggedinUser$ = this.store.pipe(select(authUser));


    if (this.DropdownParamForOrderForm == 'Color') {
      this.selectDropdownParamForOrderForm('orderColorDropdown');
    }
    else if (this.DropdownParamForOrderForm == 'Clarity') {
      this.selectDropdownParamForOrderForm('orderClarityDropdown');
      // this.allItems = this.orderClarityDropdown;
    }
    else if (this.DropdownParamForOrderForm == 'Fluorescence') {
      this.selectDropdownParamForOrderForm('orderFlDropdown');
      // this.allItems = this.orderFlDropdown;
    }

    else if (this.DropdownParamForOrderForm == 'Shape') {
      this.selectDropdownParamForOrderForm('orderShapeDropdown');
      // this.allItems = this.orderShapeDropdown;
    }
    else if (this.DropdownParamForOrderForm == 'Natts') {
      this.selectDropdownParamForOrderForm('orderNattsDropdown')
    }
    else if (this.DropdownParamForOrderForm == 'Milkyness') {
      this.selectDropdownParamForOrderForm('orderMilkynesssDropdown')
    }
    else if (this.DropdownParamForOrderForm == 'Polish') {
      this.selectDropdownParamForOrderForm('orderPolishDropdown')
    }
    else if (this.DropdownParamForOrderForm == 'Symmetry') {
      this.selectDropdownParamForOrderForm('orderSymmDropdown')
    }
    else if (this.DropdownParamForOrderForm == 'Tinch') {
      this.selectDropdownParamForOrderForm('orderTinchDropdown')
    }
    else if (this.DropdownParamForOrderForm == 'CUT') {
      this.selectDropdownParamForOrderForm('cutMaster');
      // this.allItems = this.cutMaster;
    }
    else {
      this.allItems = []
    }

  }

  selectDropdownParamForOrderForm(dropdown: string) {
 
    const selectedValues = [];
    this.viewdropdownParamForOrderForm?.map((item: any) => {
      if (item?.key == this.DropdownParamForOrderForm) {
        item?.value?.datas?.map((vdt: any) => {
          if (vdt.isChecked)
            selectedValues.push(vdt.name);
        })
      }
    });
    this.allItems = this[dropdown]?.map((item: any) => {
      if (selectedValues.includes(item.name)) {
        this.selectedItems.push(item);
        return { ...item, isChecked: true };
      }
      return item;
    });
  }

  async logout() {
    this.store.dispatch({ type: 'Logout' });
    await this.storage.clear();
    this.httpService.isAuthenticated.next(false);
    this.popoverController.dismiss({ 'type': 'Logout', 'forPage': this.forPage });
    this.router.navigate(['/login']);
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }

    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }

  getFirstLetterOfEachWord(str: string) {
    if (!!str)
      var newStr = str.split(' ').reduce((result: any, currentWord: any) =>
        result + currentWord.charAt(0).toUpperCase(), '');
    return newStr;
  }
  dropDownSelection(evt) {
    if (evt.property == 'Shape') {
      this.populateObj(evt);
      this.dropDownShapeData['selectedData'] = evt.arrayList;
    }

    if (evt.property == "Program Remark") {
      if (evt.arrayList.length !== 0) {
        this.selectProgramRemark = evt.arrayList[0];
      }
      else {
        this.selectProgramRemark = {};
      }

    }
    this.isDisableSubmit = !this.selectProgramRemark.hasOwnProperty('name') || this.selectedGrades.length === 0;
    this.isRemarkDisableSubmit = !this.selectProgramRemark.hasOwnProperty('name') || (!this.isPlanSelected ? this.selectedGrades.length === 0 : false);
    if (!!this.selectedobj && Object.keys(this.selectedobj).length != 0) {
      this.isRemarkDisableSubmit = false;
    }

  }

  dropDownSelectionOrder(evt){

    if (evt.property == 'From') {
      this.popUpFrom = {...(evt?.arrayList?.[0]||{}), priority: this.allItems?.find((item:any) => (item.id==evt.arrayList?.[0]?.id))?.priority};
    } else if (evt.property == 'To') {
      this.popupTo =  {...(evt?.arrayList?.[0]||{}), priority: this.allItems?.find((item:any) => (item.id==evt.arrayList?.[0]?.id))?.priority};
    }

    if(this.popUpFrom?.priority && this.popupTo?.priority){
      this.allItems = this.allItems.map((item:any) => {
        if(item.priority >= this.popUpFrom?.priority  && item.priority <= this.popupTo?.priority){
          item.isChecked = true;
          if(!this.selectedItems.find((item:any) => (item.id == item.id)))
          this.selectedItems.push(item);
        }

        // console.log(item);
        return item;
      });
    } else {
      this.allItems.map((item:any) => {
          item.isChecked = false;
      });
      this.selectedItems = [];
    }
  }

  dropDownSelectionparam(evt: any, plan: any) {
    if (evt.property == 'Fluorescence') {
      this.setValuesOfDropdown(evt);
      this.populateObj(evt);
    } else if (evt.property == 'Color') {
      this.setValuesOfDropdown(evt);
      this.populateObj(evt);
    } else if (evt.property == 'Clarity') {
      this.setValuesOfDropdown(evt);
      this.populateObj(evt);
    } else if (evt.property == 'Remark') {
      this.populateObj(evt);
    }
    else if (evt.property == 'Party') {
      this.populateObj(evt);
      this.partyId = evt.idList;
    }

  }
  populateObj(evt) {
    if (evt.arrayList.length > 0) {
      if (!this.filterBy[evt.property]) {
        this.filterBy[evt.property] = [];
        this.filterBy[evt.property] = evt.arrayList;
      } else {
        if (!this.filterBy[evt.property]) {
          this.filterBy[evt.property] = [];
          this.filterBy[evt.property] = evt.arrayList;
        } else {
          this.filterBy[evt.property] = evt.arrayList;
        }
      }
    } else {
      if (this.filterBy[evt.property]) {
        delete this.filterBy[evt.property];
      }
    }
  }


  gradeSelection(evt: any, d) {
    if (evt.detail.checked) {
      this.selectedGrades.push(d);
    } else {
      let currIdx = this.selectedGrades.findIndex((i) => i.id == d.id);
      this.selectedGrades.splice(currIdx, 1);
    }
    this.isDisableSubmit = this.selectedGrades.length === 0 || !this.selectProgramRemark.hasOwnProperty('name');
    this.isRemarkDisableSubmit = this.selectedGrades.length === 0 || !this.selectProgramRemark.hasOwnProperty('name');
  }
  closePushOver(res) {
    if (res) {
      if(this.forPage === 'planningRowParameterSelection'){
        if(environment.project == 'kps'){
          const mandatoryKeys = {
            "shape": "Shape",
            "expPol": "Est Pol Weight",
            "color": "Color",
            "clarity": "Clarity",
            "fluorescence": "Fluorescence",
            "cut": "Cut",
            "pol": "Pol",
            "symmetry": "Symmetry",
            // "depthPercentage": "Depth",
            // "mRatio": "1.600",
            "nattsName": "Natts",
            "tinchName": "Tinch",
            "lusterName": "Luster",
            // "topOpenName": "Top Open",
            // "bottomOpenName": "Bottom Open"
          };
          for(const key in mandatoryKeys){
            if(!res?.gridRow?.[key]){
              this.configService.showToast('Error', `${mandatoryKeys[key]} is required`);
              return;
            }
            // if(!res?.gridRow?.height && !res?.gridRow?.depthPercentage){
            //   this.configService.showToast('Error', `Depth or Height is required`);
            //   return;
            // }
            if(!res?.gridRow?.diameter && !res?.gridRow?.lOrW){
              this.configService.showToast('Error', `Diameter or Ratio is required`);
              return;
            }
          }
        }
      }
      this.popoverController.dismiss(res);
    } else {
  
      this.popoverController.dismiss();
    }
  }
  onProgramRemarkSubmit(evt, msg) {
    let d = {
      selectProgramRemark: this.selectProgramRemark,
      selectedGrades: this.selectedGrades,
      filterBy: this.filterBy,
    }
    this.closePushOver(d);
    this.selectProgramRemark = {},
      this.selectedGrades = [];
  }
  onFilterSubmit(evt, msg) {
    let d = {
      filterBy: this.filterBy,
    }
    this.closePushOver(d);
  }

  ngOndestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }



  onRemarkSubmit(evt, msg) {
    this.selectedGrades = this.selectedGrades.filter(d => d.id != 0);
    let d = {
      selectProgramRemark: !!this.selectProgramRemark && this.selectProgramRemark.name ? this.selectProgramRemark.name : null,
      grade: this.selectedGrades,
    }

    this.closePushOver(d);
    this.selectProgramRemark = {},
      this.selectedGrades = [];
  }

  onUpdateSubmit(evt, msg) {
    this.selectedGrades = this.selectedGrades.filter(d => d.id != 0);
    let d = {
      dropdown: this.dropdown,
      grade: this.selectedGrades,
    }
    this.closePushOver(d);
    this.selectProgramRemark = {},
      this.selectedGrades = [];
  }

  onResetBtnOfRemarkInPlanning() {
    if(this.selectedobj?.packetPlanStoneId){
      this.httpService.getData(true, 'packetPlanStone/updateRemarkAndNoOfCuts', {id:this.selectedobj?.packetPlanStoneId, remark:null}).subscribe((res:any) => {
        if(res.statusCode === 200){
          this.configService.showToast('success', "Reset Succesfully");
          this.currPacketPlanStone.remark = null;
          this.closePushOver(null);
        }
        else
        this.configService.showToast(false, "Error Resetting Data");
      }, (err:any)=>{
        this.configService.showToast(false, "Error Resetting Data");
      });
      return;
    }
    const url = `${this.configService?.baseURL}packetPlan/setSignerPacketPlanRemarksToNull`;
    const payload = { "packetId": this.currPacketID };
    return this.httpService.patchData(true, 'packetPlan/setSignerPacketPlanRemarksToNull', payload).subscribe(
      (response) => {
        this.configService.showToast('success', "Reset Succesfully")
      },
      (error) => {
        this.configService.showToast(false, "Error Resetting Data")
      }
    );

    return this.http.patch(url, payload)
      .subscribe(
        (response) => {
          console.log('API response:', response);
          this.configService.showToast(true, "Reset Succesfully")
        },
        (error) => {
          console.error('API error:', error);
          this.configService.showToast(false, "Error Resetting Data")
        }
      );
  }


  onSaveCriteria(evt) {
    console.log(this.popUpFrom);
    console.log(this.popupTo);
    this.selectedItems = this.selectedItems.reduce((accumulator, currentValue) => {
      const existingItem = accumulator.find(item => item.id === currentValue.id);
      if (!existingItem) {
        accumulator.push(currentValue);
      }
    
      return accumulator;
    }, []);
    let d = {
      criteria: this.DropdownParamForOrderForm,
      selectDatas: this.selectedItems,
      selectedids: this.itemIds,
    }
    this.closePushOver(d);
  }

  onSaveRvVerifyRemark(evt) {
    let d = {
      remark: this.rvVerifyRemark
    }
    this.closePushOver(d);
  }


  onItemChange(e, currentData) {
    this.searchdropdownItems = "";
    this.itemIds = [];
    if (false) {
      if (e.detail.checked) {
        this.selectedItems = [];
        this.selectedItems.push(currentData);
      } else {
        let currRecordIndex = this.selectedItems.findIndex((i: any) => i.id == currentData.id);
        if (currRecordIndex > -1) {
          this.selectedItems.splice(currRecordIndex, 1);
        }
      }
      // this.makeSelectionChange(this.selectedItem);
    } else {
      if (e.detail.checked) {
        this.selectedItems.push(currentData);
      } else {
        let currRecordIndex = this.selectedItems.findIndex((i: any) => i.id == currentData.id);
        if (currRecordIndex > -1) {
          this.selectedItems.splice(currRecordIndex, 1);
        }
      }
    }
    this.selectedItems.forEach(el => {
      this.itemIds.push(el.id);
    });

    let returnMap: object = {
      property: this.property,
      idList: this.itemIds,
      selectedArray: [...this.selectedItems].map(d => {
        let obj = {
          id: d.id
        }
        obj[this.property] = d[this.property];
        return obj;
      }),
    }

    if (!!this.name) {
      returnMap['name'] = this.name;
    }
    if (this.selectedItems.length === 0) {
      this.orderFormSavebtn = true;
    } else {
      this.orderFormSavebtn = false
    }
    this.selectedItems = this.selectedItems.filter(item => item.isChecked = true)
  }

  makeSelectionChange(selectIds) {
    this.searchdropdownItems = "";
    let ids = [];
    ids = selectIds;
    if (false) {
      this.allItems = this.allItems.map(d => {
        if (ids.indexOf(d.id) > -1) {
          d.checked = true;
        } else {
          d.checked = false;
        }
        return d;
      });
    } else {
      this.allItems = this.allItems.map(d => {
        if (ids.indexOf(d.id) > -1) {
          d.checked = true;
        } else {
          d.checked = false;
        }
        return d;
      });
      let selectAll: boolean = this.allItems.every(function (item: any) {
        return item.checked == true;
      });
      let trueIndex = this.allItems.findIndex(item => item.checked == true);
      // if (selectAll) {
      //   this.isAllSelected = true;
      //   // this.isAnyItemsSelected = true;
      // } else {
      //   this.isAllSelected = false;
      //   // this.isAnyItemsSelected = true;
      // }
      if (trueIndex > -1) {
        this.isAnyItemsSelected = true;
      } else {
        this.isAnyItemsSelected = false;
      }
    }
  }

  onPartySelection(evt) {
    let d = this.partyId
    this.closePushOver(d);
  }



  async fetchAllParty() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "dbc-party-master/getPartyList");
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dropDownPartyData['options'] = [];
      this.dropDownPartyData['options'] = response.data;
    } else {
      console.error(response);
    }
  }

  depthPercentageChange(event: any) {
    if (!(!isNaN(event) && !isNaN(parseFloat(event))))
      return;

    this.depthDropdown = this.depthDropdown?.map((item: any) => {
      const range = item.name.match(/[0-9]+\.[0-9]+/g);

      if ((Number(range?.[0]) <= Number(event)) && (Number(range?.[1]) >= Number(event)))
        return { ...item, isChecked: true };
      else
        return { ...item, isChecked: false };
    });
  }

  ratioChange(length, width){
    if(length && width && environment.project=='kg')
    this.gridRow.lOrW = (length / width).toString();
  }

  async optionClick(variable: string, item: any, index:number, resKey?: string, resId?: any, PlanningUpdateKey?: string) {
   
    let isCheck = false;
    this[variable].map((res) => {
        if (item.id !== res.id) {
            res.isChecked = false;
        } else {
          isCheck = res.isChecked === true ? false : true;
          res.isChecked = res.isChecked === true ? false : true;
        }
        return res;
    });

    if (this.forPage == 'PlanningUpdate') {
      this.dropDownSelectionparam(
        {
          property: PlanningUpdateKey,
          arrayList: [
            this[variable][index]
          ]
        },
        null
      );
      return;
    }

    let depthIndex = -1;
    if ('depthPercentage' == resKey) {
      depthIndex = this[variable].findIndex((item: any) => item?.isChecked == true);
    }
  
    const range = item.name.match(/[0-9]+\.[0-9]+/g);
    if (resKey) {
      if ('depthPercentage' == resKey) {
        if (!resId)
          this.planningReviewUpdated[resKey] = range?.[1]
        this.gridRow[resKey] = range?.[1]
      } else {
        if (!resId){
          if(isCheck)
          this.planningReviewUpdated[resKey] = item.name;
          else
          this.planningReviewUpdated[resKey] = null;
        }
        if(isCheck)
        this.gridRow[resKey] = item.name;
        else
        this.gridRow[resKey] = null;
      }
      if ('shape' == resKey) {
        if (environment.project == 'kps') {
          let d = await this.getDepthByShapeId(item.id)
          if (!!d.data) {
            this.depthDropdown = d.data.map((el:any) => {
              el.name = el.depth;
              if (this.forPage == 'planningRowParameterSelection') {
                const range = el.name.match(/[0-9]+\.[0-9]+/g);
                // if(item.name == this.gridRow?.depthPercentage)
                if ((Number(range?.[0]) <= Number(this.gridRow?.depthPercentage)) && (Number(range?.[1]) >= Number(this.gridRow?.depthPercentage)))
                  return { ...el, isChecked: true };
              }
              return el
            }
            );
          }else{
            this.depthDropdown = []
          }

          let r = await this.getRatioByShapeId(item.id)
          if (!!r.data) {
            this.ratioDropdown = r.data.map((el:any) => {
              el.name = el.ratio;
              if (this.forPage == 'planningRowParameterSelection') {
                const range = el.name.match(/[0-9]+\.[0-9]+/g);
                // if(item.name == this.gridRow?.depthPercentage)
                if ((Number(range?.[0]) <= Number(this.gridRow?.lOrW)) && (Number(range?.[1]) >= Number(this.gridRow?.lOrW)))
                  return { ...el, isChecked: true };
              }
              return el
            }
            );
          }else{
            this.ratioDropdown = []
          }
        }
        this.depthDropdown =!!this.depthDropdown ?  this.depthDropdown.map((item: any) => ({ ...item, isChecked: false })) : [];
        if (!resId)
          this.planningReviewUpdated['depthPercentage'] = '';
        this.gridRow['depthPercentage'] = '';
      }
    }

    if (resId) {
      if(isCheck){
        this.planningReviewUpdated[resId] = item.id;
        this.gridRow[resId] = item.id;
      } else {
        this.planningReviewUpdated[resId] = null;
        this.gridRow[resId] = null;
      }
    }

    if (environment.project == 'kg' && !!item.isChecked && resKey == 'nattsName') {
      let d = await this.getPDCatValueFromNatts(item.id);
      if (!!d.data)
        this.planningReviewUpdated['PD Cat'] = d.data.pdCatId;
      this.gridRow['pdCat'] = d.data.pdCat;
      this.gridRow['pdCatId'] = d.data.pdCatId;
    }
      // }

    // })
    console.log(this.planningReviewUpdated, this.gridRow)
  }

  async getPDCatValueFromNatts(nattsId) {
    return await this.httpService.getPDCatValueFromNatts(nattsId)
  }


  async getDepthByShapeId(shapeId) {
    return await this.httpService.getDepthByShapeId(shapeId)
  }
  
  async getRatioByShapeId(shapeId) {
    return await this.httpService.getRatioByShapeId(shapeId)
  }

  APM_addRow() {
    if (!(this.APM_Data?.advisorParameter && this.APM_Data?.trybeParameter?.id))
      return;
    this.APM_Data.tableData.push(
      {
        parameter: this.APM_Data.parameter,
        advisorParameter: this.APM_Data.advisorParameter,
        trybeParameter: this.APM_Data.trybeParameter,
      }
    );
    setTimeout(() => {
      this.APM_Data.advisorParameter = null;
      this.APM_Data.trybeParameter = null;
    }, 0);
  }

  APM_removeRow(row: any) {
    this.APM_Data.tableData = this.APM_Data.tableData.filter((item: any) => item != row);
  }

  APM_save() {
    if (!this.APM_Data?.tableData?.length)
      return;

    const payload = this.APM_Data.tableData.map((item: any) => (
      {
        "inMapValue": item.advisorParameter,
        "masterId": item.trybeParameter.id
      }
    ));
    this.httpService.saveData(true, 'lab-parameter-mapping/save', payload).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.closePushOver(null)
        this.configService.showToast('success', res.message || "Saved Succesfully");
        this.APM_Data.tableData = [];
        this.APM_Data.advisorParameter = null;
        this.APM_Data.trybeParameter = null;
      } else
        this.configService.showToast(false, "Error Saving Data")
        this.closePushOver(null)

    })
  }

  getYehudaDetails(id) {
    console.log(id);
    if (id) {
      let endpoint: string = `packet-yehuda-data/getYehudaDataPacketWise?packetId=${id}`;
      this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
        if (res) {
          console.log('change Status');
          console.log(res);
          let resultCode: number = res.statusCode;
          console.log(resultCode);
          let d: any = res.data;
          console.log(d);
          if (resultCode == 200) {
            this.yehudaData = [];
            for (const key in res.data) {
              let ydata = {
                version: key,
              };
              res.data[key].map((item: any) => {
                ydata[item.paramName] = item.paramValue;
                if (!this.yehudaTitles.includes(item.paramName))
                  this.yehudaTitles.push(item.paramName);
              });
              this.yehudaData.push(ydata);
            }
            this.yehudaData.sort((a, b) => b.version - a.version);
          } else {
          }
        }
      });
    }
  }
}
