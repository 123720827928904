import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WidthAlertComponent } from './width-alert.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [WidthAlertComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  exports: [WidthAlertComponent],
})
export class WidthAlertModule { }