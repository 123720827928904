import { createReducer, on } from "@ngrx/store";
import { Int_GET_ChildParameters, Int_GET_MasterParameter } from "./masters.interface";
import { ADD_CHILD_PARAMETERS, ADD_MASTER_PARAMETERS, DELETE_CHILD_PARAMETERS, DELETE_MASTER_PARAMETERS, GET_CHILD_PARAMETERS, GET_CHILD_PARAMETERS_SUCCESS, GET_MASTER_PARAMETERS, GET_MASTER_PARAMETERS_SUCCESS, UPDATE_CHILD_PARAMETERS, UPDATE_MASTER_PARAMETERS } from "./masters.action";


const initialMasterParameters: Int_GET_MasterParameter = {
    masterList: [],
    isLoaded: false,
  };

export const masterParameterReducer = createReducer(
    initialMasterParameters,
    on(GET_MASTER_PARAMETERS, (state: Int_GET_MasterParameter) => state),
    on(GET_MASTER_PARAMETERS_SUCCESS, (state: Int_GET_MasterParameter, { payload }) => ({ ...state, ...payload })),
    on(ADD_MASTER_PARAMETERS, (state: Int_GET_MasterParameter, { payload }) => {
      const masterList: any[] = [...state.masterList];
      masterList.push(payload);
      return { ...state, masterList };
    }),
    on(UPDATE_MASTER_PARAMETERS, (state: Int_GET_MasterParameter, { payload }) => {
      const masterList: any[] = [...state.masterList];
      const findIndex = masterList?.findIndex((el) => el.id === payload.id);
      masterList[findIndex] = payload;
      return { ...state, masterList };
    }),
    on(DELETE_MASTER_PARAMETERS, (state: Int_GET_MasterParameter, { id }) => {
      const masterList: any[] = [...state.masterList];
      const activeList = masterList?.filter((el) => el.id !== id);
      return { ...state, masterList: activeList };
    })
);
const initialParameters: Int_GET_ChildParameters = {
    parameters: {},
    isLoaded: false,
  };

export const childParameterReducer = createReducer(
    initialParameters,
    on(GET_CHILD_PARAMETERS, (state: Int_GET_ChildParameters) => state),
    on(GET_CHILD_PARAMETERS_SUCCESS, (state: Int_GET_ChildParameters, { payload}) => { 
        const copyState = JSON.parse(JSON.stringify(state))
        copyState.parameters[payload?.stoneParameterNameId]= payload?.parameters
        return { ...state, ...copyState }
        }
    ),
    on(ADD_CHILD_PARAMETERS, (state: Int_GET_ChildParameters, { payload }) => {
        const copyState = JSON.parse(JSON.stringify(state))
        copyState.parameters[payload?.stoneParameterNameId] = payload?.parameters
        return { ...state, ...copyState };
        }
    ),
    on(UPDATE_CHILD_PARAMETERS, (state: Int_GET_ChildParameters, { payload }) => {
        const copyState = JSON.parse(JSON.stringify(state))
        copyState.parameters[payload?.stoneParameterNameId] = payload?.parameters
        return { ...state, ...copyState };
    }),
    on(DELETE_CHILD_PARAMETERS, (state: Int_GET_ChildParameters, { id }) => {
        const tempState = JSON.parse(JSON.stringify(state))
        delete tempState.parameters[id]
      return { ...state,  ...tempState};
    })
);