import { NgModule } from "@angular/core";
import { SingleMultiDropdownComponent } from "./single-multi-dropdown.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations:[
        SingleMultiDropdownComponent
    ],
    imports: [
        NgMultiSelectDropDownModule.forRoot(),
        FormsModule,
        CommonModule,
    ],
    exports: [
        SingleMultiDropdownComponent,
    ]
})
export class SingleMultiDropdownModule {}